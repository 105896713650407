import {IJsonPeriodo} from '../entities/periodos/jsonPeriodo.entity.interface';
import {EAreasRegionais} from '../datasources/areasregionais/areasRegionais.datasource.interface';
import {TDate} from '../../common/dates';

/* eslint-disable @typescript-eslint/naming-convention */

export enum ETipoRegularizacaoCampo40 {
  None,
  Art78_n_2,
  Art78_n_3,
  Art78_n_6,
  Art78_n_7_Incobraveis_Antes_2013_Alinea_a,
  Art78_n_7_Incobraveis_Antes_2013_Alinea_b,
  Art78_n_7_Incobraveis_Antes_2013_Alinea_c,
  Art78_n_7_Incobraveis_Antes_2013_Alinea_d,
  Art78_n_7_Incobraveis_Depois_2013_Alinea_a,
  Art78_n_7_Incobraveis_Depois_2013_Alinea_b,
  Art78_n_7_Incobraveis_Depois_2013_Alinea_c,
  Art78_n_7_Incobraveis_Depois_2013_Alinea_d,
  Art78_n_8_Alinea_d,
  Art78_n_8_Alinea_b,
  Art78_n_8_Alinea_c,
  Art78_n_8_Alinea_e,
  Art78_A_n_4_Alinea_a,
  Art78_A_n_4_Alinea_b,
  Art78_A_n_4_Alinea_c,
  Art78_A_n_4_Alinea_d,
  Art78_A_n_2_Alinea_a,
  Art78_B_n_4,
  RegularizacoesCujoCreditoNaoSejaSuperiorA750,
  RegularizacoesAbrangidasPelosArt23a26,
  OutrasRegularizacoes
}

export enum ETipoRegularizacaoCampo41 {
  None,
  Art78_n_3,
  Art78_n_4,
  Art78_n_6,
  Art78_n_7_Alinea_a,
  Art78_n_7_Alinea_b,
  Art78_n_7_Alinea_c,
  Art78_n_7_Alinea_d,
  Art78_n_8_Alinea_d,
  Art78_n_12,
  Art78_C_n_1,
  Art78_C_n_3,
  RegularizacoesAbrangidasPelosArt23a26,
  OutrasRegularizacoes,
  Artigo6DecretoLei198_90,
  DecretoLei19_2017
}

/* eslint-enable @typescript-eslint/naming-convention */

export enum ETipoOperacaoAnexoI {
  TransmissoesIntraUETipo1,
  TransmissoesOpTriangularesTipo4,
  PrestacoesServicosArt6Tipo5
}

export enum ECodigoAnexoI {
  None,
  EnvioBensRegimeVendasConsignacao,
  RetornoBensCorrecoesInformacoesAnteriormenteDeclaradas,
  AlteracaoDestinatarioDosBens
}

export enum ETipoAbrevPaisIntra {
  Austria,
  Belgica,
  Bulgaria,
  Chipre,
  RepublicaCheca,
  Alemanha,
  Dinamarca,
  Estonia,
  Grecia,
  Espanha,
  Finlandia,
  Franca,
  IrlandaDoNorte,
  Hungria,
  Irlanda,
  Italia,
  Lituania,
  Luxemburgo,
  Letonia,
  Malta,
  Holanda,
  Polonia,
  Portugal,
  Romenia,
  Suecia,
  Eslovenia,
  Eslovaquia,
  Croacia
}

export interface IJsonPeriodoDecIva extends IJsonPeriodo {
  comDecGravada: boolean;
  anoCivil: number;
}

export interface IJsonDecIvaConfig {
  username: string;
  password: string;
  servicoInvocadoPorCC: boolean;
  ccComPoderesDeclarativosContribuinte: boolean;
  usernameCC: string;
  passwordCC: string;
  isConfigured?: boolean;
}

export interface IJsonDecIvaPeriodicaValoresLocal {
  impostoFavSujeitoPassivo81: number;
  impostoFavSujeitoPassivo22: number;
  impostoFavSujeitoPassivo23: number;
  impostoFavEstado68: number;
  impostoFavSujeitoPassivo20: number;
  baseTributavel8: number;
  impostoFavSujeitoPassivo21: number;
  baseTributavel9: number;
  baseTributavel10: number;
  impostoFavEstado11: number;
  baseTributavel12: number;
  baseTributavel14: number;
  baseTributavel15: number;
  baseTributavel16: number;
  baseTributavel18: number;
  impostoFavSujeitoPassivo24: number;
  impostoFavSujeitoPassivo61: number;
  impostoFavSujeitoPassivo40: number;
  impostoFavSujeitoPassivo67: number;
  baseTributavel3: number;
  totalBaseTributavel: number;
  impostoFavEstado13: number;
  impostoFavEstado17: number;
  impostoFavEstado19: number;
  totalImpostoFavSujeitoPassivo: number;
  impostoFavSujeitoPassivo65: number;
  baseTributavel1: number;
  impostoFavEstado66: number;
  impostoFavEstado41: number;
  impostoFavEstado2: number;
  baseTributavel7: number;
  totalImpostoFavEstado: number;
  baseTributavel5: number;
  impostoFavEstado6: number;
  impostoFavEstado4: number;
  baseTributavel97: number;
  baseTributavel98: number;
  baseTributavel99: number;
  baseTributavel100: number;
  baseTributavel101: number;
  baseTributavel102: number;
  baseTributavel103: number;
  baseTributavel104: number;
  baseTributavel105: number;
  baseTributavel107: number;
  efectouOperacoesRevCharge: boolean;
  impostoEntregarEstado93: number;
  creditoDoImposto94: number;
  valorReembolsoSolicitado95: number;
  excessoAReportar96: number;
  localizacao: number;
  totalQuadroA106: number;
}

export interface IJsonDecIvaPerioAnexoRegularizacoes {
  erro: string;
  ivaRegularizado: number;
  nif: string;
  baseIncidencia: number;
  nDocInterno: string;
  periodo: string;
  nDiario: number;
  tipoRegularizacaoCampo41: ETipoRegularizacaoCampo41;
  tipoRegularizacaoCampo40: ETipoRegularizacaoCampo40;
  tipoRegularizacaoCampo41Str: string;
  tipoRegularizacaoCampo40Str: string;
  controloIva: string;
  cDecPer: string;
  nPedido: string;
  dataEmissao: TDate;
}

export interface IJsonDecIvaPeriodicaAnexoCampo40 {
  outrasRegularizacoesIvaRegularizado: number;
  regCujoCreditoNaoSejaSupA750IvaRegularizado: number;
  regAbrangidasArtigos23A26BaseIncidencia: number;
  outrasRegularizacoesBaseIncidencia: number;
  regCujoCreditoNaoSejaSupA750BaseIncidencia: number;
  regAbrangidasArtigos23A26IvaRegularizado: number;
  valorTotalCampo40: number;
  listaNIFsROC: Array<string>;
  listaRegularizacoes: Array<IJsonDecIvaPerioAnexoRegularizacoes>;
}

export interface IJsonDecIvaPeriodicaAnexoCampo41 {
  outrasRegularizacoesIvaRegularizado: number;
  regAbrangidasPelosArt23a26BaseIncidencia: number;
  outrasRegularizacoesBaseIncidencia: number;
  regAbrangidasPelosArt23a26IvaRegularizado: number;
  artigo6BaseIncidencia: number;
  decretoLei192017BaseIncidencia: number;
  artigo6IvaRegularizado: number;
  decretoLei192017IvaRegularizado: number;
  valorTotalCampo41: number;
  listaRegularizacoes: Array<IJsonDecIvaPerioAnexoRegularizacoes>;
}

export interface IJsonDecIvaPerioExtratoCampo {
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  nConta: string;
  nContrib: string;
  extPocCabID: string;
  valorDebito: number;
  valorCredito: number;
  codIva: number;
  areaRegional: number;
  areaRegionalStr: string;
  nCampoOficial: string;
  contaOrigem: string;
  nomeContaOrigem: string;
  valorBase: number;
}

export interface IJsonDecIvaPeriodica {
  nifEmpresa: string;
  nomeEmpresa: string;
  nifTOC: string;
  nomeTOC: string;
  doPeriodo: string;
  atePeriodo: string;
  periodo: string;
  dentroDoPrazo: boolean;
  localizacaoOperacoes: Array<EAreasRegionais>;
  dpIVArbLocSede: EAreasRegionais;
  actImobiliarias: boolean;
  inexistenciaOperacoesNoPeriodo: boolean;
  transaccoesIntraComunitarias: boolean;
  houveOperacoesDestaNatureza: boolean;
  existePaginaDeclaracaoIVA: boolean;
  dataDe: TDate;
  dataAte: TDate;
  temRelacoesDeReembolso: boolean;
  decIvaPerioErro: string;
  continente: IJsonDecIvaPeriodicaValoresLocal;
  acores: IJsonDecIvaPeriodicaValoresLocal;
  madeira: IJsonDecIvaPeriodicaValoresLocal;
  anexoCampo40: IJsonDecIvaPeriodicaAnexoCampo40;
  anexoCampo41: IJsonDecIvaPeriodicaAnexoCampo41;
}

export interface IJsonDecIvaRecapitulativaOpIntracomunitaria {
  paisDestino: ETipoAbrevPaisIntra;
  nContribuinte: string;
  valorOperacoes: number;
  tipoOperacao: ETipoOperacaoAnexoI;
  paisDestinoStr: string;
  paisDestinoAbreviatura: string;
  paisDestinoNome: string;
  tipoOperacaoStr: string;
}

export interface IJsonDecIvaRecapitulativa {
  nifEmpresa: string;
  nifTOC: string;
  anoAQueRespeita: string;
  mesTrimestreAQueRespeita: string;
  mesIncluidoTrimestreMes1: string;
  mesIncluidoTrimestreMes2: string;
  periodo: string;
  tipoDeclaracaoPrimeiraDeclaracao: boolean;
  emPrimeiraDecHouveAltTrimestralParaMensal: number;
  emSubstituicaoDecHouveAltTrimestralParaMensal: boolean;
  emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral: boolean;
  emSubstituicaoInexistenciaDeOperacoes: boolean;
  emSubstituicaoOutrosMotivos: boolean;
  isMensal: boolean;
  totalDasVendasDeMeiosDeTransporteNovosAParticularesDaUE: number;
  decIvaRecapErro: string;
  existePaginaDeclaracaoIVA: boolean;
  dataDe: TDate;
  dataAte: TDate;
  listaOpIntracomunitarias: Array<IJsonDecIvaRecapitulativaOpIntracomunitaria>;
  q2c24: boolean;
  q2c241: boolean;
  q2c2411: boolean;
  q2c2412: boolean;
  q2c242: boolean;
  q2c243: boolean;
  q06Lista: Array<IJsonDecIvaRecapitulativaQ06>;
}

export interface IJsonDecIvaRecapitulativaQ06 {
  paisDestino: ETipoAbrevPaisIntra;
  nContribuinte: string;
  nContribuinteOriginal: string;
  codigo: ECodigoAnexoI;
  paisDestinoStr: string;
  paisDestinoAbreviatura: string;
  paisDestinoNome: string;
  codigoStr: string;
}
