import {StateOrName} from '@uirouter/core';
import {isString} from 'pl-comps-angular';
import {EPortal} from '../enums/portals.enums';
import {STATE_NAME_PORTAL} from '../../core/services/portals/portals.service.interface';

export function extractStatePortalStateName(stateOrName: StateOrName): undefined | EPortal {
  const stateName = isString(stateOrName) ? stateOrName : stateOrName.name;
  if (!stateName.startsWith(STATE_NAME_PORTAL)) {
    return undefined;
  }
  return <EPortal>stateName.substring(0, stateName.indexOf('.', STATE_NAME_PORTAL.length + 1));
}
