import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonEstado} from './jsonEstado.entity.interface';
import {ENTITY_NAME_ESTADOS} from './estados.entity.interface';

export const ENTITY_ESTADOS: IEntityDefinition<IJsonEstado> = {
  name: ENTITY_NAME_ESTADOS,
  roles: [ROLE.RH, ROLE.ADMIN, ROLE.ADMINBASIC],
  searchPlaceholder: 'estados.pesquisa',
  pageTitle: 'global.menu.estados',
  metadata: {
    keyName: 'codEstado',
    fields: [
      {name: 'codEstado', width: '200px', caption: 'estados.fields.codEstado', validators: {required: true}},
      {name: 'nome', caption: 'estados.fields.nome', validators: {required: true}},
      {name: 'nomeAbr', caption: 'estados.fields.nomeAbr', validators: {required: true}},
      {name: 'tipoEstado', caption: 'estados.fields.tipoEstado', validators: {required: true}}
    ],
    order: 'codEstado',
    searchFields: 'codEstado,nome'
  },
  autocomplete: {
    rowTemplate: '{{codEstado}} - {{nome}}',
    output: 'nome',
    searchFields: 'codEstado,nome,nomeAbr'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    delete: false,
    search: true,
    filter: true
  }
};
