import {firstValueFrom} from 'rxjs';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IContabilidadeImpdecpeHeader} from '../contabilidade.impDecPe.module.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonQIVAP} from '../../../../../entities/qivaps/jsonQIVAP.entity.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {IReportInstance} from '../../../../../components/reports/input/reports.input.component.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {ENTITY_NAME_QIVAPS} from '../../../../../entities/qivaps/qivaps.entity.interface';

const BTN_ID_PROCESSAR = 'processar';
const END_INDEX_ANO = 4;

@Component({
  selector: 'contabilidade-impdecpe',
  templateUrl: './contabilidade.impDecPe.module.component.html'
})
export class ContabilidadeImpdecpeComponent extends ModuloComponent implements OnInit {
  public readonly outputPeriodo;
  public pdfUrl: string;
  public deNCampo: string;
  public ateNCampo: string;
  public imprimirCriterios: boolean;
  public reportModel: IJsonReport;
  public header: IContabilidadeImpdecpeHeader;

  private readonly _reportService: IReportInstance;
  private readonly _servicePeriodos: IPeriodosEntityService;
  private readonly _serviceQIVAP: IEntityService<IJsonQIVAP>;
  private _periodos: Array<IJsonPeriodo>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService
  ) {
    super(_injector);
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.deNCampo = '';
    this.ateNCampo = 'ZZZZ';
    this.imprimirCriterios = true;
    this.header = {
      doPeriodo: '',
      doPeriodoNome: '',
      atePeriodo: '',
      atePeriodoNome: ''
    };
    this._reportService = this._reportsRegistryService.get(EReport.Impdecpe);
    this._servicePeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._serviceQIVAP = this._entityServiceBuilder.build<IJsonQIVAP>(ENTITY_NAME_QIVAPS);
    this._getFirstQivap();
    this._loadPeriodos();
    this._loadDefaultReport();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: BTN_ID_PROCESSAR,
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => this._processar()
    });
  }

  public doPeriodoSelected(item: string): void {
    if (!item) {
      return;
    }
    this.header.doPeriodo = item;

    const doPeriodoAno: string = item.substring(0, END_INDEX_ANO);
    const atePeriodoAno: string = this.header.atePeriodo?.length ? this.header.atePeriodo.substring(0, END_INDEX_ANO) : '';

    if (doPeriodoAno !== atePeriodoAno) {
      this.header.atePeriodo = `${doPeriodoAno}123`;
    }

    this.header.atePeriodoNome = this._getDescriptionFromPeriodo(this.header.atePeriodo);
    this.header.doPeriodoNome = this._getDescriptionFromPeriodo(this.header.doPeriodo);
    this.header = {...this.header};
  }

  public atePeriodoSelected(item: string): void {
    if (!item || !this.header.doPeriodo?.length) {
      return;
    }
    this.header.atePeriodo = item;
    this._checkPeriodos();
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _getFirstQivap(): Promise<void> {
    // Only first page with 1 record
    return this._serviceQIVAP.query({pagina: 1, porpagina: 1}).then((response: THttpQueryResponse<IJsonQIVAP>) => {
      if (response.body.list.length) {
        this.deNCampo = response.body.list[0].nCampo;
      }
    });
  }

  private _loadPeriodos(): Promise<void> {
    return this._servicePeriodos.query().then((response: THttpQueryResponse<IJsonPeriodo>) => {
      this._periodos = response.body.list;
      const periodoToFind = `${this.configurations.empresa.anoEmCursoIRC}123`;
      const atePeriodo = this._periodos.find((periodo) => periodo.periodo === periodoToFind).periodo;
      this.header = {
        ...this.header,
        doPeriodo: this._periodos[0].periodo,
        doPeriodoNome: this._periodos[0].nome,
        atePeriodo: atePeriodo,
        atePeriodoNome: this._getDescriptionFromPeriodo(atePeriodo)
      };
      this.doPeriodoSelected(this.header.doPeriodo);
    });
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this.configurations.contabilidade.impdecpe.reportImpressao,
      title: this.configurations.contabilidade.impdecpe.reportImpressao
    };
    return this._reportService.query().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        this.reportModel = reports.find((reportItem: IJsonReport) => reportItem.name === this.reportModel.name) || reports[0];
      }
    });
  }

  private _getDescriptionFromPeriodo(periodo: string): string {
    const item = this._periodos.find((periodoItem: IJsonPeriodo) => periodoItem.periodo === periodo);
    return item ? item.nome : '';
  }

  private async _processar(): Promise<void> {
    this.pdfUrl = await firstValueFrom(
      this._contabilidadeEstatisticaService.getImpDecPeUrl(this.header.doPeriodo, this.header.atePeriodo, this.deNCampo, this.ateNCampo, this.reportModel.name, this.imprimirCriterios)
    );
    this._cardPanel.collapse();
  }

  private _checkPeriodos(): Promise<void> {
    if (this.header.doPeriodo && this.header.atePeriodo) {
      return this._servicePeriodos.obterAnoDosPeriodos(this.header.doPeriodo, this.header.atePeriodo).then(() => undefined);
    }
    return Promise.resolve();
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_ID_PROCESSAR);
    }
  }
}
