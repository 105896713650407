import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IJsonPerfilCategoriaDesconto} from '../jsonPerfilCategoriaAbDes.module.interface';
import {ROLE} from '../../../services/role.const';
import {ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO} from '../perfilCategoriaAbDes.module.interface';

export const ENTITY_PERFIL_CATEGORIA_DESCONTO: IEntityDefinition<IJsonPerfilCategoriaDesconto> = {
  name: ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO,
  asModule: false,
  entityUrl: 'perfiscategabdes/descontos',
  roles: [ROLE.RH],
  searchPlaceholder: 'perfilCategoriaDesconto.pesquisa',
  metadata: {
    keyName: 'ncategoriaabdes',
    fields: [
      {name: 'nCategoriaAbDes', width: '200px', caption: 'perfilCategoriaDesconto.fields.nCategoriaAbDes', validators: {required: true}},
      {name: 'tipoArredondamento', caption: 'perfilCategoriaDesconto.fields.tipoArredondamento', validators: {required: true}},
      {name: 'tipoRecolha', caption: 'perfilCategoriaDesconto.fields.tipoRecolha', validators: {required: true}},
      {name: 'unidadeDefeito', caption: 'perfilCategoriaDesconto.fields.unidadeDefeito', validators: {required: true}},
      {name: 'nomeUnidadeDefeito', caption: 'perfilCategoriaDesconto.fields.nomeUnidadeDefeito', validators: {required: true}},
      {name: 'tipoRendimento', caption: 'perfilCategoriaDesconto.fields.tipoRendimento', validators: {required: true}},
      {name: 'codRem', caption: 'perfilCategoriaDesconto.fields.codRem', validators: {required: true}},
      {name: 'nomeCodRem', caption: 'perfilCategoriaDesconto.fields.nomeCodRem', validators: {required: true}},
      {name: 'nContaDebitoEmp', caption: 'perfilCategoriaDesconto.fields.nContaDebitoEmp', validators: {required: true}},
      {name: 'nContaCreditoEmp', caption: 'perfilCategoriaDesconto.fields.nContaCreditoEmp', validators: {required: true}},
      {name: 'nContaDebitoPatronato', caption: 'perfilCategoriaDesconto.fields.nContaDebitoPatronato', validators: {required: true}},
      {name: 'nContaCreditoPatronato', caption: 'perfilCategoriaDesconto.fields.nContaCreditoPatronato', validators: {required: true}},
      {name: 'contribuiCalculoCustoPrecoH', caption: 'perfilCategoriaDesconto.fields.contribuiCalculoCustoPrecoH', validators: {required: true}}
    ]
  },
  actions: {
    new: false,
    detail: false,
    edit: true,
    delete: false,
    search: false,
    filter: false
  }
};
