<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscomerciais.modals.precoUnitario.modalTitle'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <div class="d-flex align-items-center mb-1" style="gap: 5px">
    <div><b [translate]="'docscomerciais.modals.precoUnitario.strings.artigo'"></b>:</div>
    <div style="color: #2196f3"><span [translate]="modalInput.linha.nArtigo"></span> - <span [translate]="modalInput.linha.nomeArtigo"></span></div>
  </div>
  <div class="d-flex align-items-center mb-3" style="gap: 5px">
    <div><b [translate]="'docscomerciais.modals.precoUnitario.strings.taxa'"></b>:</div>
    <div>
      <span style="color: #2196f3"><span [translate]="modalInput.linha.taxaIva.toString()"></span> %</span>
    </div>
  </div>

  <div class="card">
    <div class="card-header" style="padding: 0.5em 1.25em"><span [translate]="'docscomerciais.modals.precoUnitario.strings.qtd'"></span>: 1</div>
    <div class="card-body">
      <pl-group>
        <pl-group>
          <label [translate]="'docscomerciais.modals.precoUnitario.strings.precoUnitarioSemIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="prUnitarioSemIvaQtdOne"
              [properties]="{decimalsLimit: decimals}"
              [model]="model.prUnitarioSemIvaQtdOne"
              (modelChange)="prUnitarioSemIvaQtdOneChanged($event)"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'docscomerciais.modals.precoUnitario.strings.precoUnitarioComIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="prUnitarioComIvaQtdOne"
              [properties]="{decimalsLimit: decimals}"
              [model]="model.prUnitarioComIvaQtdOne"
              (modelChange)="prUnitarioComIvaQtdOneChanged($event)"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </div>
  </div>

  <div class="card mt-2" *ngIf="modalInput.linha.qtd1 > 1">
    <div class="card-header" style="padding: 0.5em 1.25em">
      <span [translate]="'docscomerciais.modals.precoUnitario.strings.qtd'"></span>: <span [translate]="modalInput.linha.qtd1.toString()"></span>
    </div>
    <div class="card-body">
      <pl-group>
        <pl-group>
          <label [translate]="'docscomerciais.modals.precoUnitario.strings.precoUnitarioSemIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="prUnitarioSemIvaQtdLine"
              [properties]="{decimalsLimit: decimals}"
              [model]="model.prUnitarioSemIvaQtdLine"
              (modelChange)="prUnitarioSemIvaQtdLineChanged($event)"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'docscomerciais.modals.precoUnitario.strings.precoUnitarioComIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="prUnitarioComIvaQtdLine"
              [properties]="{decimalsLimit: decimals}"
              [model]="model.prUnitarioComIvaQtdLine"
              (modelChange)="prUnitarioComIvaQtdLineChanged($event)"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary action-submit" [click]="close" [plPromise]="promise"><i class="fa fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
