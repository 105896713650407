import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {IGTOMultiFilters, IGTOResponsavel, IGTOSingleFilters} from './gto.module.interface';
import {IJsonEmpresaErpBasic} from '../../../interfaces/jsonEmpresa.interface';
import {IJsonERPUtilizador} from '../../../interfaces/jsonERPUtilizador.interface';
import {
  IJsonGTOAlerta,
  IJsonGTOAlertsRequest,
  IJsonGTOCheckDMR,
  IJsonGTOCheckLicenseEmpresa,
  IJsonGTOCheckLicenseEmpresas,
  IJsonGTOConfig,
  IJsonGTOCopyEmpresa,
  IJsonGTOLicenseInfo,
  IJsonGTOTarefa,
  IJsonGTOTarefaCopyAnoData,
  IJsonGTOTarefaCopyEmpresasData,
  IJsonGTOTarefaExecuteData,
  IJsonGTOTarefaNewOrUpdate,
  IJsonGTOTarefas,
  IJsonGTOTarefasMulti
} from './jsonGTO.module.interface';
import {IJsonNumber} from '../../../../common/interfaces/json';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class GtoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/gto`;
  }

  public getDataForSingleView(filters: IGTOSingleFilters): TServiceResponse<IJsonGTOTarefas> {
    return this._apiService.get<IJsonGTOTarefas>({
      url: `${this._path}/single`,
      params: {
        nempresa: filters.nempresa,
        ano: filters.ano,
        tipoTarefa: filters.tipoTarefa,
        nResponsavel: filters.nResponsavel
      }
    });
  }

  public getDataForMultiView(filters: IGTOMultiFilters): TServiceResponse<IJsonGTOTarefasMulti> {
    return this._apiService.get<IJsonGTOTarefasMulti>({
      url: `${this._path}/multi`,
      params: filters
    });
  }

  public getCompanyTasks(nempresa: string, ano: number): TServiceResponse<Array<IJsonGTOTarefa>> {
    return this._apiService.get<Array<IJsonGTOTarefa>>({
      url: `${this._path}/tarefas`,
      params: {nempresa: nempresa, ano: ano}
    });
  }

  public getConfig(nempresa: string): TServiceResponse<IJsonGTOConfig> {
    return this._apiService.get<IJsonGTOConfig>({
      url: `${this._path}/config`,
      params: {nempresa: nempresa}
    });
  }

  public getAlerts(data: IJsonGTOAlertsRequest): TServiceResponse<Array<IJsonGTOAlerta>> {
    return this._apiService.get<Array<IJsonGTOAlerta>>({
      url: `${this._path}/alertas`,
      params: data
    });
  }

  public getResponsaveis(): Promise<Array<IGTOResponsavel>> {
    return this._apiService.get<Array<IJsonERPUtilizador>>({url: `${this._path}/erpusers`}).then((response: HttpResponse<Array<IJsonERPUtilizador>>) => {
      return response.body.map<IGTOResponsavel>((erpUser: IJsonERPUtilizador) => {
        return {
          nResponsavel: erpUser.nutilizador,
          nomeResponsavel: erpUser.nomeCompleto
        };
      });
    });
  }

  public toggleActive(tarefaID: string, activate: boolean): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/activate`,
      params: {tarefaID: tarefaID, activate: activate}
    });
  }

  public applyGlobalConfig(config: IJsonGTOConfig): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/config-multi`,
      body: config
    });
  }

  public createOrUpdateTask(data: IJsonGTOTarefaNewOrUpdate): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/tarefa`,
      body: data
    });
  }

  public saveConfig(nempresa: string, config: IJsonGTOConfig): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/config/${nempresa}`,
      body: config
    });
  }

  public executeTask(data: IJsonGTOTarefaExecuteData): TServiceResponse<IJsonNumber> {
    return this._apiService.put<IJsonNumber, IJsonGTOTarefaExecuteData>({
      url: `${this._path}/execute`,
      body: data
    });
  }

  public copyToCompanies(data: IJsonGTOTarefaCopyEmpresasData): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/copy/empresas`,
      body: data
    });
  }

  public copyToYear(data: IJsonGTOTarefaCopyAnoData): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/copy/ano`,
      body: data
    });
  }

  public getEmpresasGabinete(): TServiceResponse<Array<IJsonEmpresaErpBasic>> {
    return this._apiService.get<Array<IJsonEmpresaErpBasic>>({
      url: `${this._path}/allempresasgabinete`
    });
  }

  public getEmpresasEEWithActiveTasks(): TServiceResponse<Array<IJsonEmpresaErpBasic>> {
    return this._apiService.get<Array<IJsonEmpresaErpBasic>>({
      url: `${this._path}/getEmpresasEEWithActiveTasks`
    });
  }

  public checkDMRSingle(nempresa: string, ano: number, tipoTarefa: number, nResponsavel: number, checkDMRAT: boolean, checkDMRSS: boolean): TServiceResponse<IJsonGTOCheckDMR> {
    return this._apiService.get<IJsonGTOCheckDMR>({
      url: `${this._path}/checkDMRSingle`,
      params: {
        nempresa: nempresa,
        ano: ano,
        tipoTarefa: tipoTarefa,
        nResponsavel: nResponsavel,
        checkDMRAT: checkDMRAT,
        checkDMRSS: checkDMRSS
      }
    });
  }

  public checkDMRMulti(ano: number, mes: number, tipoTarefa: number, nResponsavel: number, checkDMRAT: boolean, checkDMRSS: boolean, empresas: string): TServiceResponse<IJsonGTOCheckDMR> {
    return this._apiService.get<IJsonGTOCheckDMR>({
      url: `${this._path}/checkDMRMulti`,
      params: {
        ano: ano,
        mes: mes,
        tipoTarefa: tipoTarefa,
        nResponsavel: nResponsavel,
        checkDMRAT: checkDMRAT,
        checkDMRSS: checkDMRSS,
        empresas: empresas
      }
    });
  }

  public getAllEmpresasEE(): TServiceResponse<Array<IJsonEmpresaErpBasic>> {
    return this._apiService.get<Array<IJsonEmpresaErpBasic>>({
      url: `${this._path}/getAllEmpresasEE`
    });
  }

  public updateTaskNResponsavel(tarefaID: string, nResponsavel: number): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/tarefa/nresponsavel`,
      params: {
        tarefaID: tarefaID,
        nResponsavel: nResponsavel
      }
    });
  }

  public getCompaniesForCopy(excludeNEmpresa: string): TServiceResponse<Array<IJsonGTOCopyEmpresa>> {
    return this._apiService.get<Array<IJsonGTOCopyEmpresa>>({
      url: `${this._path}/copy/empresas`,
      params: {nempresa: excludeNEmpresa}
    });
  }

  public concluirTarefaSingle(gtoTarefasMarcID: string): TServiceResponse<IJsonNumber> {
    return this._apiService.put<IJsonNumber>({
      url: `${this._path}/concluirtarefasingle`,
      params: {gtotarefasmarcid: gtoTarefasMarcID}
    });
  }

  public concluirTarefaDentroPrazoSingle(gtoTarefasMarcID: string): TServiceResponse<IJsonNumber> {
    return this._apiService.put<IJsonNumber>({
      url: `${this._path}/concluirtarefadentroprazosingle`,
      params: {gtotarefasmarcid: gtoTarefasMarcID}
    });
  }

  public getTask(gtoTarefaId: string): TServiceResponse<IJsonGTOTarefa> {
    return this._apiService.get<IJsonGTOTarefa>({
      url: `${this._path}/tarefa`,
      params: {gtoTarefaId: gtoTarefaId}
    });
  }

  public deleteTask(gtoTarefaId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/tarefa`,
      params: {gtoTarefaId: gtoTarefaId}
    });
  }

  public getLicenseInfo(): TServiceResponse<IJsonGTOLicenseInfo> {
    return this._apiService.get<IJsonGTOLicenseInfo>({url: `${this._path}/license`});
  }

  public checkLicenseEmpresa(nEmpresa: string): TServiceResponse<IJsonGTOCheckLicenseEmpresa> {
    return this._apiService.get<IJsonGTOCheckLicenseEmpresa>({url: `${this._path}/license/${nEmpresa}`});
  }

  public checkLicenseEmpresas(list: Array<string>): TServiceResponse<IJsonGTOCheckLicenseEmpresas> {
    return this._apiService.put({
      url: `${this._path}/license/check/empresas`,
      body: list
    });
  }

  public licenseAddEmpresas(list: Array<string>): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/license/add/empresas`,
      body: list
    });
  }

  public toggleActiveMultiple(list: Array<string>, activate: boolean): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/activate-multi`,
      params: {activate: activate},
      body: list
    });
  }

  public getTarefasAC(query: string, ano: number, page: number, perPage: number): TServiceResponse<Array<string>> {
    return this._apiService.get<Array<string>>({
      url: `${this._path}/tarefas-ac`,
      params: {query: query, ano: ano, pagina: page, porpagina: perPage}
    });
  }

  public restoreTasks(nEmpresa: string, ano: number): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/restore/${nEmpresa}/${ano}`
    });
  }
}
