import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {IJsonErroList} from '../../../../interfaces/jsonErro.interface';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeDocsNaoSaldadosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/documentosnaosaldados`;
  }

  public getDocs(dePeriodo: string, atePeriodo: string, deConta: string, ateConta: string): TServiceResponse<IJsonErroList> {
    return this._apiService.get<IJsonErroList>({
      url: this._path,
      params: {
        dePeriodo: dePeriodo,
        atePeriodo: atePeriodo,
        deConta: deConta,
        ateConta: ateConta
      }
    });
  }
}
