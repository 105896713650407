import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {EMBEmissorasSTR} from './mbConfigs.module.interface';
import {IJsonConfigEmissora, IJsonConfigEmissoraDocFa} from './jsonMBConfigs.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class MBConfigsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/multibanco`;
  }

  public getTiposDocList(): TServiceResponse<Array<IJsonConfigEmissoraDocFa>> {
    return this._apiService.get<Array<IJsonConfigEmissoraDocFa>>({url: `${this._path}/tiposdocumentos`});
  }

  public getConfig(emissora: EMBEmissorasSTR): TServiceResponse<IJsonConfigEmissora> {
    return this._apiService.get<IJsonConfigEmissora>({url: `${this._path}/emissoras/${emissora}/config`});
  }

  public postMBConfigs(emissora: EMBEmissorasSTR, mbConfigs: IJsonConfigEmissora): TServiceResponse<IJsonConfigEmissora> {
    return this._apiService.post<IJsonConfigEmissora>({url: `${this._path}/emissoras/${emissora}/config`, body: mbConfigs});
  }

  public testCredentials(emissora: EMBEmissorasSTR, mbConfigs: IJsonConfigEmissora): TServiceResponse<void> {
    return this._apiService.get<void>({url: `${this._path}/testcredentials`, params: {apiid: mbConfigs.apiId, apikey: mbConfigs.apiKey, emissora: emissora}});
  }
}
