import {merge} from 'lodash-es';
import {Component, Injector, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {generateName, isEmpty, isObject, PlEditBaseComponent} from 'pl-comps-angular';
import {EntityDetailComponent} from '../detail/entity.detail.component';
import {IEntityDetailUpdatableField} from '../detail/entity.detail.component.interface';
import {IInputEntityOptions} from './input.entity.component.interface';

@Component({
  selector: 'input-entity',
  templateUrl: './input.entity.component.html'
})
// @ts-expect-error IInputEntityOptions interface can't change string to object
export class InputEntityComponent extends PlEditBaseComponent<object, IInputEntityOptions> implements OnInit, OnChanges, IEntityDetailUpdatableField {
  public key: string;
  public description: string;

  private _objectMode: boolean;

  constructor(
    protected readonly _injector: Injector,
    @Optional() public readonly entityDetail: EntityDetailComponent
  ) {
    super(_injector);
    this._defaultOptions = merge({}, this._defaultOptions, {
      entity: {
        name: undefined,
        keyTarget: undefined,
        output: undefined,
        outputKey: undefined,
        outputDescription: undefined,
        outputTarget: undefined
      },
      inlineMode: false
    });
    this._objectMode = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.entityDetail) {
      this.entityDetail.addField(this);
    }
    this._handleChanges();
    if (!this._objectMode) {
      if (this.entityDetail) {
        this.value = {};
        this.value[this.key] = this.entityDetail.model[this.key];
        this.value[this.description] = this.entityDetail.model[this.description];
      } else if (!isObject(this.value)) {
        const model = this.value;
        this.value = {};
        this.value[this.key] = model;
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const {model} = changes;
    if (model && !model.isFirstChange() && !this.entityDetail && !this._objectMode) {
      if (!isObject(model.currentValue) && this.value[this.key] !== model.currentValue) {
        this.value[this.key] = model.currentValue;
      }
    }
    super.ngOnChanges(changes);
  }

  public updateComponent(properties: IInputEntityOptions): void {
    super.updateComponent(properties);
    this._handleChanges();
  }

  public updateValue(value: object): void {
    if (!this.entityDetail && !isObject(value) && isObject(this.value) && this.value[this.key] !== value) {
      this.value = {...this.value};
      this.value[this.key] = value;
    } else {
      this.value = value || {};
    }
  }

  public updateField(model: object): void {
    let newModel: object = {};
    if (!this._objectMode) {
      for (const property of Object.keys(model)) {
        if (this.options.entity.keyTarget === property) {
          newModel[this.key] = model[property];
        }
        if (this.options.entity.outputTarget === property) {
          newModel[this.description] = model[property];
        }
      }
    } else {
      newModel = model[this.attrName];
    }
    this.value = newModel;
  }

  public changedValue(value: object): void {
    if (!this._objectMode || this.options.inlineMode) {
      return;
    }
    this.value = value;
    const property = this.attrName;
    if (this.entityDetail) {
      this.entityDetail.model[property] = this.value;
    }
    this._valueChanged(this.value, property);
    this.render();
  }

  public changedKey(value: object): void {
    if (isEmpty(value)) {
      value = null;
    }
    if (this._objectMode) {
      if (this.options.inlineMode) {
        this.render(value);
      }
      return;
    }
    this._changedKeyOrDescription(this.key, value);
    this._valueChanged(value, this.key);
    if (!this.entityDetail || this.options.inlineMode) {
      this.render(value);
    }
  }

  public changedDescription(value: string): void {
    if (!this.options.entity.outputTarget || this._objectMode) {
      return;
    }
    this._changedKeyOrDescription(this.description, value);
    this._valueChanged(value, this.description);
  }

  private _handleChanges(): void {
    const key = this.options.entity.keyTarget;
    this._objectMode = isEmpty(key) || this.options.inlineMode;
    this.key = generateName(key, 'key');
    this.description = generateName(this.options.entity.outputTarget, 'outputTarget');
  }

  private _changedKeyOrDescription(property: string, value: unknown): void {
    if (isObject(this.value)) {
      this.value[property] = value;
    }
    if (this.entityDetail) {
      this.entityDetail.model[property] = value;
    }
  }

  private _valueChanged(value: unknown, fieldName: string): void {
    if (this._plEdit) {
      this._plEdit.valueChanged(value, fieldName);
    } else if (this._plGroup) {
      this._plGroup.valueChanged(value, fieldName);
    }
  }
}
