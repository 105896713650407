import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {IPlNavPillCallback, IPlNavPillEventSelected, IPlTableCallback, IPlTableDefinition, IPlValidator, isEmptyObject, isNumber, PlAlertService, toInteger} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../services/config/config.service';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {EMascaraAnaliticaTipoCamposFacturacao, EMascaraAnaliticaTipoCamposSalarios, EMascaraAnaliticaTipoMascara} from '../../../entities/mascarasanalitica/mascaraAnalitica.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonReparticoesCCusto, IJsonReparticoesCCustoItem, IJsonReparticoesCCustoLinha} from '../../../entities/reparticoesccusto/jsonReparticoesCCusto.entity.interface';
import {ENTITY_NAME_REPCC_FATURACAO, IReparticoesCCustoEntityService} from '../../../entities/reparticoesccusto/reparticoesCCusto.entity.interface';
import {Moment} from 'moment';
import {normalizeDate} from '../../../../common/utils/moment.utils';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'reparticoesccusto',
  templateUrl: './reparticoesCCusto.component.html'
})
export class ReparticoesCCustoComponent implements OnInit, OnChanges {
  @Input() public model: IJsonReparticoesCCusto;
  @Input() public new: boolean;
  @Input() public type: EMascaraAnaliticaTipoMascara;
  @Input() public stateType: EEntityStateDetailType;
  @Input() public readonly: boolean;
  @Output() public readonly modelChange: EventEmitter<IJsonReparticoesCCusto>;
  @Output() public readonly evtChangedFormGroup: EventEmitter<UntypedFormGroup>;

  public readonly definitionReparticao: IDevExpressDataGrid;
  public readonly validatorCodTabela: IPlValidator<string>;
  public readonly validatorDataReferencia: IPlValidator<string>;
  public formGroup: UntypedFormGroup;
  public perDate: boolean;
  public showCentroCusto: boolean;
  public showCategoria: boolean;
  public showClasse: boolean;
  public showCodContab: boolean;
  public showDepartamento: boolean;
  public showFamilia: boolean;
  public showGrandeFamilia: boolean;
  public showNRefProcesso: boolean;
  public showSubDepartamento: boolean;
  public showSubFamilia: boolean;
  public showTipoArtigo: boolean;
  public showVendedor: boolean;
  public showZona: boolean;
  public showClasseTrabalhador: boolean;
  public showFuncao: boolean;
  public showCCustoFuncionario: boolean;
  public showCategoriaFamilia: boolean;
  public dataSource: IJsonReparticoesCCustoItem;
  public plNavPill: IPlNavPillCallback;
  public callback: IPlTableCallback;
  public tableDefinition: IPlTableDefinition;

  private readonly _service: IReparticoesCCustoEntityService;
  private _dataGridInstance: dxDataGrid<IJsonReparticoesCCustoLinha, IJsonReparticoesCCustoLinha>;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _configService: ConfigService
  ) {
    this.modelChange = new EventEmitter<IJsonReparticoesCCusto>();
    this.evtChangedFormGroup = new EventEmitter<UntypedFormGroup>();
    this.validatorCodTabela = {
      message: 'Número de Tabela já existente.',
      validate: ({formControlValue}) => this._validateCodTabela(formControlValue)
    };
    this.validatorDataReferencia = {
      message: 'Data de referência já existe.',
      validate: ({modelValue}) => this._validateDataReferencia(modelValue)
    };

    this.definitionReparticao = {
      columns: [
        {dataField: 'perc', dataType: 'number', caption: 'reparticoesccustos.fields.perc'},
        {dataField: 'designacao', dataType: 'string', caption: 'reparticoesccustos.fields.designacao'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          alignment: 'center',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: new CustomStore({
        load: () => this._source()
      }),
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detail'},
      remoteOperations: false,
      searchPanel: {visible: true},
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'headerTemplateAddLinha',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this.plNavPill = {};
    this.callback = {};
    this.perDate = this._configService.configurations.contabilidade.reparticaocc.usadata;
    this._service = this._entityServiceBuilder.build<IJsonReparticoesCCusto, IReparticoesCCustoEntityService>(ENTITY_NAME_REPCC_FATURACAO);
  }

  public ngOnInit(): void {
    if (isEmptyObject(this.model)) {
      this.model = {
        nCodRepCC: undefined,
        descricao: '',
        mascara: '',
        items: [this._emptyItem()]
      };
      this.modelChange.emit(this.model);
    }
    if (this.model.mascara) {
      this._evaluateMascara(this.model.mascara);
    }
    if (this.model.items.length) {
      this.dataSource = this.model.items[0];
    }
  }

  public ngOnChanges({model}: SimpleChanges): void {
    if (model && !model.isFirstChange()) {
      if (this.model.mascara) {
        this._evaluateMascara(this.model.mascara);
      }
      this._refresh();
    }
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonReparticoesCCustoLinha, IJsonReparticoesCCustoLinha>): void {
    this._dataGridInstance = event.component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonReparticoesCCustoLinha, IJsonReparticoesCCustoLinha>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public populateDesignacao(value: string, linha: IJsonReparticoesCCustoLinha): void {
    linha.designacao = value;
    this.updateTable();
  }

  public changedFormGroup(value: UntypedFormGroup): void {
    this.formGroup = value;
    this.evtChangedFormGroup.emit(this.formGroup);
  }

  public changedMascara(value: string): void {
    this.model.mascara = value;
    this._evaluateMascara(this.model.mascara);
  }

  public changedPill(event: IPlNavPillEventSelected): void {
    const source: IJsonReparticoesCCustoItem = this.model.items.find((value, index: number) => index === toInteger(event.nextId.charAt(1)));
    this._setDataSource(source);
  }

  public addReferenceDate(): void {
    if (!this.model.items.length || this.model.items[this.model.items.length - 1].dataReferencia) {
      this.model.items.push({
        dataReferencia: undefined,
        linhas: []
      });
    }
    setTimeout(() => {
      const index: number = this.model.items.length - 1;
      this.plNavPill.select(`_${index}`);
    });
  }

  public deleteItem(reparticoesCCustoItem: IJsonReparticoesCCustoItem): () => Promise<void> {
    return () => this._deleteItem(reparticoesCCustoItem, '', '');
  }

  public addLine(reparticoesCCustoItem: IJsonReparticoesCCustoItem): () => Promise<void> {
    return () => this._addLine(reparticoesCCustoItem);
  }

  public deleteLine(repccid: number, reparticoesCCustoLinha: IJsonReparticoesCCustoLinha, reparticoesCCustoItem: IJsonReparticoesCCustoItem): () => Promise<void> {
    return () => this._deleteLine(repccid, reparticoesCCustoLinha, reparticoesCCustoItem);
  }

  public updateTable(): void {
    this.callback.refresh();
  }

  private _emptyItem(): IJsonReparticoesCCustoItem {
    return {dataReferencia: undefined, linhas: []};
  }

  private _source(): Array<IJsonReparticoesCCustoLinha> {
    return this.dataSource.linhas.slice();
  }

  private _evaluateMascara(value: string): void {
    switch (this.type) {
      case EMascaraAnaliticaTipoMascara.Facturacao:
        const camposFaturacao: typeof EMascaraAnaliticaTipoCamposFacturacao = this._tipoCamposFacturacao();
        this.showCentroCusto = value.includes(camposFaturacao.CentroCusto);
        this.showCategoria = value.includes(camposFaturacao.Categoria);
        this.showClasse = value.includes(camposFaturacao.Classe);
        this.showCodContab = value.includes(camposFaturacao.CodContab);
        this.showDepartamento = value.includes(camposFaturacao.Departamento);
        this.showFamilia = value.includes(camposFaturacao.Familia);
        this.showGrandeFamilia = value.includes(camposFaturacao.GrandeFamilia);
        this.showNRefProcesso = value.includes(camposFaturacao.Processo);
        this.showSubDepartamento = value.includes(camposFaturacao.SubDepartamento);
        this.showSubFamilia = value.includes(camposFaturacao.SubFamilia);
        this.showTipoArtigo = value.includes(camposFaturacao.TipoArtigo);
        this.showVendedor = value.includes(camposFaturacao.Vendedor);
        this.showZona = value.includes(camposFaturacao.Zona);
        break;
      case EMascaraAnaliticaTipoMascara.Salarios:
        const camposSalarios: typeof EMascaraAnaliticaTipoCamposSalarios = this._tipoCamposSalarios();
        this.showCentroCusto = value.includes(camposSalarios.CentroCusto);
        this.showCodContab = value.includes(camposSalarios.CodContab);
        this.showClasseTrabalhador = value.includes(camposSalarios.ClasseTrabalhador);
        this.showFuncao = value.includes(camposSalarios.Funcao);
        this.showCCustoFuncionario = value.includes(camposSalarios.CCustoFuncionario);
        this.showCategoriaFamilia = value.includes(camposSalarios.CategoriaFamilia);
        this.showZona = value.includes(camposSalarios.Zona);
        this.showDepartamento = value.includes(camposSalarios.Departamento);
        break;
    }
  }

  private _tipoCamposFacturacao(): typeof EMascaraAnaliticaTipoCamposFacturacao {
    return EMascaraAnaliticaTipoCamposFacturacao;
  }

  private _tipoCamposSalarios(): typeof EMascaraAnaliticaTipoCamposSalarios {
    return EMascaraAnaliticaTipoCamposSalarios;
  }

  private _validateCodTabela(formControlValue: string): boolean | Promise<boolean> {
    const value: number = toInteger(formControlValue);
    if (!formControlValue || isNaN(value)) {
      return true;
    }
    return this._service.validateCodTabela(value).then((response: HttpResponse<boolean>) => response.body);
  }

  private _validateDataReferencia(modelValue: string): boolean {
    const currentValue: Moment = normalizeDate(modelValue);
    if (!currentValue.isValid()) {
      return true;
    }
    const items: Array<IJsonReparticoesCCustoItem> = this.model.items.filter((item: IJsonReparticoesCCustoItem) => {
      return normalizeDate(item.dataReferencia).isSame(currentValue);
    });
    return items.length <= 1;
  }

  private _setDataSource(item: IJsonReparticoesCCustoItem): void {
    this.dataSource = item;
    this._refresh();
  }

  private _refresh(): Promise<void> {
    return this._dataGridInstance.refresh().then(() => undefined);
  }

  private _emptyLine(): IJsonReparticoesCCustoLinha {
    return {
      repCCID: undefined,
      perc: 0,
      cCusto: '',
      cCustoFuncionario: '',
      categoria: undefined,
      classe: undefined,
      classeTrab: '',
      codContab: '',
      departamento: undefined,
      familia: undefined,
      familiaCategoria: undefined,
      funcaoTrab: '',
      grandeFamilia: undefined,
      nRefProcesso: '',
      subDepartamento: '',
      subFamilia: undefined,
      tipoArtigo: undefined,
      vendedor: undefined,
      zona: undefined,
      nomeCCusto: '',
      nomeCategoria: '',
      nomeClasse: '',
      nomeContab: '',
      nomeDepartamento: '',
      nomeFamilia: '',
      nomeGrandeFamilia: '',
      nomeProcesso: '',
      nomeSubDepartamento: '',
      nomeSubFamilia: '',
      nomeTipoArtigo: '',
      nomeVendedores: '',
      nomeZona: '',
      designacao: ''
    };
  }

  private _addLine(reparticoesCCustoItem: IJsonReparticoesCCustoItem): Promise<void> {
    reparticoesCCustoItem.linhas.push(this._emptyLine());
    return this._refresh();
  }

  private async _deleteItem(reparticoesCCustoItem: IJsonReparticoesCCustoItem, title: string, message: string): Promise<void> {
    if (reparticoesCCustoItem.dataReferencia || reparticoesCCustoItem.linhas.length || !this.perDate) {
      if (title === '' && message === '') {
        title = 'reparticoesccustos.title.apagarDatadeReferencia';
        message = 'reparticoesccustos.message.desejaApagarDataReferencia';
      }
      await this._cgModalService.showOkCancel(title, message);
    }
    const needRequestServer = reparticoesCCustoItem.linhas.findIndex((value) => isNumber(value.repCCID));
    if (needRequestServer !== -1) {
      await this._service.apagarDataReferencia(this.model.nCodRepCC, reparticoesCCustoItem.dataReferencia);
    } else {
      const itemIndex: number = this.model.items.indexOf(reparticoesCCustoItem);
      if (itemIndex !== -1) {
        this.model.items.splice(itemIndex, 1);
        if (!this.model.items.length) {
          this.model.items.push(this._emptyItem());
          this._setDataSource(this.model.items[0]);
        }
      }
    }
  }

  private async _deleteLine(repccid: number, reparticoesCCustoLinha: IJsonReparticoesCCustoLinha, reparticoesCCustoItem: IJsonReparticoesCCustoItem): Promise<void> {
    if (isNumber(repccid) && this.stateType !== EEntityStateDetailType.NEW) {
      await this._service.delete({id: repccid});
    }
    const index = reparticoesCCustoItem.linhas.indexOf(reparticoesCCustoLinha);
    reparticoesCCustoItem.linhas.splice(index, 1);
    if (!reparticoesCCustoItem.linhas.length) {
      const title = 'reparticoesccustos.title.apagarItem';
      const message = 'reparticoesccustos.message.desejaApagarItem';
      await this._deleteItem(reparticoesCCustoItem, title, message);
    }
    await this._refresh();
    this._plAlertService.success('reparticoesccustos.message.reparticaoccustoEliminadoComSucesso');
  }
}
