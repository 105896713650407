import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_MEIOS_PAGAMENTO} from '../../../meiospagamento/meiosPagamento.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {GrupoDocService} from '../../../../services/grupodoc/grupo.doc.service';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IDocsComerciaisMeiosPagamentoEvtChangedStatus, IDocsComerciaisMeiosPagamentoEvtMeioValorKeydown} from './docsComerciais.meiosPagamento.component.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonDocComercialMeioPagam} from './jsonDocComercialMeioPagam.interface';
import {IJsonMeioPagamento} from '../../../meiospagamento/jsonMeioPagamento.entity.interface';
import {round} from '../../../../../common/utils/utils';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnSaved} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'docscomerciais-meios-pagamento',
  templateUrl: './docsComerciais.meiosPagamento.component.html'
})
export class DocsComerciaisMeiosPagamentoComponent implements OnInit {
  @Input() public meiosPagamento: Array<IJsonDocComercialMeioPagam>;
  @Input() public grupoDocfa: EGrupoDoc;
  @Input() public totalDocumento: number;
  @Input() public codMoeda: number;
  @Input() public abreviaturaMoeda: string;
  @Input() public emEdicao: boolean;
  @Output() public readonly evtChangedStatus: EventEmitter<IDocsComerciaisMeiosPagamentoEvtChangedStatus>;
  @Output() public readonly evtMeioValorKeydown: EventEmitter<IDocsComerciaisMeiosPagamentoEvtMeioValorKeydown>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonDocComercialMeioPagam, number>;

  public totalEntregue: number;
  public troco: number;
  public canSave: boolean;
  public alertErrorMsg: string;
  public meioPagamentoOmissao: number;
  public modalPromise: Promise<unknown>;

  private readonly _meiosPagamento: IEntityService<IJsonMeioPagamento>;

  constructor(
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _grupoDocService: GrupoDocService
  ) {
    this.emEdicao = false;
    this.evtChangedStatus = new EventEmitter<IDocsComerciaisMeiosPagamentoEvtChangedStatus>();
    this.evtMeioValorKeydown = new EventEmitter<IDocsComerciaisMeiosPagamentoEvtMeioValorKeydown>();
    this._meiosPagamento = this._entityServiceBuilder.build<IJsonMeioPagamento>(ENTITY_NAME_MEIOS_PAGAMENTO);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nomeCaixa', dataType: 'string', caption: 'recibos.saveModal.paymentMean', cellTemplate: 'cellTemplateNomeCaixa', allowEditing: false},
        {dataField: 'valorEntregue', dataType: 'double', caption: 'recibos.saveModal.paymentValue'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsActions'}
      ],
      dataSource: [],
      keyExpr: 'nCaixa',
      showBorders: false,
      showColumnLines: false,
      toolbar: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true, startEditAction: 'click'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    for (const meioPagamento of this.meiosPagamento) {
      meioPagamento.valorEntregue = meioPagamento.montanteEntregueMe;
    }
    this._calcularResumo();

    if (this.emEdicao) {
      if (!this.totalEntregue) {
        let querySearch = 'tipomovimento=0';
        if (this._grupoDocService.isVenda(this.grupoDocfa)) {
          querySearch += '|tipomovimento=1';
        } else if (this._grupoDocService.isCompra(this.grupoDocfa)) {
          querySearch += '|tipomovimento=2';
        }
        querySearch += '&tipo=1';

        this.modalPromise = this._meiosPagamento
          .query({pesquisa: querySearch})
          .then((response: THttpQueryResponse<IJsonMeioPagamento>) => {
            const meiosPagamentos: Array<IJsonMeioPagamento> = response.body.list;
            if (meiosPagamentos.length > 0) {
              for (const meioPag of this.meiosPagamento) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (meioPag.nCaixa === (<any>meiosPagamentos).nCaixa) {
                  this.meioPagamentoOmissao = meioPag.nCaixa;
                  break;
                }
              }
            }

            // Setting default values to 0
            for (const meioPagamento of this.meiosPagamento) {
              meioPagamento.montanteEntregue = 0;
              meioPagamento.montanteEntregueMe = 0;
              meioPagamento.valorEntregue = 0;
            }

            // Se tem um valor por omissão
            this._setValorOmissao();

            this._calcularResumo();
          })
          .catch((error: HttpErrorResponse) => {
            const exception: ICGExceptionError = this._cgExceptionService.get(error);
            this._showError(exception.message);
          });
      }
    }

    this.dataGridDefinition.dataSource = this.meiosPagamento;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocComercialMeioPagam, number>): void {
    if (event.rowType === 'data' && event.column.dataField === 'valorEntregue') {
      event.column.allowEditing = this.emEdicao;
    }
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IJsonDocComercialMeioPagam, number>): void {
    const index: number = this.meiosPagamento.findIndex((item: IJsonDocComercialMeioPagam) => item.nCaixa === event.changes[0].key);
    if (index !== -1) {
      this.meiosPagamento[index] = <IJsonDocComercialMeioPagam>event.changes[0].data;
    }
    this._calcularResumo();
  }

  public addTudoLinha(meioPagamento: IJsonDocComercialMeioPagam): void {
    if (this.totalEntregue < this.totalDocumento) {
      meioPagamento.valorEntregue = round((this.totalDocumento - this.totalEntregue) / meioPagamento.cambio + meioPagamento.valorEntregue, 2);
      this._setValorEntregue(meioPagamento.nCaixa, meioPagamento.valorEntregue);
      this._calcularResumo();
    }
  }

  public limpaLinha(meioPagamento: IJsonDocComercialMeioPagam): void {
    meioPagamento.valorEntregue = 0;
    this._setValorEntregue(meioPagamento.nCaixa, meioPagamento.valorEntregue);
    this._calcularResumo();
  }

  private _calcularResumo(): void {
    this.totalEntregue = 0;

    for (const meioPagamento of this.meiosPagamento) {
      if (meioPagamento.valorEntregue) {
        this.totalEntregue += meioPagamento.valorEntregue * meioPagamento.cambio;
      }
    }

    this.totalEntregue = round(this.totalEntregue, 2);

    this.troco = round(this.totalEntregue - this.totalDocumento, 2);
    if (this.troco < 0) {
      this.troco = 0;
    }

    this.canSave = this.totalEntregue >= this.totalDocumento;

    this.evtChangedStatus.emit({totalEntregue: this.totalEntregue, canSave: this.canSave});
  }

  private _showError(errorMessage: string): void {
    if (this.alertErrorMsg !== '') {
      this.alertErrorMsg += '<br/>';
    }
    this.alertErrorMsg += errorMessage;
  }

  private _setValorOmissao(): void {
    if (this.meioPagamentoOmissao) {
      this.meiosPagamento = this.meiosPagamento.map((meio: IJsonDocComercialMeioPagam) => {
        if (this.meioPagamentoOmissao === meio.nCaixa) {
          meio.valorEntregue = this.totalDocumento;
        }
        return meio;
      });
    } else if (this.meiosPagamento.length) {
      this.meiosPagamento[0].valorEntregue = this.totalDocumento;
    }
    this._calcularResumo();
  }

  private _setValorEntregue(nCaixa: number, valorEntregue: number): void {
    const index: number = this.meiosPagamento.findIndex((item: IJsonDocComercialMeioPagam) => item.nCaixa === nCaixa);
    if (index !== -1) {
      this.meiosPagamento[index].valorEntregue = valorEntregue;
    }
  }
}
