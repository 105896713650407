import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_IMPDE119} from './impDe119.module.interface';
import {ImpDe119ModuleComponent} from './components/impDe119.module.component';

export const MODULE_IMPEDE119: IModuleDefinition = {
  name: MODULE_NAME_IMPDE119,
  state: {
    url: `/${MODULE_NAME_IMPDE119}`,
    component: ImpDe119ModuleComponent,
    data: {
      roles: [ROLE.RH, ROLE.PCA, ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.impde119'
    }
  }
};
