import Hammer from 'hammerjs';
import {Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IPlEventListeners, PL_HAMMER_EVENT_LISTENERS, TPlEventListener} from './events.listener.directive.interface';
import {isFunction, isObject, isString} from '../../common/utilities/utilities';

const IGNORED_SEPARATOR = ',';

@Directive({
  selector: '[plEventsListener]',
  standalone: false
})
export class PlEventsListenerDirective implements OnChanges, OnDestroy {
  @Input() public plEventsListener: IPlEventListeners;
  @Input() public plEventsListenerIgnore: string;
  @Input() public plEventsListenerHammerOptions: HammerOptions;

  private readonly _element: HTMLElement;
  private readonly _registeredEvents: Map<string, TPlEventListener>;
  private readonly _registeredHammerEvents: Map<string, HammerListener>;
  private _ignoredEvents: Array<string>;
  private _hammerManager: HammerManager;

  constructor(private readonly _elementRef: ElementRef<HTMLElement>) {
    this._element = this._elementRef.nativeElement;
    this._registeredEvents = new Map<string, TPlEventListener>();
    this._registeredHammerEvents = new Map<string, any>();
    this._ignoredEvents = [];
  }

  public ngOnChanges({plEventsListenerIgnore, plEventsListener}: SimpleChanges): void {
    if (plEventsListenerIgnore) {
      const toIgnore: string = plEventsListenerIgnore.currentValue;
      if (isString(toIgnore)) {
        this._ignoredEvents = toIgnore.split(IGNORED_SEPARATOR);
      }
    }
    if (plEventsListener) {
      this._registerEvents(plEventsListener.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._unregisterEvents();
    this._destroyHammerManager();
  }

  public get hammerManager(): HammerManager {
    if (!this._hammerManager) {
      this._hammerManager = new Hammer(this._element, this.plEventsListenerHammerOptions);
    }
    return this._hammerManager;
  }

  private _registerEvents(events: IPlEventListeners): void {
    if (!isObject(events)) {
      return;
    }
    this._unregisterEvents();
    for (const eventName of Object.keys(events)) {
      if (this._ignoredEvents.includes(eventName)) {
        continue;
      }
      const eventListener: EventListener | HammerListener = events[eventName];
      if (isFunction(eventListener)) {
        if (!PL_HAMMER_EVENT_LISTENERS.includes(eventName)) {
          this._registerEvent(eventName, <EventListener>eventListener);
        } else {
          this._registerHammerEvent(eventName, <HammerListener>eventListener);
        }
      }
    }
  }

  private _registerEvent(name: string, originalListener: TPlEventListener): void {
    if (this._registeredEvents.has(name)) {
      this._unregisterEvent(name);
    }
    const proxyListener: EventListener = this._generateProxyListener(originalListener);
    this._element.addEventListener(name, proxyListener);
    this._registeredEvents.set(name, proxyListener);
  }

  private _registerHammerEvent(name: string, originalListener: HammerListener): void {
    if (this._registeredHammerEvents.has(name)) {
      this._unregisterHammerEvent(name);
    }
    const proxyHammerListener: HammerListener = this._generateHammerProxyListener(originalListener);
    this.hammerManager.on(name, proxyHammerListener);
    this._registeredHammerEvents.set(name, proxyHammerListener);
  }

  private _unregisterEvents(): void {
    for (const eventName of this._registeredEvents.keys()) {
      this._removeEventListener(eventName);
    }
    this._registeredEvents.clear();
    this._unregisterHammerEvents();
  }

  private _unregisterHammerEvents(): void {
    for (const eventName of this._registeredHammerEvents.keys()) {
      this._removeHammerEventListener(eventName);
    }
    this._registeredHammerEvents.clear();
  }

  private _unregisterEvent(name: string): void {
    this._removeEventListener(name);
    this._registeredEvents.delete(name);
  }

  private _unregisterHammerEvent(name: string): void {
    this._removeHammerEventListener(name);
    this._registeredHammerEvents.delete(name);
  }

  private _removeEventListener(name: string): void {
    const eventListener: EventListener = this._registeredEvents.get(name);
    this._element.removeEventListener(name, eventListener);
  }

  private _removeHammerEventListener(name: string): void {
    const eventListener: HammerListener = this._registeredHammerEvents.get(name);
    this.hammerManager.off(name, eventListener);
  }

  private _generateProxyListener(originalHandler: TPlEventListener): EventListener {
    return (event: Event) => {
      originalHandler.call(this._element, event);
    };
  }

  private _generateHammerProxyListener(originalHandler: HammerListener): HammerListener {
    return (event: HammerInput) => {
      originalHandler.call(this._element, event);
    };
  }

  private _destroyHammerManager(): void {
    if (this._hammerManager) {
      this._hammerManager.destroy();
      this._hammerManager = undefined;
    }
  }
}
