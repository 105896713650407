export enum EBrowserDetectionTypes {
  Blink,
  Chrome,
  Edge,
  Firefox,
  InternetExplorer,
  Opera,
  Safari,
  IOS,
  Android
}

export type TBrowserDetection = {[key in EBrowserDetectionTypes]: boolean};

export type TValueOrPromise<T> = T | Promise<T>;

export type TOrderByFieldsOrder = 'asc' | 'desc';

export type TOrderByFieldsIteratees = [Array<string>, Array<TOrderByFieldsOrder>];

export interface IElementOffset {
  top: number;
  left: number;
}
