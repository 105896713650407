import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ContabilidadeApuraInventPermaComponent} from './components/contabilidade.apurainventperma.module.component';
import {HttpResponse} from '@angular/common/http';
import {ContabilidadeApuraInventPermaService} from './contabilidade.apurainventperma.module.service';
import {IJsonApuraStockInfoApuramento, IJsonApuraStockParamsDoc, IJsonTiposContasPoc} from './jsonapurainventperma.module';
import {MODULE_NAME_APURA_INVENT_PERMA} from './contabilidade.apurainventperma.module.interface';

export const MODULE_APURA_INVENT_PERMA: IModuleDefinition = {
  name: MODULE_NAME_APURA_INVENT_PERMA,
  state: {
    url: '/apurainventperma',
    component: ContabilidadeApuraInventPermaComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'apurainventperma.title'
    },
    resolve: [
      {
        provide: 'infoApuramento',
        deps: [ContabilidadeApuraInventPermaService],
        useFactory: (apuraStockService: ContabilidadeApuraInventPermaService): Promise<Array<IJsonApuraStockInfoApuramento>> => {
          return apuraStockService.getInfoApuramento().then((response: HttpResponse<Array<IJsonApuraStockInfoApuramento>>) => response.body);
        }
      },
      {
        provide: 'contasApuraStock',
        deps: [ContabilidadeApuraInventPermaService],
        useFactory: (apuraStockService: ContabilidadeApuraInventPermaService): Promise<Array<IJsonTiposContasPoc>> => {
          return apuraStockService.getContasApuraStock().then((response: HttpResponse<Array<IJsonTiposContasPoc>>) => response.body);
        }
      },
      {
        provide: 'paramApuraStockDados',
        deps: [ContabilidadeApuraInventPermaService],
        useFactory: (apuraStockService: ContabilidadeApuraInventPermaService): Promise<IJsonApuraStockParamsDoc> => {
          return apuraStockService.getDefaultDadosParamsApuraStock().then((response: HttpResponse<IJsonApuraStockParamsDoc>) => response.body);
        }
      }
    ]
  }
};
