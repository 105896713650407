<div class="docscontabilidade">
  <pl-alert type="error" [closeable]="true" (evtClosed)="showLicenseNotActivatedCGOnError = false" *ngIf="showLicenseNotActivatedCGOnError">
    <div [innerHTML]="'arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrl}"></div>
  </pl-alert>

  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [showMessagesSuccess]="false"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <doccontabilidade
        [model]="docContabilidade"
        (modelChange)="modelChanged($event)"
        [editing]="editing"
        [contabilidadeDigital]="contabilidadeDigital"
        [calculouDiferimento]="!!savedDiferimentos"
        [callback]="callbackDocContabilidade"
        onLoadFocusField="periodo"
        [toolbarInstanceName]="toolbar.instanceId"
        (calculouDiferimentoChange)="onChangedCalculouDiferimento($event)"
        (disableActionDiferimentos)="moduleActionDiferimentos.disabled = $event"
        (contabilidadeDigitalAttachmentChange)="contabilidadeDigitalAttachment = $event">
      </doccontabilidade>
    </div>
  </entity-detail>
</div>

<ng-template #templateKeyboardShortcuts>
  <div class="keyboard-shortcuts">
    <keybindings-helper [actions]="keyboardShortcuts"></keybindings-helper>
  </div>
</ng-template>
