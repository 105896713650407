import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {PcaIdadeSaldosComponent} from './components/idadeSaldos.module.component';

export const MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_CLIENTES: IModuleDefinition = {
  name: 'pcaIdadeSaldosClientes',
  state: {
    url: '/idadesaldosclientes',
    component: PcaIdadeSaldosComponent,
    data: {
      roles: [ROLE.PCA, ROLE.ERP, ROLE.FINANCEIRO],
      pageTitle: 'global.menu.pcaIdadeSaldosClientes'
    },
    resolve: [{provide: 'tipo', useValue: 'clientes'}]
  }
};

export const MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_FORNECEDORES: IModuleDefinition = {
  name: 'pcaIdadeSaldosFornecedores',
  state: {
    url: '/idadesaldosfornecedores',
    component: PcaIdadeSaldosComponent,
    data: {
      roles: [ROLE.PCA, ROLE.ERP, ROLE.FINANCEIRO],
      pageTitle: 'global.menu.pcaIdadeSaldosFornecedores'
    },
    resolve: [{provide: 'tipo', useValue: 'fornecedores'}]
  }
};
