import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRetencoes} from './jsonRetencoes.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RetencoesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/retencoes`;
  }

  public getRetencoes(
    tipoVisualizacao: number,
    nifDe: string,
    nifAte: string,
    dataDe: TDate,
    dataAte: TDate,
    tipRetServNif: number,
    estadoRet: number,
    origemContas: number
  ): TServiceResponse<Array<IJsonRetencoes>> {
    return this._apiService.get<Array<IJsonRetencoes>>({
      url: this._path,
      params: {
        tipovisualizacao: tipoVisualizacao,
        nifde: nifDe,
        nifate: nifAte,
        datade: dataDe,
        dataate: dataAte,
        tipretservnif: tipRetServNif,
        estadoret: estadoRet,
        origemcontas: origemContas
      }
    });
  }
}
