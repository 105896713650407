import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonCARGR, IJsonCARGV} from '../jsonCargVeic.entity.interface';
import {ICargVeicParams} from '../cargVeic.entity.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {merge} from 'lodash-es';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IApiRequestConfigWithBody} from '../../../services/api/api.service.interface';
import {copy, isUndefinedOrNull} from 'pl-comps-angular';
import {CargVeicReboqueModalComponent} from '../modals/cargVeic.reboque.modal.component';

@Component({
  selector: 'carg-veic-edit',
  templateUrl: './cargVeic.entity.edit.component.html'
})
export class CargVeicEditComponent extends ModuloEntityDetailComponent<IJsonCARGV> implements OnInit {
  public clifosOutput: string;

  public readonly definitionsDataGrid: IDevExpressDataGrid;
  public haveDataGridChanges: boolean;

  private readonly _cargVeicParams: ICargVeicParams;
  private _dataGridInstance: dxDataGrid;
  private _origReboquesList: Array<IJsonCARGR>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this._cargVeicParams = <ICargVeicParams>this._transition.params();
    this.definitionsDataGrid = {
      columns: [
        {
          dataField: 'matriculaReboque',
          dataType: 'string',
          caption: 'cargveic.fields.matriculaReboque',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'pesoTara',
          dataType: 'number',
          caption: 'cargveic.fields.pesoTara',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: true
        },
        {
          dataField: 'vf_actions',
          type: 'buttons',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false,
          visible: true
        }
      ],
      dataSource: new CustomStore({
        key: 'matriculaReboque',
        load: () => this._getReboquesTableSource(),
        update: (matriculaReboque: string, values: Partial<IJsonCARGR>) => {
          const record: IJsonCARGR = this.model.reboques.find((item: IJsonCARGR) => item.matriculaReboque === matriculaReboque);
          if (record) {
            merge(record, values);
          }
          return Promise.resolve();
        }
      }),
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      filterRow: {visible: false}
    };
    this._origReboquesList = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.haveDataGridChanges = false;
    if (this.type === this.stateTypes.NEW) {
      this.model = {
        matricula: '',
        reboque: false,
        desativado: false,
        nConta: '',
        pesoTara: 0,
        nomeTranspor: '',
        nomeViatura: '',
        marca: '',
        modelo: '',
        motor: '',
        chassi: '',
        kilometros: 0,
        reboques: [],
        nIntViatura: ''
      };
      if (this._cargVeicParams?.matricula !== '') {
        this.model.matricula = this._cargVeicParams.matricula;
      }
    }
    this._origReboquesList = copy(this.model.reboques);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      if (this._dataGridInstance) {
        this._dataGridInstance.columnOption('vf_actions', 'visible', this.type === this.stateTypes.EDIT || this.type === this.stateTypes.NEW);
        this._dataGridInstance.updateDimensions();
      }
      return this._dataGridInstance?.refresh();
    });
  }

  public save(config?: IApiRequestConfigWithBody<IJsonCARGV>): Promise<IJsonCARGV> {
    return super.save(config).then((response) => {
      if (response) {
        this.haveDataGridChanges = false;
        return response;
      }
      return undefined;
    });
  }

  public edit(): void {
    super.edit();
    this._dataGridInstance.refresh();
  }

  public cancel(): Promise<void> {
    this.haveDataGridChanges = false;
    this.model.reboques = merge(this.model.reboques, this._origReboquesList);
    return super.cancel().then(() => {
      return this._dataGridInstance?.refresh();
    });
  }

  public addNovoReboque(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(CargVeicReboqueModalComponent, {size: 'sm'});
    const componentInstance: CargVeicReboqueModalComponent = modalInstance.componentInstance;
    componentInstance.reboquesList = this.model.reboques;
    return modalInstance.result.then((result: IJsonCARGR) => {
      this.model.reboques.push(result);
      this._dataGridInstance.refresh();
      if (this.type !== EEntityStateDetailType.NEW) {
        this.haveDataGridChanges = true;
      }
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    this._dataGridInstance.columnOption('pesoTara', 'allowEditing', this.type === EEntityStateDetailType.EDIT || this.type === this.stateTypes.NEW);
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonCARGR, string>): void {
    switch (event.column.dataField) {
      case 'vf_actions':
        event.component.columnOption(event.column.dataField, 'visible', this.type === this.stateTypes.EDIT || this.type === this.stateTypes.NEW);
        break;
    }
  }

  public onDataGridSaved(event: IDevExpressDataGridEventOnSaved<IJsonCARGR>): void {
    if (event.changes[0]) {
      this.haveDataGridChanges = true;
    }
  }

  public apagarReboque(data: IJsonCARGR): void {
    if (this.type === this.stateTypes.NEW) {
      const index = this.model.reboques.findIndex((item) => item.matriculaReboque === data.matriculaReboque);
      if (index > -1) {
        this.model.reboques.splice(index, 1);
      }
    } else {
      data.isDeleted = true;
      this.haveDataGridChanges = true;
    }
    this._dataGridInstance.refresh();
  }

  private _getReboquesTableSource(): Array<IJsonCARGR> {
    if (isUndefinedOrNull(this.model.reboques)) {
      return [];
    }
    return this.model.reboques.filter((item) => !item.isDeleted);
  }
}
