<div class="docsdigitais-docs-contab-sem-doc-digital-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.title'"></h5>
    <pl-button klass="btn-primary btn-sm" id="pesquisa" (evtClicked)="pesquisaDocsContabSemDocDigital()">
      <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
    </pl-button>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.header.periodo'"></label>
        <edit>
          <entity-autocomplete entity="periodos" [model]="periodoDe" (selectedKeyChange)="periodoDe = $event" [fieldsMap]="{periodo: 'periodoDe'}" [output]="'key'"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="periodos" [model]="periodoAte" (selectedKeyChange)="periodoAte = $event" [fieldsMap]="{periodo: 'periodoAte'}" [output]="'key'"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.header.diario'"></label>
        <edit>
          <entity-autocomplete entity="diarios" [model]="diarioDe" (selectedKeyChange)="diarioDe = $event" [fieldsMap]="{nDiario: 'diariode'}" [output]="'key'"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="diarios" [model]="diarioAte" (selectedKeyChange)="diarioAte = $event" [fieldsMap]="{nDiario: 'diarioAte'}" [output]="'key'"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="docsdigitaisdocscontabsemdocdigitalmodal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let detailInfo of 'templateMasterDetail'">
        <documento-contabilidade [(doc)]="detailInfo.data._docContabilidade" [showToolbar]="true" [maintenanceMode]="true"></documento-contabilidade>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light" [disabled]="closeDisabled" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
