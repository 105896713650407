import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonQPDUR} from './jsonQPDUR.entity.interface';
import {ENTITY_NAME_QPDUR} from './qpdur.entity.interface';

export const ENTITY_QPDUR: IEntityDefinition<IJsonQPDUR> = {
  name: ENTITY_NAME_QPDUR,
  roles: [ROLE.RH],
  searchPlaceholder: 'qpdur.pesquisa',
  pageTitle: 'global.menu.qpdur',
  metadata: {
    keyName: 'codDuracao',
    fields: [
      {name: 'codDuracao', type: 'string', width: '120px', caption: 'qpdur.fields.codDuracao', validators: {required: true, maxlength: 4}},
      {name: 'nome', caption: 'qpdur.fields.nome', validators: {required: true, maxlength: 20}}
    ],
    order: 'codDuracao',
    searchFields: 'codDuracao,nome',
    listFields: 'codDuracao,nome',
    detailFields: 'codDuracao,nome'
  },
  autocomplete: {
    rowTemplate: '{{codDuracao}} - {{nome}}',
    output: 'nome',
    searchFields: 'codDuracao,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
