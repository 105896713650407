import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../services/auth/auth.service';
import {CGModalComponent} from '../../components/cg/modal/cgmodal.component';
import {EMPTY_PASSWORD} from '../../../config/constants';
import {ROLE} from '../../services/role.const';
import {IDocfasNumsComunicacaoLogin} from '../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {DocfasNumsComunicacaoLoginService} from '../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service';

@Component({
  selector: 'docFasNumsComunicacaoLogin-modal',
  templateUrl: './docFasNumsComunicacaoLogin.modal.component.html'
})
export class DocFasNumsComunicacaoLoginModalComponent extends CGModalComponent<IDocfasNumsComunicacaoLogin> implements OnInit {
  @Input() public username: string;

  public readonly model: IDocfasNumsComunicacaoLogin;
  public isAdmin: boolean;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _serviceDocFasNumsComunicacaoLogin: DocfasNumsComunicacaoLoginService
  ) {
    super(_injector);
    this.model = {username: '', password: EMPTY_PASSWORD};
    this._authService.hasAnyAuthority([ROLE.ADMIN, ROLE.ADMINBASIC]).then((hasAuthority: boolean) => {
      this.isAdmin = hasAuthority;
    });
  }

  public ngOnInit(): void {
    if (this.username) {
      this.model.username = this.username;
    } else {
      this.promise = this._serviceDocFasNumsComunicacaoLogin.get().then((response: HttpResponse<IDocfasNumsComunicacaoLogin>) => {
        this.model.username = response.body.username ? response.body.username : `${this._configService.configurations.empresa.ncontribuemp}/UserId`;
        this.model.password = response.body.password ? response.body.password : '';
      });
    }
  }

  public close(): Promise<void> {
    if (!this.model.username) {
      this._plAlertService.error('docfasnumscomuncicacaologin.messages.errorUsernameEmpty');
      return Promise.resolve();
    }
    if (!this.model.password) {
      this._plAlertService.error('docfasnumscomuncicacaologin.messages.errorPasswordEmpty');
      return Promise.resolve();
    }
    this.promise = this._serviceDocFasNumsComunicacaoLogin.set(this.model).then((response) => {
      this.model.username = response.body.username ? response.body.username : this.username;
      this.model.password = response.body.password;
      super.close(this.model);
    });
    return this.promise;
  }
}
