import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {Properties as NumberBoxOptions, Properties as DxNumberBoxProperties} from 'devextreme/ui/number_box';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IPlEditComponentOptionsInputNumber, isEmpty, KEYCODES, PlKeyboardService, PlToolbarService, timeout} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {DocsContabilidadeService} from '../../../../service/docsContabilidade.service';
import {EMovimentosEmAbertoDC, IMovimentosEmAberto} from '../../../../../../../interfaces/jsonMovimentosEmAberto.interface';
import {focusElement} from '../../../../../../../../common/utils/element.utils';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridStoreChange} from '../../../../../../../components/devexpress/datagrid/store/devexpress.datagrid.store.interface';
import {IDocContabilidade, IDocContabilidadeLinha} from '../../../../docsContabilidade.interface';
import {IKeyBindingsHelperAction} from '../../../../../../../components/keybindings/helper/keybindings.helper.component.interface';
import {IRestCommandLinhaObterMovAb} from '../../../../restDocsContabilidadeCommands.interface';
import {round} from '../../../../../../../../common/utils/utils';
import {TMOVABValorValidationCallbackData} from '../../docContabilidade.interface';
import {TTableLegend} from '../../../../../../../components/tablelegend/tablelegend.component.interface';

const TOOLBAR_INSTANCE = 'toolbar-docscontabilidade-movab';

@Component({
  selector: 'docscontabilidade-edit-movab-modal',
  templateUrl: './docsContabilidade.edit.movAb.modal.component.html'
})
export class DocsContabilidadeEditMovabModalComponent extends CGModalComponent<Array<IMovimentosEmAberto>> implements OnInit, OnDestroy {
  @Input() public linha: IDocContabilidadeLinha;
  @Input() public doc: IDocContabilidade;

  public readonly dataGridDefinition: IDevExpressDataGrid<IMovimentosEmAberto, string>;
  public readonly toolbarInstanceName: string;
  public readonly keyboardShortcut: IKeyBindingsHelperAction;
  public readonly propertiesLines: Array<IPlEditComponentOptionsInputNumber>;
  public selectedLines: Array<string>;
  public form: UntypedFormGroup;
  public tableLegend: TTableLegend;
  public valorTotalImputado: number;
  public totalDebitoImputado: number;
  public totalCreditoImputado: number;
  public valorTotalConta: number;
  public totalDebitoConta: number;
  public totalCreditoConta: number;
  public showTableLegend: boolean;

  private _movimentosEmAberto: Array<IMovimentosEmAberto>;
  private _temContabilidadeDigital: boolean;
  private _grupoContabDigitalKeys: Array<string>;
  private _elementSubmit: HTMLElement;
  private _dataGridInstance: dxDataGrid<IMovimentosEmAberto, string>;
  private _firstLoad: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _plKeyboardService: PlKeyboardService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this._customizeTextDCAsStr = this._customizeTextDCAsStr.bind(this);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDocumento', dataType: 'string', caption: 'docscontabilidade.movab.nDocumento', allowEditing: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'docscontabilidade.movab.nDocExterno', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'docscontabilidade.movab.dataDoc', allowEditing: false, format: this.configurations.formatsettings.date},
        {dataField: 'descricao', dataType: 'string', caption: 'docscontabilidade.movab.descricao', allowEditing: false, visible: false},
        {dataField: 'descritivo', dataType: 'string', caption: 'docscontabilidade.movab.descritivo', allowEditing: false},
        {
          dataField: 'valor',
          dataType: 'double',
          caption: 'docscontabilidade.movab.valor',
          allowEditing: false,
          allowHeaderFiltering: false,
          format: {decimalsLimit: this.configurations.contabilidade.decimais.valor}
        },
        {
          dataField: '_valorAPagarOrigem',
          dataType: 'double',
          caption: 'docscontabilidade.movab.porPagar',
          allowEditing: false,
          allowHeaderFiltering: false,
          format: {decimalsLimit: this.configurations.contabilidade.decimais.valor}
        },
        {dataField: 'dC', dataType: 'string', caption: 'docscontabilidade.movab.dc', allowEditing: false, customizeText: this._customizeTextDCAsStr},
        {
          dataField: 'valorAPagar',
          dataType: 'double',
          caption: 'docscontabilidade.movab.valorPagar',
          allowEditing: true,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          allowSorting: false,
          showEditorAlways: true,
          editorOptions: {
            onKeyDown: (event) => {
              this.onKeyDown(event.event);
            }
          } satisfies NumberBoxOptions,
          validationRules: [
            {
              type: 'custom',
              ignoreEmptyValue: true,
              message: this._translateService.instant('docscontabilidade.movab.valorPagarValidationSuperior'),
              validationCallback: (options: TMOVABValorValidationCallbackData) => options.data.valorAPagar <= options.data._valorAPagarOrigem
            }
          ]
        },
        {
          dataField: 'valorDesconto',
          dataType: 'double',
          caption: 'docscontabilidade.movab.valordesconto',
          allowEditing: true,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          allowSorting: false,
          showEditorAlways: true,
          editorOptions: {
            onKeyDown: (event) => {
              this.onKeyDown(event.event);
            }
          } satisfies NumberBoxOptions,
          validationRules: [
            {
              type: 'custom',
              ignoreEmptyValue: true,
              message: this._translateService.instant('docscontabilidade.movab.valorDescontoValidationSuperior'),
              validationCallback: (options: TMOVABValorValidationCallbackData) => options.data.valorDesconto <= options.data.valorAPagar
            }
          ]
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtns', headerCellTemplate: 'cellHeaderTemplateBtns'}
      ],
      dataSource: [],
      editing: {
        mode: 'cell',
        startEditAction: 'click',
        selectTextOnEditStart: true,
        allowUpdating: true,
        refreshMode: 'repaint'
      },
      export: {filename: 'docscontabilidade.movab.title'},
      keyboardNavigation: {
        editOnKeyPress: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      },
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      toolbar: {
        items: [
          {
            location: 'before',
            locateInMenu: 'auto',
            template: 'toolbarTemplate'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.toolbarInstanceName = TOOLBAR_INSTANCE;
    this.keyboardShortcut = {description: 'docscontabilidade.movab.imputarTudoLinha', key: KEYCODES.ADD};
    this.propertiesLines = [];
    this.showTableLegend = false;
    this._movimentosEmAberto = [];
    this._grupoContabDigitalKeys = [];
    this._firstLoad = true;
    const toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceName);
    toolbarInstance.setItems([{id: 'title', type: 'title', order: 1, caption: 'docscontabilidade.movab.title'}]);
  }

  public ngOnInit(): void {
    this._plKeyboardService.preventDocumentKeyup();
    this._getMovimentosEmAbertoLista();
    this.tableLegend = [{caption: 'docscontabilidade.movab.movmesmoid', badgeCSSClass: 'bg-warning'}];
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plKeyboardService.allowDocumentKeyup();
    this._plToolbarService.unRegisterInstance(this.toolbarInstanceName);
  }

  public async close(): Promise<void> {
    if (!this.form.valid) {
      return Promise.reject(new Error(this._translateService.instant(FORM_INVALID_CANNOT_SUBMIT)));
    }
    await this._dataGridInstance.saveEditData();
    super.close(this._movimentosEmAberto);
    return Promise.resolve();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IMovimentosEmAberto, string>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    if (this._movimentosEmAberto?.length > 0 && this._firstLoad) {
      this._setSelectedLine(this._movimentosEmAberto[0].nLanc);
      this._dataGridInstance.editCell(0, 'valorAPagar');
      this._firstLoad = false;
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IMovimentosEmAberto, string>): void {
    if (event.rowType === 'data') {
      this._setSelectedLine(event.row.data.nLanc);
      if (!event.row.data.grupoContabDigitalUnico) {
        event.component
          .getDataSource()
          .store()
          .load()
          .then((listaMovimentos: Array<IMovimentosEmAberto>) => {
            this._movimentosEmAberto = listaMovimentos;
          });
      }
    }
  }

  public onRowUpdated(): void {
    this.countTotalImputar();
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IMovimentosEmAberto, string>): void {
    if (event.editorName === 'dxNumberBox') {
      (<DxNumberBoxProperties>event.editorOptions).step = 0;
    }
  }

  public async onKeyDown(event: KeyboardEvent): Promise<void> {
    const eventKey: string = event.key;
    if (eventKey === KEYCODES.LEFT || eventKey === KEYCODES.RIGHT) {
      event.preventDefault();
      return;
    }
    const keyEnter: boolean = eventKey === KEYCODES.ENTER;
    const keyAdd: boolean = eventKey === KEYCODES.ADD;
    const keyF9: boolean = eventKey === KEYCODES.F9;
    const keyUp: boolean = eventKey === KEYCODES.UP;
    const keyDown: boolean = eventKey === KEYCODES.DOWN;
    if (keyEnter || keyAdd || keyF9 || keyUp || keyDown) {
      if (keyAdd || keyF9) {
        event.preventDefault();
      }
      const movimentosEmAberto: Array<IMovimentosEmAberto> = this._dataGridInstance.getSelectedRowsData();
      if (movimentosEmAberto.length) {
        const movimentoEmAberto: IMovimentosEmAberto = movimentosEmAberto[0];
        if (keyAdd || keyF9) {
          this.imputarTudoLinha(movimentoEmAberto);
        }
        const currentIndex: number = this._dataGridInstance.getRowIndexByKey(movimentoEmAberto.nLanc);
        const nextIndex: number = keyUp ? currentIndex - 1 : currentIndex + 1;
        if (nextIndex < 0) {
          return;
        }
        const nextKey: string = this._dataGridInstance.getKeyByRowIndex(nextIndex);
        if (nextKey) {
          this._setSelectedLine(nextKey);
          this._dataGridInstance.focus(this._dataGridInstance.getCellElement(nextIndex, 'valorAPagar'));
          this._dataGridInstance.editCell(nextIndex, 'valorAPagar');
          event.preventDefault(); // so modal does not close on keycode enter
          event.stopImmediatePropagation(); // so event does not enter twice
        } else {
          event.preventDefault();
          event.stopImmediatePropagation();
          await timeout();
          focusElement(this._elementSubmit);
        }
      }
    }
  }

  public imputarTudo(): void {
    this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((movimentosEmAberto: Array<IMovimentosEmAberto>) => {
        this._setLinhaValorAPagar(movimentosEmAberto.map((movimentoEmAberto: IMovimentosEmAberto) => [movimentoEmAberto.nLanc, movimentoEmAberto._valorAPagarOrigem]));
      });
  }

  public imputarTudoLinha(movimentoEmAberto: IMovimentosEmAberto): void {
    this._setLinhaValorAPagar([[movimentoEmAberto.nLanc, movimentoEmAberto._valorAPagarOrigem]]);
  }

  public limpaValoresImputados(): void {
    this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((movimentosEmAberto: Array<IMovimentosEmAberto>) => {
        this._setLinhaValorAPagar(movimentosEmAberto.map((movimentoEmAberto: IMovimentosEmAberto) => [movimentoEmAberto.nLanc, 0]));
      });
  }

  public limpaLinha(movimentoEmAberto: IMovimentosEmAberto): void {
    this._setLinhaValorAPagar([[movimentoEmAberto.nLanc, 0]]);
  }

  public countTotalImputar(): void {
    this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((movimentosEmAberto: Array<IMovimentosEmAberto>) => {
        this.valorTotalImputado = 0;
        this.totalCreditoImputado = 0;
        this.totalDebitoImputado = 0;
        for (const movimentoEmAberto of movimentosEmAberto) {
          if (movimentoEmAberto.valorAPagar) {
            if (movimentoEmAberto.dC === 0) {
              this.totalDebitoImputado += movimentoEmAberto.valorAPagar;
            } else {
              this.totalCreditoImputado += movimentoEmAberto.valorAPagar;
            }
            this.valorTotalImputado = this.totalDebitoImputado - this.totalCreditoImputado;
          }
        }
      });
  }

  public countTotalContas(contasAberto: Array<IMovimentosEmAberto>): void {
    this.valorTotalConta = 0;
    this.totalCreditoConta = 0;
    this.totalDebitoConta = 0;
    for (const movimentoEmAbertoConta of contasAberto) {
      if (movimentoEmAbertoConta.valorAPagar) {
        if (movimentoEmAbertoConta.dC === 0) {
          this.totalDebitoConta += movimentoEmAbertoConta.valorAPagar;
        } else {
          this.totalCreditoConta += movimentoEmAbertoConta.valorAPagar;
        }
        this.valorTotalConta = this.totalDebitoConta - this.totalCreditoConta;
      }
    }
  }

  @ViewChild('elementSubmit', {read: ElementRef})
  public set elementSubmit(value: ElementRef<HTMLElement>) {
    this._elementSubmit = value?.nativeElement;
  }

  protected _onChangedConfigurations(): void {
    super._onChangedConfigurations();
    this._temContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
  }

  private _getMovimentosEmAbertoLista(): Promise<Array<IMovimentosEmAberto>> {
    return this._docsContabilidadeService.linhaObterMovAb([], this.linha._index, this.doc).then((response: IRestCommandLinhaObterMovAb) => {
      this._movimentosEmAberto = response.movImputList;
      this.countTotalContas(this._movimentosEmAberto);
      for (const movimentoEmAberto of this._movimentosEmAberto) {
        movimentoEmAberto._valorAPagarOrigem = movimentoEmAberto.valorAPagar;
        movimentoEmAberto.valorAPagar = 0;
      }
      this._applyDataSource();
      return this._movimentosEmAberto;
    });
  }

  private _applyDataSource(): void {
    this.dataGridDefinition.dataSource = new DataSource({
      store: new ArrayStore({
        key: 'nLanc',
        data: this._movimentosEmAberto
      }),
      pushAggregationTimeout: 0
    });
  }

  private _setLinhaValorAPagar(values: Array<[string, number]>): void {
    const changes: Array<IDevExpressDataGridStoreChange<IMovimentosEmAberto, string>> = values.map(([key, value]: [string, number]) => {
      return {key: key, type: 'update', data: {valorAPagar: value}};
    });
    this._dataGridInstance.getDataSource().store().push(changes);
    this.countTotalImputar();
  }

  private _setSelectedLine(key: string): void {
    this.selectedLines = [key];
    if (this._temContabilidadeDigital) {
      const rowData: IMovimentosEmAberto = this._movimentosEmAberto.find((item: IMovimentosEmAberto) => item.nLanc === key);
      if (rowData) {
        this.showTableLegend = !isEmpty(rowData.idGrupoContabDigital);

        const lastIDGrupo: string = !this._grupoContabDigitalKeys.length
          ? ''
          : this._movimentosEmAberto.find((item: IMovimentosEmAberto) => item.nLanc === this._grupoContabDigitalKeys[0]).idGrupoContabDigital;

        if (lastIDGrupo !== rowData.idGrupoContabDigital) {
          for (const keyGrupo of this._grupoContabDigitalKeys) {
            const index: number = this._dataGridInstance.getRowIndexByKey(keyGrupo);
            const elements: Array<Element> = this._dataGridInstance.getRowElement(index);
            elements[0].classList.remove('text-warning');
          }

          if (this.showTableLegend) {
            this._grupoContabDigitalKeys = this._movimentosEmAberto
              .filter((item: IMovimentosEmAberto) => item.idGrupoContabDigital === rowData.idGrupoContabDigital)
              .map((item: IMovimentosEmAberto) => item.nLanc);

            let valor = 0;
            for (const keyGrupo of this._grupoContabDigitalKeys) {
              const index: number = this._dataGridInstance.getRowIndexByKey(keyGrupo);
              const elements: Array<Element> = this._dataGridInstance.getRowElement(index);
              const movAb = this._movimentosEmAberto.find((item: IMovimentosEmAberto) => item.nLanc === keyGrupo);
              valor = movAb.dC === EMovimentosEmAbertoDC.Credito ? valor - movAb.valor : valor + movAb.valor;
              elements[0].classList.add('text-warning');
            }

            valor = round(valor, this.configurations.contabilidade.decimais.valor);

            this.tableLegend = [
              {
                caption: this._grupoContabDigitalKeys.length > 1 ? this._translateService.instant('docscontabilidade.movab.movmesmoidval', {value: valor}) : 'docscontabilidade.movab.movmesmoid',
                badgeCSSClass: 'bg-warning'
              }
            ];
          } else {
            this._grupoContabDigitalKeys = [];
          }
        }
      }
    }
  }

  private _customizeTextDCAsStr(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row') {
      return this._translateService.instant(cellInfo.value === EMovimentosEmAbertoDC.Credito ? 'docscontabilidade.movab.dcCredito' : 'docscontabilidade.movab.dcDebito');
    }
    return cellInfo.valueText;
  }
}
