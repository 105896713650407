import {HttpResponse} from '@angular/common/http';
import {EDelphiNumberTypes} from 'pl-comps-angular';
import {
  ESitEmprego,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL
} from './rhRecolhaSerieSubsidios.module.interface';
import {IJsonRHRecolhaSerieSubsidiosEmp} from './jsonRHRecolhaSerieSubsidios.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {RHRecolhaSerieSubsidiosModuleComponent} from './components/rhRecolhaSerieSubsidios.module.component';
import {RHRecolhaSerieSubsidiosService} from './rhRecolhaSerieSubsidios.module.service';
import {ROLE} from '../../../services/role.const';

const MIN_COD_EMPREGADO = 0;
const MAX_SMALLINT_DELPHI_NUMBER = EDelphiNumberTypes.MaxSmallInt;
const SITUACAO_EMPREGO_ATIVO = ESitEmprego.Ativo;

export const MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO: IModuleDefinition = generateModule(MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO);
export const MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS: IModuleDefinition = generateModule(MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS);
export const MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL: IModuleDefinition = generateModule(MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL);

function generateModule(moduleName: string): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleName}`,
      component: RHRecolhaSerieSubsidiosModuleComponent,
      data: {
        roles: [ROLE.RH],
        sidebarTitle: `portals.sidebar.modules.${moduleName}`
      },
      params: {
        moduleName: {
          type: 'string',
          value: moduleName
        }
      },
      resolve: [
        {
          provide: 'moduleName',
          useFactory: () => moduleName
        },
        {
          provide: 'rhRecolhaSerieSubsidiosEmpList',
          deps: [RHRecolhaSerieSubsidiosService],
          useFactory: (rhrecolhaSerieSubsidiosService: RHRecolhaSerieSubsidiosService): Promise<Array<IJsonRHRecolhaSerieSubsidiosEmp>> => {
            return rhrecolhaSerieSubsidiosService
              .getListaEmpregados(MIN_COD_EMPREGADO, MAX_SMALLINT_DELPHI_NUMBER, SITUACAO_EMPREGO_ATIVO)
              .then((response: HttpResponse<Array<IJsonRHRecolhaSerieSubsidiosEmp>>) => response.body);
          }
        }
      ]
    }
  };
}
