import {isEmpty, isFile, toJson} from 'pl-comps-angular';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_DOCS_PARTILHADOS, IDocsPartilhadosEntity} from './docsPartilhados.entity.interface';
import {IJsonGDFile} from '../gdoc/JsonGDoc.entity.interface';

export const ENTITY_DOCS_PARTILHADOS: IDocsPartilhadosEntity = {
  name: ENTITY_NAME_DOCS_PARTILHADOS,
  roles: [ROLE.PCA],
  icon: 'fa-exchange',
  metadata: {
    keyName: 'isChecked',
    fields: [
      {name: 'isChecked', type: 'boolean'},
      {name: 'isPdf', type: 'boolean'}
    ]
  },
  service: function () {
    this.uploadDoc = (files: Array<Blob> | Array<File>, gdFile?: IJsonGDFile) => {
      const body: FormData = new FormData();
      if (!isEmpty(gdFile)) {
        body.append('data', toJson(gdFile));
      }
      for (const file of files) {
        if (isFile(file)) {
          body.append('file', file, file.name);
        } else {
          body.append('file', file);
        }
      }
      return this.post<void, FormData>({url: 'uploaddoc', body: body});
    };
  }
};
