<div class="reembolsosiva">
  <entity-detail
    entityName="reembolsosiva"
    [type]="type"
    [(model)]="model"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [(ngForm)]="ngForm">
        <pl-group>
          <label [translate]="'reembolsosiva.fields.periodoDeclaracao'"></label>
          <edit>
            <pl-autocomplete
              [model]="model.periodoDeclaracao"
              (modelChange)="changedPeriodosDeclaracao($event)"
              [source]="periodosDeclaracao"
              [rowTemplate]="periodoRowTemplate"
              [output]="periodoOutput"
              [allowInvalid]="false"
              [allowEmpty]="false"
              [properties]="{validators: {required: {value: true}}}">
            </pl-autocomplete>
            <h6 class="mt-1 mb-3 text-danger" *ngIf="errorClosed" [translate]="'reembolsosiva.errorClosed'"></h6>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'reembolsosiva.fields.periodosRelacao'"></label>
          <edit>
            <div [plPromise]="promise">
              <ng-container *ngIf="periodosRelacao.length">
                <div *ngFor="let periodo of periodosRelacao; let $index = index">
                  <pl-edit type="boolean" attrName="periodoRelacao" [(model)]="activePeriodosRelacao[$index]" [properties]="{label: periodo}"></pl-edit>
                </div>
              </ng-container>

              <pl-alert theme="pl-alert-sm" type="info" [closeable]="false" *ngIf="!promise && ngForm?.pristine && !periodosRelacao.length">
                <div [translate]="'reembolsosiva.pristineEmptyPeriodosRelacao'"></div>
              </pl-alert>

              <pl-alert theme="pl-alert-sm" [closeable]="false" *ngIf="!promise && !ngForm?.pristine && !periodosRelacao.length">
                <div [translate]="'reembolsosiva.errorEmptyPeriodosRelacao'"></div>
              </pl-alert>
            </div>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
