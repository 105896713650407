import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {IJsonDebDiretosSEPARecord, IJsonDebDiretosSEPARecordLog} from '../debDiretosSEPA.module.interface';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../entities/gdoc/gdoc.entity.interface';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';

@Component({
  selector: 'ddsepa-log-modal',
  templateUrl: './debDiretosSEPA.log.modal.component.html'
})
export class DebDiretosSEPALogModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public record: IJsonDebDiretosSEPARecord;

  public gdocFolderId: string;
  public gdocParams: string;
  public tipoDocEntity: typeof EDocTipoEntity;
  public dataGridLogsDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPARecordLog, string>;

  private readonly _service: IGDocEntityService;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.gdocFolderId = '';
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this.tipoDocEntity = EDocTipoEntity;
    this.dataGridLogsDefinition = {
      columns: [
        {dataField: 'stampInsert', dataType: 'datetime', caption: 'debDiretosSEPA.fields.dataHora'},
        {dataField: 'nomeUtilizador', dataType: 'string', caption: 'debDiretosSEPA.fields.utilizador'},
        {dataField: 'tipoAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.operacao'}
      ],
      dataSource: new CustomStore({
        load: () => this._logsTableSource()
      })
    };
  }

  public ngOnInit(): void {
    this.gdocParams = `Lote=${this.record.loteFicheiro}`;
    this._addDocFolder(this.record.loteFicheiro, EFolderDoc.DefaultFolderDebDiretos);
  }

  public getModalTitle(): string {
    return this._plTranslateService.translate('debDiretosSEPA.strings.logModalTitle', {ndoc: this.record.nDocAsStringSAFT});
  }

  private _addDocFolder(lote: string, parentfolderId: string): Promise<unknown> {
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentfolderId,
      name: lote,
      folderType: EFolderType.System,
      additionalParams: `CGUID=Lote&CGUIDValue=${lote}&Lote=${lote}&TipoEntityRef=${EDocTipoEntity.DebitosDiretosSEPA}`
    };
    return this._service
      .post({body: gdocFolder})
      .then((response) => {
        if (response) {
          this.gdocFolderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('docscomerciais.erros.naoCriouPastaDoc');
        }
      });
  }

  private _logsTableSource(): Promise<Array<IJsonDebDiretosSEPARecordLog>> {
    return this._debDiretosSEPAModuleService.getDebDiretosSEPARecordLog(this.record.faccbId).then((response) => response.body);
  }
}
