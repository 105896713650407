import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_ABONO_AGRUPADO, ETipoAbonoAgrupado} from './tipoabonoagrupado.datasource.interface';

export const DATA_SOURCE_TIPO_ABONO_AGRUPADO: IDataSource<ETipoAbonoAgrupado> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_ABONO_AGRUPADO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoAbonoAgrupado.ND, name: 'tipoAbonoAgrupado.data.nd'},
    {value: ETipoAbonoAgrupado.Vencimentos, name: 'tipoAbonoAgrupado.data.vencimentos'},
    {value: ETipoAbonoAgrupado.SubRefeicao, name: 'tipoAbonoAgrupado.data.subRefeicao'},
    {value: ETipoAbonoAgrupado.RemunAdicionais, name: 'tipoAbonoAgrupado.data.remunAdicionais'},
    {value: ETipoAbonoAgrupado.Subsidios, name: 'tipoAbonoAgrupado.data.subsidios'},
    {value: ETipoAbonoAgrupado.RendEspecie, name: 'tipoAbonoAgrupado.data.rendEspecie'},
    {value: ETipoAbonoAgrupado.CessacaoContrato, name: 'tipoAbonoAgrupado.data.cessacaoContrato'},
    {value: ETipoAbonoAgrupado.Gratificacoes, name: 'tipoAbonoAgrupado.data.gratificacoes'}
  ]
});
