import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaSegurancaSocialComponent} from './components/mapaSegurancaSocial.module.component';

export const MODULE_PCA_RH_MAPA_SEGURANCA_SOCIAL: IModuleDefinition = {
  name: 'pcaSegurancaSocial',
  state: {
    url: '/rh/mapa/segurancasocial',
    component: MapaSegurancaSocialComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaSegurancaSocial'
    }
  }
};
