import {Component, Injector} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {AtivosImportService} from '../../ativosImport.service';

@Component({
  selector: 'modal-ativos-import-importarficheiro',
  templateUrl: './ativosImport.importarFicheiro.modal.component.html'
})
export class AtivosImportImportarFicheiroModalComponent extends CGModalComponent<void> {
  public promise: Promise<void>;
  public uploadUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _ativosImportService: AtivosImportService
  ) {
    super(_injector);
    this._getImportarFicheiroExcelUrl();
  }

  public onFileUpload(): void {
    this._plAlertService.success('ativosimport.modal.ficheirocriadocomsucesso');
    super.close();
  }

  private _getImportarFicheiroExcelUrl(): void {
    this._ativosImportService.getExcelImportar().subscribe((url: string) => {
      this.uploadUrl = url;
    });
  }
}
