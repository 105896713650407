import {Injectable, Injector} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  CONTA_CLIENTES_MAX,
  CONTA_CLIENTES_MIN,
  CONTA_FORNECEDORES_MAX,
  CONTA_FORNECEDORES_MIN,
  EModuleTipo,
  IDocComercialEstatisticaDocItem,
  IDocsComerciasEstatisticasDocFilters,
  MAX_NCCUSTO,
  MAX_STRING
} from './docsComerciaisEstatisticas.module.interface';
import {EDelphiNumberTypes, EMonth, isUndefinedOrNull} from 'pl-comps-angular';
import moment from 'moment';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IDocsComerciaisEstatisticasDocConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {IApiQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocsComerciaisEstatisticasDocService extends ConfigOptionsInstanceService<boolean, IDocsComerciaisEstatisticasDocConfigOptions, IConfigOptionsGroupErp> {
  private readonly _path: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService
  ) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS_DOC);
    this._path = `${this._apiService.path.restapi}/docscomerciaisestatisticas/pordoc`;
  }

  public getEstatisticasDocs(filters: IDocsComerciasEstatisticasDocFilters, moduleType: EModuleTipo): Promise<IApiQueryResponse<IDocComercialEstatisticaDocItem>> {
    return new Promise<IApiQueryResponse<IDocComercialEstatisticaDocItem>>((resolve, reject) => {
      this._apiService
        .get<IApiQueryResponse<IDocComercialEstatisticaDocItem>>({
          url: this._path,
          params: this._prepareFilters(filters, false, moduleType)
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public exportToDoc(params: IDocsComerciasEstatisticasDocFilters, moduleType: EModuleTipo): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/report`,
      responseType: 'blob',
      params: this._prepareFilters(params, true, moduleType)
    });
  }

  private _prepareFilters(filters: IDocsComerciasEstatisticasDocFilters, resumoiva: boolean, moduleType: EModuleTipo): IDocsComerciasEstatisticasDocFilters {
    if (isUndefinedOrNull(filters.ncontade)) {
      filters.ncontade = this._getNContaMinMaxConst(moduleType, 'MIN');
    }

    if (isUndefinedOrNull(filters.ncontaate)) {
      filters.ncontaate = this._getNContaMinMaxConst(moduleType, 'MAX');
    }

    if (isUndefinedOrNull(filters.datade)) {
      filters.datade = moment();
    }

    if (isUndefinedOrNull(filters.dataate)) {
      filters.dataate = moment().month(EMonth.December).endOf('month');
    }

    if (isUndefinedOrNull(filters.nvendedorde)) {
      filters.nvendedorde = 0;
    }

    if (isUndefinedOrNull(filters.nvendedorate)) {
      filters.nvendedorate = EDelphiNumberTypes.MaxSmallInt;
    }

    if (isUndefinedOrNull(filters.codzonade)) {
      filters.codzonade = 0;
    }

    if (isUndefinedOrNull(filters.codzonaate)) {
      filters.codzonaate = EDelphiNumberTypes.MaxSmallInt;
    }

    if (isUndefinedOrNull(filters.ccustode)) {
      filters.ccustode = '';
    }

    if (isUndefinedOrNull(filters.ccustoate)) {
      filters.ccustoate = MAX_NCCUSTO;
    }

    if (isUndefinedOrNull(filters.nrefprocessode)) {
      filters.nrefprocessode = '';
    }

    if (isUndefinedOrNull(filters.nrefprocessoate)) {
      filters.nrefprocessoate = MAX_STRING;
    }

    if (isUndefinedOrNull(filters.docfalist)) {
      filters.docfalist = '';
    }

    if (isUndefinedOrNull(filters.nartigo)) {
      filters.nartigo = '';
    }

    if (isUndefinedOrNull(filters.tipoartigolist)) {
      filters.tipoartigolist = '';
    }

    if (isUndefinedOrNull(filters.grandefamilialist)) {
      filters.grandefamilialist = '';
    }

    if (isUndefinedOrNull(filters.familialist)) {
      filters.familialist = '';
    }

    if (isUndefinedOrNull(filters.subfalist)) {
      filters.subfalist = '';
    }

    if (resumoiva) {
      filters.resumoiva = resumoiva;
    }

    if (isUndefinedOrNull(filters.tipoartigolist)) {
      filters.tipoartigolist = '';
    }
    if (isUndefinedOrNull(filters.grandefamilialist)) {
      filters.grandefamilialist = '';
    }
    if (isUndefinedOrNull(filters.familialist)) {
      filters.familialist = '';
    }
    if (isUndefinedOrNull(filters.subfalist)) {
      filters.subfalist = '';
    }

    if (isUndefinedOrNull(filters.ididechavde)) {
      filters.ididechavde = '';
    }

    if (isUndefinedOrNull(filters.ididechavate)) {
      filters.ididechavate = MAX_STRING;
    }

    if (isUndefinedOrNull(filters.cliclde)) {
      filters.cliclde = '';
    }

    if (isUndefinedOrNull(filters.cliclate)) {
      filters.cliclate = MAX_STRING;
    }

    return filters;
  }

  private _getNContaMinMaxConst(moduleType: EModuleTipo, c: 'MIN' | 'MAX'): string {
    return c === 'MIN'
      ? moduleType === EModuleTipo.VENDAS || moduleType === EModuleTipo.TODOS
        ? CONTA_CLIENTES_MIN
        : CONTA_FORNECEDORES_MIN
      : moduleType === EModuleTipo.COMPRAS || moduleType === EModuleTipo.TODOS
        ? CONTA_FORNECEDORES_MAX
        : CONTA_CLIENTES_MAX;
  }
}
