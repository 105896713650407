import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {ETipoEscolhaRendHabitPerm, ETipoOrdenacao} from '../jsonRendHabitPerm.module.interface';
import {ETipoCategoria} from '../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import moment from 'moment';
import {IRendHabitPerm} from '../rendHabitPerm.module.interface';
import {RendHabitPermService} from '../rendHabitPerm.module.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'rend-habit-perm-modal',
  templateUrl: './rendHabitPerm.modal.component.html'
})
export class RendHabitPermModalComponent extends CGModalComponent<Array<IRendHabitPerm>> {
  @Input() public estado: ETipoEscolhaRendHabitPerm;
  @Input() public tipoOrdenacao: ETipoOrdenacao;
  @Input() public rendHabitPerm: IRendHabitPerm;

  public readonly rhtipoprocessOutput: string;
  public readonly filterAbonoCategOutput: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rendHabitPermService: RendHabitPermService
  ) {
    super(_injector);
    this.rhtipoprocessOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.filterAbonoCategOutput = `grupoAbonoCategoria=${ETipoCategoria.UtilizHabitaPropriaPermanente}`;
    if (!this.rendHabitPerm) {
      this.rendHabitPerm = {
        rendHabitPermId: '',
        codAbdes: '',
        abdesNome: '',
        codEmp: '',
        nome: '',
        valorRenda: 0,
        valorRendaMaxMuni: 0,
        anoProcessamento: moment().year(),
        mesInicioProce: moment().month() + 1,
        observacoes: '',
        suspende: false,
        dataSuspensao: moment(),
        obsSuspensao: '',
        tipoProcessamento: undefined,
        valorIncidendiaIRS: 0,
        tipoProcessamentoStr: '',
        _empDesc: '',
        _abonoDesc: ''
      };
    }
  }

  public close(): Promise<void> {
    return this._rendHabitPermService.postRendHabitPerm(this.estado, this.tipoOrdenacao, this.rendHabitPerm).then((response: HttpResponse<Array<IRendHabitPerm>>) => {
      return super.close(response.body);
    });
  }

  public suspendeChanged(value: boolean): void {
    this.rendHabitPerm.suspende = value;
    if (value) {
      this.rendHabitPerm.dataSuspensao = moment();
    }
  }
}
