<div class="cg-helptopics-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="title"></h5>
  </div>

  <div class="modal-body cg-helptopics-modal-topics">
    <div class="card cg-helptopics-modal-topic" *ngFor="let topic of topics">
      <div class="card-header cg-helptopics-modal-topic-header" (click)="topic.collapsed = !topic.collapsed">
        <div class="cg-helptopics-modal-topic-header-title">
          <i class="fa fa-fw" [class.fa-folder-open-o]="!topic.collapsed" [class.fa-folder-o]="topic.collapsed"></i>&nbsp;
          <span [translate]="topic.title"></span>
        </div>

        <div class="cg-helptopics-modal-topic-header-toggle">
          <i class="fa fa-fw" [class.fa-chevron-up]="!topic.collapsed" [class.fa-chevron-down]="topic.collapsed"></i>
        </div>
      </div>

      <div class="card-body" [ngbCollapse]="topic.collapsed">
        <ul class="list-unstyled cg-helptopics-modal-topic-items">
          <li *ngFor="let item of topic.items">
            <a [attr.href]="item.href" target="_blank" rel="noopener noreferrer"> <i class="fa fa-fw" [ngClass]="item.icon"></i>&nbsp;<span [translate]="item.title"></span> </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
