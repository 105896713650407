<div class="rh-copia-abonos-descontos">
  <pl-alert type="danger" *ngIf="errors?.length" [plAnimationShake]="errors" [closeable]="true">
    <div *ngFor="let error of errors">{{ error | translate }}</div>
  </pl-alert>

  <pl-nav-wizard
    [onFinalize]="finalize"
    [beforeStepChange]="beforeStepChange"
    [definition]="wizardDefinition"
    [properties]="{disableNavigation: false, disablePreviousStep: false, disableNextStep: false}"
    [callback]="wizardCallback">
    <pl-nav-wizard-step [stepId]="steps.Inicio" caption="rhcopiaabonosdescontos.titles.copiaDeAbonosDescontosMultiEmpresa" icon="fa-home">
      <div *plNavWizardStepContent>
        <p [translate]="'rhcopiaabonosdescontos.text.1-1'"></p>
        <p [translate]="'rhcopiaabonosdescontos.text.1-2'"></p>
        <p [translate]="'rhcopiaabonosdescontos.text.1-3'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Selecao" caption="rhcopiaabonosdescontos.titles.selecaoDeEmpresas" icon="fa-list-alt">
      <div *plNavWizardStepContent>
        <div class="d-flex align-items-center gap-3">
          <div class="d-flex align-items-center gap-2">
            <label class="mb-0"><span [translate]="'rhcopiaabonosdescontos.fields.tipo'"></span>:</label>
            <pl-edit type="tipoAbonoDesc" [model]="header.abdes" (modelChange)="tipoAbonoDescChange($event)"></pl-edit>
          </div>

          <div class="d-flex align-items-center gap-2">
            <label class="mb-0" [translate]="header.abdes === tipoAbonoDesconto.Desconto ? 'rhcopiaabonosdescontos.fields.desconto' : 'rhcopiaabonosdescontos.fields.abono'"></label>
            <entity-autocomplete
              *ngIf="header.abdes === tipoAbonoDesconto.Abono"
              entity="abonos"
              [model]="header"
              (modelChange)="codDestinoChange($event)"
              (evtSelectedDescriptionChanged)="header.nomeCodAbdes = $event"
              [fieldsMap]="{codAbDesc: 'codAbdes', designacaoBreve: 'nomeCodAbdes'}"
              [output]="abdesOutput">
            </entity-autocomplete>

            <entity-autocomplete
              *ngIf="header.abdes === tipoAbonoDesconto.Desconto"
              entity="descontos"
              [model]="header"
              (modelChange)="codDestinoChange($event)"
              (evtSelectedDescriptionChanged)="header.nomeCodAbdes = $event"
              [fieldsMap]="{codAbDesc: 'codAbdes', designacaoBreve: 'nomeCodAbdes'}"
              [output]="abdesOutput">
            </entity-autocomplete>
          </div>

          <pl-edit-checkbox [(model)]="header.copiaCruzamentos" [label]="'rhcopiaabonosdescontos.fields.copiaCruzamentos'"></pl-edit-checkbox>

          <pl-edit-checkbox [(model)]="header.copiaIntSalarios" [label]="'rhcopiaabonosdescontos.fields.copiaIntSalarios'"></pl-edit-checkbox>
        </div>

        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionSelecao" [dataSource]="dataGridDefinitionSelecao.dataSource" [(selectedRowKeys)]="selectedEmpresasSelecao"></dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Confirmacao" caption="rhcopiaabonosdescontos.titles.confirmacaoDasOperacoes" icon="fa-check-square-o">
      <div *plNavWizardStepContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionConfirmacao"
          [dataSource]="dataGridDefinitionConfirmacao.dataSource"
          [(selectedRowKeys)]="selectedEmpresasConfirmacao"
          (onSelectionChanged)="onDataGridConfirmacaoSelectionChanged($event)">
          <div *dxTemplate="let item of 'cellTemplatePermiteCopiaConfirmacao'">
            <div class="d-flex align-items-center">
              <pl-button klass="btn-light" (evtClicked)="informacaoConfirmacaoModal(item.data.copiaAbDes.permiteCopia, item.data.copiaAbDes.empresa)">
                <i [ngClass]="iconByTipoErro(item.data.copiaAbDes.permiteCopia)"></i>
              </pl-button>
              &nbsp;<span>{{ item.data.copiaAbDes.mensagem }}</span>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Estado" caption="rhcopiaabonosdescontos.titles.estadoDaCopia" icon="fa-eye" [properties]="{hidePrevious: true}">
      <div *plNavWizardStepContent>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionEstado" [dataSource]="dataGridDefinitionEstado.dataSource">
          <div *dxTemplate="let item of 'cellTemplatePermiteCopiaEstado'">
            <div class="d-flex align-items-center">
              <pl-button klass="btn-light" (evtClicked)="informacaoConfirmacaoModal(item.data.copiaAbDes.permiteCopia, item.data.copiaAbDes.empresa)">
                <i [ngClass]="iconByTipoErro(item.data.copiaAbDes.permiteCopia)"></i>
              </pl-button>
              &nbsp;<span>{{ item.data.copiaAbDes.mensagem }}</span>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
