import {PlTranslateService} from 'pl-comps-angular';
import {ArtigosEditComponent} from './components/edit/artigos.entity.edit.component';
import {ArtigosListComponent} from './components/list/artigos.entity.list.component';
import {DATA_SOURCE_CRITERIOS_DISPONIVEIS} from '../../datasources/criteriodisponiveis/criterioDisponiveis.datasource';
import {DATA_SOURCE_NAME_CALC_PR_VENDA} from '../../datasources/calcprvenda/calcPrVenda.datasource.interface';
import {DATA_SOURCE_NAME_CRITERIOS_DISPONIVEIS} from '../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntity} from './artigos.entity.interface';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonArtigo, IJsonArtigosProcessParams, IJsonArtigoStockArmazens} from './jsonArtigo.entity.interface';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../config/uirouter/uirouter.resolvers';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_UNIDADES_MOVIMENTO} from '../unidadesmovimento/unidadesMovimento.entity.interface';
import {IApiRequestConfig} from '../../services/api/api.service.interface';

export const ENTITY_ARTIGOS = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IArtigosEntity => {
    return {
      name: ENTITY_NAME_ARTIGOS,
      icon: 'fa-tags',
      roles: [ROLE.ERP, ROLE.ATIVOS],
      searchPlaceholder: 'artigos.pesquisa',
      metadata: {
        keyName: 'nArtigo',
        order: 'nome',
        fields: [
          {name: 'nArtigo', width: '120px', caption: 'artigos.fields.nArtigo', placeholder: 'artigos.fields.nArtigoPlaceholder'},
          {
            name: 'nome',
            caption: 'artigos.fields.nome',
            placeholder: 'artigos.fields.nomePlaceholder',
            validators: {required: true, maxlength: 100}
          },
          {
            name: 'nFamilia',
            visible: false,
            caption: 'artigos.fields.nomeFamilia',
            entity: {name: 'familias', keyTarget: 'nFamilia', outputTarget: 'nomeFamilia'}
          },
          {
            name: 'nomeFamilia',
            caption: 'artigos.fields.nomeFamilia',
            placeholder: 'artigos.fields.nomeFamiliaPlaceholder',
            entity: {name: 'familias', keyTarget: 'nFamilia', outputTarget: 'nomeFamilia'},
            validators: {required: true}
          },
          {
            name: 'nTpArt',
            visible: false,
            caption: 'artigos.fields.nomeTpArt',
            entity: {name: 'tiposartigo', keyTarget: 'nTpArt', outputTarget: 'nomeTpArt'}
          },
          {
            name: 'nomeTpArt',
            caption: 'artigos.fields.nomeTpArt',
            placeholder: 'artigos.fields.nomeTpArtPlaceholder',
            entity: {name: 'tiposartigo', keyTarget: 'nTpArt', outputTarget: 'nomeTpArt'},
            properties: {devExpress: {dataGrid: {visible: false}}}
          },
          {
            name: 'nGrFamilia',
            visible: false,
            caption: 'artigos.fields.nomeGrFamilia',
            entity: {name: 'grandesfamilias', keyTarget: 'nGrFamilia', outputTarget: 'nomeGrFamilia'}
          },
          {
            name: 'nomeGrFamilia',
            caption: 'artigos.fields.nomeGrFamilia',
            placeholder: 'artigos.fields.nomeGrFamiliaPlaceholder',
            entity: {name: 'grandesfamilias', keyTarget: 'nGrFamilia', outputTarget: 'nomeGrFamilia'},
            properties: {devExpress: {dataGrid: {visible: false}}}
          },
          {
            name: 'nSubFa',
            visible: false,
            caption: 'artigos.fields.nomeSubFa',
            entity: {name: 'subfamilias', keyTarget: 'nSubFa', outputTarget: 'nomeSubFa'}
          },
          {
            name: 'nomeSubFa',
            caption: 'artigos.fields.nomeSubFa',
            placeholder: 'artigos.fields.nomeSubFaPlaceholder',
            entity: {name: 'subfamilias', keyTarget: 'nSubFa', outputTarget: 'nomeSubFa'},
            properties: {devExpress: {dataGrid: {visible: false}}}
          },
          {name: 'codIvaVenda', visible: false},
          {
            name: 'nomeIvaVenda',
            caption: 'artigos.fields.nomeIvaVenda',
            placeholder: 'artigos.fields.nomeIvaVendaPlaceholder',
            entity: {name: 'ivas', keyTarget: 'codIvaVenda', outputTarget: 'nomeIvaVenda'}
          },
          {name: 'taxaIvaVenda', type: 'number', align: 'right', caption: 'artigos.fields.taxaIvaVenda', placeholder: 'artigos.fields.taxaIvaVendaPlaceholder'},
          {name: 'codIvaCompra', visible: false},
          {
            name: 'nomeIvaCompra',
            caption: 'artigos.fields.nomeIvaCompra',
            placeholder: 'artigos.fields.nomeIvaCompraPlaceholder',
            entity: {name: 'ivas', keyTarget: 'codIvaCompra', outputTarget: 'nomeIvaCompra'}
          },
          {name: 'taxaIvaCompra', align: 'right', caption: 'artigos.fields.taxaIvaCompra', placeholder: 'artigos.fields.taxaIvaCompraPlaceholder'},
          {
            name: 'nomeUnidadeMov',
            caption: 'artigos.fields.nomeUnidadeMov',
            placeholder: 'artigos.fields.nomeUnidadeMovPlaceholder',
            entity: {name: ENTITY_NAME_UNIDADES_MOVIMENTO, keyTarget: 'unidadeMov', outputTarget: 'nomeUnidadeMov'}
          },
          {
            name: 'qtdPorUnid1',
            type: 'number',
            caption: 'artigos.fields.qtdPorUnid1',
            placeholder: 'artigos.fields.qtdPorUnid1Placeholder',
            properties: {decimalsLimit: 3}
          },
          {name: 'movimStock', type: 'boolean', caption: 'artigos.fields.movimStock', properties: {devExpress: {dataGrid: {visible: false}}}},
          {
            name: 'codvaloriz',
            type: DATA_SOURCE_NAME_CRITERIOS_DISPONIVEIS,
            caption: 'artigos.fields.codvaloriz',
            placeholder: 'artigos.fields.codvalorizPlaceholder',
            properties: {
              devExpress: {
                dataGrid: {
                  visible: false,
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
                    if (cellInfo.target === 'row') {
                      const index = DATA_SOURCE_CRITERIOS_DISPONIVEIS.data.findIndex((item: IDataSourceItem<number>) => item.value === <number>cellInfo.value);
                      if (index !== -1) {
                        return plTranslateService.translate(DATA_SOURCE_CRITERIOS_DISPONIVEIS.data[index].name);
                      }
                      return cellInfo.valueText;
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          },
          {
            name: 'codCalcPrecoVenda',
            caption: 'artigos.fields.codCalcPrecoVenda',
            placeholder: 'artigos.fields.codCalcPrecoVendaPlaceholder',
            type: DATA_SOURCE_NAME_CALC_PR_VENDA,
            validators: {required: true}
          },
          {
            name: 'precoSemIva',
            type: 'double',
            align: 'right',
            caption: 'artigos.fields.precoSemIva',
            placeholder: 'artigos.fields.precoSemIvaPlaceholder',
            properties: {decimalsLimit: 2}
          },
          {
            name: 'precoComIva',
            type: 'double',
            align: 'right',
            caption: 'artigos.fields.precoComIva',
            placeholder: 'artigos.fields.precoComIvaPlaceholder',
            properties: {disabled: true, decimalsLimit: 2}
          },
          {
            name: 'qtdTotal',
            type: 'integer',
            align: 'right',
            caption: 'artigos.fields.qtdTotal',
            placeholder: 'artigos.fields.qtdTotalPlaceholder',
            properties: {disabled: true, decimalsLimit: 2, devExpress: {dataGrid: {allowSearch: false, allowFiltering: false, allowHeaderFiltering: false, allowSorting: false}}}
          },
          {name: 'temRetencaoNaFonte', type: 'boolean', caption: 'artigos.fields.temRetencaoNaFonte', placeholder: 'artigos.fields.temRetencaoNaFonte'},
          {name: 'nContaPoc', visible: false},
          {
            name: 'nomeContaPoc',
            caption: 'artigos.fields.ncontapoc',
            placeholder: 'artigos.fields.ncontapoc',
            entity: {
              name: 'pocs',
              keyTarget: 'nContaPoc',
              outputTarget: 'nomeContaPoc',
              fieldsMap: {nConta: 'nContaPoc', nome: 'nomeContaPoc'},
              output: '{{nConta}} - {{nome}}'
            }
          },
          {name: 'nContaPocCompra', visible: false},
          {
            name: 'nomeContaPocCompra',
            caption: 'artigos.fields.nContaPocCompra',
            placeholder: 'artigos.fields.nContaPocCompra',
            entity: {
              name: 'pocs',
              keyTarget: 'nContaPocCompra',
              outputTarget: 'nomeContaPocCompra',
              fieldsMap: {nConta: 'nContaPocCompra', nome: 'nomeContaPocCompra'},
              output: '{{nConta}} - {{nome}}'
            }
          },
          {name: 'artDesactivado', type: 'boolean', caption: 'artigos.fields.artDesactivado', properties: {devExpress: {dataGrid: {visible: false}}}},
          {name: 'artBloqueado', type: 'boolean', caption: 'artigos.fields.artBloqueado', properties: {devExpress: {dataGrid: {visible: false}}}},
          {name: 'temLote', type: 'boolean', caption: 'artigos.fields.temLote', properties: {devExpress: {dataGrid: {visible: false}}}}
        ],
        searchFields: 'nArtigo,nome,nFamilia,nomeFamilia',
        filterFields: 'nFamilia,nTpArt,nGrFamilia,nSubFa,movimStock,temRetencaoNaFonte,artDesactivado,artBloqueado',
        listFields: 'nArtigo,nome,nomeFamilia,nomeTpArt,nomeGrFamilia,nomeSubFa,nomeIvaVenda,taxaIvaVenda,precoSemIva,precoComIva,qtdTotal,movimStock,temLote,codvaloriz,artDesactivado,artBloqueado',
        detailFields:
          'nArtigo,nome,nomeFamilia,nomeTpArt,nomeGrFamilia,nomeSubFa,nomeUnidadeMov,qtdPorUnid1,movimStock,taxaIvaVenda,codvaloriz,precoSemIva,precoComIva,qtdTotal,temRetencaoNaFonte,' +
          'nomeContaPoc,nomeContaPocCompra,artDesactivado,artBloqueado',
        newFields:
          'nArtigo,nome,nomeFamilia,nomeTpArt,nomeGrFamilia,nomeSubFa,nomeIvaVenda,nomeIvaCompra,nomeUnidadeMov,qtdPorUnid1,movimStock,codvaloriz,precoSemIva,temRetencaoNaFonte,nomeContaPoc,' +
          'nomeContaPocCompra,artDesactivado,artBloqueado',
        editFields:
          'nArtigo,nome,nomeFamilia,nomeTpArt,nomeGrFamilia,nomeSubFa,nomeIvaVenda,nomeIvaCompra,nomeUnidadeMov,qtdPorUnid1,movimStock,codvaloriz,precoSemIva,temRetencaoNaFonte,nomeContaPoc,' +
          'nomeContaPocCompra,artDesactivado,artBloqueado'
      },
      autocomplete: {
        rowTemplate: '{{nArtigo}} - {{nome}}',
        output: '{{nArtigo}} - {{nome}}',
        outputDescription: 'nome',
        searchFields: 'nArtigo,nome',
        cacheValues: true
      },
      actions: {
        new: true,
        edit: true,
        detail: true,
        delete: true,
        search: true,
        filter: false
      },
      list: {
        state: {
          component: ArtigosListComponent
        }
      },
      detail: {
        state: {
          component: ArtigosEditComponent,
          data: {
            template: {
              items: [
                {type: 'group', order: 1, fields: [{field: 'nArtigo'}, {field: 'nome'}]},
                {type: 'group', order: 2, fields: [{field: 'nomeFamilia'}, {field: 'nomeTpArt'}]},
                {type: 'group', order: 3, fields: [{field: 'nomeGrFamilia'}, {field: 'nomeSubFa'}]},
                {type: 'group', order: 4, fields: [{field: 'nomeUnidadeMov'}, {field: 'qtdPorUnid1'}]},
                {type: 'field', field: 'movimStock', order: 5},
                {type: 'group', order: 6, fields: [{field: 'taxaIvaVenda'}, {field: 'codvaloriz'}]},
                {type: 'group', order: 7, fields: [{field: 'precoSemIva'}, {field: 'precoComIva'}]},
                {type: 'field', field: 'qtdTotal', order: 8},
                {type: 'field', field: 'temRetencaoNaFonte', order: 9}
              ],
              addMissingFields: false
            }
          }
        }
      },
      edit: {
        state: {
          component: ArtigosEditComponent,
          data: {
            template: {
              items: [
                {type: 'group', order: 1, fields: [{field: 'nArtigo'}, {field: 'nome'}]},
                {type: 'group', order: 2, fields: [{field: 'nomeFamilia'}, {field: 'nomeTpArt'}]},
                {type: 'group', order: 3, fields: [{field: 'nomeGrFamilia'}, {field: 'nomeSubFa'}]},
                {type: 'group', order: 4, fields: [{field: 'nomeIvaVenda'}, {field: 'nomeIvaCompra'}]},
                {type: 'group', order: 5, fields: [{field: 'nomeUnidadeMov'}, {field: 'qtdPorUnid1'}]},
                {type: 'field', field: 'movimStock', order: 6},
                {type: 'group', order: 7, fields: [{field: 'codvaloriz'}, {field: 'precoSemIva'}]},
                {type: 'field', field: 'temRetencaoNaFonte', order: 8}
              ],
              addMissingFields: false
            }
          },
          resolve: [RESOLVER_CG_STORE_URL_BACKOFFICE]
        }
      },
      new: {
        state: {
          component: ArtigosEditComponent,
          data: {
            template: {
              items: [
                {type: 'field', order: 1, field: 'nArtigo'},
                {type: 'field', order: 1, field: 'nome'},
                {type: 'group', order: 2, fields: [{field: 'nomeFamilia'}, {field: 'nomeTpArt'}]},
                {type: 'group', order: 3, fields: [{field: 'nomeGrFamilia'}, {field: 'nomeSubFa'}]},
                {type: 'group', order: 4, fields: [{field: 'nomeIvaVenda'}, {field: 'nomeIvaCompra'}]},
                {type: 'group', order: 5, fields: [{field: 'nomeUnidadeMov'}, {field: 'qtdPorUnid1'}]},
                {type: 'field', field: 'movimStock', order: 6},
                {type: 'group', order: 7, fields: [{field: 'codvaloriz'}, {field: 'precoSemIva'}]},
                {type: 'field', field: 'temRetencaoNaFonte', order: 8}
              ],
              addMissingFields: false
            }
          },
          params: {
            nArtigo: {
              type: 'string',
              value: ''
            }
          }
        }
      },
      service: function () {
        this.getFirst = () => {
          return this.get<IJsonArtigo>({id: 'first', params: {loadallinfo: false}});
        };

        this.getNext = (nartigo: string) => {
          return this.get<IJsonArtigo>({id: 'next', params: {nartigo: nartigo, loadallinfo: false}});
        };

        this.getPrev = (nartigo: string) => {
          return this.get<IJsonArtigo>({id: 'previous', params: {nartigo: nartigo, loadallinfo: false}});
        };

        this.getLast = () => {
          return this.get<IJsonArtigo>({id: 'last', params: {loadallinfo: false}});
        };

        this.getModeloImportacao = () => this.get<Blob>({id: 'import/modelo/xls', responseType: 'blob'});

        this.getExecuteImportacaoUrl = () => `${this.entityUrl()}/import/execute/xls`;

        this.executeImportacao = () => this.apiService.post<Blob, void>({url: this.getExecuteImportacaoUrl(), responseType: 'blob'});

        this.getStockArtigoNosArmazens = (nartigo: string) => {
          return this.get<IJsonArtigoStockArmazens>({id: `${nartigo}/stockarmazens`});
        };

        this.processaStockArtigosNosArmazens = (artigosProcessParams: IJsonArtigosProcessParams, soQtdTotal: boolean) => {
          return this.post<Array<IJsonArtigoStockArmazens>, IJsonArtigosProcessParams>({url: 'stocks/armazens', body: artigosProcessParams, params: {soqtdtotal: soQtdTotal}});
        };

        this.deletePicture = (nArtigo: string) => {
          return this.delete({url: `${nArtigo}/picture`});
        };

        this.getPicture = (nArtigo: string, config?: IApiRequestConfig) => {
          return this.get<Blob>({id: `${nArtigo}/picture`, responseType: 'blob', ...config});
        };

        this.setPicture = (nArtigo: string, file: File, config?: IApiRequestConfig) => {
          const formData: FormData = new FormData();
          formData.append(file.name, file);
          return this.upload<void>({url: `${nArtigo}/picture`, body: formData, ...config});
        };
      }
    };
  }
];
