import {Draft, produce} from 'immer';
import {merge} from 'lodash-es';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Injector} from '@angular/core';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {Logger} from 'pl-comps-angular';
import {CGLocalStorageGroupService} from '../../storage/localstoragegroup.service';
import {EConfigOptionsInstanceName, IConfigOption, IConfigOptions, TConfigOptions} from './config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {IConfigOptionsInstance} from './config.options.instance.interface';

export class ConfigOptionsInstance<T, S extends IConfigOptions<T> = IConfigOptions<T>> implements IConfigOptionsInstance<T, S> {
  private readonly _logger: Logger;
  private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService;
  private readonly _configOptionsKeys: Array<keyof S>;
  private readonly _subject: BehaviorSubject<TConfigOptions<T, S>>;
  private _configOptions: TConfigOptions<T, S>;
  private _promiseFetch: Promise<unknown>;
  private _promiseSet: Promise<unknown>;
  private _observableOptions: Observable<TConfigOptions<T, S>>;

  constructor(
    private readonly _injector: Injector,
    private readonly _groupName: EGroupName,
    private readonly _instanceName: EConfigOptionsInstanceName,
    private readonly _schema: JSONSchema,
    private readonly _defaultOptions: TConfigOptions<T, S>
  ) {
    this._logger = this._injector.get<Logger>(Logger);
    this._cgLocalStorageGroupService = this._injector.get<CGLocalStorageGroupService>(CGLocalStorageGroupService);
    this._configOptions = new Map(this._defaultOptions);
    this._configOptionsKeys = Object.keys(this._defaultOptions);
    this._subject = new BehaviorSubject<TConfigOptions<T, S>>(undefined);
  }

  public keys(): Array<keyof S> {
    return this._configOptionsKeys.slice();
  }

  public defaultOptions(): TConfigOptions<T, S> {
    return this._defaultOptions;
  }

  public options(): Observable<TConfigOptions<T, S>> {
    if (!this._observableOptions) {
      this._observableOptions = this._subject.asObservable().pipe(
        mergeMap((configOptions: TConfigOptions<T, S>) => {
          if (configOptions) {
            return of(configOptions);
          }
          return this.fetchOptions();
        })
      );
    }
    return this._observableOptions;
  }

  public fetchOptions(): Promise<TConfigOptions<T, S>> {
    return new Promise<TConfigOptions<T, S>>((resolve, reject) => {
      Promise.resolve(this._promiseSet)
        .catch((reason: unknown) => {
          this._logger.error(reason);
        })
        .finally(() => {
          const self: ConfigOptionsInstance<T, S> = this;
          this._promiseFetch = (async () => {
            const normalizedConfigOptions: object = await firstValueFrom(self._cgLocalStorageGroupService.getItem<IConfigOptions<T>>(self._instanceName, self._schema, self._groupName));
            if (normalizedConfigOptions) {
              self._configOptions = produce(self._configOptions, (draft: Draft<TConfigOptions<T, S>>) => {
                for (const entry of Object.entries(normalizedConfigOptions)) {
                  const key: Draft<keyof S> = <Draft<keyof S>>entry[0];
                  const currentConfigOption: Draft<IConfigOption<T>> = draft.get(key);
                  const storageConfigOptionValue: Partial<IConfigOption<T>> = {value: entry[1]};
                  draft.set(key, merge<object, Draft<IConfigOption<T>>, Partial<IConfigOption<T>>>({}, currentConfigOption, storageConfigOptionValue));
                }
              });
            }
            self._subject.next(self._configOptions);
            return self._configOptions;
          })()
            .then(resolve)
            .catch((reason: unknown) => {
              this._logger.error(`ConfigOptionsInstance threw an error while fetching "${this._groupName}.${this._instanceName}" due to:`, reason);
              this.setOptions(this._defaultOptions).then(resolve).catch(reject);
            });
        });
    });
  }

  public setOptions(value: TConfigOptions<T, S>, persist: boolean = true): Promise<TConfigOptions<T, S>> {
    return this._internalSetOptions(value, persist, true);
  }

  public setOption(option: Draft<keyof S>, value: T, persist: boolean = true): Promise<TConfigOptions<T, S>> {
    const configOptions: TConfigOptions<T, S> = produce(this._configOptions, (draft: Draft<TConfigOptions<T, S>>) => {
      const configOption: Draft<IConfigOption<T>> = draft.get(option);
      (<T>configOption.value) = value;
      if (!configOption.dirty) {
        configOption.dirty = true;
      }
    });
    return this._internalSetOptions(configOptions, persist, false);
  }

  public setDefaultOptions(): Promise<TConfigOptions<T, S>> {
    return this.setOptions(this.defaultOptions());
  }

  public reloadOptions(): Promise<TConfigOptions<T, S>> {
    return this.fetchOptions();
  }

  public cleanup(): void {
    this._subject.complete();
  }

  private _internalSetOptions(value: TConfigOptions<T, S>, persist: boolean, markAsDirty: boolean): Promise<TConfigOptions<T, S>> {
    return new Promise<TConfigOptions<T, S>>((resolve, reject) => {
      Promise.resolve(this._promiseFetch)
        .catch((reason: unknown) => {
          this._logger.error(reason);
        })
        .finally(() => {
          const self: ConfigOptionsInstance<T, S> = this;
          this._promiseSet = (async () => {
            if (markAsDirty !== false) {
              value = produce(value, (draft: Draft<TConfigOptions<T, S>>) => {
                for (const option of draft.values()) {
                  if (!option.dirty) {
                    option.dirty = true;
                  }
                }
              });
            }
            if (persist !== false) {
              const normalizedConfigOptions: object = {};
              let persistedOption = false;
              for (const [key, configOption] of value) {
                if (configOption.persist !== false) {
                  normalizedConfigOptions[<string>key] = configOption.value;
                  persistedOption = true;
                }
              }
              if (persistedOption) {
                await firstValueFrom(self._cgLocalStorageGroupService.setItem(self._instanceName, normalizedConfigOptions, self._schema, self._groupName));
              }
            }
            self._configOptions = value;
            self._subject.next(self._configOptions);
            return self._configOptions;
          })()
            .then(resolve)
            .catch(reject);
        });
    });
  }
}
