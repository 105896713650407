<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.modalTitleConfigClientes'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridClientesDefinition" [dataSource]="dataGridClientesDefinition.dataSource" cgDxDataGridInstanceName="clientes" (onInitialized)="onClientesInitialized($event)">
    <div *dxTemplate="let cell of 'toolbarTemplateAlert'">
      <pl-tooltip
        *ngIf="countNaoTemAutorizacao > 0"
        [config]="{text: semAutorizacaoDebitosDiretos ? 'debDiretosSEPA.messages.mostrarTodosOsClientes' : 'debDiretosSEPA.messages.filtarRegistosQueNaoTemAutorizacao', container: 'body'}">
        <button type="button" class="btn btn-xs btn-warning ms-2" (click)="filtrarPorNaoTemAutorizacao()" *plTooltipContent>
          <i ng-class="semAutorizacaoDebitosDiretos ? fa fa-fw fa-cancel me-1 : fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i>
          <span [translate]="'debDiretosSEPA.strings.xNaoTemAutorizacaoAnexada'" [translateParams]="{count: countNaoTemAutorizacao}"></span>
        </button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'cellTemplateActions'">
      <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.edit' | translate" (click)="editCliente(item.data)" icon="fa fa-fw fa-pencil" [stylingMode]="'text'"></dx-button>
      <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.delete' | translate" (click)="deleteCliente(item)" icon="trash" [stylingMode]="'text'"></dx-button>
    </div>
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
