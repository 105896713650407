<div class="acrescimos-encargos-ferias-single">
  <pl-toolbar [instanceId]="toolbarAcrescimoEncargosSingleId"></pl-toolbar>

  <fieldset class="acrescimosencargosferias-fieldset mt-3">
    <legend [translate]="'acrescimosencargosferias.cab.totalEncAnuais'"></legend>

    <div class="entity-detail-form">
      <pl-group>
        <pl-group>
          <label [translate]="'acrescimosencargosferias.cab.tipo'"></label>
          <edit>
            <div class="form-control-align">{{ encargosCab.tipoDesc }}</div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'acrescimosencargosferias.cab.acTrabalho'"></label>
          <edit>
            <div class="form-control-align">{{ encargosCab.percSeguradora | cgcTax }}</div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'acrescimosencargosferias.cab.estado'"></label>
          <edit>
            <div class="form-control-align">{{ encargosCab.estadoDesc }}</div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'acrescimosencargosferias.cab.dataInt'"></label>
          <edit>
            <div class="form-control-align">{{ encargosCab.dataIntegracao | cgcDate }}</div>
          </edit>
        </pl-group>
      </pl-group>
    </div>
  </fieldset>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinitionSingle"
    cgDxDataGridInstanceName="acrescimosEncargosPorEmp"
    [dataSource]="dataGridDefinitionSingle.dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onSaved)="onSaved($event)">
    <div *dxTemplate="let item of 'cellTemplateCodEmpregado'">
      <div class="d-flex align-items-center">
        <pl-tooltip [config]="{text: 'acrescimosencargosferias.btns.empregado', placement: 'right', container: 'body'}">
          <button *plTooltipContent type="button" class="btn btn-primary btn-xs" (click)="openDGEMP(item.data.codEmpregado)" plPromise>
            <i class="fa fa-fw fa-user"></i>
          </button>
        </pl-tooltip>

        <span class="ms-auto">{{ item.data.codEmpregado }}</span>
      </div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateInfo'">
      <pl-tooltip [config]="{text: item.data.descrBaseEncFerias, placement: 'left', container: 'body'}">
        <button type="button" *plTooltipContent class="btn btn-primary btn-xs" [disabled]="!item.data.descrBaseEncFerias" (click)="showMessageInfo(item.data.descrBaseEncFerias)" plPromise>
          <i class="fa fa-fw fa-info"></i>
        </button>
      </pl-tooltip>
    </div>
  </dx-data-grid>
</div>
