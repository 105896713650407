import {HttpResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {merge} from 'lodash-es';
import {copy, IPlValidator, IPlValidatorValidateParams, isDefinedNotNull} from 'pl-comps-angular';
import {maxDateCG, maxDateCGD, minDateCG, minDateCGD} from '../../../../../../../common/utils/utils';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonModelo22, IJsonModelo22ContasDef, IJsonModelo22PrejuizosFiscais, IJsonModelo22Rosto} from '../../../jsonModelo22.module.interface';
import {IModelo22ItemPeriodoMontante, IRostoItemNIFValorPeriodo, IRostoItemValorNIF, IRostoQuadro14List} from '../../../modelo22.module.interface';
import {Modelo22Service} from '../../../modelo22.module.service';
import {Modelo22ContasDefModalComponent} from './modal/contasdef/modelo22.contasDef.modal.component';
import {Modelo22RostoModalComponent} from './modal/modelo22.rosto.modal.component';
import {Modelo22PrejuizosFiscaisModalComponent} from './modal/prejuizosfiscais/modelo22.prejuizosFiscais.modal.component';
import {Modelo22RostoQuadro14ModalComponent} from './modal/quadro14/modelo22.rosto.quadro14.modal.component';
import {TDate} from '../../../../../../../common/dates';
import moment from 'moment';
import {Modelo22NewTaxaDerramaModalComponent} from './modal/newtaxaderrama/modelo22.newTaxaDerrama.modal.component';
import {MODULE_NAME_TRIBUT_AUTONOMAS} from '../../../../../tributacaoautonoma/tributAutonomas.module.interface';
import {IModuleMaintenanceInstance} from '../../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../../components/entity/maintenance/module/module.maintenance.service';

const NUMBER_ONE = 1;
const NUMBER_TWO = 2;
const NUMBER_THREE = 3;
const NUMBER_FOUR = 4;
const NUMBER_FIVE = 5;
const NUMBER_SIX = 6;
const NUMBER_SEVEN = 7;
const NUMBER_EIGHT = 8;
const NUMBER_NINE = 9;
const NUMBER_TEN = 10;
// const NUMBER_00_1 = 0.01;
// const NUMBER_IMPOSTO_50000 = 50000;
// const PERCENT_17 = 0.17;
// const PERCENT_21 = 0.21;

@Component({
  selector: 'modelo22-anexos-rosto',
  templateUrl: './modelo22.anexos.rosto.component.html'
})
export class Modelo22AnexosRostoComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly titleContasDef: string;
  public readonly dataGridDefinitionTable396: IDevExpressDataGrid;

  public readonly dataGridDefinitionTable398: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable309: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable320: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable331: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable309B: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable397A: IDevExpressDataGrid;
  public readonly dataGridDefinitionTable397B: IDevExpressDataGrid;
  public readonly dataGridDefinitionTableQuadro12: IDevExpressDataGrid;
  public readonly dataGridDefinitionTableQuadro14: IDevExpressDataGrid;
  public readonly validatorCampo346: IPlValidator<number>;

  public rostoQuadro3Campo4Check1: boolean;
  public rostoQuadro3Campo4Check3: boolean;
  public rostoQuadro3Campo4Check4: boolean;
  public rostoQuadro3Campo4Check5: boolean;
  public rostoQuadro3Campo4Check6: boolean;
  public rostoQuadro3Campo4Check7: boolean;
  public rostoQuadro3Campo4Check8: boolean;

  public rostoQuadro3Campo4ACheck1: boolean;
  public rostoQuadro3Campo4ACheck2: boolean;

  public rostoQuadro4Campo2Check1: boolean;
  public rostoQuadro4Campo2Check2: boolean;
  public rostoQuadro4Campo2Check3: boolean;
  public rostoQuadro4Campo2Check4: boolean;
  public rostoQuadro4Campo2Check5: boolean;
  public rostoQuadro4Campo2Check7: boolean;
  public rostoQuadro4Campo2Check9: boolean;
  public rostoQuadro4Campo2Check10: boolean;

  public rostoQuadro13CampoInitAtvYes: boolean;
  public rostoQuadro13CampoInitAtvNo: boolean;

  private readonly _maintenanceInstanceTributAutonomas: IModuleMaintenanceInstance;

  private _promise: Promise<void>;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _translateService: TranslateService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
    this._maintenanceInstanceTributAutonomas = this._moduleMaintenanceService.build(MODULE_NAME_TRIBUT_AUTONOMAS);
    this.titleContasDef = this._translateService.instant('modelo22.comum.tooltipContasDef');
    this.dataGridDefinitionTable396 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro09.table396.fields.prej'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table396.fields.periodo'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro09.table396.fields.nif'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable396'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.table396.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('396'))
    };
    this.dataGridDefinitionTable398 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro09.table398.fields.prej'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table398.fields.periodo'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro09.table398.fields.nif'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable398'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.table398.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('398'))
    };
    this.dataGridDefinitionTable309 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table309.fields.periodo'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.rosto.quadro09.table309.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable309'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.reggeral'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('309'))
    };
    this.dataGridDefinitionTable320 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table320.fields.periodo'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.rosto.quadro09.table320.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable320'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.redtaxa'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('320'))
    };
    this.dataGridDefinitionTable331 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table331.fields.periodo'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.rosto.quadro09.table331.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable331'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.isencao'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('331'))
    };
    this.dataGridDefinitionTable309B = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table309B.fields.periodo'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro09.table309B.fields.montante'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro09.table309B.fields.nif'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable309B'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.reggeral'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('309B'))
    };
    this.dataGridDefinitionTable397A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro09.table397a.fields.valor'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table397a.fields.periodo'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro09.table397a.fields.nif'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable397A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.table397a.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('397A'))
    };
    this.dataGridDefinitionTable397B = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro09.table397b.fields.valor'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro09.table397b.fields.periodo'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro09.table397b.fields.nif'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTable397B'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro09.table397b.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro9('397B'))
    };
    this.dataGridDefinitionTableQuadro12 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.rosto.quadro12.table.fields.nif'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.rosto.quadro12.table.fields.retfonte'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTableQuadro12'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro12.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro12())
    };
    this.dataGridDefinitionTableQuadro14 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codPais', dataType: 'number', caption: 'modelo22.rosto.quadro14.table.fields.codpais'},
        {dataField: 'paisComCDT', dataType: 'boolean', caption: 'modelo22.rosto.quadro14.table.fields.paisComCDT'},
        {dataField: 'tipoRendimentos', dataType: 'number', caption: 'modelo22.rosto.quadro14.table.fields.tiprend'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.rosto.quadro14.table.fields.periodo'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.salcaducado'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.saldnaored'},
        {
          caption: 'modelo22.rosto.quadro14.table.fields.apurapreio',
          alignment: 'center',
          columns: [
            {dataField: 'impostoPago', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.imppagestr', width: 200},
            {dataField: 'fraccaoImposto', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.facimprendest', width: 200},
            {dataField: 'creditoDeImposto', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.credpreio', width: 200}
          ]
        },
        {dataField: 'deducaoEfetuada', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.dedperio'},
        {dataField: 'saldoTransita', dataType: 'double', caption: 'modelo22.rosto.quadro14.table.fields.saldotrans'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnTableQuadro14'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.rosto.quadro14.title'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineRostoQuadro14())
    };

    this.rostoQuadro3Campo4Check1 = false;
    this.rostoQuadro3Campo4Check3 = false;
    this.rostoQuadro3Campo4Check4 = false;
    this.rostoQuadro3Campo4Check5 = false;
    this.rostoQuadro3Campo4Check6 = false;
    this.rostoQuadro3Campo4Check7 = false;
    this.rostoQuadro3Campo4Check8 = false;
    this.rostoQuadro3Campo4ACheck1 = false;
    this.rostoQuadro3Campo4ACheck2 = false;
    this.rostoQuadro4Campo2Check1 = false;
    this.rostoQuadro4Campo2Check2 = false;
    this.rostoQuadro4Campo2Check3 = false;
    this.rostoQuadro4Campo2Check4 = false;
    this.rostoQuadro4Campo2Check5 = false;
    this.rostoQuadro4Campo2Check7 = false;
    this.rostoQuadro4Campo2Check9 = false;
    this.rostoQuadro4Campo2Check10 = false;
    this.rostoQuadro13CampoInitAtvYes = false;
    this.rostoQuadro13CampoInitAtvNo = false;

    this.validatorCampo346 = {
      message: 'modelo22.errors.campo346',
      validate: ({modelValue}: IPlValidatorValidateParams<number>) => this._validateCampo346(modelValue)
    };
  }

  public ngOnInit(): void {
    for (const field of this.rosto.quadro03.campo4_1a8) {
      this.rostoQuadro3Campo4CheckChanged(field, true);
    }

    this.rostoQuadro3Campo4ACheckChanged(this.rosto.quadro03.campo4A, true);

    for (const field of this.rosto.quadro04.campo2_1a5e7) {
      this.rostoQuadro4Campo2Check1a7Changed(field, true);
    }

    this.rostoQuadro4Campo2Check9e10Changed(true, this.rosto.quadro04.campo2_9e10);

    this._evaluateCampoIniciouAtividade();
    this.rostoQuadro13CampoInitAtvYes = this.rosto.quadro13.campoIniciouAtividade === 1;
    this.rostoQuadro13CampoInitAtvNo = this.rosto.quadro13.campoIniciouAtividade !== 1;
    this._setListIndexes();
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
      this._applyRosto(this.modelo22.rosto);
      // this._setListIndexes();
    }
  }

  public addLineRostoQuadro9(field: string): Promise<void> {
    if (field !== '309') {
      const modalInstance = this._cgModalService.showVanilla(Modelo22RostoModalComponent);
      const componentInstance: Modelo22RostoModalComponent = modalInstance.componentInstance;
      componentInstance.modelo22 = copy(this.modelo22);
      componentInstance.newLineFor = field;
      return modalInstance.result.then((result: IJsonModelo22) => {
        this.modelo22 = result;
        this._applyRosto(result.rosto);
      });
    }

    return this._modelo22Service.prejuizosFiscais().then((response: HttpResponse<Array<IJsonModelo22PrejuizosFiscais>>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22PrejuizosFiscaisModalComponent);
      const componentInstance: Modelo22PrejuizosFiscaisModalComponent = modalInstance.componentInstance;
      componentInstance.modelo22 = copy(this.modelo22);
      componentInstance.prejuizosFiscais = response.body;
      return modalInstance.result.then((result: IJsonModelo22) => {
        this.modelo22 = result;
        this._applyRosto(result.rosto);
      });
    });
  }

  public deleteLineRostoQuadro9(line: IRostoItemValorNIF | IModelo22ItemPeriodoMontante | IRostoItemNIFValorPeriodo, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      let doCalculate = false;
      if (field === '396') {
        this.rosto.quadro09.campo396List.splice(line._index - 1, 1);
        this._setListIndexes();
        this.emitModelo22();
      }
      if (field === '398') {
        this.rosto.quadro09.campo398List.splice(line._index - 1, 1);
        this._setListIndexes();
        this.emitModelo22();
      }
      if (field === '309') {
        if ('periodo' in line) {
          // this.modelo22.rosto = this.rosto;
          this.promise = this._modelo22Service.deletePrejuizoFiscal(line.periodo, this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
            this.modelo22 = response.body;
            // this.rosto = response.body.rosto;
            this._applyRosto(response.body.rosto);
          });
          return;
        }
      }
      if (field === '320') {
        this.rosto.quadro09.campo320List.splice(line._index - 1, 1);
        doCalculate = true;
      }
      if (field === '331') {
        this.rosto.quadro09.campo331List.splice(line._index - 1, 1);
        doCalculate = true;
      }
      if (field === '397A') {
        this.rosto.quadro09.campo397AList.splice(line._index - 1, 1);
        doCalculate = true;
      }
      if (field === '397B') {
        this.rosto.quadro09.campo397BList.splice(line._index - 1, 1);
        doCalculate = true;
      }
      if (field === '309B') {
        this.rosto.quadro09.campo309BList.splice(line._index - 1, 1);
        doCalculate = true;
      }
      if (doCalculate) {
        this.promise = this._modelo22Service.calculaRosto(this.rosto).then((response: HttpResponse<IJsonModelo22Rosto>) => {
          this._applyRosto(response.body);
        });
      }
    });
  }

  public addLineRostoQuadro12(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22RostoModalComponent);
    const componentInstance: Modelo22RostoModalComponent = modalInstance.componentInstance;
    componentInstance.modelo22 = copy(this.modelo22);
    componentInstance.newLineFor = 'quadro12List';
    return modalInstance.result.then((result: IJsonModelo22) => {
      this.modelo22 = result;
      this._applyRosto(result.rosto);
    });
  }

  public deleteLineRostoQuadro12(line: IRostoItemValorNIF): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.rosto.quadro12.list.splice(line._index - 1, 1);
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineRostoQuadro14(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22RostoQuadro14ModalComponent);
    const componentInstance: Modelo22RostoQuadro14ModalComponent = modalInstance.componentInstance;
    componentInstance.rosto = copy(this.rosto);
    return modalInstance.result.then((result: IJsonModelo22Rosto) => {
      this._applyRosto(result);
    });
  }

  public deleteLineRostoQuadro14(line: IRostoQuadro14List): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.rosto.quadro14.list.splice(line._index - 1, 1);
      this.promise = this._modelo22Service.calculaRosto(this.rosto).then((response: HttpResponse<IJsonModelo22Rosto>) => {
        this._applyRosto(response.body);
      });
    });
  }

  public rostoQuadro3Campo4CheckChanged(value: number, checked: boolean): void {
    switch (value) {
      case NUMBER_ONE:
        this.rostoQuadro3Campo4Check1 = checked;
        break;
      case NUMBER_THREE:
        this.rostoQuadro3Campo4Check3 = checked;
        break;
      case NUMBER_FOUR:
        this.rostoQuadro3Campo4Check4 = checked;
        break;
      case NUMBER_FIVE:
        this.rostoQuadro3Campo4Check5 = checked;
        break;
      case NUMBER_SIX:
        this.rostoQuadro3Campo4Check6 = checked;
        break;
      case NUMBER_SEVEN:
        this.rostoQuadro3Campo4Check7 = checked;
        break;
      case NUMBER_EIGHT:
        this.rostoQuadro3Campo4Check8 = checked;
        break;
    }

    const index = this.rosto.quadro03.campo4_1a8.findIndex((field: number) => field === value);
    if (index !== -1) {
      if (!checked) {
        this.rosto.quadro03.campo4_1a8.splice(index, 1);
      }
    } else {
      this.rosto.quadro03.campo4_1a8.push(value);
    }

    this.emitModelo22();
  }

  public rostoQuadro3Campo4ACheckChanged(value: number, checked: boolean): void {
    switch (value) {
      case NUMBER_ONE:
        this.rostoQuadro3Campo4ACheck1 = checked;
        this.rostoQuadro3Campo4ACheck2 = false;
        break;
      case NUMBER_TWO:
        this.rostoQuadro3Campo4ACheck2 = checked;
        this.rostoQuadro3Campo4ACheck1 = false;
        break;
    }

    this.rosto.quadro03.campo4A = !checked ? undefined : value;

    this.emitModelo22();
  }

  public rostoQuadro4Campo2Check1a7Changed(value: number, checked: boolean): void {
    switch (value) {
      case NUMBER_ONE:
        this.rostoQuadro4Campo2Check1 = checked;
        this.rostoQuadro4Campo2Check2 = false;
        break;
      case NUMBER_TWO:
        this.rostoQuadro4Campo2Check2 = checked;
        this.rostoQuadro4Campo2Check1 = false;
        this.rostoQuadro4Campo2Check3 = false;
        this.rostoQuadro4Campo2Check4 = false;
        this.rostoQuadro4Campo2Check5 = false;
        this.rostoQuadro4Campo2Check7 = false;
        break;
      case NUMBER_THREE:
        this.rostoQuadro4Campo2Check3 = checked;
        this.rostoQuadro4Campo2Check2 = false;
        break;
      case NUMBER_FOUR:
        this.rostoQuadro4Campo2Check4 = checked;
        this.rostoQuadro4Campo2Check2 = false;
        this.rostoQuadro4Campo2Check5 = false;
        break;
      case NUMBER_FIVE:
        this.rostoQuadro4Campo2Check5 = checked;
        this.rostoQuadro4Campo2Check2 = false;
        this.rostoQuadro4Campo2Check4 = false;
        break;
      case NUMBER_SEVEN:
        this.rostoQuadro4Campo2Check7 = checked;
        this.rostoQuadro4Campo2Check2 = false;
        break;
    }

    this.rosto.quadro04.campo2_1a5e7 = [];
    if (this.rostoQuadro4Campo2Check1) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_ONE);
    }
    if (this.rostoQuadro4Campo2Check2) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_TWO);
    }
    if (this.rostoQuadro4Campo2Check3) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_THREE);
    }
    if (this.rostoQuadro4Campo2Check4) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_FOUR);
    }
    if (this.rostoQuadro4Campo2Check5) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_FIVE);
    }
    if (this.rostoQuadro4Campo2Check7) {
      this.rosto.quadro04.campo2_1a5e7.push(NUMBER_SEVEN);
    }

    this.emitModelo22();
  }

  public rostoQuadro4Campo2Check9e10Changed(value: boolean, field: number): void {
    this.rostoQuadro4Campo2Check9 = false;
    this.rostoQuadro4Campo2Check10 = false;
    switch (field) {
      case NUMBER_NINE:
        this.rosto.quadro04.campo2_9e10 = field;
        this.rostoQuadro4Campo2Check9 = value;
        break;
      case NUMBER_TEN:
        this.rosto.quadro04.campo2_9e10 = field;
        this.rostoQuadro4Campo2Check10 = value;
        break;
      default:
        this.rosto.quadro04.campo2_9e10 = undefined;
        break;
    }

    this.emitModelo22();
  }

  public async emitModelo22(evaluateModelo22: boolean = false): Promise<void> {
    this._evaluateCampoIniciouAtividade();
    if (evaluateModelo22) {
      await this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        this.modelo22 = response.body;
      });
    }
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public openContasDef(campo: string): Promise<void> {
    this.promise = this._modelo22Service.getCamposContasDef(campo).then((response: HttpResponse<Array<IJsonModelo22ContasDef>>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22ContasDefModalComponent);
      const componentInstance: Modelo22ContasDefModalComponent = modalInstance.componentInstance;
      componentInstance.campo = campo;
      componentInstance.contasDef = response.body;
      componentInstance.modelo22 = copy(this.modelo22);
      modalInstance.result.then((result: IJsonModelo22) => {
        this.modelo22 = result;
        this.emitModelo22();
      });
    });
    return this.promise;
  }

  public openNewTaxaDerrama(): void {
    const modalInstance = this._cgModalService.showVanilla(Modelo22NewTaxaDerramaModalComponent, {size: 'sm'});
    const componentInstance: Modelo22NewTaxaDerramaModalComponent = modalInstance.componentInstance;
    componentInstance.modelo22 = copy(this.modelo22);
    modalInstance.result.then((result: IJsonModelo22) => {
      this.modelo22 = result;
      this.emitModelo22();
    });
  }

  public openTributacoesAutonomas(): Promise<void> {
    return this._maintenanceInstanceTributAutonomas.maintenance({modalOptions: {size: 'xxl'}});
  }

  public consideraPrejuizoFiscalChanged(): Promise<void> {
    this.rosto.quadro10.consideraPrejuizoFiscalChanged = true;
    return this.emitModelo22(true);
  }

  public rostoQuadro13InitAtvChanged(initAtv131: boolean): void {
    if (initAtv131 && this.rostoQuadro13CampoInitAtvYes) {
      this.rostoQuadro13CampoInitAtvNo = false;
    }
    if (!initAtv131 && this.rostoQuadro13CampoInitAtvNo) {
      this.rostoQuadro13CampoInitAtvYes = false;
    }

    this.rosto.quadro13.campoIniciouAtividade = !this.rostoQuadro13CampoInitAtvNo && !this.rostoQuadro13CampoInitAtvYes ? -1 : this.rostoQuadro13CampoInitAtvYes ? 1 : 0;
  }

  public get rosto(): IJsonModelo22Rosto {
    return this.modelo22.rosto;
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures,grouped-accessor-pairs
  public set rosto(value: IJsonModelo22Rosto) {
    this.modelo22.rosto = value;
  }

  private _setListIndexes(): void {
    let index396List = 1;
    let index398List = 1;
    let index309List = 1;
    let index320List = 1;
    let index331List = 1;
    let index397AList = 1;
    let index397BList = 1;
    let index309BList = 1;
    let indexQuadro12 = 1;
    let indexQuadro14 = 1;

    for (const listElement of this.rosto.quadro09.campo396List) {
      listElement._index = index396List;
      index396List++;
    }
    this.dataGridDefinitionTable396.dataSource = this.rosto.quadro09.campo396List;

    for (const listElement of this.rosto.quadro09.campo398List) {
      listElement._index = index398List;
      index398List++;
    }
    this.dataGridDefinitionTable398.dataSource = this.rosto.quadro09.campo398List;

    for (const listElement of this.rosto.quadro09.campo309List) {
      listElement._index = index309List;
      index309List++;
    }
    this.dataGridDefinitionTable309.dataSource = this.rosto.quadro09.campo309List;

    for (const listElement of this.rosto.quadro09.campo320List) {
      listElement._index = index320List;
      index320List++;
    }
    this.dataGridDefinitionTable320.dataSource = this.rosto.quadro09.campo320List;

    for (const listElement of this.rosto.quadro09.campo331List) {
      listElement._index = index331List;
      index331List++;
    }
    this.dataGridDefinitionTable331.dataSource = this.rosto.quadro09.campo331List;

    for (const listElement of this.rosto.quadro09.campo397AList) {
      listElement._index = index397AList;
      index397AList++;
    }
    this.dataGridDefinitionTable397A.dataSource = this.rosto.quadro09.campo397AList;

    for (const listElement of this.rosto.quadro09.campo397BList) {
      listElement._index = index397BList;
      index397BList++;
    }
    this.dataGridDefinitionTable397B.dataSource = this.rosto.quadro09.campo397BList;

    for (const listElement of this.rosto.quadro09.campo309BList) {
      listElement._index = index309BList;
      index309BList++;
    }
    this.dataGridDefinitionTable309B.dataSource = this.rosto.quadro09.campo309BList;

    for (const listElement of this.rosto.quadro12.list) {
      listElement._index = indexQuadro12;
      indexQuadro12++;
    }
    this.dataGridDefinitionTableQuadro12.dataSource = this.rosto.quadro12.list;

    for (const listElement of this.rosto.quadro14.list) {
      listElement._index = indexQuadro14;
      indexQuadro14++;
    }
    this.dataGridDefinitionTableQuadro14.dataSource = this.rosto.quadro14.list;
  }

  private _applyRosto(rosto: IJsonModelo22Rosto, emitRosto: boolean = true): void {
    this.rosto = merge(this.rosto, rosto);
    this._clearCGDatesDefault();
    this._evaluateCampoIniciouAtividade();
    if (emitRosto) {
      this._setListIndexes();
      this.emitModelo22();
    }
  }

  private _validateCampo346(value: number): boolean {
    return value >= 0;
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _clearCGDatesDefault(): void {
    this.rosto.quadro04.campo1_Data = this._validateDate(this.rosto.quadro04.campo1_Data);
    this.rosto.quadro04.campo2_11 = this._validateDate(this.rosto.quadro04.campo2_11);
    this.rosto.quadro04.campo2_6 = this._validateDate(this.rosto.quadro04.campo2_6);
    this.rosto.quadro04.campo2_8 = this._validateDate(this.rosto.quadro04.campo2_8);

    this.rosto.quadro05.campo3 = this._validateDate(this.rosto.quadro05.campo3);
    this.rosto.quadro05.campo5 = this._validateDate(this.rosto.quadro05.campo5);
    this.rosto.quadro05.campo6 = this._validateDate(this.rosto.quadro05.campo6);

    this.rosto.quadro10B.campo4 = this._validateDate(this.rosto.quadro10B.campo4);

    this.rosto.quadro11.campo418 = this._validateDate(this.rosto.quadro11.campo418);

    this.rosto.quadro11A.campo465 = this._validateDate(this.rosto.quadro11A.campo465);

    this.rosto.quadro13.campo13e3 = this._validateDate(this.rosto.quadro13.campo13e3);
  }

  private _validateDate(date: TDate): TDate {
    if (
      (isDefinedNotNull(date) && moment(date).isSameOrBefore(minDateCG(), 'date')) ||
      moment(date).isSameOrBefore(minDateCGD(), 'date') ||
      moment(date).isSameOrAfter(maxDateCG(), 'date') ||
      moment(date).isSameOrAfter(maxDateCGD(), 'date')
    ) {
      return undefined;
    }
    return date;
  }

  private _evaluateCampoIniciouAtividade(): void {
    if (!this.rosto.quadro13.campoIniciouAtividade || this.rosto.quadro13.campoIniciouAtividade === 0) {
      this.rostoQuadro13CampoInitAtvNo = true;
      this.rostoQuadro13CampoInitAtvYes = false;
    }

    if (this.rosto.quadro13.campoIniciouAtividade === 1) {
      this.rostoQuadro13CampoInitAtvNo = false;
      this.rostoQuadro13CampoInitAtvYes = true;
    }

    if (this.rosto.quadro13.campoIniciouAtividade === -1) {
      this.rostoQuadro13CampoInitAtvNo = false;
      this.rostoQuadro13CampoInitAtvYes = false;
    }
  }
}
