import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlUploadCallback, IPlUploadFile, IPlUploadParams, isDefined, isNumber} from 'pl-comps-angular';
import {CG_DEFAULT_GERAL_CONTAB_DIGITAL_MAX_UPLOAD_FILES_AT_A_TIME, CG_DEFAULT_MAX_FILE_SIZE, ICGConfigurations} from '../../../../../services/config/config.service.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonDocDigitalizado} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'modal-docsdigitais-adicionar-documentos',
  templateUrl: './docsDigitais.adicionarDocumentos.modal.component.html'
})
export class DocsDigitaisAdicionarDocumentosModalComponent extends CGModalComponent<Array<IJsonDocDigitalizado>> implements OnInit, OnDestroy {
  @Input() public folderId: string;

  public readonly callback: IPlUploadCallback;
  public uploadUrl: string;
  public params: IPlUploadParams;
  public maxFiles: number;
  public maxFileSize: number;

  private readonly _subscription: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this._contabilidadeDigitalService.documents.addUrl().subscribe((url: string) => {
      this.uploadUrl = url;
    });
    this.params = {};
    this.callback = {};
    this.maxFiles = CG_DEFAULT_GERAL_CONTAB_DIGITAL_MAX_UPLOAD_FILES_AT_A_TIME;
    this.maxFileSize = CG_DEFAULT_MAX_FILE_SIZE;
    this._subscription = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      const maxUploadFilesAtATime: number = configurations.geral.contabilidadeDigital.maxUploadFilesAtATime;
      if (isNumber(maxUploadFilesAtATime)) {
        this.maxFiles = maxUploadFilesAtATime;
      }
      const maxFileSize: number = configurations.empresa.erpcloud.ficheiros.maxFileSize;
      if (isNumber(maxFileSize)) {
        this.maxFileSize = maxFileSize;
      }
    });
  }

  public ngOnInit(): void {
    this.params = {folderId: this.folderId};
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  public close(): void {
    const responses: Array<HttpResponse<Array<IJsonDocDigitalizado>>> = [];
    const responseFiles: Array<IJsonDocDigitalizado> = this.callback
      .getSuccessFiles()
      .map<Array<IJsonDocDigitalizado>>((file: IPlUploadFile) => {
        if (!(file.upload.response instanceof HttpResponse)) {
          return undefined;
        }
        const uploadResponse: HttpResponse<Array<IJsonDocDigitalizado>> = <HttpResponse<Array<IJsonDocDigitalizado>>>file.upload.response;
        const alreadyExists: boolean = responses.includes(uploadResponse);
        if (alreadyExists) {
          return undefined;
        }
        responses.push(uploadResponse);
        return file.upload.response.body;
      })
      .filter(isDefined)
      .reduce((previousValue: Array<IJsonDocDigitalizado>, currentValue: Array<IJsonDocDigitalizado>) => previousValue.concat(currentValue), []);
    super.close(responseFiles);
  }

  public dismiss(): void {
    this.close();
  }

  public evaluateAllowClose(): void {
    const pendingFiles: Array<IPlUploadFile> = this.callback.getPendingFiles();
    if (pendingFiles.length > 0) {
      this.disableClose();
    } else {
      this.enableClose();
    }
  }

  public finishedUpload(): void {
    this.evaluateAllowClose();
    if (!this.closeDisabled) {
      const erroredFiles: Array<IPlUploadFile> = this.callback.getErroredFiles();
      if (!erroredFiles.length) {
        this.close();
      }
    }
  }
}
