import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {SaftContabService} from '../../saftContab.module.service';
import {PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'saft-contab-import-modal',
  templateUrl: './saftContab.importConfigs.modal.component.html'
})
export class SaftContabImportConfigsModalComponent extends CGModalComponent<void> {
  @Input() public convertContas: boolean;

  public uploadUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _saftContabService: SaftContabService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._getImportarFicheiroUrl();
  }

  public onFileUpload(): void {
    this._plAlertService.success('saftcontab.modals.import.configimportedsuccess');
    super.close();
  }

  private _getImportarFicheiroUrl(): void {
    const importUrl: string = this.convertContas ? 'importConfigContas' : 'importConfig';
    this._saftContabService.getImportConfig(importUrl).subscribe((url: string) => {
      this.uploadUrl = url;
    });
  }
}
