import {IJsonRHProcSegSocialConsultEnvios} from './jsonRHProcCegCocialConsultEnvios.module.interface';
import {IPlTooltipCallback} from 'pl-comps-angular';

/* eslint-disable @typescript-eslint/naming-convention */

export enum ESSEstadoFicheiro {
  SSEF_none,
  SSEF_Gerado,
  SSEF_Enviado,
  SSEF_EnviadoPorProcessar,
  SSEF_EnviadoAceite,
  SSEF_EnviadoNaoAceite,
  SSEF_EnviadoRejeitado,
  SSEF_Erro
}

/* eslint-enable @typescript-eslint/naming-convention */

export interface IRHProcSegSocialConsultEnviosParams {
  ano: number;
  mes: number;
}

export interface IRHProcSegSocialConsultEnvios extends IJsonRHProcSegSocialConsultEnvios {
  tooltipCallback: IPlTooltipCallback;
}

export const MODULE_NAME_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS = 'rhprocsegsocialconsultenvios';
