import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'efatura-generate-docs',
  templateUrl: './eFaturaGenerateDocs.modal.component.html'
})
export class EFaturaGenerateDocsModalComponent extends CGModalComponent<boolean> implements OnInit {
  @Input() public docsTodosComunicados: boolean;
  public soDocsPorComunicar: boolean;

  constructor(protected _injector: Injector) {
    super(_injector);
    this.soDocsPorComunicar = true;
  }

  public ngOnInit(): void {
    if (this.docsTodosComunicados) {
      this.soDocsPorComunicar = false;
    }
  }
}
