import {Directive, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, Type} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isArray, isBoolean, isFunction, Logger} from 'pl-comps-angular';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {EGestaoDGEMPSType, EGestaoDGEMPSView, GESTAO_DGEMPS_KEY_FORMAT, IGestaoDGEMPSCalendario, IGestaoDGEMPSEvent, IGestaoDGEMPSMarcarData, IGestaoDGEMPSPRHServico} from '../gestaodgemps.interface';
import {EMPTY_GUID} from '../../../../config/constants';
import {gestaoDGEMPSAllDay, gestaoDGEMPSDefaultViewDate, gestaoDGEMPSEvaluateEventStatus, gestaoDGEMPSEvaluateEventStatusIntegrado, gestaoDGEMPSRangeHasValidDates} from '../gestaodgemps.utilities';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {GestaoDGEMPSService} from '../gestaodgemps.service';
import {IGestaoDGEMPSEventsDetailAddEvent} from '../eventsdetail/gestaodgemps.eventsdetail.component.interface';
import {
  IGestaoDGEMPSViewDetailRefresh,
  IGestaoDGEMPSViewFetchMarcacoes,
  IGestaoDGEMPSViewRangeSelect,
  IGestaoDGEMPSViewRefreshMarcacoes,
  IGestaoDGEMPSViewSelectionChanged,
  TGestaoDGEMPSViewPostMarcarTarefaFn
} from './gestaodgemps.view.component.interface';
import {IJsonConfigAbdes, IJsonConfigEstrutura, IJsonConfigTipo, IJsonConfigTipoProcessamento, IJsonPRHEventosConfig} from '../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHAbonos, IJsonPRHMarcarAbonos} from '../../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas, IJsonPRHMarcarFaltas} from '../../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias, IJsonPRHMarcarFerias} from '../../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHFluxo} from '../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {isMoment, normalizeDate} from '../../../../common/utils/moment.utils';
import moment, {Moment, MomentInput} from 'moment';

const TODAY: Moment = moment();

@Directive()
export abstract class GestaoDGEMPSViewComponent implements OnInit, OnChanges {
  @Input() public type: EGestaoDGEMPSType;
  @Input() public manager: boolean;
  @Input() public configStructure: IJsonConfigEstrutura;
  @Input() public configEvents: Array<IJsonPRHEventosConfig>;
  @Input() public fluxos: Array<IJsonPRHFluxo>;
  @Input() public empregado: IJsonDGEMP;
  @Input() public holidaysDates: Array<MomentInput>;
  @Input() public servico: IGestaoDGEMPSPRHServico;
  @Input() public viewDate: Moment;
  @Input() public forceDelete?: boolean;
  @Input() public loading?: boolean;
  @Output() public readonly viewDateChange: EventEmitter<Moment>;
  @Output() public readonly loadingChange: EventEmitter<boolean>;

  public readonly types: typeof EGestaoDGEMPSType;
  public readonly views: typeof EGestaoDGEMPSView;
  public codEmpLanca: number;
  public calendarios: Array<IGestaoDGEMPSCalendario>;
  public detailActive: boolean;
  public detailStart: Moment;
  public detailEnd: Moment;
  public detailEvents: Array<IGestaoDGEMPSEvent>;

  protected readonly _logger: Logger;
  protected readonly _translateService: TranslateService;
  protected readonly _cgModalService: CGModalService;
  protected readonly _gestaoDGEMPSService: GestaoDGEMPSService;
  protected readonly _today: Moment;
  protected readonly _empregadosByDate: Map<string, Set<number>>;
  protected _multiSelect: boolean;
  protected _eventTitle: string;
  protected _startOfYear: Moment;
  protected _endOfYear: Moment;
  protected _postMarcarTarefaFn: TGestaoDGEMPSViewPostMarcarTarefaFn;

  private _modoColaboradorMarcarFerias: boolean;
  private _loadingPromises: number;

  protected constructor(protected readonly _injector: Injector) {
    this.configEvents = [];
    this.manager = true;
    this.forceDelete = false;
    this.loading = false;
    this.viewDateChange = new EventEmitter<Moment>();
    this.loadingChange = new EventEmitter<boolean>();
    this.types = EGestaoDGEMPSType;
    this.views = EGestaoDGEMPSView;
    this.calendarios = [];
    this.detailActive = false;
    this.detailEvents = [];
    this._logger = this._injector.get<Logger>(Logger);
    this._translateService = this._injector.get<TranslateService>(TranslateService);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    this._gestaoDGEMPSService = this._injector.get<GestaoDGEMPSService>(GestaoDGEMPSService);
    this._today = moment().startOf('date');
    this._empregadosByDate = new Map<string, Set<number>>();
    this._multiSelect = false;
    this._modoColaboradorMarcarFerias = false;
    this._loadingPromises = 0;
  }

  public ngOnInit(): void {
    this._changedType();
    this._changedManager();
    this._changedViewDate(this.viewDate);
    this._changedEmpregado();
    this._evaluateModoColaboradorMarcarFerias();
  }

  public ngOnChanges({type, manager, empregado}: SimpleChanges): void {
    const changedType: boolean = type && !type.isFirstChange();
    const changedManager: boolean = manager && !manager.isFirstChange();
    if (changedType || changedManager) {
      if (changedType) {
        this._changedType(type.currentValue);
      }
      if (changedManager) {
        this._changedManager(manager.currentValue);
      }
      this._evaluateModoColaboradorMarcarFerias();
    }
    if (empregado && !empregado.isFirstChange()) {
      this._changedEmpregado(empregado.currentValue);
    }
  }

  public setViewDate(value: MomentInput): void {
    this.viewDate = moment(value);
    this.viewDateChange.emit(this.viewDate);
    this.hideEventsDetail();
    this._refreshCalendarios();
  }

  public onRangeSelect({startDate, endDate, events}: IGestaoDGEMPSViewRangeSelect): void {
    if (events.length) {
      return;
    }
    this._marcarTarefa({
      startDate: startDate,
      endDate: endDate,
      events: events
    });
  }

  public onSelectionChanged({startDate, endDate, events}: IGestaoDGEMPSViewSelectionChanged): void {
    this.detailStart = startDate;
    this.detailEnd = endDate;
    this.detailEvents = events;
  }

  public onDetailRefresh({startDate, endDate}: IGestaoDGEMPSViewDetailRefresh): Promise<void> {
    const promise: Promise<void> = this._refreshCalendarios(undefined, startDate, endDate);
    this._addLoadingPromise(promise);
    return promise;
  }

  public onDetailAddEvent({startDate, endDate}: IGestaoDGEMPSEventsDetailAddEvent): void {
    this._marcarTarefa({
      startDate: moment(startDate),
      endDate: moment(endDate),
      events: []
    });
  }

  public hideEventsDetail(): void {
    if (this.detailActive) {
      this.detailActive = false;
    }
  }

  public abstract get view(): EGestaoDGEMPSView;

  public get colaboradorMarcarFerias(): boolean {
    return this._modoColaboradorMarcarFerias;
  }

  protected abstract _fetchMarcacoes(params: IGestaoDGEMPSViewFetchMarcacoes): unknown | Promise<unknown>;

  protected abstract _refreshMarcacoes(params: IGestaoDGEMPSViewRefreshMarcacoes<unknown>): void;

  protected abstract _addCalendarioEvent(calendario: IGestaoDGEMPSCalendario, event: IGestaoDGEMPSEvent): void;

  protected _changedType(value: EGestaoDGEMPSType = this.type): void {
    this.type = value;
    let title = '';
    switch (this.type) {
      case EGestaoDGEMPSType.Abonos:
        title = 'prheventosconfig.tipos.abonos';
        break;
      case EGestaoDGEMPSType.Faltas:
        title = 'prheventosconfig.tipos.faltas';
        break;
      case EGestaoDGEMPSType.Ferias:
        title = 'prheventosconfig.tipos.ferias';
        break;
    }
    this._multiSelect = true;
    this._eventTitle = title ? this._translateService.instant(title) : undefined;
  }

  protected _changedManager(value: boolean = this.manager): void {
    this.manager = isBoolean(value) ? value : true;
  }

  protected _changedViewDate(value: Moment = this.viewDate): void {
    this.viewDate = moment.isMoment(value) ? value.clone() : gestaoDGEMPSDefaultViewDate();
    this._startOfYear = normalizeDate(this.viewDate.clone().startOf('year'));
    this._endOfYear = normalizeDate(this.viewDate.clone().endOf('year'));
  }

  protected _changedEmpregado(value: IJsonDGEMP = this.empregado): void {
    this.empregado = value;
    this.codEmpLanca = this.empregado?.codEmp;
  }

  protected _setLoading(value: boolean): void {
    this.loading = value === true || this._loadingPromises > 0;
    this.loadingChange.emit(this.loading);
  }

  protected _addLoadingPromise(value: Promise<unknown>): void {
    this._loadingPromises++;
    this._evaluateLoading();
    Promise.resolve(value).finally(() => {
      this._loadingPromises--;
      this._evaluateLoading();
    });
  }

  protected _refreshCalendarios(calendarios: Array<IGestaoDGEMPSCalendario> = this._getActiveCalendarios(), startDate?: Moment, endDate?: Moment): Promise<void> {
    if (!isMoment(startDate)) {
      startDate = this._startOfYear.clone();
    }
    if (!isMoment(endDate)) {
      endDate = this._endOfYear.clone();
    }
    for (const calendario of calendarios) {
      this._clearEmpregadoByDate(startDate, endDate, calendario.codEmp);
    }
    const fullYear = startDate.isSame(this._startOfYear, 'date') && endDate.isSame(this._endOfYear, 'date');
    return Promise.all(
      calendarios.map((calendario: IGestaoDGEMPSCalendario) => {
        return this._refreshCalendario(calendario.codEmp, startDate, endDate, false).catch(() => undefined);
      })
    ).then((marcacoesCalendarios: Array<unknown>) => {
      for (let i = 0; i < calendarios.length; i++) {
        const calendario: IGestaoDGEMPSCalendario = calendarios[i];
        const marcacoes: unknown = marcacoesCalendarios[i];
        if (!marcacoes) {
          continue;
        }
        this._refreshMarcacoes({
          codEmp: calendario.codEmp,
          startDate: startDate,
          endDate: endDate,
          fullYear: fullYear,
          codServico: this.servico?.codServico || 0,
          marcacoes: marcacoes
        });
      }
    });
  }

  protected _refreshCalendario(codEmp: number, startDate?: Moment, endDate?: Moment, standalone: boolean = true): Promise<unknown> {
    if (!isMoment(startDate)) {
      startDate = this.viewDate.clone().startOf('year');
    }
    if (!isMoment(endDate)) {
      endDate = startDate.clone().endOf('year');
    }
    const fullYear = startDate.isSame(this._startOfYear, 'date') && endDate.isSame(this._endOfYear, 'date');
    const codServico: number = this.servico?.codServico || 0;
    if (standalone && startDate && endDate) {
      this._clearEmpregadoByDate(startDate, endDate, codEmp);
    }
    const fetchParams: IGestaoDGEMPSViewFetchMarcacoes = {
      startDate: startDate,
      endDate: endDate,
      codEmp: codEmp,
      codServico: codServico
    };
    const promise: Promise<unknown> = Promise.resolve(this._fetchMarcacoes(fetchParams)).then((marcacoes: unknown) => {
      if (standalone) {
        this._refreshMarcacoes({
          ...fetchParams,
          fullYear: fullYear,
          marcacoes: marcacoes
        });
      }
      return marcacoes;
    });
    this._addLoadingPromise(promise);
    return promise;
  }

  protected _addCalendarioEvents(calendario: IGestaoDGEMPSCalendario): void {
    let detail: IGestaoDGEMPSEvent;

    for (let evento of calendario.listaMarcadas) {
      for (let linha of evento.linhas) {
        switch (this.type) {
          case EGestaoDGEMPSType.Abonos:
            evento = <IJsonPRHMarcarAbonos>evento;
            linha = <IJsonPRHAbonos>linha;
            detail = {
              idTarefaCab: linha.idTarefaCab,
              tipoEvento: this.type,
              codEvento: linha.codPRHAbonos,
              nomeEvento: this._eventTitle,
              codIntegracao: linha.remunId,
              codEmp: calendario.codEmp,
              nomeEmp: calendario.nomeEmp,
              posicao: evento.cab.posicao,
              tipo: undefined,
              processamento: this._getDesignacaoTipoProcessamento(linha.tipoProcessamento),
              abdes: this._getDesignacaoAbDes(linha.ncodAbdes),
              date: moment(linha.dataAbono),
              horaInicio: undefined,
              horaFim: undefined,
              qtd: linha.quantidade,
              motivo: evento.motivo,
              allDay: true,
              status: undefined,
              docID: evento.cab.docid !== EMPTY_GUID ? evento.cab.docid : undefined,
              downloadUrl: undefined
            };
            break;
          case EGestaoDGEMPSType.Faltas:
            evento = <IJsonPRHMarcarFaltas>evento;
            linha = <IJsonPRHFaltas>linha;
            detail = {
              idTarefaCab: linha.idTarefaCab,
              tipoEvento: this.type,
              codEvento: linha.idFaltas,
              nomeEvento: this._eventTitle,
              codIntegracao: linha.ocupacaoId,
              codEmp: calendario.codEmp,
              nomeEmp: calendario.nomeEmp,
              posicao: evento.cab.posicao,
              tipo: this._getDesignacaoTipo(linha.tipoFalta),
              processamento: this._getDesignacaoTipoProcessamento(linha.tipoProcessamento),
              abdes: this._getDesignacaoAbDes(linha.ncodAbdes),
              date: moment(linha.data),
              horaInicio: moment(linha.horaInicio),
              horaFim: moment(linha.horaFim),
              qtd: undefined,
              motivo: evento.motivo,
              allDay: gestaoDGEMPSAllDay(linha.horaInicio, linha.horaFim),
              status: undefined,
              docID: evento.cab.docid && evento.cab.docid !== EMPTY_GUID ? evento.cab.docid : undefined,
              downloadUrl: undefined
            };
            if (detail.docID) {
              detail.downloadUrl = this._getDownloadTarefaDocUrl(detail.idTarefaCab);
            }
            break;
          case EGestaoDGEMPSType.Ferias:
            evento = <IJsonPRHMarcarFerias>evento;
            linha = <IJsonPRHFerias>linha;
            detail = {
              idTarefaCab: linha.idTarefaCab,
              tipoEvento: this.type,
              codEvento: linha.idFerias,
              nomeEvento: this._eventTitle,
              codIntegracao: linha.ocupacaoId,
              codEmp: calendario.codEmp,
              nomeEmp: calendario.nomeEmp,
              posicao: evento.cab.posicao,
              tipo: undefined,
              processamento: undefined,
              abdes: undefined,
              date: moment(linha.data),
              horaInicio: moment(linha.horaInicio),
              horaFim: moment(linha.horaFim),
              qtd: undefined,
              motivo: evento.motivo,
              allDay: gestaoDGEMPSAllDay(linha.horaInicio, linha.horaFim),
              status: undefined,
              docID: evento.cab.docid !== EMPTY_GUID ? evento.cab.docid : undefined,
              downloadUrl: undefined
            };
            break;
        }
        this._addEmpregadoByDate(detail.date, detail.codEmp);
        detail.status = gestaoDGEMPSEvaluateEventStatus(this.type, this.fluxos, this.servico, evento, detail.date, this.forceDelete, this._evaluateConfito(detail.date));
        this._addCalendarioEvent(calendario, detail);
      }
    }

    for (let linha of calendario.listaIntegradas) {
      switch (this.type) {
        case EGestaoDGEMPSType.Abonos:
          linha = <IJsonPRHAbonos>linha;
          detail = {
            idTarefaCab: linha.idTarefaCab,
            tipoEvento: this.type,
            codEvento: undefined,
            nomeEvento: this._eventTitle,
            codIntegracao: linha.remunId,
            codEmp: calendario.codEmp,
            nomeEmp: calendario.nomeEmp,
            posicao: linha.posicao,
            tipo: undefined,
            processamento: this._getDesignacaoTipoProcessamento(linha.tipoProcessamento),
            abdes: this._getDesignacaoAbDes(linha.ncodAbdes),
            date: moment(linha.dataAbono),
            horaInicio: undefined,
            horaFim: undefined,
            qtd: linha.quantidade,
            motivo: undefined,
            allDay: true,
            status: undefined,
            docID: undefined,
            downloadUrl: undefined
          };
          break;
        case EGestaoDGEMPSType.Faltas:
          linha = <IJsonPRHFaltas>linha;
          detail = {
            idTarefaCab: linha.idTarefaCab,
            tipoEvento: this.type,
            codEvento: undefined,
            nomeEvento: this._eventTitle,
            codIntegracao: linha.ocupacaoId,
            codEmp: calendario.codEmp,
            nomeEmp: calendario.nomeEmp,
            posicao: linha.posicao,
            tipo: this._getDesignacaoTipo(linha.tipoFalta),
            processamento: this._getDesignacaoTipoProcessamento(linha.tipoProcessamento),
            abdes: this._getDesignacaoAbDes(linha.ncodAbdes),
            date: moment(linha.data),
            horaInicio: moment(linha.horaInicio),
            horaFim: moment(linha.horaFim),
            qtd: undefined,
            motivo: undefined,
            allDay: gestaoDGEMPSAllDay(linha.horaInicio, linha.horaFim),
            status: undefined,
            docID: linha.docid && linha.docid !== EMPTY_GUID ? linha.docid : undefined,
            downloadUrl: undefined
          };
          if (detail.docID) {
            detail.downloadUrl = this._getDownloadTarefaDocUrl(detail.idTarefaCab);
          }
          break;
        case EGestaoDGEMPSType.Ferias:
          linha = <IJsonPRHFerias>linha;
          detail = {
            idTarefaCab: linha.idTarefaCab,
            tipoEvento: this.type,
            codEvento: undefined,
            nomeEvento: this._eventTitle,
            codIntegracao: linha.ocupacaoId,
            codEmp: calendario.codEmp,
            nomeEmp: calendario.nomeEmp,
            posicao: linha.posicao,
            tipo: undefined,
            processamento: undefined,
            abdes: undefined,
            date: moment(linha.data),
            horaInicio: moment(linha.horaInicio),
            horaFim: moment(linha.horaFim),
            qtd: undefined,
            motivo: undefined,
            allDay: gestaoDGEMPSAllDay(linha.horaInicio, linha.horaFim),
            status: undefined,
            docID: undefined,
            downloadUrl: undefined
          };
          break;
      }
      this._addEmpregadoByDate(detail.date, detail.codEmp);
      detail.status = gestaoDGEMPSEvaluateEventStatusIntegrado(this.type, detail.date, this.forceDelete, this._evaluateConfito(detail.date));
      this._addCalendarioEvent(calendario, detail);
    }
  }

  protected _marcarTarefa(data: IGestaoDGEMPSMarcarData): Promise<void> {
    const calendarios: Array<IGestaoDGEMPSCalendario> = this._getActiveCalendarios();
    const {startDate, endDate}: IGestaoDGEMPSMarcarData = data;
    if (!isArray(calendarios) || !calendarios.length || startDate.isAfter(endDate, 'day')) {
      return Promise.resolve();
    }
    if (this.colaboradorMarcarFerias) {
      const singleDay: boolean = startDate.isSame(endDate, 'day');
      if (singleDay) {
        const events: Array<IGestaoDGEMPSEvent> = data.events.filter((event: IGestaoDGEMPSEvent) => moment(event.date).isSame(startDate, 'day'));
        if (events.length === 1 && events[0].status.desmarcavel) {
          return this._desmarcarTarefa(events[0]);
        }
      }
      if (startDate.isBefore(TODAY, 'day')) {
        return Promise.resolve();
      }
    }
    if (this.type !== EGestaoDGEMPSType.Faltas) {
      let foundValidCalendario = false;
      for (const calendario of calendarios) {
        if (gestaoDGEMPSRangeHasValidDates(startDate, endDate, calendario.listaCalendario)) {
          foundValidCalendario = true;
          break;
        }
      }
      if (!foundValidCalendario) {
        return Promise.resolve();
      }
    }
    const modalInstance: NgbModalRef = this._getMarcarModalInstance(data);
    return modalInstance.result.then((successfulCodEmps: ReadonlySet<IGestaoDGEMPSCalendario>) => {
      if (isFunction(this._postMarcarTarefaFn)) {
        this._postMarcarTarefaFn(successfulCodEmps);
      }
      const promise: Promise<void> = this._refreshCalendarios(calendarios, startDate, endDate);
      this._addLoadingPromise(promise);
      return promise;
    });
  }

  protected async _desmarcarTarefa(event: IGestaoDGEMPSEvent): Promise<void> {
    await this._gestaoDGEMPSService.desmarcarTarefa(this.type, event);
    await this._refreshCalendario(event.codEmp, event.date, event.date);
  }

  protected _getActiveCalendarios(): Array<IGestaoDGEMPSCalendario> {
    return this.calendarios.filter((calendario: IGestaoDGEMPSCalendario) => calendario.selected);
  }

  protected _getMarcarModalInstance(data: IGestaoDGEMPSMarcarData): NgbModalRef {
    const modalComponent: Type<GestaoDGEMPSMarcarAbstractModalComponent> = this._gestaoDGEMPSService.getMarcarModalComponent(this.type);
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(modalComponent, {size: 'md'});
    const componentInstance: GestaoDGEMPSMarcarAbstractModalComponent = modalInstance.componentInstance;
    componentInstance.codEmpLanca = this.empregado?.codEmp || 0;
    componentInstance.managerMode = this.manager;
    componentInstance.configEvents = this.configEvents;
    componentInstance.codServico = this.servico?.codServico;
    componentInstance.marcarData = {
      configStructure: this.configStructure,
      configEvents: this.configEvents,
      calendarios: this._getActiveCalendarios(),
      viewDate: this.viewDate,
      startDate: data.startDate,
      endDate: data.endDate,
      events: data.events
    };
    return modalInstance;
  }

  protected _getDesignacaoTipo(codTipo: number): string {
    return this.configStructure.listaTipo.find((tipo: IJsonConfigTipo) => tipo.codTipo === codTipo)?.descricaoTipo;
  }

  protected _getDesignacaoTipoProcessamento(codTipoProcessamento: number): string {
    return this.configStructure.listaTipoProcessamento.find((tipoProcessamento: IJsonConfigTipoProcessamento) => {
      return tipoProcessamento.codTipoProcessamento === codTipoProcessamento;
    })?.descricaoTipoProcessamento;
  }

  protected _getDesignacaoAbDes(nCodAbDes: string): string {
    return this.configStructure.listaAbDes.find((abdes: IJsonConfigAbdes) => abdes.nCodAbDes === nCodAbDes)?.designacao;
  }

  protected _getDownloadTarefaDocUrl(idTarefaCab: number): string {
    return this._gestaoDGEMPSService.getDownloadTarefaDocUrlUrl(idTarefaCab);
  }

  protected _addEmpregadoByDate(date: Moment, codEmp: number): void {
    const key = date.format(GESTAO_DGEMPS_KEY_FORMAT);
    let empregados: Set<number> = this._empregadosByDate.get(key);
    if (!empregados) {
      empregados = new Set<number>();
      this._empregadosByDate.set(key, empregados);
    }
    empregados.add(codEmp);
  }

  protected _clearEmpregadoByDate(startDate: Moment, endDate: Moment, codEmp: number): void {
    for (let date = startDate.clone(); date.diff(endDate, 'days') <= 0; date.add(1, 'day')) {
      const dateKey: string = date.format(GESTAO_DGEMPS_KEY_FORMAT);
      const empregados: Set<number> = this._empregadosByDate.get(dateKey);
      if (empregados) {
        empregados.delete(codEmp);
        if (!empregados.size) {
          this._empregadosByDate.delete(dateKey);
        }
      }
    }
  }

  protected _evaluateConfito(date: Moment): boolean {
    if (this.view !== EGestaoDGEMPSView.Year) {
      return false;
    }
    const key = date.format(GESTAO_DGEMPS_KEY_FORMAT);
    const empregados: Set<number> = this._empregadosByDate.get(key);
    return empregados && empregados.size > 1;
  }

  private _evaluateModoColaboradorMarcarFerias(): void {
    this._modoColaboradorMarcarFerias = this.type === EGestaoDGEMPSType.Ferias && !this.manager;
  }

  private _evaluateLoading(): void {
    this._setLoading(this._loadingPromises > 0);
  }
}
