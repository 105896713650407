<div class="assistente-config-portais-portal-client-connect-utilizador-module entity-detail-form">
  <pl-form [formInstance]="formInstance" [properties]="{validators: {required: {value: true}}}">
    <pl-group>
      <label [translate]="'assistenteconfigportais.fields.utilizador'"></label>
      <edit>
        <entity-autocomplete entity="users" attrName="user" (modelChange)="changedUser($event)" [getDataConfig]="{params: {centralGestId: centralGestId}}" [properties]="{allowInvalid: false}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'assistenteconfigportais.fields.empresa'"></label>
      <edit>
        <entity-autocomplete
          entity="empresas"
          attrName="empresa"
          (modelChange)="changedEmpresa($event)"
          [filter]="filterEmpresas"
          [getDataConfig]="{params: {centralGestId: centralGestId}}"
          [properties]="{allowInvalid: false}">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</div>
