<div class="rhqpirct entity-detail-form">
  <pl-split-view [initialSizeLeft]="50" [initialSizeRight]="50">
    <div *plSplitViewLeftSide>
      <div class="card">
        <div class="card-header" [translate]="'rhqpirct.card.irctcateg'"></div>
        <div class="card-body tree-overflow" [plPromise]="promise">
          <tree-viewer [tree]="listQPIRCTTree" [model]="qpirctTreeSelected" [collapseOnSelect]="true" [selectOnCollapse]="true" (modelChange)="modelChanged($event)"></tree-viewer>
        </div>
      </div>
    </div>

    <div *plSplitViewRightSide>
      <div class="card">
        <div class="card-header" [translate]="'rhqpirct.card.irct'"></div>
        <div class="card-body">
          <div class="d-flex flex-row mb-2" *ngIf="isVisibleSaveAndAddButtons">
            <button type="button" class="btn btn-sm btn-light me-2" (click)="createIRCT()" [disabled]="!!promise">
              <span [translate]="'rhqpirct.btn.addirct'"></span>
            </button>
            <button type="button" class="btn btn-sm btn-light" (click)="createCateg()" [disabled]="!!promise || !qpirctSelected">
              <span [translate]="'rhqpirct.btn.addcateg'"></span>
            </button>
          </div>
          <pl-group>
            <label [translate]="'rhqpirct.card.codirct'"></label>
            <edit>
              <pl-edit type="text" attrName="codIRCT" [(model)]="codIRCT" [properties]="{placeholder: 'rhqpirct.card.codirct', disabled: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhqpirct.card.nomeirct'"></label>
            <edit>
              <pl-edit type="text" attrName="nomeIRCT" [(model)]="nomeIRCT" [properties]="{placeholder: 'rhqpirct.card.nomeirct', disabled: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhqpirct.card.codcategoria'"></label>
            <edit>
              <pl-edit type="text" attrName="codCategoria" [(model)]="codCategoria" [properties]="{placeholder: 'rhqpirct.card.codcategoria', disabled: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhqpirct.card.nomecategoria'"></label>
            <edit>
              <pl-edit type="text" attrName="nomeCategoria" [(model)]="nomeCategoria" [properties]="{placeholder: 'rhqpirct.card.nomecategoria', disabled: true}"></pl-edit>
            </edit>
          </pl-group>
          <div class="d-flex flex-column mt-4">
            <a
              href="https://www.relatoriounico.pt/ru/request/requestIRCT.seam?actionMethod=login.xhtml%3AListIRCT.clear%28%29"
              class="btn btn-sm btn-info text-white"
              target="_blank"
              rel="noopener noreferrer">
              <span [translate]="'rhqpirct.btn.infooficialirct'"></span>
            </a>
            <button *ngIf="isVisible" type="button" class="btn btn-sm btn-success mt-2" (click)="importFile()">
              <span [translate]="'rhqpirct.btn.importlistacateg'"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </pl-split-view>
</div>
