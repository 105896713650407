import {Component, Injector} from '@angular/core';
import {DocContabilidadeFieldInput} from '../docContabilidade.field.input';
import {EDocContabilidadeField} from '../../docContabilidade.interface';

@Component({
  selector: 'doccontabilidade-cdecanual',
  templateUrl: './docContabilidade.CDecAnual.component.html'
})
export class DocContabilidadeCDecAnualComponent extends DocContabilidadeFieldInput<string> {
  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.C_DEC_ANUAL, _injector);
  }

  public modelChanged(value: string): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.cDecAnual
    });
  }

  protected _disabled(): boolean {
    return true;
  }

  protected _disallowInput(): boolean {
    return true;
  }

  protected _hasGroup(): boolean {
    return false;
  }
}
