import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty, isObject, isUndefined, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../services/config/config.service';
import {DefinicaoEmailService} from '../../clifos/components/component/definicaoemails/definicaoEmail.service';
import {EClifoExtraValueTipo} from '../../clifoextravaluetipo/jsonClifoExtraValueTipo.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonClifoExtraValue} from '../../clifoextravalue/jsonClifoExtraValue.entity.interface';
import {IJsonRecibo, IJsonReciboLinha} from '../jsonRecibo.entity.interface';
import {IReciboImprimirModalParams} from '../modals/imprimirmodal/recibo.imprimir.modal.component.interface';
import {IRecibosEntityService} from '../recibos.entity.interface';
import {IRecibosService} from './recibos.entity.service.interface';
import {ReciboImprimirModalComponent} from '../modals/imprimirmodal/recibo.imprimir.modal.component';
import {THttpQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RecibosService implements IRecibosService {
  private readonly _entityName: string;
  private readonly _entityService: IRecibosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _configService: ConfigService,
    private readonly _apiService: ApiService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _definicaoEmailService: DefinicaoEmailService
  ) {
    this._entityName = 'recibos';
    this._entityService = this._entityServiceBuilder.build<IJsonRecibo, IRecibosEntityService>(this._entityName);
  }

  public atribuiMontanteRetencao(recibo: IJsonRecibo): void {
    recibo.cab.temRetencao = false;
    for (const linha of recibo.linhas) {
      if (linha.montanteAReter > 0) {
        recibo.cab.temRetencao = true;
        linha.montanteRetido = linha.montanteAReter;
      } else {
        linha.montanteRetido = 0;
      }
    }
  }

  public calculaPercentagemDesconto(linha: IJsonReciboLinha, percDescSobreValorIVA: boolean): void {
    if (!linha.valorDesconto) {
      return;
    }

    const valorDesconto = linha.valorDesconto;
    if (valorDesconto > linha.valorRecebido) {
      this._plAlertService.error(this._translateService.instant('recibos.erros.valorDescontoMaiorValor'));
    }

    let valorRealIVA = 0;
    if (valorDesconto > 0) {
      valorRealIVA = linha.valorPorReceber > 0 && !percDescSobreValorIVA ? (linha.valorRecebido * linha.valorIVA) / linha.valorPorReceber : 0;

      linha.percDesconto = linha.valorRecebido - valorRealIVA > 0 ? (valorDesconto * 100) / (linha.valorRecebido - valorRealIVA) : 0;
    } else {
      linha.percDesconto = 0;
    }
  }

  public calculaValorDesconto(linha: IJsonReciboLinha, percDescSobreValorIVA: boolean): void {
    if (!linha.percDesconto) {
      linha.valorDesconto = 0;
      return;
    }

    const percDesconto = linha.percDesconto;
    if (percDesconto > 100) {
      this._plAlertService.error(this._translateService.instant('recibos.erros.percentagemDescontoMaior100'));
    }
    let valorRealIVA = 0;
    if (percDesconto > 0) {
      valorRealIVA = linha.valorPorReceber > 0 && percDescSobreValorIVA ? (linha.valorRecebido * linha.valorIVA) / linha.valorPorReceber : 0;

      linha.valorDesconto = linha.valorRecebido - valorRealIVA > 0 ? (percDesconto / 100) * (linha.valorRecebido - valorRealIVA) : 0;
    } else {
      linha.valorDesconto = 0;
    }
  }

  public calculaValorRetencao(linha: IJsonReciboLinha): void {
    if (linha.valorRecebido === linha.valorPorReceber || linha.valorRecebido === 0 || linha.valorPorReceber === 0) {
      linha.montanteRetido = linha.montanteAReter;
    } else {
      const percentagem = (linha.valorRecebido * 100) / linha.valorPorReceber;
      linha.montanteRetido = this.customRound((linha.montanteAReter * percentagem) / 100, this._configService.configurations.contabilidade.decimais.desconto);
    }
  }

  public calculaTotais(recibo: IJsonRecibo): void {
    recibo.cab.totalDescontos = 0;
    recibo.cab.totalReceber = 0;
    recibo.cab.totalRetencao = 0;

    for (const linha of recibo.linhas) {
      if (linha.valorRecebido) {
        recibo.cab.totalRetencao = this.customRound(recibo.cab.totalRetencao + linha.montanteRetido, this._configService.configurations.contabilidade.decimais.valor);
        // atribui total receber
        recibo.cab.totalReceber = this.customRound(recibo.cab.totalReceber + linha.valorRecebido, this._configService.configurations.contabilidade.decimais.valor);
        if (linha.percDesconto) {
          // atribui total desconto
          recibo.cab.totalDescontos = this.customRound(recibo.cab.totalDescontos + linha.valorDesconto, this._configService.configurations.contabilidade.decimais.valor);
        }
      }
    }
    recibo.cab.total = this.customRound(recibo.cab.totalReceber - recibo.cab.totalDescontos - recibo.cab.totalRetencao, this._configService.configurations.contabilidade.decimais.valor);
  }

  public loadLinhas(recibo: IJsonRecibo): Promise<IJsonRecibo> {
    return new Promise<IJsonRecibo>((resolve, reject) => {
      this._entityService
        .post<IJsonRecibo>({url: undefined, body: recibo, params: {loadLinhas: 1}})
        .then((response) => {
          recibo.percDescSobreValorIVA = response.body.percDescSobreValorIVA;
          recibo.cab = response.body.cab;
          recibo.linhas = response.body.linhas;

          this.atribuiMontanteRetencao(recibo);
          this.calculaTotais(recibo);

          resolve(recibo);
        })
        .catch(reject);
    });
  }

  public validaDados(recibo: IJsonRecibo): Array<{fieldname: string; message: string}> {
    const erros = [];

    if (isUndefined(recibo.cab) || !recibo.cab.nConta) {
      erros.push({fieldname: 'nConta', message: 'O fornecedor não está preenchido.'});
    }

    if (isUndefined(recibo.cab.data)) {
      erros.push({fieldname: 'data', message: 'Tem de definir uma data de recebimento'});
    }

    let valRecDefinido = false;
    for (let i = 0; i < recibo.linhas.length; i++) {
      const linha = recibo.linhas[i];

      if (Math.abs(linha.valorRecebido) > Math.abs(linha.valorPorReceber)) {
        erros.push({
          fieldname: 'error',
          message: `O valor recebido na linha nº${i + 1} é superior ao valor do documento "${linha.numeroDocumento}"`
        });
      }

      if (linha.valorRecebido && Math.abs(linha.valorRecebido) !== 0) {
        if (linha.valorRecebido > 0 && linha.valorPorReceber < 0) {
          erros.push({
            fieldname: 'error',
            message: `O valor recebido na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ser negativo por se tratar de uma nota de crédito.`
          });
        } else if (linha.valorRecebido < 0 && linha.valorPorReceber > 0) {
          erros.push({
            fieldname: 'error',
            message: `O valor recebido na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ser positivo.`
          });
        }
        valRecDefinido = true;
      }

      if (Math.abs(linha.percDesconto) !== 0) {
        if (!linha.percDesconto || linha.percDesconto < 0 || linha.percDesconto > 100) {
          erros.push({
            fieldname: 'error',
            message: `A percentagem de desconto na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ter um valor entre 0 e 100.`
          });
        }
      }

      if (Math.abs(linha.valorDesconto) !== 0) {
        if (!linha.valorDesconto || linha.valorDesconto > linha.valorRecebido) {
          erros.push({
            fieldname: 'error',
            message: `O valor de desconto na linha nº${i + 1} do documento "${linha.numeroDocumento}" é superior ao valor recebido.`
          });
        }
      }
    }
    if (!valRecDefinido) {
      erros.push({fieldname: 'error', message: 'Não definiu nenhum valor a receber.'});
    }

    return erros;
  }

  public async getPdf(params: IReciboImprimirModalParams): Promise<void> {
    if (!isObject(params) || isEmpty(params.extPocCabID) || isEmpty(params.nConta)) {
      this._plAlertService.error(this._translateService.instant('recibos.erros.notselected'));
      return Promise.resolve();
    }
    if (params.nConta) {
      const response: THttpQueryResponse<IJsonClifoExtraValue> = await this._definicaoEmailService.queryByNContaTipo(params.nConta, EClifoExtraValueTipo.EmailRecibosCliente);
      const emails: string = response.body.list.map((clifoExtraValue: IJsonClifoExtraValue) => clifoExtraValue.valor).join(';');
      if (emails) {
        params = {...params, email: emails};
      }
    }
    const modalInstance = this._cgModalService.showVanilla(ReciboImprimirModalComponent);
    const componentInstance: ReciboImprimirModalComponent = modalInstance.componentInstance;
    componentInstance.params = params;
    componentInstance.recibosService = this;
    return modalInstance.result;
  }

  public getPdfUrl(extPocCabID: string, nomereport: string, nconta: string, ncopias: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/${this._entityName}/${extPocCabID}/pdf`, {nomereport: nomereport, nconta: nconta, ncopias: ncopias});
  }

  public enviaPdfPorEmail(extPocCabID: string, nconta: string, email: string, ncopias: number): TServiceResponse<IJsonRecibo> {
    return this._entityService.sendEmail(extPocCabID, nconta, email, ncopias);
  }

  public anular(recibo: IJsonRecibo): Promise<void> {
    if (!recibo?.cab?.extPocCabID) {
      const error: string = this._translateService.instant('recibos.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    return this._entityService.delete({url: `${recibo.cab.extPocCabID}/anular`}).then(() => undefined);
  }

  public getNDocFa(recibo: IJsonRecibo): Promise<number> {
    return this._entityService.get<number>({id: `${recibo.cab.extPocCabID}/ndocfa`}).then((response) => {
      return response.body;
    });
  }

  public customRound(value: number, precision = 2): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / 100;
  }
}
