<div class="rhatualizaferiasemp entity-detail-form">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="rhatualizaferiasemp"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onSaved)="onSaved($event)">
    <div *dxTemplate="let item of 'toolbarTemplateBtn'">
      <pl-button klass="btn-primary btn-xs" (evtClicked)="openModalDataCadastro()">
        <i class="fa fa-fw fa-calendar" aria-hidden="true"></i>&nbsp;<span [translate]="'rhatualizaferiasemp.atualizadatacadastro'"></span>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'cellTemplateLivre'">
      <i *ngIf="item.data.livre && item.data.livre !== ''" class="fa fa-fw fa-check-square text-success" aria-hidden="true"></i>
    </div>
  </dx-data-grid>
</div>
