{
    "pt": {
        "Yes": "Sim",
        "No": "Não",
        "Cancel": "Cancelar",
        "CheckState": "Check state",
        "Close": "Fechar",
        "Clear": "Limpar",
        "Done": "Concluído",
        "Loading": "Carregando ...",
        "Select": "Selecione ...",
        "Search": "Pesquisar ...",
        "Back": "Voltar",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Sem dados",

        "dxDropDownEditor-selectLabel": "Selecione",

        "validation-required": "Preenchimento obrigatório",
        "validation-required-formatted": "{0} é de preenchimento obrigatório",
        "validation-numeric": "Valor deve ser um número",
        "validation-numeric-formatted": "{0} deve ser um número",
        "validation-range": "Valor está fora do intervalo",
        "validation-range-formatted": "{0} está fora do intervalo",
        "validation-stringLength": "O comprimento do valor não está correto",
        "validation-stringLength-formatted": "O comprimento de {0} não está correto",
        "validation-custom": "Valor inválido",
        "validation-custom-formatted": "{0} é inválido",
        "validation-async": "Valor inválido",
        "validation-async-formatted": "{0} é inválido",
        "validation-compare": "Valores não coincidem",
        "validation-compare-formatted": "{0} não coincidem",
        "validation-pattern": "Valor não corresponde ao padrão",
        "validation-pattern-formatted": "{0} não corresponde ao padrão",
        "validation-email": "Email inválido",
        "validation-email-formatted": "{0} é inválido",
        "validation-mask": "Valor inválido",

        "dxLookup-searchPlaceholder": "Número mínimo de caracteres: {0}",

        "dxList-pullingDownText": "Puxar para baixo para recarregar...",
        "dxList-pulledDownText": "Soltar para recarregar...",
        "dxList-refreshingText": "Recarregando ...",
        "dxList-pageLoadingText": "A carregar ...",
        "dxList-nextButtonText": "Mais",
        "dxList-selectAll": "Selecionar todos",
        "dxList-listAriaLabel": "Items",
        "dxList-listAriaLabel-deletable": "Deletable items",
        "dxListEditDecorator-delete": "Eliminar",
        "dxListEditDecorator-more": "Mais",
        "dxList-selectAll-indeterminate": "Half-checked",
        "dxList-selectAll-checked": "Checked",
        "dxList-selectAll-notChecked": "Not checked",

        "dxScrollView-pullingDownText": "Puxar para baixo para recarregar...",
        "dxScrollView-pulledDownText": "Soltar para recarregar...",
        "dxScrollView-refreshingText": "Recarregando ...",
        "dxScrollView-reachBottomText": "A carregar ...",

        "dxDateBox-simulatedDataPickerTitleTime": "Selecionar hora",
        "dxDateBox-simulatedDataPickerTitleDate": "Selecionar data",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Selecionar data e hora",
        "dxDateBox-validation-datetime": "Valor deve ser uma data ou hora",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Data de Início",
        "dxDateRangeBox-endDateLabel": "Data Final",

        "dxFileUploader-selectFile": "Selecionar arquivo",
        "dxFileUploader-dropFile": "ou Soltar arquivo aqui",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Upload",
        "dxFileUploader-uploaded": "Upload concluído",
        "dxFileUploader-readyToUpload": "Pronto para upload",
        "dxFileUploader-uploadAbortedMessage": "Upload cancelado",
        "dxFileUploader-uploadFailedMessage": "Upload falhou",
        "dxFileUploader-invalidFileExtension": "Tipo de arquivo não é permitido",
        "dxFileUploader-invalidMaxFileSize": "O arquivo é muito grande",
        "dxFileUploader-invalidMinFileSize": "O arquivo é muito pequeno",

        "dxRangeSlider-ariaFrom": "De {0}",
        "dxRangeSlider-ariaTill": "Até {0}",
        "dxSwitch-switchedOnText": "LIGADO",
        "dxSwitch-switchedOffText": "DESLIGADO",

        "dxForm-optionalMark": "opcional",
        "dxForm-requiredMessage": "{0} é de preenchimento obrigatório",

        "dxNumberBox-invalidValueMessage": "Valor deve ser um número",
        "dxNumberBox-noDataText": "Sem dados",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} para exibir colunas",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Arraste uma coluna do painel do grupo aqui",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} ou arraste uma coluna do painel do grupo",
        "dxDataGrid-emptyHeaderColumnChooserText": "seletor de coluna",
        "dxDataGrid-columnChooserTitle": "Seletor de Colunas",
        "dxDataGrid-columnChooserEmptyText": "Arraste uma coluna para até aqui para escondê-la",
        "dxDataGrid-groupContinuesMessage": "Continua na página seguinte",
        "dxDataGrid-groupContinuedMessage": "Continuação da página anterior",
        "dxDataGrid-groupHeaderText": "Agrupar pela coluna",
        "dxDataGrid-ungroupHeaderText": "Remover grupo",
        "dxDataGrid-ungroupAllText": "Remover todos os grupos",
        "dxDataGrid-editingEditRow": "Editar",
        "dxDataGrid-editingSaveRowChanges": "Salvar",
        "dxDataGrid-editingCancelRowChanges": "Cancelar",
        "dxDataGrid-editingDeleteRow": "Eliminar",
        "dxDataGrid-editingUndeleteRow": "Recuperar",
        "dxDataGrid-editingConfirmDeleteMessage": "Tem certeza que deseja eliminar este registro?",
        "dxDataGrid-validationCancelChanges": "Cancelar alterações",
        "dxDataGrid-groupPanelEmptyText": "Arrastar o cabeçalho de uma coluna para aqui para agrupar por essa coluna",
        "dxDataGrid-noDataText": "Sem dados",
        "dxDataGrid-searchPanelPlaceholder": "Pesquisar ...",
        "dxDataGrid-filterRowShowAllText": "(Todos)",
        "dxDataGrid-filterRowResetOperationText": "Limpar",
        "dxDataGrid-filterRowOperationEquals": "Igual",
        "dxDataGrid-filterRowOperationNotEquals": "Diferente",
        "dxDataGrid-filterRowOperationLess": "Menor que",
        "dxDataGrid-filterRowOperationLessOrEquals": "Menor que ou igual a",
        "dxDataGrid-filterRowOperationGreater": "Maior que",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Maior que ou igual a",
        "dxDataGrid-filterRowOperationStartsWith": "Começa com",
        "dxDataGrid-filterRowOperationContains": "Contém",
        "dxDataGrid-filterRowOperationNotContains": "Não contém",
        "dxDataGrid-filterRowOperationEndsWith": "Termina com",
        "dxDataGrid-filterRowOperationBetween": "Entre",
        "dxDataGrid-filterRowOperationBetweenStartText": "Início",
        "dxDataGrid-filterRowOperationBetweenEndText": "Fim",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Aplicar filtro",
        "dxDataGrid-trueText": "verdadeiro",
        "dxDataGrid-falseText": "falso",
        "dxDataGrid-sortingAscendingText": "Ordenar ascendentemente",
        "dxDataGrid-sortingDescendingText": "Ordenar descendentemente",
        "dxDataGrid-sortingClearText": "Limpar ordenação",
        "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
        "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
        "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
        "dxDataGrid-ariaSortIndex": "Sort index {0}",
        "dxDataGrid-editingSaveAllChanges": "Salvar todas as alterações",
        "dxDataGrid-editingCancelAllChanges": "Descartar alterações",
        "dxDataGrid-editingAddRow": "Adicionar uma linha",
        "dxDataGrid-summaryMin": "Mín: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Mín de {1} é {0}",
        "dxDataGrid-summaryMax": "Máx: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Máx de {1} é {0}",
        "dxDataGrid-summaryAvg": "Méd: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Média de {1} é {0}",
        "dxDataGrid-summarySum": "Soma: {0}",
        "dxDataGrid-summarySumOtherColumn": "Soma de {1} é {0}",
        "dxDataGrid-summaryCount": "Contagem: {0}",
        "dxDataGrid-columnFixingFix": "Fixar",
        "dxDataGrid-columnFixingUnfix": "Não fixar",
        "dxDataGrid-columnFixingLeftPosition": "À esquerda",
        "dxDataGrid-columnFixingRightPosition": "À direita",
        "dxDataGrid-exportTo": "Exportar para",
        "dxDataGrid-exportToExcel": "Exportar para Excel",
        "dxDataGrid-exporting": "Exportar...",
        "dxDataGrid-excelFormat": "Planilha Excel",
        "dxDataGrid-selectedRows": "Linhas selecionadas",
        "dxDataGrid-exportSelectedRows": "Exportar linhas selecionadas",
        "dxDataGrid-exportAll": "Exportar todos os dados",
        "dxDataGrid-headerFilterLabel": "Opções de filtro",
        "dxDataGrid-headerFilterIndicatorLabel": "Mostrar opções de filtro para coluna '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Vazio)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Cancelar",
        "dxDataGrid-ariaAdaptiveCollapse": "Ocultar dados adicionais",
        "dxDataGrid-ariaAdaptiveExpand": "Exibir dados adicionais",
        "dxDataGrid-ariaColumn": "Coluna",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Valor",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Filtro de célula",
        "dxDataGrid-ariaCollapse": "Contrair",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Expandir",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Grelha de dados",
        "dxDataGrid-ariaSearchInGrid": "Pesquisar na grade de dados",
        "dxDataGrid-ariaSelectAll": "Selecionar todos",
        "dxDataGrid-ariaSelectRow": "Selecionar linha",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Construtor de filtro",
        "dxDataGrid-filterPanelCreateFilter": "Criar filtro",
        "dxDataGrid-filterPanelClearFilter": "Limpar",
        "dxDataGrid-filterPanelFilterEnabledHint": "Habilitar o filtro",
        "dxDataGrid-masterDetail": "Cell with details",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaSearchInGrid": "Pesquisar na lista de árvores",
        "dxTreeList-ariaToolbar": "Barra de ferramentas da lista de árvores",
        "dxTreeList-editingAddRowToNode": "Adicionar",

        "dxPager-infoText": "Página {0} de {1} ({2} itens)",
        "dxPager-pagesCountText": "de",
        "dxPager-pageSize": "Itens por página: {0}",
        "dxPager-pageSizesAllText": "Todos",
        "dxPager-page": "Página {0}",
        "dxPager-prevPage": "Página anterior",
        "dxPager-nextPage": "Próxima página",
        "dxPager-ariaLabel": "Navegação na página",
        "dxPager-ariaPageSize": "Tamanho da página",
        "dxPager-ariaPageNumber": "Número de páginas",

        "dxPivotGrid-grandTotal": "Grande Total",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Seletor de Colunas",
        "dxPivotGrid-showFieldChooser": "Mostrar Seletor de Colunas",
        "dxPivotGrid-expandAll": "Expandir Tudo",
        "dxPivotGrid-collapseAll": "Contrair Tudo",
        "dxPivotGrid-sortColumnBySummary": "Ordenar \"{0}\" por esta Coluna",
        "dxPivotGrid-sortRowBySummary": "Ordenar \"{0}\" por esta Linha",
        "dxPivotGrid-removeAllSorting": "Remover Todas as Ordenações",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Campos de Linha",
        "dxPivotGrid-columnFields": "Campos de Coluna",
        "dxPivotGrid-dataFields": "Campos de Dados",
        "dxPivotGrid-filterFields": "Campos de Filtro",
        "dxPivotGrid-allFields": "Todos os Campos",
        "dxPivotGrid-columnFieldArea": "Arraste os campos de coluna até aqui",
        "dxPivotGrid-dataFieldArea": "Arraste os campos de dados até aqui",
        "dxPivotGrid-rowFieldArea": "Arraste os campos de linha até aqui",
        "dxPivotGrid-filterFieldArea": "Arraste os campos de filtro até aqui",

        "dxScheduler-editorLabelTitle": "Assunto",
        "dxScheduler-editorLabelStartDate": "Data de Início",
        "dxScheduler-editorLabelEndDate": "Data Final",
        "dxScheduler-editorLabelDescription": "Descrição",
        "dxScheduler-editorLabelRecurrence": "Repetir",

        "dxScheduler-openAppointment": "Abrir compromisso",

        "dxScheduler-recurrenceNever": "Nunca",
        "dxScheduler-recurrenceMinutely": "Minuciosamente",
        "dxScheduler-recurrenceHourly": "De hora em hora",
        "dxScheduler-recurrenceDaily": "Diariamente",
        "dxScheduler-recurrenceWeekly": "Semanalmente",
        "dxScheduler-recurrenceMonthly": "Mensalmente",
        "dxScheduler-recurrenceYearly": "Anualmente",

        "dxScheduler-recurrenceRepeatEvery": "Todos",
        "dxScheduler-recurrenceRepeatOn": "Repetir em",
        "dxScheduler-recurrenceEnd": "Fim da repetição",
        "dxScheduler-recurrenceAfter": "Depois de",
        "dxScheduler-recurrenceOn": "A",

        "dxScheduler-recurrenceRepeatMinutely": "minuto(s)",
        "dxScheduler-recurrenceRepeatHourly": "hora(s)",
        "dxScheduler-recurrenceRepeatDaily": "dia(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mês(es)",
        "dxScheduler-recurrenceRepeatYearly": "ano(s)",

        "dxScheduler-switcherDay": "Dia",
        "dxScheduler-switcherWeek": "Semana",
        "dxScheduler-switcherWorkWeek": "Dias úteis",
        "dxScheduler-switcherMonth": "Mês",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Linha de tempo Dia",
        "dxScheduler-switcherTimelineWeek": "Linha de tempo Semana",
        "dxScheduler-switcherTimelineWorkWeek": "Linha de tempo Dias úteis",
        "dxScheduler-switcherTimelineMonth": "Linha de tempo Mês",

        "dxScheduler-recurrenceRepeatOnDate": "na data",
        "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
        "dxScheduler-allDay": "Todo o dia",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "Deseja editar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Deseja eliminar só este compromisso ou a série toda?",

        "dxScheduler-confirmRecurrenceEditSeries": "Editar série",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar série",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Editar compromisso",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar compromisso",

        "dxScheduler-noTimezoneTitle": "Sem fuso horário",
        "dxScheduler-moreAppointments": "{0} mais",

        "dxCalendar-currentDay": "Today",
        "dxCalendar-currentMonth": "Current month",
        "dxCalendar-currentYear": "Current year",
        "dxCalendar-currentYearRange": "Current year range",
        "dxCalendar-todayButtonText": "Hoje",
        "dxCalendar-ariaWidgetName": "Calendário",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",
        "dxCalendar-selectedDate": "The selected date is {0}",
        "dxCalendar-selectedDateRange": "The selected date range is from {0} to {1}",

        "dxColorView-ariaRed": "Vermelho",
        "dxColorView-ariaGreen": "Verde",
        "dxColorView-ariaBlue": "Azul",
        "dxColorView-ariaAlpha": "Transparência",
        "dxColorView-ariaHex": "Código de cor",

        "dxTagBox-selected": "{0} selecionados",
        "dxTagBox-allSelected": "Todos selecionados ({0})",
        "dxTagBox-moreSelected": "{0} mais",
        "dxTagBox-tagRoleDescription": "Tag. Press the delete button to remove this tag",

        "vizExport-printingButtonText": "Imprimir",
        "vizExport-titleMenuText": "Exportar/Imprimir",
        "vizExport-exportButtonText": "{0}-Arquivo",

        "dxFilterBuilder-and": "E",
        "dxFilterBuilder-or": "OU",
        "dxFilterBuilder-notAnd": "NÃO E",
        "dxFilterBuilder-notOr": "NÃO OU",
        "dxFilterBuilder-addCondition": "Adicionar condição",
        "dxFilterBuilder-addGroup": "Adicionar Grupo",
        "dxFilterBuilder-enterValueText": "<preencha com um valor>",
        "dxFilterBuilder-filterOperationEquals": "Igual",
        "dxFilterBuilder-filterOperationNotEquals": "Diferente",
        "dxFilterBuilder-filterOperationLess": "Menor que",
        "dxFilterBuilder-filterOperationLessOrEquals": "Menor ou igual que",
        "dxFilterBuilder-filterOperationGreater": "Maior que",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Maior ou igual que",
        "dxFilterBuilder-filterOperationStartsWith": "Começa com",
        "dxFilterBuilder-filterOperationContains": "Contém",
        "dxFilterBuilder-filterOperationNotContains": "Não contém",
        "dxFilterBuilder-filterOperationEndsWith": "Termina com",
        "dxFilterBuilder-filterOperationIsBlank": "É vazio",
        "dxFilterBuilder-filterOperationIsNotBlank": "Não é vazio",
        "dxFilterBuilder-filterOperationBetween": "Entre",
        "dxFilterBuilder-filterOperationAnyOf": "Algum de",
        "dxFilterBuilder-filterOperationNoneOf": "Nenhum de",

        "dxHtmlEditor-dialogColorCaption": "Alterar cor da fonte",
        "dxHtmlEditor-dialogBackgroundCaption": "Alterar cor de plano de fundo",
        "dxHtmlEditor-dialogLinkCaption": "Adicionar link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Texto",
        "dxHtmlEditor-dialogLinkTargetField": "Abrir link em nova janela",
        "dxHtmlEditor-dialogImageCaption": "Adicionar imagem",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Texto alternativo",
        "dxHtmlEditor-dialogImageWidthField": "Largura (px)",
        "dxHtmlEditor-dialogImageHeightField": "Altura (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Linhas",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Colunas",
        "dxHtmlEditor-dialogInsertTableCaption": "Inserir tabela",
        "dxHtmlEditor-dialogUpdateImageCaption": "Atualizar imagem",
        "dxHtmlEditor-dialogImageUpdateButton": "Atualizar",
        "dxHtmlEditor-dialogImageAddButton": "Adicionar",
        "dxHtmlEditor-dialogImageSpecifyUrl": "Da web",
        "dxHtmlEditor-dialogImageSelectFile": "Deste dispositivo",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Manter a proporção",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Codificar para Base64",
        "dxHtmlEditor-heading": "Cabeçalho",
        "dxHtmlEditor-normalText": "Texto normal",
        "dxHtmlEditor-background": "Cor de fundo",
        "dxHtmlEditor-bold": "Negrito",
        "dxHtmlEditor-color": "Cor da fonte",
        "dxHtmlEditor-font": "Fonte",
        "dxHtmlEditor-italic": "Itálico",
        "dxHtmlEditor-link": "Adicionar link",
        "dxHtmlEditor-image": "Adicionar imagem",
        "dxHtmlEditor-size": "Tamanho",
        "dxHtmlEditor-strike": "Tachado",
        "dxHtmlEditor-subscript": "Subscrito",
        "dxHtmlEditor-superscript": "Sobrescrito",
        "dxHtmlEditor-underline": "Sublinhado",
        "dxHtmlEditor-blockquote": "Bloco de citação",
        "dxHtmlEditor-header": "Cabeçalho",
        "dxHtmlEditor-increaseIndent": "Aumentar recuo",
        "dxHtmlEditor-decreaseIndent": "Diminuir recuo",
        "dxHtmlEditor-orderedList": "Lista ordenada",
        "dxHtmlEditor-bulletList": "Lista de marcadores",
        "dxHtmlEditor-alignLeft": "Alinhar à esquerda",
        "dxHtmlEditor-alignCenter": "Alinhar no centro",
        "dxHtmlEditor-alignRight": "Alinhar à direita",
        "dxHtmlEditor-alignJustify": "Justificar",
        "dxHtmlEditor-codeBlock": "Bloco de código",
        "dxHtmlEditor-variable": "Adicionar variável",
        "dxHtmlEditor-undo": "Desfazer",
        "dxHtmlEditor-redo": "Refazer",
        "dxHtmlEditor-clear": "Limpar formatação",
        "dxHtmlEditor-insertTable": "Inserir tabela",
        "dxHtmlEditor-insertHeaderRow": "Inserir linha de cabeçalho",
        "dxHtmlEditor-insertRowAbove": "Inserir linha acima",
        "dxHtmlEditor-insertRowBelow": "Inserir linha abaixo",
        "dxHtmlEditor-insertColumnLeft": "Inserir coluna à esquerda",
        "dxHtmlEditor-insertColumnRight": "Inserir coluna à direita",
        "dxHtmlEditor-deleteColumn": "Deletar coluna",
        "dxHtmlEditor-deleteRow": "Deletar linha",
        "dxHtmlEditor-deleteTable": "Deletar tabela",
        "dxHtmlEditor-cellProperties": "Propriedades da célula",
        "dxHtmlEditor-tableProperties": "Propriedades da tabela",
        "dxHtmlEditor-insert": "Inserir",
        "dxHtmlEditor-delete": "Deletar",
        "dxHtmlEditor-border": "Bordas",
        "dxHtmlEditor-style": "Estilo",
        "dxHtmlEditor-width": "Largura",
        "dxHtmlEditor-height": "Altura",
        "dxHtmlEditor-borderColor": "Cor",
        "dxHtmlEditor-borderWidth": "Border Width",
        "dxHtmlEditor-tableBackground": "Fundo",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alinhamento",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Preenchimento vertical",
        "dxHtmlEditor-paddingHorizontal": "Preenchimento horizontal",
        "dxHtmlEditor-pixels": "Píxeis",
        "dxHtmlEditor-list": "Lista",
        "dxHtmlEditor-ordered": "Ordenado",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Alinhar",
        "dxHtmlEditor-center": "Centro",
        "dxHtmlEditor-left": "Esquerda",
        "dxHtmlEditor-right": "Direita",
        "dxHtmlEditor-indent": "Recuar",
        "dxHtmlEditor-justify": "Justificar",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Diretório sem título",
        "dxFileManager-rootDirectoryName": "Arquivos",
        "dxFileManager-errorNoAccess": "Acesso negado. A operação não pôde ser concluída.",
        "dxFileManager-errorDirectoryExistsFormat": "O diretório '{0}' já existe.",
        "dxFileManager-errorFileExistsFormat": "O arquivo '{0}' já existe.",
        "dxFileManager-errorFileNotFoundFormat": "Arquivo '{0}' não encontrado.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Diretório '{0}' não encontrado.",
        "dxFileManager-errorWrongFileExtension": "A extensão do arquivo não é permitida.",
        "dxFileManager-errorMaxFileSizeExceeded": "O tamanho do arquivo excede o tamanho máximo permitido.",
        "dxFileManager-errorInvalidSymbols": "Este nome contém caracteres inválidos.",
        "dxFileManager-errorDefault": "Erro não especificado.",
        "dxFileManager-errorDirectoryOpenFailed": "O diretório não pode ser aberto",

        "dxFileManager-commandCreate": "Novo diretório",
        "dxFileManager-commandRename": "Renomear",
        "dxFileManager-commandMove": "Mover para",
        "dxFileManager-commandCopy": "Copiar para",
        "dxFileManager-commandDelete": "Deletar",
        "dxFileManager-commandDownload": "Baixar",
        "dxFileManager-commandUpload": "Fazer upload de arquivos",
        "dxFileManager-commandRefresh": "Atualizar",
        "dxFileManager-commandThumbnails": "Exibir miniaturas",
        "dxFileManager-commandDetails": "Ver detalhes",
        "dxFileManager-commandClearSelection": "Limpar seleção",
        "dxFileManager-commandShowNavPane": "Alternar painel de navegação",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Mover para",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Mover",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Copiar para",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copiar",
        "dxFileManager-dialogRenameItemTitle": "Renomear",
        "dxFileManager-dialogRenameItemButtonText": "Salvar",
        "dxFileManager-dialogCreateDirectoryTitle": "Novo diretório",
        "dxFileManager-dialogCreateDirectoryButtonText": "Criar",
        "dxFileManager-dialogDeleteItemTitle": "Deletar",
        "dxFileManager-dialogDeleteItemButtonText": "Deletar",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Tem certeza de que deseja excluir {0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Tem certeza de que deseja excluir {0} itens?",
        "dxFileManager-dialogButtonCancel": "Cancelar",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Criando um diretório dentro de {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Criou um diretório dentro de {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "O diretório não foi criado",
        "dxFileManager-editingCreateCommonErrorMessage": "O diretório não foi criado",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Renomeando um item dentro de {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Renomeou um item dentro de {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "O item não foi renomeado",
        "dxFileManager-editingRenameCommonErrorMessage": "O item não foi renomeado",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Excluindo um item de {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Excluindo {0} itens de {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Excluiu um item de {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "{0} itens excluídos de {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "O item não foi excluído",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} itens não foram excluídos",
        "dxFileManager-editingDeleteCommonErrorMessage": "Alguns itens não foram excluídos",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Movendo um item para {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Movendo {0} itens para {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Moveu um item para {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Movidos {0} itens para {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "O item não foi movido",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} itens não foram movidos",
        "dxFileManager-editingMoveCommonErrorMessage": "Alguns itens não foram movidos",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copiando um item para {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copiando {0} itens para {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copiou um item para {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copiou {0} itens para {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "O item não foi copiado",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} itens não foram copiados",
        "dxFileManager-editingCopyCommonErrorMessage": "Alguns itens não foram copiados",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Carregando um item para {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Fazendo upload de {0} itens para {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Carregou um item para {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Carregado {0} itens para {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "O item não foi carregado",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} itens não foram carregados",
        "dxFileManager-editingUploadCanceledMessage": "Cancelado",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "O item não foi baixado",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} itens não foram baixados",

        "dxFileManager-listDetailsColumnCaptionName": "Nome",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Data de modificação",
        "dxFileManager-listDetailsColumnCaptionFileSize": "Tamanho do arquivo",

        "dxFileManager-listThumbnailsTooltipTextSize": "Tamanho",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Data de modificação",

        "dxFileManager-notificationProgressPanelTitle": "Progresso",
        "dxFileManager-notificationProgressPanelEmptyListText": "Nenhuma operação",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Cancelado",

        "dxDiagram-categoryGeneral": "Geral",
        "dxDiagram-categoryFlowchart": "Fluxograma",
        "dxDiagram-categoryOrgChart": "Organograma",
        "dxDiagram-categoryContainers": "Contêineres",
        "dxDiagram-categoryCustom": "Personalizado",

        "dxDiagram-commandExportToSvg": "Exportar para SVG",
        "dxDiagram-commandExportToPng": "Exportar para PNG",
        "dxDiagram-commandExportToJpg": "Exportar para JPG",
        "dxDiagram-commandUndo": "Desfazer",
        "dxDiagram-commandRedo": "Refazer",
        "dxDiagram-commandFontName": "Nome da fonte",
        "dxDiagram-commandFontSize": "Tamanho da fonte",
        "dxDiagram-commandBold": "Negrito",
        "dxDiagram-commandItalic": "Itálico",
        "dxDiagram-commandUnderline": "Sublinhado",
        "dxDiagram-commandTextColor": "Cor da fonte",
        "dxDiagram-commandLineColor": "Cor da linha",
        "dxDiagram-commandLineWidth": "Espessura da linha",
        "dxDiagram-commandLineStyle": "Estilo da linha",
        "dxDiagram-commandLineStyleSolid": "Sólido",
        "dxDiagram-commandLineStyleDotted": "Pontilhado",
        "dxDiagram-commandLineStyleDashed": "Tracejado",
        "dxDiagram-commandFillColor": "Cor de preenchimento",
        "dxDiagram-commandAlignLeft": "Alinhar à esquerda",
        "dxDiagram-commandAlignCenter": "Centralizar horizontalmente",
        "dxDiagram-commandAlignRight": "Alinhar à direita",
        "dxDiagram-commandConnectorLineType": "Tipo de conexão",
        "dxDiagram-commandConnectorLineStraight": "Reto",
        "dxDiagram-commandConnectorLineOrthogonal": "Ortogonal",
        "dxDiagram-commandConnectorLineStart": "Conector de início de linha",
        "dxDiagram-commandConnectorLineEnd": "Conector de fim de linha",
        "dxDiagram-commandConnectorLineNone": "Nenhum",
        "dxDiagram-commandConnectorLineArrow": "Flecha",
        "dxDiagram-commandFullscreen": "Tela cheia",
        "dxDiagram-commandUnits": "Unidades",
        "dxDiagram-commandPageSize": "Tamanho da página",
        "dxDiagram-commandPageOrientation": "Orientação",
        "dxDiagram-commandPageOrientationLandscape": "Paiagem",
        "dxDiagram-commandPageOrientationPortrait": "Retrato",
        "dxDiagram-commandPageColor": "Cor da página",
        "dxDiagram-commandShowGrid": "Mostrar grade",
        "dxDiagram-commandSnapToGrid": "Ajustar à grade",
        "dxDiagram-commandGridSize": "Tamanho da grade",
        "dxDiagram-commandZoomLevel": "Nível de zoom",
        "dxDiagram-commandAutoZoom": "Zoom automático",
        "dxDiagram-commandFitToContent": "Ajustar ao conteúdo",
        "dxDiagram-commandFitToWidth": "Ajustar à largura",
        "dxDiagram-commandAutoZoomByContent": "Zoom automático por conteúdo",
        "dxDiagram-commandAutoZoomByWidth": "Zoom automático por largura",
        "dxDiagram-commandSimpleView": "Visualização simples",
        "dxDiagram-commandCut": "Cortar",
        "dxDiagram-commandCopy": "Copiar",
        "dxDiagram-commandPaste": "Colar",
        "dxDiagram-commandSelectAll": "Selecionar tudo",
        "dxDiagram-commandDelete": "Remover",
        "dxDiagram-commandBringToFront": "Trazer para a frente",
        "dxDiagram-commandSendToBack": "Enviar para o fundo",
        "dxDiagram-commandLock": "Bloquear",
        "dxDiagram-commandUnlock": "Desbloquear",
        "dxDiagram-commandInsertShapeImage": "Inserir imagem...",
        "dxDiagram-commandEditShapeImage": "Alterar imagem...",
        "dxDiagram-commandDeleteShapeImage": "Remover imagem",
        "dxDiagram-commandLayoutLeftToRight": "Da esquerda para a direita",
        "dxDiagram-commandLayoutRightToLeft": "Da direita para a esquerda",
        "dxDiagram-commandLayoutTopToBottom": "De cima para baixo",
        "dxDiagram-commandLayoutBottomToTop": "De baixo para cima",

        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "Aceitar",
        "dxDiagram-dialogButtonCancel": "Cancelar",
        "dxDiagram-dialogInsertShapeImageTitle": "Inserir imagem",
        "dxDiagram-dialogEditShapeImageTitle": "Alterar imagem",
        "dxDiagram-dialogEditShapeImageSelectButton": "Selecionar imagem",
        "dxDiagram-dialogEditShapeImageLabelText": "ou arraste um arquivo aqui",

        "dxDiagram-uiExport": "Exportar",
        "dxDiagram-uiProperties": "Propriedades",
        "dxDiagram-uiSettings": "Configurações",
        "dxDiagram-uiShowToolbox": "Exibir ferramentas",
        "dxDiagram-uiSearch": "Pesquisar",
        "dxDiagram-uiStyle": "Estilo",
        "dxDiagram-uiLayout": "Leiaute",
        "dxDiagram-uiLayoutTree": "Árvores",
        "dxDiagram-uiLayoutLayered": "Níveis",
        "dxDiagram-uiDiagram": "Diagrama",
        "dxDiagram-uiText": "Texto",
        "dxDiagram-uiObject": "Objeto",
        "dxDiagram-uiConnector": "Conector",
        "dxDiagram-uiPage": "Página",

        "dxDiagram-shapeText": "Texto",
        "dxDiagram-shapeRectangle": "Retângulo",
        "dxDiagram-shapeEllipse": "Elipse",
        "dxDiagram-shapeCross": "Cruz",
        "dxDiagram-shapeTriangle": "Triângulo",
        "dxDiagram-shapeDiamond": "Diamante",
        "dxDiagram-shapeHeart": "Coração",
        "dxDiagram-shapePentagon": "Pentágono",
        "dxDiagram-shapeHexagon": "Hexágono",
        "dxDiagram-shapeOctagon": "Octógono",
        "dxDiagram-shapeStar": "Estrela",
        "dxDiagram-shapeArrowLeft": "Flecha à esquerda",
        "dxDiagram-shapeArrowUp": "Flecha para cima",
        "dxDiagram-shapeArrowRight": "Flecha à direita",
        "dxDiagram-shapeArrowDown": "Flecha para baixo",
        "dxDiagram-shapeArrowUpDown": "Flecha para cima e para baixo",
        "dxDiagram-shapeArrowLeftRight": "Flecha à esquerda e direita",
        "dxDiagram-shapeProcess": "Processo",
        "dxDiagram-shapeDecision": "Decisão",
        "dxDiagram-shapeTerminator": "Exterminador",
        "dxDiagram-shapePredefinedProcess": "Processo predefinido",
        "dxDiagram-shapeDocument": "Documento",
        "dxDiagram-shapeMultipleDocuments": "Vários documentos",
        "dxDiagram-shapeManualInput": "Entrada manual",
        "dxDiagram-shapePreparation": "Preparação",
        "dxDiagram-shapeData": "Dados",
        "dxDiagram-shapeDatabase": "Base de dados",
        "dxDiagram-shapeHardDisk": "Disco rígido",
        "dxDiagram-shapeInternalStorage": "Memória interna",
        "dxDiagram-shapePaperTape": "Fita de papel",
        "dxDiagram-shapeManualOperation": "Operação manual",
        "dxDiagram-shapeDelay": "Atraso",
        "dxDiagram-shapeStoredData": "Dados armazenados",
        "dxDiagram-shapeDisplay": "Tela",
        "dxDiagram-shapeMerge": "Fusão",
        "dxDiagram-shapeConnector": "Conector",
        "dxDiagram-shapeOr": "Ou",
        "dxDiagram-shapeSummingJunction": "Junção de soma",
        "dxDiagram-shapeContainerDefaultText": "Contêiner",
        "dxDiagram-shapeVerticalContainer": "Contêiner vertical",
        "dxDiagram-shapeHorizontalContainer": "Contêiner horizontal",
        "dxDiagram-shapeCardDefaultText": "Nome",
        "dxDiagram-shapeCardWithImageOnLeft": "Cartão com imagem à esquerda",
        "dxDiagram-shapeCardWithImageOnTop": "Cartão com imagem na parte superior",
        "dxDiagram-shapeCardWithImageOnRight": "Cartão com imagem à direita",

        "dxGantt-dialogTitle": "Título",
        "dxGantt-dialogStartTitle": "Iniciar",
        "dxGantt-dialogEndTitle": "Encerrar",
        "dxGantt-dialogProgressTitle": "Progresso",
        "dxGantt-dialogResourcesTitle": "Recursos",
        "dxGantt-dialogResourceManagerTitle": "Gerenciador de recursos",
        "dxGantt-dialogTaskDetailsTitle": "Detalhes da tarefa",
        "dxGantt-dialogEditResourceListHint": "Editar lista de recursos",
        "dxGantt-dialogEditNoResources": "Sem recursos",
        "dxGantt-dialogButtonAdd": "Adicionar",
        "dxGantt-contextMenuNewTask": "Nova tarefa",
        "dxGantt-contextMenuNewSubtask": "Nova subtarefa",
        "dxGantt-contextMenuDeleteTask": "Excluir tarefa",
        "dxGantt-contextMenuDeleteDependency": "Excluir dependência",
        "dxGantt-dialogTaskDeleteConfirmation": "Excluir uma tarefa também exclui suas dependências e subtarefas. Você tem certeza que quer excluir essa tarefa?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Você tem certeza que quer excluir a dependência desta tarefa?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Ao excluir o recurso, ele também será excluido das tarefas em que está atribuído. Tem certeza que quer excluir estes recursos? Recurso: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "A tarefa a ser movida está ligada a uma outra tarefa, por uma relação de dependência. Esta alteração conflita com as regras de dependências. Como você gostaria de proceder?",
        "dxGantt-dialogConstraintViolationMessage": "A tarefa a ser movida está ligada a uma outra tarefa, por uma relação de dependência. Como você gostaria de proceder?",
        "dxGantt-dialogCancelOperationMessage": "Cancelar operação",
        "dxGantt-dialogDeleteDependencyMessage": "Excluir a dependência",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Mover a tarefa e manter a dependência",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "A tarefa que você está tentando mover está vinculada a outras tarefas por relações de dependência. Essa alteração entraria em conflito com as regras de dependência. Como você gostaria de proceder?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "A tarefa que você está tentando mover está vinculada a outras tarefas por relações de dependência. Como você gostaria de proceder?",
        "dxGantt-dialogDeleteDependenciesMessage": "Exclua as relações de dependência",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Mova a tarefa e mantenha as dependências",
        "dxGantt-undo": "Desfazer",
        "dxGantt-redo": "Refazer",
        "dxGantt-expandAll": "Expandir tudo",
        "dxGantt-collapseAll": "Contrair tudo",
        "dxGantt-addNewTask": "Nova tarefa",
        "dxGantt-deleteSelectedTask": "Excluir tarefas selecionadas",
        "dxGantt-zoomIn": "Aumentar zoom",
        "dxGantt-zoomOut": "Diminuir zoom",
        "dxGantt-fullScreen": "Tela cheia",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Ordenar ascendentemente",
        "dxGantt-sortingDescendingText": "Ordenar descendentemente",
        "dxGantt-sortingClearText": "Limpar ordenação",
        "dxGantt-showResources": "Mostrar recursos",
        "dxGantt-showDependencies": "Mostrar dependências",
        "dxGantt-dialogStartDateValidation": "A data de início deve ser após {0}",
        "dxGantt-dialogEndDateValidation": "A data de término deve ser posterior a {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}",

        "dxSplitter-resizeHandleAriaLabel": "Split bar"
    }
}
