/**
 * CentralGest Amalia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArticleCategory = 'CG_CLOUD' | 'CG_WINDOWS' | 'ANALISADOR_SAFT' | 'SUPORTE_INTERNO';

export const ArticleCategory = {
    CgCloud: 'CG_CLOUD' as ArticleCategory,
    CgWindows: 'CG_WINDOWS' as ArticleCategory,
    AnalisadorSaft: 'ANALISADOR_SAFT' as ArticleCategory,
    SuporteInterno: 'SUPORTE_INTERNO' as ArticleCategory
};

