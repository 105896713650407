import {EMascaraAnaliticaTipoMascara} from '../mascarasanalitica/mascaraAnalitica.entity.interface';
import {ENTITY_NAME_REPCC, ENTITY_NAME_REPCC_FATURACAO, ENTITY_NAME_REPCC_SALARIOS, IReparticoesCCustoEntity} from './reparticoesCCusto.entity.interface';
import {ReparticoesCCustoEditComponent} from './components/edit/reparticoesCCusto.entity.edit.component';
import {ROLE} from '../../services/role.const';
import {TDate} from '../../../common/dates';

export const ENTITY_REPCC: IReparticoesCCustoEntity = generateEntity(ENTITY_NAME_REPCC);

export const ENTITY_REPCC_FATURACAO: IReparticoesCCustoEntity = generateEntity(ENTITY_NAME_REPCC_FATURACAO, EMascaraAnaliticaTipoMascara.Facturacao);

export const ENTITY_REPCC_SALARIOS: IReparticoesCCustoEntity = generateEntity(ENTITY_NAME_REPCC_SALARIOS, EMascaraAnaliticaTipoMascara.Salarios);

function generateEntity(name: string, tipoMascara?: EMascaraAnaliticaTipoMascara): IReparticoesCCustoEntity {
  return {
    name: name,
    asModule: name !== ENTITY_NAME_REPCC,
    url: 'reparticoesccustos',
    roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.RH],
    searchPlaceholder: 'reparticoesccustos.pesquisa',
    sidebarTitle: `portals.sidebar.modules.${name}`,
    metadata: {
      keyName: 'nCodRepCC',
      fields: [
        {name: 'nCodRepCC', type: 'cgsmallint', caption: 'reparticoesccustos.fields.nCodRepCC'},
        {name: 'descricao', caption: 'reparticoesccustos.fields.descricao', validators: {maxlength: 150}},
        {name: 'mascara', visible: false},
        {
          name: 'nomeMascara',
          caption: 'reparticoesccustos.fields.mascara',
          entity: {name: 'mascarasanalitica', keyTarget: 'mascara', outputTarget: 'nomeMascara', output: 'mascara', outputKey: 'mascara'}
        }
      ],
      order: 'nCodRepCC',
      searchFields: 'nCodRepCC,descricao',
      listFields: 'nCodRepCC,descricao,nomeValorMascara,perc,dataReferencia'
    },
    autocomplete: {
      rowTemplate: '{{nCodRepCC}} - {{descricao}}',
      output: 'descricao',
      searchFields: 'nCodRepCC,descricao'
    },
    actions: {
      new: true,
      detail: true,
      edit: true,
      delete: false,
      search: true,
      filter: true
    },
    detail: {
      state: {
        component: ReparticoesCCustoEditComponent,
        data: {
          tipoMascara: tipoMascara
        }
      }
    },
    service: function () {
      this.validateCodTabela = (nCodRepCC: number) => {
        return this.get({id: `${nCodRepCC}/validate`});
      };

      this.apagarDataReferencia = (nCodRepCC: number, dataReferencia: TDate) => {
        return this.delete({url: `${nCodRepCC}/apagardatareferencia`, params: {datareferencia: dataReferencia}});
      };
    }
  };
}
