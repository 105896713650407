import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../../../translate/translate.module';
import {CGCIconDirective} from '../../../icon';

import {PlDynamicVisualsSecondaryClickComponent} from './component/dynamicvisuals.secondaryclick.component';

export * from './dynamicvisuals.secondaryclick.interface';
export * from './component/dynamicvisuals.secondaryclick.component.interface';
export * from './component/dynamicvisuals.secondaryclick.component';
export * from './services/dynamicvisuals.secondaryclick.config.service';
export * from './services/dynamicvisuals.secondaryclick.service';

const DECLARATIONS = [PlDynamicVisualsSecondaryClickComponent];

@NgModule({
  imports: [CommonModule, PlTranslateModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlDynamicVisualsSecondaryClickModule {}
