<div class="rhagencias-agencia-modal entity-detail-form">
  <pl-form plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'rhagencias.modal.agencia.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <label [translate]="'rhagencias.modal.agencia.fields.banco'"></label>
        <edit>
          <div class="form-control-align">{{ model.codBanco }} - {{ model.nomeBanco }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhagencias.modal.agencia.fields.codAgencia'"></label>
        <edit>
          <pl-edit type="text" attrName="codAgencia" [(model)]="model.codAgencia" [properties]="{disabled: isUpdate, validators: {required: {value: !isUpdate}}}" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.descBreve'"></label>
          <edit>
            <pl-edit type="text" attrName="designaBreve" [(model)]="model.designaBreve" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.morada'"></label>
          <edit>
            <pl-edit type="text" attrName="morada" [(model)]="model.morada"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.fax'"></label>
          <edit>
            <pl-edit type="text" attrName="fax" [(model)]="model.fax"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.localidade'"></label>
          <edit>
            <entity-autocomplete
              entity="local"
              attrName="localidade"
              [model]="model"
              (selectedKeyChange)="model.localidade = $event"
              (evtSelectedDescriptionChanged)="model.nomeLocalidade = $event"
              [fieldsMap]="{nLocalidade: 'localidade', designaBreve: 'nomeLocalidade'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.codPostal'"></label>
          <edit>
            <entity-autocomplete
              entity="codpostais"
              attrName="codPostal"
              [(selectedKey)]="model.codPostal"
              (evtSelectedDescriptionChanged)="model.nomeCodPostal = $event"
              [fieldsMap]="{localidade: 'nomeCodPostal'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.telefone'"></label>
          <edit>
            <pl-edit type="text" attrName="telefone" [(model)]="model.telefone"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.contacto'"></label>
          <edit>
            <pl-edit type="text" attrName="contacto" [(model)]="model.contacto"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.contaagencia'"></label>
          <edit>
            <pl-edit type="text" attrName="nContAgencia" [(model)]="model.nContAgencia"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.agencia.fields.estado'"></label>
          <edit>
            <pl-edit type="estadoregisto" attrName="estado" [(model)]="model.estado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'rhagencias.modal.agencia.fields.tipoInstal'"></label>
        <edit>
          <pl-edit type="radiogroup" attrName="tipoInstal" [(model)]="model.tipoInstalac" [properties]="radioGroupTipoInstalacao"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button" plPromise>
      <pl-button klass="btn-success btn-sm action-save" (evtClicked)="close()" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

      <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
