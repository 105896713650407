<config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>
<div class="listagens">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.datade'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="filters.datade"></pl-edit-datepicker>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="filters.dataate"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="optionShowFilterNArtigo || optionShowFilterTipoArtigo">
            <pl-group *ngIf="optionShowFilterNArtigo">
              <pl-group>
                <label [translate]="'docsComerciaisEstatisticas.caption.artigoCaption'"></label>
                <edit>
                  <entity-autocomplete
                    entity="artigos"
                    attrName="nartigo"
                    [selectedKey]="filters.nartigo"
                    (selectedKeyChange)="nartigoChanged($event)"
                    [helperMode]="true"
                    [fieldsMap]="{nartigo: 'nartigo', nome: 'nome'}"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group *ngIf="optionShowFilterTipoArtigo">
              <label [translate]="'docsComerciaisEstatisticas.fields.nomeTipoArtigo'"></label>
              <edit>
                <pl-select
                  [(model)]="tipoArtigoList"
                  [multiple]="true"
                  [source]="tipoArtigoSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="tipoArtigoTemplate"
                  [valueField]="'codTpArt'"
                  [output]="tipoArtigoOutput"
                  appendTo="body">
                </pl-select>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="optionShowFilterCentroCusto || optionShowFilterClasse">
          <pl-group *ngIf="optionShowFilterCentroCusto">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ccustode'"></label>
              <edit>
                <entity-autocomplete
                  entity="ccustos"
                  attrName="ccustode"
                  [selectedKey]="filters.ccustode"
                  (selectedKeyChange)="ccustodeChanged($event)"
                  [helperMode]="true"
                  output="key"
                  [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="ccustos"
                  attrName="ccustoate"
                  [selectedKey]="filters.ccustoate"
                  (selectedKeyChange)="ccustoateChanged($event)"
                  [helperMode]="true"
                  output="key"
                  [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="optionShowFilterClasse">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.classede'"></label>
              <edit>
                <entity-autocomplete entity="clicls" attrName="cliclde" [selectedKey]="filters.cliclde" (selectedKeyChange)="cliclDeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="clicls" attrName="cliclAte" [selectedKey]="filters.cliclate" (selectedKeyChange)="cliclAteChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="optionShowFilterConta || optionShowFilterGrandeFamilia || optionShowFilterFamilia">
          <pl-group>
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ncontade'"></label>
              <edit>
                <entity-autocomplete entity="clifos" [filter]="nContaFilter" [(selectedKey)]="filters.ncontade" [helperMode]="true" output="key" plAutoFocus></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="clifos" [filter]="nContaFilter" [(selectedKey)]="filters.ncontaate" [helperMode]="true" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="optionShowFilterGrandeFamilia || optionShowFilterFamilia">
            <pl-group *ngIf="optionShowFilterGrandeFamilia">
              <label [translate]="'docsComerciaisEstatisticas.fields.nomeDepart'"></label>
              <edit>
                <pl-select
                  [(model)]="grandeFamiliaList"
                  [multiple]="true"
                  [source]="grandeFamiliaSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="grandeFamiliaTemplate"
                  [valueField]="'nDepart'"
                  [output]="grandeFamiliaOutput"
                  appendTo="body"></pl-select>
              </edit>
            </pl-group>
            <pl-group *ngIf="optionShowFilterFamilia">
              <label [translate]="'docsComerciaisEstatisticas.fields.nomeFamilia'"></label>
              <edit>
                <pl-select
                  [(model)]="familiaList"
                  [multiple]="true"
                  [source]="familiaSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="familiaTemplate"
                  [valueField]="'nFamilia'"
                  [output]="familiaOutput"
                  appendTo="body"></pl-select>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="optionShowFilterGrupoConta || optionShowFilterProcesso">
          <pl-group *ngIf="optionShowFilterGrupoConta">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ididechavde'"></label>
              <edit>
                <entity-autocomplete entity="idide" attrName="ididechavde" [selectedKey]="filters.ididechavde" (selectedKeyChange)="idIdeChavDeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="idide" attrName="ididechavate" [selectedKey]="filters.ididechavate" (selectedKeyChange)="idIdeChavAteChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="optionShowFilterProcesso">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nrefprocessode'"></label>
              <edit>
                <entity-autocomplete
                  entity="processos"
                  attrName="nrefprocessode"
                  [selectedKey]="filters.nrefprocessode"
                  (selectedKeyChange)="nrefprocessodeChanged($event)"
                  [helperMode]="true"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="processos"
                  attrName="nrefprocessoate"
                  [selectedKey]="filters.nrefprocessoate"
                  (selectedKeyChange)="nrefprocessoateChanged($event)"
                  [helperMode]="true"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="optionShowFilterSubFamilia || optionShowFilterDocfaList || optionShowFilterVendedor">
          <pl-group>
            <pl-group *ngIf="optionShowFilterSubFamilia">
              <label [translate]="'docsComerciaisEstatisticas.fields.nomeSubFamilia'"></label>
              <edit>
                <pl-select
                  [(model)]="subFamiliaList"
                  [multiple]="true"
                  [source]="subFamiliaSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="subFamiliaTemplate"
                  [valueField]="'nSubFa'"
                  [output]="subFamiliaOutput"
                  appendTo="body"></pl-select>
              </edit>
            </pl-group>
            <pl-group *ngIf="optionShowFilterDocfaList">
              <label [translate]="'docsComerciaisEstatisticas.caption.docFa'"></label>
              <edit>
                <pl-select
                  [(model)]="docfalist"
                  [multiple]="true"
                  [source]="docFaSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="docFaTemplate"
                  [valueField]="'nDocFa'"
                  [output]="docFaOutput"
                  appendTo="body"></pl-select>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="optionShowFilterVendedor">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nvendedorde'"></label>
              <edit>
                <entity-autocomplete
                  entity="vendedores"
                  attrName="nvendedorde"
                  [selectedKey]="filters.nvendedorde"
                  (selectedKeyChange)="nvendedordeChanged($event)"
                  [helperMode]="true"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="vendedores"
                  attrName="nvendedorate"
                  [selectedKey]="filters.nvendedorate"
                  (selectedKeyChange)="nvendedorateChanged($event)"
                  [helperMode]="true"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="optionShowFilterZona">
          <pl-group>
            <label [translate]="'docsComerciaisEstatisticas.caption.codzonade'"></label>
            <edit>
              <entity-autocomplete
                entity="zonas"
                attrName="codzonade"
                [selectedKey]="filters.codzonade"
                (selectedKeyChange)="codzonadeChanged($event)"
                [helperMode]="true"
                output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="zonas"
                attrName="codzonaate"
                [selectedKey]="filters.codzonaate"
                (selectedKeyChange)="codzonaateChanged($event)"
                [helperMode]="true"
                output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-sm btn-primary" id="btnPesquisar"
              ><i class="fa fa-search fa-fw"></i><span [translate]="'docsComerciaisEstatisticas.pesquisa'"></span>
            </pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <pl-tabs>
    <pl-tab id="geral" caption="global.text.general">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsComerciaisEstatDoc"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onCellClick)="onCellClick($event)"
          (onContentReady)="onContentReady()">
          <div *dxTemplate="let item of 'detailTemplateDocumento'">
            <documento-faturacao [doc]="item.data._doc" [actionGetDoc]="fnGetDoc" [actionPdf]="fnGetPdf" [actionAnexarGDocComercial]="fnAnexarGDocComercial"></documento-faturacao>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="cubos" caption="docsComerciaisEstatisticas.tabs.cubo">
      <div *plTabContent>
        <dx-pivot-grid
          id="pivotgridEstComDoc"
          [fieldChooser]="pivotGridDefinition.fieldChooser"
          [scrolling]="pivotGridDefinition.scrolling"
          [stateStoring]="pivotGridDefinition.stateStoring"
          [allowSortingBySummary]="pivotGridDefinition.allowSortingBySummary"
          [allowFiltering]="pivotGridDefinition.allowFiltering"
          [showBorders]="pivotGridDefinition.showBorders"
          [showRowGrandTotals]="pivotGridDefinition.showRowGrandTotals"
          [showRowTotals]="pivotGridDefinition.showRowTotals"
          [showColumnGrandTotals]="pivotGridDefinition.showColumnGrandTotals"
          [showColumnTotals]="pivotGridDefinition.showColumnTotals"
          [texts]="pivotGridDefinition.texts"
          [export]="pivotGridDefinition.export"
          [dataSource]="pivotGridDataSource"
          (onExporting)="onExporting($event)">
        </dx-pivot-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
