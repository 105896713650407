<div class="ativos">
  <ng-container [ngSwitch]="stateType">
    <div *ngSwitchCase="stateTypes.NEW">
      <pl-nav-wizard [definition]="definition" [beforeStepChange]="fnBeforeStepChange" [onFinalize]="onFinalize" (evtStepChange)="stepChanged($event)" (modelChange)="onStepChange($event)">
        <pl-nav-wizard-step caption="ativos.steps.aquisicaoAtivo" icon="fa-truck">
          <pl-form *plNavWizardStepContent (formInstanceChange)="formAquisicaoAtivo = $event">
            <h4 class="page-header" [ngClass]="{'header-new-space': !!isModalContas}"><label [translate]="'ativos.titles.dadosDoAtivo'"></label></h4>
            <pl-group>
              <pl-group>
                <label [translate]="'ativos.fields.codAtivo'"></label>
                <edit>
                  <pl-edit type="cginteger" attrName="codAtivo" [(model)]="model.codAtivo" [properties]="{readonly: true}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativos.fields.refExterna'"></label>
                <edit>
                  <pl-edit type="text" attrName="refExterna" [(model)]="model.refExterna" [properties]="{validators: {maxlength: {value: 30}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <label [translate]="'ativos.fields.designacao'"></label>
              <edit>
                <pl-edit type="text" attrName="designacao" [(model)]="model.designacao" [properties]="{validators: {maxlength: {value: 150}, required: {value: true}}}" plAutoFocus></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.codAmortizacao'"></label>
                <edit>
                  <entity-autocomplete
                    entity="amortizacao"
                    attrName="amortizacao"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}}"
                    [selectedKey]="model.aquisicao.codAmortizacao"
                    (selectedKeyChange)="model.aquisicao.codAmortizacao = $event"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeAmortizacao = $event"
                    [fieldsMap]="{nImtab: 'codAmortizacao', nome: 'nomeAmortizacao'}"
                    [output]="amortizacaoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nMapaAmortizacao'"></label>
                <edit>
                  <entity-autocomplete
                    entity="mapasamortizacao"
                    attrName="mapasAmortizacao"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 5}, required: {value: true}}}"
                    [(selectedKey)]="model.aquisicao.nMapaAmortizacao"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeMapaAmortizacao = $event"
                    [fieldsMap]="{nImmap: 'nMapaAmortizacao', nome: 'nomeMapaAmortizacao'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.dataAquisicao'"></label>
                <edit>
                  <pl-edit
                    type="date"
                    attrName="dataAquisicao"
                    [model]="model.aquisicao.dataAquisicao"
                    (modelChange)="dataAquisicaoChanged($event)"
                    [properties]="{validators: {required: {value: true}}}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.dataUtilizacao'"></label>
                <edit>
                  <pl-edit type="date" attrName="dataUtilizacao" [(model)]="model.aquisicao.dataUtilizacao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.quantidade'"></label>
                <edit>
                  <pl-edit type="number" attrName="quantidade" [(model)]="model.aquisicao.quantidade" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.usado'"></label>
                <edit>
                  <pl-edit type="boolean" attrName="usado" [(model)]="model.aquisicao.usado"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <h4 class="page-header"><label [translate]="'ativos.titles.dadosDoFornecedor'"></label></h4>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nDocExterno'"></label>
                <edit>
                  <pl-edit type="text" attrName="nDocExterno" [(model)]="model.aquisicao.nDocExterno" [properties]="{validators: {maxlength: {value: 60}}}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.dataDocExterno'"></label>
                <edit>
                  <pl-edit type="date" attrName="dataDocExterno" [(model)]="model.aquisicao.dataDocExterno" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nContaFornecedor'"></label>
                <edit>
                  <entity-autocomplete
                    entity="fornecedores"
                    attrName="fornecedores"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}}"
                    [(selectedKey)]="model.aquisicao.nContaFornecedor"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeFornecedor = $event"
                    [fieldsMap]="{nConta: 'nContaFornecedor', nome: 'nomeFornecedor'}"
                    [output]="fornecedoresOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nomeFornecedor'"></label>
                <edit>
                  <pl-edit type="text" attrName="nomeFornecedor" [(model)]="model.aquisicao.nomeFornecedor" [properties]="{validators: {maxlength: {value: 150}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativos.steps.dadosContabilisticosDadosFiscais" icon="fa-file-text-o">
          <pl-form *plNavWizardStepContent [properties]="{disabled: !!promise}" (formInstanceChange)="formDadosContabilisticosDadosFiscais = $event">
            <h4 class="page-header"><label [translate]="'ativos.titles.dadosContabilisticos'"></label></h4>

            <pl-group>
              <pl-group [hasError]="contabValorAquisicao > 0 && model.aquisicao.contabValorAquisicao > contabValorAquisicao">
                <label [translate]="'ativosAquisicao.fields.contabValorAquisicao'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="contabValorAquisicao"
                    [model]="model.aquisicao.contabValorAquisicao"
                    (modelChange)="model.aquisicao.contabValorAquisicao = $event; commandValoresContabilisticosEFiscais()"
                    [properties]="{modelOptions: {updateOn: 'blur'}, validators: {required: {value: true}, contabValorAquisicao: validatorValorAquisicao}}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabTaxaAmortiza'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="contabTaxaAmortiza"
                    [model]="model.aquisicao.contabTaxaAmortiza"
                    (modelChange)="model.aquisicao.contabTaxaAmortiza = $event; commandValidaTaxasCodAmortizacao()"
                    [properties]="{modelOptions: {updateOn: 'blur'}}">
                    <ng-container *ngIf="validaTaxaContab">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <pl-tooltip [config]="tooltipTaxa">
                          <i *plTooltipContent class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                        </pl-tooltip>
                      </div>
                    </ng-container>
                  </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabNumAnosUteis'"></label>
                <edit>
                  <pl-edit type="number" attrName="contabNumAnosUteis" [properties]="{disabled: true}" [(model)]="model.aquisicao.contabNumAnosUteis"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabPercReducao'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="contabPercReducao"
                    [model]="model.aquisicao.contabPercReducao"
                    (modelChange)="model.aquisicao.contabPercReducao = $event; commandValidaTaxasCodAmortizacao()"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabValorResidual'"></label>
                <edit>
                  <pl-edit type="number" attrName="contabValorResidual" [(model)]="model.aquisicao.contabValorResidual"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label class="d-flex">
                  <span [translate]="'ativosAquisicao.fields.contabDataDispUso'"></span>
                  <pl-tooltip class="tooltip-info-label" [config]="{text: 'ativosAquisicao.tooltip.contabDataDispUso', placement: ['bottom', 'left']}">
                    <i *plTooltipContent class="fa fa-fw fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit type="date" attrName="contabDataDispUso" [(model)]="model.aquisicao.contabDataDispUso" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabBaseCalculo'"></label>
                <edit>
                  <pl-edit
                    type="radiogroup"
                    attrName="contabBaseCalculo"
                    [model]="model.aquisicao.contabBaseCalculo"
                    (modelChange)="model.aquisicao.contabBaseCalculo = $event; commandDataPorBaseParaCalculo()"
                    [properties]="{groupItems: baseCaclAtivosSource}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.contabMetodoCalculo'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="contabMetodoCalculo" [(model)]="model.aquisicao.contabMetodoCalculo" [properties]="{groupItems: metodoCaclAtivosSource}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <h4 class="page-header"><label [translate]="'ativos.titles.dadosFiscais'"></label></h4>

            <pl-group>
              <pl-group [hasError]="model.aquisicao.fiscalValorAquisicao > model.aquisicao.contabValorAquisicao">
                <label [translate]="'ativosAquisicao.fields.fiscalValorAquisicao'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="fiscalValorAquisicao"
                    [(model)]="model.aquisicao.fiscalValorAquisicao"
                    [properties]="{validators: {required: {value: true}, fiscalValorAquisicao: validatorFiscalAquisicao}}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalTaxaAmortiza'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="fiscalTaxaAmortiza"
                    [model]="model.aquisicao.fiscalTaxaAmortiza"
                    (modelChange)="model.aquisicao.fiscalTaxaAmortiza = $event; commandValidaTaxasCodAmortizacao()"
                    [properties]="{modelOptions: {updateOn: 'blur'}}">
                    <ng-container *ngIf="validaTaxaFiscal">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <pl-tooltip [config]="tooltipTaxa">
                          <i *plTooltipContent class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                        </pl-tooltip>
                      </div>
                    </ng-container>
                  </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalNumAnosUteis'"></label>
                <edit>
                  <pl-edit type="number" attrName="fiscalNumAnosUteis" [properties]="{disabled: true}" [(model)]="model.aquisicao.fiscalNumAnosUteis"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalPercReducao'"></label>
                <edit>
                  <pl-edit
                    type="number"
                    attrName="fiscalPercReducao"
                    [model]="model.aquisicao.fiscalPercReducao"
                    (modelChange)="model.aquisicao.fiscalPercReducao = $event; commandValidaTaxasCodAmortizacao()"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalValorResidual'"></label>
                <edit>
                  <pl-edit type="number" attrName="fiscalValorResidual" [(model)]="model.aquisicao.fiscalValorResidual"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalDataInicioUtil'"></label>
                <edit>
                  <pl-edit type="date" attrName="fiscalDataInicioUtil" [(model)]="model.aquisicao.fiscalDataInicioUtil" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label class="d-flex">
                  <span [translate]="'ativosAquisicao.fields.fiscalBaseCalculo'"></span>
                  <pl-tooltip
                    *ngIf="model.aquisicao.fiscalBaseCalculo !== model.aquisicao.contabBaseCalculo"
                    class="tooltip-warning-label"
                    [config]="{text: 'ativosAquisicao.warn.baseFiscDifContab', tooltipClass: 'tooltip-warning', placement: ['bottom', 'left'], container: 'body'}">
                    <i *plTooltipContent class="fa fa-exclamation-triangle fa-fw text-warning" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit
                    type="radiogroup"
                    attrName="fiscalBaseCalculo"
                    [model]="model.aquisicao.fiscalBaseCalculo"
                    (modelChange)="model.aquisicao.fiscalBaseCalculo = $event; commandDataPorBaseParaCalculo()"
                    [properties]="{groupItems: baseCaclAtivosSource}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.fiscalMetodoCalculo'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="fiscalMetodoCalculo" [(model)]="model.aquisicao.fiscalMetodoCalculo" [properties]="{groupItems: metodoCaclAtivosSource}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativos.steps.contasPlanoOutrosDados" icon="fa-id-card-o">
          <pl-form *plNavWizardStepContent (formInstanceChange)="formContasPlanoOutrosDados = $event">
            <h4 class="page-header"><label [translate]="'ativos.titles.contas'"></label></h4>

            <pl-group>
              <label [translate]="'ativosAquisicao.fields.nContaDoAtivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaDoAtivo"
                  [model]="model.aquisicao"
                  (selectedKeyChange)="model.aquisicao.nContaDoAtivo = $event; commandContasAndIVAFromNContaAtivo()"
                  (evtSelectedDescriptionChanged)="model.aquisicao.nomeContaDoAtivo = $event"
                  [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}, modelOptions: {updateOn: 'blur'}, disabled: isModalContas}"
                  [fieldsMap]="{nConta: 'nContaDoAtivo', nome: 'nomeContaDoAtivo'}"
                  [filter]="filterContaDoAtivo"
                  [output]="pocsOutput"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosAquisicao.fields.nContaAmortExerc'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaAmortExerc"
                  [model]="model.aquisicao"
                  (selectedKeyChange)="model.aquisicao.nContaAmortExerc = $event"
                  (evtSelectedDescriptionChanged)="model.aquisicao.nomeContaAmortExerc = $event"
                  [properties]="{validators: {required: {value: true}}, disabled: !canEditContaAmortExerc}"
                  [fieldsMap]="{nConta: 'nContaAmortExerc', nome: 'nomeContaAmortExerc'}"
                  [filter]="filterMovimento"
                  [output]="pocsOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <label class="pe-2"><span [translate]="'ativosAquisicao.fields.nContaAmortAcumu'"></span>:</label>
                <span>{{ contaAmortAcumulado }}</span>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <label class="pe-2"><span [translate]="'ativosAquisicao.fields.nContaIVADedutivel'"></span>:</label>
                <span>{{ contaIvaDedutivel }}</span>
              </edit>
            </pl-group>

            <h4 class="page-header"><label [translate]="'ativos.titles.outrosDados'"></label></h4>

            <pl-group>
              <edit>
                <label class="pe-2"><span [translate]="'ativosAquisicao.fields.codIVAAquisicao'"></span>:</label>
                <span>{{ contaIvadeAquisicao }}</span>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <label class="pe-2"><span [translate]="'ativosAquisicao.fields.iVADeduzido'"></span>:</label>
                <span>{{ model.aquisicao.iVADeduzido | cgcDecimal }}</span>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nCodRepCC'"></label>
                <edit>
                  <entity-autocomplete entity="reparticoesccustosfaturacao" attrName="reparticoesccustos" [(selectedKey)]="model.aquisicao.nCodRepCC"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.codRubOrcam'"></label>
                <edit>
                  <pl-edit type="string" attrName="codRubOrcam" [(model)]="model.aquisicao.codRubOrcam"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativos.steps.classificadoresDoAtivo" icon="fa-tags">
          <pl-form *plNavWizardStepContent>
            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nCCusto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="ccustos"
                    attrName="ccustos"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 10}}}"
                    [(selectedKey)]="model.aquisicao.nCCusto"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeCCusto = $event"
                    [fieldsMap]="{nCCusto: 'nCCusto', nome: 'nomeCCusto'}"
                    [output]="ccustosOutput"
                    [filter]="'encerrado=0'">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nZona'"></label>
                <edit>
                  <entity-autocomplete
                    entity="zonas"
                    attrName="zonas"
                    [model]="model.aquisicao"
                    [(selectedKey)]="model.aquisicao.nZona"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeZona = $event"
                    [fieldsMap]="{nZona: 'nZona', nome: 'nomeZona'}"
                    [output]="zonaOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nDepto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="depto"
                    attrName="depto"
                    [model]="model.aquisicao"
                    [(selectedKey)]="model.aquisicao.nDepto"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeDepto = $event"
                    [fieldsMap]="{nDepto: 'nDepto', nome: 'nomeDepto'}"
                    [output]="departamentoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nSubde'"></label>
                <edit>
                  <entity-autocomplete
                    entity="subde"
                    attrName="subde"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.nSubde"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeSubDe = $event"
                    [fieldsMap]="{nSubDe: 'nSubde', nome: 'nomeSubDe'}"
                    [output]="subDepartamentoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.tipoArtigo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="tiposartigo"
                    attrName="tiposartigo"
                    [model]="model.aquisicao"
                    [properties]="{validators: {max: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.tipoArtigo"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeTipoArtigo = $event"
                    [fieldsMap]="{codTpArt: 'tipoArtigo', nome: 'nomeTipoArtigo'}"
                    [output]="tiposArtigoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nGrFamilia'"></label>
                <edit>
                  <entity-autocomplete
                    entity="grandesfamilias"
                    attrName="grandesfamilias"
                    [model]="model.aquisicao"
                    [properties]="{validators: {max: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.nGrFamilia"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeGrFamilia = $event"
                    [fieldsMap]="{nDepart: 'nGrFamilia', nome: 'nomeGrFamilia'}"
                    [output]="grFamiliaOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nFamilia'"></label>
                <edit>
                  <entity-autocomplete
                    entity="familias"
                    attrName="familias"
                    [model]="model.aquisicao"
                    [properties]="{validators: {max: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.nFamilia"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeFamilia = $event"
                    [fieldsMap]="{nFamilia: 'nFamilia', nome: 'nomeFamilia'}"
                    [output]="familiaOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nSubfa'"></label>
                <edit>
                  <entity-autocomplete
                    entity="subfamilias"
                    attrName="subfamilias"
                    [model]="model.aquisicao"
                    [properties]="{validators: {max: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.nSubfa"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeSubFa = $event"
                    [fieldsMap]="{nSubFa: 'nSubfa', nome: 'nomeSubFa'}"
                    [output]="subFamiliaOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.codContab'"></label>
                <edit>
                  <entity-autocomplete
                    entity="codigoscontabilisticos"
                    attrName="codigoscontabilisticos"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.codContab"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeContab = $event"
                    [fieldsMap]="{codcontab: 'codContab', nome: 'nomeContab'}"
                    [output]="codContabOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.classe'"></label>
                <edit>
                  <entity-autocomplete
                    entity="artigoclasses"
                    attrName="artigoclasses"
                    [model]="model.aquisicao"
                    [properties]="{validators: {max: {value: 4}}}"
                    [(selectedKey)]="model.aquisicao.classe"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeClasse = $event"
                    [fieldsMap]="{classe: 'classe', descricao: 'nomeClasse'}"
                    [output]="classeOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.categoria'"></label>
                <edit>
                  <entity-autocomplete
                    entity="categoriaartigo"
                    attrName="categoriaartigo"
                    [model]="model.aquisicao"
                    [(selectedKey)]="model.aquisicao.categoria"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeCategoria = $event"
                    [fieldsMap]="{categoria: 'categoria', descricao: 'nomeCategoria'}"
                    [output]="categoriaOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nVendedor'"></label>
                <edit>
                  <entity-autocomplete
                    entity="vendedores"
                    attrName="vendedores"
                    [model]="model.aquisicao"
                    [(selectedKey)]="model.aquisicao.nVendedor"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeVendedores = $event"
                    [fieldsMap]="{nVendedor: 'nVendedor', nome: 'nomeVendedores'}"
                    [output]="vendedoresOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nRefProcesso'"></label>
                <edit>
                  <entity-autocomplete
                    entity="processos"
                    attrName="processos"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 25}}}"
                    [(selectedKey)]="model.aquisicao.nRefProcesso"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeProcesso = $event"
                    [fieldsMap]="{nRefProcesso: 'nRefProcesso', nome: 'nomeProcesso'}"
                    [output]="processoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nSeccao'"></label>
                <edit>
                  <entity-autocomplete
                    entity="seccoes"
                    attrName="seccoes"
                    [model]="model.aquisicao"
                    [properties]="{validators: {maxlength: {value: 25}}}"
                    [(selectedKey)]="model.aquisicao.nSeccao"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeSeccao = $event"
                    [fieldsMap]="{nSeccao: 'nSeccao', designacaoBreve: 'nomeSeccao'}"
                    [output]="seccaoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativos.steps.planningAmortizacoes" icon="fa-list-alt ">
          <div *plNavWizardStepContent>
            <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="planningAmortizacoes" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
          </div>
        </pl-nav-wizard-step>
      </pl-nav-wizard>
    </div>

    <div *ngSwitchCase="stateTypes.EDIT">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <edit>
                <label class="me-1" [translate]="'ativos.header.estado'"></label>
                <span
                  [ngClass]="{'ativos-green-estado': model.estado === estadosAtivo.Normal, 'ativos-yellow-estado': model.estado !== estadosAtivo.Normal}"
                  [translate]="'ativos.estado.' + model.estado"
                  [translateParams]="{date: model.dataEstado | cgcDate}"></span>
              </edit>
            </div>

            <div class="col-md-3">
              <edit>
                <label class="me-1" [translate]="'ativos.header.valorAquisicao'"></label>
                <span class="text-end">{{ model.valorAquisicaoContab | cgcDecimal }}</span>
              </edit>
            </div>

            <div class="col-md-3">
              <edit>
                <label class="me-1" [translate]="'ativos.header.depAcumulada'"></label>
                <span class="text-end">{{ model.valorDepreciacaoContab | cgcDecimal }}</span>
              </edit>
            </div>

            <div class="col-md-3">
              <edit>
                <label class="me-1" [translate]="'ativos.header.valorLiquido'"></label>
                <span class="text-end">{{ model.valorLiquidoContab | cgcDecimal }}</span>
              </edit>
            </div>
          </div>
        </div>
      </div>
      <pl-tabs>
        <pl-tab>
          <div *plTabTitle><i class="fa fa-fw fa-truck" aria-hidden="true"></i>&nbsp;<span [translate]="'ativos.steps.aquisicaoAtivo'"></span></div>

          <div *plTabContent>
            <pl-form [properties]="{readonly: readonly}">
              <h4 class="page-header"><label [translate]="'ativos.titles.dadosDoAtivo'"></label></h4>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativos.fields.codAtivo'"></label>
                  <edit>
                    <pl-edit type="cginteger" attrName="codAtivo" [(model)]="model.codAtivo" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativos.fields.designacao'"></label>
                  <edit>
                    <pl-edit type="text" attrName="designacao" [(model)]="model.designacao" [properties]="{validators: {maxlength: {value: 150}, required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativos.fields.refExterna'"></label>
                  <edit>
                    <pl-edit type="text" attrName="refExterna" [(model)]="model.refExterna" [properties]="{validators: {maxlength: {value: 30}}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativos.fields.origem'"></label>
                  <edit>
                    <div class="form-control-align disabled" [translate]="'ativos.enum.origem.' + model.origem"></div>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.codAmortizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="amortizacao"
                      attrName="amortizacao"
                      [model]="model.aquisicao"
                      [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}, disabled: !model.aquisicao.podeEditar}"
                      (selectedKeyChange)="model.aquisicao.codAmortizacao = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeAmortizacao = $event"
                      [fieldsMap]="{nImtab: 'codAmortizacao', nome: 'nomeAmortizacao'}"
                      [output]="amortizacaoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nMapaAmortizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="mapasamortizacao"
                      attrName="mapasAmortizacao"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nMapaAmortizacao = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeMapaAmortizacao = $event"
                      [properties]="{validators: {maxlength: {value: 5}, required: {value: true}}, disabled: !model.aquisicao.podeEditar}"
                      [fieldsMap]="{nImmap: 'nMapaAmortizacao', nome: 'nomeMapaAmortizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.dataAquisicao'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dataAquisicao"
                      [(model)]="model.aquisicao.dataAquisicao"
                      [properties]="{validators: {required: {value: true}}, disabled: !model.aquisicao.podeEditar}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.dataUtilizacao'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dataUtilizacao"
                      [(model)]="model.aquisicao.dataUtilizacao"
                      [properties]="{validators: {required: {value: true}}, disabled: !model.aquisicao.podeEditar}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.usado'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="usado" [(model)]="model.aquisicao.usado" [properties]="{disabled: !model.aquisicao.podeEditar}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.quantidade'"></label>
                  <edit>
                    <pl-edit type="number" attrName="quantidade" [(model)]="model.aquisicao.quantidade" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h4 class="page-header"><label [translate]="'ativos.titles.dadosDoFornecedor'"></label></h4>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nDocExterno'"></label>
                  <edit>
                    <pl-edit type="text" attrName="nDocExterno" [(model)]="model.aquisicao.nDocExterno" [properties]="{validators: {maxlength: {value: 10}}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.dataDocExterno'"></label>
                  <edit>
                    <pl-edit type="date" attrName="dataDocExterno" [(model)]="model.aquisicao.dataDocExterno" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nContaFornecedor'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="fornecedores"
                      attrName="fornecedores"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nContaFornecedor = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeFornecedor = $event"
                      [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}}"
                      [fieldsMap]="{nConta: 'nContaFornecedor', nome: 'nomeFornecedor'}"
                      [output]="fornecedoresOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nomeFornecedor'"></label>
                  <edit>
                    <pl-edit type="text" attrName="fornecedores" [(model)]="model.aquisicao.nomeFornecedor" [properties]="{validators: {maxlength: {value: 150}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h4 class="page-header" *ngIf="model.codAtivoPai"><label [translate]="'ativos.titles.grandeReparacao'"></label></h4>

              <pl-group *ngIf="model.codAtivoPai">
                <label [translate]="'ativos.fields.codAtivoPai'"></label>
                <edit>
                  <div class="form-control-align">{{ model.codAtivoPai }} - {{ model.nomeAtivoPai }}</div>
                </edit>
              </pl-group>
            </pl-form>
          </div>
        </pl-tab>

        <pl-tab>
          <div *plTabTitle><i class="fa fa-fw fa-file-text-o" aria-hidden="true"></i>&nbsp;<span [translate]="'ativos.steps.dadosContabilisticosDadosFiscais'"></span></div>

          <div *plTabContent>
            <pl-form [properties]="{readonly: readonly, disabled: !model.aquisicao.podeEditar}">
              <h4 class="page-header"><label [translate]="'ativos.titles.dadosContabilisticos'"></label></h4>
              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabValorAquisicao'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="contabValorAquisicao"
                      [model]="model.aquisicao.contabValorAquisicao"
                      (modelChange)="model.aquisicao.contabValorAquisicao = $event; commandValoresContabilisticosEFiscais()"
                      [properties]="{modelOptions: {updateOn: 'blur'}, validators: {required: {value: true}}}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabTaxaAmortiza'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="contabTaxaAmortiza"
                      [model]="model.aquisicao.contabTaxaAmortiza"
                      (modelChange)="model.aquisicao.contabTaxaAmortiza = $event; commandValidaTaxasCodAmortizacao()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}">
                      <ng-container *ngIf="validaTaxaContab">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <pl-tooltip [config]="tooltipTaxa">
                            <i *plTooltipContent class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                          </pl-tooltip>
                        </div>
                      </ng-container>
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabNumAnosUteis'"></label>
                  <edit>
                    <pl-edit type="number" attrName="contabNumAnosUteis" [(model)]="model.aquisicao.contabNumAnosUteis" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabPercReducao'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="contabPercReducao"
                      [model]="model.aquisicao.contabPercReducao"
                      (modelChange)="model.aquisicao.contabPercReducao = $event; commandValidaTaxasCodAmortizacao()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabValorResidual'"></label>
                  <edit>
                    <pl-edit type="number" attrName="contabValorResidual" [(model)]="model.aquisicao.contabValorResidual"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabDataDispUso'"></label>
                  <edit>
                    <pl-edit type="date" attrName="contabDataDispUso" [(model)]="model.aquisicao.contabDataDispUso" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabBaseCalculo'"></label>
                  <edit>
                    <pl-edit
                      type="radiogroup"
                      attrName="contabBaseCalculo"
                      [model]="model.aquisicao.contabBaseCalculo"
                      (modelChange)="model.aquisicao.contabBaseCalculo = $event; commandDataPorBaseParaCalculo()"
                      [properties]="{groupItems: baseCaclAtivosSource}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.contabMetodoCalculo'"></label>
                  <edit>
                    <pl-edit type="radiogroup" attrName="contabMetodoCalculo" [(model)]="model.aquisicao.contabMetodoCalculo" [properties]="{groupItems: metodoCaclAtivosSource}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h4 class="page-header"><label [translate]="'ativos.titles.dadosFiscais'"></label></h4>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalValorAquisicao'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="model.aquisicao.fiscalValorAquisicao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalTaxaAmortiza'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="fiscalTaxaAmortiza"
                      [model]="model.aquisicao.fiscalTaxaAmortiza"
                      (modelChange)="model.aquisicao.fiscalTaxaAmortiza = $event; commandValidaTaxasCodAmortizacao()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}">
                      <ng-container *ngIf="validaTaxaFiscal">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <pl-tooltip [config]="tooltipTaxa">
                            <i *plTooltipContent class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                          </pl-tooltip>
                        </div>
                      </ng-container>
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalNumAnosUteis'"></label>
                  <edit>
                    <pl-edit type="number" attrName="fiscalNumAnosUteis" [(model)]="model.aquisicao.fiscalNumAnosUteis" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalPercReducao'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="fiscalPercReducao"
                      [model]="model.aquisicao.fiscalPercReducao"
                      (modelChange)="model.aquisicao.fiscalPercReducao = $event; commandValidaTaxasCodAmortizacao()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalValorResidual'"></label>
                  <edit>
                    <pl-edit type="number" attrName="fiscalValorResidual" [(model)]="model.aquisicao.fiscalValorResidual"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalDataInicioUtil'"></label>
                  <edit>
                    <pl-edit type="date" attrName="fiscalDataInicioUtil" [(model)]="model.aquisicao.fiscalDataInicioUtil" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalBaseCalculo'"></label>
                  <edit>
                    <pl-edit
                      type="radiogroup"
                      attrName="fiscalBaseCalculo"
                      [model]="model.aquisicao.fiscalBaseCalculo"
                      (modelChange)="model.aquisicao.fiscalBaseCalculo = $event; commandDataPorBaseParaCalculo()"
                      [properties]="{groupItems: baseCaclAtivosSource}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.fiscalMetodoCalculo'"></label>
                  <edit>
                    <pl-edit type="radiogroup" attrName="fiscalMetodoCalculo" [(model)]="model.aquisicao.fiscalMetodoCalculo" [properties]="{groupItems: metodoCaclAtivosSource}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
            </pl-form>
          </div>
        </pl-tab>

        <pl-tab>
          <div *plTabTitle><i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>&nbsp;<span [translate]="'ativos.steps.contasPlanoOutrosDados'"></span></div>

          <div *plTabContent>
            <pl-form [properties]="{readonly: readonly}">
              <h4 class="page-header"><label [translate]="'ativos.titles.contas'"></label></h4>
              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nContaDoAtivo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="pocs"
                    attrName="pocs"
                    [model]="model.aquisicao"
                    (selectedKeyChange)="model.aquisicao.nContaDoAtivo = $event; commandContasAndIVAFromNContaAtivo()"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeContaDoAtivo = $event"
                    [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}, disabled: !model.aquisicao.podeEditarContas, modelOptions: {updateOn: 'blur'}}"
                    [fieldsMap]="{nConta: 'nContaDoAtivo', nome: 'nomeContaDoAtivo'}"
                    [filter]="filterContaDoAtivo"
                    [output]="pocsOutput"
                    plAutoFocus>
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nContaAmortExerc'"></label>
                <edit>
                  <entity-autocomplete
                    entity="pocs"
                    attrName="nContaAmortExerc"
                    [model]="model.aquisicao"
                    (selectedKeyChange)="model.aquisicao.nContaAmortExerc = $event"
                    (evtSelectedDescriptionChanged)="model.aquisicao.nomeContaAmortExerc = $event"
                    [properties]="{validators: {required: {value: true}}, disabled: !canEditContaAmortExerc}"
                    [fieldsMap]="{nConta: 'nContaAmortExerc', nome: 'nomeContaAmortExerc'}"
                    [filter]="filterMovimento"
                    [output]="pocsOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <label class="pe-2"><span [translate]="'ativosAquisicao.fields.nContaAmortAcumu'"></span>:</label>
                  <span>{{ contaAmortAcumulado }}</span>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <label class="pe-2"><span [translate]="'ativosAquisicao.fields.nContaIVADedutivel'"></span>:</label>
                  <span>{{ contaIvaDedutivel }}</span>
                </edit>
              </pl-group>

              <h4 class="page-header"><label [translate]="'ativos.titles.outrosDados'"></label></h4>

              <pl-group>
                <edit>
                  <label class="pe-2"><span [translate]="'ativosAquisicao.fields.codIVAAquisicao'"></span>:</label>
                  <span>{{ contaIvadeAquisicao }}</span>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <label class="pe-2"><span [translate]="'ativosAquisicao.fields.iVADeduzido'"></span>:</label>
                  <span>{{ model.aquisicao.iVADeduzido | cgcDecimal }}</span>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosAquisicao.fields.nCodRepCC'"></label>
                <edit>
                  <entity-autocomplete entity="reparticoesccustosfaturacao" attrName="reparticoesccustos" [(selectedKey)]="model.aquisicao.nCodRepCC"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativos.fields.documentoDeAquisicao'"></label>
                <edit>
                  <pl-edit type="text" attrName="documentoDeAquisicao" [model]="model.documentoDeAquisicao" [properties]="{readonly: true}"></pl-edit>
                </edit>
              </pl-group>
            </pl-form>
          </div>
        </pl-tab>

        <pl-tab>
          <div *plTabTitle><i class="fa fa-fw fa-tags" aria-hidden="true"></i>&nbsp;<span [translate]="'ativos.steps.classificadoresDoAtivo'"></span></div>

          <div *plTabContent>
            <pl-form [properties]="{readonly: readonly}">
              <h4 class="page-header"><label [translate]="'ativos.titles.classificadores'"></label></h4>
              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nCCusto'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="ccustos"
                      attrName="ccustos"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nCCusto = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeCCusto = $event"
                      [properties]="{validators: {maxlength: {value: 10}}}"
                      [fieldsMap]="{nCCusto: 'nCCusto', nome: 'nomeCCusto'}"
                      [output]="ccustosOutput"
                      [filter]="'encerrado=0'">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nZona'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="zonas"
                      attrName="zonas"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nZona = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeZona = $event"
                      [fieldsMap]="{nZona: 'nZona', nome: 'nomeZona'}"
                      [output]="zonaOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nDepto'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="depto"
                      attrName="depto"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nDepto = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeDepto = $event"
                      [fieldsMap]="{nDepto: 'nDepto', nome: 'nomeDepto'}"
                      [output]="departamentoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nSubde'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="subde"
                      attrName="subde"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nSubde = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeSubDe = $event"
                      [properties]="{validators: {maxlength: {value: 4}}}"
                      [fieldsMap]="{nSubDe: 'nSubde', nome: 'nomeSubDe'}"
                      [output]="subDepartamentoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.tipoArtigo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="tiposartigo"
                      attrName="tiposartigo"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.tipoArtigo = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeTipoArtigo = $event"
                      [properties]="{validators: {max: {value: 4}}}"
                      [fieldsMap]="{codTpArt: 'tipoArtigo', nome: 'nomeTipoArtigo'}"
                      [output]="tiposArtigoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nGrFamilia'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="grandesfamilias"
                      attrName="grandesfamilias"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nGrFamilia = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeGrFamilia = $event"
                      [properties]="{validators: {max: {value: 4}}}"
                      [fieldsMap]="{nDepart: 'nGrFamilia', nome: 'nomeGrFamilia'}"
                      [output]="grFamiliaOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nFamilia'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="familias"
                      attrName="familias"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nFamilia = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeFamilia = $event"
                      [properties]="{validators: {max: {value: 4}}}"
                      [fieldsMap]="{nFamilia: 'nFamilia', nome: 'nomeFamilia'}"
                      [output]="familiaOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nSubfa'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="subfamilias"
                      attrName="subfamilias"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nSubfa = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeSubFa = $event"
                      [properties]="{validators: {max: {value: 4}}}"
                      [fieldsMap]="{nSubFa: 'nSubfa', nome: 'nomeSubFa'}"
                      [output]="subFamiliaOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.codContab'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="codigoscontabilisticos"
                      attrName="codigoscontabilisticos"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.codContab = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeContab = $event"
                      [properties]="{validators: {maxlength: {value: 4}}}"
                      [fieldsMap]="{codcontab: 'codContab', nome: 'nomeContab'}"
                      [output]="codContabOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.classe'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="artigoclasses"
                      attrName="artigoclasses"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.classe = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeClasse = $event"
                      [properties]="{validators: {max: {value: 4}}}"
                      [fieldsMap]="{classe: 'classe', descricao: 'nomeClasse'}"
                      [output]="classeOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.categoria'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="categoriaartigo"
                      attrName="categoriaartigo"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.categoria = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeCategoria = $event"
                      [fieldsMap]="{categoria: 'categoria', descricao: 'nomeCategoria'}"
                      [output]="categoriaOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nVendedor'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="vendedores"
                      attrName="vendedores"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nVendedor = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeVendedores = $event"
                      [fieldsMap]="{nVendedor: 'nVendedor', nome: 'nomeVendedores'}"
                      [output]="vendedoresOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nRefProcesso'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="processos"
                      attrName="processos"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nRefProcesso = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeProcesso = $event"
                      [properties]="{validators: {maxlength: {value: 25}}}"
                      [fieldsMap]="{nRefProcesso: 'nRefProcesso', nome: 'nomeProcesso'}"
                      [output]="processoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativosAquisicao.fields.nSeccao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="seccoes"
                      attrName="seccoes"
                      [model]="model.aquisicao"
                      (selectedKeyChange)="model.aquisicao.nSeccao = $event"
                      (evtSelectedDescriptionChanged)="model.aquisicao.nomeSeccao = $event"
                      [properties]="{validators: {maxlength: {value: 25}}}"
                      [fieldsMap]="{nSeccao: 'nSeccao', designacaoBreve: 'nomeSeccao'}"
                      [output]="seccaoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>
            </pl-form>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </ng-container>
</div>
