import {Component, Injector, Input, OnInit} from '@angular/core';
import type {IPlDynamicVisualsSecondaryClickAction} from './dynamicvisuals.secondaryclick.component.interface';
import {isArray, isEmpty} from '../../../../common/utilities/utilities';
import {PlDynamicVisualsAbstractComponent} from '../../abstract/dynamicvisuals.abstract.component';
import type {TNgClassSupportedTypes} from '../../../../common/angular.interface';

@Component({
  selector: 'pl-dynamic-visuals-secondary-click',
  templateUrl: './dynamicvisuals.secondaryclick.component.html',
  standalone: false
})
export class PlDynamicVisualsSecondaryClickComponent extends PlDynamicVisualsAbstractComponent implements OnInit {
  @Input() public cssClass: TNgClassSupportedTypes;
  @Input() public actions: Array<IPlDynamicVisualsSecondaryClickAction>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.cssClass = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!isArray(this.cssClass) && isEmpty(this.cssClass)) {
      this.cssClass = '';
    }
  }

  public clickedAction(action: IPlDynamicVisualsSecondaryClickAction, event: MouseEvent): void {
    if (action.disabled) {
      return;
    }
    action.disabled = true;
    action.promise = Promise.resolve(action.click(action, event))
      .then(() => {
        action.disabled = false;
        action.promise = undefined;
      })
      .catch(() => {
        action.disabled = false;
        action.promise = undefined;
      });
  }
}
