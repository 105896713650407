import {HttpResponse} from '@angular/common/http';
import {IJsonTributAutonomaHeader} from '../jsonTributAutonomas.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ManutTributAutonomasModuleComponent} from './components/manutTributAutonomas.module.component';
import {MODULE_NAME_MANUT_TRIBUT_AUTONOMAS} from '../tributAutonomas.module.interface';
import {ROLE} from '../../../services/role.const';
import {TributAutonomasModuleService} from '../tributAutonomas.module.service';

export const MODULE_TRIBUT_AUTONOMAS: IModuleDefinition = {
  name: MODULE_NAME_MANUT_TRIBUT_AUTONOMAS,
  state: {
    url: `/${MODULE_NAME_MANUT_TRIBUT_AUTONOMAS}`,
    component: ManutTributAutonomasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.manuttributautonomas'
    },
    params: {
      nConta: {
        type: 'string',
        value: ''
      }
    },
    resolve: [
      {
        provide: 'header',
        deps: [TributAutonomasModuleService],
        useFactory: (tributAutonomasModuleService: TributAutonomasModuleService): Promise<IJsonTributAutonomaHeader> => {
          return tributAutonomasModuleService.loadDefaultHeader().then((response: HttpResponse<IJsonTributAutonomaHeader>) => response.body);
        }
      }
    ]
  }
};
