import moment from 'moment';
import {catchError, timeout} from 'rxjs/operators';
import {from, mergeMap, Observable, Subscription, TimeoutError, timer} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Logger} from 'pl-comps-angular';
import {AppService} from '../../../../../services/app/app.service';
import {HealthService} from '../../../../../services/health/health.service';
import {IAppStatus} from '../../../../../services/app/app.service.interface';
import {TDate} from '../../../../../../common/dates';

/* eslint-disable @typescript-eslint/no-magic-numbers */

const CHECK_STATUS_PERIOD: number = moment.duration(1, 'minute').asMilliseconds();
const CHECK_STATUS_TIMEOUT: number = moment.duration(5, 'seconds').asMilliseconds();
const AUTO_HIDE_TIMEOUT: number = moment.duration(10, 'seconds').asMilliseconds();

/* eslint-enable @typescript-eslint/no-magic-numbers */

@Component({
  selector: 'cg-app-status-maintenance',
  templateUrl: './cg.app.status.maintenance.component.html',
  exportAs: 'appStatusMaintenance'
})
export class CGAppStatusMaintenanceComponent implements OnDestroy {
  public showToast: boolean;
  public maintenance: boolean;
  public ended: boolean;
  public lastChecked: TDate;

  private readonly _autoHide: boolean;
  private readonly _subscriptionAppStatus: Subscription;
  private _subscriptionCheckStatus: Subscription;
  private _subscriptionAutoHide: Subscription;

  constructor(
    private readonly _logger: Logger,
    private readonly _appService: AppService,
    private readonly _healthService: HealthService
  ) {
    this.showToast = false;
    this.maintenance = false;
    this.ended = false;
    this._autoHide = false;

    this._subscriptionAppStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._evaluateStatus(status.maintenance);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionAppStatus.unsubscribe();
    this._stopCheckStatus();
    this._clearAutoHide();
  }

  public show(): void {
    this.showToast = true;
    this.lastChecked = moment();
    this.ended = false;
    this._clearAutoHide();
  }

  public hide(): void {
    this.showToast = false;
  }

  private _evaluateStatus(maintenance: boolean): void {
    if (maintenance === this.maintenance) {
      return;
    }

    if (maintenance) {
      this.show();
      this._startCheckStatus();
    } else {
      this._stopCheckStatus();

      if (this.maintenance) {
        this.ended = true;

        if (this._autoHide) {
          this._clearAutoHide();

          this._subscriptionAutoHide = timer(AUTO_HIDE_TIMEOUT).subscribe(() => {
            this.hide();
            this._subscriptionAutoHide = undefined;
          });
        }
      }
    }

    this.maintenance = maintenance;
  }

  private _startCheckStatus(): void {
    if (this._subscriptionCheckStatus) {
      return;
    }

    const checkStatus = (): Observable<HttpResponse<unknown>> =>
      timer(CHECK_STATUS_PERIOD)
        .pipe(mergeMap(() => from(this._healthService.live({reportExceptions: false})).pipe(timeout(CHECK_STATUS_TIMEOUT))))
        .pipe(
          catchError((error: HttpErrorResponse | TimeoutError) => {
            this._logger.error('Error checking status', error);
            this.lastChecked = moment();
            return checkStatus();
          })
        );

    this._subscriptionCheckStatus = checkStatus().subscribe((response: HttpResponse<unknown>) => {
      if (response.ok) {
        this._stopCheckStatus();
      }
    });
  }

  private _stopCheckStatus(): void {
    if (this._subscriptionCheckStatus) {
      this._subscriptionCheckStatus.unsubscribe();
      this._subscriptionCheckStatus = undefined;
    }
  }

  private _clearAutoHide(): void {
    if (this._subscriptionAutoHide) {
      this._subscriptionAutoHide.unsubscribe();
      this._subscriptionAutoHide = undefined;
    }
  }
}
