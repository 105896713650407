import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'importador-saft-finalized-modal',
  templateUrl: './importadorSaftDirect.finalized.modal.component.html'
})
export class ImportadorSaftDirectFinalizedModalComponent extends CGModalComponent<void> {
  @Input() public modalMessage: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.modalMessage = '';
  }
}
