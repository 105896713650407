import {Component, Input} from '@angular/core';
import {IPlUploadFile} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';

@Component({
  selector: 'contabilidadedigital-docviewer-recolha-attachexistingdoc-modal',
  templateUrl: './contabilidadedigital.docviewer.recolha.attachexistingdoc.modal.component.html'
})
export class ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent extends CGModalComponent<File> {
  @Input() public foldersNamePath: Array<string>;

  public acceptedFile(uploadFile: IPlUploadFile): void {
    this.close(uploadFile.file);
  }

  public get maxFileSize(): number {
    return this.configurations.empresa.erpcloud.ficheiros.maxFileSize;
  }
}
