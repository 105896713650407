import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaFeriasComponent} from './components/mapaFerias.module.component';

export const MODULE_PCA_RH_MAPA_FERIAS: IModuleDefinition = {
  name: 'pcaMapaFerias',
  state: {
    url: '/rh/mapa/ferias',
    component: MapaFeriasComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaMapaFerias'
    }
  }
};
