<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.addDocNaoConciliadosTitle'"></h4>
</div>

<div class="modal-body">
  <pl-table theme="style2" [definition]="tableDefinition" [source]="fnGetTableSource" [callback]="tableCallback" [properties]="tableOptions" (evtSelect)="onSelect($event)"></pl-table>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-save" [disabled]="closeDisabled" [click]="close" plPromise>
    <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="btnAddCaption"></span>
  </button>

  <button type="button" class="btn btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>
