import type {ILogger} from './logger.interface';

export abstract class Logger implements ILogger {
  public abstract setDebugEnabled(enabled: boolean): void;

  public abstract fallback(...values: Array<any>): void;

  public abstract log(...values: Array<any>): void;

  public abstract debug(...values: Array<any>): void;

  public abstract error(...values: Array<any>): void;

  public abstract assert(...values: Array<any>): void;

  public abstract info(...values: Array<any>): void;

  public abstract warn(...values: Array<any>): void;

  public abstract group(...values: Array<any>): void;

  public abstract groupEnd(...values: Array<any>): void;
}
