import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonAplicabilidadeIRCT} from './jsonCCT.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class CctService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/cct`;
  }

  public getDataSourceAplicabilidadeIRCT(): TServiceResponse<Array<IJsonAplicabilidadeIRCT>> {
    return this._apiService.get<Array<IJsonAplicabilidadeIRCT>>({
      url: `${this._path}/aplicabilidadeirct`
    });
  }
}
