import {Subscription} from 'rxjs';
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IPlFormDefinition, isObject} from 'pl-comps-angular';
import {ConfigService} from '../../../services/config/config.service';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_NIFS} from '../../../entities/nifs/nifs.entity.interface';
import {EntityRegistryService} from '../../../components/entity/entity.registry.service';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {IEntity, IEntityState} from '../../../components/entity/entity.definition.interface';
import {IJsonNIFs} from '../../../entities/nifs/jsonNifs.entity.interface';

const FIELD_INDEX_ABREV_FISCAL = 0;
const FIELD_INDEX_TEM_CATIVO = 4;
const FIELD_INDEX_TAXA_CATIVO = 5;

@Component({
  selector: 'nifs',
  templateUrl: './nifs.component.html'
})
export class NifsComponent implements OnChanges, OnDestroy {
  @Input() public model: IJsonNIFs;
  @Input() public type: EEntityStateDetailType;

  public readonly stateTypes: typeof EEntityStateDetailType;
  public formDefinition: IPlFormDefinition;

  private readonly _entity: IEntity<IJsonNIFs>;
  private readonly _subscription: Subscription;
  private _empresaSujeitaAsLeisVigoramEmAngola: boolean;

  constructor(
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _configService: ConfigService
  ) {
    this.stateTypes = EEntityStateDetailType;
    this._entity = this._entityRegistryService.getEntity<IJsonNIFs>(ENTITY_NAME_NIFS);
    this._subscription = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      if (isObject(configurations)) {
        this._empresaSujeitaAsLeisVigoramEmAngola = configurations.empresa.sujeitaAsLeisVigoramEmAngola;
        if (this.formDefinition) {
          this._evaluateFormFields();
        }
      }
    });
  }

  public ngOnChanges({type}: SimpleChanges): void {
    if (type) {
      const entityType: IEntityState = this._entity[type.currentValue];
      this.formDefinition = entityType.definition;
      this._evaluateFormFields();
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _evaluateFormFields(): void {
    this.formDefinition.fields[FIELD_INDEX_ABREV_FISCAL].properties.disabled = this.type === this.stateTypes.EDIT;
    this.formDefinition.fields[FIELD_INDEX_TEM_CATIVO].visible = this._empresaSujeitaAsLeisVigoramEmAngola;
    this.formDefinition.fields[FIELD_INDEX_TAXA_CATIVO].visible = this._empresaSujeitaAsLeisVigoramEmAngola;
  }
}
