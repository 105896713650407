import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_SEGUR} from './segur.entity.interface';
import {IJsonSegur} from './jsonSegur.entity.interface';
import {SegurEditComponent} from './components/edit/segur.entity.edit.component';

export const ENTITY_SEGUR: IEntityDefinition<IJsonSegur> = {
  name: ENTITY_NAME_SEGUR,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.segur',
  searchPlaceholder: 'segur.pesquisa',
  metadata: {
    keyName: 'nsegur',
    fields: [
      {name: 'nsegur', caption: 'segur.fields.nsegur', placeholder: 'segur.fields.nsegur', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', caption: 'segur.fields.designaBreve', placeholder: 'segur.fields.designaBreve', validators: {required: true, maxlength: 150}},
      {name: 'nome', caption: 'segur.fields.nome', placeholder: 'segur.fields.nome', visible: false},
      {name: 'morada', caption: 'segur.fields.morada', placeholder: 'segur.fields.morada', visible: false},
      {
        name: 'npostal',
        caption: 'segur.fields.npostal',
        placeholder: 'segur.fields.npostal',
        entity: {name: 'codpostais', keyTarget: 'npostal', outputTarget: 'npostal'},
        visible: false
      },
      {name: 'localidade', caption: 'segur.fields.localidade', placeholder: 'segur.fields.localidade', visible: false},
      {name: 'telefone', caption: 'segur.fields.telefone', placeholder: 'segur.fields.telefone', visible: false},
      {name: 'codSeguradoraISP', caption: 'segur.fields.codSeguradoraISP', placeholder: 'segur.fields.codSeguradoraISP', validators: {required: true}},
      {name: 'napolice', caption: 'segur.fields.napolice', placeholder: 'segur.fields.napolice', validators: {required: true}}
    ],
    order: 'nsegur',
    searchFields: 'nsegur,napolice,codSeguradoraISP,designaBreve'
  },
  autocomplete: {
    rowTemplate: '{{nsegur}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nsegur,napolice,codSeguradoraISP,designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      component: SegurEditComponent
    }
  }
};
