import {ENTITY_NAME_COD_POSTAIS} from './codPostais.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCodPostal} from './jsonCodPostal.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_COD_POSTAIS: IEntityDefinition<IJsonCodPostal> = {
  name: ENTITY_NAME_COD_POSTAIS,
  roles: [ROLE.ERP, ROLE.RH, ROLE.CONTABILIDADE],
  searchPlaceholder: 'codpostais.pesquisa',
  pageTitle: 'global.menu.codpostais',
  metadata: {
    keyName: 'codPostal',
    fields: [
      {
        name: 'codPostal',
        width: '120px',
        caption: 'codpostais.fields.codPostal',
        placeholder: 'codpostais.fields.codPostalPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {name: 'localidade', caption: 'codpostais.fields.localidade', placeholder: 'codpostais.fields.localidadePlaceholder', validators: {required: true, maxlength: 50}},
      {name: 'codPais', caption: 'codpostais.fields.codPais', visible: false},
      {name: 'nomePais', caption: 'codpostais.fields.nomePais', visible: false}
    ],
    order: 'localidade',
    searchFields: 'codPostal,localidade'
  },
  autocomplete: {
    rowTemplate: '{{codPostal}} - {{localidade}}',
    output: 'codPostal',
    searchFields: 'codPostal,localidade'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
