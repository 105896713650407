<div class="pocscopy entity-detail-form">
  <pl-form>
    <pl-nav-wizard [definition]="definitionWizard" [beforeStepChange]="beforeStepChange" [onFinalize]="finalize" [callback]="navWizardCallback" (modelChange)="selectedStep = $event">
      <pl-nav-wizard-step caption="pocscopy.steps.intro.step" icon="fa-home" [disabled]="disableSteps">
        <div *plNavWizardStepContent>
          <h4 [translate]="'pocscopy.steps.intro.title'"></h4>
          <p class="mt-4" [translate]="'pocscopy.steps.intro.description'"></p>
          <p [translate]="'pocscopy.steps.intro.description2'"></p>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="pocscopy.steps.contas.step" icon="fa-list-alt" [disabled]="disableSteps">
        <div *plNavWizardStepContent>
          <h5 [translate]="'pocscopy.steps.contas.title'"></h5>
          <p class="mt-2" [translate]="'pocscopy.steps.contas.descriptions'"></p>

          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinition"
            cgDxDataGridInstanceName="pocscopy"
            [dataSource]="dataGridDefinition.dataSource"
            [(selectedRowKeys)]="selectedRowKeys"
            (onInitialized)="onDataGridInitialized($event)"
            (onContentReady)="onDataGridContentReady()">
            <div *dxTemplate="let item of 'templateToolbarNConta'">
              <div class="datagrid-nconta-template">
                <div>
                  <span [translate]="'pocscopy.fields.ncontade'"></span>
                  <entity-autocomplete entity="pocs" attrName="nContaDe" [model]="nContaDe" (selectedKeyChange)="nContaDe = $event" output="key"></entity-autocomplete>
                </div>
                <div>
                  <span [translate]="'global.text.to'"></span>
                  <entity-autocomplete entity="pocs" attrName="nContaAte" [model]="nContaAte" (selectedKeyChange)="nContaAte = $event" output="key"></entity-autocomplete>
                </div>
                <pl-button klass="btn btn-sm btn-primary action-add ms-auto" id="addNewLine" [onClick]="pesquisaContasPOC">
                  <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </pl-button>
              </div>
            </div>
          </dx-data-grid>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="pocscopy.steps.empresas.step" icon="fa-th-list" [disabled]="disableSteps || !selectedRowKeys || selectedRowKeys.length < 1">
        <div *plNavWizardStepContent>
          <h5 [translate]="'pocscopy.steps.empresas.title'"></h5>
          <p class="mt-2" [translate]="'pocscopy.steps.empresas.descriptions'"></p>

          <pl-multiselect [model]="empresasSel" [source]="empresasSource" key="nEmpresa" [template]="'{{nEmpresa}} - {{nomeEmpresa}}'"></pl-multiselect>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="pocscopy.steps.relatorio.step" icon="fa-flag-checkered" [disabled]="!selectedRowKeys || selectedRowKeys.length < 1 || !empresasSel || empresasSel.length < 1">
        <div *plNavWizardStepContent>
          <div *ngIf="!errorList.length">
            <h5 [translate]="'pocscopy.steps.relatorio.title'"></h5>
            <p class="mt-2" [translate]="'pocscopy.steps.relatorio.descriptions'"></p>
          </div>

          <div *ngIf="errorList.length > 0">
            <h5 [translate]="'pocscopy.steps.relatorio.titleerror'"></h5>
            <p class="mt-2" [translate]="'pocscopy.steps.relatorio.descriptionserror'"></p>

            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionErrorList" cgDxDataGridInstanceName="pocscopyerrorlist" [dataSource]="errorList"></dx-data-grid>
          </div>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
