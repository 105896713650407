import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN, ETipoContaDiasSubAlimentacao} from './tipoContaDiasSubAlimen.datasource.interface';

export const DATA_SOURCE_TIPOS_CONTA_DIAS_SUB_ALIMEN: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoContaDiasSubAlimentacao.NA, name: 'tipoContaDiasSubAlimentacao.data.notApplicable'},
    {value: ETipoContaDiasSubAlimentacao.Sim, name: 'tipoContaDiasSubAlimentacao.data.yes'},
    {value: ETipoContaDiasSubAlimentacao.Nao, name: 'tipoContaDiasSubAlimentacao.data.no'}
  ]
});
