import {NgModule} from '@angular/core';
import {PlSpinnerComponent} from './spinner.component';
import {CommonModule} from '@angular/common';

export * from './spinner.component';

const DECLARATIONS = [
  PlSpinnerComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlSpinnerModule {
}
