import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_REGISTAR_EMP_SS} from './rhRegistarEmpregadosSS.module.interface';
import {RHRegistarEmpregadosSSModuleComponent} from './components/rhRegistarEmpregadosSS.module.component';

export const MODULE_RH_REGISTAR_EMPREGADOS_SS: IModuleDefinition = {
  name: MODULE_NAME_RH_REGISTAR_EMP_SS,
  state: {
    url: `/${MODULE_NAME_RH_REGISTAR_EMP_SS}`,
    component: RHRegistarEmpregadosSSModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhregistarempregadosss'
    },
    params: {
      dgemp: {
        value: null,
        type: 'any'
      },
      registerTabActive: {
        value: false,
        type: 'bool'
      }
    }
  }
};
