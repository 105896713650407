import {Component, Injector, Input, OnInit} from '@angular/core';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonExportacaoComprasConfig, IJsonFamiliasConfig, IJsonGeofoliaFamilia} from '../../jsonExportacaoCompras.module.interface';
import {ExportacaoComprasService} from '../../exportacaoCompras.module.service';
import {IJsonFamilia} from '../../../../../../entities/familias/jsonFamilia.entity.interface';
import {TranslateService} from '@ngx-translate/core';
import {IExportacaoComprasConfigFamilias, IExportacaoComprasDocumentos, IExportacaoComprasTipoDocumentos, TABLE_LEGEND_FAMILIAS} from '../../exportacaoCompras.module.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnSaved,
  IDevExpressDataGridEventOnSaving
} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TTableLegend} from '../../../../../../components/tablelegend/tablelegend.component.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonDocfa} from '../../../../../../entities/docfas/jsonDocFa.entity.interface';

@Component({
  selector: 'modal-exportacaocompras-config',
  templateUrl: './exportacaoCompras.config.modal.component.html'
})
export class ExportacaoComprasConfigModalComponent extends CGModalComponent<IJsonExportacaoComprasConfig> implements OnInit {
  @Input() public config: IJsonExportacaoComprasConfig;
  @Input() public documentos: Array<IJsonDocfa>;
  @Input() public familias: Array<IJsonFamilia>;
  @Input() public geofoliaFamilias: Array<IJsonGeofoliaFamilia>;

  public dataGridDefinitionDocsComprasList: IDevExpressDataGrid<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>;
  public dataGridDefinitionDocsNotaCreditoList: IDevExpressDataGrid<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>;
  public dataGridDefinitionFamiliasList: IDevExpressDataGrid<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>;
  public dataDocsComprasList: Array<IExportacaoComprasDocumentos>;
  public dataDocsNotaCreditoList: Array<IExportacaoComprasDocumentos>;
  public dataFamiliasList: Array<IExportacaoComprasConfigFamilias>;
  public familiasDataGridLegend: TTableLegend;

  private _dataGridInstanceDocsComprasList: dxDataGrid<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>;
  private _dataGridInstanceDocsNotaCreditoList: dxDataGrid<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>;
  private _dataGridInstanceFamiliasList: dxDataGrid<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>;
  private _dataListTipoDocsCompras: Array<IExportacaoComprasTipoDocumentos>;
  private _dataListTipoDocsNotasCredito: Array<IExportacaoComprasTipoDocumentos>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _exportacaoComprasService: ExportacaoComprasService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataFamiliasList = [];
    this._dataListTipoDocsCompras = [];
    this._dataListTipoDocsNotasCredito = [];
  }

  public ngOnInit(): void {
    this.familiasDataGridLegend = TABLE_LEGEND_FAMILIAS;
    this._fillDataLists();
    this._filterTiposDocumentos();
    this._fillTiposDocumentos();

    this.dataGridDefinitionDocsComprasList = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'id',
          dataType: 'number',
          caption: 'exportacaoCompras.config.docsDataGrid.id',
          showEditorAlways: true,
          lookup: {
            dataSource: this._dataListTipoDocsCompras,
            valueExpr: 'id',
            displayExpr: (item: IExportacaoComprasTipoDocumentos) => `${item.id} - ${item.name}`
          }
        },
        {dataField: 'name', dataType: 'string', caption: 'exportacaoCompras.config.docsDataGrid.name', allowEditing: false},
        {dataField: 'desc', dataType: 'string', caption: 'exportacaoCompras.config.docsDataGrid.desc', allowEditing: false}
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell'
      },
      toolbar: {
        items: [{location: 'before', text: this._translateService.instant('exportacaoCompras.config.docsDataGrid.titleDocsCompras')}, 'addRowButton']
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false
    };

    this.dataGridDefinitionDocsNotaCreditoList = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'id',
          dataType: 'number',
          caption: 'exportacaoCompras.config.docsDataGrid.id',
          showEditorAlways: true,
          lookup: {
            dataSource: this._dataListTipoDocsNotasCredito,
            valueExpr: 'id',
            displayExpr: (item: IExportacaoComprasTipoDocumentos) => `${item.id} - ${item.name}`
          }
        },
        {dataField: 'name', dataType: 'string', caption: 'exportacaoCompras.config.docsDataGrid.name', allowEditing: false},
        {dataField: 'desc', dataType: 'string', caption: 'exportacaoCompras.config.docsDataGrid.desc', allowEditing: false}
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell'
      },
      toolbar: {
        items: [{location: 'before', text: this._translateService.instant('exportacaoCompras.config.docsDataGrid.titleDocsNotaCredito')}, 'addRowButton']
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false
    };

    this.dataGridDefinitionFamiliasList = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nFamiliaCG', dataType: 'number', caption: 'exportacaoCompras.config.familiasDataGrid.nFamCG', allowEditing: false},
        {dataField: 'nomeFamiliaCG', dataType: 'string', caption: 'exportacaoCompras.config.familiasDataGrid.nomeFamCG', allowEditing: false},
        {
          dataField: 'idGeo',
          dataType: 'string',
          caption: 'exportacaoCompras.config.familiasDataGrid.idFamGeo',
          showEditorAlways: true,
          lookup: {
            dataSource: this.geofoliaFamilias,
            valueExpr: 'id',
            displayExpr: (item: IJsonGeofoliaFamilia) => `${item.id} - ${item.artigo}`
          },
          sortOrder: 'desc'
        },
        {dataField: 'nomeFamiliaGeo', dataType: 'string', caption: 'exportacaoCompras.config.familiasDataGrid.nomeFamGeo', allowEditing: false},
        {dataField: 'operacaoGeo', dataType: 'string', caption: 'exportacaoCompras.config.familiasDataGrid.operacao', allowEditing: false}
      ],
      editing: {
        allowUpdating: true,
        mode: 'cell'
      },
      toolbar: {
        items: [{location: 'before', text: this._translateService.instant('exportacaoCompras.config.familiasDataGrid.title')}, 'searchPanel']
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false
    };
  }

  public onInitializedDocsComprasList({component}: IDevExpressDataGridEventOnInitialized<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>): void {
    this._dataGridInstanceDocsComprasList = component;
  }

  public onSavingDocs(event: IDevExpressDataGridEventOnSaving<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>): void {
    const eventDataSource: Array<IExportacaoComprasDocumentos> = event.component.getDataSource().items();
    event.changes.forEach((item) => {
      if (isUndefinedOrNull(item.data.id)) {
        this._plAlertService.warning('exportacaoCompras.mensagens.linhaObrigatoria');
        event.cancel = true;
      } else if (eventDataSource.some((value) => value.id === item.data.id)) {
        this._plAlertService.warning(this._translateService.instant('exportacaoCompras.mensagens.documentosDupsNotAllowed', {docNome: item.data.id}));
        event.cancel = true;
      }
    });
  }

  public onSavedDocsCompras(event: IDevExpressDataGridEventOnSaved<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>): void {
    event.changes.forEach((value, index: number) => {
      const docItem = this._dataListTipoDocsCompras.find((item) => item.id === event.changes[index].data.id);
      if (docItem) {
        this.dataDocsComprasList = this.dataDocsComprasList.map((item) => {
          if (event.changes[index].data.id === item.id) {
            return {...item, name: docItem.name, desc: docItem.desc};
          }
          return item;
        });
      }
    });
    this._dataGridInstanceDocsComprasList.refresh().then(() => undefined);
  }

  public onInitializedDocsNotaCreditoList({component}: IDevExpressDataGridEventOnInitialized<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>): void {
    this._dataGridInstanceDocsNotaCreditoList = component;
  }

  public onSavedDocsNotaCredito(event: IDevExpressDataGridEventOnSaved<IExportacaoComprasDocumentos, IExportacaoComprasDocumentos>): void {
    event.changes.forEach((value, index: number) => {
      const docItem = this._dataListTipoDocsNotasCredito.find((item) => item.id === event.changes[index].data.id);
      if (docItem) {
        this.dataDocsNotaCreditoList = this.dataDocsNotaCreditoList.map((item) => {
          if (event.changes[index].data.id === item.id) {
            return {...item, name: docItem.name, desc: docItem.desc};
          }
          return item;
        });
      }
    });
    this._dataGridInstanceDocsNotaCreditoList.refresh().then(() => undefined);
  }

  public onInitializedFamiliasList({component}: IDevExpressDataGridEventOnInitialized<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>): void {
    this._dataGridInstanceFamiliasList = component;
  }

  public onCellPreparedFamiliasList(event: IDevExpressDataGridEventOnCellPrepared<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'idGeo') {
        event.cellElement.classList.add('campo-edit');
      }
      if (event.row.data.idGeo === undefined) {
        event.cellElement.classList.add('linha-nao-configed');
      }
    }
  }

  public onSavingFamilias(event: IDevExpressDataGridEventOnSaving<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>): void {
    const eventDataSource: Array<IExportacaoComprasConfigFamilias> = event.component.getDataSource().items();
    event.changes.forEach((item) => {
      if (eventDataSource.some((value) => value.idGeo === item.data.idGeo)) {
        this._plAlertService.warning(this._translateService.instant('exportacaoCompras.mensagens.familiasDupsNotAllowed', {id: item.data.idGeo}));
        event.cancel = true;
      }
    });
  }

  public onRowUpdatedFamiliasList(event: IDevExpressDataGridEventOnRowUpdated<IExportacaoComprasConfigFamilias, IExportacaoComprasConfigFamilias>): void {
    const famItem = this.geofoliaFamilias.find((item) => item.id === event.data.idGeo);
    if (famItem) {
      this.dataFamiliasList = this.dataFamiliasList.map((item) => {
        if (event.data.idGeo === item.idGeo) {
          return {...item, nomeFamiliaGeo: famItem.artigo, operacaoGeo: famItem.tipoOperacao};
        }
        return item;
      });
    }
    this._dataGridInstanceFamiliasList.refresh();
  }

  public async close(): Promise<void> {
    this._parseDataToConfig();
    await this._exportacaoComprasService.saveConfig(this.config).then((response: HttpResponse<IJsonExportacaoComprasConfig>) => {
      this._plAlertService.success('exportacaoCompras.mensagens.configSuccess');
      super.close(response.body);
    });
  }

  private _fillDataLists(): void {
    this.dataDocsComprasList = [];
    this.dataDocsComprasList = this.config.docsCompra.map((value: number) => {
      return {id: value};
    });

    this.dataDocsNotaCreditoList = [];
    this.dataDocsNotaCreditoList = this.config.docsNotaCredito.map((value: number) => {
      return {id: value};
    });

    this.dataFamiliasList = [];
    this.familias.forEach((value: IJsonFamilia): void => {
      const item: IExportacaoComprasConfigFamilias = {
        nFamiliaCG: value.nFamilia,
        nomeFamiliaCG: value.nome
      };
      const configFamilia = this.config.familias.find((valueFam: IJsonFamiliasConfig) => valueFam.nFamilia === item.nFamiliaCG);
      if (isDefinedNotNull(configFamilia)) {
        item.idGeo = configFamilia.id;
        const geoFamilia = this.geofoliaFamilias.find((valueGeofoliaFamilia: IJsonGeofoliaFamilia) => valueGeofoliaFamilia.id === item.idGeo);
        if (geoFamilia) {
          item.nomeFamiliaGeo = geoFamilia.artigo;
          item.operacaoGeo = geoFamilia.tipoOperacao;
        }
      }
      this.dataFamiliasList.push(item);
    });
  }

  private _parseDataToConfig(): void {
    this.config.docsCompra = this.dataDocsComprasList.map((value: IExportacaoComprasDocumentos) => {
      return value.id;
    });

    this.config.docsNotaCredito = this.dataDocsNotaCreditoList.map((value: IExportacaoComprasDocumentos) => {
      return value.id;
    });

    this.config.familias = this.dataFamiliasList.map((value: IExportacaoComprasConfigFamilias) => {
      return {id: value.idGeo, nFamilia: value.nFamiliaCG};
    });
  }

  private _filterTiposDocumentos(): void {
    this._dataListTipoDocsCompras = this.documentos.reduce((filtered: Array<IExportacaoComprasTipoDocumentos>, value: IJsonDocfa) => {
      if (value.tipoMovimentoStock === -1) {
        filtered.push({id: value.nDocFa, name: value.nome, desc: value.descricao});
      }
      return filtered;
    }, []);

    this._dataListTipoDocsNotasCredito = this.documentos.reduce((filtered: Array<IExportacaoComprasTipoDocumentos>, value: IJsonDocfa) => {
      if (value.tipoMovimentoStock === 0) {
        filtered.push({id: value.nDocFa, name: value.nome, desc: value.descricao});
      }
      return filtered;
    }, []);
  }

  private _fillTiposDocumentos(): void {
    this.dataDocsComprasList.forEach((value, index) => {
      const itemDocumento = this._dataListTipoDocsCompras.find((valueDoc) => valueDoc.id === value.id);
      if (itemDocumento) {
        this.dataDocsComprasList[index].name = itemDocumento.name;
        this.dataDocsComprasList[index].desc = itemDocumento.desc;
      }
    });

    this.dataDocsNotaCreditoList.forEach((value, index) => {
      const itemDocumento = this._dataListTipoDocsNotasCredito.find((valueDoc) => valueDoc.id === value.id);
      if (itemDocumento) {
        this.dataDocsNotaCreditoList[index].name = itemDocumento.name;
        this.dataDocsNotaCreditoList[index].desc = itemDocumento.desc;
      }
    });
  }
}
