<div class="docsnaosaldados">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'docsnaosaldados.fields.doPeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="doPeriodo"
                [model]="model"
                (modelChange)="changedDePeriodo($event)"
                [fieldsMap]="{periodo: 'dePeriodo', nome: 'dePeriodoNome'}"
                [helperMode]="true"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="model"
                (modelChange)="changedAtePeriodo($event)"
                [filter]="'loadOnlyAno=' + loadOnlyAno"
                [fieldsMap]="{periodo: 'atePeriodo', nome: 'atePeriodoNome'}"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'docsnaosaldados.fields.deConta'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="daConta" [(selectedKey)]="model.deConta" [fieldsMap]="{nconta: 'deConta'}" output="nConta"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="ateConta" [(selectedKey)]="model.ateConta" [fieldsMap]="{nconta: 'ateConta'}" output="nConta"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="docsNaoSaldados" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onDataGridInitialized($event)">
    <div *dxTemplate="let item of 'actions'" class="text-center">
      <button type="button" class="btn btn-sm btn-warning" [click]="fnEditDocument(item.data.extPocCabID)" [attr.title]="'reembolsosiva.action.editDoc' | translate" plPromise>
        <i class="fa fa-fw fa-pencil"></i><span class="d-sm-none d-md-inline-block">&nbsp;<span [translate]="'reembolsosiva.action.editDoc'"></span></span>
      </button>
    </div>
  </dx-data-grid>
</div>
