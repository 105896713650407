import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AtivosImportService} from '../../ativosImport.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosImport} from '../../jsonAtivosImport.entity.interface';
import {PlAlertService} from 'pl-comps-angular';

const DEFAULT_PERCENT_REDUCAO = 50;

@Component({
  selector: 'modal-ativos-import-atpercreducao',
  templateUrl: './ativosImport.atPercReducao.modal.component.html'
})
export class AtivosImportAtPercReducaoModalComponent extends CGModalComponent<Array<IJsonAtivosImport>> {
  @Input() public ativosimport: Array<IJsonAtivosImport>;
  @Input() public onlyToImport: boolean;
  public promise: Promise<void>;
  public todosanos: boolean;
  public ano: number;
  public perc: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImportService: AtivosImportService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.todosanos = true;
    this.ano = this._configService.configurations.empresa.anoEmCursoIRC - 1;
    this.perc = DEFAULT_PERCENT_REDUCAO;
  }

  public close(): Promise<void> {
    if (this.promise) {
      return Promise.resolve();
    }
    return this._ativosImportService
      .postAtribuirPercentagemDeReducao(this.ativosimport, this.onlyToImport, this.todosanos, this.ano, this.perc)
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.atpercred');
        super.close(response.body);
      });
  }
}
