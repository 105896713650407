<div class="keybindings-helper">
  <pl-tooltip [config]="tooltip">
    <i class="fa fa-keyboard-o keybindings-helper-icon" *plTooltipContent aria-hidden="true"></i>
  </pl-tooltip>
</div>

<ng-template #templateActions>
  <table class="table keybindings-helper-table">
    <thead>
      <tr>
        <th colspan="2" [translate]="'components.keybindingshelper.header'"></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let action of helperActions">
        <tr *ngIf="action.visible !== false">
          <td [translate]="action.description"></td>
          <td>
            <span class="keybindings-helper-actions">
              <span class="keybindings-helper-actions-key" *ngIf="action.key">
                <strong>"{{ action.key }}"</strong>
              </span>
              <span class="keybindings-helper-actions-key" *ngIf="action.keys && action.keys.length">
                <span class="keybindings-helper-action-keys" *ngFor="let key of action.keys">
                  <strong>"{{ key }}"</strong>
                </span>
              </span>
              <span class="keybindings-helper-actions-key" *ngIf="action.combination && action.combination.length">
                "<span class="keybindings-helper-action-keys-combination" *ngFor="let key of action.combination">
                  <strong>{{ key }}</strong> </span
                >"
              </span>
            </span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>
