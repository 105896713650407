import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {PlPipesModule} from '../pipes/pipes.module';
import {PlTranslateModule} from '../translate/translate.module';
import {PlSelectComponent} from './select.component';

export * from './select.component.interface';
export * from './select.component';
export * from './select.di';

const DECLARATIONS = [PlSelectComponent];

@NgModule({
  imports: [CommonModule, FormsModule, PlPipesModule, PlTranslateModule, NgSelectModule],
  declarations: DECLARATIONS,
  exports: [NgSelectModule, ...DECLARATIONS]
})
export class PlSelectModule {}
