import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_LOCAL_OBTENCAO_RENDIMENTOS, IDataSourceItemLocalObtRend} from './localObtencaoRendimentos.datasource.interface';

export const DATA_SOURCE_LOCAL_OBTENCAO_RENDIMENTOS: IDataSource<string, IDataSourceItemLocalObtRend> = deepFreeze({
  name: DATA_SOURCE_NAME_LOCAL_OBTENCAO_RENDIMENTOS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: 'name'
  },
  data: [
    {value: 'C', name: 'localObtencaoRendimentos.data.continente'},
    {value: 'RA', name: 'localObtencaoRendimentos.data.regiaoAcores'},
    {value: 'RM', name: 'localObtencaoRendimentos.data.regiaoMadeira'},
    {value: 'E', name: 'localObtencaoRendimentos.data.estrangeiro'}
  ]
});
