<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoEFaturas.extratosModalTitle'"></h4>
</div>

<div class="modal-body">
  <cg-contab-extratos [filters]="filters" [processOnInit]="procOnInit" [callback]="callback"></cg-contab-extratos>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn btn-success" (evtClicked)="process()"><i class="fa fa-fw fa-gears"></i>&nbsp;<span [translate]="'global.btn.process'"></span></pl-button>

  <pl-button klass="btn btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
