import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {IPlTooltipConfig, isObject, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../exceptions/exceptions.service';
import {CGModalComponent} from '../cgmodal.component';
import {compareSemanticVersion} from '../../../../../common/utils/utils';
import {DATE_UPDATED_TERMS, REJECTED_TERMS_MODAL} from './terms.modal.interface';
import {ICGExceptionError} from '../../../exceptions/exceptions.service.interface';
import {ISemanticVersionComparison} from '../../../../../common/interfaces/interfaces';
import {Moment} from 'moment';
import {TermsAcceptedService} from '../../../../services/termsaccepted/termsaccepted.service';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'cg-terms-modal',
  templateUrl: './terms.modal.component.html'
})
export class CGTermsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public session: TUserSession;
  @Input() public cancelable: boolean;
  @Input() public viewOnly: boolean;

  public readonly dateUpdated: Moment;
  public readonly tooltip: IPlTooltipConfig;
  public version: string;
  public firstTime: boolean;
  public accepted: boolean;
  public acceptedOnce: boolean;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _termsAcceptedService: TermsAcceptedService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this.cancelable = false;
    this.viewOnly = false;
    this.dateUpdated = DATE_UPDATED_TERMS;
    this.tooltip = {disabled: false, text: 'terms.acceptTooltip'};
    this.firstTime = true;
    this.accepted = false;
    this.acceptedOnce = false;
    this.promise = this._termsAcceptedService.termsRGPDVersion().then((value: string) => {
      this.version = value;
    });
  }

  public ngOnInit(): void {
    Promise.resolve(this.promise).then(() => {
      this.firstTime = !isObject(this.session) || !this.session.termsAccepted;
      if (!this.firstTime) {
        const comparison: ISemanticVersionComparison = compareSemanticVersion(this.session.termsAccepted, this.version);
        this.accepted = comparison.equal();
        if (this.accepted) {
          this.firstTime = true;
        }
      }
    });
  }

  public close(): Promise<void> {
    if (this.viewOnly || !this.accepted) {
      return Promise.reject(new Error("Didn't accept terms and conditions"));
    }
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(this.promise).finally(() => {
        this._termsAcceptedService
          .acceptTerms(this.version, {reportExceptions: false})
          .then(() => {
            super.close();
            resolve();
          })
          .catch((reason: HttpErrorResponse) => {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            if (exception.class === 'EAlreadyAcceptedTerms') {
              super.close();
              resolve();
            } else {
              if (exception.message) {
                this._plAlertService.error(exception.message);
              }
              reject(reason);
            }
          });
      });
    });
  }

  public dismiss(): void {
    super.dismiss(REJECTED_TERMS_MODAL);
  }

  public changedAccepted(value: boolean): void {
    this.accepted = value;
    this.tooltip.disabled = this.accepted;
    if (this.accepted && !this.acceptedOnce) {
      this.acceptedOnce = true;
    }
  }
}
