import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonGrDesconto} from './jsonGrDescontos.entity.interface';
import {ROLE} from '../../services/role.const';
import {DATA_SOURCE_NAME_TIPOS_REMUNERADO} from '../../datasources/tiporemunerado/tipoRemunerado.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN} from '../../datasources/tipodiascontasubalimen/tipoContaDiasSubAlimen.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_AUSENCIAS} from '../../datasources/tipoausencias/tipoAusencias.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_CATEGORIA_DESCONTOS} from '../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ENTITY_NAME_GR_DESCONTOS} from './grDescontos.entity.interface';

export const ENTITY_GR_DESCONTOS: IEntityDefinition<IJsonGrDesconto> = {
  name: ENTITY_NAME_GR_DESCONTOS,
  roles: [ROLE.RH],
  searchPlaceholder: 'grdescontos.pesquisa',
  metadata: {
    keyName: 'codGrDesconto',
    fields: [
      {name: 'codGrDesconto', type: 'string', caption: 'grdescontos.fields.codGrDesconto', validators: {required: true}},
      {name: 'descricao', type: 'string', caption: 'grdescontos.fields.descricao', validators: {required: true}},
      {name: 'categoria', type: DATA_SOURCE_NAME_TIPOS_CATEGORIA_DESCONTOS, caption: 'grdescontos.fields.categoria'},
      {name: 'remunerado', type: DATA_SOURCE_NAME_TIPOS_REMUNERADO, caption: 'grdescontos.fields.remunerado'},
      {name: 'ausencias', type: DATA_SOURCE_NAME_TIPOS_AUSENCIAS, caption: 'grdescontos.fields.ausencias'},
      {name: 'contaDiasSubAlimen', type: DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN, caption: 'grdescontos.fields.contaDiasSubAlimen'}
    ],
    order: 'codGrDesconto',
    searchFields: 'codGrDesconto,descricao',
    listFields: 'codGrDesconto,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codGrDesconto}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codGrDesconto,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.grdescontos'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.grdescontos'
      }
    },
    template: {
      items: [
        {type: 'field', field: 'codGrDesconto', order: 1},
        {type: 'field', field: 'descricao', order: 2},
        {type: 'field', field: 'categoria', order: 3},
        {type: 'field', field: 'ausencias', order: 4},
        {type: 'group', order: 5, fields: [{field: 'remunerado'}, {field: 'contaDiasSubAlimen'}]}
      ]
    }
  }
};
