import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonDocContabilidade} from '../../jsonDocContabilidade.interface';
import {DocsContabilidadeService} from '../../service/docsContabilidade.service';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';

@Component({
  selector: 'docscontabilidade-print-modal',
  templateUrl: './docsContabilidade.print.modal.component.html'
})
export class DocsContabilidadePrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public doc: IJsonDocContabilidade;
  @Input() public email: string;

  public url: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._docsContabilidadeService.getPdfUrl(this.doc.extPocCabID, this.doc.periodo).subscribe((url: string) => {
      this.url = url;
    });
  }

  public readonly fnSendEmail = (): Promise<void> => this._sendEmail();

  private _sendEmail(): Promise<void> {
    return this._docsContabilidadeService
      .sendEmail(this.doc.extPocCabID, this.email, this.doc.periodo)
      .then(() => {
        this._plAlertService.success(this._plTranslateService.translate('docscontabilidadeimprimir.email.emailEnviado', {email: this.email}));
      })
      .catch(() => {
        this._plAlertService.error(this._plTranslateService.translate('docscontabilidadeimprimir.email.emailErro', {email: this.email}));
      });
  }
}
