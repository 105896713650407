import {AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, ContentChildren, Directive, ElementRef, Injector, Input, QueryList, TemplateRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {applyMaximumBodyHeight} from '../modals.utils';
import {CGCModalContentDirective} from './modal.content.directive';
import {CGCModalHeaderDirective} from './modal.header.directive';
import type {ICGCModalComponent, ICGCModalOptions} from '../service/modal.service.interface';

@Directive()
export class CGCModalComponent<T> implements AfterContentChecked, AfterViewInit, AfterViewChecked, ICGCModalComponent<T> {
  @Input() public modalOptions: ICGCModalOptions;
  public templateHeader: TemplateRef<object>;
  public templateContent: TemplateRef<object>;

  protected readonly _modalInstance: NgbActiveModal;
  protected readonly _changeDetectorRef: ChangeDetectorRef;
  protected readonly _element: HTMLElement;

  @ContentChildren(CGCModalHeaderDirective, {descendants: false}) private readonly _templateHeader: QueryList<CGCModalHeaderDirective>;
  @ContentChildren(CGCModalContentDirective, {descendants: false}) private readonly _templateContent: QueryList<CGCModalContentDirective>;
  private readonly _elementRef: ElementRef<HTMLElement>;
  private _closeDisabled: boolean;

  constructor(protected readonly _injector: Injector) {
    this._modalInstance = this._injector.get<NgbActiveModal>(NgbActiveModal);
    this._changeDetectorRef = this._injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this._elementRef = this._injector.get<ElementRef<HTMLElement>>(ElementRef);
    this._element = this._elementRef.nativeElement;
    this._closeDisabled = false;
  }

  public ngAfterContentChecked(): void {
    let templateRefHeader: TemplateRef<object>;
    let templateRefContent: TemplateRef<object>;

    const header: CGCModalHeaderDirective = this._templateHeader.first;
    if (header?.templateRef) {
      templateRefHeader = header.templateRef;
    } else if (this.modalOptions) {
      templateRefHeader = this.modalOptions.templateRefHeader;
    }
    this.templateHeader = templateRefHeader;

    const content: CGCModalContentDirective = this._templateContent.first;
    if (content?.templateRef) {
      templateRefContent = content.templateRef;
    } else if (this.modalOptions) {
      templateRefContent = this.modalOptions.templateRefContent;
    }
    this.templateContent = templateRefContent;
  }

  public ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  public ngAfterViewChecked(): void {
    applyMaximumBodyHeight(this._element);
  }

  public close(result?: T): void {
    this._modalInstance.close(result);
  }

  public dismiss(reason?: unknown): void {
    this._modalInstance.dismiss(reason);
  }

  public beforeDismiss(): boolean | Promise<boolean> {
    return true;
  }

  public enableClose(): void {
    this._closeDisabled = false;
  }

  public disableClose(): void {
    this._closeDisabled = true;
  }

  public fnCloseWithArgs(result?: T): () => unknown {
    return () => {
      this.close(result);
    };
  }

  public fnDismissWithArgs(reason?: unknown): () => unknown {
    return () => {
      this.dismiss(reason);
    };
  }

  public readonly fnClose: (result?: T) => unknown = (result?: T) => {
    this.close(result);
  };

  public readonly fnDismiss: (reason?: unknown) => unknown = (reason?: unknown) => {
    this.dismiss(reason);
  };

  public get closeDisabled(): boolean {
    return this._closeDisabled;
  }
}
