import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../../components/reports/reports.registry.service';
import {ETipoExport} from '../../../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {EReport} from '../../../../../../entities/reports/reports.interface';
import {IExtratosDTOutputParams} from '../../extratosDT.module.interface';
import {ContabilidadeEstatisticaService} from '../../../contabilidadeEstatistica.module.service';

@Component({
  selector: 'extratosdt-print-modal',
  templateUrl: './extratosDT.print.modal.component.html'
})
export class ExtratosDTPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public filters: IExtratosDTOutputParams;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;
  public tipoExport: ETipoExport;
  public readonly tiposExport: typeof ETipoExport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService
  ) {
    super(_injector);
    this.loadPdf = this.loadPdf.bind(this);
    this.tipoExport = ETipoExport.PDF;
    this.tiposExport = ETipoExport;
  }

  public ngOnInit(): void {
    this.reportType = EReport.ExtratosDT;
    this._loadDefaultReport();
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
  }

  public loadPdf(): void {
    this._contabilidadeEstatisticaService.getExtratosDTPdf(this.reportModel.name, this.filters.nConta, this.filters.deData, this.filters.ateDate).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportsRegistryService
      .get(EReport.ExtratosDT)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
          this.loadPdf();
        }
      });
  }
}
