import {NgModule} from '@angular/core';
import {PlMediaDevicesCameraCaptureImageModule} from './camera/captureimage/mediadevices.camera.captureimage.module';

export * from './service/mediadevices.service.interface';
export * from './service/mediadevices.service';
export * from './camera/captureimage/mediadevices.camera.captureimage.module';

const MEDIA_DEVICES_MODULES = [PlMediaDevicesCameraCaptureImageModule];

@NgModule({
  imports: MEDIA_DEVICES_MODULES,
  exports: MEDIA_DEVICES_MODULES
})
export class PlMediaDevicesModule {}
