import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosConsistenciaPeriodo} from '../../jsonAtivosConsistencia.module.interface';
import {AtivosConsistenciaService} from '../../ativosConsistencia.module.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'modal-ativos-consistencia-lanca-dif',
  templateUrl: './ativosConsistencia.lancaDif.modal.component.html'
})
export class AtivosConsistenciaLancaDifModalComponent extends CGModalComponent<string> {
  public readonly outputPeriodo: string;
  public model: IJsonAtivosConsistenciaPeriodo;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosConsistenciaService: AtivosConsistenciaService
  ) {
    super(_injector);
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    if (!this.model) {
      this.model = {
        periodo: '',
        nomePeriodo: ''
      };
    }
    this._getDefaultPeriodo();
  }

  public close(): void {
    super.close(this.model.periodo);
  }

  private _getDefaultPeriodo(): Promise<void> {
    return this._ativosConsistenciaService.getCarregaPeriodoDefault().then((response: HttpResponse<IJsonAtivosConsistenciaPeriodo>) => {
      this.model = response.body;
    });
  }
}
