import {Moment, MomentInput} from 'moment';
import {HttpResponse} from '@angular/common/http';
import {isArray, isObject} from 'pl-comps-angular';
import {CALENDARIO_CAB_ID_SISTEMA, ECalendarioTipoDia, IJsonCalendario, IJsonCalendarioCab, IJsonCalendarioData, IJsonCalendarioNDiasUteis} from './jsonCalendarios.entity.interface';
import {COLABORADORES_GESTAO_DGEMPS_GET_CALENDARIO_PARAMS} from '../../modules/colaboradores/gestaodgemps/colaboradores.gestaodgemps.module.interface';
import {ENTITY_NAME_CALENDARIOS, ICalendariosEntity, ICalendariosEntityService, ICalendariosGetParams, TCalendariosEntityServiceRequestData} from './calendarios.entity.interface';
import {ROLE} from '../../services/role.const';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../../services/api/api.service.interface';

export const ENTITY_CALENDARIOS: ICalendariosEntity = {
  name: ENTITY_NAME_CALENDARIOS,
  roles: [ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'calendarios.pesquisa',
  pageTitle: 'global.menu.calendarios',
  metadata: {
    keyName: 'calenCabID',
    fields: [
      {name: 'calenCabID', type: 'integer', caption: 'calendarios.fields.calenCabID', validators: {required: true, max: 4}},
      {name: 'nome', caption: 'calendarios.fields.nome', validators: {required: true, maxlength: 100}},
      {name: 'sabadosDiaUtil', type: 'boolean', caption: 'calendarios.fields.sabadosDiaUtil'},
      {name: 'domingosDiaUtil', type: 'boolean', caption: 'calendarios.fields.domingosDiaUtil'},
      {name: 'naoTemFeriado', type: 'boolean', caption: 'calendarios.fields.naoTemFeriado'}
    ],
    order: 'calenCabID',
    searchFields: 'calenCabID,nome',
    listFields: 'calenCabID,nome'
  },
  autocomplete: {
    rowTemplate: '{{calenCabID}} - {{nome}}',
    output: 'nome',
    searchFields: 'calenCabID,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  service: function () {
    const superGet: ICalendariosEntityService['get'] = this.get.bind(this);

    this.get = <TResponse = IJsonCalendario>(requestData: number | TCalendariosEntityServiceRequestData): TServiceResponse<TResponse> => {
      let id: number;
      let params: ICalendariosGetParams;
      if (isObject(requestData)) {
        id = <number>(<TCalendariosEntityServiceRequestData>requestData).id;
        params = (<TCalendariosEntityServiceRequestData>requestData).params;
      } else {
        id = <number>requestData;
      }
      return superGet<TResponse>({
        id: id,
        params: {
          datade: params?.dataDe,
          dataate: params?.dataAte,
          holidaysonly: params?.holidaysOnly,
          tiposdia: isArray(params?.tiposDia) ? params.tiposDia.join() : undefined,
          excludetiposdia: isArray(params?.excludeTiposDia) ? params.excludeTiposDia.join() : undefined
        }
      });
    };

    this.getDefault = (params?: ICalendariosGetParams): TServiceResponse<IJsonCalendario> => {
      return this.get({id: CALENDARIO_CAB_ID_SISTEMA, params: params});
    };

    this.getCabecalho = (calenCabID: number): TServiceResponse<IJsonCalendarioCab> => {
      return this.get<IJsonCalendarioCab>({id: `${calenCabID}/cabecalho`});
    };

    this.getDefaultCabecalho = (): TServiceResponse<IJsonCalendarioCab> => {
      return this.getCabecalho(CALENDARIO_CAB_ID_SISTEMA);
    };

    this.getNDiasCalendarioPorTipo = (calenCabID: number, dataInicial: TDate, dataFim: TDate, tipoDia: ECalendarioTipoDia, excluiFimdeSemana: boolean): TServiceResponse<IJsonCalendarioNDiasUteis> => {
      return this.apiService.get<IJsonCalendarioNDiasUteis>({
        url: `${this.entityUrl()}/${calenCabID}/nDiasCalendarioPorTipo`,
        params: {
          dataInicial: dataInicial,
          dataFim: dataFim,
          tipoDia: tipoDia,
          excluiFimdeSemana: excluiFimdeSemana
        }
      });
    };

    this.gerarCalendarioParaAno = (calenCabID: number, ano: number): TServiceResponse<void> => {
      return this.post<void>({
        url: `${calenCabID}/gerar`,
        params: {ano: ano}
      });
    };

    this.getHolidaysDates = (viewDate: Moment): Promise<Array<MomentInput>> => {
      const dataDe: Moment = viewDate.clone().startOf('year');
      const dataAte: Moment = dataDe.clone().endOf('year');
      const params: ICalendariosGetParams = {
        ...COLABORADORES_GESTAO_DGEMPS_GET_CALENDARIO_PARAMS,
        dataDe: dataDe,
        dataAte: dataAte
      };
      return this.getDefault(params).then((response: HttpResponse<IJsonCalendario>) => {
        return response.body.datas
          .filter((dataCalendario: IJsonCalendarioData) => dataCalendario.tipoDia !== ECalendarioTipoDia.DiaUtil)
          .map<MomentInput>((dataCalendario: IJsonCalendarioData) => dataCalendario.data);
      });
    };
  }
};
