<div class="modal-planos-contas-alternativos-extrato modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="captionTitle"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
