import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isBoolean, isEmpty, isObject, timeout} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IJsonBancosExtratoPreDefCfg} from '../../jsonBancosExtrato.module.interface';
import {IJsonBankPreDefCfg, IJsonEntidadeBancariaTransaction} from '../../../../interfaces/jsonEntidadeBancaria.interface';
import {MODULE_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../../portalcontabilidade/manutencao/predefinidos/preDefinidosContab.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IEntityAutocompleteCustomActionDefinition} from '../../../../components/entity/entity.autocomplete.definition.interface';

@Component({
  selector: 'bancos-extrato-module-predefdesc-modal',
  templateUrl: './bancosExtrato.predefdesc.modal.component.html'
})
export class BancosExtratoModulePredefDescModalComponent extends CGModalComponent<IJsonBankPreDefCfg> implements OnInit {
  @Input() public simpleMode: boolean;
  @Input() public transaction: IJsonEntidadeBancariaTransaction;
  @Input() public initialData: Partial<IJsonBankPreDefCfg>;

  public readonly filterConta: string;
  public readonly filterPredefinido: string;
  public readonly customActionPreDefinido: IEntityAutocompleteCustomActionDefinition;
  public model: IJsonBankPreDefCfg;
  public promise: Promise<void>;

  private _updating: boolean;
  private _maintenancePreDefinidos: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.simpleMode = false;
    this.filterConta = `tipo=${ETipoContaContabilidade.Movimento}&bloqueada=false`;
    this.filterPredefinido = 'cgBanking=true';
    this.customActionPreDefinido = {
      caption: 'docscontabilidade.text.maintenancePreDefinidos',
      action: (selectedKey: number) => {
        if (!this._maintenancePreDefinidos) {
          this._maintenancePreDefinidos = this._moduleMaintenanceService.build(MODULE_NAME_CONTABILIDADE_PREDEFINIDOS);
        }
        return this._maintenancePreDefinidos.maintenance({params: {editPreDefinidosID: selectedKey}});
      }
    };
    this.model = {
      bankPreDefCfgId: undefined,
      bankAccountId: undefined,
      descriptionKey: undefined,
      preDefinidosID: undefined,
      preDefinidosNome: undefined,
      nConta: undefined,
      nomeConta: undefined
    };
    this._updating = false;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.simpleMode)) {
      this.simpleMode = false;
    }
    if (isObject(this.initialData)) {
      this.model = {
        ...this.model,
        ...this.initialData
      };
    }
    if (!isEmpty(this.model.bankPreDefCfgId)) {
      this._updating = true;
      this.promise = this._bancosExtratoService.getPredefDesc(this.model.bankPreDefCfgId).then((response: HttpResponse<IJsonBankPreDefCfg>) => {
        this.model = response.body;
        if (!this.model.preDefinidosID) {
          this.model.preDefinidosID = undefined;
          this.model.preDefinidosNome = undefined;
        }
      });
    }
    Promise.resolve(this.promise).finally(() => {
      this.promise = undefined;
      timeout().then(() => {
        const element: HTMLInputElement = this._element.querySelector<HTMLInputElement>('input[name="descricao"]');
        focusElement(element);
      });
    });
  }

  public close(): Promise<void> {
    this.disableClose();
    const bancosExtratoPreDefCfg: IJsonBancosExtratoPreDefCfg = {
      preDefCfg: this.model,
      transaction: this.transaction
    };
    return (!this._updating ? this._bancosExtratoService.insertPredefDesc(bancosExtratoPreDefCfg) : this._bancosExtratoService.updatePredefDesc(bancosExtratoPreDefCfg))
      .then((response: HttpResponse<IJsonBancosExtratoPreDefCfg>) => {
        this.enableClose();
        super.close(response.body.preDefCfg);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
