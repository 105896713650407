import {merge, union, uniq} from 'lodash-es';
import dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  copy,
  EMonth,
  IPlDynamicVisualsSecondaryClickAction,
  IPlLifeCycleEvent,
  isArray,
  isDefined,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import moment, {Moment, MomentInput} from 'moment';
import {
  AT_FATURAS_RECIBOS_VERDES_SITUACAO_TODOS,
  AT_FATURAS_RECIBOS_VERDES_TIPO_TODOS,
  IATFaturasRecibosVerdesFilter,
  IATFaturasRecibosVerdesResultFindInSource,
  IATFaturasRecibosVerdesSituacao,
  IATFaturasRecibosVerdesTipo,
  IFRVImportDoc,
  IFRVImportDocApiList
} from './atFaturasRecibosVerdes.module.interface';
import {AtFaturasRecibosVerdesConfigModalComponent} from './modal/config/atFaturasRecibosVerdes.config.modal';
import {AtFaturasRecibosVerdesLancarDocsEmSerieModalComponent} from './modal/lancarDocsEmSerie/atFaturasRecibosVerdes.lancarDocsEmSerie.modal';
import {AtFaturasRecibosVerdesNifConfigModalComponent} from './modal/nifConfig/atFaturasRecibosVerdes.nifConfig.modal';
import {AtFaturasRecibosVerdesNovaContaCorrenteModalComponent} from './modal/novaContaCorrente/atFaturasRecibosVerdes.novaContaCorrente.modal';
import {AtFaturasRecibosVerdesService} from './atFaturasRecibosVerdes.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {DocContabilidadeService} from '../docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../docscontabilidade/service/docsContabilidade.service';
import {EATFaturasRecibosVerdesJaLancado, IJsonFRVDocContabilidade, IJsonFRVErro, IJsonFRVImportDocApiList, IJsonFRVNIFConfig} from './jsonATFaturasRecibosVerdes.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {EXCEPTION_AT_FRV_CALL_LOGIN} from '../../../../common/exceptions';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, TDevExpressDataGridSelection} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowCollapsed,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDocContabilidadeLoadPreDefinidoParams} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../docscontabilidade/jsonDocContabilidade.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {SimpleLoginModalComponent} from '../../simplelogin/modals/simpleLogin.modal.component';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';

const BTN_CONFIG_ID = 'config';
const SAVE_PROMPT_IDENTIFIER = 'at-frv-doc';
const TOOLBAR_GROUP_ID = 'at-faturas-recibos-verdes-toolbars';

@Component({
  selector: 'module-at-faturas-recibos-verdes',
  templateUrl: './atFaturasRecibosVerdes.module.component.html'
})
export class AtFaturasRecibosVerdesModuleComponent extends ModuloComponent implements OnInit {
  @Input() public tiposFatura: Array<IATFaturasRecibosVerdesTipo>;
  @Input() public situacoesFatura: Array<IATFaturasRecibosVerdesSituacao>;

  public readonly tiposJaLancado: typeof EATFaturasRecibosVerdesJaLancado;
  public tableSource: Array<IFRVImportDoc>;
  public tableSourceLancIgnored: Array<IFRVImportDoc>;
  public dataGridDefinition: IDevExpressDataGrid<IFRVImportDoc, number>;
  public filters: IATFaturasRecibosVerdesFilter;
  public promise: Promise<void>;
  public selectedTab: string;
  public lancarDocsEmSerie: boolean;
  public lancarDocsEmSerieCount: number;

  private readonly _docsModel: Map<string, IJsonDocContabilidade>;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid<IFRVImportDoc, number>;
  private _firstLoad: boolean;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atFaturasRecibosVerdesService: AtFaturasRecibosVerdesService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.search = this.search.bind(this);
    this.tiposJaLancado = EATFaturasRecibosVerdesJaLancado;
    this._docsModel = new Map<string, IJsonDocContabilidade>();
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.selectedTab = 'tabDocsEFatura';
    this._applyDataGridDefinition();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: BTN_CONFIG_ID,
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._configButtonClick()
    });
    this._firstLoad = true;
    this.lancarDocsEmSerieCount = 0;
    this.tableSource = [];
    this.tableSourceLancIgnored = [];
    if (!isArray(this.tiposFatura)) {
      this.tiposFatura = [{...AT_FATURAS_RECIBOS_VERDES_TIPO_TODOS}];
    }
    if (!isArray(this.situacoesFatura)) {
      this.situacoesFatura = [{...AT_FATURAS_RECIBOS_VERDES_SITUACAO_TODOS}];
    }
    const fromDate: Moment = moment().startOf('year');
    const toDate: Moment = fromDate.clone().month(EMonth.February).endOf('month');
    this.filters = {
      fromDate: fromDate,
      toDate: toDate,
      tipo: this.tiposFatura[0],
      situacao: this.situacoesFatura[0]
    };
    const momObj = moment(this._configService.configurations.empresa.periodo, 'YYYYMMD');
    if (isDefined(momObj) && momObj.isValid()) {
      this.filters.fromDate = momObj.clone().startOf('month');
      this._fillToDate();
    }
  }

  public async search(): Promise<void> {
    try {
      await this.pesquisar();
      if (this.tableSource?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  public async pesquisar(): Promise<void> {
    this._firstLoad = false;
    this.lancarDocsEmSerieCount = 0;
    this.lancarDocsEmSerieChange(false);
    await this._getTableSource();
  }

  public changeTab(nextId: string): void {
    this.selectedTab = nextId;
    this._applyDataGridDefinition();
  }

  public async getTableSources(): Promise<IJsonFRVImportDocApiList> {
    if (this._firstLoad) {
      return {list: [], listLancadosIgnorados: []};
    }
    try {
      const response = await this._atFaturasRecibosVerdesService.getAtList(this.filters.fromDate, this.filters.toDate);
      return response.body;
    } catch (reason: unknown) {
      if (reason instanceof HttpErrorResponse) {
        const cgException = this._cgExceptionService.get(reason);
        if (cgException.class === EXCEPTION_AT_FRV_CALL_LOGIN) {
          this._cgModalService.show(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'}).then(() => {
            this._plAlertService.success('simpleLogin.messages.successLogin');
            this.pesquisar();
          });
        } else {
          this._plAlertService.error(cgException.message);
        }
      }
      return {list: [], listLancadosIgnorados: []};
    }
  }

  public async ignoreRecibo(item: IFRVImportDoc): Promise<void> {
    await this._atFaturasRecibosVerdesService.ignorarRecibo(item.nifAdquirente, item.numDocumento);
    this._plAlertService.success(this._plTranslateService.translate('atFRV.messages.docIgnoradoSuccessMsg', {ndoc: item.numDocumento}));
    await this.pesquisar();
  }

  public async ignoreNif(item: IFRVImportDoc): Promise<void> {
    await this._atFaturasRecibosVerdesService.ignorarSempreNif(item.nifAdquirente, item.nomeAdquirente);
    this._plAlertService.success(
      this._plTranslateService.translate('atFRV.messages.nifIgnoradoSuccessMsg', {
        nif: item.nomeAdquirente,
        nome: item.nomeAdquirente
      })
    );
    await this.pesquisar();
  }

  public async openNifConfigModal(item: IFRVImportDoc): Promise<void> {
    const response: IJsonFRVNIFConfig = await this._atFaturasRecibosVerdesService.getNifConfig(item.nifAdquirente);
    const listNumDocWithSameNIF: Array<IFRVImportDoc> = this.tableSource.filter((itemSource: IFRVImportDoc) => itemSource.nifAdquirente === item.nifAdquirente);

    const modalRef = this._cgModalService.showVanilla(AtFaturasRecibosVerdesNifConfigModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtFaturasRecibosVerdesNifConfigModalComponent = modalRef.componentInstance;
    componentInstance.nifAdquirente = item.nifAdquirente;
    componentInstance.nomeAdquirente = item.nomeAdquirente;
    componentInstance.model = {...response, nif: item.nifAdquirente, nome: item.nomeAdquirente};
    componentInstance.docsList = listNumDocWithSameNIF;

    await modalRef.result;
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      await this.pesquisar();
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  public async gerarNovaCC(item: IFRVImportDoc): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(AtFaturasRecibosVerdesNovaContaCorrenteModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtFaturasRecibosVerdesNovaContaCorrenteModalComponent = modalRef.componentInstance;
    componentInstance.nif = item.nifAdquirente;
    componentInstance.nome = item.nomeAdquirente;
    await modalRef.result;
    this._dataGridInstance.beginCustomLoading(undefined);
    const promises: Array<Promise<void>> = [];
    for (const tableItem of this.tableSource) {
      if (item.nifAdquirente === tableItem.nifAdquirente) {
        promises.push(
          this._atFaturasRecibosVerdesService.reprocessEFaturaDoc(tableItem).then((response: HttpResponse<IFRVImportDoc>) => {
            const index = this.tableSource.findIndex((itemIndex: IFRVImportDoc) => itemIndex.numDocumento === item.numDocumento);
            if (index !== -1) {
              this.tableSource[index] = merge(tableItem, response.body);
              this.tableSource[index].erro = response.body.erro;
              this.tableSource[index].listaErros = response.body.listaErros;
            }
          })
        );
      }
    }
    await Promise.all(promises);

    const index = this.tableSource.findIndex((itemIndex: IFRVImportDoc) => itemIndex.numDocumento === item.numDocumento);
    if (index !== -1) {
      this.tableSource[index].docModel.predefinido = undefined;
      await this._applyDocContabilidade(this.tableSource[index]);
      this.tableSource[index].errorText = this.getErrorString(this.tableSource[index]);
      this.tableSource[index] = copy(this.tableSource[index]);
    }
    this._dataGridInstance.endCustomLoading();
  }

  public async reciboPDF(item: IFRVImportDoc): Promise<void> {
    await this._cgModalService.showPdf('', this._atFaturasRecibosVerdesService.getReciboPDFURL(item.numDocumento));
  }

  public async deixarIgnorarRecibo(item: IFRVImportDoc): Promise<void> {
    await this._atFaturasRecibosVerdesService.deixarIgnorarRecibo(item.nifAdquirente, item.numDocumento);
    this._plAlertService.success(this._plTranslateService.translate('atFRV.messages.docRemIgnoringSuccessMsg', {ndoc: item.numDocumento}));
    await this.pesquisar();
  }

  public async deixarIgnorarNif(item: IFRVImportDoc): Promise<void> {
    await this._atFaturasRecibosVerdesService.deixarIgnorarNif(item.nifAdquirente);
    this._plAlertService.success(this._plTranslateService.translate('atFRV.messages.docRemIgnoringFornecedorSuccessMsg', {nif: item.nifAdquirente}));
    await this.pesquisar();
  }

  public fromDateChanged(value: MomentInput): void {
    this.filters.fromDate = moment(value);
    this._fillToDate();
  }

  public getErrorString(item: IFRVImportDoc): string {
    let errors: Array<string> = [];
    if (!isEmpty(item.erro)) {
      errors.push(item.erro);
    }
    if (isArray(item.listaErros)) {
      for (const error of item.listaErros) {
        errors.push(error.errorMessage);
      }
    }
    errors = uniq(errors);
    return errors.length > 0 ? errors.join('<br>') : '';
  }

  public itemDocModelChanged(value: IJsonDocContabilidade, item: IFRVImportDoc): void {
    this._setItemDocModel(item, value);
  }

  public docContabilidadeInit(item: IFRVImportDoc, {element}: IPlLifeCycleEvent): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${item.numDocumento}`,
      callbackGetDoc: () => item.docModel,
      callbackOnSave: () => this._saveTableDoc(item, item.docModel),
      contabilidadeDigital: false,
      simulation: false,
      whenNode: element
    });
  }

  public docContabilidadeDestroy(item: IFRVImportDoc): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${item.numDocumento}`);
    this._plToolbarService.unRegisterInstance(`${item.numDocumento}-toolbar`);
  }

  public lancarDocsEmSerieChange(value: boolean): void {
    this.lancarDocsEmSerie = value;

    const selection: TDevExpressDataGridSelection = {
      mode: value ? 'multiple' : 'single',
      showCheckBoxesMode: value ? 'always' : 'none'
    };
    this._dataGridInstance.option('selection', selection);
  }

  public async doLancarDocsEmSerie(): Promise<void> {
    const docs: Array<IFRVImportDoc> = this._dataGridInstance.getSelectedRowsData();
    const response: Array<IFRVImportDoc> = await this._atFaturasRecibosVerdesService.lancarDocsEmSerie(docs);
    let lancadosCount = 0;
    let naoLancadosCount = 0;

    for (const item of response) {
      const tblitem: IATFaturasRecibosVerdesResultFindInSource = this._findInTableSource(item);
      if (tblitem.index === -1) {
        continue;
      }
      if (item.listaErros.length) {
        item.listaErros.forEach((error: IJsonFRVErro) => {
          this.tableSource[tblitem.index].listaErros.push({
            errorType: error.errorType,
            errorMessage: error.errorMessage
          });
        });
        naoLancadosCount++;
      } else {
        lancadosCount++;
        this.tableSource.splice(tblitem.index, 1);
        this.lancarDocsEmSerieCount--;
      }
    }

    const modalRef = this._cgModalService.showVanilla(AtFaturasRecibosVerdesLancarDocsEmSerieModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: AtFaturasRecibosVerdesLancarDocsEmSerieModalComponent = modalRef.componentInstance;
    componentInstance.naoLancadosCount = naoLancadosCount;
    componentInstance.lancadosCount = lancadosCount;
    componentInstance.processing = false;
    await modalRef.result;
    await this.pesquisar();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IFRVImportDoc, number>): void {
    this._dataGridInstance = component;
  }

  public async onRowCollapsed({key}: IDevExpressDataGridEventOnRowCollapsed<IFRVImportDoc, number>): Promise<void> {
    const item: IFRVImportDoc = await this._dataGridInstance.byKey(key);
    if (item.docModel?.linhas?.length > 1) {
      const lastLine: IJsonDocContabilidadeLinha = item.docModel.linhas[item.docModel.linhas.length - 1];
      if (!lastLine.nConta && !lastLine.valor) {
        item.docModel.linhas.pop();
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IFRVImportDoc, number>): void {
    if (event.column.dataField === 'vfactions') {
      return;
    }
    devExpressDataGridExpandDetailHandler(event, () => this._applyDocContabilidade(event.data), {collapseOthers: true}).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IFRVImportDoc, number>): void {
    if (event.rowType === 'data' && (!isEmpty(event.data.erro) || event.data.listaErros?.length > 0)) {
      event.cellElement.classList.add('text-danger');
    } else if (event.cellElement.classList.contains('text-danger')) {
      event.cellElement.classList.remove('text-danger');
    }
  }

  public async onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IFRVImportDoc, number>): Promise<void> {
    if (!this.lancarDocsEmSerie) {
      return;
    }

    const withouPreDefinidoKeys: Array<number> = [];
    for (const item of event.selectedRowsData) {
      if (!item.preDefinidoId) {
        withouPreDefinidoKeys.push(item.numDocumento);
      }
    }

    if (withouPreDefinidoKeys.length > 0) {
      await this._dataGridInstance.deselectRows(withouPreDefinidoKeys);

      this._plAlertService.error(this._plTranslateService.translate(withouPreDefinidoKeys.length === 1 ? 'atFRV.cantSelectRowDueInvalidPredefinido' : 'atFRV.cantSelectRowsDueInvalidPredefinido'));
    }

    this.lancarDocsEmSerieCount = event.selectedRowKeys.length;
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IFRVImportDoc, number>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();

      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [
        {
          caption: this._translateService.instant('atFRV.btn.dropdown.ignorarDocumento'),
          click: () => this.ignoreRecibo(event.row.data)
        },
        {
          caption: this._translateService.instant('atFRV.btn.dropdown.ignorarSempreTodosDocumentos'),
          click: () => this.ignoreNif(event.row.data)
        },
        {
          caption: this._translateService.instant('atFRV.btn.dropdown.nifConfig'),
          click: () => this.openNifConfigModal(event.row.data)
        },
        {
          caption: this._translateService.instant('atFRV.btn.dropdown.gerarNovaCC'),
          click: () => this.gerarNovaCC(event.row.data),
          disabled: !event.row.data.podeGenNovaCC
        },
        {divider: true},
        {
          caption: this._translateService.instant('atFRV.btn.dropdown.pdf'),
          click: () => this.reciboPDF(event.row.data)
        }
      ];

      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public readonly fnOnLoadPreDefinido =
    (item: IFRVImportDoc) =>
    (params: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> =>
      this._loadPreDefinido(item, params);

  public readonly fnDocContabilidadeAfterInitDocument = (item: IFRVImportDoc) => (): void => {
    if (!item.docLoadFocusField && isFunction(item.docCallback.addLine)) {
      item.docCallback.addLine();
    }
  };

  public readonly fnDoLancarDocsEmSerie = (): Promise<void> => this.doLancarDocsEmSerie();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _setItemDocModel(item: IFRVImportDoc, docModel: IJsonDocContabilidade): void {
    item.docModel = docModel;
    this._docsModel.set(item.numDocumento.toString(), item.docModel);
  }

  private _fillToDate(): void {
    this.filters.toDate = moment(this.filters.fromDate).clone().endOf('month');
  }

  private async _loadPreDefinido(item: IFRVImportDoc, {predefinido, docContabilidade}: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> {
    const doc: IFRVImportDoc = {...item, preDefinidoId: predefinido?.preDefinidosID};
    let predefinidoChanged = predefinido && item.preDefinidoId !== predefinido.preDefinidosID;
    if (!predefinidoChanged) {
      if (predefinido && !isObject(docContabilidade.predefinido)) {
        docContabilidade.predefinido = predefinido;
      } else {
        predefinidoChanged = isObject(docContabilidade.predefinido) ? item.preDefinidoId !== docContabilidade.predefinido.preDefinidosID : isNumber(item.preDefinidoId);
      }
    }
    if (predefinidoChanged) {
      const response: HttpResponse<IFRVImportDoc> = await this._atFaturasRecibosVerdesService.reprocessEFaturaDoc(doc);
      merge(item, response.body || {});
      docContabilidade = await this._getDocContabilidadePredefinido(item);
    }
    return docContabilidade;
  }

  private async _getDocContabilidadePredefinido(item: IFRVImportDoc): Promise<IJsonDocContabilidade> {
    const atItem: IFRVImportDoc = {
      atividadePrestador: item.atividadePrestador,
      cobradoATituloDescr: item.cobradoATituloDescr,
      documentoLancado: item.documentoLancado,
      incidenciaIRS: item.incidenciaIRS,
      isDeAnoEncerrado: item.isDeAnoEncerrado,
      listaErros: item.listaErros,
      nifOutro: item.nifOutro,
      numeroUnico: item.numeroUnico,
      regimeIVA: item.regimeIVA,
      regimeIVADescr: item.regimeIVADescr,
      retencaoIRS: item.retencaoIRS,
      taxasIva: item.taxasIva,
      valorIRSDescr: item.valorIRSDescr,
      contaCorrente: item.contaCorrente,
      preDefinidoStr: item.preDefinidoStr,
      tipoDocumento: item.tipoDocumento,
      tipoItemJaLancado: EATFaturasRecibosVerdesJaLancado.None,
      dataEmissao: item.dataEmissao,
      dataTransmissao: item.dataTransmissao,
      nifAdquirente: item.nifAdquirente,
      nomeAdquirente: item.nomeAdquirente,
      extPocCabID: item.extPocCabID,
      importanciaRecebida: item.importanciaRecebida,
      numDocumento: item.numDocumento,
      observacoes: item.observacoes,
      situacao: item.situacao,
      taxaIVA: item.taxaIVA,
      valorBase: item.valorBase,
      valorImpostoSelo: item.valorImpostoSelo,
      valorIRS: item.valorIRS,
      valorIVA: item.valorIVA,
      preDefinidoId: item.preDefinidoId,
      erro: '',
      podeGenNovaCC: true
    };
    const responsePredefinido: HttpResponse<IJsonFRVDocContabilidade> = await this._atFaturasRecibosVerdesService.getDocContabilidadePredefinido(atItem);
    item.listaErros = union(item.listaErros, responsePredefinido.body.errors);
    return responsePredefinido.body.doc;
  }

  private async _saveTableDoc(item: IFRVImportDoc, docContabilidade: IDocContabilidade): Promise<void> {
    const itemIndex = this.tableSource.findIndex((tableSourceItem: IFRVImportDoc) => item.numDocumento === tableSourceItem.numDocumento);
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      await this._saveDoc(docContabilidade, item);

      this.tableSource = this.tableSource.filter((tblItem: IFRVImportDoc) => {
        return tblItem.numDocumento !== item.numDocumento;
      });

      await this.pesquisar();

      if (this.tableSource.length && itemIndex >= 0 && itemIndex < this.tableSource.length && this.tableSource.length > 1) {
        await this._applyDocContabilidade(this.tableSource[itemIndex]);
        await this._dataGridInstance.expandRow(this.tableSource[itemIndex].numDocumento);
      }
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _applyDocContabilidade(item: IFRVImportDoc): Promise<void> {
    if (item.docModel?.predefinido) {
      this._createDocumentToolbar(item);
      return;
    }
    const docContabilidade: IJsonDocContabilidade = await this._getDocContabilidadePredefinido(item);
    this._setItemDocModel(item, docContabilidade);
    item.preDefinidoContab = item.docModel.predefinido;
    await this._loadPreDefinido(item, {
      predefinido: item.preDefinidoContab,
      docContabilidade: item.docModel,
      docOCRCab: undefined
    });
    this._docContabilidadeService.calculaTotais(item.docModel);
    this._createDocumentToolbar(item);
    await this._docContabilidadeAfterInitDocument(item, item.docModel);
    if (!item.docModel.isUsingPreDefinido || item.docModel.linhas.length !== 1 || !item.docModel.linhas[0]?.nConta) {
      item.docLoadFocusField = 'linhas';
    }
  }

  private _saveDoc(docContabilidade: IDocContabilidade, sourceObj: IFRVImportDoc): Promise<void> {
    this.promise = this._serviceDocsContabilidade
      .post({body: docContabilidade})
      .then(async (responseSave: HttpResponse<IJsonDocContabilidade>) => {
        const response: HttpResponse<IDocContabilidade> = await this._atFaturasRecibosVerdesService.getDocContabilidade(responseSave.body.extPocCabID);
        sourceObj.docModel = response.body;
        this._plAlertService.success(this._plTranslateService.translate('docscontabilidade.saved', {id: response.body.nDocumento}));
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _createDocumentToolbar(item: IFRVImportDoc): void {
    this._plToolbarService.registerInstance(`${item.numDocumento}-toolbar`, {
      items: [
        {
          groupId: TOOLBAR_GROUP_ID,
          id: `lastDocToolbarSaveDoc-${item.numDocumento}`,
          order: 1,
          type: 'button',
          class: 'btn-primary',
          iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
          caption: 'global.btn.save',
          click: () => this._saveTableDoc(item, item.docModel)
        }
      ]
    });
  }

  private async _docContabilidadeAfterInitDocument(item: IFRVImportDoc, doc: IJsonDocContabilidade): Promise<void> {
    if (!doc.predefinido) {
      doc.documentoExterno = item.numeroUnico.toString();
      doc.dataDoc = item.dataEmissao;
      doc.nContribuinte = item.nifAdquirente;
    }
    if (!doc.predefinido || doc.predefinido.isGeneric) {
      await this._docsContabilidadeService.registaNif(doc.nContribuinte);
    }
  }

  private _findInTableSource(item: IFRVImportDoc): IATFaturasRecibosVerdesResultFindInSource {
    const result: IATFaturasRecibosVerdesResultFindInSource = {index: -1, item: null};
    for (let i = 0; i < this.tableSource.length; i++) {
      if (this.tableSource[i].numDocumento === item.numDocumento) {
        result.item = this.tableSource[i];
        result.index = i;
        break;
      }
    }
    return result;
  }

  private async _getTableSource(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: IFRVImportDocApiList = await this.getTableSources();
      for (const item of response.list) {
        item.docCallback = {};
        item.errorText = this.getErrorString(item);
      }
      for (const item of response.listLancadosIgnorados) {
        item.docCallback = {};
        item.errorText = this.getErrorString(item);
      }
      this.tableSource = response.list;
      this.tableSourceLancIgnored = response.listLancadosIgnorados;
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  private async _configButtonClick(): Promise<void> {
    await this._cgModalService.show(AtFaturasRecibosVerdesConfigModalComponent, {size: 'md'});
    if (this.tableSource.length > 0) {
      await this.pesquisar();
    }
  }

  private _applyDataGridDefinition(): void {
    const columns: Array<IDevExpressDataGridColumn> = [
      {dataField: 'nifAdquirente', dataType: 'string', caption: 'atFRV.fields.nifAdquirente'},
      {dataField: 'nomeAdquirente', dataType: 'string', caption: 'atFRV.fields.nomeAdquirente', width: 150},
      {dataField: 'numeroUnico', dataType: 'string', caption: 'atFRV.fields.numeroUnico'},
      {dataField: 'dataEmissao', dataType: 'date', caption: 'atFRV.fields.dataEmissao'},
      {dataField: 'tipoDocumento', dataType: 'string', caption: 'atFRV.fields.tipoDocumento'},
      {dataField: 'situacao', dataType: 'string', caption: 'atFRV.fields.situacao'},
      {dataField: 'importanciaRecebida', dataType: 'double', caption: 'atFRV.fields.importanciaRecebida'},
      {dataField: 'valorBase', dataType: 'double', caption: 'atFRV.fields.valorBase'},
      {dataField: 'valorIVA', dataType: 'double', caption: 'atFRV.fields.valorIVA'},
      {dataField: 'valorIRS', dataType: 'double', caption: 'atFRV.fields.valorIRS'},
      {dataField: 'valorImpostoSelo', dataType: 'double', caption: 'atFRV.fields.valorImpostoSelo'},
      {dataField: 'taxaIVA', dataType: 'double', caption: 'atFRV.fields.taxaIVA'}
    ];

    if (this.selectedTab === 'tabDocsEFatura') {
      columns.push({dataField: 'preDefinidoId', dataType: 'number', caption: 'atFRV.fields.preDefinido'});
    }

    columns.push({
      dataField: 'vfactions',
      fixed: true,
      fixedPosition: 'right',
      caption: '',
      cellTemplate: 'cellTemplateActions',
      allowHiding: false,
      allowSearch: false,
      allowReordering: false,
      allowSorting: false,
      allowFiltering: false,
      allowResizing: false,
      allowHeaderFiltering: false,
      allowGrouping: false,
      allowFixing: false,
      allowEditing: false,
      allowExporting: false,
      showInColumnChooser: false
    });

    this.dataGridDefinition = {
      keyExpr: 'numDocumento',
      columns: columns,
      dataSource: [],
      columnHidingEnabled: false,
      export: {
        filename: this.selectedTab === 'tabDocsEFatura' ? 'atFRV.tabDocsEFaturaCaption' : 'atFRV.tabJaLancadosCaption'
      },
      paging: {pageSize: 25},
      remoteOperations: false
    };

    if (this.selectedTab === 'tabDocsEFatura') {
      this.dataGridDefinition.toolbar = {
        items: [
          {
            location: 'after',
            template: 'templateToolbarLancDocSerie',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      };

      this.dataGridDefinition.masterDetail = {
        enabled: true,
        template: 'cellTemplateMasterDetail'
      };
    }
  }
}
