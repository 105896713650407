import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressValidationCallbackData} from '../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IJsonContasDepreciacao} from './jsonAtivosConfiguracoes.module.interface';

export enum EAtivosConfiguracoesDepAgrupa {
  AtivoPeriodo,
  AtivoPeriodoUltimo,
  AtivosPeriodo,
  AtivosPeriodoUltimo
}

export type TAtivosConfiguracoesContasDepValidationCallbackData = IDevExpressValidationCallbackData<'async', dxDataGrid, IJsonContasDepreciacao, IJsonContasDepreciacao>;

export type TAtivosConfiguracoesRadicaisValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, IRadicaisSujeitos, IRadicaisSujeitos>;

export interface IRadicaisSujeitos {
  id: number;
  radicalSujeito: string;
}

export const MODULE_NAME_ATIVOS_CONFIGURACOES = 'ativosconfiguracoes';
