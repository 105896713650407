import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonNumber} from '../../../../../../../../../common/interfaces/json';
import {IJsonErpUser, TUserSession} from '../../../../../../../../services/account/jsonUserApi.interface';
import {THttpQueryResponse} from '../../../../../../../../services/api/api.service.interface';
import {ENTITY_NAME_EMPRESAS, IEmpresaExtended} from '../../../../../../../../entities/empresas/empresas.entity.interface';
import {IEntityService} from '../../../../../../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../../../../../../services/entity/entity.service.builder';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../../../../../services/auth/auth.service';

@Component({
  selector: 'modal-modelo22-contasdefcopy',
  templateUrl: './modelo22.contasDefCopy.modal.component.html'
})
export class Modelo22ContasDefCopyModalComponent extends CGModalComponent<IJsonNumber> implements OnInit {
  public readonly msEmpresasTemplate: string;

  public promise: Promise<void>;
  public empresasSource: Array<IEmpresaExtended>;
  public empresasSel: Array<IEmpresaExtended>;
  public temEmpresasSelecionadas: boolean;
  public apagacontasempresadestino: boolean = true;

  private readonly _serviceEmpresas: IEntityService<IEmpresaExtended>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS);
    this.msEmpresasTemplate = '{{nempresa}} - {{nome}}';
    this.empresasSel = [];
    this.temEmpresasSelecionadas = false;
  }

  public ngOnInit(): void {
    this._loadEmpresas();
  }

  public close(): Promise<void> {
    const empresas = [];
    this.empresasSel.forEach((element) => {
      empresas.push(element.nempresa);
    });
    return this._modelo22Service.copiaContasEmpresas(this.apagacontasempresadestino, empresas).then(() => {
      super.close();
      this._plAlertService.success('modelo22.messages.contasdef.contascopiadascomsucesso');
    });
  }

  public fnEmpresasChanged(): void {
    this.temEmpresasSelecionadas = this.empresasSel.length > 0;
  }

  private _loadEmpresas(): Promise<void> {
    return this._authService.identity().then((session: TUserSession) => {
      const erps: Array<string> = session.erps.map((erp: IJsonErpUser) => erp.nEmpresa);
      const filter = `NEMPRESA=${erps.join(',')}`;
      return this._serviceEmpresas.query({pesquisa: filter}).then((response: THttpQueryResponse<IEmpresaExtended>) => {
        this.empresasSource = response.body.list.filter((empresa: IEmpresaExtended) => {
          return empresa.nempresa !== session.erp.nEmpresa;
        });
      });
    });
  }
}
