import {Component, Injector, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {ConfigDocsEFaturaGlobalComponent} from '../components/global/configDocsEfatura.global.component';

@Component({
  selector: 'config-efatura-docs-por-lancar',
  templateUrl: './configEfaturaDocPorLanc.modal.component.html'
})
export class ConfigEfaturaDocPorLancModalComponent extends CGModalComponent<void> {
  @ViewChild('configDocsEFaturaGlobal') public readonly configDocsEFaturaGlobal: ConfigDocsEFaturaGlobalComponent;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.configDocsEFaturaGlobal
        .save()
        .then(() => {
          resolve();
          super.close();
        })
        .catch(reject);
    });
  }
}
