import {Component, Injector, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../gdoc/JsonGDoc.entity.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../gdoc/gdoc.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IGDocsCallback, IGDocsOnAddingFile} from '../../../../components/gdocs/gdocs.component.interface';
import {IJsonDocComercialCab} from '../../jsonDocComercial.entity.interface';

@Component({
  selector: 'gdoc-comercial-modal',
  templateUrl: './gdoc.comercial.modal.component.html'
})
export class GDocComercialModalComponent extends DocsComerciaisModalComponent implements OnInit {
  public readonly tipoDocEntity: EDocTipoEntity;
  public readonly callbackGDocs: IGDocsCallback;
  public folderId: string;
  public params: string;

  private readonly _service: IGDocEntityService;

  private _isChanged: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.tipoDocEntity = EDocTipoEntity.GestaoComercial;
    this.callbackGDocs = {};
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this._isChanged = false;
  }

  public ngOnInit(): void {
    this.params = `NDocFaDraft=${this.doc.cab.nDocFa}&NNumerDraft=${this.doc.cab.nNumer}&NDocumentoDraft=${this.doc.cab.nDocumentoDraft}&FaccbId=${this.doc.cab.faccbId}`;
    this._getGDocFolder(this._generateNDoc(this.doc.cab), EFolderDoc.DefaultFolderGestCom);
  }

  public close(): void {
    super.close(this.doc);
  }

  public dismiss(): void {
    if (this._isChanged) {
      this.close();
    } else {
      super.dismiss();
    }
  }

  public onGDocsViewerAddingFile(event: IGDocsOnAddingFile): void {
    event.promise = (async () => {
      const nomePasta = this._generateNDoc(this.doc.cab);
      await this._addDocFolder(nomePasta, EFolderDoc.DefaultFolderGestCom);
      if (!this._isChanged) {
        this._isChanged = true;
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });
    })();
  }

  public onGDocsDeleteFile(): void {
    if (!this._isChanged) {
      this._isChanged = true;
    }
  }

  private _addDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    const nDocDraft: string = this._generateNDoc(this.doc.cab);
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentfolderId,
      name: name,
      folderType: EFolderType.System,
      additionalParams: `CGUID=GestCom&CGUIDValue=${nDocDraft}&GestCom=${nDocDraft}&TipoEntityRef=${EDocTipoEntity.GestaoComercial}`
    };
    return this._service
      .post({body: gdocFolder})
      .then((response) => {
        if (response) {
          this.folderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('docscomerciais.erros.naoCriouPastaDoc');
        }
      });
  }

  private _generateNDoc(cab: IJsonDocComercialCab): string {
    return !cab ? '' : `${cab.nDocFa}.${cab.nNumer}.${cab.nDocumentoDraft}`;
  }

  private _getGDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    return this._service
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.folderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }
}
