import {Component, Injector, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'ddsepa-impressao-cliente-modal',
  templateUrl: './debDiretosSEPA.impressaoCliente.modal.component.html'
})
export class DebDiretosSEPAImpressaoClienteModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nConta: string;
  @Input() public email: string;
  @Input() public nomeConta: string;

  public pdfUrl: string;
  public reportModel: IJsonReport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _config: ConfigService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
    this.listar();
  }

  public listar(): void {
    this.pdfUrl = undefined;
    this._debDiretosSEPAModuleService.getListagemClienteUrl(this.nConta, this.reportModel.name).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  public getPanelTitle(): string {
    return `${this.nConta} - ${this.nomeConta}`;
  }

  public readonly fnEnviarEmail = (): Promise<void> => this._enviarEmail();

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._config.configurations.gestaoComercial.debitosDiretosSEPA.reportClienteImpressao,
      title: this._config.configurations.gestaoComercial.debitosDiretosSEPA.reportClienteImpressao
    };
    return this._reportsRegistryService
      .get(EReport.DDSEPACLIENTE)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === this.reportModel.name) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }

  private async _enviarEmail(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'debDiretosSEPA.messages.temACertezaQuePertendeEnviarEmail', {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no'
    });

    await this._debDiretosSEPAModuleService.enviarEmailDDSepaCliente(this.nConta, this.email, this.reportModel.name).then((response) => {
      if (response.body) {
        this._plAlertService.success('debDiretosSEPA.messages.emailEnviadoComSucesso');
      } else {
        this._plAlertService.error('debDiretosSEPA.messages.emailEnviadoComErro');
      }
    });
  }
}
