import {NgModule} from '@angular/core';
import {PlAnimationShakeDirective} from './animation.shake.directive';

export * from './animation.shake.directive';

const DECLARATIONS = [
  PlAnimationShakeDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlAnimationShakeModule {
}
