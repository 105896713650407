import {IJsonConfigValue} from '../../../interfaces/jsonConfigValue.interface';

export enum EConfigSiteGroup {
  Geral,
  Email
}

export enum EConfigSiteEditType {
  Text = 'text',
  Password = 'password',
  Boolean = 'boolean'
}

export interface IConfigSiteValue extends IJsonConfigValue {
  readonly: boolean;
  group: EConfigSiteGroup;
  type: EConfigSiteEditType;
}

export const MODULE_NAME_CONFIGS_SITE = 'configsSite';

export const ENTITY_NAME_CONFIGS_SITE = MODULE_NAME_CONFIGS_SITE;

export const CONFIG_SITE_DATABASE_VERSION = 'database.version';
export const CONFIG_SITE_MAIL_SMTP_ADMIN_EMAIL = 'mail.smtp.adminemail';
export const CONFIG_SITE_MAIL_SMTP_SITE_EMAIL = 'mail.smtp.siteemail';
export const CONFIG_SITE_MAIL_SMTP_PASSWORD = 'mail.smtp.smtppassword';
export const CONFIG_SITE_MAIL_SMTP_PORT = 'mail.smtp.smtpport';
export const CONFIG_SITE_MAIL_SMTP_SERVER = 'mail.smtp.smtpserver';
export const CONFIG_SITE_MAIL_SMTP_USER = 'mail.smtp.smtpuser';
export const CONFIG_SITE_MAIL_SMTP_USE_SSL = 'mail.smtp.smtpusessl';
export const CONFIG_SITE_MAIL_TERMS_RGPD_VERSION = 'termsRGPDVersion';
