import {Component, Injector, Input, OnInit} from '@angular/core';
import {isNumber} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {DGEMPSService} from '../../../../../entities/dgemps/dgemps.entity.service';
import {EDGEMPSSaveCadastroMode} from '../../dgempsFull.interface';
import {IJsonDHEMP} from '../../jsonDGEMPFull.interface';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'dgemps-full-save-cadastro-modal-component',
  templateUrl: './dgempsFull.saveCadastro.modal.component.html'
})
export class DGEMPSFullSaveCadastroModalComponent extends CGModalComponent<EDGEMPSSaveCadastroMode> implements OnInit {
  @Input() public dhemp: IJsonDHEMP;
  @Input() public latestHistoricoDadosKey: TDate;

  public readonly dgempsSaveCadastroMode: typeof EDGEMPSSaveCadastroMode;
  public saveCadastroMode: EDGEMPSSaveCadastroMode;
  public invalid: boolean;
  public dataRegisto: TDate;

  private _datasDadosHistoricos: Array<string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _dgempsService: DGEMPSService
  ) {
    super(_injector);
    this.dgempsSaveCadastroMode = EDGEMPSSaveCadastroMode;
    this.saveCadastroMode = EDGEMPSSaveCadastroMode.UpdateLatestCadastro;
    this.invalid = false;
    this.dataRegisto = this._dgempsService.normalizeDataCadastro(moment());
    this._datasDadosHistoricos = [];
  }

  public ngOnInit(): void {
    if (this.latestHistoricoDadosKey) {
      const dataCadastro: Moment = this._dgempsService.normalizeDataCadastro(this.dhemp.dtMotMov);
      const latestHistoricoDados: Moment = this._dgempsService.normalizeDataCadastro(this.latestHistoricoDadosKey);
      if (dataCadastro.isAfter(latestHistoricoDados, 'day')) {
        this.saveCadastroMode = EDGEMPSSaveCadastroMode.NewCadastro;
        this.dataRegisto = dataCadastro;
      }
    }
    this._datasDadosHistoricos = this.dhemp.datasDadosHistoricos.map((dataDadosHistoricos: TDate) => {
      return this._dgempsService.normalizeDataCadastro(dataDadosHistoricos).toISOString();
    });
  }

  public close(): void {
    this.invalid = !isNumber(this.saveCadastroMode) || this.saveCadastroMode === 0;
    if (!this.invalid) {
      if (this.saveCadastroMode === EDGEMPSSaveCadastroMode.NewCadastro) {
        this.dhemp.dtMotMov = this.dataRegisto;
      }
      super.close(this.saveCadastroMode);
    }
  }

  public changedDataRegisto(value: TDate): void {
    this.dataRegisto = this._dgempsService.normalizeDataCadastro(value);
  }

  public readonly fnIsDateDisabled: (date: Moment) => boolean = (date: Moment) => this._isDateDisabled(date);

  public readonly fnIsDateMarked: (date: Moment) => boolean = (date: Moment) => this._isDateMarked(date);

  private _isDateDisabled(date: Moment): boolean {
    return this._dgempsService.isDateDisabled(date, this.latestHistoricoDadosKey);
  }

  private _isDateMarked(date: Moment): boolean {
    return this._dgempsService.isDateMarked(date, this._datasDadosHistoricos);
  }
}
