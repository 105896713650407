import {Component, Input} from '@angular/core';
import {IPlNavWizardInstance, IPlNavWizardStep, IPlNavWizardStepAction} from '../navwizard.interface';

@Component({
  selector: 'pl-nav-wizard-actions',
  templateUrl: './navwizard.actions.component.html',
  standalone: false
})
export class PlNavWizardActionsComponent {
  @Input() public navWizardInstance: IPlNavWizardInstance;

  public evaluateFooter(type: 'previous' | 'next'): boolean {
    if (!this.navWizardInstance.selected) {
      return false;
    }
    let index;
    switch (type) {
      case 'previous':
        index = 0;
        break;
      case 'next':
        index = -1;
        break;
      default:
        return false;
    }
    const step: IPlNavWizardStep = this.navWizardInstance.getStep(index);
    if (!step) {
      return false;
    }
    return this.navWizardInstance.selected.stepId === step.stepId;
  }

  public readonly fnActionClick = (action: IPlNavWizardStepAction) => (): Promise<void> => Promise.resolve(action.click(action));
}
