import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {ICGUserAccessesCallback, ICGUserAccessesTable, ICGUserAccessesTableHeader, ICGUserAccessesTableRow, ICGUserAccessEvtChanged} from './utilizadores.accesses.interface';
import {EPoliciesCategory, IUserInstallationPolicy, IUserInstallationPolicyConfigERP} from '../installations/utilizadores.installation.interface';
import {configErpBooleanToValue} from '../../../../common/data';

const ACCESSES_PREFIX = 'acessos.erpcloud';
const ACCESSES_ADDONS = `${ACCESSES_PREFIX}.addons`;
const ACCESSES_COMERCIAL = `${ACCESSES_PREFIX}.gestaocomercial.documentos`;
const ACCESSES_CONTABILIDADE = `${ACCESSES_PREFIX}.contabilidade`;
const ACCESSES_ATIVOS = `${ACCESSES_PREFIX}.ativos`;

@Component({
  selector: 'cg-user-accesses',
  templateUrl: './utilizadores.accesses.component.html'
})
export class CGUserAccessesComponent implements OnInit, OnChanges {
  @Input() public attrName: string;
  @Input() public policy: IUserInstallationPolicy;
  @Input() public disabled: boolean;
  @Input() public callback: ICGUserAccessesCallback;
  @Output() public readonly evtConfigErpChanged: EventEmitter<ICGUserAccessEvtChanged>;
  @Output() public readonly evtConfigsErpChanged: EventEmitter<void>;

  public readonly categories: typeof EPoliciesCategory;
  public readonly configsGroupAddons: Array<IUserInstallationPolicyConfigERP>;
  public readonly configsGroupAtivos: Array<IUserInstallationPolicyConfigERP>;
  public readonly configsGroupComercial: ICGUserAccessesTable;
  public readonly configsGroupContabilidade: Array<IUserInstallationPolicyConfigERP>;

  constructor() {
    this.evtConfigErpChanged = new EventEmitter<ICGUserAccessEvtChanged>();
    this.evtConfigsErpChanged = new EventEmitter<void>();
    this.categories = EPoliciesCategory;
    this.configsGroupAddons = [];
    this.configsGroupAtivos = [];
    this.configsGroupContabilidade = [];
    this.configsGroupComercial = {
      headers: [
        {selectedAll: false, indeterminate: true, name: 'usersbasic.accesses.generic.add', key: 'criarDocumentos'},
        {selectedAll: false, indeterminate: true, name: 'usersbasic.accesses.generic.edit', key: 'editarDocumentos'},
        {selectedAll: false, indeterminate: true, name: 'usersbasic.accesses.generic.delete', key: 'anularDocumentos'},
        {selectedAll: false, indeterminate: true, name: 'usersbasic.accesses.generic.view', key: 'visualizarDocumentos'}
      ],
      rows: []
    };
  }

  public ngOnInit(): void {
    this._changedConfigsErp();
  }

  public ngOnChanges({configsErp, callback, hideGestaoComercial}: SimpleChanges): void {
    if (callback) {
      const cb: ICGUserAccessesCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.evaluateUserAccesses = () => {
          this._evaluateUserAccesses();
        };
      }
      if (hideGestaoComercial && !hideGestaoComercial.isFirstChange()) {
        this._evaluateTablePolicy(this.configsGroupComercial);
      }
    }
    if (configsErp && !configsErp.isFirstChange()) {
      this._changedConfigsErp();
    }
  }

  public changedHeaderSelectAll(value: boolean, header: ICGUserAccessesTableHeader, accessesGroup: ICGUserAccessesTable): void {
    header.selectedAll = !header.selectedAll;
    for (const row of accessesGroup.rows) {
      for (const configErp of row.configsErp) {
        if (configErp.name.endsWith(header.key)) {
          configErp.value = configErpBooleanToValue(configErp, header.selectedAll);
        }
      }
    }
    this.evtConfigsErpChanged.emit();
    this._evaluateTablePolicy(accessesGroup);
  }

  public changedRowSelectAll(value: boolean, row: ICGUserAccessesTableRow, accessesGroup: ICGUserAccessesTable): void {
    row.selectedAll = !row.selectedAll;
    for (const configErp of row.configsErp) {
      configErp.value = configErpBooleanToValue(configErp, row.selectedAll);
    }
    this.evtConfigsErpChanged.emit();
    this._evaluateTablePolicy(accessesGroup);
  }

  public changedConfigValue(value: unknown, configErp: IUserInstallationPolicyConfigERP): void {
    const oldValue: unknown = configErp.value;
    configErp.value = value;
    this.evtConfigErpChanged.emit({
      oldValue: oldValue,
      newValue: configErp.value,
      configErp: configErp
    });
  }

  public changedTableConfigValue(value: unknown, configErp: IUserInstallationPolicyConfigERP, accessesGroup: ICGUserAccessesTable): void {
    this.changedConfigValue(value, configErp);
    this._evaluateTablePolicy(accessesGroup);
  }

  private _changedConfigsErp(): void {
    this.configsGroupComercial.rows.length = 0;
    this.configsGroupContabilidade.length = 0;
    this.configsGroupAtivos.length = 0;
    this.configsGroupAddons.length = 0;
    for (const configErp of this.policy.configsErp) {
      if (configErp.name.startsWith(ACCESSES_ADDONS)) {
        this.configsGroupAddons.push(configErp);
      } else if (configErp.name.startsWith(ACCESSES_ATIVOS)) {
        this.configsGroupAtivos.push(configErp);
      } else if (configErp.name.startsWith(ACCESSES_CONTABILIDADE)) {
        this.configsGroupContabilidade.push(configErp);
      } else if (configErp.name.startsWith(ACCESSES_COMERCIAL)) {
        const configName = configErp.name.substring(0, configErp.name.lastIndexOf('.'));
        let row: ICGUserAccessesTableRow = this.configsGroupComercial.rows.find((rowItem: ICGUserAccessesTableRow) => rowItem.name === configName);
        if (!row) {
          row = {
            name: configName,
            description: `${configName}.title`,
            selectedAll: false,
            indeterminate: false,
            configsErp: [],
            configsErpMap: new Map<string, IUserInstallationPolicyConfigERP>()
          };
          this.configsGroupComercial.rows.push(row);
        }
        row.configsErp.push(configErp);
        row.configsErpMap.set(configErp.name, configErp);
      }
    }
    this._evaluateUserAccesses();
  }

  private _evaluateUserAccesses(): void {
    // Evaluate table accesses
    this._evaluateTablePolicy(this.configsGroupComercial);
  }

  private _evaluateTablePolicy(accessesGroup: ICGUserAccessesTable): void {
    // Evaluate table accesses
    const selectedHeadersMap: Map<string, number> = new Map<string, number>();
    for (const header of accessesGroup.headers) {
      selectedHeadersMap.set(header.key, 0);
    }
    for (const row of accessesGroup.rows) {
      let selectedConfigsErp = 0;
      for (const configErp of row.configsErp) {
        if (!configErp.value) {
          continue;
        }
        selectedConfigsErp++;
        const headerName = configErp.name.substring(configErp.name.lastIndexOf('.') + 1, configErp.name.length);
        let headerCount = selectedHeadersMap.get(headerName);
        selectedHeadersMap.set(headerName, ++headerCount);
      }
      row.selectedAll = selectedConfigsErp === row.configsErp.length;
      row.indeterminate = !row.selectedAll && selectedConfigsErp > 0;
    }
    for (const header of accessesGroup.headers) {
      const selectedCount = selectedHeadersMap.get(header.key);
      header.selectedAll = selectedCount === accessesGroup.rows.length;
      header.indeterminate = !header.selectedAll && selectedCount > 0;
    }
  }
}
