import {IEntityService} from '../../services/entity/entity.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonARTAR} from './jsonArtar.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IARTAREntityService extends IEntityService<IJsonARTAR> {
  atualizaQuantidadesStock(nArtigo: string, nArmazem: number, qtdMax: number, qtdMin: number): TServiceResponse<void>;
}

export interface IARTAREntity extends IEntityDefinition<IJsonARTAR, IARTAREntityService> {}

export const ENTITY_NAME_ARTAR = 'artars';
