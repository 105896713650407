import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGridEventOnToolbarPreparing} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonFatEletronicaConfCliente} from '../../jsonFaturacaoEletronicaConfiguracoes.module.interface';
import {IJsonString} from '../../../../../common/interfaces/json';

@Component({
  selector: 'faturacao-eletronica-configuracoes-client-modal',
  templateUrl: './faturacaoEletronicaConfiguracoes.client.modal.component.html'
})
export class FaturacaoEletronicaConfiguracoesClientModalComponent extends CGModalComponent<Array<IJsonFatEletronicaConfCliente>> implements OnInit {
  @Input() public fatEletronicaConfCliente: IJsonFatEletronicaConfCliente;
  @Input() public fatEletronicaConfClienteList: Array<IJsonFatEletronicaConfCliente>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public email: string;
  public emailsSource: Array<IJsonString>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'value', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.email'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnEmail'}
      ],
      dataSource: [],
      export: {filename: 'faturacaoeletronicaconfiguracoes.configemailsclientemodal.title'},
      remoteOperations: false
    };
    this.fatEletronicaConfCliente = {
      nConta: '',
      nome: '',
      estado: '',
      emails: '',
      emailGeral: '',
      faturacaoElectronica: false
    };
    this.email = '';
    this.emailsSource = [];
  }

  public ngOnInit(): void {
    if (!isEmpty(this.fatEletronicaConfCliente.emails)) {
      const emailsSplited: Array<string> = this.fatEletronicaConfCliente.emails.split(';');
      for (const email of emailsSplited) {
        this.emailsSource.push({value: email});
      }
    }
    this.dataGridDefinition.dataSource = this.emailsSource;
  }

  public close(): void {
    this.fatEletronicaConfCliente.emails = this.emailsSource.map((item: IJsonString) => item.value).join(';');
    if (isEmpty(this.fatEletronicaConfCliente.emails)) {
      this._plAlertService.error('faturacaoeletronicaconfiguracoes.messages.emailsEmpty');
    } else {
      this.fatEletronicaConfCliente.faturacaoElectronica = false;
      const index = this.fatEletronicaConfClienteList.findIndex((item: IJsonFatEletronicaConfCliente) => item.nConta === this.fatEletronicaConfCliente.nConta);
      if (index !== -1) {
        this.fatEletronicaConfClienteList[index] = this.fatEletronicaConfCliente;
      } else {
        this.fatEletronicaConfClienteList.push(this.fatEletronicaConfCliente);
      }
      super.close(this.fatEletronicaConfClienteList);
    }
  }

  public clientesChanged(event: IJsonClifo): void {
    this.fatEletronicaConfCliente.nConta = event.nConta;
    this.fatEletronicaConfCliente.nome = event.nome;
    this.fatEletronicaConfCliente.emailGeral = event.email;
    this.email = '';

    const index = this.fatEletronicaConfClienteList.findIndex((item: IJsonFatEletronicaConfCliente) => item.nConta === event.nConta);
    if (index !== -1) {
      this.fatEletronicaConfCliente.emails = this.fatEletronicaConfClienteList[index].emails;
      if (!isEmpty(this.fatEletronicaConfCliente.emails)) {
        const emailsSplited: Array<string> = this.fatEletronicaConfCliente.emails.split(';');
        for (const email of emailsSplited) {
          this.emailsSource.push({value: email});
        }
      }
    } else {
      this.fatEletronicaConfCliente.emails = '';
      this.emailsSource = [];
      if (!isEmpty(this.fatEletronicaConfCliente.emailGeral)) {
        this.emailsSource.push({value: this.fatEletronicaConfCliente.emailGeral});
      }
    }

    this.dataGridDefinition.dataSource = this.emailsSource;
  }

  public addLine(): void {
    if (!isEmpty(this.email) && this.emailsSource.findIndex((item: IJsonString) => item.value === this.email) === -1) {
      this.emailsSource.push({value: this.email});
      this.dataGridDefinition.dataSource = this.emailsSource;
    }
    this.email = '';
  }

  public removeLine(item: string): void {
    const index = this.emailsSource.findIndex((value: IJsonString) => value.value === item);
    if (index !== -1) {
      this.emailsSource.splice(index, 1);
    }
    this.dataGridDefinition.dataSource = this.emailsSource;
  }

  public onToolbarPreparing(event: IDevExpressDataGridEventOnToolbarPreparing): void {
    event.toolbarOptions.items.unshift({
      location: 'before',
      template: 'templateToolbar'
    });
  }
}
