import {IJsonREMUNFX} from './jsonRHRecolhaDados.entity.interface';

export interface IRHRecolhaDadosStateParams {
  defaultRecolhaDados: IJsonREMUNFX;
  fromBatchAdd: boolean;
  isByCodEmp: boolean;
}

export const VALORABONO = 0;
export const DDFIXOSFALSE = 0;
export const DDFIXOSTRUE = 1;

export const ENTITY_NAME_REMUN = 'remun';
export const ENTITY_NAME_REMFX = 'remfx';
