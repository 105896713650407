import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isArray, isDefinedNotNull, isFunction, isObject, PlUploadConfigService} from 'pl-comps-angular';
import {EViewType, IFileItem, IFileItemDefinition, IIconBtn} from './fileviewer.interface';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../devexpress/datagrid/events/devexpress.datagrid.events.interface';

const DEBOUNCE_TIME = 500;

@Component({
  selector: 'file-viewer',
  templateUrl: './fileviewer.component.html'
})
export class FileViewerComponent implements OnChanges {
  @Input() public filesView: Array<IFileItemDefinition>;
  @Input() public viewType: EViewType;
  @Input() public urlPdf: string;
  @Input() public loading: boolean;
  @Input() public showList: boolean;
  @Input() public showBox: boolean;
  @Input() public readOnly: boolean;
  @Output() public readonly viewTypeChange: EventEmitter<EViewType>;
  @Output() public readonly evtSelectedFile: EventEmitter<IFileItem>;
  @Output() public readonly evtFileDelete: EventEmitter<IFileItem>;
  @Output() public readonly evtFileUpload: EventEmitter<File>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly viewTypes: typeof EViewType;
  public readonly debounceTime: number;
  public btnIcons: IIconBtn;
  public files: Array<IFileItem>;
  public filteredFiles: Array<IFileItem>;
  public selectedFile: IFileItem;
  public searchModel: string;

  private _dataGridInstance: dxDataGrid;

  constructor(
    private readonly _plUploadConfigService: PlUploadConfigService,
    private readonly _cgModalService: CGModalService
  ) {
    this.viewTypeChange = new EventEmitter<EViewType>();
    this.evtSelectedFile = new EventEmitter<IFileItem>();
    this.evtFileDelete = new EventEmitter<IFileItem>();
    this.evtFileUpload = new EventEmitter<File>();
    this.readOnly = false;
    this.viewTypes = EViewType;
    this.debounceTime = DEBOUNCE_TIME;
    this.viewType = EViewType.List;
    this.files = [];
    this.filteredFiles = [];
    this.btnIcons = {
      btnDownload: 'fa fa-download fa-fw',
      btnSearch: 'fa fa-search fa-fw'
    };
    this.filesView = [];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'text', dataType: 'string', caption: 'fileviewer.table.nome', width: 350, cellTemplate: 'cellTemplateNome'},
        {dataField: 'size', dataType: 'string', caption: 'fileviewer.table.tamanho'},
        {dataField: 'dateModified', dataType: 'date', caption: 'fileviewer.table.datamod'},
        {dataField: 'hourModified', dataType: 'datetime', caption: 'fileviewer.table.horamod', format: 'longTime'},
        {dataField: 'dateCreate', dataType: 'date', caption: 'fileviewer.table.datacriacao'},
        {dataField: 'hourCreate', dataType: 'datetime', caption: 'fileviewer.table.horacriacao', format: 'longTime'},
        {dataField: 'user', dataType: 'string', caption: 'fileviewer.table.utilizador'},
        {dataField: 'docExt', dataType: 'string', caption: 'fileviewer.table.docexterno'},
        {dataField: 'classificador', dataType: 'string', caption: 'fileviewer.table.classificador'},
        {dataField: 'path', dataType: 'string', caption: 'fileviewer.table.pasta'}
      ],
      dataSource: [],
      remoteOperations: false,
      height: 500,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'}
    };
  }

  public ngOnChanges({filesView}: SimpleChanges): void {
    if (filesView) {
      this.files = [];
      for (const file of this.filesView) {
        this.files.push({
          ...file,
          urlExtension: this._plUploadConfigService.getThumbnail(file.mimeType),
          tooltipCallback: {}
        });
      }
      this.filteredFiles = this.files;
      this.dataGridDefinition.dataSource = this.files;
    }
    if (this.filesView.length < 1 && this.selectedFile) {
      this.selectedFile = undefined;
    }
  }

  public selectFile(file: IFileItem): void {
    if (this.viewType === this.viewTypes.Icon && this.selectedFile?.tooltipCallback && isFunction(this.selectedFile.tooltipCallback.close)) {
      this.selectedFile.tooltipCallback.close();
    }
    this.selectedFile = file;
    if (this.viewType === this.viewTypes.Icon && this.selectedFile?.tooltipCallback && isFunction(this.selectedFile.tooltipCallback.open)) {
      this.selectedFile.tooltipCallback.open({iconDownload: '', iconSearch: ''});
    }
    this.evtSelectedFile.emit(file);
  }

  public previsualizarModal(): Promise<void> {
    if (this.selectedFile && this.selectedFile.mimeType === 'application/pdf') {
      const modalInstance = this._cgModalService.showPdfVanilla(this.selectedFile.text, this.urlPdf);
      return modalInstance.result;
    }
    return Promise.resolve();
  }

  public typeViewChanged(value: EViewType): void {
    this.viewType = value;
    this.viewTypeChange.emit(this.viewType);
  }

  public updateSearchModel(value: string): void {
    this.filteredFiles = this.files.filter((file: IFileItem) => this._valueInObject(value, file));
    this.dataGridDefinition.dataSource = this.files.filter((file: IFileItem) => this._valueInObject(value, file));
  }

  public removeFile(): void {
    if (this.selectedFile) {
      this.evtFileDelete.emit(this.selectedFile);
    }
  }

  public addFile(event: Event): void {
    const file: File = (<HTMLInputElement>event.target).files[0];
    this.evtFileUpload.emit(file);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    if (this.selectedFile) {
      this._dataGridInstance.deselectAll().then(() => {
        const items: Array<IFileItem> = this._dataGridInstance.getDataSource().items();
        const indexByDoc: number = items.findIndex((value: IFileItem) => value.docId === this.selectedFile.docId);
        if (indexByDoc !== -1) {
          this._dataGridInstance.selectRowsByIndexes([indexByDoc]);
        } else {
          const indexByFolder: number = items.findIndex((value: IFileItem) => value.folderId === this.selectedFile.folderId);
          if (indexByFolder !== -1) {
            this._dataGridInstance.selectRowsByIndexes([indexByFolder]);
          }
        }
      });
    }
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick({rowType, data}: IDevExpressDataGridEventOnCellClick<IFileItem>): void {
    if (rowType === 'data') {
      this.selectFile(data);
    }
  }

  public onCellDblClick(): Promise<void> {
    if (this.selectedFile && this.selectedFile.mimeType === 'application/pdf') {
      const modalInstance = this._cgModalService.showPdfVanilla(this.selectedFile.text, this.urlPdf);
      return modalInstance.result;
    }
    return Promise.resolve();
  }

  private _valueInObject(value: string, object: unknown): boolean {
    if (isArray(object)) {
      for (const item of object) {
        const inItem: boolean = this._valueInObject(value, item);
        if (inItem) {
          return true;
        }
      }
    } else if (isObject(object)) {
      for (const key of Object.keys(object)) {
        const val: unknown = object[key];
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const result = String(val).toUpperCase().includes(value.toString().toUpperCase());
        if (result) {
          return true;
        }
      }
    } else if (isDefinedNotNull(object)) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      return String(object).includes(value);
    }
    return false;
  }
}
