import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsoSSCol} from './jsonSSCol.entity.interface';
import {ENTITY_NAME_SSCOL} from './ssCol.entity.interface';

export const ENTITY_SSCOL: IEntityDefinition<IJsoSSCol> = {
  name: ENTITY_NAME_SSCOL,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.sscol',
  searchPlaceholder: 'sscol.pesquisa',
  metadata: {
    keyName: 'codSSCol',
    fields: [
      {name: 'codSSCol', caption: 'sscol.fields.codSSCol', placeholder: 'sscol.fields.codSSCol', validators: {required: true, maxlength: 5}},
      {name: 'designacao', caption: 'sscol.fields.designacao', placeholder: 'sscol.fields.designacao', validators: {required: true, maxlength: 50}},
      {name: 'dias', caption: 'sscol.fields.dias', placeholder: 'sscol.fields.dias', validators: {maxlength: 5}},
      {name: 'valor', caption: 'sscol.fields.valor', properties: {maxlength: 5}}
    ],
    order: 'codSSCol',
    searchFields: 'codSSCol,designacao'
  },
  autocomplete: {
    rowTemplate: '{{codSSCol}} - {{designacao}}',
    output: 'codSSCol',
    searchFields: 'codSSCol,designacao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
