import {Component, Injector} from '@angular/core';
import {IModule} from '../../../components/module/module.definition.interface';
import {ModuleRegistryService} from '../../../components/module/module.registry.service';
import {ModuloComponent} from '../../../components/module/module.component';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'module-modules',
  templateUrl: './modules.module.component.html'
})
export class ModulesModuleComponent extends ModuloComponent {
  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleRegistryService: ModuleRegistryService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'name', dataType: 'string', caption: ''},
        {dataField: 'state.url', dataType: 'string', caption: ''},
        {dataField: 'state.data.pageTitle', dataType: 'string', caption: ''},
        {dataField: 'state.data.description', dataType: 'string', caption: ''}
      ],
      dataSource: new CustomStore({
        load: () => this._getSource()
      }),
      remoteOperations: false
    };
  }

  private _getSource(): Array<IModule<unknown>> {
    return this._moduleRegistryService.getAll();
  }
}
