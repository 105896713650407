<div class="encomendas entity-detail-form">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="toolbarInstanceName"></config-options>

  <cg-card-panel #cardPanel="cgCardPanel" class="mb-3" [collapsed]="false">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="searchEncomendas" plPromise>
        <pl-group>
          <pl-group *ngIf="showArtigos">
            <label [translate]="'encomendas.header.artigos'"></label>
            <edit>
              <entity-autocomplete attrName="artigos" entity="artigos" [model]="header" [(selectedKey)]="header.nArtigo" [filter]="'artDesactivado=false&artBloqueado=false'" output="key" plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'encomendas.header.dataDoc'"></label>
            <edit>
              <pl-edit type="date" attrName="dataDocDe" [(model)]="header.dataDocDe"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" attrName="dataDocAte" [(model)]="header.dataDocAte"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'encomendas.header.dataPrevEnt'"></label>
            <edit>
              <pl-edit type="date" attrName="dataPrevEntregaDe" [(model)]="header.dataPrevEntregaDe"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" attrName="dataPrevEntregaAte" [(model)]="header.dataPrevEntregaAte"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group *ngIf="showArmazens">
            <label [translate]="'encomendas.modal.impressao.fields.armazens'"></label>
            <edit>
              <pl-select
                [source]="armazensSource"
                [(model)]="armazemSource"
                (evtChanged)="armazensChanged($event.item)"
                [rowTemplate]="armazemTemplate"
                [output]="armazemTemplate"
                [properties]="{placeholder: 'global.text.all' | translate}"
                [selectableGroup]="true"
                [multiple]="true">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group *ngIf="showDocFas">
            <label [translate]="'encomendas.modal.impressao.fields.docfaclist'"></label>
            <edit>
              <pl-select
                [source]="docFaListSource"
                [(model)]="docFaSource"
                (evtChanged)="docfaListChanged($event.item)"
                [rowTemplate]="docFaTemplate"
                [output]="'nDocFa'"
                [properties]="{placeholder: 'global.text.all' | translate}"
                [multiple]="true">
              </pl-select>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <hr />

  <pl-group *ngIf="!clifoMode">
    <label>
      <span [translate]="clifoDescription"></span>
      <pl-tooltip *ngIf="!processado" class="tooltip-info-label" [config]="{text: infoProcessamentoClifo, placement: ['bottom', 'left']}">
        <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
      </pl-tooltip>
    </label>
    <edit>
      <pl-autocomplete
        [source]="clifosSource"
        [model]="clifoSource"
        [rowTemplate]="outputClifos"
        (evtSelected)="clifoChanged($event.item)"
        [allowInvalid]="false"
        [output]="outputClifos"
        [properties]="{
          placeholder: processado
            ? ''
            : grupoClifos === 'clientes'
              ? 'encomendas.header.clientePlaceHolder'
              : grupoClifos === 'fornecedores'
                ? 'encomendas.header.fornecedorPlaceHolder'
                : 'encomendas.header.clifoPlaceHolder'
        }">
      </pl-autocomplete>
    </edit>
  </pl-group>

  <pl-group *ngIf="clifoMode">
    <label [translate]="clifoDescription"></label>
    <edit>
      <div class="form-control-align">{{ header.nConta }} - {{ header.nomeConta }}</div>
    </edit>
  </pl-group>

  <div class="bancos-extrato-module-grid">
    <dx-data-grid
      class="mt-3"
      cgDxDataGridInstanceName="gridEncomendasPendentes"
      [cgDxDataGrid]="dataGridDefinition"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onEditingStart)="onEditorStart($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onRowPrepared)="onRowPrepared($event)"
      (onKeyDown)="onKeyDown($event)"
      (cgDxDataGridStateLoad)="onDataGridStateLoad($event)">
      <div *dxTemplate="let item of 'templateBtnToolbar'">
        <pl-button klass="btn-success btn-sm me-2" *ngIf="!processado && !btnProcessar" [disabled]="!editMode || !header.nConta || !gridBtnProcessarEnabled" [onClick]="processaEncomendas">
          <i class="fa fa-cog" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.process'"></span>
        </pl-button>

        <pl-button klass="btn-light btn-sm" (evtClicked)="printEncPendentes()" *ngIf="!callFromFaturacao && !btnImprimir" [disabled]="!header.nConta">
          <i class="fa fa-print" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.print'"></span>
        </pl-button>
      </div>

      <div *dxTemplate="let item of 'cellTemplateActions'">
        <div ngbDropdown container="body" class="col-main-table-actions">
          <pl-tooltip *ngIf="!editMode && !processado" [config]="{text: captionSelecionarClifo, container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="fnSelecionarCliente(item.data)" plPromise><i class="fa fa-user-plus"></i></button>
          </pl-tooltip>
          <pl-tooltip *ngIf="!editMode && processado && !header.nConta" [config]="{text: captionVerDadosClifo, container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="fnSelecionarCliente(item.data)" plPromise><i class="fa fa-user"></i></button>
          </pl-tooltip>
          &nbsp;
          <pl-tooltip *ngIf="!editMode" [config]="{text: 'encomendas.actions.verDocumento', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="fnVerDocumento(item.data)" plPromise><i class="fa fa-file-text"></i></button>
          </pl-tooltip>

          <button *ngIf="editMode" type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
          <div *ngIf="editMode" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.satTotal'" (click)="satTotalmente(item.data)"></button>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.satTotalArtigo'" (click)="satTotLinhasArtigo(item.data)"></button>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.satTotalDocumento'" (click)="satTotLinhasDoc(item.data)"></button>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.satLinhaSemFat'" (click)="satLinhaSemFat(item.data)"></button>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.limparQtdSat'" (click)="limparQtdSat(item.data)"></button>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.limparQtdSatTodas'" (click)="limparQtdSatLinhas()"></button>
            <!-- <button type="button" class="dropdown-item" [translate]="captionSelecionarClifo | translate" (click)="selecionarCliente(item.data)" *ngIf="!editMode"></button> -->
            <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" [translate]="'encomendas.actions.verDocumento'" (click)="fnVerDocumento(item.data)" plPromise></button>
          </div>
        </div>
      </div>

      <div *dxTemplate="let row of 'dataGridTemplateDetail'">
        {{ row.data.textoLivre }}
      </div>
    </dx-data-grid>
  </div>

  <cg-table-legend *ngIf="editMode" [legend]="encomendasDataGridLegend"></cg-table-legend>
</div>
