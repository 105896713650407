import {IReportInstanceDefinition} from '../../components/reports/input/reports.input.component.interface';
import {EReport} from './reports.interface';

export const REPORT_RECIBOS: IReportInstanceDefinition = generateReport(EReport.Recibos, 'reccl');

export const REPORT_PAGAMENTOS: IReportInstanceDefinition = generateReport(EReport.Pagamentos, 'ESTDOCNL');

export const REPORT_DOCUMENTOS: IReportInstanceDefinition = generateReport(EReport.Documentos, 'CONTABILIDADE.ESTATISTICA\\VIZUTILI');

export const REPORT_ATIVOS: IReportInstanceDefinition = generateReport(EReport.Ativos, 'Ativos');

export const REPORT_ATIVOS_MAPAS_OFICIAIS: IReportInstanceDefinition = generateReport(EReport.AtivosMapasOficiais, 'AtivosMapas');

export const REPORT_BALANCETES_ANALITICA: IReportInstanceDefinition = generateReport(EReport.BalancetesAnalitica, 'BALANCETESANALITICA');

export const REPORT_BALANCETES_NORMAL: IReportInstanceDefinition = generateReport(EReport.BalancetesNormal, 'BALANCETE\\Normal');

export const REPORT_BALANCETES_COMPARATIVO: IReportInstanceDefinition = generateReport(EReport.BalancetesComparativo, 'BALANCETE\\Comparativo');

export const REPORT_EXTRATOS: IReportInstanceDefinition = generateReport(EReport.Extratos, 'VIZEXTCT');

export const REPORT_EXTRATOS_POR_CONTA: IReportInstanceDefinition = generateReport(EReport.ExtratosPorConta, 'CONTABILIDADE.ESTATISTICA\\EXTRACTOSCONTA');

export const REPORT_IMPDECPE: IReportInstanceDefinition = generateReport(EReport.Impdecpe, 'VIZDECPE');

export const REPORT_MOVIMENTOS_EM_ABERTO: IReportInstanceDefinition = generateReport(EReport.MovimentosEmAberto, 'CGFORMULARIOS.MOVABATEPRINT');

export const REPORT_SVAT: IReportInstanceDefinition = generateReport(EReport.Svat, 'SVAT');

export const REPORT_RECSAL: IReportInstanceDefinition = generateReport(EReport.RecibosSal, 'RECSAL');

export const REPORT_CONCILIACAO_BANCARIA: IReportInstanceDefinition = generateReport(EReport.ConciliacaoBancaria, 'ConcilBancaria');

export const REPORT_RH_PROCE_SEG_SOCIAL_MAPACX: IReportInstanceDefinition = generateReport(EReport.RHProceSegSocialMapacx, 'Mapacx');

export const REPORT_RH_PROCE_SEG_SOCIAL_MAPACX_RE: IReportInstanceDefinition = generateReport(EReport.RHProceSegSocialMapacxRe, 'MapacxRE');

export const REPORT_RH_PROCE_DMR_AT_MAPA: IReportInstanceDefinition = generateReport(EReport.RHProceDMRATMapa, 'DeclMensalRemuneracoesAT');

export const REPORT_PROC_SEGU_FOLHA_FERIAS_MAPA: IReportInstanceDefinition = generateReport(EReport.ProcSeguFolhaFeriasMapa, 'SeguradoraFFerias');

export const REPORT_DOCS_COMERCIAIS_ESTATISTICAS: IReportInstanceDefinition = generateReport(EReport.DocsComerciaisEstatisticas, 'GESTAOCOMERCIAL.ESTATISTICA\\DADOSFACTURACAO');

export const REPORT_DOCS_COMERCIAIS_ESTATISTICAS_DOC: IReportInstanceDefinition = generateReport(EReport.DocsComerciaisEstatisticasDoc, 'GESTAOCOMERCIAL.ESTATISTICA\\DADOSFACTURACAO\\DADOSCABECALHO');

export const REPORT_RH_FOLFER: IReportInstanceDefinition = generateReport(EReport.RHFolFer, 'FOLFER');

export const REPORT_INVENTARIOS: IReportInstanceDefinition = generateReport(EReport.Inventarios, 'inventar');

export const REPORT_RH_IMPVENCT: IReportInstanceDefinition = generateReport(EReport.RHImpVenct, 'VENCT');

export const REPORT_CTRIRS: IReportInstanceDefinition = generateReport(EReport.ImpCtrIrs, 'CTRIRS');

export const REPORT_FFERPOC: IReportInstanceDefinition = generateReport(EReport.ImpFFERPOC, 'FFERPOC');

export const REPORT_ENCAR: IReportInstanceDefinition = generateReport(EReport.ImpENCAR, 'ENCAR');

export const REPORT_IMP_AVISOS_COBRANCA: IReportInstanceDefinition = generateReport(EReport.ImpAvisosCobranca, 'SOLIC');

export const REPORT_AVISOS_COBRANCA: IReportInstanceDefinition = generateReport(EReport.AvisosCobranca, 'AvisosDeCobranca');

export const REPORT_EVENTOS_RH: IReportInstanceDefinition = generateReport(EReport.ImpEventosRH, 'RhEventos');

export const REPORT_IMP_DADOS_EMP: IReportInstanceDefinition = generateReport(EReport.ImpDadosEmp, 'DEMP');

export const REPORT_DEM_FIN_SNC: IReportInstanceDefinition = generateReport(EReport.DemFinSNC, 'DemFinSNC');

export const REPORT_CONTRATOS: IReportInstanceDefinition = generateReport(EReport.Contratos, 'CONTRATOS');

export const REPORT_IMPDE119: IReportInstanceDefinition = generateReport(EReport.ImpDe119, 'DEC119');

export const REPORT_MAPAS_SINDICATOS: IReportInstanceDefinition = generateReport(EReport.LSindic, 'LSINDIC');

export const REPORT_ENCOMENDAS: IReportInstanceDefinition = generateReport(EReport.Encomendas, 'CGFORMULARIOS.ENCOMENDASPRINT');

export const REPORT_ACRESCIMOS_ENCARGOS_FERIAS: IReportInstanceDefinition = generateReport(EReport.AcrescimosEncargosFerias, 'RHAcrescimosEncargosFerias');

export const REPORT_ADIANTAMENTOS: IReportInstanceDefinition = generateReport(EReport.Adiantamentos, 'Adiantamentos');

export const REPORT_TRIBUT_AUTONOMA_ANALISE: IReportInstanceDefinition = generateReport(EReport.TributAutonomaAnalise, 'TributAutonomaAnalise');

export const REPORT_DEBITOS_DIRETOS_SEPA: IReportInstanceDefinition = generateReport(EReport.DDSEPA, 'DEBITOSDIRETOSSEPA');

export const REPORT_DEBITOS_DIRETOS_SEPA_CLIENTE: IReportInstanceDefinition = generateReport(EReport.DDSEPACLIENTE, 'DEBITOSDIRETOSSEPA\\CLIENTES');

export const REPORT_LBANC: IReportInstanceDefinition = generateReport(EReport.LBanc, 'LBANC');

export const REPORT_ANALISE_MARGEM_LUCRO: IReportInstanceDefinition = generateReport(EReport.AnaliseMargemLucro, 'AnaliseMargemLucro');

export const REPORT_EXTRATOSDT: IReportInstanceDefinition = generateReport(EReport.ExtratosDT, 'EXTRACTOSDT');

function generateReport(reportName: EReport, folder: string): IReportInstanceDefinition {
  return {
    name: reportName,
    url: `reports/${folder}`,
    metadata: {
      keyName: 'name',
      fields: [
        {
          name: 'name',
          caption: 'reports.fields.name',
          placeholder: 'reports.fields.namePlaceholder'
        },
        {
          name: 'title',
          caption: 'reports.fields.title',
          placeholder: 'reports.fields.titlePlaceholder'
        }
      ]
    },
    autocomplete: {
      rowTemplate: '{{name}} - {{title}}',
      output: 'title'
    }
  };
}
