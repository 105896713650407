import type {Subscription} from 'rxjs';
import moment, {Moment, MomentInput} from 'moment';
import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {formatCurrency as angularFormatCurrency, formatDate as angularFormatDate, formatNumber as angularFormatNumber, getCurrencySymbol, getLocaleCurrencyCode} from '@angular/common';
import {ECGCDateType} from '../common/locale/locale.interface';
import type {IPlFormatConfig} from '../common/format/format.service.interface';
import {isNumber, isString} from '../common/utilities/utilities';
import {PlFormatService} from '../common/format/format.service';

@Injectable({
  providedIn: 'root'
})
export class PlI18nService implements OnDestroy {
  private readonly _subscriptionFormat: Subscription;
  private _format: IPlFormatConfig;

  constructor(
    private readonly _plFormatService: PlFormatService,
    @Inject(LOCALE_ID) private readonly _locale: string
  ) {
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
  }

  public formatCurrency(
    value: string | number,
    digitsInfo: string | number = this._getDigitsInfo(),
    currencyCode?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string = 'symbol-narrow',
    locale: string = this._locale
  ): string {
    value = this._parseNumber(value);
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    let currency: string = currencyCode || this._format.currencyCode || getLocaleCurrencyCode(locale);
    if (display !== 'code') {
      switch (display) {
        case 'symbol':
        case 'symbol-narrow':
          // eslint-disable-next-line @typescript-eslint/no-deprecated
          currency = getCurrencySymbol(currency, display === 'symbol' ? 'wide' : 'narrow', locale);
          break;
        default:
          currency = display;
          break;
      }
    }
    return angularFormatCurrency(value, locale, currency, currencyCode, this._parseDigitsInfo(String(digitsInfo)));
  }

  public formatDate(value: MomentInput, format: ECGCDateType | string = ECGCDateType.DATE, timezone?: string, locale: string = this._locale): string | null {
    if (!value) {
      return '';
    }
    /* eslint-disable @typescript-eslint/no-restricted-types */
    const dateValue: string | number | Date = value instanceof moment ? (<Moment>value).toDate() : <string | number | Date>value;
    /* eslint-enable @typescript-eslint/no-restricted-types */
    format = this._getDateTypeValue(format);
    return angularFormatDate(dateValue, format, locale, timezone);
  }

  public formatTime(value: MomentInput, timezone?: string, locale: string = this._locale): string | null {
    return this.formatDate(value, ECGCDateType.TIME, locale, timezone);
  }

  public formatDateTime(value: MomentInput, timezone?: string, locale: string = this._locale): string | null {
    return this.formatDate(value, ECGCDateType.DATETIME, locale, timezone);
  }

  public formatNumber(value: string | number, digitsInfo: string | number = this._getDigitsInfo(), locale: string = this._locale): string {
    value = this._parseNumber(value);
    return angularFormatNumber(value, locale, this._parseDigitsInfo(String(digitsInfo)));
  }

  public formatPercent(value: string | number, digitsInfo: string | number = this._getDigitsInfo(), locale: string = this._locale): string {
    value = this._parseNumber(value);
    return `${this.formatNumber(value, digitsInfo, locale)}%`;
  }

  private _getDigitsInfo(): string {
    return this._format.digitsInfoCurrency;
  }

  private _parseDigitsInfo(digitsInfo: string): string {
    if (!isString(digitsInfo) || digitsInfo.length !== 1) {
      return digitsInfo;
    }
    return `0.${digitsInfo}-${digitsInfo}`;
  }

  private _getDateTypeValue(format: ECGCDateType | string): string {
    format = format || '';
    return format.toUpperCase() in ECGCDateType ? this._format[format] : format;
  }

  private _parseNumber(value: string | number): number {
    if (isNumber(value)) {
      return !Number.isNaN(value) ? value : 0;
    }
    try {
      const val: number = parseFloat(value);
      return val || 0;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (exception: unknown) {
      return 0;
    }
  }
}
