import {isDefined, isObject, isUndefined} from 'pl-comps-angular';
import {ContabilidadeSvatComponent} from './components/contabilidade.svat.module.component';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-magic-numbers, @typescript-eslint/naming-convention */

export class SvatDataBuilder {
  public readonly subGroup: any;
  public tableDefinitions: any;
  public collapseTracking: {[uid: string]: boolean} = {};

  private readonly _constants: any;
  private _output: any;
  private _input: any;

  constructor(private readonly _svatComponent: ContabilidadeSvatComponent) {
    this._constants = {
      NONE: 0,
      RUBRICA: 1,
      SEP: 2,
      SUBRUBRICACAPTION: 3,
      MEDIARUBRICACAPTION: 4,
      GRANDERUBRICACAPTION: 5,
      SUBRUBRICATOTAL: 6,
      MEDIARUBRICATOTAL: 7,
      GRANDERUBRICATOTAL: 8,
      GERALTOTAL: 9
    };
    this.setup();
  }

  public _gen(length: number): string {
    const radom13chars = (): string => Math.random().toString(16).substring(2, 15);
    const loops = Math.ceil(length / 13);
    return new Array(loops)
      .fill(radom13chars)
      .reduce((value, func) => {
        return value + func();
      }, '')
      .substring(0, length);
  }

  public rand(): string {
    return this._gen(16);
  }

  public setup(): void {
    this.tableDefinitions = {
      subGroupTableDef: {
        keyname: 'rubricasID',
        fields: [
          {order: 1, name: 'descricao', caption: 'svat.fields.descricao'},
          {order: 2, name: 'notas', caption: 'svat.fields.notas', width: '10%'},
          {order: 3, name: 'anoN', caption: this._svatComponent.fieldAnoNCaption, type: 'currency', emptyIfZero: true, width: '15%'},
          {order: 4, name: 'anoN1', caption: this._svatComponent.fieldAnoN1Caption, type: 'currency', emptyIfZero: true, width: '15%'}
        ]
      },
      intervalosContasTableDef: {
        keyname: 'nContaDe',
        fields: [
          {order: 1, name: 'nContaDe', caption: 'svat.fields.nContaDe'},
          {order: 2, name: 'nContaAte', caption: 'svat.fields.nContaAte'},
          {order: 3, name: 'taxonomyCode', caption: 'svat.fields.taxonomyCode'},
          {order: 4, name: 'taxonomyDescription', caption: 'svat.fields.taxonomyDescription'},
          {order: 5, name: 'taxonomyClasse', caption: 'svat.fields.taxonomyClasse'},
          {order: 6, name: 'taxonomySaldoEsperado', caption: 'svat.fields.taxonomySaldoEsperado'},
          {order: 7, name: 'mesPeriodo', caption: 'svat.fields.mesPeriodo'},
          {order: 8, name: 'tipoSaldo', caption: 'svat.fields.tipoSaldo'},
          {order: 9, name: 'anoN', caption: this._svatComponent.fieldAnoNCaption, type: 'currency', emptyIfZero: true},
          {order: 10, name: 'anoN1', caption: this._svatComponent.fieldAnoN1Caption, type: 'currency', emptyIfZero: true}
        ]
      },
      tableDetailsDef: {
        keyname: 'nConta',
        fields: [
          {order: 1, name: 'nConta', caption: 'svat.fields.nConta'},
          {order: 2, name: 'nomeConta', caption: 'svat.fields.nomeCliente'},
          {order: 3, name: 'anoN', caption: this._svatComponent.fieldAnoNCaption, type: 'currency', emptyIfZero: true},
          {order: 4, name: 'anoN1', caption: this._svatComponent.fieldAnoN1Caption, type: 'currency', emptyIfZero: true}
        ]
      },
      balanceteTableDef: {
        keyname: 'nConta',
        fields: [
          {order: 1, name: 'nConta', caption: 'svat.fields.nConta'},
          {order: 2, name: 'nomeConta', caption: 'svat.fields.nomeCliente'},
          {order: 3, name: 'anoN', caption: this._svatComponent.fieldAnoNCaption, type: 'currency', emptyIfZero: true},
          {order: 4, name: 'anoN1', caption: this._svatComponent.fieldAnoN1Caption, type: 'currency', emptyIfZero: true}
        ]
      }
    };
  }

  public build(resp: any): Array<any> {
    this._output = [];
    this._input = resp.body || [];
    this.run();
    return this._output;
  }

  public createMainTab(title: string): any {
    const tab = {id: this.rand(), tabTitle: title || '', grandGroups: []};
    this._output.push(tab);
    return tab;
  }

  public createGrandGroup(parentObj: object, prop: string): any {
    const group = {uid: this.rand(), title: '', medGroups: [], total: {}, haveTotal: false};
    this.collapseTracking[group.uid] = false;
    if (parent && prop) {
      if (!parentObj[prop]) {
        parentObj[prop] = [];
      }
      parentObj[prop].push(group);
    }
    return group;
  }

  public createMedGroup(parentObj: object, prop: string): any {
    const group = {uid: this.rand(), title: '', subGroups: []};
    this.collapseTracking[group.uid] = false;
    if (parent && prop) {
      if (!parentObj[prop]) {
        parentObj[prop] = [];
      }
      parentObj[prop].push(group);
    }
    return group;
  }

  public createSubGroup(parentObj: object, prop: string): any {
    const subGroup = {
      uid: this.rand(),
      title: '',
      tableDef: this.tableDefinitions.subGroupTableDef,
      tableSource: {list: [], footer: []},
      details: []
    };
    this.collapseTracking[subGroup.uid] = false;
    if (parent && prop) {
      if (!parentObj[prop]) {
        parentObj[prop] = [];
      }
      parentObj[prop].push(subGroup);
    }
    return subGroup;
  }

  public createSubGroupTab(parentObj?: object, prop?: string): any {
    const subGroupTab = {id: this.rand(), tabTitle: '', tableDef: {}, tableSource: {list: [], footer: []}, tableDetailsDef: {}, tableDetailsSources: []};
    if (parent && prop) {
      if (!parentObj[prop]) {
        parentObj[prop] = [];
      }
      parentObj[prop].push(subGroupTab);
    }
    return subGroupTab;
  }

  public run(): void {
    if (!isObject(this._input)) {
      return;
    }
    // parse the Balanco
    let mainTab = this.createMainTab('svat.strings.mainTabBalanco');
    this._parseArray(this._input.rubricasBalanco, mainTab);
    // parse the Resultados Por Naturezas
    mainTab = this.createMainTab('svat.strings.mainTabDemoResultadosPorNaturezas');
    this._parseArray(this._input.rubricasDR, mainTab, true);
  }

  public toggleCollapseGroup(uid: string): void {
    this.collapseTracking[uid] = !this.collapseTracking[uid];
  }

  public isCollapsed(uid: string): boolean {
    return this.collapseTracking[uid];
  }

  private _parseArray(array: Array<unknown>, mainTab: any, initEmptyGroups?: boolean): void {
    let grandGroup;
    let medGroup;
    let subGroup;
    let item;

    // init empty tabGroup & subGroup
    if (isDefined(initEmptyGroups) && initEmptyGroups) {
      grandGroup = this.createGrandGroup(mainTab, 'grandGroups');
      medGroup = this.createMedGroup(grandGroup, 'medGroups');
      subGroup = this.createSubGroup(medGroup, 'subGroups');
    }

    for (let i = 0; i < array.length; i++) {
      item = array[i];
      if (item.tipo === this._constants.SEP) {
        continue;
      }

      if ((i === 0 && isUndefined(grandGroup)) || item.tipo === this._constants.GRANDERUBRICACAPTION) {
        grandGroup = this.createGrandGroup(mainTab, 'grandGroups');
        grandGroup.title = item.descricao;
        continue;
      }

      if ((i === 0 && isUndefined(medGroup)) || item.tipo === this._constants.MEDIARUBRICACAPTION) {
        if (isUndefined(grandGroup)) {
          grandGroup = this.createGrandGroup(mainTab, 'grandGroups');
        }
        medGroup = this.createMedGroup(grandGroup, 'medGroups');
        medGroup.title = item.descricao;
        if (isDefined(subGroup)) {
          subGroup = this.createSubGroup(medGroup, 'subGroups');
        }
        continue;
      }

      if ((i === 0 && isUndefined(subGroup)) || item.tipo === this._constants.SUBRUBRICACAPTION) {
        if (isUndefined(grandGroup)) {
          grandGroup = this.createGrandGroup(mainTab, 'grandGroups');
        }
        if (isUndefined(medGroup)) {
          medGroup = this.createMedGroup(grandGroup, 'medGroups');
        }
        subGroup = this.createSubGroup(medGroup, 'subGroups');
        subGroup.title = item.descricao;
        continue;
      }

      if (item.tipo === this._constants.RUBRICA) {
        if (isUndefined(grandGroup)) {
          grandGroup = this.createGrandGroup(mainTab, 'grandGroups');
        }
        if (isUndefined(medGroup)) {
          medGroup = this.createMedGroup(grandGroup, 'medGroups');
        }
        if (isUndefined(subGroup)) {
          subGroup = this.createSubGroup(medGroup, 'subGroups');
        }

        if (item.anoN === 0 && item.anoN1 === 0) {
          continue;
        }

        const obj = {
          rubricasID: item.rubricasID,
          descricao: item.descricao,
          notas: item.notas,
          anoN: item.anoN,
          anoN1: item.anoN1,
          subGroup: subGroup,
          tdClass: undefined
        };

        if (item.descricao === 'Resultado líquido do período') {
          obj.tdClass = 'resultado-liquido-periodo';
        }

        subGroup.tableSource.list.push(obj);

        const detail = [];

        // create sub tabs
        let tmpTab = this.createSubGroupTab();
        tmpTab.tabTitle = 'svat.strings.rubricaSubTabIntervaloContas';
        tmpTab.tableDef = this.tableDefinitions.intervalosContasTableDef;
        tmpTab.tableDetailsDef = this.tableDefinitions.tableDetailsDef;
        detail.push(tmpTab);

        for (const trace of item.traceList) {
          if (trace.anoN === 0 && trace.anoN1 === 0) {
            continue;
          }

          tmpTab.tableSource.list.push({
            nContaDe: trace.nContaDe,
            nContaAte: trace.nContaAte,
            taxonomyCode: trace.taxonomyCode,
            mesPeriodo: trace.mesPeriodo,
            tipoSaldo: trace.tipoSaldo,
            anoN: trace.anoN,
            anoN1: trace.anoN1,
            taxonomyDescription: trace.taxonomyDescription,
            taxonomyClasse: trace.taxonomyClasse,
            taxonomySaldoEsperado: trace.taxonomySaldoEsperado
          });

          const source = [];
          for (const detalhe of trace.detalheList) {
            if (detalhe.anoN === 0 && detalhe.anoN1 === 0) {
              continue;
            }
            source.push({
              nConta: detalhe.nConta,
              nomeConta: detalhe.nomeConta,
              anoN: detalhe.anoN,
              anoN1: detalhe.anoN1
            });
          }

          tmpTab.tableDetailsSources.push(source);
        }

        tmpTab = this.createSubGroupTab();
        tmpTab.tabTitle = 'svat.strings.rubricaSubTabBalancete';
        tmpTab.tableDef = this.tableDefinitions.balanceteTableDef;
        detail.push(tmpTab);

        // Tab Balancete Source
        for (const detalhe of item.detalheList) {
          tmpTab.tableSource.list.push({
            nConta: detalhe.nConta,
            nomeConta: detalhe.nomeConta,
            anoN: detalhe.anoN,
            anoN1: detalhe.anoN1,
            _hasDetail: false
          });
        }

        subGroup.details.push(detail);
      }

      if (item.tipo === this._constants.GRANDERUBRICATOTAL) {
        grandGroup.haveTotal = true;
        grandGroup.total = {
          descricao: item.descricao,
          anoN: item.anoN,
          anoN1: item.anoN1
        };
        continue;
      }

      if (item.tipo === this._constants.MEDIARUBRICATOTAL) {
        medGroup.haveTotal = true;
        medGroup.total = {
          descricao: item.descricao,
          anoN: item.anoN,
          anoN1: item.anoN1
        };
        continue;
      }

      if (item.tipo === this._constants.SUBRUBRICATOTAL) {
        subGroup.tableSource.footer.push({
          rubricasID: item.rubricasID,
          descricao: item.descricao,
          notas: item.notas,
          anoN: item.anoN,
          anoN1: item.anoN1
        });
        subGroup = undefined;
      }
    }
  }
}
