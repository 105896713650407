import moment from 'moment';
import {Subscription} from 'rxjs';
import {DxTooltipComponent} from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import {on} from 'devextreme/events';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  EMonth,
  IPlDynamicVisualsSecondaryClickAction,
  IPlFormatConfig,
  IPlTooltipConfig,
  isDefinedNotNull,
  isEmpty,
  isObject,
  isString,
  isUndefinedOrNull,
  PlAlertService,
  PlI18nService,
  PlTranslateService
} from 'pl-comps-angular';
import {AppService} from '../../../../../services/app/app.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../../../services/config/config.service';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {EGTOExecuteEstado, EGTOMostrarFilter, EGTOTarefasMarcacaoState, IJsonGTOCheckDMR, IJsonGTOTarefaMarc, IJsonGTOTarefas} from '../../jsonGTO.module.interface';
import {GtoAlertsModalComponent} from '../../modals/alerts/gto.alerts.modal.component';
import {GtoCreateEditTaskModalComponent} from '../../modals/createedittask/gto.createEditTask.modal.component';
import {GtoExecuteModalComponent} from '../../modals/execute/gto.execute.modal.component';
import {GtoService} from '../../gto.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnContextMenuPreparing} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IGTOResponsavel, IGTOSingleFilters, IGTOTabCallback, IGTOTarefa, IGTOTarefaMarc, IGTOTarefas} from '../../gto.module.interface';
import {IJsonEmpresaErpBasic} from '../../../../../interfaces/jsonEmpresa.interface';
import {IJsonErpUser, TUserSession} from '../../../../../services/account/jsonUserApi.interface';
import {IJsonNumber} from '../../../../../../common/interfaces/json';
import {monthNamesShort} from '../../../../../../common/dates';

@Component({
  selector: 'gto-por-empresa',
  templateUrl: './gto.porEmpresa.component.html'
})
export class GTOPorEmpresaComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DxTooltipComponent) public readonly tooltip: DxTooltipComponent;
  @Input() public sourceAnos: Array<number>;
  @Input() public sourceResponsaveis: Array<IGTOResponsavel>;
  @Input() public centralGestId: number;
  @Input() public storeModePublic: boolean;
  @Input() public callback: IGTOTabCallback;
  @Input() public gtoSingleFilters: IGTOSingleFilters;
  @Output() public readonly gtoSingleFiltersChange: EventEmitter<IGTOSingleFilters>;

  public readonly gtoMostrarFilterEnum: typeof EGTOMostrarFilter;
  public readonly configTooltip: IPlTooltipConfig;
  public readonly empresaOutput: string;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public usersTemplate: string;
  public sourceEmpresas: Array<IJsonEmpresaErpBasic>;
  public empresaView: string;
  public gtoTarefas: IGTOTarefas;
  public gtoMostrarFilter: EGTOMostrarFilter;
  public concluidoDentroPrazoCount: number;
  public guardarValidacaoCount: number;
  public emAtrasoJustificadoCount: number;
  public concluidoForaPrazoCount: number;
  public emAtrasoCount: number;
  public prazoADecorrerCount: number;
  public naoAplicavelCount: number;
  public captionCheckStateDMR: string;
  public promise: Promise<void>;
  public cellToolTipText: string;

  private readonly _decimais: number;
  private readonly _monthNamesShort: Array<string>;
  private readonly _subscriptionFormat: Subscription;
  private _formatDateTime: string;
  private _auxERPUser: IJsonErpUser;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _config: ConfigService,
    private readonly _configService: ConfigService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _gtoService: GtoService
  ) {
    this.gtoSingleFiltersChange = new EventEmitter<IGTOSingleFilters>();
    this.empresaOutput = '{{nempresa}} - {{nome}}';
    this.configTooltip = {disabled: false, text: 'gto.tabs.btn.alertas', placement: 'left', container: 'body', tooltipClass: 'tooltip-warning'};
    this.empresaView = '';
    this.gtoMostrarFilterEnum = EGTOMostrarFilter;
    this.gtoMostrarFilter = EGTOMostrarFilter.DataLimite;
    this.gtoTarefas = {haveAlerts: false, haveDMRAT: false, haveDMRSS: false, list: []};
    this.captionCheckStateDMR = 'gto.tabs.btn.checkstatedmratss';
    this.gtoSingleFilters = {
      nempresa: this._config.configurations.empresa.nEmpresa,
      ano: moment().year(),
      tipoTarefa: -1,
      nResponsavel: undefined,
      nome: this._config.configurations.empresa.nome
    };
    this.concluidoDentroPrazoCount = 0;
    this.guardarValidacaoCount = 0;
    this.emAtrasoJustificadoCount = 0;
    this.concluidoForaPrazoCount = 0;
    this.emAtrasoCount = 0;
    this.prazoADecorrerCount = 0;
    this.naoAplicavelCount = 0;
    this._decimais = this._configService.configurations.contabilidade.decimais.valor;
    this._monthNamesShort = monthNamesShort();
    this.dataGridDefinition = {
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      columns: this._evaluateDataGridDefinitionColumns(),
      dataSource: [],
      export: {filename: 'gto.tabs.title.porEmpresa'},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false},
      headerFilter: {visible: false},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      searchPanel: {visible: true},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false,
      allowColumnReordering: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateTable',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };

    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      this._formatDateTime = format.momentDatetime;
    });

    setTimeout(() => {
      this._getEmpresasList().then(() => {
        this._authService.identity().then((session: TUserSession) => {
          this._auxERPUser = session.erp;
        });
      });
    });
  }

  public ngOnInit(): void {
    this.usersTemplate = !this.storeModePublic ? '{{nResponsavel}} - {{nomeResponsavel}}' : '{{nomeResponsavel}}';
  }

  public ngOnChanges({callback}: SimpleChanges): void {
    if (callback) {
      const cb: IGTOTabCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.refresh = () => this._pesquisar();
        cb.getFilters = () => {
          return this.gtoSingleFilters;
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
  }

  public evtEmpresaChanged(value: IJsonEmpresaErpBasic): void {
    if (isObject(value)) {
      this.gtoSingleFilters.nempresa = value.nempresa;
      this.gtoSingleFilters.nome = value.nome;
      this.gtoSingleFiltersChange.emit(this.gtoSingleFilters);
    }
  }

  public checkStateDMR(checkDMRAT: boolean, checkDMRSS: boolean): Promise<void> {
    return this._gtoService
      .checkDMRSingle(this.gtoSingleFilters.nempresa, this.gtoSingleFilters.ano, this.gtoSingleFilters.tipoTarefa, this.gtoSingleFilters.nResponsavel, checkDMRAT, checkDMRSS)
      .then((response: HttpResponse<IJsonGTOCheckDMR>) => {
        this._plAlertService.success('gto.messages.validacaosuccess');
        if (response.body.needRefresh) {
          return this._pesquisar();
        }
        return Promise.resolve();
      });
  }

  public showAlertsModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GtoAlertsModalComponent, {size: 'lg'});
    const componentInstance: GtoAlertsModalComponent = modalInstance.componentInstance;
    componentInstance.requestData = {
      isMulti: false,
      nempresa: this.gtoSingleFilters.nempresa,
      ano: this.gtoSingleFilters.ano,
      mes: -1,
      tipoTarefa: this.gtoSingleFilters.tipoTarefa,
      nResponsavel: this.gtoSingleFilters.nResponsavel,
      periodoIVA: -1,
      regimeIRC: -1,
      nomeTarefa: undefined
    };
    return modalInstance.result;
  }

  public userSelect(user: string | IGTOResponsavel): void {
    let nResponsavel: number;
    let nomeResponsavel: string;
    if (isString(user)) {
      nResponsavel = Number(user);
      if (Number.isNaN(nResponsavel)) {
        nResponsavel = undefined;
      } else {
        const responsavel: IGTOResponsavel = this.sourceResponsaveis.find((item: IGTOResponsavel) => item.nResponsavel === nResponsavel);
        if (responsavel) {
          user = responsavel;
        }
      }
    }
    if (isObject(user)) {
      user = <IGTOResponsavel>user;
      nResponsavel = user.nResponsavel;
      nomeResponsavel = user.nomeResponsavel;
    }
    this.gtoSingleFilters = {
      ...this.gtoSingleFilters,
      nResponsavel: nResponsavel,
      nomeResponsavel: nomeResponsavel
    };
  }

  public getHint(tarefaMarc: IGTOTarefaMarc): string {
    let hintText = '';

    switch (tarefaMarc.state) {
      case EGTOTarefasMarcacaoState.NaoAplicavel:
        hintText = this._plTranslateService.translate('gto.estados.naoAplicavel');
        break;
      case EGTOTarefasMarcacaoState.GuardarValidacao:
        hintText = this._plTranslateService.translate('gto.estados.guardarValidacao');
        break;
      case EGTOTarefasMarcacaoState.EmAtrasoJustificado:
        hintText = this._plTranslateService.translate('gto.hints.emAtrasoJustificado', {justificacao: tarefaMarc.justificacao});
        break;
      case EGTOTarefasMarcacaoState.ConcluidoDentroPrazo:
        if (isUndefinedOrNull(tarefaMarc.dataExec)) {
          hintText = '';
          break;
        }
        hintText = this._plTranslateService.translate('gto.hints.concluidoDentroPrazo', {
          dataExec: moment(tarefaMarc.dataExec).format(this._formatDateTime),
          user: tarefaMarc.nUtilizNameExec
        });
        break;
      case EGTOTarefasMarcacaoState.ConcluidoForaPrazo:
        hintText = this._plTranslateService.translate('gto.hints.concluidoForaPrazo', {
          dataExec: moment(tarefaMarc.dataExec).format(this._formatDateTime),
          user: tarefaMarc.nUtilizNameExec
        });
        break;
      default:
        break;
    }

    if (tarefaMarc.valor.length) {
      if (hintText.length) {
        hintText += '<br/>';
      }
      hintText += this._plTranslateService.translate('gto.hints.valor', {valor: tarefaMarc.valor});
    }

    if (tarefaMarc.nota.length) {
      if (hintText.length) {
        hintText += '<br/>';
      }
      hintText += this._plTranslateService.translate('gto.hints.nota', {nota: tarefaMarc.nota});
    }

    return hintText;
  }

  public gtoMostrarFilterChanged(): void {
    this.dataGridDefinition.columns = this._evaluateDataGridDefinitionColumns();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IGTOTarefa>): void {
    if (event.rowType === 'data') {
      const gtoTarefaMarc: IGTOTarefaMarc = this._getGTOTarefaMarc(event.column.dataField, event.data);
      if (gtoTarefaMarc && !isEmpty(gtoTarefaMarc._cssClass)) {
        event.cellElement.classList.add(gtoTarefaMarc._cssClass);
        if (event.column.dataField !== 'nomeTarefa') {
          on(event.cellElement, 'mouseover', (arg) => {
            this.cellToolTipText = this.getHint(gtoTarefaMarc);
            if (this.cellToolTipText.length) {
              this.tooltip.instance.show(arg.target);
            }
          });

          on(event.cellElement, 'mouseout', () => {
            this.tooltip.instance.hide();
          });
        }
      }
    }
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IGTOTarefa>): void {
    if (event.target === 'content' && event.row.rowType === 'data' && isObject(event.row.data) && event.column.dataField !== 'nomeTarefa') {
      event.event.preventDefault();
      const gtoTarefaMarc: IGTOTarefaMarc = this._getGTOTarefaMarc(event.column.dataField, event.row.data);
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data, gtoTarefaMarc);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public getDataLimitePlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.data)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.data).format('DD/MM')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getDataExecucaoPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.dataExec)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.dataExec).format('DD/MM')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getDiasPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.data)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.data).format('DD')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getValuesPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.valor) && marcacao.valor.length) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${this._plI18nService.formatNumber(marcacao.valor, this._decimais)}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public fnPesquisar = (): Promise<void> => this._pesquisar();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _getEmpresasList(): Promise<void> {
    return this._gtoService.getEmpresasEEWithActiveTasks().then((response: HttpResponse<Array<IJsonEmpresaErpBasic>>) => {
      this.sourceEmpresas = response.body;
      const index = this.sourceEmpresas.findIndex((value: IJsonEmpresaErpBasic) => value.nempresa === this.gtoSingleFilters.nempresa);
      if (index !== -1) {
        this.empresaView = this.sourceEmpresas[index].nome;
      }
    });
  }

  private _pesquisar(): Promise<void> {
    if (this.promise) {
      return Promise.resolve();
    }
    this.promise = this._gtoService
      .getDataForSingleView(this.gtoSingleFilters)
      .then((response: HttpResponse<IJsonGTOTarefas>) => {
        this.gtoTarefas.list = [];
        this.gtoTarefas = response.body;
        if (this.gtoTarefas.list.length) {
          this._cardPanel.collapse();
        } else {
          this._cardPanel.focusFirstElement();
          this._plAlertService.info('global.text.searchNoData');
        }
        this._evaluateStateGTOTarefas();

        this.captionCheckStateDMR =
          (this.gtoTarefas.haveDMRSS && this.gtoTarefas.haveDMRAT) || (!this.gtoTarefas.haveDMRSS && !this.gtoTarefas.haveDMRAT)
            ? 'gto.tabs.btn.checkstatedmratss'
            : this.gtoTarefas.haveDMRSS && !this.gtoTarefas.haveDMRAT
              ? 'gto.tabs.btn.checkstatedmrss'
              : 'gto.tabs.btn.checkstatedmrat';
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _evaluateStateGTOTarefas(): void {
    this.concluidoDentroPrazoCount = 0;
    this.guardarValidacaoCount = 0;
    this.emAtrasoJustificadoCount = 0;
    this.concluidoForaPrazoCount = 0;
    this.emAtrasoCount = 0;
    this.prazoADecorrerCount = 0;
    this.naoAplicavelCount = 0;

    for (const gtoTarefa of this.gtoTarefas.list) {
      for (const tarefaMarc of gtoTarefa.gtoTarefaMarc) {
        if (isEmpty(tarefaMarc.gtoTarefasMarcID)) {
          tarefaMarc._cssClass = '';
        } else {
          switch (tarefaMarc.state) {
            case EGTOTarefasMarcacaoState.ConcluidoDentroPrazo:
              tarefaMarc._cssClass = 'state-color-concluido-dentro-prazo';
              this.concluidoDentroPrazoCount++;
              break;
            case EGTOTarefasMarcacaoState.EmAtraso:
              tarefaMarc._cssClass = 'state-color-em-atraso';
              this.emAtrasoCount++;
              break;
            case EGTOTarefasMarcacaoState.ConcluidoForaPrazo:
              tarefaMarc._cssClass = 'state-color-concluido-fora-prazo';
              this.concluidoForaPrazoCount++;
              break;
            case EGTOTarefasMarcacaoState.EmAtrasoJustificado:
              tarefaMarc._cssClass = 'state-color-em-atraso-justificado';
              this.emAtrasoJustificadoCount++;
              break;
            case EGTOTarefasMarcacaoState.NaoAplicavel:
              tarefaMarc._cssClass = 'state-color-nao-aplicavel';
              this.naoAplicavelCount++;
              break;
            case EGTOTarefasMarcacaoState.GuardarValidacao:
              tarefaMarc._cssClass = 'state-color-guardar-validacao';
              this.guardarValidacaoCount++;
              break;
            case EGTOTarefasMarcacaoState.PrazoADecorrer:
              tarefaMarc._cssClass = 'state-color-prazo-a-decorrer';
              this.prazoADecorrerCount++;
              break;
            default:
              tarefaMarc._cssClass = '';
              break;
          }
        }

        if (moment(tarefaMarc.dataExec).year() !== this.gtoSingleFilters.ano) {
          tarefaMarc.dataExec = undefined;
        }
        if (moment(tarefaMarc.data).year() !== this.gtoSingleFilters.ano) {
          tarefaMarc.data = undefined;
        }
      }
      gtoTarefa.janeiro = gtoTarefa.gtoTarefaMarc[EMonth.January];
      gtoTarefa.fevereiro = gtoTarefa.gtoTarefaMarc[EMonth.February];
      gtoTarefa.marco = gtoTarefa.gtoTarefaMarc[EMonth.March];
      gtoTarefa.abril = gtoTarefa.gtoTarefaMarc[EMonth.April];
      gtoTarefa.maio = gtoTarefa.gtoTarefaMarc[EMonth.May];
      gtoTarefa.junho = gtoTarefa.gtoTarefaMarc[EMonth.June];
      gtoTarefa.julho = gtoTarefa.gtoTarefaMarc[EMonth.July];
      gtoTarefa.agosto = gtoTarefa.gtoTarefaMarc[EMonth.August];
      gtoTarefa.setembro = gtoTarefa.gtoTarefaMarc[EMonth.September];
      gtoTarefa.outubro = gtoTarefa.gtoTarefaMarc[EMonth.October];
      gtoTarefa.novembro = gtoTarefa.gtoTarefaMarc[EMonth.November];
      gtoTarefa.dezembro = gtoTarefa.gtoTarefaMarc[EMonth.December];
    }
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: 'gtoTarefaID',
      data: this.gtoTarefas.list
    });
  }

  private _getGTOTarefaMarc(datafield: string, data: IGTOTarefa): IGTOTarefaMarc {
    switch (datafield) {
      case 'janeiro.data':
      case 'janeiro.dataExec':
      case 'janeiro.valor':
        return data.janeiro;
      case 'fevereiro.data':
      case 'fevereiro.dataExec':
      case 'fevereiro.valor':
        return data.fevereiro;
      case 'marco.data':
      case 'marco.dataExec':
      case 'marco.valor':
        return data.marco;
      case 'abril.data':
      case 'abril.dataExec':
      case 'abril.valor':
        return data.abril;
      case 'maio.data':
      case 'maio.dataExec':
      case 'maio.valor':
        return data.maio;
      case 'junho.data':
      case 'junho.dataExec':
      case 'junho.valor':
        return data.junho;
      case 'julho.data':
      case 'julho.dataExec':
      case 'julho.valor':
        return data.julho;
      case 'agosto.data':
      case 'agosto.dataExec':
      case 'agosto.valor':
        return data.agosto;
      case 'setembro.data':
      case 'setembro.dataExec':
      case 'setembro.valor':
        return data.setembro;
      case 'outubro.data':
      case 'outubro.dataExec':
      case 'outubro.valor':
        return data.outubro;
      case 'novembro.data':
      case 'novembro.dataExec':
      case 'novembro.valor':
        return data.novembro;
      case 'dezembro.data':
      case 'dezembro.dataExec':
      case 'dezembro.valor':
        return data.dezembro;
      default:
        return undefined;
    }
  }

  private _generateSecondaryClickActions(line: IGTOTarefa, tarefaMarc: IJsonGTOTarefaMarc): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'gto.tabs.actions.executartarefa',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcID),
        icon: 'fa-bolt',
        click: () => {
          const modalInstance = this._cgModalService.showVanilla(GtoExecuteModalComponent, {size: 'lg'});
          const componentInstance: GtoExecuteModalComponent = modalInstance.componentInstance;
          componentInstance.sessionNUtilizador = this._auxERPUser.nUtilizador;
          componentInstance.nResponsavel = line.nResponsavel;
          componentInstance.gtoTarefaMarcacao = tarefaMarc;
          modalInstance.result.then(() => this._pesquisar());
        }
      },
      {
        caption: 'gto.tabs.actions.concluirtarefa',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
        icon: 'fa-check',
        click: () => {
          return this._gtoService
            .executeTask({
              gtoTarefasMarcacaoID: tarefaMarc.gtoTarefasMarcID,
              execDate: moment(),
              state: EGTOExecuteEstado.Concluir,
              justificacao: '',
              valor: '',
              nota: ''
            })
            .then((response: HttpResponse<IJsonNumber>) => {
              tarefaMarc.state = response.body.value;
              this._evaluateStateGTOTarefas();
              this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            });
        }
      },
      {
        caption: 'gto.tabs.actions.conctarefdentroprazo',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
        icon: 'fa-check',
        click: () => {
          const now = moment();
          return this._gtoService
            .executeTask({
              gtoTarefasMarcacaoID: tarefaMarc.gtoTarefasMarcID,
              execDate: now > moment(tarefaMarc.data) ? tarefaMarc.data : now,
              state: EGTOExecuteEstado.Concluir,
              justificacao: '',
              valor: '',
              nota: ''
            })
            .then((response: HttpResponse<IJsonNumber>) => {
              tarefaMarc.state = response.body.value;
              this._evaluateStateGTOTarefas();
              this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            });
        }
      },
      {
        divider: true
      },
      {
        caption: 'gto.tabs.actions.editartarefa',
        icon: 'fa-pencil',
        click: () => {
          const modalInstance = this._cgModalService.showVanilla(GtoCreateEditTaskModalComponent, {size: 'lg'});
          const componentInstance: GtoCreateEditTaskModalComponent = modalInstance.componentInstance;
          componentInstance.nempresa = this._auxERPUser.nEmpresa;
          componentInstance.nomeEmpresa = this._auxERPUser.nomeEmpresa;
          componentInstance.nUtilizador = this._auxERPUser.nUtilizador;
          componentInstance.gtoTarefaId = line.gtoTarefaID;
          return modalInstance.result.then(() => {
            this._pesquisar();
          });
        }
      },
      {
        caption: 'gto.tabs.actions.desativartarefa',
        icon: 'fa-ban',
        click: () => {
          return this._gtoService.toggleActive(line.gtoTarefaID, false).then(() => {
            this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            this._pesquisar();
          });
        }
      }
    ];
  }

  private _evaluateDataGridDefinitionColumns(): Array<IDevExpressDataGridColumn> {
    switch (this.gtoMostrarFilter) {
      case EGTOMostrarFilter.DataLimite:
      case EGTOMostrarFilter.DataLimitePlusNotes:
        const genDataLimiteGridColumn = (dataField: string, caption: string): IDevExpressDataGridColumn => {
          const column: IDevExpressDataGridColumn = {dataField: dataField, dataType: 'date', format: 'dd/MM', caption: caption, alignment: 'center'};
          if (this.gtoMostrarFilter === EGTOMostrarFilter.DataLimitePlusNotes) {
            column.cellTemplate = 'dataLimitePlusNotesTmpl';
          }
          return column;
        };

        return [
          {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.tabs.table.fields.nometarefa'},
          genDataLimiteGridColumn('janeiro.data', this._monthNamesShort[EMonth.January]),
          genDataLimiteGridColumn('fevereiro.data', this._monthNamesShort[EMonth.February]),
          genDataLimiteGridColumn('marco.data', this._monthNamesShort[EMonth.March]),
          genDataLimiteGridColumn('abril.data', this._monthNamesShort[EMonth.April]),
          genDataLimiteGridColumn('maio.data', this._monthNamesShort[EMonth.May]),
          genDataLimiteGridColumn('junho.data', this._monthNamesShort[EMonth.June]),
          genDataLimiteGridColumn('julho.data', this._monthNamesShort[EMonth.July]),
          genDataLimiteGridColumn('agosto.data', this._monthNamesShort[EMonth.August]),
          genDataLimiteGridColumn('setembro.data', this._monthNamesShort[EMonth.September]),
          genDataLimiteGridColumn('outubro.data', this._monthNamesShort[EMonth.October]),
          genDataLimiteGridColumn('novembro.data', this._monthNamesShort[EMonth.November]),
          genDataLimiteGridColumn('dezembro.data', this._monthNamesShort[EMonth.December])
        ];
      case EGTOMostrarFilter.Values:
      case EGTOMostrarFilter.ValuesPlusNotes:
        const genValuesGridColumn = (dataField: string, caption: string): IDevExpressDataGridColumn => {
          const column: IDevExpressDataGridColumn = {dataField: dataField, dataType: 'double', caption: caption, alignment: 'center'};
          if (this.gtoMostrarFilter === EGTOMostrarFilter.ValuesPlusNotes) {
            column.cellTemplate = 'valuesPlusNotesTmpl';
          }
          return column;
        };

        return [
          {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.tabs.table.fields.nometarefa'},
          genValuesGridColumn('janeiro.valor', this._monthNamesShort[EMonth.January]),
          genValuesGridColumn('fevereiro.valor', this._monthNamesShort[EMonth.February]),
          genValuesGridColumn('marco.valor', this._monthNamesShort[EMonth.March]),
          genValuesGridColumn('abril.valor', this._monthNamesShort[EMonth.April]),
          genValuesGridColumn('maio.valor', this._monthNamesShort[EMonth.May]),
          genValuesGridColumn('junho.valor', this._monthNamesShort[EMonth.June]),
          genValuesGridColumn('julho.valor', this._monthNamesShort[EMonth.July]),
          genValuesGridColumn('agosto.valor', this._monthNamesShort[EMonth.August]),
          genValuesGridColumn('setembro.valor', this._monthNamesShort[EMonth.September]),
          genValuesGridColumn('outubro.valor', this._monthNamesShort[EMonth.October]),
          genValuesGridColumn('novembro.valor', this._monthNamesShort[EMonth.November]),
          genValuesGridColumn('dezembro.valor', this._monthNamesShort[EMonth.December])
        ];
      case EGTOMostrarFilter.Dias:
      case EGTOMostrarFilter.DiasPlusNotes:
        const genDiasGridColumn = (dataField: string, caption: string): IDevExpressDataGridColumn => {
          const column: IDevExpressDataGridColumn = {dataField: dataField, dataType: 'date', format: 'day', caption: caption, alignment: 'center'};
          if (this.gtoMostrarFilter === EGTOMostrarFilter.DiasPlusNotes) {
            column.cellTemplate = 'diasPlusNotesTmpl';
          }
          return column;
        };
        return [
          {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.tabs.table.fields.nometarefa'},
          genDiasGridColumn('janeiro.data', this._monthNamesShort[EMonth.January]),
          genDiasGridColumn('fevereiro.data', this._monthNamesShort[EMonth.February]),
          genDiasGridColumn('marco.data', this._monthNamesShort[EMonth.March]),
          genDiasGridColumn('abril.data', this._monthNamesShort[EMonth.April]),
          genDiasGridColumn('maio.data', this._monthNamesShort[EMonth.May]),
          genDiasGridColumn('junho.data', this._monthNamesShort[EMonth.June]),
          genDiasGridColumn('julho.data', this._monthNamesShort[EMonth.July]),
          genDiasGridColumn('agosto.data', this._monthNamesShort[EMonth.August]),
          genDiasGridColumn('setembro.data', this._monthNamesShort[EMonth.September]),
          genDiasGridColumn('outubro.data', this._monthNamesShort[EMonth.October]),
          genDiasGridColumn('novembro.data', this._monthNamesShort[EMonth.November]),
          genDiasGridColumn('dezembro.data', this._monthNamesShort[EMonth.December])
        ];
      case EGTOMostrarFilter.DataExecucao:
      case EGTOMostrarFilter.DataExecucaoPlusNotes:
        const genDataExecucaoGridColumn = (dataField: string, caption: string): IDevExpressDataGridColumn => {
          const column: IDevExpressDataGridColumn = {dataField: dataField, dataType: 'date', format: 'dd/MM', caption: caption, alignment: 'center'};
          if (this.gtoMostrarFilter === EGTOMostrarFilter.DataExecucaoPlusNotes) {
            column.cellTemplate = 'dataExecucaoPlusNotesTmpl';
          }
          return column;
        };
        return [
          {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.tabs.table.fields.nometarefa'},
          genDataExecucaoGridColumn('janeiro.dataExec', this._monthNamesShort[EMonth.January]),
          genDataExecucaoGridColumn('fevereiro.dataExec', this._monthNamesShort[EMonth.February]),
          genDataExecucaoGridColumn('marco.dataExec', this._monthNamesShort[EMonth.March]),
          genDataExecucaoGridColumn('abril.dataExec', this._monthNamesShort[EMonth.April]),
          genDataExecucaoGridColumn('maio.dataExec', this._monthNamesShort[EMonth.May]),
          genDataExecucaoGridColumn('junho.dataExec', this._monthNamesShort[EMonth.June]),
          genDataExecucaoGridColumn('julho.dataExec', this._monthNamesShort[EMonth.July]),
          genDataExecucaoGridColumn('agosto.dataExec', this._monthNamesShort[EMonth.August]),
          genDataExecucaoGridColumn('setembro.dataExec', this._monthNamesShort[EMonth.September]),
          genDataExecucaoGridColumn('outubro.dataExec', this._monthNamesShort[EMonth.October]),
          genDataExecucaoGridColumn('novembro.dataExec', this._monthNamesShort[EMonth.November]),
          genDataExecucaoGridColumn('dezembro.dataExec', this._monthNamesShort[EMonth.December])
        ];
      default:
        return [];
    }
  }
}
