import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, isArray, isEmpty} from 'pl-comps-angular';
import {CGModalComponent} from '../cgmodal.component';
import {ICGHelpTopic} from './helptopics.modal.component.interface';

@Component({
  selector: 'cg-help-topics-modal',
  templateUrl: './helptopics.modal.component.html'
})
export class CGHelpTopicsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public title: string;
  @Input() public topics: Array<ICGHelpTopic>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    if (isEmpty(this.title)) {
      this.title = 'global.text.help';
    }
    this.topics = !isArray(this.topics) || !this.topics.length ? [] : copy(this.topics);
  }
}
