import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHGozoFerias} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CONFIGURACOES_GOZO_FERIAS} from '../rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {RHGozoFeriasModuleComponent} from './components/rhGozoFerias.module.component';

export const MODULE_RH_CONFIGURACOES_GOZO_FERIAS: IModuleDefinition = {
  name: MODULE_NAME_RH_CONFIGURACOES_GOZO_FERIAS,
  state: {
    url: `/${MODULE_NAME_RH_CONFIGURACOES_GOZO_FERIAS}`,
    component: RHGozoFeriasModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhgozoferias'
    },
    resolve: [
      {
        provide: 'model',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHGozoFerias> => {
          return rhConfiguracoesService.getConfigGozoFerias().then((response: HttpResponse<IJsonConfigRHGozoFerias>) => response.body);
        }
      }
    ]
  }
};
