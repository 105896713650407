import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonClasseCaixa} from './jsonClasseCaixa.entity.interface';
import {ENTITY_NAME_CLASSES_CAIXA} from './classesCaixa.entity.interface';
import {ClassesCaixaEditComponent} from './components/edit/classesCaixa.entity.edit.component';

export const ENTITY_CLASSES_CAIXA: IEntityDefinition<IJsonClasseCaixa> = {
  name: ENTITY_NAME_CLASSES_CAIXA,
  roles: [ROLE.RH],
  searchPlaceholder: 'classescaixa.pesquisa',
  pageTitle: 'global.menu.classescaixa',
  metadata: {
    keyName: 'nclcx',
    fields: [
      {name: 'nclcx', width: '200px', caption: 'classescaixa.fields.nClasseCaixa', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'classescaixa.fields.designacaoBreve', validators: {required: true, maxlength: 100}},
      {name: 'percentPATR', type: 'double', caption: 'classescaixa.fields.percentPatr', properties: {decimalsLimit: 2}},
      {name: 'percentEMPR', type: 'double', caption: 'classescaixa.fields.percentEmpr', properties: {decimalsLimit: 2}},
      {name: 'naoProcSubNatal', type: 'boolean', caption: 'classescaixa.fields.naoProcSubNatal', properties: {devExpress: {dataGrid: {visible: false}}}},
      {name: 'naoProcSubFerias', type: 'boolean', caption: 'classescaixa.fields.naoProcSubFerias', properties: {devExpress: {dataGrid: {visible: false}}}}
    ],
    order: 'nclcx',
    searchFields: 'nclcx,designacaoBreve,naoProcSubNatal,naoProcSubFerias,percentPATR,percentEMPR'
  },
  autocomplete: {
    rowTemplate: '{{nclcx}} - {{designacaoBreve}} - Patronal: {{percentPATR}}% - Empregado: {{percentEMPR}}%',
    output: 'designacaoBreve',
    searchFields: 'nclcx,designacaoBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: ClassesCaixaEditComponent
    }
  }
};
