import {Component, Injector} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonContratoFaturadoDocSel} from '../../faturacaoContratos.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {DocumentosService} from '../../../../entities/docscomerciais/service/documentos.entity.service';
import {copy, isObject, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {FaturacaoContratosService} from '../../faturacaoContratos.module.service';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {EEstadoDocFaturado, IEstadosDocRadioItem, IGestaoContratosFaturadosFilterModel} from './gestaoAvencas.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonContratoTipo, IJsonContratoTipoSelect} from '../../../../entities/contratostipos/jsonContratoTipo.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import moment from 'moment';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {DocumentoDetailModalComponent} from '../../../../entities/docscomerciais/modals/detalhe/documento.detail.modal.component';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';
import {TDate} from '../../../../../common/dates';
import {ENTITY_NAME_CONTRATOS_TIPOS} from '../../../../entities/contratostipos/contratosTipos.entity.interface';

@Component({
  selector: 'gestao-avencas-modal',
  templateUrl: './gestaoAvencas.modal.component.html'
})
export class GestaoAvencasModalComponent extends CGModalComponent<void> {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly tipoEstadoDoc: typeof EEstadoDocFaturado;
  public readonly estadosDocSource: Array<IEstadosDocRadioItem>;
  public filterModel: IGestaoContratosFaturadosFilterModel;
  public selectedRowKeys: Array<number>;
  public onClickAction: boolean;
  public btnEncerrarVisible: boolean;
  public contratosFaturados: Array<IJsonContratoFaturadoDocSel>;
  public contratoTipoSource: Array<IJsonContratoTipoSelect>;
  public contratoTipolist: Array<string>;

  private readonly _documentosService: DocumentosService;
  private readonly _contratoTipoService: IEntityService<IJsonContratoTipo>;
  private readonly _maintenanceInstanceDocsComerciais: IEntityMaintenanceInstance;
  private readonly _contaDe: string;
  private readonly _contaAte: string;
  private _dataGridInstance: dxDataGrid;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _faturacaoContratosService: FaturacaoContratosService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this._maintenanceInstanceDocsComerciais = this._entityMaintenanceService.build('vendas');

    this.tipoEstadoDoc = EEstadoDocFaturado;

    this.estadosDocSource = [
      {value: this.tipoEstadoDoc.NaoEncerrados, label: 'gestaoContratos.filters.estadosDoc.naoEncerrados'},
      {value: this.tipoEstadoDoc.Encerrados, label: 'gestaoContratos.filters.estadosDoc.encerrados'},
      {value: this.tipoEstadoDoc.Todos, label: 'global.text.all'}
    ];

    this._isFirstTime = true;
    this._documentosService = this._injector.get<DocumentosService>(DocumentosService);
    this._contratoTipoService = this._entityServiceBuilder.build(ENTITY_NAME_CONTRATOS_TIPOS);
    this.selectedRowKeys = [];
    this.contratoTipolist = [];
    this.contratoTipoSource = [];
    this.contratosFaturados = [];

    this._contaDe = this._configService.configurations.clientesFornecedores.radicalClientes;
    this._contaAte = `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`;

    this.filterModel = {
      nClasseContrato: '',
      nTiposContrato: '',
      dataDocDe: moment().startOf('month'),
      dataDocAte: moment().endOf('month'),
      nContaDe: this._contaDe,
      nContaAte: this._contaAte,
      estadoDoc: EEstadoDocFaturado.NaoEncerrados
    };

    this.btnEncerrarVisible = this.filterModel.estadoDoc !== EEstadoDocFaturado.Encerrados;

    this.dataGridDefinition = {
      keyExpr: 'faccbId',
      columns: [
        {dataField: 'contratoAsString', dataType: 'string', caption: 'faturacaoContratosLog.tabs.faturados.fields.contratoAsString', allowEditing: false, allowResizing: true},
        {dataField: 'descricao', dataType: 'string', caption: 'faturacaoContratosLog.tabs.faturados.fields.descricao', allowEditing: false},
        {dataField: 'nConta', dataType: 'string', caption: 'faturacaoContratosLog.tabs.faturados.fields.nConta', allowEditing: false},
        {dataField: 'contaNome', dataType: 'number', caption: 'faturacaoContratosLog.tabs.faturados.fields.contaNome', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'faturacaoContratosLog.tabs.faturados.fields.dataDoc', allowEditing: false},
        {dataField: 'nDocAsString', dataType: 'string', caption: 'faturacaoContratosLog.tabs.faturados.fields.nDocAsString', allowEditing: false},
        {dataField: 'docJaImprimido', dataType: 'boolean', caption: 'faturacaoContratosLog.tabs.faturados.fields.docJaImprimido', allowEditing: false},
        {dataField: 'emailEnviado', dataType: 'boolean', caption: 'faturacaoContratosLog.tabs.faturados.fields.emailEnviado', allowEditing: false},
        {
          dataField: 'vf_actions',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false,
          visible: true,
          width: 117
        }
      ],
      dataSource: [],
      columnHidingEnabled: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };

    this.onClickAction = false;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this._isFirstTime) {
      this._dataGridInstance.selectAll();
      this._isFirstTime = false;
    }
  }

  public onSelectedRowKeysChange(): void {
    for (const docGerado of this.contratosFaturados) {
      docGerado.selecionado = false;
    }

    for (const selectedKey of this.selectedRowKeys) {
      const index: number = this.contratosFaturados.findIndex((item: IJsonContratoFaturadoDocSel) => item.faccbId === selectedKey);
      if (index !== -1) {
        this.contratosFaturados[index].selecionado = true;
      }
    }
  }

  public fnPesquisar = (): Promise<void> => this._pesquisar();

  public classeContratoChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      return;
    }
    this._contratoTipoService.query({pesquisa: this._getClasse(value)}).then((response) => {
      this.contratoTipoSource = response.body.list;
      for (const item of this.contratoTipoSource) {
        item.selected = true;
      }
    });

    this.filterModel.nClasseContrato = value;
  }

  public onDataDocDeChange(value: TDate): void {
    this.filterModel.dataDocDe = value ? moment(value) : undefined;
    this.filterModel.dataDocAte = this.filterModel.dataDocDe.clone().endOf('month');
  }

  public previsualizarDoc(item: IJsonContratoFaturadoDocSel): Promise<void> {
    return this._documentosService.getDoc(item.faccbId).then((result) => {
      this._documentosService.getPdf(result.body, true);
    });
  }

  public encerrarImprimirDoc(item: IJsonContratoFaturadoDocSel): Promise<void> {
    return this._documentosService.getDoc(item.faccbId).then((data) => {
      if (data?.body?.cab?.nDocumento !== 0 && data?.body?.cab?.terminado) {
        this._plAlertService.info(this._translateService.instant('faturacaoContratosLog.errors.documentoJaEncerrado', {nDoc: item.nDocAsString}));
        return this._documentosService.getPdf(data.body, false);
      }
      return this._documentosService.encerrarDocumento(data?.body, true).then((result) => {
        item.nDocumento = result?.cab?.nDocumento;
        item.nDocAsString = result?.cab?.nDoc;
        this._dataGridInstance.refresh();
        return this._documentosService.getPdf(result, false);
      });
    });
  }

  public editarDocumento(item: IJsonContratoFaturadoDocSel): Promise<void> {
    return this._maintenanceInstanceDocsComerciais.maintenanceEdit(item.faccbId).then((result: IJsonDocComercial) => {
      item.nDocumento = result?.cab?.nDocumento;
      item.nDocAsString = result?.cab?.nDoc;
      return this._dataGridInstance.refresh();
    });
  }

  public visualizarDocumento(item: IJsonContratoFaturadoDocSel): Promise<void> {
    return this._documentosService.getDoc(item.faccbId).then((response) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoDetailModalComponent);
      const componentInstance: DocumentoDetailModalComponent = modalRef.componentInstance;
      componentInstance.doc = response.body;
      return modalRef.result.then(() => {
        this._dataGridInstance.refresh();
      });
    });
  }

  public onNContaDeChanged(value: string | IJsonClifo): void {
    if (isUndefinedOrNull(value) || value === '0') {
      this.filterModel.nContaDe = this._contaDe;
      this.filterModel.nContaAte = this._contaAte;
      return;
    }
    if (isObject(value)) {
      const clifo = <IJsonClifo>value;
      this.filterModel.nContaDe = clifo.nConta;
      this.filterModel.nContaAte = clifo.nConta;
    } else {
      this.filterModel.nContaDe = <string>value;
      this.filterModel.nContaAte = this._contaAte;
    }
  }

  public onNContaAteChanged(value: string): void {
    if (!value) {
      this.filterModel.nContaAte = this._contaAte;
      return;
    }
    this.filterModel.nContaAte = value;
  }

  public encerrarSelecionados(): Promise<void> {
    let numPorEncerrarSelecionados = 0;

    for (const item of this.contratosFaturados) {
      if (item.selecionado && item.nDocumento === 0) {
        numPorEncerrarSelecionados += 1;
      }
    }

    if (numPorEncerrarSelecionados === 0) {
      this._plAlertService.warning(this._translateService.instant('faturacaoContratosLog.info.documentosEncerrados'));
      return undefined;
    }

    return this._cgModalService.showOkCancel('faturacaoContratosLog.okCancel.encerrardocs.title', 'faturacaoContratosLog.okCancel.encerrardocs.message').then(() => {
      const self: GestaoAvencasModalComponent = this;
      self.onClickAction = true;
      const items = self.contratosFaturados;
      self._faturacaoContratosService
        .encerrarDocsGeradosServicos(items)
        .then((response: HttpResponse<void>) => {
          if (response) {
            self._faturacaoContratosService.getProcContratoGeradosOperationsResults().then((responseAux: HttpResponse<Array<IJsonContratoFaturadoDocSel>>) => {
              if (responseAux) {
                let numContratosEncerrados = 0;
                for (const contrato of responseAux.body) {
                  if (contrato.nDocumento > 0) {
                    numContratosEncerrados++;
                  }
                }

                if (numContratosEncerrados > 0) {
                  self.contratosFaturados = copy(responseAux.body);
                  if ((responseAux.body.length = numContratosEncerrados)) {
                    self._plAlertService.success(self._translateService.instant('faturacaoContratosLog.success.documentosEncerrados'));
                  } else {
                    self._plAlertService.success(self._translateService.instant('faturacaoContratosLog.success.encerradosNumDocumentos', {value: numContratosEncerrados}));
                  }
                } else {
                  self._plAlertService.error(self._translateService.instant('faturacaoContratosLog.errors.erroAEncerrarDocs'));
                }
              }
              self.onClickAction = false;
              return self._dataGridInstance.refresh();
            });
          }
        })
        .catch((reason) => {
          self.onClickAction = false;
          const exception = this._cgExceptionService.get(reason);
          if (exception) {
            this._plAlertService.error(exception.message);
          } else {
            this._plAlertService.error('faturacaoContratosLog.errors.erroAEncerrarDoc');
          }
          return undefined;
        });
    });
  }

  public enviarFatEletronicaSelecionadas(): Promise<void> {
    let numEmailsSelEnviados = 0;
    let numEmailsPorEnviar = 0;
    let numEmailsPorEncerrar = 0;

    for (const item of this.contratosFaturados) {
      if (item.selecionado) {
        if (item.emailEnviado) {
          numEmailsSelEnviados += 1;
        } else if (item.nDocumento === 0) {
          numEmailsPorEncerrar += 1;
        } else {
          numEmailsPorEnviar += 1;
        }
      }
    }

    if (numEmailsSelEnviados === 0 && numEmailsPorEnviar === 0 && numEmailsPorEncerrar === 0) {
      this._plAlertService.warning(this._translateService.instant('faturacaoContratosLog.errors.naoExistemItensSelecionados'));
      return undefined;
    }

    if (numEmailsPorEncerrar > 0 && numEmailsSelEnviados === 0 && numEmailsPorEnviar === 0) {
      this._plAlertService.error(this._translateService.instant('faturacaoContratosLog.errors.documentosEnvioEmailNaoEncerrados'));
      return undefined;
    }

    if (numEmailsPorEncerrar > 0) {
      this._plAlertService.warning(this._translateService.instant('faturacaoContratosLog.info.docsNaoEncerradosSelecionadosParaEnvioEmail'));
    }

    const message = numEmailsSelEnviados === 0 ? 'faturacaoContratosLog.okCancel.enviardocs.message' : 'faturacaoContratosLog.okCancel.enviardocs.message2';

    return this._cgModalService.showOkCancel('faturacaoContratosLog.okCancel.enviardocs.title', message).then(() => {
      const self: GestaoAvencasModalComponent = this;
      self.onClickAction = true;
      let numEmailsEnviados = 0;
      const items = self.contratosFaturados;
      for (const item of items) {
        if (item.selecionado && item.emailEnviado) {
          item.emailEnviado = false;
        }
      }
      return self._faturacaoContratosService
        .enviarEmailDocsGeradosServicos(items)
        .then((response: HttpResponse<void>) => {
          if (response) {
            return self._faturacaoContratosService.getProcContratoGeradosOperationsResults().then((responseAux: HttpResponse<Array<IJsonContratoFaturadoDocSel>>) => {
              if (responseAux) {
                if (responseAux.body.length > 0) {
                  self.contratosFaturados = copy(responseAux.body);
                  for (const item of self.contratosFaturados) {
                    if (item.selecionado && item.nDocumento > 0 && item.emailEnviado) {
                      numEmailsEnviados += 1;
                    }
                  }
                }
              }
              if (numEmailsEnviados > 0) {
                self._plAlertService.success(self._translateService.instant('faturacaoContratosLog.success.emailsEnviados', {value: numEmailsEnviados}));
              } else {
                self._plAlertService.error(self._translateService.instant('faturacaoContratosLog.errors.emailsNaoEnviados'));
              }
              self.onClickAction = false;
              return self._dataGridInstance.refresh();
            });
          }
          self.onClickAction = false;
          return undefined;
        })
        .catch((reason) => {
          self.onClickAction = false;
          const exception = this._cgExceptionService.get(reason);
          if (exception) {
            this._plAlertService.error(exception.message);
          } else {
            this._plAlertService.error('faturacaoContratosLog.errors.erroAEnviarEmail');
          }
          return undefined;
        });
    });
  }

  private _pesquisar(): Promise<void> {
    this.contratoTipolist = [];
    for (const item of this.contratoTipoSource) {
      if (item.selected) {
        this.contratoTipolist.push(item.nTipoContrato);
      }
    }
    this.filterModel.nTiposContrato = this.contratoTipolist.toString();

    if (!this._validate()) {
      return undefined;
    }

    return this._faturacaoContratosService
      .getContratosServicosFaturados(
        this.filterModel.nClasseContrato,
        this.filterModel.nTiposContrato,
        this.filterModel.dataDocDe,
        this.filterModel.dataDocAte,
        this.filterModel.nContaDe,
        this.filterModel.nContaAte,
        this.filterModel.estadoDoc
      )
      .then((response) => {
        this.contratosFaturados = response.body.list;
        this._dataGridInstance.refresh();
        this._dataGridInstance.selectAll();
        this.btnEncerrarVisible = this.filterModel.estadoDoc !== EEstadoDocFaturado.Encerrados;
      });
  }

  private _getClasse(value: string): string {
    return `nClasseCtr=${value}&classeTipo=0`;
  }

  private _validate(): boolean {
    if (!this.filterModel.nClasseContrato) {
      this._plAlertService.error('faturacaoContratos.messages.semClasseSelecionada');
      return false;
    }
    if (!this.filterModel.nTiposContrato) {
      this._plAlertService.error('faturacaoContratos.messages.semTiposSelecionado');
      return false;
    }
    if (!this.filterModel.dataDocDe) {
      this._plAlertService.error('faturacaoContratos.messages.semDataDocDeSelecionado');
      return false;
    }
    if (!this.filterModel.dataDocAte) {
      this._plAlertService.error('faturacaoContratos.messages.semDataDocAteSelecionado');
      return false;
    }
    if (!this.filterModel.nContaDe) {
      this._plAlertService.error('faturacaoContratos.messages.semNContaDeSelecionado');
      return false;
    }
    if (!this.filterModel.nContaAte) {
      this._plAlertService.error('faturacaoContratos.messages.semNContaAteSelecionado');
      return false;
    }
    return true;
  }
}
