import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {RHProcSegSocialConsultEnviosModuleComponent} from './components/rhProcCegCocialConsultEnvios.module.component';
import {MODULE_NAME_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS} from './rhProcCegCocialConsultEnvios.module.interface';

export const MODULE_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS: IModuleDefinition = {
  name: MODULE_NAME_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS,
  state: {
    url: '/processamentosegsocialconsultaenvios',
    component: RHProcSegSocialConsultEnviosModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhprocsegsocialconsultenvios'
    },
    params: {
      ano: {
        type: 'int',
        value: -1
      },
      mes: {
        type: 'int',
        value: -1
      }
    }
  }
};
