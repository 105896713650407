import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {merge} from 'lodash-es';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isString, PlAlertService} from 'pl-comps-angular';
import {AtivosConfiguracoesService} from '../ativosConfiguracoes.module.service';
import {devExpressValidatorDigitsOnly} from '../../../../components/devexpress/validator/devexpress.validators.interface';
import {
  EAtivosConfiguracoesDepAgrupa,
  IRadicaisSujeitos,
  TAtivosConfiguracoesContasDepValidationCallbackData,
  TAtivosConfiguracoesRadicaisValidationCallbackData
} from '../ativosConfiguracoes.module.interface';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IDevExpressDataGrid, IDevExpressDataGridEditCellTemplateData} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnInitNewRow} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressValidationAsyncRule} from '../../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IJsonAtivosconfiguracoes, IJsonContasDepreciacao, IJsonContasInvestCurso} from '../jsonAtivosConfiguracoes.module.interface';

let radicalListId = 0;

@Component({
  selector: 'module-ativos-configuracoes',
  templateUrl: './ativosConfiguracoes.module.component.html'
})
export class AtivosConfiguracoesModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public model: IJsonAtivosconfiguracoes;

  public readonly depsAgrupa: typeof EAtivosConfiguracoesDepAgrupa;
  public readonly dataGridDefinitionRadical: IDevExpressDataGrid;
  public readonly dataGridDefinitionContasDep: IDevExpressDataGrid;
  public readonly dataGridDefinitionContasInvestCurso: IDevExpressDataGrid;
  public readonly filterMovimento: string;
  public dataSourceContasDep: ArrayStore<IJsonContasDepreciacao, IJsonContasDepreciacao>;
  public dataSourceContasInvestCurso: ArrayStore<IJsonContasInvestCurso>;
  public dataSourceRadicalList: Array<IRadicaisSujeitos>;
  public radical: IRadicaisSujeitos;
  public depAgrupa: EAtivosConfiguracoesDepAgrupa;

  private readonly _servicePocs: IEntityService<IJsonPOC>;
  private _dataGridInstanceContasDep: dxDataGrid<IJsonContasDepreciacao, IJsonContasDepreciacao>;
  private _dataGridInstanceContasInvestCurso: dxDataGrid<IJsonContasInvestCurso>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _ativosConfiguracoesService: AtivosConfiguracoesService
  ) {
    super(_injector);
    this.depsAgrupa = EAtivosConfiguracoesDepAgrupa;
    this.dataGridDefinitionRadical = {
      columns: [
        {
          dataField: 'radicalSujeito',
          dataType: 'string',
          caption: 'ativos.configuracoes.radicaisSujeitosList',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('ativos.configuracoes.messages.radicalRequired')
            },
            devExpressValidatorDigitsOnly(this._translateService.instant('ativos.configuracoes.messages.radicalInvalid')),
            {
              type: 'custom',
              message: this._translateService.instant('ativos.configuracoes.messages.radicalJaExistente'),
              validationCallback: (options: TAtivosConfiguracoesRadicaisValidationCallbackData) => this._validateRadical(options)
            }
          ]
        }
      ],
      keyExpr: 'id',
      editing: {
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: false},
      columnChooser: {enabled: false}
    };
    const validatorPoc: IDevExpressValidationAsyncRule = {
      type: 'async',
      message: this._translateService.instant('ativos.configuracoes.messages.pocInvalid'),
      validationCallback: (options: TAtivosConfiguracoesContasDepValidationCallbackData) => this._validatePoc(options)
    };
    this.dataGridDefinitionContasDep = {
      columns: [
        {
          dataField: 'contaImobilizado',
          dataType: 'string',
          caption: 'ativos.configuracoes.contaImobilizado',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('ativos.configuracoes.messages.contaImobilizadoRequired')
            },
            devExpressValidatorDigitsOnly(this._translateService.instant('ativos.configuracoes.messages.contaImobilizadoInvalid'))
          ]
        },
        {
          dataField: 'contaDepreciacao',
          dataType: 'string',
          caption: 'ativos.configuracoes.contaDepreciacao',
          editCellTemplate: 'editCellContaDepreciacao',
          validationRules: [validatorPoc]
        },
        {
          dataField: 'contaDepreciacaoAcumulada',
          dataType: 'string',
          caption: 'ativos.configuracoes.contaDepreciacaoAcumulada',
          editCellTemplate: 'editCellContaDepreciacaoAcumulada',
          validationRules: [validatorPoc]
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: true},
      columnChooser: {enabled: false}
    };
    this.dataGridDefinitionContasInvestCurso = {
      columns: [
        {
          dataField: 'cursoRadicalInvestimentos',
          dataType: 'string',
          caption: 'ativos.configuracoes.cursoRadicalInvestimentos',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('ativos.configuracoes.messages.radicalRequired')
            },
            devExpressValidatorDigitsOnly(this._translateService.instant('ativos.configuracoes.messages.radicalInvalid'))
          ]
        },
        {
          dataField: 'cursoRadicalAtivo',
          dataType: 'string',
          caption: 'ativos.configuracoes.cursoRadicalAtivo',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('ativos.configuracoes.messages.radicalRequired')
            },
            devExpressValidatorDigitsOnly(this._translateService.instant('ativos.configuracoes.messages.radicalInvalid'))
          ]
        },
        {
          dataField: 'cursoContaTransicao',
          dataType: 'string',
          caption: 'ativos.configuracoes.cursoContaTransicao',
          editCellTemplate: 'editCellCursoContaTransicao',
          validationRules: [
            {
              type: 'async',
              message: this._translateService.instant('ativos.configuracoes.messages.contaTransicaoInvalid'),
              validationCallback: (options: TAtivosConfiguracoesContasDepValidationCallbackData) => this._validatePoc(options)
            }
          ]
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: true},
      columnChooser: {enabled: false}
    };
    this.dataSourceRadicalList = [];
    this.depAgrupa = EAtivosConfiguracoesDepAgrupa.AtivosPeriodo;
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.model = merge(
      {
        trataAtivosNoCentralGest: false,
        novoBemRadical: undefined,
        novoBemNDigitos: undefined,
        novoBemOperacaoSeccao: undefined,
        novoBemNCCusto: undefined,
        novoBemNZona: undefined,
        novoBemNSubde: undefined,
        novoBemDepartamento: undefined,
        novoBemTipoArtigo: undefined,
        novoBemGrandeFamilia: undefined,
        novoBemFamilia: undefined,
        novoBemNSubfa: undefined,
        novoBemCodContab: undefined,
        novoBemClasse: undefined,
        novoBemCategoria: undefined,
        novoBemNVendedor: undefined,
        novoBemNRefProcesso: undefined,
        convertidoParaAtivos: false,
        depreciacoesNDiario: undefined,
        depreciacoesNDescritivo: undefined,
        aquisicaoNDiario: undefined,
        aquisicaoNDescritivo: undefined,
        ajustesNConta: undefined,
        ajustesNDiario: undefined,
        ajustesNDescritivo: undefined,
        errosNConta: undefined,
        errosNDiario: undefined,
        errosNDescritivo: undefined,
        vendaNDiario: undefined,
        vendaNDescritivo: undefined,
        abateNDiario: undefined,
        abateNDescritivo: undefined,
        depreciacaoDataEncerramento: undefined,
        dataLimiteParaCalculoContabilisticoIgualFiscal: false,
        contabilidadeAnaliticaUsa: false,
        contabilidadeAnaliticaMascara: undefined,
        taxaIRCEstimada: undefined,
        mostraContabilidadeConversao: false,
        conversaoIgnoraAdquiridosNoAno: false,
        conversaoConverteAbatidosAnosAnteriores: false,
        radicaisContasImobilizado: undefined,
        vendaRadicalMenosValiaFatura: undefined,
        vendaRadicalMaisValiaFatura: undefined,
        vendaNContaMenosValia: undefined,
        vendaNContaMaisValia: undefined,
        abateRadical: undefined,
        radicalIVA: undefined,
        radicaisFornecedorAquisicao: undefined,
        impostosDiferidosContaPassivo: undefined,
        impostosDiferidosContaAjustamentos: undefined,
        impostosDiferidosContaImpostoDiferido: undefined,
        depreciacoesAgrupaAnual: false,
        depreciacoesAgrupaDocumento: false,
        abpRadical: undefined,
        abpNDiario: undefined,
        abpNDescritivo: undefined,
        abpNContaReducao: undefined,
        abpNContaAumento: undefined,
        impostosDiferidosContaPassivoABP: undefined,
        impostosDiferidosContaAtivoABP: undefined,
        impostosDiferidosContaAjustamentosABP: undefined,
        reavaliacaoNDiario: undefined,
        reavaliacaoNDescritivo: undefined,
        reavaliacaoNContaContrapartida: undefined,
        impostosDiferidosContaPassivoReavaliacoes: undefined,
        impostosDiferidosContaAjustamentosReavaliacoes: undefined,
        jaExistemDepreciacoesEfetuadasParaOAno: false,
        depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao: false,
        depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes: false,
        nomeAquisicaoDiario: undefined,
        nomeAquisicaoDescritivo: undefined,
        nomeImpostosDiferidosContaPassivo: undefined,
        nomeImpostosDiferidosContaAjustamentos: undefined,
        nomeImpostosDiferidosContaImpDiferidos: undefined,
        nomeDepreciacoesDiario: undefined,
        nomeDepreciacoesDescritivos: undefined,
        nomeAjustesContaContrapartida: undefined,
        nomeAjustesDiario: undefined,
        nomeAjustesDescritivo: undefined,
        nomeErrosContaContrapartida: undefined,
        nomeErrosDiario: undefined,
        nomeErrosDescritivo: undefined,
        nomeVendaDiario: undefined,
        nomeVendaDescritivo: undefined,
        nomeVendaContaMaisValia: undefined,
        nomeVendaContaMenosValia: undefined,
        nomeAbateDiario: undefined,
        nomeAbateDescritivo: undefined,
        nomeClassificadoresCCusto: undefined,
        nomeClassificadoresZona: undefined,
        nomeClassificadoresDepartamento: undefined,
        nomeClassificadoresSubDepartamento: undefined,
        nomeClassificadoresTipoArtigo: undefined,
        nomeClassificadoresGrandeFamilia: undefined,
        nomeClassificadoresFamilia: undefined,
        nomeClassificadoresSubFamilia: undefined,
        nomeClassificadoresContabilidade: undefined,
        nomeClassificadoresClasse: undefined,
        nomeClassificadoresCategoria: undefined,
        nomeClassificadoresVendedor: undefined,
        nomeClassificadoresProcesso: undefined,
        contasDepreciacaoList: [],
        radicaisSujeitosList: undefined,
        analiticaContaContraPartida: undefined,
        nomeAnaliticaContaContrapartida: undefined,
        nomeABPNDiario: undefined,
        nomeABPNDescritivo: undefined,
        nomeABPNContaReducao: undefined,
        nomeABPNContaAumento: undefined,
        nomeImpostosDiferidosContaPassivoABP: undefined,
        nomeImpostosDiferidosContaAtivoABP: undefined,
        nomeImpostosDiferidosContaAjustamentosABP: undefined,
        nomeReavaliacaoNDiario: undefined,
        nomeReavaliacaoNDescritivo: undefined,
        nomeReavaliacaoNContaContrapartida: undefined,
        nomeImpostosDiferidosContaPassivoReavaliacoes: undefined,
        nomeImpostosDiferidosContaAjustamentosReavaliacoes: undefined,
        contasInvestCursoList: []
      },
      this.model
    );
    this.dataSourceContasDep = new ArrayStore({
      data: this.model.contasDepreciacaoList
    });
    this.dataSourceContasInvestCurso = new ArrayStore({
      data: this.model.contasInvestCursoList
    });
    this.toolbar
      .addButton({
        id: 'guardar',
        order: 2,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
        class: 'btn-primary',
        caption: 'ativos.configuracoes.btn.guardar',
        click: () => this._guardar()
      })
      .addButton({
        id: 'cancelar',
        order: 2,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-ban"></i>',
        class: 'btn-light',
        caption: 'ativos.configuracoes.btn.cancelar',
        click: () => this._cancelar()
      });
    if (!this.model.novoBemNCCusto) {
      this.model.novoBemNCCusto = undefined;
      this.model.nomeClassificadoresCCusto = undefined;
    }
    if (!this.model.novoBemNZona) {
      this.model.novoBemNZona = undefined;
      this.model.nomeClassificadoresDepartamento = undefined;
    }
    if (!this.model.novoBemDepartamento) {
      this.model.novoBemDepartamento = undefined;
      this.model.nomeClassificadoresSubDepartamento = undefined;
    }
    if (!this.model.novoBemNSubde) {
      this.model.novoBemNSubde = undefined;
      this.model.nomeClassificadoresTipoArtigo = undefined;
    }
    if (!this.model.novoBemTipoArtigo) {
      this.model.novoBemTipoArtigo = undefined;
      this.model.nomeClassificadoresGrandeFamilia = undefined;
    }
    if (!this.model.novoBemGrandeFamilia) {
      this.model.novoBemGrandeFamilia = undefined;
      this.model.nomeClassificadoresFamilia = undefined;
    }
    if (!this.model.novoBemFamilia) {
      this.model.novoBemFamilia = undefined;
      this.model.nomeClassificadoresSubFamilia = undefined;
    }
    if (!this.model.novoBemNSubfa) {
      this.model.novoBemNSubfa = undefined;
      this.model.nomeClassificadoresContabilidade = undefined;
    }
    if (!this.model.novoBemCodContab) {
      this.model.novoBemCodContab = undefined;
      this.model.nomeClassificadoresClasse = undefined;
    }
    if (!this.model.novoBemClasse) {
      this.model.novoBemClasse = undefined;
      this.model.nomeClassificadoresCategoria = undefined;
    }
    if (!this.model.novoBemCategoria) {
      this.model.novoBemCategoria = undefined;
      this.model.nomeClassificadoresVendedor = undefined;
    }
    if (!this.model.novoBemNVendedor) {
      this.model.novoBemNVendedor = undefined;
      this.model.nomeClassificadoresProcesso = undefined;
    }
    this._modelToRadicalList();
    this._setDepAgrupa();
  }

  public onDataGridRadicalInitNewRow(event: IDevExpressDataGridEventOnInitNewRow<IRadicaisSujeitos, number>): void {
    event.data.id = radicalListId++;
  }

  public onDataGridInitializedContasDep(event: IDevExpressDataGridEventOnInitialized<IJsonContasDepreciacao, IJsonContasDepreciacao>): void {
    this._dataGridInstanceContasDep = event.component;
  }

  public onDataGridDisposingContasDep(): void {
    this._dataGridInstanceContasDep = undefined;
  }

  public onDataGridInitializedContasInvestCurso(event: IDevExpressDataGridEventOnInitialized<IJsonContasInvestCurso>): void {
    this._dataGridInstanceContasInvestCurso = event.component;
  }

  public onDataGridDisposingContasInvestCurso(): void {
    this._dataGridInstanceContasInvestCurso = undefined;
  }

  public changedCellContaDepreciacao(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContasDepreciacao>, value: string | IJsonPOC): void {
    let nConta: string;
    let nomeConta: string;
    if (isString(value)) {
      nConta = value || null;
      nomeConta = null;
    } else {
      nConta = value?.nConta || null;
      nomeConta = value?.nome || null;
    }
    cellInfo.setValue(nConta, nConta);
    cellInfo.component.cellValue(cellInfo.rowIndex, 'nomeContaDepreciacao', nomeConta);
  }

  public changedCellContaDepreciacaoAcumulada(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContasDepreciacao>, value: string | IJsonPOC): void {
    let nConta: string;
    let nomeConta: string;
    if (isString(value)) {
      nConta = value;
      nomeConta = null;
    } else {
      nConta = value?.nConta || null;
      nomeConta = value?.nome || null;
    }
    cellInfo.setValue(nConta, nConta);
    cellInfo.component.cellValue(cellInfo.rowIndex, 'nomeContaDepreciacaoAcumulada', nomeConta);
  }

  public changedCellContaTransicao(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContasInvestCurso>, value: string | IJsonPOC): void {
    let nConta: string;
    if (isString(value)) {
      nConta = value;
    } else {
      nConta = value?.nConta || null;
    }
    cellInfo.setValue(nConta, nConta);
    cellInfo.component.cellValue(cellInfo.rowIndex, 'cursoContaTransicao');
  }

  public changedDepAgrupa(depAgrupa: EAtivosConfiguracoesDepAgrupa): void {
    this.depAgrupa = depAgrupa;
    switch (this.depAgrupa) {
      case EAtivosConfiguracoesDepAgrupa.AtivoPeriodo:
        this.model.depreciacoesAgrupaDocumento = false;
        this.model.depreciacoesAgrupaAnual = false;
        break;
      case EAtivosConfiguracoesDepAgrupa.AtivoPeriodoUltimo:
        this.model.depreciacoesAgrupaDocumento = false;
        this.model.depreciacoesAgrupaAnual = true;
        break;
      case EAtivosConfiguracoesDepAgrupa.AtivosPeriodo:
        this.model.depreciacoesAgrupaDocumento = true;
        this.model.depreciacoesAgrupaAnual = false;
        break;
      case EAtivosConfiguracoesDepAgrupa.AtivosPeriodoUltimo:
        this.model.depreciacoesAgrupaDocumento = true;
        this.model.depreciacoesAgrupaAnual = true;
        break;
    }
  }

  private _setDepAgrupa(): void {
    if (!this.model.depreciacoesAgrupaDocumento && !this.model.depreciacoesAgrupaAnual) {
      this.depAgrupa = EAtivosConfiguracoesDepAgrupa.AtivoPeriodo;
    } else if (this.model.depreciacoesAgrupaDocumento && !this.model.depreciacoesAgrupaAnual) {
      this.depAgrupa = EAtivosConfiguracoesDepAgrupa.AtivosPeriodo;
    } else if (!this.model.depreciacoesAgrupaDocumento && this.model.depreciacoesAgrupaAnual) {
      this.depAgrupa = EAtivosConfiguracoesDepAgrupa.AtivoPeriodoUltimo;
    } else {
      this.depAgrupa = EAtivosConfiguracoesDepAgrupa.AtivosPeriodoUltimo;
    }
  }

  private _modelToRadicalList(): void {
    if (this.model.radicaisSujeitosList) {
      const radicalList: Array<string> = this.model.radicaisSujeitosList.split(',');
      this.dataSourceRadicalList = radicalList.map<IRadicaisSujeitos>((value: string) => {
        return {id: radicalListId++, radicalSujeito: value};
      });
    }
  }

  private async _guardar(): Promise<void> {
    this.model.radicaisSujeitosList = this.dataSourceRadicalList.map((item: IRadicaisSujeitos) => item.radicalSujeito).join(',');
    if (this._dataGridInstanceContasDep) {
      await this._dataGridInstanceContasDep.saveEditData();
    }
    if (this._dataGridInstanceContasInvestCurso) {
      await this._dataGridInstanceContasInvestCurso.saveEditData();
    }
    this.model.contasDepreciacaoList = await this.dataSourceContasDep.load();
    this.model.contasInvestCursoList = await this.dataSourceContasInvestCurso.load();
    const response: HttpResponse<IJsonAtivosconfiguracoes> = await this._ativosConfiguracoesService.update(this.model);
    this.model = response.body;
    await this._configService.refresh();
    this._plAlertService.success('ativos.configuracoes.messages.successfullySaved');
  }

  private _cancelar(): Promise<void> {
    return this._ativosConfiguracoesService.get().then((response: HttpResponse<IJsonAtivosconfiguracoes>) => {
      this.model = response.body;
    });
  }

  private _validateRadical(options: TAtivosConfiguracoesRadicaisValidationCallbackData): boolean {
    return !this.dataSourceRadicalList.find((radical: IRadicaisSujeitos) => radical.radicalSujeito === options.data.radicalSujeito && radical.id !== options.data.id);
  }

  private async _validatePoc(options: TAtivosConfiguracoesContasDepValidationCallbackData): Promise<boolean> {
    if (!options.value) {
      return false;
    }
    let valid = true;
    await this._servicePocs.get({id: options.value, reportExceptions: false}).catch(() => {
      valid = false;
    });
    return valid;
  }
}
