import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosHistorico} from '../../interfaces/jsonAtivosHistorico.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-ativos-historico',
  templateUrl: './ativosHistorico.modal.component.html'
})
export class AtivosHistoricoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ativoHistorico: Array<IJsonAtivosHistorico>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoAcontecimentoSTR', dataType: 'string', caption: 'ativoshistorico.table.tipoAcontecimentoSTR'},
        {dataField: 'dataReferencia', dataType: 'date', caption: 'ativoshistorico.table.dataReferencia'},
        {dataField: 'nomeUtilizador', dataType: 'string', caption: 'ativoshistorico.table.nomeUtilizador'},
        {dataField: 'stampInsert', dataType: 'date', caption: 'ativoshistorico.table.stampInsert'},
        {dataField: 'observacoes', dataType: 'string', caption: 'ativoshistorico.table.observacoes'}
      ],
      dataSource: [],
      export: {filename: 'ativoshistorico.title.historico'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.ativoHistorico;
  }
}
