import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EGerarUtilizarGrupo} from '../../rhCopiaAbonosDescontos.module.interface';
import {ETipoAcaoLoad, ETipoErroLoad, IJsonRelatorioLoad} from '../../jsonrhCopiaAbonosDescontos.module.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';
import {RADIO_GROUP_GERAR_UTILIZAR_GRUPO_TEMPLATE} from './rhCopiaAbonosDescontos.informacaoConfirmacao.modal.interface';

@Component({
  selector: 'rhcopiaabonosdescontos-informacaoconfirmacao-modal-component',
  templateUrl: './rhCopiaAbonosDescontos.informacaoConfirmacao.modal.component.html'
})
export class RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent extends CGModalComponent<IJsonRelatorioLoad> implements OnInit {
  @Input() public tipoErroLoad: ETipoErroLoad;
  @Input() public relatorioLoad: IJsonRelatorioLoad;
  public readonly radioGroupGerarUtilizarGrupo: IRadioGroup<EGerarUtilizarGrupo>;
  public gerarUtilizarGrupo: EGerarUtilizarGrupo;
  public painelGravarCancelar: boolean;
  public botaoFechar: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.radioGroupGerarUtilizarGrupo = copy(RADIO_GROUP_GERAR_UTILIZAR_GRUPO_TEMPLATE);
    this.gerarUtilizarGrupo = EGerarUtilizarGrupo.GerarNovo;
  }

  public ngOnInit(): void {
    this.relatorioLoad = copy(this.relatorioLoad);
    if (this.tipoErroLoad === ETipoErroLoad.Info) {
      this.painelGravarCancelar = false;
      this.botaoFechar = true;
    } else if (this.relatorioLoad.situacaoCategoria !== ETipoAcaoLoad.None) {
      if (this.relatorioLoad.situacaoCategoria === ETipoAcaoLoad.GeraNovo) {
        this.radioGroupGerarUtilizarGrupo.groupItems[1] = {...this.radioGroupGerarUtilizarGrupo.groupItems[1], disabled: true};
      }
      this.painelGravarCancelar = true;
      this.botaoFechar = false;
    } else {
      this.painelGravarCancelar = false;
      this.botaoFechar = true;
    }
  }

  public close(): void {
    Promise.resolve().then(() => {
      if (this.tipoErroLoad !== ETipoErroLoad.Info) {
        switch (this.gerarUtilizarGrupo) {
          case EGerarUtilizarGrupo.GerarNovo: {
            this.relatorioLoad.geraNovoGrupoDestino = true;
            break;
          }
          case EGerarUtilizarGrupo.UtilizarExistente: {
            this.relatorioLoad.geraNovoGrupoDestino = false;
            break;
          }
        }
      }
      super.close(this.relatorioLoad);
    });
  }
}
