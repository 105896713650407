<div class="taxonomias-module">
  <pl-tabs [activeId]="activeId" (activeIdChange)="changedTab($event)">
    <pl-tab caption="taxonomias.relacaocontasTab" [id]="tabs.RelacaoContas">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="definitionRelacaoContas" cgDxDataGridInstanceName="relacaoContas" [dataSource]="definitionRelacaoContas.dataSource">
          <div *dxTemplate="let item of 'templateToolbartitle'" class="text-center">
            <span>{{ titleTaxonomia }}</span>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="taxonomias.informacaoLegalTab" [id]="tabs.InformacaoLegal">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="definitionInformacaoLegal" cgDxDataGridInstanceName="informacaoLegal" [dataSource]="definitionInformacaoLegal.dataSource">
          <div *dxTemplate="let cell of 'templateToolbartitle'">
            <span>{{ titleTaxonomia }}</span>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="taxonomias.planoOficialTab" [id]="tabs.PlanoOficial">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="definitionPlanoOficial" cgDxDataGridInstanceName="planoOficial" [dataSource]="definitionPlanoOficial.dataSource">
          <div *dxTemplate="let cell of 'templateToolbartitle'">
            <span>{{ titleTaxonomia }}</span>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="taxonomias.validacoesSvatTab" [id]="tabs.ValidacoesSvat">
      <div *plTabContent>
        <pl-edit-checkbox [(model)]="svatFiltered" attrName="showonlyerrors" [properties]="{label: 'taxonomias.validacoesSvat.showOnlyErrors'}"></pl-edit-checkbox>

        <dx-data-grid
          [cgDxDataGrid]="definitionValidacoesSVAT"
          cgDxDataGridInstanceName="validacoesSVAT"
          [dataSource]="definitionValidacoesSVAT.dataSource"
          (cgDxDataGridStateLoad)="onDataGridValidacoesSVATStateLoad($event)">
          <dxo-filter-panel [filterEnabled]="svatFiltered"></dxo-filter-panel>
          <div *dxTemplate="let item of 'templateToolbartitle'" class="text-center">
            <span>{{ titleTaxonomia }}</span>
          </div>

          <div *dxTemplate="let item of 'actions'" class="text-center">
            <div ngbDropdown container="body">
              <button type="button" class="btn btn-link p-0" ngbDropdownToggle [translate]="'entity.action.actions'"></button>
              <div ngbDropdownMenu>
                <button
                  type="button"
                  ngbDropdownItem
                  [click]="fnOpenManutencaoPlanoContas(item.data.nConta)"
                  [translate]="'entity.maintenance.headerMaintenance'"
                  [translateParams]="{entityName: 'pocs.title_plural' | translate}"
                  plPromise></button>

                <button
                  type="button"
                  ngbDropdownItem
                  [translate]="'taxonomias.actions.criarContaTerceiro'"
                  [click]="fnOpenCriarContaTerceiro(item.data.nConta)"
                  [disabled]="!item.podeCriarContaCorrente"
                  plPromise></button>

                <button type="button" ngbDropdownItem [translate]="'taxonomias.actions.trfConta'" [click]="fnOpenTrfConta(item.data.nConta)" plPromise></button>

                <button type="button" ngbDropdownItem [translate]="'taxonomias.actions.extratos'" [click]="fnOpenMaintenanceExtratosGrid(item.data.nConta)" plPromise></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="taxonomias.validacoesPlanoTab" [id]="tabs.ValidacoesPlano">
      <div *plTabContent>
        <div class="cg-table-toolbar">
          <button type="button" class="btn btn-sm btn-success" [click]="fnAplicarCorrecoesAuto" [disabled]="!existErrors" plPromise>
            <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'taxonomias.autoCorrect'"></span>
          </button>

          <button type="button" class="btn btn-sm btn-light" (click)="refreshValidacoesPlano()"><i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'entity.action.refresh'"></span></button>
        </div>

        <dx-data-grid
          [cgDxDataGrid]="definitionValidacoesPlano"
          cgDxDataGridInstanceName="validacoesPlano"
          [dataSource]="definitionValidacoesPlano.dataSource"
          (onInitialized)="onDataGridValidacoesPlanoInitialized($event)">
          <div *dxTemplate="let item of 'templateToolbartitle'" class="text-center">
            <span>{{ titleTaxonomia }}</span>
          </div>

          <div *dxTemplate="let item of 'actions'" class="text-center">
            <div class="d-inline-block" ngbDropdown container="body">
              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem [click]="fnOpenManutencaoPlanoContas(item.data.conta)" [translate]="'taxonomias.actions.manutencaoPoc'" plPromise></button>

                <button
                  type="button"
                  ngbDropdownItem
                  [translate]="'taxonomias.actions.criarContaTerceiro'"
                  [click]="fnOpenCriarContaTerceiro(item.data.conta)"
                  [disabled]="!item.podeCriarContaCorrente"
                  plPromise></button>
                <button type="button" ngbDropdownItem [translate]="'taxonomias.actions.trfConta'" [click]="fnOpenTrfConta(item.data.conta)" plPromise></button>
              </div>
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle [translate]="'entity.action.actions'"></button>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="taxonomias.validacoesLancamentosTab" [id]="tabs.ValidacoesLancamentos">
      <div *plTabContent>
        <div class="cg-table-toolbar">
          <div ngbDropdown>
            <button type="button" class="btn btn-sm btn-outline-success" id="actionsDD" ngbDropdownToggle [disabled]="!existErrorsLancamentos" [translate]="'taxonomias.autoCorrect'"></button>

            <div ngbDropdownMenu aria-labelledby="actionsDD">
              <button type="button" ngbDropdownItem [translate]="'taxonomias.autoCorrectDatas'" (click)="aplicarCorrecoesDatas()"></button>
              <button type="button" ngbDropdownItem [translate]="'taxonomias.autoCorrectDoc0'" (click)="aplicarCorrecoesDocValor()"></button>
              <button type="button" ngbDropdownItem [translate]="'taxonomias.autoCorrectDocsSemLinhas'" (click)="aplicarCorrecoes(eTaxonomiasModoModalPoc.ModalContaNormal)"></button>
              <button type="button" ngbDropdownItem [translate]="'taxonomias.autoCorrectClasse9'" (click)="aplicarCorrecoes(eTaxonomiasModoModalPoc.ModalContaAnalita)"></button>
              <button type="button" ngbDropdownItem [translate]="'taxonomias.autoCorrectClasse0'" (click)="aplicarCorrecoes(eTaxonomiasModoModalPoc.ModalContaClasse0)"></button>
            </div>
          </div>
        </div>

        <dx-data-grid
          [cgDxDataGrid]="definitionValidacoesLancamentos"
          cgDxDataGridInstanceName="validacoesLancamentos"
          [dataSource]="definitionValidacoesLancamentos.dataSource"
          (onInitialized)="onDataGridValidacoesLancamentosInitialized($event)"
          (onCellClick)="onDataGridValidacoesLancamentosCellClick($event)">
          <div *dxTemplate="let item of 'templateToolbartitle'" class="text-center">
            <span>{{ titleTaxonomia }}</span>
          </div>

          <div *dxTemplate="let item of 'actions'" class="text-center">
            <button type="button" class="btn btn-xs btn-primary" [click]="fnOpenDoc(item.data.extPocCabId)" plPromise>
              <i class="fa fa-fw fa-file"></i>
            </button>
          </div>

          <div *dxTemplate="let item of 'detail'">
            <pl-alert [type]="item.data.tipo === tipoErro ? 'error' : 'warning'" [closeable]="false">
              <i class="fa" [class.fa-exclamation-triangle]="item.data.tipo !== tipoErro" [class.fa-times-circle]="item.data.tipo === tipoErro" aria-hidden="true"></i>&nbsp;<span
                [translate]="item.data.mensagemErro"></span>
            </pl-alert>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
