import moment from 'moment';
import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {downloadStream, IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem, isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {IJsonInvec, IJsonInven} from '../../../entities/inven/jsonInvec.entity.interface';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {InventariosImprimirModalComponent} from '../modals/imprimir/inventarios.imprimir.modal.component';
import {ENTITY_NAME_INVEC} from '../../../entities/inven/invec.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ECriterio, IMetodoSource} from '../../../interfaces/jsonArtigosTemplate.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {ETipoValorizacao} from '../../../datasources/tipovalorizacao/tipoValorizacao.datasource.interface';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {HttpResponse} from '@angular/common/http';
import {EInventariosStockViewType} from '../inventariosStock.module.interface';
import {InventariosNewModalComponent} from '../modals/new/inventarios.new.modal.component';
import {ModuleMaintenanceService} from '../../../components/entity/maintenance/module/module.maintenance.service';
import {ENTITY_NAME_ARTIGOS} from '../../../entities/artigos/artigos.entity.interface';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {MODULE_NAME_EXTRACTOSARTIGOS} from '../../extractosartigos/jsonExtractoArtigo.module.interface';
import {IModuleMaintenanceInstance} from '../../../components/entity/maintenance/module/module.maintenance.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {TDate} from '../../../../common/dates';
import {EConfigOptionsInstanceName, IComercialInventariosConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {Subscription} from 'rxjs';
import {ConfigOptionsService} from '../../../services/config/options/config.options.service';

const TOOLBAR_INSTANCE_ID_INVENTARIO = 'existing-inventario-toolbar';

@Component({
  selector: 'inventarios-stock',
  templateUrl: './inventariosStock.module.component.html'
})
export class InventariosStockComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonInven>;
  public readonly metodoSourceCriterio: Array<IMetodoSource>;
  public readonly mtdOutput: string = '{{valueMetodo}} - {{nameMetodo}}';
  public readonly inventarioStockTmpl: string = '{{nome}}';
  public invec: IJsonInvec;
  public inventarioStock: IJsonInvec;
  public linhasInven: Array<IJsonInven>;
  public saldoTotal: number;
  public view: EInventariosStockViewType;
  public viewTypes: typeof EInventariosStockViewType;
  public toolbarInstanceIdInventario: string;
  public optionShowTipoArtigo: boolean;
  public optionShowGrandeFamilia: boolean;
  public optionShowSubFamilia: boolean;
  public optionShowClasse: boolean;
  public optionShowCategoria: boolean;
  public optionShowValorimetria: boolean;

  protected _subscription: Subscription;

  private readonly _invecService: IEntityService<IJsonInvec>;
  private readonly _btnExportXls: IPlToolbarItem;
  private readonly _btnApagar: IPlToolbarItem;
  private readonly _btnRevalorizar: IPlToolbarItem;
  private readonly _btnRetroceder: IPlToolbarItem;
  private readonly _btnViewExistente: IPlToolbarItem;
  private readonly _btnImprimir: IPlToolbarItem;
  private readonly _btnGuardar: IPlToolbarItem;
  private readonly _decimaisValor: number;
  private readonly _decimaisQtd: number;
  private readonly _btnRevalorizarPrUltimaCompraMenuItem: IPlToolbarMenuItem;
  private readonly _btnExportFicheiroCSV: IPlToolbarMenuItem;
  private readonly _btnExportFicheiroXML: IPlToolbarMenuItem;
  private readonly _entityMaintenanceInstanceArtigos: IEntityMaintenanceInstance;

  private _moduleMaintenanceExtratoArtigos: IModuleMaintenanceInstance;
  private _dataGridInstance: dxDataGrid<IJsonInven>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.fnPesquisar = this.fnPesquisar.bind(this);
    this.configOptionsGroupName = EGroupName.ERP;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.INVENTARIOS;
    this.viewTypes = EInventariosStockViewType;
    this._entityMaintenanceInstanceArtigos = this._entityMaintenanceService.build(ENTITY_NAME_ARTIGOS);
    this._invecService = this._entityServiceBuilder.build<IJsonInvec>(ENTITY_NAME_INVEC);
    this._decimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;
    this._decimaisValor = this._configService.configurations.gestaoComercial.decimais.stocks.valores;
    this.metodoSourceCriterio = [
      {
        valueMetodo: ECriterio.CustoMedioPonderado,
        nameMetodo: 'familias.metodos.customedioponderado'
      },
      {
        valueMetodo: ECriterio.Lifo,
        nameMetodo: 'familias.metodos.lifo'
      },
      {
        valueMetodo: ECriterio.Fifo,
        nameMetodo: 'familias.metodos.fifo'
      },
      {
        valueMetodo: ECriterio.CustoPadrao,
        nameMetodo: 'familias.metodos.custopadrao'
      },
      {
        valueMetodo: ECriterio.CustoMedio,
        nameMetodo: 'familias.metodos.customedio'
      },
      {
        valueMetodo: ECriterio.LoteEspecifico,
        nameMetodo: 'familias.metodos.loteespecifico'
      }
    ];
    this.linhasInven = [];
    this.invec = {
      invecID: -1,
      nome: '',
      descricao: '',
      data: moment(),
      deValorimetria: 0,
      ateClasse: 2147483647,
      deClasse: 0,
      ateSubFamilia: 2147483647,
      deSubFamilia: 0,
      deFamilia: 0,
      deGrandeFamilia: 0,
      ateTipoArtigo: 2147483647,
      deTipoArtigo: 0,
      ateNArmazem: 32767,
      deNArmazem: 0,
      ateNArtigo: 'ZZZZ',
      deNArtigo: '',
      ateCategoria: 2147483647,
      ateFamilia: 2147483647,
      ateGrandeFamilia: 2147483647,
      ateValorimetria: 17,
      deCategoria: 0,
      stockNulo: false,
      desactivados: false,
      porLote: false
    };
    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'custom_actions',
          width: 120,
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        },
        {dataField: 'nArtigo', dataType: 'string', caption: 'inven.fields.nArtigo'},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'inven.fields.nomeArtigo'},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'inven.fields.nomeArmazem'},
        {dataField: 'nArmazem', dataType: 'number', caption: 'inven.fields.nArmazem'},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'inven.fields.nLoteEspecifico', visible: false},
        {dataField: 'qtd', dataType: 'double', caption: 'inven.fields.qtd', format: {decimalsLimit: this._decimaisQtd}},
        {dataField: 'precoValor', dataType: 'double', caption: 'inven.fields.precoValor', format: {decimalsLimit: this._decimaisValor}},
        {dataField: 'valor', dataType: 'double', caption: 'inven.fields.valor', format: {decimalsLimit: this._decimaisValor}}
      ],
      dataSource: new CustomStore({
        load: () => this._source()
      }),
      export: {filename: 'inven.filename'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateDataGrid',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this._btnGuardar = {
      id: 'criar',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      class: 'btn-success',
      visible: false,
      caption: 'invec.btn.criar',
      tooltip: {
        text: 'invec.criarTooltip'
      },
      click: () => this._saveNewInventario()
    };

    this._btnRetroceder = {
      id: 'retroceder',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-left"></i>',
      class: 'btn-secondary',
      caption: 'invec.btn.retroceder',
      visible: false,
      click: () => {
        this._setView(EInventariosStockViewType.NEW);
      }
    };

    this._btnViewExistente = {
      id: 'vizualizarExistente',
      order: 5,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      class: 'btn-light',
      caption: 'Ver inventários gravados',
      click: () => {
        this._setView(EInventariosStockViewType.SAVED);
      }
    };

    this._btnRevalorizarPrUltimaCompraMenuItem = {
      caption: 'tipoValorizacao.data.precoUltimaCompra',
      iconLeft: '<i class="fa fa-bolt"></i>&nbsp;',
      click: () => this._revalorizar(ETipoValorizacao.PrecoUltimaCompra)
    };

    this._btnApagar = {
      id: 'delete',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash-o"></i>',
      class: 'btn-danger',
      caption: 'Apagar',
      click: () => this._apagar()
    };

    this._btnRevalorizar = {
      id: 'revalorizar',
      order: 2,
      type: 'dropdown',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-light',
      caption: 'Revalorizar',
      menu: [this._btnRevalorizarPrUltimaCompraMenuItem]
    };

    this._btnImprimir = {
      id: 'imprimir',
      order: 7,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      class: 'btn-primary',
      caption: 'Imprimir',
      click: () => this._imprimir()
    };

    this._btnExportFicheiroCSV = {
      caption: 'invec.btn.ficheiroCSV',
      iconLeft: '<i class="fa fa-fw fa-file-o" aria-hidden="true"></i>&nbsp;',
      type: 'download',
      download: {
        url: undefined
      }
    };

    this._btnExportFicheiroXML = {
      caption: 'invec.btn.ficheiroXML',
      iconLeft: '<i class="fa fa-fw fa-file-code-o" aria-hidden="true"></i>&nbsp;',
      type: 'button',
      click: () => this._exportXML()
    };

    this._btnExportXls = {
      id: 'btnExport',
      order: 8,
      type: 'dropdown',
      menu: [this._btnExportFicheiroCSV, this._btnExportFicheiroXML],
      iconLeft: '<i class="fa fa-fw fa-download" aria-hidden="true"></i>',
      class: 'btn-success',
      caption: 'invec.btn.exportAT',
      tooltip: {
        text: 'invec.btn.exportATHint',
        placement: 'bottom'
      }
    };

    this._setView(EInventariosStockViewType.NEW);
    this._subscription = this._configOptionsService
      .getOptionsErp()
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IComercialInventariosConfigOptions>) => {
        this.optionShowTipoArtigo = configOptions.get('showTipoArtigo').value;
        this.optionShowGrandeFamilia = configOptions.get('showGrandeFamilia').value;
        this.optionShowSubFamilia = configOptions.get('showSubFamilia').value;
        this.optionShowClasse = configOptions.get('showClasse').value;
        this.optionShowCategoria = configOptions.get('showCategoria').value;
        this.optionShowValorimetria = configOptions.get('showValorimetria').value;
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGuardar);
    this.toolbar.addButton(this._btnRetroceder);
    this.toolbar.addButton(this._btnViewExistente);

    const toolbarInstanceInventario: IPlToolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceIdInventario);
    toolbarInstanceInventario.setItems([this._btnApagar, this._btnExportXls, this._btnImprimir, this._btnRevalorizar]);

    this.saldoTotal = 0;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    if (this.toolbarInstanceIdInventario) {
      this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdInventario);
    }
    this.toolbarInstanceIdInventario = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_INVENTARIO}`;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonInven>): void {
    this._dataGridInstance = component;
  }

  public inventarioStockChanged(value: IJsonInvec): void {
    this.inventarioStock = value;
    if (isDefinedNotNull(this.inventarioStock)) {
      this._mostrar();
    } else {
      this._clearDT();
    }
    this._configSavedToolbar();
  }

  public onDataChanged(value: TDate): void {
    this.invec.data = value;
    this._clearDT();
    this._btnGuardar.visible = false;
  }

  public onPorLoteChanged(value: boolean): void {
    this.invec.porLote = value;
    if (value) {
      const columnVisible = this._dataGridInstance.getVisibleColumns();
      const idx = columnVisible.findIndex((column) => column.name === 'nLoteEspecifico');
      if (idx === -1) {
        this._dataGridInstance.columnOption('nLoteEspecifico', 'visible', value);
      }
    }
  }

  public readonly fnFichaArtigo = (item: IJsonInven) => (): Promise<unknown> => this._openFichaArtigo(item);

  public readonly fnExtratoArtigo = (item: IJsonInven) => (): Promise<unknown> => this._extratoArtigo(item);

  public async fnPesquisar(): Promise<void> {
    await this.getLinhas();
  }

  public async getLinhas(isNewInv: boolean = true): Promise<void> {
    if (isUndefinedOrNull(this.invec.data)) {
      this._plAlertService.error('invec.messages.dataRequired');
      return;
    }
    try {
      const response: HttpResponse<IApiQueryResponse<IJsonInven>> = await this._invecService.put<IApiQueryResponse<IJsonInven>, IJsonInvec>({
        url: 'linhas',
        body: this.invec,
        params: {isnewinv: isNewInv}
      });
      this.saldoTotal = 0;
      this._btnRevalorizar.disabled = false;
      this.linhasInven = response.body.list;
      this._btnGuardar.visible = this.view === EInventariosStockViewType.NEW && this.linhasInven.length > 0;
      for (const linha of this.linhasInven) {
        this.saldoTotal += linha.valor;
      }
      if (this.view === EInventariosStockViewType.NEW) {
        if (response.body?.list?.length) {
          this._cardPanel.collapse();
        } else {
          this._cardPanel.focusFirstElement();
          this._plAlertService.info('global.text.searchNoData');
        }
      }
      await this._dataGridInstance.refresh();
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _setView(view: EInventariosStockViewType): void {
    this.view = view;
    this._btnRetroceder.visible = this.view === EInventariosStockViewType.SAVED;
    this._btnViewExistente.visible = this.view === EInventariosStockViewType.NEW;
    this._btnGuardar.visible = this.view === EInventariosStockViewType.NEW && this.linhasInven.length > 0;
    if (this.view === EInventariosStockViewType.NEW) {
      this._novo();
    } else {
      this._clearDT();
      this._configSavedToolbar();
    }
  }

  private _clearDT(): void {
    this.linhasInven = [];
    if (this._dataGridInstance) {
      this._dataGridInstance.refresh();
    }
  }

  private _novo(): void {
    this.invec = {
      invecID: -1,
      nome: '',
      descricao: '',
      data: moment(),
      deValorimetria: 0,
      ateClasse: 2147483647,
      deClasse: 0,
      ateSubFamilia: 2147483647,
      deSubFamilia: 0,
      deFamilia: 0,
      deGrandeFamilia: 0,
      ateTipoArtigo: 2147483647,
      deTipoArtigo: 0,
      ateNArmazem: 32767,
      deNArmazem: 0,
      ateNArtigo: 'ZZZZ',
      deNArtigo: '',
      ateCategoria: 2147483647,
      ateFamilia: 2147483647,
      ateGrandeFamilia: 2147483647,
      ateValorimetria: 17,
      deCategoria: 0,
      stockNulo: false,
      desactivados: false,
      porLote: false
    };
    this.linhasInven = [];
    if (this._dataGridInstance) {
      this._dataGridInstance.refresh();
    }
    this._btnGuardar.visible = false;
  }

  private _mostrar(): Promise<void> {
    return this._invecService.get({id: this.inventarioStock.invecID}).then((result) => {
      this.invec = result.body;
      this._dataGridInstance.refresh();
      this._setExportUrls();
      return this.getLinhas(false);
    });
  }

  private _imprimir(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(InventariosImprimirModalComponent);
    const componentInstance: InventariosImprimirModalComponent = modalInstance.componentInstance;
    componentInstance.invec = this.inventarioStock;
    return modalInstance.result;
  }

  private _gravar(): Promise<void> {
    if (!this.invec.nome) {
      this._plAlertService.warning('invec.messages.errorName');
      return Promise.resolve();
    }
    if (isUndefinedOrNull(this.invec.data)) {
      this._plAlertService.error('invec.messages.dataRequired');
      return Promise.resolve();
    }
    return this._invecService.post({body: this.invec}).then((response) => {
      this._plAlertService.success('invec.messages.saveSuccess');
      this.invec.invecID = response.body.invecID;
      this.inventarioStockChanged(this.invec);
      this._setView(EInventariosStockViewType.SAVED);
    });
  }

  private _apagar(): Promise<void> {
    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'invec.messages.deletePrompt', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        return this._invecService.delete({id: this.invec.invecID}).then(() => {
          this._plAlertService.success('invec.messages.deleteSuccess');
          this.inventarioStockChanged(undefined);
        });
      });
  }

  private _revalorizar(tipo: ETipoValorizacao): Promise<void> {
    return this._invecService.put<IApiQueryResponse<IJsonInven>, IJsonInvec>({url: `revaloriza/${tipo}`, body: this.invec}).then((response) => {
      this._plAlertService.success('invec.messages.revalorizaSuccess');
      this.linhasInven = response.body.list;
      return this._dataGridInstance.refresh();
    });
  }

  private _setExportUrls(): void {
    this._btnExportFicheiroCSV.download.url = `${this._invecService.entityUrl()}/exportatxls/${this.invec.invecID}`;
  }

  private _source(): Array<IJsonInven> {
    return this.linhasInven;
  }

  private _saveNewInventario(): Promise<void> {
    if (isUndefinedOrNull(this.invec.data)) {
      this._plAlertService.error('invec.messages.dataRequired');
      return Promise.resolve();
    }
    const modalInstance = this._cgModalService.showVanilla(InventariosNewModalComponent, {size: 'md', keyboard: false, backdrop: 'static'});
    const componentInstance: InventariosNewModalComponent = modalInstance.componentInstance;
    componentInstance.data = this.invec.data;
    return modalInstance.result.then((nome: string) => {
      this.invec.nome = nome;
      this.invec.descricao = '';
      return this._gravar();
    });
  }

  private _configSavedToolbar(): void {
    this._btnApagar.visible = isDefinedNotNull(this.inventarioStock);
    this._btnExportXls.visible = isDefinedNotNull(this.inventarioStock);
    this._btnImprimir.visible = isDefinedNotNull(this.inventarioStock);
    this._btnRevalorizar.visible = isDefinedNotNull(this.inventarioStock);
  }

  private _exportXML(): Promise<void> {
    return this._invecService
      .get<Blob>({
        url: `exportxml/${this.invec.invecID}`,
        responseType: 'blob'
      })
      .then((response) => {
        const doc: Blob = response.body;
        if (!doc?.size) {
          this._plAlertService.success(this._translateService.instant('invec.messages.sistemaNaoDevolveuFicheiro'));
        } else {
          downloadStream(response);
        }
      });
  }

  private _openFichaArtigo(item: IJsonInven): Promise<void> {
    return this._entityMaintenanceInstanceArtigos.maintenanceEdit(item.nArtigo).then(() => undefined);
  }

  private _extratoArtigo(item: IJsonInven): Promise<void> {
    if (!this._moduleMaintenanceExtratoArtigos) {
      this._moduleMaintenanceExtratoArtigos = this._moduleMaintenanceService.build(MODULE_NAME_EXTRACTOSARTIGOS);
    }
    return this._moduleMaintenanceExtratoArtigos.maintenance({
      params: {nArtigo: item.nArtigo}
    });
  }
}
