import {TDate} from '../../../common/dates';

export const SUCCESS_CODE_SERIE_FINALIZADA = 2004;

export enum EATEstadoSerie {
  None = 'Por comunicar',
  Ativa = 'Ativa',
  Anulada = 'Anulada',
  Finalizada = 'Finalizada'
}

export enum EATAutoFaturacaoEnquadramento {
  None,
  Desperdicios,
  Cortica
}

export interface IJsonDocfaNum {
  idDocfaNum: number;
  nDocfa: number;
  nNumer: number;
  num: number;
  descNumer: string;
  data: TDate;
  numDraft: number;
  nConta: string;
  encerrado: boolean;
  atcud: string;
  visivelERPCloud: boolean;
  codLocalEmissao?: string;
  atEstadoSerie?: number;
  atEstadoSerieDescricao?: string;
  atAfNifTerceiro?: string;
  nomeClifo?: string;
  atAFEnquadramento?: EATAutoFaturacaoEnquadramento;
}

export interface IJsonDocfaNumATConsulta {
  sucesso: boolean;
  motivoEstado: string;
  numInicialSeq: number;
  justificacao: string;
  tipoSerie: string;
  dataRegisto: TDate;
  seqUltimoDocEmitido: number;
  numCertSWFatur: number;
  serie: string;
  classeDoc: string;
  nifComunicou: string;
  tipoDoc: string;
  meioProcessamento: string;
  dataEstado: TDate;
  estado: string;
  codValidacaoSerie: string;
  dataInicioPrevUtiliz: TDate;
  numeroCertificado: string;
  atAfNifTerceiro: string;
  enquadramento: EATAutoFaturacaoEnquadramento;
  nConta: string;
}

export interface IJsonDocfaNumATRegisto {
  idDocfaNum: number;
  nDocfa: number;
  nNumer: number;
  codValidacaoSerie: string;
}

export interface IJsonDocfaNumATResultado {
  sucesso: boolean;
  retornoCodigo: number;
  retornoMensagem: string;
}

export interface IJsonDocfanumPorComunicarAT {
  idDocfaNum: number;
  nDocfa: number;
  nNumer: number;
  nome: string;
  tipoDocSaft: string;
  numAtual: number;
  numInicial: number;
  dataInicio: TDate;
  codValidacaoSerie: string;
}

export interface IJsonDocfanumPorComunicarATSel extends IJsonDocfanumPorComunicarAT {
  selecionado?: boolean;
}
