import {merge} from 'lodash-es';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {copy, isObject, skipIf} from 'pl-comps-angular';
import {AuthService} from '../../auth/auth.service';
import {IJsonContabDigitalConfigs} from '../jsonContabDigital.interface';
import {TUserSession} from '../../account/jsonUserApi.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeDigitalConfigStoreService implements OnDestroy {
  private readonly _subjectConfigs: BehaviorSubject<IJsonContabDigitalConfigs>;
  private readonly _subjectEmpresaTemPastasCriadas: BehaviorSubject<boolean>;
  private readonly _subscriptionIdentity: Subscription;
  private _observableEmpresaTemPastasCriadas: Observable<boolean>;
  private _valid: boolean;
  private _observableIdentity: Observable<IJsonContabDigitalConfigs>;

  constructor(private readonly _authService: AuthService) {
    this._subjectConfigs = new BehaviorSubject<IJsonContabDigitalConfigs>(undefined);
    this._subjectEmpresaTemPastasCriadas = new BehaviorSubject<boolean>(undefined);
    this._subscriptionIdentity = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._valid = isObject(session);
      this._subjectConfigs.next(undefined);
    });
  }

  public ngOnDestroy(): void {
    this._subjectConfigs.complete();
    this._subjectEmpresaTemPastasCriadas.unsubscribe();
    this._subscriptionIdentity.unsubscribe();
  }

  public get(): Observable<IJsonContabDigitalConfigs> {
    if (!this._observableIdentity) {
      this._observableIdentity = this._subjectConfigs
        .asObservable()
        .pipe(skipIf((configs: IJsonContabDigitalConfigs) => !this._valid || !isObject(configs)))
        .pipe(map(copy));
    }
    return this._observableIdentity;
  }

  public getValue(): IJsonContabDigitalConfigs {
    return this._subjectConfigs.value;
  }

  public set(configs: Partial<IJsonContabDigitalConfigs>): void {
    const configurations: IJsonContabDigitalConfigs = merge({}, this._subjectConfigs.value, configs);
    this._subjectConfigs.next(configurations);
  }

  public saveLastFolder(folderId: string): void {
    this.set({lastFolder: folderId});
  }

  public empresaTemPastasCriadas(): Observable<boolean> {
    if (!this._observableEmpresaTemPastasCriadas) {
      this._observableEmpresaTemPastasCriadas = this._subjectEmpresaTemPastasCriadas.asObservable();
    }
    return this._observableEmpresaTemPastasCriadas;
  }

  public setEmpresaTemPastasCriadas(value: boolean): void {
    this._subjectEmpresaTemPastasCriadas.next(value);
  }
}
