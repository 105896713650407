import {ENTITY_NAME_INDICE_FLUXOS, IIndiceFluxo} from './indiceFluxos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_INDICE_FLUXOS: IEntityDefinition<IIndiceFluxo> = {
  name: ENTITY_NAME_INDICE_FLUXOS,
  roles: [ROLE.ERP, ROLE.GESTORRH],
  searchPlaceholder: 'indicefluxos.pesquisa',
  icon: 'fa-list-ol',
  metadata: {
    keyName: 'indice',
    fields: [
      {name: 'indice', width: '120px', caption: 'prhfluxos.fields.idFluxo', placeholder: 'prhfluxos.fields.idFluxoPlaceholder', validators: {required: true, min: 1}},
      {name: 'tipo', caption: 'prhfluxos.fields.posicao', placeholder: 'prhfluxos.fields.posicaoPlaceholder', validators: {required: true}},
      {name: 'nome', caption: 'prhfluxos.fields.indice', placeholder: 'prhfluxos.fields.indicePlaceholder', validators: {required: true}}
    ],
    order: 'indice',
    searchFields: 'indice,tipo'
  },
  autocomplete: {
    rowTemplate: '{{indice}} - {{tipo}}',
    output: 'tipo',
    searchFields: 'indice,tipo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: false,
    search: true
  }
};
