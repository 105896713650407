import {ENTITY_NAME_PRH_FLUXOS, IPRHFluxosEntity} from './pRHFluxos.entity.interface';
import {IJsonPRHFluxo} from './jsonPRHFluxo.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_FLUXOS: IPRHFluxosEntity = {
  name: ENTITY_NAME_PRH_FLUXOS,
  icon: 'fa-list-ol',
  roles: [ROLE.GESTORRH],
  pageTitle: 'global.menu.prhfluxos',
  searchPlaceholder: 'prhfluxos.pesquisa',
  metadata: {
    keyName: 'idFluxo',
    descriptionName: 'posicao',
    fields: [
      {
        name: 'idFluxo',
        width: '120px',
        caption: 'prhfluxos.fields.idFluxo',
        placeholder: 'prhfluxos.fields.idFluxoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'posicao',
        caption: 'prhfluxos.fields.posicao',
        placeholder: 'prhfluxos.fields.posicaoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'indice',
        caption: 'prhfluxos.fields.indice',
        placeholder: 'prhfluxos.fields.indicePlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'codPapel',
        caption: 'prhfluxos.fields.codPapel',
        placeholder: 'prhfluxos.fields.codPapelPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'integraCG', type: 'boolean', caption: 'prhfluxos.fields.integraCG', placeholder: 'prhfluxos.fields.integraCGPlaceholder'}
    ],
    order: 'indice',
    searchFields: 'indice,posicao'
  },
  autocomplete: {
    rowTemplate: '{{indice}} - {{posicao}}',
    output: 'posicao',
    searchFields: 'indice,posicao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  service: function () {
    this.mudarPosicao = (codFluxo: number, inc: number) => {
      return this.put<IJsonPRHFluxo, void>({
        url: `${codFluxo}/mudarposicao`,
        params: {inc: inc}
      });
    };
  }
};
