export enum ETipoPagamento {
  Indiferente = 0,
  Recebimento = 1,
  Pagamento = 2
}

export enum ETipo {
  Indiferente = 0,
  Dinheiro = 1,
  Multibanco = 2,
  CartaoCredito = 3,
  Cheque = 4,
  ValorAdicional = 5,
  Pontos = 6,
  ContraReemsolso = 7,
  TrfBancaria = 8,
  PayPal = 9
}

export interface ITipoPagamento {
  value: ETipoPagamento;
  label: string;
}

export interface ITipo {
  value: ETipo;
  label: string;
}

export const ENTITY_NAME_MEIOS_PAGAMENTO = 'meiosPagamento';
