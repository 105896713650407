<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body fct-pedido-reembolso-modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="fctPedidosReembolsosEfetuados" [dataSource]="source"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
</div>
