export interface IAssistenteConfigPortal {
  name: string;
  title: string;
  icon: string;
  operations: Array<IAssistenteConfigPortalOperation>;
}

export interface IAssistenteConfigPortalOperation {
  title: string;
  moduleName: string;
}

export const MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS = 'assistenteConfigPortais';
