<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.abonos.marcarmodal.title'"></h4>
</div>

<div class="modal-body">
  <p>{{ textMarcarDate }}</p>

  <pl-form>
    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.tipo'"></span>:</label>
      <edit>
        <pl-edit-select
          attrName="tipoAbono"
          [source]="configEvents"
          [model]="tipoAbono?.tipo"
          (modelChange)="changedTipoAbono($event)"
          [properties]="{select: {valueProp: 'tipo', labelProp: 'descricao'}}">
        </pl-edit-select>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.motivo'"></span>:</label>
      <edit>
        <pl-edit type="text" [(model)]="motivo" attrName="motivo"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.quantidade'"></span>:</label>
      <edit>
        <pl-edit type="number" [(model)]="quantidade" attrName="quantidade"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.valor'"></span>:</label>
      <edit>
        <pl-edit type="number" [(model)]="valor" attrName="valor"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.dadosFixos'"></span>:</label>
      <edit>
        <pl-edit type="boolean" [(model)]="dadosFixos" attrName="dadosFixos"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="dadosFixos">
      <pl-group>
        <label><span [translate]="'global.text.fromDate'"></span>:</label>
        <edit>
          <pl-edit type="date" [(model)]="marcarData.startDate" attrName="dataDe"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'global.text.toDate'"></span>:</label>
        <edit>
          <pl-edit type="date" [(model)]="marcarData.endDate" attrName="dataAte"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.mark'"></span></pl-button>

  <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
