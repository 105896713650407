import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonListaExclProcessamento} from '../rhfolfer/jsonRHFolfer.module.interface';
import {IJsonIMPENCAR} from './jsonImpEncar.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ImpencarService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/impencar`;
  }

  public getListagemIMPENCARUrl(listagem: IJsonIMPENCAR, listaEmpresas: string, listProcessamento: string, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      datade: listagem.dataDe,
      dataate: listagem.dataAte,
      codempde: listagem.codEmpDe,
      codempate: listagem.codEmpAte,
      codencargode: listagem.codEncargoDe,
      codencargoate: listagem.codEncargoAte,
      listaempresas: listaEmpresas,
      slexcprocessamento: listProcessamento,
      reportname: reportName
    });
  }

  public postExclusoesProcessamento(listaexclprocessamento: IJsonListaExclProcessamento): TServiceResponse<IJsonListaExclProcessamento> {
    return this._apiService.post<IJsonListaExclProcessamento>({
      url: `${this._path}/exclusoesprocessamento`,
      body: listaexclprocessamento
    });
  }
}
