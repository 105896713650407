<pl-navpill attrName="select" (evtSelected)="changedGroup($event.nextId)">
  <pl-navpill-panel *ngFor="let group of configList" [id]="group.id" [caption]="group.title">
    <div *plNavPillPanelContent>
      <div class="table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>N. Doc</th>
              <th>Nome</th>
              <th>Visível</th>
              <th>Documento por Defeito</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let config of group.pagedItems">
              <tr *ngIf="group.items[config.index].visible">
                <td>{{ group.items[config.index].nDoc }}</td>
                <td>{{ group.items[config.index].description }}</td>
                <td>
                  <pl-edit
                    type="boolean"
                    [model]="group.items[config.index].value"
                    (modelChange)="group.items[config.index].value = $event; saveField(group.items[config.index])"
                    [properties]="{readonly: group.items[config.index].readOnly}">
                  </pl-edit>
                </td>
                <td>
                  <pl-edit
                    type="boolean"
                    [model]="group.documentoPorDefeito"
                    (modelChange)="setDefaultDoc($event, group)"
                    [properties]="{
                      round: true,
                      value: group.items[config.index].nDoc,
                      disabled: !group.items[config.index].value,
                      events: {beforeChange: fnBeforeChange}
                    }">
                  </pl-edit>
                </td>
              </tr>
            </ng-container>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="4">
                <pl-pagination [perPage]="perPage" [page]="page" [total]="group.items.length" (evtPaginationChanged)="pageChanged($event)"></pl-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </pl-navpill-panel>
</pl-navpill>
