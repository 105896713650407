import {orderBy} from 'lodash-es';
import moment, {Moment} from 'moment';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EGestaoDGEMPSColors} from '../../../../../components/gestaodgemps/gestaodgemps.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../../entities/prhservicos/pRHServicos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EPRHFluxoIndice} from '../../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {generateColConfigStructureMap, IColaborador, IColBoard, IColConfigStructureMap} from '../colaboradorBoard.module.interface';
import {gestaoDGEMPSPrettyFeriasMarcadas} from '../../../../../components/gestaodgemps/gestaodgemps.utilities';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnSelectionChanged} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressPieChart, IDevExpressPieChartDefaultItem} from '../../../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IJsonConfigEstrutura} from '../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMPDetail, IJsonDGEMPMarcacoes} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHServico} from '../../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {TDevExpressPieChartSeries} from '../../../../../components/devexpress/charts/pie/devexpress.pie.chart.types.interface';

const SEARCH_PANEL_WIDTH = 325;

@Component({
  selector: 'colaboradores-board',
  templateUrl: './colaboradorBoard.module.component.html'
})
export class ColaboradorBoardComponent extends ModuloComponent implements OnInit {
  @Input() public configStructureAbonos: IJsonConfigEstrutura;
  @Input() public configStructureFaltas: IJsonConfigEstrutura;
  @Input() public servicos: Array<IJsonPRHServico>;

  public readonly board: IColBoard;
  public readonly dataGridColaboradores: IDevExpressDataGrid;
  public readonly dataGridAbonosMarcadas: IDevExpressDataGrid;
  public readonly dataGridAbonosIntegradas: IDevExpressDataGrid;
  public readonly dataGridAbonosRejeitadas: IDevExpressDataGrid;
  public readonly dataGridFeriasMarcadas: IDevExpressDataGrid;
  public readonly dataGridFeriasIntegradas: IDevExpressDataGrid;
  public readonly dataGridFeriasRejeitadas: IDevExpressDataGrid;
  public readonly dataGridFaltasMarcadas: IDevExpressDataGrid;
  public readonly dataGridFaltasIntegradas: IDevExpressDataGrid;
  public readonly dataGridFaltasRejeitadas: IDevExpressDataGrid;
  public readonly chartAbonosGlobal: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartFerias: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartFeriasGlobal: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartFaltas: IDevExpressPieChart<'DoughnutSeries'>;
  public chartDataAbonosGlobal: Array<IDevExpressPieChartDefaultItem>;
  public chartDataFerias: Array<IDevExpressPieChartDefaultItem>;
  public chartDataFeriasGlobal: Array<IDevExpressPieChartDefaultItem>;
  public chartDataFaltas: Array<IDevExpressPieChartDefaultItem>;
  public prettyFeriasMarcadas: string;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private readonly _servicePRHServicos: IPRHServicosEntityService;
  private _configStructureMapAbonos: IColConfigStructureMap;
  private _configStructureMapFaltas: IColConfigStructureMap;
  private _selectedEmp: IColaborador;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.board = {
      codServico: -1,
      codEmpSelected: undefined,
      servico: undefined,
      colaboradoresList: [],
      colaboradorDetail: undefined,
      dgempMarcacoes: undefined,
      numDiasFerias: 0,
      abonos: {
        abonosMarcadas: [],
        abonosRejeitadas: [],
        abonosIntegradas: []
      },
      faltas: {
        faltasMarcadas: [],
        faltasRejeitadas: [],
        faltasIntegradas: []
      },
      ferias: {
        feriasMarcadas: [],
        feriasRejeitadas: [],
        feriasIntegradas: []
      }
    };
    this.dataGridColaboradores = {
      columns: [
        {dataField: 'codEmp', caption: 'dgemps.fields.codEmp'},
        {dataField: 'nome', caption: 'dgemps.fields.nome'},
        {dataField: 'nomeCategoria', caption: 'dgemps.fields.nomeCategoria'}
      ],
      keyExpr: 'codEmp',
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      pager: {displayMode: 'compact'},
      remoteOperations: false,
      searchPanel: {visible: true, width: SEARCH_PANEL_WIDTH},
      selection: {
        allowSelectAll: false,
        mode: 'single'
      },
      toolbar: {
        items: [{name: 'searchPanel', location: 'before'}, 'columnChooserButton']
      }
    };
    this.dataGridAbonosMarcadas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Abonos Marcados'},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento'},
        {dataField: 'abono', dataType: 'string', caption: 'Abono'},
        {dataField: 'qtd', dataType: 'string', caption: 'Qtd.'},
        {dataField: 'motivo', dataType: 'string', caption: 'Motivo'}
      ],
      export: {enabled: false}
    };
    this.dataGridAbonosIntegradas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Abonos Aprovados'},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento'},
        {dataField: 'abono', dataType: 'string', caption: 'Abono'},
        {dataField: 'qtd', dataType: 'string', caption: 'Qtd.'}
      ],
      export: {enabled: false}
    };
    this.dataGridAbonosRejeitadas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Abonos Rejeitados'},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento'},
        {dataField: 'abono', dataType: 'string', caption: 'Abono'},
        {dataField: 'qtd', dataType: 'string', caption: 'Qtd.'},
        {dataField: 'motivo', dataType: 'string', caption: 'Motivo'}
      ],
      export: {enabled: false}
    };
    this.dataGridFeriasMarcadas = {
      columns: [{dataField: 'data', dataType: 'date', caption: 'Férias Marcadas'}],
      export: {enabled: false}
    };
    this.dataGridFeriasIntegradas = {
      columns: [{dataField: 'data', dataType: 'date', caption: 'Férias Aprovadas'}],
      export: {enabled: false}
    };
    this.dataGridFeriasRejeitadas = {
      columns: [{dataField: 'data', dataType: 'date', caption: 'Férias Rejeitadas'}],
      export: {enabled: false}
    };
    this.dataGridFaltasMarcadas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Faltas Marcadas'},
        {dataField: 'tipoFalta', dataType: 'string', caption: 'Tipo Falta', visible: false},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento', visible: false},
        {dataField: 'abono', dataType: 'string', caption: 'Abono', visible: false},
        {dataField: 'motivo', dataType: 'string', caption: 'Motivo', visible: false}
      ],
      export: {enabled: false}
    };
    this.dataGridFaltasIntegradas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Faltas Aprovadas'},
        {dataField: 'tipoFalta', dataType: 'string', caption: 'Tipo Falta', visible: false},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento', visible: false},
        {dataField: 'abono', dataType: 'string', caption: 'Abono', visible: false}
      ],
      export: {enabled: false}
    };
    this.dataGridFaltasRejeitadas = {
      columns: [
        {dataField: 'data', dataType: 'date', caption: 'Faltas Rejeitadas'},
        {dataField: 'tipoFalta', dataType: 'string', caption: 'Tipo Falta', visible: false},
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'Tp. Processamento', visible: false},
        {dataField: 'abono', dataType: 'string', caption: 'Abono', visible: false},
        {dataField: 'motivo', dataType: 'string', caption: 'Motivo', visible: false}
      ],
      export: {enabled: false}
    };
    const series: TDevExpressPieChartSeries<'DoughnutSeries'> = {
      argumentField: 'arg',
      valueField: 'val',
      label: {
        visible: true
      }
    };
    this.chartAbonosGlobal = {
      type: 'doughnut',
      title: {
        text: 'Abonos'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Marcadas, EGestaoDGEMPSColors.Rejeitadas, EGestaoDGEMPSColors.Integradas]
    };
    this.chartFerias = {
      type: 'doughnut',
      title: {
        text: 'Férias Aprovadas'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Integradas, EGestaoDGEMPSColors.Weekend]
    };
    this.chartFeriasGlobal = {
      type: 'doughnut',
      title: {
        text: 'Férias'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Marcadas, EGestaoDGEMPSColors.Rejeitadas, EGestaoDGEMPSColors.Integradas]
    };
    this.chartFaltas = {
      type: 'doughnut',
      title: {
        text: 'Faltas'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Marcadas, EGestaoDGEMPSColors.Rejeitadas, EGestaoDGEMPSColors.Integradas]
    };
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
    this._servicePRHServicos = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._configStructureMapAbonos = generateColConfigStructureMap(this.configStructureAbonos);
    this._configStructureMapFaltas = generateColConfigStructureMap(this.configStructureFaltas);
    if (this.servicos.length > 0) {
      this.selectServico(this.servicos[0]);
    }
  }

  public selectServico(servico: IJsonPRHServico): void {
    this.board.servico = servico;
    this.board.codServico = this.board.servico.codServico;
    this._servicePRHServicos.getListaEmpregados(this.board.codServico).then((data) => {
      this.board.colaboradoresList = orderBy(data.body, 'nome', 'asc');
      for (const colaborador of this.board.colaboradoresList) {
        colaborador.selected = false;
        if (colaborador.codEmp === this.board.codEmpSelected) {
          this._selectColaborador(colaborador);
        }
      }
    });
  }

  public onDataGridColaboradoresCellClick(event: IDevExpressDataGridEventOnCellClick<IColaborador, number>): void {
    if (event.rowType === 'data' && !event.column.type) {
      if (!event.component.isRowSelected(event.key)) {
        event.component.selectRows([event.key], false);
      } else {
        event.component.deselectRows([event.key]);
      }
    }
  }

  public onDataGridColaboradoresSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IColaborador, number>): void {
    if (event.selectedRowKeys.length) {
      const item = this.board.colaboradoresList.find((value) => value.codEmp === event.selectedRowKeys[0]);
      this._selectColaborador(item);
    }
  }

  private _selectColaborador(item: IColaborador): void {
    if (this._selectedEmp && item === this._selectedEmp) {
      return;
    }
    if (this._selectedEmp && this._selectedEmp !== item) {
      this._selectedEmp.selected = false;
    }
    this._selectedEmp = item;
    this._selectedEmp.selected = true;
    if (item.selected) {
      const codEmp: number = item.codEmp;
      const dataDe: Moment = moment().startOf('year');
      const dataAte: Moment = moment().endOf('year');
      Promise.all([
        this._serviceDGEMPS.getDetail(codEmp).then((response: HttpResponse<IJsonDGEMPDetail>) => response.body),
        this._serviceDGEMPS.getListaMarcacoes(codEmp, dataDe, dataAte).then((response: HttpResponse<IJsonDGEMPMarcacoes>) => response.body)
      ]).then(([detail, marcacoes]: [IJsonDGEMPDetail, IJsonDGEMPMarcacoes]) => {
        this.board.codEmpSelected = codEmp;
        this.board.colaboradorDetail = {
          ...detail,
          nome: item.nome,
          saldoFerias: detail.saldoFerias + detail.numDiasFerias,
          servicoPRH: `${item.codServicoPRH} - ${item.nomeServicoPRH}`
        };
        this.board.dgempMarcacoes = marcacoes;
        this.board.numDiasFerias = 0;
        this._fillFerias();
        this._fillFaltas();
        this._fillAbonos();
      });
    } else {
      this.board.codEmpSelected = undefined;
      this.board.colaboradorDetail = undefined;
      this.board.dgempMarcacoes = undefined;
    }
  }

  private _fillAbonos(): void {
    this.board.abonos = {
      abonosMarcadas: [],
      abonosRejeitadas: [],
      abonosIntegradas: []
    };

    let tipoProcessamento: string;
    let abono: string;

    for (const abonos of this.board.dgempMarcacoes.listaAbonosMarcadas) {
      for (const linha of abonos.linhas) {
        tipoProcessamento = this._configStructureMapAbonos.tiposProcessamento.get(linha.tipoProcessamento)?.descricaoTipoProcessamento;
        abono = this._configStructureMapAbonos.abdes.get(linha.ncodAbdes)?.designacao;
        if (abonos.cab.fluxo.indice === EPRHFluxoIndice.AbonosRejeitar) {
          this.board.abonos.abonosRejeitadas.push({
            data: linha.dataAbono,
            tipoProcessamento: this._configStructureMapAbonos.tiposProcessamento.get(linha.tipoProcessamento)?.descricaoTipoProcessamento,
            abono: this._configStructureMapAbonos.abdes.get(linha.ncodAbdes)?.designacao,
            qtd: linha.quantidade,
            motivo: abonos.motivo
          });
        } else {
          this.board.abonos.abonosMarcadas.push({
            data: linha.dataAbono,
            tipoProcessamento: this._configStructureMapAbonos.tiposProcessamento.get(linha.tipoProcessamento)?.descricaoTipoProcessamento,
            abono: this._configStructureMapAbonos.abdes.get(linha.ncodAbdes)?.designacao,
            qtd: linha.quantidade,
            motivo: abonos.motivo
          });
        }
      }
    }

    for (const abonoIntegrado of this.board.dgempMarcacoes.listaAbonosJaIntegradas) {
      tipoProcessamento = this._configStructureMapAbonos.tiposProcessamento.get(abonoIntegrado.tipoProcessamento)?.descricaoTipoProcessamento;
      abono = this._configStructureMapAbonos.abdes.get(abonoIntegrado.ncodAbdes)?.designacao;
      this.board.abonos.abonosIntegradas.push({
        data: abonoIntegrado.dataAbono,
        tipoProcessamento: tipoProcessamento,
        abono: abono,
        qtd: abonoIntegrado.quantidade,
        motivo: ''
      });
    }

    this.board.abonos.abonosMarcadas = orderBy(this.board.abonos.abonosMarcadas, 'data', 'asc');
    this.board.abonos.abonosIntegradas = orderBy(this.board.abonos.abonosIntegradas, 'data', 'asc');
    this.board.abonos.abonosRejeitadas = orderBy(this.board.abonos.abonosRejeitadas, 'data', 'asc');

    this.chartDataAbonosGlobal = [
      {arg: this._translateService.instant('gestaodgemps.text.marcadas'), val: this.board.abonos.abonosMarcadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.rejeitadas'), val: this.board.abonos.abonosRejeitadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.aprovadas'), val: this.board.abonos.abonosIntegradas.length}
    ];
  }

  private _fillFerias(): void {
    this.board.ferias = {
      feriasMarcadas: [],
      feriasRejeitadas: [],
      feriasIntegradas: []
    };

    for (const feria of this.board.dgempMarcacoes.listaFeriasMarcadas) {
      for (const linha of feria.linhas) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (feria.cab.fluxo.indice === 4) {
          this.board.ferias.feriasRejeitadas.push({data: linha.data});
        } else {
          this.board.ferias.feriasMarcadas.push({data: linha.data});
        }
      }
    }

    const feriasJaIntegradas = this.board.dgempMarcacoes.listaFeriasJaIntegradas;
    for (const feria of feriasJaIntegradas) {
      this.board.ferias.feriasIntegradas.push({data: feria.data});
    }

    this.board.numDiasFerias = this.board.colaboradorDetail.saldoFerias || 0;
    this.board.colaboradorDetail.saldoFerias = Math.max(0, this.board.numDiasFerias - feriasJaIntegradas.length);

    this.board.ferias.feriasMarcadas = orderBy(this.board.ferias.feriasMarcadas, 'data', 'asc');
    this.board.ferias.feriasIntegradas = orderBy(this.board.ferias.feriasIntegradas, 'data', 'asc');
    this.board.ferias.feriasRejeitadas = orderBy(this.board.ferias.feriasRejeitadas, 'data', 'asc');

    // FERIAS INTEGRADAS / RESTANTES DOUGHNUT
    this.chartDataFerias = [
      {arg: this._translateService.instant('gestaodgemps.text.aprovadas'), val: this.board.ferias.feriasIntegradas.length},
      {arg: this._translateService.instant('gestaodgemps.text.restantes'), val: this.board.numDiasFerias - this.board.ferias.feriasIntegradas.length}
    ];

    // FERIAS GLOBAL DOUGHNUT
    this.chartDataFeriasGlobal = [
      {arg: this._translateService.instant('gestaodgemps.text.marcadas'), val: this.board.ferias.feriasMarcadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.rejeitadas'), val: this.board.ferias.feriasRejeitadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.aprovadas'), val: this.board.ferias.feriasIntegradas.length}
    ];

    this.prettyFeriasMarcadas = gestaoDGEMPSPrettyFeriasMarcadas(this._translateService, this.board.dgempMarcacoes.dgEmp);
  }

  private _fillFaltas(): void {
    this.board.faltas = {
      faltasMarcadas: [],
      faltasRejeitadas: [],
      faltasIntegradas: []
    };

    let tipoFalta = '';
    let tipoProcessamento = '';
    let abono = '';

    for (const faltas of this.board.dgempMarcacoes.listaFaltasMarcadas) {
      for (const linha of faltas.linhas) {
        tipoFalta = this._configStructureMapFaltas.tipos.get(linha.tipoFalta)?.descricaoTipo;
        tipoProcessamento = this._configStructureMapFaltas.tiposProcessamento.get(linha.tipoProcessamento)?.descricaoTipoProcessamento;
        abono = this._configStructureMapFaltas.abdes.get(linha.ncodAbdes)?.designacao;
        if (faltas.cab.fluxo.indice === EPRHFluxoIndice.FaltasRejeitar) {
          this.board.faltas.faltasRejeitadas.push({
            data: linha.data,
            tipoFalta: tipoFalta,
            tipoProcessamento: tipoProcessamento,
            abono: abono,
            motivo: faltas.motivo
          });
        } else {
          this.board.faltas.faltasMarcadas.push({
            data: linha.data,
            tipoFalta: tipoFalta,
            tipoProcessamento: tipoProcessamento,
            abono: abono,
            motivo: faltas.motivo
          });
        }
      }
    }

    for (const faltaIntegrada of this.board.dgempMarcacoes.listaFaltasJaIntegradas) {
      tipoFalta = this._configStructureMapFaltas.tipos.get(faltaIntegrada.tipoFalta)?.descricaoTipo;
      tipoProcessamento = this._configStructureMapFaltas.tiposProcessamento.get(faltaIntegrada.tipoProcessamento)?.descricaoTipoProcessamento;
      abono = this._configStructureMapFaltas.abdes.get(faltaIntegrada.ncodAbdes)?.designacao;
      this.board.faltas.faltasIntegradas.push({
        data: faltaIntegrada.data,
        tipoFalta: tipoFalta,
        tipoProcessamento: tipoProcessamento,
        abono: abono,
        motivo: ''
      });
    }

    this.board.faltas.faltasMarcadas = orderBy(this.board.faltas.faltasMarcadas, 'data', 'asc');
    this.board.faltas.faltasIntegradas = orderBy(this.board.faltas.faltasIntegradas, 'data', 'asc');
    this.board.faltas.faltasRejeitadas = orderBy(this.board.faltas.faltasRejeitadas, 'data', 'asc');

    this.chartDataFaltas = [
      {arg: this._translateService.instant('gestaodgemps.text.marcadas'), val: this.board.faltas.faltasMarcadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.rejeitadas'), val: this.board.faltas.faltasRejeitadas.length},
      {arg: this._translateService.instant('gestaodgemps.text.aprovadas'), val: this.board.faltas.faltasIntegradas.length}
    ];
  }
}
