<div class="prevites">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="previTesManual"
    [dataSource]="previTesList"
    [(selectedRowKeys)]="selectedKeys"
    (onInitialized)="onInitialized($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onRowRemoving)="onRowRemoving($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onContentReady)="onContentReady()"
    (onSelectionChanged)="onSelectionChanged()">
  </dx-data-grid>
</div>
