import {JSONSchema} from '@ngx-pwa/local-storage';

export interface IOfficeReportingPagina {
  _id: number;
  pagina: string;
}

export const MODULE_NAME_OFFICE_REPORTING = 'officereporting';

export const STORAGE_KEY_OFFICE_REPORTING_SELECTED_PAGINAS = 'officereportingselectedpaginas';

export const SCHEMA_OFFICE_REPORTING_SELECTED_PAGINAS: JSONSchema = {
  type: 'array',
  items: {
    type: 'integer'
  }
};
