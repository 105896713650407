import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from '../../entities/empresas/empresas.entity.interface';
import {TUserSession} from '../../services/account/jsonUserApi.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ROLE} from '../../services/role.const';
import {TaxonomiasModuleComponent} from './components/taxonomias.module.component';
import {MODULE_NAME_TAXONOMIAS} from './taxonomias.module.interface';

export const MODULE_TAXONOMIAS: IModuleDefinition = {
  name: MODULE_NAME_TAXONOMIAS,
  nameAlias: ['taxonomiasModule'],
  state: {
    url: '/taxonomias',
    component: TaxonomiasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE]
    },
    resolve: [
      {
        provide: 'empresa',
        deps: ['session', EntityServiceBuilder],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<IEmpresaExtended> => {
          return entityServiceBuilder
            .build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS_ERP)
            .get({id: session.erp.nEmpresa})
            .then((response: HttpResponse<IEmpresaExtended>) => response.body);
        }
      }
    ]
  }
};
