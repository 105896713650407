import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_MODELO22_DIST_MUNICI} from '../modelo22.datasource.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_MODELO22_DIST_MUNICI: IDataSource<string> = deepFreeze({
  name: DATA_SOURCE_NAME_MODELO22_DIST_MUNICI,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: '{{value}} - {{name}}'
  },
  data: [
    {value: '0101', name: 'ÁGUEDA'},
    {value: '0102', name: 'ALBERGARIA-A-VELHA'},
    {value: '0103', name: 'ANADIA'},
    {value: '0104', name: 'AROUCA'},
    {value: '0105', name: 'AVEIRO'},
    {value: '0106', name: 'CASTELO DE PAIVA'},
    {value: '0107', name: 'ESPINHO'},
    {value: '0108', name: 'ESTARREJA'},
    {value: '0109', name: 'SANTA MARIA DA FEIRA'},
    {value: '0110', name: 'ÍLHAVO'},
    {value: '0111', name: 'MEALHADA'},
    {value: '0112', name: 'MURTOSA'},
    {value: '0113', name: 'OLIVEIRA DE AZEMÉIS'},
    {value: '0114', name: 'OLIVEIRA DO BAIRRO'},
    {value: '0115', name: 'OVAR'},
    {value: '0116', name: 'SÃO JOÃO DA MADEIRA'},
    {value: '0117', name: 'SERVER DO VOUGA'},
    {value: '0118', name: 'VAGOS'},
    {value: '0119', name: 'VALE DE CAMBRA'},
    {value: '0201', name: 'ALJUSTREL'},
    {value: '0202', name: 'ALMODÔVAR'},
    {value: '0203', name: 'ALVITO'},
    {value: '0204', name: 'BARRANCOS'},
    {value: '0205', name: 'BEJA'},
    {value: '0206', name: 'CASTRO VERDE'},
    {value: '0207', name: 'CUBA'},
    {value: '0208', name: 'FERREIRA DO ALENTEJO'},
    {value: '0209', name: 'MÉRTOLA'},
    {value: '0210', name: 'MOURA'},
    {value: '0211', name: 'ODEMIRA'},
    {value: '0212', name: 'OURIQUE'},
    {value: '0213', name: 'SERPA'},
    {value: '0214', name: 'VIDIGUEIRA'},
    {value: '0301', name: 'AMARES'},
    {value: '0302', name: 'BARCELOS'},
    {value: '0303', name: 'BRAGA'},
    {value: '0304', name: 'CABECEIRAS DE BASTO'},
    {value: '0305', name: 'CELORICO DE BASTO'},
    {value: '0306', name: 'ESPOSENDE'},
    {value: '0307', name: 'FAFE'},
    {value: '0308', name: 'GUIMARÃES'},
    {value: '0309', name: 'PÓVOA DE LANHOSO'},
    {value: '0310', name: 'TERRAS DE BOURO'},
    {value: '0311', name: 'VIEIRA DO MINHO'},
    {value: '0312', name: 'VILA NOVA DE FAMALICÃO'},
    {value: '0313', name: 'VILA VERDE'},
    {value: '0314', name: 'VIZELA'},
    {value: '0401', name: 'ALFÂNDEGA DA FÉ'},
    {value: '0402', name: 'BRAGANÇA'},
    {value: '0403', name: 'CARRAZEDA DE ANSIÃES'},
    {value: '0404', name: 'FREIXO ESPADA À CINTA'},
    {value: '0405', name: 'MACEDO CAVALEIROS'},
    {value: '0406', name: 'MIRANDA DO DOURO'},
    {value: '0407', name: 'MIRANDELA'},
    {value: '0408', name: 'MOGADOURO'},
    {value: '0409', name: 'TORRE DE MONCORVO'},
    {value: '0410', name: 'VILA FLOR'},
    {value: '0411', name: 'VIMIOSO'},
    {value: '0412', name: 'VINHAIS'},
    {value: '0501', name: 'BELMONTE'},
    {value: '0502', name: 'CASTELO BRANCO'},
    {value: '0503', name: 'COVILHÃ'},
    {value: '0504', name: 'FUNDÃO'},
    {value: '0505', name: 'IDANHA-A-NOVA'},
    {value: '0506', name: 'OLEIROS'},
    {value: '0507', name: 'PENAMACOR'},
    {value: '0508', name: 'PROENÇA-A-NOVA'},
    {value: '0509', name: 'SERTÃ'},
    {value: '0510', name: 'VILA DE REI'},
    {value: '0511', name: 'VILA VELHA DE RÓDÃO'},
    {value: '0601', name: 'ARGANIL'},
    {value: '0602', name: 'CANTANHEDE'},
    {value: '0603', name: 'COIMBRA'},
    {value: '0604', name: 'CONDEIXA-A-NOVA'},
    {value: '0605', name: 'FIGUEIRA DA FOZ'},
    {value: '0606', name: 'GÓIS'},
    {value: '0607', name: 'LOUSÃ'},
    {value: '0608', name: 'MIRA'},
    {value: '0609', name: 'MIRANDA DO CORVO'},
    {value: '0610', name: 'MONTEMOR-O-VELHO'},
    {value: '0611', name: 'OLIVEIRADO HOSPITAL'},
    {value: '0612', name: 'PAMPILHOSA DA SERRA'},
    {value: '0613', name: 'PENACOVA'},
    {value: '0614', name: 'PENELA'},
    {value: '0615', name: 'SOURE'},
    {value: '0616', name: 'TÁBUA'},
    {value: '0617', name: 'VILA NOVA DE POIARES'},
    {value: '0701', name: 'ALANDROAL'},
    {value: '0702', name: 'ARRAIOLOS'},
    {value: '0703', name: 'BORBA'},
    {value: '0704', name: 'ESTREMOZ'},
    {value: '0705', name: 'ÉVORA'},
    {value: '0706', name: 'MONTEMOR-O-NOVO'},
    {value: '0707', name: 'MORA'},
    {value: '0708', name: 'MOURÃO'},
    {value: '0709', name: 'PORTEL'},
    {value: '0710', name: 'REDONDO'},
    {value: '0711', name: 'REGUENGOS MONSARAZ'},
    {value: '0712', name: 'VENDAS NOVAS'},
    {value: '0713', name: 'VIANA DO ALENTEJO'},
    {value: '0714', name: 'VILA VIÇOSA'},
    {value: '0801', name: 'ALBUFEIRA'},
    {value: '0802', name: 'ALCOUTIM'},
    {value: '0803', name: 'ALJEZUR'},
    {value: '0804', name: 'CASTRO MARIM'},
    {value: '0805', name: 'FARO'},
    {value: '0806', name: 'LAGOA'},
    {value: '0807', name: 'LAGOS'},
    {value: '0808', name: 'LOULÉ'},
    {value: '0809', name: 'MONCHIQUE'},
    {value: '0810', name: 'OLHÃO'},
    {value: '0811', name: 'PORTIMÃO'},
    {value: '0812', name: 'SÃO BRÁS DE ALPORTEL'},
    {value: '0813', name: 'SILVES'},
    {value: '0814', name: 'TAVIRA'},
    {value: '0815', name: 'VILA DO BISPO'},
    {value: '0816', name: 'VILA REAL STO. ANTÓNIO'},
    {value: '0901', name: 'AGUIAR DA BEIRA'},
    {value: '0902', name: 'ALMEIDA'},
    {value: '0903', name: 'CELORICO DA BEIRA'},
    {value: '0904', name: 'FIGUEIRA C. RODRIGO'},
    {value: '0905', name: 'FORNOS DE ALGODRES'},
    {value: '0906', name: 'GOUVEIA'},
    {value: '0907', name: 'GUARDA'},
    {value: '0908', name: 'MANTEIGAS'},
    {value: '0909', name: 'MEDA'},
    {value: '0910', name: 'PINHEL'},
    {value: '0911', name: 'SABUGAL'},
    {value: '0912', name: 'SEIA'},
    {value: '0913', name: 'TRANCOSO'},
    {value: '0914', name: 'VILA NOVA DE FOZ CÔA'},
    {value: '1001', name: 'ALCOBAÇA'},
    {value: '1002', name: 'ALVAIÁZERE'},
    {value: '1003', name: 'ANSIÃO'},
    {value: '1004', name: 'BATALHA'},
    {value: '1005', name: 'BOMBARRAL'},
    {value: '1006', name: 'CALDAS DA RAINHA'},
    {value: '1007', name: 'CASTANHEIRA DE PÊRA'},
    {value: '1008', name: 'FIGUEIRÓ DOS VINHOS'},
    {value: '1009', name: 'LEIRIA'},
    {value: '1010', name: 'MARINHA GRANDE'},
    {value: '1011', name: 'NAZARÉ'},
    {value: '1012', name: 'ÓBIDOS'},
    {value: '1013', name: 'PEDRÓGÃO GRANDE'},
    {value: '1014', name: 'PENICHE'},
    {value: '1015', name: 'POMBAL'},
    {value: '1016', name: 'PORTO DE MÓS'},
    {value: '1101', name: 'ALENQUER'},
    {value: '1102', name: 'ARRUDA DOS VINHOS'},
    {value: '1103', name: 'AZAMBUJA'},
    {value: '1104', name: 'CADAVAL'},
    {value: '1105', name: 'CASCAIS'},
    {value: '1106', name: 'LISBOA'},
    {value: '1107', name: 'LOURES'},
    {value: '1108', name: 'LOURINHÃ'},
    {value: '1109', name: 'MAFRA'},
    {value: '1110', name: 'OEIRAS'},
    {value: '1111', name: 'SINTRA'},
    {value: '1112', name: 'SOBRAL MONTE AGRAÇO'},
    {value: '1113', name: 'TORRES VEDRAS'},
    {value: '1114', name: 'VILA FRANCA DE XIRA'},
    {value: '1115', name: 'AMADORA'},
    {value: '1116', name: 'ODIVELAS'},
    {value: '1201', name: 'ALTER DO CHÃO'},
    {value: '1202', name: 'ARRONCHES'},
    {value: '1203', name: 'AVIS'},
    {value: '1204', name: 'CAMPO MAIOR'},
    {value: '1205', name: 'CASTELO DE VIDE'},
    {value: '1206', name: 'CRATO'},
    {value: '1207', name: 'ELVAS'},
    {value: '1208', name: 'FRONTEIRA'},
    {value: '1209', name: 'GAVIÃO'},
    {value: '1210', name: 'MARVÃO'},
    {value: '1211', name: 'MONFORTE'},
    {value: '1212', name: 'NISA'},
    {value: '1213', name: 'PONTE DE SOR'},
    {value: '1214', name: 'PORTALEGRE'},
    {value: '1215', name: 'SOUSEL'},
    {value: '1301', name: 'AMARANTE'},
    {value: '1302', name: 'BAIÃO'},
    {value: '1303', name: 'FELGUEIRAS'},
    {value: '1304', name: 'GONDOMAR'},
    {value: '1305', name: 'LOUSADA'},
    {value: '1306', name: 'MAIA'},
    {value: '1307', name: 'MARCO DE CANAVEZES'},
    {value: '1308', name: 'MATOSINHOS'},
    {value: '1309', name: 'PAÇOS DE FERREIRA'},
    {value: '1310', name: 'PAREDES'},
    {value: '1311', name: 'PENAFIEL'},
    {value: '1312', name: 'PORTO'},
    {value: '1313', name: 'PÓVOA DE VARZIM'},
    {value: '1314', name: 'SANTO TIRSO'},
    {value: '1318', name: 'TROFA'},
    {value: '1315', name: 'VALONGO'},
    {value: '1316', name: 'VILA DO CONDE'},
    {value: '1317', name: 'VILA NOVA DE GAIA'},
    {value: '1401', name: 'ABRANTES'},
    {value: '1402', name: 'ALCANENA'},
    {value: '1403', name: 'ALMEIRIM'},
    {value: '1404', name: 'ALPIARÇA'},
    {value: '1405', name: 'BENAVENTE'},
    {value: '1406', name: 'CARTAXO'},
    {value: '1407', name: 'CHAMUSCA'},
    {value: '1408', name: 'CONSTÂNCIA'},
    {value: '1409', name: 'CORUCHE'},
    {value: '1410', name: 'ENTRONCAMENTO'},
    {value: '1411', name: 'FERREIRA DO ZÊZERE'},
    {value: '1412', name: 'GOLEGÃ'},
    {value: '1413', name: 'MAÇÃO'},
    {value: '1414', name: 'RIO MAIOR'},
    {value: '1415', name: 'SALVATERRA DE MAGOS'},
    {value: '1416', name: 'SANTARÉM'},
    {value: '1417', name: 'SARDOAL'},
    {value: '1418', name: 'TOMAR'},
    {value: '1419', name: 'TORRES NOVAS'},
    {value: '1420', name: 'VILA NOVA BARQUINHA'},
    {value: '1421', name: 'OURÉM'},
    {value: '1501', name: 'ALCÁCER DO SAL'},
    {value: '1502', name: 'ALCOCHETE'},
    {value: '1503', name: 'ALMADA'},
    {value: '1504', name: 'BARREIRO'},
    {value: '1505', name: 'GRÂNDOLA'},
    {value: '1506', name: 'MOITA'},
    {value: '1507', name: 'MONTIJO'},
    {value: '1508', name: 'PALMELA'},
    {value: '1509', name: 'SANTIAGO DO CACÉM'},
    {value: '1510', name: 'SEIXAL'},
    {value: '1511', name: 'SESIMBRA'},
    {value: '1512', name: 'SETÚBAL'},
    {value: '1513', name: 'SINES'},
    {value: '1601', name: 'ARCOS DE VALDEVEZ'},
    {value: '1602', name: 'CAMINHA'},
    {value: '1603', name: 'MELGAÇO'},
    {value: '1604', name: 'MONÇÃO'},
    {value: '1605', name: 'PAREDES DE COURA'},
    {value: '1606', name: 'PONTE DA BARCA'},
    {value: '1607', name: 'PONTE DE LIMA'},
    {value: '1608', name: 'VALENÇA'},
    {value: '1609', name: 'VIANA DO CASTELO'},
    {value: '1610', name: 'VILA NOVA DE CERVEIRA'},
    {value: '1701', name: 'ALIJÓ'},
    {value: '1702', name: 'BOTICAS'},
    {value: '1703', name: 'CHAVES'},
    {value: '1704', name: 'MESÃO FRIO'},
    {value: '1705', name: 'MONDIM DE BASTO'},
    {value: '1706', name: 'MONTALEGRE'},
    {value: '1707', name: 'MURÇA'},
    {value: '1708', name: 'PESO DA RÉGUA'},
    {value: '1709', name: 'RIBEIRA DE PENA'},
    {value: '1710', name: 'SABROSA'},
    {value: '1711', name: 'SANTA MARTA PENAGUIÃO'},
    {value: '1712', name: 'VALPAÇOS'},
    {value: '1713', name: 'VILA POUCA DE AGUIAR'},
    {value: '1714', name: 'VILA REAL'},
    {value: '1801', name: 'ARMAMAR'},
    {value: '1802', name: 'CARREGAL DO SAL'},
    {value: '1803', name: 'CASTRO DAIRE'},
    {value: '1804', name: 'CINFÃES'},
    {value: '1805', name: 'LAMEGO'},
    {value: '1806', name: 'MANGUALDE'},
    {value: '1807', name: 'MOIMENTA DA BEIRA'},
    {value: '1808', name: 'MORTÁGUA'},
    {value: '1809', name: 'NELAS'},
    {value: '1810', name: 'OLIVEIRA DE FRADES'},
    {value: '1811', name: 'PENALVA DO CASTELO'},
    {value: '1812', name: 'PENEDONO'},
    {value: '1813', name: 'RESENDE'},
    {value: '1814', name: 'SANTA COMBA DÃO'},
    {value: '1815', name: 'SÃO JOÃO DA PESQUEIRA'},
    {value: '1816', name: 'SÃO PEDRO DO SUL'},
    {value: '1817', name: 'SÁTÃO'},
    {value: '1818', name: 'SERNANCELHE'},
    {value: '1819', name: 'TABUAÇO'},
    {value: '1820', name: 'TAROUCA'},
    {value: '1821', name: 'TONDELA'},
    {value: '1822', name: 'VILA NOVA DE PAIVA'},
    {value: '1823', name: 'VISEU'},
    {value: '1824', name: 'VOUZELA'},
    {value: '1901', name: 'ANGRA DO HEROÍSMO'},
    {value: '1902', name: 'CALHETA'},
    {value: '1903', name: 'SANTA CRUZ GRACIOSA'},
    {value: '1904', name: 'VELAS'},
    {value: '1905', name: 'VILA PRAIA DA VITÓRIA'},
    {value: '2001', name: 'CORVO'},
    {value: '2002', name: 'HORTA'},
    {value: '2003', name: 'LAJES DAS FLORES'},
    {value: '2004', name: 'LAJES DO PICO'},
    {value: '2005', name: 'MADALENA'},
    {value: '2006', name: 'SANTA CRUZ FLORES'},
    {value: '2007', name: 'SÃO ROQUE DO PICO'},
    {value: '2101', name: 'LAGOA'},
    {value: '2102', name: 'NORDESTE'},
    {value: '2103', name: 'PONTA DELGADA'},
    {value: '2104', name: 'POVOAÇÃO'},
    {value: '2105', name: 'RIBEIRA GRANDE'},
    {value: '2106', name: 'VILA FRANCA DO CAMPO'},
    {value: '2107', name: 'VILA PORTO'},
    {value: '2201', name: 'CALHETA'},
    {value: '2202', name: 'CÂMARA DE LOBOS'},
    {value: '2203', name: 'FUNCHAL'},
    {value: '2204', name: 'MACHICO'},
    {value: '2205', name: 'PONTA DO SOL'},
    {value: '2206', name: 'PORTO MONIZ'},
    {value: '2207', name: 'PORTO SANTO'},
    {value: '2208', name: 'RIBEIRA BRAVA'},
    {value: '2209', name: 'SANTA CRUZ'},
    {value: '2210', name: 'SANTANA'},
    {value: '2211', name: 'SÃO VICENTE'}
  ]
});
