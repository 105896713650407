import {IJsonBalancete} from './jsonContabilidade.balancetes.module.interface';

export interface IBalancetesFilters {
  anosComparativos: Array<number>;
  ateConta: string;
  atePeriodo: string;
  atePeriodoNome?: string;
  contasClasse: boolean;
  contasCorrentes: boolean;
  contasMovimento: boolean;
  contasRazao: boolean;
  contasSubTotal: boolean;
  deConta: string;
  dePeriodo: string;
  dePeriodoNome?: string;
  escodeContasSaldoZero: boolean;
  loadAllAnos: number;
  loadOnlyAno: number;
  dePeriodoAno: string;
  deCCusto: string;
  ateCCusto: string;
  calculaSaldosIniciaisAnoNMaisUm: boolean;
  pocAltCabID: string;
  nomePocAlt: string;
  escondeRubricasSemMovimento: boolean;
}

export interface IYearBalancete {
  caption: string;
  ano: number;
  headerFields: Array<IHeaderFieldBalancete>;
  listaBalancetes: Array<IJsonBalancete>;
}

export interface IHeaderFieldBalancete {
  caption: string;
  fields: Array<IFieldBalancete>;
}

export interface IFieldBalancete {
  _dataField: string;
  _dataType: string;
  _caption: string;
  _format?: string;
}

export interface IDynamicBalancete {
  [key: string]: unknown;

  nConta: string;
  nomeConta: string;
  nContaPai: string;
  tipo: number;
  temCC: boolean;
}

export interface IColumnBalanceteTotal {
  column: string;
  value: string;
}

export interface IContabilidadeBalancetesParams {
  dePeriodo?: string;
  periodoAte?: string;
  deConta?: string;
  contaAte?: string;
  fromModelo22?: boolean;
  date?: string;
}

export enum ETipoPerfilBalancetes {
  TODAS,
  SALDO
}

export const MODULE_NAME_PCA_ESTATISTICA_BALANCETES = 'balancetes';
export const MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS = 'balancetesalternativos';
