import {Injector} from '@angular/core';
import {NgbModalConfig, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';

export function configNgBootstrapModalConfig(injector: Injector): void {
  const modalConfig: NgbModalConfig = injector.get<NgbModalConfig>(NgbModalConfig);
  modalConfig.scrollable = true;
}

export function configNgBootstrapTooltipConfig(injector: Injector): void {
  const tooltipConfig: NgbTooltipConfig = injector.get<NgbTooltipConfig>(NgbTooltipConfig);
  tooltipConfig.container = 'body';
}
