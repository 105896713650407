<pl-form [submit]="close" [properties]="{disabled: closeDisabled, validators: {required: {value: true}}}" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'atFRV.novacontaccmodal.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="contabilidade-digital-novacontacc-info" *ngIf="info">
      <div>{{ info }}</div>
      <hr />
    </div>

    <pl-group>
      <label [translate]="'atFRV.novacontaccmodal.fields.radical'"></label>
      <edit>
        <entity-autocomplete entity="naturezasContab" [model]="model.naturezaContab" (modelChange)="changedNaturezaContabilistica($event)" output="radical" #elementNaturezasContab>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'atFRV.novacontaccmodal.fields.nConta'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.nConta" [properties]="{validators: {maxlength: {value: 75}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'atFRV.novacontaccmodal.fields.nome'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.nome" [properties]="{validators: {maxlength: {value: 100}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-submit" #elementActionSubmit><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-cancel" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
