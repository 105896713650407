import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_CATEGORIA_DESCONTOS, ETipoCategoriaDesc} from './tipoCategoriaDesc.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS: IDataSource<ETipoCategoriaDesc> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_CATEGORIA_DESCONTOS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoCategoriaDesc.SegGrupo, name: 'tiposCategoriaDesc.data.segGrupo'},
    {value: ETipoCategoriaDesc.IRS, name: 'tiposCategoriaDesc.data.irs'},
    {value: ETipoCategoriaDesc.SegSocial, name: 'tiposCategoriaDesc.data.segSocial'},
    {value: ETipoCategoriaDesc.CGA, name: 'tiposCategoriaDesc.data.cga'},
    {value: ETipoCategoriaDesc.ImpostoSelo, name: 'tiposCategoriaDesc.data.impostoSelo'},
    {value: ETipoCategoriaDesc.Sindicato, name: 'tiposCategoriaDesc.data.sindicato'},
    {value: ETipoCategoriaDesc.Seguro, name: 'tiposCategoriaDesc.data.seguro'},
    {value: ETipoCategoriaDesc.Falta, name: 'tiposCategoriaDesc.data.falta'},
    {value: ETipoCategoriaDesc.Ferias, name: 'tiposCategoriaDesc.data.ferias'},
    {value: ETipoCategoriaDesc.Punicao, name: 'tiposCategoriaDesc.data.punicao'},
    {value: ETipoCategoriaDesc.RetImpTerceiros, name: 'tiposCategoriaDesc.data.retImpTerceiros'},
    {value: ETipoCategoriaDesc.SubsidioRefeicao, name: 'tiposCategoriaDesc.data.subsidioRefeicao'},
    {value: ETipoCategoriaDesc.Outra, name: 'tiposCategoriaDesc.data.outra'},
    {value: ETipoCategoriaDesc.SobretaxaExtra, name: 'tiposCategoriaDesc.data.sobretaxaExtra'},
    {value: ETipoCategoriaDesc.ADSE, name: 'tiposCategoriaDesc.data.adse'},
    {value: ETipoCategoriaDesc.Penhora, name: 'tiposCategoriaDesc.data.penhora'},
    {value: ETipoCategoriaDesc.FCT, name: 'tiposCategoriaDesc.data.fct'},
    {value: ETipoCategoriaDesc.FGCT, name: 'tiposCategoriaDesc.data.fgct'},
    {value: ETipoCategoriaDesc.PenhoraPensaoAlimentos, name: 'tiposCategoriaDesc.data.penhoraPensaoAlimentos'},
    {value: ETipoCategoriaDesc.IndemnizacaoFaltaAvisoPrevio, name: 'tiposCategoriaDesc.data.indemnizacaoFaltaAvisoPrevio'}
  ]
});
