import {Injector} from '@angular/core';
import {IWindowCGErpCloudTests} from '../core/services/tests/tests.service.interface';
import {TestsService} from '../core/services/tests/tests.service';

export function configTests(injector: Injector): void {
  const testsService: TestsService = injector.get<TestsService>(TestsService);
  const cgErpCloudTests: IWindowCGErpCloudTests = {
    pendingRequests: -1,
    authenticate: testsService.authenticate.bind(testsService),
    dismissAllModals: testsService.dismissAllModals.bind(testsService)
  };
  testsService.pendingRequests().subscribe((pendingRequests: number) => {
    cgErpCloudTests.pendingRequests = pendingRequests;
  });
  Object.defineProperty(window, 'cgErpCloudTests', {
    configurable: false,
    enumerable: false,
    value: cgErpCloudTests,
    writable: false
  });
}
