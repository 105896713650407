<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'impdoc.modalTitle'" [translateParams]="{nDoc: doc.cab.nDoc, clifo: clifo}"></h4>
</div>

<div class="modal-body" [ngSwitch]="view">
  <div *ngSwitchCase="'pdf'">
    <cg-pdf-viewer [pdfSrc]="url"></cg-pdf-viewer>
  </div>

  <div class="entity-detail-form" *ngSwitchDefault>
    <pl-form [(ngForm)]="form" [properties]="{orientation: 'horizontal'}">
      <pl-group>
        <label [translate]="'impdoc.label.nvias'"></label>
        <edit>
          <pl-edit type="rowselect" [(model)]="nvias" [properties]="{start: 1, end: 10}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'impdoc.label.segundaVia'"></label>
        <edit>
          <pl-edit type="boolean" attrName="segundaVia" [(model)]="segundaVia"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="reports.length">
        <label [translate]="'impdoc.label.rptName'"></label>
        <edit>
          <pl-edit type="select" attrName="reportSelect" [(model)]="rptName" [properties]="{select: {valueProp: 'nome', labelProp: 'descricao', list: reports}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'impdoc.label.emailcliente'"></label>
        <edit>
          <pl-edit type="text" [(model)]="email" [properties]="{placeholder: 'impdoc.placeholder.email'}"></pl-edit>
        </edit>
        <actions>
          <pl-button
            klass="btn btn-primary"
            [onClick]="fnSendEmail"
            [disabled]="form?.invalid || !email || doc.cab.nDocumento === 0"
            [tooltip]="{disabled: doc.cab.nDocumento !== 0, text: 'impdoc.tooltip.avisoEnviar', placement: 'top', tooltipClass: 'tooltip-warning'}">
            <i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'impdoc.label.enviar'"></span>
          </pl-button>
        </actions>
      </pl-group>
      <pl-group *ngIf="tipoDocEDeFaturaEletronica">
        <edit>
          <div class="alert alert-info" role="alert" style="padding-top: 4px; padding-bottom: 4px">
            <i class="fa fa-info-circle"></i>
            <span *ngIf="!semConfiguracao">
              {{ messageEmissaoFaturaEletronica }}
            </span>
            <span *ngIf="semConfiguracao" title="{{ hintSemConfiguracao }}">
              {{ messageEmissaoFaturaEletronica }}
            </span>
          </div>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-info btn-sm" (click)="callFaturacaoPublica()" *ngIf="doc?.cab?.faturacaoPublica && doc?.clifo?.facPublicaModelo !== 0">
    <i class="fa fa-fw fa-file-text"></i>&nbsp;<span [translate]="'impdoc.btn.faturacaoPublica'"></span>
  </button>

  <button type="button" class="btn btn-primary btn-sm action-switch" (click)="switchView()" *ngIf="!preview">
    <i *ngIf="view !== 'pdf'" class="fa fa-fw fa-file-pdf-o"></i>
    <i *ngIf="view === 'pdf'" class="fa fa-fw fa-chevron-left"></i>&nbsp;{{ view === 'pdf' ? 'Voltar' : 'PDF' }}
  </button>

  <button type="button" class="btn btn-light btn-sm" (click)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
