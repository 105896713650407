import {Subscription} from 'rxjs';
import {Component, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {PlCompsService, THyperlinkTarget} from 'pl-comps-angular';
import {AppService} from './core/services/app/app.service';
import {EAppLaunchMode} from './common/site';
import {IAppStatus} from './core/services/app/app.service.interface';
import {isDev, isProduction, isTest} from './config/constants';
import {TestsService} from './core/services/tests/tests.service';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  public readonly launchModes: typeof EAppLaunchMode;
  public readonly isDev: boolean;
  public readonly cookiesConsent: boolean;
  public launchMode: EAppLaunchMode;
  public hasPendingRequests: boolean;

  private readonly _document: Document;
  private readonly _subscriptionStatus: Subscription;
  private readonly _subscriptionPendingRequests: Subscription;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _appService: AppService,
    private readonly _testsService: TestsService,
    private readonly _cgCompsService: PlCompsService
  ) {
    this.launchModes = EAppLaunchMode;
    this.isDev = isDev();
    this.hasPendingRequests = false;
    this.cookiesConsent = this._evaluateCookiesConsent();
    this.launchMode = EAppLaunchMode.Default;
    this._document = <Document>document;

    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this.launchMode = status.launchMode ?? EAppLaunchMode.Default;
      const defaultTarget: THyperlinkTarget = this.launchMode === EAppLaunchMode.HybridPartial ? '_self' : '_blank';
      this._cgCompsService.setConfig({
        plToolbar: {
          defaultHyperlinkTarget: defaultTarget,
          defaultDownloadHyperlinkTarget: defaultTarget
        }
      });
    });

    if (isTest()) {
      this._subscriptionPendingRequests = this._testsService.pendingRequests().subscribe((pendingRequests: number) => {
        this.hasPendingRequests = pendingRequests > 0;
      });
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
    if (this._subscriptionPendingRequests) {
      this._subscriptionPendingRequests.unsubscribe();
    }
  }

  private _evaluateCookiesConsent(): boolean {
    if (!this._document) {
      return false;
    }
    const window: Window = this._document.defaultView;
    const userAgent: string = window.navigator.userAgent.toLowerCase();
    const ios: boolean = /iphone|ipod|ipad/.test(userAgent);
    const safari: boolean = userAgent.includes('safari');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const standalone = Boolean((<any>window.navigator).standalone);
    return isProduction() && (!ios || (safari && !standalone) || (standalone && !safari));
  }
}
