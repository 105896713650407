import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IAtivosImpressaoHeader} from './ativosImpressao.module.interface';
import {IJsonAtivosDepreciacoesDetalhadasImpressao, IJsonAtivosImpressaoConsultDep, IJsonAtivosImpressaoHeader} from './jsonAtivosImpressao.module.interface';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosImpressaoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosimpressao`;
  }

  public ativosImpressaoGet(header: IAtivosImpressaoHeader): TServiceResponse<Array<IJsonAtivosDepreciacoesDetalhadasImpressao>> {
    return this._apiService.get<Array<IJsonAtivosDepreciacoesDetalhadasImpressao>>({
      url: this._path,
      params: {
        codativode: header.codAtivoDe,
        codativoate: header.codAtivoAte,
        ncontade: header.nContaDe,
        ncontaate: header.nContaAte,
        nccustode: header.nCCustoDe,
        nccustoate: header.nCCustoAte,
        codamortde: header.codAmortDe,
        codamortate: header.codAmortAte,
        codmapade: header.codMapaDe,
        codmapaate: header.codMapaAte,
        normal: header.normal,
        abatido: header.abatido,
        vendido: header.vendido,
        dividido: header.dividido,
        estadouso: header.estadoUso,
        dataaquisicaode: header.dataAquisicaoDe,
        dataaquisicaoate: header.dataAquisicaoAte,
        dataabatede: header.dataAbateDe,
        dataabateate: header.dataAbateAte,
        datareferencia: header.dataReferencia
      }
    });
  }

  public getDefaultDataHeader(): TServiceResponse<IJsonAtivosImpressaoHeader> {
    return this._apiService.get<IJsonAtivosImpressaoHeader>({
      url: `${this._path}/header`
    });
  }

  public getListagemAtivosUrl(listagemAtivos: IAtivosImpressaoHeader): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      codativode: listagemAtivos.codAtivoDe,
      codativoate: listagemAtivos.codAtivoAte,
      ncontade: listagemAtivos.nContaDe,
      ncontaate: listagemAtivos.nContaAte,
      nccustode: listagemAtivos.nCCustoDe,
      nccustoate: listagemAtivos.nCCustoAte,
      codamortde: listagemAtivos.codAmortDe,
      codamortate: listagemAtivos.codAmortAte,
      codmapade: listagemAtivos.codMapaDe,
      codmapaate: listagemAtivos.codMapaAte,
      normal: listagemAtivos.normal,
      abatido: listagemAtivos.abatido,
      vendido: listagemAtivos.vendido,
      dividido: listagemAtivos.dividido,
      estadouso: listagemAtivos.estadoUso,
      dataaquisicaode: listagemAtivos.dataAquisicaoDe,
      dataaquisicaoate: listagemAtivos.dataAquisicaoAte,
      dataabatede: listagemAtivos.dataAbateDe,
      dataabateate: listagemAtivos.dataAbateAte,
      datareferencia: listagemAtivos.dataReferencia,
      reportname: listagemAtivos.reportName,
      tipoexportacao: listagemAtivos.tipoExportacao
    });
  }

  public getConsultarDepreciacoes(codativo: number): TServiceResponse<IJsonAtivosImpressaoConsultDep> {
    return this._apiService.get<IJsonAtivosImpressaoConsultDep>({
      url: `${this._path}/consultardepreciacoes`,
      params: {
        codativo: codativo
      }
    });
  }
}
