import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {IJsonInvec} from '../../../../entities/inven/jsonInvec.entity.interface';
import {ENTITY_NAME_INVEC} from '../../../../entities/inven/invec.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {buildSessionUrlWithParams} from '../../../../../common/utils/utils';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {EReport} from '../../../../entities/reports/reports.interface';

@Component({
  selector: 'inventarios-imprimir-modal',
  templateUrl: './inventarios.imprimir.modal.component.html'
})
export class InventariosImprimirModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public invec: IJsonInvec;

  public report: IJsonReport;
  public pdfUrl: string;

  private readonly _serviceInvec: IEntityService<IJsonInvec>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceInvec = this._entityServiceBuilder.build<IJsonInvec>(ENTITY_NAME_INVEC);
    this.pdfUrl = '';
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public reportChange(reportModel: IJsonReport): void {
    this.report = reportModel;
    buildSessionUrlWithParams(`${this._serviceInvec.entityUrl()}/pdf/${this.invec.invecID}`, {reportName: this.report.name}).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.Inventarios)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
          this.reportChange(this.report);
        }
      });
  }
}
