import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  EMapaPagamentoConfigStepsEnum,
  EMapaPagamImpostoEnum,
  IJsonMapaPagamEmpresaSimpleItem,
  IJsonMapaPagamentoConfig,
  IJsonMapaPagamentoUserConfig,
  IJsonMapaPagamImposto
} from '../../mapaPagamentos.module.interface';
import {MapaPagamentosService} from '../../mapaPagamentos.module.service';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';
import {isDefinedNotNull} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'module-mapa-pagamentos-config-modal',
  templateUrl: './mapaPagamentos.config.modal.component.html'
})
export class MapaPagamentosConfigModalComponent extends CGModalComponent<Partial<IJsonMapaPagamentoConfig>> implements OnInit {
  @Input() public config: IJsonMapaPagamentoConfig;
  @Input() public allowCancel: boolean;

  public currentStep: EMapaPagamentoConfigStepsEnum;
  public modalSteps: typeof EMapaPagamentoConfigStepsEnum;
  public readonly templateMultiSelectEmpresas: string;
  public readonly templateMultiSelectPagamentos: string;
  public empresasDisponiveis: Array<IJsonMapaPagamEmpresaSimpleItem>;
  public empresasSelecionadas: Array<IJsonMapaPagamEmpresaSimpleItem>;
  public pagamentosDisponiveis: Array<IJsonMapaPagamImposto>;
  public pagamentosSelecionadas: Array<IJsonMapaPagamImposto>;

  private readonly _impostosList: Array<{value: EMapaPagamImpostoEnum; label: string}>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _mapaPagamentosService: MapaPagamentosService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.modalSteps = EMapaPagamentoConfigStepsEnum;
    this.currentStep = EMapaPagamentoConfigStepsEnum.EMPRESAS;
    this.empresasDisponiveis = [];
    this.empresasSelecionadas = [];
    this.pagamentosDisponiveis = [];
    this.pagamentosSelecionadas = [];
    this._impostosList = [
      {value: EMapaPagamImpostoEnum.IVA, label: this._translateService.instant('mapapagam.fields.iva')},
      {value: EMapaPagamImpostoEnum.IRCMod22, label: this._translateService.instant('mapapagam.fields.ircMod22')},
      {value: EMapaPagamImpostoEnum.IMI, label: this._translateService.instant('mapapagam.fields.imi')},
      {value: EMapaPagamImpostoEnum.IUC, label: this._translateService.instant('mapapagam.fields.iuc')},
      {value: EMapaPagamImpostoEnum.DMR, label: this._translateService.instant('mapapagam.fields.dmr')},
      {value: EMapaPagamImpostoEnum.RetencaoNaFonte, label: this._translateService.instant('mapapagam.fields.retencaoNaFonte')},
      {value: EMapaPagamImpostoEnum.PagAntIRC, label: this._translateService.instant('mapapagam.fields.pagAntIRC')},
      {value: EMapaPagamImpostoEnum.DMRSS, label: this._translateService.instant('mapapagam.fields.dmrSS')}
    ];
    this.templateMultiSelectEmpresas = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.templateMultiSelectPagamentos = '{{value}} - {{label}}';
    this.allowCancel = true;
  }

  public ngOnInit(): void {
    this.empresasDisponiveis = [...this.config.empresas];
    this.empresasSelecionadas = [...this.config.selectedEmpresas];
    this.pagamentosDisponiveis = [...this._impostosList];
    this.config.selectedPagamentos.forEach((item) => {
      const impostoSelecionado = this._impostosList.find((imposto) => imposto.value === item);
      if (isDefinedNotNull(impostoSelecionado)) {
        this.pagamentosSelecionadas.push(impostoSelecionado);
      }
    });
  }

  public stepPrevClick(): void {
    this.currentStep = EMapaPagamentoConfigStepsEnum.EMPRESAS;
  }

  public stepNextClick(): void {
    this.currentStep = EMapaPagamentoConfigStepsEnum.PAGAMENTOS;
  }

  public readonly fnSaveConfig: () => TServiceResponse<void> = () => this._saveConfig();

  private _saveConfig(): TServiceResponse<void> {
    const model: IJsonMapaPagamentoUserConfig = {
      selectedPagamentos: [],
      selectedEmpresas: this.empresasSelecionadas
    };

    this.pagamentosSelecionadas.forEach((item) => {
      model.selectedPagamentos.push(item.value);
    });

    return this._mapaPagamentosService.saveConfig(model).then(() => {
      this.close({
        selectedPagamentos: model.selectedPagamentos,
        selectedEmpresas: this.empresasSelecionadas
      });
      return undefined;
    });
  }
}
