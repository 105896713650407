import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPISP} from './qpisp.entity.interface';
import {IJsonQPISP} from './jsonQPISP.entity.interface';

export const ENTITY_QPISP: IEntityDefinition<IJsonQPISP> = {
  name: ENTITY_NAME_QPISP,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.qpisp',
  searchPlaceholder: 'qpisp.pesquisa',
  metadata: {
    keyName: 'codISP',
    fields: [
      {name: 'codISP', caption: 'qpisp.fields.codISP'},
      {name: 'nome', caption: 'qpisp.fields.nome'}
    ],
    order: 'codISP',
    searchFields: 'codISP,nome',
    filterFields: 'codISP,nome'
  },
  autocomplete: {
    rowTemplate: '{{codISP}} - {{nome}}',
    output: 'nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
