import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDebDiretosSEPANotificarItem} from '../debDiretosSEPA.module.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'ddsepa-por-notificar-modal',
  templateUrl: './debDiretosSEPA.porNotificar.modal.component.html'
})
export class DebDiretosSEPAPorNotificarModalComponent extends CGModalComponent<void> {
  @Input() public loteFicheiro: string;

  public dataGridDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPANotificarItem>;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      },
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'emailDD', dataType: 'string', caption: 'debDiretosSEPA.fields.emailDD'},
        {dataField: 'valor', dataType: 'double', caption: 'debDiretosSEPA.fields.valor'},
        {
          dataField: 'enviadoEmail',
          caption: 'debDiretosSEPA.fields.estado',
          calculateDisplayValue: (rowData: IJsonDebDiretosSEPANotificarItem) => {
            return rowData.enviadoEmail ? 'Notificação enviada' : 'Notificação por enviar';
          }
        }
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getNotificaoTableSource()
      })
    };
  }

  public fnNotificar = (): Promise<void> => this._notificar();

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  private _getNotificaoTableSource(): Promise<Array<IJsonDebDiretosSEPANotificarItem>> {
    return this._debDiretosSEPAModuleService.getClientesPorNotificar(this.loteFicheiro).then((response: HttpResponse<Array<IJsonDebDiretosSEPANotificarItem>>) => response.body);
  }

  private _notificar(): Promise<void> {
    const selectedRowKeys: Array<string> = [...new Set(this._dataGridInstance.getSelectedRowKeys())];
    if (!selectedRowKeys.length) {
      this.close();
      return Promise.resolve();
    }
    return this._debDiretosSEPAModuleService.notificarClientesPorNotificar(this.loteFicheiro, selectedRowKeys).then(() => {
      this._plAlertService.success(this._translateService.instant('debDiretosSEPA.messages.devedoresNotificadosSucesso', {count: selectedRowKeys.length}));
      this.close();
    });
  }
}
