import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {copy, isNumber} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {DocContabilidadeService} from '../../docContabilidade.service';
import {DocsContabilidadeService} from '../../../../service/docsContabilidade.service';
import {ECampoCalculadoME} from '../../../../jsonDocContabilidade.interface';
import {ENTITY_NAME_POCS} from '../../../../../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {IDocContabilidade, IDocContabilidadeLinha, TDocContabilidadeCommandResult} from '../../../../docsContabilidade.interface';
import {IJsonPOC} from '../../../../../../../entities/pocs/jsonPOC.entity.interface';

@Component({
  selector: 'docscontabilidade-edit-moeda-estrangeira-modal',
  templateUrl: './docsContabilidade.edit.moedaEstrangeira.modal.component.html'
})
export class DocsContabilidadeEditMoedaEstrangeiraModalComponent extends CGModalComponent<IDocContabilidade> implements OnInit {
  @Input() public doc: IDocContabilidade;
  @Input() public nLanc: string;
  @Input() public valorComIvaIncluido: boolean;

  public readonly types: typeof ECampoCalculadoME;
  public linha: IDocContabilidadeLinha;
  public form: UntypedFormGroup;
  public conta: string;
  public nomeMoeda: string;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService
  ) {
    super(_injector);
    this.types = ECampoCalculadoME;
  }

  public ngOnInit(): void {
    this.doc = copy<IDocContabilidade>(this.doc);
    this._evaluateLinha();
    this.nomeMoeda = this.linha.nomeMoeda;
    this.promise = this._entityServiceBuilder
      .build<IJsonPOC>(ENTITY_NAME_POCS)
      .get({id: this.linha.nConta})
      .then((response: HttpResponse<IJsonPOC>) => {
        this.conta = `${response.body.nConta} - ${response.body.nome}`;
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  public async close(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    this.disableClose();
    try {
      await this.promise;
      this.enableClose();
      super.close(this.doc);
    } catch (reason: unknown) {
      this.enableClose();
      this._logger.error(reason);
    }
  }

  public doLock(type: ECampoCalculadoME): void {
    this._docContabilidadeService.doLock(this.linha, type);
  }

  public isLocked(type: ECampoCalculadoME): boolean {
    return type === this.linha.campoMeCalculado;
  }

  public getBtnClass(type: ECampoCalculadoME): string {
    return this._docContabilidadeService.getLockButtonClass(this.linha, type);
  }

  public getLockClass(type: ECampoCalculadoME): string {
    return this._docContabilidadeService.getLockClass(this.linha, type);
  }

  public changedValor(value: number): void {
    this._handleResponse(this._docsContabilidadeService.linhaValorChanged(value, this.valorComIvaIncluido, this.linha._index, this.doc));
  }

  public changedValorME(value: number): void {
    this._handleResponse(this._docsContabilidadeService.linhaValorMEChanged(value, this.valorComIvaIncluido, this.linha._index, this.doc));
  }

  public changedCambio(value: number): void {
    this._handleResponse(this._docsContabilidadeService.linhaCambioChanged(value, this.valorComIvaIncluido, this.linha._index, this.doc));
  }

  private _evaluateLinha(): void {
    this.linha = this._docContabilidadeService.getLinha(this.doc, this.nLanc);
    if (!this.linha) {
      throw new Error(`Failed to get linha with nLanc: ${this.nLanc}`);
    }
    if (!isNumber(this.linha.campoMeCalculado)) {
      this.doLock(ECampoCalculadoME.ValorME);
    }
  }

  private _handleResponse(request: TDocContabilidadeCommandResult): void {
    this.promise = (async () => {
      try {
        this.doc = await request;
        this._evaluateLinha();
      } finally {
        this.promise = undefined;
      }
    })();
  }
}
