import moment from 'moment';
import {Observable, of, Subscription, timer} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {Component, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {Logger} from 'pl-comps-angular';
import {AppService} from '../../../../../services/app/app.service';
import {EAppLaunchMode} from '../../../../../../common/site';
import {IAppStatus} from '../../../../../services/app/app.service.interface';
import {SERVICE_WORKER_DELAY} from '../../../../../../config/service.worker.config';

/* eslint-disable @typescript-eslint/no-magic-numbers */

const CHECK_UPDATE_PERIOD: number = moment.duration(5, 'minutes').asMilliseconds();

/* eslint-enable @typescript-eslint/no-magic-numbers */

@Component({
  selector: 'cg-app-status-new-version-available',
  templateUrl: './cg.app.status.new.version.available.component.html'
})
export class CGAppStatusNewVersionAvailableComponent implements OnDestroy {
  public showToast: boolean;

  private readonly _document: Document;
  private readonly _subscriptionStatus: Subscription;
  private _launchMode: EAppLaunchMode;
  private _subscriptionVersionUpdates: Subscription;
  private _subscriptionStartCheckForUpdate: Subscription;
  private _subscriptionCheckForUpdate: Subscription;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _logger: Logger,
    private readonly _appService: AppService,
    private readonly _swUpdate: SwUpdate
  ) {
    this.showToast = false;
    this._document = <Document>document;

    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._launchMode = status.launchMode ?? EAppLaunchMode.Default;

      if (this._launchMode !== EAppLaunchMode.Hybrid && this._launchMode !== EAppLaunchMode.HybridPartial && this._swUpdate.isEnabled) {
        this._subscriptionVersionUpdates = this._swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
          if (event.type === 'VERSION_READY') {
            this.show();
          }
        });

        this._subscriptionStartCheckForUpdate = timer(SERVICE_WORKER_DELAY).subscribe(() => {
          this._subscriptionStartCheckForUpdate = undefined;
          this._startCheckForUpdate();
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
    if (this._subscriptionVersionUpdates) {
      this._subscriptionVersionUpdates.unsubscribe();
    }
    if (this._subscriptionStartCheckForUpdate) {
      this._subscriptionStartCheckForUpdate.unsubscribe();
    }
    this._stopCheckForUpdate();
  }

  public show(): void {
    this.showToast = true;
  }

  public hide(): void {
    this.showToast = false;
  }

  public reloadApplication(): void {
    if (this._document) {
      this._document.defaultView.location.reload();
    } else {
      this._appService.setGlobalLoading(this._swUpdate.activateUpdate());
    }
  }

  private _startCheckForUpdate(): void {
    const checkForUpdate = (): Observable<unknown> =>
      timer(CHECK_UPDATE_PERIOD)
        .pipe(mergeMap(() => this._swUpdate.checkForUpdate()))
        .pipe(
          catchError((error: unknown) => {
            this._logger.error(error);
            return checkForUpdate();
          })
        )
        .pipe(
          mergeMap((newVersionAvailable: boolean) => {
            if (newVersionAvailable) {
              this.show();
              this._stopCheckForUpdate();
              return of();
            }
            return checkForUpdate();
          })
        );

    this._stopCheckForUpdate();

    this._subscriptionCheckForUpdate = checkForUpdate().subscribe();
  }

  private _stopCheckForUpdate(): void {
    if (this._subscriptionCheckForUpdate) {
      this._subscriptionCheckForUpdate.unsubscribe();
      this._subscriptionCheckForUpdate = undefined;
    }
  }
}
