import {TDate} from '../../../../common/dates';
import {IJsonMod10} from '../../../interfaces/jsonMod10';
import {ICGTableData} from '../../../components/cg/table/cg.table.interface';

export enum ERHProceDMRATStatus {
  Inactive = 'rhpdmrInactive',
  Scheduled = 'rhpdmrScheduled',
  Started = 'rhpdmrStarted',
  Ended = 'rhpdmrEnded',
  Timeout = 'rhpdmrTimeout',
  Error = 'rhpdmrError'
}

export interface IRHProceDMRATStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: ERHProceDMRATStatus;
  description: string;
  position: number;
  max: number;
}

export interface IRHProceDMRATModel {
  empresas: Array<string>;
  datas: Array<IRHProceDMRATDataItem>;
  reProcList: Array<IRHProceDMRATReProcItem>;
  processaNovamente: boolean;
  decleracaoSubstiticao: boolean;
}

export interface IRHProceDMRATDataItem {
  mes: number;
  ano: number;
  selected: boolean;
  descricao: string;
}

export interface IRHProceDMRATProcessStatus {
  max: number;
  position: number;
  description: string;
}

export enum ERHProceDMRATSteps {
  INTRO = 'RH_PROCE_DMR_AT_INTRO',
  EMPRESAS_SEL = 'RH_PROCE_DMR_AT_EMPRESAS_SEL',
  PROC_CONFIG = 'RH_PROCE_DMR_AT_CONFIG',
  PROC = 'RH_PROCE_DMR_AT_PROC',
  PROC_RESULTS = 'RH_PROCE_DMR_AT_PROC_RESULTS'
}

export interface IRHProceDMRATProcessExistentesItem {
  _index?: number;
  selected: boolean;
  empresa: string;
  nomeEmpresa: string;
  dataProcessamento: TDate;
  dmrMes: number;
  dmrAno: number;
  tipoProcessamento: number;
  tipoProcessamentoDescricao: string;
  nProcessamento: number;
}

export interface IRHProceDMRATProcessExistentesParams {
  empresas: Array<string>;
  datas: Array<IRHProceDMRATDataItem>;
}

export interface IRHProceDMRATReProcItem {
  empresa: string;
  dmrMes: number;
  dmrAno: number;
  nProcessamento: number;
}

export interface IRHProceDMRATErroItem {
  nEmpresa: string;
  erro: string;
  queFazer: string;
}

export interface IRHProceDMRATItem {
  ssNEMPRESA: string;
  ssNOMEEMPRESA: string;
  mes: number;
  ano: number;
  totalValorRendimentos: number;
  totalValorRetencaoIRS: number;
  totalValorContribuicoesObrigatorias: number;
  totalValorQuotizacaoSindicais: number;
  totalValorRetencaoSobretaxa: number;
  totalValores: number;
  infoDeclaracao: number;
  erros: number;
  wsMensagemErro: string;
  wsEstadoFicheiro: number;
  wsEstadoFicheiroStr: string;
  isSubstituicao: boolean;
  statusImage?: string;
}

export interface IRHProceDMRATDMRATList {
  list: Array<IRHProceDMRATItem>;
  erros: Array<IRHProceDMRATErroItem>;
}

export interface IRHProceDMRATControloItem {
  origemRegisto: number;
  tipoRegisto: number;
  nif: string;
  codEmp: number;
  nome: string;
  abonosRendimentosSujeitos: number;
  abonosRendimentosNaoSujeitos: number;
  descontosContribuicoesObrigatorias: number;
  descontosIRS: number;
  descontosSindicatos: number;
  descontosSobretaxa: number;
  abonosOutros: number;
  descontosOutros: number;
  liquidoCalculado: number;
  liquidoVencimentos: number;
  rendimentosAnoAnterior: number;
  descricaoAbono: string;
  erro: number;
  erroDescricao: string;
  statusImage?: string;
  resumo: Array<IJsonMod10>;
  resumoSource?: ICGTableData<IJsonMod10>;
}

export interface IRHProceDMRAT006Item {
  tipoRegisto: string;
  nifSujeitoPassivo: string;
  numeroLinha: number;
  rendAnoAnteriorValores: number;
  rendAnoAnteriorAno: number;
  rendimentodoano: number;
  tipoRendimento: string;
  localObtencaoRend: string;
  retencaoIRS: number;
  contribuicosObrigatoriasValores: number;
  contribuicosObrigatoriasNIF1: string;
  contribuicosObrigatoriasNIF2: string;
  contribuicosObrigatoriasNIF3: string;
  quotizacaoSindical: number;
  retencaoSobretaxa: number;
  codEmp: number;
  nome: number;
}

export interface IRHProceDMRATSendWebService {
  isEstadoComErros: boolean;
}

export interface IRHProceDMRATViewInfoResults {
  controloAutoList: Array<IRHProceDMRATControloItem>;
  controloManualList: Array<IRHProceDMRATControloItem>;
  controloTotalList: Array<IRHProceDMRAT006Item>;
  list: Array<IJsonMod10>;
}

export const MODULE_NAME_PROCE_DMR_AT = 'proceDMRAT';
