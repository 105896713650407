import {MODULE_NAME_MODELO_30} from './modelo30.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {Modelo30ModuleComponent} from './components/modelo30.module.component';
import {ROLE} from '../../../services/role.const';
import {HttpResponse} from '@angular/common/http';
import {Modelo30Service} from './modelo30.module.service';
import {IJsonJustosImpedimentos, IJsonModelo30, IJsonRegimesTributacao} from './jsonModelo30.module.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {IJsonPeriodo} from '../../../entities/periodos/jsonPeriodo.entity.interface';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../entities/periodos/periodos.entity.interface';
import {ENTITY_NAME_RENDIS} from '../../../entities/rendis/rendis.entity.interface';
import {ERendiTipoDeclaracao, IJsonRENDI} from '../../../entities/rendis/jsonRendi.entity.interface';

export const MODULE_MODELO_30: IModuleDefinition = {
  name: MODULE_NAME_MODELO_30,
  state: {
    url: '/modelo30',
    component: Modelo30ModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.modelo30'
    },
    resolve: [
      {
        provide: 'modelo30',
        deps: [Modelo30Service],
        useFactory: (modelo30Service: Modelo30Service): Promise<IJsonModelo30> => {
          return modelo30Service.getModelo30().then((response: HttpResponse<IJsonModelo30>) => response.body);
        }
      },
      {
        provide: 'periodos',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPeriodo>> => {
          const periodosService: IEntityService<IJsonPeriodo> = entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
          return periodosService.query().then((response: THttpQueryResponse<IJsonPeriodo>) => response.body.list);
        }
      },
      {
        provide: 'justosImpedimentos',
        deps: [Modelo30Service],
        useFactory: (modelo30Service: Modelo30Service): Promise<Array<IJsonJustosImpedimentos>> => {
          return modelo30Service.getJustosImpedimentos().then((response: HttpResponse<Array<IJsonJustosImpedimentos>>) => response.body);
        }
      },
      {
        provide: 'regimesTributacao',
        deps: [Modelo30Service],
        useFactory: (modelo30Service: Modelo30Service): Promise<Array<IJsonRegimesTributacao>> => {
          return modelo30Service.getRegimesTributacao().then((response: HttpResponse<Array<IJsonRegimesTributacao>>) => response.body);
        }
      },
      {
        provide: 'rendimentos',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonRENDI>> => {
          const rendiService: IEntityService<IJsonRENDI> = entityServiceBuilder.build<IJsonRENDI>(ENTITY_NAME_RENDIS);
          return rendiService.query({pesquisa: `tipoDeclaracao=${<number>ERendiTipoDeclaracao.Modelo30}`}).then((response: THttpQueryResponse<IJsonRENDI>) => response.body.list);
        }
      }
    ]
  }
};
