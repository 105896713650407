<div class="imp-avisos-cobranca" [plPromise]="promise">
  <pl-form class="entity-detail-form">
    <pl-group>
      <pl-group>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-edit type="reports_imp_avisos_cobranca" attrName="listagem" [(model)]="report" [properties]="{disallowClear: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <pl-group>
          <label [translate]="'avisoscobranca.fields.nContaDe'"></label>
          <edit>
            <entity-autocomplete entity="clientes" attrName="nContaDe" [selectedKey]="filters.ncontade" (selectedKeyChange)="onNContaDeChanged($event)" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'global.text.to'"></label>
          <edit>
            <entity-autocomplete entity="clientes" attrName="nContaAte" [(selectedKey)]="filters.ncontaate" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group>
        <label [translate]="'avisoscobranca.fields.dataVencimentoDe'"></label>
        <edit>
          <pl-edit-datepicker attrName="dataVenc" [(model)]="filters.datavencimentode"></pl-edit-datepicker>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <pl-edit-datepicker attrName="dataVenc" [(model)]="filters.datavencimentoate"></pl-edit-datepicker>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'avisoscobranca.fields.dataDocDe'"></label>
        <edit>
          <pl-edit-datepicker attrName="dataVenc" [(model)]="filters.datadocde"></pl-edit-datepicker>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <pl-edit-datepicker attrName="dataVenc" [(model)]="filters.datadocate"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group>
        <label [translate]="'avisoscobranca.fields.nVendedorDe'"></label>
        <edit>
          <entity-autocomplete entity="vendedores" attrName="nVendedorDe" [(selectedKey)]="filters.nvendedorde" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="vendedores" attrName="nVendedorAte" [(selectedKey)]="filters.nvendedorate" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'avisoscobranca.fields.nCondPagamDe'"></label>
        <edit>
          <entity-autocomplete entity="condicoespagamento" attrName="nCondPagamDe" [(selectedKey)]="filters.ncondpagamde" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="condicoespagamento" attrName="nCondPagamAte" [(selectedKey)]="filters.ncondpagamate" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>

  <hr />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
