import {Injectable, Type} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CGCModalComponent} from '../components/modal.component';
import {CGCModalOkCancelComponent} from '../components/okcancel/okcancel.modal.component';
import type {ICGCModalComponent, ICGCModalOptions, ICGCOkCancelModalOptions} from './modal.service.interface';
import {isFunction, isObject, isString} from '../../common/utilities/utilities';

export const CGC_MODAL_CLASS = 'cgc-modal';

@Injectable({
  providedIn: 'root'
})
export class CGCModalService {
  private readonly _defaultOptions: Readonly<ICGCModalOptions>;

  constructor(private readonly _ngbModal: NgbModal) {
    this._defaultOptions = Object.freeze({
      size: 'xl'
    });
  }

  public showVanilla(content: Type<CGCModalComponent<any>>, options?: ICGCModalOptions): NgbModalRef {
    const properties: ICGCModalOptions = {...this._defaultOptions, ...options};

    const windowClass = isString(properties.windowClass) ? properties.windowClass.split(' ') : [];
    windowClass.push('disable-animations');
    windowClass.push(CGC_MODAL_CLASS);
    if (properties.size) {
      windowClass.push(`${CGC_MODAL_CLASS}-${properties.size}`);
    }
    properties.windowClass = windowClass.join(' ');

    const modalRef = this._ngbModal.open(
      content,
      (() => {
        properties.beforeDismiss = () => this._beforeDismissHandler(modalRef, options);
        return properties;
      })()
    );
    modalRef.componentInstance.modalOptions = properties;
    modalRef.result
      .then((result: unknown) => {
        if (properties?.evtClosed) {
          properties.evtClosed.emit(result);
        }
      })
      .catch((reason: unknown) => {
        if (properties?.evtDismissed) {
          properties.evtDismissed.emit(reason);
        }
      });
    return modalRef;
  }

  public show<T = unknown>(content: Type<CGCModalComponent<T>>, options?: ICGCModalOptions): Promise<T> {
    return <Promise<T>>this.showVanilla(content, options).result;
  }

  public dismissAll(reason?: unknown): void {
    this._ngbModal.dismissAll(reason);
  }

  public hasOpenModals(): boolean {
    return this._ngbModal.hasOpenModals();
  }

  public showOkCancelVanilla(title: string, message: string, options?: ICGCOkCancelModalOptions): NgbModalRef {
    const modalRef = this.showVanilla(CGCModalOkCancelComponent, {size: 'lg', ...options});
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    if (isObject(options)) {
      modalRef.componentInstance.type = options.type;
      modalRef.componentInstance.btnOkText = options.btnOkText;
      modalRef.componentInstance.btnOkIcon = options.btnOkIcon;
      modalRef.componentInstance.btnOkCssClass = options.btnOkCssClass;
      modalRef.componentInstance.btnCancelText = options.btnCancelText;
      modalRef.componentInstance.btnCancelIcon = options.btnCancelIcon;
      modalRef.componentInstance.btnCancelCssClass = options.btnCancelCssClass;
    }
    return modalRef;
  }

  public showOkCancel(title: string, message: string, options?: ICGCOkCancelModalOptions): Promise<void> {
    return <Promise<void>>this.showOkCancelVanilla(title, message, options).result;
  }

  private async _beforeDismissHandler(modalRef: NgbModalRef, options: ICGCModalOptions): Promise<boolean> {
    const instance: ICGCModalComponent<unknown> = modalRef.componentInstance;
    let result: boolean = await instance.beforeDismiss();
    if (result === false) {
      return false;
    }
    if (isObject(options) && isFunction(options.beforeDismiss)) {
      result = await options.beforeDismiss();
    }
    return result === false ? result : !instance.closeDisabled;
  }
}
