<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.modalTitleConfigClientes'"></h4>
</div>

<div class="modal-body">
  <pl-group>
    <label [translate]="'debDiretosSEPA.fields.nConta'"></label>
    <edit>
      <entity-autocomplete
        entity="clifos"
        [cacheValues]="false"
        [model]="model"
        (modelChange)="nContaChange($event)"
        (selectedKeyChange)="model.nConta = $event"
        (evtSelectedDescriptionChanged)="model.nome = $event"
        [outputKey]="'nConta'"
        [properties]="{disabled: editMode, allowInvalid: false}">
      </entity-autocomplete>
    </edit>
  </pl-group>

  <ng-container [plPromise]="nContaChangePromise" *ngIf="model.nConta?.length">
    <pl-group>
      <pl-group>
        <label [translate]="'global.form.email'"></label>
        <edit>
          <pl-edit type="string" [(model)]="model.emailDD"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'debDiretosSEPA.fields.iban'"></label>
        <edit>
          <pl-edit type="string" [(model)]="model.iban"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'debDiretosSEPA.strings.contaCredora'"></label>
        <edit>
          <!-- [model]="ibanBancoCredorStr" -->
          <pl-select
            [source]="ibanBancoCredorSource"
            [model]="ibanBancoCredorStr"
            (evtChanged)="setIBANDD($event.item)"
            [rowTemplate]="ibanBancoCredorTemplate"
            [output]="ibanBancoCredorOutput"
            [clearable]="false">
          </pl-select>
        </edit>
      </pl-group>

      <pl-group>
        <label>
          <span [translate]="'debDiretosSEPA.fields.nAutorizacao'"></span>
          <pl-tooltip [config]="{text: 'debDiretosSEPA.tooltips.geradoAutomatico', container: 'body'}">
            <i *plTooltipContent class="fa fa-fw fa-info-circle" aria-hidden="true"></i>
          </pl-tooltip>
        </label>
        <edit>
          <pl-edit type="string" [(model)]="model.nAutorizacao" [properties]="{disabled: !nAutorizacaoInEditMode, validators: {required: {value: true}}}"></pl-edit>
        </edit>
        <actions>
          <pl-button [disabled]="nAutorizacaoInEditMode" klass="btn btn-sm btn-outline-warning" (evtClicked)="nAutorizacaoInEditMode = true">
            <i class="fa fa-fw fa-pencil"></i>
          </pl-button>
        </actions>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label>
          <span [translate]="'debDiretosSEPA.fields.dataInicio'"></span>
          <pl-tooltip [config]="{text: 'debDiretosSEPA.tooltips.atencaoSoFicaraoDisponiveisDataDocSuperiorADataIni', container: 'body'}">
            <i *plTooltipContent class="fa fa-fw fa-info-circle" aria-hidden="true"></i>
          </pl-tooltip>
        </label>
        <edit>
          <pl-edit type="date" [model]="model.dataInicio" (modelChange)="dataInicioChange($event)">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="documentoPorLiquidarAteDataTooltipText.length" [config]="{text: documentoPorLiquidarAteDataTooltipText, tooltipClass: 'tooltip-warning', container: 'body'}">
                <i *plTooltipContent class="fa fa-fw fa-exclamation-triangle text-warning" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'debDiretosSEPA.fields.fim'"></label>
        <edit>
          <pl-edit type="date" [(model)]="model.dataFim"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.documentoAutorizacao'"></h5>
    <pl-alert type="warning" [closeable]="false" *ngIf="!model.temAutorizacaoDebitosDiretos">
      <ng-container><i class="fa fa-fw fa-info-circle me-1" aria-hidden="true"></i></ng-container>
      <ng-container>
        <span [translate]="'debDiretosSEPA.messages.naoTemAutorizacaoAnexada'"></span>
      </ng-container>
    </pl-alert>

    <div class="d-flex align-items-center mb-3" style="gap: 5px">
      <input type="file" accept=".pdf" style="display: none" (change)="addAutorizacaoFile($event)" #fileInput />

      <ng-container *ngIf="!model.temAutorizacaoDebitosDiretos">
        <pl-button klass="btn-warning btn-sm" (evtClicked)="cancelAutorizacaoFile()" *ngIf="isAutorizacaoFilePendingUpload()">
          <i class="fa fa-fw fa-ban me-1" aria-hidden="true"></i><span [translate]="'global.btn.cancel'"></span>
        </pl-button>

        <pl-button klass="btn-success btn-sm" (evtClicked)="fileInput.click(); fileInput.value = ''" *ngIf="!isAutorizacaoFilePendingUpload()">
          <i class="fa fa-fw fa-cloud-upload me-1" aria-hidden="true"></i><span [translate]="'global.btn.attach'"></span>
        </pl-button>
      </ng-container>

      <pl-button klass="btn-primary btn-sm" (evtClicked)="consultarAutorizacao()">
        <i class="fa fa-fw fa-file-text-o me-1" aria-hidden="true"></i><span [translate]="'debDiretosSEPA.strings.consultar'"></span>
      </pl-button>

      <pl-button klass="btn-danger btn-sm" (evtClicked)="apagarAutorizacao()"><i class="fa fa-fw fa-trash-o" aria-hidden="true"></i><span [translate]="'global.btn.delete'"></span></pl-button>
    </div>

    <h5 class="ddsepa-title" [translate]="'contratosservicos.title_plural'"></h5>
    <pl-group>
      <edit>
        <pl-select
          [source]="contratosSource"
          [multipleCheckbox]="true"
          [(model)]="contratosModel"
          [rowTemplate]="contratosTemplate"
          [output]="contratosOutput"
          [valueField]="'refCtrCAB'"
          [multiple]="true">
        </pl-select>
      </edit>
    </pl-group>

    <pl-group class="saft-inline-block pl-group-no-label">
      <edit>
        <pl-edit type="checkbox" [(model)]="model.firstEnviado" [properties]="{label: 'debDiretosSEPA.strings.jaFoiEnviadaPrimeiraComunicacaoFRST'}"></pl-edit>
      </edit>
    </pl-group>
  </ng-container>
</div>

<div class="modal-footer" plNavigation>
  <pl-button klass="btn btn-sm btn-success" [plPromise]="nContaChangePromise" (evtClicked)="showClientPrintModal()" [disabled]="!isEnabledObterAutorizacao()">
    <i class="fa fa-fw fa-print"></i>&nbsp;<span [translate]="'debDiretosSEPA.strings.obterAutorizacao'"></span>
  </pl-button>
  <pl-button klass="btn btn-sm btn-primary" [plPromise]="nContaChangePromise" (evtClicked)="closeModal()">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
  </pl-button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
