<div class="entity-detail-form">
  <pl-button klass="btn-success btn-sm" id="processar" [onClick]="fnRefreshData" *ngIf="!hideRefresh">
    <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'global.btn.update'"></span>
  </pl-button>

  <dx-data-grid [cgDxDataGrid]="dataGrid" cgDxDataGridInstanceName="emails" [dataSource]="dataGrid.dataSource" (onInitialized)="onDataGridInitializedEmails($event)">
    <div *dxTemplate="let itemAction of 'toolbarAddEmail'">
      <pl-button *ngIf="!readonly" klass="btn-primary btn-sm action-add-line" (evtClicked)="addNew()"><i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'global.btn.new'"></span></pl-button>
    </div>

    <div *dxTemplate="let item of 'actions'" class="text-center">
      <pl-button *ngIf="!readonly" klass="btn-warning btn-xs action-edit-line me-1" [attr.title]="'clifoExtraValues.tooltips.editTypeEmail' | translate" [onClick]="fnAddNew(item.data)">
        <i class="fa fa-fw fa-pencil"></i>
      </pl-button>

      <pl-button *ngIf="!readonly" klass="btn-danger btn-xs action-delete" [attr.title]="'clifoExtraValues.tooltips.removeTypeEmail' | translate" [onClick]="fnDelete(item.data)">
        <i class="fa fa-fw fa-trash"></i>
      </pl-button>
    </div>
  </dx-data-grid>
</div>
