import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import type {IPlLocale} from '../common/locale/locales.interface';
import {isBoolean, isNumber} from '../common/utilities/utilities';
import {PlLocaleService} from '../common/locale/locale.service';
import type {TPlAlertStickyType} from './alert.interface';

const KLASS_ATTENTION_SEEKER = 'pl-alert-sticky-attention-seeker';

@Component({
  selector: 'pl-alert',
  templateUrl: './alert.component.html',
  standalone: false
})
export class PlAlertComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public type: TPlAlertStickyType;
  @Input() public theme: string;
  @Input() public showing: boolean;
  @Input() public closeable: boolean;
  @Input() public autoClose: boolean;
  @Input() public closeDelay: number;
  @Input() public seekAttention: boolean;
  @Input() public attentionSeeker: string;
  @Output() public readonly showingChange: EventEmitter<boolean>;
  @Output() public readonly evtClosed: EventEmitter<void>;

  public locale: IPlLocale;

  private readonly _subscriptionLocale: Subscription;
  private _intervalAutoClose: number;

  constructor(private readonly _plLocaleService: PlLocaleService) {
    this.showingChange = new EventEmitter<boolean>();
    this.evtClosed = new EventEmitter<void>();
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({showing, closeable, autoClose, closeDelay, seekAttention, attentionSeeker}: SimpleChanges): void {
    if (closeable && !closeable.isFirstChange()) {
      this._changedCloseable(closeable.currentValue);
    }
    if (autoClose && !autoClose.isFirstChange()) {
      this._changedAutoClose(autoClose.currentValue);
    }
    if (closeDelay && !closeDelay.isFirstChange()) {
      this._changedCloseDelay(closeDelay.currentValue);
    }
    if (seekAttention && !seekAttention.isFirstChange()) {
      this._changedSeekAttention(seekAttention.currentValue);
    }
    if (attentionSeeker && !attentionSeeker.isFirstChange()) {
      this._changedAttentionSeeker(attentionSeeker.currentValue);
    }
    if (showing && !showing.isFirstChange()) {
      this._changedShowing(showing.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._clearAutoClose();
  }

  public close(): void {
    this.evtClosed.emit();
    this.showing = false;
    this.showingChange.emit(this.showing);
  }

  private _handleChanges(): void {
    this._changedCloseable();
    this._changedAutoClose();
    this._changedCloseDelay();
    this._changedSeekAttention();
    this._changedAttentionSeeker();
    this._changedShowing();
  }

  private _changedCloseable(value: boolean = this.closeable): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.closeable = val;
  }

  private _changedAutoClose(value: boolean = this.autoClose): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.autoClose = val;
  }

  private _changedCloseDelay(value: number = this.closeDelay): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = 0;
    }
    this.closeDelay = val;
  }

  private _changedSeekAttention(value: boolean = this.seekAttention): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.seekAttention = val;
  }

  private _changedAttentionSeeker(value: string = this.attentionSeeker): void {
    this.attentionSeeker = value || KLASS_ATTENTION_SEEKER;
  }

  private _changedShowing(value: boolean = this.showing): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.showing = val;
    if (this.showing && this.autoClose && this.closeable) {
      this._queueAutoClose();
    }
  }

  private _queueAutoClose(): void {
    this._clearAutoClose();
    this._intervalAutoClose = window.setInterval(() => {
      this.close();
    }, this.closeDelay);
  }

  private _clearAutoClose(): void {
    if (this._intervalAutoClose) {
      window.clearInterval(this._intervalAutoClose);
      this._intervalAutoClose = undefined;
    }
  }
}
