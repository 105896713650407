import {deepFreeze} from 'pl-comps-angular';
import {ERHAgenciasTipoInstalacao, IJsonRHAgenciasAgencia} from './jsonRHAgencias.module.interface';
import {IJsonBanco} from '../../../entities/bancos/jsonBanco.entity.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';

export interface IRHAgenciasBanco extends IJsonBanco {
  agenciasList: Array<IJsonRHAgenciasAgencia>;
}

export const RADIO_GROUP_RH_AGENCIAS_TIPO_INSTALACAO: IRadioGroup<ERHAgenciasTipoInstalacao> = deepFreeze<IRadioGroup<ERHAgenciasTipoInstalacao>>({
  groupItems: [
    {value: ERHAgenciasTipoInstalacao.Sede, label: 'rhagencias.modal.agencia.fields.sede'},
    {value: ERHAgenciasTipoInstalacao.Agencia, label: 'rhagencias.modal.agencia.fields.agencia'}
  ]
});
