import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {copy, isDefinedNotNull} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {IJsonModelo22, IJsonModelo22AnexoG} from '../../../jsonModelo22.module.interface';
import {Modelo22AnexoGModalComponent} from './modals/modelo22.anexoG.modal.component';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {maxDateCG, maxDateCGD, minDateCG, minDateCGD} from '../../../../../../../common/utils/utils';
import {HttpResponse} from '@angular/common/http';
import {Modelo22Service} from '../../../modelo22.module.service';

@Component({
  selector: 'modelo22-anexos-anexog',
  templateUrl: './modelo22.anexos.anexoG.component.html'
})
export class Modelo22AnexosAnexoGComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly dataGridDefinitionLista032: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro04: IDevExpressDataGrid;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
    this.dataGridDefinitionLista032 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoG.quadro03.table.fields.perio'},
        {dataField: 'montanteDedutiveis', dataType: 'double', caption: 'modelo22.anexoG.quadro03.table.fields.montprejregespecial'},
        {dataField: 'montanteDeduzidos', dataType: 'double', caption: 'modelo22.anexoG.quadro03.table.fields.montprejperio'},
        {dataField: 'saldo', dataType: 'double', caption: 'modelo22.anexoG.quadro03.table.fields.saldo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnLista032'}
      ],
      dataSource: [],
      export: {filename: 'AnexoG_032'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoGQuadro3())
    };
    this.dataGridDefinitionQuadro04 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nIdentificacao', dataType: 'string', caption: 'modelo22.anexoG.quadro04.table.fields.field1'},
        {dataField: 'paisDeRegisto', dataType: 'string', caption: 'modelo22.anexoG.quadro04.table.fields.field2'},
        {dataField: 'regime', dataType: 'string', caption: 'modelo22.anexoG.quadro04.table.fields.field3'},
        {dataField: 'paisDeGestao', dataType: 'string', caption: 'modelo22.anexoG.quadro04.table.fields.field4'},
        {dataField: 'percTripulantes', dataType: 'double', caption: 'modelo22.anexoG.quadro04.table.fields.field5'},
        {dataField: 'arqueacao', dataType: 'double', caption: 'modelo22.anexoG.quadro04.table.fields.field6'},
        {dataField: 'percRendimentos', dataType: 'double', caption: 'modelo22.anexoG.quadro04.table.fields.field7'},
        {dataField: 'numeroDias', dataType: 'number', caption: 'modelo22.anexoG.quadro04.table.fields.field8'},
        {dataField: 'reducaoMateria', dataType: 'double', caption: 'modelo22.anexoG.quadro04.table.fields.field9'},
        {dataField: 'materiaColectavel', dataType: 'double', caption: 'modelo22.anexoG.quadro04.table.fields.field10'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnQuadro04'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoG.quadro04.desc'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoGQuadro4())
    };
  }

  public ngOnInit(): void {
    this._evaluateAnexoG();
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
      this._evaluateAnexoG();
    }
  }

  public addLineAnexoGQuadro3(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoGModalComponent);
    const componentInstance: Modelo22AnexoGModalComponent = modalInstance.componentInstance;
    componentInstance.anexoG = copy(this.anexoG);
    componentInstance.newLineFor = 'quadro3';
    return modalInstance.result.then((result: IJsonModelo22AnexoG) => {
      this._applyAnexoG(result);
    });
  }

  public deleteLineAnexoGQuadro3(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.anexoG.quadro03.lista032.splice(line, 1);
      this._calcQuadro3Field10();
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoGQuadro4(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoGModalComponent);
    const componentInstance: Modelo22AnexoGModalComponent = modalInstance.componentInstance;
    componentInstance.anexoG = copy(this.anexoG);
    componentInstance.newLineFor = 'quadro4';
    return modalInstance.result.then((result: IJsonModelo22AnexoG) => {
      this._applyAnexoG(result);
    });
  }

  public deleteLineAnexoGQuadro4(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.anexoG.quadro04.lista.splice(line, 1);
      this._setListIndexes();
      this._calcQuadro4Field11();
      this.emitModelo22();
    });
  }

  public async emitModelo22(evaluateModelo22: boolean = false): Promise<void> {
    if (evaluateModelo22) {
      await this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        this.modelo22 = response.body;
      });
    }
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public get anexoG(): IJsonModelo22AnexoG {
    return this.modelo22.anexoG;
  }

  public set anexoG(value: IJsonModelo22AnexoG) {
    this.modelo22.anexoG = value;
  }

  private _applyAnexoG(anexoG: IJsonModelo22AnexoG): void {
    this.anexoG = merge(this.anexoG, anexoG);
    this._clearCGDatesDefault();
    this._evaluateAnexoG();
    this.emitModelo22();
  }

  private _evaluateAnexoG(): void {
    this._setListIndexes();
    this._calcQuadro3Field10();
    this._calcQuadro4Field11();
  }

  private _setListIndexes(): void {
    let index32 = 1;
    for (const listElement of this.anexoG.quadro03.lista032) {
      listElement._index = index32;
      index32++;
    }
    this.dataGridDefinitionLista032.dataSource = this.anexoG.quadro03.lista032;

    let index = 1;
    for (const listElement of this.anexoG.quadro04.lista) {
      listElement._index = index;
      index++;
    }
    this.dataGridDefinitionQuadro04.dataSource = this.anexoG.quadro04.lista;
  }

  private _calcQuadro3Field10(): void {
    this.anexoG.quadro03.campo10 = 0;
    for (const item of this.anexoG.quadro03.lista032) {
      this.anexoG.quadro03.campo10 += item.montanteDeduzidos;
    }
  }

  private _calcQuadro4Field11(): void {
    this.anexoG.quadro04.campo11 = 0;
    for (const item of this.anexoG.quadro04.lista) {
      this.anexoG.quadro04.campo11 += item.materiaColectavel;
    }
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _clearCGDatesDefault(): void {
    if (
      (isDefinedNotNull(this.anexoG.quadro06.campo01) && moment(this.anexoG.quadro06.campo01).isSameOrBefore(minDateCG(), 'date')) ||
      moment(this.anexoG.quadro06.campo01).isSameOrBefore(minDateCGD(), 'date') ||
      moment(this.anexoG.quadro06.campo01).isSameOrAfter(maxDateCG(), 'date') ||
      moment(this.anexoG.quadro06.campo01).isSameOrAfter(maxDateCGD(), 'date')
    ) {
      this.anexoG.quadro06.campo01 = undefined;
    }
  }
}
