import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHDGEMPRequiredFields} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CONFIGURACOES_INTEGRACAO_SALARIOS} from '../rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {RHIntegracaoSalariosModuleComponent} from './components/rhIntegracaoSalarios.module.component';

export const MODULE_RH_CONFIGURACOES_INTEGRACAO_SALARIOS: IModuleDefinition = {
  name: MODULE_NAME_RH_CONFIGURACOES_INTEGRACAO_SALARIOS,
  state: {
    url: `/${MODULE_NAME_RH_CONFIGURACOES_INTEGRACAO_SALARIOS}`,
    component: RHIntegracaoSalariosModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.rhassistentetrabalhador'
    },
    resolve: [
      {
        provide: 'model',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHDGEMPRequiredFields> => {
          return rhConfiguracoesService.getRequiredFields().then((response: HttpResponse<IJsonConfigRHDGEMPRequiredFields>) => response.body);
        }
      }
    ]
  }
};
