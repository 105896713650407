<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-multiselect">
  <div class="pl-multiselect-table table-responsive">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>{{ leftCaption }}</th>
          <th></th>
          <th>{{ rightCaption }}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <select class="form-control multiselect-source" multiple [formControl]="rights">
              <ng-container *ngFor="let item of filteredSource; index as i">
                <option *ngIf="item.hidden !== true && item.visible !== false" [ngValue]="item[key] || item" (dblclick)="selectedRight(item)">{{ rows[item[key]] || rows[i] || getRow(item) }}</option>
              </ng-container>
            </select>
          </td>

          <td class="d-grid gap-2 pl-multiselect-controls">
            <button type="button" class="btn btn-primary btn-sm action-all-right" [disabled]="options.disabled" (click)="allRight()">
              <i class="fa fa-fw fa-angle-double-right"></i>
            </button>
            <button type="button" class="btn btn-primary btn-sm action-selected-right" [disabled]="options.disabled" (click)="selectedRight()">
              <i class="fa fa-fw fa-angle-right"></i>
            </button>
            <button type="button" class="btn btn-primary btn-sm action-selected-left" [disabled]="options.disabled" (click)="selectedLeft()">
              <i class="fa fa-fw fa-angle-left"></i>
            </button>
            <button type="button" class="btn btn-primary btn-sm action-all-left" [disabled]="options.disabled" (click)="allLeft()">
              <i class="fa fa-fw fa-angle-double-left"></i>
            </button>
          </td>

          <td>
            <select class="form-control multiselect-model" multiple [formControl]="lefts">
              <ng-container *ngFor="let item of filteredValue; index as i">
                <option *ngIf="item.hidden !== true && item.visible !== false" [ngValue]="item[key] || item" (dblclick)="selectedLeft(item)">{{ rows[item[key]] || rows[i] || getRow(item) }}</option>
              </ng-container>
            </select>
          </td>
        </tr>

        <tr>
          <td>
            <input
              class="form-control pl-multiselect-input multiselect-filter-source"
              autocapitalize="off"
              autocomplete="off"
              [ngModel]="leftSearch"
              [attr.placeholder]="leftPlaceholder"
              (ngModelChange)="filterLeft($event)" />
          </td>
          <td></td>
          <td>
            <input
              class="form-control pl-multiselect-input multiselect-filter-model"
              autocapitalize="off"
              autocomplete="off"
              [ngModel]="rightSearch"
              [attr.placeholder]="rightPlaceholder"
              (ngModelChange)="filterRight($event)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="validate" class="pl-multiselect-validators">
    <select class="invisible pl-multiselect-validator" multiple [formControl]="formControl"></select>
    <pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
    </pl-messages>
  </div>
</div>
