import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_EXCECAO_CRABD, ETipoExcecaoCRABD} from './tipoExcecaoCRABD.datasource.interface';

export const DATA_SOURCE_TIPO_EXCECAO_CRABD: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_EXCECAO_CRABD,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoExcecaoCRABD.SemExcecoes, name: 'tipoExcecaoCRABD.data.SemExcecoes'},
    {value: ETipoExcecaoCRABD.ExcluirEmpregados, name: 'tipoExcecaoCRABD.data.ExcluirEmpregados'},
    {value: ETipoExcecaoCRABD.IncluirEmpregados, name: 'tipoExcecaoCRABD.data.IncluirEmpregados'}
  ]
});
