<config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="toolbarInstance"></config-options>
<div class="cg-ui-tree">
  <div class="row">
    <div class="col-sm-7">
      <div class="info"><span [translate]="'portals.module.menu'"></span></div>
      <div class="angular-ui-tree">
        <pl-ui-tree-drag
          [uiTree]="modulos"
          [disabled]="!editing"
          [maxLevels]="2"
          [templateContent]="templateNodeContentModulos"
          (uiTreeChange)="modulos = $event; menuChanged()"
          (evtDropped)="droppedNode($event.nodeItem)">
        </pl-ui-tree-drag>
      </div>
    </div>

    <div class="col-sm-5" *ngIf="editing">
      <div class="sticky-top">
        <div class="info">
          <span [translate]="'portals.module.modulosdisponiveis'"></span>
          <br />
          <pl-edit type="string" attrName="portal-filter" [model]="filter" (modelChange)="filterModulosDisponiveis($event)"></pl-edit>
        </div>

        <br />

        <div class="modulos-disponiveis">
          <pl-ui-tree-drag [uiTree]="modulosDisponiveis" [disabled]="!editing" [noDrop]="true" [cloneEnabled]="true" [templateContent]="templateNodeContentModulosDisponiveis"></pl-ui-tree-drag>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateNodeContentModulos let-node="node" let-meta="meta" let-self="self">
  <ng-container *ngTemplateOutlet="templateModulos; context: {allowEditing: true, node: node, meta: meta, self: self}"></ng-container>
</ng-template>

<ng-template #templateNodeContentModulosDisponiveis let-meta="meta">
  <ng-container *ngTemplateOutlet="templateModulos; context: {allowEditing: false, meta: meta}"></ng-container>
</ng-template>

<ng-template #templateModulos let-node="node" let-meta="meta" let-self="self" let-allowEditing="allowEditing">
  <div class="tree-node-icon" [class.align-self-start]="!node || !node.items">
    <i class="d-inline-block" [cgcIcon]="meta.icon"></i>
  </div>

  <div class="d-flex flex-wrap flex-grow-1" style="row-gap: 0.5rem">
    <div class="d-flex flex-column flex-grow-1">
      <div class="tree-node-page-title">
        <span class="tree-node-title-value">{{ meta.pageTitle.translatedTitle }}</span>
        <span class="tree-node-title-original" *ngIf="meta.name && meta.pageTitle.translatedTitle !== meta.pageTitle.translatedOriginal">&nbsp;({{ meta.pageTitle.translatedOriginal }})</span>
        <span class="tree-node-module-name text-nowrap" *ngIf="showModuleName && meta.name">
          "<button type="button" class="btn btn-link p-0 tree-node-name" [ngbTooltip]="'portals.btn.copy' | translate" (click)="copyToClipboard(meta.name)">{{ meta.name }}</button>"
        </span>
      </div>

      <div
        class="tree-node-sidebar-title"
        *ngIf="meta.name && (meta.sidebarTitle.translatedTitle || meta.sidebarTitle.translatedOriginal) && meta.sidebarTitle.translatedTitle !== meta.pageTitle.translatedTitle">
        <span class="tree-node-title-caption"><span [translate]="'portals.text.sidebarTitle'"></span>:</span>&nbsp;
        <span class="tree-node-title-value">"{{ meta.sidebarTitle.translatedTitle }}"</span>
        <span class="tree-node-title-original" *ngIf="meta.name && meta.sidebarTitle.translatedTitle !== meta.sidebarTitle.translatedOriginal"
          >&nbsp;({{ meta.sidebarTitle.translatedOriginal }} )</span
        >
      </div>
    </div>

    <div class="d-flex flex-wrap align-items-center gap-1">
      <span *ngFor="let role of meta.roles" class="role badge rounded-pill bg-secondary">{{ role }}</span>

      <ng-container *ngIf="allowEditing">
        <button *ngIf="editing" type="button" class="btn btn-xs btn-primary" [title]="'portals.btn.edit' | translate" (click)="editNode(meta)">
          <span class="fa fa-pencil"></span>
        </button>

        <button
          *ngIf="editing"
          type="button"
          class="btn btn-xs"
          [ngClass]="{'btn-primary': meta.visible !== false, 'btn-light': meta.visible === false}"
          [title]="'portals.btn.toggleVisibility' | translate"
          (click)="toggleVisibility(node)">
          <span class="fa" [ngClass]="{'fa-eye': meta.visible !== false, 'fa-eye-slash': meta.visible === false}"></span>
        </button>

        <button *ngIf="editing" type="button" class="btn btn-xs btn-danger" [title]="'portals.btn.remove' | translate" (click)="removeNode(self)">
          <span class="fa fa-remove"></span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
