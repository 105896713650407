import {Component, Injector, Input} from '@angular/core';
import {IJsonEmailClifo} from '../jsonEmailCliFo.interface';
import {CGModalComponent} from '../../cg/modal/cgmodal.component';

@Component({
  selector: 'clifo-send-mail',
  templateUrl: './emailClifo.modal.component.html'
})
export class EmailClifoSendMailModalComponent extends CGModalComponent<IJsonEmailClifo> {
  @Input() public emailContent: IJsonEmailClifo;
  @Input() public numEmails: number;
  @Input() public anexos: Array<string>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): void {
    super.close(this.emailContent);
  }

  public ignore(): void {
    super.close();
  }
}
