import {Injectable, Injector, Type} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {EGestaoDGEMPSType, IGestaoDGEMPSEvent} from './gestaodgemps.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_EVENTOS_CONFIG, IPRHEventosConfigEntityService} from '../../entities/prheventosconfig/pRHEventosConfig.entity.interface';
import {ENTITY_NAME_PRH_FLUXOS} from '../../entities/prhfluxos/pRHFluxos.entity.interface';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../entities/prhservicos/pRHServicos.entity.interface';
import {ENTITY_NAME_PRH_TAREFAS_CAB, IPRHTarefasCabEntityService} from '../../entities/prhtarefascab/pRHTarefasCab.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {EPRHFluxoIndiceTipo, IJsonPRHFluxo} from '../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {GestaoDGEMPSMarcarAbonosModalComponent} from './modals/marcar/abonos/gestaodgemps.marcar.abonos.modal.component';
import {GestaoDGEMPSMarcarAbstractModalComponent} from './modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {GestaoDGEMPSMarcarFaltasModalComponent} from './modals/marcar/faltas/gestaodgemps.marcar.faltas.modal.component';
import {GestaoDGEMPSMarcarFeriasModalComponent} from './modals/marcar/ferias/gestaodgemps.marcar.ferias.modal.component';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP, IJsonDGEMPMarcacoes} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHServico, IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from '../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {IJsonPRHTarefaCab} from '../../entities/prhtarefascab/jsonPRHTarefaCab.entity.interface';
import {Moment} from 'moment';
import {THttpQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {isNumber} from 'pl-comps-angular';

@Injectable({
  providedIn: 'root'
})
export class GestaoDGEMPSService {
  private _entityServiceBuilder: EntityServiceBuilder;
  private _translateService: TranslateService;
  private _cgModalService: CGModalService;
  private _servicePRHServicos: IPRHServicosEntityService;
  private _servicePRHEventosConfig: IPRHEventosConfigEntityService;
  private _servicePRHServicoEmpregados: IEntityService<IJsonPRHServicoEmpregado>;
  private _serviceDGEMPS: IDGEMPSEntityService;
  private _servicePRHTarefasCab: IPRHTarefasCabEntityService;
  private _servicePRHFluxos: IEntityService<IJsonPRHFluxo>;

  constructor(protected readonly _injector: Injector) {}

  public getConfigStructure(type: EGestaoDGEMPSType): Promise<IJsonConfigEstrutura> {
    return this.servicePRHEventosConfig.getConfiguracoesByTipo(type).then((response: HttpResponse<IJsonConfigEstrutura>) => response.body);
  }

  public getConfigEvents(type?: EGestaoDGEMPSType): Promise<Array<IJsonPRHEventosConfig>> {
    const pesquisa = isNumber(type) ? `tipoEvento=${type}` : undefined;
    return this.servicePRHEventosConfig.query({pesquisa: pesquisa}).then((response: THttpQueryResponse<IJsonPRHEventosConfig>) => response.body.list);
  }

  public getServicosEmpregados(codEmp: number): Promise<Array<IJsonPRHServicoEmpregado>> {
    return this.servicePRHServicoEmpregados.query({pesquisa: `codEmp=${codEmp}`}).then((response: THttpQueryResponse<IJsonPRHServicoEmpregado>) => response.body.list);
  }

  public getEmpregado(codEmp: number): Promise<IJsonDGEMP> {
    if (!codEmp || codEmp === 0) {
      return Promise.resolve(this._defaultEmpregado());
    }
    return this.serviceDGEMPS
      .get({id: codEmp, reportExceptions: false})
      .then((response: HttpResponse<IJsonDGEMP>) => response.body)
      .catch(() => this._defaultEmpregado());
  }

  public getListaMarcacoesAno(codEmp: number, dataDe: Moment, dataAte: Moment): Promise<IJsonDGEMPMarcacoes> {
    return this.serviceDGEMPS.getListaMarcacoes(codEmp, dataDe, dataAte).then((response: HttpResponse<IJsonDGEMPMarcacoes>) => response.body);
  }

  public getListaMarcacoesMes(type: EGestaoDGEMPSType, codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment): Promise<IJsonPRHServicoMarcacoes> {
    let promise: TServiceResponse<IJsonPRHServicoMarcacoes>;
    switch (type) {
      case EGestaoDGEMPSType.Abonos:
        promise = this._servicePRHServicos.getMarcacoesAbonos(codServico, dataDe, dataAte, dataContext);
        break;
      case EGestaoDGEMPSType.Faltas:
        promise = this._servicePRHServicos.getMarcacoesFaltas(codServico, dataDe, dataAte, dataContext);
        break;
      case EGestaoDGEMPSType.Ferias:
        promise = this._servicePRHServicos.getMarcacoesFerias(codServico, dataDe, dataAte, dataContext);
        break;
    }
    return promise.then((response: HttpResponse<IJsonPRHServicoMarcacoes>) => response.body);
  }

  public getListaEmpregados(codServico: number): Promise<Array<IJsonDGEMP>> {
    return this.servicePRHServicos.getListaEmpregados(codServico).then((response: HttpResponse<Array<IJsonDGEMP>>) => response.body);
  }

  public getFluxos(tipo: EPRHFluxoIndiceTipo): Promise<Array<IJsonPRHFluxo>> {
    return this.servicePRHFluxos.query({pesquisa: `tipo=${tipo}`}).then((response: THttpQueryResponse<IJsonPRHFluxo>) => {
      return response.body.list.sort((a: IJsonPRHFluxo, b: IJsonPRHFluxo) => a.posicao - b.posicao);
    });
  }

  public getDatasMarcacoesPendentes(tipo: EPRHFluxoIndiceTipo, codServico: number, dataContext: Moment): Promise<IJsonPRHServicoDatasMarcacoesPendentes> {
    return this.servicePRHServicos.getDatasMarcacoesPendentes(tipo, codServico, dataContext).then((response: HttpResponse<IJsonPRHServicoDatasMarcacoesPendentes>) => response.body);
  }

  public getDownloadTarefaDocUrlUrl(idTarefaCab: number): string {
    return this.servicePRHTarefasCab.downloadDocUrl(idTarefaCab);
  }

  public deleteTarefa(event: IGestaoDGEMPSEvent): Promise<void> {
    return this.servicePRHTarefasCab.delete({id: event.idTarefaCab}).then(() => undefined);
  }

  public desmarcarTarefa(type: EGestaoDGEMPSType, event: IGestaoDGEMPSEvent): Promise<void> {
    let title = '';
    let message = '';
    switch (type) {
      case EGestaoDGEMPSType.Abonos:
        title = 'gestaodgemps.abonos.cancelarmodal.title';
        message = 'gestaodgemps.abonos.cancelarmodal.message';
        break;
      case EGestaoDGEMPSType.Faltas:
        title = 'gestaodgemps.faltas.cancelarmodal.title';
        message = 'gestaodgemps.faltas.cancelarmodal.message';
        break;
      case EGestaoDGEMPSType.Ferias:
        title = 'gestaodgemps.ferias.cancelarmodal.title';
        message = 'gestaodgemps.ferias.cancelarmodal.message';
        break;
    }
    return this.cgModalService.showOkCancel(title, message, {size: 'md'}).then(() => this.deleteTarefa(event));
  }

  public getMarcarModalComponent(type: EGestaoDGEMPSType): Type<GestaoDGEMPSMarcarAbstractModalComponent> {
    switch (type) {
      case EGestaoDGEMPSType.Abonos:
        return GestaoDGEMPSMarcarAbonosModalComponent;
      case EGestaoDGEMPSType.Faltas:
        return GestaoDGEMPSMarcarFaltasModalComponent;
      case EGestaoDGEMPSType.Ferias:
        return GestaoDGEMPSMarcarFeriasModalComponent;
      default:
        return undefined;
    }
  }

  public get entityServiceBuilder(): EntityServiceBuilder {
    if (!this._entityServiceBuilder) {
      this._entityServiceBuilder = this._injector.get<EntityServiceBuilder>(EntityServiceBuilder);
    }
    return this._entityServiceBuilder;
  }

  public get translateService(): TranslateService {
    if (!this._translateService) {
      this._translateService = this._injector.get<TranslateService>(TranslateService);
    }
    return this._translateService;
  }

  public get cgModalService(): CGModalService {
    if (!this._cgModalService) {
      this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    }
    return this._cgModalService;
  }

  public get servicePRHServicos(): IPRHServicosEntityService {
    if (!this._servicePRHServicos) {
      this._servicePRHServicos = this.entityServiceBuilder.build<IJsonPRHServico, IPRHServicosEntityService>(ENTITY_NAME_PRH_SERVICOS);
    }
    return this._servicePRHServicos;
  }

  public get servicePRHEventosConfig(): IPRHEventosConfigEntityService {
    if (!this._servicePRHEventosConfig) {
      this._servicePRHEventosConfig = this.entityServiceBuilder.build<IJsonPRHEventosConfig, IPRHEventosConfigEntityService>(ENTITY_NAME_PRH_EVENTOS_CONFIG);
    }
    return this._servicePRHEventosConfig;
  }

  public get servicePRHServicoEmpregados(): IEntityService<IJsonPRHServicoEmpregado> {
    if (!this._servicePRHServicoEmpregados) {
      this._servicePRHServicoEmpregados = this.entityServiceBuilder.build<IJsonPRHServicoEmpregado>(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
    }
    return this._servicePRHServicoEmpregados;
  }

  public get serviceDGEMPS(): IDGEMPSEntityService {
    if (!this._serviceDGEMPS) {
      this._serviceDGEMPS = this.entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    }
    return this._serviceDGEMPS;
  }

  public get servicePRHTarefasCab(): IPRHTarefasCabEntityService {
    if (!this._servicePRHTarefasCab) {
      this._servicePRHTarefasCab = this.entityServiceBuilder.build<IJsonPRHTarefaCab, IPRHTarefasCabEntityService>(ENTITY_NAME_PRH_TAREFAS_CAB);
    }
    return this._servicePRHTarefasCab;
  }

  public get servicePRHFluxos(): IEntityService<IJsonPRHFluxo> {
    if (!this._servicePRHFluxos) {
      this._servicePRHFluxos = this.entityServiceBuilder.build<IJsonPRHFluxo>(ENTITY_NAME_PRH_FLUXOS);
    }
    return this._servicePRHFluxos;
  }

  protected _defaultEmpregado(): IJsonDGEMP {
    return {
      codEmp: 0,
      nome: '',
      apelido: '',
      morada: '',
      codPostal: '',
      nomeLocalida: '',
      email: '',
      nTelefone: '',
      telemovelPart1: '',
      codServicoPRH: 0,
      nomeServicoPRH: this.translateService.instant('global.text.notDefined'),
      categoriaActual: '',
      nomeCategoria: '',
      rua: '',
      ativo: false,
      numContrib: ''
    };
  }
}
