import {merge} from 'lodash-es';
import {DxPieChartComponent} from 'devextreme-angular/ui/pie-chart';
import {Directive, Host, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {IDevExpressPieChart} from '../pie/devexpress.pie.chart.interface';

const FONT_FAMILY = 'Nunito Sans';

@Directive({
  selector: '[cgDxPieChart]'
})
export class DevExpressPieChartCentralGestDirective implements OnInit, OnChanges {
  @Input() public cgDxPieChart: IDevExpressPieChart;

  private _chart: IDevExpressPieChart;

  constructor(@Host() private readonly _chartComponent: DxPieChartComponent) {}

  public ngOnInit(): void {
    this._changedChart(this.cgDxPieChart);
    this._applyConfiguration();
  }

  public ngOnChanges({cgDxPieChart}: SimpleChanges): void {
    if (cgDxPieChart && !cgDxPieChart.isFirstChange()) {
      this._applyConfiguration();
    }
  }

  private _changedChart(dataGrid: IDevExpressPieChart): void {
    this._chart = this._setChartDefaultConfiguration(dataGrid);
  }

  private _setChartDefaultConfiguration(chart: IDevExpressPieChart): IDevExpressPieChart {
    return merge<IDevExpressPieChart, IDevExpressPieChart>(
      {
        palette: 'Soft Blue',
        resolveLabelOverlapping: 'shift',
        title: {
          horizontalAlignment: 'center',
          font: {
            size: 18,
            weight: 800,
            family: FONT_FAMILY
          }
        },
        legend: {
          verticalAlignment: 'top',
          horizontalAlignment: 'center',
          itemTextPosition: 'right',
          font: {
            weight: 700,
            family: FONT_FAMILY
          },
          margin: {
            top: 0,
            bottom: 15
          }
        }
      },
      chart
    );
  }

  private _applyConfiguration(): void {
    for (const key of Object.keys(this._chart)) {
      let value: unknown = this._chart[key];
      if (key === 'dataSource') {
        continue;
      }
      if (isObject(value) && isObject(this._chartComponent.instance.option(key))) {
        value = merge({}, this._chartComponent.instance.option(key), <object>value);
      }
      this._chartComponent.instance.option(key, value);
    }
  }
}
