import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IMapaPagamJaEnviadoItem} from '../../mapaPagamentos.module.interface';

@Component({
  selector: 'module-mapa-pagamentos-enviados-prompt-modal',
  templateUrl: './mapaPagamentos.enviados.prompt.modal.component.html'
})
export class MapaPagamentosEnviadosPromptModalComponent extends CGModalComponent<void> {
  @Input() public listJaEnviados: Array<IMapaPagamJaEnviadoItem>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
