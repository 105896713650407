import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonNotaCredito, IJsonNotaCreditoNovoValor, IJsonNotaCreditoPesquisaContab} from '../modals/ativosnotacredito/jsonAtivosNotaCredito.interface';
import {IJsonAtivos} from '../interfaces/jsonAtivos.entity.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosNotaCreditoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosnotacredito`;
  }

  public getPesquisaNotaCreditoContab(): TServiceResponse<Array<IJsonNotaCreditoPesquisaContab>> {
    return this._apiService.get<Array<IJsonNotaCreditoPesquisaContab>>({
      url: this._path
    });
  }

  public postSelecionaNotaCredito(notacreditopesquisacontab: IJsonNotaCreditoPesquisaContab, codativo: number): TServiceResponse<IJsonNotaCredito> {
    return this._apiService.post<IJsonNotaCredito, IJsonNotaCreditoPesquisaContab>({
      url: `${this._path}/selecionanotacredito`,
      body: notacreditopesquisacontab,
      params: {
        codativo: codativo
      }
    });
  }

  public postAssociaNotaDeCreditoDeReducaoDeValorAquisicao(notacreditonovovalor: IJsonNotaCreditoNovoValor): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IJsonNotaCreditoNovoValor>({
      url: `${this._path}/associanotacredito`,
      body: notacreditonovovalor
    });
  }
}
