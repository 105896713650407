import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAtivos} from '../interfaces/jsonAtivos.entity.interface';
import {IJsonAtivosDivisao} from '../modals/ativosdivisao/jsonAtivosDivisao.interface';
import {IJsonAtivosAjusteDepAntigas} from '../modals/ajustedepantigas/jsonAtivosAjusteDepAntigas.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosMudarContaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = this._apiService.path.restapi;
  }

  public postRegistaMudancaDeContaDoAtivo(ativo: IJsonAtivos, ncontadoativo: string): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/ativosmudarcontaativo`,
      body: ativo,
      params: {
        ncontadoativo: ncontadoativo
      }
    });
  }

  public postRegistaMudancaDeContaDeDepreciacaoDoExercicio(ativo: IJsonAtivos, ncontaamortexerc: string): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/ativosmudarcontadep/exercicio`,
      body: ativo,
      params: {
        ncontaamortexerc: ncontaamortexerc
      }
    });
  }

  public postRegistaMudancaDeContaDeDepreciacaoAcumulado(ativo: IJsonAtivos, ncontaamortacumu: string): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/ativosmudarcontadep/acumulado`,
      body: ativo,
      params: {
        ncontaamortacumu: ncontaamortacumu
      }
    });
  }

  public postRegistaAlteracaoTaxasDepreciacao(ativo: IJsonAtivos, contabtaxaamortiza: number, fiscaltaxaamortiza: number): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/ativosmudartaxasdep`,
      body: ativo,
      params: {
        contabtaxaamortiza: contabtaxaamortiza,
        fiscaltaxaamortiza: fiscaltaxaamortiza
      }
    });
  }

  public postDivisaoDoAtivo(divisaoativo: IJsonAtivosDivisao): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IJsonAtivosDivisao>({
      url: `${this._path}/ativosdivisao`,
      body: divisaoativo
    });
  }

  public getIniciaValoresAjuste(codativo: number): TServiceResponse<IJsonAtivosAjusteDepAntigas> {
    return this._apiService.get<IJsonAtivosAjusteDepAntigas>({
      url: `${this._path}/ativosajustedepantigas`,
      params: {
        codativo: codativo
      }
    });
  }

  public getRegistaAjustesDeDepreciacoesAcumuladasDeAnosAnteriores(codativo: number, periodo: string): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._path}/ativosajustedepantigas/registaajustes`,
      params: {
        codativo: codativo,
        periodo: periodo
      }
    });
  }
}
