<div class="modal-tribut-autonomas-empresas">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'tributAutonomas.modals.empresas.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-multiselect [model]="empresas" [source]="empresasSource" key="nEmpresa" [template]="empresasTemplate"></pl-multiselect>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-ok" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
