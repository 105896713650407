import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IRHEstatisticasExclusionSourceItem} from '../../rhEstatisticas.module.interface';

@Component({
  selector: 'rhestatisticas-exclusao-modal',
  templateUrl: './rhEstatisticas.exclusao.modal.html'
})
export class RhEstatisticasExclusaoModalComponent extends CGModalComponent<void> {
  @Input() public source: Array<IRHEstatisticasExclusionSourceItem>;
  @Input() public model: Array<IRHEstatisticasExclusionSourceItem>;

  public readonly msTemplate: string = '{{caption}}';
}
