<div class="ativos-aumentoifrs16-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativos.aumentoifrs16.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-alert class="mb-2" type="info" [closeable]="false">
        <div [innerHTML]="'ativos.aumentoifrs16.info' | translate"></div>
      </pl-alert>

      <pl-group>
        <label [translate]="'ativos.aumentoifrs16.data'"></label>
        <edit>
          <pl-edit type="date" attrName="data" [(model)]="data" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.aumentoifrs16.valor'"></label>
        <edit>
          <pl-edit type="number" attrName="valor" [(model)]="valor"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.aumentoifrs16.designacao'"></label>
        <edit>
          <pl-edit type="text" attrName="designacao" [(model)]="designacao"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-submit" id="saveNewLine" [onClick]="close" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
