export enum ETipoAusencias {
  NA,
  PorFerias,
  PorDoencaImprevista,
  PorDoencaProf,
  PorDoencaProlongada,
  PorTribunal,
  PorServicoMilitar,
  PorAssistenciaFamiliar,
  PorPaternidadeMaternidade,
  PorLicencaAurorizada,
  PorPenaDisciplinar,
  PorLicencaSabatica,
  PorCasamento,
  PorNascimentoFamiliar,
  PorFalecimentoFamiliar,
  PorTrabalhadorEstudante,
  PorGreveParalizacao,
  PorConsultaMedico,
  Outras,
  PorFaltaInjustificada,
  ActividadeSindical,
  AcidenteTrabalho
}

export const DATA_SOURCE_NAME_TIPOS_AUSENCIAS = 'tiposAusencias';
