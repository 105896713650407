import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_DESCRITIVO} from './tipoDescritivo.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_DESCRITIVO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_DESCRITIVO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoDescritivo.data.receipt'},
    {value: 1, name: 'tipoDescritivo.data.acceptedLetter'},
    {value: 2, name: 'tipoDescritivo.data.acceptedDiscounted'},
    {value: 3, name: 'tipoDescritivo.data.acceptedPaymentDiscounted'},
    {value: 4, name: 'tipoDescritivo.data.acceptedReturnDiscounted'},
    {value: 5, name: 'tipoDescritivo.data.acceptedReformDiscounted'},
    {value: 6, name: 'tipoDescritivo.data.acceptedPaymentNotDiscounted'},
    {value: 7, name: 'tipoDescritivo.data.acceptedReturnNotDiscounted'},
    {value: 8, name: 'tipoDescritivo.data.acceptedReformNotDiscounted'},
    {value: 9, name: 'tipoDescritivo.data.preDatedCheck'},
    {value: 10, name: 'tipoDescritivo.data.preDatedCheckDiscount'},
    {value: 11, name: 'tipoDescritivo.data.preDatedCheckPaymentDiscounted'},
    {value: 12, name: 'tipoDescritivo.data.preDatedCheckReturnDiscounted'},
    {value: 13, name: 'tipoDescritivo.data.preDatedCheckRetirementDiscounted'},
    {value: 14, name: 'tipoDescritivo.data.preDatedCheckPaymentNotDiscounted'},
    {value: 15, name: 'tipoDescritivo.data.preDatedCheckReturnNotDiscounted'},
    {value: 16, name: 'tipoDescritivo.data.preDatedCheckRetirementNotDiscounted'},
    {value: 17, name: 'tipoDescritivo.data.saqueSemAceite'},
    {value: 18, name: 'tipoDescritivo.data.DescontoSaqueSemAceite'},
    {value: 19, name: 'tipoDescritivo.data.pagamentoSaqueSemAceiteDiscounted'},
    {value: 20, name: 'tipoDescritivo.data.devoluçãoSaqueSemAceiteDiscounted'},
    {value: 21, name: 'tipoDescritivo.data.reformaSaqueSemAceiteDiscounted'},
    {value: 22, name: 'tipoDescritivo.data.pagamentoSaqueSemAceiteNotDiscounted'},
    {value: 23, name: 'tipoDescritivo.data.devoluçãoSaqueSemAceiteNotDiscounted'},
    {value: 24, name: 'tipoDescritivo.data.reformaSaqueSemAceiteNotDiscounted'},
    {value: 25, name: 'tipoDescritivo.data.doesNotControlVAT'},
    {value: 26, name: 'tipoDescritivo.data.documentManagement'},
    {value: 27, name: 'tipoDescritivo.data.firstAdoptionNewAccountingReference'},
    {value: 28, name: 'tipoDescritivo.data.accountingPolicyChanges'},
    {value: 29, name: 'tipoDescritivo.data.differencesTranslationFinancialStatements'},
    {value: 30, name: 'tipoDescritivo.data.realizationExcessesRevaluationTangibleIntangibleAssets'},
    {value: 31, name: 'tipoDescritivo.data.excessesRevaluationTangibleIntangibleAssets'},
    {value: 32, name: 'tipoDescritivo.data.deferredTaxAdjustments'},
    {value: 33, name: 'tipoDescritivo.data.otherChangesShareholdersEquity'},
    {value: 34, name: 'tipoDescritivo.data.netIncome'},
    {value: 35, name: 'tipoDescritivo.data.capitalRealizations'},
    {value: 36, name: 'tipoDescritivo.data.performanceEmissionPremiums'},
    {value: 37, name: 'tipoDescritivo.data.distributions'},
    {value: 38, name: 'tipoDescritivo.data.lossCoverageEntries'},
    {value: 39, name: 'tipoDescritivo.data.otherOperations'}
  ]
});
