import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, timeout} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {focusElement} from '../../../../../common/utils/element.utils';
import {ECGBankingDataRegistoRecebimentosPagamentos, IJsonBancosExtratoConfigs, RADIO_GROUP_CG_BANKING_DATA_REGISTO_RECEBIMENTOS_PAGAMENTOS} from '../../jsonBancosExtrato.module.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';

@Component({
  selector: 'bancos-extrato-module-config-modal',
  templateUrl: './bancosExtrato.config.modal.component.html'
})
export class BancosExtratoModuleConfigModalComponent extends CGModalComponent<IJsonBancosExtratoConfigs> {
  @Input() public simpleMode: boolean;
  public readonly radioGroupDataRegistoRecebimentosPagamentos: IRadioGroup<ECGBankingDataRegistoRecebimentosPagamentos>;
  public configs: IJsonBancosExtratoConfigs;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.radioGroupDataRegistoRecebimentosPagamentos = RADIO_GROUP_CG_BANKING_DATA_REGISTO_RECEBIMENTOS_PAGAMENTOS;
    this.configs = {
      nDiarioRecebimentos: undefined,
      nomeDiarioRecebimentos: undefined,
      nDiarioPagamentos: undefined,
      nomeDiarioPagamentos: undefined,
      descricao: undefined,
      movAbAuto: undefined,
      showDescritivo: undefined,
      dataRegistoRecebimentosPagamentos: undefined,
      apiProvider: undefined
    };
    this.promise = firstValueFrom(this._bancosExtratoService.configs(true).pipe(take(1)))
      .then((configs: IJsonBancosExtratoConfigs) => {
        this.configs = configs;
        if (!this.configs.nDiarioRecebimentos) {
          this.configs.nDiarioRecebimentos = undefined;
          this.configs.nomeDiarioRecebimentos = undefined;
        }
        if (!this.configs.nDiarioPagamentos) {
          this.configs.nDiarioPagamentos = undefined;
          this.configs.nomeDiarioPagamentos = undefined;
        }
      })
      .finally(() => {
        this.promise = undefined;
        timeout().then(() => {
          const element: HTMLInputElement = this._element.querySelector<HTMLInputElement>(
            !this.simpleMode ? 'input[name="diariorecebimentos"]' : 'pl-edit[data-attr-name="dataRegistoRecebimentosPagamentos"] .pl-checkbox-true'
          );
          focusElement(element);
        });
      });
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._bancosExtratoService
      .saveConfigs(this.configs, this.simpleMode)
      .then((response: HttpResponse<IJsonBancosExtratoConfigs>) => {
        this.enableClose();
        this._plAlertService.success('bancosextrato.configmodal.success');
        super.close(response.body);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
