import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, IPlToolbarMenuItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IDocfasNumsComunicacaoLogin} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {ISimpleLoginCredentials} from '../../../../modules/simplelogin/simpleLogin.module.interface';
import {SimpleLoginService} from '../../../../modules/simplelogin/simpleLogin.module.service';
import {SeriesPorComunicarATModalComponent} from '../../../docfasnums/modals/comunicacao/seriesporcomunicarat/seriesPorComunicarAt.modal.component';
import {IDocFasEntityService} from '../../docFas.entity.interface';
import {IJsonDocfa} from '../../jsonDocFa.entity.interface';
import {DocfasNewModalComponent} from '../../modals/newdocfas/docFas.new.modal.component';
import {DocfasNumsComunicacaoLoginService} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service';

@Component({
  selector: 'docfas-list',
  templateUrl: './docFas.entity.list.component.html'
})
export class DocfasListComponent extends ModuloEntityListComponent<IJsonDocfa, IDocFasEntityService> implements OnInit {
  private readonly _btnComunicacaoSeries: IPlToolbarItem;
  private readonly _btnLoginWebServiceAT: IPlToolbarMenuItem;
  private _docFasNumsComunicacaoLogin: IDocfasNumsComunicacaoLogin;
  private _atLogin: ISimpleLoginCredentials;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _simpleLoginService: SimpleLoginService,
    private readonly _serviceDocFasNumsComunicacaoLogin: DocfasNumsComunicacaoLoginService
  ) {
    super(_injector);
    this._btnComunicacaoSeries = {
      id: 'btn-gestao-docs-editaveis-toolbar',
      type: 'button',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-paper-plane"></i>',
      caption: 'docfas.modals.comunicacaoSeriesAT',
      order: this.btnNovo.order + 1,
      click: () => this._comunicacaoSeriesAT()
    };
    this._btnLoginWebServiceAT = {
      order: this._btnComunicacaoSeries.order + 1,
      caption: 'docfas.loginAT',
      iconLeft: '<i class="fa fa-fw fa-key" aria-hidden="true"></i>',
      click: () => this._configLoginAt()
    };
    this._atLogin = {
      username: '',
      password: ''
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._simpleLoginService.getAtCredentials().then((result) => {
      this._atLogin = result.body;
    });
    this._serviceDocFasNumsComunicacaoLogin.get().then((response) => {
      this._docFasNumsComunicacaoLogin = response.body;
    });
    this.btnConfig.visible = true;
    this.btnConfig.type = 'dropdown';
    this.btnConfig.menu = [this._btnLoginWebServiceAT];
    this.toolbar.addButton(this._btnComunicacaoSeries);
  }

  public async actionAdd(docFaBase?: IJsonDocfa): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocfasNewModalComponent, {size: 'md'});
    modalInstance.componentInstance.docFasBase = docFaBase;
    const newDocfa = await modalInstance.result;
    const response = await this.service.duplicateDocfa(newDocfa);
    const docFa: IJsonDocfa = response.body;
    this._plAlertService.success(this._translateService.instant('docfas.saved', {id: this.entity.getSuccessMessageId(docFa)}));
    if (this.maintenanceMode) {
      this.closeMaintenance(docFa);
      return;
    }
    this._navigating = true;
    try {
      await this._stateService.go(this.states.detail, {id: docFa.nDocFa});
      this._subjectOnNew.next();
    } finally {
      this._navigating = false;
    }
  }

  private _comunicacaoSeriesAT(): Promise<void> {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      const modalRef = this._cgModalService.showVanilla(SeriesPorComunicarATModalComponent, {size: 'xxl'});
      return modalRef.result.then(() => {
        this.refreshList();
      });
    }
    return this._configLoginAt().then(() => {
      const modalRef = this._cgModalService.showVanilla(SeriesPorComunicarATModalComponent, {size: 'xxl'});
      return modalRef.result.then(() => {
        this.refreshList();
      });
    });
  }

  private _configLoginAt(): Promise<void> {
    const instance = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
    return instance.result.then((result: IDocfasNumsComunicacaoLogin) => {
      this._docFasNumsComunicacaoLogin = result;
    });
  }
}
