<div class="modal-body">
  <div [translate]="'mapapagam.messages.processMessage'" style="margin-bottom: 8px"></div>
  <div class="progress" style="height: 4px">
    <div
      class="progress-bar bg-info progress-bar-striped progress-bar-animated"
      role="progressbar"
      [attr.aria-valuenow]="100"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"
      style="width: 100%"></div>
  </div>
  <div class="mt-2" style="font-size: 12px" [innerHTML]="message"></div>
</div>
