<div class="tree-viewer-node">
  <a
    [ngClass]="treeItem.nodeClass"
    [class.disabled]="treeItem.selectable === false || disabled === true"
    [class.angle-transform]="treeItem.collapsed === false"
    [attr.aria-expanded]="!treeItem.collapsed">
    <i class="fa fa-fw" [ngClass]="treeItem.childNodes && treeItem.childNodes.length ? 'fa-angle-right' : 'fa-caret-right'" (click)="collapse()" aria-hidden="true"></i>
    <i class="fa" [ngClass]="treeItem.nodeIcon" aria-hidden="true"></i>
    <span
      class="tree-viewer-node-text"
      [class.selected]="treeItem === selectedItem"
      [attr.title]="treeItem.nodeText | translate"
      [translate]="treeItem.nodeText"
      (click)="selectItem(treeItem)"
      (dblclick)="doubleClickedItem(treeItem)">
    </span>
  </a>

  <ul class="tree-viewer-nodes" [ngbCollapse]="treeItem.collapsed === undefined || treeItem.collapsed">
    <li *ngFor="let childNode of treeItem.childNodes">
      <tree-viewer-node
        [treeItem]="childNode"
        [selectedItem]="selectedItem"
        [disabled]="disabled"
        [collapseOnSelect]="collapseOnSelect"
        (evtSelectedItem)="selectItem($event)"
        (evtDoubleClickedItem)="evtDoubleClickedItem.emit($event)">
      </tree-viewer-node>
    </li>
  </ul>
</div>
