<div class="entity-detail-form empresas">
  <pl-alert type="error" *ngIf="errorCount" [plAnimationShake]="errorCount" (evtClosed)="errorCount = 0">
    <div *ngIf="errorStoreCompanyRequiredFields">
      <div class="h6"><span [translate]="'empresas.storewelcome.message2'"></span>:</div>
      <ul>
        <ng-container *ngFor="let key of storeCompanyRequiredFieldsKeys">
          <li class="h6" *ngIf="storeCompanyRequiredFields[key].invalid" [translate]="storeCompanyRequiredFields[key].caption"></li>
        </ng-container>
      </ul>
    </div>
  </pl-alert>

  <pl-form>
    <pl-group>
      <pl-group>
        <label [translate]="'empresas.fields.nempresa'"></label>
        <edit>
          <pl-edit type="text" attrName="nEmpresa" [(model)]="empresa.nempresa" [properties]="{disabled: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'empresas.fields.nome'"></label>
        <edit>
          <pl-edit type="text" attrName="nomeEmpresa" [(model)]="empresa.nome" [properties]="{disabled: empresa.demonstracao}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>

  <pl-nav-wizard [definition]="definition" [onFinalize]="fnFinalize">
    <pl-nav-wizard-step caption="empresas.steps.dadosGerais" icon="fa-user" [complete]="true">
      <pl-form *plNavWizardStepContent (formInstanceChange)="changedFormInstance($event)">
        <pl-group>
          <label [translate]="'empresas.fields.nomeAlternativo'"></label>
          <edit>
            <pl-edit type="text" attrName="nomeComercial" [(model)]="empresa.nomeAlternativo"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.morada'"></label>
          <edit>
            <pl-edit type="text" attrName="morada" [(model)]="empresa.morada"></pl-edit>
          </edit>
        </pl-group>

        <!--Codigo Postal e Localidade-->
        <pl-group>
          <pl-group [properties]="{subGroupClass: 'col-12 col-sm-3'}">
            <label [translate]="'empresas.fields.codpostal'"></label>
            <edit>
              <entity-autocomplete
                entity="codpostais"
                attrName="codPostal"
                [(selectedKey)]="empresa.codpostal"
                (evtSelectedDescriptionChanged)="getLocalidadeFromCodPostal($event)"
                output="key"
                outputDescription="localidade">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group [properties]="{subGroupClass: 'col-12 col-sm-9'}">
            <label [translate]="'empresas.fields.localidade'"></label>
            <edit>
              <pl-edit type="text" attrName="localidade" [(model)]="empresa.localidade"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <!--Nº Contribuinte e AT-->
        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.nif'"></label>
            <edit>
              <pl-edit
                type="text"
                attrName="nifEmpresa"
                [(model)]="empresa.nif"
                [properties]="{
                  disabled: empresa.demonstracao,
                  validators: {nif: validatorNif}
                }">
                <div *inputGroup="{klass: 'btn-primary btnLoginAT', disabled: empresa.demonstracao, promise: promiseConfiguracaoAtModal}">
                  <span plDelegateEventDownwardsClick (click)="configuracaoAtModal()">
                    <img class="img-responsive" src="../../assets/images/logo-at.svg" width="16px" height="16px" alt="logo-at" />
                    <span class="d-none d-sm-inline"> Login AT</span>
                  </span>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.nContribSegSocial'"></label>
            <edit>
              <pl-edit type="text" attrName="nContribSegSocial" [(model)]="empresa.nContribSegSocial" [properties]="{validators: {nContribSegSocial: validatorNif}}">
                <div *inputGroup="{klass: 'btn-primary btnLoginAT'}">
                  <span plDelegateEventDownwardsClick (click)="configuracaoSegSocialModal()">
                    <span class="d-none d-sm-inline">Seg. Social</span>
                  </span>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.cae'"></label>
          <edit>
            <entity-autocomplete
              entity="qpcae"
              attrName="cae"
              [model]="empresa"
              (selectedKeyChange)="empresa.cae = $event"
              (evtSelectedDescriptionChanged)="empresa.nomeCAE = $event"
              [fieldsMap]="{codcae: 'cae', nome: 'nomeCAE'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.atividadeprincipal'"></label>
          <edit>
            <pl-edit type="text" attrName="atividadeprincipal" [(model)]="empresa.actividadePrincipal"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.concelhoEmp'"></label>
          <edit>
            <entity-autocomplete
              entity="concelhos"
              attrName="concelhos"
              [model]="empresa"
              (selectedKeyChange)="empresa.concelhoEmp = $event"
              (evtSelectedDescriptionChanged)="empresa.nomeConcelhoEmp = $event"
              [fieldsMap]="{codConcelho: 'concelhoEmp', nomeConcelho: 'nomeConcelhoEmp'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.pais'"></label>
          <edit>
            <entity-autocomplete entity="paises" attrName="paises" [model]="empresa" (selectedKeyChange)="empresa.codPais = $event" (evtSelectedDescriptionChanged)="empresa.nomePais = $event">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.moeda'"></label>
          <edit>
            <entity-autocomplete entity="moeda" attrName="moeda" [model]="empresa" (selectedKeyChange)="empresa.codMoeda = $event" (evtSelectedDescriptionChanged)="empresa.nomeMoeda = $event">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.tipoEmpresa'"></label>
          <edit>
            <pl-edit type="tiposEmpresa" attrName="tipoEmpresa" [(model)]="empresa.codTipoEmpresa" [properties]="{required: {value: true}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.alvara'"></label>
          <edit>
            <pl-edit type="text" attrName="alvara" [(model)]="empresa.alvara"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.textoRelatorio'"></label>
          <edit>
            <pl-edit type="textarea" attrName="relatorio" [(model)]="empresa.textoRelatorio" [properties]="{placeholder: 'empresas.fields.textoRelatorioPlaceholder'}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.capitalSocial'"></label>
          <edit>
            <pl-edit type="number" attrName="capitalSocial" [(model)]="empresa.capitalSocial"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.nomeComarca'"></label>
          <edit>
            <pl-edit type="text" attrName="nomeComarca" [(model)]="empresa.nomeComarca"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.nomeConservatoria'"></label>
          <edit>
            <pl-edit type="text" attrName="nomeConservatoria" [(model)]="empresa.conservatoria"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.registoConservatoria'"></label>
          <edit>
            <pl-edit type="text" attrName="registoConservatoria" [(model)]="empresa.registo"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.capPublico'"></label>
            <edit>
              <pl-edit type="number" attrName="capPublico" [(model)]="empresa.capPublico"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.capPublicoPerc'"></label>
            <edit>
              <pl-edit type="number" attrName="capPublicoPerc" [(model)]="empresa.capPublicoPerc"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.capPrivado'"></label>
            <edit>
              <pl-edit type="number" attrName="capPrivado" [(model)]="empresa.capPrivado"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.capPrivadoPerc'"></label>
            <edit>
              <pl-edit type="number" attrName="capPrivadoPerc" [(model)]="empresa.capPrivadoPerc"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.capEstrangeiro'"></label>
            <edit>
              <pl-edit type="number" attrName="capEstrangeiro" [(model)]="empresa.capEstrangeiro"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.capEstrangeiroPerc'"></label>
            <edit>
              <pl-edit type="number" attrName="capEstrangeiroPerc" [(model)]="empresa.capEstrangeiroPerc"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="empresas.steps.contactos" icon="fa-address-card" [complete]="true">
      <pl-form *plNavWizardStepContent>
        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.telefone'"></label>
            <edit>
              <pl-edit type="text" attrName="telefone" [(model)]="empresa.telefone"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.telemovel'"></label>
            <edit>
              <pl-edit type="text" attrName="telemovel" [(model)]="empresa.telemovel"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.email'"></label>
            <edit>
              <pl-edit type="email" attrName="email" [(model)]="empresa.email"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'empresas.fields.web'"></label>
          <edit>
            <pl-edit type="text" attrName="enderecoWEB" [(model)]="empresa.web"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="empresas.steps.logotipo" icon="fas fa-image" [hideFinalize]="true" [complete]="true">
      <pl-form *plNavWizardStepContent>
        <div>
          <pl-button klass="btn btn-sm btn-primary" [onClick]="fnChangeLogo"><i class="fa fa-fw fa-upload"></i>&nbsp;<span [translate]="'empresas.messages.changeLogo'"></span></pl-button>

          <pl-button klass="btn btn-sm btn-danger" [onClick]="fnDeleteLogo"><i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'empresas.messages.deleteLogo'"></span></pl-button>
        </div>

        <hr />

        <div>
          <cg-company-logo></cg-company-logo>
        </div>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="empresas.steps.dadosContab" icon="fa-calculator" [hideFinalize]="true" [complete]="true" *ngIf="hasApiContabilidade">
      <pl-form *plNavWizardStepContent>
        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.repFinancas'"></label>
            <edit>
              <entity-autocomplete
                entity="repfinancas"
                attrName="repFinancas"
                [model]="empresa"
                (selectedKeyChange)="empresa.codRepFinancas = $event"
                (evtSelectedDescriptionChanged)="empresa.repFinancas = $event"
                [fieldsMap]="{codFiscal: 'codRepFinancas', nome: 'repFinancas'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'empresas.fields.nifAdmin'"></label>
              <edit>
                <entity-autocomplete entity="nifs" attrName="nifAdmin" [(selectedKey)]="empresa.nifAdmin"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.nifCC'"></label>
            <edit>
              <entity-autocomplete entity="nifs" attrName="nifCC" [(selectedKey)]="empresa.nifCC"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.nOCC'"></label>
            <edit>
              <pl-edit type="text" attrName="nOCC" [(model)]="empresa.nOCC" [properties]="{required: {value: true}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.tipoContab'"></label>
            <edit>
              <pl-edit type="tiposContabilidade" attrName="tipoContabilidade" [model]="empresa.codTipoContab" (modelChange)="empresa.codTipoContab = $event" [properties]="{required: {value: true}}">
              </pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.regimeIRC'"></label>
            <edit>
              <pl-edit type="regIRC" attrName="regimeIRC" [model]="empresa.codRegimeIRC" (modelChange)="empresa.codRegimeIRC = $event" [properties]="{required: {value: true}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.regimeIva'"></label>
            <edit>
              <pl-edit type="regIVA" [model]="empresa.codRegimeIva" (modelChange)="empresa.codRegimeIva = $event" [properties]="{required: {value: true}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.periodoIva'"></label>
            <edit>
              <pl-edit type="periodosIva" attrName="periodoIVA" [model]="empresa.codPeriodoIva" (modelChange)="empresa.codPeriodoIva = $event" [properties]="{required: {value: true}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.anoCursoIRC'"></label>
            <edit>
              <pl-edit type="text" attrName="anoIRC" [(model)]="empresa.anoCursoIRC"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.periodoContabilistico'"></label>
            <edit>
              <pl-edit type="text" attrName="periodoContabilistico" [(model)]="empresa.periodoContabilistico">
                <div
                  *inputGroup="{klass: 'btn-xs btn-primary action-limpar-linha', title: 'empresas.changePerioContabTooltip' | translate}"
                  (click)="openPerioMudaPrimeiroMesContab()"
                  data-capture-click>
                  <i class="fa fa-fw fa-retweet" aria-hidden="true"></i>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <label [translate]="'empresas.fields.tipoAnalitica'"></label>
          <edit>
            <pl-edit type="tiposAnalitica" attrName="tipoAnalitica" [model]="empresa.tipoAnalitica" (modelChange)="tipoAnaliticaChanged($event)" [properties]="{required: {value: true}}">
              <div class="item-group-dropdown" *inputGroup="{klass: 'hollow-form', tabindex: -1}" ngbDropdown [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']">
                <div class="item-group-dropdown-toggle" ngbDropdownToggle data-capture-click>
                  <i class="pl-icon-more-vertical" aria-hidden="true"></i>
                </div>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button type="button" class="dropdown-item" [click]="fnMaintenanceAnaliticaConfig" plPromise>
                    <i class="fa fa-fw fa-pencil-square" aria-hidden="true"></i><span [translate]="'empresas.maintenance.analiticaconfig'"></span>
                  </button>
                </div>
              </div>
            </pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="empresas.steps.dadosRH" icon="fa-users" [hideFinalize]="true" [complete]="true" *ngIf="hasApiRH">
      <pl-form *plNavWizardStepContent>
        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.repFinancas'"></label>
            <edit>
              <entity-autocomplete
                entity="repfinancas"
                attrName="repFinancas"
                [model]="empresa"
                (selectedKeyChange)="empresa.codRepFinancas = $event"
                (evtSelectedDescriptionChanged)="empresa.repFinancas = $event"
                [fieldsMap]="{codFiscal: 'codRepFinancas', nome: 'repFinancas'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.nifAdmin'"></label>
            <edit>
              <entity-autocomplete entity="nifs" attrName="nifAdmin" [(selectedKey)]="empresa.nifAdmin"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.nifCC'"></label>
            <edit>
              <entity-autocomplete entity="nifs" attrName="nifCC" [(selectedKey)]="empresa.nifCC"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'empresas.fields.codNatJuridica'"></label>
            <edit>
              <entity-autocomplete
                entity="qpnj"
                attrName="codNatJuridica"
                [model]="empresa"
                (selectedKeyChange)="empresa.codNatJuridica = $event"
                (evtSelectedDescriptionChanged)="empresa.nomeNatJuridica = $event"
                [fieldsMap]="{nome: 'nomeNatJuridica'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.codAssocPatronal'"></label>
            <edit>
              <entity-autocomplete
                entity="qpapa"
                attrName="codAssocPatronal"
                [model]="empresa"
                (selectedKeyChange)="empresa.codAssocPatronal = $event"
                (evtSelectedDescriptionChanged)="empresa.nomeAssocPatronal = $event">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'empresas.fields.codddccff'"></label>
            <edit>
              <entity-autocomplete
                entity="qpdcf"
                attrName="codddccff"
                [model]="empresa"
                (selectedKeyChange)="empresa.codddccff = $event"
                (evtSelectedDescriptionChanged)="empresa.nomeCodddccff = $event"
                [fieldsMap]="{codDistConcFreg: 'codddccff', nome: 'nomeCodddccff'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
