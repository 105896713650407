<div class="demfinsnc entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <div class="d-flex flex-wrap gap-2 mb-2" [class.flex-column]="isMobile">
          <pl-group>
            <label [translate]="'global.text.year'"></label>
            <edit>
              <pl-autocomplete [source]="defaultData.anosSource" [model]="ano" (modelChange)="ano = $event; anoChanged()" [allowInvalid]="false" [allowEmpty]="false"></pl-autocomplete>
            </edit>
          </pl-group>

          <div class="d-flex flex-wrap gap-2" [ngClass]="{'align-items-center': !isMobile}" [class.flex-column]="isMobile">
            <pl-group>
              <label *ngIf="!isMobile"></label>
              <edit>
                <pl-edit
                  type="checkbox"
                  [model]="processaIntercalares"
                  (modelChange)="processaIntercalaresChanged($event)"
                  [properties]="{label: 'demfinsnc.fields.processaIntercalares', disabled: ano !== anoEmCursoIRC}">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label *ngIf="!isMobile"></label>
              <edit>
                <pl-edit type="checkbox" [(model)]="processaFluxos" (modelChange)="checkboxChanged()" [properties]="{label: 'demfinsnc.fields.processafluxo'}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label *ngIf="!isMobile"></label>
              <edit>
                <pl-edit type="checkbox" [(model)]="verComDados" (modelChange)="checkboxChanged()" [properties]="{label: 'demfinsnc.fields.vercomdados'}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>

        <cg-card-panel [caption]="'demfinsnc.fields.variaveisapuramento'" [hideThumb]="true" *ngIf="processaIntercalares">
          <div *cgCardPanelContent>
            <div class="periodo-intercalar">
              <label class="mb-0 me-2 align-self-center" [translate]="'demfinsnc.btn.periodo'"></label>
              <pl-autocomplete
                [source]="periodosSource"
                [model]="periodoItem"
                (modelChange)="periodoIntercalaresChanged($event)"
                [allowInvalid]="false"
                [rowTemplate]="periodoSourceTemplate"
                [output]="periodoSourceTemplate">
              </pl-autocomplete>
            </div>

            <pl-split-view [initialSizeLeft]="50" (evtSizeValuesChanged)="changedResizerValues()">
              <div *plSplitViewLeftSide>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionVarApuramento"
                  cgDxDataGridInstanceName="variaveisApuramento"
                  [dataSource]="variaveisApuramentoN"
                  (onInitialized)="onInitializedVariaveisApuramentoN($event)"
                  (onCellClick)="onDataGridVariaveisApuramentoCellClick($event)">
                  <div *dxTemplate="let item of 'templateToolbar'">
                    <label [translate]="'demfinsnc.fields.periointercalartable'" [translateParams]="{ano: ano}"></label>
                  </div>
                  <div *dxTemplate="let item of 'masterDetailTemplate'; let itemIndex = index">
                    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionVarApuramentoDetail" [dataSource]="item.data.valoresInventario" (onRowUpdated)="changeValorParentRow(item.data)"></dx-data-grid>
                  </div>
                </dx-data-grid>
              </div>
              <div *plSplitViewRightSide>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionVarApuramento"
                  cgDxDataGridInstanceName="variaveisApuramentoN1"
                  [dataSource]="variaveisApuramentoN1"
                  (onInitialized)="onInitializedVariaveisApuramentoN1($event)"
                  (onCellClick)="onDataGridVariaveisApuramentoCellClick($event)">
                  <div *dxTemplate="let item of 'templateToolbar'">
                    <label [translate]="'demfinsnc.fields.periointercalartable'" [translateParams]="{ano: ano - 1}"></label>
                  </div>
                  <div *dxTemplate="let item of 'masterDetailTemplate'; let itemIndex = index">
                    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionVarApuramentoDetail" [dataSource]="item.data.valoresInventario" (onRowUpdated)="changeValorParentRow(item.data)"></dx-data-grid>
                  </div>
                </dx-data-grid>
              </div>
            </pl-split-view>
          </div>
        </cg-card-panel>

        <pl-group [ngClass]="{'mt-2': processaIntercalares}">
          <edit>
            <pl-button klass="btn-primary" type="submit"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <pl-tabs [(activeId)]="tabActiveId" (activeIdChange)="changeTab()">
    <pl-tab id="dembalanco" caption="demfinsnc.tabs.balancoTitle">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemBalancoDefinition"
          cgDxDataGridInstanceName="dembalanco"
          [dataSource]="dataGridDemBalancoDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemBalanco'">
            <pl-tabs>
              <pl-tab id="demBalancoIntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demBalancoBalancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="demresultNat" caption="demfinsnc.tabs.demresultNatTitle">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemDRNatDefinition"
          cgDxDataGridInstanceName="demresultNat"
          [dataSource]="dataGridDemDRNatDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemDRNat'">
            <pl-tabs>
              <pl-tab id="demDRNatIntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demDRNatBalancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="demresultFunc" caption="demfinsnc.tabs.demresultFuncTitle">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemDRFncDefinition"
          cgDxDataGridInstanceName="demresultFunc"
          [dataSource]="dataGridDemDRFncDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemDRFunc'">
            <pl-tabs>
              <pl-tab id="demDRFuncIntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demDRFuncBalancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="demfluxocaixa" caption="demfinsnc.tabs.demfluxocaixaTitle">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemDRFluxCaixaDefinition"
          cgDxDataGridInstanceName="demfluxocaixa"
          [dataSource]="dataGridDemDRFluxCaixaDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemDRFluxoCaixa'">
            <pl-tabs>
              <pl-tab id="demDRFLFluxoCaixa" caption="demfinsnc.tabs.fluxosCaixaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridFluxoCaixaDefinition" [dataSource]="item.data.traceListFC"></dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demDRFLIntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demDRFLBalancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="demAltCP" [caption]="demAltCPCaption" *ngIf="showDACP">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemAltCPDefinition"
          cgDxDataGridInstanceName="demAltCP"
          [dataSource]="dataGridDemAltCPDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemAltCP'">
            <pl-tabs>
              <pl-tab id="demAltCPIntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demAltCPBalancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="demAltCP2" [caption]="demAltCP2Caption" *ngIf="showDACP">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDemAltCP2Definition"
          cgDxDataGridInstanceName="demAltCP2"
          [dataSource]="dataGridDemAltCP2Definition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onRowUpdated)="onRowUpdated($event)">
          <div *dxTemplate="let cell of 'templateToolbarBadgeCellManualEdited'">
            <cg-table-legend [legend]="demFinSNSDataGridLegend"></cg-table-legend>
          </div>

          <div *dxTemplate="let cell of 'templateToolbarBtnReporDados'">
            <pl-button klass="btn-sm btn-light me-1" (evtClicked)="reporValoresOriginais()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'demfinsnc.btn.repvaloriginal'"></span>
            </pl-button>

            <pl-button klass="btn-primary btn-sm" [onClick]="fnSaveDemFinSNC" [disabled]="!canSaveData"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplateDemAltCP2'">
            <pl-tabs>
              <pl-tab id="demAltCP2IntConta" caption="demfinsnc.tabs.intContaTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridIntContaDefinition" [dataSource]="item.data.traceList">
                    <div *dxTemplate="let item of 'masterDetailTemplateIntContas'">
                      <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab id="demAltCP2Balancete" caption="demfinsnc.tabs.balanceteTitle">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDetailDefinition" [dataSource]="item.data.detalheList"></dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
