import {Injectable} from '@angular/core';
import {HttpHeaders, HttpResponseBase} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {fromJson, isArray, isNumber, isString, toJson} from 'pl-comps-angular';
import {EStatusCode, HTTP_HEADER_EXCEPTION, isDev} from '../../../config/constants';
import {ICGException, ICGExceptionError} from './exceptions.service.interface';

@Injectable({
  providedIn: 'root'
})
export class CGExceptionService {
  private readonly _fatalMessages: Array<string>;
  private readonly _excludedFromExceptionReporting: Array<string>;

  constructor(private readonly _translateService: TranslateService) {
    this._fatalMessages = ['FireDAC', 'Access violation', 'Invalid pointer operation'];
    this._excludedFromExceptionReporting = [];
  }

  public isFatal(exception: ICGExceptionError): boolean {
    if (isDev()) {
      return false;
    }
    for (const fatalMessage of this._fatalMessages) {
      if (exception.message.includes(fatalMessage)) {
        return true;
      }
    }
    return false;
  }

  public responseHasException(response: HttpResponseBase): boolean {
    return response.headers.has(HTTP_HEADER_EXCEPTION);
  }

  public get(response: HttpResponseBase): ICGExceptionError {
    const error: ICGExceptionError = {
      class: 'unknown',
      fields: [],
      message: this.parseStatusCode(response.status),
      status: response.status
    };
    if (response && response.headers instanceof HttpHeaders) {
      const rawException: string = response.headers.get(HTTP_HEADER_EXCEPTION);
      if (rawException) {
        const cgException: ICGException = fromJson<ICGException>(rawException);
        if (response.status !== EStatusCode.InternalServerError || !this.isFatal(cgException.exception)) {
          if (cgException.exception.class) {
            error.class = cgException.exception.class;
          }
          if (isArray(cgException.exception.fields) && cgException.exception.fields.length) {
            error.fields = cgException.exception.fields;
          }
          if (cgException.exception.message) {
            error.message = cgException.exception.message;
          }
          if (isNumber(cgException.exception.status) && cgException.exception.status >= 0) {
            error.status = cgException.exception.status;
          }
        }
      }
    }
    return error;
  }

  public parseHeaders(httpResponseBase: HttpResponseBase): string {
    const headers = {};
    const headerKeys = httpResponseBase.headers.keys();
    for (const headerKey of headerKeys) {
      const headerData = httpResponseBase.headers.getAll(headerKey);
      if (headerData) {
        headers[headerKey] = headerData;
      }
    }
    return toJson(headers);
  }

  public parseUrl(value: string): string {
    if (!isString(value) || !value) {
      return '';
    }
    return value.split('?')[0];
  }

  public parseStatusCode(statusCode: EStatusCode): string {
    if (statusCode < EStatusCode.BadRequest) {
      return '';
    }
    let message: string;

    switch (statusCode) {
      case EStatusCode.NoResponse:
        message = 'error.server.unavailable';
        break;
      case EStatusCode.BadRequest:
        message = 'error.server.badRequest';
        break;
      case EStatusCode.Unauthorized:
        message = 'error.server.unauthorized';
        break;
      case EStatusCode.Forbidden:
        message = 'error.server.forbidden';
        break;
      case EStatusCode.NotFound:
        message = 'error.server.notFound';
        break;
      case EStatusCode.InternalServerError:
        message = 'error.server.internalServerError';
        break;
      case EStatusCode.BadGateway:
        break;
      case EStatusCode.ServiceUnavailable:
        message = 'error.server.serviceUnavailable';
        break;
      default:
        return this._translateService.instant('global.text.errorNum', {error: statusCode});
    }
    return message ? this._translateService.instant(message) : '';
  }

  public excludeFromExceptionReporting(value: string): void {
    this._excludedFromExceptionReporting.push(this.parseUrl(value));
  }

  public executeExceptionReporting(value: string, callback: (excluded: boolean) => void): void {
    const exclusionIndex: number = this._findIndexFromExclusions(value);
    const excluded: boolean = exclusionIndex !== -1;
    callback(excluded);
    if (excluded) {
      this._excludedFromExceptionReporting.splice(exclusionIndex, 1);
    }
  }

  private _findIndexFromExclusions(value: string): number {
    return this._excludedFromExceptionReporting.lastIndexOf(value);
  }
}
