import {Component, Injector, OnInit} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonReparticaoFinancas} from '../../jsonReparticaoFinancas.entity.interface';
import {RADIO_GROUP_TIPOFINANCAS} from '../../repFinancas.entity.interface';
import {IJsonDistrito} from '../../../distritos/jsonDistrito.entity.interface';

@Component({
  selector: 'repfinancas-edit',
  templateUrl: './repFinancas.entity.edit.component.html'
})
export class RepFinancasEditComponent extends ModuloEntityDetailComponent<IJsonReparticaoFinancas> implements OnInit {
  public readonly radioGroupTipoFinancas = RADIO_GROUP_TIPOFINANCAS;
  public readonly qpdcfOutput: string;
  public filterCodFinancasAT: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.qpdcfOutput = '{{codServicoFinancas}} - {{nomeServicoFinancas}}';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.filterCodFinancasAT = this.model.codDistritoAT ? `CODDISTRITO='${this.model.codDistritoAT}'` : '';
  }

  public onDistritoChanged(distrito: IJsonDistrito): void {
    if (isObject(distrito)) {
      this.model.codDistrito = distrito.codDistrito;
      this.model.nomeDistrito = distrito.nomeDistrito;
      this.model.codDistritoAT = distrito.codDistritoAT;
      this.filterCodFinancasAT = `CODDISTRITO='${this.model.codDistritoAT}'`;
    } else {
      this.model.codDistrito = 0;
      this.model.nomeDistrito = '';
      this.model.codDistritoAT = '';
      this.filterCodFinancasAT = '';
    }
    this.model = {
      ...this.model,
      codFinancasAT: '',
      nomeFinancasAT: ''
    };
  }
}
