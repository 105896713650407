<div class="cg-dashboards dashboard-financeiro">
  <div class="row">
    <div class="col-12 cg-dashboards-spacing banking-automation">
      <pl-alert
        *ngIf="!empresaTemCGBanking || (licencaStoreModePublic && cgOnCGBankingExpired) || !acessoBankingAutomation"
        theme="mb-0"
        [type]="!licencaStoreModePublic && acessoBankingAutomation ? 'info' : 'error'"
        [closeable]="false">
        <ng-container *ngIf="!empresaTemCGBanking" [ngSwitch]="licencaStoreModePublic">
          <ng-container *ngSwitchCase="false">
            <div [translate]="'bancosextrato.text.noLicense'"></div>
            <a *ngIf="!promiseActivateCGBanking" [translate]="'bancosextrato.text.activate'" (click)="activateCGBanking()"></a>
          </ng-container>

          <div *ngSwitchCase="true" [innerHTML]="'bancosextrato.errors.licenseNotActivatedCGOn' | translate: {href: this.cgStoreUrlBackOffice}"></div>
        </ng-container>

        <ng-container *ngIf="empresaTemCGBanking">
          <div *ngIf="!acessoBankingAutomation" [translate]="!licencaStoreModePublic ? 'bancosextrato.text.accessDenied' : 'bancosextrato.text.accessDeniedStore'"></div>

          <div *ngIf="acessoBankingAutomation && cgOnCGBankingExpired" [innerHTML]="'bancosextrato.errors.licenseCGOnExpired' | translate: {href: this.cgStoreUrlBackOffice}"></div>
        </ng-container>
      </pl-alert>

      <pl-dashboard
        *ngIf="empresaTemCGBanking && (!licencaStoreModePublic || !cgOnCGBankingExpired) && acessoBankingAutomation"
        class="cg-dashboards-table"
        [dashboard]="contasAOrdem"
        [showTotalTable]="true"></pl-dashboard>
    </div>

    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-sm-12 col-lg-6 cg-dashboards-spacing">
          <div class="row">
            <div class="col-12">
              <div class="card p-3">
                <dx-pie-chart [cgDxPieChart]="chartPieReceberDeClientes" [dataSource]="chartPieReceberDeClientesData" (onInitialized)="onInitializedChartPieReceberDeClientes($event)"></dx-pie-chart>
              </div>
            </div>
            <div class="col-12 mt-3">
              <pl-dashboard class="cg-dashboards-table" [dashboard]="receberClientesTable"></pl-dashboard>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6 cg-dashboards-spacing">
          <div class="row">
            <div class="col-12">
              <div class="card p-3">
                <dx-pie-chart [cgDxPieChart]="chartPiePagarFornecedores" [dataSource]="chartPiePagarFornecedoresData" (onInitialized)="onInitializedChartPiePagarFornecedores($event)"></dx-pie-chart>
              </div>
            </div>
            <div class="col-12 mt-3">
              <pl-dashboard class="cg-dashboards-table table-success" [dashboard]="pagarFornecedoresTable"></pl-dashboard>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 cg-dashboards-spacing mt-3">
      <pl-dashboard class="cg-dashboards-table" [dashboard]="valoresEmAberto"></pl-dashboard>
    </div>
  </div>
</div>
