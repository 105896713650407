import {HttpResponse} from '@angular/common/http';
import {IJsonDashboardsPercentagem} from '../../../interfaces/jsonDashboards.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ListagensService} from '../../../services/listagens.service';
import {MODULE_NAME_LISTAGENS_VENDAS_ARTIGO} from './vendasArtigo.module.interface';
import moment from 'moment';
import {ROLE} from '../../../services/role.const';
import {VendasArtigoModuleComponent} from './components/vendasArtigo.module.component';

export const MODULE_LISTAGENS_VENDAS_ARTIGO: IModuleDefinition = {
  name: MODULE_NAME_LISTAGENS_VENDAS_ARTIGO,
  state: {
    url: '/listagens/vendas/artigo',
    component: VendasArtigoModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.vendasartigo'
    },
    resolve: [
      {
        provide: 'listagensArtigos',
        deps: [ListagensService],
        useFactory: (listagensService: ListagensService): Promise<IJsonDashboardsPercentagem> => {
          const startOfYear = moment().startOf('year');
          const endOfYear = moment().endOf('year');
          return listagensService.listagensArtigos(startOfYear, endOfYear).then((response: HttpResponse<IJsonDashboardsPercentagem>) => response.body);
        }
      }
    ]
  }
};
