import {IApiQueryResponse} from '../../services/api/api.service.interface';
import {ENTITY_NAME_TEMPLATES, ITemplatesEntity} from './templates.entity.interface';
import {IJsonTemplateVar} from './jsonTemplateConfig.entity.interface';
import {ROLE} from '../../services/role.const';
import {TemplatesDetailComponent} from './components/detail/templates.entity.detail.component';

export const ENTITY_TEMPLATES: ITemplatesEntity = {
  name: ENTITY_NAME_TEMPLATES,
  pageTitle: 'global.menu.templates',
  icon: 'fa-drivers-license',
  roles: [ROLE.ADMIN],
  metadata: {
    keyName: 'name',
    fields: [
      {name: 'name', caption: 'templates.fields.name', validators: {required: true}},
      {name: 'subject', caption: 'templates.fields.subject', validators: {required: true}},
      {name: 'body', caption: 'templates.fields.body', validators: {required: true}}
    ],
    order: 'name',
    listFields: 'name,subject',
    searchFields: 'name,subject,body'
  },
  autocomplete: {
    rowTemplate: '{{name}} - {{subject}}',
    output: 'name',
    searchFields: 'name,subject,body'
  },
  actions: {
    new: false,
    detail: true,
    edit: true,
    delete: false,
    search: true
  },
  detail: {
    state: {
      component: TemplatesDetailComponent
    }
  },
  service: function () {
    this.getVariaveis = (nomeTemplate: string) => {
      return this.get<IApiQueryResponse<IJsonTemplateVar>>({id: `${nomeTemplate}/variaveis`});
    };
  }
};
