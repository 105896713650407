import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {RawParams, TransitionOptions} from '@uirouter/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isObject, TOrientation} from 'pl-comps-angular';
import {CGDeletePromptModalComponent} from '../../cg/modal/deleteprompt/deleteprompt.modal.component';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {IApiRequestConfig, IApiRequestConfigWithBody} from '../../../services/api/api.service.interface';
import {IEntityDetailCallback} from '../../entity/detail/entity.detail.component.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IModuleEntityDetailActionDuplicateOptions} from '../module.definition.interface';
import {ModuloDetailComponent} from '../module.detail.component';

@Component({
  selector: 'modulo-entity-detail',
  templateUrl: './module.entitydetail.component.html'
})
export class ModuloEntityDetailComponent<TJson extends object, TEntityService extends IEntityService<TJson> = IEntityService<TJson>>
  extends ModuloDetailComponent<TJson, TEntityService>
  implements OnInit
{
  public readonly callback: IEntityDetailCallback<TJson>;
  public formOrientation: TOrientation;

  protected readonly _cgModalService: CGModalService;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    this.callback = {};
    this.save = this.save.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.click = () => this.save();
    this.btnEdit.click = () => {
      this.edit();
    };
    this.btnCancel.click = () => this.cancel();
    this.btnNovo.click = () => this.novo();
    this.btnDelete.click = () => this.delete();
    this.btnDuplicate.click = () => this.duplicate();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    super.onUpdate(stateType);
    const params: unknown = {};
    params[this._entityState] = stateType;
    return this._stateService.go(this._uiRouterGlobals.current, params, {supercede: false}).then(() => undefined);
  }

  public save(config?: IApiRequestConfigWithBody<TJson>): Promise<TJson> {
    const promise: Promise<TJson> = (async () => {
      const response: TJson = await this.callback.save(config);
      if (this.type === EEntityStateDetailType.NEW) {
        if (this.entity.redirectKey) {
          await this._stateService.go(this.states.detail.name, {id: response[this.entity.redirectKey]});
        }
      } else if (!this.entity.actions.detail) {
        await this.back();
      } else if (this.entity.actions.batchAdd && this._previousType === EEntityStateDetailType.NEW) {
        this.btnNovo.visible = true;
      }
      return response;
    })();
    this._subjectOnSave.next(promise);
    return promise;
  }

  public edit(): void {
    this.callback.edit();
    this._subjectOnEdit.next();
  }

  public cancel(): Promise<void> {
    this.callback.cancel();
    this._subjectOnCancel.next(undefined);
    return Promise.resolve();
  }

  public novo(params?: RawParams): Promise<void> {
    return this._stateService.go(this.states.new, params, {reload: true, inherit: false}).then(() => {
      this._subjectOnNew.next();
    });
  }

  public beforeDelete(): Promise<void | IApiRequestConfig> {
    const {title, message} = this.entity.getDeleteMessage(this.model, this._translateService);
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(CGDeletePromptModalComponent, {size: 'md'});
    const componentInstance: CGDeletePromptModalComponent = modalInstance.componentInstance;
    componentInstance.title = title;
    componentInstance.message = message;
    return modalInstance.result;
  }

  public delete(config?: IApiRequestConfig): Promise<void> {
    const promise: Promise<void> = (async () => {
      const promptConfig: IApiRequestConfig = <IApiRequestConfig>await this.beforeDelete();
      if (isObject(promptConfig)) {
        config = {...config, ...promptConfig};
      }
      const response: HttpResponse<TJson> = <HttpResponse<TJson>>await this.callback.delete(config);
      await Promise.resolve(this.afterDelete(response));
    })();
    this._subjectOnDelete.next(promise);
    return promise;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async afterDelete(response: HttpResponse<TJson>): Promise<void> {
    if (!this.maintenanceMode) {
      await this.back();
    } else {
      this.resetModel();
    }
  }

  public duplicate(options?: IModuleEntityDetailActionDuplicateOptions): Promise<void> {
    const actionOptions: IModuleEntityDetailActionDuplicateOptions = isObject(options) ? options : {};
    const promise: Promise<void> = (async () => {
      const response: TJson = this.callback.duplicate();
      if (!this.maintenanceMode) {
        let transitionOptions: TransitionOptions;
        if (this._previousType === EEntityStateDetailType.NEW) {
          transitionOptions = {reload: true, inherit: false};
        }
        transitionOptions = {...transitionOptions, ...actionOptions.transitionOptions};
        await this._stateService.go(this.states.new.name, {model: response, ...actionOptions.params}, transitionOptions);
      } else {
        this.model = response;
        this.callback.update(EEntityStateDetailType.NEW);
      }
    })();
    this._subjectOnDuplicate.next(promise);
    return promise;
  }

  public refresh(): void {
    this.callback.refresh();
    this._subjectOnRefresh.next();
  }

  protected _faqsName(): string {
    return this.entity.name;
  }
}
