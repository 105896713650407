import {Component, EventEmitter, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {TranslateService} from '@ngx-translate/core';
import {PagamentosService} from '../../service/pagamentos.entity.service';
import {PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'pagamento-sepaanular-modal',
  templateUrl: './pagamento.sepaAnular.modal.component.html'
})
export class PagamentoSepaAnularModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public extPocCabID: string;
  @Input() public evtRefresh: EventEmitter<void>;
  public readonly dataGridPagamentosLoteDefinition: IDevExpressDataGrid;
  public readonly title: string;
  public readonly message: string;
  private _dataGridPagamentosLoteInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _pagamentosService: PagamentosService
  ) {
    super(_injector);
    this.title = 'pagamentos.info.anularpagamento';

    this.dataGridPagamentosLoteDefinition = {
      columns: [
        {dataField: 'numero', dataType: 'string', caption: 'pagamentos.fields.numero', allowEditing: false},
        {dataField: 'nConta', dataType: 'string', caption: 'pagamentos.fields.nconta', allowEditing: false},
        {dataField: 'nome', dataType: 'string', caption: 'pagamentos.fields.nome', allowEditing: false},
        {dataField: 'data', dataType: 'date', caption: 'global.text.date', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'global.text.total', allowEditing: false},
        {dataField: 'abreviaturaMoeda', dataType: 'string', caption: 'pagamentos.fields.abreviaturaMoeda', allowEditing: false}
      ],
      dataSource: [],
      columnHidingEnabled: false
    };

    this.message = this._translateService.instant('pagamentos.info.avisoAnularPagamSepa');
  }

  public ngOnInit(): void {
    this._pagamentosService.getPagamentosNoFicheiroSEPA(this.extPocCabID).then((response) => {
      this.dataGridPagamentosLoteDefinition.dataSource = response.body.list;
    });
  }

  public close(): void {
    this._pagamentosService.anular(this.extPocCabID).then((response) => {
      if (response.ok) {
        this.evtRefresh.emit();
        this._plAlertService.success('pagamentos.info.anular');
        super.close();
      }
    });
  }

  public dismiss(): void {
    super.dismiss();
  }

  public onGridPagamentosLoteInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridPagamentosLoteInstance = component;
  }

  public onGridPagamentosLoteContentReady(): void {
    this.dataGridPagamentosLoteInstance.endCustomLoading();
  }

  public get dataGridPagamentosLoteInstance(): dxDataGrid {
    return this._dataGridPagamentosLoteInstance;
  }
}
