import {Component, OnInit} from '@angular/core';
import {ESolicTipoTexto, IJsonSOLIC} from '../../jsonSolic.entity.interface';
import {IApiRequestConfig} from '../../../../services/api/api.service.interface';
import {ISOLICRequest} from '../../solic.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'solic-edit',
  templateUrl: './solic.entity.edit.component.html'
})
export class SOLICEntityEditComponent extends ModuloEntityDetailComponent<IJsonSOLIC> implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
    this.model = {
      tipoTexto: ESolicTipoTexto.Cabecalho,
      nTexto: undefined,
      tituloTexto: '',
      nDiasAtraso: undefined,
      note: '',
      ...this.model
    };
  }

  public delete(config?: IApiRequestConfig): Promise<void> {
    const params: ISOLICRequest = {tipotexto: this.model.tipoTexto};
    return super.delete({...config, params: params});
  }
}
