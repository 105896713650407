import {Component, Injector, Input} from '@angular/core';
import {IJsonPrevTesObra} from '../../jsonPrevTesObras.module.interface';
import {HttpResponse} from '@angular/common/http';
import {PrevTesObrasService} from '../../prevTesObras.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-prevtesobras-rubrica',
  templateUrl: './prevTesObras.rubrica.modal.component.html'
})
export class PrevTesObrasRubricaModalComponent extends CGModalComponent<Array<IJsonPrevTesObra>> {
  @Input() public prevTesObrasList: Array<IJsonPrevTesObra>;
  @Input() public codTipoPlaneamentoDe: number;
  @Input() public codTipoPlaneamentoAte: number;
  @Input() public codEstadoObraDe: string;
  @Input() public codEstadoObraAte: string;
  public rubrica: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _prevTesObrasService: PrevTesObrasService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.rubrica = 0;
  }

  public close(): Promise<void> {
    if (!this.rubrica) {
      this._plAlertService.error('prevtesobras.rubricanull');
      return Promise.resolve();
    }
    return this._prevTesObrasService
      .importPrevTesObras(this.codTipoPlaneamentoDe, this.codTipoPlaneamentoAte, this.codEstadoObraDe, this.codEstadoObraAte, this.rubrica, this.prevTesObrasList)
      .then((response: HttpResponse<Array<IJsonPrevTesObra>>) => {
        this._plAlertService.success('prevtesobras.importsuccess');
        return super.close(response.body);
      });
  }
}
