<div class="saft-container">
  <div class="blocked-by-other-user-container" *ngIf="isBlocked">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>

    <div class="block-inline">
      <h5><span [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></span>:</h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-tabs *ngIf="!isBlocked" [theme]="'style3'" [activeId]="tabActiveId" (evtSelected)="onTabChanged($event)">
    <pl-tab caption="saft.tabProcess" [id]="tabProcess">
      <div *plTabContent>
        <ng-container *ngIf="currentStep === saftSteps.Configuration">
          <p class="text-danger"><strong [translate]="'saft.warningMessage'"></strong></p>

          <div *ngIf="exportSaftType === saftType.TODOS">
            <pl-group>
              <edit>
                <pl-edit type="radiogroup" [(model)]="model.type" [properties]="{groupItems: typeGroupItems}"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="saft-block">
            <ng-container *ngIf="model.type === saftType.CONTABILIDADE_FATURACAO_RECIBOS">
              <div>
                <h4 [translate]="'saft.documentos'"></h4>
              </div>

              <pl-group class="saft-inline-block pl-group-no-label" *ngIf="exportContabilidade">
                <edit>
                  <pl-edit type="checkbox" [(model)]="model.contabilidade" [properties]="{label: 'saft.contabilidade'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group class="saft-inline-block pl-group-no-label" *ngIf="exportFaturacao">
                <edit>
                  <pl-edit type="checkbox" [(model)]="model.faturacao" [properties]="{label: 'saft.faturacao'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group class="saft-inline-block pl-group-no-label" *ngIf="exportFaturacao">
                <edit>
                  <pl-edit type="checkbox" [(model)]="model.recibos" [properties]="{label: 'saft.recibos'}"></pl-edit>
                </edit>
              </pl-group>
            </ng-container>

            <ng-container *ngIf="model.type === saftType.AUTOFATURACAO">
              <div>
                <h4 [translate]="'saft.nConta'"></h4>
              </div>

              <pl-group style="width: 300px">
                <edit>
                  <pl-autocomplete
                    [model]="model"
                    [source]="fornecedoresAfSource"
                    [rowTemplate]="fornecedoresRowTemplate"
                    (modelChange)="fornecedoresAfChanged($event)"
                    [allowInvalid]="false"
                    [output]="fornecedoresOutput">
                  </pl-autocomplete>
                </edit>
              </pl-group>
            </ng-container>
          </div>

          <div class="saft-block">
            <div class="saft-inline-block">
              <div><h4 [translate]="'global.text.year'"></h4></div>
              <div ngbDropdown container="body">
                <button type="button" class="btn btn-primary" ngbDropdownToggle>{{ model.ano }}</button>
                <div ngbDropdownMenu>
                  <button type="button" *ngFor="let item of anosSource" ngbDropdownItem (click)="model.ano = item">{{ item }}</button>
                </div>
              </div>
            </div>

            <div class="saft-inline-block">
              <div><h4 [translate]="'global.text.month'"></h4></div>
              <div ngbDropdown container="body">
                <button type="button" class="btn btn-primary" ngbDropdownToggle>{{ model.mes.name | translate }}</button>
                <div ngbDropdownMenu>
                  <button type="button" *ngFor="let item of mesesSource" ngbDropdownItem [translate]="item.name" (click)="onMesChange(item)"></button>
                </div>
              </div>
            </div>
          </div>

          <div class="saft-block">
            <div>
              <h4 style="border-bottom-color: transparent">&nbsp;</h4>
            </div>

            <pl-button type="button" klass="btn btn-success" [onClick]="fnVisualProcess" [disabled]="model.type === saftType.AUTOFATURACAO && !model.nConta">
              <i class="fa fa-fw fa-bolt" aria-hidden="true"></i><span [translate]="'saft.processar'"></span>
            </pl-button>
          </div>
        </ng-container>

        <div class="row" *ngIf="currentStep === saftSteps.Processing">
          <div class="col-md-12">
            <div class="saft-pb-container">
              <div class="saft-pb-label" [translate]="'saft.processingMessage'"></div>

              <div class="progress">
                <div
                  class="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [attr.aria-valuenow]="pbPos"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [style.width.%]="pbPos"></div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="saft-pb-label-op" [translate]="statusDescription"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === saftSteps.Errors">
          <div>
            <h4 [translate]="'saft.erros'"></h4>
          </div>

          <pl-alert type="error" [closeable]="false" *ngFor="let error of statusErrorList">
            <i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<strong [translate]="error.nome"></strong>:&nbsp;<span [translate]="error.descricao"></span>
          </pl-alert>

          <pl-button klass="btn-light" type="button" (evtClicked)="closeErrorPanel()">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <span [translate]="'saft.buttons.fechar'"></span>
          </pl-button>
        </div>
      </div>
    </pl-tab>

    <pl-tab caption="saft.tabDownload" [id]="tabDownload" [disabled]="!currentStatus.ficheiroProcessado">
      <div *plTabContent>
        <label class="d-block" [translate]="'saft.fileReady'"></label>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'saft.dataCriacao'"></span>:<strong>{{ currentStatus.ficheiroProcessadoDate | cgcDateTime }}</strong>
        </div>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'saft.tamanho'"></span>: <strong>{{ formatBytes(currentStatus.ficheiroProcessadoSize) }}</strong>
        </div>

        <a class="btn btn-link" target="_blank" (click)="doDownload()"> <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'saft.buttons.download'"></span> </a>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'saft.utilizador'"></span>: <strong>{{ currentStatus.userStarted }}</strong>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
