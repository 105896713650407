import {Component, Injector, OnInit} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditConfigERP} from '../../components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'module-configs-erp-rh',
  templateUrl: './configs.rh.module.component.html'
})
export class ConfigsErpRHModuleComponent extends ConfigsErpBaseModuleComponent implements OnInit {
  public dataGridConfigRH: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.dataGridConfigRH = {
      columns: [
        {dataField: 'description', caption: 'entity.detail.field', dataType: 'string', allowEditing: false},
        {caption: 'entity.detail.value', dataType: 'string', editCellTemplate: 'cellTemplateValues', showEditorAlways: true, allowEditing: true, alignment: 'center'}
      ],
      allowColumnReordering: false,
      allowColumnResizing: true,
      columnAutoWidth: true,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      editing: {mode: 'cell', allowUpdating: true},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: true},
      showBorders: true,
      showColumnLines: true,
      sorting: {mode: 'none'}
    };
  }

  public readonly fnOnValidateReport = (): Promise<IJsonConfigERP> => this._onValidateReport();

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Multiple;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP> {
    return rawConfigurations
      .filter((item) => item.name === 'pca.reportrecibosal')
      .map<IConfigsErpEditConfigERP>((item: IJsonConfigERP, index: number) => {
        item.cguid = 'reports_recibossal';
        const transformedItem: IConfigsErpEditConfigERP = {...item, saveField: this.fnSaveField, index: index};
        return transformedItem;
      });
  }

  private _onValidateReport(): Promise<IJsonConfigERP> {
    const object: IConfigsErpEditConfigERP = <IConfigsErpEditConfigERP>this.configList.find((item: IConfigsErpEditConfigERP) => item.name === 'pca.reportrecibosal');
    return this.saveField(object);
  }
}
