import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {DefinicaoEmailService} from './definicaoEmail.service';
import {DefinicaoEmailsModalComponent} from '../../../modals/definicaoemails/definicaoEmails.modal.component';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonClifoExtraValue} from '../../../../clifoextravalue/jsonClifoExtraValue.entity.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'clifo-definicao-emails',
  templateUrl: './clifoDefinicaoEmails.component.html'
})
export class ClifoDefinicaoEmailsComponent {
  @Input() public nConta: string;
  @Input() public readonly: boolean;
  @Input() public hideRefresh: boolean;

  public readonly dataGrid: IDevExpressDataGrid;

  private _dataGridInstance: dxDataGrid<IJsonClifoExtraValue, number>;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _definicaoEmailService: DefinicaoEmailService
  ) {
    this.hideRefresh = false;
    this.readonly = false;
    this.dataGrid = {
      keyExpr: 'codigo',
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'clifoExtraValues.table.descricao'},
        {dataField: 'valor', dataType: 'string', caption: 'clifoExtraValues.table.valor'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: new CustomStore({
        load: () => this._getList()
      }),
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: true},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      searchPanel: {visible: true},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarAddEmail',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
  }

  public async addNew(item?: IJsonClifoExtraValue): Promise<void> {
    const definicoesExistentes: Array<IJsonClifoExtraValue> = <Array<IJsonClifoExtraValue>>await this._dataGridInstance.getDataSource().store().load();
    const modalInstance = this._cgModalService.showVanilla(DefinicaoEmailsModalComponent);
    const componentInstance: DefinicaoEmailsModalComponent = modalInstance.componentInstance;
    componentInstance.definicoesExistentes = definicoesExistentes.slice();
    componentInstance.nConta = this.nConta;
    if (item) {
      componentInstance.definicaoEmail = item;
    }
    return modalInstance.result.then((result: IJsonClifoExtraValue) => {
      return this._definicaoEmailService.post(result).then(() => {
        this._plAlertService.success('clifoExtraValues.save');
        return this._dataGridInstance.refresh().then(() => undefined);
      });
    });
  }

  public onDataGridInitializedEmails(event: IDevExpressDataGridEventOnInitialized<IJsonClifoExtraValue, number>): void {
    this._dataGridInstance = event.component;
  }

  public readonly fnRefreshData = (): Promise<void> => this._refreshData();

  public readonly fnAddNew = (item: IJsonClifoExtraValue) => (): Promise<void> => this.addNew(item);

  public readonly fnDelete = (item: IJsonClifoExtraValue) => (): Promise<void> => this._delete(item);

  private _getList(): Promise<Array<IJsonClifoExtraValue>> {
    return this._definicaoEmailService.query(`NCONTA=${this.nConta}`).then((response: HttpResponse<IApiQueryResponse<IJsonClifoExtraValue>>) => response.body.list);
  }

  private _refreshData(): Promise<void> {
    return this._dataGridInstance.refresh().then(() => undefined);
  }

  private _delete(item: IJsonClifoExtraValue): Promise<void> {
    return this._cgModalService.showOkCancel('clifoExtraValues.modalConfirmDelete.title', 'clifoExtraValues.modalConfirmDelete.message').then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._definicaoEmailService
        .delete(item)
        .then(() => this._dataGridInstance.refresh().then(() => undefined))
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    });
  }
}
