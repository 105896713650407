import {merge} from 'lodash-es';
import {Subject, Subscription} from 'rxjs';
import {Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlFormFieldValueChangedEvent, isArray, isEmpty, isFunction, isNumber, isObject, isString, Logger, PlAutocompleteCache, isUndefinedOrNull} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {CGInjector} from '../../../common/injectors/cginjector';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {EntityRegistryService} from '../../components/entity/entity.registry.service';
import {
  IApiQueryRequestConfig,
  IApiQueryResponse,
  IApiRequestConfig,
  IApiRequestConfigWithBody,
  IApiService,
  IApiUploadRequestConfig,
  THttpQueryResponse,
  TServiceQueryResponse,
  TServiceResponse
} from '../api/api.service.interface';
import {
  IEntityService,
  IEntityServiceBuilder,
  IEntityServiceExtendableEvents,
  IEntityServiceQueryRequestConfig,
  IEntityWatchFields,
  TEntityServiceEventType,
  TEntityServiceRequestCallback,
  TEntityServiceRequestData,
  TEntityServiceRequestDataWithBody,
  TEntityServiceResponseCallback,
  TEntityWatchFieldCallback
} from './entity.service.interface';
import {IEntity} from '../../components/entity/entity.definition.interface';

export class EntityService<T extends object> implements IEntityService<T> {
  public readonly entity: IEntity<T, IEntityService<T>>;
  public events?: IEntityServiceExtendableEvents<T>;
  public onInit?: (model?: T, type?: EEntityStateDetailType) => void;

  protected readonly _url: string;
  protected readonly _entityUrl: string;
  protected _watchers?: IEntityWatchFields<T>;

  private readonly _logger: Logger;
  private readonly _apiService: ApiService;
  private readonly _entityRegistryService: EntityRegistryService;
  private readonly _autocompleteCache: PlAutocompleteCache;
  private _subscriptionWatchers: Subscription;
  private _callbackOnBefore: TEntityServiceRequestCallback<unknown>;
  private _callbackOnAfter: TEntityServiceResponseCallback<unknown>;

  constructor({logger, apiService, entityRegistryService, autocompleteCache, entityOrName}: IEntityServiceBuilder<T>) {
    this._logger = logger;
    this._apiService = apiService;
    this._entityRegistryService = entityRegistryService;
    this._autocompleteCache = autocompleteCache;

    if (isString(entityOrName)) {
      this.entity = this._entityRegistryService.getEntity(entityOrName);
      if (!this.entity) {
        throw new Error(`Entity ${entityOrName} not found`);
      }
    } else {
      this.entity = entityOrName;
    }
    this._url = this.entity.name;
    if (!isEmpty(this.entity.entityUrl)) {
      this._url = this.entity.entityUrl;
    } else if (!isEmpty(this.entity.url)) {
      this._url = this.entity.url;
    }
    this._entityUrl = `${this._apiService.path.restapi}/${this._url}`;
  }

  public entityUrl(): string {
    return this._entityUrl;
  }

  public observe<TNewValue>(fieldName: string & keyof T, callback: TEntityWatchFieldCallback<TNewValue, T>): void {
    if (!isObject(this._watchers)) {
      this._watchers = {};
    }
    this._watchers[fieldName] = callback;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public runObservers(subscriber: Subject<IPlFormFieldValueChangedEvent<any>>, model: () => any, callback: (returnValue: any | Promise<void>) => void): void {
    this.clearObservers();
    if (isObject(this._watchers)) {
      this._subscriptionWatchers = subscriber.subscribe((event: IPlFormFieldValueChangedEvent<unknown>) => {
        const watcherCallback: TEntityWatchFieldCallback<unknown, T> = this._watchers[event.field];
        if (isFunction(watcherCallback)) {
          const result: unknown | Promise<unknown> = watcherCallback(event.value, model());
          if (isFunction(callback)) {
            try {
              callback(result);
            } catch (exception: unknown) {
              this._logger.error(exception);
            }
          }
        }
      });
    }
  }

  public clearObservers(): void {
    if (this._subscriptionWatchers) {
      this._subscriptionWatchers.unsubscribe();
      this._subscriptionWatchers = undefined;
    }
  }

  public query<TResponse = T>(config?: IEntityServiceQueryRequestConfig): TServiceQueryResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }

    if (config.filtro) {
      if (config.pesquisa) {
        config.pesquisa += `&${config.filtro}`;
      } else {
        config.pesquisa = config.filtro;
      }
    }

    const skip = Number(config.skip);
    const take = Number(config.take);
    const modernPagination: boolean = isNumber(skip) && !Number.isNaN(skip) && isNumber(take) && !Number.isNaN(take);
    const pagina = !modernPagination ? Number(config.pagina) : undefined;
    const porPagina = !modernPagination ? Number(config.porpagina) : undefined;

    let request: IApiQueryRequestConfig = merge<object, IEntityServiceQueryRequestConfig, IEntityServiceQueryRequestConfig>({}, config, {
      url: this._buildUrl(config.url),
      params: {
        pesquisa: config.pesquisa,
        ordena: config.ordena,
        skip: !modernPagination ? undefined : skip,
        take: !modernPagination ? undefined : take,
        pagina: modernPagination || Number.isNaN(pagina) ? undefined : pagina,
        porpagina: modernPagination || Number.isNaN(porPagina) ? undefined : porPagina,
        campospesq: config.campospesq
      }
    });

    request = this._onBefore(request);

    return this._apiService.query(request).then((response: THttpQueryResponse<TResponse>) => {
      this._wrappersOnQuery(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public get<TResponse = T>(config?: TEntityServiceRequestData): TServiceResponse<TResponse> {
    // Entity service GET method must have an [id] or [url] property
    if (!isObject(config) || (!config.url && !config.id && config.id !== 0)) {
      throw new Error(
        'Entity service [GET] method called but an [id] or [url] was not provided. This can have unexpected results such as `query` method being called without pagination parameters which ' +
          'can be very impactul on the server, aborting request.'
      );
    }
    if (config.url) {
      config.url = `${this._entityUrl}/${config.url}`;
    }
    if (config.id || config.id === 0) {
      config.url = `${this._entityUrl}/${encodeURI(String(config.id))}`;
    }
    config = this._onBefore(config);
    return this._apiService.get(config).then((response: HttpResponse<TResponse>) => {
      this._wrappersOnLoad(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public post<TResponse = T, TRequest = TResponse>(config?: IApiRequestConfigWithBody<TRequest>): TServiceResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }
    config.url = this._buildUrl(config.url);
    this._wrappersOnBeforePost(config.body);
    config = this._onBefore(config);
    return this._apiService.post<TResponse, TRequest>(config).then((response: HttpResponse<TResponse>) => {
      this._autocompleteCache.delete(this.entity.name);
      this._wrappersOnAfterPost(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public put<TResponse = T, TRequest = TResponse>(config?: TEntityServiceRequestDataWithBody<TRequest>): TServiceResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }
    config.url = config.url ? `${this._entityUrl}/${config.url}` : config.id || config.id === 0 ? `${this._entityUrl}/${encodeURI(String(config.id))}` : this._entityUrl;
    this._wrappersOnBeforePut(config.body);
    config = this._onBefore(config);
    return this._apiService.put<TResponse, TRequest>(config).then((response: HttpResponse<TResponse>) => {
      this._autocompleteCache.delete(this.entity.name);
      this._wrappersOnAfterPut(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public patch<TResponse = T, TRequest = TResponse>(config?: TEntityServiceRequestDataWithBody<TRequest>): TServiceResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }
    config.url = config.url ? `${this._entityUrl}/${config.url}` : config.id || config.id === 0 ? `${this._entityUrl}/${encodeURI(String(config.id))}` : this._entityUrl;
    this._wrappersOnBeforePatch(config.body);
    config = this._onBefore(config);
    return this._apiService.patch<TResponse, TRequest>(config).then((response: HttpResponse<TResponse>) => {
      this._autocompleteCache.delete(this.entity.name);
      this._wrappersOnAfterPatch(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public delete<TResponse = void, TRequest = TResponse>(config?: TEntityServiceRequestDataWithBody<TRequest>): TServiceResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }
    config.url = config.url ? `${this._entityUrl}/${config.url}` : config.id || config.id === 0 ? `${this._entityUrl}/${encodeURI(String(config.id))}` : this._entityUrl;
    this._wrappersOnBeforeDelete(config.body);
    config = this._onBefore(config);
    return this._apiService.delete<TResponse, TRequest>(config).then((response: HttpResponse<TResponse>) => {
      this._autocompleteCache.delete(this.entity.name);
      this._wrappersOnAfterDelete(response.body);
      response = this._onAfter(response);
      return response;
    });
  }

  public upload<TResponse = T, TRequest extends FormData = FormData>(config?: IApiUploadRequestConfig<TRequest>): TServiceResponse<TResponse> {
    if (!isObject(config)) {
      config = {};
    }
    config.url = this._buildUrl(config.url);
    const method = config.method;
    if (method === 'PUT') {
      this._wrappersOnBeforePut(config.body);
    } else {
      this._wrappersOnBeforePost(config.body);
    }
    config = this._onBefore(config);
    return this._apiService.upload<TResponse, TRequest>(config).then((response) => {
      if (method === 'PUT') {
        this._wrappersOnAfterPut(response.body);
      } else {
        this._wrappersOnAfterPost(response.body);
      }
      response = this._onAfter(response);
      return response;
    });
  }

  public setOnBefore<TRequest = T>(callback: TEntityServiceRequestCallback<TRequest>): void {
    if (isFunction(callback)) {
      this._callbackOnBefore = callback;
    }
  }

  public setOnAfter<TRequest = T>(callback: TEntityServiceResponseCallback<TRequest>): void {
    if (isFunction(callback)) {
      this._callbackOnAfter = callback;
    }
  }

  public invokeEntity(injector?: Injector): void {
    if (isFunction(this.entity.service)) {
      this.invoke(this.entity.service, undefined, injector);
    } else if (isArray(this.entity.serviceInjectable)) {
      this.invoke(this.entity.serviceInjectable, undefined, injector);
    }
    this.events = {};
    if (isFunction(this.entity.events)) {
      this.invoke(this.entity.events, this.events, injector);
    } else if (isArray(this.entity.eventsInjectable)) {
      this.invoke(this.entity.eventsInjectable, this.events, injector);
    }
  }

  // eslint-disable-next-line consistent-this,@typescript-eslint/no-explicit-any
  public invoke(fn: Function | Array<any>, self: any = this, injector?: Injector): void {
    if (isFunction(fn)) {
      fn.call(self);
    } else if (isArray(fn)) {
      CGInjector.invoke(fn, self, injector);
    }
  }

  public get apiService(): IApiService {
    return this._apiService;
  }

  private _onBefore<S extends IApiRequestConfig>(request: S): S {
    if (this._callbackOnBefore) {
      const mutatedRequest: S = <S>this._callbackOnBefore(request);
      if (mutatedRequest) {
        request = mutatedRequest;
      }
    }
    return request;
  }

  private _onAfter<TResponse = T>(response: HttpResponse<TResponse>): HttpResponse<TResponse> {
    if (this._callbackOnAfter) {
      const mutatedResponse: HttpResponse<TResponse> = <HttpResponse<TResponse>>this._callbackOnAfter(response);
      if (mutatedResponse) {
        response = mutatedResponse;
      }
    }
    return response;
  }

  private _wrappersOnQuery<TResponse = T>(body: IApiQueryResponse<TResponse>): void {
    if (isFunction(this.events?.onQuery)) {
      this.events.onQuery(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnLoad<TResponse = T>(body: TResponse): void {
    if (isFunction(this.events?.onLoad)) {
      this.events.onLoad(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnBefore<TResponse = T>(type: TEntityServiceEventType, body: TResponse): void {
    if (isFunction(this.events?.onBefore)) {
      this.events.onBefore(type, body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnAfter<TResponse = T>(type: TEntityServiceEventType, body: TResponse): void {
    if (isFunction(this.events?.onAfter)) {
      this.events.onAfter(type, body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnBeforePost<TResponse = T>(body: TResponse): void {
    this._wrappersOnBefore('post', body);
    if (isFunction(this.events?.onBeforePost)) {
      this.events.onBeforePost(body);
    }
    if (isFunction(this.events?.onBeforeSave)) {
      this.events.onBeforeSave(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnAfterPost<TResponse = T>(body: TResponse): void {
    this._wrappersOnAfter('post', body);
    if (isFunction(this.events?.onAfterPost)) {
      this.events.onAfterPost(body);
    }
    if (isFunction(this.events?.onAfterSave)) {
      this.events.onAfterSave(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnBeforePut<TResponse = T>(body: TResponse): void {
    this._wrappersOnBefore('put', body);
    if (isFunction(this.events?.onBeforePut)) {
      this.events.onBeforePut(body);
    }
    if (isFunction(this.events?.onBeforeSave)) {
      this.events.onBeforeSave(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnAfterPut<TResponse = T>(body: TResponse): void {
    this._wrappersOnAfter('put', body);
    if (isFunction(this.events?.onAfterPut)) {
      this.events.onAfterPut(body);
    }
    if (isFunction(this.events?.onAfterSave)) {
      this.events.onAfterSave(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnBeforePatch<TResponse = T>(body: TResponse): void {
    this._wrappersOnBefore('patch', body);
    if (isFunction(this.events?.onBeforePatch)) {
      this.events.onBeforePatch(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnAfterPatch<TResponse = T>(body: TResponse): void {
    this._wrappersOnAfter('patch', body);
    if (isFunction(this.events?.onAfterPatch)) {
      this.events.onAfterPatch(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnBeforeDelete<TResponse = T>(body: TResponse): void {
    this._wrappersOnBefore('delete', body);
    if (isFunction(this.events?.onBeforeDelete)) {
      this.events.onBeforeDelete(body);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private _wrappersOnAfterDelete<TResponse = T>(body: TResponse): void {
    this._wrappersOnAfter('delete', body);
    if (isFunction(this.events?.onAfterDelete)) {
      this.events.onAfterDelete(body);
    }
  }

  private _buildUrl(url: string): string {
    if (isUndefinedOrNull(url)) {
      return this._entityUrl;
    }
    return url.startsWith(this._entityUrl) ? url : `${this._entityUrl}/${url}`;
  }
}
