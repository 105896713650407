import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_GTO_MOSTRAR_FILTER} from '../gto.datasources.interface';
import {EGTOMostrarFilter} from '../../../modules/portalcontabilidade/gto/jsonGTO.module.interface';

export const DATA_SOURCE_GTO_MOSTRAR_FILTER: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_GTO_MOSTRAR_FILTER,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: '{{name}}'
  },
  data: [
    {value: EGTOMostrarFilter.DataLimite, name: 'gto.datasources.mostrarfilter.datalimite'},
    {value: EGTOMostrarFilter.DataLimitePlusNotes, name: 'gto.datasources.mostrarfilter.datalimitePlusNotes'},
    {value: EGTOMostrarFilter.Values, name: 'gto.datasources.mostrarfilter.values'},
    {value: EGTOMostrarFilter.ValuesPlusNotes, name: 'gto.datasources.mostrarfilter.valuesPlusNotes'},
    {value: EGTOMostrarFilter.Dias, name: 'gto.datasources.mostrarfilter.dias'},
    {value: EGTOMostrarFilter.DiasPlusNotes, name: 'gto.datasources.mostrarfilter.diasPlusNotes'},
    {value: EGTOMostrarFilter.DataExecucao, name: 'gto.datasources.mostrarfilter.dataexecucao'},
    {value: EGTOMostrarFilter.DataExecucaoPlusNotes, name: 'gto.datasources.mostrarfilter.dataexecucaoPlusNotes'}
  ]
});
