import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {StateService, Transition} from '@uirouter/core';
import {PlAlertService} from 'pl-comps-angular';
import {AccountComponent} from '../account.state.component';
import {AuthService} from '../../../services/auth/auth.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGValidators} from '../../../../common/forms/validators';
import {FORM_INVALID_CANNOT_SUBMIT, validatePasswordSecureMode} from '../../../../config/constants';
import {ICGValidatorWithSubscription} from '../../../../common/forms/validators.interface';
import {IChangePasswordRequest, IChangePasswordStateParams} from './changepassword.interface';
import {IJsonRecaptchaConfig} from '../../../interfaces/jsonConfigValue.interface';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';

const MIN_LENGTH = 5;

@Component({
  selector: 'change-password',
  templateUrl: './changepassword.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input() public recaptchaConfig: IJsonRecaptchaConfig;
  @Input() public passwordSecureMode: boolean;

  public recaptchaActive: boolean;
  public form: UntypedFormGroup;

  private readonly _confirmPasswordValidator: ICGValidatorWithSubscription;
  private _submitted: boolean;

  constructor(
    private readonly _transition: Transition,
    private readonly _stateService: StateService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _accountComponent: AccountComponent
  ) {
    this._confirmPasswordValidator = CGValidators.equals('password');
    this._submitted = false;
  }

  public ngOnInit(): void {
    const passwordValidators: Array<ValidatorFn> = [Validators.required];
    if (!this.passwordSecureMode) {
      passwordValidators.push(Validators.minLength(MIN_LENGTH));
    } else {
      passwordValidators.push(CGValidators.securePassword);
    }
    this.recaptchaActive = this.recaptchaConfig.active;
    this.form = this._formBuilder.group({
      password: this._formBuilder.control('', passwordValidators),
      confirmPassword: this._formBuilder.control('', [Validators.required, this._confirmPasswordValidator.validatorFn])
    });
    if (this.recaptchaActive) {
      this.form.addControl('recaptcha', this._formBuilder.control('', [Validators.required]));
    }
  }

  public ngOnDestroy(): void {
    this._confirmPasswordValidator.subscription.unsubscribe();
  }

  public hasError(control: string, error: string): boolean {
    return (this._submitted || !this.form.controls[control].pristine) && this.form.controls[control].hasError(error);
  }

  public readonly fnChangePassword: () => Promise<void> = () => this._changePassword();

  private _changePassword(): Promise<void> {
    this._submitted = true;
    const model: IChangePasswordRequest = this.form.value;
    const token: string = this._getToken();
    if (this.form.invalid) {
      this._accountComponent.setHasError(true);
      let message: string;
      if (model.password !== model.confirmPassword) {
        message = 'changepassword.erro.repetirinvalido';
      } else if (!validatePasswordSecureMode(model.password)) {
        message = 'account.messages.error.invalidSecurePassword';
      } else if (this.recaptchaActive && !model.recaptcha) {
        message = 'account.messages.error.invalidRecaptcha';
      }
      this._accountComponent.errorMessage = message;
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this._accountComponent.setHasError(false);
    return this._authService
      .changePassword(model.password, model.confirmPassword, token)
      .then(() => {
        this._plAlertService.success('changepassword.success');
        return this._stateService.go(STATE_NAME_LOGIN).then(() => undefined);
      })
      .catch((error: HttpErrorResponse) => {
        const exception = this._cgExceptionService.get(error);
        this._accountComponent.setHasError(true);
        this._accountComponent.errorMessage = exception.message;
        if (this.recaptchaActive) {
          this.form.get('recaptcha').setValue('');
        }
      });
  }

  private _getToken(): string {
    return (<IChangePasswordStateParams>this._transition.params()).token;
  }
}
