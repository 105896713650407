import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TESOURARIA_PERIODICIDADE} from './tesourariaPeriodicidade.datasource.interface';
import {EObjTesourariaPeriodicidade} from '../../entities/tesrubrica/jsonTesRubrica.entity.interface';

export const DATA_SOURCE_TESOURARIA_PERIODICIDADE: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TESOURARIA_PERIODICIDADE,
  autocomplete: {
    rowTemplate: '{{value}} - {{name}}',
    output: 'name',
    valueExpr: 'value'
  },
  data: [
    {value: EObjTesourariaPeriodicidade.None, name: 'prevites.datasource.tesourariaperiodicidade.none'},
    {value: EObjTesourariaPeriodicidade.Diaria, name: 'prevites.datasource.tesourariaperiodicidade.diaria'},
    {value: EObjTesourariaPeriodicidade.Semanal, name: 'prevites.datasource.tesourariaperiodicidade.semanal'},
    {value: EObjTesourariaPeriodicidade.Quinzenal, name: 'prevites.datasource.tesourariaperiodicidade.quinzenal'},
    {value: EObjTesourariaPeriodicidade.Mensal, name: 'prevites.datasource.tesourariaperiodicidade.mensal'},
    {value: EObjTesourariaPeriodicidade.Bimensal, name: 'prevites.datasource.tesourariaperiodicidade.bimensal'},
    {value: EObjTesourariaPeriodicidade.Trimestral, name: 'prevites.datasource.tesourariaperiodicidade.trimestral'},
    {value: EObjTesourariaPeriodicidade.Quadrimestral, name: 'prevites.datasource.tesourariaperiodicidade.quadrimestral'},
    {value: EObjTesourariaPeriodicidade.Semestral, name: 'prevites.datasource.tesourariaperiodicidade.semestral'},
    {value: EObjTesourariaPeriodicidade.Anual, name: 'prevites.datasource.tesourariaperiodicidade.anual'}
  ]
});
