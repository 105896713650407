import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonConfigContas} from '../../jsonapurainventperma.module';
import {ContabilidadeApuraInventPermaService} from '../../contabilidade.apurainventperma.module.service';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'modal-config-contas',
  templateUrl: './configContas.modal.component.html'
})
export class ContabilidadeApuraInventPermaConfigContasModalComponent extends CGModalComponent<void> {
  @Input() public configModal: IJsonConfigContas;

  public readonly outputKeyPocs: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apuraInventPermaService: ContabilidadeApuraInventPermaService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.outputKeyPocs = 'nConta';
  }

  public async close(): Promise<void> {
    await this._apuraInventPermaService.saveConfigContas(this.configModal).then((response: HttpResponse<boolean>) => {
      if (response.ok) {
        this._plAlertService.success('apurainventperma.menagens.savedSuccess');
      }
    });
    super.close();
  }

  public async reporValoresOriginais(): Promise<void> {
    this.configModal = await this._apuraInventPermaService.getConfigContasOriginal().then((response: HttpResponse<IJsonConfigContas>) => {
      this._plAlertService.success('apurainventperma.menagens.valoresOriginaisRepostos');
      return response.body;
    });
  }
}
