import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Modelo22Service} from '../modelo22.module.service';
import {IJsonModelo22ConfigWS} from '../jsonModelo22.module.interface';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {PlAlertService, PlTranslateService, TPlAlertStickyType} from 'pl-comps-angular';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'modal-modelo22-configws',
  templateUrl: './modelo22.configWS.modal.component.html'
})
export class Modelo22ConfigwsModalComponent extends CGModalComponent<void> implements OnInit {
  public config: IJsonModelo22ConfigWS;
  public alertType: TPlAlertStickyType;
  public alertMessage: string;
  public form: FormGroup;
  public promise: Promise<IJsonModelo22ConfigWS>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.config = {
      passwordCC: '',
      usernameCC: '',
      servicoInvocadoPorCC: false,
      password: '',
      ccComPoderesDeclarativosContribuinte: false,
      username: '',
      isConfigured: false
    };

    this.promise = this._modelo22Service.getModelo22AtConfig().then((response: HttpResponse<IJsonModelo22ConfigWS>) => response.body);
    Promise.resolve(this.promise)
      .then((response: IJsonModelo22ConfigWS) => {
        this.config = response;
        this.config.password = '';
        this.config.passwordCC = '';
        if (this.config.isConfigured) {
          this.alertType = 'success';
          this.alertMessage = this._plTranslateService.translate('modelo22.modal.configws.configured');
        } else {
          this.alertType = 'warning';
          this.alertMessage = this._plTranslateService.translate('modelo22.modal.configws.notConfigured');
        }
        if (!this.config.username) {
          this.config.username = `${this._configService.configurations.empresa.ncontribuemp}/0`;
        }
        if (!this.config.usernameCC) {
          this.config.usernameCC = this._configService.configurations.empresa.ncontribtoc;
        }
      })
      .finally(() => {
        this.promise = null;
      });
  }

  public close(): void {
    if (this.form.valid) {
      this.disableClose();

      this.promise = this._modelo22Service.saveModelo22AtConfig(this.config).then((response: HttpResponse<IJsonModelo22ConfigWS>) => response.body);
      Promise.resolve(this.promise)
        .then(() => {
          this.enableClose();
          this._plAlertService.success('modelo22.modal.configws.configsaved');
          super.close();
        })
        .catch(() => {
          this.enableClose();
        });
    }
  }

  public servicoInvocadoPorCCChanged(value: boolean): void {
    this.config.servicoInvocadoPorCC = value;
    if (!this.config.servicoInvocadoPorCC) {
      this.config.ccComPoderesDeclarativosContribuinte = false;
    }
  }
}
