import {IJsonExportMaskNameFilesType, IJsonExportMaskNameFilesTypeField, IJsonMascaraNomeFicheirosExportConfig} from './jsonDocsComerciaisConfigNomeFileExport.module.interface';

export enum EExportMaskNameFilesType {
  None,
  Recibos,
  Pagamentos,
  Faturacao
}

export enum EExportMaskNameFilesFields {
  None,
  CaracterFixo,
  NomeDocfa,
  NDocumento,
  DataDoc,
  Via,
  NDocExterno,
  Recibo
}

export interface IMascaraNomeFicheiroItem extends IJsonExportMaskNameFilesTypeField {
  ordem: number;
  valor: string;
}

export interface IExportMaskNameFilesTypeField extends IJsonExportMaskNameFilesTypeField {
  tagRow: string;
}

export interface IObjExportMaskNameFiles {
  tipoConfig: IJsonMascaraNomeFicheirosExportConfig;
  tipo: IJsonExportMaskNameFilesType;
  tipoCampo: IJsonExportMaskNameFilesTypeField;
}

export const MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT = 'docsComerciaisConfigNomeExportFile';
