import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_MAPAS_OFICIAIS} from './ativosMapasOficiais.module.interface';
import {AtivosMapasOficiaisModuleComponent} from './components/ativosMapasOficiais.module.component';
import {IJsonAnosCalculo} from '../ativosdepreciacao/jsonAtivosDepreciacao.module.interface';
import {AtivosMapasOficiaisService} from './ativosMapasOficiais.module.service';

export const MODULE_ATIVOS_MAPAS_OFICIAIS: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_MAPAS_OFICIAIS,
  state: {
    url: `/${MODULE_NAME_ATIVOS_MAPAS_OFICIAIS}`,
    component: AtivosMapasOficiaisModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosmapasoficiais'
    },
    resolve: [
      {
        provide: 'anosCalculo',
        deps: [AtivosMapasOficiaisService],
        useFactory: (ativosMapasOficiaisService: AtivosMapasOficiaisService): Promise<Array<IJsonAnosCalculo>> => ativosMapasOficiaisService.getAnos()
      }
    ]
  }
};
