import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_GERA_MAPAS_AUTO} from './ativosGeraMapasAuto.module.interface';
import {AtivosGeraMapasAutoModuleComponent} from './components/ativosGeraMapasAuto.module.component';
import {HttpResponse} from '@angular/common/http';
import {AtivosGeraMapasAutoService} from './ativosGeraMapasAuto.module.service';
import {IJsonAtivosGeraMapasAuto} from './jsonAtivosGeraMapasAuto.module.interface';

export const MODULE_ATIVOS_GERA_MAPAS_AUTO: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_GERA_MAPAS_AUTO,
  state: {
    url: `/${MODULE_NAME_ATIVOS_GERA_MAPAS_AUTO}`,
    component: AtivosGeraMapasAutoModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosgeramapasauto'
    },
    resolve: [
      {
        provide: 'ativosGeraMapas',
        deps: [AtivosGeraMapasAutoService],
        useFactory: (ativosGeraMapasAutoService: AtivosGeraMapasAutoService): Promise<Array<IJsonAtivosGeraMapasAuto>> => {
          return ativosGeraMapasAutoService.getBens().then((response: HttpResponse<Array<IJsonAtivosGeraMapasAuto>>) => response.body);
        }
      }
    ]
  }
};
