import {EmpregadosComponent} from './empregados/empregados.module.component';
import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_DGEMPS: IModuleDefinition = {
  name: 'colaboradoresdgemps',
  state: {
    urlSuffix: '/utilizadores',
    url: '/colaboradores',
    component: EmpregadosComponent,
    data: {
      roles: [ROLE.GESTORRH],
      pageTitle: 'global.menu.colaboradoresdgemps',
      icon: 'fa-users'
    }
  }
};
