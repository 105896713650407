import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {IUserFeedbackConfig, userFeedbackUrlWithUser} from '../../../../services/userfeedback/userfeedback.service.interface';
import {UserFeedbackService} from '../../../../services/userfeedback/userfeedback.service';

@Component({
  selector: 'cg-user-feedback',
  templateUrl: './cg.user.feedback.component.html'
})
export class CGUserFeedbackComponent implements OnDestroy {
  public origin: string;

  private readonly _subscriptionConfig: Subscription;

  constructor(private readonly _userFeedbackService: UserFeedbackService) {
    this._subscriptionConfig = this._userFeedbackService.config().subscribe((config: IUserFeedbackConfig) => {
      const websiteUrl: string = config?.websiteUrl;
      this.origin = websiteUrl ? userFeedbackUrlWithUser(websiteUrl, config.token) : '';
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfig.unsubscribe();
  }
}
