import {merge} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IRadioGroup} from '../../../../../../../../common/interfaces/interfaces';
import {EEstadoRegisto} from '../../../../../../../datasources/estadoregisto/estadoRegisto.datasource.interface';
import {RHAgenciasService} from '../../../../rhAgencias.module.service';
import {ERHAgenciasTipoInstalacao, IJsonRHAgenciasAgencia} from '../../../../jsonRHAgencias.module.interface';
import {RADIO_GROUP_RH_AGENCIAS_TIPO_INSTALACAO} from '../../../../rhAgencias.module.interface';

@Component({
  selector: 'modal-rhagencias-agencia',
  templateUrl: './rhAgencias.agencia.modal.component.html'
})
export class RHAgenciasAgenciaModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public agencia: Partial<IJsonRHAgenciasAgencia>;

  public readonly radioGroupTipoInstalacao: IRadioGroup<ERHAgenciasTipoInstalacao>;
  public model: IJsonRHAgenciasAgencia;
  public isUpdate: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhAgenciasService: RHAgenciasService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.radioGroupTipoInstalacao = RADIO_GROUP_RH_AGENCIAS_TIPO_INSTALACAO;
    this.model = {
      codAgencia: undefined,
      codBanco: undefined,
      nome: undefined,
      designaBreve: undefined,
      morada: undefined,
      codPostal: undefined,
      localidade: undefined,
      telefone: undefined,
      fax: undefined,
      contacto: undefined,
      nContAgencia: undefined,
      tipoInstalac: ERHAgenciasTipoInstalacao.Agencia,
      estado: EEstadoRegisto.Activo,
      nomeLocalidade: undefined,
      nomeCodPostal: undefined,
      nomeBanco: undefined,
      contasList: []
    };
  }

  public ngOnInit(): void {
    this.model = merge<IJsonRHAgenciasAgencia, Partial<IJsonRHAgenciasAgencia>>(this.model, this.agencia);
    this.isUpdate = !isEmpty(this.agencia.codAgencia);
  }

  public close(): Promise<void> {
    if (this.isUpdate) {
      return this._rhAgenciasService.updateAgencia(this.model.codBanco, this.model.codAgencia, this.model).then(() => {
        this._plAlertService.success('rhagencias.messages.updateSuccAgencia');
        super.close();
      });
    }

    return this._rhAgenciasService.saveAgencia(this.model.codBanco, this.model).then(() => {
      this._plAlertService.success('rhagencias.messages.newSuccAgencia');
      super.close();
    });
  }
}
