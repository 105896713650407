import {Injectable} from '@angular/core';
import {ITrDocsService} from './trDocs.service.interface';
import {ApiService} from '../../../services/api/api.service';
import {IJsonTrDoc, IJsonTrDocDocumento} from '../jsonTrDoc.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonDocComercial, IJsonDocComercialCab, IJsonDocComercialLinha} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {MODULE_NAME_TRDOCS} from '../trDocs.module.interface';
import {
  IJsonTrDocsCabsWizardCabItem,
  IJsonTrDocsCabsWizardCabResumo,
  IJsonTrDocsCabsWizardFilter,
  ITrDocsCabsWizardConfig
} from '../../../components/docscomerciais/trDocs/components/cabsWizard/trdocs.cabsWizard.interface';

@Injectable({
  providedIn: 'root'
})
export class TrDocsService implements ITrDocsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_TRDOCS}`;
  }

  public processar(trDoc: IJsonTrDoc, ignoraorigemtransformada: boolean = false): TServiceResponse<Array<IJsonDocComercialCab>> {
    return this._apiService.post<Array<IJsonDocComercialCab>, IJsonTrDoc>({
      url: this._path,
      params: {
        ignoraorigemtransformada: ignoraorigemtransformada
      },
      body: trDoc
    });
  }

  public getLinhasTransformadasDoDocumento(faccbId: number): TServiceResponse<Array<IJsonDocComercialLinha>> {
    return this._apiService.get({
      url: `${this._path}/${faccbId}/linhastransformadas`
    });
  }

  public pesquisarDocumentos(filters: IJsonTrDocsCabsWizardFilter): TServiceResponse<Array<IJsonTrDocsCabsWizardCabItem>> {
    return this._apiService.post<Array<IJsonTrDocsCabsWizardCabItem>, IJsonTrDocsCabsWizardFilter>({
      url: `${this._path}/pesquisa-documentos`,
      body: filters
    });
  }

  public processarDocumentos(config: ITrDocsCabsWizardConfig): TServiceResponse<IJsonTrDocsCabsWizardCabResumo> {
    return this._apiService.post<IJsonTrDocsCabsWizardCabResumo, ITrDocsCabsWizardConfig>({
      url: `${this._path}/processar-documentos`,
      body: config
    });
  }

  public saveDocumentosTransformados(listOfDocs: Array<IJsonTrDocDocumento>): TServiceResponse<Array<IJsonDocComercial>> {
    return this._apiService.post<Array<IJsonDocComercial>, Array<IJsonTrDocDocumento>>({
      url: this._path,
      body: listOfDocs
    });
  }

  public encerrarDocumentos(list: Array<number>): TServiceResponse<Array<IJsonDocComercial>> {
    return this._apiService.post<Array<IJsonDocComercial>, Array<number>>({
      url: `${this._path}/encerrar-documentos`,
      body: list
    });
  }
}
