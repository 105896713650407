import {fromEvent, Observable, race, Subject} from 'rxjs';
import {delay, filter, map, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {NgZone} from '@angular/core';
import {browserDetection, isContainedIn, matchesSelectorIfAny, timeout} from '../common/utilities/utilities';
import {EBrowserDetectionTypes, TBrowserDetection} from '../common/utilities/utilities.interface';
import {KEYCODES} from '../common/constants';
import type {TPlAutoCloseType} from './autoclose.interface';

const isMobile: () => boolean = () => {
  const detection: TBrowserDetection = browserDetection();
  return detection[EBrowserDetectionTypes.IOS] || detection[EBrowserDetectionTypes.Android];
};

const wrapForMobile = (fn: () => void): (() => void | Promise<() => void>) => {
  return isMobile ? () => timeout(100).then(fn) : fn;
};

export function plAutoClose(
  zone: NgZone,
  document: Document,
  type: TPlAutoCloseType,
  closeHandler: () => void,
  insideElements: Array<HTMLElement>,
  ignoreElements?: Array<HTMLElement>,
  insideSelector?: string
): void {
  if (type) {
    zone.runOutsideAngular(
      wrapForMobile(() => {
        const shouldCloseOnClick = (event: MouseEvent): boolean => {
          const element: HTMLElement = <HTMLElement>event.target;
          if (isContainedIn(element, ignoreElements)) {
            return false;
          }
          if (type === 'inside') {
            return isContainedIn(element, insideElements) && matchesSelectorIfAny(element, insideSelector);
          } else if (type === 'outside') {
            return !isContainedIn(element, insideElements);
          }
          return matchesSelectorIfAny(element, insideSelector) || !isContainedIn(element, insideElements);
        };

        const closed: Subject<void> = new Subject<void>();

        const keyboardEscape: Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(document, 'keydown')
          .pipe(takeUntil(closed))
          .pipe(filter((event: KeyboardEvent) => event.key === KEYCODES.ESC))
          .pipe(
            tap((event: KeyboardEvent) => {
              event.preventDefault();
            })
          );

        const mouseDown: Observable<boolean> = fromEvent<MouseEvent>(document, 'mousedown').pipe(map(shouldCloseOnClick)).pipe(takeUntil(closed));

        const closeableClicks: Observable<[MouseEvent, boolean]> = fromEvent<MouseEvent>(document, 'mouseup')
          .pipe(withLatestFrom(mouseDown))
          .pipe(filter(([, shouldClose]) => shouldClose))
          .pipe(delay(0))
          .pipe(takeUntil(closed));

        race([keyboardEscape, closeableClicks]).subscribe(() => {
          closed.next();
          closed.complete();
          zone.run(closeHandler);
        });
      })
    );
  }
}
