<div class="transferencias-armazens-config-modal entity-detail-form">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'global.menu.configs'"></h5>
    </div>

    <div class="modal-body">
      <fieldset class="mb-3">
        <legend [translate]="'transferenciasarmazens.modal.titleorigem'"></legend>
        <pl-group>
          <label [translate]="'transferenciasarmazens.modal.fields.tipodoc'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              [filter]="'grupoDocfa=10'"
              attrName="nDocfaOrigem"
              [model]="configTransfArmazem"
              (selectedKeyChange)="configTransfArmazem.nDocfaOrigem = $event"
              (evtSelectedDescriptionChanged)="configTransfArmazem.nomeDocfaOrigem = $event"
              [fieldsMap]="{nDocFa: 'nDocfaOrigem', nome: 'nomeDocfaOrigem'}"
              [output]="docfasOutput"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.nnumer'"></label>
            <edit>
              <entity-autocomplete
                entity="docfasnums"
                attrName="nNumerOrigem"
                [filter]="docfaOrigemNumFilter"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nNumerOrigem = $event"
                [fieldsMap]="{nNumer: 'nNumerOrigem'}"
                [output]="'nNumer'">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.clientfor'"></label>
            <edit>
              <entity-autocomplete
                entity="clifos"
                attrName="nContaOrigem"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nContaOrigem = $event"
                (evtSelectedDescriptionChanged)="configTransfArmazem.nomeContaOrigem = $event"
                [fieldsMap]="{nConta: 'nContaOrigem', nome: 'nomeContaOrigem'}"
                [output]="clifosOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.armazem'"></label>
            <edit>
              <entity-autocomplete
                entity="armazens"
                attrName="nArmazemOrigem"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nArmazemOrigem = $event"
                (evtSelectedDescriptionChanged)="configTransfArmazem.nomeArmazemOrigem = $event"
                [fieldsMap]="{nArmazem: 'nArmazemOrigem', nome: 'nomeArmazemOrigem'}"
                [output]="armazensOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </fieldset>

      <fieldset>
        <legend [translate]="'transferenciasarmazens.modal.titledestino'"></legend>
        <pl-group>
          <label [translate]="'transferenciasarmazens.modal.fields.tipodoc'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              attrName="nDocfaDestino"
              [filter]="'grupoDocfa=9'"
              [model]="docFaDestino"
              (modelChange)="nDocfaDestinoChanged($event)"
              outputKey="nDocFa"
              outputDescription="nome"
              [output]="docfasOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.nnumer'"></label>
            <edit>
              <entity-autocomplete
                entity="docfasnums"
                attrName="nNumerDestino"
                [filter]="docfaDestinoNumFilter"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nNumerDestino = $event"
                [fieldsMap]="{nNumer: 'nNumerDestino'}"
                [output]="'nNumer'">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.clientfor'"></label>
            <edit>
              <entity-autocomplete
                entity="clifos"
                attrName="ncontaDestino"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nContaDestino = $event"
                (evtSelectedDescriptionChanged)="configTransfArmazem.nomeContaDestino = $event"
                [fieldsMap]="{nConta: 'nContaDestino', nome: 'nomeContaDestino'}"
                [output]="clifosOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'transferenciasarmazens.modal.fields.armazem'"></label>
            <edit>
              <entity-autocomplete
                entity="armazens"
                attrName="armazemDestino"
                [model]="configTransfArmazem"
                (selectedKeyChange)="configTransfArmazem.nArmazemDestino = $event"
                (evtSelectedDescriptionChanged)="configTransfArmazem.nomeArmazemDestino = $event"
                [fieldsMap]="{nArmazem: 'nArmazemDestino', nome: 'nomeArmazemDestino'}"
                [output]="armazensOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </fieldset>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
      </pl-button>

      <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
