import {merge} from 'lodash-es';
import {produce} from 'immer';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlEditComponentOptionsInputNumber} from '../edit/components/number/edit.number.component.interface';
import {IPlFormatConfig} from '../common/format/format.service.interface';
import {IPlI18nNumberFormat} from './i18n.plNumber.service.interface';
import {isEmpty, isNumber, isObject, isString, stripCharacters as cgcStripCharacters} from '../common/utilities/utilities';
import {KEYCODES} from '../common/constants';
import {PlFormatService} from '../common/format/format.service';

@Injectable({
  providedIn: 'root'
})
export class PlI18nPlNumberService implements OnDestroy {
  private readonly _subjectI18nFormat: BehaviorSubject<IPlI18nNumberFormat>;
  private readonly _subscriptionFormat: Subscription;
  private _internalFormat: IPlI18nNumberFormat;
  private _observableInternalFormat: Observable<IPlI18nNumberFormat>;

  constructor(private readonly _plFormatService: PlFormatService) {
    this._subjectI18nFormat = new BehaviorSubject(
      Object.freeze({
        allowNegative: true,
        thousandsSeparator: undefined,
        decimalsSeparator: undefined,
        decimalsLimit: undefined,
        maxNumber: 2147483647
      })
    );
    this._subscriptionFormat = combineLatest([this._subjectI18nFormat, this._plFormatService.format]).subscribe(([i18nFormat, format]: [IPlI18nNumberFormat, IPlFormatConfig]) => {
      this._internalFormat = produce(i18nFormat, (draft) => {
        if (isEmpty(draft.thousandsSeparator)) {
          draft.thousandsSeparator = format.thousandsSeparator;
        }
        if (isEmpty(draft.decimalsSeparator)) {
          draft.decimalsSeparator = format.decimalsSeparator;
        }
        if (!isNumber(draft.decimalsLimit)) {
          draft.decimalsLimit = format.decimalsLimit;
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
  }

  public formatPlNumber(value: string | any, format?: Partial<IPlI18nNumberFormat>): string {
    if (isEmpty(value)) {
      return value;
    }
    let options: IPlI18nNumberFormat = this._internalFormat;
    if (isObject(format)) {
      options = merge({}, options, format);
    }
    value = this._checkValue(value, options);
    const firstChar = value.charAt(0);
    if (firstChar === options.decimalsSeparator) {
      value = `0${String(value)}`;
    } else if (firstChar === '-' && value[1] === options.decimalsSeparator) {
      value = `-0${String(value).substring(1, value.length)}`;
    }
    value = this._checkMinus(value, options);
    value = this._checkOverFlow(value, options);
    value = this._stripLeftZeroes(value, options);
    value = this._formatThousands(value, options);
    return value;
  }

  public formatTax(value: string | any, format?: Partial<IPlI18nNumberFormat>): string {
    return `${this.formatPlNumber(value, format)}%`;
  }

  public setFormat(format: Partial<IPlI18nNumberFormat>): void {
    this._subjectI18nFormat.next(Object.freeze({...this._subjectI18nFormat.value, ...format}));
  }

  public get format(): Observable<IPlI18nNumberFormat> {
    if (!this._observableInternalFormat) {
      this._observableInternalFormat = this._subjectI18nFormat.asObservable();
    }
    return this._observableInternalFormat;
  }

  private _checkValue(value: string | number, options: IPlEditComponentOptionsInputNumber): string {
    let returnValue = '0';
    if (value) {
      if (isNumber(value)) {
        if (value > options.maxNumber) {
          returnValue = options.maxNumber.toString();
        } else {
          returnValue = value.toString();
          if (options.decimalsSeparator !== '.') {
            returnValue = returnValue.replace('.', options.decimalsSeparator);
          }
        }
      } else {
        if (!isString(value)) {
          value = (<number>value).toString();
        }
        const normalizedValue: string = options.decimalsSeparator !== '.' ? value.replace(options.decimalsSeparator, '.') : value;
        returnValue = parseFloat(normalizedValue) > options.maxNumber ? options.maxNumber.toString() : value;
      }
    }
    return returnValue;
  }

  private _checkMinus(value: string, options: IPlI18nNumberFormat): string {
    const minusLastIndex: number = value.lastIndexOf(KEYCODES.SUBTRACT);
    if (minusLastIndex > 0) {
      const hasValidMinus: boolean = options.allowNegative && value.startsWith(KEYCODES.SUBTRACT);
      value = value.split(KEYCODES.SUBTRACT).join('');
      if (hasValidMinus) {
        value = KEYCODES.SUBTRACT + value;
      }
    }
    return value;
  }

  private _checkOverFlow(value: string, options: IPlI18nNumberFormat): string {
    if (options.decimalsLimit > 0) {
      let val = value;
      const index = val.indexOf(options.decimalsSeparator);
      if (index !== -1) {
        val = val.substring(index + 1, val.length);
        if (val.length > options.decimalsLimit) {
          return value.substring(0, index) + options.decimalsSeparator + val.substring(0, options.decimalsLimit);
        }
      }
    }
    return value;
  }

  private _defaultDecimals(options: IPlI18nNumberFormat): string {
    if (options.decimalsLimit > 0) {
      let decimals = '';
      for (let i = 0; i < options.decimalsLimit; i++) {
        decimals += '0';
      }
      return options.decimalsSeparator + decimals;
    }
    return '';
  }

  private _stripLeftZeroes(value: string, options: IPlI18nNumberFormat): string {
    value = this._stripCharacters(value, options);
    let decimalsValue = '';

    if (options.decimalsLimit > 0) {
      let index = value.indexOf(options.decimalsSeparator);
      if (index === -1) {
        index = value.length;
      } else {
        decimalsValue = value.substring(value.indexOf(options.decimalsSeparator), value.length);
      }
      value = value.substring(0, index);
    }

    if (value === '') {
      return value;
    }

    if (value.startsWith('0') && value.length > 1) {
      let count = 0;
      for (const char of value) {
        if (char === '0') {
          count++;
        } else {
          break;
        }
      }
      if (count > 0) {
        value = value.substring(count, value.length);
      }
    }
    return this._formatDecimals(value + decimalsValue, options);
  }

  private _formatThousands(value: string, options: IPlI18nNumberFormat): string {
    value = this._stripCharacters(value, options);
    let decimalsValue = '';

    if (options.decimalsLimit > 0) {
      let index = value.indexOf(options.decimalsSeparator);
      if (index === -1) {
        index = value.length;
      } else {
        decimalsValue = value.substring(value.indexOf(options.decimalsSeparator), value.length);
      }
      value = value.substring(0, index);
    }

    if (value === '') {
      return value;
    }

    let result = '';
    let thousandsCount = 0;
    for (let i = value.length; i > 0; i--) {
      let char = value[i - 1];
      thousandsCount++;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (thousandsCount % 3 === 0) {
        char = options.thousandsSeparator + char;
      }
      result = char + result;
    }

    if (options.thousandsSeparator) {
      // If result starts with a thousands separator, eg: ',123.99'
      if (result.startsWith(options.thousandsSeparator)) {
        result = result.substring(1, result.length);
      }
      // If result starts with a negative sign followed by a thousands separator, eg: '-,123.99'
      if (result.startsWith('-') && result.substring(1, 2) === options.thousandsSeparator) {
        result = result.substring(0, 1) + result.substring(2, result.length);
      }
    }

    return this._formatDecimals(result + decimalsValue, options);
  }

  private _formatDecimals(value: string, options: IPlI18nNumberFormat): string {
    if (options.decimalsLimit > 0) {
      const index = value.indexOf(options.decimalsSeparator);
      if (index === -1) {
        return value + this._defaultDecimals(options);
      }
      const decimalsValue = value.substring(index, value.length);
      // If decimals value is filled
      if (decimalsValue.length > 1) {
        // If decimals has less characters than it is defined in options fills empty chunk with zeroes
        if (decimalsValue.length - 1 !== options.decimalsLimit) {
          for (let i = decimalsValue.length - 1; i < options.decimalsLimit; i++) {
            value += '0';
          }
        }
        return value;
      }
      // If not simply return default decimals value
      if (decimalsValue === options.decimalsSeparator) {
        // If user left nothing but the decimals separator on decimals value
        value = value.substring(0, value.length - 1);
      }
      return value + this._defaultDecimals(options);
    }
    return value;
  }

  private _stripCharacters(value: string, format: IPlI18nNumberFormat = this._internalFormat): string {
    return cgcStripCharacters(value, format);
  }
}
