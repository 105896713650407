import {EPortalColaboradorNotificacao} from '../../modules/configs/erp/portalcolaborador/configs.portalcolaborador.module.interface';
import {TDate} from '../../../common/dates';
import {EWeekDay} from 'pl-comps-angular';

export enum ETypeKind {
  Unknown,
  Integer,
  Char,
  Enumeration,
  Float,
  String,
  Set,
  Class,
  Method,
  WChar,
  LString,
  WString,
  Variant,
  Array,
  Record,
  Interface,
  Int64,
  DynArray,
  UString,
  ClassRef,
  Pointer,
  Procedure
}

export enum EConfigERPGenDefaultDataState {
  Idle = 0,
  Stopped = 1,
  Starting = 2,
  Running = 3,
  Stopping = 4,
  Error = 5,
  Paused = 6,
  Destroying = 7,
  Terminated = 8
}

export interface IJsonConfigERPValue<T = unknown> {
  value: T;
  kind: ETypeKind;
}

export interface IJsonConfigERPCustom {
  name: string;
  description: string;
  readOnly: boolean;
  cguid: string;
}

export interface IJsonConfigERP<T = unknown> extends IJsonConfigERPValue<T>, IJsonConfigERPCustom {}

export interface IJsonConfigERPUserEmpresa<T = unknown> extends IJsonConfigERPValue<T> {
  nempresa: string;
}

export interface IJsonConfigERPUser extends IJsonConfigERPCustom {
  empresas: Array<IJsonConfigERPUserEmpresa>;
}

export interface IJsonConfigERPCheck {
  name: string;
  description: string;
  error: string;
  canRepair: boolean;
  repairHint: string;
  repairHintLink: string;
  checked: boolean;
}

export interface IJsonRHPortalColaboradorNotificationConfig {
  notificacao: EPortalColaboradorNotificacao;
  hora: TDate;
  dia: EWeekDay;
}
