import {DATA_SOURCE_NAME_TIPOS_REFEICOES} from '../../datasources/tiposrefeicoes/tiposRefeicoes.datasource.interface';
import {ENTITY_NAME_PRH_REL_EMENTAS} from './pRHRelEmentas.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHRelEmentas} from './jsonPRHRelEmentas.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_PRH_MENUS} from '../../modules/colaboradores/refeicoes/menus/prhMenus.module.interface';
import {ENTITY_NAME_PRH_LOCAIS} from '../prhlocais/pRHLocais.entity.interface';

export const ENTITY_PRH_REL_EMENTAS: IEntityDefinition<IJsonPRHRelEmentas> = {
  name: ENTITY_NAME_PRH_REL_EMENTAS,
  icon: 'fa-question-circle-o',
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhrelementas.pesquisa',
  metadata: {
    keyName: 'codPRHRelEmentas',
    descriptionName: 'data',
    fields: [
      {
        name: 'codPRHRelEmentas',
        width: '120px',
        caption: 'prhrelementas.fields.codPRHRelEmentas',
        placeholder: 'prhrelementas.fields.codPRHRelEmentasPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'codLocal',
        caption: 'prhrelementas.fields.codLocal',
        placeholder: 'prhrelementas.fields.codLocalPlaceholder',
        entity: {name: ENTITY_NAME_PRH_LOCAIS, keyTarget: 'codLocal', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      },
      {
        name: 'codMenu',
        caption: 'prhrelementas.fields.codMenu',
        placeholder: 'prhrelementas.fields.codMenuPlaceholder',
        entity: {name: ENTITY_NAME_PRH_MENUS, keyTarget: 'codMenu', outputTarget: 'titulo'},
        validators: {required: true, min: 1}
      },
      {
        name: 'data',
        type: 'date',
        caption: 'global.text.date',
        placeholder: 'global.text.date',
        validators: {required: true}
      },
      {
        name: 'tipoRefeicao',
        caption: 'prhrelementas.fields.tipoRefeicao',
        placeholder: 'prhrelementas.fields.tipoRefeicaoPlaceholder',
        type: DATA_SOURCE_NAME_TIPOS_REFEICOES,
        validators: {required: true, min: 1}
      }
    ],
    order: 'codPRHRelEmentas',
    searchFields: 'codPRHRelEmentas,codMenu'
  },
  autocomplete: {
    rowTemplate: '{{codPRHRelEmentas}} - {{codMenu}}',
    output: 'codMenu',
    searchFields: 'codPRHRelEmentas,codMenu'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhrelementas'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhrelementas'
      }
    }
  }
};
