export enum ETipoArredondamentoABDES {
  NaoArredonda,
  UnidMaisProxima, // 1
  DezenaMaisProxima, // 10
  Baixo1,
  Cima1,
  Baixo10,
  Cima10,
  ParaDecimaMaisProxima, // 0.1
  ParaCentesimaMaisProxima, // 0.01
  ParaMilesimaMaisProxima, // 0.001
  ParaDezenaMaisInferior, // 10
  ParaDezenaMaisSuperior, // 10
  ParaDecimaMaisInferior, // 10
  ParaDecimaMaisSuperior, // 10
  ParaCentesimaMaisInferior, // 100
  ParaCentesimaMaisSuperior, // 100
  ParaMilesimaMaisInferior, // 1000
  ParaMilesimaMaisSuperior, // 1000
  ParaCentenaMaisProxima
}

export const DATA_SOURCE_NAME_TIPOS_ARREDONDAMENTO_ABDES = 'tipoArrendodamentoABDES';
