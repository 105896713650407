<div class="controlo-iva">
  <pl-alert *ngIf="!!model.declaracaoPeriodica" class="mt-2" type="info" [closeable]="false" [plAnimationShake]="true">
    <b><span [translate]="'global.text.info'"></span>: </b>
    <span [translate]="'controloIVA.fields.declaracaoPerio'" [translateParams]="{info: model.declaracaoPeriodica}"></span>
  </pl-alert>

  <entity-detail
    entityName="controloIVA"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [definition]="definition" [model]="model" [template]="stickyTemplate" [properties]="{readonly: isDetailState}"></pl-form>

      <pl-nav-wizard [definition]="navWizardDefinition" [onFinalize]="finalize">
        <pl-nav-wizard-step caption="controloIVA.steps.operacaoIVA" icon="fa-percent">
          <div *plNavWizardStepContent>
            <pl-form [definition]="definition" [template]="templateOperacaoIva" [model]="model" [properties]="{readonly: isDetailState}" (evtFieldValueChanged)="onFieldValueChange($event)"></pl-form>

            <pl-form [properties]="{readonly: isDetailState}">
              <pl-group *ngIf="!regularizacaoCampoEnabled40 && !regularizacaoCampoEnabled41">
                <label [translate]="regularizacaoCampoCaption"></label>
                <edit>
                  <pl-edit [(model)]="model.regularizacoesC40" [type]="dataSourceNameTipoRegularizacaoCampo40" [properties]="{disabled: true}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group *ngIf="regularizacaoCampoEnabled40">
                <label [translate]="regularizacaoCampoCaption"></label>
                <edit>
                  <pl-edit [(model)]="model.regularizacoesC40" [type]="dataSourceNameTipoRegularizacaoCampo40"></pl-edit>
                </edit>
              </pl-group>

              <pl-group *ngIf="regularizacaoCampoEnabled41">
                <label [translate]="regularizacaoCampoCaption"></label>
                <edit>
                  <pl-edit [(model)]="model.regularizacoesC41" [type]="dataSourceNameTipoRegularizacaoCampo41"></pl-edit>
                </edit>
              </pl-group>
            </pl-form>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="controloIVA.steps.camposIVA" icon="fa-calculator" [properties]="{hideFinalize: !isNewState}">
          <pl-form *plNavWizardStepContent [properties]="{readonly: isDetailState}">
            <pl-group>
              <pl-group>
                <label [translate]="'controloIVA.fields.decPERBASETRIBUTNome'"></label>
                <edit>
                  <entity-autocomplete
                    entity="qivaps"
                    attrName="decPERBASETRIBUT"
                    [model]="model"
                    (modelChange)="changedDecPERBASETRIBUT($event)"
                    (selectedKeyChange)="model.decPERBASETRIBUT = $event"
                    (evtSelectedDescriptionChanged)="model.decPERBASETRIBUTNome = $event"
                    [fieldsMap]="{nCampo: 'decPERBASETRIBUT', nomeCampo: 'decPERBASETRIBUTNome'}"
                    [output]="qivapsOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'controloIVA.fields.decANUBASETRIBUTNome'"></label>
                <edit>
                  <entity-autocomplete
                    entity="qivaas"
                    attrName="decANUBASETRIBUT"
                    [model]="model"
                    (selectedKeyChange)="model.decANUBASETRIBUT = $event"
                    (evtSelectedDescriptionChanged)="model.decANUBASETRIBUTNome = $event"
                    [fieldsMap]="{nCampo: 'decANUBASETRIBUT', nome: 'decANUBASETRIBUTNome'}"
                    [output]="qivaasOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.codIVA'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="ivas"
                              attrName="codIVA"
                              [model]="model"
                              (modelChange)="changeCodIva($event)"
                              (selectedKeyChange)="model.codIVA = $event"
                              (evtSelectedDescriptionChanged)="model.codIVANome = $event"
                              [fieldsMap]="{codIva: 'codIVA', nome: 'codIVANome'}"
                              [output]="ivasOutput"
                              [properties]="{modelOptions: {updateOn: 'blur'}}">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.taxaAtual' | translate">{{ model.taxaAtualIva | cgcPercent: 0 }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.percDedGasoleo' | translate">{{ model.percDedGasoleo | cgcPercent: 0 }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.percDedProRata' | translate">{{ model.percDedProRata | cgcPercent: 0 }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaIVADEDLIQREG'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaIVADEDLIQREG"
                              [model]="model"
                              (modelChange)="changenContaIVADEDLIQREG($event)"
                              (selectedKeyChange)="model.nContaIVADEDLIQREG = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAIVADEDLIQREGNome = $event"
                              [fieldsMap]="{nConta: 'nContaIVADEDLIQREG', nome: 'nCONTAIVADEDLIQREGNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">{{ model.decPERIVADEDLIQREG }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">{{ model.decANUIVADEDLIQREG }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaVTNAODEDUT'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaVTNAODEDUT"
                              [model]="model"
                              (modelChange)="changenContaVTNAODEDUT($event)"
                              (selectedKeyChange)="model.nContaVTNAODEDUT = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAVTNAODEDUTNome = $event"
                              [fieldsMap]="{nConta: 'nContaVTNAODEDUT', nome: 'nCONTAVTNAODEDUTNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">{{ model.decPERVTNAODEDUT }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">{{ model.decANUVTNAODEDUT }}</div>
                          <div class="ps-2">
                            <pl-edit type="boolean" [(model)]="model.usaVTNAODEDUTOrigem" attrName="usaVTNAODEDUTOrigem" [properties]="{label: 'controloIVA.fields.usaVTNAODEDUTOrigem'}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaIVANAODEDUT'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaIVANAODEDUT"
                              [model]="model"
                              (modelChange)="changenContaIVANAODEDUT($event)"
                              (selectedKeyChange)="model.nContaIVANAODEDUT = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAIVANAODEDUTNome = $event"
                              [fieldsMap]="{nConta: 'nContaIVANAODEDUT', nome: 'nCONTAIVANAODEDUTNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">{{ model.decPERIVANAODEDUT }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">{{ model.decANUIVANAODEDUT }}</div>
                          <div class="ps-2">
                            <pl-edit type="boolean" [(model)]="model.usaIVANAODEDUTOrigem" attrName="usaIVANAODEDUTOrigem" [properties]="{label: 'controloIVA.fields.usaIVANAODEDUTOrigem'}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaVTNDEDPROR'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaVTNDEDPROR"
                              [model]="model"
                              (modelChange)="changenContaVTNDEDPROR($event)"
                              (selectedKeyChange)="model.nContaVTNDEDPROR = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAVTNDEDPRORNome = $event"
                              [fieldsMap]="{nConta: 'nContaVTNDEDPROR', nome: 'nCONTAVTNDEDPRORNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">
                            {{ model.decPERVTNDEDPROR }}
                          </div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">
                            {{ model.decANUVTNDEDPROR }}
                          </div>
                          <div class="ps-2">
                            <pl-edit type="boolean" [(model)]="model.usaVTNDEDPROROrigem" attrName="usaVTNDEDPROROrigem" [properties]="{label: 'controloIVA.fields.usaVTNDEDPROROrigem'}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaIVANDEDPROR'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaIVANDEDPROR"
                              [model]="model"
                              (modelChange)="changenContaIVANDEDPROR($event)"
                              (selectedKeyChange)="model.nContaIVANDEDPROR = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAIVANDEDPRORNome = $event"
                              [fieldsMap]="{nConta: 'nContaIVANDEDPROR', nome: 'nCONTAIVANDEDPRORNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">{{ model.decPERIVANDEDPROR }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">{{ model.decANUIVANDEDPROR }}</div>
                          <div class="ps-2">
                            <pl-edit type="boolean" [(model)]="model.usaIVANDEDPROROrigem" attrName="usaIVANDEDPROROrigem" [properties]="{label: 'controloIVA.fields.usaIVANDEDPROROrigem'}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.decPERVTLIQUIDRCH'"></label>
              <edit>
                <entity-autocomplete
                  entity="qivaps"
                  attrName="decPERVTLIQUIDRCH"
                  [model]="model"
                  (modelChange)="changedDecPERVTLIQUIDRCH($event)"
                  (selectedKeyChange)="model.decPERVTLIQUIDRCH = $event"
                  (evtSelectedDescriptionChanged)="model.decPERVTLIQUIDRCHNome = $event"
                  [fieldsMap]="{nCampo: 'decPERVTLIQUIDRCH', nomeCampo: 'decPERVTLIQUIDRCHNome'}"
                  [output]="qivapsOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'controloIVA.fields.nContaIVALIQUIDRCH'"></label>
              <edit>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-8 col-sm-6">
                        <div class="controlo-iva-split-wrapper">
                          <div class="controlo-iva-split">
                            <entity-autocomplete
                              entity="pocs"
                              attrName="nContaIVALIQUIDRCH"
                              [model]="model"
                              (modelChange)="changenContaIVALIQUIDRCH($event)"
                              (selectedKeyChange)="model.nContaIVALIQUIDRCH = $event"
                              (evtSelectedDescriptionChanged)="model.nCONTAIVALIQUIDRCHNome = $event"
                              [fieldsMap]="{nConta: 'nContaIVALIQUIDRCH', nome: 'nCONTAIVALIQUIDRCHNome'}"
                              [output]="pocsOutput">
                            </entity-autocomplete>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decPeriodicaIva' | translate">{{ model.decPERIVALIQUIDRCH }}</div>
                          <div class="form-control-align form-control-align-flex" [attr.title]="'controloIVA.helper.decAnualIva' | translate">{{ model.decANUIVALIQUIDRCH }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </edit>
            </pl-group>

            <pl-group *ngIf="sujeitaAsLeisVigoramEmAngola">
              <label [translate]="'controloIVA.fields.nContaIvaCativo'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaIvaCativo"
                  [model]="model"
                  (modelChange)="changedNContaIvaCativo($event)"
                  [fieldsMap]="{nConta: 'nContaIvaCativo', nome: 'nContaIvaCativoNome'}"
                  [output]="pocsOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="!regularizacaoCampoEnabledRCH40 && !regularizacaoCampoEnabledRCH41">
              <label [translate]="regularizacaoCampoCaptionRCH"></label>
              <edit>
                <pl-edit [(model)]="model.regularizacoesC40RCH" [type]="dataSourceNameTipoRegularizacaoCampo40" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="regularizacaoCampoEnabledRCH40">
              <label [translate]="regularizacaoCampoCaptionRCH"></label>
              <edit>
                <pl-edit [(model)]="model.regularizacoesC40RCH" [type]="dataSourceNameTipoRegularizacaoCampo40"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="regularizacaoCampoEnabledRCH41">
              <label [translate]="regularizacaoCampoCaptionRCH"></label>
              <edit>
                <pl-edit [(model)]="model.regularizacoesC41RCH" [type]="dataSourceNameTipoRegularizacaoCampo41"></pl-edit>
              </edit>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>
      </pl-nav-wizard>
    </div>
  </entity-detail>
</div>
