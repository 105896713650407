import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_INVERSAO_SUJ_PASSIVO} from './inversaoSujPassivo.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_INVERSAO_SUJ_PASSIVO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_INVERSAO_SUJ_PASSIVO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'inversaoSujPassivo.data.notApplicable'},
    {value: 1, name: 'inversaoSujPassivo.data.withInversion'},
    {value: 2, name: 'inversaoSujPassivo.data.noInversion'}
  ]
});
