import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRHProceSalariosResultSingleProcError} from '../../proceSalarios.module.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'proce-salarios-proc-errors-modal',
  templateUrl: './proceSalarios.procErrors.modal.component.html'
})
export class ProceSalariosProcErrorsModalComponent extends CGModalComponent<void> {
  @Input() public list: Array<IJsonRHProceSalariosResultSingleProcError>;

  public readonly dataGridDefinitionProceSalErrors: IDevExpressDataGrid<IJsonRHProceSalariosResultSingleProcError, number>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinitionProceSalErrors = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codEmpregado', dataType: 'number', caption: 'proceSalarios.fields.codEmp'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'proceSalarios.fields.nome'},
        {dataField: 'nProcessamento', dataType: 'number', caption: 'proceSalarios.fields.nProcessamento'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'proceSalarios.fields.dataProcessamento'},
        {dataField: 'descricaoErro', dataType: 'string', caption: 'global.text.error'}
      ],
      dataSource: new CustomStore({
        key: 'codEmp',
        load: () => this._dgEmpTableDefTableSource()
      }),
      export: {filename: 'proceSalarios.pesqEmpregados'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
  }

  private _dgEmpTableDefTableSource(): Array<IJsonRHProceSalariosResultSingleProcError> {
    return this.list;
  }
}
