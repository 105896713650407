import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonMod10} from '../../../../../interfaces/jsonMod10';
import {IRHProceDMRATControloItem, IRHProceDMRATViewInfoResults} from '../../proceDMRAT.module.interface';
import {ProceDMRATService} from '../../proceDMRAT.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'proce-dmr-at-view-info-modal',
  templateUrl: './proceDMRAT.viewInfo.modal.component.html'
})
export class ProceDMRATViewInfoComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nEmpresa: string;
  @Input() public ano: number;
  @Input() public mes: number;

  public readonly dataGridDefinitionControloAuto: IDevExpressDataGrid;
  public readonly dataGridDefinitionResumoDadosFuncionario: IDevExpressDataGrid;
  public readonly dataGridDefinitionControloManual: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobalControl: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobValorRendim: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobRetencaoIRS: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobContribObrig: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobQuotizSindicais: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobRetencaoSobretaxa: IDevExpressDataGrid;
  public readonly dataGridDefinitionGlobRendimAnosAnt: IDevExpressDataGrid;

  public mainPromise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _proceDMRATService: ProceDMRATService
  ) {
    super(_injector);
    this.dataGridDefinitionControloAuto = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'statusImage', dataType: 'string', caption: 'proceDMRAT.fields.statusImage', alignment: 'center', cellTemplate: 'cellTemplateStatusImage'},
        {dataField: 'nif', dataType: 'string', caption: 'proceDMRAT.fields.nif'},
        {dataField: 'codEmp', dataType: 'string', caption: 'proceDMRAT.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceDMRAT.fields.nome'},
        {dataField: 'abonosRendimentosSujeitos', dataType: 'double', caption: 'proceDMRAT.fields.abonosRendimentosSujeitos'},
        {dataField: 'abonosRendimentosNaoSujeitos', dataType: 'double', caption: 'proceDMRAT.fields.abonosRendimentosNaoSujeitos'},
        {dataField: 'descontosContribuicoesObrigatorias', dataType: 'double', caption: 'proceDMRAT.fields.descontosContribuicoesObrigatorias'},
        {dataField: 'descontosIRS', dataType: 'double', caption: 'proceDMRAT.fields.descontosIRS'},
        {dataField: 'descontosSindicatos', dataType: 'double', caption: 'proceDMRAT.fields.descontosSindicatos'},
        {dataField: 'descontosSobretaxa', dataType: 'double', caption: 'proceDMRAT.fields.descontosSobretaxa'},
        {dataField: 'abonosOutros', dataType: 'double', caption: 'proceDMRAT.fields.abonosOutros'},
        {dataField: 'descontosOutros', dataType: 'double', caption: 'proceDMRAT.fields.descontosOutros'},
        {dataField: 'rendimentosAnoAnterior', dataType: 'double', caption: 'proceDMRAT.fields.rendimentosAnoAnterior'},
        {dataField: 'liquidoCalculado', dataType: 'double', caption: 'proceDMRAT.fields.liquidoCalculado'},
        {dataField: 'liquidoVencimentos', dataType: 'double', caption: 'proceDMRAT.fields.liquidoVencimentos'}
      ],
      dataSource: [],
      export: {filename: 'proceDMRAT.viewInfoDadosAutoTab'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailProcResults'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'abonosRendimentosSujeitos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'abonosRendimentosNaoSujeitos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosContribuicoesObrigatorias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosIRS', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosSindicatos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosSobretaxa', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'abonosOutros', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosOutros', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'rendimentosAnoAnterior', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'liquidoCalculado', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'liquidoVencimentos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridDefinitionResumoDadosFuncionario = {
      columnHidingEnabled: false,
      columnChooser: {enabled: false},
      columns: [
        {dataField: 'enviaparaDMR', dataType: 'boolean', caption: 'proceDMRAT.fields.enviaparaDMR'},
        {dataField: 'nContribuint', dataType: 'string', caption: 'proceDMRAT.fields.nif'},
        {dataField: 'titular', dataType: 'string', caption: 'proceDMRAT.fields.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'string', caption: 'proceDMRAT.fields.tipRendiment'},
        {dataField: 'valorInciden', dataType: 'double', caption: 'proceDMRAT.fields.rendSujeitos'},
        {dataField: 'valorNaoSujeito', dataType: 'double', caption: 'proceDMRAT.fields.rendNaoSujeitos'},
        {dataField: 'irsRetido', dataType: 'double', caption: 'proceDMRAT.fields.irsRetido'},
        {dataField: 'descObrigSSocial', dataType: 'double', caption: 'proceDMRAT.fields.descObrigSSocial'},
        {dataField: 'quotizSindical', dataType: 'double', caption: 'proceDMRAT.fields.quotizSindical'},
        {dataField: 'sobretaxaExtraord', dataType: 'double', caption: 'proceDMRAT.fields.sobretaxaExtraord'},
        {dataField: 'outrosDescontos', dataType: 'double', caption: 'proceDMRAT.fields.outrosDescontos'},
        {dataField: 'outrosAbonos', dataType: 'double', caption: 'proceDMRAT.fields.outrosAbonos'},
        {dataField: 'rendimentosAnoAnterior', dataType: 'double', caption: 'proceDMRAT.fields.anoAntRend'},
        {dataField: 'nCodAbdes', dataType: 'string', caption: 'proceDMRAT.fields.nCodAbdes'},
        {dataField: 'descricaoAbono', dataType: 'string', caption: 'proceDMRAT.fields.descricaoAbono'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'proceDMRAT.fields.descrInsManual'}
      ],
      dataSource: [],
      export: {enabled: false},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'valorInciden', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorNaoSujeito', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'irsRetido', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descObrigSSocial', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'quotizSindical', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'sobretaxaExtraord', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'outrosDescontos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'outrosAbonos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'rendimentosAnoAnterior', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridDefinitionControloManual = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nif', dataType: 'string', caption: 'proceDMRAT.fields.nif'},
        {dataField: 'codEmp', dataType: 'string', caption: 'proceDMRAT.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceDMRAT.fields.nome'},
        {dataField: 'abonosRendimentosSujeitos', dataType: 'double', caption: 'proceDMRAT.fields.abonosRendimentosSujeitos'},
        {dataField: 'abonosRendimentosNaoSujeitos', dataType: 'double', caption: 'proceDMRAT.fields.abonosRendimentosNaoSujeitos'},
        {dataField: 'descontosContribuicoesObrigatorias', dataType: 'double', caption: 'proceDMRAT.fields.descontosContribuicoesObrigatorias'},
        {dataField: 'descontosIRS', dataType: 'double', caption: 'proceDMRAT.fields.descontosIRS'},
        {dataField: 'descontosSindicatos', dataType: 'double', caption: 'proceDMRAT.fields.descontosSindicatos'},
        {dataField: 'descontosSobretaxa', dataType: 'double', caption: 'proceDMRAT.fields.descontosSobretaxa'},
        {dataField: 'liquidoCalculado', dataType: 'double', caption: 'proceDMRAT.fields.liquidoCalculado'},
        {dataField: 'liquidoVencimentos', dataType: 'double', caption: 'proceDMRAT.fields.liquidoVencimentos'}
      ],
      dataSource: [],
      export: {filename: 'proceDMRAT.viewInfoDadosManuaisTab'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'abonosRendimentosSujeitos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'abonosRendimentosNaoSujeitos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosContribuicoesObrigatorias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosIRS', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosSindicatos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'descontosSobretaxa', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'liquidoCalculado', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'liquidoVencimentos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridDefinitionGlobalControl = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nifSujeitoPassivo', dataType: 'string', caption: 'proceDMRAT.fields.nif'},
        {dataField: 'codEmp', dataType: 'string', caption: 'proceDMRAT.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceDMRAT.fields.nome'},
        {dataField: 'rendAnoAnteriorAno', dataType: 'number', caption: 'proceDMRAT.fields.rendAnoAnteriorAno', width: 140},
        {dataField: 'rendAnoAnteriorValores', dataType: 'double', caption: 'proceDMRAT.fields.rendAnoAnteriorValores', width: 140},
        {dataField: 'rendimentodoano', dataType: 'double', caption: 'proceDMRAT.fields.rendimentodoano'},
        {dataField: 'tipoRendimento', dataType: 'string', caption: 'proceDMRAT.fields.tipoRendimento'},
        {dataField: 'localObtencaoRend', dataType: 'string', caption: 'proceDMRAT.fields.localObtencaoRend'},
        {dataField: 'retencaoIRS', dataType: 'double', caption: 'proceDMRAT.fields.retencaoIRS'},
        {dataField: 'contribuicosObrigatoriasValores', dataType: 'double', caption: 'proceDMRAT.fields.contribuicosObrigatoriasValores'},
        {dataField: 'contribuicosObrigatoriasNIF1', dataType: 'string', caption: 'proceDMRAT.fields.contribuicosObrigatoriasNIF1', width: '120px'},
        {dataField: 'contribuicosObrigatoriasNIF2', dataType: 'string', caption: 'proceDMRAT.fields.contribuicosObrigatoriasNIF2', width: '120px'},
        {dataField: 'contribuicosObrigatoriasNIF3', dataType: 'string', caption: 'proceDMRAT.fields.contribuicosObrigatoriasNIF3', width: '120px'},
        {dataField: 'quotizacaoSindical', dataType: 'double', caption: 'proceDMRAT.fields.quotizacaoSindical'},
        {dataField: 'retencaoSobretaxa', dataType: 'double', caption: 'proceDMRAT.fields.retencaoSobretaxa'}
      ],
      dataSource: [],
      export: {filename: 'proceDMRAT.viewInfoDadosGlobaisControloTab'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'rendAnoAnteriorValores', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'rendimentodoano', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'retencaoIRS', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'contribuicosObrigatoriasValores', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'quotizacaoSindical', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'retencaoSobretaxa', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridDefinitionGlobValorRendim = this._applyDataGridDefinitionGlobal('globValorRendim', 'proceDMRAT.viewInfoDadosGlobaisValorRendimentosTab', 'valorInciden');
    this.dataGridDefinitionGlobRetencaoIRS = this._applyDataGridDefinitionGlobal('globRetencaoIRS', 'proceDMRAT.viewInfoDadosGlobaisRetencaoIRSTab', 'irsRetido');
    this.dataGridDefinitionGlobContribObrig = this._applyDataGridDefinitionGlobal('globContribObrig', 'proceDMRAT.viewInfoDadosGlobaisContribObrigTab', 'descObrigSSocial');
    this.dataGridDefinitionGlobQuotizSindicais = this._applyDataGridDefinitionGlobal('globQuotizSindicais', 'proceDMRAT.viewInfoDadosGlobaisQuotizSindicaisTab', 'quotizSindical');
    this.dataGridDefinitionGlobRetencaoSobretaxa = this._applyDataGridDefinitionGlobal('globRetencaoSobretaxa', 'proceDMRAT.viewInfoDadosGlobaisRetencaoSobretaxaTab', 'sobretaxaExtraord');
    this.dataGridDefinitionGlobRendimAnosAnt = this._applyDataGridDefinitionGlobal('globRendimAnosAnt', 'proceDMRAT.viewInfoDadosGlobaisRendimAnosAntTab', 'rendimentosAnoAnterior');
  }

  public ngOnInit(): void {
    this._load();
  }

  private _load(): void {
    this.mainPromise = this._proceDMRATService.getVDMRATViewInfo(this.nEmpresa, this.ano, this.mes).then((response: IRHProceDMRATViewInfoResults) => {
      const sourceAuto: Array<IRHProceDMRATControloItem> = response.controloAutoList;
      sourceAuto.forEach((item: IRHProceDMRATControloItem) => {
        const klass: string = item.erro !== 0 ? 'text-danger' : 'text-success';
        item.statusImage = `fa fa-circle ${klass}`;
      });
      this.dataGridDefinitionControloAuto.dataSource = sourceAuto;
      this.dataGridDefinitionControloManual.dataSource = response.controloManualList;
      this.dataGridDefinitionGlobalControl.dataSource = response.controloTotalList;

      const globValorRendim: Array<Partial<IJsonMod10>> = [];
      const globRetencaoIRS: Array<Partial<IJsonMod10>> = [];
      const globContribObrig: Array<Partial<IJsonMod10>> = [];
      const globQuotizSindicais: Array<Partial<IJsonMod10>> = [];
      const globRetencaoSobretaxa: Array<Partial<IJsonMod10>> = [];
      const globRendimAnosAnt: Array<Partial<IJsonMod10>> = [];

      response.list.forEach((item: IJsonMod10) => {
        if (item.rendimentosAnoAnterior === 0) {
          if (item.valorInciden !== 0) {
            globValorRendim.push(item);
          }

          if (item.irsRetido !== 0) {
            globRetencaoIRS.push(item);
          }

          if (item.descObrigSSocial !== 0) {
            globContribObrig.push(item);
          }

          if (item.quotizSindical !== 0) {
            globQuotizSindicais.push(item);
          }

          if (item.sobretaxaExtraord !== 0) {
            globRetencaoSobretaxa.push(item);
          }
        } else {
          globRendimAnosAnt.push(item);
        }
      });

      this.dataGridDefinitionGlobValorRendim.dataSource = globValorRendim;
      this.dataGridDefinitionGlobRetencaoIRS.dataSource = globRetencaoIRS;
      this.dataGridDefinitionGlobContribObrig.dataSource = globContribObrig;
      this.dataGridDefinitionGlobQuotizSindicais.dataSource = globQuotizSindicais;
      this.dataGridDefinitionGlobRetencaoSobretaxa.dataSource = globRetencaoSobretaxa;
      this.dataGridDefinitionGlobRendimAnosAnt.dataSource = globRendimAnosAnt;
    });
  }

  private _applyDataGridDefinitionGlobal(table: string, exportName: string, totalItem: string): IDevExpressDataGrid {
    return {
      columnHidingEnabled: false,
      columns: this._setColumnsGlobal(table),
      dataSource: [],
      export: {filename: exportName},
      remoteOperations: false,
      summary: {
        totalItems: [{column: totalItem, displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}]
      }
    };
  }

  private _setColumnsGlobal(table: string): Array<IDevExpressDataGridColumn> {
    const columns: Array<IDevExpressDataGridColumn> = [
      {dataField: 'origemRendiDescr', dataType: 'string', caption: 'proceDMRAT.fields.origemRendiDescr'},
      {dataField: 'descrInsManual', dataType: 'string', caption: 'proceDMRAT.fields.descrInsManual'},
      {dataField: 'nContribuint', dataType: 'string', caption: 'proceDMRAT.fields.nif'},
      {dataField: 'codEmp', dataType: 'string', caption: 'proceDMRAT.fields.codEmp'},
      {dataField: 'titular', dataType: 'string', caption: 'proceDMRAT.fields.nome'},
      {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
      {dataField: 'tipRendiment', dataType: 'string', caption: 'proceDMRAT.fields.tipRendiment'}
    ];

    switch (table) {
      case 'globValorRendim':
        columns.push({dataField: 'valorInciden', dataType: 'double', caption: 'proceDMRAT.fields.valorInciden'});
        break;
      case 'globRetencaoIRS':
        columns.push({dataField: 'irsRetido', dataType: 'double', caption: 'proceDMRAT.fields.irsRetido'});
        break;
      case 'globContribObrig':
        columns.push({dataField: 'descObrigSSocial', dataType: 'double', caption: 'proceDMRAT.fields.descObrigSSocial'});
        break;
      case 'globQuotizSindicais':
        columns.push({dataField: 'quotizSindical', dataType: 'double', caption: 'proceDMRAT.fields.quotizSindical'});
        break;
      case 'globRetencaoSobretaxa':
        columns.push({dataField: 'sobretaxaExtraord', dataType: 'double', caption: 'proceDMRAT.fields.sobretaxaExtraord'});
        break;
      case 'globRendimAnosAnt':
        columns.push({dataField: 'rendimentosAnoAnterior', dataType: 'double', caption: 'proceDMRAT.fields.rendimentosAnoAnterior'});
        break;
    }
    return columns;
  }
}
