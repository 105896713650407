import {NgModule} from '@angular/core';
import {PlEventsListenerDirective} from './listener/events.listener.directive';

export * from './listener/events.listener.directive.interface';
export * from './listener/events.listener.directive';

const DECLARATIONS = [
  PlEventsListenerDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlEventsModule {
}
