import {Injectable} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {ApiService} from './api/api.service';
import {ConfigService} from './config/config.service';
import {EntityServiceBuilder} from './entity/entity.service.builder';
import {IApiQueryParams, IApiRequestConfigWithBody, TServiceResponse} from './api/api.service.interface';
import {IEntityService} from './entity/entity.service.interface';
import {IJsonConfigERP, IJsonConfigERPCheck, IJsonConfigERPUser, IJsonRHPortalColaboradorNotificationConfig} from '../entities/configserp/jsonConfigERP.entity.interface';
import {ENTITY_NAME_CONFIGS_ERP} from '../entities/configserp/configsErp.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigErpService {
  private readonly _path: string;
  private readonly _service: IEntityService<IJsonConfigERP>;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _configService: ConfigService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/configs`;
    this._service = this._entityServiceBuilder.build<IJsonConfigERP>(ENTITY_NAME_CONFIGS_ERP);
  }

  public get(): TServiceResponse<Array<IJsonConfigERPCheck>> {
    return this._apiService.get<Array<IJsonConfigERPCheck>>({
      url: `${this._apiService.path.restapi}/configs/erpcheck`,
      params: {configERPType: 'cetAll'}
    });
  }

  public getConfiguration(name: string): TServiceResponse<IJsonConfigERP> {
    return this._apiService
      .get<IJsonConfigERP>({
        url: `${this._path}/erp/${name}`
      })
      .then((result) => result);
  }

  public genDefaultData(): TServiceResponse<boolean> {
    return this._apiService.put<boolean>({url: `${this._apiService.path.restapi}/configs/erpgendefaultdata/novosdadosempresa`});
  }

  public genDefaultDataStatus(): TServiceResponse<number> {
    return this._apiService.get<number>({url: `${this._apiService.path.restapi}/configs/erpgendefaultdata/novosdadosempresa/status`});
  }

  public validateItem(item: IJsonConfigERPCheck): TServiceResponse<IJsonConfigERPCheck> {
    return this._apiService.put<IJsonConfigERPCheck>({url: `${this._apiService.path.restapi}/configs/erpcheck/${item.name}/validate`, body: undefined});
  }

  public repairItem(item: IJsonConfigERPCheck): TServiceResponse<IJsonConfigERPCheck> {
    return this._apiService.put<IJsonConfigERPCheck>({url: `${this._apiService.path.restapi}/configs/erpcheck/${item.name}/repair`, body: undefined});
  }

  public save(jsonConfigERP: IJsonConfigERP, doAlert: boolean = true, doRefresh: boolean = true, config?: IApiRequestConfigWithBody<IJsonConfigERP>): Promise<IJsonConfigERP> {
    return new Promise<IJsonConfigERP>((resolve, reject) => {
      this._service
        .put<IJsonConfigERP>({id: jsonConfigERP.name, body: jsonConfigERP, ...config})
        .then((response) => {
          let promise: Promise<unknown>;
          if (doRefresh) {
            promise = this._configService.refresh();
          }
          Promise.resolve(promise).finally(() => {
            if (doAlert) {
              this._plAlertService.success('configsErp.items.updated');
            }
            resolve(response.body);
          });
        })
        .catch(reject);
    });
  }

  public queryAccesses(params?: IApiQueryParams): TServiceResponse<Array<IJsonConfigERP>> {
    return this._apiService.get<Array<IJsonConfigERP>>({url: `${this._path}/erpaccesses`, params: params});
  }

  public queryUserAccesses(params?: IApiQueryParams): TServiceResponse<Array<IJsonConfigERPUser>> {
    return this._apiService.get<Array<IJsonConfigERPUser>>({url: `${this._path}/erpuseraccesses`, params: params});
  }

  public getRHPortalColaboradorNotificationConfig(): TServiceResponse<IJsonRHPortalColaboradorNotificationConfig> {
    return this._apiService.get({url: `${this._path}/rhportalcolaborador/notification/config`});
  }

  public saveRHPortalColaboradorNotificationConfig(config: IJsonRHPortalColaboradorNotificationConfig): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHPortalColaboradorNotificationConfig>({url: `${this._path}/rhportalcolaborador/notification/config`, body: config});
  }
}
