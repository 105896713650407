import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_ESTADO_CONTA, EEstadoConta} from './estadoConta.datasource.interface';

export const DATA_SOURCE_ESTADO_CONTA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ESTADO_CONTA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EEstadoConta.Activa, name: 'estadoconta.ativa'},
    {value: EEstadoConta.Desactivada, name: 'estadoconta.desactivada'},
    {value: EEstadoConta.Bloqueada, name: 'estadoconta.bloqueada'}
  ]
});
