import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AtivosService} from '../../services/ativos.service';
import {EAtivosEstado, IJsonAtivos, IJsonAtivosError, IJsonAtivosTotals} from '../../interfaces/jsonAtivos.entity.interface';
import {MODULE_NAME_ATIVOS_VENDA} from '../../../../modules/ativos/ativosvenda/ativosVenda.module.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IDevExpressDataGridEventOnCellPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {DATA_SOURCE_ATIVOS_ESTADOS} from '../../../../datasources/ativosestado/ativosEstado.datasource';
import {isNumber} from 'pl-comps-angular';
import {MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from '../../../../modules/ativos/ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {IJsonDashboardAtivoInconsistencias} from '../../../../modules/ativos/dashboardsativos/jsonDashboardAtivos.module.interface';
import {DashboardAtivosService} from '../../../../modules/ativos/dashboardsativos/dashboardAtivos.module.service';

const AVISO = 'Há documentos de venda de Ativos na Contabilidade, sem relação com os Ativos. Proceda ao registo das vendas respectivas.';

@Component({
  selector: 'ativos-list',
  templateUrl: './ativos.entity.list.component.html'
})
export class AtivosListComponent extends ModuloEntityListComponent<IJsonAtivos> implements OnInit {
  @Input() public ativosTotals: Array<IJsonAtivosTotals>;

  public verificacaoDeConsistenciaVenda: Array<IJsonAtivosError>;
  public dataGridDefinitionTotals: IDevExpressDataGrid<IJsonAtivosTotals>;
  public inconsistContab: boolean;
  public inconsistInvestEmCurso: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosService: AtivosService,
    private readonly _dashboardsService: DashboardAtivosService
  ) {
    super(_injector);
    this._evaluateInconsistency();
    this.verificacaoDeConsistenciaVenda = [];
    this._ativosService.getVerificacaoDeConsistenciaVenda().then((response: HttpResponse<Array<IJsonAtivosError>>) => {
      this.verificacaoDeConsistenciaVenda = response.body.map((inconsistencia: IJsonAtivosError) => {
        if (inconsistencia.error === AVISO) {
          inconsistencia.error = 'ativos.verificacaoDeConsistenciaVenda.error';
        }
        return inconsistencia;
      });
    });
    this.dataGridDefinitionTotals = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'estado', dataType: 'number', caption: 'ativos.listTotals.estado', customizeText: this._fnCustomizeEstadoTotais},
        {dataField: 'qtdEstado', dataType: 'number', caption: 'ativos.listTotals.totalativos'},
        {dataField: 'totalValAquisicao', dataType: 'double', caption: 'ativos.listTotals.totalvalaquisicao'},
        {dataField: 'totalDepAcumulada', dataType: 'double', caption: 'ativos.listTotals.totaldepacumulada'},
        {dataField: 'totalValLiquido', dataType: 'double', caption: 'ativos.listTotals.totalvalliquido'}
      ],
      dataSource: [],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      toolbar: {visible: false},
      remoteOperations: false,
      showColumnLines: false,
      summary: {
        totalItems: [
          {column: 'totalValAquisicao', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'totalDepAcumulada', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'totalValLiquido', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
  }

  public onDataGridCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAtivos>): void {
    if (event.rowType === 'data' && (event.column.dataField === 'estado' || event.column.dataField === 'dataEstado')) {
      event.cellElement.classList.add(event.data.estado === EAtivosEstado.Normal ? 'ativos-green-estado' : 'ativos-yellow-estado');
    }
  }

  public redirectToVendaSerie(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_VENDA, params: {isfromativos: true}});
  }

  public redirectToAquisicaoContab(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_AQUISICAO_CONTAB});
  }

  private _evaluateInconsistency(): void {
    this.inconsistContab = this.inconsistInvestEmCurso = false;
    this._dashboardsService.getAtivosInconsistencias().then((response: HttpResponse<IJsonDashboardAtivoInconsistencias>) => {
      this.inconsistContab = response.body.consisContab;
      this.inconsistInvestEmCurso = response.body.investEmCurso;
    });
  }

  private _customizeEstadoTotais(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (isNumber(Number(cellInfo.value))) {
      return this._translateService.instant(DATA_SOURCE_ATIVOS_ESTADOS.data[Number(cellInfo.value)]?.name);
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeEstadoTotais = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => this._customizeEstadoTotais(cellInfo);
}
