import type {Subscribable} from 'rxjs';
import {InjectionToken} from '@angular/core';

export interface IPortalComponent {
  readonly toolbarInstanceId: string;

  toolbarInstanceId$(): Subscribable<string>;

  setToolbarInstanceId(toolbarInstanceId: string): void;
}

export const DI_PORTAL_COMPONENT: InjectionToken<IPortalComponent> = new InjectionToken<IPortalComponent>('DI_PORTAL_COMPONENT');
