import {Observable} from 'rxjs';
import {TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService, IEntityServiceQueryRequestConfig} from '../../services/entity/entity.service.interface';
import {IJsonPRHComIntCab, IJsonPRHComunicacaoInterna} from './jsonPRHComunicacaoInterna.entity.interface';

export interface IPRHComunicacoesInternasEntityService extends IEntityService<IJsonPRHComunicacaoInterna> {
  query<TResponse = IJsonPRHComIntCab>(config?: IEntityServiceQueryRequestConfig): TServiceQueryResponse<TResponse>;

  downloadDoc(comIntCabId: number): TServiceResponse<Blob>;

  downloadDocUrl(comIntCabId: number): Observable<string>;

  uploadDoc(comIntCabId: number, file: Blob, filename?: string): TServiceResponse<IJsonPRHComIntCab>;

  uploadDocUrl(comIntCabId: number, filename?: string): Observable<string>;
}

export interface IPRHComunicacoesInternasEntity extends IEntityDefinition<IJsonPRHComunicacaoInterna, IPRHComunicacoesInternasEntityService> {}

export const ENTITY_NAME_PRH_COMUNICACOES_INTERNAS = 'prhcomunicacoesinternas';
