import {merge} from 'lodash-es';
import {BehaviorSubject, from, fromEvent, Observable, Subscription} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {EPlMediaDevicesGetMediaStreamErrorKind, IPlMediaDevicesSupports} from './mediadevices.service.interface';
import {isObject} from '../../common/utilities/utilities';

export function plMediaDevicesAdapterFunctionFactory(): PlMediaDevicesAdapter {
  return new PlMediaDevicesService();
}

@Injectable({
  providedIn: 'root',
  useFactory: plMediaDevicesAdapterFunctionFactory
})
export abstract class PlMediaDevicesAdapter {
  public abstract mediaDevices(): Observable<MediaDevices>;

  public abstract supports(): Observable<IPlMediaDevicesSupports>;

  public abstract mediaStream(constraints?: MediaStreamConstraints): Observable<MediaStream>;
}

@Injectable()
export class PlMediaDevicesService extends PlMediaDevicesAdapter implements OnDestroy {
  private readonly _subjectMediaDevices: BehaviorSubject<MediaDevices>;
  private readonly _observableMediaDevices: Observable<MediaDevices>;
  private readonly _subscriptionMediaDevices: Subscription;
  private _subscriptionDeviceChange: Subscription;

  constructor() {
    super();
    this._subjectMediaDevices = new BehaviorSubject<MediaDevices>(undefined);
    this._onDeviceChange();
    this._observableMediaDevices = this._subjectMediaDevices.asObservable();
    this._subscriptionMediaDevices = this.mediaDevices().subscribe((mediaDevices: MediaDevices) => {
      this._attachDeviceChangeListener(mediaDevices);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionMediaDevices.unsubscribe();
    this._clearDeviceChangeListener();
    this._subjectMediaDevices.complete();
  }

  public mediaDevices(): Observable<MediaDevices> {
    return this._observableMediaDevices;
  }

  public supports(): Observable<IPlMediaDevicesSupports> {
    return this.mediaDevices()
      .pipe(
        mergeMap<MediaDevices, Observable<Array<MediaDeviceInfo>>>((mediaDevices: MediaDevices) => {
          if (!mediaDevices) {
            throw new Error(EPlMediaDevicesGetMediaStreamErrorKind.UnavailableMediaDevices);
          }
          return from(mediaDevices.enumerateDevices());
        })
      )
      .pipe(
        map<Array<MediaDeviceInfo>, IPlMediaDevicesSupports>((devices: Array<MediaDeviceInfo>) => {
          const mediaDevicesSupports: IPlMediaDevicesSupports = {camera: false, microphone: false, sound: false};
          for (const device of devices) {
            switch (device.kind) {
              case 'videoinput':
                mediaDevicesSupports.camera = true;
                break;
              case 'audioinput':
                mediaDevicesSupports.sound = true;
                break;
              case 'audiooutput':
                mediaDevicesSupports.microphone = true;
                break;
            }
          }
          return mediaDevicesSupports;
        })
      );
  }

  public mediaStream(constraints?: MediaStreamConstraints): Observable<MediaStream> {
    return this.mediaDevices().pipe(
      mergeMap<MediaDevices, Observable<MediaStream>>((mediaDevices: MediaDevices) => {
        if (!mediaDevices) {
          throw new Error(EPlMediaDevicesGetMediaStreamErrorKind.UnavailableMediaDevices);
        }
        constraints = merge({}, constraints, {video: {facingMode: 'user'}, audio: false});
        return from<Promise<MediaStream>>(mediaDevices.getUserMedia(constraints));
      })
    );
  }

  private _onDeviceChange(): void {
    const mediaDevices: MediaDevices = this._mediaDevices();
    this._subjectMediaDevices.next(mediaDevices);
  }

  private _attachDeviceChangeListener(mediaDevices: MediaDevices): void {
    this._clearDeviceChangeListener();
    if (mediaDevices) {
      this._subscriptionDeviceChange = fromEvent(mediaDevices, 'devicechange', {passive: true}).subscribe(() => {
        this._onDeviceChange();
      });
    }
  }

  private _clearDeviceChangeListener(): void {
    if (this._subscriptionDeviceChange) {
      this._subscriptionDeviceChange.unsubscribe();
      this._subscriptionDeviceChange = undefined;
    }
  }

  private _mediaDevices(): MediaDevices {
    if (isObject(window.navigator) && isObject(window.navigator.mediaDevices)) {
      return window.navigator.mediaDevices;
    }
    return undefined;
  }
}
