<div class="impencar entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_imp_encar" attrName="listagem" [(model)]="report"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'impencar.fields.dataDe'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataDe">
                  <div *inputGroup="{klass: 'hollow-form dropdown-processamento'}">
                    <div ngbDropdown placement="right">
                      <button type="button" class="btn btn-secondary btn-sm" ngbDropdownToggle data-capture-click></button>
                      <div ngbDropdownMenu>
                        <button *ngFor="let date of listaDateProcDistinct" type="button" ngbDropdownItem (click)="header.dataDe = date">{{ date | cgcDate }}</button>
                      </div>
                    </div>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataAte">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip *ngIf="!promiseProcessamento" [config]="tooltipExcepcoesProcessamento">
                      <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" plDelegateEventDownwardsClick (click)="openExclusionsProcessamento()"></i>
                    </pl-tooltip>
                    <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true" *ngIf="promiseProcessamento"></i>
                  </div>
                  <div *inputGroup="{klass: 'hollow-form dropdown-processamento'}">
                    <div ngbDropdown placement="right">
                      <button type="button" class="btn btn-secondary btn-sm" ngbDropdownToggle data-capture-click></button>
                      <div ngbDropdownMenu>
                        <button *ngFor="let date of listaDateProcDistinct" type="button" ngbDropdownItem (click)="header.dataAte = date">{{ date | cgcDate }}</button>
                      </div>
                    </div>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'impencar.fields.codEmpDe'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="codEmpDe" [model]="header" (selectedKeyChange)="header.codEmpDe = $event" [fieldsMap]="{codEmp: 'codEmpDe'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="codEmpAte" [model]="header" (selectedKeyChange)="header.codEmpAte = $event" [fieldsMap]="{codEmp: 'codEmpAte'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'impencar.fields.codEncargoDe'"></label>
            <edit>
              <pl-edit [(model)]="header.codEncargoDe" type="text"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit [(model)]="header.codEncargoAte" type="text" [properties]="{events: {keydown: fnKeydownProcessar}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
      </pl-form>
    </div>
  </cg-card-panel>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
