import type {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import type {IPlButton, TPlButtonOnClickFn, TPlButtonType} from './button.component.interface';
import type {IPlTooltipConfig} from '../tooltip/tooltip.interface';
import {isBoolean, isFunction} from '../common/utilities/utilities';
import {KEYCODES} from '../common/constants';
import {PlTranslateService} from '../translate/translate.service';

@Component({
  selector: 'pl-button',
  templateUrl: './button.component.html',
  standalone: false,
  exportAs: 'cgcButton'
})
export class PlButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public type: TPlButtonType;
  @Input() public klass: string;
  @Input() public text: string;
  @Input() public disabled: boolean;
  @Input() public tooltip: IPlTooltipConfig;
  @Input() public onClick: TPlButtonOnClickFn;
  @Input() public attrName: string;
  @Input() public attrTitle: string;
  @Input() public properties: IPlButton;
  @Input() public focus: void | '';
  @Input() public promise: Promise<any>;
  @Output() public readonly promiseChange: EventEmitter<Promise<any>>;
  @Output() public readonly evtClicked: EventEmitter<MouseEvent | KeyboardEvent>;

  @ViewChild('elementBtn') private readonly _elementBtn: ElementRef<HTMLButtonElement>;
  private readonly _subscriptionClicked: Subscription;

  constructor(private readonly _plTranslateService: PlTranslateService) {
    this.promiseChange = new EventEmitter<Promise<any>>();
    this.evtClicked = new EventEmitter<MouseEvent | KeyboardEvent>();
    this._subscriptionClicked = this.evtClicked.pipe(distinctUntilChanged()).subscribe(() => {
      if (isFunction(this.onClick)) {
        this.promise = Promise.resolve(this.onClick());
        this.promiseChange.emit(this.promise);
      }
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({properties, type, klass, text, disabled, tooltip, onClick}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._handleChanges();
      return;
    }
    if (type && !type.isFirstChange()) {
      this._changedType(type.currentValue);
    }
    if (klass && !klass.isFirstChange()) {
      this._changedKlass(klass.currentValue);
    }
    if (text && !text.isFirstChange()) {
      this._changedText(text.currentValue);
    }
    if (disabled && !disabled.isFirstChange()) {
      this._changedDisabled(disabled.currentValue);
    }
    if (tooltip && !tooltip.isFirstChange()) {
      this._changedTooltip(tooltip.currentValue);
    }
    if (onClick && !onClick.isFirstChange()) {
      this._changedOnClick(onClick.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionClicked.unsubscribe();
  }

  public doClick(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    if (!event.defaultPrevented) {
      event.preventDefault();
    }
    this.evtClicked.emit(event);
  }

  public doKeydown(event: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }
    if (event.key === KEYCODES.SPACEBAR || event.key === KEYCODES.ENTER) {
      if (!event.defaultPrevented) {
        event.preventDefault();
      }
      this.evtClicked.emit(event);
    }
  }

  public get buttonElement(): HTMLButtonElement {
    return this._elementBtn?.nativeElement;
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedType();
    this._changedKlass();
    this._changedText();
    this._changedDisabled();
    this._changedTooltip();
    this._changedOnClick();
  }

  private _changedProperties(value: IPlButton = this.properties): void {
    this.properties = value || {};
  }

  private _changedType(value: TPlButtonType = this.type): void {
    this.type = value || this.properties.type || 'button';
  }

  private _changedKlass(value: string = this.klass): void {
    this.klass = value || this.properties.class || 'btn-secondary';
  }

  private _changedText(value: string = this.text): void {
    value = value || this.properties.text || undefined;
    this.text = this._plTranslateService.translate(value);
  }

  private _changedDisabled(value: boolean = this.disabled): void {
    let newValue: boolean = value;
    if (!isBoolean(newValue)) {
      newValue = this.properties.disabled;
    }
    if (!isBoolean(newValue)) {
      newValue = false;
    }
    this.disabled = newValue;
  }

  private _changedTooltip(value: IPlTooltipConfig = this.tooltip): void {
    this.tooltip = value || this.properties.tooltip || undefined;
  }

  private _changedOnClick(value: TPlButtonOnClickFn = this.onClick): void {
    let newValue: TPlButtonOnClickFn = value;
    if (!isFunction(newValue)) {
      newValue = this.properties.onClick;
    }
    this.onClick = newValue;
  }
}
