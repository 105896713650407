import {IJsonControloIVA} from './jsonControloIVA.entity.interface';

export enum EDeclaracaoPeriodicaInfo {
  NA = '',
  SQUADROACAMPO97 = 'QUADRO A - CAMPO 97',
  SQUADROACAMPO98 = 'QUADRO A - CAMPO 98',
  SQUADROBCAMPO99 = 'QUADRO B - CAMPO 99',
  SQUADROBCAMPO100 = 'QUADRO B - CAMPO 100',
  SQUADROBCAMPO101 = 'QUADRO B - CAMPO 101',
  SQUADROBCAMPO102 = 'QUADRO B - CAMPO 102',
  SQUADROCCAMPO103 = 'QUADRO C - CAMPO 103',
  SQUADRODCAMPO104 = 'QUADRO D - CAMPO 104',
  SQUADROBCAMPO105 = 'QUADRO B - CAMPO 105',
  SQUADROBCAMPO107 = 'QUADRO B - CAMPO 107'
}

export interface IControloIVA extends IJsonControloIVA {
  qivapsNCampoOficial: string;
}

export const ENTITY_NAME_CONTROLO_IVA = 'controloIVA';
