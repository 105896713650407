import moment, {Moment} from 'moment';
import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PlDynamicVisualsReference, PlDynamicVisualsService} from 'pl-comps-angular';
import {EGestaoDGEMPSView} from '../gestaodgemps.interface';
import {gestaoDGEMPSDefaultViewDate} from '../gestaodgemps.utilities';
import {GestaoDGEMPSViewDatePickerComponent} from './picker/gestaodgemps.viewdate.picker.component';

@Component({
  selector: 'gestao-dgemps-view-date',
  templateUrl: './gestaodgemps.viewdate.component.html'
})
export class GestaoDGEMPSViewDateComponent implements OnChanges {
  @Input() public view: EGestaoDGEMPSView;
  @Input() public viewDate: Moment;
  @Input() public format: string;
  @Output() public readonly viewDateChange: EventEmitter<Moment>;

  private readonly _element: HTMLElement;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plDynamicVisualsService: PlDynamicVisualsService
  ) {
    this.viewDateChange = new EventEmitter<Moment>();
    this._element = this._elementRef.nativeElement;
  }

  public ngOnChanges({viewDate}: SimpleChanges): void {
    if (viewDate) {
      this.viewDate = moment.isMoment(this.viewDate) ? this.viewDate.clone() : gestaoDGEMPSDefaultViewDate();
    }
  }

  public openPicker(): void {
    const reference: PlDynamicVisualsReference<void> = this._plDynamicVisualsService.openVanilla(GestaoDGEMPSViewDatePickerComponent);
    const componentInstance: GestaoDGEMPSViewDatePickerComponent = reference.componentInstance;
    componentInstance.hostElement = this._element;
    componentInstance.autoClose = 'outside';
    componentInstance.view = this.view;
    componentInstance.viewDate = this.viewDate.clone();
    componentInstance.viewDateChange.subscribe((viewDate: Moment) => {
      this.viewDate = viewDate;
      this.viewDate = this.viewDate.clone();
      this.viewDateChange.emit(this.viewDate);
    });
  }
}
