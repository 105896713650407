<div *ngIf="!options.readonly" #elementHost class="pl-autocomplete" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="stopCloseDropdown()">
  <div class="input-form" [ngClass]="{'input-group pl-input-group': options.raw === false, 'input-focus': inputFocused, 'input-disabled': inputElement.disabled, validating: validating}">
    <input
      #inputElement
      type="text"
      class="form-control pl-autocomplete-input"
      autocapitalize="off"
      autocomplete="off"
      editEventsHandlerIgnore="keydown,focus,blur"
      [formControl]="formControl"
      [attr.name]="attrName"
      [attr.placeholder]="placeholder"
      [class.raw]="options.raw"
      [ngClass]="inputClass"
      [editEventsHandler]="options.events"
      [editEventsHandlerValue]="value"
      (dblclick)="showDropdown()"
      (keydown)="onInputKeyDown(value, $event)"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)" />

    <div *ngIf="validating" class="pl-loading-loader">
      <div class="circle-spinner">
        <div class="spinner"></div>
      </div>
    </div>

    <button *ngIf="showClear" type="button" class="btn hollow-form" tabindex="-1" (click)="clearViewValue()">
      <i class="pl-icon-close" aria-hidden="true"></i>
    </button>

    <ng-container *ngIf="hasGroup && options.raw !== true">
      <button
        *ngFor="let itemGroup of itemGroups"
        #buttonElement
        type="button"
        class="btn"
        plDelegateEventDownwards
        [ngClass]="itemGroup.klass"
        [class.raw]="options.raw || itemGroup.raw"
        [disabled]="itemGroup.disabled ?? options.disabled ?? false"
        [attr.tabindex]="itemGroup.tabindex"
        [attr.title]="itemGroup.title"
        [plPromise]="itemGroup.promise">
        <ng-container *ngTemplateOutlet="itemGroup.templateRef; context: {button: buttonElement, isMouseIn: isMouseIn}"></ng-container>
      </button>
    </ng-container>

    <button
      *ngIf="!options.raw && options.showToggle !== false"
      type="button"
      class="btn btn-show-autocomplete btn-outline-secondary dropdown-toggle"
      [disabled]="options.disabled"
      (click)="toggleDropdown()"></button>
  </div>

  <div
    *ngIf="dropdownOpen"
    #elementDropdown
    class="pl-autocomplete-dropdown-wrapper"
    plLifeCycle
    [class.filter-active]="options.filterFields && options.showFilter"
    [ngClass]="options.cssClassDropdownWrapper || ''"
    [plPromise]="promise"
    (evtInit)="initDropdown(elementHost, elementDropdown)"
    (mouseenter)="onWrapperMouseEnter()"
    (mouseleave)="onWrapperMouseLeave()"
    (click)="stopCloseDropdown()">
    <ng-container *ngTemplateOutlet="templateDropdownContent?.templateRef || templateAutocompleteDropdown; context: {component: self, properties: options}"></ng-container>

    <div *ngIf="options.filterFields && options.showFilter" class="pl-autocomplete-dropdown-filter">
      <pl-filter [fields]="options.filterFields" [collapsed]="isFilterCollapsed" [showCaption]="true" [(serializedFilters)]="options.filterModel" (evtFiltered)="doFilter($event)"></pl-filter>
    </div>
  </div>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>

<span *ngIf="options.readonly" class="form-control-align">{{ formControl.value }}</span>

<!-- eslint-disable @angular-eslint/template/no-call-expression -->

<ng-template #templateAutocompleteDropdown>
  <div class="pl-autocomplete-dropdown" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="0" (scrolled)="loadMore()">
    <ul #dropdown class="list-unstyled">
      <li *ngIf="searching && !items.length" class="pl-autocomplete-dropdown-searching">{{ textSearching }}</li>
      <li *ngIf="!searching && !items.length" class="pl-autocomplete-dropdown-empty">{{ textEmpty }}</li>
      <li
        *ngFor="let item of items; index as $index; trackBy: trackByFn"
        class="pl-autocomplete-dropdown-item"
        [ngClass]="{'pl-autocomplete-selected-row': $index === currentIndex}"
        [innerHTML]="rows.get(item) | cgcHighlight: formControl.value | cgcBindHtml"
        (mousedown)="onItemMousedown(item, $event)"></li>
    </ul>
  </div>
</ng-template>
