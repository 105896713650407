import {HttpParameterCodec} from '@angular/common/http';

// TODO: Remove when #https://github.com/angular/angular/issues/11058 gets FIXED
export class HttpParamsEncoder implements HttpParameterCodec {
  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  public decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  public decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const httpParamsEncoder: HttpParamsEncoder = new HttpParamsEncoder();
