import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonNacionalidade} from './jsonNacionalidade.entity.interface';
import {ENTITY_NAME_NACIONALIDADES} from './nacionalidades.entity.interface';
import {ENTITY_NAME_QPPAISES} from '../qppais/qpPaises.entity.interface';

export const ENTITY_NACIONALIDADES: IEntityDefinition<IJsonNacionalidade> = {
  name: ENTITY_NAME_NACIONALIDADES,
  roles: [ROLE.RH],
  searchPlaceholder: 'nacionalidades.pesquisa',
  metadata: {
    keyName: 'nNacionalidade',
    fields: [
      {name: 'nNacionalidade', type: 'string', width: '120px', caption: 'nacionalidades.fields.nNacionalidade', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'nacionalidades.fields.designacaoBreve', validators: {required: true, maxlength: 20}},
      {name: 'designacaoCompleta', caption: 'nacionalidades.fields.designacaoCompleta', validators: {required: true, maxlength: 40}},
      {name: 'codNacionQPessoal', caption: 'nacionalidades.fields.codNacionQPessoal', visible: true},
      {
        name: 'nomeNacionQPessoal',
        caption: 'nacionalidades.fields.nomeNacionQPessoal',
        entity: {name: ENTITY_NAME_QPPAISES, keyTarget: 'codNacionQPessoal', outputTarget: 'nomeNacionQPessoal'},
        validators: {required: true}
      }
    ],
    order: 'nNacionalidade',
    searchFields: 'nNacionalidade,designacaoBreve',
    listFields: 'nNacionalidade,designacaoBreve,designacaoCompleta,codNacionQPessoal,nomeNacionQPessoal',
    newFields: 'nNacionalidade,designacaoBreve,designacaoCompleta,nomeNacionQPessoal',
    editFields: 'nNacionalidade,designacaoBreve,designacaoCompleta,nomeNacionQPessoal',
    detailFields: 'nNacionalidade,designacaoBreve,designacaoCompleta,nomeNacionQPessoal',
    filterFields: 'nNacionalidade, designacaoBreve, designacaoCompleta, codNacionQPessoal, nomeNacionQPessoal'
  },
  autocomplete: {
    rowTemplate: '{{nNacionalidade}} - {{designacaoBreve}}',
    output: 'designacaoBreve',
    searchFields: 'nNacionalidade,designacaoBreve,designacaoCompleta,codNacionQPessoal,nomeNacionQPessoal'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.nacionalidades'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.nacionalidades'
      }
    }
  },
  new: {
    state: {
      data: {
        pageTitle: 'global.menu.nacionalidades'
      }
    }
  }
};
