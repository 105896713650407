import {Injectable} from '@angular/core';
import type {ILogger} from './logger.interface';
import {isError} from '../common/utilities/utilities';
import {Logger} from './logger';

@Injectable({
  providedIn: 'root'
})
export class CGLoggerService extends Logger implements ILogger {
  protected _enabled: boolean;

  private readonly _formatStackTrace: boolean;

  constructor() {
    super();
    this._enabled = true;
    // document.documentMode = Internet Explorer only
    this._formatStackTrace = (<any>window.document).documentMode || /\bEdge\//.test(window.navigator?.userAgent);
  }

  public fallback(...values: Array<any>): void {
    this.log(values);
  }

  public setDebugEnabled(enabled: boolean): void {
    this._enabled = enabled;
  }

  public log(...values: Array<any>): void {
    this._execute('log', values);
  }

  public error(...values: Array<any>): void {
    this._execute('error', values);
  }

  public debug(...values: Array<any>): void {
    this._execute('debug', values);
  }

  public info(...values: Array<any>): void {
    this._execute('info', values);
  }

  public warn(...values: Array<any>): void {
    this._execute('warn', values);
  }

  public assert(...values: Array<any>): void {
    this._execute('assert', values);
  }

  public group(...values: Array<any>): void {
    this._execute('group', values);
  }

  public groupEnd(...values: Array<any>): void {
    this._execute('groupEnd', values);
  }

  protected _execute(fn: 'log' | 'error' | 'debug' | 'info' | 'warn' | 'assert' | 'group' | 'groupEnd', values: Array<any>): void {
    const console: any = window.console || {};
    if (this._enabled && console) {
      const logFn =
        console[fn] ||
        console.log ||
        function () {
          // do nothing
        };

      const args = [];
      for (const arg of values) {
        args.push(this._formatError(arg));
      }

      // Support: IE 9 only
      // console methods don't inherit from Function.prototype in IE 9 so we can't
      // call `logFn.apply(console, values)` directly.
      Function.prototype.apply.call(logFn, console, args);
    }
  }

  protected _formatError(error: any): any {
    if (isError(error)) {
      if (error.stack && this._formatStackTrace) {
        error = error.message && !error.stack.includes(error.message) ? `Error: ${error.message}\n${error.stack}` : error.stack;
      } else if ((<any>error).sourceURL) {
        error = `${error.message}\n${String((<any>error).sourceURL)}:${String((<any>error).line)}`;
      }
    }
    return error;
  }
}
