import {Draft, produce} from 'immer';
import {deepFreeze} from 'pl-comps-angular';
import {ENotificationType} from '../../services/notificationcenter/notificationcenter.service.interface';

export interface INotificationCenterStateParams {
  newNotificationsIds?: Array<string>;
}

export interface INotificationCenterCategory {
  readonly type: ENotificationType;
  readonly caption: string;
  readonly active: boolean;
}

export const MODULE_NAME_NOTIFICATION_CENTER = 'notificationcenter';

export const NOTIFICATION_CENTER_CATEGORIES: ReadonlyArray<INotificationCenterCategory> = deepFreeze([
  {type: ENotificationType.All, caption: 'notificationcenter.categories.all', active: true},
  {type: ENotificationType.News, caption: 'notificationcenter.categories.news', active: false},
  {type: ENotificationType.Maintenance, caption: 'notificationcenter.categories.maintenance', active: false}
]);

export function notificationCenterActivateCategory(categories: ReadonlyArray<INotificationCenterCategory>, type: ENotificationType): ReadonlyArray<INotificationCenterCategory> {
  return notificationCenterSetCategoryState(categories, type, true);
}

export function notificationCenterDeactivateCategory(categories: ReadonlyArray<INotificationCenterCategory>, type: ENotificationType): ReadonlyArray<INotificationCenterCategory> {
  return notificationCenterSetCategoryState(categories, type, false);
}

function notificationCenterSetCategoryState(categories: ReadonlyArray<INotificationCenterCategory>, type: ENotificationType, active: boolean): ReadonlyArray<INotificationCenterCategory> {
  return produce(categories, (draft: Draft<Array<INotificationCenterCategory>>) => {
    const category: Draft<INotificationCenterCategory> = draft.find((categoryItem: INotificationCenterCategory) => categoryItem.type === type);
    if (category) {
      category.active = active;
    }
  });
}
