import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../translate/translate.module';
import {PlNavPillComponent} from './navpill.component';
import {PlNavPillFooterDirective} from './navpill.footer.directive';
import {PlNavPillPanelDirective} from './navpill.panel.directive';
import {PlNavPillPanelTitleDirective} from './navpill.panel.title.directive';
import {PlNavPillPanelContentDirective} from './navpill.panel.content.directive';

export * from './navpill.interface';
export * from './navpill.component';
export * from './navpill.footer.directive';
export * from './navpill.panel.directive';
export * from './navpill.panel.title.directive';
export * from './navpill.panel.content.directive';

const DECLARATIONS = [
  PlNavPillComponent,
  PlNavPillPanelDirective,
  PlNavPillPanelTitleDirective,
  PlNavPillPanelContentDirective,
  PlNavPillFooterDirective
];

@NgModule({
  imports: [
    CommonModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlNavPillModule {
}
