<pl-form class="encomendas-simulacao-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.simulacao.title'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
