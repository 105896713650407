import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'modelo22-inputs-currency-inline',
  templateUrl: './inputsCurrencyInline.component.html'
})
export class Modelo22InputsCurrencyInlineComponent implements OnChanges {
  @Input() public mod22Translate: string;
  @Input() public mod22Field: string;
  @Input() public mod22AttrName: string;
  @Input() public mod22FieldValue: number;
  @Input() public titleContasDef: string;
  @Input() public classBoxNumbers: string;
  @Output() public readonly evtChangedFieldValue: EventEmitter<number>;
  @Output() public readonly evtOpenContasDef: EventEmitter<string>;

  constructor() {
    this.evtChangedFieldValue = new EventEmitter<number>();
    this.evtOpenContasDef = new EventEmitter<string>();
    this.mod22Translate = '';
    this.mod22Field = '';
    this.mod22AttrName = '';
    this.mod22FieldValue = undefined;
    this.titleContasDef = '';
    this.classBoxNumbers = 'input-box-numbers-hundred';
  }

  public ngOnChanges({fieldValue}: SimpleChanges): void {
    if (fieldValue && !fieldValue.isFirstChange()) {
      this.mod22FieldValue = fieldValue.currentValue;
    }
  }

  public fieldValueChanged(): void {
    this.evtChangedFieldValue.emit(this.mod22FieldValue);
  }

  public openContasDef(): void {
    this.evtOpenContasDef.emit(this.mod22Field);
  }
}
