import {Component, Injector} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditConfigERP} from '../../components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const CONFIG_TYPE = 'licenca';

@Component({
  selector: 'module-configs-erp-licenca',
  templateUrl: './configs.licenca.module.component.html'
})
export class ConfigsErpLicencaModuleComponent extends ConfigsErpBaseModuleComponent {
  public readonly definition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'description', dataType: 'string', caption: 'entity.detail.field'},
        {dataField: 'value', caption: 'entity.detail.value'}
      ]
    };
  }

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Single;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP> {
    return rawConfigurations
      .filter((item) => item.name.startsWith(`${CONFIG_TYPE}.`))
      .map<IConfigsErpEditConfigERP>((item: IJsonConfigERP, index: number) => {
        const transformedItem: IConfigsErpEditConfigERP = {...item, saveField: this.fnSaveField, index: index};
        return transformedItem;
      });
  }
}
