import {HttpResponse} from '@angular/common/http';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {IJsonRHFolFerHeader} from './jsonRHFolfer.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_FOLFER} from './rhFolfer.module.interface';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {RHFolFerModuleComponent} from './components/rhFolfer.module.component';
import {RHFolFerService} from './rhFolfer.module.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_FOLFER: IModuleDefinition = {
  name: MODULE_NAME_RH_FOLFER,
  state: {
    url: `/${MODULE_NAME_RH_FOLFER}`,
    component: RHFolFerModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhfolfer'
    },
    resolve: [
      {
        provide: 'header',
        deps: [RHFolFerService],
        useFactory: (rhFolFerService: RHFolFerService): Promise<IJsonRHFolFerHeader> => {
          return rhFolFerService.getLoadDefaultHeader().then((response: HttpResponse<IJsonRHFolFerHeader>) => response.body);
        }
      },
      {
        provide: 'defaultReport',
        deps: [ReportsRegistryService],
        useFactory: (reportsRegistryService: ReportsRegistryService): Promise<IJsonReport> => {
          return reportsRegistryService
            .get(EReport.RHFolFer)
            .query({pagina: 1, porpagina: 1})
            .then((reports: Array<IJsonReport>) => {
              if (reports.length) {
                return reports[0];
              }
              return undefined;
            });
        }
      }
    ]
  }
};
