import {IPlEditComponentOptionsInputAutocomplete, TPlEditAutocompleteRowTemplateFn} from 'pl-comps-angular';
import {EReport} from '../../../entities/reports/reports.interface';
import {IEntityServiceQueryRequestConfig} from '../../../services/entity/entity.service.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';

export type TReportInstanceQueryRequest = (requestConfig?: IReportsQueryRequestConfig) => Promise<Array<IJsonReport>>;

export type TReportInstanceGetRequest = (name: EReport, requestConfig?: IReportsQueryRequestConfig) => Promise<Array<IJsonReport>>;

export interface IReportsQueryRequestConfig extends IEntityServiceQueryRequestConfig {
  subfolder?: string | Array<string>;
}

export interface IReportInstance extends IReportInstanceDefinition {
  _originalUrl: string;
  query: TReportInstanceQueryRequest;
  get: TReportInstanceGetRequest;

  subfolder(name: string): IReportInstance;
}

export interface IReportInstanceDefinition {
  name: EReport;
  url?: string;
  metadata: IReportInstanceOptionsMetadata;
  autocomplete: IReportInstanceOptionsAutocomplete;
}

export interface IReportInstanceOptionsMetadata {
  keyName: string;
  fields: Array<IReportInstanceOptionsMetadataField>;
}

export interface IReportInstanceOptionsMetadataField {
  name: string;
  caption: string;
  placeholder?: string;
  properties?: IReportInstanceOptions;
}

export interface IReportInstanceOptionsAutocomplete {
  rowTemplate: string | TPlEditAutocompleteRowTemplateFn;
  output: string;
  outputKey?: string;
}

export interface IReportInstanceOptions extends Partial<IPlEditComponentOptionsInputAutocomplete>, Partial<IReportInstanceOptionsAutocomplete> {
  inlineMode?: boolean;
  subfolder?: string | Array<string>;
  serviceMethodsOverride?: IReportInstanceMethodsOverride;
  _report?: EReport;
}

export interface IReportInstanceMethodsOverride {
  query?: TReportInstanceQueryRequest;
  get?: TReportInstanceGetRequest;
}

export const reportInputComponentName = 'inputReport';
