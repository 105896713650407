import {Injectable} from '@angular/core';
import type {IPlPdfOptions} from './pdf.component.interface';

@Injectable({
  providedIn: 'root'
})
export class PlPdfOptionsService implements IPlPdfOptions {
  private _withCredentials: boolean;

  constructor() {
    this._withCredentials = false;
  }

  public get withCredentials(): boolean {
    return this._withCredentials;
  }

  public set withCredentials(value: boolean) {
    this._withCredentials = value;
  }
}
