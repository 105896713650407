<div class="contabilidade-digital-doc-viewer-recolha-footer" [class.has-collapse]="collapsed">
  <div class="contabilidade-digital-doc-viewer-recolha-footer-collapse" *ngIf="showCollapse" (click)="toggleCollapse()">
    <i class="fa" aria-hidden="true" [class.fa-angle-up]="collapsed" [class.fa-angle-down]="!collapsed"></i>
  </div>

  <ng-container *ngIf="!collapsed">
    <div class="contabilidade-digital-doc-viewer-recolha-linhas-iva" *ngIf="showLinhasIVA && docOCR?.linhasIVA.length > 0">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-right" [translate]="'arquivodigital.docviewerrecolha.title.linhasIVA.valorBase'"></th>
            <th class="text-right" [translate]="'arquivodigital.docviewerrecolha.title.linhasIVA.valorIVA'" *ngIf="!docOCR?.cab.validadoEFatura"></th>
            <th class="text-center" [translate]="'arquivodigital.docviewerrecolha.title.linhasIVA.tipoTaxa'" *ngIf="!docOCR?.cab.validadoEFatura"></th>
            <th class="text-center" [translate]="'arquivodigital.docviewerrecolha.title.linhasIVA.areaRegional'"></th>
            <th class="text-right" [translate]="'arquivodigital.docviewerrecolha.title.linhasIVA.taxa'" *ngIf="docOCR?.cab.validadoEFatura"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let linhaIVA of docOCR?.linhasIVA">
            <td class="text-right">{{ linhaIVA.valorBase | cgcDecimal }}</td>
            <td class="text-right" *ngIf="!docOCR?.cab.validadoEFatura">{{ linhaIVA.valorIVA | cgcDecimal }}</td>
            <td class="text-center" *ngIf="!docOCR?.cab.validadoEFatura" [translate]="'tiposIVA.data.' + linhaIVA.tipoTaxa"></td>
            <td class="text-center" [translate]="'areasRegionais.data.' + linhaIVA.areaRegional"></td>
            <td class="text-right" *ngIf="docOCR?.cab.validadoEFatura">{{ linhaIVA.taxa | cgcTax }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="contabilidade-digital-doc-viewer-recolha-footer-values">
      <table class="table table-sm table-borderless">
        <tbody>
          <tr>
            <td>
              <span class="contabilidade-digital-doc-viewer-recolha-footer-values-source" [ngSwitch]="attachmentValuesSource">
                <i
                  *ngSwitchCase="valuesSource.OCR"
                  class="fa pl-icon-ocr-doc"
                  aria-hidden="true"
                  [attr.title]="'arquivodigital.docviewerrecolha.text.valuesSource.' + attachmentValuesSource | translate">
                </i>

                <i *ngSwitchCase="valuesSource.QrCode" class="fa fa-qrcode" aria-hidden="true" [attr.title]="'arquivodigital.docviewerrecolha.text.valuesSource.' + attachmentValuesSource | translate">
                </i>

                <img
                  *ngSwitchCase="valuesSource.EFatura"
                  class="img-fluid icon-efatura"
                  src="../../../assets/images/logo-efatura.svg"
                  alt="icon-efatura"
                  [attr.title]="'arquivodigital.docviewerrecolha.text.valuesSource.' + attachmentValuesSource | translate" />
              </span>

              <span class="text-right"> <span [translate]="'arquivodigital.docviewerrecolha.title.totais.baseTributavel'"></span>: </span>
            </td>
            <td class="text-right">{{ docOCR?.cab.totalBase | cgcDecimal }}</td>
          </tr>
          <tr>
            <td class="text-right"><span [translate]="'arquivodigital.docviewerrecolha.title.totais.totalIVA'"></span>:</td>
            <td class="text-right">{{ docOCR?.cab.totalIVA | cgcDecimal }}</td>
          </tr>
          <tr *ngIf="docOCR?.cab.valorRetencao > 0">
            <td class="text-right"><span [translate]="'arquivodigital.docviewerrecolha.title.totais.valorRetencao'"></span>:</td>
            <td class="text-right">{{ docOCR?.cab.valorRetencao | cgcDecimal }}</td>
          </tr>
          <tr>
            <td class="text-right"><span [translate]="'global.text.total'"></span>:</td>
            <td class="text-right">{{ docOCR?.cab.total | cgcDecimal }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
