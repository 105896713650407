import {Component, Injector} from '@angular/core';
import type {IPlEditComponentOptionsInputRadio} from '../radio/edit.radio.component.interface';
import {PlEditInputsGroupComponent} from '../../generic/inputgroup/edit.inputs.group.component';

@Component({
  selector: 'pl-edit-radio-group',
  templateUrl: './edit.radio.group.component.html',
  standalone: false,
  exportAs: 'cgcEditRadioGroup'
})
export class PlEditRadioGroupComponent extends PlEditInputsGroupComponent<any, IPlEditComponentOptionsInputRadio> {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
