import {Injector} from '@angular/core';
import {Transition} from '@uirouter/core';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {IEntityDefinition, IEntityDeleteMessage} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {TranslateService} from '@ngx-translate/core';
import {CargVeicEditComponent} from './component/cargVeic.entity.edit.component';
import {ENTITY_NAME_CARGVEIC, ICargVeicParams} from './cargVeic.entity.interface';
import {IJsonCARGV} from './jsonCargVeic.entity.interface';

export const ENTITY_CARGVEIC: IEntityDefinition<IJsonCARGV> = {
  name: ENTITY_NAME_CARGVEIC,
  roles: [ROLE.ERP],
  searchPlaceholder: 'cargveic.pesquisa',
  metadata: {
    keyName: 'matricula',
    fields: [
      {name: 'matricula', caption: 'cargveic.fields.matricula', validators: {required: true, maxlength: 30}},
      {name: 'nomeViatura', caption: 'cargveic.fields.nomeViatura', validators: {maxlength: 20}},
      {name: 'desativado', caption: 'cargveic.fields.desativado', type: 'boolean'}
    ],
    order: 'matricula',
    searchFields: 'matricula,nomeViatura'
  },
  autocomplete: {
    rowTemplate: '{{matricula}} {{nomeViatura}}',
    output: 'matricula',
    searchFields: 'matricula,nomeViatura'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  new: {
    state: {
      component: CargVeicEditComponent,
      params: {
        matricula: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  serviceInjectable: [
    Injector,
    function (injector: Injector): void {
      this.onInit = (model: IJsonCARGV, type: EEntityStateDetailType) => {
        if (type === EEntityStateDetailType.NEW) {
          const transition: Transition = injector.get(Transition, null, {optional: true});
          if (transition) {
            const params: ICargVeicParams = <ICargVeicParams>transition.params();
            model.matricula = params.matricula;
          }
        }
      };
    }
  ],
  detail: {
    state: {
      component: CargVeicEditComponent
    }
  },
  edit: {
    state: {
      component: CargVeicEditComponent
    }
  },
  getDeleteMessage(model: IJsonCARGV, translate: TranslateService): IEntityDeleteMessage {
    return {
      title: translate.instant('cargveic.deleteModalText', {nome: model.matricula}),
      message: translate.instant('entity.delete.message')
    };
  }
};
