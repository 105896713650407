import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAquisicaoContabResAddDoc, IJsonAquisicaoContasEAtivo, IJsonAquisicaoPesquisaContab} from './jsonAtivosAquisicaoContab.module.interface';
import {IAquisicaoAdicionaDocumento, IContabAdicionaDocEListaAtivos} from './ativosAquisicaoContab.module.interface';
import {IJsonAtivos} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosAquisicaoContabService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosaquisicaocontab`;
  }

  public adicionarDocumento(adicionaDoc: IAquisicaoAdicionaDocumento): TServiceResponse<IJsonAquisicaoContabResAddDoc> {
    return this._apiService.post<IJsonAquisicaoContabResAddDoc, IAquisicaoAdicionaDocumento>({
      url: this._path,
      body: adicionaDoc
    });
  }

  public atualizaListaContasAquisicao(extPocCabID: string, listas: IJsonAquisicaoContasEAtivo): TServiceResponse<IJsonAquisicaoContabResAddDoc> {
    return this._apiService.post<IJsonAquisicaoContabResAddDoc, IJsonAquisicaoContasEAtivo>({
      url: `${this._path}/updatelistacontas`,
      body: listas,
      params: {
        extpoccabid: extPocCabID
      }
    });
  }

  public postBeforeNovoAtivo(aquisicaoContabAdicionaDoc: IContabAdicionaDocEListaAtivos): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IContabAdicionaDocEListaAtivos>({
      url: `${this._path}/pesquisacontab/beforenovoativo`,
      body: aquisicaoContabAdicionaDoc
    });
  }

  public getPesquisaAquisicaoContab(): TServiceResponse<Array<IJsonAquisicaoPesquisaContab>> {
    return this._apiService.get<Array<IJsonAquisicaoPesquisaContab>>({
      url: `${this._path}/pesquisacontab`
    });
  }

  public postAdicionarListaAtivos(ativo: IJsonAtivos, extPocCabID: string): TServiceResponse<Array<IJsonAtivos>> {
    return this._apiService.post<Array<IJsonAtivos>, IJsonAtivos>({
      url: `${this._path}/listaativos`,
      body: ativo,
      params: {extPocCabID: extPocCabID}
    });
  }

  public postAtualizaValorNovosAtivosNasContas(listas: IJsonAquisicaoContasEAtivo): TServiceResponse<IJsonAquisicaoContasEAtivo> {
    return this._apiService.post<IJsonAquisicaoContasEAtivo>({
      url: `${this._path}/atualizacontas`,
      body: listas
    });
  }

  public postSaveAtivosAquisicao(valorComIVA: number, valorAquisicoes: number, listas: IJsonAquisicaoContasEAtivo): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/saveativosaquisicao`,
      body: listas,
      params: {valorComIVA: valorComIVA, valorAquisicoes: valorAquisicoes}
    });
  }
}
