<div class="generic-importer-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'components.genericImporter.title'" [translateParams]="{type: textType}"></h4>
  </div>

  <div class="modal-body">
    <generic-importer [type]="type" (evtSuccess)="close()"></generic-importer>
  </div>
</div>
