import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import dxCheckbox, {InitializedEvent, ValueChangedEvent} from 'devextreme/ui/check_box';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {EDateMonth, IPlEditComponentOptionsInput, IPlTableDefinition, IPlToolbarItem, IPlToolbarMenuItem, isNumber, isObject, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {minDateCG} from '../../../../../common/utils/utils';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX} from '../../../../components/devexpress/widget/devexpress.selectors.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IAnosCalculo, IAtivosDepreciacaoHeader} from '../ativosDepreciacao.module.interface';
import {AtivosDepreciacaoService} from '../ativosDepreciacao.module.service';
import {IJsonAnosCalculo, IJsonAtivosDepreciacao, IJsonAtivosDeprecicaoLinha} from '../jsonAtivosDepreciacao.module.interface';
import {AtivosDepreciacaoDatasPosteModalComponent} from '../modal/datasposte/ativosDepreciacao.datasPoste.modal.component';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import moment, {Moment} from 'moment';

enum EAtivosDepreciacaoTabId {
  PorDepreciar = 'ativosPorDepreciarNoAno',
  Depreciados = 'ativosDepreciadosNoAno',
  DepreciadosAnoAnterior = 'ativosDepreciadosEmAnoAnterior'
}

enum EATEstadoSTR {
  Normal = 'Normal',
  Abatido = 'Abatido',
  Vendido = 'Vendido'
}

const CURRENT_DATE: Moment = moment();

@Component({
  selector: 'module-ativos-depreciacao',
  templateUrl: './ativosDepreciacao.module.component.html'
})
export class AtivosDepreciacaoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public anosCalculo: Array<IJsonAnosCalculo>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly tabsIds: typeof EAtivosDepreciacaoTabId;
  public readonly definition: IPlTableDefinition;
  public readonly metodoSource: Array<IAnosCalculo>;
  public readonly anoDeCalculoTemplate: string;
  public readonly propertiesInput: IPlEditComponentOptionsInput<void>;

  public model: IJsonAtivosDepreciacao;
  public header: IAtivosDepreciacaoHeader;
  public activeTab: EAtivosDepreciacaoTabId;
  public mesCalculo: EDateMonth;
  public selectedKeys: Array<number>;

  private _dataGridInstance: dxDataGrid;
  private _doSelectPorDepreciar: boolean;
  private _selectAllCheckBox: dxCheckbox;
  private _checkBoxUpdating: boolean;
  private _cardPanel: CGCardPanelComponent;
  private _mesEmCurso: number;
  private _meses: Array<IPlToolbarMenuItem>;
  private _mnuMes: IPlToolbarItem;
  private _selectedMes: IPlToolbarMenuItem;
  private _btnRegistaDeps: IPlToolbarItem;
  private _btnAnularDeps: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosDepreciacaoService: AtivosDepreciacaoService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._mesEmCurso = CURRENT_DATE.month();
    this._meses = [];
    this.search = this.search.bind(this);
    this._checkBoxUpdating = false;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [],
      dataSource: [],
      export: {filename: 'global.menu.ativosdepreciacao'},
      height: '60vh',
      headerFilter: {visible: true},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      keyExpr: 'atCodAtivo',
      showBorders: true,
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'valorAnualJaRegistado', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'contabValorAnual', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'fiscalValorAnualJaRegistado', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'fiscalValorAnual', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.propertiesInput = {modelOptions: {debounce: 500}};
    this._doSelectPorDepreciar = true;
    this.anoDeCalculoTemplate =
      `<b>${<string>this._translateService.instant('global.text.year')}:</b> {{ano}} <b>| ${<string>this._translateService.instant('global.text.fromDate')}:</b>` +
      `{{dataDeFormated}} <b>${<string>this._translateService.instant('global.text.toDate')}:</b> {{dataAteFormated}}`;
    this.activeTab = EAtivosDepreciacaoTabId.PorDepreciar;
    this.tabsIds = EAtivosDepreciacaoTabId;
    this.mesCalculo = EDateMonth.December;
    this.metodoSource = [];
    this.selectedKeys = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        porDepreciar: [],
        depreciados: [],
        depreciadosAnoAnterior: [],
        comDataUtilEmAnosPost: []
      };
    }
    if (!this.header && this.anosCalculo) {
      this.header = {
        anoDeCalculo: this.anosCalculo[0].ano,
        codAtivoDe: 1,
        codAtivoAte: 2147483647,
        dataAquisicaoDe: minDateCG(),
        dataAquisicaoAte: this.anosCalculo[0].dataAte,
        pesquisaAnosAnteriores: false
      };
    }
    this._setSourceAnosCalculo();
    this._initDxDataGrid();

    this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EDateMonth>) => {
      const month: number = value - 1;
      this.mesCalculo = value - 1;
      const itemMes: IPlToolbarMenuItem = {
        id: String(month),
        caption: this._translateService.instant(name),
        active: month === this._mesEmCurso,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedMes(menuItem, value);
        }
      };
      if (itemMes.active) {
        this._selectedMes = itemMes;
      }
      return itemMes;
    });
    this._mnuMes = {order: 1, id: 'ativosDepreciacaoListMnuMes', caption: this._getCaptionMes(), type: 'dropdown', menu: this._meses};
    this._btnRegistaDeps = {
      id: 'registadeps',
      order: 2,
      caption: 'ativosdepreciacao.btn.calcular',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-calculator"></i>',
      class: 'btn btn-sm btn-primary',
      tooltip: {
        text: 'ativosdepreciacao.tooltip.selecionarumadep',
        placement: ['bottom', 'bottom-left']
      },
      visible: this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar,
      disabled: !this.selectedKeys.length,
      click: () => {
        this.fnPutAtivosDepreciacao();
      }
    };

    this._btnAnularDeps = {
      id: 'anulardeps',
      order: 3,
      caption: 'ativosdepreciacao.btn.anulaCalculo',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-times"></i>',
      class: 'btn btn-sm btn-primary',
      tooltip: {
        text: 'ativosdepreciacao.tooltip.selecionarumadep',
        placement: ['bottom', 'bottom-left']
      },
      visible: this.activeTab === EAtivosDepreciacaoTabId.Depreciados,
      disabled: !this.selectedKeys.length,
      click: () => {
        this.fnDeleteAtivosDepreciacao();
      }
    };
    this.toolbar.addButton(this._mnuMes);
    this.toolbar.addButton(this._btnRegistaDeps);
    this.toolbar.addButton(this._btnAnularDeps);
  }

  public anoDeCalculoChanged(value: {inputValue: string; item: IAnosCalculo}): void {
    if (isObject(value.item) && value.item.ano !== this.header.anoDeCalculo) {
      this.header.anoDeCalculo = value.item.ano;
      this.header.dataAquisicaoDe = minDateCG();
      this.header.dataAquisicaoAte = value.item.dataAte;
      this._getAtivosDepreciacao();
    }
  }

  public changedTab(activeId: string | number): void {
    this.activeTab = <EAtivosDepreciacaoTabId>activeId;
    this.selectedKeys = [];
    this.checkBtnsStates();
    this._initDxDataGrid();
  }

  public pesquisaAnosAnterioresChanged(value: boolean): void {
    this.header.pesquisaAnosAnteriores = value;
    if (!this.header.pesquisaAnosAnteriores && this.activeTab === EAtivosDepreciacaoTabId.DepreciadosAnoAnterior) {
      if (this.model.porDepreciar.length < 1 && this.model.depreciados.length > 0) {
        this.activeTab = EAtivosDepreciacaoTabId.Depreciados;
      } else {
        this.activeTab = EAtivosDepreciacaoTabId.PorDepreciar;
      }
    }
  }

  public contabPercReducaoChanged(linha: IJsonAtivosDeprecicaoLinha, value: number): Promise<void> {
    linha.contabPercReducao = isNumber(value) ? value : 0;
    linha.fiscalPercReducao = linha.contabPercReducao;
    this._dataGridInstance.beginCustomLoading(undefined);
    this._doSelectPorDepreciar = false;
    return this._ativosDepreciacaoService
      .postContabPercReducaoCalc(this.header, linha)
      .then((response: HttpResponse<IJsonAtivosDeprecicaoLinha>) => {
        linha = response.body;
        const listDep: Array<IJsonAtivosDeprecicaoLinha> = this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar ? this.model.porDepreciar : this.model.depreciados;
        const index: number = listDep.findIndex((item: IJsonAtivosDeprecicaoLinha) => item.atCodAtivo === response.body.atCodAtivo);
        if (index !== -1) {
          listDep[index] = response.body;
        }
        return this._dataGridInstance.refresh(true);
      })
      .finally(() => {
        this._doSelectPorDepreciar = true;
      });
  }

  public openAtivosDataPosterior(): void {
    const modalInstance = this._cgModalService.showVanilla(AtivosDepreciacaoDatasPosteModalComponent);
    const componentInstance: AtivosDepreciacaoDatasPosteModalComponent = modalInstance.componentInstance;
    componentInstance.ativosDataUtilEmAnosPost = this.model.comDataUtilEmAnosPost;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    if (this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar && this._doSelectPorDepreciar) {
      this.selectedKeys = this.model.porDepreciar.filter((value: IJsonAtivosDeprecicaoLinha) => value.atSel).map((value: IJsonAtivosDeprecicaoLinha) => value.atCodAtivo);
    }
    this._dataGridInstance.endCustomLoading();
    this.checkBtnsStates();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAtivosDeprecicaoLinha, number>): void {
    if (event.rowType === 'data' && event.data.parcialmenteDepreciado) {
      event.cellElement.classList.add('parcialmenteColor');
    }

    if (this.activeTab === EAtivosDepreciacaoTabId.Depreciados && event.rowType === 'data' && event.column.type === 'selection' && event.data.atEstadoSTR !== EATEstadoSTR.Normal) {
      if (event.column.command === 'select') {
        const elementSelectCheckbox: HTMLElement = event.cellElement.querySelector(SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX);
        if (elementSelectCheckbox) {
          const checkbox: dxCheckbox = <dxCheckbox>dxCheckbox.getInstance(elementSelectCheckbox);
          if (checkbox) {
            checkbox.option('disabled', true);
          }
        }
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IJsonAtivosDeprecicaoLinha, number>): void {
    if (this.activeTab === EAtivosDepreciacaoTabId.Depreciados) {
      if (event.type !== 'selection') {
        return;
      }

      if (event.parentType === 'headerRow') {
        const dataGrid = event.component;
        event.editorOptions.value = this._isSelectAll();
        event.editorOptions.onInitialized = (e: InitializedEvent) => {
          if (e.component) {
            this._selectAllCheckBox = e.component;
          }
        };
        event.editorOptions.onValueChanged = (e: ValueChangedEvent) => {
          if (!e.event) {
            if (e.previousValue && !this._checkBoxUpdating) {
              e.component.option('value', e.previousValue);
            }
            return;
          }

          if (this._isSelectAll() === e.value) {
            return;
          }

          if (e.value) {
            dataGrid.selectAll();
          } else {
            dataGrid.deselectAll();
          }

          e.event.preventDefault();
        };
      }
    }
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonAtivosDeprecicaoLinha, number>): void {
    if (this.activeTab === EAtivosDepreciacaoTabId.Depreciados) {
      // const deselectKeys: Array<number> = event.selectedRowsData
      //   .filter((item: IJsonAtivosDeprecicaoLinha) => item.atEstadoSTR !== EATEstadoSTR.Normal)
      //   .map((item: IJsonAtivosDeprecicaoLinha) => item.atCodAtivo);
      //
      // event.component.deselectRows(deselectKeys);

      const deselectRowKeys: Array<number> = event.selectedRowsData
        .filter((item: IJsonAtivosDeprecicaoLinha) => item.atEstadoSTR !== EATEstadoSTR.Normal)
        .map((item: IJsonAtivosDeprecicaoLinha) => item.atCodAtivo);

      if (deselectRowKeys.length) {
        event.component.deselectRows(deselectRowKeys);
      }

      this._checkBoxUpdating = true;
      this._selectAllCheckBox.option('value', this._isSelectAll());
      this._checkBoxUpdating = false;
    }
    this.checkBtnsStates();
  }

  public checkBtnsStates(): void {
    if (this.selectedKeys.length > 0) {
      this._btnAnularDeps.disabled = this._btnRegistaDeps.disabled = false;
      this._btnRegistaDeps.tooltip.disabled = this._btnAnularDeps.tooltip.disabled = true;
    } else {
      this._btnRegistaDeps.tooltip.disabled = this._btnAnularDeps.tooltip.disabled = false;
      this._btnAnularDeps.disabled = this._btnRegistaDeps.disabled = true;
    }

    if (this.activeTab === EAtivosDepreciacaoTabId.Depreciados) {
      this._mnuMes.visible = this._btnRegistaDeps.visible = false;
      this._btnAnularDeps.visible = true;
    } else if (this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar) {
      this._mnuMes.visible = this._btnRegistaDeps.visible = true;
      this._btnAnularDeps.visible = false;
    } else {
      this._mnuMes.visible = this._btnRegistaDeps.visible = this._btnAnularDeps.visible = false;
    }
  }

  public readonly fnPutAtivosDepreciacao: () => Promise<void> = () => this._putAtivosDepreciacao();

  public readonly fnDeleteAtivosDepreciacao: () => Promise<void> = () => this._deleteAtivosDepreciacao();

  public async search(): Promise<void> {
    await this._getAtivosDepreciacao();
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private async _getAtivosDepreciacao(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<IJsonAtivosDepreciacao> = await this._ativosDepreciacaoService.ativosConfiguracoesGet(this.header);
      if (response.body?.depreciados?.length || response.body?.porDepreciar?.length || response.body?.comDataUtilEmAnosPost?.length || response.body?.depreciadosAnoAnterior?.length) {
        this._applyModel(response.body);
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
        this._dataGridInstance.endCustomLoading();
      }
    } catch (e) {
      this._plAlertService.error(e);
    }
  }

  private _putAtivosDepreciacao(): Promise<void> {
    let percentAbove = false;
    let promise: Promise<void>;
    const porDepreciarList: Array<IJsonAtivosDeprecicaoLinha> = this._dataGridInstance.getDataSource().items();

    for (const linha of porDepreciarList) {
      linha.atSel = false;
    }
    for (const selectedKey of this.selectedKeys) {
      porDepreciarList.find((value: IJsonAtivosDeprecicaoLinha) => value.atCodAtivo === selectedKey).atSel = true;
    }

    for (const ativosDeprecicaoLinha of porDepreciarList) {
      if (ativosDeprecicaoLinha.atSel && (ativosDeprecicaoLinha.contabPercReducao > 100 || ativosDeprecicaoLinha.fiscalPercReducao > 100)) {
        percentAbove = true;
        break;
      }
    }

    if (percentAbove) {
      promise = Promise.resolve(this._cgModalService.showOkCancel('ativosdepreciacao.title.titlePercentAbove', 'ativosdepreciacao.message.percentAbove'));
    }
    return Promise.resolve(promise).then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      this.model.porDepreciar = porDepreciarList;
      return this._ativosDepreciacaoService
        .ativosConfiguracoesPut(this.header, this.model, this.mesCalculo)
        .then((response: HttpResponse<IJsonAtivosDepreciacao>) => {
          this._applyModel(response.body);
          this._plAlertService.success('ativosdepreciacao.message.calculado');
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    });
  }

  private _deleteAtivosDepreciacao(): Promise<void> {
    const depreciadosList: Array<IJsonAtivosDeprecicaoLinha> = this._dataGridInstance.getDataSource().items();

    for (const item of depreciadosList) {
      item.atSel = false;
      const index: number = this.selectedKeys.findIndex((key: number) => item.atCodAtivo === key);
      if (index !== -1 && item.atEstadoSTR === EATEstadoSTR.Normal) {
        item.atSel = true;
      }
    }

    this.model.depreciados = depreciadosList;
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._ativosDepreciacaoService
      .ativosConfiguracoesDelete(this.header, this.model)
      .then((response: HttpResponse<IJsonAtivosDepreciacao>) => {
        this._applyModel(response.body);
        this._plAlertService.success('ativosdepreciacao.message.calculoAnulado');
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  private _setSourceAnosCalculo(): void {
    if (this.anosCalculo) {
      for (const source of this.anosCalculo) {
        this.metodoSource.push({
          ano: source.ano,
          dataDe: source.dataDe,
          dataAte: source.dataAte,
          dataDeFormated: this._plI18nService.formatDate(source.dataDe),
          dataAteFormated: this._plI18nService.formatDate(source.dataAte)
        });
      }
    }
  }

  private _applyModel(value: IJsonAtivosDepreciacao = this.model): void {
    if (value !== this.model) {
      this.model = value;
      if (this.model.porDepreciar.length < 1 && this.model.depreciados.length > 0) {
        this.activeTab = EAtivosDepreciacaoTabId.Depreciados;
        this._plAlertService.info('ativosdepreciacao.message.jaforamdepreciadostodosano');
      } else {
        this.activeTab = EAtivosDepreciacaoTabId.PorDepreciar;
      }
      this.checkBtnsStates();

      if (this.model.porDepreciar.length > 1) {
        this._plAlertService.info('ativosdepreciacao.message.hasujeitosadepreciacaoano');
      } else if (this.model.porDepreciar.length < 1 && this.model.depreciados.length < 1) {
        this._plAlertService.info('ativosdepreciacao.message.naohanenhumsujeitodepreciacaoano');
      }
      this._initDxDataGrid();
    }
  }

  private _initDxDataGrid(): void {
    this.dataGridDefinition.columns = [
      {
        caption: 'global.text.active',
        alignment: 'center',
        columns: [
          {dataField: 'atCodAtivo', caption: 'ativosdepreciacao.table.subtitle.atCodAtivo', dataType: 'number', allowEditing: false},
          {dataField: 'atDesignacao', caption: 'ativosdepreciacao.table.subtitle.atDesignacao', dataType: 'string', width: 120, allowEditing: false},
          {dataField: 'atDataAquisicao', caption: 'ativosdepreciacao.table.subtitle.atDataAquisicao', dataType: 'date', allowEditing: false},
          {dataField: 'atEstadoSTR', caption: 'ativosdepreciacao.table.subtitle.atEstadoSTR', dataType: 'string', allowEditing: false},
          {dataField: 'atDataAbate', caption: 'ativosdepreciacao.table.subtitle.atDataAbate', dataType: 'string', allowEditing: false},
          {dataField: 'atRefExterna', caption: 'ativosdepreciacao.table.subtitle.atRefExterna', dataType: 'string', allowEditing: false}
        ]
      },
      {
        caption: 'ativosdepreciacao.table.title.ultimaDepreciacao',
        alignment: 'center',
        columns: [
          {dataField: 'ultAno', caption: 'global.text.year', dataType: 'number', allowEditing: false},
          {dataField: 'ultMes', caption: 'global.text.month', dataType: 'number', allowEditing: false},
          {dataField: 'ultPeriodo', caption: 'ativosdepreciacao.table.subtitle.ultPeriodo', dataType: 'string', allowEditing: false}
        ]
      },
      {
        caption: 'ativosdepreciacao.table.title.depreciacaoContabilisticos',
        alignment: 'center',
        columns: [
          {
            dataField: 'contabValorAquisicaoComResidual',
            caption: 'ativosdepreciacao.table.subtitle.valorAquisicaoComResidual',
            dataType: 'double',
            allowEditing: false
          },
          {dataField: 'contabTaxaAmortiza', caption: 'ativosdepreciacao.table.subtitle.taxaAmortiza', dataType: 'double', allowEditing: false},
          {
            dataField: 'contabPercReducao',
            caption: 'ativosdepreciacao.table.subtitle.percReducao',
            dataType: 'double',
            allowEditing: this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar,
            editCellTemplate: this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar ? 'editCellTemplateContabPercReducao' : undefined,
            showEditorAlways: this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar
          },
          {dataField: 'contabValorAnualJaRegistado', caption: 'ativosdepreciacao.table.subtitle.valorAnualJaRegistado', dataType: 'double', allowEditing: false},
          {dataField: 'contabValorAnual', caption: 'ativosdepreciacao.table.subtitle.valorAnual', dataType: 'double', allowEditing: false}
        ]
      },
      {
        caption: 'ativosdepreciacao.table.title.depreciacaoFiscal',
        alignment: 'center',
        columns: [
          {
            dataField: 'fiscalValorAquisicaoComResidual',
            caption: 'ativosdepreciacao.table.subtitle.valorAquisicaoComResidual',
            dataType: 'double',
            allowEditing: false
          },
          {dataField: 'fiscalTaxaAmortiza', caption: 'ativosdepreciacao.table.subtitle.taxaAmortiza', dataType: 'double', allowEditing: false},
          {dataField: 'fiscalPercReducao', caption: 'ativosdepreciacao.table.subtitle.percReducao', dataType: 'double', allowEditing: false},
          {dataField: 'fiscalValorAnualJaRegistado', caption: 'ativosdepreciacao.table.subtitle.valorAnualJaRegistado', dataType: 'double', allowEditing: false},
          {dataField: 'fiscalValorAnual', caption: 'ativosdepreciacao.table.subtitle.valorAnual', dataType: 'double', allowEditing: false}
        ]
      }
    ];
    this.dataGridDefinition.editing = {
      allowDeleting: false,
      allowUpdating: this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar,
      mode: 'cell',
      selectTextOnEditStart: true,
      startEditAction: 'click'
    };
    this.dataGridDefinition.selection = {
      mode: this.activeTab === EAtivosDepreciacaoTabId.DepreciadosAnoAnterior ? 'single' : 'multiple',
      showCheckBoxesMode: 'always'
    };
    this.dataGridDefinition.dataSource =
      this.activeTab === EAtivosDepreciacaoTabId.PorDepreciar
        ? this.model.porDepreciar
        : this.activeTab === EAtivosDepreciacaoTabId.Depreciados
          ? this.model.depreciados
          : this.model.depreciadosAnoAnterior;
  }

  private _isSelectAll(): boolean {
    const selectableItems: Array<IJsonAtivosDeprecicaoLinha> = this._dataGridInstance
      .getDataSource()
      .items()
      .filter((item: IJsonAtivosDeprecicaoLinha) => item.atEstadoSTR === EATEstadoSTR.Normal);
    const selectedRowKeys: Array<IJsonAtivosDeprecicaoLinha> = this._dataGridInstance.option('selectedRowKeys');

    if (!selectedRowKeys?.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  }

  private _getCaptionMes(): string {
    const nomeMesEmCurso = this._meses[this._mesEmCurso].caption;
    return this._translateService.instant('ativosdepreciacao.tab.registoMes', {nameMonth: nomeMesEmCurso});
  }

  private _changedMes(menuItem: IPlToolbarMenuItem, value: EDateMonth, refresh: boolean = true): void {
    if (menuItem.id !== String(this._mesEmCurso)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this.mesCalculo = value;
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = Number(this._selectedMes.id);
      this._mnuMes.caption = this._getCaptionMes();
      if (refresh) {
        this._dataGridInstance.refresh();
      }
    }
  }
}
