import {merge, uniqBy} from 'lodash-es';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, isArray, isEmpty, isNumber, isObject} from 'pl-comps-angular';
import {API_PORTAL_PREFIX, generatePortalId} from '../../../../config/constants';
import {AuthService} from '../../../services/auth/auth.service';
import {configErpBooleanToValue} from '../../../../common/data';
import {
  emptyUserInstallation,
  EPoliciesCategory,
  IUserInstallation,
  IUserInstallationAccess,
  IUserInstallationAccessCompany,
  IUserInstallationEventChangedConfigErpValue,
  IUserInstallationEventChangedConfigsErpValue,
  IUserInstallationEventChangedFilterValue,
  IUserInstallationEventChangedSelectAllCompanies,
  IUserInstallationEventChangedSelectCompany,
  IUserInstallationPolicy,
  IUserInstallationPolicyConfigERP
} from './utilizadores.installation.interface';
import {EPortal} from '../../../../common/enums/portals.enums';
import {evaluatePortalTitle} from '../../../entities/portal/portal.entity.interface';
import {ICGEnumeratorMap} from '../../../../common/interfaces/interfaces';
import {IJsonConfigERP, IJsonConfigERPUserEmpresa} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonErp, IJsonRole} from '../../../interfaces/jsonUserManager.interface';
import {IJsonErpUser, IJsonUserNewWithAccesses, IJsonUserRole} from '../../../services/account/jsonUserApi.interface';
import {IJsonPortal} from '../../../entities/portal/jsonPortal.entity.interface';
import {IUserInstallationsCallback, IUserInstallationsData, USER_INSTALLATIONS_COMPANY_GLOBAL, USER_INSTALLATIONS_PORTAL_GLOBAL} from './utilizadores.installations.interface';
import {ROLE} from '../../../services/role.const';
import {UtilizadoresInstallationsService} from './utilizadores.installations.service';

@Component({
  selector: 'user-installations',
  templateUrl: './utilizadores.installations.component.html'
})
export class UtilizadoresInstallationsComponent implements OnInit, OnChanges {
  @Input() public installation: number;
  @Input() public userWithAccesses: IJsonUserNewWithAccesses;
  @Input() public erps: Array<IJsonErp>;
  @Input() public new: boolean;
  @Input() public disabled: boolean;
  @Input() public applyToModelOnChanged: boolean;
  @Input() public callback: IUserInstallationsCallback;

  public readonly installations: ICGEnumeratorMap<number, IUserInstallation>;
  public promise: Promise<void>;

  private readonly _portalsRolesMap: Map<EPortal, string>;
  private _installationData: IUserInstallationsData;
  private _installations: Array<IJsonErp>;
  private _roles: Array<IJsonRole>;
  private _portals: Array<IJsonPortal>;
  private _configsErp: Array<IUserInstallationPolicyConfigERP>;
  private _rolePortalAtivos: ROLE;
  private _rolePortalComercial: ROLE;
  private _rolePortalContabilidade: ROLE;

  constructor(
    private readonly _authService: AuthService,
    private readonly _utilizadoresInstallationsService: UtilizadoresInstallationsService,
    private readonly _translateService: TranslateService
  ) {
    this.installations = {
      keys: [],
      values: new Map<number, IUserInstallation>()
    };
    this.new = false;
    this.disabled = false;
    this.applyToModelOnChanged = false;
    this._portalsRolesMap = new Map<EPortal, string>();
  }

  public ngOnInit(): void {
    this.promise = Promise.all([
      this._utilizadoresInstallationsService
        .getInstallationData({
          loadInstallations: !isArray(this.erps)
        })
        .then((installationData: IUserInstallationsData) => {
          this._installationData = installationData;
          this._portalsRolesMap.clear();
          for (const portal of this._installationData.portals) {
            const role: string = API_PORTAL_PREFIX + portal.id;
            this._portalsRolesMap.set(portal.url, role);
          }
        }),
      this._authService.getAndGeneratePortalRole(EPortal.ATIVOS).then((role: ROLE) => {
        this._rolePortalAtivos = role;
      }),
      this._authService.getAndGeneratePortalRole(EPortal.CONTABILIDADE).then((role: ROLE) => {
        this._rolePortalContabilidade = role;
      }),
      this._authService.getAndGeneratePortalRole(EPortal.ERP).then((role: ROLE) => {
        this._rolePortalComercial = role;
      })
    ])
      .then(() => {
        this._initInstallationData();
        this._changedInstallation();
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  public ngOnChanges({installation, userWithAccesses, callback}: SimpleChanges): void {
    if (installation && !installation.isFirstChange()) {
      this._changedInstallation();
    } else if (userWithAccesses && !userWithAccesses.isFirstChange()) {
      this._changedUser();
    }
    if (callback) {
      const cb: IUserInstallationsCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.fromModelToInstallations = () => {
          this._changedUser();
        };
        cb.fromInstallationsToModel = () => {
          this._changedInstallations();
        };
        cb.reset = () => {
          this._reset();
        };
      }
    }
  }

  public changedFilterValue({installation}: IUserInstallationEventChangedFilterValue): void {
    this._evaluateAccesses(installation);
  }

  public changedSelectAllCompanies({installation, value, access}: IUserInstallationEventChangedSelectAllCompanies): void {
    if (access.portal !== USER_INSTALLATIONS_PORTAL_GLOBAL) {
      for (const key of access.filteredKeys) {
        const accessCompany: IUserInstallationAccessCompany = access.values.get(key);
        this.changedSelectCompany({
          installation: installation,
          value: value,
          access: access,
          company: accessCompany,
          evaluate: false
        });
      }
      this._evaluateAccesses(installation);
    } else {
      this.changedSelectAllCompaniesGlobal(installation, value, access);
    }
    if (this.applyToModelOnChanged) {
      this._changedInstallations();
    }
  }

  public changedSelectCompany({installation, value, company, access, evaluate}: IUserInstallationEventChangedSelectCompany): void {
    if (access.portal !== USER_INSTALLATIONS_PORTAL_GLOBAL) {
      company.selected = value;

      if (company.selected && access.portalIncludes.length) {
        for (const portal of access.portalIncludes) {
          const portalRole: string = generatePortalId(portal.id);
          const accessPortal: IUserInstallationAccess = installation.accesses.values.get(portalRole);
          if (accessPortal) {
            const accessCompany: IUserInstallationAccessCompany = accessPortal.values.get(company.nempresa);
            if (accessCompany) {
              accessCompany.selected = true;
            }
          }
        }
      }
    } else {
      this.changedSelectCompanyGlobal(installation, value, company, true);
    }
    if (evaluate) {
      this._evaluateAccesses(installation);
      if (this.applyToModelOnChanged) {
        this._changedInstallations();
      }
    }
  }

  public changedSelectAllCompaniesGlobal(installation: IUserInstallation, value: boolean, access: IUserInstallationAccess): void {
    for (const keyCompany of access.filteredKeys) {
      const globalAccessCompany: IUserInstallationAccessCompany = access.values.get(keyCompany);
      this.changedSelectCompanyGlobal(installation, value, globalAccessCompany, false);
    }
    this._evaluateAccesses(installation);
  }

  public changedSelectCompanyGlobal(installation: IUserInstallation, value: boolean, company: IUserInstallationAccessCompany, evaluate: boolean): void {
    for (const keyAccess of installation.accesses.keys) {
      if (keyAccess === USER_INSTALLATIONS_PORTAL_GLOBAL) {
        continue;
      }
      const access: IUserInstallationAccess = installation.accesses.values.get(keyAccess);
      const accessCompany: IUserInstallationAccessCompany = access.values.get(company.nempresa);
      this.changedSelectCompany({installation: installation, value: value, access: access, company: accessCompany, evaluate: evaluate});
    }
    if (evaluate && this.applyToModelOnChanged) {
      this._changedInstallations();
    }
  }

  public changedConfigErpValue({installation, nEmpresa, event}: IUserInstallationEventChangedConfigErpValue): void {
    const globalPolicy: IUserInstallationPolicy = installation.policies.values.get(USER_INSTALLATIONS_COMPANY_GLOBAL);
    for (const keyCompany of installation.policies.keys) {
      if (keyCompany === USER_INSTALLATIONS_COMPANY_GLOBAL) {
        continue;
      }
      const policy: IUserInstallationPolicy = installation.policies.values.get(keyCompany);
      const policyConfigErp: IUserInstallationPolicyConfigERP = policy.configsErpMap.values.get(event.configErp.name);
      if (policyConfigErp) {
        if (nEmpresa === USER_INSTALLATIONS_COMPANY_GLOBAL) {
          policyConfigErp.value = event.configErp.value;
        } else if (!event.newValue) {
          const globalPolicyConfigErp = globalPolicy.configsErpMap.values.get(policyConfigErp.name);
          if (globalPolicyConfigErp) {
            globalPolicyConfigErp.value = configErpBooleanToValue(globalPolicyConfigErp, false);
          }
        }
      }
    }
    this._evaluatePolicies(installation);
    if (this.applyToModelOnChanged) {
      this._changedInstallations();
    }
  }

  public changedConfigsErpValue({installation, nEmpresa}: IUserInstallationEventChangedConfigsErpValue): void {
    const globalPolicy: IUserInstallationPolicy = installation.policies.values.get(USER_INSTALLATIONS_COMPANY_GLOBAL);
    for (const keyCompany of installation.policies.keys) {
      if (keyCompany === USER_INSTALLATIONS_COMPANY_GLOBAL) {
        continue;
      }
      const policy: IUserInstallationPolicy = installation.policies.values.get(keyCompany);
      for (const configErp of policy.configsErp) {
        const globalPolicyConfigErp: IUserInstallationPolicyConfigERP = globalPolicy.configsErpMap.values.get(configErp.name);
        if (globalPolicyConfigErp) {
          if (nEmpresa === USER_INSTALLATIONS_COMPANY_GLOBAL) {
            configErp.value = globalPolicyConfigErp.value;
          } else if (!configErp.value && Boolean(globalPolicyConfigErp.value)) {
            globalPolicyConfigErp.value = configErpBooleanToValue(globalPolicyConfigErp, false);
          }
        }
      }
    }
    this._evaluatePolicies(installation);
    if (this.applyToModelOnChanged) {
      this._changedInstallations();
    }
  }

  public changedPolicies(installation: IUserInstallation): void {
    this._evaluateAccesses(installation);
  }

  private _initInstallationData(): void {
    if (this.installations.keys.length) {
      this.installations.keys = [];
      this.installations.values.clear();
    }

    const installationData: IUserInstallationsData = this._installationData;
    this._installations = isArray(this.erps) ? this.erps : installationData.installations || [];
    this._roles = installationData.roles;
    this._portals = installationData.portals;
    this._configsErp = installationData.configsErp.map((configErp: IJsonConfigERP) => {
      const configValue: unknown = this.new ? configErpBooleanToValue(configErp, true) : configErp.value;
      const policyConfigERP: IUserInstallationPolicyConfigERP = {
        ...configErp,
        value: configValue,
        originalValue: configValue,
        indeterminate: false
      };
      return policyConfigERP;
    });

    for (const erp of this._installations) {
      let installation: IUserInstallation = this.installations.values.get(erp.centralGestId);
      if (!installation) {
        installation = {
          ...emptyUserInstallation(),
          centralGestId: erp.centralGestId,
          centralGestName: erp.name,
          centralGestCaption: erp.name,
          userId: this.userWithAccesses.userId,
          policiesNeedLoading: !this.new
        };
        this.installations.values.set(erp.centralGestId, installation);
        this.installations.keys.push(erp.centralGestId);

        const accessAll: IUserInstallationAccess = {
          portal: USER_INSTALLATIONS_PORTAL_GLOBAL,
          portalIncludes: [],
          name: 'usersbasic.text.allRoles',
          values: new Map<string, IUserInstallationAccessCompany>(),
          keys: [],
          filteredKeys: [],
          selectedAll: false,
          indeterminate: false
        };
        installation.accesses.values.set(USER_INSTALLATIONS_PORTAL_GLOBAL, accessAll);
        installation.accesses.keys.push(USER_INSTALLATIONS_PORTAL_GLOBAL);

        const globalPolicy: IUserInstallationPolicy = {
          centralGestId: undefined,
          centralGestName: undefined,
          nEmpresa: USER_INSTALLATIONS_COMPANY_GLOBAL,
          name: 'usersbasic.text.allPolicies',
          visible: true,
          codEmp: undefined,
          nomeEmpregado: undefined,
          groupsVisibility: new Map<EPoliciesCategory, boolean>([
            [EPoliciesCategory.Comercial, false],
            [EPoliciesCategory.Contabilidade, false],
            [EPoliciesCategory.Ativos, false],
            [EPoliciesCategory.Addons, true]
          ]),
          configsErp: copy(this._configsErp),
          configsErpMap: {
            values: new Map<string, IUserInstallationPolicyConfigERP>(),
            keys: []
          },
          loadingAccessesErrored: false
        };
        for (const configErp of globalPolicy.configsErp) {
          configErp.value = configErpBooleanToValue(configErp, true);
          globalPolicy.configsErpMap.values.set(configErp.name, configErp);
        }
        globalPolicy.configsErpMap.keys = Array.from<string>(globalPolicy.configsErpMap.values.keys());
        installation.policies.values.set(USER_INSTALLATIONS_COMPANY_GLOBAL, globalPolicy);
        installation.policies.keys.push(USER_INSTALLATIONS_COMPANY_GLOBAL);
      }
    }
  }

  private _changedInstallation(): void {
    Promise.resolve(this.promise).then(async () => {
      if (!this.installation) {
        return;
      }

      const installation: IUserInstallation = this.installations.values.get(this.installation);
      if (!installation) {
        return;
      }
      if (installation.empresas.length) {
        this._evaluateAccesses(installation);
        return;
      }

      installation.empresasLoading = true;
      try {
        installation.empresas = await this._utilizadoresInstallationsService.getInstallationEmpresas({
          centralGestId: installation.centralGestId,
          name: installation.centralGestName
        });
      } finally {
        installation.empresasLoading = false;
      }

      for (const empresa of installation.empresas) {
        const accessAll: IUserInstallationAccess = installation.accesses.values.get(USER_INSTALLATIONS_PORTAL_GLOBAL);

        const defaultAccess: IUserInstallationAccessCompany = {
          nempresa: undefined,
          nome: undefined,
          selected: false,
          indeterminate: false,
          ativo: true,
          centralGestId: undefined,
          centralGestName: undefined,
          nUtilizador: undefined,
          nomeUtilizador: undefined,
          codEmpregado: undefined,
          nomeEmpregado: undefined
        };

        accessAll.values.set(empresa.nempresa, merge({}, defaultAccess, empresa));
        accessAll.keys.push(empresa.nempresa);
        accessAll.filteredKeys.push(empresa.nempresa);

        for (const portal of this._portals) {
          const portalAsRole: string = generatePortalId(portal.id);
          let access: IUserInstallationAccess = installation.accesses.values.get(portalAsRole);
          if (!access) {
            access = {
              portal: portalAsRole,
              portalIncludes: portal.includes?.length ? this._portals.filter((portalItem: IJsonPortal) => portal.includes.includes(portalItem.url)) : [],
              name: evaluatePortalTitle(portal, this._translateService),
              values: new Map<string, IUserInstallationAccessCompany>(),
              keys: [],
              filteredKeys: [],
              selectedAll: false,
              indeterminate: false
            };
            installation.accesses.values.set(portalAsRole, access);
            installation.accesses.keys.push(portalAsRole);
          }
          if (!access.values.has(empresa.nempresa)) {
            access.values.set(empresa.nempresa, merge({}, defaultAccess, empresa));
            access.keys.push(empresa.nempresa);
            access.filteredKeys.push(empresa.nempresa);
          }
        }

        const policy: IUserInstallationPolicy = {
          centralGestId: empresa.centralGestId,
          centralGestName: empresa.centralGestName,
          nEmpresa: empresa.nempresa,
          name: `${empresa.nome} (${empresa.nempresa})`,
          visible: false,
          codEmp: undefined,
          nomeEmpregado: undefined,
          groupsVisibility: new Map<EPoliciesCategory, boolean>([
            [EPoliciesCategory.Comercial, false],
            [EPoliciesCategory.Contabilidade, false],
            [EPoliciesCategory.Ativos, false],
            [EPoliciesCategory.Addons, true]
          ]),
          configsErp: copy(this._configsErp),
          configsErpMap: {
            values: new Map<string, IUserInstallationPolicyConfigERP>(),
            keys: []
          },
          loadingAccessesErrored: false
        };

        installation.policies.values.set(empresa.nempresa, policy);
        installation.policies.keys.push(empresa.nempresa);

        for (const configErp of policy.configsErp) {
          policy.configsErpMap.values.set(configErp.name, configErp);
        }
        policy.configsErpMap.keys = Array.from<string>(policy.configsErpMap.values.keys());
      }

      this.installations.values.set(installation.centralGestId, {...installation});

      this._changedUser();
    });
  }

  private _changedUser(): void {
    Promise.resolve(this.promise).then(() => {
      for (const erp of this.userWithAccesses.erps) {
        const installation: IUserInstallation = this.installations.values.get(erp.centralGestId);
        if (!installation) {
          continue;
        }
        if (!erp.codEmp) {
          erp.codEmp = undefined;
          erp.nomeEmpregado = undefined;
        }

        const accessAll: IUserInstallationAccess = installation.accesses.values.get(USER_INSTALLATIONS_PORTAL_GLOBAL);
        const companiesCount: Map<string, number> = new Map<string, number>();
        let selectedInPortals = 0;
        for (const role of erp.rolesAcess) {
          const portal: ROLE = role.role;
          const accessValue: IUserInstallationAccess = installation.accesses.values.get(portal);
          if (accessValue) {
            const empresaValue: IUserInstallationAccessCompany = accessValue.values.get(erp.nEmpresa);
            if (empresaValue) {
              if (!companiesCount.has(empresaValue.nempresa)) {
                companiesCount.set(empresaValue.nempresa, 0);
              }
              empresaValue.selected = true;
              selectedInPortals++;
              companiesCount.set(empresaValue.nempresa, companiesCount.get(empresaValue.nempresa) + 1);

              const policyValue: IUserInstallationPolicy = installation.policies.values.get(erp.nEmpresa);
              policyValue.codEmp = erp.codEmp;
              policyValue.nomeEmpregado = erp.nomeEmpregado;
            }
          }
        }
        const accessAllCompany: IUserInstallationAccessCompany = accessAll.values.get(erp.nEmpresa);
        if (accessAllCompany) {
          accessAllCompany.selected = selectedInPortals === installation.accesses.keys.length - 1;
        }
      }

      for (const key of this.installations.keys) {
        const installation: IUserInstallation = this.installations.values.get(key);

        // Policies only supports one installation
        for (const politica of this.userWithAccesses.acessosErpCloud) {
          for (const politicaEmpresa of politica.empresas) {
            const policy: IUserInstallationPolicy = installation.policies.values.get(politicaEmpresa.nempresa);
            if (policy) {
              const policyValue: IUserInstallationPolicyConfigERP = policy.configsErpMap.values.get(politica.name);
              if (policyValue) {
                policyValue.value = politicaEmpresa.value;
                policyValue.originalValue = policyValue.value;
              }
            }
          }
        }

        this._evaluateAccesses(installation);
      }
    });
  }

  private _evaluateAccesses(installation: IUserInstallation): void {
    installation.showPolicies = false;
    for (const policy of installation.policies.values.values()) {
      for (const portal of policy.groupsVisibility.keys()) {
        if (portal === EPoliciesCategory.Addons) {
          continue;
        }
        policy.groupsVisibility.set(portal, false);
      }
    }

    const companiesCount: Map<string, number> = new Map<string, number>();
    for (const accessKey of installation.accesses.keys) {
      if (accessKey === USER_INSTALLATIONS_PORTAL_GLOBAL) {
        continue;
      }
      const access: IUserInstallationAccess = installation.accesses.values.get(accessKey);
      let selectedCompanies = 0;
      for (const companyKey of access.filteredKeys) {
        const company: IUserInstallationAccessCompany = access.values.get(companyKey);
        if (!companiesCount.has(company.nempresa)) {
          companiesCount.set(company.nempresa, 0);
        }
        if (company.selected) {
          selectedCompanies++;
          installation.showPolicies = true;
          companiesCount.set(company.nempresa, companiesCount.get(company.nempresa) + 1);
        }
      }
      access.selectedAll = selectedCompanies === access.filteredKeys.length;
      access.indeterminate = !access.selectedAll && selectedCompanies > 0;
    }

    const accessAll: IUserInstallationAccess = installation.accesses.values.get(USER_INSTALLATIONS_PORTAL_GLOBAL);
    let accessAllCount = 0;
    let shouldEvaluatePolicies = false;
    let globalShowAtivos = false;
    let globalShowComercial = false;
    let globalShowContabilidade = false;
    for (const companyKey of accessAll.filteredKeys) {
      const companyCount: number = companiesCount.get(companyKey);
      if (!isNumber(companyCount)) {
        continue;
      }

      const accessAllCompany: IUserInstallationAccessCompany = accessAll.values.get(companyKey);
      accessAllCompany.selected = companyCount === installation.accesses.keys.length - 1;
      accessAllCompany.indeterminate = !accessAllCompany.selected && companyCount > 0;
      if (accessAllCompany.selected || accessAllCompany.indeterminate) {
        accessAllCount++;
      }

      const policyValue: IUserInstallationPolicy = installation.policies.values.get(companyKey);
      const wasVisible = policyValue.visible;
      policyValue.visible = !policyValue.loadingAccessesErrored && companyCount > 0;
      if (policyValue.visible !== wasVisible) {
        shouldEvaluatePolicies = true;
      }
      if (policyValue.visible) {
        const accessAtivos: IUserInstallationAccess = installation.accesses.values.get(this._rolePortalAtivos);
        if (accessAtivos) {
          const accessCompany = accessAtivos.values.get(companyKey);
          if (accessCompany.selected) {
            policyValue.groupsVisibility.set(EPoliciesCategory.Ativos, true);
            globalShowAtivos = true;
          }
        }
        const accessErp: IUserInstallationAccess = installation.accesses.values.get(this._rolePortalComercial);
        if (accessErp) {
          const accessCompany = accessErp.values.get(companyKey);
          if (accessCompany.selected) {
            policyValue.groupsVisibility.set(EPoliciesCategory.Comercial, true);
            globalShowComercial = true;
          }
        }
        const accessContabilidade: IUserInstallationAccess = installation.accesses.values.get(this._rolePortalContabilidade);
        if (accessContabilidade) {
          const accessCompany = accessContabilidade.values.get(companyKey);
          if (accessCompany.selected) {
            policyValue.groupsVisibility.set(EPoliciesCategory.Contabilidade, true);
            globalShowContabilidade = true;
          }
        }
      }
    }
    accessAll.selectedAll = accessAllCount === accessAll.filteredKeys.length;
    accessAll.indeterminate = !accessAll.selectedAll && accessAllCount > 0;

    const policyAll = installation.policies.values.get(USER_INSTALLATIONS_COMPANY_GLOBAL);
    policyAll.groupsVisibility.set(EPoliciesCategory.Ativos, globalShowAtivos);
    policyAll.groupsVisibility.set(EPoliciesCategory.Comercial, globalShowComercial);
    policyAll.groupsVisibility.set(EPoliciesCategory.Contabilidade, globalShowContabilidade);

    if (shouldEvaluatePolicies) {
      this._evaluatePolicies(installation);
    }
  }

  private _evaluatePolicies(installation: IUserInstallation): void {
    const visiblePolicies = installation.policies.keys.filter((policyKey) => {
      if (policyKey === USER_INSTALLATIONS_COMPANY_GLOBAL) {
        return false;
      }
      const policy: IUserInstallationPolicy = installation.policies.values.get(policyKey);
      return policy.visible;
    });
    const configsCount: Map<string, number> = new Map<string, number>();
    for (const policyKey of visiblePolicies) {
      if (policyKey === USER_INSTALLATIONS_COMPANY_GLOBAL) {
        continue;
      }
      const policy: IUserInstallationPolicy = installation.policies.values.get(policyKey);
      for (const configErp of policy.configsErp) {
        if (!configsCount.has(configErp.name)) {
          configsCount.set(configErp.name, 0);
        }
        if (configErp.value) {
          configsCount.set(configErp.name, configsCount.get(configErp.name) + 1);
        }
      }
    }
    const policyAll: IUserInstallationPolicy = installation.policies.values.get(USER_INSTALLATIONS_COMPANY_GLOBAL);
    for (const configKey of Array.from(configsCount.keys())) {
      const configCount = configsCount.get(configKey);
      const configErp: IUserInstallationPolicyConfigERP = policyAll.configsErpMap.values.get(configKey);
      const selectedAll: boolean = configCount === visiblePolicies.length;
      configErp.value = configErpBooleanToValue(configErp, selectedAll);
      configErp.indeterminate = !selectedAll && configCount > 0;
    }
  }

  private _changedInstallations(): void {
    if (!this.installation) {
      return;
    }

    if (this.userWithAccesses.erps) {
      for (const erp of this.userWithAccesses.erps) {
        erp.rolesAcess.length = 0;
        erp.rolesNotAcess.length = 0;
      }
    }

    const installation: IUserInstallation = this.installations.values.get(this.installation);

    // Acessos
    const empresasMap: Map<string, IJsonErpUser> = new Map<string, IJsonErpUser>();
    const rolesMap: Map<string, IJsonRole> = new Map<string, IJsonRole>();
    for (const accessKey of installation.accesses.keys) {
      if (accessKey === USER_INSTALLATIONS_PORTAL_GLOBAL) {
        continue;
      }
      const access: IUserInstallationAccess = installation.accesses.values.get(accessKey);
      if (access) {
        for (const companyKey of access.keys) {
          const company: IUserInstallationAccessCompany = access.values.get(companyKey);
          if (!company?.selected) {
            continue;
          }
          let erp: IJsonErpUser = empresasMap.get(company.nempresa);
          if (!erp) {
            erp = this.userWithAccesses.erps.find((erpUser: IJsonErpUser) => erpUser.centralGestId === company.centralGestId && erpUser.nEmpresa === company.nempresa);
            if (!erp) {
              erp = {
                cgID: undefined,
                centralGestId: company.centralGestId,
                name: company.centralGestName,
                apiUrl: undefined,
                nEmpresa: company.nempresa,
                nUtilizador: undefined,
                ativo: true,
                nomeEmpresa: company.nome,
                nomeUtilizadorCG: undefined,
                codEmp: undefined,
                nomeEmpregado: undefined,
                rolesAcess: [],
                rolesNotAcess: []
              };
            }
            empresasMap.set(company.nempresa, erp);
          }
          let role: IJsonRole = rolesMap.get(access.portal);
          if (!role) {
            role = this._roles.find((roleItem: IJsonRole) => roleItem.role === access.portal);
            rolesMap.set(access.portal, role);
          }
          erp.rolesAcess.push({
            role: role.role,
            description: role.description
          });
          for (const roleDependency of role.depends) {
            let dependency: IJsonRole = rolesMap.get(roleDependency);
            if (!dependency) {
              dependency = this._roles.find((roleItem: IJsonRole) => roleItem.role === roleDependency);
              rolesMap.set(roleDependency, dependency);
            }
            erp.rolesAcess.push({
              role: dependency.role,
              description: dependency.description
            });
          }
        }
      }
    }

    const lastNEmpresa: string = this.userWithAccesses.erp?.nEmpresa;
    this.userWithAccesses.erps = Array.from(empresasMap.keys()).map<IJsonErpUser>((nEmpresa: string) => empresasMap.get(nEmpresa));
    if (this.userWithAccesses.erps.length) {
      this.userWithAccesses.erp = lastNEmpresa ? empresasMap.get(lastNEmpresa) : this.userWithAccesses.erps[0];
    } else {
      this.userWithAccesses.erp = undefined;
    }

    // Acessos das empresas
    this.userWithAccesses.acessosErpCloud = [];
    for (const configErp of this._configsErp) {
      let changedValue = false;
      const empresas: Array<IJsonConfigERPUserEmpresa> = [];
      for (const nEmpresa of installation.policies.keys) {
        if (nEmpresa === USER_INSTALLATIONS_COMPANY_GLOBAL) {
          continue;
        }
        const policy: IUserInstallationPolicy = installation.policies.values.get(nEmpresa);
        if (!policy.visible) {
          continue;
        }
        const erp = this.userWithAccesses.erps.find((erpUser: IJsonErpUser) => erpUser.centralGestId === policy.centralGestId && erpUser.nEmpresa === policy.nEmpresa);
        if (erp) {
          erp.codEmp = policy.codEmp;
          erp.nomeEmpregado = policy.nomeEmpregado;
        }
        const policyConfig: IUserInstallationPolicyConfigERP = policy.configsErpMap.values.get(configErp.name);
        if (!isEmpty(policyConfig.value) && policyConfig.value !== policyConfig.originalValue) {
          changedValue = true;
          policyConfig.originalValue = policyConfig.value;
          empresas.push({
            nempresa: nEmpresa,
            kind: policyConfig.kind,
            value: policyConfig.value
          });
        }
      }
      if (changedValue) {
        this.userWithAccesses.acessosErpCloud.push({
          name: configErp.name,
          description: configErp.description,
          readOnly: configErp.readOnly,
          cguid: configErp.cguid,
          empresas: empresas
        });
      }
    }

    // Clear duplicated roles
    for (const erp of this.userWithAccesses.erps) {
      erp.rolesAcess = uniqBy<IJsonUserRole>(erp.rolesAcess, 'role');
    }
  }

  private _reset(): void {
    this.installations.keys = [];
    this.installations.values.clear();
    this._initInstallationData();
    this._changedInstallation();
  }
}
