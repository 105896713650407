import {Injectable} from '@angular/core';
import {IAutocompleteCacheSetQueryParams} from './autocomplete.cache.interface';
import {isNumber, isObject} from '../../utilities/utilities';

const DEFAULT_KEY_VALUE_STRING = '?';
const DEFAULT_KEY_VALUE_NUMBER = 0;

function generateQueryKey({search, page, perPage, filter}: IAutocompleteCacheSetQueryParams): string {
  if (!search) {
    search = DEFAULT_KEY_VALUE_STRING;
  }
  if (!isNumber(page)) {
    page = DEFAULT_KEY_VALUE_NUMBER;
  }
  if (!isNumber(perPage)) {
    perPage = DEFAULT_KEY_VALUE_NUMBER;
  }
  if (!filter) {
    filter = DEFAULT_KEY_VALUE_STRING;
  }
  return `${search}_${page}_${perPage}_${filter}`;
}

@Injectable({
  providedIn: 'root'
})
export class PlAutocompleteCache {
  private _queryCache: Map<string, Map<string, Array<unknown>>>;
  private _instanceCache: Map<string, Map<unknown, unknown>>;

  public getQuery<T>(instanceId: string, params: IAutocompleteCacheSetQueryParams): Array<T> | undefined {
    if (!isObject(params)) {
      throw new TypeError('Invalid parameteres provided.');
    }
    if (!this._queryCache) {
      return undefined;
    }
    const instance: Map<string, Array<unknown>> = this._queryCache.get(instanceId);
    if (!instance) {
      return undefined;
    }
    const key: string = generateQueryKey(params);
    return <Array<T> | undefined>instance.get(key);
  }

  public setQuery<T>(instanceId: string, params: IAutocompleteCacheSetQueryParams, result: Array<T>): void {
    if (!isObject(params)) {
      throw new TypeError('Invalid parameteres provided.');
    }
    if (!this._queryCache) {
      this._queryCache = new Map<string, Map<string, Array<unknown>>>();
    }
    let instance: Map<string, Array<unknown>> = this._queryCache.get(instanceId);
    if (!instance) {
      instance = new Map<string, Array<unknown>>();
      this._queryCache.set(instanceId, instance);
    }
    const key: string = generateQueryKey(params);
    instance.set(key, result);
  }

  public deleteQueryKey(instanceId: string, params: IAutocompleteCacheSetQueryParams): void {
    if (!isObject(params)) {
      throw new TypeError('Invalid parameteres provided.');
    }
    if (!this._queryCache) {
      return;
    }
    const instance: Map<string, Array<unknown>> = this._queryCache.get(instanceId);
    if (!instance) {
      return;
    }
    const key: string = generateQueryKey(params);
    instance.delete(key);
  }

  public deleteQuery(instanceId: string): void {
    if (!this._queryCache) {
      return;
    }
    this._queryCache.delete(instanceId);
  }

  public clearQuery(): void {
    if (this._queryCache) {
      this._queryCache.clear();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  public getInstance<T>(instanceId: string, key: unknown): T | undefined {
    if (!this._instanceCache) {
      return undefined;
    }
    const instance: Map<unknown, unknown> = this._instanceCache.get(instanceId);
    if (!instance) {
      return undefined;
    }
    return <T | undefined>instance.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  public setInstance<T>(instanceId: string, key: unknown, result: T): void {
    if (!this._instanceCache) {
      this._instanceCache = new Map<string, Map<unknown, unknown>>();
    }
    let instance: Map<unknown, unknown> = this._instanceCache.get(instanceId);
    if (!instance) {
      instance = new Map<unknown, unknown>();
      this._instanceCache.set(instanceId, instance);
    }
    instance.set(key, result);
  }

  public deleteInstanceKey(instanceId: string, key: unknown): void {
    if (!this._instanceCache) {
      return;
    }
    const instance: Map<unknown, unknown> = this._instanceCache.get(instanceId);
    if (!instance) {
      return;
    }
    instance.delete(key);
  }

  public deleteInstance(instanceId: string): void {
    if (!this._instanceCache) {
      return;
    }
    this._instanceCache.delete(instanceId);
  }

  public clearInstance(): void {
    if (this._instanceCache) {
      this._instanceCache.clear();
    }
  }

  public delete(instanceId: string): void {
    this.deleteQuery(instanceId);
    this.deleteInstance(instanceId);
  }

  public clear(): void {
    this.clearQuery();
    this.clearInstance();
  }
}
