<div class="colaborador-alerts">
  <div class="pl-alert-sticky alert alert-danger" *ngIf="iColHome.temAlteracoes && iColHome.gestorRH && stateFixAlteracoesEntidades">
    <div class="clickable active" (click)="goToAprova(stateNameAlteracaoEntidades)">
      <i class="fa fa-fw fa-exclamation-circle"></i>&nbsp;<span [translate]="'dgemps.home.altDeFichasPorAprov'"></span>
    </div>
  </div>

  <div class="pl-alert-sticky alert alert-danger" *ngIf="iColHome.temFeriasPorAprovar && stateFixFerias">
    <div><i class="fa fa-fw fa-exclamation-circle"></i>&nbsp;<span [translate]="'dgemps.home.pedidosFeriasPorAprov'"></span>:</div>
    <ul>
      <li class="clickable" [class.active]="stateFixFerias" *ngFor="let item of iColHome.listaServicosPendentes.servicosFerias" (click)="goToAprova(stateNameFerias, item)">
        <span>{{ item.nome }}</span>
      </li>
    </ul>
  </div>

  <div class="pl-alert-sticky alert alert-danger" *ngIf="iColHome.temFaltasPorAprovar && stateFixFaltas">
    <div><i class="fa fa-fw fa-exclamation-circle"></i>&nbsp;<span [translate]="'dgemps.home.faltasPorAprov'"></span>:</div>
    <ul>
      <li class="clickable" [class.active]="stateFixFaltas" *ngFor="let item of iColHome.listaServicosPendentes.servicosFaltas" (click)="goToAprova(stateNameFaltas, item)">
        <span>{{ item.nome }}</span>
      </li>
    </ul>
  </div>

  <div class="pl-alert-sticky alert alert-danger" *ngIf="iColHome.temAbonosPorAprovar && stateFixAbonos">
    <div><i class="fa fa-fw fa-exclamation-circle"></i>&nbsp;<span [translate]="'dgemps.home.abonosPorAprov'"></span>:</div>
    <ul>
      <li class="clickable" [class.active]="stateFixAbonos" *ngFor="let item of iColHome.listaServicosPendentes.servicosAbonos" (click)="goToAprova(stateNameAbonos, item)">
        <span>{{ item.nome }}</span>
      </li>
    </ul>
  </div>
</div>

<pl-tabs [justified]="isMobile">
  <pl-tab id="comunicacoes">
    <div *plTabTitle>
      <i class="fa fa-exchange fa-fw"></i>&nbsp;<span [translate]="'dgemps.home.comunicacoes'"></span>&nbsp; <span class="badge">{{ iColHome.newComunicationsCount }}</span>
    </div>

    <div *plTabContent>
      <pl-navpill [activeId]="comunicacaoActiveId" (activeIdChange)="comunicacaoActiveIdChange($event)">
        <pl-navpill-panel *ngFor="let tipo of iColHome.tpComunicacao" [id]="'comunicacaoNav-' + tipo.codPRHTipoCom">
          <div *plNavPillPanelTitle>
            <div class="d-flex" style="justify-content: space-between">
              <span>{{ tipo.nome }}</span>
              <span *ngIf="tipo.count > 0" style="position: relative">
                <i class="fa fa-fw fa-envelope"></i>
                <span style="font-size: 7px; position: absolute; right: -5px; top: -3px" class="badge bg-danger rounded-pill">{{ tipo.count }}</span>
              </span>
            </div>
          </div>
          <div *plNavPillPanelContent>
            <div class="comunicacao-toolbar" *ngIf="tipo.codPRHTipoCom !== comunicacaoInternaSystem.RECRUTAMENTO_INTERNO">
              <button type="button" class="btn btn-primary btn-xs action-nova-com-int" (click)="novaComInt(tipo)"><i class="fa fa-fw fa-plus-circle"></i>{{ tipo.nome }}</button>
            </div>

            <div class="home-comunicacao-interna-messages comunicacao-interna-messages">
              <div class="comunicacao-interna-message-row" *ngFor="let data of tipo.cabecalhosComInt" [ngClass]="{expanded: data.cab.childrenVisible}">
                <comunicacaointerna-recursiva (evtComunicacaoSent)="onComunicacaoSent()" [answer]="false" [iColHome]="iColHome" [data]="data" [item]="tipo"></comunicacaointerna-recursiva>
              </div>
            </div>
          </div>
        </pl-navpill-panel>
      </pl-navpill>
    </div>
  </pl-tab>

  <pl-tab id="recibos">
    <div *plTabTitle>
      <i class="fa fa-file-text fa-fw"></i>&nbsp;<span [translate]="'dgemps.home.recibos'"></span>&nbsp;<span class="badge">{{ iColHome.countRecibosPorLer }}</span>
    </div>

    <div *plTabContent>
      <dx-data-grid [cgDxDataGrid]="iColHome.defRecibos" cgDxDataGridInstanceName="recibos" [dataSource]="iColHome.defRecibos.dataSource" (onInitialized)="onInitialized($event, 'recibos')">
        <div *dxTemplate="let item of 'cellTemplateBtnRecibos'">
          <button type="button" class="btn btn-primary btn-xs action-pdf" (click)="pdfModalRecibos(item.data)">
            <i class="fa fa-fw fa-file-pdf-o"></i>
          </button>
        </div>
      </dx-data-grid>
    </div>
  </pl-tab>

  <pl-tab id="ferias" (click)="refreshFerias()">
    <div *plTabTitle>
      <i class="fa fa-picture-o fa-fw"></i>&nbsp;<span [translate]="'dgemps.home.ferias'"></span>&nbsp;<span class="badge">{{ prettyFeriasMarcadas }}</span>
    </div>

    <div *plTabContent>
      <div class="row">
        <div class="col-6">
          <div class="chart-container-doughnut">
            <dx-pie-chart [cgDxPieChart]="chartFeriasIntegradas" [dataSource]="chartDataFeriasIntegradas"></dx-pie-chart>
          </div>
        </div>

        <div class="col-6">
          <div class="chart-container-doughnut">
            <dx-pie-chart [cgDxPieChart]="chartFeriasGlobais" [dataSource]="chartDataFeriasGlobais"></dx-pie-chart>
          </div>
        </div>
      </div>

      <hr />

      <div class="entity-detail-form">
        <pl-form>
          <h4 [translate]="'dgemps.home.intFerias'"></h4>
          <pl-group>
            <pl-group>
              <label [translate]="'global.text.fromDate'"></label>
              <edit>
                <pl-edit-datepicker [model]="dataDeFilter" (modelChange)="dataDeFilter = $event; pesqFerias()"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit-datepicker [model]="dataAteFilter" (modelChange)="dataAteFilter = $event; pesqFerias()"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <button type="button" class="btn btn-outline btn-primary btn-sm" (click)="pesqFerias()"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></button>
        </pl-form>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFeriasMarcadas"
            cgDxDataGridInstanceName="feriasMarcadas"
            [dataSource]="iColHome.defFeriasMarcadas.dataSource"
            (onInitialized)="onInitialized($event, 'feriasMarcadas')">
          </dx-data-grid>
        </div>

        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFeriasRejeitadas"
            cgDxDataGridInstanceName="tableFeriasRejeitadas"
            [dataSource]="iColHome.defFeriasRejeitadas.dataSource"
            (onInitialized)="onInitialized($event, 'feriasRejeitadas')">
          </dx-data-grid>
        </div>

        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFeriasIntegradas"
            cgDxDataGridInstanceName="tableFeriasIntegradas"
            [dataSource]="iColHome.defFeriasIntegradas.dataSource"
            (onInitialized)="onInitialized($event, 'feriasIntegradas')">
          </dx-data-grid>
        </div>
      </div>
    </div>
  </pl-tab>

  <pl-tab id="faltas" (click)="refreshFaltas()">
    <div *plTabTitle>
      <i class="fa fa-calendar-times-o fa-fw"></i>&nbsp;<span [translate]="'dgemps.home.faltas'"></span>&nbsp;<span class="badge">{{ iColHome.faltasIntegradas.length }}</span>
    </div>

    <div *plTabContent>
      <div class="chart-container-doughnut">
        <dx-pie-chart [cgDxPieChart]="chartFaltas" [dataSource]="chartDataFaltas"></dx-pie-chart>
      </div>

      <hr />

      <div class="entity-detail-form">
        <pl-form>
          <h4 [translate]="'dgemps.home.intFaltas'"></h4>
          <pl-group>
            <pl-group>
              <label [translate]="'global.text.fromDate'"></label>
              <edit>
                <pl-edit-datepicker [model]="dataDeFilter" (modelChange)="dataDeFilter = $event; pesqFaltas()"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit-datepicker [model]="dataAteFilter" (modelChange)="dataAteFilter = $event; pesqFaltas()"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <button type="button" class="btn btn-outline btn-primary btn-sm" (click)="pesqFaltas()"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></button>
        </pl-form>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFaltasMarcadas"
            cgDxDataGridInstanceName="tableFaltasMarcadas"
            [dataSource]="iColHome.defFaltasMarcadas.dataSource"
            (onInitialized)="onInitialized($event, 'faltasMarcadas')">
          </dx-data-grid>
        </div>
        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFaltasRejeitadas"
            cgDxDataGridInstanceName="tableFaltasRejeitadas"
            [dataSource]="iColHome.defFaltasRejeitadas.dataSource"
            (onInitialized)="onInitialized($event, 'faltasRejeitadas')">
          </dx-data-grid>
        </div>
        <div class="col-md-4">
          <dx-data-grid
            [cgDxDataGrid]="iColHome.defFaltasIntegradas"
            cgDxDataGridInstanceName="tableFaltasIntegradas"
            [dataSource]="iColHome.defFaltasIntegradas.dataSource"
            (onInitialized)="onInitialized($event, 'faltasIntegradas')">
          </dx-data-grid>
        </div>
      </div>
    </div>
  </pl-tab>

  <pl-tab id="docs">
    <div *plTabTitle>
      <i class="fa fa-exchange fa-fw"></i> Docs. Plataforma <span class="badge">{{ iColHome.listDocsPlata.length }}</span>
    </div>

    <div *plTabContent>
      <dx-data-grid [cgDxDataGrid]="iColHome.defDocsPlata" cgDxDataGridInstanceName="tableDocsPlata" [dataSource]="iColHome.defDocsPlata.dataSource">
        <div *dxTemplate="let item of 'cellTemplateBtnDocsPlata'">
          <a class="btn btn-primary btn-xs action-download-pdf" [href]="item.data.url" download target="_blank">
            <i class="fa fa-fw fa-file-pdf-o"></i>
          </a>
        </div>
      </dx-data-grid>
    </div>
  </pl-tab>
</pl-tabs>
