import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_CCT} from './cct.entity.interface';
import {IJsonCCT} from './jsonCCT.entity.interface';
import {CctEditComponent} from './components/edit/cct.entity.edit.component';

export const ENTITY_CCT: IEntityDefinition<IJsonCCT> = {
  name: ENTITY_NAME_CCT,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.cct',
  searchPlaceholder: 'cct.pesquisa',
  metadata: {
    keyName: 'ncct',
    fields: [
      {name: 'ncct', type: 'cgsmallint', caption: 'cct.fields.ncct', validators: {required: true}},
      {name: 'designaBreve', caption: 'cct.fields.designaBreve', validators: {required: true, maxlength: 20}},
      {name: 'nome', caption: 'cct.fields.nome', validators: {maxlength: 60}},
      {name: 'numBolTrab', type: 'cginteger', caption: 'cct.fields.numBolTrab'},
      {name: 'dtBolTrab', type: 'date', caption: 'cct.fields.dtBolTrab'},
      {name: 'dtIniValidad', type: 'date', caption: 'cct.fields.dtIniValidad'},
      {name: 'mediaTabelas', type: 'cginteger', caption: 'cct.fields.mediaTabelas'},
      {
        name: 'codIRCT',
        visible: true,
        caption: 'cct.fields.codIRCT',
        entity: {name: 'qpirt', keyTarget: 'codIRCT', outputTarget: 'codIRCT', output: 'codirct', fieldsMap: {codirct: 'codIRCT'}}
      },
      {name: 'aplicabilidadeIRCT', caption: 'cct.fields.aplicabilidadeIRCT', visible: false, validators: {required: true}}
    ],
    order: 'ncct',
    searchFields: 'ncct,designaBreve,nome,numBolTrab,dtBolTrab,dtIniValidad,mediaTabelas,nomeCodIRCT',
    filterFields: 'ncct,designaBreve,nome,numBolTrab,dtBolTrab,dtIniValidad,mediaTabelas,nomeCodIRCT',
    listFields: 'ncct,designaBreve,nome'
  },
  autocomplete: {
    rowTemplate: '{{ncct}} - {{designaBreve}}',
    output: 'designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      component: CctEditComponent
    }
  }
};
