<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.viewSaldoDetalhesModalTitle'"></h4>
</div>

<div class="modal-body">
  <div class="text-center">
    <h4 class="main-title" [translate]="'conciliacaoBancaria.saldoContabilidade'"></h4>
  </div>

  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.saldoInicial'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(saldoInicialCG)"></span>
  </div>
  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.saldoFinal'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(saldoFinalCG)"></span>
  </div>

  <br />
  <div class="text-center">
    <h4 class="main-title" [translate]="'conciliacaoBancaria.saldoBanco'"></h4>
  </div>

  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.saldoInicial'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(saldoInicialBanco)"></span>
  </div>
  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.saldoFinal'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(saldoFinalBanco)"></span>
  </div>
  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.saldoFinalCalc'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(saldoFinalBancoCalculado)"></span>
  </div>
  <div class="text-right">
    <span><strong [translate]="'conciliacaoBancaria.fields.diferencaConciliacao'"></strong></span>
    <span class="badge bg-primary" [innerHTML]="getSaldoString(diferencaConciliacao)"></span>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <span class="conciliadoLabel" *ngif="conciliado"> <i class="fa fa-check" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.fields.conciliado'"></span> </span>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
