<pl-animation-collapse [collapsed]="collapsed">
  <div class="card entity-filter">
    <div class="card-header">
      <h6 class="card-title" [translate]="'components.filter.advancedFilter'"></h6>
      <button type="button" class="btn-close" *ngIf="allowCollapse" (click)="collapse()"></button>
    </div>

    <div class="card-body">
      <pl-filter [fields]="fields" [field]="selectedFilter" [serializedFilters]="filterModel" [showCaption]="false" (evtFiltered)="filtered($event)"></pl-filter>
    </div>
  </div>
</pl-animation-collapse>
