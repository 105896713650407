import type {Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {copy, isObject, isString} from '../common/utilities/utilities';
import {findFormControl} from '../common/utilities/form.utilities';
import type {IPlLocale} from '../common/locale/locales.interface';
import type {IPlValidator, IPlValidatorValidateParams, TPlValidatorsRegistry} from './validate.interface';
import {PlEditInputComponent} from '../edit/generic/input/edit.input.component';
import {PlLocaleService} from '../common/locale/locale.service';

@Injectable({
  providedIn: 'root'
})
export class PlValidatorRegistryService implements OnDestroy {
  private readonly _validators: TPlValidatorsRegistry;
  private readonly _subscriptionLocale: Subscription;

  constructor(private readonly _plLocaleService: PlLocaleService) {
    this._validators = new Map<string, IPlValidator>();
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      // Built in validators
      this.register('equals', {
        value: undefined,
        message: locale.validators.equals,
        validate: (() => {
          let currentTargetControl: AbstractControl;
          let subscription: Subscription;
          const clearSubscription = (): void => {
            if (subscription) {
              subscription.unsubscribe();
              subscription = undefined;
            }
          };
          return ({formControlValue, formControlInstance, instance, plValidatorValue}: IPlValidatorValidateParams) => {
            if (!plValidatorValue || !formControlValue) {
              return true;
            }
            if (!(formControlInstance.root instanceof FormGroup)) {
              throw new Error('No parent FormGroup found, this is required to find the target control');
            }
            const targetControl: AbstractControl = findFormControl(<string>plValidatorValue, formControlInstance.root, false);
            if (!targetControl) {
              clearSubscription();
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              throw new Error(`Control to compare [${String(plValidatorValue)}] not found`);
            }
            if (currentTargetControl !== targetControl) {
              currentTargetControl = targetControl;
              clearSubscription();
              if (instance instanceof PlEditInputComponent) {
                subscription = targetControl.valueChanges.subscribe(() => {
                  const valueChangesPrevented: boolean = instance.valueChangesPrevented;
                  instance.preventValueChanges();
                  formControlInstance.updateValueAndValidity();
                  if (!valueChangesPrevented) {
                    instance.allowValueChanges();
                  }
                });
              }
            }
            return formControlValue === targetControl.value;
          };
        })()
      });
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public register<T, S>(name: string, validator: IPlValidator<T, S>): this {
    if (!isString(name) || !isObject(validator)) {
      throw new TypeError('Invalid custom validation register');
    }
    this._validators.set(name.toLowerCase(), copy(validator));
    return this;
  }

  public get<T = any, S = T>(name: string): IPlValidator<T, S> {
    return copy(this._validators[name.toLowerCase()]);
  }

  public getAll(): TPlValidatorsRegistry {
    return copy(this._validators);
  }
}
