import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PlAlertService, PlTranslateService, TPlAlertStickyType} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../../services/contabilidade.declaracaoiva.service';
import {IJsonDecIvaConfig} from '../../../../../../interfaces/jsonDeclaracaoIva.interface';
import {SimpleLoginModalComponent} from '../../../../../simplelogin/modals/simpleLogin.modal.component';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'declaracaoiva-config-modal',
  templateUrl: './contabilidade.declaracaoIva.config.modal.component.html'
})
export class ContabilidadeDeclaracaoivaConfigModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public forDrIva: boolean;

  public model: IJsonDecIvaConfig;
  public alertType: TPlAlertStickyType;
  public alertMessage: string;
  public form: UntypedFormGroup;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _contabilidadeDeclaracaoIvaService: ContabilidadeDeclaracaoIvaService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.forDrIva = false;
  }

  public ngOnInit(): void {
    this.model = {
      passwordCC: '',
      usernameCC: '',
      servicoInvocadoPorCC: false,
      password: '',
      ccComPoderesDeclarativosContribuinte: false,
      username: ''
    };
    this.promise = this._contabilidadeDeclaracaoIvaService
      .getConfig(this.forDrIva)
      .then((response) => {
        this.model = response.body;
        if (this.model.isConfigured) {
          this.alertType = 'success';
          this.alertMessage = this._plTranslateService.translate('declaracaoiva.configured');
        } else {
          this.model.password = '';
          this.model.passwordCC = '';
          this.alertType = 'warning';
          this.alertMessage = this._plTranslateService.translate('declaracaoiva.notConfigured');
        }
        if (!this.model.username) {
          this.model.username = `${this._configService.configurations.empresa.ncontribuemp}/0`;
        }
        if (!this.model.usernameCC) {
          this.model.usernameCC = this._configService.configurations.empresa.ncontribtoc;
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  public close(): void {
    if (this.form.valid) {
      this.disableClose();
      this._contabilidadeDeclaracaoIvaService
        .saveConfig(this.model)
        .then(() => {
          this.enableClose();
          this._plAlertService.success('Configuração guardada com sucesso.');
          super.close();
        })
        .catch(() => {
          this.enableClose();
        });
    }
  }

  public servicoInvocadoPorCCChanged(value: boolean): void {
    this.model.servicoInvocadoPorCC = value;
    if (!this.model.servicoInvocadoPorCC) {
      this.model.ccComPoderesDeclarativosContribuinte = false;
    }
  }

  public showLoginModal(): Promise<unknown> {
    return this._cgModalService.show(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'});
  }
}
