import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IDocsComerciaisEntityService} from '../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../exceptions/exceptions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';

@Component({
  selector: 'documento-facturacao-encerrar-modal',
  templateUrl: './documento.facturacao.encerrar.modal.component.html'
})
export class DocumentoFacturacaoEncerrarModalComponent extends CGModalComponent<IJsonDocComercial> implements OnInit {
  @Input() public doc: IJsonDocComercial;
  @Input() public service: IDocsComerciaisEntityService;

  public question: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.question = this._plTranslateService.translate('documentoFaturacao.modals.questions.confirmEncerrar', {id: this.doc.cab.nDoc});
  }

  public close(): Promise<void> {
    this.disableClose();
    return this.service
      .put({url: `${this.doc.cab.faccbId}/encerrar`})
      .then((response) => {
        if (response.body) {
          this.doc = response.body;
          if (this.doc.cab.grupoDocfa && this.doc.cab.grupoDocfa === EGrupoDoc.GuiasTransporteRemessa && this.doc.cab.returnMessage !== '') {
            const erro = this._plTranslateService.translate('docscomerciais.erros.encerrarDocumento');
            this._plAlertService.error(`${erro}: ${this.doc.cab.returnMessage}`);
          } else {
            this._plAlertService.success('docscomerciais.info.encerrarDocumento');
          }
        }
        super.close(this.doc);
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        }
      })
      .finally(() => {
        this.enableClose();
      });
  }
}
