import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import type {FileListPipelineHandler} from './filelist.pipeline.interface';

@Injectable()
export class NoopFileListPipelineHandler implements FileListPipelineHandler {
  public handle(value: Array<File>): Observable<Array<File>> {
    return of<Array<File>>(value);
  }
}
