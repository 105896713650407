<div *ngIf="showMessagesError" [plAnimationShake]="messages.error">
  <pl-alert type="danger" *ngIf="messages.error.length" (evtClosed)="messages.error = []">
    <div [innerHTML]="messages.errorMsg | translate: {id: prettyId || prettyId === 0 ? prettyId : ''}"></div>
    <hr class="mt-1 mb-1" />
    <div *ngFor="let msg of messages.error">
      <strong [innerHTML]="msg | translate"></strong>
    </div>
  </pl-alert>
</div>

<div *ngIf="showMessagesSuccess">
  <pl-alert type="success" *ngIf="messages.success" [autoClose]="true" [closeDelay]="alertAutoCloseDelay" (evtClosed)="messages.success = false">
    <span [innerHTML]="messages.successMsg | translate: {id: prettyId || prettyId === 0 ? prettyId : ''}"></span><br />
    <ng-container *ngTemplateOutlet="templateEntityDetailSuccess"></ng-container>
  </pl-alert>
</div>

<div class="entity-detail-form" [plPromise]="watchResult">
  <ng-container [ngSwitch]="!!templateEntityDetailContent">
    <pl-form
      *ngSwitchCase="false"
      [(formInstance)]="formGroup"
      [(ngForm)]="formGroupDirective"
      [model]="model"
      [definition]="definition"
      [template]="template"
      [properties]="{readonly: readonly, orientation: formOrientation}"
      (evtFieldValueChanged)="fieldValueChanged($event)">
    </pl-form>

    <ng-container *ngSwitchCase="true">
      <ng-container
        *ngTemplateOutlet="
          templateEntityDetailContent;
          context: {
            formInstance: formGroup,
            model: model,
            definition: definition,
            template: template,
            properties: {readonly: readonly, orientation: formOrientation}
          }
        "></ng-container>
    </ng-container>
  </ng-container>

  <ng-container
    *ngTemplateOutlet="
      templateEntityDetailPartialContent;
      context: {
        formInstance: formGroup,
        model: model,
        definition: definition,
        template: template,
        properties: {readonly: readonly, orientation: formOrientation}
      }
    "></ng-container>
</div>
