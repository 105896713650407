import type {TranslateService} from '@ngx-translate/core';
import {
  ETipoCalculoDiferimento,
  ETipoDataRefDiferimento,
  ETipoDiferimento,
  ETipoOperacaoDiferimentos,
  IJsonInfoDiferimentos,
  IJsonInfoDiferimentosDetalhes,
  IJsonInfoDiferimentosTotais,
  IJsonMapaDiferimentosAcrescimos,
  IJsonMapaDiferimentosAcrescimosGeral,
  IJsonMapaDiferimentosAcrescimosMonthGeral
} from './jsonCalculoDiferimentos.interface';
import {TDate} from '../../../common/dates';
import {IJsonDocContabilidade} from '../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../services/contabilidadedigital/contabilidadedigital.interface';

export interface IHeaderCalculoDiferimentos {
  tipodiferimento: ETipoCalculoDiferimento;
  nomeTipodiferimento: string;
  tipoDataRefDiferimento: ETipoDataRefDiferimento;
  nomeTipoDataRefDiferimento: string;
  dataini: TDate;
  datafim: TDate;
  valor: number;
  ncasasarredondamento: number;
}

export interface IDiferimentosParams {
  fromComercial: boolean;
  fromContabilidade: boolean;
  dataIniDiferimento: TDate;
  dataFimDiferimento: TDate;
  valor: number;
  docBalanceado: boolean;
}

export interface IDiferimentosSaved {
  dataIniDiferimento: TDate;
  dataFimDiferimento: TDate;
  tipodiferimento?: ETipoCalculoDiferimento;
  tipoDataRefDiferimento?: ETipoDataRefDiferimento;
}

export interface IInfoDiferimentosTotais extends IJsonInfoDiferimentosTotais {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IInfoDiferimentosDetalhes extends IJsonInfoDiferimentosDetalhes {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IInfoDiferimentos extends IJsonInfoDiferimentos {
  totais: Array<IInfoDiferimentosTotais>;
  detalhes: Array<IInfoDiferimentosDetalhes>;
}

export interface IInfoGlobalParams {
  moduleName: string;
}

export interface IMapaDiferimentosAcrescimos extends IJsonMapaDiferimentosAcrescimos {
  _docContabilidade?: IJsonDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}

export interface IMapaDiferimentosAcrescimosGeral extends IJsonMapaDiferimentosAcrescimosGeral {
  _docContabilidade?: IJsonDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}

export interface IMapaDiferimentosAcrescimosDynamic {
  [key: string]: unknown;

  extPocCabID: string;
  nDocumento: string;
  descricaoDoc: string;
  nomeConta: string;
  nConta: string;
  dataInicio: TDate;
  dataFim: TDate;
  dias: number;
  valor: number;
  nDocExterno: string;
  dataDocExterno: TDate;
  nomeDescriti: string;
  nContaAcrescimo: string;
  nContaDiferimento: string;
  nomeContaAcrescimo: string;
  nomeContaDiferimento: string;
  diferimentoValorAnoAtual: number;
  acrescimoValorAnoAtual: number;
  diferimentoValorAnoAnterior: number;
  diferimentoValorAnoSeguinte: number;
  acrescimoValorAnoAnterior: number;
  acrescimoValorAnoSeguinte: number;
}

export interface IMapaDiferimentosAcrescimosYear {
  year: number;
  acrescimoList: Array<IJsonMapaDiferimentosAcrescimosMonthGeral>;
  diferimentoList: Array<IJsonMapaDiferimentosAcrescimosMonthGeral>;
}

export interface ITipoOperacaoDiferimentoSourceItem {
  value: ETipoOperacaoDiferimentos;
  label: string;
}

export interface ITipoDiferimentoSourceItem {
  value: ETipoDiferimento;
  label: string;
}

export const MODULE_NAME_CALCULO_DIFERIMENTOS = 'calculodiferimentos';
export const MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS = 'infoglobaldiferimentosacrescimos';
export const MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS = 'infoglobaldiferimentos';
export const MODULE_NAME_INFO_GLOBAL_ACRESCIMOS = 'infoglobalacrescimos';
export const MODULE_NAME_MAPA_DIFERIMENTOS_ACRESCIMOS = 'mapadiferimentosacrescimos';

export function calculoDiferimentosSourceTipos(translateService: TranslateService): Array<IDataSourceItem<ETipoCalculoDiferimento>> {
  return [
    {
      value: ETipoCalculoDiferimento.Anual,
      label: translateService.instant('calculodiferimentos.metodos.anual')
    },
    {
      value: ETipoCalculoDiferimento.Mensal,
      label: translateService.instant('calculodiferimentos.metodos.mensal')
    }
  ];
}

export function calculoDiferimentosSourceTiposDataRef(translateService: TranslateService): Array<IDataSourceItem<ETipoDataRefDiferimento>> {
  return [
    {
      value: ETipoDataRefDiferimento.DataDoDocumento,
      label: translateService.instant('calculodiferimentos.tipoDataRef.dataDoDocumento')
    },
    {
      value: ETipoDataRefDiferimento.DataDoLancamento,
      label: translateService.instant('calculodiferimentos.tipoDataRef.dataDoLancamento')
    }
  ];
}
