<div class="ativos-import-atvaloranualman-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.contasinconsistencias'"></h5>
  </div>

  <div class="modal-body">
    <pl-alert type="warning" [closeable]="false">
      <span [translate]="'ativosimport.modal.msg.avisoinconsistenciasfirst'"></span>
      <br />
      <span [translate]="'ativosimport.modal.msg.avisoinconsistenciassecond'"></span>
    </pl-alert>
    <dx-data-grid [cgDxDataGrid]="tableDefinition" [dataSource]="ativosconsistencias"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" (evtClicked)="close()">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'ativosimport.modal.btn.inconsistenciabtn'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
