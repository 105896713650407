import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {
  copy,
  IPlFormDefinition,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventStep,
  IPlNavWizardStep,
  IPlTooltipConfig,
  IPlValidator,
  IPlValidatorValidateParams,
  PlAlertService,
  TPlNavWizardOnFinalizeFn
} from 'pl-comps-angular';
import {AtivosConfiguracoesService} from '../../../../modules/ativos/ativosconfiguracoes/ativosConfiguracoes.module.service';
import {AtivosService} from '../../services/ativos.service';
import {ConfigService} from '../../../../services/config/config.service';
import {EAtivosAquisicaoBaseCalculo, EAtivosAquisicaoMetodoCalculo} from '../../../ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {EAtivosComponentState} from './ativos.component.interface';
import {EAtivosEstado, IJsonAtivos, IRadioItemAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {EntityRegistryService} from '../../../../components/entity/entity.registry.service';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IEntity} from '../../../../components/entity/entity.definition.interface';
import moment, {Moment} from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TDate} from '../../../../../common/dates';
import ArrayStore from 'devextreme/data/array_store';
import {TranslateService} from '@ngx-translate/core';
import {DATA_SOURCE_NAME_MESES} from '../../../../datasources/meses/meses.datasource.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AtivosAquisicaoInvestEmCursoPorDocService} from '../../../../modules/ativos/ativosaquisicaoinvestemcurso/ativosAquisicaoInvestEmCursoPorDoc.module.service';
import {IJsonAtivosconfiguracoes} from '../../../../modules/ativos/ativosconfiguracoes/jsonAtivosConfiguracoes.module.interface';
import {ENTITY_NAME_ATIVOS} from '../../interfaces/ativos.entity.interface';

const STEP_INDEX_AQUISICAO_ATIVO = 0;
const STEP_INDEX_DADOS = 1;
const STEP_INDEX_CONTAS = 2;
const STEP_INDEX_CLASSIFICADORES = 3;
const STEP_INDEX_PLANNING = 4;

@Component({
  selector: 'ativos',
  templateUrl: './ativos.component.html'
})
export class AtivosComponent implements OnInit, OnChanges {
  @Input() public stateType: EAtivosComponentState;
  @Input() public model: IJsonAtivos;
  @Input() public readonly: boolean;
  @Input() public onFinalize: TPlNavWizardOnFinalizeFn;
  @Input() public contabValorAquisicao: number;
  @Input() public ativoContab: boolean;
  @Input() public ativoInvestimentosEmCurso: boolean;
  @Input() public docExtPocCabId: string;
  @Input() public valorInvEmCursoDisponivelAnoN: number;
  @Input() public valorInvEmCursoDisponivelAnoN1: number;
  @Output() public readonly modelChange: EventEmitter<IJsonAtivos>;
  @Output() public readonly evtCanSaveNewAtivoChanged: EventEmitter<boolean>;

  public readonly fornecedoresOutput: string;
  public readonly ccustosOutput: string;
  public readonly zonaOutput: string;
  public readonly departamentoOutput: string;
  public readonly subDepartamentoOutput: string;
  public readonly tiposArtigoOutput: string;
  public readonly grFamiliaOutput: string;
  public readonly familiaOutput: string;
  public readonly subFamiliaOutput: string;
  public readonly codContabOutput: string;
  public readonly classeOutput: string;
  public readonly categoriaOutput: string;
  public readonly vendedoresOutput: string;
  public readonly processoOutput: string;
  public readonly seccaoOutput: string;
  public readonly amortizacaoOutput: string;
  public readonly pocsOutput: string;
  public readonly filterMovimento: string;
  public readonly stateTypes: typeof EAtivosComponentState;
  public readonly estadosAtivo: typeof EAtivosEstado;
  public readonly formDefinition: IPlFormDefinition;
  public readonly tooltipTaxa: IPlTooltipConfig;
  public readonly validatorValorAquisicao: IPlValidator<number>;
  public readonly validatorFiscalAquisicao: IPlValidator<number>;
  public readonly definition: IPlNavWizardDefinition;
  public readonly metodoCaclAtivosSource: Array<IRadioItemAtivos>;
  public readonly baseCaclAtivosSource: Array<IRadioItemAtivos>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public filterContaDoAtivo: string;
  public formAquisicaoAtivo: UntypedFormGroup;
  public formDadosContabilisticosDadosFiscais: UntypedFormGroup;
  public formContasPlanoOutrosDados: UntypedFormGroup;
  public contabTaxaDecRegu: number;
  public fiscalTaxaDecRegu: number;
  public selectedStep: IPlNavWizardStep;
  public promise: Promise<void>;
  public contaAmortExercicio: string;
  public contaAmortAcumulado: string;
  public contaIvaDedutivel: string;
  public contaIvadeAquisicao: string;
  public validaTaxaContab: boolean;
  public validaTaxaFiscal: boolean;
  public isModalContas: boolean;
  public canEditContaAmortExerc: boolean;

  private readonly _element: HTMLElement;
  private _propNContaForn: string;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _ativosService: AtivosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _configService: ConfigService,
    private readonly _ativosConfiguracoesService: AtivosConfiguracoesService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosAquisicaoInvestEmCursoPorDocService: AtivosAquisicaoInvestEmCursoPorDocService
  ) {
    this.fornecedoresOutput = '{{nConta}} - {{nome}}';
    this.ccustosOutput = '{{nCCusto}} - {{nome}}';
    this.zonaOutput = '{{nZona}} - {{nome}}';
    this.departamentoOutput = '{{nDepto}} - {{nome}}';
    this.subDepartamentoOutput = '{{nSubDe}} - {{nome}}';
    this.tiposArtigoOutput = '{{codTpArt}} - {{nome}}';
    this.grFamiliaOutput = '{{nDepart}} - {{nome}}';
    this.familiaOutput = '{{nFamilia}} - {{nome}}';
    this.subFamiliaOutput = '{{nSubFa}} - {{nome}}';
    this.codContabOutput = '{{codcontab}} - {{nome}}';
    this.classeOutput = '{{classe}} - {{descricao}}';
    this.categoriaOutput = '{{categoria}} - {{descricao}}';
    this.vendedoresOutput = '{{nVendedor}} - {{nome}}';
    this.processoOutput = '{{nRefProcesso}} - {{nome}}';
    this.seccaoOutput = '{{nSeccao}} - {{designacaoBreve}}';
    this.amortizacaoOutput = '{{nImtab}} - {{nome}}';
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.metodoCaclAtivosSource = [
      {
        value: EAtivosAquisicaoMetodoCalculo.QuotasConstantes,
        label: 'ativosAquisicao.radioOptions.quotasConstantes'
      },
      {
        value: EAtivosAquisicaoMetodoCalculo.QuotasDecrescentes,
        label: 'ativosAquisicao.radioOptions.quotasDecrescentes'
      }
    ];
    this.baseCaclAtivosSource = [
      {
        value: EAtivosAquisicaoBaseCalculo.InicioUtilizacao,
        label: 'ativosAquisicao.radioOptions.inicioUtilizacao'
      },
      {
        value: EAtivosAquisicaoBaseCalculo.BaseAnual,
        label: 'ativosAquisicao.radioOptions.baseAnual'
      }
    ];
    this.contabValorAquisicao = 0;
    this.ativoContab = false;
    this.docExtPocCabId = '';
    this.modelChange = new EventEmitter<IJsonAtivos>();
    this.evtCanSaveNewAtivoChanged = new EventEmitter<boolean>();
    this.stateTypes = EAtivosComponentState;
    this.estadosAtivo = EAtivosEstado;
    this.definition = {
      items: []
    };
    this._element = this._elementRef.nativeElement;
    const entity: IEntity<IJsonAtivos> = this._entityRegistryService.getEntity<IJsonAtivos>(ENTITY_NAME_ATIVOS);
    this.formDefinition = entity.edit.definition;
    this.validaTaxaContab = false;
    this.validaTaxaFiscal = false;
    this.tooltipTaxa = {text: 'ativosAquisicao.warn.tooltipTaxa', placement: 'bottom', tooltipClass: 'tooltip-warning'};
    this.isModalContas = false;
    this.validatorValorAquisicao = {
      message: 'ativosaquisicaocontab.error.contabValorAquisicaoSup',
      validate: ({modelValue}: IPlValidatorValidateParams<number>) => this._validateValorAquisicao(modelValue)
    };
    this.validatorFiscalAquisicao = {
      message: 'ativosaquisicaocontab.error.fiscalValorAquisicaoSup',
      validate: ({modelValue}: IPlValidatorValidateParams<number>) => this._validateFiscalAquisicao(modelValue)
    };
    this.canEditContaAmortExerc = false;
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.filterContaDoAtivo = `tipo=${ETipoContaContabilidade.Movimento}`;
    this._ativosConfiguracoesService.get().then((response: HttpResponse<IJsonAtivosconfiguracoes>) => {
      if (response.body.radicaisContasImobilizado) {
        const filterRadicaisAtivos: string = response.body.radicaisContasImobilizado
          .split(',')
          .map((radicalAtivo: string) => `nconta=%${radicalAtivo}%`)
          .join('|');
        this.filterContaDoAtivo += `&(${filterRadicaisAtivos})`;
      }
    });
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'global.text.year',
          columns: [{dataField: 'ano', dataType: 'number', caption: 'ativos.table.body.anoEconomico'}]
        },
        {
          caption: 'ativos.table.depreciacoes',
          columns: [
            {dataField: 'mesde', dataType: 'number', caption: 'ativos.table.body.mesde', lookup: {cgDataSource: DATA_SOURCE_NAME_MESES}},
            {dataField: 'mesate', dataType: 'number', caption: 'ativos.table.body.mesate', lookup: {cgDataSource: DATA_SOURCE_NAME_MESES}}
          ]
        },
        {
          caption: 'ativos.table.dadosContabilisticos',
          columns: [
            {dataField: 'contabValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'contabValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'contabTaxa', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'contabPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'contabDepreciacao', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        },
        {
          caption: 'ativos.table.dadosFiscais',
          columns: [
            {dataField: 'fiscalValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'fiscalValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'fiscalTaxa', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'fiscalPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'fiscalDepreciacao', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        }
      ],
      dataSource: [],
      export: {filename: 'ativos.steps.planningAmortizacoes'},
      headerFilter: {visible: false},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'contabDepreciacao', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'fiscalDepreciacao', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
  }

  public ngOnInit(): void {
    if (this.ativoInvestimentosEmCurso) {
      this._propNContaForn = this.model.aquisicao.nContaFornecedor;
    }
  }

  public ngOnChanges({stateType, model}: SimpleChanges): void {
    if (model) {
      if (!this.model) {
        this.model = {
          codAtivo: undefined,
          designacao: '',
          refExterna: '',
          estado: undefined,
          codAtivoPai: undefined,
          viatLigArtigo11DR: false,
          ignoraImpostosDif: false,
          origem: undefined,
          depreciacoesEstado: undefined,
          depreciacoesLastAno: undefined,
          depreciacoesLastMes: undefined,
          documentoDeAquisicao: '',
          dataEstado: undefined,
          isAtivoBiologicoDeProducao: false,
          aquisicao: undefined,
          valorAquisicaoContab: undefined,
          valorDepreciacaoContab: undefined,
          valorLiquidoContab: undefined
        };
      }
      if (!this.model.aquisicao) {
        this.model.aquisicao = {
          ativosAquisicaoID: '',
          codAtivo: undefined,
          codAmortizacao: '',
          nMapaAmortizacao: '32',
          dataAquisicao: this._getCurrentDay(),
          dataUtilizacao: this._getFirstDayOfMonth(),
          nDocExterno: '',
          dataDocExterno: this._getCurrentDay(),
          nomeFornecedor: '',
          quantidade: 1,
          usado: false,
          contabValorAquisicao: undefined,
          contabTaxaAmortiza: undefined,
          contabNumAnosUteis: undefined,
          contabPercReducao: undefined,
          contabDataDispUso: '',
          contabBaseCalculo: undefined,
          contabMetodoCalculo: undefined,
          contabValorResidual: undefined,
          fiscalValorAquisicao: undefined,
          fiscalTaxaAmortiza: undefined,
          fiscalNumAnosUteis: undefined,
          fiscalPercReducao: undefined,
          fiscalDataInicioUtil: '',
          fiscalBaseCalculo: undefined,
          fiscalMetodoCalculo: undefined,
          fiscalValorResidual: undefined,
          fiscalTribAutonTipo: undefined,
          nContaDoAtivo: '',
          nContaAmortAcumu: '',
          nContaAmortExerc: '',
          nContaFornecedor: '',
          nContaIVADedutivel: '',
          iVADeduzido: undefined,
          codIVAAquisicao: undefined,
          codRubOrcam: '',
          nCCusto: '',
          nCodRepCC: undefined,
          nZona: undefined,
          nDepto: undefined,
          nSubde: '',
          tipoArtigo: undefined,
          nGrFamilia: undefined,
          nFamilia: undefined,
          nSubfa: undefined,
          codContab: '',
          classe: undefined,
          categoria: undefined,
          nVendedor: undefined,
          nRefProcesso: '',
          nSeccao: '',
          nomeAmortizacao: '',
          nomeCCusto: '',
          nomeCategoria: '',
          nomeClasse: '',
          nomeContaDoAtivo: '',
          nomeContaFornecedor: '',
          nomeContab: '',
          nomeDepto: '',
          nomeFamilia: '',
          nomeGrFamilia: '',
          nomeMapaAmortizacao: 'Mapa de depreciações e amortizações',
          nomeProcesso: '',
          nomeSubDe: '',
          nomeSubFa: '',
          nomeTipoArtigo: '',
          nomeVendedores: '',
          nomeZona: '',
          nomeSeccao: '',
          nomeRepcc: '',
          nomeContaAmortExerc: '',
          nomeContaAmortAcumu: '',
          nomeContaIva: '',
          nomeCodIva: '',
          planningList: undefined,
          podeEditar: undefined,
          podeEditarContas: undefined
        };
      }
      if (this.stateType !== EAtivosComponentState.NEW) {
        this._contasPlanoAndOutrosDados();
        this._validateclassificadoresValues();
      }
    }
    if (stateType) {
      const stateTypeAtivos: EAtivosComponentState = stateType.currentValue;
      this.isModalContas = stateTypeAtivos === EAtivosComponentState.NEW && this.ativoContab;
    }
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep === this.definition.items[STEP_INDEX_DADOS]) {
      setTimeout(() => {
        let element: HTMLElement;
        if (!this.ativoContab && !this.model.aquisicao?.contabValorAquisicao) {
          element = document.querySelector('input[name="contabValorAquisicao"]');
        } else {
          element = this._element.querySelector('.pl-nav-wizard-footer-actions button.action-next-step');
        }
        focusElement(element);
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_CONTAS]) {
      setTimeout(() => {
        if (this.isModalContas || this.ativoInvestimentosEmCurso) {
          this._element.scrollIntoView(false);
          const button: HTMLElement = this._element.querySelector('.pl-nav-wizard-footer-actions button.action-next-step');
          focusElement(button);
        } else {
          const input: HTMLElement = this._element.querySelector('.pl-nav-wizard-footer-actions input[name="nContaDoAtivo"]');
          focusElement(input);
        }
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_CLASSIFICADORES]) {
      setTimeout(() => {
        const button: HTMLElement = this._element.querySelector('.pl-nav-wizard-footer-actions button.action-next-step');
        focusElement(button);
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_PLANNING]) {
      this.evtCanSaveNewAtivoChanged.emit(true);
      setTimeout(() => {
        this.dataGridDefinition.dataSource = new ArrayStore({
          data: this.model.aquisicao.planningList
        });
        this._element.scrollIntoView(false);
        const button: HTMLElement = this._element.querySelector('.pl-nav-wizard-footer-actions button.action-finalize');
        focusElement(button);
      });
    }
  }

  public onStepChange(step: IPlNavWizardStep): void {
    this.selectedStep = step;
  }

  public dataAquisicaoChanged(date: TDate): void {
    this.model.aquisicao.dataAquisicao = date;
    if (this.ativoInvestimentosEmCurso) {
      const dateStartOfMonth: TDate = moment(this.model.aquisicao.dataAquisicao).startOf('month');
      this.model.aquisicao.dataUtilizacao = dateStartOfMonth;
      this.model.aquisicao.dataDocExterno = dateStartOfMonth;
      this.model.aquisicao.contabDataDispUso = dateStartOfMonth;
      this.model.aquisicao.fiscalDataInicioUtil = dateStartOfMonth;
    }
  }

  public commandValoresContabilisticosEFiscais(): Promise<void> {
    this.promise = this._ativosService
      .commandValoresContabilisticosEFiscais(this.model, this.contabValorAquisicao, this.ativoContab, this.docExtPocCabId)
      .then((response: IJsonAtivos) => {
        if (response.aquisicao.contabValorAquisicao === 0) {
          response.aquisicao.contabValorAquisicao = undefined;
        }
        if (response.aquisicao.fiscalValorAquisicao === 0) {
          response.aquisicao.fiscalValorAquisicao = undefined;
        }
        this.contabTaxaDecRegu = response.aquisicao.contabTaxaAmortiza;
        this.fiscalTaxaDecRegu = response.aquisicao.fiscalTaxaAmortiza;
        this._applyModel(response);
      })
      .finally(() => {
        this.promise = undefined;
        if (this.ativoContab || this.model?.aquisicao?.contabValorAquisicao) {
          const button: HTMLElement = this._element.querySelector('.pl-nav-wizard-footer-actions button.action-next-step');
          focusElement(button);
        }
      });
    return this.promise;
  }

  public commandValoresClassificadores(): Promise<void> {
    this.promise = this._ativosService
      .commandValoresClassificadores(this.model)
      .then((response: IJsonAtivos) => {
        this._applyModel(response);
        this._validateclassificadoresValues();
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public commandContasAndIVAFromNContaAtivo(): Promise<void> {
    this.promise = this._ativosService
      .commandContasAndIVAFromNContaAtivo(this.model)
      .then((response: IJsonAtivos) => {
        this._applyModel(response);
        this.model.aquisicao = copy(this.model.aquisicao);
      })
      .finally(() => {
        this._contasPlanoAndOutrosDados();
        this.promise = undefined;
      });
    return this.promise;
  }

  public commandValidaTaxasCodAmortizacao(): Promise<void> {
    this.promise = this._ativosService
      .commandValidaTaxasCodAmortizacao(this.model)
      .then((response: IJsonAtivos) => {
        if (response.aquisicao.contabTaxaAmortiza !== this.contabTaxaDecRegu) {
          this.validaTaxaContab = true;
        }
        if (response.aquisicao.fiscalTaxaAmortiza !== this.fiscalTaxaDecRegu) {
          this.validaTaxaFiscal = true;
        }
        this._applyModel(response);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public commandPlanning(): Promise<void> {
    this.promise = this._ativosService
      .commandPlanning(this.model)
      .then((response: IJsonAtivos) => {
        this._applyModel(response);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public commandDataPorBaseParaCalculo(): Promise<void> {
    this.promise = this._ativosService
      .commandDataPorBaseParaCalculo(this.model)
      .then((response: IJsonAtivos) => {
        this._applyModel(response);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  private async _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_AQUISICAO_ATIVO] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_DADOS]))) {
      if (!this.formAquisicaoAtivo.valid) {
        this._plAlertService.error('ativosaquisicaocontab.error.invalidStep');
        return Promise.resolve(false);
      }
      if (this.ativoInvestimentosEmCurso) {
        const anoAquisicao = moment(this.model.aquisicao.dataAquisicao).year();
        const anoEmCursoIRC = this._configService.configurations.empresa.anoEmCursoIRC;
        if (anoAquisicao !== anoEmCursoIRC && anoAquisicao !== anoEmCursoIRC + 1) {
          this._plAlertService.error(
            this._translateService.instant('ativosaquisicaoinvestemcurso.messages.invalidDateNewAtivo', {
              ano: this._configService.configurations.empresa.anoEmCursoIRC,
              anoseguinte: this._configService.configurations.empresa.anoEmCursoIRC + 1
            })
          );
          return Promise.resolve(false);
        }
        this.contabValorAquisicao = anoAquisicao === anoEmCursoIRC ? this.valorInvEmCursoDisponivelAnoN : this.valorInvEmCursoDisponivelAnoN1;
        this.model.aquisicao.contabValorAquisicao = anoAquisicao === anoEmCursoIRC ? this.valorInvEmCursoDisponivelAnoN : this.valorInvEmCursoDisponivelAnoN1;
        this.model.aquisicao.fiscalValorAquisicao = anoAquisicao === anoEmCursoIRC ? this.valorInvEmCursoDisponivelAnoN : this.valorInvEmCursoDisponivelAnoN1;
      }
      return this._initStep2().then(() => true);
    }
    if (currentStep === this.definition.items[STEP_INDEX_DADOS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_CONTAS]))) {
      if (!this.formDadosContabilisticosDadosFiscais.valid) {
        this._plAlertService.error('ativosaquisicaocontab.error.invalidStep');
        return Promise.resolve(false);
      }
      if (this.isModalContas) {
        this._contasPlanoAndOutrosDados();
        this._validateclassificadoresValues();
      }
      if (this.model.aquisicao.fiscalBaseCalculo !== this.model.aquisicao.contabBaseCalculo) {
        return this._cgModalService
          .showOkCancel('global.text.warning', 'ativosAquisicao.warn.confirmBaseFiscDifContab')
          .then(() => true)
          .catch(() => false);
      }
    }
    if (currentStep === this.definition.items[STEP_INDEX_CONTAS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_CLASSIFICADORES]))) {
      if (!this.formContasPlanoOutrosDados.valid) {
        this._plAlertService.error('ativosaquisicaocontab.error.invalidStep');
        return Promise.resolve(false);
      }

      if (this.ativoInvestimentosEmCurso) {
        if (this.model.aquisicao.contabValorAquisicao > this.valorInvEmCursoDisponivelAnoN) {
          this._plAlertService.error(
            this._translateService.instant('ativosaquisicaoinvestemcurso.messages.invalidvalue', {
              value: this._configService.configurations.empresa.anoEmCursoIRC ? this.valorInvEmCursoDisponivelAnoN : this.valorInvEmCursoDisponivelAnoN1
            })
          );
          return Promise.resolve(false);
        }
        await this._ativosAquisicaoInvestEmCursoPorDocService.validateNContaAtivo(this.model.aquisicao.nContaDoAtivo, this._propNContaForn);
      }
      return this._initStep4().then(() => true);
    }
    if (currentStep === this.definition.items[STEP_INDEX_CLASSIFICADORES] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_PLANNING]))) {
      return this.commandPlanning().then(() => true);
    }
    return Promise.resolve(true);
  }

  private async _initStep2(): Promise<void> {
    await this.commandValoresContabilisticosEFiscais();
    if (this.ativoInvestimentosEmCurso) {
      this.model.aquisicao.contabValorAquisicao = this.valorInvEmCursoDisponivelAnoN;
      this.model.aquisicao.fiscalValorAquisicao = this.valorInvEmCursoDisponivelAnoN;
    }
  }

  private _initStep4(): Promise<void> {
    if (!this.isModalContas) {
      return this.commandValoresClassificadores();
    }
    this._validateclassificadoresValues();
    return Promise.resolve();
  }

  private _applyModel(value: IJsonAtivos = this.model): void {
    if (value !== this.model) {
      // this.model = value;
      this.model.codAtivo = value.codAtivo;
      this.model.designacao = value.designacao;
      this.model.refExterna = value.refExterna;
      this.model.estado = value.estado;
      this.model.codAtivoPai = value.codAtivoPai;
      this.model.ignoraImpostosDif = value.ignoraImpostosDif;
      this.model.origem = value.origem;
      this.model.depreciacoesEstado = value.depreciacoesEstado;
      this.model.depreciacoesLastAno = value.depreciacoesLastAno;
      this.model.depreciacoesLastMes = value.depreciacoesLastMes;
      this.model.documentoDeAquisicao = value.documentoDeAquisicao;
      this.model.dataEstado = value.dataEstado;
      this.model.isAtivoBiologicoDeProducao = value.isAtivoBiologicoDeProducao;
      this.model.valorAquisicaoContab = value.valorAquisicaoContab;
      this.model.valorDepreciacaoContab = value.valorDepreciacaoContab;
      this.model.valorLiquidoContab = value.valorLiquidoContab;

      this.model.aquisicao.ativosAquisicaoID = value.aquisicao.ativosAquisicaoID;
      this.model.aquisicao.codAtivo = value.aquisicao.codAtivo;
      this.model.aquisicao.codAmortizacao = value.aquisicao.codAmortizacao;
      this.model.aquisicao.nMapaAmortizacao = value.aquisicao.nMapaAmortizacao;
      this.model.aquisicao.dataAquisicao = value.aquisicao.dataAquisicao;
      this.model.aquisicao.dataUtilizacao = value.aquisicao.dataUtilizacao;
      this.model.aquisicao.nDocExterno = value.aquisicao.nDocExterno;
      this.model.aquisicao.dataDocExterno = value.aquisicao.dataDocExterno;
      this.model.aquisicao.nomeFornecedor = value.aquisicao.nomeFornecedor;
      this.model.aquisicao.quantidade = value.aquisicao.quantidade;
      this.model.aquisicao.usado = value.aquisicao.usado;
      this.model.aquisicao.contabValorAquisicao = value.aquisicao.contabValorAquisicao;
      this.model.aquisicao.contabTaxaAmortiza = value.aquisicao.contabTaxaAmortiza;
      this.model.aquisicao.contabNumAnosUteis = value.aquisicao.contabNumAnosUteis;
      this.model.aquisicao.contabPercReducao = value.aquisicao.contabPercReducao;
      this.model.aquisicao.contabDataDispUso = value.aquisicao.contabDataDispUso;
      this.model.aquisicao.contabBaseCalculo = value.aquisicao.contabBaseCalculo;
      this.model.aquisicao.contabMetodoCalculo = value.aquisicao.contabMetodoCalculo;
      this.model.aquisicao.contabValorResidual = value.aquisicao.contabValorResidual;
      this.model.aquisicao.fiscalValorAquisicao = value.aquisicao.fiscalValorAquisicao;
      this.model.aquisicao.fiscalTaxaAmortiza = value.aquisicao.fiscalTaxaAmortiza;
      this.model.aquisicao.fiscalNumAnosUteis = value.aquisicao.fiscalNumAnosUteis;
      this.model.aquisicao.fiscalPercReducao = value.aquisicao.fiscalPercReducao;
      this.model.aquisicao.fiscalDataInicioUtil = value.aquisicao.fiscalDataInicioUtil;
      this.model.aquisicao.fiscalBaseCalculo = value.aquisicao.fiscalBaseCalculo;
      this.model.aquisicao.fiscalMetodoCalculo = value.aquisicao.fiscalMetodoCalculo;
      this.model.aquisicao.fiscalValorResidual = value.aquisicao.fiscalValorResidual;
      this.model.aquisicao.fiscalTribAutonTipo = value.aquisicao.fiscalTribAutonTipo;
      this.model.aquisicao.nContaDoAtivo = value.aquisicao.nContaDoAtivo;
      this.model.aquisicao.nContaAmortAcumu = value.aquisicao.nContaAmortAcumu;
      this.model.aquisicao.nContaAmortExerc = value.aquisicao.nContaAmortExerc;
      this.model.aquisicao.nContaFornecedor = value.aquisicao.nContaFornecedor;
      this.model.aquisicao.nContaIVADedutivel = value.aquisicao.nContaIVADedutivel;
      this.model.aquisicao.iVADeduzido = value.aquisicao.iVADeduzido;
      this.model.aquisicao.codIVAAquisicao = value.aquisicao.codIVAAquisicao;
      this.model.aquisicao.codRubOrcam = value.aquisicao.codRubOrcam;
      this.model.aquisicao.nCCusto = value.aquisicao.nCCusto;
      this.model.aquisicao.nCodRepCC = value.aquisicao.nCodRepCC;
      this.model.aquisicao.nZona = value.aquisicao.nZona;
      this.model.aquisicao.nDepto = value.aquisicao.nDepto;
      this.model.aquisicao.nSubde = value.aquisicao.nSubde;
      this.model.aquisicao.tipoArtigo = value.aquisicao.tipoArtigo;
      this.model.aquisicao.nGrFamilia = value.aquisicao.nGrFamilia;
      this.model.aquisicao.nFamilia = value.aquisicao.nFamilia;
      this.model.aquisicao.nSubfa = value.aquisicao.nSubfa;
      this.model.aquisicao.codContab = value.aquisicao.codContab;
      this.model.aquisicao.classe = value.aquisicao.classe;
      this.model.aquisicao.categoria = value.aquisicao.categoria;
      this.model.aquisicao.nVendedor = value.aquisicao.nVendedor;
      this.model.aquisicao.nRefProcesso = value.aquisicao.nRefProcesso;
      this.model.aquisicao.nSeccao = value.aquisicao.nSeccao;
      this.model.aquisicao.nomeAmortizacao = value.aquisicao.nomeAmortizacao;
      this.model.aquisicao.nomeCCusto = value.aquisicao.nomeCCusto;
      this.model.aquisicao.nomeCategoria = value.aquisicao.nomeCategoria;
      this.model.aquisicao.nomeClasse = value.aquisicao.nomeClasse;
      this.model.aquisicao.nomeContaDoAtivo = value.aquisicao.nomeContaDoAtivo;
      this.model.aquisicao.nomeContaFornecedor = value.aquisicao.nomeContaFornecedor;
      this.model.aquisicao.nomeContab = value.aquisicao.nomeContab;
      this.model.aquisicao.nomeDepto = value.aquisicao.nomeDepto;
      this.model.aquisicao.nomeFamilia = value.aquisicao.nomeFamilia;
      this.model.aquisicao.nomeGrFamilia = value.aquisicao.nomeGrFamilia;
      this.model.aquisicao.nomeMapaAmortizacao = value.aquisicao.nomeMapaAmortizacao;
      this.model.aquisicao.nomeProcesso = value.aquisicao.nomeProcesso;
      this.model.aquisicao.nomeSubDe = value.aquisicao.nomeSubDe;
      this.model.aquisicao.nomeSubFa = value.aquisicao.nomeSubFa;
      this.model.aquisicao.nomeTipoArtigo = value.aquisicao.nomeTipoArtigo;
      this.model.aquisicao.nomeVendedores = value.aquisicao.nomeVendedores;
      this.model.aquisicao.nomeZona = value.aquisicao.nomeZona;
      this.model.aquisicao.nomeSeccao = value.aquisicao.nomeSeccao;
      this.model.aquisicao.nomeRepcc = value.aquisicao.nomeRepcc;
      this.model.aquisicao.nomeContaAmortExerc = value.aquisicao.nomeContaAmortExerc;
      this.model.aquisicao.nomeContaAmortAcumu = value.aquisicao.nomeContaAmortAcumu;
      this.model.aquisicao.nomeContaIva = value.aquisicao.nomeContaIva;
      this.model.aquisicao.nomeCodIva = value.aquisicao.nomeCodIva;
      this.model.aquisicao.planningList = value.aquisicao.planningList;
      this.model.aquisicao.podeEditar = value.aquisicao.podeEditar;
      this.model.aquisicao.podeEditarContas = value.aquisicao.podeEditarContas;
    }
    this.modelChange.emit(this.model);
  }

  private _getFirstDayOfMonth(): Moment {
    return moment().startOf('month');
  }

  private _getCurrentDay(): Moment {
    return moment();
  }

  private _concatValuesFromContaAtivo(value: string | number, name: string): string {
    const concatValue = `${value} - ${name}`;
    return concatValue.toString();
  }

  private _contasPlanoAndOutrosDados(): void {
    const aquisicao = this.model.aquisicao;

    this.canEditContaAmortExerc = false;
    if (!this.ativoContab && this.model.aquisicao.podeEditarContas) {
      this.canEditContaAmortExerc = this.model.depreciacoesLastAno < this._configService.configurations.empresa.anoEmCursoIRC;
    }

    this.contaAmortExercicio = aquisicao.nContaDoAtivo ? this._concatValuesFromContaAtivo(aquisicao.nContaAmortExerc, aquisicao.nomeContaAmortExerc) : '';
    this.contaAmortAcumulado = aquisicao.nContaDoAtivo ? this._concatValuesFromContaAtivo(aquisicao.nContaAmortAcumu, aquisicao.nomeContaAmortAcumu) : '';
    this.contaIvaDedutivel = aquisicao.nContaDoAtivo ? this._concatValuesFromContaAtivo(aquisicao.nContaIVADedutivel, aquisicao.nomeContaIva) : '';
    this.contaIvadeAquisicao = aquisicao.nContaDoAtivo ? this._concatValuesFromContaAtivo(aquisicao.codIVAAquisicao, aquisicao.nomeCodIva) : '';
  }

  private _validateclassificadoresValues(): void {
    if (this.model.aquisicao.nZona === 0) {
      this.model.aquisicao.nZona = undefined;
    }
    if (this.model.aquisicao.nDepto === 0) {
      this.model.aquisicao.nDepto = undefined;
    }
    if (this.model.aquisicao.tipoArtigo === 0) {
      this.model.aquisicao.tipoArtigo = undefined;
    }
    if (this.model.aquisicao.nGrFamilia === 0) {
      this.model.aquisicao.nGrFamilia = undefined;
    }
    if (this.model.aquisicao.nFamilia === 0) {
      this.model.aquisicao.nFamilia = undefined;
    }
    if (this.model.aquisicao.nSubfa === 0) {
      this.model.aquisicao.nSubfa = undefined;
    }
    if (this.model.aquisicao.classe === 0) {
      this.model.aquisicao.classe = undefined;
    }
    if (this.model.aquisicao.categoria === 0) {
      this.model.aquisicao.categoria = undefined;
    }
    if (this.model.aquisicao.nVendedor === 0) {
      this.model.aquisicao.nVendedor = undefined;
    }
  }

  private _validateValorAquisicao(modelValue: number): boolean {
    return !this.contabValorAquisicao || modelValue <= this.contabValorAquisicao;
  }

  private _validateFiscalAquisicao(modelValue: number): boolean {
    return modelValue <= this.model.aquisicao.contabValorAquisicao;
  }
}
