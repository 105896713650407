export enum EPlMediaDevicesGetMediaStreamErrorKind {
  AbortError = 'AbortError',
  NotAllowedError = 'NotAllowedError',
  NotFoundError = 'NotFoundError',
  NotReadableError = 'NotReadableError',
  OverconstrainedError = 'OverconstrainedError',
  SecurityError = 'SecurityError',
  TypeError = 'TypeError',
  TimedOutError = 'TimedOutError',
  UnavailableMediaDevices = 'UnavailableMediaDevices'
}

export interface IPlMediaDevicesSupports {
  camera: boolean;
  microphone: boolean;
  sound: boolean;
}
