import {NgModule} from '@angular/core';
import {PlDelegateEventDownwardsClickDirective} from './downward/delegate.event.downwards.click.directive';
import {PlDelegateEventDownwardsDirective} from './downward/delegate.event.downwards.directive';

export * from './downward/delegate.event.downwards.click.directive.interface';
export * from './downward/delegate.event.downwards.click.directive';
export * from './downward/delegate.event.downwards.directive';

const DECLARATIONS = [
  PlDelegateEventDownwardsClickDirective,
  PlDelegateEventDownwardsDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlDelegateEventModule {
}
