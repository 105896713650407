import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {IJsonClifoAnalyzer, IJsonInconsistenciasContabilidade, IJsonReconstrucaoAcum, IJsonVerificacaoAcum} from '../interfaces/jsonAcumulados.interface';
import {TServiceQueryResponse, TServiceResponse} from './api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeToolsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/contabtools`;
  }

  public reconstruirAcumulados(acumulado: IJsonReconstrucaoAcum): TServiceResponse<IJsonReconstrucaoAcum> {
    return this._apiService.post<IJsonReconstrucaoAcum>({url: `${this._path}/reconstrucaoacum`, body: acumulado});
  }

  public verificarAcumuladosMovAberto(deNConta: string, ateNConta: string): TServiceQueryResponse<IJsonVerificacaoAcum> {
    return this._apiService.query<IJsonVerificacaoAcum>({url: `${this._path}/verificacaoacum/acummovaberto`, params: {deNConta: deNConta, ateNConta: ateNConta}});
  }

  public verificarAcumuladosMovAbertoPeriodo(deNConta: string, ateNConta: string, atePeriodo: string): TServiceQueryResponse<IJsonVerificacaoAcum> {
    return this._apiService.query<IJsonVerificacaoAcum>({
      url: `${this._path}/verificacaoacum/acummovabertoate`,
      params: {deNConta: deNConta, ateNConta: ateNConta, atePeriodo: atePeriodo}
    });
  }

  public verificarAcumuladosComExtratos(deNConta: string, ateNConta: string): TServiceQueryResponse<IJsonVerificacaoAcum> {
    return this._apiService.query<IJsonVerificacaoAcum>({url: `${this._path}/verificacaoacum/acumcomextratos`, params: {deNConta: deNConta, ateNConta: ateNConta}});
  }

  public inconsistenciasContabilidade(): TServiceQueryResponse<IJsonInconsistenciasContabilidade> {
    return this._apiService.query<IJsonInconsistenciasContabilidade>({url: `${this._path}/inconscontabilidade`});
  }

  public consistenciasContaCorrente(deNConta: string, ateNConta: string): TServiceQueryResponse<IJsonClifoAnalyzer> {
    return this._apiService.query<IJsonClifoAnalyzer>({url: `${this._path}/consistcontacorrente`, params: {deNConta: deNConta, ateNConta: ateNConta}});
  }

  public corrigirConsistContaCorrente(consistencias: Array<IJsonClifoAnalyzer>): TServiceResponse<boolean> {
    return this._apiService.post<boolean, Array<IJsonClifoAnalyzer>>({url: `${this._path}/consistcontacorrente/corrigir`, body: consistencias});
  }
}
