import {Component, EventEmitter, Injector, Output} from '@angular/core';
import {IPlEditComponentOptionsInputNumber} from 'pl-comps-angular';
import {DocContabilidadeFieldInputNumber} from '../docContabilidade.field.input.number';
import {DocsContabilidadeService} from '../../../../service/docsContabilidade.service';
import {EDocContabilidadeField} from '../../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../../docsContabilidade.interface';

@Component({
  selector: 'doccontabilidade-valortaxa',
  templateUrl: './docContabilidade.valorTaxa.component.html'
})
export class DocContabilidadeValorTaxaComponent extends DocContabilidadeFieldInputNumber {
  @Output() public readonly evtClosedModal: EventEmitter<void>;

  public allowEditValorTaxa: boolean;

  private _promiseAddValorTaxa: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsContabilidadeService: DocsContabilidadeService
  ) {
    super(EDocContabilidadeField.VALOR_TAXA, _injector);
    this.evtClosedModal = new EventEmitter<void>();
    this.allowEditValorTaxa = false;
  }

  public modelChanged(value: number): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.valorTaxa
    });
  }

  public changeValorTaxa(): void {
    if (this.locked) {
      return;
    }
    this._docsContabilidadeService.podeUltrapassarToleranciaIVA(this.docContabilidade.nDiario).then((result: boolean) => {
      this._docContabilidadeService
        .editValorModal(this.linha, 'valorTaxa', 'docscontabilidade.doc.linhas.valorTaxa', result)
        .then((responseLinha: IDocContabilidadeLinha) => {
          this.modelChanged(responseLinha.valorTaxa);
        })
        .finally(() => {
          this.evtClosedModal.emit();
        });
    });
  }

  public addValorTaxa(value: number): void {
    if (this.locked || this._promiseAddValorTaxa) {
      return;
    }
    const newValue: number = this.linha.valorTaxa + value;
    this._promiseAddValorTaxa = this._docsContabilidadeService
      .validateAddValorTaxa({
        docContabilidadeLinha: this.linha,
        value: newValue,
        alertTitle: 'docscontabilidade.doc.linhas.valorTaxa'
      })
      .then((valid: boolean) => {
        if (valid) {
          this.modelChanged(newValue);
        }
      })
      .finally(() => {
        this.evtClosedModal.emit();
        this._promiseAddValorTaxa = undefined;
      });
  }

  protected _handleLinhaChanged(linha: IDocContabilidadeLinha): void {
    super._handleLinhaChanged(linha);
    this.allowEditValorTaxa = this._docContabilidadeService.evaluateAllowEditValorTaxa(linha);
  }

  protected _generateProperties(linha: IDocContabilidadeLinha): IPlEditComponentOptionsInputNumber {
    const properties: IPlEditComponentOptionsInputNumber = super._generateProperties(linha);
    delete properties.events;
    return properties;
  }

  protected _decimais(): number {
    return this.configurations.contabilidade.decimais.valor;
  }

  protected _disabled(): boolean {
    return true;
  }

  protected _disallowInput(): boolean {
    return false;
  }

  protected _hasGroup(): boolean {
    return this.allowEditValorTaxa;
  }
}
