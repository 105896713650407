import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {DATA_SOURCE_NAME_ESTADOS_VALIDACAO} from '../../datasources/estadosvalidacao/estadosValidacao.datasource.interface';
import {IJsonTesEstados} from './jsonTesEstados.entity';
import {ENTITY_NAME_TESESTADOS} from './tesEstados.entity.interface';

export const ENTITY_TES_ESTADOS: IEntityDefinition<IJsonTesEstados> = {
  name: ENTITY_NAME_TESESTADOS,
  url: ENTITY_NAME_TESESTADOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'tesestados.pesquisa',
  metadata: {
    keyName: 'codEstado',
    fields: [
      {name: 'codEstado', caption: 'tesestados.fields.codEstado', placeholder: 'tesestados.fields.codEstadoPlaceholder', validators: {required: true, min: 1, maxlength: 5}},
      {name: 'descricao', caption: 'tesestados.fields.descricao', placeholder: 'tesestados.fields.descricaoPlaceholder', validators: {required: true, maxlength: 100}},
      {name: 'estadoValidacao', type: DATA_SOURCE_NAME_ESTADOS_VALIDACAO, caption: 'tesestados.fields.estadoValidacao', placeholder: 'tesestados.fields.estadoValidacaoPlaceholder'}
    ],
    order: 'codEstado',
    searchFields: 'codEstado,descricao',
    listFields: 'codEstado,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codEstado}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codEstado,descricao,estadoValidacao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.tesestados'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.tesestados'
      }
    }
  }
};
