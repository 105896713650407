import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DmrfService} from '../dmrf.module.service';
import {EDMRFZona} from '../../../../datasources/dmrfzona/dmrfZona.datasource.interface';
import {IDMRFItem} from '../dmrf.module.interface';
import {EDMRFRubrica} from '../../../../datasources/dmrfrubrica/dmrfRubrica.datasource.interface';

@Component({
  selector: 'dmrf-edit-modal',
  templateUrl: './dmrf.edit.modal.component.html'
})
export class DmrfEditModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public periodo: string;
  @Input() public item: IDMRFItem;

  public modalTitle: string;
  public model: IDMRFItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _dmrfService: DmrfService
  ) {
    super(_injector);
    this.close = this.close.bind(this);
    this.modalTitle = 'dmrf.newModalTitle';
    this.model = {
      dmrfId: '',
      valorRetencao: 0,
      nDocExterno: '',
      nContrib: '',
      codRet: '',
      frestran: false,
      nLanc: '',
      periodo: '',
      rubrica: EDMRFRubrica.R000,
      zona: EDMRFZona.Continente
    };
  }

  public ngOnInit(): void {
    this.model.periodo = this.periodo;
    if (this.item?.dmrfId) {
      this.modalTitle = 'dmrf.editModalTitle';
      this.model = {...this.item};
    }
  }

  public async close(): Promise<void> {
    if (isEmpty(this.model.nContrib)) {
      this._plAlertService.info('dmrf.messages.contribrequired');
      await Promise.reject();
    }
    if (this.model.rubrica === EDMRFRubrica.R000) {
      this._plAlertService.info('dmrf.messages.rubricaRequired');
      await Promise.reject();
    }
    await this._dmrfService.saveRecord(this.model);
    this._plAlertService.success('dmrf.messages.savedSuccess');
    super.close();
  }
}
