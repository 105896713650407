import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonFreguesias} from './JsonFreguesias.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_FREGUESIAS} from './freguesias.entity.interface';

export const ENTITY_FREGUESIAS: IEntityDefinition<IJsonFreguesias> = {
  name: ENTITY_NAME_FREGUESIAS,
  roles: [ROLE.RH],
  searchPlaceholder: 'freguesias.pesquisa',
  metadata: {
    keyName: 'nFreguesia',
    fields: [
      {name: 'nFreguesia', width: '200px', caption: 'freguesias.fields.nFreguesia', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'freguesias.fields.designacaoBreve', validators: {required: true}},
      {name: 'designacaoCompleta', caption: 'freguesias.fields.designacaoCompleta', validators: {required: true}}
    ],
    order: 'nFreguesia',
    searchFields: 'nFreguesia,designacaoBreve,designacaoCompleta'
  },
  autocomplete: {
    rowTemplate: '{{nFreguesia}} - {{designacaoBreve}}',
    output: 'designacaoBreve',
    searchFields: 'nFreguesia,designacaoBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.freguesias'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.freguesias'
      }
    }
  }
};
