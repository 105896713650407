import {ETipoLiquidacao} from '../../datasources/tipoliquidacao/tipoLiquidacao.datasource.interface';
import {ETipoTransmissaoPassiva} from '../../datasources/tipotransmissaopassiva/tipoTransmissaoPassiva.datasource.interface';
import {ETipoTransmissaoActiva} from '../../datasources/tipotransmissaoactiva/tipoTransmissaoActiva.datasource.interface';
import {EDeclaracaoPeriodicaInfo} from './controloIVA.entity.interface';

export enum EControloIvaTipoOperacao {
  NA,
  Activa,
  Passiva,
  RegularizacaoAFavorEstado,
  RegularizacaoAFavorEmpresa
}

export enum EControloIvaTipoValidacaoNif {
  NA,
  ObrigaNifValido,
  ObrigaNIfValidoPermiteCF,
  NaoObrigaNIF
}

export interface IJsonControloIVA {
  codControloIVA: string;
  descAbreviada: string;
  descCompleta: string;
  tipoBem: number;
  tipoTaxa: number;
  tipoOperacao: EControloIvaTipoOperacao;
  tipoLiquidacao: ETipoLiquidacao;
  tipoTransmissaoAct: ETipoTransmissaoActiva;
  tipoTransmissaoPas: ETipoTransmissaoPassiva;
  mercado: number;
  areaRegional: number;
  inversaoSujPassivo: number;
  tipoDeducao: number;
  decPERBASETRIBUT: string;
  decANUBASETRIBUT: string;
  codIVA: number;
  nContaIVADEDLIQREG: string;
  nContaVTNAODEDUT: string;
  nContaIVANAODEDUT: string;
  nContaVTNDEDPROR: string;
  nContaIVANDEDPROR: string;
  nContaVTLIQUIDRCH: string;
  nContaIVALIQUIDRCH: string;
  decPERIVADEDLIQREG: string;
  decPERVTNAODEDUT: string;
  decPERIVANAODEDUT: string;
  decPERVTNDEDPROR: string;
  decPERIVANDEDPROR: string;
  decPERVTLIQUIDRCH: string;
  decPERIVALIQUIDRCH: string;
  decANUIVADEDLIQREG: string;
  decANUVTNAODEDUT: string;
  decANUIVANAODEDUT: string;
  decANUVTNDEDPROR: string;
  decANUIVANDEDPROR: string;
  decANUVTLIQUIDRCH: string;
  decANUIVALIQUIDRCH: string;
  tipoValidacaoNIF: EControloIvaTipoValidacaoNif;
  usaVTNAODEDUTOrigem: boolean;
  usaIVANAODEDUTOrigem: boolean;
  usaVTNDEDPROROrigem: boolean;
  usaIVANDEDPROROrigem: boolean;
  isRegimeIvaCaixa: boolean;
  regularizacoesC40: number;
  regularizacoesC41: number;
  regularizacoesC40RCH: number;
  regularizacoesC41RCH: number;
  codIVANome: string;
  decPERBASETRIBUTNome: string;
  decANUBASETRIBUTNome: string;
  nCONTAIVADEDLIQREGNome: string;
  nCONTAVTNAODEDUTNome: string;
  nCONTAIVANAODEDUTNome: string;
  nCONTAVTNDEDPRORNome: string;
  nCONTAIVANDEDPRORNome: string;
  nCONTAVTLIQUIDRCHNome: string;
  nCONTAIVALIQUIDRCHNome: string;
  nCampoOficial: string;
  taxaAtualIva: number;
  percDedGasoleo: number;
  percDedProRata: number;
  decPERVTLIQUIDRCHNome: string;
  nContaIvaCativo: string;
  nContaIvaCativoNome: string;
  declaracaoPeriodica: EDeclaracaoPeriodicaInfo;
}
