import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {AvisoscobrancaService} from '../../../avisosCobranca.module.service';
import {ReportsRegistryService} from '../../../../../../components/reports/reports.registry.service';
import {EReport} from '../../../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../../../entities/reports/jsonReport.interface';
import {IJsonAvisoDeCobranca} from '../../../jsonAvisosCobranca.module.interface';

@Component({
  selector: 'modal-avisos-cobranca-email',
  templateUrl: './avisosCobranca.email.modal.component.html'
})
export class AvisosCobrancaEmailModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public selectedRowsList: Array<IJsonAvisoDeCobranca>;
  @Input() public subject: string;
  @Input() public body: string;
  @Input() public reportName: string;

  public report: IJsonReport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _avisosCobrancaService: AvisoscobrancaService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public close(): Promise<void> {
    return this._avisosCobrancaService.sendEmailAvisosCobrancaList(this.selectedRowsList, this.subject, this.body, this.report.name).then(() => {
      super.close();
    });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportsRegistryService
      .get(EReport.AvisosCobranca)
      .query()
      .then((reports: Array<IJsonReport>) => {
        for (const report of reports) {
          if (report.name === this.reportName) {
            this.report = report;
          }
        }
      });
  }
}
