<div class="site-standalone-container">
  <h1 [translate]="'empresabloqueada.title'"></h1>

  <div class="alert alert-danger" plAnimationShake [ngSwitch]="!!motivo">
    <div *ngSwitchDefault [innerHTML]="'empresabloqueada.message' | translate"></div>
    <div *ngSwitchCase="true" [innerHTML]="motivo | translate"></div>
  </div>

  <div class="site-standalone-container-actions">
    <button type="button" class="btn btn-light" [click]="fnBack" plPromise><i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span></button>

    <ng-container *ngIf="!partnerMode && !hybridMode">
      <button type="button" class="btn btn-light" [click]="fnMudaEmpresa" plPromise><i class="fa fa-fw fa-institution"></i>&nbsp;<span [translate]="'empresabloqueada.changeCompany'"></span></button>

      <button type="button" class="btn btn-primary" [click]="fnLogin" plPromise><i class="fa fa-fw fa-sign-in"></i>&nbsp;<span [translate]="'global.menu.account.login'"></span></button>
    </ng-container>
  </div>
</div>
