import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';

export interface IJsonDGEMPVencimentoSimulacaoABDES {
  tipoAbono: number;
  codAbDesc: string;
  descricao: string;
  qtd: number;
  valorUnit: number;
  valor: number;
  incidencia: number;
  percentagem: number;
}

export interface IJsonDGEMPReciboVencimentoSimulacao {
  vencimento: number;
  totalIliquido: number;
  totalDesconto: number;
  totalLiquido: number;
  totalEspecie: number;
  totalAPagar: number;
  nomeMoeda: string;
}

export interface IJsonDGEMPVencimentoSimulacao {
  codEmp: number;
  valorLiquidoAObter: number;
  numDias: number;
  valorBrutoVencSimulado: number;
  reciboVencimentoSimulado: IJsonDGEMPReciboVencimentoSimulacao;
  listAbdesc: Array<IJsonDGEMPVencimentoSimulacaoABDES>;
}

export interface IVencimentoSimulacaoStateParams {
  dgemp?: IJsonDGEMP;
}

export const MODULE_NAME_PCA_RH_SIMULACAO_VENCIMENTO = 'simulacaovencimento';
