import {TDate} from '../../../common/dates';

export enum ETipoCalculoDiferimento {
  NotDefined = -1,
  Anual = 0,
  Mensal = 1
}

export enum ETipoDataRefDiferimento {
  NotDefined = -1,
  DataDoDocumento = 0,
  DataDoLancamento = 1
}

export enum ETipoOperacaoDiferimentos {
  NaoDefinido,
  Acrescimo,
  Diferimento,
  Ambos
}

export enum ETipoDiferimento {
  NaoDefinido,
  Rendimento,
  Gasto
}

export interface IJsonCalculoDiferimentos {
  ano: number;
  mes: number;
  valor: number;
  mesContabilistico: number;
  anoContabilistico: number;
  dataDoc: TDate;
  numeroDias: number;
  percReparticaoNDias: number;
}

export interface IJsonCalculoDiferimentosRadicalList {
  radical: string;
  nContaAcrescimos: string;
  nContaDiferimentos: string;
}

export interface IJsonCalculoDiferimentosConfig {
  rendimentosRadicais: Array<IJsonCalculoDiferimentosRadicalList>;
  rendimentosNContaDiferimentos: string;
  nomeRendimentosNContaDif: string;
  rendimentosNContaAcrescimos: string;
  nomeRendimentosNContaAcresc: string;
  rendimentosTipoCalculo: ETipoCalculoDiferimento;
  gastosRadicais: Array<IJsonCalculoDiferimentosRadicalList>;
  gastosNContaDiferimentos: string;
  nomeGastosNContaDif: string;
  gastosNContaAcrescimos: string;
  nomeGastosNContaAcresc: string;
  gastosTipoCalculo: ETipoCalculoDiferimento;
  nDiario: number;
  nomeNDiario: string;
  descritivoAnulacaoInicial: number;
  nomeDescritivoAnulacaoInicial: string;
  descritivo: number;
  nomeDescritivo: string;
  descritivoAcrescimos: number;
  nomeDescritivoAcrescimos: string;
  isEmpresaSNC: boolean;
}

export interface IJsonInfoDiferimentos {
  temAcrescimo: boolean;
  temDiferimento: boolean;
  descricao: string;
  totais: Array<IJsonInfoDiferimentosTotais>;
  detalhes: Array<IJsonInfoDiferimentosDetalhes>;
}

export interface IJsonInfoDiferimentosDetalhes {
  ano: number;
  mesStr: string;
  nDocumento: string;
  nConta: string;
  nomeConta: string;
  valorDebito: number;
  valorCredito: number;
  descricao: string;
  extPocCabID: string;
}

export interface IJsonInfoDiferimentosTotais {
  mes: number;
  mesStr: string;
  ano: number;
  valor: number;
  extPocCabID: string;
  descricao: string;
  ateData: TDate;
  deData: TDate;
  valorDiferimentoNoMes: number;
  valorAcrescimoNoMes: number;
  saldoDiferimentoNoMes: number;
  saldoAcrescimoNoMes: number;
  valorDiferimentoTotal: number;
  valorAcrescimoTotal: number;
  nDocPrinc: string;
  extPocCabIDDocPrinc: string;
}

export interface IJsonInfoGlobalDiferimentosAcrescimosDetail {
  valor: number;
  extPocCabID: string;
  descricao: string;
  nDocInterno: string;
  dC: number; // TDebitoCredito
  periodo: string;
  nConta: string;
  nDiario: number;
  naturezaDoc: number; // TNaturezaDoc
  nomeConta: string;
  mes: number;
  ano: number;
  mesStr: string;
  valorDebito: number;
  valorCredito: number;
  nDocumento: string;
}

export interface IJsonInfoDiferimentosAcrescimos extends IJsonInfoDiferimentosTotais {
  detalhes: Array<IJsonInfoGlobalDiferimentosAcrescimosDetail>;
  docsSaldo: Array<IJsonInfoGlobalDiferimentosAcrescimosDetail>;
}

export interface IJsonInfoGlobalDiferimentosAcrescimos {
  temAcrescimo: boolean;
  temDiferimento: boolean;
  infoList: Array<IJsonInfoDiferimentosAcrescimos>;
}

export interface IJsonMapaDiferimentosAcrescimosMonth {
  extPocCabID: string;
  valor: number;
  ano: number;
}

export interface IJsonMapaDiferimentosAcrescimos {
  extPocCabID: string;
  nDocumento: string;
  descricaoDoc: string;
  nomeConta: string;
  nConta: string;
  dataInicio: TDate;
  dataFim: TDate;
  dias: number;
  valor: number;
  valorAno1N: number;
  valorAnoN: number;
  valorAnoN1: number;
  nDocExterno: string;
  dataDocExterno: TDate;
  nomeDescriti: string;
  docJaneiro: IJsonMapaDiferimentosAcrescimosMonth;
  docFevereiro: IJsonMapaDiferimentosAcrescimosMonth;
  docMarco: IJsonMapaDiferimentosAcrescimosMonth;
  docAbril: IJsonMapaDiferimentosAcrescimosMonth;
  docMaio: IJsonMapaDiferimentosAcrescimosMonth;
  docJunho: IJsonMapaDiferimentosAcrescimosMonth;
  docJulho: IJsonMapaDiferimentosAcrescimosMonth;
  docAgosto: IJsonMapaDiferimentosAcrescimosMonth;
  docSetembro: IJsonMapaDiferimentosAcrescimosMonth;
  docOutubro: IJsonMapaDiferimentosAcrescimosMonth;
  docNovembro: IJsonMapaDiferimentosAcrescimosMonth;
  docDezembro: IJsonMapaDiferimentosAcrescimosMonth;
}

export interface IJsonMapaDiferimentosAcrescimosMonthGeral {
  extPocCabID: string;
  valorDiferimento: number;
  valorAcrescimo: number;
  ano: number;
  mes: number;
}

export interface IJsonMapaDiferimentosAcrescimosGeral {
  extPocCabID: string;
  nDocumento: string;
  descricaoDoc: string;
  nomeConta: string;
  nConta: string;
  dataInicio: TDate;
  dataFim: TDate;
  dias: number;
  valor: number;
  nDocExterno: string;
  dataDocExterno: TDate;
  nomeDescriti: string;
  nContaAcrescimo: string;
  nContaDiferimento: string;
  nomeContaAcrescimo: string;
  nomeContaDiferimento: string;
  diferimentoValorAnoAtual: number;
  acrescimoValorAnoAtual: number;
  diferimentoValorAnoAnterior: number;
  diferimentoValorAnoSeguinte: number;
  acrescimoValorAnoAnterior: number;
  acrescimoValorAnoSeguinte: number;
  valorMeses: Array<IJsonMapaDiferimentosAcrescimosMonthGeral>;
}
