import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonPenhoraDecimais, IJsonPenhoraDescontos} from './jsonRHPenhoras.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHPenhorasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhpenhoras`;
  }

  public getDecimaisPorDefeito(): TServiceResponse<IJsonPenhoraDecimais> {
    return this._apiService.get<IJsonPenhoraDecimais>({url: `${this._path}/decimaispordefeito`});
  }

  public getDescontosPorCategoria(): TServiceResponse<Array<IJsonPenhoraDescontos>> {
    return this._apiService.get<Array<IJsonPenhoraDescontos>>({url: `${this._path}/descontosporcategoria`});
  }
}
