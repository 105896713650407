import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlProgressComponent} from './progress.component';

export * from './progress.component';

const DECLARATIONS = [
  PlProgressComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlProgressModule {
}
