import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_CONTTRABCAB} from './contTrabCab.entity.interface';
import {IJsonContTrabCab} from './jsonContTrabCab.entity.interface';

export const ENTITY_CONTTRABCAB: IEntityDefinition<IJsonContTrabCab> = {
  name: ENTITY_NAME_CONTTRABCAB,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.conttrabcab',
  searchPlaceholder: 'conttrabcab.pesquisa',
  metadata: {
    keyName: 'idContTrabCab',
    fields: [
      {name: 'idContTrabCab', visible: false},
      {name: 'nContrato', type: 'text', caption: 'conttrabcab.fields.nContrato', validators: {required: true}},
      {
        name: 'codEmp',
        type: 'cgsmallint',
        caption: 'conttrabcab.fields.codEmp',
        validators: {required: true},
        visible: false
      },
      {
        name: 'nomeCodEmp',
        caption: 'conttrabcab.fields.codEmp',
        validators: {required: true},
        entity: {name: 'dgemps', keyTarget: 'codEmp', outputTarget: 'nomeCodEmp'}
      },
      {name: 'descricao', type: 'text', caption: 'conttrabcab.fields.descricao', validators: {required: true}},
      {name: 'vigor', type: 'boolean', caption: 'conttrabcab.fields.vigor', properties: {trueValue: '1', falseValue: '0'}},
      {name: 'codEstado', type: 'text', caption: 'conttrabcab.fields.codEstado', readonly: true},
      {name: 'dataCelebracaoContr', type: 'date', caption: 'conttrabcab.fields.dataCelebracaoContr'},
      {
        name: 'nCodBaseLegalContr',
        type: 'cginteger',
        caption: 'conttrabcab.fields.nCodBaseLegalContr'
      },
      {
        name: 'nomeBaseLegalContr',
        caption: 'conttrabcab.fields.nCodBaseLegalContr',
        validators: {required: true},
        entity: {name: 'conttrabbaselegal', keyTarget: 'nCodBaseLegalContr', outputTarget: 'nomeBaseLegalContr'}
      },
      {name: 'actContratada', type: 'text', caption: 'conttrabcab.fields.actContratada'},
      {name: 'retribuicao', type: 'cginteger', caption: 'conttrabcab.fields.retribuicao'},
      {name: 'localTrab', type: 'text', caption: 'conttrabcab.fields.localTrab'},
      {name: 'perioNormalTrab', type: 'text', caption: 'conttrabcab.fields.perioNormalTrab'},
      {name: 'dataInicioTrab', type: 'date', caption: 'conttrabcab.fields.dataInicioTrab'},
      {name: 'termo', type: 'text', caption: 'conttrabcab.fields.termo'},
      {name: 'motivoTermo', type: 'text', caption: 'conttrabcab.fields.motivoTermo'},
      {
        name: 'codContratoMotivo',
        type: 'cginteger',
        caption: 'conttrabcab.fields.codContratoMotivo'
      },
      {
        name: 'nomeMotivo',
        caption: 'conttrabcab.fields.codContratoMotivo',
        entity: {name: 'conttrabmotivo', keyTarget: 'codContratoMotivo', outputTarget: 'nomeMotivo'}
      },
      {name: 'dataCessacao', type: 'date', caption: 'conttrabcab.fields.dataCessacao'},
      {name: 'observacoes', type: 'text', caption: 'conttrabcab.fields.observacoes'}
    ],
    order: 'idContTrabCab',
    searchFields: 'nContrato,descricao',
    listFields:
      'nContrato,codEmp,descricao,vigor,codEstado,dataCelebracaoContr,nCodBaseLegalContr,actContratada,retribuicao,' +
      'localTrab,perioNormalTrab,dataInicioTrab,termo,motivoTermo,codContratoMotivo,dataCessacao,observacoes'
  },
  autocomplete: {
    rowTemplate: '{{nContrato}} - {{descricao}}',
    output: '{{nContrato}} - {{descricao}}',
    outputDescription: 'descricao',
    searchFields: 'nContrato,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    template: {
      items: [
        {type: 'field', field: 'nContrato', order: 1},
        {type: 'field', field: 'nomeCodEmp', order: 2},
        {type: 'field', field: 'descricao', order: 3},
        {type: 'group', order: 4, fields: [{field: 'nomeBaseLegalContr'}, {field: 'codEstado'}]},
        {type: 'group', order: 5, fields: [{field: 'vigor'}, {field: 'dataCelebracaoContr'}]},
        {type: 'group', order: 6, fields: [{field: 'dataInicioTrab'}, {field: 'dataCessacao'}]},
        {type: 'group', order: 7, fields: [{field: 'actContratada'}, {field: 'retribuicao'}]},
        {type: 'group', order: 8, fields: [{field: 'localTrab'}, {field: 'perioNormalTrab'}]},
        {type: 'group', order: 9, fields: [{field: 'termo'}, {field: 'motivoTermo'}]},
        {type: 'group', order: 10, fields: [{field: 'nomeMotivo'}, {field: 'observacoes'}]}
      ],
      addMissingFields: false
    }
  }
};
