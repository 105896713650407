import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment, {Moment} from 'moment';
import DxTreeList, {CellClickEvent, CellPreparedEvent, Column, ContextMenuPreparingEvent, InitializedEvent, Node} from 'devextreme/ui/tree_list';
import {
  EAnalisadorTesLancoForeignOrigin,
  EAnalisadorTesPeriodicidadeAnalise,
  EAnalisadorTesRecordItemType,
  ETesRubricaReceitaDespesa,
  IAnalisadorTesCellSelectionItem,
  IAnalisadorTesDateInterval,
  IAnalisadorTesLanco,
  IAnalisadorTesLancoUpdateByNConta,
  IAnalisadorTesMultipleTesLancoItem,
  IAnalisadorTesRecibosPagamParameters,
  IAnalisadorTesRecord,
  IAnalisadorTesRecordData,
  IAnalisadorTesUIModel,
  IJsonAnalisadorTesFACCBInfo,
  IJsonAnalisadorTesValoresAntItem
} from '../analisadorTes.module.interface';
import {AnalisadorTesService} from '../analisadorTes.module.service';
import {monthName, TDate} from '../../../../../common/dates';
import {downloadStream, EWeekDay, IPlToolbarItem, isDefinedNotNull, isEmpty, isUndefinedOrNull, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {merge} from 'lodash-es';
import {AnalisadorTesProgressModalComponent} from '../modals/progress/analisadorTes.progress.modal';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {AnalisadorTesConfigModalComponent} from '../modals/config/analisadorTes.config.modal.component';
import {AnalisadorTesTeslancoModalComponent} from '../modals/teslanco/analisadorTes.tesLanco.modal.component';
import {MODULE_NAME_PREVI_TES, MODULE_NAME_PREVI_TES_AUTO} from '../../prevites/previTes.module.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import DataSource from 'devextreme/data/data_source';
import {EMPTY_GUID} from '../../../../../config/constants';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {ENTITY_NAME_TES_RUBRICA} from '../../../../entities/tesrubrica/tesRubrica.entity.interface';
import {EEntityMaintenanceEditMode, IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {MODULE_NAME_EXTRATOS_DT} from '../../../portalcontabilidade/estatistica/extratosdt/extratosDT.module.interface';
import {ENTITY_NAME_RECIBOS} from '../../../../entities/recibos/recibos.entity.interface';
import {ENTITY_NAME_PAGAMENTOS} from '../../../../entities/pagamentos/pagamentos.entity.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {AnalisadorTesValoresAnterioresModalComponent} from '../modals/valoresAnteriores/analisadorTes.valoresAnteriores.modal';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {docsComerciaisGrupoDocToEntityName} from '../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {Workbook, Worksheet} from 'exceljs';
import {AnalisadorTesExcelExporter} from '../export/analisadorTes.excelExporter';
import DevExpress from 'devextreme';
import ColumnCustomizeTextArg = DevExpress.common.grids.ColumnCustomizeTextArg;

const DEFAULT_DAY_INTERVAL = 14;
const PROP_NAME_DATE_FORMAT = 'DDMMYYYY';
const DYNAMIC_RIGHT_COL_NAME = 'MY_DYNAMIC_COL';
const SALDO_FINAL_NODE_ID = 'SALDO_FINAL_NODE_ID';
const DX_TREE_COL_MIN_WIDTH = 60;
const DX_TREE_CELL_HIGHLIGHT_CSS_CLASS = 'yellow-legend-color';
const CELL_CSS_CLASS_WEEKEND = 'day-weekend';
const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'analisadorTes',
  templateUrl: './analisadorTes.module.component.html'
})
export class AnalisadorTesComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly nomeRubricaDataField: string = 'nomeRubrica';
  public readonly descricaoRubricaDataField: string = 'descicaoRubrica';
  public readonly dataSource: DataSource;
  public readonly placeholderColName: string = 'placeholderColumn';
  public model: IAnalisadorTesUIModel;
  public perioAnaliseSource: Array<{value: number; name: string}>;
  public promiseUpdateSaldoInicial: Promise<void>;
  public isFirstTime: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public exportButtonOptions: any;

  private readonly _decimais: number;
  private readonly _btnMainConfigToolbarItem: IPlToolbarItem;
  private readonly _btnMainSyncToolbarItem: IPlToolbarItem;
  private readonly _btnMainPerioToolbarItem: IPlToolbarItem<EAnalisadorTesPeriodicidadeAnalise>;
  private readonly _btnMainPrevTesRubricaToolbarItem: IPlToolbarItem;
  private readonly _btnMainPreviTesToolbarItem: IPlToolbarItem;
  private readonly _btnMainPreviTesAutoToolbarItem: IPlToolbarItem;
  private readonly _btnDropdownActionsNew: IPlToolbarItem;
  private readonly _maintenanceExtratosConta: IModuleMaintenanceInstance;
  private _tree: DxTreeList;
  private _isMustRenderCols: boolean;
  private _isExpandAll: boolean;
  private _columnValues: Array<IAnalisadorTesDateInterval> = [];
  private _dynamicColumns: Array<Column>;
  private _moduleMaintenancePreviTesInstance: IModuleMaintenanceInstance;
  private _moduleMaintenancePreviTesAutoInstance: IModuleMaintenanceInstance;
  private _entityMaintenancePrevTesRubricaInstance: IEntityMaintenanceInstance;
  private _entityMaintenanceRecibosInstance: IEntityMaintenanceInstance;
  private _entityMaintenancePagamentosInstance: IEntityMaintenanceInstance;
  private _multiCellSelection: Array<IAnalisadorTesCellSelectionItem>;
  private _saldoInicialRow: IAnalisadorTesRecord;
  private _cardPanel: CGCardPanelComponent;
  private _getAndUseSaldoInicial: boolean;
  private _maintenanceDocsContabilidade: IEntityMaintenanceInstance;
  private _maintenanceDocsComerciais: Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _analisadorTesService: AnalisadorTesService,
    private readonly _plI18nService: PlI18nService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._columnValues = [];
    this._dynamicColumns = [];
    this._decimais = this._configService.configurations.contabilidade.decimais.valor;
    this._isExpandAll = false;
    this._multiCellSelection = [];
    this._saldoInicialRow = undefined;
    this.perioAnaliseSource = [
      {value: EAnalisadorTesPeriodicidadeAnalise.DIARIA, name: 'Diária'},
      {value: EAnalisadorTesPeriodicidadeAnalise.SEMANAL, name: 'Semanal'},
      {value: EAnalisadorTesPeriodicidadeAnalise.MENSAL, name: 'Mensal'}
    ];
    this.model = {
      saldoInicial: 0,
      fromDate: moment(),
      toDate: moment().add(DEFAULT_DAY_INTERVAL, 'days'),
      periodoAnalise: EAnalisadorTesPeriodicidadeAnalise.DIARIA
    };
    this.isFirstTime = true;
    this._isMustRenderCols = true;
    this._getAndUseSaldoInicial = true;
    this._maintenanceExtratosConta = this._moduleMaintenanceService.build(MODULE_NAME_EXTRATOS_DT);
    this.dataSource = new DataSource({
      load: (loadOptions) => {
        if (this.isFirstTime) {
          return Promise.resolve([]);
        }

        let promise: TServiceResponse<Array<IAnalisadorTesRecord>>;
        let parentIds: Array<string> = ['-1'];
        if (this._isExpandAll) {
          promise = this._analisadorTesService.getFullData(this.model.fromDate, this.model.toDate, this.model.saldoInicial);
        } else {
          parentIds = loadOptions.parentIds?.length ? loadOptions.parentIds : ['-1'];

          promise = this._analisadorTesService.getTreeRowData(parentIds, this.model.fromDate, this.model.toDate, this.model.saldoInicial);
        }

        return new Promise((resolve, reject) => {
          promise
            .then((response) => {
              const list: Array<IAnalisadorTesRecord> = response.body;
              if (this._isMustRenderCols) {
                this._columnValues = this._renderColumns();
              } else {
                this._resetColumnValues();
              }
              this._prepareSource(list);

              if (parentIds.length && parentIds[0] === '-1') {
                this._renderSaldoFinalRow(list);
              }

              const indexSaldoInicial = list.findIndex((item) => {
                return item.itemType === EAnalisadorTesRecordItemType.TOP && item.groupId === -1 && item.nodeId !== SALDO_FINAL_NODE_ID;
              });

              if (indexSaldoInicial > -1) {
                this._saldoInicialRow = list.splice(indexSaldoInicial, 1)[0];
              }

              resolve(list);
            })
            .catch(reject);
        });
      }
    });

    this._btnMainPerioToolbarItem = {
      id: 'mainToolbar-perio-btn',
      type: 'dropdown',
      order: 3,
      caption: this._generatePeriodoAnaliseCaption(),
      class: 'btn-primary',
      menu: [
        {
          caption: 'analisadorTes.strings.diaria',
          data: EAnalisadorTesPeriodicidadeAnalise.DIARIA,
          click: (perioAnaliseItem: IPlToolbarItem<EAnalisadorTesPeriodicidadeAnalise>) => {
            this.model.periodoAnalise = perioAnaliseItem.data;
            this._perioAnaliseChange();
            this._btnMainPerioToolbarItem.caption = this._generatePeriodoAnaliseCaption();
          }
        },
        {
          caption: 'analisadorTes.strings.semanal',
          data: EAnalisadorTesPeriodicidadeAnalise.SEMANAL,
          click: (perioAnaliseItem: IPlToolbarItem<EAnalisadorTesPeriodicidadeAnalise>) => {
            this.model.periodoAnalise = perioAnaliseItem.data;
            this._perioAnaliseChange();
            this._btnMainPerioToolbarItem.caption = this._generatePeriodoAnaliseCaption();
          }
        },
        {
          caption: 'analisadorTes.strings.mensal',
          data: EAnalisadorTesPeriodicidadeAnalise.MENSAL,
          click: (perioAnaliseItem: IPlToolbarItem<EAnalisadorTesPeriodicidadeAnalise>) => {
            this.model.periodoAnalise = perioAnaliseItem.data;
            this._perioAnaliseChange();
            this._btnMainPerioToolbarItem.caption = this._generatePeriodoAnaliseCaption();
          }
        }
      ]
    };
    this._btnMainPreviTesToolbarItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'mainToolbar-prev-tes-manual',
      type: 'button',
      order: this._btnMainPerioToolbarItem.order + 1,
      class: 'btn-success',
      iconLeft: '<i class="fa fa-plus fa-fw"></i>&nbsp;',
      caption: 'analisadorTes.btns.toolbarPreviTes',
      click: () => this._openPreviTes()
    };
    this._btnMainPreviTesAutoToolbarItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'mainToolbar-prev-tes-auto',
      type: 'button',
      order: this._btnMainPreviTesToolbarItem.order + 1,
      class: 'btn-success',
      iconLeft: '<i class="fa fa-plus fa-fw"></i>&nbsp;',
      caption: 'analisadorTes.btns.toolbarPreviTesAuto',
      click: () => this._openPreviTesAuto()
    };
    this._btnMainPrevTesRubricaToolbarItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'mainToolbar-criar-tes-rubrica',
      type: 'button',
      order: this._btnMainPreviTesAutoToolbarItem.order + 1,
      class: 'btn-success',
      iconLeft: '<i class="fa fa-plus fa-fw"></i>&nbsp;',
      caption: 'analisadorTes.btns.criarTesRubricas',
      click: () => this._openPrevTesRubrica()
    };

    this._btnMainSyncToolbarItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'mainToolbar-sync-btn',
      type: 'button',
      order: this._btnMainPrevTesRubricaToolbarItem.order + 1,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>&nbsp;',
      caption: 'analisadorTes.btns.syncToolbar',
      click: () => this._syncData()
    };
    this._btnMainConfigToolbarItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'mainToolbar-config-btn',
      type: 'button',
      order: this._btnMainSyncToolbarItem.order + 1,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>&nbsp;',
      caption: 'analisadorTes.btns.toolbarConfig',
      click: () => {
        this._showConfigModal().then(async (result) => {
          if (this._cardPanel.collapsed) {
            this._cardPanel.toggleCollapse();
          }
          this._getAndUseSaldoInicial = true;
          if (result === true) {
            await this.updateSaldoInicial();
            await this._loadData();
          }
        });
      }
    };
    this._btnDropdownActionsNew = {
      id: 'btnAcoesNew',
      order: this._btnMainPerioToolbarItem.order - 1,
      caption: 'global.btn.new',
      iconLeft: '<i class="fa fa-fw fa-bars"></i>',
      type: 'dropdown',
      class: 'btn-success',
      menu: [this._btnMainPreviTesToolbarItem, this._btnMainPreviTesAutoToolbarItem, this._btnMainPrevTesRubricaToolbarItem]
    };

    this.exportButtonOptions = {
      icon: 'xlsxfile',
      onClick: this._exportToExcel.bind(this)
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.dropdownActions.menu = [this._btnMainSyncToolbarItem, this._btnMainConfigToolbarItem];
    this._buildToolbarResponsive(this.isMobile);
    this.toolbar.addButton(this._btnDropdownActionsNew).addButton(this._btnMainPerioToolbarItem);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._analisadorTesService.stopMS();
    this.dataSource.dispose();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public hasItemsExprFn(rowData: IAnalisadorTesRecord): boolean {
    return rowData.itemType !== EAnalisadorTesRecordItemType.RECORD && rowData.groupId !== -1;
  }

  public onInitialized({component}: InitializedEvent): void {
    this._tree = component;
  }

  public onCellPreparedEvent($event: CellPreparedEvent<IAnalisadorTesRecord>): void {
    if ($event.rowType === 'data') {
      if ($event.column.dataField !== this.nomeRubricaDataField && $event.column.dataField !== this.descricaoRubricaDataField) {
        if ($event.data.groupId === -1) {
          $event.cellElement.classList.add('fw-bold');
        } else if ($event.data.receitaDespesa === ETesRubricaReceitaDespesa.DESPESA) {
          $event.cellElement.classList.add('text-danger');
        } else {
          $event.cellElement.classList.add('text-success');
        }

        if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
          if (this._isWeekEnd($event.column.dataField)) {
            $event.cellElement.classList.add(CELL_CSS_CLASS_WEEKEND);
          }
        }
      } else if ($event.data.groupId === -1) {
        $event.cellElement.classList.add('fw-bold');
      }
    }
  }

  public fromDateChanged(value: TDate): void {
    this.model.fromDate = this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.MENSAL ? moment(value).startOf('month') : moment(value);
    this._isMustRenderCols = true;
  }

  public toDateChanged(value: TDate): void {
    this.model.toDate = this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.MENSAL ? moment(value).endOf('month') : moment(value);
    this._isMustRenderCols = true;
  }

  public addContextMenuItems($event: ContextMenuPreparingEvent<IAnalisadorTesRecord>): void {
    if ($event.target === 'content') {
      if (!$event.items) {
        $event.items = [];
      }

      $event.items.push({
        icon: 'fa fa-plus-square-o',
        text: this._translateService.instant('analisadorTes.btns.expand'),
        onItemClick: () => {
          this._tree.expandRow($event.row.key);
        }
      });

      $event.items.push({
        icon: 'fa fa-minus-square-o',
        text: this._translateService.instant('analisadorTes.btns.collapse'),
        onItemClick: () => {
          this._tree.collapseRow($event.row.key);
        }
      });

      $event.items.push({
        icon: 'fa fa-plus-square-o',
        text: this._translateService.instant('analisadorTes.btns.expandAll'),
        beginGroup: true,
        onItemClick: () => {
          this._isExpandAll = true;
          this._tree.refresh().then(() => {
            const keys = this._getNodeKeys(this._tree.getRootNode());
            this._tree.beginUpdate();
            keys.forEach((key) => {
              this._tree.expandRow(key);
            });
            this._tree.endUpdate();
            this._isExpandAll = false;
          });
        }
      });

      $event.items.push({
        icon: 'fa fa-minus-square-o',
        text: this._translateService.instant('analisadorTes.btns.collapseAll'),
        onItemClick: () => {
          const keys: Array<string> = this._getNodeKeys(this._tree.getRootNode());
          this._tree.beginUpdate();
          keys.forEach((key: string) => {
            this._tree.collapseRow(key);
          });
          this._tree.endUpdate();
        }
      });

      if ($event.rowIndex === -1) {
        return;
      }

      if ($event.row.data.itemType === EAnalisadorTesRecordItemType.RUBRICA) {
        $event.items.push({
          beginGroup: true,
          icon: 'fa fa-plus fa-fw',
          text:
            $event.row.data.receitaDespesa === ETesRubricaReceitaDespesa.DESPESA
              ? this._translateService.instant('analisadorTes.btns.pagamento')
              : this._translateService.instant('analisadorTes.btns.recebimento'),
          onItemClick: () => {
            if ($event.row.data.receitaDespesa === ETesRubricaReceitaDespesa.RECEITA) {
              return this._openRecebimentos(undefined);
            }
            return this._openPagamentos(undefined);
          }
        });
        $event.items.push({
          icon: 'fa fa-pencil',
          text: this._translateService.instant('analisadorTes.btns.editarRubrica'),
          onItemClick: () => {
            this._openEditPrevTesRubrica($event.row.data.tesRubricaId);
          }
        });
      } else if ($event.row.data.itemType === EAnalisadorTesRecordItemType.CLIENT) {
        $event.items.push({
          beginGroup: true,
          icon: 'fa fa-plus fa-fw text-success',
          text:
            $event.row.data.receitaDespesa === ETesRubricaReceitaDespesa.DESPESA
              ? this._translateService.instant('analisadorTes.btns.pagamento')
              : this._translateService.instant('analisadorTes.btns.recebimento'),
          onItemClick: () => {
            if ($event.row.data.receitaDespesa === ETesRubricaReceitaDespesa.RECEITA) {
              return this._openRecebimentos({
                nConta: $event.row.data.nomeRubrica,
                nome: $event.row.data.descicaoRubrica
              });
            }
            return this._openPagamentos({
              nConta: $event.row.data.nomeRubrica,
              nome: $event.row.data.descicaoRubrica
            });
          }
        });
        $event.items.push({
          beginGroup: true,

          icon: 'fa fa-times fa-fw text-danger',
          text: this._translateService.instant('analisadorTes.btns.removerTodosDocumentosDaConta'),
          onItemClick: async () => {
            const msg = this._translateService.instant('analisadorTes.messages.temACertezaQueQuerEliminarTodosNConta', {nConta: $event.row.data.nomeRubrica});
            await this._cgModalService.showOkCancel('global.text.confirmation', msg);
            await this._analisadorTesService.deleteTesLancoByNConta($event.row.data.nomeRubrica);
            await this._tree.refresh();
          }
        });
      }

      if (this._multiCellSelection.length > 1) {
        $event.items.push({
          beginGroup: true,
          icon: 'fa fa-pencil text-primary',
          text: this._translateService.instant('analisadorTes.btns.alterarDataTesouraria'),
          onItemClick: async () => {
            const dummyModel: IAnalisadorTesLanco = {
              data: moment(),
              nota: '',
              foreignOrigin: EAnalisadorTesLancoForeignOrigin.None,
              nLanc: EMPTY_GUID,
              tesLancoID: EMPTY_GUID,
              foreignKeyID: '',
              tesRubricaID: $event.row.data.tesRubricaId,
              valor: 0,
              descricao: '',
              notaLastUpd: undefined
            };
            const newModel: IAnalisadorTesLanco = await this._showEditTesLancoModal(dummyModel, false);
            const data: Array<IAnalisadorTesMultipleTesLancoItem> = [];
            this._multiCellSelection.forEach((cellInfo: IAnalisadorTesCellSelectionItem) => {
              data.push({
                tesLancoID: cellInfo.cellData.tesLancoID,
                data: {
                  nota: newModel.nota,
                  toDate: newModel.data
                }
              });
            });
            await this._analisadorTesService.updateMultipleTesLanco(data);
            await this._tree.refresh();
          }
        });

        $event.items.push({
          icon: 'fa fa-trash-o text-danger',
          text: this._translateService.instant('analisadorTes.btns.removerDocumentoTesouraria'),
          onItemClick: async () => {
            await this._promptDeleteTesLancoModal();
            const list: Array<string> = this._multiCellSelection.map((cellInfo: IAnalisadorTesCellSelectionItem) => {
              return cellInfo.cellData.tesLancoID;
            });
            await this._analisadorTesService.deleteMultipleTesLanco(list);
            await this._tree.refresh();
          }
        });
      } else if ($event.row.data.itemType === EAnalisadorTesRecordItemType.RECORD || $event.row.data.itemType === EAnalisadorTesRecordItemType.CLIENT) {
        if ($event.row.data.itemType === EAnalisadorTesRecordItemType.CLIENT) {
          $event.items.push({
            icon: 'fa fa-list-alt',
            text: this._translateService.instant('analisadorTes.btns.extratoConta'),
            beginGroup: true,
            onItemClick: () => {
              return this._maintenanceExtratosConta.maintenance({params: {nConta: $event.row.data.nomeRubrica}});
            }
          });
        }

        const cellValue: IAnalisadorTesRecordData = this._getFirstCellValue($event.row.data, $event.column.dataField);
        if (isDefinedNotNull(cellValue)) {
          if ($event.row.data.itemType === EAnalisadorTesRecordItemType.RECORD) {
            $event.items.push({
              icon: 'fa fa-pencil text-primary',
              text: this._translateService.instant('analisadorTes.btns.editarPrevisaoRecebimento'),
              beginGroup: true,
              onItemClick: async () => {
                const response: HttpResponse<IAnalisadorTesLanco> = await this._analisadorTesService.getTesLanco(cellValue.tesLancoID);
                const newModel: IAnalisadorTesLanco = await this._showEditTesLancoModal(response.body, true);
                await this._analisadorTesService.updateTesLanco(cellValue.tesLancoID, newModel);
                await this._tree.refresh();
              }
            });
            $event.items.push({
              icon: 'fa fa-trash-o text-danger',
              text: this._translateService.instant('analisadorTes.btns.removerDocumentoTesouraria'),
              onItemClick: () => {
                return this._showDeleteTesLancoModal(cellValue.tesLancoID);
              }
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const verDocumentoContabMenuItem: any = {
              icon: 'fa fa-eye',
              disabled: true,
              text: this._translateService.instant('analisadorTes.btns.verDocumentoContabilidade')
            };
            $event.items.push(verDocumentoContabMenuItem);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const verDocumentoGestComMenuItem: any = {
              icon: 'fa fa-eye',
              disabled: true,
              text: this._translateService.instant('analisadorTes.btns.verDocumentoGestComercial')
            };
            $event.items.push(verDocumentoGestComMenuItem);

            this._analisadorTesService.getExtPocCabIDByTesLancoId(cellValue.tesLancoID).then((response: HttpResponse<string>) => {
              if (!isEmpty(response.body)) {
                verDocumentoContabMenuItem.disabled = false;
                verDocumentoContabMenuItem.onItemClick = () => {
                  return this._showDocumentoContabilidadeModal(response.body);
                };
              }
            });

            this._analisadorTesService.getFaccbInfoByTesLancoId(cellValue.tesLancoID).then((response: HttpResponse<IJsonAnalisadorTesFACCBInfo>) => {
              if (response.body.faccbId > 0 && response.body.docfaGrupoId > 0) {
                verDocumentoGestComMenuItem.disabled = false;
                verDocumentoGestComMenuItem.onItemClick = () => {
                  return this._showDocumentoGestaoComercialModal(response.body.faccbId, response.body.docfaGrupoId);
                };
              }
            });
          } else if ($event.row.data.itemType === EAnalisadorTesRecordItemType.CLIENT) {
            $event.items.push({
              icon: 'fa fa-pencil',
              text: this._translateService.instant('analisadorTes.btns.alterarDataTesouraria'),
              beginGroup: true,
              onItemClick: async () => {
                const dummyModel: IAnalisadorTesLanco = {
                  data: cellValue.data,
                  nota: '',
                  foreignOrigin: EAnalisadorTesLancoForeignOrigin.None,
                  nLanc: EMPTY_GUID,
                  tesLancoID: EMPTY_GUID,
                  foreignKeyID: '',
                  tesRubricaID: $event.row.data.tesRubricaId,
                  valor: 0,
                  descricao: '',
                  notaLastUpd: undefined
                };

                const newModel: IAnalisadorTesLanco = await this._showEditTesLancoModal(dummyModel, false);
                const uniqueDates: Array<string> = [...new Set(this._getCellValues($event.row.data, $event.column.dataField).map((item: IAnalisadorTesRecordData) => item.data))];
                const data: IAnalisadorTesLancoUpdateByNConta = {
                  fromDate: uniqueDates,
                  nota: newModel.nota,
                  toDate: newModel.data
                };
                await this._analisadorTesService.updateTesLancoByNConta($event.row.data.tesRubricaId, $event.row.data.nomeRubrica, data);
                await this._tree.refresh();
              }
            });
          }
        }
      }
    }
  }

  public updateSaldoInicial(): Promise<void> {
    this.promiseUpdateSaldoInicial = this._analisadorTesService.getSaldoInicial(this.model.fromDate).then((response: HttpResponse<number>) => {
      this.model.saldoInicial = response.body;
    });
    return this.promiseUpdateSaldoInicial;
  }

  public saldoInicialChange(value: number): void {
    this._getAndUseSaldoInicial = false;
    this.model.saldoInicial = value || 0;
  }

  public onCellClick(event: CellClickEvent<IAnalisadorTesRecord>): void {
    if (event.rowType === 'data' && event.column.ownerBand !== 0) {
      const ctrlKey: boolean = event.event.ctrlKey;
      const cellValue: IAnalisadorTesRecordData = this._getFirstCellValue(event.row.data, event.column.dataField);
      if (isDefinedNotNull(cellValue) && cellValue.tesLancoID.length && cellValue.tesLancoID !== EMPTY_GUID) {
        const cellSelectionItem: IAnalisadorTesCellSelectionItem = {
          cellData: cellValue,
          model: event.row.data
        };
        if (ctrlKey) {
          if (event.cellElement.classList.contains(DX_TREE_CELL_HIGHLIGHT_CSS_CLASS)) {
            this._multiCellSelection = this._multiCellSelection.filter((item: IAnalisadorTesCellSelectionItem) => {
              return item.cellData.tesLancoID !== cellSelectionItem.cellData.tesLancoID;
            });
            event.cellElement.classList.remove(DX_TREE_CELL_HIGHLIGHT_CSS_CLASS);
          } else {
            this._multiCellSelection.push(cellSelectionItem);
            event.cellElement.classList.add(DX_TREE_CELL_HIGHLIGHT_CSS_CLASS);
          }
        } else {
          this._clearAllCellHighlight();
          this._multiCellSelection = [cellSelectionItem];
          event.cellElement.classList.add(DX_TREE_CELL_HIGHLIGHT_CSS_CLASS);
        }
      } else {
        this._clearAllCellHighlight();
        this._multiCellSelection = [];
      }
    }
  }

  public getColHeaderSaldoInicialValue(column: Column): string {
    if (isUndefinedOrNull(this._saldoInicialRow) || isUndefinedOrNull(this._saldoInicialRow[column.dataField])) {
      return '';
    }
    return this._plI18nService.formatNumber(String(this._saldoInicialRow[column.dataField]), this._decimais);
  }

  public readonly fnPesquisar = (): Promise<void> => this._loadData();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._analisadorTesService.stopMSUrl());
  }

  private async _loadData(): Promise<void> {
    if (this.isFirstTime || this._getAndUseSaldoInicial) {
      const responseSaldoInicial: HttpResponse<number> = await this._analisadorTesService.getSaldoInicial(this.model.fromDate);
      this.model.saldoInicial = responseSaldoInicial.body;
      await this._syncData();
    }
    this.isFirstTime = false;
    const responseValoresAnteriores: HttpResponse<Array<IJsonAnalisadorTesValoresAntItem>> = await this._analisadorTesService.getTesLancoValoresAntList(this.model.fromDate);

    if (responseValoresAnteriores.body.length) {
      await new Promise((resolve) => {
        this._showValoresAnterioresModal(responseValoresAnteriores.body).finally(() => {
          setTimeout(resolve, 0);
        });
      });
    }

    await this._tree.refresh().then(() => {
      this._cardPanel.collapse();
    });
  }

  private _clearAllCellHighlight(): void {
    this._tree
      .element()
      .querySelectorAll(`.${DX_TREE_CELL_HIGHLIGHT_CSS_CLASS}`)
      .forEach((cellElement) => {
        cellElement.classList.remove(DX_TREE_CELL_HIGHLIGHT_CSS_CLASS);
      });
  }

  private _syncData(): Promise<void | HttpResponse<void>> {
    const modalInstance = this._showProgressModal(this._translateService.instant('analisadorTes.messages.syncDados'));
    return this._analisadorTesService
      .synchronizeData()
      .catch((reason) => {
        this._handleRequestError(reason);
      })
      .finally(() => {
        modalInstance.close();
      });
  }

  private _renderColumns(): Array<IAnalisadorTesDateInterval> {
    this._tree.deleteColumn(this.placeholderColName);

    if (this._dynamicColumns.length) {
      this._dynamicColumns.forEach((column) => {
        this._tree.deleteColumn(column.name);
      });
      this._dynamicColumns = [];
    }

    let myBeginDate: Moment = moment(this.model.fromDate).startOf('day');
    let myColBand: Column;
    let lastMonth = -1;
    let lastYear = -1;
    let dayWeekStart: Moment;
    let dayWeekEnd: Moment;

    const results: Array<IAnalisadorTesDateInterval> = [];
    while (myBeginDate <= this.model.toDate) {
      const myMonth: number = myBeginDate.month();
      const myYear: number = myBeginDate.year();

      if (this.model.periodoAnalise in [EAnalisadorTesPeriodicidadeAnalise.DIARIA, EAnalisadorTesPeriodicidadeAnalise.SEMANAL]) {
        if (myMonth !== lastMonth) {
          if (isDefinedNotNull(myColBand)) {
            this._dynamicColumns.push(myColBand);
            this._tree.addColumn(myColBand);
          }

          myColBand = {
            name: DYNAMIC_RIGHT_COL_NAME + myMonth + myYear,
            caption: `${monthName(myMonth)} / ${myBeginDate.year()}`,
            alignment: 'center',
            columns: []
          };
        }
      } else if (myYear !== lastYear) {
        if (isDefinedNotNull(myColBand)) {
          this._dynamicColumns.push(myColBand);
          this._tree.addColumn(myColBand);
        }
        myColBand = {
          name: DYNAMIC_RIGHT_COL_NAME + myYear,
          caption: `${myYear}`,
          alignment: 'center',
          columns: []
        };
        lastYear = myYear;
      }

      if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
        const dataField: string = myBeginDate.format(PROP_NAME_DATE_FORMAT);
        const col = this._createColumn('', dataField, {
          headerCellTemplate: 'colHeaderSaldoInicial',
          alignment: 'right',
          cssClass: this._isWeekEnd(dataField) ? CELL_CSS_CLASS_WEEKEND : ''
        });
        const columnDate: Column = {
          caption: myBeginDate.date().toString(),
          alignment: 'center',
          cssClass: this._isWeekEnd(dataField) ? CELL_CSS_CLASS_WEEKEND : '',
          columns: [col]
        };
        myColBand.columns.push(columnDate);
        results.push({fromDate: myBeginDate.clone(), toDate: myBeginDate.clone(), value: undefined});
        myBeginDate = myBeginDate.add(1, 'days');
      } else if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.SEMANAL) {
        const dayWeek: number = myBeginDate.day();
        if (dayWeek === EWeekDay.Monday) {
          dayWeekStart = myBeginDate.clone();
        } else if (dayWeek === EWeekDay.Friday) {
          dayWeekEnd = myBeginDate.clone();
          if (dayWeekStart === undefined && dayWeek > EWeekDay.Monday && dayWeek < EWeekDay.Saturday) {
            dayWeekStart = myBeginDate.clone();
          }
        }

        if (dayWeekStart !== undefined && dayWeekEnd !== undefined) {
          const dataField = `${dayWeekStart.format(PROP_NAME_DATE_FORMAT)}_${dayWeekEnd.format(PROP_NAME_DATE_FORMAT)}`;
          myColBand.columns.push({
            caption: `Semana ${dayWeekStart.date()} - ${dayWeekEnd.date()}`,
            alignment: 'center',
            columns: [
              this._createColumn('', dataField, {
                headerCellTemplate: 'colHeaderSaldoInicial',
                alignment: 'right'
              })
            ]
          });
          results.push({fromDate: dayWeekStart.clone(), toDate: dayWeekEnd.clone(), value: undefined});
          dayWeekStart = undefined;
          dayWeekEnd = undefined;
        }

        myBeginDate = myBeginDate.add(1, 'days');
      } else if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.MENSAL) {
        if (myMonth !== lastMonth) {
          const dataField = `${myBeginDate.clone().startOf('month').format(PROP_NAME_DATE_FORMAT)}_${myBeginDate.clone().endOf('month').format(PROP_NAME_DATE_FORMAT)}`;

          myColBand.columns.push({
            caption: monthName(myMonth),
            alignment: 'center',
            minWidth: 80,
            columns: [
              this._createColumn('', dataField, {
                headerCellTemplate: 'colHeaderSaldoInicial',
                alignment: 'right'
              })
            ]
          });

          results.push({fromDate: myBeginDate.clone().startOf('month'), toDate: myBeginDate.clone().endOf('month'), value: undefined});
        }
        myBeginDate = myBeginDate.add(1, 'days');
      }

      lastMonth = myMonth;
    }

    if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.SEMANAL && dayWeekStart !== undefined && dayWeekEnd === undefined) {
      const dataField = `${dayWeekStart.format(PROP_NAME_DATE_FORMAT)}_${this.model.toDate.format(PROP_NAME_DATE_FORMAT)}`;

      myColBand.columns.push({
        caption: `Semana ${dayWeekStart.date()} - ${this.model.toDate.date()}`,
        alignment: 'center',
        columns: [
          this._createColumn('', dataField, {
            headerCellTemplate: 'colHeaderSaldoInicial',
            alignment: 'right'
          })
        ]
      });

      results.push({fromDate: dayWeekStart.clone(), toDate: this.model.toDate.clone(), value: undefined});
    }

    this._dynamicColumns.push(myColBand);
    this._tree.addColumn(myColBand);
    this._isMustRenderCols = false;
    return results;
  }

  private _renderSaldoFinalRow(list: Array<IAnalisadorTesRecord>): void {
    const footerRow: IAnalisadorTesRecord = {
      groupId: -1,
      itemType: EAnalisadorTesRecordItemType.TOP,
      receitaDespesa: ETesRubricaReceitaDespesa.NONE,
      nomeRubrica: this._translateService.instant('analisadorTes.strings.saldoFinal'),
      descicaoRubrica: '',
      keyId: '',
      nodeId: SALDO_FINAL_NODE_ID,
      nodeParentId: '-1',
      tesRubricaId: -1,
      data: []
    };

    let summaryValue: number = this.model.saldoInicial;
    let myPrevValue: number = this.model.saldoInicial;
    this._columnValues.forEach((colVal: IAnalisadorTesDateInterval) => {
      const propName: string =
        this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA
          ? colVal.fromDate.format(PROP_NAME_DATE_FORMAT)
          : `${colVal.fromDate.format(PROP_NAME_DATE_FORMAT)}_${colVal.toDate.format(PROP_NAME_DATE_FORMAT)}`;

      list.forEach((item: IAnalisadorTesRecord) => {
        if (item.itemType === EAnalisadorTesRecordItemType.TOP) {
          if (isDefinedNotNull(item[propName])) {
            const val: unknown = item[propName];
            let num = 0;
            if (typeof val === 'number') {
              num = val;
            } else if (typeof val === 'string') {
              num = Number(val);
            }
            if (item.receitaDespesa === ETesRubricaReceitaDespesa.RECEITA) {
              summaryValue += num;
            } else if (item.receitaDespesa === ETesRubricaReceitaDespesa.DESPESA) {
              if (num < 0) {
                summaryValue -= Math.abs(num);
              } else {
                summaryValue += Math.abs(num);
              }
            }
          }
        }
      });
      footerRow[propName] = summaryValue.toFixed(this._decimais);

      if (this.model.periodoAnalise !== EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
        if (isDefinedNotNull(myPrevValue)) {
          const saldoInicialItem: IAnalisadorTesRecord = list.find((item: IAnalisadorTesRecord) => {
            return item.itemType === EAnalisadorTesRecordItemType.TOP && item.groupId === -1;
          });
          if (isDefinedNotNull(saldoInicialItem)) {
            saldoInicialItem[propName] = myPrevValue.toFixed(this._decimais);
          }
        }
        myPrevValue = summaryValue;
      }
    });
    list.push(footerRow);
  }

  private _prepareSource(list: Array<IAnalisadorTesRecord>): void {
    list.forEach((item: IAnalisadorTesRecord) => {
      item.data.forEach((dataItem: IAnalisadorTesRecordData) => {
        const columnValue: IAnalisadorTesDateInterval = this._columnValues.find((colVal: IAnalisadorTesDateInterval) => {
          return moment(dataItem.data).startOf('day').isBetween(colVal.fromDate, colVal.toDate, undefined, '[]');
        });
        if (isDefinedNotNull(columnValue)) {
          if (isUndefinedOrNull(columnValue.value)) {
            columnValue.value = 0;
          }
          columnValue.value += dataItem.valor;
        }
      });

      this._columnValues.forEach((colVal: IAnalisadorTesDateInterval) => {
        const propName: string =
          this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA
            ? colVal.fromDate.format(PROP_NAME_DATE_FORMAT)
            : `${colVal.fromDate.format(PROP_NAME_DATE_FORMAT)}_${colVal.toDate.format(PROP_NAME_DATE_FORMAT)}`;

        if (isDefinedNotNull(colVal.value)) {
          item[propName] = colVal.value.toFixed(this._decimais);
        }
      });

      this._resetColumnValues();
    });
  }

  private _resetColumnValues(): void {
    this._columnValues.forEach((item: IAnalisadorTesDateInterval) => {
      item.value = undefined;
    });
  }

  private _createColumn(caption: string, dataField: string, overrideOptions?: Partial<Column>): Column {
    const common: Column = {
      caption: caption,
      alignment: 'center',
      dataField: dataField,
      minWidth: DX_TREE_COL_MIN_WIDTH,
      customizeText: (cellInfo: ColumnCustomizeTextArg) => {
        return isDefinedNotNull(cellInfo.value) ? this._plI18nService.formatNumber(String(cellInfo.value), this._decimais) : '';
      }
    };
    return merge<Column, Column>(common, overrideOptions || {});
  }

  private _showProgressModal(message: string): NgbModalRef {
    const modalInstance = this._cgModalService.showVanilla(AnalisadorTesProgressModalComponent, {size: 'md'});
    modalInstance.componentInstance.processMessage = message;
    return modalInstance;
  }

  private _handleRequestError(reason: HttpErrorResponse): void {
    if (reason instanceof HttpErrorResponse) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this._plAlertService.error(exception.message);
    } else {
      this._logger.error(reason);
    }
  }

  private _showConfigModal(): Promise<boolean | void> {
    const modalInstance = this._cgModalService.showVanilla(AnalisadorTesConfigModalComponent, {size: 'lg'});
    return modalInstance.result;
  }

  private _showEditTesLancoModal(item: IAnalisadorTesLanco, showValorField: boolean): Promise<IAnalisadorTesLanco> {
    const modalInstance = this._cgModalService.showVanilla(AnalisadorTesTeslancoModalComponent, {size: 'md'});
    modalInstance.componentInstance.model = item;
    modalInstance.componentInstance.showValorField = showValorField;
    return modalInstance.result;
  }

  private _getFirstCellValue(rowData: IAnalisadorTesRecord, dataField: string): IAnalisadorTesRecordData {
    if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
      return rowData.data.find((item: IAnalisadorTesRecordData) => {
        return moment(item.data).format(PROP_NAME_DATE_FORMAT) === dataField;
      });
    }

    const dateRangeArray: Array<string> = dataField.split('_');
    const startDate: Moment = moment(dateRangeArray[0], PROP_NAME_DATE_FORMAT);
    const endDate: Moment = moment(dateRangeArray[1], PROP_NAME_DATE_FORMAT);
    for (let m = startDate; m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
      const cellValue: IAnalisadorTesRecordData = rowData.data.find((item: IAnalisadorTesRecordData) => {
        return moment(item.data).isSame(m, 'date');
      });
      if (isDefinedNotNull(cellValue)) {
        return cellValue;
      }
    }
    return undefined;
  }

  private _getCellValues(rowData: IAnalisadorTesRecord, dataField: string): Array<IAnalisadorTesRecordData> {
    if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
      const foundItem: IAnalisadorTesRecordData = rowData.data.find((item: IAnalisadorTesRecordData) => {
        return moment(item.data).format(PROP_NAME_DATE_FORMAT) === dataField;
      });
      return isDefinedNotNull(foundItem) ? [foundItem] : [];
    }

    const dateRangeArray: Array<string> = dataField.split('_');
    const startDate: Moment = moment(dateRangeArray[0], PROP_NAME_DATE_FORMAT);
    const endDate: Moment = moment(dateRangeArray[1], PROP_NAME_DATE_FORMAT);
    const values: Array<IAnalisadorTesRecordData> = [];
    for (let m = startDate; m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
      const cellValue: IAnalisadorTesRecordData = rowData.data.find((item: IAnalisadorTesRecordData) => {
        return moment(item.data).isSame(m, 'date');
      });
      if (isDefinedNotNull(cellValue)) {
        values.push(cellValue);
      }
    }
    return values;
  }

  private _promptDeleteTesLancoModal(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'analisadorTes.messages.deleteTesLancoMessage', {
      size: 'md',
      btnCancelText: 'global.btn.no',
      btnOkText: 'global.btn.yes',
      backdrop: 'static',
      showCloseBtn: false,
      keyboard: false
    });
  }

  private async _showDeleteTesLancoModal(tesLancoID: string): Promise<void> {
    await this._promptDeleteTesLancoModal();
    await this._analisadorTesService.deleteTesLanco(tesLancoID);
    await this._tree.refresh();
  }

  private _getNodeKeys(node: Node): Array<string> {
    let keys = [];
    if (node.key !== '-1') {
      keys.push(node.key);
    }
    const children = node.children.filter((n) => n.hasChildren);
    children.forEach((item) => {
      keys = keys.concat(this._getNodeKeys(item));
    });
    return keys;
  }

  private _openPreviTes(): Promise<void> {
    if (!this._moduleMaintenancePreviTesInstance) {
      this._moduleMaintenancePreviTesInstance = this._moduleMaintenanceService.build(MODULE_NAME_PREVI_TES);
    }
    return this._moduleMaintenancePreviTesInstance.maintenance();
  }

  private _openPrevTesRubrica(): Promise<unknown> {
    if (!this._entityMaintenancePrevTesRubricaInstance) {
      this._entityMaintenancePrevTesRubricaInstance = this._entityMaintenanceService.build(ENTITY_NAME_TES_RUBRICA);
    }
    return this._entityMaintenancePrevTesRubricaInstance.maintenanceNew({modalOptions: {size: 'lg'}});
  }

  private _openEditPrevTesRubrica(tesRubricaId: number): Promise<unknown> {
    if (!this._entityMaintenancePrevTesRubricaInstance) {
      this._entityMaintenancePrevTesRubricaInstance = this._entityMaintenanceService.build(ENTITY_NAME_TES_RUBRICA);
    }
    return this._entityMaintenancePrevTesRubricaInstance.maintenanceEdit(tesRubricaId);
  }

  private _openRecebimentos(params: IAnalisadorTesRecibosPagamParameters): Promise<unknown> {
    if (!this._entityMaintenanceRecibosInstance) {
      this._entityMaintenanceRecibosInstance = this._entityMaintenanceService.build(ENTITY_NAME_RECIBOS);
    }
    return this._entityMaintenanceRecibosInstance
      .maintenanceNew({
        params: params
      })
      .then(() => {
        this._syncData();
      });
  }

  private _openPagamentos(params: IAnalisadorTesRecibosPagamParameters): Promise<unknown> {
    if (!this._entityMaintenancePagamentosInstance) {
      this._entityMaintenancePagamentosInstance = this._entityMaintenanceService.build(ENTITY_NAME_PAGAMENTOS);
    }
    return this._entityMaintenancePagamentosInstance
      .maintenanceNew({
        params: params
      })
      .then(() => {
        this._syncData();
      });
  }

  private _openPreviTesAuto(): Promise<void> {
    if (!this._moduleMaintenancePreviTesAutoInstance) {
      this._moduleMaintenancePreviTesAutoInstance = this._moduleMaintenanceService.build(MODULE_NAME_PREVI_TES_AUTO);
    }
    return this._moduleMaintenancePreviTesAutoInstance.maintenance();
  }

  private _perioAnaliseChange(): void {
    this._isMustRenderCols = true;
    if (this.model.periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.MENSAL) {
      this.model.fromDate = this.model.fromDate.clone().startOf('month');
      this.model.toDate = this.model.toDate.clone().endOf('month');
    }
    this._tree.refresh();
  }

  private _isWeekEnd(dataField: string): boolean {
    const dw: number = moment(dataField, PROP_NAME_DATE_FORMAT).day();
    return dw === EWeekDay.Saturday || dw === EWeekDay.Sunday;
  }

  private _generatePeriodoAnaliseCaption(): string {
    if (this.isMobile) {
      return `${<string>this._translateService.instant('analisadorTes.strings.periodicidadeAnaliseMobile')}: ${this._getPeriodoAnaliseName()}`;
    }
    return `${<string>this._translateService.instant('analisadorTes.strings.periodicidadeAnalise')}: ${this._getPeriodoAnaliseName()}`;
  }

  private _getPeriodoAnaliseName(): string {
    switch (this.model.periodoAnalise) {
      case EAnalisadorTesPeriodicidadeAnalise.DIARIA:
        return this._translateService.instant('analisadorTes.strings.diaria');
      case EAnalisadorTesPeriodicidadeAnalise.SEMANAL:
        return this._translateService.instant('analisadorTes.strings.semanal');
      case EAnalisadorTesPeriodicidadeAnalise.MENSAL:
        return this._translateService.instant('analisadorTes.strings.mensal');
      default:
        return '';
    }
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = this._btnDropdownActionsNew.visible = isMobile;
    if (!isMobile) {
      this._btnMainPreviTesToolbarItem.class = this._btnMainPreviTesAutoToolbarItem.class = this._btnMainPrevTesRubricaToolbarItem.class = 'btn-success';
      this._btnMainSyncToolbarItem.class = 'btn-info';
      this._btnMainConfigToolbarItem.class = 'btn-light';
      this._btnMainPerioToolbarItem.caption = this._generatePeriodoAnaliseCaption();
      this.toolbar
        .addButton(this._btnMainPreviTesToolbarItem)
        .addButton(this._btnMainPreviTesAutoToolbarItem)
        .addButton(this._btnMainPrevTesRubricaToolbarItem)
        .addButton(this._btnMainSyncToolbarItem)
        .addButton(this._btnMainConfigToolbarItem);
    } else {
      this._btnMainPreviTesToolbarItem.class = this._btnMainPreviTesAutoToolbarItem.class = this._btnMainPrevTesRubricaToolbarItem.class = undefined;
      this._btnMainSyncToolbarItem.class = this._btnMainConfigToolbarItem.class = undefined;
      this._btnMainPerioToolbarItem.caption = this._generatePeriodoAnaliseCaption();
    }
  }

  private _showDocumentoContabilidadeModal(extPocCabId: string): Promise<unknown> {
    if (!this._maintenanceDocsContabilidade) {
      this._maintenanceDocsContabilidade = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    }
    return this._maintenanceDocsContabilidade.maintenanceEdit(extPocCabId, {mode: EEntityMaintenanceEditMode.ReadOnly});
  }

  private _showDocumentoGestaoComercialModal(faccbId: number, grupoDocfa: EGrupoDoc): Promise<unknown> {
    if (!this._maintenanceDocsComerciais) {
      this._maintenanceDocsComerciais = new Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>();
    }
    let maintenanceDocsComerciais: IEntityMaintenanceInstance<IJsonDocComercial> = this._maintenanceDocsComerciais.get(grupoDocfa);
    if (!maintenanceDocsComerciais) {
      const entityName: string = docsComerciaisGrupoDocToEntityName(grupoDocfa);
      maintenanceDocsComerciais = this._entityMaintenanceService.build<IJsonDocComercial>(entityName);
      this._maintenanceDocsComerciais.set(grupoDocfa, maintenanceDocsComerciais);
    }
    return maintenanceDocsComerciais.maintenanceEdit(faccbId, {mode: EEntityMaintenanceEditMode.ReadOnly});
  }

  private _showValoresAnterioresModal(list: Array<IJsonAnalisadorTesValoresAntItem>): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AnalisadorTesValoresAnterioresModalComponent, {size: 'xxl', backdrop: 'static'});
    const componentInstance: AnalisadorTesValoresAnterioresModalComponent = modalInstance.componentInstance;
    componentInstance.dateSourceList = list;
    return modalInstance.result;
  }

  private _exportToExcel(): void {
    const workbook = new Workbook();
    const fileName: string = this._getExcelFileName();
    const worksheet: Worksheet = workbook.addWorksheet(fileName);

    this._isExpandAll = true;
    this._tree.refresh().then(() => {
      AnalisadorTesExcelExporter.exportTreeList({
        saldoInicialRow: this._saldoInicialRow,
        periodoAnalise: this.model.periodoAnalise,
        component: this._tree.instance(),
        empresa: {
          nEmpresa: this.session.erp.nEmpresa,
          nomeEmpresa: this.session.erp.nomeEmpresa
        },
        worksheet
      })
        .then(() => {
          workbook.xlsx
            .writeBuffer()
            .then((buffer) => {
              downloadStream(new Blob([buffer], {type: 'application/octet-stream'}), `${fileName}.xlsx`);
            })
            .catch((reason: unknown) => {
              this._logger.error(reason);
            });
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
        })
        .finally(() => {
          this._isExpandAll = false;
        });
    });
  }

  private _getExcelFileName(): string {
    let periodoAnalise = '';
    switch (this.model.periodoAnalise) {
      case EAnalisadorTesPeriodicidadeAnalise.DIARIA: {
        periodoAnalise = 'Diaria';
        break;
      }
      case EAnalisadorTesPeriodicidadeAnalise.SEMANAL: {
        periodoAnalise = 'Semanal';
        break;
      }
      case EAnalisadorTesPeriodicidadeAnalise.MENSAL: {
        periodoAnalise = 'Mensal';
        break;
      }
    }
    return `PlanTesouraria_${periodoAnalise}_${moment(this.model.fromDate).format(PROP_NAME_DATE_FORMAT)}_${moment(this.model.toDate).format(PROP_NAME_DATE_FORMAT)}`;
  }
}
