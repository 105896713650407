import {ROLE} from '../../../../../services/role.const';
import {IModuleDefinition} from '../../../../../components/module/module.definition.interface';
import {ListagemMenusComponent} from './components/listagemMenus.module.component';

export const MODULE_REFEICOES_LISTAGEM_MENUS: IModuleDefinition = {
  name: 'colaboradoresListagemMenus',
  state: {
    url: '/listagemmenus',
    component: ListagemMenusComponent,
    data: {
      roles: [ROLE.GESTOREMENTAS],
      pageTitle: 'global.menu.colaboradoresListagemMenus'
    }
  }
};
