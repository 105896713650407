import {DocsPlataformaComponent} from './components/docsPlataforma.module.component';
import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_DOCS_PLATAFORMA: IModuleDefinition = {
  name: 'colaboradoresdocsplataforma',
  state: {
    url: '/docsplataforma',
    component: DocsPlataformaComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTORRH],
      pageTitle: 'colaboradores.docsplata.title',
      icon: 'fa-exchange'
    }
  }
};
