import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ConfigDocsEFaturaGlobalComponent} from './global/configDocsEfatura.global.component';
import {IJsonEFaturaConfigDocs} from '../jsonEFaturaConfigDocs.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'module-config-efatura-docs-por-lancar',
  templateUrl: './configEfaturaDocPorLanc.module.component.html'
})
export class ConfigEfaturaDocPorLancModuleComponent extends ModuloComponent implements OnInit {
  @ViewChild('configDocsEfaturaGlobal') public readonly configDocsEfaturaGlobal: ConfigDocsEFaturaGlobalComponent;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'btnGuardar',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-save"></i>',
      class: 'btn-success',
      caption: 'configEfaturaDocPorLanc.btnGuardar',
      click: () => this._save()
    });
  }

  private _save(): Promise<HttpResponse<IJsonEFaturaConfigDocs>> {
    return this.configDocsEfaturaGlobal.save();
  }
}
