<ng-container class="user-access-config">
  <pl-edit-checkbox
    *ngIf="config.kind === typeKind.Integer || config.kind === typeKind.Enumeration"
    attrName="{{ attrName }}-user-access-config-{{ config.name }}"
    [model]="config.value"
    (modelChange)="changedValue($event)"
    [label]="label"
    [falseValue]="0"
    [trueValue]="1"
    [indeterminate]="config.indeterminate"
    [properties]="{disabled: disabled}">
  </pl-edit-checkbox>
</ng-container>
