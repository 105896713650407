import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRelacaoContasTaxonomia} from './jsonTaxonomias.entity.interface';
import {ENTITY_NAME_TAXONOMIAS} from './taxonomias.entity.interface';

export const ENTITY_TAXONOMIAS: IEntityDefinition<IJsonRelacaoContasTaxonomia> = {
  name: ENTITY_NAME_TAXONOMIAS,
  roles: [ROLE.CONTABILIDADE],
  searchPlaceholder: 'taxonomias.pesquisa',
  metadata: {
    keyName: 'nContaDe',
    fields: [
      {name: 'nContaDe', type: 'text', caption: 'taxonomias.fields.nContaDe', visible: true},
      {name: 'nContaAte', type: 'text', caption: 'taxonomias.fields.nContaAte', visible: true},
      {name: 'codigoTaxonomia', type: 'text', caption: 'taxonomias.fields.codigoTaxonomia', visible: true},
      {name: 'descricao', caption: 'taxonomias.fields.descricao', visible: true}
    ],
    order: 'nContaDe',
    searchFields: 'nContaDe,nContaAte,codigoTaxonomia,descricao'
  }
};
