import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'erp-check-validate-all-modal',
  templateUrl: './erp.check.validateAll.modal.component.html'
})
export class ErpCheckValidateAllModalComponent extends CGModalComponent<boolean> {
  public generateDefaultData: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.generateDefaultData = false;
  }

  public close(): void {
    super.close(this.generateDefaultData);
  }
}
