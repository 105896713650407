import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonReparticoesCCusto} from './jsonReparticoesCCusto.entity.interface';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IReparticoesCCustoEntityService extends IEntityService<IJsonReparticoesCCusto> {
  validateCodTabela(nCodRepCC: number): TServiceResponse<boolean>;

  apagarDataReferencia(nCodRepCC: number, dataReferencia: TDate): TServiceResponse<void>;
}

export interface IReparticoesCCustoEntity extends IEntityDefinition<IJsonReparticoesCCusto, IReparticoesCCustoEntityService> {}

export const ENTITY_NAME_REPCC = 'reparticoesccustos';

export const ENTITY_NAME_REPCC_FATURACAO = `${ENTITY_NAME_REPCC}faturacao`;

export const ENTITY_NAME_REPCC_SALARIOS = `${ENTITY_NAME_REPCC}salarios`;
