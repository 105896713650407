import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {Observable} from 'rxjs';
import {ApiService} from '../../../../../services/api/api.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {buildSessionUrlWithParams} from '../../../../../../common/utils/utils';
import {ERHProceSegSocialPrintMode} from '../../proceSegSocial.module.interface';

@Component({
  selector: 'proce-seg-social-print-modal',
  templateUrl: './proceSegSocial.print.modal.component.html'
})
export class ProceSegSocialPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public modalTitle: string;
  @Input() public mode: ERHProceSegSocialPrintMode;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;
  public nibADebitar: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.nibADebitar = '';
  }

  public ngOnInit(): void {
    this.reportType = this.mode === ERHProceSegSocialPrintMode.MAPA_POR_EMPREGADO ? EReport.RHProceSegSocialMapacx : EReport.RHProceSegSocialMapacxRe;
    this._loadDefaultReport().then(() => {
      this.loadPdf();
    });
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this.loadPdf();
  }

  public loadPdf(): void {
    this._getPdfUrl().subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _getPdfUrl(): Observable<string> {
    const path =
      this.mode === ERHProceSegSocialPrintMode.MAPA_POR_EMPREGADO
        ? `${this._apiService.path.restapi}/rhprocesegsocial/mapacx/report/pdf`
        : `${this._apiService.path.restapi}/rhprocesegsocial/mapacxre/report/pdf`;
    return buildSessionUrlWithParams(path, {reportName: this.reportModel.name, nibADebitar: this.nibADebitar});
  }

  private _loadDefaultReport(): Promise<void> {
    let promise: Promise<Array<IJsonReport>>;
    if (this.mode === ERHProceSegSocialPrintMode.MAPA_POR_EMPREGADO) {
      this.reportModel = {
        name: this._configService.configurations.rh.proceSegSocial.reports.Mapacx.reportImpressao,
        title: this._configService.configurations.rh.proceSegSocial.reports.Mapacx.reportImpressao
      };
      promise = this._reportsRegistryService.get(EReport.RHProceSegSocialMapacx).query();
    } else {
      this.reportModel = {
        name: this._configService.configurations.rh.proceSegSocial.reports.MapacxRE.reportImpressao,
        title: this._configService.configurations.rh.proceSegSocial.reports.MapacxRE.reportImpressao
      };
      promise = this._reportsRegistryService.get(EReport.RHProceSegSocialMapacxRe).query();
    }

    return promise.then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        let foundDefault = false;
        for (const report of reports) {
          if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
            this.reportModel = {name: report.name, title: report.title};
            foundDefault = true;
            break;
          }
        }

        if (!foundDefault) {
          this.reportModel = {
            name: reports[0].name,
            title: reports[0].title
          };
        }
      }
    });
  }
}
