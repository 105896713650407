<div class="transferenciasarmazens">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="transferenciasArmazens" [dataSource]="dataGridDefinition.dataSource">
    <div *dxTemplate="let item of 'headerCellTemplateBtn'">
      <pl-tooltip [config]="{text: 'transferenciasarmazens.tooltip.addNewLine', placement: 'left'}">
        <button *plTooltipContent type="button" class="btn btn-primary btn-xs" (click)="addLine()">
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'cellTemplateBtn'">
      <button type="button" class="btn btn-warning btn-xs" (click)="editLine(item.data)">
        <i class="fa fa-fw fa-pencil-square-o"></i>
      </button>
      &nbsp;
      <button type="button" class="btn btn-danger btn-xs" (click)="deleteLine(item.data)">
        <i class="fa fa-fw fa-trash"></i>
      </button>
    </div>
  </dx-data-grid>
</div>
