import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_PERIODOS_IVA} from './periodosIva.datasource.interface';

export const DATA_SOURCE_PERIODOS_IVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_PERIODOS_IVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 1, name: 'periodosIVA.data.mensal'},
    {value: 2, name: 'periodosIVA.data.trimestral'}
  ]
});
