import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonModeloConfigWS} from './modelo.configWS.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ObrigacoesAcessoriasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/obrigacoesAcessorias`;
  }

  public getConfigWS(): TServiceResponse<IJsonModeloConfigWS> {
    return this._apiService.get<IJsonModeloConfigWS>({url: `${this._path}/config/webservice`});
  }

  public saveConfigWS(config: IJsonModeloConfigWS): TServiceResponse<IJsonModeloConfigWS> {
    return this._apiService.post<IJsonModeloConfigWS>({url: `${this._path}/config/webservice`, body: config});
  }
}
