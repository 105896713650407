import {ENTITY_NAME_SUBDE} from './subDe.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonSubDe} from './jsonSubDe.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_SUBDE: IEntityDefinition<IJsonSubDe> = {
  name: ENTITY_NAME_SUBDE,
  roles: [ROLE.ERP, ROLE.ATIVOS, ROLE.RH, ROLE.CONTABILIDADE],
  searchPlaceholder: 'subde.pesquisa',
  metadata: {
    keyName: 'nSubDe',
    fields: [
      {name: 'nSubDe', width: '120px', caption: 'subde.fields.nSubDe', placeholder: 'subde.fields.nSubDePlaceholder', validators: {required: true, min: 1, maxlength: 4}},
      {name: 'nome', caption: 'subde.fields.nome', placeholder: 'subde.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'nSubDe,nome'
  },
  autocomplete: {
    rowTemplate: '{{nSubDe}} - {{nome}}',
    output: 'nome',
    searchFields: 'nSubDe,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.subde'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.subde'
      }
    }
  }
};
