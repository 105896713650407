import {Transition} from '@uirouter/core';
import {isArray, isString} from 'pl-comps-angular';
import {BlockedPluginModuleComponent} from './components/blockedPlugin.module.component';
import {IBlockedPluginModuleStateParams, MODULE_NAME_BLOCKED_PLUGIN} from './blockedPlugin.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';

export const MODULE_BLOCKED_PLUGIN: IModuleDefinition = {
  name: MODULE_NAME_BLOCKED_PLUGIN,
  state: {
    url: '/blockedplugin',
    component: BlockedPluginModuleComponent,
    data: {
      invisible: true
    },
    params: {
      pageTitle: {
        type: 'string',
        value: ''
      },
      pluginRoles: {
        type: 'any',
        value: null
      },
      requiredRoles: {
        type: 'any',
        value: null
      }
    },
    redirectTo: (transition: Transition): string => {
      const params: IBlockedPluginModuleStateParams = <IBlockedPluginModuleStateParams>transition.params();
      if (!isString(params.pageTitle) || !params.pageTitle || !isArray(params.requiredRoles) || !isArray(params.pluginRoles)) {
        return STATE_NAME_PORTAL;
      }
      return undefined;
    }
  }
};
