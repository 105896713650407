<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemDetail="let item">
      <pagamento [pagamento]="item.data._pagamento" (evtRefresh)="refreshList()"></pagamento>
    </div>
  </entity-list>
</div>

<ng-template #templateTooltipGestaoSepa>
  <div
    [innerHTML]="(licencaStoreModePublic ? 'blockedPlugin.text.portalNoLicense' : 'blockedPlugin.text.portalNoLicenseStoreMode') | translate: {href: cgStoreUrlBackOffice, portal: portalName}"></div>
</ng-template>
