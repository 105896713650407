import {isBoolean, isEmpty, isNumber, isObject, isSerializable, isString, toInteger} from '../common/utilities/utilities';
import {IPlFilterPanelField, IPlFilterPanelFilters} from './filter.panel.interface';

const QUERY_PARAM_VALUES_SEPARATOR = '&';
const QUERY_PARAM_VALUE_SEPARATOR = '=';
const QUERY_PARAM_VALUE_LENGTH = 2;
const QUERY_PARAM_INDEX_KEY = 0;
const QUERY_PARAM_INDEX_VALUE = 1;

export function filterPanelFiltersToUrl(filters: IPlFilterPanelFilters): string {
  let query = '';
  for (const fieldName of Object.keys(filters)) {
    let value: unknown = filters[fieldName];
    if (isEmpty(value)) {
      continue;
    }
    if (isObject(value)) {
      if (isSerializable(value)) {
        value = value.toJSON();
      } else {
        value = (<{value: unknown}>value).value;
      }
    }
    if (query) {
      query += QUERY_PARAM_VALUES_SEPARATOR;
    }
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    query += fieldName + QUERY_PARAM_VALUE_SEPARATOR + String(value);
  }
  return query;
}

export function filterPanelFiltersFromUrl(value: string, fields: Map<string, IPlFilterPanelField> | Array<IPlFilterPanelField>): IPlFilterPanelFilters {
  const filters: IPlFilterPanelFilters = {};
  if (isEmpty(value)) {
    return filters;
  }
  let fieldsMap: Map<string, IPlFilterPanelField>;
  if (fields instanceof Map) {
    fieldsMap = fields;
  } else {
    fieldsMap = new Map<string, IPlFilterPanelField>(fields.map((field: IPlFilterPanelField) => [field.name, field]));
  }
  const rawValues: Array<string> = value.split(QUERY_PARAM_VALUES_SEPARATOR);
  for (const rawValue of rawValues) {
    const paramValue: Array<string> = rawValue.split(QUERY_PARAM_VALUE_SEPARATOR);
    if (paramValue.length < QUERY_PARAM_VALUE_LENGTH) {
      continue;
    }
    const filterName: string = paramValue[QUERY_PARAM_INDEX_KEY];
    let filterValue: unknown = paramValue[QUERY_PARAM_INDEX_VALUE];
    if (!isEmpty(filterName) && !isEmpty(filterValue)) {
      const field: IPlFilterPanelField = fieldsMap.get(filterName);
      if (field) {
        switch (field.type) {
          case 'boolean':
            if (!isBoolean(filterValue)) {
              filterValue = isString(filterValue) ? filterValue === 'true' || filterValue === '1' : Boolean(filterValue);
            }
            break;
          case 'integer':
            if (!isNumber(filterValue)) {
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              filterValue = toInteger(String(filterValue));
            }
            break;
          case 'number':
          case 'tax':
            if (!isNumber(filterValue)) {
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              filterValue = parseFloat(String(filterValue));
            }
            break;
        }
      }
      filters[filterName] = filterValue;
    }
  }
  return filters;
}
