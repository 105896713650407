import {ENTITY_NAME_PRH_ENT_ALTERS} from './pRHEntAlers.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHEntAlter} from './jsonPRHEntAlter.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_ENTALTERS: IEntityDefinition<IJsonPRHEntAlter> = {
  name: ENTITY_NAME_PRH_ENT_ALTERS,
  asModule: false,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhentalters.pesquisa',
  metadata: {
    keyName: 'codPRHEntAlter',
    descriptionName: 'campo',
    fields: [
      {name: 'codPRHEntAlter', caption: 'prhentalters.fields.codLocal', placeholder: 'prhentalters.fields.codPRHEntAlter', validators: {required: true, min: 1}},
      {name: 'entidade', caption: 'prhentalters.fields.entidade', placeholder: 'prhentalters.fields.entidadePlaceholder', validators: {required: true, maxlength: 30}},
      {name: 'campo', caption: 'prhentalters.fields.campo', placeholder: 'prhentalters.fields.campoPlaceholder', validators: {required: true, maxlength: 50}},
      {
        name: 'descricaoCampo',
        caption: 'prhentalters.fields.descricaoCampo',
        placeholder: 'prhentalters.fields.descricaoCampoPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {name: 'tipo', caption: 'prhentalters.fields.tipo', placeholder: 'prhentalters.fields.tipoPlaceholder', validators: {required: true, min: 0}},
      {name: 'valor', caption: 'prhentalters.fields.valor', placeholder: 'prhentalters.fields.valorPlaceholder', validators: {required: true, maxlength: 250}},
      {name: 'estado', caption: 'prhentalters.fields.estado', placeholder: 'prhentalters.fields.estadoPlaceholder', validators: {required: true, min: 0}},
      {name: 'codEmp', caption: 'prhentalters.fields.codEmp', placeholder: 'prhentalters.fields.codEmpPlaceholder', validators: {required: true, min: 1}},
      {
        name: 'codEmpAlterou',
        caption: 'prhentalters.fields.codEmpAlterou',
        placeholder: 'prhentalters.fields.codEmpAlterouPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'dataAlterou',
        type: 'date',
        caption: 'prhentalters.fields.dataAlterou',
        placeholder: 'prhentalters.fields.dataAlterouPlaceholder',
        validators: {required: true}
      },
      {
        name: 'codEmpAprovou',
        caption: 'prhentalters.fields.codEmpAprovou',
        placeholder: 'prhentalters.fields.codEmpAprovouPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'dataAprovou',
        type: 'date',
        caption: 'prhentalters.fields.codEmpAprovou',
        placeholder: 'prhentalters.fields.codEmpAprovouPlaceholder',
        validators: {required: true}
      }
    ],
    order: 'codPRHEntAlter',
    searchFields: 'codPRHEntAlter,campo'
  },
  autocomplete: {
    rowTemplate: '{{codPRHEntAlter}} - {{campo}}',
    output: 'campo',
    searchFields: 'codPRHEntAlter,campo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  }
};
