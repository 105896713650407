import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClifosEditComponent} from '../../edit/clifos.entity.edit.component';
import {EConfigOptionsInstanceName} from '../../../../../services/config/options/config.options.service.interface';
import {copy, IPlFormDefinition, IPlFormTemplate} from 'pl-comps-angular';
import {EGroupName} from '../../../../../../config/constants';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {EntityListComponent} from '../../../../../components/entity/list/entity.list.component';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {AuthService} from '../../../../../services/auth/auth.service';
import {EPortal} from '../../../../../../common/enums/portals.enums';
import {TranslateService} from '@ngx-translate/core';

const TOOLBAR_ID_PAGAMENTOS = 'toolbar-pagamentos';

@Component({
  selector: 'fornecedores-edit',
  templateUrl: './fornecedores.entity.edit.component.html'
})
export class FornecedoresEditComponent extends ClifosEditComponent implements OnInit, OnDestroy {
  public readonly pagamentosFields: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public toolbarInstancePagamentos: string;
  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public optionShowZona: boolean;
  public optionShowVendedor: boolean;
  public optionShowSector: boolean;
  public grupoEncomenda: EGrupoDoc;

  @ViewChild('entityListPagamentos') private readonly _entityListPagamentos: EntityListComponent;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    protected readonly _entityMaintenanceService: EntityMaintenanceService,
    protected readonly _authService: AuthService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector, _configOptionsService, _entityMaintenanceService, _authService, _translateService);
    this.pagamentosFields = 'numero,data,valor,stampUpdate';
    this.grupoEncomenda = EGrupoDoc.EncomendasFornecedores;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formDefinition = this.entity[this.type].definition;
    this.formTemplate = copy(this.entity[this.type].template);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.formDefinition = this.entity[stateType].definition;
      this.formTemplate = copy(this.entity[stateType].template);
      this.disableTabArtigos = this.currentPortal.portal !== EPortal.ERP;
    });
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarInstancePagamentos = `${this.instanceName}-${TOOLBAR_ID_PAGAMENTOS}`;
  }

  public refreshPagamentosList(): void {
    this._entityListPagamentos.refresh();
  }
}
