<div
  class="contabilidade-digital-doc-viewer-recolha"
  [class.contabilidade-digital-doc-viewer-recolha-default]="state === states.Default"
  [class.contabilidade-digital-doc-viewer-recolha-editing]="state === states.Editing"
  [class.contabilidade-digital-doc-viewer-recolha-searching]="state === states.Searching">
  <div class="card">
    <div class="contabilidade-digital-doc-viewer-recolha-body">
      <pl-alert type="info" *ngIf="!loading && state === states.Default && attachmentsTotal === 0" [closeable]="false" theme="mb-1 p-2">
        <ng-container><i class="fa fa-exclamation-circle"></i>&nbsp;</ng-container>
        <ng-container *ngIf="location"><span [translate]="'arquivodigital.docviewerrecolha.text.emptyAttachments1'" [translateParams]="{location: location}"></span>&nbsp;</ng-container>
        <span
          class="hyperlink"
          [translate]="location ? 'arquivodigital.docviewerrecolha.text.emptyAttachments2' : 'arquivodigital.docviewerrecolha.text.emptyLocation'"
          (click)="chooseFolder()"></span>
      </pl-alert>

      <arquivodigital-docviewer
        [documentsService]="documentsService"
        [(docId)]="attachmentDocId"
        [toolbarInstanceId]="toolbarInstanceId"
        [hideToolbarOnEmptyDoc]="hideToolbarOnEmptyDoc"
        [showAll]="true"
        [nDocumento]="nDocumento"
        [callback]="callback"
        (evtConfigureToolbar)="configureToolbar($event)">
      </arquivodigital-docviewer>
    </div>
  </div>
</div>
