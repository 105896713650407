<div class="rh-dados-config-ficha-module entity-detail-form">
  <pl-alert type="danger" *ngIf="editing && error" (evtClosed)="error = ''">
    <div class="rh-dados-config-ficha-error" [plTranslate]="error"></div>
  </pl-alert>

  <pl-alert type="primary" *ngIf="!hasDadosFicha" [seekAttention]="true" theme="light">
    <div class="rh-dados-config-ficha-suggestion">
      <span [plTranslate]="'rhdadosconfigficha.text.suggestion1'"> </span>&nbsp;<a
        class="rh-dados-config-ficha-suggestion-apply"
        [plTranslate]="'rhdadosconfigficha.text.suggestion2'"
        (click)="applyRecommendedConfiguration()"></a>
      <span>?</span>
    </div>
  </pl-alert>

  <div class="rh-dados-config-ficha" [plPromise]="promise">
    <pl-form (formInstanceChange)="formInstance = $event">
      <rh-dados-config-ficha [(model)]="dadosFicha" [readonly]="!editing" [required]="true"></rh-dados-config-ficha>
    </pl-form>
  </div>
</div>
