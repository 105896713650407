import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';
import {EDateMonth, IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, PlI18nService, toInteger} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {DATA_SOURCE_MESES} from '../../../../../datasources/meses/meses.datasource';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IColumnMapaExploracaoTotal, IMapaExploracaoFilters} from '../mapaExploracao.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonMapaExploracao, TServiceHttpQueryResponseMapaExploracao} from '../jsonMapaExploracao.module.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../../../entities/empresas/empresas.entity.interface';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {MapaExploracaoConfigModalComponent} from './modal/mapaExploracao.config.modal.component';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES} from '../../balancetes/contabilidade.balancetes.module.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {round} from '../../../../../../common/utils/utils';

const LENGTH_YEAR = 4;
const NUMBER_3 = 3;
const NUMBER_4 = 4;
const ROUND_PRECISION = 2;
const ROUND_PRECISION_EURO = 0;
const BTN_PROCESS_XLS = 'processaXls';

@Component({
  selector: 'contabilidade-mapaexploracao',
  templateUrl: './mapaExploracao.module.component.html'
})
export class MapaExploracaoComponent extends ModuloComponent implements OnInit {
  @Input() public periodos: Array<IJsonPeriodo>;
  @Input() public empresaMesContab: IJsonPerioMudaPrimeiroMesContab;

  public totalExploracaoCollumnName: Array<IColumnMapaExploracaoTotal>;
  public dataGridDefinition: IDevExpressDataGrid;
  public filters: IMapaExploracaoFilters;
  public anosSource: Array<number>;
  public dePeriodoNome: string;
  public atePeriodoNome: string;
  public reportName: string;
  public periodoAte: string;
  public ano: number;

  @ViewChild('cardPanel') private readonly _cardPanel: CGCardPanelComponent;
  private readonly _entityPeriodos: IPeriodosEntityService;
  private readonly _btnProcessXls: IPlToolbarItem;
  private readonly _maintenanceInstanceBalanceteGrid: IModuleMaintenanceInstance;
  private _decimais: number;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _cgModalService: CGModalService,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this._cellFormatter = this._cellFormatter.bind(this);
    this._maintenanceInstanceBalanceteGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_BALANCETES);
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._btnProcessXls = {
      id: BTN_PROCESS_XLS,
      order: 1,
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.exportXls',
      disabled: true,
      download: {
        url: undefined,
        target: '_self'
      }
    };
    this.anosSource = [];
    this.filters = {
      dePeriodo: '',
      dePeriodoNome: '',
      atePeriodo: 'ZZZZZZZ',
      atePeriodoNome: '',
      dePeriodoAno: '',
      loadAllAnos: 0,
      loadOnlyAno: 0
    };
    this.totalExploracaoCollumnName = [
      {column: 'saldo01', value: '0'},
      {column: 'saldo02', value: '0'},
      {column: 'saldo03', value: '0'},
      {column: 'saldo04', value: '0'},
      {column: 'saldo05', value: '0'},
      {column: 'saldo06', value: '0'},
      {column: 'saldo07', value: '0'},
      {column: 'saldo08', value: '0'},
      {column: 'saldo09', value: '0'},
      {column: 'saldo10', value: '0'},
      {column: 'saldo11', value: '0'},
      {column: 'saldo12', value: '0'},
      {column: 'total', value: '0'},
      {column: 'nomeconta', value: this._translateService.instant('mapaexploracao.summary.totalexploracao')}
    ];
    this.dataGridDefinition = {};
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.periodoAte = this._configService.configurations.empresa.periodoDaData;
    this.toolbar.addButton(this._btnProcessXls);
    this.btnConfig.visible = true;
    this.btnConfig.order = this._btnProcessXls.order + 1;
    this.btnConfig.click = () => this._config();
    await this._loadPeriodos();
    this._filtersChanged();
    this._loadDataGridMapaExploracao();
  }

  public async changedDePeriodo(value: string): Promise<void> {
    this.filters.dePeriodo = value;
    this.filters.dePeriodoAno = '';
    if (this.filters.dePeriodo) {
      let dePeriodoAno: string = this.filters.dePeriodo.substring(0, LENGTH_YEAR);
      if (dePeriodoAno) {
        dePeriodoAno = `&periodo=%${dePeriodoAno}%`;
        this.filters.dePeriodoAno = dePeriodoAno;
      }
      this.filters.atePeriodo = `${value.substring(0, NUMBER_4)}121`;
      this.ano = toInteger(value.substring(0, NUMBER_4));
      if (this.ano === this._configService.configurations.empresa.anoEmCursoIRC || this.ano === this._configService.configurations.empresa.anoEmCursoIRC + 1) {
        this.filters.loadOnlyAno = this.filters.loadAllAnos = 0;
      } else {
        this.filters.loadOnlyAno = this.ano;
        this.filters.loadAllAnos = 1;
      }
      await this._checkPeriodos();
    }
    this.filters.dePeriodoNome = this._getDescriptionFromPeriodo(this.filters.dePeriodo);
    this.filters.atePeriodoNome = this._getDescriptionFromPeriodo(this.filters.atePeriodo);
    this.filters = {...this.filters};
    this._filtersChanged();
  }

  public async changedAtePeriodo(value: string): Promise<void> {
    this.filters.atePeriodo = value;
    const periodoDe: number = toInteger(this.filters.dePeriodo);
    const periodoAte: number = toInteger(this.filters.atePeriodo);

    if (periodoAte < periodoDe) {
      this.filters.dePeriodo = value;
    }

    this.filters.dePeriodoNome = this._getDescriptionFromPeriodo(this.filters.dePeriodo);
    this.filters.atePeriodoNome = this._getDescriptionFromPeriodo(this.filters.atePeriodo);

    this.filters = {...this.filters};

    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      await this._checkPeriodos();
    }
    this._filtersChanged();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public openMaintenanceInstanceBalanceteGrid(nconta: string): void {
    this._maintenanceInstanceBalanceteGrid.maintenance({
      params: {dePeriodo: this.filters.dePeriodo, periodoAte: this.filters.atePeriodo, deConta: nconta, contaAte: `${nconta}9999999`}
    });
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonMapaExploracao>): void {
    if (event.target === 'content' && event.row?.rowType === 'data') {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data.conta);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public readonly fnPesquisar = (): Promise<void> => this._mapaExploracao();

  private async _config(): Promise<void> {
    return this._cgModalService.show(MapaExploracaoConfigModalComponent).then(() => {
      this._mapaExploracao();
    });
  }

  private async _loadPeriodos(): Promise<void> {
    if (this.periodos?.length) {
      this.filters = {
        ...this.filters,
        dePeriodoNome: this.periodos[0].nome,
        dePeriodo: this.periodos[0].periodo,
        atePeriodo: this.periodoAte,
        atePeriodoNome: this._getDescriptionFromPeriodo(this.periodoAte)
      };
      await this.changedDePeriodo(this.filters.dePeriodo);
    }
  }

  private _getDescriptionFromPeriodo(periodoKey: string): string {
    periodoKey = periodoKey.substring(periodoKey.length - NUMBER_3);
    return this.periodos.find((periodo: IJsonPeriodo) => periodo.periodo.substring(periodo.periodo.length - NUMBER_3) === periodoKey).nome;
  }

  private _checkPeriodos(): Promise<unknown> {
    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      return this._entityPeriodos.obterAnoDosPeriodos(this.filters.dePeriodo, this.filters.atePeriodo);
    }
    return Promise.resolve();
  }

  private _mapaExploracao(): Promise<void> {
    return this._contabilidadeEstatisticaService.getMapaExploracao(this.filters.dePeriodo, this.filters.atePeriodo).then((response: TServiceHttpQueryResponseMapaExploracao) => {
      let i = 0;
      this._decimais = response.body.tipoArredondamento === 1 ? ROUND_PRECISION_EURO : ROUND_PRECISION;
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo01, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo02, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo03, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo04, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo05, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo06, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo07, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo08, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo09, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo10, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo11, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.saldo12, this._decimais));
      this.totalExploracaoCollumnName[i++].value = String(round(response.body.totaisExploracao.total, this._decimais));
      this._dataGridInstance.option('columns[3].caption', String(this.ano));
      if (response.body.list.length > 0) {
        this._cardPanel.collapse();
        this._btnProcessXls.disabled = false;
        setTimeout(() => {
          this.toolbar.focusItem(BTN_PROCESS_XLS);
        }, 0);
      }
      this.dataGridDefinition.dataSource = response.body.list;
    });
  }

  private _generateSecondaryClickActions(nConta: string): Array<IPlDynamicVisualsSecondaryClickAction> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [];
    actions.push({
      caption: 'mapaexploracao.btn.dropdown.balancete',
      click: () => {
        this.openMaintenanceInstanceBalanceteGrid(nConta);
      }
    });
    return actions;
  }

  private _cellFormatter(value: number): string {
    return this._plI18nService.formatNumber(value, this._decimais);
  }

  private _loadDataGridMapaExploracao(): void {
    const FIELDS_SALDOS: Array<string> = ['saldo01', 'saldo02', 'saldo03', 'saldo04', 'saldo05', 'saldo06', 'saldo07', 'saldo08', 'saldo09', 'saldo10', 'saldo11', 'saldo12'];

    const columsContabMapaExploracao: IDevExpressDataGridColumn<IJsonMapaExploracao, string> = {
      columns: [],
      caption: String(this.ano),
      fixedPosition: 'right'
    };

    let mes: number = this.empresaMesContab.primeiroMesContab;
    for (let i = 0; i < EDateMonth.December; i++) {
      if (mes > EDateMonth.December) {
        mes = 1;
      }
      columsContabMapaExploracao.columns.push({dataField: FIELDS_SALDOS[i], dataType: 'double', caption: DATA_SOURCE_MESES.data[mes - 1].name, width: '6%', format: {formatter: this._cellFormatter}});
      mes++;
    }
    columsContabMapaExploracao.columns.push({dataField: 'total', dataType: 'double', caption: 'global.text.total', width: '6%', format: {formatter: this._cellFormatter}});

    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'tipo',
          dataType: 'number',
          caption: 'Tipo',
          visible: false,
          showInColumnChooser: false,
          fixed: !this.isMobile,
          groupIndex: 0,
          fixedPosition: !this.isMobile ? 'left' : '',
          customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
            if (cellInfo.target === 'row') {
              if (cellInfo.value === 0) {
                return this._translateService.instant('mapaexploracao.summary.rendimentos');
              } else if (cellInfo.value === 1) {
                return this._translateService.instant('mapaexploracao.summary.compras');
              }
            }
            return cellInfo.valueText;
          }
        },
        {dataField: 'conta', dataType: 'string', caption: 'mapaexploracao.fields.conta', fixed: !this.isMobile, fixedPosition: !this.isMobile ? 'left' : '', cellTemplate: 'cellNContaTemplate'},
        {dataField: 'nomeconta', dataType: 'string', caption: 'mapaexploracao.fields.descricao', fixed: !this.isMobile, fixedPosition: !this.isMobile ? 'left' : ''},
        columsContabMapaExploracao
      ],
      summary: {
        groupItems: [
          {
            column: 'tipo',
            showInColumn: 'nomeconta',
            name: 'nomeconta',
            alignByColumn: true,
            showInGroupFooter: true,
            summaryType: 'min',
            customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
              if (cellInfo.target === 'row') {
                if (cellInfo.value === 0) {
                  return this._translateService.instant('mapaexploracao.summary.totalrendimentos');
                } else if (cellInfo.value === 1) {
                  return this._translateService.instant('mapaexploracao.summary.totalcompras');
                }
              }
              return cellInfo.valueText;
            }
          },
          {column: 'saldo01', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo02', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo03', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo04', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo05', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo06', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo07', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo08', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo09', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo10', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo11', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo12', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}},
          {column: 'total', displayFormat: '{0}', alignByColumn: true, showInGroupFooter: true, summaryType: 'sum', valueFormat: {formatter: this._cellFormatter}}
        ],
        totalItems: [
          {column: 'nomeconta', name: '13', skipEmptyValues: true, summaryType: 'custom'},
          {column: 'saldo01', name: '0', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo02', name: '1', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo03', name: '2', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo04', name: '3', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo05', name: '4', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo06', name: '5', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo07', name: '6', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo08', name: '7', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo09', name: '8', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo10', name: '9', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo11', name: '10', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'saldo12', name: '11', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}},
          {column: 'total', name: '12', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'custom', valueFormat: {formatter: this._cellFormatter}}
        ],
        calculateCustomSummary: (options) => {
          if (options.summaryProcess === 'finalize') {
            options.totalValue = this.totalExploracaoCollumnName[Number(options.name)].value;
          }
        }
      },
      keyExpr: 'conta',
      allowColumnReordering: false,
      allowColumnResizing: true,
      export: {enabled: false},
      height: '70vh',
      searchPanel: {visible: false},
      sorting: {mode: 'none'},
      paging: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
  }

  private _filtersChanged(): void {
    this._contabilidadeEstatisticaService.getMapaExploracaoXlsUrl(this.filters.dePeriodo, this.filters.atePeriodo).subscribe((url: string) => {
      this._btnProcessXls.download.url = url;
    });
  }
}
