export enum ETipoContabilidade {
  None,
  OrganizadaComPOCDL35,
  NaoOrganizadaComPOCDL35,
  OrganizadaComSNC,
  NaoOrganizadaComSNC,
  OrganizadaComPOCIPSS,
  OrganizadaComPOCAL,
  OrganizadaComPOCP,
  OrganizadaComPOCMZ,
  NaoOrganizadaComPOCMZ,
  OrganizadaComPOCKZ,
  NaoOrganizadaComPOCKZ,
  OrganizadaNIRFMZ,
  OrganizadaComSNCIPSS,
  OrganizadaComSNCPE,
  OrganizadaComSNCME,
  OrganizadaESNL,
  NaoOrganizadaArtigo50,
  OrganizadaSNCAP
}

export const DATA_SOURCE_NAME_TIPOS_CONTABILIDADE = 'tiposContabilidade';
