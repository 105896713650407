import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonProcessamentoEmpregadoSalarios} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IRecibosVencimentoProcessamentoEmpregadoSalarios} from '../recibosVencimento.module.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {firstValueFrom} from 'rxjs';

const DEFAULT_REPORT_NAME = 'RECIBA5.FR3';

@Component({
  selector: 'colaboradores-recibosvencimento',
  templateUrl: './recibosVencimento.module.component.html'
})
export class RecibosVencimentoComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private _reportModel: IJsonReport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
    this.dataGridDefinition = {
      columnAutoWidth: true,
      columns: [
        {dataField: 'nRecibo', dataType: 'number', caption: 'dgemps.recibos.fields.nrecibo', sortOrder: 'desc'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'dgemps.recibos.fields.datarecibo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: new CustomStore({
        load: () => this._source()
      }),
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._reportsRegistryService
      .get(EReport.RecibosSal)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let index: number;
          index = reports.findIndex((report: IJsonReport) => report.name.toLowerCase() === this._configService.configurations.portalcolaborador.reportrecibosal.toLowerCase());
          if (index !== -1) {
            this._reportModel = reports[index];
          } else {
            index = reports.findIndex((report: IJsonReport) => report.name.toLowerCase() === DEFAULT_REPORT_NAME.toLowerCase());
            if (index !== -1) {
              this._reportModel = reports[index];
            } else {
              this._reportModel = reports[0];
            }
          }
        }
      });
  }

  private _source(): Promise<Array<IRecibosVencimentoProcessamentoEmpregadoSalarios>> {
    return this._authService.identity().then((session: TUserSession) => {
      const codEmp: number = session.erp.codEmp;
      return this._serviceDGEMPS.getListaProcessamentos(codEmp, minDateCG(), maxDateCG(), true).then((response: HttpResponse<Array<IJsonProcessamentoEmpregadoSalarios>>) => {
        return response.body.map<IRecibosVencimentoProcessamentoEmpregadoSalarios>((processamento: IJsonProcessamentoEmpregadoSalarios) => {
          return {
            ...processamento,
            showPdf: this._fnShowPdf(codEmp, processamento)
          };
        });
      });
    });
  }

  private _showPdf(codEmp: number, processamento: IJsonProcessamentoEmpregadoSalarios): Promise<void> {
    return firstValueFrom(this._serviceDGEMPS.exportReciboVencimentoToPdfUrl(codEmp, processamento.nProcessamento, undefined, this._reportModel.name)).then((pdfUrl: string) =>
      this._cgModalService.showPdf('dgemps.recibos.title', pdfUrl)
    );
  }

  private readonly _fnShowPdf: (codEmp: number, processamento: IJsonProcessamentoEmpregadoSalarios) => () => Promise<void> = (codEmp: number, processamento: IJsonProcessamentoEmpregadoSalarios) => {
    return () => this._showPdf(codEmp, processamento);
  };
}
