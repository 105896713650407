import {firstValueFrom} from 'rxjs';
import {PlRecaptchaV3Service} from 'pl-comps-angular';
import {TRecaptchaActionResult} from './recaptcha.interface';

const ACTION_PREFIX = 'erpsite';

export class RecaptchaActions {
  constructor(private readonly _plRecaptchaV3Service: PlRecaptchaV3Service) {}

  public login(): TRecaptchaActionResult {
    return firstValueFrom(this._plRecaptchaV3Service.execute(`${ACTION_PREFIX}/login`));
  }

  public resetPassword(): TRecaptchaActionResult {
    return firstValueFrom(this._plRecaptchaV3Service.execute(`${ACTION_PREFIX}/resetpassword`));
  }

  public changePassword(): TRecaptchaActionResult {
    return firstValueFrom(this._plRecaptchaV3Service.execute(`${ACTION_PREFIX}/changepassword`));
  }
}
