import {Subscription} from 'rxjs';
import {Component, ElementRef, Inject, OnDestroy, Optional, ViewChild} from '@angular/core';
import {DOCUMENT, NgForOf, NgIf} from '@angular/common';
import {CGCToastComponent} from '../toast/toast.component';
import {PlAlertService} from '../../service/toasts.service';
import {ICGCToast} from '../../toasts.interface';

@Component({
  selector: 'cgc-toasts',
  templateUrl: './toasts.component.html',
  standalone: true,
  imports: [NgForOf, NgIf, CGCToastComponent]
})
export class CGCToastsComponent implements OnDestroy {
  public toasts: ReadonlyArray<ICGCToast>;
  public toastsGap: number;

  private readonly _document: Document;
  private readonly _observerToastsContainer: ResizeObserver;
  private readonly _subscriptionToasts: Subscription;
  private _elementToastsContainer: HTMLElement;

  constructor(
    @Inject(DOCUMENT) @Optional() document: unknown,
    private readonly _toastsService: PlAlertService
  ) {
    this._evaluateGap = this._evaluateGap.bind(this);
    this.toastsGap = 0;
    this._document = <Document>document;
    this._observerToastsContainer = new ResizeObserver(this._evaluateGap);
    this._subscriptionToasts = this._toastsService.toasts().subscribe((toasts: ReadonlyArray<ICGCToast>) => {
      this.toasts = toasts;
    });
  }

  public ngOnDestroy(): void {
    this._observerToastsContainer.disconnect();
    this._subscriptionToasts.unsubscribe();
  }

  @ViewChild('elementToastsContainer', {static: true})
  public set elementToastsContainer(elementRef: ElementRef<HTMLElement>) {
    if (this._elementToastsContainer) {
      this._observerToastsContainer.unobserve(this._elementToastsContainer);
      this._elementToastsContainer = undefined;
    }

    this._elementToastsContainer = elementRef?.nativeElement;

    if (this._elementToastsContainer) {
      this._observerToastsContainer.observe(this._elementToastsContainer);
      this._evaluateGap();
    }
  }

  private _evaluateGap(): void {
    if (!this._document || !this._elementToastsContainer) {
      return;
    }
    const window: Window = this._document.defaultView;
    const computedStyles: CSSStyleDeclaration = window.getComputedStyle(this._elementToastsContainer);
    const gap: number = parseFloat(computedStyles.getPropertyValue('gap'));
    this.toastsGap = Number.isNaN(gap) ? 0 : gap;
  }
}
