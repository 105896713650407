<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-dashboards" [ngSwitch]="type" [plPromise]="promise">
  <div *ngSwitchCase="types.Table" class="card card-table">
    <div class="card-body">
      <div class="table-dashboard">
        <div class="card card-primary">
          <div class="card-header">
            <h4 class="card-title">{{ resolvedDashboard?.title }}</h4>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr class="table-header">
                  <td *ngFor="let key of tableDataKeys" [class.text-end]="key.formatRight">{{ key.keyName }}</td>
                </tr>
              </thead>

              <tbody>
                <tr *ngIf="!resolvedTableData?.length">
                  <td class="text-center" style="padding: 30px 0">{{ locale.emptyData }}</td>
                </tr>
                <tr *ngFor="let item of resolvedTableData">
                  <td *ngFor="let key of tableDataKeys" [class.text-end]="key.formatRight">
                    <div [ngbTooltip]="getTableTooltip(item[key.keyName])">{{ getTableValue(key, item) | slice: 0 : (windowWidth <= 1440 ? 50 : 90) }}</div>
                  </td>
                </tr>
              </tbody>

              <tfoot *ngIf="showTotalTable && resolvedTableData?.length">
                <tr>
                  <td class="text-end" [attr.colspan]="tableDataKeys.length">{{ locale.total }}: {{ totalTable }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="types.Tile" class="card card-tile" [ngClass]="resolvedDashboard?.style">
    <div class="card-header">
      <div class="row">
        <div class="col-3">
          <span class="fa-2x" [cgcIcon]="resolvedDashboard?.icon"></span>
        </div>

        <div class="col-9 text-end">
          <span [plTranslate]="resolvedDashboard?.title"></span><br />
          <small [plTranslate]="resolvedDashboard?.subtitle"></small>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <h5>{{ resolvedTileData }}</h5>
    </div>
  </div>
</div>
