<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'pesquisaDocumentos.title'"></h4>
</div>

<div class="modal-body">
  <div class="form-group">
    <div class="entity-detail-form">
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'docsComerciaisEstatisticas.caption.ncontade'"></label>
            <edit>
              <entity-autocomplete entity="clifos" [model]="filters" [(selectedKey)]="filters.deConta" [fieldsMap]="{nConta: 'deConta'}" output="nConta" plAutoFocus></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="clifos" [model]="filters" [(selectedKey)]="filters.ateConta" [fieldsMap]="{nConta: 'ateConta'}" output="nConta"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'docsComerciaisEstatisticas.caption.datade'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="filters.dataDe"></pl-edit-datepicker>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="filters.dataAte"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'docsComerciaisEstatisticas.caption.docFa'"></label>
          <edit>
            <pl-select
              [(model)]="filters.docfalist"
              [multiple]="true"
              [source]="docFaSource"
              [multipleCheckbox]="true"
              [rowTemplate]="docFaTemplate"
              [valueField]="'nDocFa'"
              [output]="docFaOutput"
              [selectableGroup]="true"
              appendTo="body">
            </pl-select>
          </edit>
        </pl-group>

        <pl-group class="text-end">
          <button type="button" class="btn btn-primary" id="btnPesquisar" [click]="fnPesquisar" plPromise focus>
            <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'docsComerciaisEstatisticas.pesquisa'"></span>
          </button>
        </pl-group>
      </pl-form>

      <br />

      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        cgDxDataGridInstanceName="movimentosPendentes"
        [dataSource]="docsList"
        (onInitialized)="onInitialized($event)"
        (onContentReady)="onContentReady()"
        (onCellClick)="onCellClick($event)">
        <div *dxTemplate="let item of 'detailTemplateDocFaturacao'">
          <documento-faturacao [doc]="item.data._thedoc"></documento-faturacao>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button [attr.name]="'btnDuplicarDoc'" type="button" class="btn btn-primary btn-sm" [click]="fnDuplicateDoc" plPromise [disabled]="!selectedDoc || differentGrupoFa || docStateType === 'edit'">
    <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'docscomerciais.auxiliares.duplicarDocumento'"></span>
  </button>

  <button [attr.name]="'btnCopiarLinhas'" type="button" class="btn btn-primary btn-sm" [click]="fnCopyLines" plPromise [disabled]="!selectedDoc">
    <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'docscomerciais.auxiliares.copiarLinhasDocumento'"></span>
  </button>

  <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
