import {difference, merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional, Renderer2, SimpleChanges, SkipSelf} from '@angular/core';
import {AbstractControl, FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {generateUniqueID, isBoolean, isEmpty} from '../../../common/utilities/utilities';
import {IPlCompsServiceConfig, IPlCompsServiceConfigEditForm, TOrientation} from '../../../common/interface';
import type {IPlEditBaseComponent} from '../../component/edit.component.interface';
import type {IPlEditGroupOptions} from './edit.group.interface';
import {PlCompsService} from '../../../common/service/comps.service';
import {PlEditFormToken} from '../form/form.token';
import {PlEditGroupToken} from './edit.group.token';

const DEFAULT_VERTICAL_SUB_GROUP_CLASS = 'col-sm-6 col-md';
const DEFAULT_HORIZONTAL_LABEL_CLASS = 'col-md-2';
const DEFAULT_HORIZONTAL_EDIT_CLASS = 'col-md-8';
const DEFAULT_HORIZONTAL_ACTIONS_CLASS = 'col-md-2';
const DEFAULT_SUB_HORIZONTAL_EDIT_CLASS = 'col-md-3';

@Component({
  selector: 'pl-group',
  templateUrl: './edit.group.component.html',
  standalone: false,
  providers: [{provide: PlEditGroupToken, useExisting: PlEditGroupComponent}],
  exportAs: 'cgcGroup'
})
export class PlEditGroupComponent extends PlEditGroupToken implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() public attrName: string;
  @Input() public labelClass: string;
  @Input() public editClass: string;
  @Input() public actionsClass: string;
  @Input() public subGroupClass: string;
  @Input() public orientation: TOrientation;
  @Input() public hasError: boolean;
  @Input() public properties: IPlEditGroupOptions | any;

  public klass: string;
  public klassLabel: string;
  public klassEdit: string;
  public klassActions: string;
  public klassSubGroup: string;
  public hasParent: boolean;
  public validationHasError: boolean;
  public hideActions: boolean;

  private readonly _formGroup: UntypedFormGroup;
  private readonly _element: HTMLElement;
  private readonly _groups: Array<PlEditGroupComponent>;
  private readonly _edits: Array<IPlEditBaseComponent<any>>;
  private readonly _subscriptionPlCompsConfig: Subscription;
  private _ngFormInstance: FormGroupDirective;
  private _plCompsConfig: IPlCompsServiceConfigEditForm;
  private _defaultOptions: IPlEditGroupOptions;
  private _previousSubGroupClasses: Array<string>;

  constructor(
    @SkipSelf() @Optional() private readonly _plGroup: PlEditGroupComponent,
    @Optional() private readonly _plForm: PlEditFormToken,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    private readonly _plCompsService: PlCompsService
  ) {
    super();
    this._element = this._elementRef.nativeElement;
    this._defaultOptions = Object.freeze<IPlEditGroupOptions>({
      orientation: 'horizontal',
      labelClass: undefined,
      editClass: undefined,
      actionsClass: undefined,
      subGroupClass: undefined,
      hideActions: undefined,
      visible: undefined
    });
    this.hasParent = false;
    this.validationHasError = false;
    this.hideActions = this._defaultOptions.hideActions;
    this._formGroup = new UntypedFormGroup({});
    this._groups = [];
    this._edits = [];
    this._subscriptionPlCompsConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._plCompsConfig = config.plEditForm;
      this._defaultOptions = Object.freeze<IPlEditGroupOptions>({
        orientation: this._plCompsConfig.orientation || 'horizontal',
        labelClass: undefined,
        editClass: undefined,
        actionsClass: undefined,
        subGroupClass: undefined,
        hideActions: undefined,
        visible: undefined
      });
    });
  }

  public ngOnInit(): void {
    if (isEmpty(this.attrName)) {
      this.attrName = generateUniqueID('plGroup');
    }
    const initialOptions: Partial<IPlEditGroupOptions> = {};
    if (this._plForm?.properties) {
      merge(initialOptions, this._plForm.properties);
    }
    if (this._plGroup?.properties) {
      merge(initialOptions, this._plGroup.properties);
    }
    this.options = merge({}, this._defaultOptions, initialOptions, this.options, this.properties);
    this._handleChanges();

    // If component has parent
    if (this._plGroup) {
      this._initChild();
      this._plGroup._addGroup(this);
      this._plGroup.addControl(this.attrName, this.formGroup);
    } else if (this._plForm) {
      this._plForm.addGroup(this);
      this._plForm.addControl(this.attrName, this.formGroup);
    }
  }

  public ngOnChanges({orientation, labelClass, editClass, actionsClass, subGroupClass, hasError, properties}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this.updateGroup(properties.currentValue);
    } else {
      const changedOrientation: boolean = orientation && !orientation.isFirstChange();
      const changedLabelClass: boolean = labelClass && !labelClass.isFirstChange();
      const changedEditClass: boolean = editClass && !editClass.isFirstChange();
      const changedActionsClass: boolean = actionsClass && !actionsClass.isFirstChange();
      const changedSubGroupClass: boolean = subGroupClass && !subGroupClass.isFirstChange();
      const changedHasError: boolean = hasError && !hasError.isFirstChange();
      if (changedOrientation || changedLabelClass || changedEditClass || changedActionsClass || changedHasError) {
        if (changedOrientation) {
          this._changedOrientation(orientation.currentValue);
        }
        if (changedLabelClass) {
          this._changedLabelClass(labelClass.currentValue);
        }
        if (changedEditClass) {
          this._changedEditClass(editClass.currentValue);
        }
        if (changedActionsClass) {
          this._changedActionsClass(actionsClass.currentValue);
        }
        if (changedSubGroupClass) {
          this._changedSubGroupClass(subGroupClass.currentValue);
        }
        if (changedHasError) {
          this._changedHasError(hasError.currentValue);
        }
        this.evaluateKlass();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionPlCompsConfig.unsubscribe();
    if (this._plGroup) {
      this._plGroup.removeControl(this.attrName);
      this._plGroup._removeGroup(this);
    } else if (this._plForm) {
      this._plForm.removeControl(this.attrName);
      this._plForm.removeGroup(this);
    }
  }

  public ngAfterViewInit(): void {
    if (!this._ngFormInstance) {
      if (this._plGroup?.ngFormInstance) {
        this._ngFormInstance = this._plGroup.ngFormInstance;
      } else if (this._plForm?.ngFormInstance) {
        this._ngFormInstance = this._plForm.ngFormInstance;
      }
    }
    if (this._ngFormInstance) {
      for (const edit of this._edits) {
        edit.ngFormInstance = this._ngFormInstance;
      }
    }
  }

  public addEdit(plEdit: IPlEditBaseComponent<any>): void {
    if (!this._edits.includes(plEdit)) {
      this._edits.push(plEdit);
    }
  }

  public removeEdit(name: string): void {
    for (let i = 0; i < this._edits.length; i++) {
      const edit: IPlEditBaseComponent<any> = this._edits[i];
      if (edit.attrName === name) {
        this.removeControl(edit.attrName);
        this._edits.splice(i, 1);
        break;
      }
    }
  }

  public updateGroup(properties: Partial<IPlEditGroupOptions>): void {
    if (properties && properties !== this.options) {
      this.options = merge({}, this._defaultOptions, this.options, properties, this.properties);
      this._handleChanges();
      if (this._plGroup) {
        this._initChild();
      }
      this._updateChildComponents(this.options);
    }
  }

  public valueChanged(value: unknown, field: string): void {
    if (this._plForm) {
      this._plForm.valueChanged(value, field);
    }
  }

  public addControl(name: string, control: AbstractControl): void {
    if (this.hasParent) {
      this._plGroup.addControl(name, control);
    } else if (this._plForm) {
      this._plForm.addControl(name, control);
    } else {
      this.formGroup.registerControl(name, control);
    }
  }

  public removeControl(name: string): void {
    if (this.hasParent) {
      this._plGroup.removeControl(name);
    } else if (this._plForm) {
      this._plForm.removeControl(name);
    } else {
      this.formGroup.removeControl(name);
    }
  }

  public setHasError(hasError: boolean): void {
    this.validationHasError = hasError;
    this.evaluateKlass();
  }

  public evaluateKlass(): void {
    const subGroupClassesToAdd: Array<string> = [`form-${this.orientation}`, 'cgc-form-group'];
    const klass: Array<string> = [];
    const klassSubGroup: Array<string> = [];
    if (this.hasError || this.validationHasError) {
      klass.push('has-error');
    }
    if (this.orientation === 'vertical') {
      this.klassLabel = this.labelClass || undefined;
      this.klassEdit = this.klassEdit || undefined;
      this.klassActions = this.klassActions || undefined;
      subGroupClassesToAdd.push(...(this.subGroupClass ? this.subGroupClass : this.hasParent ? DEFAULT_VERTICAL_SUB_GROUP_CLASS : '').split(' '));
      klass.push('pl-edit-group-flex');
      klassSubGroup.push('form-row');
    } else {
      klass.push('form-row');
      this.klassLabel = this.labelClass || DEFAULT_HORIZONTAL_LABEL_CLASS;
      this.klassActions = this.actionsClass || DEFAULT_HORIZONTAL_ACTIONS_CLASS;
      if (!this.hasParent) {
        this.klassEdit = this.editClass || DEFAULT_HORIZONTAL_EDIT_CLASS;
        klass.push('clearfix');
        klassSubGroup.push('clearfix');
      } else {
        this.klassEdit = this.editClass || DEFAULT_SUB_HORIZONTAL_EDIT_CLASS;
      }
    }
    this.klass = klass.join(' ');
    this.klassSubGroup = klassSubGroup.join(' ');
    for (const className of subGroupClassesToAdd) {
      if (!className) {
        continue;
      }
      this._renderer.addClass(this._element, className);
    }
    if (this._previousSubGroupClasses) {
      const subGroupClassesToRemove = difference(this._previousSubGroupClasses, subGroupClassesToAdd);
      for (const className of subGroupClassesToRemove) {
        if (!className) {
          continue;
        }
        this._renderer.removeClass(this._element, className);
      }
    }
    this._previousSubGroupClasses = subGroupClassesToAdd;
  }

  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  public get ngFormInstance(): FormGroupDirective {
    return this._ngFormInstance;
  }

  public set ngFormInstance(value: FormGroupDirective) {
    this._ngFormInstance = value;
    if (this._ngFormInstance) {
      for (const edit of this._edits) {
        if (!edit.ngFormInstance) {
          edit.ngFormInstance = this._ngFormInstance;
        }
      }
    }
  }

  private _initChild(): void {
    this.hasParent = true;
    this.evaluateKlass();
  }

  private _addGroup(group: PlEditGroupComponent): void {
    if (!this._groups.includes(group)) {
      if (this.orientation === 'horizontal') {
        const previousLastGroup: PlEditGroupComponent = this._groups[this._groups.length - 1];
        if (previousLastGroup) {
          previousLastGroup.hideActions = true;
        }
        group.hideActions = false;
      }
      this._groups.push(group);
      for (const groupItem of this._groups) {
        groupItem.evaluateKlass();
      }
    }
  }

  private _removeGroup(group: PlEditGroupComponent): void {
    const groupIndex: number = this._groups.indexOf(group);
    if (groupIndex > -1) {
      this._groups.splice(groupIndex, 1);
      if (this._groups.length > 0) {
        const currentLastGroup: PlEditGroupComponent = this._groups[this._groups.length - 1];
        if (currentLastGroup.hideActions) {
          currentLastGroup.hideActions = false;
        }
      }
    }
  }

  private _updateChildComponents(options: IPlEditGroupOptions): void {
    // Child groups
    for (const childGroup of this._groups) {
      childGroup.updateGroup(options);
    }

    // Child plEdit's
    for (const plEdit of this._edits) {
      plEdit.updateComponent(options);
    }
  }

  private _handleChanges(): void {
    this._changedOrientation();
    this._changedLabelClass();
    this._changedEditClass();
    this._changedActionsClass();
    this._changedSubGroupClass();
    this._changedHasError();
    this.evaluateKlass();
  }

  private _changedOrientation(value: TOrientation = this.orientation): void {
    let val: TOrientation = value;
    if (isEmpty(val)) {
      val = this.options.orientation;
    }
    if (isEmpty(val)) {
      val = this._plCompsConfig.orientation;
    }
    if (isEmpty(val)) {
      val = 'vertical';
    }
    this.orientation = val;
  }

  private _changedLabelClass(value: string = this.labelClass): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this.options.labelClass;
    }
    this.labelClass = val;
  }

  private _changedEditClass(value: string = this.editClass): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this.options.editClass;
    }
    this.editClass = val;
  }

  private _changedActionsClass(value: string = this.actionsClass): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this.options.actionsClass;
    }
    this.actionsClass = val;
  }

  private _changedSubGroupClass(value: string = this.subGroupClass): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this.options.subGroupClass;
    }
    this.subGroupClass = val;
  }

  private _changedHasError(value: boolean = this.hasError): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.hasError = val;
  }
}
