import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlEditMultiSelectCallback, IPlFormDefinition, IPlFormTemplate, PlAlertService} from 'pl-comps-angular';
import {ContabilidadeDocsNaoSaldadosService} from '../../../../modules/portalcontabilidade/manutencao/docsnaosaldados/contabilidade.docsNaoSaldados.module.service';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {FimPeriodoEncModalComponent} from '../../modals/fimPeriodoEnc.modal.component';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IFimPeriodoEnc, IJsonFimPeriodo} from '../../jsonFimPeriodoEnc.entity.interface';
import {IJsonDiario} from '../../../diarios/jsonDiario.entity.interface';
import {IJsonPeriodo} from '../../../periodos/jsonPeriodo.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ENTITY_NAME_FIM_PERIODO_ENC, IFimPeriodoEncEntityService} from '../../fimPeriodoEnc.entity.interface';
import {IJsonString} from '../../../../../common/interfaces/json';
import {IJsonErroList} from '../../../../interfaces/jsonErro.interface';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'fim-periodoenc-edit',
  templateUrl: './fimPeriodoEnc.entity.edit.component.html'
})
export class FimPeriodoEncEditComponent extends ModuloEntityDetailComponent<IFimPeriodoEnc> implements OnInit {
  public definition: IPlFormDefinition;
  public template: IPlFormTemplate;
  public diariosTemplate: string;
  public plMultiselect: IPlEditMultiSelectCallback;

  private _diariosResponse: IEntityService<IJsonDiario>;
  private _periodosResponse: IEntityService<IJsonPeriodo>;
  private _totalPossiveis: number;
  private _periodos: Array<IJsonPeriodo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _docsNaoSaldadosService: ContabilidadeDocsNaoSaldadosService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._periodos = [];
    this._diariosResponse = this._entityServiceBuilder.build<IJsonDiario>('diarios');
    this._periodosResponse = this._entityServiceBuilder.build<IJsonPeriodo>('periodos');
    super.ngOnInit();
    this.plMultiselect = {};
    this.diariosTemplate = '{{nDiario}} - {{nome}}';

    this._periodosResponse.query().then((response: HttpResponse<IApiQueryResponse<IJsonPeriodo>>) => {
      this._periodos = response.body.list;
    });
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.definition = this.entity
      .fieldEvents('flagRigorAbsoluto')
      .change((value: boolean) => {
        this._changedFlag(value);
      })
      .build(stateType);
    this.template = this.entity[stateType].template;
    this._loadDiarios(stateType !== EEntityStateDetailType.NEW);
    return super.onUpdate(stateType);
  }

  public changedDiarios(diarios: Array<IJsonDiario>): void {
    this.model.flagRigorAbsoluto = diarios && diarios.length === this._totalPossiveis;
  }

  public async save(): Promise<IFimPeriodoEnc> {
    if (this.model.diarios?.length > 0) {
      const responseDocs: HttpResponse<IJsonErroList> = await this._docsNaoSaldadosService.getDocs(this.model.periodo, this.model.periodo, '1', '89999999999');

      if (responseDocs?.body?.erros?.length) {
        await this._cgModalService.showOkCancel('global.text.warning', 'fimperiodoenc.temDocsNSald').catch((reason: unknown) => {
          this._logger.error(reason);
          return Promise.reject();
        });
      }
    }

    return super.save().then((response: IFimPeriodoEnc) => {
      if (this.model.diarios.length > 0) {
        this._stateService.go(this.states.detail.name, {id: response.periodo, model: response});
      }
      this._changePeriodAndSave();
      return response;
    });
  }

  private _changePeriodAndSave(): Promise<void> {
    return this._entityServiceBuilder
      .build<IJsonFimPeriodo, IFimPeriodoEncEntityService>(ENTITY_NAME_FIM_PERIODO_ENC)
      .postPerioDisponivel(this._periodos, this.model.periodo)
      .then((response: HttpResponse<IJsonString>) => {
        const modalInstance = this._cgModalService.showVanilla(FimPeriodoEncModalComponent);
        const componentInstance: FimPeriodoEncModalComponent = modalInstance.componentInstance;
        componentInstance.periodoDisponivel = response.body.value;
        componentInstance.periodos = this._periodos;
        return modalInstance.result.then(() => {
          this._plAlertService.success('fimperiodoenc.messages.successfullychanged');
        });
      });
  }

  private _loadDiarios(filterDiarios: boolean): void {
    this._setDefaultModel();
    this._diariosResponse.query().then((response) => {
      this.model.diariosPossiveis = response.body.list;
      this._totalPossiveis = this.model.diariosPossiveis.length;
      if (filterDiarios) {
        if (!this.model.flagRigorAbsoluto) {
          this.model.diariosPossiveis = this.model.diariosPossiveis.filter((diarioPossivel) => {
            return this.model.diarios.findIndex((diarioSelecionado) => diarioSelecionado.nDiario === diarioPossivel.nDiario) === -1;
          });
        } else {
          this.model.diarios = this.model.diariosPossiveis.slice();
          this.model.diariosPossiveis = [];
        }
      }
    });
  }

  private _setDefaultModel(): void {
    this.model = {
      diarios: [],
      diariosPossiveis: [],
      flagRigorAbsoluto: false,
      nomePeriodo: '',
      observacoes: '',
      periodo: this._configService.configurations.empresa.periodo,
      ...this.model
    };
    this._periodosResponse.get({id: this.model.periodo}).then((response) => {
      this.model.nomePeriodo = response.body.nome;
    });
  }

  private _changedFlag(value: boolean): void {
    if (value) {
      this.plMultiselect.allRight();
    } else {
      this.plMultiselect.allLeft();
    }
  }
}
