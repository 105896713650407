<div class="card documento-faturacao">
  <div class="card-header">
    <div class="card-title">
      <span class="fatura-title"><strong [translate]="'transferenciasarmazens.detail.cab.documentos'"></strong>: {{ doc.cab.numeroOrigem }} - {{ doc.cab.numeroDestino }}</span>

      <span class="fatura-data">
        <strong><span [translate]="'global.text.date'"></span>:</strong>&nbsp;<span>{{ doc.cab.dataDoc | cgcDate }}</span>
      </span>
    </div>
  </div>

  <div class="card-body card-documento">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="tableDocsRecibos" [dataSource]="doc.linhas"></dx-data-grid>
  </div>
</div>
