import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {EMPTY_PASSWORD} from '../../../../config/constants';
import {ESimpleLoginType, ISimpleLoginCredentials} from '../simpleLogin.module.interface';
import {SimpleLoginService} from '../simpleLogin.module.service';
import {isUndefinedOrNull} from 'pl-comps-angular';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'simple-login-modal',
  templateUrl: './simpleLogin.modal.component.html'
})
export class SimpleLoginModalComponent extends CGModalComponent<ISimpleLoginCredentials> implements OnInit {
  @Input() public loginType: ESimpleLoginType;
  public model: ISimpleLoginCredentials;
  public userNameLabel: string;
  public logoImageSource: string;
  public promise: TServiceResponse<ISimpleLoginCredentials>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _simpleLoginService: SimpleLoginService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.model = {username: '', password: EMPTY_PASSWORD};
  }

  public ngOnInit(): void {
    if (isUndefinedOrNull(this.loginType)) {
      this.loginType = ESimpleLoginType.AT;
    }
    switch (this.loginType) {
      case ESimpleLoginType.AT:
        this.logoImageSource = '../../../assets/images/logo-at.webp';
        this.userNameLabel = 'simpleLogin.at.userNameFieldLabel';
        this.promise = this._simpleLoginService.getAtCredentials();
        break;
      case ESimpleLoginType.SSD:
        this.logoImageSource = '../../../assets/images/logo-ssd.svg';
        this.userNameLabel = 'simpleLogin.ssd.userNameFieldLabel';
        this.promise = this._simpleLoginService.getSDDCredentials();
        break;
      case ESimpleLoginType.VIACTT:
        this.logoImageSource = '../../../assets/images/logo-viactt.jpg';
        this.userNameLabel = 'simpleLogin.viactt.userNameFieldLabel';
        this.promise = this._simpleLoginService.getViaCTTCredentials();
        break;
      case ESimpleLoginType.EFATURA:
        this.logoImageSource = '../../../assets/images/logo-efatura-full-bg-white.png';
        this.userNameLabel = 'simpleLogin.at.userNameFieldLabel';
        this.promise = this._simpleLoginService.getEFaturaCredentials();
        break;
    }

    this.promise.then((response) => {
      this.model = {...response.body};
    });
  }

  public close(): Promise<void> {
    if (this.isSaveBtnDisabled()) {
      return Promise.reject(new Error(this._translateService.instant('simpleLogin.messages.credentialsEmpty')));
    }
    switch (this.loginType) {
      case ESimpleLoginType.AT:
        this.promise = this._simpleLoginService.saveAtCredentials(this.model);
        break;
      case ESimpleLoginType.SSD:
        this.promise = this._simpleLoginService.saveSDDCredentials(this.model);
        break;
      case ESimpleLoginType.VIACTT:
        this.promise = this._simpleLoginService.saveViaCTTCredentials(this.model);
        break;
      case ESimpleLoginType.EFATURA:
        this.promise = this._simpleLoginService.saveEFaturaCredentials(this.model);
        break;
    }

    return this.promise.then((response) => {
      this.model.username = response.body.username;
      this.model.password = response.body.password;
      super.close(this.model);
    });
  }

  public isSaveBtnDisabled(): boolean {
    return this.model.password === EMPTY_PASSWORD || this.model.password === '' || isUndefinedOrNull(this.model.password) || this.model.username === '' || isUndefinedOrNull(this.model.username);
  }
}
