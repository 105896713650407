import {DashboardsERPModuleComponent} from './components/dashBoardsERP.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_DASHBOARDS_ERP} from './dashBoardsERP.module.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_DASHBOARDS_ERP: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARDS_ERP,
  nameAlias: ['dashboards'],
  state: {
    url: '/dashboard',
    component: DashboardsERPModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    }
  }
};
