import {HttpResponse} from '@angular/common/http';
import {MODULE_NAME_ADIANTAMENTOS_CLIENTES, MODULE_NAME_ADIANTAMENTOS_FORNECEDORES, TAdiantamentosType} from './adiantamentos.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {AdiantamentosModuleComponent} from './components/adiantamentos.module.component';
import {AdiantamentosService} from './adiantamentos.module.service';
import {IJsonAdiantamentoConfig} from './jsonAdiantamentos.module.interface';

export const MODULE_ADIANTAMENTOS_CLIENTES: IModuleDefinition = generateModule(MODULE_NAME_ADIANTAMENTOS_CLIENTES, 'clientes');

export const MODULE_ADIANTAMENTOS_FORNECEDORES: IModuleDefinition = generateModule(MODULE_NAME_ADIANTAMENTOS_FORNECEDORES, 'fornecedores');

function generateModule(moduleName: string, type: TAdiantamentosType): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/adiantamentos/${type}`,
      component: AdiantamentosModuleComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: `global.menu.${moduleName}`,
        sidebarTitle: 'portals.sidebar.modules.adiantamentos'
      },
      params: {
        clifo: {
          type: 'string',
          value: ''
        },
        nomeClifo: {
          type: 'string',
          value: ''
        },
        faccbId: {
          type: 'int',
          value: 0
        },
        ndocfa: {
          type: 'int',
          value: 0
        },
        nnumer: {
          type: 'int',
          value: 0
        },
        doc: {
          type: 'any',
          value: null
        }
      },
      resolve: [
        {
          provide: 'type',
          useFactory: () => type
        },
        {
          provide: 'configAdiantamentos',
          deps: [AdiantamentosService],
          useFactory: (adiantamentosService: AdiantamentosService): Promise<IJsonAdiantamentoConfig> =>
            adiantamentosService.adiantamentosConfig().then((response: HttpResponse<IJsonAdiantamentoConfig>) => response.body)
        }
      ]
    }
  };
}
