import {merge} from 'lodash-es';
import moment from 'moment';
import {BehaviorSubject, combineLatest, fromEvent, lastValueFrom, merge as rxMerge, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, skip} from 'rxjs/operators';
import dxThemes from 'devextreme/ui/themes';
import {refreshTheme as dxRefreshTheme} from 'devextreme/viz/themes';
import {Inject, Injectable, Injector, OnDestroy, Optional} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {
  IPlCookiesConsent,
  IPlFormatConfig,
  isArray,
  isMobile as cgcIsMobile,
  LoadingQueue,
  Logger,
  PlCookiesConsentService,
  PlDocumentService,
  PlFormatService,
  PlLocaleService,
  PlPageWrapperService
} from 'pl-comps-angular';
import {APP_LAUNCH_MODE} from '../../../common/api';
import {
  APP_LOCALE,
  EStatusCode,
  isProduction,
  LOCAL_STORAGE_APP_THEME,
  LOCAL_STORAGE_CONSENT,
  LOCAL_STORAGE_LOCALE,
  LOCAL_STORAGE_REFRESHED,
  LOCAL_STORAGE_SIDEBAR_TOGGLED
} from '../../../config/constants';
import {APP_LOCALES} from '../../../common/i18n/locales';
import {APP_THEMES, EAppTheme, IAppThemeChangeEvt} from '../../../common/themes/themes.interface';
import {CGLocalStorageService} from '../storage/localstorage.service';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../components/state/cg.state.service';
import {DEFAULT_COOKIES_CONSENT, SCHEMA_CONSENT} from '../../components/cg/cookieconsent/cg.cookies.consent.interface';
import {EAppLaunchMode} from '../../../common/site';
import {FUTURE_STATE_NAME_PORTAL, ICGStateDeclaration, ICGStateDeclarationData} from '../portals/portals.service.interface';
import {handleFirstPageLoad} from '../../../config/pageload';
import {IAppStatus} from './app.service.interface';
import {SCHEMA_BOOLEAN} from '../../../common/schemas';
import {STATE_NAME_DISCONNECTED} from '../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../states/account/login/login.state.interface';

const GLOBAL_PAGE_TITLE = 'CentralGest Cloud';
const CSS_CLASS_DOCUMENT_DRAGGING = 'cg-document-dragging';

// Skip initial BehaviorSubject and local storage load
const PAGE_WRAPPER_TOGGLED_SKIP = 2;

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {
  private readonly _document: Document;
  private readonly _subjectStatus: BehaviorSubject<IAppStatus>;
  private readonly _subjectCookiesConsentOpen: BehaviorSubject<boolean>;
  private readonly _subjectTheme: BehaviorSubject<EAppTheme>;
  private readonly _subjectPageUnload: Subject<void>;
  private readonly _loadingQueue: LoadingQueue;
  private readonly _subscriptionAppLaunchMode: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _subscriptionSidebarToggled: Subscription;
  private readonly _subscriptionPageHide: Subscription;
  private readonly _subscriptionPrefersColorSchemeDark: Subscription;
  private readonly _subscriptionOnStart: Subscription;
  private readonly _subscriptionOnSuccess: Subscription;
  private readonly _subscriptionOnError: Subscription;
  private readonly _subscriptionDocumentDragging: Subscription;
  private _modalService: CGModalService;
  private _firstLoad: boolean;
  private _isMobile: boolean;
  private _toggled: boolean;
  private _storedToggled: boolean;
  private _prefersColorSchemeDark: boolean;
  private _previousTheme: EAppTheme;
  private _observableStatus: Observable<IAppStatus>;
  private _observableCookiesConsentOpen: Observable<boolean>;
  private _observableGlobalLoading: Observable<boolean>;
  private _observableTheme: Observable<IAppThemeChangeEvt>;
  private _observablePageHide: Observable<void>;
  private _observablePageUnload: Observable<void>;
  private _observableLanguage: Observable<string>;
  private _subscriptionCookiesConsent: Subscription;

  constructor(
    @Inject(DOCUMENT) @Optional() document: unknown,
    private readonly _injector: Injector,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _stateService: StateService,
    private readonly _translateService: TranslateService,
    private readonly _logger: Logger,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plFormatService: PlFormatService,
    private readonly _plPageWrapperService: PlPageWrapperService,
    private readonly _plCookiesConsentService: PlCookiesConsentService,
    private readonly _cgLocalStorageService: CGLocalStorageService,
    private readonly _cgStateService: CGStateService
  ) {
    this._document = <Document>document;
    this._subjectStatus = new BehaviorSubject<IAppStatus>({
      launchMode: APP_LAUNCH_MODE.value,
      refreshed: false,
      disconnected: false,
      maintenance: false
    });
    this._subjectCookiesConsentOpen = new BehaviorSubject<boolean>(false);
    this._subjectTheme = new BehaviorSubject<EAppTheme>(undefined);
    this._subjectPageUnload = new Subject<void>();
    this._loadingQueue = new LoadingQueue(this._logger);
    this._firstLoad = true;
    this._isMobile = cgcIsMobile();
    this._toggled = !this._isMobile;
    this._plPageWrapperService.setToggled(this._toggled);

    this._initPageWrapper();

    this._subscriptionAppLaunchMode = APP_LAUNCH_MODE.subscribe((launchMode: EAppLaunchMode) => {
      if (launchMode === EAppLaunchMode.HybridPartial && this._toggled) {
        this._plPageWrapperService.setToggled(false);
      }
      this.setStatus({launchMode: launchMode});
    });

    this._subscriptionIsMobile = this._plDocumentService
      .isMobile()
      .pipe(skip(1))
      .subscribe((isMobile: boolean) => {
        this._isMobile = isMobile;
        if (!this._isMobile && this._storedToggled) {
          this._plPageWrapperService.setToggled(true);
        }
        if (this._isMobile && this._storedToggled) {
          this._plPageWrapperService.setToggled(false);
        }
      });

    this._subscriptionSidebarToggled = this._plPageWrapperService
      .toggled()
      .pipe(skip(PAGE_WRAPPER_TOGGLED_SKIP))
      .subscribe((toggled: boolean) => {
        this._toggled = toggled;
        if (!this._isMobile && this._subjectStatus.value.launchMode !== EAppLaunchMode.HybridPartial) {
          this._storedToggled = this._toggled;
          this._cgLocalStorageService.setItem(LOCAL_STORAGE_SIDEBAR_TOGGLED, this._toggled, SCHEMA_BOOLEAN).subscribe();
        }
      });

    this._subscriptionPageHide = this._onPageHide().subscribe(() => {
      if (this._document) {
        this._document.defaultView.sessionStorage.setItem(LOCAL_STORAGE_REFRESHED, JSON.stringify(true));
      }
    });

    this._subscriptionPrefersColorSchemeDark = this._plDocumentService.prefersColorSchemeDark().subscribe((prefersColorSchemeDark: boolean) => {
      this._prefersColorSchemeDark = prefersColorSchemeDark;
      if (!this._firstLoad) {
        this.setTheme(this._subjectTheme.value, false);
      }
    });

    this._subscriptionOnStart = this._cgStateService.routerTransitionOnStart().subscribe((transition: Transition) => {
      const toState: ICGStateDeclaration = <ICGStateDeclaration>transition.to();
      this.setGlobalLoading(isArray(toState.resolve));
      if (toState.data?.disableRecover) {
        if (!this._modalService) {
          // Avoid "Circular dependency in DI" error when injected by constructor declaration
          this._modalService = this._injector.get<CGModalService>(CGModalService);
        }
        this._modalService.dismissAll(`DISMISS_ALL_STATE_NAME-${toState.name}`);
      }
    });

    this._subscriptionOnSuccess = this._cgStateService.routerTransitionOnSuccess().subscribe((transition: Transition) => {
      this.setGlobalLoading(false);
      if (transition.to().name !== FUTURE_STATE_NAME_PORTAL || transition.from().name) {
        this._checkFirstLoad();
      }
    });

    this._subscriptionOnError = this._cgStateService.routerTransitionOnError().subscribe((transition: Transition) => {
      this.setGlobalLoading(false);

      if (transition.to().name === FUTURE_STATE_NAME_PORTAL && !transition.from().name) {
        const detail: unknown = transition.error()?.detail;
        if (detail && detail instanceof HttpErrorResponse) {
          if (detail.status === EStatusCode.NoResponse) {
            this._stateService.go(STATE_NAME_DISCONNECTED);
          } else {
            this._stateService.go(STATE_NAME_LOGIN);
          }
        }
      } else {
        this._checkFirstLoad();
      }
    });

    this._subscriptionDocumentDragging = this._plDocumentService.dragging().subscribe((documentDragging: boolean) => {
      if (!this._document) {
        return;
      }
      if (documentDragging) {
        this._document.body.classList.add(CSS_CLASS_DOCUMENT_DRAGGING);
      } else {
        this._document.body.classList.remove(CSS_CLASS_DOCUMENT_DRAGGING);
      }
    });
  }

  public ngOnDestroy(): void {
    this._subjectStatus.complete();
    this._subjectCookiesConsentOpen.complete();
    this._subjectTheme.complete();
    this._subjectPageUnload.complete();

    this._subscriptionAppLaunchMode.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
    this._subscriptionSidebarToggled.unsubscribe();
    this._subscriptionPageHide.unsubscribe();
    this._subscriptionPrefersColorSchemeDark.unsubscribe();
    this._subscriptionOnStart.unsubscribe();
    this._subscriptionOnSuccess.unsubscribe();
    this._subscriptionOnError.unsubscribe();
    this._subscriptionDocumentDragging.unsubscribe();

    if (this._subscriptionCookiesConsent) {
      this._subscriptionCookiesConsent.unsubscribe();
    }

    if (this._loadingQueue) {
      this._loadingQueue.dispose();
    }
  }

  public status(): Observable<IAppStatus> {
    if (!this._observableStatus) {
      this._observableStatus = this._subjectStatus.asObservable();
    }
    return this._observableStatus;
  }

  public cookiesConsentOpen(): Observable<boolean> {
    if (!this._observableCookiesConsentOpen) {
      this._observableCookiesConsentOpen = this._subjectCookiesConsentOpen.asObservable().pipe(distinctUntilChanged());
    }
    return this._observableCookiesConsentOpen;
  }

  public globalLoading(): Observable<boolean> {
    if (!this._observableGlobalLoading) {
      this._observableGlobalLoading = this._loadingQueue.loading();
    }
    return this._observableGlobalLoading;
  }

  public theme(): Observable<IAppThemeChangeEvt> {
    if (!this._observableTheme) {
      this._observableTheme = combineLatest([this._subjectTheme, this._plDocumentService.prefersColorSchemeDark()]).pipe(
        map(([theme, prefersColorSchemeDark]: [EAppTheme, boolean]) => {
          return Object.freeze({
            theme: theme,
            prefersColorSchemeDark: prefersColorSchemeDark,
            previousTheme: this._previousTheme
          } satisfies IAppThemeChangeEvt);
        })
      );
    }
    return this._observableTheme;
  }

  public triggerPageUnload(): void {
    this._subjectPageUnload.next();
  }

  public onPageUnload(): Observable<void> {
    if (!this._observablePageUnload) {
      this._observablePageUnload = rxMerge(this._subjectPageUnload.asObservable(), this._onPageHide()).pipe(map(() => undefined));
    }
    return this._observablePageUnload;
  }

  public setStatus(value: Partial<IAppStatus>): void {
    this._subjectStatus.next(
      Object.freeze<IAppStatus>({
        ...this._subjectStatus.value,
        ...value
      })
    );
  }

  public setCookiesConsentOpen(value: boolean): void {
    this._subjectCookiesConsentOpen.next(value);
  }

  public setGlobalLoading(value: boolean | Promise<unknown> | Observable<unknown>, clearLoadingQueue: boolean = false): void {
    this._loadingQueue.setLoading(value, clearLoadingQueue);
  }

  public setTheme(theme: EAppTheme, persist: boolean = true): void {
    if (!APP_THEMES.includes(theme)) {
      this._logger.error(new Error(`Tried to set an invalid theme: ${theme}`));
      return;
    }

    const changed: boolean = theme !== this._subjectTheme.value;

    if (changed) {
      this._previousTheme = this._subjectTheme.value;
      this._subjectTheme.next(theme);
      if (persist && this._document?.defaultView) {
        this._document.defaultView.localStorage.setItem(LOCAL_STORAGE_APP_THEME, this._subjectTheme.value);
      }
    }

    if (changed || this._subjectTheme.value === EAppTheme.Auto) {
      let newTheme: string = this._subjectTheme.value;

      if (newTheme === EAppTheme.Auto) {
        if (this._document) {
          newTheme = this._prefersColorSchemeDark ? EAppTheme.Dark : EAppTheme.Light;
        } else {
          newTheme = EAppTheme.Light;
        }
      }

      const dxTheme = `material.blue.${newTheme === EAppTheme.Dark ? 'dark' : 'light'}.compact`;
      dxThemes.current(dxTheme);
      if (!this._firstLoad) {
        dxRefreshTheme();
      }

      if (this._document) {
        newTheme = newTheme.toLowerCase();
        this._document.documentElement.setAttribute('data-cg-theme', newTheme);
        this._document.documentElement.setAttribute('data-bs-theme', newTheme);
      }
    }
  }

  public updateTitle(value?: string, params?: object): void {
    if (!this._document) {
      return;
    }
    if (!value) {
      const data: ICGStateDeclarationData = <ICGStateDeclarationData>this._uiRouterGlobals.$current.data;
      if (data?.pageTitle) {
        value = data.pageTitle;
      }
    }
    if (value) {
      value = this._translateService.instant(value, params);
    }
    this._document.title = value ? `${GLOBAL_PAGE_TITLE} - ${value}` : GLOBAL_PAGE_TITLE;
  }

  public locale(): Observable<string> {
    if (!this._observableLanguage) {
      this._observableLanguage = APP_LOCALE.asObservable();
    }
    return this._observableLanguage;
  }

  public async loadLocale(locale: string): Promise<void> {
    if (!APP_LOCALES.includes(locale)) {
      this._logger.error(new Error(`Invalid locale: ${locale}`));
      return;
    }
    if (locale.startsWith('pt-')) {
      moment.locale('pt');
    } else {
      moment.locale(locale);
    }
    this._plLocaleService.applyLocale(locale);
    await lastValueFrom(this._translateService.use(locale));
  }

  public async setLocale(locale: string): Promise<void> {
    if (!APP_LOCALES.includes(locale)) {
      throw new Error(`Invalid locale: ${locale}`);
    }
    if (this._document) {
      this._document.defaultView.localStorage.setItem(LOCAL_STORAGE_LOCALE, locale);
      this._document.location.reload();
    } else {
      await this.loadLocale(locale);
      await this._cgStateService.reload();
    }
  }

  public format(): Observable<IPlFormatConfig> {
    return this._plFormatService.format;
  }

  public sendBeacon(url: string, data?: BodyInit | null): boolean {
    if (!this._document?.defaultView) {
      return false;
    }
    return this._document.defaultView.navigator.sendBeacon(url, data);
  }

  private _initPageWrapper(): void {
    this._cgLocalStorageService.getItem<boolean>(LOCAL_STORAGE_SIDEBAR_TOGGLED, SCHEMA_BOOLEAN).subscribe((value: boolean) => {
      this._storedToggled = value !== false;
      this._plPageWrapperService.setToggled(!this._isMobile && this._subjectStatus.value.launchMode !== EAppLaunchMode.HybridPartial && this._storedToggled);
    });
    if (isProduction()) {
      this._cgLocalStorageService
        .has(LOCAL_STORAGE_CONSENT)
        .pipe(
          mergeMap<boolean, Observable<IPlCookiesConsent>>((hasConsent: boolean) => {
            return hasConsent ? this._cgLocalStorageService.getItem<IPlCookiesConsent>(LOCAL_STORAGE_CONSENT, SCHEMA_CONSENT) : of(undefined);
          })
        )
        .subscribe((storageConsent: IPlCookiesConsent) => {
          this._subscriptionCookiesConsent = this._plCookiesConsentService.consent.subscribe((consent: IPlCookiesConsent) => {
            this._cgLocalStorageService.setItem(LOCAL_STORAGE_CONSENT, consent, SCHEMA_CONSENT).subscribe();
            this.setCookiesConsentOpen(!consent.consented);
          });
          this._plCookiesConsentService.setConfig(merge({}, DEFAULT_COOKIES_CONSENT, {consent: storageConsent}));
        });
    }
  }

  private _checkFirstLoad(): void {
    if (this._firstLoad) {
      this._firstLoad = false;
      handleFirstPageLoad();
    }
  }

  private _onPageHide(): Observable<void> {
    if (!this._observablePageHide) {
      this._observablePageHide = fromEvent<PageTransitionEvent>(this._document.defaultView, 'pagehide', {capture: true, passive: true}).pipe(map(() => undefined));
    }
    return this._observablePageHide;
  }
}
