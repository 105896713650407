import {AbDesEditComponent} from './components/edit/abdes.entity.edit.component';
import {DATA_SOURCE_NAME_TIPO_DESCONTO_PROPORCIONAL_ABDES} from '../../datasources/tipodescontoproporcionalabdes/tipoDescontoProporcionalABDES.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_ENT_SUPORTE_ABDES} from '../../datasources/tipoentsuporteabdes/tipoEntSuporteABDES.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_TAXA_RETENCAO} from '../../datasources/tipotaxaretencao/tipoTaxaRetencao.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_VALOR_UNITARIO_RECOLHER} from '../../datasources/tipovalorunitariorecolher/tipoValorUnitarioRecolher.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_ARREDONDAMENTO_ABDES} from '../../datasources/tipoarredondamentoabdes/tipoArredondamentoABDES.datasource.interface';
import {ENTITY_NAME_ABDES, ENTITY_NAME_ABONOS, ENTITY_NAME_DESCONTOS, IABDESEntity, IABDESEntityService} from './abdes.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonABDES, IJsonPerfilSistemaAbdes} from './jsonABDES.entity.interface';
import {ROLE} from '../../services/role.const';
import {AbDesListComponent} from './components/list/abdes.entity.list.component';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';

export const ENTITY_ABDES: IEntityDefinition<IJsonABDES> = generateEntity(ENTITY_NAME_ABDES);

export const ENTITY_ABONOS: IEntityDefinition<IJsonABDES> = generateEntity(ENTITY_NAME_ABONOS, 'abonos');

export const ENTITY_DESCONTOS: IEntityDefinition<IJsonABDES> = generateEntity(ENTITY_NAME_DESCONTOS, 'descontos');

function generateEntity(entityName: string, entityUrl?: string): IABDESEntity {
  const baseUrl = 'abonosdescontos';
  let url = baseUrl;
  if (entityUrl) {
    url += `/${entityUrl}`;
  }
  return {
    name: entityName,
    asModule: entityName !== ENTITY_NAME_ABDES,
    entityUrl: url,
    roles: [ROLE.RH],
    searchPlaceholder: 'abdes.pesquisa',
    pageTitle: `global.menu.${entityName}`,
    metadata: {
      keyName: 'codAbDesc',
      fields: [
        {
          name: 'abdes',
          type: 'cgsmallint',
          width: '120px',
          caption: 'abdes.fields.abdes',
          placeholder: 'abdes.fields.abonoDescPlaceholder',
          validators: {required: true}
        },
        {
          name: 'codAbDesc',
          type: 'string',
          caption: 'abdes.fields.codAbDesc',
          placeholder: 'abdes.fields.codAbDescPlaceholder',
          validators: {required: true, maxlength: 50}
        },
        {
          name: 'designacaoBreve',
          type: 'string',
          caption: 'abdes.fields.designacaoBreve',
          placeholder: 'abdes.fields.designacaoBrevePlaceholder',
          validators: {maxlength: 50}
        },
        {name: 'designacaoCompleta', type: 'text', caption: 'abdes.fields.designacaoCompleta'},
        {name: 'valorFixo', type: 'number', caption: 'abdes.fields.valorFixo'},
        {name: 'valorLimIncidencia', type: 'number', caption: 'abdes.fields.valorLimIncidencia'},
        {name: 'tipoArredondamento', type: DATA_SOURCE_NAME_TIPOS_ARREDONDAMENTO_ABDES, caption: 'abdes.fields.tipoArredondamento'},
        {name: 'procNatal', type: DATA_SOURCE_NAME_TIPO_TAXA_RETENCAO, caption: 'abdes.fields.procNatal'},
        {name: 'procFerias', type: 'cgsmallint', caption: 'abdes.fields.procFerias'},
        {name: 'mapaSeguro', type: 'cgsmallint', caption: 'abdes.fields.mapaSeguro'},
        {name: 'mapaSindic', type: 'cgsmallint', caption: 'abdes.fields.mapaSindic'},
        {name: 'colQuadroP', type: 'cgsmallint', caption: 'abdes.fields.colQuadroP'},
        {name: 'colMapaCX', type: 'cgsmallint', caption: 'abdes.fields.colMapaCX'},
        {name: 'nCodABDESC', type: 'text', caption: 'abdes.fields.nCodABDESC'},
        {name: 'entiSuporte', type: DATA_SOURCE_NAME_TIPO_ENT_SUPORTE_ABDES, caption: 'abdes.fields.entiSuporte'},
        {name: 'contaMovDeb', type: 'text', caption: 'abdes.fields.contaMovDeb'},
        {name: 'contaMovCred', type: 'text', caption: 'abdes.fields.contaMovCred'},
        {name: 'calcCustHora', type: 'boolean', caption: 'abdes.fields.calcCustHora'},
        {name: 'coefHExtra', type: 'number', caption: 'abdes.fields.coefHExtra'},
        {name: 'horaDiaValor', type: 'cgsmallint', caption: 'abdes.fields.horaDiaValor'},
        {name: 'tipoHoraExtr', type: 'cgsmallint', caption: 'abdes.fields.tipoHoraExtr'},
        {name: 'diaHoraExtra', type: 'cgsmallint', caption: 'abdes.fields.diaHoraExtra'},
        {name: 'grupoAbono', caption: 'abdes.fields.grupoAbono'},
        {name: 'grupoAbonoNome', caption: 'abdes.fields.grupoAbonoNome'},
        {name: 'grupoDesconto', caption: 'abdes.fields.grupoDesconto'},
        {name: 'grupoDescontoNome', caption: 'abdes.fields.grupoDescontoNome'},
        {name: 'naoImpDecIRS114', type: 'cgsmallint', caption: 'abdes.fields.naoImpDecIRS114'},
        {name: 'tipoValorUnitRecolha', type: DATA_SOURCE_NAME_TIPO_VALOR_UNITARIO_RECOLHER, caption: 'abdes.fields.tipoValorUnitRecolha'},
        {name: 'tipoDescontoProporcional', type: DATA_SOURCE_NAME_TIPO_DESCONTO_PROPORCIONAL_ABDES, caption: 'abdes.fields.tipoDescontoProporcional'},
        {name: 'percLimValorHExtra', type: 'number', caption: 'abdes.fields.percLimValorHExtra'},
        {name: 'codCGASit', type: 'text', caption: 'abdes.fields.codCGASit'},
        {name: 'contaEncDEB', type: 'text', caption: 'abdes.fields.contaEncDEB'},
        {name: 'contaEncCRE', type: 'text', caption: 'abdes.fields.contaEncCRE'},
        {name: 'tipoRendimento', type: 'text', caption: 'abdes.fields.tipoRendimento'},
        {name: 'codSSCol', type: 'text', caption: 'abdes.fields.codSSCol'},
        {name: 'codUnidade', type: 'cginteger', caption: 'abdes.fields.codUnidade'},
        {name: 'codRubrOrcAbon', type: 'text', caption: 'abdes.fields.codRubrOrcAbon'},
        {name: 'codRubrOrcEnc', type: 'text', caption: 'abdes.fields.codRubrOrcEnc'},
        {name: 'abonoDescDesativado', type: 'boolean', caption: 'abdes.fields.abonoDescDesativado'},
        {name: 'percInciSegSocial', type: 'number', caption: 'abdes.fields.percInciSegSocial'},
        {name: 'nHorasDiaProvDesc', type: 'number', caption: 'abdes.fields.nHorasDiaProvDesc'},
        {name: 'nHorasDescontoProporcional', type: 'number', caption: 'abdes.fields.nHorasDescontoProporcional'},
        {name: 'usaSPReducaoRemuneratoria', type: 'boolean', caption: 'abdes.fields.usaSPReducaoRemuneratoria'},
        {name: 'perctDescSeguro', type: 'number', caption: 'abdes.fields.perctDescSeguro'}
      ],
      order: 'codAbDesc',
      searchFields: 'codAbDesc,designacaoBreve,designacaoCompleta',
      listFields: 'codAbDesc,designacaoBreve'
    },
    autocomplete: {
      rowTemplate: '{{codAbDesc}} - {{designacaoBreve}}',
      output: '{{codAbDesc}} - {{designacaoBreve}}',
      outputDescription: 'designacaoBreve',
      searchFields: 'codAbDesc,designacaoBreve'
    },
    actions: {
      new: true,
      detail: true,
      edit: true,
      search: true,
      delete: true,
      filter: true
    },
    list: {
      state: {
        component: AbDesListComponent
      }
    },
    detail: {
      state: {
        component: AbDesEditComponent,
        params: {
          defaultABDES: {
            type: 'any',
            value: null
          },
          defaultCRABD: {
            type: 'any',
            value: null
          }
        },
        resolve: [
          {
            provide: 'perfilSistemaAbdes',
            deps: ['model', EntityServiceBuilder],
            useFactory: async (model: IJsonABDES, entityServiceBuilder: EntityServiceBuilder): Promise<IJsonPerfilSistemaAbdes> => {
              if (!model?.perfilSistema) {
                return Promise.resolve(undefined);
              }
              const serviceABDES: IABDESEntityService = entityServiceBuilder.build<IJsonABDES, IABDESEntityService>(ENTITY_NAME_ABDES);
              const response: HttpResponse<IJsonPerfilSistemaAbdes> = await serviceABDES.getPerfilSistema(model.perfilSistema);
              return response.body;
            }
          }
        ]
      }
    },
    service: function () {
      this.getPerfilSistema = (perfilSistema: number): TServiceResponse<IJsonPerfilSistemaAbdes> => {
        return this.apiService.get({url: `${this.apiService.path.restapi}/${baseUrl}/perfilsistema`, params: {perfilsistema: perfilSistema}});
      };

      this.listaPerfilSistemaByCategoria = (abono: boolean, categoria: number): TServiceResponse<Array<IJsonPerfilSistemaAbdes>> => {
        return this.apiService.get({url: `${this.apiService.path.restapi}/${baseUrl}/perfilsistema/listbycategoria`, params: {abono: abono, categoria: categoria}});
      };

      this.listaPerfilSistemaByTipo = (abono: boolean, tipo: number): TServiceResponse<Array<IJsonPerfilSistemaAbdes>> => {
        return this.apiService.get({url: `${this.apiService.path.restapi}/${baseUrl}/perfilsistema/listbytipo`, params: {abono: abono, tipo: tipo}});
      };
    }
  };
}
