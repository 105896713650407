import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDelphiNumberTypes, EMonth} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {EGestaoDGEMPSType, IPrintEventsFilters} from '../../gestaodgemps.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {IReportInstance} from '../../../reports/input/reports.input.component.interface';
import {monthDateRange} from '../../../../../common/dates';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';
import {ReportsRegistryService} from '../../../reports/reports.registry.service';

const FERIAS_DEFAULT_REPORT_NAME = 'FERIAS0.FR3';
const FALTAS_DEFAULT_REPORT_NAME = 'FALTAS3.FR3';

@Component({
  selector: 'gestao-dgemps-print-modal',
  templateUrl: './gestaodgemps.print.modal.component.html'
})
export class GestaoDGEMPSPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public type: EGestaoDGEMPSType;

  public readonly printFaltasFilters: IPrintEventsFilters;
  public readonly reportType: string;
  public reportModel: IJsonReport;
  public pdfUrl: string;
  public promise: Promise<void>;

  private readonly _reportService: IReportInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
    this.reportType = EReport.ImpEventosRH;
    this.printFaltasFilters = {
      codEmpDe: 0,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      deData: monthDateRange(EMonth.January).start,
      ateData: monthDateRange(EMonth.December).end,
      soAtivos: true
    };
    this._reportService = this._reportsRegistryService.get(EReport.ImpEventosRH);
  }

  public ngOnInit(): void {
    this.promise = this._loadDefaultReport().then(() => {
      this.getPDFUrl();
    });
  }

  public reportModelChange(value: IJsonReport): void {
    this.reportModel = value;
    this.getPDFUrl();
  }

  public getPDFUrl(): void {
    if (!this.reportModel) {
      this.pdfUrl = undefined;
      return;
    }
    this._portalClientAccountService
      .getPrintUrl(
        this.type,
        this.printFaltasFilters.deData,
        this.printFaltasFilters.ateData,
        this.printFaltasFilters.codEmpDe,
        this.printFaltasFilters.codEmpAte,
        this.printFaltasFilters.soAtivos,
        this.reportModel.name
      )
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportService.query({pagina: 1, porpagina: 1}).then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        let defaultReport: IJsonReport = reports[0];
        const defaultReportName = this._getDefaultReportName();
        if (defaultReportName.length) {
          const index = reports.findIndex((item) => item.name === defaultReportName);
          if (index > -1) {
            defaultReport = reports[index];
          }
        }
        this.reportModel = defaultReport;
      }
    });
  }

  private _getDefaultReportName(): string {
    if (this.type === EGestaoDGEMPSType.Ferias) {
      return FERIAS_DEFAULT_REPORT_NAME;
    }
    if (this.type === EGestaoDGEMPSType.Faltas) {
      return FALTAS_DEFAULT_REPORT_NAME;
    }
    return '';
  }
}
