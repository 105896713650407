import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EDelphiNumberTypes} from 'pl-comps-angular';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {AcrescimosEncargosFeriasService} from '../../acrescimosEncargosFerias.module.service';
import {IAcrescimoEncFeriasPrintHeader} from '../../acrescimosEncargosFerias.module.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-print',
  templateUrl: './acrescimosEncargosFerias.print.modal.component.html'
})
export class AcrescimosEncargosFeriasPrintModalComponent extends CGModalComponent<boolean> implements OnInit {
  @Input() public nEmpresa: string;
  @Input() public anoEncargo: number;

  public report: IJsonReport;
  public header: IAcrescimoEncFeriasPrintHeader;
  public pdfUrl: string;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _acrescimosEncFeriasService: AcrescimosEncargosFeriasService
  ) {
    super(_injector);
    this.anoEncargo = this.configurations.empresa.anoEmCursoIRC;
    this.nEmpresa = this.configurations.empresa.nEmpresa;
  }

  public ngOnInit(): void {
    this.header = {
      nEmpresa: this.nEmpresa,
      anoEncargo: this.anoEncargo,
      deCodEmp: 0,
      ateCodEmp: EDelphiNumberTypes.MaxSmallInt,
      deCCusto: '',
      ateCCusto: 'ZZZZ',
      deReparticao: undefined,
      ateReparticao: EDelphiNumberTypes.MaxSmallInt
    };
    this._loadDefaultReport();
    this.previsualizar = this.previsualizar.bind(this);
  }

  public previsualizar(): void {
    this._acrescimosEncFeriasService.getListagemAcrescimosEncFeriasUrl(this.header, this.report.name).subscribe((url: string) => {
      this.pdfUrl = url;
      this._cardPanel.collapse();
    });
  }

  @ViewChild('cardPanelCubo')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.AcrescimosEncargosFerias)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      })
      .then(() => {
        this.previsualizar();
      });
  }
}
