import {deepFreeze} from 'pl-comps-angular';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDocfaNum} from '../docfasnums/jsonDocfaNum.entity.interface';
import {IJsonDocfa, IJsonDocfaNewFromBase, IJsonDocfaReport, IJsonDocfaReportList} from './jsonDocFa.entity.interface';

export interface IDocFasEntityService extends IEntityService<IJsonDocfa> {
  reports(id: number): TServiceResponse<IJsonDocfaReportList>;

  series(id: number): TServiceResponse<Array<IJsonDocfaNum>>;

  duplicateDocfa(data: IJsonDocfaNewFromBase): TServiceResponse<IJsonDocfa>;
}

export interface IDocFasEntity extends IEntityDefinition<IJsonDocfa, IDocFasEntityService> {}

export interface ISelectKeyPair {
  value: number;
  name: string;
}

export interface IDocfaReport extends IJsonDocfaReport {
  tipoDescricao: string;
}

export const SELECT_NVIASDEFEITO: Array<ISelectKeyPair> = deepFreeze([
  {value: 1, name: '1'},
  {value: 2, name: '2'},
  {value: 3, name: '3'},
  {value: 4, name: '4'},
  {value: 5, name: '5'},
  {value: 6, name: '6'},
  {value: 7, name: '7'},
  {value: 8, name: '8'},
  {value: 9, name: '9'},
  {value: 10, name: '10'}
]);

export const ENTITY_NAME_DOC_FAS = 'docfas';
