import {ROLE} from '../../services/role.const';
import {
  EPOCAltTipoValidacao,
  IJsonPlanoContasAlternativoCubo,
  IJsonPlanoContasAlternativoExtrato,
  IJsonPlanoContasAlternativoLin,
  IJsonPlanoContasAlternativoRadicais
} from './jsonPlanoContasAlternativo.entity.interface';
import {ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS, IPlanosContasAlternativosEntity} from './planosContasAlternativos.entity.interface';
import {PlanosContasAlternativosEditComponent} from './components/edit/planosContasAlternativos.entity.edit.component';
import {DATA_SOURCE_NAME_POC_ALT_TIPO_VALIDACAO} from '../../datasources/pocalttipovalidacao/pocAltTipoValidacao.datasource.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {DATA_SOURCE_POC_ALT_TIPO_VALIDACAO} from '../../datasources/pocalttipovalidacao/pocAltTipoValidacao.datasource';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {PlTranslateService} from 'pl-comps-angular';

export const ENTITY_PLANOS_CONTAS_ALTERNATIVOS = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IPlanosContasAlternativosEntity => {
    return {
      name: ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS,
      roles: [ROLE.CONTABILIDADE],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM],
      pageTitle: 'global.menu.planoscontasalternativos',
      searchPlaceholder: 'planoscontasalternativos.pesquisa',
      metadata: {
        keyName: 'pocAltCabID',
        fields: [
          {name: 'nome', caption: 'planoscontasalternativos.fields.nome', validators: {required: true}},
          {
            name: 'tipoValidacao',
            type: DATA_SOURCE_NAME_POC_ALT_TIPO_VALIDACAO,
            caption: 'planoscontasalternativos.fields.tipoValidacao',
            validators: {required: true},
            properties: {
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                    if (cellInfo.target === 'row') {
                      const nameDataSource: string = DATA_SOURCE_POC_ALT_TIPO_VALIDACAO.data.find((value: IDataSourceItem<number>) => value.value === <EPOCAltTipoValidacao>cellInfo.value).name;
                      return plTranslateService.translate(nameDataSource);
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          }
        ],
        order: 'pocAltCabID',
        searchFields: 'nome'
      },
      autocomplete: {
        rowTemplate: '{{nome}}',
        output: 'nome',
        searchFields: 'nome'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        search: true,
        delete: true,
        filter: true
      },
      detail: {
        state: {
          component: PlanosContasAlternativosEditComponent
        }
      },
      service: function () {
        this.getModImportPlanoAlt = () => {
          return this.apiService.get<Blob>({
            url: `${this.entityUrl()}/modeloimportplanoalt`,
            responseType: 'blob'
          });
        };

        this.exportPlanoAlt = (pocAltCabID: string) => {
          return this.apiService.get<Blob>({
            url: `${this.entityUrl()}/${pocAltCabID}/exportplanoalt`,
            responseType: 'blob'
          });
        };

        this.cuboPlanoContasAlt = (pocAltCabID: string, periodoDe: string, periodoAte: string, contaDe: string, contaAte: string, escondeRubricasSemMovimento: boolean) => {
          return this.apiService.get<Array<IJsonPlanoContasAlternativoCubo>>({
            url: `${this.entityUrl()}/${pocAltCabID}/cubo`,
            params: {
              periodode: periodoDe,
              periodoate: periodoAte,
              contade: contaDe,
              contaate: contaAte,
              esconderubricassemmovimento: escondeRubricasSemMovimento
            }
          });
        };

        this.extratoPlanoContasAlt = (pocAltCabID: string, periodoDe: string, periodoAte: string, nConta: string, acumulado: boolean) => {
          return this.apiService.get<Array<IJsonPlanoContasAlternativoExtrato>>({
            url: `${this.entityUrl()}/${pocAltCabID}/extrato`,
            params: {
              periodode: periodoDe,
              periodoate: periodoAte,
              nconta: nConta,
              acumulados: acumulado
            }
          });
        };

        this.importPlanoAlt = (pocAltCabID: string, file: File) => {
          const formData: FormData = new FormData();
          formData.append('file', file, file.name);
          return this.apiService.post<Array<IJsonPlanoContasAlternativoLin>, FormData>({
            url: `${this.entityUrl()}/importplanoalt`,
            params: {pocaltcabid: pocAltCabID},
            body: formData
          });
        };

        this.fillRadicals = (radicais: Array<IJsonPlanoContasAlternativoRadicais>) => {
          return this.apiService.post<Array<IJsonPlanoContasAlternativoRadicais>>({
            url: `${this.entityUrl()}/radicais`,
            body: radicais
          });
        };

        this.updatePCAltLinha = (pocAltCabID: string, pcaltLinha: IJsonPlanoContasAlternativoLin) => {
          return this.apiService.put<IJsonPlanoContasAlternativoLin>({
            url: `${this.entityUrl()}/${pocAltCabID}/contas/linha`,
            body: pcaltLinha
          });
        };

        this.deletePCAltLinha = (pocAltCabID: string, pocAltLinID: string) => {
          return this.apiService.delete<void>({
            url: `${this.entityUrl()}/${pocAltCabID}/contas/linha`,
            params: {pocaltlinid: pocAltLinID}
          });
        };
      }
    };
  }
];
