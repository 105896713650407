<div class="adiantamentos-configs-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'adiantamentos.modals.config.title'"></h5>
  </div>

  <div class="modal-body">
    <p class="mb-2" [translate]="'adiantamentos.modals.config.desc'"></p>

    <pl-group>
      <label [translate]="'adiantamentos.modals.familia'"></label>
      <edit>
        <entity-autocomplete
          entity="familias"
          attrName="familias"
          [model]="configAdiantamentos"
          (selectedKeyChange)="configAdiantamentos.familia = $event"
          (evtSelectedDescriptionChanged)="configAdiantamentos.nomeFamilia = $event"
          [properties]="{validators: {required: {value: true}}}"
          [fieldsMap]="{nFamilia: 'familia', nome: 'nomeFamilia'}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'adiantamentos.modals.nArtigoAdiantamento'"></label>
      <edit>
        <entity-autocomplete
          entity="artigos"
          attrName="artigo"
          [model]="configAdiantamentos"
          (modelChange)="artigoChanged($event)"
          (selectedKeyChange)="configAdiantamentos.nArtigoAdiantamento = $event"
          (evtSelectedDescriptionChanged)="configAdiantamentos.nomeNArtigoAdiantamento = $event"
          [properties]="{validators: {required: {value: true}}}"
          [fieldsMap]="{nArtigo: 'nArtigoAdiantamento', nome: 'nomeNArtigoAdiantamento'}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'adiantamentos.modals.nArtigoRegAdiantamento'"></label>
      <edit>
        <entity-autocomplete
          entity="artigos"
          attrName="artigoReg"
          [model]="configAdiantamentos"
          (selectedKeyChange)="configAdiantamentos.nArtigoRegAdiantamento = $event"
          (evtSelectedDescriptionChanged)="configAdiantamentos.nomeNArtigoRegAdiantamento = $event"
          [properties]="{validators: {required: {value: true}}}"
          [fieldsMap]="{nArtigo: 'nArtigoRegAdiantamento', nome: 'nomeNArtigoRegAdiantamento'}">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-tooltip [config]="{text: 'adiantamentos.messages.criaConfigTooltip', tooltipClass: 'tooltip-warning', placement: 'top'}">
      <pl-button *plTooltipContent klass="btn btn-sm btn-warning action-auto-config" [disabled]="closeDisabled" [onClick]="autoConfigModal">
        <i class="fa fa-fw fa-plus-circle"></i>&nbsp;<span [translate]="'adiantamentos.btns.autoConfig'"></span>
      </pl-button>
    </pl-tooltip>

    <pl-button klass="btn btn-sm btn-success action-ok" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
