<div [ngSwitch]="contabilistaView">
  <!--  contabilista componente view-->
  <ng-container *ngSwitchCase="true">
    <div class="message-empresa p-2" [class.active]="itemSelected" (click)="onClickEmpresa(empresa.nEmpresa)">
      <div class="row">
        <div class="col">
          <div class="message-name" [innerText]="empresa.nome"></div>
          <div class="message-counters" [ngSwitch]="empresa.nComunicacoes > 0 || empresa.nMensagens > 0">
            <ng-container *ngSwitchCase="true">
              <div class="counters" *ngIf="empresa.nComunicacoes > 0">
                <span class="badge badge-pill counter comunicacoes-count" [innerText]="empresa.nComunicacoes"></span>
                <span [translate]="'gestaoComunicacoes.messageSidebarSelector.comunicacoes'"></span>
              </div>
              <div class="counters" *ngIf="empresa.nMensagens > 0">
                <span class="badge badge-pill counter new-messages-count" [innerText]="empresa.nMensagens"></span>
                <span [translate]="'gestaoComunicacoes.messageSidebarSelector.newMessages'"></span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="false">
              <div class="counters">
                <span [translate]="'gestaoComunicacoes.messageSidebarSelector.noComunicacoes'"></span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-3 ms-auto d-flex align-items-center">
          <span class="ms-auto" [innerText]="empresa._dateShow"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <!--  cliente componente view-->
  <ng-container *ngSwitchCase="false">
    <div class="message-empresa p-2" [class.active]="itemSelected" (click)="onClickClienteTopico()">
      <div class="message-empresa-container">
        <div class="message-sigla"><span [innerText]="contabilistaSigla"></span></div>
        <div class="message-data">
          <div class="message-name" [innerText]="topico.assunto"></div>
          <div class="message-last-message">{{ topico.ultimaMensagem ? topico.ultimaMensagem : ('gestaoComunicacoes.common.semMensagens' | translate) }}</div>
        </div>
        <div class="message-info">
          <div class="message-counters" *ngIf="topico.hasUnreadMessages > 0">
            <div class="counters ms-auto">
              <span class="badge badge-pill counter new-messages-count" [innerText]="topico.hasUnreadMessages"></span>
            </div>
          </div>

          <span class="ms-auto" [innerText]="topico._dateShow"></span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
