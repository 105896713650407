import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HammerModule} from '@angular/platform-browser';
import {PlSchedulerModule} from '../../scheduler/scheduler.module';

import {PlCalendarYearViewComponent} from './calendar.year.view.component';

export * from './calendar.year.view.component.interface';
export * from './calendar.year.view.component';

const DECLARATIONS = [PlCalendarYearViewComponent];

@NgModule({
  imports: [CommonModule, HammerModule, PlSchedulerModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class CGCCalendarYearViewModule {}
