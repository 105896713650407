import {deepFreeze} from 'pl-comps-angular';
import {TDate} from '../../../../common/dates';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';

export enum EAvisosCobrancaEmissora {
  Desativado = -1,
  EasyPay = 0,
  EasyPayOnline = 2
}

export enum EAvisosCobrancaDataGridLegendColors {
  CampoAlterado = 'campo-alterado'
}

export enum EAvisosCobrancaDefaultReport {
  DefaultReport = 'AvisoDeCobranca01.FR3'
}

export interface IImpAvisosCobrancaFilters {
  ncontade: string;
  ncontaate: string;
  datadocde: TDate;
  datadocate: TDate;
  datavencimentode: TDate;
  datavencimentoate: TDate;
  nvendedorde: number;
  nvendedorate: number;
  ncondpagamde: number;
  ncondpagamate: number;
  dataultavisode?: TDate;
  dataultavisoate?: TDate;
  nzonade?: number;
  nzonaate?: number;
  pesqprocinterrompido?: boolean;
}

export interface IMetodoSourceRmissora {
  valueMetodo: EAvisosCobrancaEmissora;
  nameMetodo: string;
}

export const RADIO_GROUP_EMISSORA: IRadioGroup<EAvisosCobrancaEmissora> = deepFreeze<IRadioGroup<EAvisosCobrancaEmissora>>({
  groupItems: [
    {value: EAvisosCobrancaEmissora.EasyPayOnline, label: 'global.btn.yes'},
    {value: EAvisosCobrancaEmissora.Desativado, label: 'global.btn.no'}
  ]
});

export const TABLE_LEGEND_AVISOS_COBRANCA: TTableLegend = deepFreeze([
  {
    caption: 'avisoscobranca.datagrid.legend.campoAlterado',
    badgeCSSClass: EAvisosCobrancaDataGridLegendColors.CampoAlterado
  }
]);

export const MODULE_NAME_IMP_AVISOS_COBRANCA = 'avisoscobranca';
export const MODULE_NAME_AVISOS_COBRANCA_GRID = 'avisoscobrancagrid';
