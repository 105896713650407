import {HttpInterceptorFn} from '@angular/common/http';
import {interceptorErrorHandler} from '../interceptor/errorhandler.interceptor';
import {interceptorNotification} from '../interceptor/notification.interceptor';
import {interceptorRequest} from '../interceptor/request.interceptor';
import {interceptorResponse} from '../interceptor/response.interceptor';
import {interceptorTestsHelper} from '../interceptor/tests.helper.interceptor';

export const HTTP_INTERCEPTORS: Array<HttpInterceptorFn> = [
  interceptorRequest,
  /*interceptorCacheBuster,*/
  interceptorTestsHelper,
  interceptorErrorHandler,
  interceptorResponse,
  interceptorNotification
];
