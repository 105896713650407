<div class="entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'protecaoPassword.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group>
        <label [translate]="'protecaoPassword.modal.tipoPassword'"></label>
        <edit>
          <pl-edit type="tipoPasswordRecibo" [(model)]="tipoPassword" attrName="tipoPassword"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
  </div>
</div>
