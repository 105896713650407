import moment, {Moment} from 'moment';
import {Observable, of} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {EMonth, isArray, isBlob, isDate, isEmpty, isFile, isNumber, isObject, isString, toInteger, toJson} from 'pl-comps-angular';
import {API_AUTHENTICATION_URL_PARAM_NAME} from '../../config/constants';
import {APP_API_KEY} from '../app';
import {APP_LAUNCH_MODE} from '../api';
import {EAppLaunchMode} from '../site';
import {ISemanticVersion, ISemanticVersionComparison} from '../interfaces/interfaces';
import {isMoment, momentDateTime} from './moment.utils';

export const REGEX_ISO_8601_COMPLETE_PRECISION = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
export const REGEX_CDATA = /<!\[CDATA\[(.*)]]>/;
const REGEX_BREAK_LINE = /\r\n|\n+/g;

const MAP_ROUND_PADS: Map<number, number> = new Map<number, number>();

export function escapeSelector(selector: string): string {
  return selector.replace(/([:.[])/g, '\\$1');
}

export function generateUUID(encapsulated: boolean = false, encapsulationPrefix: string = '{', encapsulationSufix: string = '}'): string {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  let d = moment().valueOf();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16).toUpperCase();
  });
  return !encapsulated ? uuid : `${encapsulationPrefix}${uuid}${encapsulationSufix}`;
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

export const MIN_DATE_CG = '1900-01-01T00:00:00.000Z';

export function minDateCG(): Moment {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return momentDateTime(1900, EMonth.January, 1, 0, 0, 0, 0);
}

export const MAX_DATE_CG = '3000-12-31T00:00:00.000Z';
export const MAX_SMALL_INT = 32767;

export function maxDateCG(): Moment {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return momentDateTime(3000, EMonth.December, 31, 0, 0, 0, 0);
}

export const MIN_DATE_CGD = '1899-12-30T00:00:00.000Z';

export function minDateCGD(): Moment {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return momentDateTime(1899, EMonth.December, 30, 0, 0, 0, 0);
}

export const MAX_DATE_CGD = '9999-12-30T00:00:00.000Z';

export function maxDateCGD(): Moment {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return momentDateTime(9999, EMonth.December, 30, 0, 0, 0, 0);
}

export const MIN_UNIX_DATE_CG = '1970-01-01T00:00:00.000Z';

export function minUnixDateCG(): Moment {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return momentDateTime(1970, EMonth.January, 1, 0, 0, 0, 0);
}

export const MAX_UNIX_DATE_CG = '3000-12-31T00:00:00.000Z';

export function maxUnixDateCG(): Moment {
  return maxDateCG();
}

export function round(value: number, precision: number = 2): number {
  if (!isNumber(value)) {
    return value;
  }

  let pad: number = MAP_ROUND_PADS.get(precision);
  if (!pad) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    pad = Math.pow(10, precision);
    MAP_ROUND_PADS.set(precision, pad);
  }

  return Math.round((value + Number.EPSILON) * pad) / pad;
}

export function numberEquals(a: number, b: number, precision?: number): boolean {
  return round(a, precision) === round(b, precision);
}

export function numberBiggerThan(a: number, b: number, precision?: number): boolean {
  return round(a, precision) > round(b, precision);
}

export function numberLessThan(a: number, b: number, precision?: number): boolean {
  return round(a, precision) < round(b, precision);
}

export function numberBiggerOrEqualThan(a: number, b: number, precision?: number): boolean {
  a = round(a, precision);
  b = round(b, precision);
  return a === b || a > b;
}

export function numberLessOrEqualThan(a: number, b: number, precision?: number): boolean {
  a = round(a, precision);
  b = round(b, precision);
  return a === b || a < b;
}

export function parseSemanticVersion(value: string): ISemanticVersion {
  const result: ISemanticVersion = {major: -1, minor: -1, patch: -1};
  const parts: Array<string> = value.split('.');
  if (parts.length > 0) {
    result.major = toInteger(parts[0]) || -1;
  }
  if (parts.length > 1) {
    result.minor = toInteger(parts[1]) || -1;
  }
  if (parts.length > 2) {
    result.patch = toInteger(parts[2]) || -1;
  }
  return result;
}

export function compareSemanticVersion(a: string | ISemanticVersion, b: string | ISemanticVersion): ISemanticVersionComparison {
  const semanticA = isString(a) ? parseSemanticVersion(a) : a;
  const semanticB = isString(b) ? parseSemanticVersion(b) : b;
  const equal: boolean = semanticA.major === semanticB.major && semanticA.minor === semanticB.minor && semanticA.patch === semanticB.patch;
  const bigger: boolean = semanticA.major > semanticB.major || semanticA.minor > semanticB.minor || semanticA.patch > semanticB.patch;
  const biggerOrEqual: boolean = equal || bigger;
  const less: boolean = semanticA.major < semanticB.major || semanticA.minor < semanticB.minor || semanticA.patch < semanticB.patch;
  const lessOrEqual: boolean = equal || less;
  return {
    equal: () => equal,
    bigger: () => bigger,
    biggerOrEqual: () => biggerOrEqual,
    less: () => less,
    lessOrEqual: () => lessOrEqual
  };
}

function buildUrl(url: string): string {
  const path: URL = new URL(url);
  if (APP_API_KEY.value && APP_LAUNCH_MODE.value === EAppLaunchMode.HybridPartial) {
    path.searchParams.set(API_AUTHENTICATION_URL_PARAM_NAME, APP_API_KEY.value);
  }
  return path.toJSON();
}

function buildUrlWithParams(url: string, params: object): string {
  const path: URL = new URL(url);
  if (APP_API_KEY.value && APP_LAUNCH_MODE.value === EAppLaunchMode.HybridPartial) {
    path.searchParams.set(API_AUTHENTICATION_URL_PARAM_NAME, APP_API_KEY.value);
  }
  if (isObject(params)) {
    for (const key of Object.keys(params)) {
      let value: string = params[key];
      if (isEmpty(value)) {
        continue;
      } else if (isMoment(value) || isDate(value) || (isString(value) && REGEX_ISO_8601_COMPLETE_PRECISION.test(value))) {
        value = moment(value).toISOString();
      } else if (isArray(value)) {
        value = value.join();
      }
      path.searchParams.set(key, value);
    }
  }
  return path.toJSON();
}

export function buildSessionUrl(path: string): Promise<string> {
  return Promise.resolve(buildUrl(path));
}

export function buildSessionUrlWithParams(path: string, params: object): Observable<string> {
  return of(buildUrlWithParams(path, params));
}

export function isLineDisabled(element: Element | EventTarget | JQuery<Element>): boolean {
  if (!element) {
    return false;
  }
  const $element = $(element);
  if (!$element.length) {
    return false;
  }
  const $inputs = $element.find('input');
  if (!$inputs.length) {
    return false;
  }
  return $inputs.length === $element.find('input:disabled').length;
}

export function formGroupHasError(form: UntypedFormGroup, control: string, error?: string): boolean {
  return form.controls[control] ? (error ? form.controls[control].hasError(error) : form.controls[control].invalid) : false;
}

export function cyrb53(str: string, seed: number = 0): string {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  let h1: number = 0xdeadbeef ^ seed;
  let h2: number = 0x41c6ce57 ^ seed;
  for (let i = 0, ch: number; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

export function extractFromCData(value: string): string {
  if (!isString(value) || !value) {
    return '';
  }
  const result: RegExpExecArray = REGEX_CDATA.exec(value);
  if (!result) {
    return value;
  }
  return result[1] ?? '';
}

const REGEX_DYNAMIC_PROPERTIES = /{{(.*?)}}/g;
const REGEX_REMOVE_CHARACTERS = /[{} -]/g;

export function extractBindingProperty(expression: string, last: boolean): string {
  const matches = Array.from(String(expression).matchAll(REGEX_DYNAMIC_PROPERTIES));
  if (!matches.length) {
    return expression;
  }
  const index = last ? matches.length - 1 : 0;
  return String(matches[index][1]).replace(REGEX_REMOVE_CHARACTERS, '');
}

export function convertLineBreaksToHTML(value: string): string {
  return value.replace(REGEX_BREAK_LINE, '<br/>');
}

export function jsonWithFileToFormData(json: unknown, file: Blob | Array<Blob>): FormData {
  if (!isArray(file)) {
    file = [file];
  }
  const formData = new FormData();
  formData.set('data', toJson(json));
  for (const blob of file) {
    if (isBlob(blob) || isFile(blob)) {
      formData.set('', blob);
    }
  }
  return formData;
}

/**
 * Convert skip/take pagination to page/perPage
 * @param skip Number of items to skip
 * @param take Number of items to return
 * @returns [page, perPage] Converted page/perPage values; Pages start at 1
 */
export function skipTakeToPagePerPage(skip: number, take: number): [number, number] {
  if (!take) {
    return [1, 0];
  }
  const page: number = Math.floor(skip / take) + 1;
  return [page, take];
}

/**
 * Convert page/perPage pagination to skip/take
 * @param page Current page; This function assumes pages start at 1
 * @param perPage Number of items per page
 * @returns [skip, take] Converted skip/take values
 */
export function pagePerPageToSkipTake(page: number, perPage: number): [number, number] {
  if (page > 0) {
    page--;
  }
  const skip = page * perPage;
  return [skip, perPage];
}

export function appendValueToQueryFilter(filter: string, value: string): string {
  return !filter ? value : `${filter}&(${value})`;
}
