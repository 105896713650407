import {HttpResponse} from '@angular/common/http';
import {AT_FATURAS_RECIBOS_VERDES_SITUACAO_TODOS, AT_FATURAS_RECIBOS_VERDES_TIPO_TODOS, IATFaturasRecibosVerdesSituacao, IATFaturasRecibosVerdesTipo} from './atFaturasRecibosVerdes.module.interface';
import {AtFaturasRecibosVerdesModuleComponent} from './atFaturasRecibosVerdes.module.component';
import {AtFaturasRecibosVerdesService} from './atFaturasRecibosVerdes.service';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_AT_FATURAS_RECIBOS_VERDES: IModuleDefinition = {
  name: 'atFaturasRecibosVerdes',
  state: {
    url: '/atFaturasRecibosVerdes',
    component: AtFaturasRecibosVerdesModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.atFaturasRecibosVerdes'
    },
    resolve: [
      {
        provide: 'tiposFatura',
        deps: [AtFaturasRecibosVerdesService],
        useFactory: (atFaturasRecibosVerdesService: AtFaturasRecibosVerdesService): Promise<Array<IATFaturasRecibosVerdesTipo>> =>
          atFaturasRecibosVerdesService.getAtTiposList().then((response: HttpResponse<Array<IATFaturasRecibosVerdesTipo>>) => [{...AT_FATURAS_RECIBOS_VERDES_TIPO_TODOS}, ...response.body])
      },
      {
        provide: 'situacoesFatura',
        deps: [AtFaturasRecibosVerdesService],
        useFactory: (atFaturasRecibosVerdesService: AtFaturasRecibosVerdesService): Promise<Array<IATFaturasRecibosVerdesSituacao>> =>
          atFaturasRecibosVerdesService.getAtSituacaoList().then((response: HttpResponse<Array<IATFaturasRecibosVerdesSituacao>>) => [{...AT_FATURAS_RECIBOS_VERDES_SITUACAO_TODOS}, ...response.body])
      }
    ]
  }
};
