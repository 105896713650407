import {Observable} from 'rxjs';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {EReport} from '../../../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../../components/reports/reports.registry.service';
import {buildSessionUrlWithParams} from '../../../../../../../common/utils/utils';

@Component({
  selector: 'contab-svat-print-modal',
  templateUrl: './contabilidade.svat.print.modal.component.html'
})
export class ContabilidadeSvatPrintComponent extends CGModalComponent<void> implements OnInit {
  @Input() public apiPath: string;
  @Input() public modalTitle: string;
  @Input() public anoEmCurso: number;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reports: Array<IJsonReport>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.modalTitle = '';
    this.pdfUrl = '';
  }

  public ngOnInit(): void {
    this._loadDefaultReport().then(() => {
      this._getPdf();
    });
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this._getPdf();
  }

  private _getPdf(): void {
    this._getPdfDfSvatUrl().subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _getPdfDfSvatUrl(): Observable<string> {
    return buildSessionUrlWithParams(`${this.apiPath}/report/pdf`, {anoEmCurso: this.anoEmCurso, reportName: this.reportModel.name});
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.contabilidade.svat.reportImpressao,
      title: this._configService.configurations.contabilidade.svat.reportImpressao
    };
    return this._reportsRegistryService
      .get(EReport.Svat)
      .query()
      .then((reports: Array<IJsonReport>) => {
        this.reports = reports;
        if (this.reports.length) {
          let foundDefault = false;
          for (const report of this.reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: this.reports[0].name,
              title: this.reports[0].title
            };
          }
        }
      });
  }
}
