<div class="lotes">
  <entity-detail
    entityName="lotes"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group class="artigo-edit" [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT || nArtigoReadonlyOnNew, validators: {required: {value: true}}}">
          <label [translate]="'lotes.fields.nArtigo'"></label>
          <edit>
            <entity-autocomplete
              entity="artigos"
              filter="temLote=1"
              attrName="nArtigoLote"
              [model]="model"
              (modelChange)="nArtigoChanged($event)"
              (selectedKeyChange)="model.nArtigo = $event"
              (evtSelectedDescriptionChanged)="model.nomeArtigo = $event"
              [fieldsMap]="{nArtigo: 'nArtigo', nome: 'nomeArtigo'}"
              plAutoFocus
              [plAutoFocusDisabled]="type === stateTypes.EDIT || nArtigoReadonlyOnNew">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT, validators: {required: {value: true}}}">
          <label [translate]="'lotes.fields.nLoteEspecifico'"></label>
          <edit>
            <pl-edit-text attrName="nLoteEspecifico" [(model)]="model.nLoteEspecifico" plAutoFocus [plAutoFocusDisabled]="!nArtigoReadonlyOnNew"></pl-edit-text>
          </edit>
        </pl-group>

        <!-- <pl-group>
          <label [translate]="'lotes.fields.nConta'"></label>
          <edit>
            <entity-autocomplete
              entity="fornecedores"
              attrName="nConta"
              [model]="model"
              (selectedKeyChange)="model.nConta = $event"
              (evtSelectedDescriptionChanged)="model.nomeConta = $event"
              [fieldsMap]="{nDepart: 'nConta', nome: 'nomeConta'}"
              plAutoFocus
              [plAutoFocusDisabled]="type === stateTypes.NEW || nArtigoReadonlyOnNew">
            </entity-autocomplete>
          </edit>
        </pl-group> -->

        <pl-group>
          <pl-group>
            <label [translate]="'lotes.fields.dataFabrico'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="model.dataFabrico" attrName="dataFabrico" plAutoFocus [plAutoFocusDisabled]="type === stateTypes.NEW || nArtigoReadonlyOnNew"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'lotes.fields.dataValidade'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="model.dataValidade" attrName="dataValidade"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="type !== stateTypes.NEW">
          <pl-group *ngIf="type === stateTypes.DETAIL">
            <label [translate]="'lotes.fields.qtdTotal'"></label>
            <edit>
              <pl-edit-number attrName="qtdTotal" [(model)]="model.qtdTotal" [placeholder]="'lotes.fields.qtdTotal'"></pl-edit-number>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'lotes.fields.terminado'"></label>
            <edit>
              <pl-edit attrName="terminado" type="boolean" [(model)]="model.terminado"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
