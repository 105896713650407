import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCAE} from './jsonCAE.entity.interface';
import {ENTITY_NAME_CAE} from './cae.entity.interface';

export const ENTITY_CAE: IEntityDefinition<IJsonCAE> = {
  name: ENTITY_NAME_CAE,
  asModule: false,
  roles: [ROLE.ERP],
  metadata: {
    keyName: 'codCAE',
    fields: [
      {name: 'codCAE', width: '120px', caption: 'cae.fields.codCAE', validators: {required: true, maxlength: 10}},
      {name: 'nome', caption: 'cae.fields.nome', validators: {required: true, maxlength: 50}},
      {name: 'codSubSec', caption: 'cae.fields.codSubSec', validators: {maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'codCAE,nome'
  },
  autocomplete: {
    rowTemplate: '{{codCAE}} - {{nome}}',
    output: 'nome',
    searchFields: 'codCAE,nome'
  }
};
