import moment from 'moment';
import {merge, orderBy} from 'lodash-es';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import {
  cgcFilter,
  copy,
  ICGTableOnSelect,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  IPlUploadCallback,
  isArray,
  isDefined,
  isDefinedNotNull,
  isEmpty,
  isFunction,
  isUndefinedOrNull,
  KEYCODES,
  PlAlertService,
  PlI18nPlNumberService,
  PlTranslateService,
  TPlTableItem
} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {CLOUD_PUBLICA_HORAS} from '../../../../../common/resolvers/saft.resolvers';
import {
  EImportadorSaftDirectCorrespContasAtribuirFieldEnum,
  EImportadorSaftDirectCriterioFormacao,
  EImportadorSaftDirectCriterioFormacaoStr,
  EImportadorSaftDirectCurrentOperation,
  EImportadorSaftDirectGeneratedDocContabErrorType,
  EImportadorSaftDirectInvoiceType,
  EImportadorSaftDirectNifErrorType,
  EImportadorSaftDirectState,
  EImportadorSaftDirectTipoAnaliticaIS,
  EImportadorSaftDirectTipoFormacaoConta,
  EImportadorSaftDirectTipoFormacaoContaStr,
  EImportadorSaftDirectTipoImportacao,
  EImportadorSaftDirectTipoInfo,
  EImportadorSaftDirectTipoLancamDatas,
  EImportadorSaftDirectTipoLancamDatasStr,
  EImportadorSaftDirectTipoMercado,
  EImportadorSaftDirectTipoProduto,
  EImportadorSaftDirectTypeHasDocsData,
  EImportadorSaftDirectValoresImportar,
  EImportadorSaftDirectWizStepIds,
  IImportadorSaftDirectConfigMascaraAnalitica,
  IImportadorSaftDirectCorrespContasAtribuirModel,
  IImportadorSaftDirectProcessFileFilters,
  IImportadorSaftDirectWizStepData,
  IImportadorSaftDirectWizSteps,
  IJsonImportadorSaftDirectArtigo,
  IJsonImportadorSaftDirectCliente,
  IJsonImportadorSaftDirectDataInfo,
  IJsonImportadorSaftDirectDocFaturacao,
  IJsonImportadorSaftDirectDocRecibo,
  IJsonImportadorSaftDirectGeneratedDocContab,
  IJsonImportadorSaftDirectImportDocSummary,
  IJsonImportadorSaftDirectImportRecibosSummary,
  IJsonImportadorSaftDirectObjFullModel,
  IJsonImportadorSaftDirectStatus,
  IJsonImportadorSaftDirectTaxaIva,
  IJsonImportadorSaftDirectTipoDocumento,
  RADIO_GROUP_TIPOS_IMPORTACAO,
  RADIO_GROUP_TIPOS_IMPORTACAO_PT,
  TABLE_LEGEND_IMPORT_SAFT_DIRECT_LEGEND,
  TPlTableItemImportadorSaftConfigArtigosItem,
  TPlTableItemImportadorSaftConfigTipoDocsItem,
  TPlTableItemImportadorSaftCorrespContasCorrentesItem,
  TPlTableItemImportadorSaftCorrespTaxasIvaItem,
  TPlTableItemImportadorSaftDatasDocumentosItem,
  TPlTableItemImportadorSaftGenDocsItem
} from '../importadorSaftDirect.module.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../docscontabilidade/docsContabilidade.interface';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EStatusCode, isTest} from '../../../../../config/constants';
import {EStoreMode} from '../../../../../common/enums/licenca.enums';
import {hasAuthority} from '../../../../../common/utils/roles.utils';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ImportadorSaftDirectArtigosConfigModalComponent} from '../editors/artigosconfig/importadorSaftDirect.artigos.config.modal.component';
import {ImportadorSaftDirectConfigMascaraAnaliticaModalComponent} from '../editors/configmascaraanalitica/importadorSaftDirect.configMascaraAnalitica.modal.component';
import {ImportadorSaftDirectCorrespContasAssociarModalComponent} from '../editors/correspcontasassociar/importadorSaftDirect.correspContas.associar.modal.component';
import {ImportadorSaftDirectCorrespContasAtribuirModalComponent} from '../editors/correspcontasatribuir/importadorSaftDirect.correspContas.atribuir.modal.component';
import {ImportadorSaftDirectCorrespContasModalComponent} from '../editors/correspcontas/importadorSaftDirect.correspContas.modal.component';
import {ImportadorSaftDirectDatasDocumentosEditModalComponent} from '../editors/datasdocumentos/importadorSaftDirect.datasDocumentos.edit.modal.component';
import {ImportadorSaftDirectFinalizedModalComponent} from '../modals/finalized/importadorSaftDirect.finalized.modal.component';
import {ImportadorSaftDirectInProcessModalComponent} from '../modals/inprocess/importadorSaftDirect.inProcess.modal.component';
import {ImportadorSaftDirectService} from '../importadorSaftDirect.module.service';
import {ImportadorSaftDirectTaxasIvaModalComponent} from '../editors/taxasiva/importadorSaftDirect.taxasIva.modal.component';
import {ImportadorSaftDirectTiposDocsModalComponent} from '../editors/tiposdocs/importadorSaftDirect.tiposDocs.modal.component';
import {ImportadorSaftDirectUploadErrorHandler} from '../importadorSaftDirect.upload.errorHandler';
import {ImportadorSaftDirectVerContaFormadaModalComponent} from '../editors/vercontaformada/importadorSaftDirect.verContaFormada.modal.component';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {
  IRestCmdImportadorSaftDirectConfigArtigos,
  IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo,
  IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica,
  IRestCmdImportadorSaftDirectGetCorrespondenciaContas,
  IRestCmdImportadorSaftDirectGetCorrespTaxas,
  IRestCmdImportadorSaftDirectGetTiposDocumento,
  IRestCmdImportadorSaftDirectLoadConfigArtigos,
  IRestCmdImportadorSaftDirectLoadConfigFormacaoConta,
  IRestCmdImportadorSaftDirectLoadConfigMercados,
  IRestCmdImportadorSaftDirectLoadDatasDocumentos,
  IRestCmdImportadorSaftDirectSaveConfigArtigos,
  IRestCmdImportadorSaftDirectSaveConfigCorrespContas,
  IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas,
  IRestCmdImportadorSaftDirectSaveConfigFormacaoConta,
  IRestCmdImportadorSaftDirectSaveConfigMercados,
  IRestCmdImportadorSaftDirectSaveDocumentos,
  IRestCmdImportadorSaftDirectSaveTiposDocumento
} from '../importadorSaftDirectCommands.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ROLE} from '../../../../services/role.const';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';

const STEP_INDEX_WELCOME = 0;
const STEP_INDEX_TIPO_IMPORTACAO = 1;
const STEP_INDEX_UPLOADER = 2;
const STEP_INDEX_CENTRALGEST_CLOUD = 3;
const STEP_INDEX_CENTRALGEST_POS = 4;
const STEP_INDEX_CENTRALGEST_WINTOUCH = 5;
const STEP_INDEX_DOCUMENTOS = 6;
const STEP_INDEX_TIPOS_DOCUMENTO = 7;
const STEP_INDEX_CONFIG_MERCADOS = 8;
const STEP_INDEX_CONFIG_FORMACAO_CONTA = 9;
const STEP_INDEX_CONFIG_CORRESP_CONTAS_CORRENTES = 10;
const STEP_INDEX_CONFIG_CORRESP_TAXAS = 11;
const STEP_INDEX_CONFIG_ARTIGOS = 12;
const STEP_INDEX_DATAS_DOCUMENTOS = 13;
const STEP_INDEX_GEN_DOCUMENTOS = 14;
const INTERVAL_TIMEOUT = 3000;
const PROGRESS_BAR_MIN_VALUE = 0;
// const PROGRESS_BAR_MAX_VALUE = 100;
const JOB_NOT_FOUND_ERROR = 'A tarefa não foi encontrada ou não foi inicializada';

enum EDocsToImportTabID {
  TabDocs = 'tabDocs',
  TabPayments = 'tabPayments'
}

@Component({
  selector: 'importador-saft-direct',
  templateUrl: './importadorSaftDirect.module.component.html',
  providers: [ImportadorSaftDirectUploadErrorHandler]
})
export class ImportadorSaftDirectComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public currentStatus: IJsonImportadorSaftDirectStatus;
  @Input() public licencaStoreMode: EStoreMode;
  @Input() public licencaStoreModePublic: boolean;
  @Input() public importacaoSAFTMaxFileSize: number;

  public readonly maintenanceInstanceDocsContab: IEntityMaintenanceInstance;
  public readonly maintenanceInstancePocs: IEntityMaintenanceInstance;
  public readonly dataGridDefinitionPayments: IDevExpressDataGrid;
  public readonly dataGridDefinitionDocs: IDevExpressDataGrid;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly plWizardCallback: IPlNavWizardCallback;
  public readonly plUploadCallback: IPlUploadCallback;
  public readonly typeImportadorSaftDirectTipoInfo: typeof EImportadorSaftDirectTipoInfo;
  public readonly enumTypesHasDocsData: typeof EImportadorSaftDirectTypeHasDocsData;
  public readonly tipoAnaliticaISType: typeof EImportadorSaftDirectTipoAnaliticaIS;
  public readonly enumTiposImportacao: typeof EImportadorSaftDirectTipoImportacao;
  public readonly enumStepIds: typeof EImportadorSaftDirectWizStepIds;
  public readonly tabsId: typeof EDocsToImportTabID;
  public readonly storeModes: typeof EStoreMode;
  public readonly isHoursBeforeSix: boolean;
  public readonly importadorSaftDirectLegend: TTableLegend;

  public hasRoleContabilidadePremium: boolean;
  public tiposImportacao: IRadioGroup<EImportadorSaftDirectTipoImportacao>;
  public tipoImportacaoEscolhido: EImportadorSaftDirectTipoImportacao;
  public processFileFilters: IImportadorSaftDirectProcessFileFilters;
  public typeHasDocsData: EImportadorSaftDirectTypeHasDocsData;
  public activeTabDocsToImport: EDocsToImportTabID;
  public propertiesNavWizard: IPlNavWizardOptions;
  public wizSteps: IImportadorSaftDirectWizSteps;
  public processFileCloudForm: FormGroup;
  public inProcessFilename: string;
  public pbProcessLabel: string;
  public blockedByUser: boolean;
  public pbProcessPercentage: number;
  public pbProcessPos: number;
  public inProcess: boolean;
  public uploadUrl: string;

  private _savingGenDocs: boolean;
  private _importProcessCanceled: boolean;
  private _deRegisterOnStartFn: Function;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgExceptionService: CGExceptionService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.storeModes = EStoreMode;
    this.isHoursBeforeSix = moment().hours() < CLOUD_PUBLICA_HORAS;
    this.enumTiposImportacao = EImportadorSaftDirectTipoImportacao;
    this.tipoImportacaoEscolhido = EImportadorSaftDirectTipoImportacao.FicheiroSaft;
    this.enumTypesHasDocsData = EImportadorSaftDirectTypeHasDocsData;
    this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.LoadingData;
    this.tipoAnaliticaISType = EImportadorSaftDirectTipoAnaliticaIS;
    this.enumStepIds = EImportadorSaftDirectWizStepIds;
    this.maintenanceInstancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this.maintenanceInstanceDocsContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.typeImportadorSaftDirectTipoInfo = EImportadorSaftDirectTipoInfo;
    this.tabsId = EDocsToImportTabID;
    this.importadorSaftDirectLegend = TABLE_LEGEND_IMPORT_SAFT_DIRECT_LEGEND;
    this.dataGridDefinitionDocs = {
      columns: [
        {
          dataField: 'jaImportado',
          caption: 'importadorSaftDirect.fields.jaImportado',
          dataType: 'boolean',
          width: 80,
          allowFiltering: false,
          allowHeaderFiltering: false
        },
        {dataField: 'invoiceNo', caption: 'importadorSaftDirect.fields.invoiceNo', dataType: 'string'},
        {dataField: 'invoiceDate', caption: 'global.text.date', dataType: 'date'},
        {dataField: 'invoiceTypeStr', caption: 'importadorSaftDirect.fields.invoiceTypeStr', dataType: 'string'},
        {dataField: 'totalBase', caption: 'importadorSaftDirect.fields.totalBase', dataType: 'double'},
        {dataField: 'totalIVA', caption: 'importadorSaftDirect.fields.totalIVA', dataType: 'double'},
        {dataField: 'total', caption: 'global.text.total', dataType: 'double'},
        {dataField: 'anulado', caption: 'importadorSaftDirect.fields.anulado', dataType: 'boolean'},
        {caption: '', cellTemplate: 'cellTemplateIcon', showInColumnChooser: false, allowHeaderFiltering: false, allowSearch: false, allowReordering: false}
      ],
      dataSource: [],
      columnHidingEnabled: false,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateMessage'},
      remoteOperations: false,
      export: {filename: 'importadorSaftDirect.steps.viewDocsToImport.tabDocsTitle'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarBefore',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionPayments = {
      columns: [
        {
          dataField: 'jaImportado',
          caption: 'importadorSaftDirect.fields.jaImportado',
          dataType: 'boolean',
          width: 80,
          allowFiltering: false,
          allowHeaderFiltering: false
        },
        {dataField: 'paymentRefNo', caption: 'importadorSaftDirect.fields.paymentRefNo', dataType: 'string'},
        {dataField: 'systemEntryDate', caption: 'global.text.date', dataType: 'date'},
        {dataField: 'nomeCliente', caption: 'importadorSaftDirect.fields.nomeCliente', dataType: 'string'},
        {dataField: 'documentTotalsNetTotal', caption: 'importadorSaftDirect.fields.totalBase', dataType: 'double'},
        {dataField: 'valorIVA', caption: 'importadorSaftDirect.fields.totalIVA', dataType: 'double'},
        {dataField: 'settlementAmount', caption: 'importadorSaftDirect.fields.settlementAmount', dataType: 'double'},
        {dataField: 'documentTotalsGrossTotal', caption: 'global.text.total', dataType: 'double'},
        {dataField: 'valorLinhasCredito', caption: 'importadorSaftDirect.fields.valorLinhasCredito', dataType: 'double'},
        {dataField: 'valorLinhasDebito', caption: 'importadorSaftDirect.fields.valorLinhasDebito', dataType: 'double'},
        {caption: '', cellTemplate: 'cellTemplateIcon', showInColumnChooser: false, allowHeaderFiltering: false, allowSearch: false, allowReordering: false}
      ],
      dataSource: [],
      columnHidingEnabled: false,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateMessage'},
      remoteOperations: false,
      export: {filename: 'importadorSaftDirect.steps.viewDocsToImport.tabPaymentsTitle'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarBefore',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.inProcessFilename = '';
    this.inProcess = false;
    this.definitionNavWizard = {items: []};
    this.plUploadCallback = {};
    this.plWizardCallback = {};
    this.propertiesNavWizard = {disableNavigation: false, disablePreviousStep: false, disableNextStep: false, disableFinalize: true};
    this.wizSteps = {
      tipoImportacao: {
        _index: STEP_INDEX_TIPO_IMPORTACAO,
        _needSave: false,
        _needLoadNext: true,
        valid: true
      },
      upload: {
        _index: STEP_INDEX_UPLOADER,
        _needLoadNext: true,
        _needSave: false,
        showUploader: true,
        showProcessFileProgressBar: false,
        valid: false,
        processFileIntervalId: -1
      },
      centralGestCloud: {
        _index: STEP_INDEX_CENTRALGEST_CLOUD,
        _needSave: false,
        _needLoadNext: true,
        valid: false
      },
      centralGestPOS: {
        _index: STEP_INDEX_CENTRALGEST_POS,
        _needSave: false,
        _needLoadNext: true,
        valid: false
      },
      centralGestPOSWinTouch: {
        _index: STEP_INDEX_CENTRALGEST_WINTOUCH,
        _needSave: false,
        _needLoadNext: true,
        valid: false
      },
      importDocs: {
        _index: STEP_INDEX_DOCUMENTOS,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        docsHaveErrorsOrWarnings: false,
        paymentsHaveErrorsOrWarnings: false,
        tableDocsSource: [],
        tablePaymentsSource: [],
        summaryDocs: {
          totalDocs: 0,
          totalIva: 0,
          totalBase: 0,
          total: 0
        },
        summaryRecibos: {
          totalPayments: 0,
          totalCredit: 0,
          totalDebit: 0,
          total: 0
        },
        showDocsWithErrorOrWarningOnly: false,
        showRecibosWithErrorOrWarningOnly: false,
        showTabDocs: false,
        showTabPayments: false,
        dataGridInstanceDocs: undefined
      },
      configTiposDocs: {
        _index: STEP_INDEX_TIPOS_DOCUMENTO,
        _needLoadNext: true,
        _needSave: true,
        criaDiariosEDescritivos: true,
        configMascaraAnalitica: {
          usaAnalitica: false,
          mascara: '',
          tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento,
          mascaraAutocompleteOutput: '{{mascara}}'
        },
        valid: false,
        tableCallback: {},
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableDefinition: {
          fields: [
            {name: 'importa', caption: 'importadorSaftDirect.fields.importa', type: 'boolean', align: 'center'},
            {name: 'invoice', caption: 'importadorSaftDirect.fields.invoice'},
            {name: 'invoiceTypeStr', caption: 'importadorSaftDirect.fields.invoiceTypeStr'},
            {name: 'nDiario', caption: 'importadorSaftDirect.fields.nDiario'},
            {name: 'descritivo', caption: 'importadorSaftDirect.fields.descritivo'},
            {name: 'lancaCaixa', caption: 'importadorSaftDirect.fields.lancaCaixa', type: 'boolean', align: 'center'},
            {name: 'contaCaixa', caption: 'importadorSaftDirect.fields.contaCaixa'},
            {name: 'contaDescRec', caption: 'importadorSaftDirect.fields.contaDescRecibo'},
            {name: 'lancaContaCorrente', caption: 'importadorSaftDirect.fields.lancaContaCorrente', type: 'boolean', align: 'center'},
            {name: 'nCCusto', caption: 'importadorSaftDirect.fields.nCCusto', visible: false},
            {name: 'nCodRepCC', caption: 'importadorSaftDirect.fields.nCodRepCC', visible: false, emptyIfZero: true},
            {name: 'agrupamentoDocumentosStr', caption: 'importadorSaftDirect.fields.agrupamentoDocumentos'},
            {name: 'tipoRetencaoStr', caption: 'importadorSaftDirect.fields.tipoRetencao'},
            {name: 'loja', caption: 'importadorSaftDirect.fields.loja'}
          ]
        },
        tableSource: []
      },
      configMercados: {
        _index: STEP_INDEX_CONFIG_MERCADOS,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        valorMercadoComunitario: '',
        valorMercadoNacional: '',
        valorOutrosMercados: ''
      },
      configFormacaoContas: {
        _index: STEP_INDEX_CONFIG_FORMACAO_CONTA,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        tipoFormacaoConta: EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes,
        criterioFormacao: EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia,
        radical: '2111',
        nDigitos: 5,
        nContaDiversos: '',
        usaDigitosIdentExterna: false,
        tipoFormacaoContaGroupItems: [
          {value: EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes, label: EImportadorSaftDirectTipoFormacaoContaStr.CriarContasCorrentes},
          {value: EImportadorSaftDirectTipoFormacaoConta.UsaContaClientesDiversos, label: EImportadorSaftDirectTipoFormacaoContaStr.UsaContaClientesDiversos}
        ],
        criterioFormacaoGroupItems: [
          {value: EImportadorSaftDirectCriterioFormacao.RadicalSequencia, label: EImportadorSaftDirectCriterioFormacaoStr.RadicalSequencia},
          {value: EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia, label: EImportadorSaftDirectCriterioFormacaoStr.RadicalMercadoSequencia},
          {value: EImportadorSaftDirectCriterioFormacao.RadicalContribuinte, label: EImportadorSaftDirectCriterioFormacaoStr.RadicalContribuinte},
          {value: EImportadorSaftDirectCriterioFormacao.RadicalIdExterna, label: EImportadorSaftDirectCriterioFormacaoStr.RadicalIdExterna},
          {value: EImportadorSaftDirectCriterioFormacao.IgualIdExterna, label: EImportadorSaftDirectCriterioFormacaoStr.IgualIdExterna},
          {value: EImportadorSaftDirectCriterioFormacao.RadicalIdExternaConvertCharsAscii, label: EImportadorSaftDirectCriterioFormacaoStr.RadicalIdExternaConvertCharsAscii}
        ],
        nDigitosHelperText: ''
      },
      correspContasCorrentes: {
        _index: STEP_INDEX_CONFIG_CORRESP_CONTAS_CORRENTES,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        querySearch: '',
        mostrarContasComErro: false,
        mostrarNIFInvalid: false,
        haveNifInvalid: false,
        mostrarContasComErroToolbarItem: null,
        mostrarNIFInvalidToolbarItem: null,
        showPb: false,
        pbText: '',
        tableDefinition: {
          fields: [
            {name: '_selected', caption: '', type: 'boolean', align: 'center'},
            {name: 'isNew', caption: 'importadorSaftDirect.fields.isNew', type: 'boolean', align: 'center'},
            {name: 'customerID', caption: 'importadorSaftDirect.fields.customerID'},
            {name: 'customerTaxID', caption: 'importadorSaftDirect.fields.customerTaxID'},
            {name: 'name', caption: 'importadorSaftDirect.fields.name'},
            {name: 'country', caption: 'importadorSaftDirect.fields.country'},
            {name: 'mercadoStr', caption: 'importadorSaftDirect.fields.mercado'},
            {name: 'jaExiste', caption: 'importadorSaftDirect.fields.jaExiste', type: 'boolean', align: 'center'},
            {name: 'contaDestino', caption: 'importadorSaftDirect.fields.contaDestino'},
            {name: 'codRetencao', caption: 'importadorSaftDirect.fields.codRetencao'},
            {name: 'nCCusto', caption: 'importadorSaftDirect.fields.nCCusto', visible: false},
            {name: 'nCodRepCC', caption: 'importadorSaftDirect.fields.nCodRepCC', visible: false},
            {name: 'nomeContaCG', caption: 'importadorSaftDirect.fields.nomeContaCG'}
          ]
        },
        tableCallback: {},
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableSource: [],
        _cacheSource: []
      },
      correspTaxasIva: {
        _index: STEP_INDEX_CONFIG_CORRESP_TAXAS,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        tableDefinition: {
          fields: [
            {name: 'taxCountryRegion', caption: 'importadorSaftDirect.fields.taxCountryRegion'},
            {name: 'taxCode', caption: 'importadorSaftDirect.fields.taxCode'},
            {name: 'taxPercentage', caption: 'importadorSaftDirect.fields.taxPercentage', type: 'tax'},
            {name: 'taxExemptionCode', caption: 'importadorSaftDirect.fields.motivoTaxa'},
            {name: 'codIva', caption: 'importadorSaftDirect.fields.codIva'},
            {name: 'taxaIva', caption: 'importadorSaftDirect.fields.taxaIva', type: 'tax'},
            {name: 'motivoTaxa', caption: 'importadorSaftDirect.fields.motivoTaxa'},
            {name: 'digitosIva', caption: 'importadorSaftDirect.fields.digitosIva'}
          ]
        },
        tableCallback: {},
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableSource: []
      },
      configArtigos: {
        _index: STEP_INDEX_CONFIG_ARTIGOS,
        _needLoadNext: true,
        _needSave: true,
        toolbarMostrarApenasComErro: null,
        querySearch: '',
        valid: false,
        showPb: false,
        mostrarApenasComErro: false,
        tableDefinition: {
          fields: [
            {name: '_selected', caption: '', type: 'boolean'},
            {name: 'isNew', caption: 'importadorSaftDirect.fields.isNew', type: 'boolean', align: 'center'},
            {name: 'productCode', caption: 'importadorSaftDirect.fields.productCode'},
            {name: 'productDescription', caption: 'importadorSaftDirect.fields.productDescription'},
            {name: 'productTypeStr', caption: 'importadorSaftDirect.fields.productType'},
            {name: 'nConta', caption: 'importadorSaftDirect.fields.nConta'},
            {name: 'nContaNC', caption: 'importadorSaftDirect.fields.nContaNC'},
            {name: 'acresceDigMercado', caption: 'importadorSaftDirect.fields.acresceDigMercado', type: 'boolean', align: 'center'},
            {name: 'acresceDigIVA', caption: 'importadorSaftDirect.fields.acresceDigIVA', type: 'boolean', align: 'center'},
            {name: 'retencao', caption: 'importadorSaftDirect.fields.retencao', type: 'boolean', align: 'center'},
            {name: 'nCCusto', caption: 'importadorSaftDirect.fields.nCCusto', visible: false},
            {name: 'nCodRepCC', caption: 'importadorSaftDirect.fields.nCodRepCC', visible: false},
            {name: 'stateContasFormadas', caption: 'global.text.error'}
          ]
        },
        tableCallback: {},
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableSource: [],
        _cacheSource: []
      },
      datasDocumentos: {
        _index: STEP_INDEX_DATAS_DOCUMENTOS,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        tableDefinition: {
          fields: [
            {name: 'ano', caption: 'global.text.year', type: 'integer'},
            {name: 'mesStr', caption: 'global.text.month'},
            {name: 'dataUltimoDoc', caption: 'importadorSaftDirect.fields.dataUltimoDoc', type: 'date'},
            {name: 'dataLancamento', caption: 'importadorSaftDirect.fields.dataLancamento', type: 'date'}
          ]
        },
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableCallback: {},
        tableSource: [],
        dataLancContab: EImportadorSaftDirectTipoLancamDatas.DataDoDocumento,
        dataLancContabGroupItems: [
          {value: EImportadorSaftDirectTipoLancamDatas.UltimoDiaMes, label: EImportadorSaftDirectTipoLancamDatasStr.UltimoDiaMes},
          {value: EImportadorSaftDirectTipoLancamDatas.UltimaDataDocMes, label: EImportadorSaftDirectTipoLancamDatasStr.UltimaDataDocMes},
          {value: EImportadorSaftDirectTipoLancamDatas.DataDoDocumento, label: EImportadorSaftDirectTipoLancamDatasStr.DataDoDocumento}
        ]
      },
      genDocs: {
        _index: STEP_INDEX_GEN_DOCUMENTOS,
        _needLoadNext: true,
        _needSave: true,
        valid: false,
        totalValorCG: 0,
        totalValorIVACG: 0,
        totalValorBaseCG: 0,
        totalValor: 0,
        totalValorIVA: 0,
        totalValorBase: 0,
        mostrarApenasComErro: false,
        mostrarApenasComErroToolbarItem: null,
        intervalId: -1,
        pbValue: 0,
        showPb: false,
        pbMessage: '',
        tableDefinition: {
          fields: [
            {name: 'select', caption: 'importadorSaftDirect.fields.selectNode', type: 'boolean'},
            {name: 'nDocumento', caption: 'importadorSaftDirect.fields.nDocumento'},
            {name: 'tipoStr', caption: 'importadorSaftDirect.fields.invoiceTypeStr'},
            {name: 'invoices', caption: 'importadorSaftDirect.fields.invoices', tdClass: 'td-no-wrap'},
            {name: 'valorBase', caption: 'importadorSaftDirect.fields.valorBase', type: 'double'},
            {name: 'valorIVA', caption: 'importadorSaftDirect.fields.valorIVA', type: 'double'},
            {name: 'valor', caption: 'importadorSaftDirect.fields.valor', type: 'double'},
            {name: 'valorBaseCG', caption: 'importadorSaftDirect.fields.valorBaseCG', type: 'double'},
            {name: 'valorIVACG', caption: 'importadorSaftDirect.fields.valorIVACG', type: 'double'},
            {name: 'valorCG', caption: 'importadorSaftDirect.fields.valorCG', type: 'double'},
            {name: 'valorArredBase', caption: 'importadorSaftDirect.fields.valorArredBase', type: 'double'},
            {name: 'valorArredIVA', caption: 'importadorSaftDirect.fields.valorArredIVA', type: 'double'}
          ]
        },
        tableProperties: {perPage: 20, suppressEmptyLines: true},
        tableCallback: {},
        tableSource: []
      }
    };
    this.wizSteps.configArtigos.toolbarMostrarApenasComErro = {
      id: 'toolbarMostrarApenasComErro',
      type: 'custom',
      templateRef: undefined
    };
    this.wizSteps.genDocs.mostrarApenasComErroToolbarItem = {
      id: 'mostrarContasComErro',
      type: 'custom',
      templateRef: undefined
    };
    this.pbProcessLabel = 'global.text.initProcess';
    this.pbProcessPos = 0;
    this.pbProcessPos = PROGRESS_BAR_MIN_VALUE;
    this._importadorSaftDirectService.generateSaftUploadUrl().subscribe((url: string) => {
      this.uploadUrl = url;
    });
    this._importProcessCanceled = false;
    this._cleanProcessFileFilters();
    this.cancelImportProcess = this.cancelImportProcess.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.hasRoleContabilidadePremium = hasAuthority(this.session, ROLE.CONTABILIDADEPREMIUM);
    this.tiposImportacao = this.licencaStoreModePublic ? RADIO_GROUP_TIPOS_IMPORTACAO_PT : RADIO_GROUP_TIPOS_IMPORTACAO;
    if (this.currentStatus.currentOp !== EImportadorSaftDirectCurrentOperation.Idle) {
      this.blockedByUser = this.currentStatus.userStartedId !== this.session.userId;

      if (!this.blockedByUser) {
        const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectInProcessModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
        const componentInstance: ImportadorSaftDirectInProcessModalComponent = modalInstance.componentInstance;
        componentInstance.modalMessage = this._plTranslateService.translate('importadorSaftDirect.messages.importInProcessModalMessageCancelJob');
        modalInstance.result.then((result: 'load' | 'reset') => {
          if (result === 'load') {
            this._importadorSaftDirectService
              .loadState()
              .then((response) => {
                this.currentStatus.objModel = response.model;
                this._loadImportProcess(this.currentStatus);
              })
              .catch((reason) => {
                this._handleError(reason);
              });
          } else if (result === 'reset') {
            this._uiReset(STEP_INDEX_WELCOME, true);
            this._importadorSaftDirectService.stopMS().then(() => {
              this._plAlertService.success(this._translateService.instant('importadorSaftDirect.messages.jobCancelled'));
            });
          }
        });
      }
    } else if (this.currentStatus.state === EImportadorSaftDirectState.Error) {
      this._showCriticalError(this.currentStatus.description);
    }

    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    window.clearInterval(this.wizSteps.upload.processFileIntervalId);
    window.clearInterval(this.wizSteps.genDocs.intervalId);
    this._deRegisterOnStart();
  }

  public cancelProcess(): void {
    this._uiReset(STEP_INDEX_UPLOADER);
    this._plAlertService.success(this._translateService.instant('importadorSaftDirect.messages.jobCancelled'));
  }

  public onShowDocsWithErrorOrWarningOnlyChange(value: boolean): void {
    this.wizSteps.importDocs.showDocsWithErrorOrWarningOnly = value;
    this.wizSteps.importDocs.tableDocsSource = orderBy(this.wizSteps.importDocs.tableDocsSource, ['invoiceNo', 'asc']);
    this.dataGridDefinitionDocs.dataSource = this.wizSteps.importDocs.showDocsWithErrorOrWarningOnly
      ? this.wizSteps.importDocs.tableDocsSource.filter((item: IJsonImportadorSaftDirectDocFaturacao) => item.tipoInfo !== EImportadorSaftDirectTipoInfo.None)
      : this.wizSteps.importDocs.tableDocsSource;
  }

  public onShowRecibosWithErrorOrWarningOnlyChange(value: boolean): void {
    this.wizSteps.importDocs.showRecibosWithErrorOrWarningOnly = value;
    this.dataGridDefinitionPayments.dataSource = this.wizSteps.importDocs.showDocsWithErrorOrWarningOnly
      ? this.wizSteps.importDocs.tablePaymentsSource.filter((item: IJsonImportadorSaftDirectDocRecibo) => item.tipoInfo !== EImportadorSaftDirectTipoInfo.None)
      : this.wizSteps.importDocs.tablePaymentsSource;
  }

  public isVisible(blockName: string): boolean {
    switch (blockName) {
      case 'criterioFormacao':
        return this.wizSteps.configFormacaoContas.tipoFormacaoConta === EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes;
      case 'radicalAndDigitos':
        return this.wizSteps.configFormacaoContas.tipoFormacaoConta === EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes;
      case 'nContaDiversos':
        return this.wizSteps.configFormacaoContas.tipoFormacaoConta === EImportadorSaftDirectTipoFormacaoConta.UsaContaClientesDiversos;
      case 'radical':
        return this.wizSteps.configFormacaoContas.criterioFormacao !== EImportadorSaftDirectCriterioFormacao.IgualIdExterna;
      case 'nDigitos':
        return (
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalSequencia ||
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia ||
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExterna ||
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExternaConvertCharsAscii
        );
      case 'dataLancContabTableRow':
        return this.wizSteps.datasDocumentos.dataLancContab !== EImportadorSaftDirectTipoLancamDatas.DataDoDocumento;
      case 'usaDigitosIdentExternaCheckbox':
        return (
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExterna ||
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExternaConvertCharsAscii
        );
      default:
        return true;
    }
  }

  public onCriterioFormacaoChange(value: EImportadorSaftDirectCriterioFormacao): void {
    this.wizSteps.configFormacaoContas.criterioFormacao = value;
    this._checkNDigitosHelperText();
  }

  public setWizStepState(obj: IImportadorSaftDirectWizStepData, needSave: boolean = true, needLoadNext: boolean = true): void {
    obj._needSave = needSave;
    obj._needLoadNext = needLoadNext;
  }

  public editConfigTiposDocsRow(item: TPlTableItemImportadorSaftConfigTipoDocsItem): void {
    this._showConfigTiposDocsEditModal(item);
  }

  public editCorrespContasCorrentesRow(item: TPlTableItemImportadorSaftCorrespContasCorrentesItem): void {
    this._showCorrespContasCorrentesEditModal(item);
  }

  public editTaxasIvaRow(item: TPlTableItemImportadorSaftCorrespTaxasIvaItem): void {
    this._showTaxasIvaEditModal(item);
  }

  public editConfigArtigosRow(item: TPlTableItemImportadorSaftConfigArtigosItem): void {
    this.showConfigArtigosModal(item);
  }

  public showContaFormada(item: TPlTableItemImportadorSaftConfigArtigosItem): void {
    this._importadorSaftDirectService
      .getContaFormadaDoArtigo(item.productCode)
      .then((response) => {
        const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectVerContaFormadaModalComponent, {backdrop: 'static', keyboard: false});
        const componentInstance: ImportadorSaftDirectVerContaFormadaModalComponent = modalInstance.componentInstance;
        componentInstance.source = response.list;
        componentInstance.modalTitle = `${item.productCode}-${item.productDescription}`;
      })
      .catch((reason) => {
        this._handleError(reason);
      });
  }

  public editDatasDocumentosRow(item: TPlTableItemImportadorSaftDatasDocumentosItem): void {
    this._showDatasDocumentosEditModal(item);
  }

  public wizardModelChange(step: IPlNavWizardStep): void {
    if (step.stepId === 'wizStepArtigos') {
      let haveNew = false;
      this.wizSteps.configArtigos.tableSource.forEach((item) => {
        item._selected = item.isNew;
        if (item.isNew) {
          haveNew = true;
        }
      });
      if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.rePaintBody)) {
        this.wizSteps.configArtigos.tableCallback.rePaintBody();
      }
      if (haveNew) {
        this.showConfigArtigosModal(this._configArtigosGetSelected(), undefined, true);
      }
    }
    if (step.stepId === 'wizStepDocsToImport' && this.wizSteps.importDocs.dataGridInstanceDocs) {
      setTimeout(() => {
        this.wizSteps.importDocs.dataGridInstanceDocs.updateDimensions();
      });
    }
  }

  public onSelectConfigTiposDocs(event: ICGTableOnSelect<TPlTableItemImportadorSaftConfigTipoDocsItem>): void {
    if (event.columnIndex === 0) {
      event.item.importa = !event.item.importa;
      this.setWizStepState(this.wizSteps.configTiposDocs);
      this.definitionNavWizard.items[STEP_INDEX_TIPOS_DOCUMENTO].setIncomplete();
      if (this.wizSteps.configTiposDocs.tableCallback && isFunction(this.wizSteps.configTiposDocs.tableCallback.rePaintBody)) {
        this.wizSteps.configTiposDocs.tableCallback.rePaintBody();
      }
    }
    if (event.columnIndex > 0) {
      this._showConfigTiposDocsEditModal(event.item);
    }
  }

  public onSelectCorrespContasCorrentes(event: ICGTableOnSelect<TPlTableItemImportadorSaftCorrespContasCorrentesItem>): void {
    if (event.columnIndex === 0) {
      event.item._selected = !event.item._selected;
      if (this.wizSteps.correspContasCorrentes.tableCallback && isFunction(this.wizSteps.correspContasCorrentes.tableCallback.rePaintBodyItem)) {
        this.wizSteps.correspContasCorrentes.tableCallback.rePaintBodyItem(event.item);
      }
    } else {
      this._showCorrespContasCorrentesEditModal(event.item);
    }
  }

  public onSelectCorrespCorrespTaxasIva(event: ICGTableOnSelect<TPlTableItemImportadorSaftCorrespTaxasIvaItem>): void {
    if (event.columnIndex > 0) {
      this._showTaxasIvaEditModal(event.item);
    }
  }

  public changedMostrarContasComErro(value: boolean): void {
    this.wizSteps.correspContasCorrentes.mostrarContasComErro = value;
    if (this.wizSteps.correspContasCorrentes.tableCallback && isFunction(this.wizSteps.correspContasCorrentes.tableCallback.refresh)) {
      this.wizSteps.correspContasCorrentes.tableCallback.refresh();
    }
  }

  public changedMostrarNIFInvalid(value: boolean): void {
    this.wizSteps.correspContasCorrentes.mostrarNIFInvalid = value;
    if (this.wizSteps.correspContasCorrentes.tableCallback && isFunction(this.wizSteps.correspContasCorrentes.tableCallback.refresh)) {
      this.wizSteps.correspContasCorrentes.tableCallback.refresh();
    }
  }

  public changedConfigArtigosMostrarApenasComErro(value: boolean): void {
    this.wizSteps.configArtigos.mostrarApenasComErro = value;
    if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.refresh)) {
      this.wizSteps.configArtigos.tableCallback.refresh();
    }
  }

  public changedGenDocsMostrarApenasComErro(value: boolean): void {
    this.wizSteps.genDocs.mostrarApenasComErro = value;
    if (this.wizSteps.genDocs.tableCallback && isFunction(this.wizSteps.genDocs.tableCallback.refresh)) {
      this.wizSteps.genDocs.tableCallback.refresh();
    }
  }

  public onSelectCorrespConfigArtigos(event: ICGTableOnSelect<TPlTableItemImportadorSaftConfigArtigosItem>): void {
    if (event.columnIndex === 0) {
      event.item._selected = !event.item._selected;
      if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.rePaintBodyItem)) {
        this.wizSteps.configArtigos.tableCallback.rePaintBodyItem(event.item);
      }
    } else {
      this.showConfigArtigosModal(event.item);
    }
  }

  public onSelectDatasDocumentos(event: ICGTableOnSelect<TPlTableItemImportadorSaftDatasDocumentosItem>): void {
    this._showDatasDocumentosEditModal(event.item);
  }

  public onSelectGenDocs(event: ICGTableOnSelect<TPlTableItemImportadorSaftGenDocsItem>): void {
    if (event.columnIndex === 0) {
      event.item._selected = !event.item._selected;
      if (this.wizSteps.genDocs.tableCallback && isFunction(this.wizSteps.genDocs.tableCallback.rePaintBodyItem)) {
        this.wizSteps.genDocs.tableCallback.rePaintBodyItem(event.item);
      }
    }
  }

  public changedDataLancContab(value: EImportadorSaftDirectTipoLancamDatas): void {
    this.wizSteps.datasDocumentos.dataLancContab = value;
    this._importadorSaftDirectService
      .datasDocumentosTipoLancamDatasChange(this.wizSteps.datasDocumentos.dataLancContab)
      .then((response) => {
        this.setWizStepState(this.wizSteps.datasDocumentos);
        this.definitionNavWizard.items[STEP_INDEX_DATAS_DOCUMENTOS].setIncomplete();
        if (this._isAlive(response.jobState)) {
          this._assignDatasDocumentosSource(response.list);
        }
      })
      .catch((reason) => {
        this._handleError(reason);
      });
  }

  public associarContaCorrenteClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (selList.length) {
      this._showContasCorrentesAssocModal().then((nConta) => {
        if (isDefinedNotNull(nConta)) {
          const clientesKeyList = selList.map<string>((item) => item.customerID);
          this._importadorSaftDirectService
            .correspContasAtribuirConta(clientesKeyList, nConta)
            .then((response) => {
              if (this._isAlive(response.jobState)) {
                this._assignCorrespContasSource(response.list);
              }
            })
            .catch((reason) => {
              this._handleError(reason);
            });
        }
      });
    } else {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
    }
  }

  public removerAssociarContaCorrenteClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (selList.length) {
      const clientesKeyList = selList.map<string>((item) => item.customerID);
      this._importadorSaftDirectService
        .correspContasRemoverContaAssociada(clientesKeyList)
        .then((response) => {
          if (this._isAlive(response.jobState)) {
            this._assignCorrespContasSource(response.list);
          }
        })
        .catch((reason) => {
          this._handleError(reason);
        });
    } else {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
    }
  }

  public configArtigosSelectNewsClick(): void {
    this.wizSteps.configArtigos.tableSource.forEach((item) => {
      item._selected = item.isNew;
    });
    if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.rePaintBody)) {
      this.wizSteps.configArtigos.tableCallback.rePaintBody();
    }
  }

  public showConfigArtigosConfigSelected(): void {
    this.showConfigArtigosModal(this._configArtigosGetSelected());
  }

  public configArtigosRefreshClick(): void {
    this.wizSteps.configArtigos._reloadFromServer = true;
    if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.refresh)) {
      this.wizSteps.configArtigos.tableCallback.refresh();
    }
  }

  public genDocsToggleSelect(sel: boolean): void {
    this.wizSteps.genDocs.tableSource.forEach((item) => {
      item.select = sel;
    });
    if (this.wizSteps.genDocs.tableCallback && isFunction(this.wizSteps.genDocs.tableCallback.rePaintBody)) {
      this.wizSteps.genDocs.tableCallback.rePaintBody();
    }
  }

  public configArtigosToggleSelect(sel: boolean): void {
    this.wizSteps.configArtigos.tableSource.forEach((item) => {
      item._selected = sel;
    });
    if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.rePaintBody)) {
      this.wizSteps.configArtigos.tableCallback.rePaintBody();
    }
  }

  public correspContasCorrentesToggleSelect(sel: boolean): void {
    this.wizSteps.correspContasCorrentes.tableSource.forEach((item) => {
      item._selected = sel;
    });
    if (this.wizSteps.correspContasCorrentes.tableCallback && isFunction(this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody)) {
      this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody();
    }
  }

  public genDocDeleteDocSelected(): void {
    const selList = this.wizSteps.genDocs.tableSource.filter((item) => item.select);
    const docsKeyList = selList.map<string>((item) => item.extPocCabID);
    this._importadorSaftDirectService
      .deleteGeneratedDocsContab(docsKeyList)
      .then((response) => {
        if (this._isAlive(response.jobState)) {
          const newList = this.wizSteps.genDocs.tableSource.filter((item) => !item.select);
          this._assignGenDocsSource(newList);
        }
      })
      .catch((reason) => {
        this._handleError(reason);
      });
  }

  public changedCriaDiariosDescritivos(value: boolean): void {
    this.wizSteps.configTiposDocs.criaDiariosEDescritivos = value;
    if (value) {
      this._importadorSaftDirectService
        .atribuirAutomaticamenteDiarioEDescritivoATiposDoc()
        .then((response) => {
          this._loadConfigTiposDocsStep(response.list);
          this.setWizStepState(this.wizSteps.configTiposDocs);
        })
        .catch((reason) => {
          this._handleError(reason);
        });
    }
  }

  public viewGenDoc(item: TPlTableItemImportadorSaftGenDocsItem): Promise<unknown> {
    return this.maintenanceInstanceDocsContab.maintenanceEdit(item.extPocCabID, {preventDocumentKeyup: false});
  }

  public showConfigArtigosModal(
    selected: TPlTableItemImportadorSaftConfigArtigosItem | Array<TPlTableItemImportadorSaftConfigArtigosItem>,
    tipoProduto?: EImportadorSaftDirectTipoProduto,
    isNew?: boolean
  ): void {
    const selectedItems: Array<TPlTableItemImportadorSaftConfigArtigosItem> = isDefined(selected) ? (isArray(selected) ? selected : [selected]) : [];
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectArtigosConfigModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    const componentInstance: ImportadorSaftDirectArtigosConfigModalComponent = modalInstance.componentInstance;
    componentInstance.selectedItems = selectedItems;
    componentInstance.tipoProduto = tipoProduto;
    componentInstance.source = this.wizSteps.configArtigos.tableSource;
    componentInstance.modalTitle = isNew === true ? 'importadorSaftDirect.strings.artigosNewConfigModalTitle' : 'importadorSaftDirect.strings.artigosConfigModalTitle';
    componentInstance.showCCusto = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorArtigo);
    componentInstance.showRepCC = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao);
    modalInstance.result
      .then((response: IRestCmdImportadorSaftDirectConfigArtigos | IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo) => {
        if (this._isAlive(response.jobState)) {
          this._loadConfigArtigos(response.artigos);
          this.setWizStepState(this.wizSteps.configArtigos);
        }
      })
      .catch((reason) => {
        this._handleError(reason);
      });
  }

  public onSelect({item, columnIndex}: ICGTableOnSelect<unknown>): void {
    if (columnIndex !== -1) {
      item._detailOpen = !item._detailOpen;
    }
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    this.wizSteps.importDocs.dataGridInstanceDocs = event.component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonImportadorSaftDirectDocFaturacao>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && isEmpty(event.data.info)) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.data.tipoInfo === EImportadorSaftDirectTipoInfo.Erro) {
        event.cellElement.classList.add('red-legend-color');
      } else if (event.data.tipoInfo === EImportadorSaftDirectTipoInfo.Aviso) {
        event.cellElement.classList.add('orange-legend-color');
      }
    }
  }

  public isNDigitosDisabled(): boolean {
    return (
      (this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExterna ||
        this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalIdExternaConvertCharsAscii) &&
      !this.wizSteps.configFormacaoContas.usaDigitosIdentExterna
    );
  }

  public showConfigMascaraAnaliticaModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectConfigMascaraAnaliticaModalComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectConfigMascaraAnaliticaModalComponent = modalInstance.componentInstance;
    componentInstance.configModel = {...this.wizSteps.configTiposDocs.configMascaraAnalitica};
    return modalInstance.result.then((changedModel: IImportadorSaftDirectConfigMascaraAnalitica) => {
      if (JSON.stringify(this.wizSteps.configTiposDocs.configMascaraAnalitica) !== JSON.stringify(changedModel)) {
        this.wizSteps.configTiposDocs.configMascaraAnalitica = {...changedModel};
        this._configMascaraAnaliticaChanged();
        this.definitionNavWizard.items[STEP_INDEX_TIPOS_DOCUMENTO].setIncomplete();
        this.setWizStepState(this.wizSteps.configTiposDocs);
      }
    });
  }

  public isVisibleByMaskDependency(visibleFor: EImportadorSaftDirectTipoAnaliticaIS): boolean {
    if (!this.wizSteps.configTiposDocs.configMascaraAnalitica.usaAnalitica) {
      return false;
    }
    return this.wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS === visibleFor;
  }

  public contaCorrenteAtribuirCCustoClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (!selList.length) {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
      return;
    }

    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectCorrespContasAtribuirModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectCorrespContasAtribuirModalComponent = modalInstance.componentInstance;
    componentInstance.field = EImportadorSaftDirectCorrespContasAtribuirFieldEnum.NCCUSTO;
    modalInstance.result.then((modalModel: IImportadorSaftDirectCorrespContasAtribuirModel) => {
      if (isDefinedNotNull(modalModel) && isDefinedNotNull(modalModel.nCCusto)) {
        const clientesKeyList = selList.map<string>((item) => item.customerID);
        this._importadorSaftDirectService.correspContasAtribuirCCusto(clientesKeyList, modalModel.nCCusto, selList.length === this.wizSteps.correspContasCorrentes.tableSource.length).then(() => {
          selList.forEach((tableItem) => {
            tableItem.nCCusto = modalModel.nCCusto;
          });
          this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody();
        });
      }
    });
  }

  public contaCorrenteRemoverCCustoClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (!selList.length) {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
      return;
    }

    this._cgModalService
      .showOkCancel('global.text.confirmation', 'importadorSaftDirect.messages.temACertezaQuePertendeContinuar', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        const clientesKeyList = selList.map<string>((item) => item.customerID);
        this._importadorSaftDirectService.correspContasAtribuirCCusto(clientesKeyList, '', selList.length === this.wizSteps.correspContasCorrentes.tableSource.length).then(() => {
          selList.forEach((tableItem) => {
            tableItem.nCCusto = '';
          });
          this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody();
        });
      });
  }

  public contaCorrenteAtribuirRepCCClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (!selList.length) {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
      return;
    }

    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectCorrespContasAtribuirModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectCorrespContasAtribuirModalComponent = modalInstance.componentInstance;
    componentInstance.field = EImportadorSaftDirectCorrespContasAtribuirFieldEnum.RepCC;
    modalInstance.result.then((modalModel: IImportadorSaftDirectCorrespContasAtribuirModel) => {
      if (isDefinedNotNull(modalModel) && isDefinedNotNull(modalModel.nCodRepCC)) {
        const clientesKeyList = selList.map<string>((item) => item.customerID);
        this._importadorSaftDirectService.correspContasAtribuirRepCC(clientesKeyList, modalModel.nCodRepCC, selList.length === this.wizSteps.correspContasCorrentes.tableSource.length).then(() => {
          selList.forEach((tableItem) => {
            tableItem.nCodRepCC = modalModel.nCodRepCC;
          });
          this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody();
        });
      }
    });
  }

  public contaCorrenteRemoverRepCCClick(): void {
    const selList = this._contasCorrentesGetSelected();
    if (!selList.length) {
      this._plAlertService.error('importadorSaftDirect.messages.contasCorrentesNoSelection');
      return;
    }

    this._cgModalService
      .showOkCancel('global.text.confirmation', 'importadorSaftDirect.messages.temACertezaQuePertendeContinuar', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        const clientesKeyList = selList.map<string>((item) => item.customerID);
        this._importadorSaftDirectService.correspContasAtribuirRepCC(clientesKeyList, 0, selList.length === this.wizSteps.correspContasCorrentes.tableSource.length).then(() => {
          selList.forEach((tableItem) => {
            tableItem.nCodRepCC = 0;
          });
          this.wizSteps.correspContasCorrentes.tableCallback.rePaintBody();
        });
      });
  }

  public checkCheckBoxValues(model: number): void {
    if (model === EImportadorSaftDirectValoresImportar.ImportarVendas || model === EImportadorSaftDirectValoresImportar.ImportarRecibos) {
      this.processFileFilters.processaCompras = this.processFileFilters.processaPagamentos = false;
    } else if (model === EImportadorSaftDirectValoresImportar.ImportarCompras || model === EImportadorSaftDirectValoresImportar.ImportarPagamentos) {
      this.processFileFilters.processaVendas = this.processFileFilters.processaRecibos = false;
    } else {
      this.processFileFilters.processaCompras = this.processFileFilters.processaPagamentos = this.processFileFilters.processaVendas = this.processFileFilters.processaRecibos = false;
    }
  }

  public async cancelImportProcess(): Promise<void> {
    this._importProcessCanceled = true;
    await this._importadorSaftDirectService.stopMS();
    this._uiReset(STEP_INDEX_TIPO_IMPORTACAO, true);
    this.pbProcessPercentage = PROGRESS_BAR_MIN_VALUE;
    this.pbProcessPos = PROGRESS_BAR_MIN_VALUE;
    this.pbProcessLabel = 'global.text.initProcess';
    this._importProcessCanceled = false;
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeStepChange(event);
  public readonly fnValidateTipoImportacaoSelected: () => Promise<boolean> = () => this._validateTipoImportacaoSelected();
  public readonly fnValidateImportDocsStep: () => Promise<boolean> = () => this._validateImportDocsStep();
  public readonly fnValidateUpload: () => Promise<boolean> = () => this._validateUpload();
  public readonly fnValidateProcessFileCloud: () => Promise<boolean> = () => this._validateProcessFileCloud();
  public readonly fnValidateProcessFilePOS: () => Promise<boolean> = () => this._validateProcessFilePOS();
  public readonly fnValidateProcessFilePOSWinTouch: () => Promise<boolean> = () => this._validateProcessFilePOSWinTouch();
  public readonly fnValidateConfigTiposDocsStep: () => Promise<boolean> = () => this._validateConfigTiposDocsStep();
  public readonly fnValidateConfigMercadosStep: () => Promise<boolean> = () => this._validateConfigMercadosStep();
  public readonly fnValidateConfigContasCorrentesStep: () => Promise<boolean> = () => this._validateConfigContasCorrentesStep();
  public readonly fnValidateCorrespContasCorrentesStep: () => Promise<boolean> = () => this._validateCorrespContasCorrentesStep();
  public readonly fnValidateCorrespTaxasIvaStep: () => Promise<boolean> = () => this._validateCorrespTaxasIvaStep();
  public readonly fnValidateConfigArtigosStep: () => Promise<boolean> = () => this._validateConfigArtigosStep();
  public readonly fnValidateDataDocsStep: () => Promise<boolean> = () => this._validateDataDocsStep();
  public readonly fnFinalize: () => Promise<void> = () => this._finalize();
  public readonly fnWizStepGetTiposDocsSource: () => Array<TPlTableItemImportadorSaftConfigTipoDocsItem> = () => this._wizStepGetTiposDocsSource();
  public readonly fnWizStepsCorrespContasCorrentesTableSource: (search: string) => Array<TPlTableItemImportadorSaftCorrespContasCorrentesItem> = (search: string) => {
    return this._wizStepsCorrespContasCorrentesTableSource(search);
  };
  public readonly fnWizStepsCorrespTaxasIvaTableSource: () => Array<TPlTableItemImportadorSaftCorrespTaxasIvaItem> = () => this._wizStepsCorrespTaxasIvaTableSource();
  public readonly fnWizStepsConfigArtigosTableSource: () => Array<TPlTableItemImportadorSaftConfigArtigosItem> | Promise<Array<TPlTableItemImportadorSaftConfigArtigosItem>> = () => {
    return this._wizStepsConfigArtigosTableSource();
  };
  public readonly fnWizStepsDatasDocumentosTableSource: () => Array<TPlTableItemImportadorSaftDatasDocumentosItem> = () => this._wizStepsDatasDocumentosTableSource();
  public readonly fnWizStepsGenDocsTableSource: () => Array<TPlTableItemImportadorSaftGenDocsItem> = () => this._wizStepsGenDocsTableSource();
  public readonly fnKeydownProcessarFile = (value: string, event: KeyboardEvent): void => {
    this._keydownEventProcessarFile(event);
  };

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._importadorSaftDirectService.stopMSUrl(true));
  }

  private _showConfigTiposDocsEditModal(item: TPlTableItemImportadorSaftConfigTipoDocsItem): void {
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectTiposDocsModalComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectTiposDocsModalComponent = modalInstance.componentInstance;
    componentInstance.model = {...item};
    componentInstance.showCCusto = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento);
    componentInstance.showRepCC = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumentoReparticao);
    modalInstance.result.then((model: IJsonImportadorSaftDirectTipoDocumento) => {
      if (isDefined(model)) {
        item = merge(item, model, {
          _isValidRow: true,
          _errorMsg: '',
          rowClass: '',
          _detailOpen: false,
          _hasDetail: false
        });
        this.setWizStepState(this.wizSteps.configTiposDocs);
        this.wizSteps.configTiposDocs.tableCallback.rePaintBodyItem(item);
        this.definitionNavWizard.items[STEP_INDEX_TIPOS_DOCUMENTO].setIncomplete();
      }
    });
  }

  private _showDatasDocumentosEditModal(item: TPlTableItemImportadorSaftDatasDocumentosItem): void {
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectDatasDocumentosEditModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectDatasDocumentosEditModalComponent = modalInstance.componentInstance;
    componentInstance.model = item.dataLancamento;
    componentInstance.dataUltimoDoc = item.dataUltimoDoc;
    modalInstance.result.then((newDataLancamento: string) => {
      if (isDefined(newDataLancamento)) {
        const momDataLanc = moment(newDataLancamento);
        if (!momDataLanc.isSame(item.dataLancamento, 'day')) {
          this.setWizStepState(this.wizSteps.datasDocumentos);
          this.definitionNavWizard.items[STEP_INDEX_DATAS_DOCUMENTOS].setIncomplete();
        }
        item.dataLancamento = momDataLanc;
        this.wizSteps.datasDocumentos.tableCallback.rePaintBodyItem(item);
      }
    });
  }

  private _showCorrespContasCorrentesEditModal(item: TPlTableItemImportadorSaftCorrespContasCorrentesItem): void {
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectCorrespContasModalComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectCorrespContasModalComponent = modalInstance.componentInstance;
    componentInstance.model = {...item};
    componentInstance.showCCusto = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorCliente);
    componentInstance.showRepCC = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorClienteReparticao);
    modalInstance.result.then((model: IJsonImportadorSaftDirectCliente) => {
      if (isDefined(model)) {
        item = merge(item, model);
        this.wizSteps.correspContasCorrentes.tableCallback.rePaintBodyItem(item);
      }
    });
  }

  private _showTaxasIvaEditModal(item: TPlTableItemImportadorSaftCorrespTaxasIvaItem): void {
    const modalInstance = this._cgModalService.showVanilla(ImportadorSaftDirectTaxasIvaModalComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorSaftDirectTaxasIvaModalComponent = modalInstance.componentInstance;
    componentInstance.model = {...item};
    modalInstance.result.then((model: IJsonImportadorSaftDirectTaxaIva) => {
      if (isDefined(model)) {
        item = merge(item, model, {
          _isValidRow: true,
          _errorMsg: '',
          rowClass: '',
          _detailOpen: false,
          _hasDetail: false
        });
        this.setWizStepState(this.wizSteps.correspTaxasIva);
        this.definitionNavWizard.items[STEP_INDEX_CONFIG_CORRESP_TAXAS].setIncomplete();
        this.wizSteps.correspTaxasIva.tableCallback.rePaintBodyItem(item);
      }
    });
  }

  private _checkNDigitosHelperText(): void {
    if (this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalSequencia) {
      this.wizSteps.configFormacaoContas.nDigitosHelperText = 'importadorSaftDirect.steps.contasCorrentes.nDigitosHelperText1';
    } else if (this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia) {
      this.wizSteps.configFormacaoContas.nDigitosHelperText = 'importadorSaftDirect.steps.contasCorrentes.nDigitosHelperText2';
    } else {
      this.wizSteps.configFormacaoContas.nDigitosHelperText = '';
    }
  }

  private _finalize(): Promise<void> {
    if (this._savingGenDocs) {
      return Promise.resolve();
    }
    return this._cgModalService.show(ImportadorSaftDirectFinalizedModalComponent, {size: 'md', keyboard: false, backdrop: 'static'}).then(() => {
      this._importadorSaftDirectService.stopMS(false).then(() => {
        this._wizNavControl(true, true);
        this._uiReset(STEP_INDEX_WELCOME, true);
      });
    });
  }

  private _uiReset(activeWizStepIndex: number, forceChange: boolean = false): void {
    const model: IJsonImportadorSaftDirectObjFullModel = {
      docFaturacaoList: [],
      docFaturacaoSummary: {
        totalDocs: 0,
        total: 0,
        totalIva: 0,
        totalBase: 0
      },
      docRecibosList: [],
      docRecibosSummary: {
        totalPayments: 0,
        total: 0,
        totalDebit: 0,
        totalCredit: 0
      },
      tiposDocumentoList: [],
      clientesList: [],
      taxasIvaList: [],
      artigosList: [],
      datasDocumentosList: [],
      genDocs: [],
      usaAnalitica: false,
      mascara: '',
      tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento,
      criaDiariosEDescritivos: true,
      valorMercadoNacional: '',
      valorMercadoComunitario: '',
      valorOutrosMercados: '',
      tipoFormacaoConta: EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes,
      criterioFormacao: EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia,
      radical: '2111',
      nDigitos: 5,
      nContaDiversos: '',
      usaDigitosIdentExterna: false,
      tipoLancamDatas: EImportadorSaftDirectTipoLancamDatas.DataDoDocumento
    };
    this.inProcess = false;
    window.clearInterval(this.wizSteps.upload.processFileIntervalId);
    window.clearInterval(this.wizSteps.genDocs.intervalId);
    this.wizSteps.upload.showUploader = true;
    this.wizSteps.upload.showProcessFileProgressBar = false;
    this.wizSteps.upload.valid = false;
    if (isFunction(this.plUploadCallback.removeAllFiles)) {
      this.plUploadCallback.removeAllFiles();
    }
    this.definitionNavWizard.items.forEach((item: IPlNavWizardStep) => {
      item.visited = false;
      item.setInvalid();
      item.setIncomplete();
    });
    this._loadFullModel(model);
    if (activeWizStepIndex > 0) {
      for (let i = 0; i < activeWizStepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    } else {
      this.definitionNavWizard.items[0].visited = true;
      this.definitionNavWizard.items[0].setComplete();
    }
    this.plWizardCallback.setStep(this.definitionNavWizard.items[activeWizStepIndex], forceChange);
  }

  private async _validateTipoImportacaoSelected(): Promise<boolean> {
    this._wizNavControl(false);
    if (isDefinedNotNull(this.tipoImportacaoEscolhido)) {
      this._wizNavControl(true);

      const responseData = await this._importadorSaftDirectService.loadConfigProcess().then((response) => response.body);
      if (responseData) {
        this.processFileFilters.utilizadorCloud = responseData.userCloud;
        this.processFileFilters.passwordCloud = responseData.passwordCloud;
        this.processFileFilters.utilizadorPOS = responseData.userPOS;
        this.processFileFilters.passwordPOS = responseData.passwordPOS;
        this.processFileFilters.utilizadorPOSWinTouch = responseData.userPOSWinTouch;
        this.processFileFilters.passwordPOSWinTouch = responseData.passwordPOSWinTouch;
        return true;
      }

      return false;
    }
    this._plAlertService.error('importadorSaftDirect.messages.obgEscolherTipoImportacao');
    this._wizNavControl(true);
    return false;
  }

  private _validateProcessFileCloud(): Promise<boolean> {
    this._wizNavControl(false);
    this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.LoadingData;
    this._importadorSaftDirectService
      .processFileCloud(this.processFileFilters)
      .then(() => {
        this._runProcessFileChecker()
          .catch((reason) => {
            this._logger.error(reason);
            this._handleError(reason);
          })
          .finally(() => {
            this._wizNavControl(true);
          });
      })
      .finally(() => {
        this._wizNavControl(true);
      });
    return Promise.resolve(true);
  }

  private _validateProcessFilePOS(): Promise<boolean> {
    this._wizNavControl(false);
    this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.LoadingData;
    this._importadorSaftDirectService
      .processFilePOS(this.processFileFilters)
      .then(() => {
        this._runProcessFileChecker()
          .catch((reason) => {
            this._logger.error(reason);
            this._handleError(reason);
          })
          .finally(() => {
            this._wizNavControl(true);
          });
      })
      .finally(() => {
        this._wizNavControl(true);
      });
    return Promise.resolve(true);
  }

  private _validateProcessFilePOSWinTouch(): Promise<boolean> {
    this._wizNavControl(false);
    this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.LoadingData;
    this._importadorSaftDirectService
      .processFilePOSWinTouch(this.processFileFilters)
      .then(() => {
        this._runProcessFileChecker()
          .catch((reason) => {
            this._logger.error(reason);
            this._handleError(reason);
          })
          .finally(() => {
            this._wizNavControl(true);
          });
      })
      .finally(() => {
        this._wizNavControl(true);
      });
    return Promise.resolve(true);
  }

  private _validateUpload(): Promise<boolean> {
    this._wizNavControl(false);
    return new Promise<boolean>((resolve) => {
      if (!this.inProcess) {
        this.wizSteps.upload.valid = false;

        if (!this.plUploadCallback.getAcceptedFiles().length) {
          this._plAlertService.error('importadorSaftDirect.messages.noFiles');
          this._wizNavControl(true);
          resolve(false);
          return;
        }

        this.plUploadCallback
          .uploadAll()
          .then(() => {
            this._importadorSaftDirectService
              .processFile()
              .then((processFileResponse) => {
                this.inProcessFilename = processFileResponse.filename;
                this.pbProcessPercentage = PROGRESS_BAR_MIN_VALUE;
                this.pbProcessPos = PROGRESS_BAR_MIN_VALUE;
                this.pbProcessLabel = 'global.text.initProcess';
                this._runProcessFileChecker()
                  .then(() => {
                    resolve(true);
                  })
                  .catch((reason) => {
                    this._logger.error(reason);
                    this._handleError(reason);
                    resolve(false);
                  })
                  .finally(() => {
                    this._wizNavControl(true);
                  });
              })
              .catch((reason: HttpErrorResponse) => {
                this._handleError(reason);
                resolve(false);
              })
              .finally(() => {
                this._wizNavControl(true);
              });
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
            this.wizSteps.upload.showProcessFileProgressBar = false;
            resolve(false);
          })
          .finally(() => {
            this._wizNavControl(true);
          });
      } else {
        this.wizSteps.upload.valid = true;
        resolve(true);
      }
    }).finally(() => {
      this._wizNavControl(true);
    });
  }

  private _loadImportDocsStep(source: Array<IJsonImportadorSaftDirectDocFaturacao>, summary: IJsonImportadorSaftDirectImportDocSummary): void {
    this.wizSteps.importDocs.tableDocsSource = source;
    this.wizSteps.importDocs.showTabDocs = source.length > 0;
    this.wizSteps.importDocs.summaryDocs = {
      totalDocs: summary.totalDocs,
      totalBase: summary.totalBase,
      totalIva: summary.totalIva,
      total: summary.total
    };

    this.wizSteps.importDocs.valid = true;
    this.wizSteps.importDocs.docsHaveErrorsOrWarnings = false;

    for (const item of this.wizSteps.importDocs.tableDocsSource) {
      if (item.tipoInfo !== EImportadorSaftDirectTipoInfo.None) {
        this.wizSteps.importDocs.docsHaveErrorsOrWarnings = true;
        if (item.tipoInfo === EImportadorSaftDirectTipoInfo.Erro) {
          this.wizSteps.importDocs.valid = false;
        }
      }
    }

    this.setWizStepState(this.wizSteps.importDocs);
    this.onShowDocsWithErrorOrWarningOnlyChange(this.wizSteps.importDocs.showDocsWithErrorOrWarningOnly);
  }

  private _loadImportRecibosStep(source: Array<IJsonImportadorSaftDirectDocRecibo>, summary: IJsonImportadorSaftDirectImportRecibosSummary): void {
    this.wizSteps.importDocs.tablePaymentsSource = source;
    this.wizSteps.importDocs.showTabPayments = source.length > 0;
    this.wizSteps.importDocs.summaryRecibos = {
      totalPayments: summary.totalPayments,
      totalDebit: summary.totalDebit,
      totalCredit: summary.totalCredit,
      total: summary.total
    };

    this.wizSteps.importDocs.paymentsHaveErrorsOrWarnings = false;
    for (const item of this.wizSteps.importDocs.tablePaymentsSource) {
      if (item.tipoInfo !== EImportadorSaftDirectTipoInfo.None) {
        this.wizSteps.importDocs.paymentsHaveErrorsOrWarnings = true;
      }
    }

    this.onShowRecibosWithErrorOrWarningOnlyChange(this.wizSteps.importDocs.showRecibosWithErrorOrWarningOnly);
    this.setWizStepState(this.wizSteps.importDocs);
  }

  private _haveDocsToImport(): boolean {
    return isDefined(this.wizSteps.importDocs.tableDocsSource.find((item) => !item.jaImportado));
  }

  private _havePaymentsToImport(): boolean {
    return isDefined(this.wizSteps.importDocs.tablePaymentsSource.find((item) => !item.jaImportado));
  }

  private async _validateImportDocsStep(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      if (!this._haveDocsToImport() && !this._havePaymentsToImport()) {
        await this._cgModalService.showOkCancel('global.text.error', 'importadorSaftDirect.strings.noDocsPaymentsToImports', {
          size: 'md',
          showCancelBtn: false,
          backdrop: 'static',
          keyboard: false
        });
        return false;
      }

      if (!this.wizSteps.importDocs.valid) {
        try {
          await this._cgModalService.showOkCancel('global.text.confirmation', 'importadorSaftDirect.steps.viewDocsToImport.existemDocsComErroDesejaContinuar', {
            size: 'md',
            btnOkText: 'global.btn.yes',
            btnCancelText: 'global.btn.no',
            backdrop: 'static',
            keyboard: false
          });
        } catch {
          return false;
        }
      }

      if (this.wizSteps.importDocs.showTabPayments && this.wizSteps.importDocs.paymentsHaveErrorsOrWarnings) {
        await this._promptPaymentsWithErroOrWarnContinue();
      }

      if (this.wizSteps.importDocs._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectGetTiposDocumento = await this._importadorSaftDirectService.getTiposDocumento();
        if (this._isAlive(response.jobState)) {
          const responseConfigMascaraAnalitica: IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica = await this._importadorSaftDirectService.loadConfiguracoesMascaraAnalitica();
          this._setConfigTiposDocsConfigMascaraAnalitica(responseConfigMascaraAnalitica.usaAnalitica, responseConfigMascaraAnalitica.mascara, responseConfigMascaraAnalitica.tipoAnaliticaIS);
          this._loadConfigTiposDocsStep(response.list, response.criaDiariosEDescritivos);
        }
      }

      return true;
    } catch (reason: unknown) {
      this._handleError(reason);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private _promptPaymentsWithErroOrWarnContinue(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', 'importadorSaftDirect.messages.importHaveErrorsOrWarningsModalMessage', {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _correspContasCorrentesWithInvalidNifsPromptContinue(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', 'importadorSaftDirect.messages.correspCCHaveInvalidNifsModalMessage', {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _wizStepGetTiposDocsSource(): Array<TPlTableItemImportadorSaftConfigTipoDocsItem> {
    for (const item of this.wizSteps.configTiposDocs.tableSource) {
      item._hasDetail = !item._isValidRow;
    }
    return this.wizSteps.configTiposDocs.tableSource;
  }

  private async _validateConfigTiposDocsStep(): Promise<boolean> {
    let isValidData = true;
    const keyList: Array<IJsonImportadorSaftDirectTipoDocumento> = [];
    for (const item of this.wizSteps.configTiposDocs.tableSource) {
      item._isValidRow = true;
      item._errorMsg = '';
      item.rowClass = '';
      item._detailOpen = false;
      item._hasDetail = false;

      if (item.importa) {
        if (this.wizSteps.configTiposDocs.configMascaraAnalitica.usaAnalitica) {
          if (
            this.wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS === EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento &&
            (isUndefinedOrNull(item.nCCusto) || item.nCCusto.length === 0)
          ) {
            item._isValidRow = false;
            item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.temIndicarCentroCusto');
            item.rowClass = 'red-legend-color';
            item._detailOpen = true;
            item._hasDetail = true;
            isValidData = false;
          }

          if (
            this.wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS === EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumentoReparticao &&
            (isUndefinedOrNull(item.nCodRepCC) || item.nCodRepCC === 0)
          ) {
            item._isValidRow = false;
            item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.temIndicarCodRepCC');
            item.rowClass = 'red-legend-color';
            item._detailOpen = true;
            item._hasDetail = true;
            isValidData = false;
          }
        }

        if (isValidData) {
          if (item.invoiceType === EImportadorSaftDirectInvoiceType.Recibo) {
            if (!item.contaCaixa.length) {
              item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.configTiposDocsTemIndicarContaCaixa');
              item.rowClass = 'red-legend-color';
              item._isValidRow = false;
              item._detailOpen = true;
              item._hasDetail = true;
              isValidData = false;
            }
          } else if (!item.lancaCaixa && !item.lancaContaCorrente) {
            item._isValidRow = false;
            item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.configTiposDocsTemSelLancaCaixaOuLancaCC');
            item.rowClass = 'red-legend-color';
            item._detailOpen = true;
            item._hasDetail = true;
            isValidData = false;
          } else if (item.lancaCaixa && !item.contaCaixa.length) {
            item._isValidRow = false;
            item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.configTiposDocsTemIndicarContaCaixa');
            item.rowClass = 'red-legend-color';
            item._detailOpen = true;
            item._hasDetail = true;
            isValidData = false;
          }
        }
      }

      if (item._isValidRow) {
        const cleanItem: TPlTableItemImportadorSaftConfigTipoDocsItem = copy(item);
        delete cleanItem._hasDetail;
        delete cleanItem._isValidRow;
        delete cleanItem.rowClass;
        delete cleanItem._detailOpen;
        delete cleanItem._errorMsg;
        delete cleanItem.empty;
        keyList.push(cleanItem);
      }
    }

    if (!isValidData) {
      return false;
    }

    this._wizNavControl(false);
    try {
      let alive = true;
      if (this.wizSteps.configTiposDocs._needSave) {
        const response: IRestCmdImportadorSaftDirectSaveTiposDocumento = await this._importadorSaftDirectService.saveConfigTiposDocumento(
          keyList,
          this.wizSteps.configTiposDocs.criaDiariosEDescritivos
        );
        alive = this._isAlive(response.jobState);
      }

      if (alive && this.wizSteps.configTiposDocs._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectLoadConfigMercados = await this._importadorSaftDirectService.loadConfigMercados();
        if (this._isAlive(response.jobState)) {
          this._loadConfigMercados(response.valorMercadoNacional, response.valorOutrosMercados, response.valorMercadoComunitario);
        }
      }

      return true;
    } catch (error) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private async _validateConfigMercadosStep(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      let alive = true;
      if (this.wizSteps.configMercados._needSave) {
        const response: IRestCmdImportadorSaftDirectSaveConfigMercados = await this._importadorSaftDirectService.saveConfigMercados(
          this.wizSteps.configMercados.valorMercadoNacional,
          this.wizSteps.configMercados.valorMercadoComunitario,
          this.wizSteps.configMercados.valorOutrosMercados
        );
        alive = this._isAlive(response.jobState);
      }

      if (alive && this.wizSteps.configMercados._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectLoadConfigFormacaoConta = await this._importadorSaftDirectService.loadConfigFormacaoConta();
        if (this._isAlive(response.jobState)) {
          this._loadConfigFormacaoConta(response.tipoFormacaoConta, response.criterioFormacao, response.radical, response.nDigitos, response.nContaDiversos, response.usaDigitosIdentExterna);
        }
      }

      return true;
    } catch (error) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private _loadFullModel(model: IJsonImportadorSaftDirectObjFullModel): void {
    this._loadImportDocsStep(model.docFaturacaoList, model.docFaturacaoSummary);
    this._loadImportRecibosStep(model.docRecibosList, model.docRecibosSummary);
    this._setConfigTiposDocsConfigMascaraAnalitica(model.usaAnalitica, model.mascara, model.tipoAnaliticaIS);
    this._loadConfigTiposDocsStep(model.tiposDocumentoList, model.criaDiariosEDescritivos);
    this._loadConfigMercados(model.valorMercadoNacional, model.valorOutrosMercados, model.valorMercadoComunitario);
    this._loadConfigFormacaoConta(model.tipoFormacaoConta, model.criterioFormacao, model.radical, model.nDigitos, model.nContaDiversos, model.usaDigitosIdentExterna);
    this._loadCorrespContasCorrentes(model.clientesList);
    this._loadCorrespTaxas(model.taxasIvaList);
    this._loadConfigArtigos(model.artigosList);
    this._loadDatasDocumentos(model.datasDocumentosList, model.tipoLancamDatas);
    this._loadGeneratedDocs(model.genDocs);
  }

  private _loadImportProcess(status: IJsonImportadorSaftDirectStatus): void {
    this._loadFullModel(status.objModel);
    let stepIndex = this._getStepIndexOfCurrentOp(status.currentOp, status.state);
    for (let i = 0; i <= stepIndex; i++) {
      const obj: IImportadorSaftDirectWizStepData = this._getWizStepsByIndex(i);
      if (i < stepIndex) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
      if (obj) {
        const bool: boolean = i === stepIndex;
        this.setWizStepState(obj, bool, bool);
      }
    }

    if (stepIndex === STEP_INDEX_DOCUMENTOS) {
      this._checkImportDocsErrors();
    }

    if (status.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentos || status.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentosAgruparDocumentos) {
      if (status.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentos && status.state === EImportadorSaftDirectState.Error) {
        stepIndex = STEP_INDEX_DATAS_DOCUMENTOS;
        this._showSaveDocsErrorModal(status.description);
      } else {
        this._startGenDocProgressChecker();
      }
    } else if (status.currentOp === EImportadorSaftDirectCurrentOperation.ProcessFile) {
      this._runProcessFileChecker();
    }
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _setConfigTiposDocsConfigMascaraAnalitica(usaAnalitica: boolean, mascara: string, tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS): void {
    this.wizSteps.configTiposDocs.configMascaraAnalitica.usaAnalitica = usaAnalitica;
    this.wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS = tipoAnaliticaIS;

    if (isDefined(mascara)) {
      this.wizSteps.configTiposDocs.configMascaraAnalitica.mascara = mascara;
      this.wizSteps.configTiposDocs.configMascaraAnalitica._mascaraObj = {mascara: mascara};
    } else {
      this.wizSteps.configTiposDocs.configMascaraAnalitica.mascara = '';
      this.wizSteps.configTiposDocs.configMascaraAnalitica._mascaraObj = undefined;
    }
    this._configMascaraAnaliticaChanged();
  }

  private _loadConfigTiposDocsStep(source: Array<TPlTableItemImportadorSaftConfigTipoDocsItem>, criaDiariosEDescritivos?: boolean): void {
    if (isDefined(criaDiariosEDescritivos)) {
      this.wizSteps.configTiposDocs.criaDiariosEDescritivos = criaDiariosEDescritivos;
    }

    source.forEach((item) => {
      item._errorMsg = '';
      item._isValidRow = true;
    });

    this.wizSteps.configTiposDocs.tableSource = source;
    this.setWizStepState(this.wizSteps.configTiposDocs);
    if (this.wizSteps.configTiposDocs.tableCallback && isFunction(this.wizSteps.configTiposDocs.tableCallback.refresh)) {
      this.wizSteps.configTiposDocs.tableCallback.refresh();
    }
  }

  private _loadConfigMercados(valorMercadoNacional: string, valorOutrosMercados: string, valorMercadoComunitario: string): void {
    this.wizSteps.configMercados.valorMercadoNacional = valorMercadoNacional;
    this.wizSteps.configMercados.valorOutrosMercados = valorOutrosMercados;
    this.wizSteps.configMercados.valorMercadoComunitario = valorMercadoComunitario;
    this.setWizStepState(this.wizSteps.configMercados);
  }

  private _loadConfigFormacaoConta(
    tipoFormacaoConta: EImportadorSaftDirectTipoFormacaoConta,
    criterioFormacao: EImportadorSaftDirectCriterioFormacao,
    radical: string,
    nDigitos: number,
    nContaDiversos: string,
    usaDigitosIdentExterna: boolean
  ): void {
    this.wizSteps.configFormacaoContas = merge(this.wizSteps.configFormacaoContas, {
      tipoFormacaoConta: tipoFormacaoConta,
      criterioFormacao: criterioFormacao,
      radical: radical,
      nDigitos: nDigitos,
      nContaDiversos: nContaDiversos,
      usaDigitosIdentExterna: usaDigitosIdentExterna
    });

    this.setWizStepState(this.wizSteps.configFormacaoContas);
    this._checkNDigitosHelperText();
  }

  private _getStepIndexOfCurrentOp(currentOp: EImportadorSaftDirectCurrentOperation, state: EImportadorSaftDirectState): number {
    switch (currentOp) {
      case EImportadorSaftDirectCurrentOperation.ProcessFile:
        return state === EImportadorSaftDirectState.Started
          ? STEP_INDEX_UPLOADER
          : this.wizSteps.importDocs.docsHaveErrorsOrWarnings || this.wizSteps.importDocs.paymentsHaveErrorsOrWarnings
            ? STEP_INDEX_DOCUMENTOS
            : STEP_INDEX_TIPOS_DOCUMENTO;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesTiposDocumento:
        return STEP_INDEX_CONFIG_MERCADOS;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesMercado:
        return STEP_INDEX_CONFIG_FORMACAO_CONTA;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesFormacaoConta:
        return STEP_INDEX_CONFIG_CORRESP_CONTAS_CORRENTES;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesCorrespondenciaContas:
        return STEP_INDEX_CONFIG_CORRESP_TAXAS;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesCorrespondenciaTaxas:
        return STEP_INDEX_CONFIG_ARTIGOS;
      case EImportadorSaftDirectCurrentOperation.SaveConfiguracoesArtigos:
        return STEP_INDEX_DATAS_DOCUMENTOS;
      case EImportadorSaftDirectCurrentOperation.SaveDocumentosAgruparDocumentos:
      case EImportadorSaftDirectCurrentOperation.SaveDocumentos:
        return STEP_INDEX_GEN_DOCUMENTOS;
      default:
        return STEP_INDEX_WELCOME;
    }
  }

  private _wizStepsCorrespContasCorrentesTableSource(search: string): Array<TPlTableItemImportadorSaftCorrespContasCorrentesItem> {
    let source;

    if (this.wizSteps.correspContasCorrentes.mostrarNIFInvalid || this.wizSteps.correspContasCorrentes.mostrarContasComErro) {
      source = [];

      if (this.wizSteps.correspContasCorrentes.mostrarNIFInvalid) {
        source = source.concat(this.wizSteps.correspContasCorrentes.tableSource.filter((item) => item.nifError === EImportadorSaftDirectNifErrorType.Invalido));
      }

      if (this.wizSteps.correspContasCorrentes.mostrarContasComErro) {
        source = source.concat(this.wizSteps.correspContasCorrentes.tableSource.filter((item) => item.nifError === EImportadorSaftDirectNifErrorType.Critical));
      }
    } else {
      source = [...this.wizSteps.correspContasCorrentes._cacheSource];
    }

    this.wizSteps.correspContasCorrentes.tableSource = source;

    if (search.length) {
      this.wizSteps.correspContasCorrentes.tableSource = cgcFilter(source, search);
    }

    return this.wizSteps.correspContasCorrentes.tableSource;
  }

  private async _validateConfigContasCorrentesStep(): Promise<boolean> {
    if (this.wizSteps.configFormacaoContas.tipoFormacaoConta === EImportadorSaftDirectTipoFormacaoConta.CriarContasCorrentes) {
      if (this.wizSteps.configFormacaoContas.criterioFormacao !== EImportadorSaftDirectCriterioFormacao.IgualIdExterna) {
        if (!this.wizSteps.configFormacaoContas.radical) {
          this._plAlertService.error('importadorSaftDirect.messages.configFormacaoContasRadicalInvalid');
          return false;
        }

        if (
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalSequencia ||
          this.wizSteps.configFormacaoContas.criterioFormacao === EImportadorSaftDirectCriterioFormacao.RadicalMercadoSequencia
        ) {
          if (!this.wizSteps.configFormacaoContas.nDigitos) {
            this._plAlertService.error('importadorSaftDirect.messages.configFormacaoContasNDigitosInvalid');
            return false;
          }
        }
      }
    } else if (!this.wizSteps.configFormacaoContas.nContaDiversos.length) {
      this._plAlertService.error('importadorSaftDirect.messages.configFormacaoContasNContaDiversosInvalid');
      return false;
    }

    this._wizNavControl(false);

    try {
      let alive = true;
      if (this.wizSteps.configFormacaoContas._needSave) {
        const response: IRestCmdImportadorSaftDirectSaveConfigFormacaoConta = await this._importadorSaftDirectService.saveConfigFormacaoConta(
          this.wizSteps.configFormacaoContas.tipoFormacaoConta,
          this.wizSteps.configFormacaoContas.criterioFormacao,
          this.wizSteps.configFormacaoContas.nContaDiversos,
          this.wizSteps.configFormacaoContas.nDigitos,
          this.wizSteps.configFormacaoContas.radical,
          this.wizSteps.configFormacaoContas.usaDigitosIdentExterna
        );
        alive = this._isAlive(response.jobState);
      }

      if (alive && this.wizSteps.configFormacaoContas._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectGetCorrespondenciaContas = await this._importadorSaftDirectService.getCorrespondenciaContas();
        if (this._isAlive(response.jobState)) {
          this._loadCorrespContasCorrentes(response.list);
        }
      }

      return true;
    } catch (error) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private _loadCorrespContasCorrentes(source: Array<IJsonImportadorSaftDirectCliente>): void {
    source.forEach((item) => {
      item.mercadoStr = EImportadorSaftDirectTipoMercado[item.mercado];
      if (item.nifError === EImportadorSaftDirectNifErrorType.Invalido) {
        this.wizSteps.correspContasCorrentes.haveNifInvalid = true;
      }
    });
    this.setWizStepState(this.wizSteps.correspContasCorrentes);
    this._assignCorrespContasSource(source);
  }

  private _wizStepsCorrespTaxasIvaTableSource(): Array<TPlTableItemImportadorSaftCorrespTaxasIvaItem> {
    for (const item of this.wizSteps.correspTaxasIva.tableSource) {
      item._hasDetail = !item._isValidRow;
    }
    return this.wizSteps.correspTaxasIva.tableSource;
  }

  private _wizStepsConfigArtigosTableSource(): Promise<Array<TPlTableItemImportadorSaftConfigArtigosItem>> {
    if (isDefined(this.wizSteps.configArtigos._reloadFromServer) && this.wizSteps.configArtigos._reloadFromServer) {
      return new Promise<Array<TPlTableItemImportadorSaftConfigArtigosItem>>((resolve, reject) => {
        this._importadorSaftDirectService
          .loadConfigArtigos()
          .then((response) => {
            if (this._isAlive(response.jobState)) {
              this.wizSteps.configArtigos.tableSource = response.list;
              this.wizSteps.configArtigos._cacheSource = response.list;
              this.wizSteps.configArtigos._reloadFromServer = false;
              resolve(response.list);
            }
          })
          .catch((reason: HttpErrorResponse) => {
            this._handleError(reason);
            reject(reason);
          });
      });
    }

    this.wizSteps.configArtigos.tableSource = this.wizSteps.configArtigos.mostrarApenasComErro
      ? this.wizSteps.configArtigos.tableSource.filter((item) => item.stateContasFormadas !== '')
      : this.wizSteps.configArtigos._cacheSource;

    return Promise.resolve(this.wizSteps.configArtigos.tableSource);
  }

  private async _validateCorrespContasCorrentesStep(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      let canContinue = true;
      if (this.wizSteps.correspContasCorrentes.haveNifInvalid) {
        await this._correspContasCorrentesWithInvalidNifsPromptContinue();
        canContinue = true;
      }

      if (canContinue) {
        this.wizSteps.correspContasCorrentes.showPb = true;

        let alive = true;

        if (this.wizSteps.correspContasCorrentes._needSave) {
          this.wizSteps.correspContasCorrentes.pbText = 'importadorSaftDirect.steps.correspContasCorrentes.pbSaveText';
          const saveResponse: IRestCmdImportadorSaftDirectSaveConfigCorrespContas = await this._importadorSaftDirectService.saveConfigCorrespContas();
          alive = this._isAlive(saveResponse.jobState);
        }

        if (alive && this.wizSteps.correspContasCorrentes._needLoadNext) {
          this.wizSteps.correspContasCorrentes.pbText = 'importadorSaftDirect.steps.correspContasCorrentes.pbLoadCorrespTaxas';
          const response: IRestCmdImportadorSaftDirectGetCorrespTaxas = await this._importadorSaftDirectService.getCorrespTaxas();
          if (this._isAlive(response.jobState)) {
            this._loadCorrespTaxas(response.list);
          }
        }

        return true;
      }
      return false;
    } catch (error: unknown) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
      this.wizSteps.correspContasCorrentes.showPb = false;
    }
  }

  private async _validateCorrespTaxasIvaStep(): Promise<boolean> {
    let isValidData = true;
    for (const item of this.wizSteps.correspTaxasIva.tableSource) {
      item._isValidRow = true;
      item._errorMsg = '';
      item.rowClass = '';
      item._detailOpen = false;
      item._hasDetail = false;
      if (item.codIva < 0) {
        item._isValidRow = false;
        item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.correspTaxasIvaCodIvaInvalid', {
          taxa: `${item.taxCode}-${this._plI18nPlNumberService.formatTax(item.taxPercentage)}`
        });
        item.rowClass = 'red-legend-color';
        item._detailOpen = true;
        item._hasDetail = true;
        isValidData = false;
      } else if (!item.digitosIva?.length) {
        item._isValidRow = false;
        item._errorMsg = this._translateService.instant('importadorSaftDirect.messages.correspTaxasIvaDigitosIvaEmpty', {
          taxa: `${item.taxCode}-${this._plI18nPlNumberService.formatTax(item.taxPercentage)}`
        });
        item.rowClass = 'red-legend-color';
        item._detailOpen = true;
        item._hasDetail = true;
        isValidData = false;
      }
    }

    if (!isValidData) {
      return false;
    }

    this._wizNavControl(false);

    try {
      let alive = true;

      if (this.wizSteps.correspTaxasIva._needSave) {
        const response: IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas = await this._importadorSaftDirectService.saveConfigCorrespTaxas();
        alive = this._isAlive(response.jobState);
      }

      if (alive && this.wizSteps.correspTaxasIva._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectLoadConfigArtigos = await this._importadorSaftDirectService.loadConfigArtigos();
        if (this._isAlive(response.jobState)) {
          this._loadConfigArtigos(response.list);
        }
      }

      return true;
    } catch (error: unknown) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private _loadCorrespTaxas(source: Array<IJsonImportadorSaftDirectTaxaIva>): void {
    source.forEach((item) => {
      item._errorMsg = '';
      item._isValidRow = true;
    });
    this.wizSteps.correspTaxasIva.tableSource = source;
    this.setWizStepState(this.wizSteps.correspTaxasIva);
    if (this.wizSteps.correspTaxasIva.tableCallback && isFunction(this.wizSteps.correspTaxasIva.tableCallback.refresh)) {
      this.wizSteps.correspTaxasIva.tableCallback.refresh();
    }
  }

  private _assignConfigArtigosSource(source: Array<IJsonImportadorSaftDirectArtigo>): void {
    this.wizSteps.configArtigos.tableSource = source;
    this.wizSteps.configArtigos._cacheSource = source;
    if (this.wizSteps.configArtigos.tableCallback && isFunction(this.wizSteps.configArtigos.tableCallback.refresh)) {
      this.wizSteps.configArtigos.tableCallback.refresh();
    }
  }

  private _assignDatasDocumentosSource(source: Array<IJsonImportadorSaftDirectDataInfo>): void {
    for (const item of source) {
      item.mesStr = moment()
        .month(item.mes - 1)
        .format('MMMM');
    }
    this.wizSteps.datasDocumentos.tableSource = source;
    if (this.wizSteps.datasDocumentos.tableCallback && isFunction(this.wizSteps.datasDocumentos.tableCallback.refresh)) {
      this.wizSteps.datasDocumentos.tableCallback.refresh();
    }
  }

  private _assignCorrespContasSource(source: Array<TPlTableItemImportadorSaftCorrespContasCorrentesItem>): void {
    for (const item of source) {
      item._isValidRow = item.nifError === EImportadorSaftDirectNifErrorType.None;
      item._errorMsg = item.erro;
      item._detailOpen = !item._isValidRow;
      item._hasDetail = !item._isValidRow;
      item.rowClass = !item._isValidRow ? 'red-legend-color' : '';
    }
    this.wizSteps.correspContasCorrentes.tableSource = source;
    this.wizSteps.correspContasCorrentes._cacheSource = source;
    if (this.wizSteps.correspContasCorrentes.tableCallback && isFunction(this.wizSteps.correspContasCorrentes.tableCallback.refresh)) {
      this.wizSteps.correspContasCorrentes.tableCallback.refresh();
    }
  }

  private _assignGenDocsSource(source: Array<IJsonImportadorSaftDirectGeneratedDocContab>): void {
    this.wizSteps.genDocs.totalValorBase = 0;
    this.wizSteps.genDocs.totalValorIVA = 0;
    this.wizSteps.genDocs.totalValor = 0;
    this.wizSteps.genDocs.totalValorBaseCG = 0;
    this.wizSteps.genDocs.totalValorIVACG = 0;
    this.wizSteps.genDocs.totalValorCG = 0;
    source.forEach((item) => {
      this.wizSteps.genDocs.totalValorBase += item.valorBase;
      this.wizSteps.genDocs.totalValorIVA += item.valorIVA;
      this.wizSteps.genDocs.totalValor += item.valor;
      this.wizSteps.genDocs.totalValorBaseCG += item.valorBaseCG;
      this.wizSteps.genDocs.totalValorIVACG += item.valorIVACG;
      this.wizSteps.genDocs.totalValorCG += item.valorCG;
    });
    this.wizSteps.genDocs.tableSource = source;
    if (this.wizSteps.genDocs.tableCallback && isFunction(this.wizSteps.genDocs.tableCallback.refresh)) {
      this.wizSteps.genDocs.tableCallback.refresh();
    }
  }

  private _loadConfigArtigos(source: Array<TPlTableItem<IJsonImportadorSaftDirectArtigo>>): void {
    for (const item of source) {
      item._selected = item.isNew;
    }
    this.setWizStepState(this.wizSteps.configArtigos);
    this._assignConfigArtigosSource(source);
  }

  private _loadDatasDocumentos(source: Array<IJsonImportadorSaftDirectDataInfo>, tipoLancamDatas: EImportadorSaftDirectTipoLancamDatas): void {
    this.setWizStepState(this.wizSteps.datasDocumentos);
    this._assignDatasDocumentosSource(source);
    this.wizSteps.datasDocumentos.dataLancContab = tipoLancamDatas;
  }

  private _loadGeneratedDocs(source: Array<TPlTableItemImportadorSaftGenDocsItem>): void {
    for (const item of source) {
      item.errorType = EImportadorSaftDirectGeneratedDocContabErrorType.None;
      item._hasDetail = false;
      item._detailOpen = false;
      if (item.erro.length || item.valorArredBase > 1 || item.valorArredIVA > 1) {
        item._hasDetail = true;
        item._detailOpen = true;
        if (item.erro.length) {
          item.errorType = EImportadorSaftDirectGeneratedDocContabErrorType.Critical;
          item.rowClass = 'red-legend-color';
        } else if (item.valorArredBase > 1 || item.valorArredIVA > 1) {
          item.errorType = EImportadorSaftDirectGeneratedDocContabErrorType.Warning;
          item.rowClass = 'orange-legend-color';
        }
      }
    }

    this.setWizStepState(this.wizSteps.genDocs);
    this._assignGenDocsSource(source);
  }

  private _getWizStepsByIndex(stepIndex: number): IImportadorSaftDirectWizStepData {
    for (const prop of Object.keys(this.wizSteps)) {
      if (this.wizSteps[prop]._index === stepIndex) {
        return this.wizSteps[prop];
      }
    }
    return undefined;
  }

  private _contasCorrentesGetSelected(): Array<TPlTableItemImportadorSaftCorrespContasCorrentesItem> {
    return this.wizSteps.correspContasCorrentes.tableSource.filter((item) => item._selected);
  }

  private _configArtigosGetSelected(): Array<TPlTableItemImportadorSaftConfigArtigosItem> {
    return this.wizSteps.configArtigos.tableSource.filter((item) => item._selected);
  }

  private _showContasCorrentesAssocModal(): Promise<string> {
    return this._cgModalService.show(ImportadorSaftDirectCorrespContasAssociarModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
  }

  private async _validateConfigArtigosStep(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      let alive = true;

      this.wizSteps.configArtigos.showPb = true;

      if (this.wizSteps.configArtigos._needSave) {
        const response: IRestCmdImportadorSaftDirectSaveConfigArtigos = await this._importadorSaftDirectService.saveConfigArtigos();
        alive = this._isAlive(response.jobState);
      }

      if (alive && this.wizSteps.configArtigos._needLoadNext) {
        const response: IRestCmdImportadorSaftDirectLoadDatasDocumentos = await this._importadorSaftDirectService.loadDatasDocumentos();
        if (this._isAlive(response.jobState)) {
          this._loadDatasDocumentos(response.list, EImportadorSaftDirectTipoLancamDatas.DataDoDocumento);
        }
      }

      return true;
    } catch (error: unknown) {
      this._handleError(error);
      return false;
    } finally {
      this.wizSteps.configArtigos.showPb = false;
      this._wizNavControl(true);
    }
  }

  private async _validateDataDocsStep(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      let alive = true;
      if (this.wizSteps.datasDocumentos._needSave) {
        const saveResponse: IRestCmdImportadorSaftDirectSaveDocumentos = await this._importadorSaftDirectService.saveDocumentos();
        alive = this._isAlive(saveResponse.jobState);
      }

      if (alive && this.wizSteps.datasDocumentos._needLoadNext) {
        this._startGenDocProgressChecker();
      }

      return true;
    } catch (error: unknown) {
      this._handleError(error);
      return false;
    } finally {
      this._wizNavControl(true);
    }
  }

  private _wizStepsDatasDocumentosTableSource(): Array<TPlTableItemImportadorSaftDatasDocumentosItem> {
    return this.wizSteps.datasDocumentos.tableSource;
  }

  private _wizStepsGenDocsTableSource(): Array<TPlTableItemImportadorSaftGenDocsItem> {
    return this.wizSteps.genDocs.mostrarApenasComErro ? this.wizSteps.genDocs.tableSource.filter((item) => item.erro.length > 0) : this.wizSteps.genDocs.tableSource;
  }

  private _startGenDocProgressChecker(): void {
    this.wizSteps.genDocs.showPb = true;
    this._savingGenDocs = true;
    this._wizNavControl(false);
    this.wizSteps.genDocs.intervalId = window.setInterval(() => {
      this._importadorSaftDirectService
        .getJobStatus()
        .then((response) => {
          if (this._isAlive(response.state)) {
            if (response.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentosAgruparDocumentos || response.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentos) {
              this._setGenDocsProgressBar(response);
            }
          } else {
            clearTimeout(this.wizSteps.genDocs.intervalId);
          }
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
        });
    }, INTERVAL_TIMEOUT);
  }

  private _runProcessFileChecker(): Promise<void> {
    this.wizSteps.upload.showUploader = false;
    this.wizSteps.upload.showProcessFileProgressBar = true;
    return new Promise<void>((resolve, reject) => {
      this.wizSteps.upload.processFileIntervalId = window.setInterval(() => {
        if (!this._importProcessCanceled) {
          this._importadorSaftDirectService
            .getJobStatus()
            .then((response) => {
              if (this._isAlive(response.state)) {
                if (
                  (response.currentOp === EImportadorSaftDirectCurrentOperation.ProcessFile || response.currentOp === EImportadorSaftDirectCurrentOperation.Idle) &&
                  response.state === EImportadorSaftDirectState.Ended
                ) {
                  this._processFileCheck(response, resolve, reject);
                }

                if (response.state === EImportadorSaftDirectState.Error || response.state === EImportadorSaftDirectState.Inactive) {
                  this._uiReset(STEP_INDEX_UPLOADER);
                  this._showModalStateError(response.description);
                  resolve();
                }
              } else {
                clearTimeout(this.wizSteps.upload.processFileIntervalId);
              }
              this.pbProcessPos = response.position;
              this.pbProcessPercentage = Math.round((response.position / response.max) * 100);
              this.pbProcessLabel = response.description;
            })
            .catch((reason: HttpErrorResponse) => {
              reject(reason);
              this._handleError(reason);
              window.clearInterval(this.wizSteps.upload.processFileIntervalId);
              this.wizSteps.upload.processFileIntervalId = undefined;
              this.pbProcessPos = PROGRESS_BAR_MIN_VALUE;
              this.pbProcessLabel = 'global.text.initProcess';
            });
        }
      }, INTERVAL_TIMEOUT);
    });
  }

  private _processFileCheck(status: IJsonImportadorSaftDirectStatus, resolve: Function, reject: Function): void {
    if (status.state === EImportadorSaftDirectState.Error) {
      this._showCriticalError(status.description);
      this.wizSteps.upload.showUploader = true;
      this.wizSteps.upload.showProcessFileProgressBar = false;
      this._plAlertService.error(status.description);
      window.clearInterval(this.wizSteps.upload.processFileIntervalId);
      reject();
    } else if (status.position === status.max) {
      window.clearInterval(this.wizSteps.upload.processFileIntervalId);
      this.inProcess = true;
      this.inProcessFilename = status.filename;
      this.wizSteps.upload.valid = true;

      if (!this.wizSteps.upload._needLoadNext) {
        resolve();
        return;
      }

      this._importadorSaftDirectService
        .getDocumentosImportar()
        .then((response) => {
          if (this._isAlive(response.jobState)) {
            this.inProcess = true;
            this._loadImportDocsStep(response.docs.list, response.docs.summary);
            this._loadImportRecibosStep(response.payments.list, response.payments.summary);
            this._checkImportDocsErrors();
            this.plWizardCallback.setStep(EImportadorSaftDirectWizStepIds.DocsToImport, true);
            if (response.docs.list.length > 0 || response.payments.list.length > 0) {
              this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.HasDocsData;
            } else {
              this.typeHasDocsData = EImportadorSaftDirectTypeHasDocsData.NoDocsData;
            }
            resolve();
          } else {
            reject();
          }
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
          reject(reason);
        });
    }
  }

  private _wizNavControl(nav: boolean, disableFinalize: boolean = true): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: disableFinalize};
  }

  private _setGenDocsProgressBar(status: IJsonImportadorSaftDirectStatus): void {
    this._wizNavControl(false);
    if (status.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentos && status.state !== EImportadorSaftDirectState.Started) {
      window.clearInterval(this.wizSteps.genDocs.intervalId);
      if (status.state === EImportadorSaftDirectState.Error) {
        this._wizNavControl(true);
        this.wizSteps.genDocs.showPb = false;
        this._showSaveDocsErrorModal(status.description);
      } else if (status.position === status.max) {
        this._wizNavControl(false, false);
        this.wizSteps.genDocs.showPb = false;
        this._savingGenDocs = false;
        this._importadorSaftDirectService
          .getGeneratedDocsContab()
          .then((response) => {
            if (this._isAlive(response.jobState)) {
              this._loadGeneratedDocs(response.list);
            }
          })
          .catch((reason) => {
            this._handleError(reason);
          });
      }
      return;
    }
    this.wizSteps.genDocs.showPb = true;
    this.wizSteps.genDocs.pbMessage =
      status.currentOp === EImportadorSaftDirectCurrentOperation.SaveDocumentos && status.position > 0
        ? `${<string>this._translateService.instant('importadorSaftDirect.steps.genDocsContab.gravarDocsMessage')}: ${status.description}`
        : status.description;
    this.wizSteps.genDocs.pbValue = Math.round((status.position / status.max) * 100);
  }

  private _isAlive(state: EImportadorSaftDirectState): boolean {
    if (state === EImportadorSaftDirectState.Timeout) {
      this._cgModalService.showOkCancel('importadorSaftDirect.strings.jobTimeoutModalTitle', 'importadorSaftDirect.strings.jobTimeoutModalMessage', {
        size: 'md',
        showCancelBtn: false,
        backdrop: 'static',
        keyboard: false,
        showCloseBtn: false
      });
      this._uiReset(STEP_INDEX_WELCOME, true);
      return false;
    }
    return true;
  }

  private _showCriticalError(erro: string): void {
    this._cgModalService
      .showOkCancel('global.text.error', erro, {
        size: 'md',
        showCancelBtn: false,
        btnOkText: 'Ok',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        this._uiReset(STEP_INDEX_UPLOADER);
      });
  }

  private _handleError(reason: unknown): void {
    if (reason instanceof HttpErrorResponse) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      if (exception.status === EStatusCode.InternalServerError && exception.message === JOB_NOT_FOUND_ERROR) {
        this._cgModalService
          .showOkCancel('global.text.error', 'importadorSaftDirect.strings.timeoutModalMessage', {
            size: 'md',
            showCancelBtn: false,
            btnOkText: 'Ok',
            backdrop: 'static',
            keyboard: false
          })
          .then(() => {
            this._uiReset(STEP_INDEX_UPLOADER);
          });
      } else {
        this._plAlertService.error(exception.message);
      }
    }
  }

  private _configMascaraAnaliticaChanged(): void {
    let col = this.wizSteps.configTiposDocs.tableDefinition.fields.find((item) => {
      return item.name === 'nCCusto';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento);
    }

    col = this.wizSteps.configTiposDocs.tableDefinition.fields.find((item) => {
      return item.name === 'nCodRepCC';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumentoReparticao);
    }

    col = this.wizSteps.correspContasCorrentes.tableDefinition.fields.find((item) => {
      return item.name === 'nCCusto';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorCliente);
    }

    col = this.wizSteps.correspContasCorrentes.tableDefinition.fields.find((item) => {
      return item.name === 'nCodRepCC';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorClienteReparticao);
    }

    col = this.wizSteps.configArtigos.tableDefinition.fields.find((item) => {
      return item.name === 'nCCusto';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorArtigo);
    }

    col = this.wizSteps.configArtigos.tableDefinition.fields.find((item) => {
      return item.name === 'nCodRepCC';
    });

    if (isDefinedNotNull(col)) {
      col.visible = this.isVisibleByMaskDependency(EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao);
    }
  }

  private _checkImportDocsErrors(): void {
    if (this.wizSteps.importDocs.docsHaveErrorsOrWarnings || this.wizSteps.importDocs.paymentsHaveErrorsOrWarnings) {
      this._cgModalService.showOkCancel('global.text.warning', 'importadorSaftDirect.messages.importHaveErrorsOrWarningsModalMessage', {
        size: 'md',
        showOkBtn: false,
        btnCancelText: 'OK',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  private _showSaveDocsErrorModal(errorMessage: string): void {
    this._cgModalService
      .showOkCancel('global.text.error', errorMessage.replace(/\r\n|\r|\n/g, '<br/>'), {
        size: 'md',
        btnOkText: 'global.btn.ok',
        showCancelBtn: false,
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false,
        icon: 'error'
      })
      .then(() => {
        this.setWizStepState(this.wizSteps.datasDocumentos);
        this.plWizardCallback.setStep(EImportadorSaftDirectWizStepIds.DataDocs, true);
        this.definitionNavWizard.items[STEP_INDEX_DATAS_DOCUMENTOS].setIncomplete();
      });
  }

  private _cleanProcessFileFilters(): void {
    this.processFileFilters = {
      utilizadorCloud: '',
      passwordCloud: '',
      utilizadorPOS: '',
      passwordPOS: '',
      utilizadorPOSWinTouch: '',
      passwordPOSWinTouch: '',
      periodo: this.configurations.empresa.periodo,
      processaVendas: true,
      processaCompras: false,
      processaRecibos: false,
      processaPagamentos: false
    };
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): boolean {
    if (
      (currentStep.stepId === EImportadorSaftDirectWizStepIds.ProcessaFileCloud ||
        currentStep.stepId === EImportadorSaftDirectWizStepIds.ProcessaFilePOS ||
        currentStep.stepId === EImportadorSaftDirectWizStepIds.ProcessaFilePOSWinTouch) &&
      (type === 'next' || (nextStep && nextStep.stepId === EImportadorSaftDirectWizStepIds.DocsToImport))
    ) {
      if (this.processFileCloudForm.invalid) {
        return false;
      }
      if (!this.processFileFilters.processaVendas && !this.processFileFilters.processaRecibos && !this.processFileFilters.processaCompras && !this.processFileFilters.processaPagamentos) {
        this._plAlertService.error(this._translateService.instant('importadorSaftDirect.steps.common.semValoresImportar'));
        return false;
      }
    }
    return true;
  }

  private _showModalStateError(description: string): void {
    this._cgModalService
      .showOkCancel('global.text.error', description, {
        size: 'md',
        showCancelBtn: false,
        btnOkText: 'global.btn.ok',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        const stepVisible = this.definitionNavWizard.items.find((item) => {
          return (
            (item.stepId === EImportadorSaftDirectWizStepIds.Upload ||
              item.stepId === EImportadorSaftDirectWizStepIds.ProcessaFileCloud ||
              item.stepId === EImportadorSaftDirectWizStepIds.ProcessaFilePOS ||
              item.stepId === EImportadorSaftDirectWizStepIds.ProcessaFilePOSWinTouch) &&
            item.visible
          );
        });
        this.plWizardCallback.setStep(stepVisible.stepId, true);
      });
  }

  private _keydownEventProcessarFile(event: KeyboardEvent): void {
    if (event.key === KEYCODES.BACKSPACE) {
      if (this.tipoImportacaoEscolhido === EImportadorSaftDirectTipoImportacao.CentralGestCloud) {
        this.processFileFilters.passwordCloud = '';
      } else if (this.tipoImportacaoEscolhido === EImportadorSaftDirectTipoImportacao.CentralGestPos) {
        this.processFileFilters.passwordPOS = '';
      } else if (this.tipoImportacaoEscolhido === EImportadorSaftDirectTipoImportacao.CentralGestPosWinTouch) {
        this.processFileFilters.passwordPOSWinTouch = '';
      }
    }
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest() && this.inProcess) {
      return this._cgModalService
        .showOkCancel('importadorSaftDirect.leavePromptTitle', 'importadorSaftDirect.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        })
        .then(() => this._importadorSaftDirectService.stopMS())
        .then(() => undefined);
    }
    return Promise.resolve();
  }
}
