import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DemFinSNCService} from '../../demFinSNC.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDemFinSNCNotas, IJsonDemFinSNCNotasItem, TJsonDemFinSNCNotasItemValidationCallbackData} from '../../jsonDemFinSNC.module.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'modal-demfinsnc-notas',
  templateUrl: './demFinSNC.notas.modal.component.html'
})
export class DemFinSNCNotasModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public notas: IJsonDemFinSNCNotas;

  public readonly dataGridAtivoNaoCorrenteDefinition: IDevExpressDataGrid;
  public readonly dataGridAtivoCorrenteDefinition: IDevExpressDataGrid;
  public readonly dataGridCptlProprioDefinition: IDevExpressDataGrid;
  public readonly dataGridPassvNaoCorrenteDefinition: IDevExpressDataGrid;
  public readonly dataGridPassvCorrenteDefinition: IDevExpressDataGrid;
  public readonly dataGridPorNaturezasDefinition: IDevExpressDataGrid;
  public readonly dataGridPorFuncoesDefinition: IDevExpressDataGrid;
  public readonly dataGridCptlProprioPerioDefinition: IDevExpressDataGrid;
  public readonly dataGridAtvOperacDefinition: IDevExpressDataGrid;
  public readonly dataGridAtvInvestDefinition: IDevExpressDataGrid;
  public readonly dataGridAtvFinanDefinition: IDevExpressDataGrid;

  public collapsedAtivo: boolean;
  public collapsedPassivo: boolean;
  public canSaveData: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _demFinSNCService: DemFinSNCService,
    private readonly _cgModalService: CGModalService,
    private readonly _tranlateService: TranslateService
  ) {
    super(_injector);
    this.dataGridAtivoNaoCorrenteDefinition = this._generateDataGrid('demfinsnc.modal.tabs.balanco.tab.ativonaocorrente', 'ativoNaoCorrente');
    this.dataGridAtivoCorrenteDefinition = this._generateDataGrid('demfinsnc.modal.tabs.balanco.tab.ativocorrente', 'ativoCorrente');
    this.dataGridCptlProprioDefinition = this._generateDataGrid('demfinsnc.modal.tabs.balanco.tab.capitalproprio', 'cptlProprio');
    this.dataGridPassvNaoCorrenteDefinition = this._generateDataGrid('demfinsnc.modal.tabs.balanco.tab.passivonaocorrente', 'passivoNaoCorrente');
    this.dataGridPassvCorrenteDefinition = this._generateDataGrid('demfinsnc.modal.tabs.balanco.tab.passivocorrente', 'passivoCorrente');
    this.dataGridPorNaturezasDefinition = this._generateDataGrid('demfinsnc.modal.tabs.demresult.pornaturezas', 'porNaturezas');
    this.dataGridPorFuncoesDefinition = this._generateDataGrid('demfinsnc.modal.tabs.demresult.porfuncoes', 'porFuncoes');
    this.dataGridCptlProprioPerioDefinition = this._generateDataGrid('demfinsnc.modal.tabs.capproprioperio.demaltcptproprio', 'cptlProprioPerio');
    this.dataGridAtvOperacDefinition = this._generateDataGrid('demfinsnc.modal.tabs.fluxocaixa.atvop', 'atvOperac');
    this.dataGridAtvInvestDefinition = this._generateDataGrid('demfinsnc.modal.tabs.fluxocaixa.atvinvest', 'atvInvest');
    this.dataGridAtvFinanDefinition = this._generateDataGrid('demfinsnc.modal.tabs.fluxocaixa.atvfin', 'atvFinan');
    this.collapsedAtivo = false;
    this.collapsedPassivo = false;
    this.canSaveData = true;
  }

  public ngOnInit(): void {
    this._applyDataGridsDataSource();
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._demFinSNCService
      .postNotasDemFinSNC(this.notas)
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public onTabChanged(): void {
    this.canSaveData = true;
  }

  public readonly fnValPorDefeito = (): Promise<void> => this._valPorDefeito();

  private _validateGrid(options: IJsonDemFinSNCNotasItem): boolean {
    this.canSaveData = /^[0-9]+[,.]{0,1}[0-9]*$/.test(options.valueNota);
    return this.canSaveData;
  }

  private _generateDataGrid(exportTitle: string, tableName: string): IDevExpressDataGrid {
    let templateName: string;
    switch (tableName) {
      case 'ativoNaoCorrente':
        templateName = 'templateToolbarAtivoNaoCorrente';
        break;
      case 'ativoCorrente':
        templateName = 'templateToolbarAtivoCorrente';
        break;
      case 'cptlProprio':
        templateName = 'templateToolbarCptlProprio';
        break;
      case 'passivoNaoCorrente':
        templateName = 'templateToolbarPassivoNaoCorrente';
        break;
      case 'passivoCorrente':
        templateName = 'templateToolbarPassivoCorrente';
        break;
      case 'porNaturezas':
        templateName = 'templateToolbarPorNaturezas';
        break;
      case 'porFuncoes':
        templateName = 'templateToolbarPorFuncoes';
        break;
      case 'cptlProprioPerio':
        templateName = 'templateToolbarCptlProprioPerio';
        break;
      case 'atvOperac':
        templateName = 'templateToolbarAtvOperac';
        break;
      case 'atvInvest':
        templateName = 'templateToolbarAtvInvest';
        break;
      case 'atvFinan':
        templateName = 'templateToolbarAtvFinan';
        break;
    }
    return {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'descricaoNota', dataType: 'string', caption: 'demfinsnc.modal.tabs.table.rubrica', allowEditing: false},
        {
          dataField: 'valueNota',
          dataType: 'string',
          caption: 'demfinsnc.modal.tabs.table.notas',
          validationRules: [
            {
              type: 'custom',
              ignoreEmptyValue: true,
              message: this._tranlateService.instant('demfinsnc.messages.notastemdeserumnumero'),
              validationCallback: (options: TJsonDemFinSNCNotasItemValidationCallbackData): boolean => this._validateGrid(options.data)
            }
          ]
        }
      ],
      //dataSource: new ArrayStore(),
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true, startEditAction: 'click'},
      export: {filename: exportTitle},
      filterRow: {visible: false},
      paging: {enabled: false},
      keyboardNavigation: {editOnKeyPress: false, enterKeyAction: 'moveFocus', enterKeyDirection: 'column'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: templateName,
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  private _applyDataGridsDataSource(): void {
    this.dataGridAtivoNaoCorrenteDefinition.dataSource = this.notas.notasBalancoActivoNaoCorrenteList;
    this.dataGridAtivoCorrenteDefinition.dataSource = this.notas.notasBalancoActivoCorrenteList;
    this.dataGridCptlProprioDefinition.dataSource = this.notas.notasBalancoCapitalProprioList;
    this.dataGridPassvNaoCorrenteDefinition.dataSource = this.notas.notasBalancoPassivoNaoCorrenteList;
    this.dataGridPassvCorrenteDefinition.dataSource = this.notas.notasBalancoPassivoCorrenteList;
    this.dataGridPorNaturezasDefinition.dataSource = this.notas.notasDemoResultPorNaturezaList;
    this.dataGridPorFuncoesDefinition.dataSource = this.notas.notasDemoResultPorFuncoesList;
    this.dataGridCptlProprioPerioDefinition.dataSource = this.notas.notasAlterCapitalProprioPerioList;
    this.dataGridAtvOperacDefinition.dataSource = this.notas.notasFluxoCaixaActividadesOperacionaisList;
    this.dataGridAtvInvestDefinition.dataSource = this.notas.notasFluxoCaixaActividadesInvestimentoList;
    this.dataGridAtvFinanDefinition.dataSource = this.notas.notasFluxoCaixaActividadesFinaciamentoList;
  }

  private _valPorDefeito(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', 'demfinsnc.modal.message.valdef').then(() =>
      this._demFinSNCService.getValoresPorDefeitoNotasDemFinSNC().then((response: HttpResponse<IJsonDemFinSNCNotas>) => {
        this.notas = response.body;
        this._applyDataGridsDataSource();
      })
    );
  }
}
