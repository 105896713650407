import {IJsonDocfaNum} from '../docfasnums/jsonDocfaNum.entity.interface';
import {ESubTipoMovimentoSAFT} from '../docscomerciais/docsComerciais.entity.interface';

export enum EDocFasReportLoadType {
  None,
  RptDB,
  RptDBAndFixed
}

export interface IJsonDocfaReport {
  nome: string;
  descricao: string;
  defaultReport: boolean;
  visible: boolean;
  tipo: EReportKind;
}

export interface IDocfaReportSelect {
  nome: string;
  descricao: string;
  nomeDescricao: string;
}

export enum EReportKind {
  System,
  User,
  UserEmpresa
}

export interface IJsonDocfaReportList {
  list: Array<IJsonDocfaReport>;
  reportDefault: string;
  nviasDefault: number;
}

export interface IJsonDocfaDecimais {
  qtd1: number;
  qtd2: number;
  qtd1Fact: number;
  qtd2Fact: number;
  qtdPorUnid1: number;
  qtdPorUnid2: number;
  prVendaQtd1: number;
  prVendaQtd2: number;
  liquido: number;
  taxaIva: number;
  valorIva: number;
  prVendIvaInc: number;
  totalDocumento: number;
  desconto: number;
  comissao: number;
  d1: number;
  d2: number;
  d3: number;
  d4: number;
  d5: number;
  d6: number;
  percComissao: number;
}

export enum ETipoAutoFaturacao {
  None = 0,
  ParaFornecedoresComAcordo = 1,
  ParaClientes = 2,
  ParaFornecedoresSemAcordo = 3
}

export enum ESAFTTipoDoc {
  SAFTOutros,
  SAFTFactura,
  SAFTNotaDebito,
  SAFTNotaCredito,
  SAFTFaturaRecibo,
  SAFTTalaoVenda,
  SAFTTalaoDevolucao,
  SAFTAlienacaoActivos,
  SAFTDevolucaoActivos,
  SAFTSegurPremio,
  SAFTSegurEstorno,
  SAFTSegurImputacaoCoSegur,
  SAFTSegurImputacaoCoSegurLider,
  SAFTSegurResseguroAceite,
  SAFTFaturaGenerica,
  SAFTVendaDinheiro,
  SAFTFaturaGlobal,
  SAFTAvisoCobranca,
  SAFTAvisoCobrancaRecibo,
  SAFTFaturaReciboAutoFacturacao,
  SAFTCVFaturaEletronica,
  SAFTCVFaturaReciboEletronica,
  SAFTCVTalaoVendaEletronico,
  SAFTCVReciboEletronico,
  SAFTCVNotaCreditoEletronica,
  SAFTCVNotaDebitoEletronica,
  SAFTCVDocumentoTransporteEletronico,
  SAFTCVNotaDevolucao,
  SAFTCVNotaLancamento
}

export interface IJsonDocfa {
  nDocFa: number;
  nome: string;
  descricao: string;
  identificaCarga: boolean;
  faturaComIvaIncluido: boolean;
  integraStocks: boolean;
  integraNaContabilidade: boolean;
  integraEmContasCorrentes?: boolean;
  tipoMovimentoStock: number;
  reportDefeito: string;
  decimais: IJsonDocfaDecimais;
  grupoDocfa: number;
  fluxoMonetario: number;
  nDocfaDestino: number;
  docfanumList: Array<IJsonDocfaNum>;
  faturacaoPublica: boolean;
  obrigatorioMeiosPagam: boolean;
  nViasDefeito: number;
  codigoSAFTTipoDoc?: string;
  descricaoSAFTTipoDoc?: string;
  tipoDocSujeitoFaturaDigital?: boolean;
  codigoSAFTTipoDocDescStr?: string;
  saftSubTipoMovimento?: ESubTipoMovimentoSAFT;
  autoFaturacao?: ETipoAutoFaturacao;
  sujeitoAHASHStr?: string;
  reportList?: IJsonDocfaReportList;
  codCae?: string;
  nomeCae?: string;
  ndiarioContab?: number;
  nomeDiario?: string;
  ndescritiContab?: number;
  nomeDescriti?: string;
}

export interface IJsonDocfaNewFromBase {
  nDocfasBase: number;
  nDocfasNew: number;
  nomeNew: string;
  descricaoNew: string;
  saftSubTipoMovimento: ESubTipoMovimentoSAFT;
  autoFaturacao: ETipoAutoFaturacao;
  saftTipoDoc: ESAFTTipoDoc;
}
