import {deepFreeze} from 'pl-comps-angular';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';

export enum EPrevisAutoLegendColors {
  CorrecaoAutomatica = 'yellow-legend-color'
}

export enum EClientesFornecedoresPrevisAuto {
  Cliente,
  Fornecedor
}

export const RADIO_GROUP_PREVI_TES_AUTO: IRadioGroup<EClientesFornecedoresPrevisAuto> = deepFreeze<IRadioGroup<EClientesFornecedoresPrevisAuto>>({
  groupItems: [
    {value: EClientesFornecedoresPrevisAuto.Cliente, label: 'prevites.automatica.clientes'},
    {value: EClientesFornecedoresPrevisAuto.Fornecedor, label: 'prevites.automatica.fornecedores'}
  ]
});

export const TABLE_LEGEND_PREVIS_AUTO: TTableLegend = deepFreeze([{caption: 'prevites.legend.correcaoAutomatica', badgeCSSClass: EPrevisAutoLegendColors.CorrecaoAutomatica}]);

export const MODULE_NAME_PREVI_TES = 'prevites';
export const MODULE_NAME_PREVI_TES_AUTO = 'previtesauto';
