import {HttpResponse} from '@angular/common/http';
import {DiferimentosService} from '../diferimentos.module.service';
import {ETipoOperacaoDiferimentos, IJsonInfoGlobalDiferimentosAcrescimos} from '../jsonCalculoDiferimentos.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {InfoGlobalDiferimentosAcrescimosModuleComponent} from './components/infoGlobalDiferimentosAcrescimos.module.component';
import {MODULE_NAME_INFO_GLOBAL_ACRESCIMOS, MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS, MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS} from '../diferimentos.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS: IModuleDefinition = generateModule(MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS);
export const MODULE_INFO_GLOBAL_DIFERIMENTOS: IModuleDefinition = generateModule(MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS);
export const MODULE_INFO_GLOBAL_ACRESCIMOS: IModuleDefinition = generateModule(MODULE_NAME_INFO_GLOBAL_ACRESCIMOS);

function generateModule(moduleName: string): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleName}`,
      component: InfoGlobalDiferimentosAcrescimosModuleComponent,
      data: {
        roles: [ROLE.CONTABILIDADE, ROLE.ERP],
        pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
        sidebarTitle: `portals.sidebar.modules.${moduleName}`
      },
      params: {
        moduleName: {
          type: 'string',
          value: moduleName
        }
      },
      resolve: [
        {
          provide: 'infoGlobal',
          deps: [DiferimentosService],
          useFactory: (diferimentosService: DiferimentosService): Promise<IJsonInfoGlobalDiferimentosAcrescimos> => {
            const tipoOperacao: ETipoOperacaoDiferimentos = moduleName === MODULE_NAME_INFO_GLOBAL_ACRESCIMOS ? ETipoOperacaoDiferimentos.Acrescimo : ETipoOperacaoDiferimentos.Diferimento;
            return diferimentosService.getInfoGlobalDiferimentosAcrescimos(tipoOperacao).then((response: HttpResponse<IJsonInfoGlobalDiferimentosAcrescimos>) => response.body);
          }
        }
      ]
    }
  };
}
