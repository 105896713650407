import {TDate} from '../../../common/dates';

export enum ERHEstadoEncargos {
  None,
  Novo,
  Aberto
}

export enum ERHFEEstadoRegEmp {
  Novo,
  OK,
  Alterado,
  Apagado
}

export enum ETipoAcrescimoEncFerias {
  None,
  Anual,
  Mensal
}

export enum EEstadoAcrescimoEncFerias {
  None,
  Processado,
  Integrado,
  ComInconsistencias
}

export interface IJsonAcrescimoEncargoComFeriasLin {
  rhEncFeriasLinID: string;
  anoEncargo: number;
  codEmpregado: number;
  dataAdmissao: TDate;
  nMesesTrabalhados: number;
  ccusto: string;
  nCodRepCC: number;
  vBaseEncargosFerias: number;
  valorAcertoManual: number;
  valorSubFerias: number;
  valorFerias: number;
  valorEncargosSS: number;
  valorEncargoAcTrab: number;
  valorOutrosEncargos: number;
  descrBaseEncFerias: string;
  percentagemSegSocial: number;
  percentagemAcTrab: number;
  estadoRegisto: ERHFEEstadoRegEmp;
  dhempFuncao: string;
  dhempClasse: string;
  dhempFamiliaCategoria: number;
  dhempCodContab: string;
  dhempNZona: number;
  dhempDepartamento: number;
  dhempSubDepartamento: string;
  nomeEmpregado: string;
  estadoID: ERHFEEstadoRegEmp;
  total: number;
}

export interface IJsonAcrescimoEncargoComFeriasAnuais {
  valorAcertoManual: number;
  valorSubFerias: number;
  valorFerias: number;
  valorEncargosSS: number;
  valorEncargoAcTrab: number;
  valorOutrosEncargos: number;
  total: number;
}

export interface IJsonAcrescimoEncargoComFeriasConfig {
  percActTrabalho: number;
  pocDebitoSubFerias: string;
  pocCreditoSubFerias: string;
  pocDebitoFerias: string;
  pocCreditoFerias: string;
  pocDebitoEncargosSegSocial: string;
  pocCreditoEncargosSegSocial: string;
  pocDebitoEncargosActTrabalho: string;
  pocCreditoEncargosActTrabalho: string;
  pocDebitoOutrosEncargos: string;
  pocCreditoOutrosEncargos: string;
  diario: number;
  descritivo: number;
  pocDebitoSubFeriasDesc: string;
  pocCreditoSubFeriasDesc: string;
  pocDebitoFeriasDesc: string;
  pocCreditoFeriasDesc: string;
  pocDebitoEncargosSegSocialDesc: string;
  pocCreditoEncargosSegSocialDesc: string;
  pocDebitoEncargosActTrabalhoDesc: string;
  pocCreditoEncargosActTrabalhoDesc: string;
  pocDebitoOutrosEncargosDesc: string;
  pocCreditoOutrosEncargosDesc: string;
  diarioDesc: string;
  descritivoDesc: string;
}

export interface IJsonAcrescimoEncargoComFeriasCab {
  anoEncargo: number;
  tipo: ETipoAcrescimoEncFerias;
  tipoDesc: string;
  descricao: string;
  percSeguradora: number;
  estado: EEstadoAcrescimoEncFerias;
  estadoDesc: string;
  dataIntegracao: TDate;
}

export interface IJsonAcrescimoEncargoComFeriasCabConfig {
  cab: IJsonAcrescimoEncargoComFeriasCab;
  config: IJsonAcrescimoEncargoComFeriasConfig;
}

export interface IJsonAcrescimoEncargoComFerias {
  cab: IJsonAcrescimoEncargoComFeriasCab;
  linhas: Array<IJsonAcrescimoEncargoComFeriasLin>;
}

export interface IJsonAcrescimoEncargoComFeriasLinMulti {
  rhEncFeriasAnoID: string;
  sel: boolean;
  nEmpresa: string;
  nomeEmpresa: string;
  estadoID: number;
  tipoID: number;
  temErro: boolean;
  erro: string;
  encargoButton: string;
  anoEncargo: number;
  tipo: ETipoAcrescimoEncFerias;
  estado: EEstadoAcrescimoEncFerias;
  dataIntegracao: TDate;
  vBaseEncargosFerias: number;
  valorAcertoManual: number;
  valorSubFerias: number;
  valorFerias: number;
  valorEncargosSS: number;
  valorEncargoAcTrab: number;
  vAlorOutrosEncargos: number;
  totalEncargos: number;
  infoErro: number;
  descricaoErro: string;
  multiLinhaErro: string;
}

export interface IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas {
  sel: boolean;
  nEmpresa: string;
  nomeEmpresa: string;
  anoEncargo: number;
  tipo: EEstadoAcrescimoEncFerias;
  tipoDesc: string;
  percSeguradora: number;
}

export interface IJsonAcrescimosEncFeriasDocContabIntegrado {
  caption: string;
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  documento: string;
  extPocCabID: string;
}
