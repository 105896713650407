import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonClifoExtraValue} from './jsonClifoExtraValue.entity.interface';
import {ENTITY_NAME_CLIFOEXTRAVALUES} from './clifoExtraValue.entity.interface';

export const ENTITY_CLIFOEXTRAVALUES: IEntityDefinition<IJsonClifoExtraValue> = {
  name: ENTITY_NAME_CLIFOEXTRAVALUES,
  asModule: false,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  metadata: {
    keyName: 'nConta',
    fields: [
      {name: 'nConta', width: '200px', caption: 'clifoExtraValues.fields.nConta', validators: {required: true, maxlength: 4}},
      {name: 'codigo', caption: 'clifoExtraValues.fields.tipoEmail', validators: {required: true}},
      {name: 'descricao', caption: 'clifoExtraValues.fields.tipoEmailDescricao'},
      {name: 'valor', caption: 'clifoExtraValues.fields.valor', validators: {required: true}}
    ],
    order: 'nConta',
    searchFields: 'nConta,codigo,descricao,valor'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
