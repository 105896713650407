<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'dgempsfull.deletePrompt.title'" [translateParams]="{id: codEmp}"></h4>
</div>

<div class="modal-body">
  <h6 class="mb-2" [translate]="'dgempsfull.deletePrompt.message'"></h6>

  <pl-edit type="radio" attrName="apenasultimohistorico" [(model)]="apenasUltimoHistorico" [properties]="{value: true, label: 'dgempsfull.deletePrompt.lastOnly'}"></pl-edit>

  <pl-edit type="radio" attrName="apenasultimohistorico" [(model)]="apenasUltimoHistorico" [properties]="{value: false, label: 'dgempsfull.deletePrompt.all'}"></pl-edit>

  <div class="text-danger" [translate]="'dgempsfull.deletePrompt.invalid'" *ngIf="invalid"></div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button type="button" klass="btn-danger action-delete" (evtClicked)="close()"><i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span></pl-button>

  <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
