import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHRecolhaSerieSubsidios, IJsonRHRecolhaSerieSubsidiosEmp, IJsonRHRecolhaSerieSubsidiosGravar, IJsonRHRecolhaSerieSubsidiosProcess} from './jsonRHRecolhaSerieSubsidios.module.interface';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHRecolhaSerieSubsidiosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/recolhaseriesubsidios`;
  }

  public getListaEmpregados(codEmpDe: number, codEmpAte: number, situacaoEmprego: number): TServiceResponse<Array<IJsonRHRecolhaSerieSubsidiosEmp>> {
    return this._apiService.get<Array<IJsonRHRecolhaSerieSubsidiosEmp>>({
      url: `${this._path}/listaempregados`,
      params: {
        codempde: codEmpDe,
        codempate: codEmpAte,
        sitemp: situacaoEmprego
      }
    });
  }

  public getDadosDiaPagamentoDefault(tiposubsidio: number): TServiceResponse<IJsonRHRecolhaSerieSubsidios> {
    return this._apiService.get<IJsonRHRecolhaSerieSubsidios>({
      url: `${this._path}/dadosdiapagamento`,
      params: {
        tiposubsidio: tiposubsidio
      }
    });
  }

  public postProcessarSubsidio(emplist: string, rhrecolhaseriesubsidios: IJsonRHRecolhaSerieSubsidios): TServiceResponse<Array<IJsonRHRecolhaSerieSubsidiosProcess>> {
    return this._apiService.post<Array<IJsonRHRecolhaSerieSubsidiosProcess>, IJsonRHRecolhaSerieSubsidios>({
      url: `${this._path}/processarsubsidio`,
      body: rhrecolhaseriesubsidios,
      params: {
        emplist: emplist
      }
    });
  }

  public postGravarPagamentoSubsidio(rhrecolhaseriesubsidiosgravar: IJsonRHRecolhaSerieSubsidiosGravar): TServiceResponse<IJsonBoolean> {
    return this._apiService.post<IJsonBoolean, IJsonRHRecolhaSerieSubsidiosGravar>({
      url: `${this._path}/gravarpagamentosubsidio`,
      body: rhrecolhaseriesubsidiosgravar
    });
  }

  public postCalculaValorAPagar(rhrecolhaseriesubsidiosprocess: IJsonRHRecolhaSerieSubsidiosProcess, nDiasAPagar: number): TServiceResponse<IJsonRHRecolhaSerieSubsidiosProcess> {
    return this._apiService.post<IJsonRHRecolhaSerieSubsidiosProcess>({
      url: `${this._path}/calculavalorapagar`,
      body: rhrecolhaseriesubsidiosprocess,
      params: {
        ndiasapagar: nDiasAPagar
      }
    });
  }

  public postDatareferenciachange(rhrecolhaseriesubsidios: IJsonRHRecolhaSerieSubsidios): TServiceResponse<IJsonRHRecolhaSerieSubsidios> {
    return this._apiService.post<IJsonRHRecolhaSerieSubsidios>({
      url: `${this._path}/datareferenciachange`,
      body: rhrecolhaseriesubsidios
    });
  }
}
