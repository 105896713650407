import {Moment} from 'moment';
import {TDate} from '../../../../common/dates';

export enum ECompanyStatusInstance {
  FimAno = 'ECGOnFimAno'
}

export interface ICompanyStatusStateParams {
  statusInstance: ECompanyStatusInstance;
}

export interface IApiJobStatus<T extends string | number> {
  companyId: string;
  description: string;
  finished: boolean;
  plannedStartDate: TDate;
  position: number;
  requestDate: TDate;
  state: T;
  startedByUserId: number;
  startedByUser: string;
}

export interface ICompanyStatusMessage {
  timestamp: Moment;
  timestampFormatted: string;
  content: string;
}

export const STATE_NAME_COMPANY_STATUS = 'companystatus';

export const COMPANY_STATUS_INSTANCES: ReadonlySet<string> = new Set<string>(Object.keys(ECompanyStatusInstance).map((instance: string) => ECompanyStatusInstance[instance]));
