import {AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FOCUSABLE_QUERY_PERMISSIVE_SELECTOR, isNumber} from '../common/utilities/utilities';
import {prev} from '../common/utilities/dom.utilities';
import {WINDOW_TIMEOUT} from '../common/constants';

const MAX_RETRIES = 20; // 2 seconds

@Directive({
  selector: '[plAutoFocus]',
  standalone: false
})
export class PlAutofocusDirective implements AfterViewInit, OnDestroy {
  @Input() public plAutoFocusParent: string;
  @Input() public plAutoFocusDisabled: boolean;

  private readonly _document: Document;
  private _element: HTMLElement;
  private _selector: string;
  private _interval: number;
  private _retries: number;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _elementRef: ElementRef<HTMLElement>
  ) {
    this._document = <Document>document;
    this._element = this._elementRef.nativeElement;
    this._retries = 0;
  }

  public ngAfterViewInit(): void {
    if (this.plAutoFocusDisabled) {
      return;
    }
    if (this.plAutoFocusParent) {
      this._selector = this.plAutoFocusParent;
      if (!this._element.matches(this._selector)) {
        this._element = this._element.closest(this._selector);
      }
      if (!this._element.matches(':first-child')) {
        this._element = prev(this._element, `${this._selector}:first-child`);
      }
    }

    let target: HTMLElement = this._element.matches(FOCUSABLE_QUERY_PERMISSIVE_SELECTOR) ? this._element : this._element.querySelector<HTMLElement>(FOCUSABLE_QUERY_PERMISSIVE_SELECTOR);
    if (target && this._document.contains(target)) {
      target.focus(<any>{focusVisible: true});
    } else {
      this._interval = window.setInterval(() => {
        target = this._element.querySelector<HTMLElement>(FOCUSABLE_QUERY_PERMISSIVE_SELECTOR);
        if (target || this._retries > MAX_RETRIES) {
          this._clearInterval();
          if (target) {
            target.focus(<any>{focusVisible: true});
          }
        }
        this._retries++;
      }, WINDOW_TIMEOUT);
    }
  }

  public ngOnDestroy(): void {
    if (isNumber(this._interval)) {
      this._clearInterval();
    }
  }

  private _clearInterval(): void {
    window.clearInterval(this._interval);
    this._interval = undefined;
  }
}
