import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDocComercialLinha, IDocComercialLinhaSerie} from '../../docsComerciais.entity.interface';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';

@Component({
  selector: 'alterar-campos-serie-modal',
  templateUrl: './alterarCamposSerie.modal.component.html'
})
export class AlterarCamposSerieModalComponent extends CGModalComponent<IDocComercialLinha> implements OnInit {
  @Input() public linhaDoc: IDocComercialLinha;

  public linhaValoresSerie: IDocComercialLinhaSerie;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.linhaValoresSerie = {
      nArmazemSelected: false,
      nCCustoSelected: false,
      nrefprocessoSelected: false,
      nVendedorSelected: false,
      codIvaSelected: false,
      d1Selected: false,
      nArmazem: 0,
      nCCusto: '',
      nrefprocesso: '',
      nVendedor: 0,
      codIva: 0,
      d1: 0,
      taxaIva: 0
    };
  }

  public ngOnInit(): void {
    this.linhaValoresSerie.nArmazem = this.linhaDoc.nArmazem;
    this.linhaValoresSerie.nCCusto = this.linhaDoc.nCCusto;
    this.linhaValoresSerie.nrefprocesso = this.linhaDoc.nrefprocesso;
    this.linhaValoresSerie.nVendedor = this.linhaDoc.nVendedor;
    this.linhaValoresSerie.codIva = this.linhaDoc.codIva;
    this.linhaValoresSerie.d1 = this.linhaDoc.d1;
  }

  public onCodIvaChanged(iva: IJsonIva): void {
    this.linhaValoresSerie.taxaIva = iva.taxaActual;
  }

  public close(): void | Promise<void> {
    return super.close(this.linhaValoresSerie);
  }
}
