import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../services/auth/auth.service';
import {EPortal} from '../../../common/enums/portals.enums';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ISaftStatus, MODULE_NAME_SAFT} from './saft.module.interface';
import {ROLE} from '../../services/role.const';
import {SaftComponent} from './components/saft.module.component';
import {SaftService} from './saft.module.service';

export const MODULE_SAFT: IModuleDefinition = {
  name: MODULE_NAME_SAFT,
  state: {
    url: '/saft',
    component: SaftComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      icon: 'fa-upload',
      sidebarTitle: 'portals.sidebar.modules.saft'
    },
    params: {
      exportSaftType: {
        type: 'int',
        value: 0,
        squash: true,
        dynamic: true
      },
      ano: {
        type: 'int',
        value: -1,
        squash: true,
        dynamic: true
      },
      mes: {
        type: 'int',
        value: -1,
        squash: true,
        dynamic: true
      }
    },
    resolve: [
      {
        provide: 'currentStatus',
        deps: [SaftService],
        useFactory: (saftService: SaftService): Promise<ISaftStatus> => {
          return saftService.getStatus().then((response: HttpResponse<ISaftStatus>) => response.body);
        }
      },
      {
        provide: 'exportContabilidade',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<boolean> => {
          return authService.getAndGeneratePortalRole(EPortal.CONTABILIDADE).then((role: ROLE) => authService.hasAuthority(role));
        }
      },
      {
        provide: 'exportFaturacao',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<boolean> => {
          return authService.getAndGeneratePortalRole(EPortal.ERP).then((role: ROLE) => authService.hasAuthority(role));
        }
      }
    ]
  }
};
