import {ProviderLike} from '@uirouter/core';
import {ConfigSiteService} from '../configsite.service';
import {IJsonRecaptchaConfig} from '../../interfaces/jsonConfigValue.interface';

export function recaptchaConfigFactoryProvider(configSiteService: ConfigSiteService): Promise<IJsonRecaptchaConfig> {
  return configSiteService.recaptchaConfig();
}

export const RESOLVER_RECAPTCHA_CONFIG: ProviderLike = {
  provide: 'recaptchaConfig',
  useFactory: recaptchaConfigFactoryProvider,
  deps: [ConfigSiteService]
};
