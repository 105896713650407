import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IRHManutencaoCamposField,
  IRHManutencaoCamposProceItem,
  IRHManutencaoCamposSaveData,
  IRHManutencaoCamposSummaryItem,
  MODULE_NAME_RH_MANUTENCAO_CAMPOS
} from './rhManutencaoCampos.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {TDate} from '../../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class RHManutencaoCamposService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_RH_MANUTENCAO_CAMPOS}`;
  }

  public getCampos(): TServiceResponse<Array<IRHManutencaoCamposField>> {
    return this._apiService.get<Array<IRHManutencaoCamposField>>({
      url: `${this._path}/campos`
    });
  }

  public getProcessamentos(dataDe: TDate, dataAte: TDate): TServiceResponse<Array<IRHManutencaoCamposProceItem>> {
    return this._apiService.get<Array<IRHManutencaoCamposProceItem>>({
      url: `${this._path}/processamentos`,
      params: {
        dataDe: dataDe,
        dataAte: dataAte
      }
    });
  }

  public saveCampos(data: IRHManutencaoCamposSaveData): TServiceResponse<Array<IRHManutencaoCamposSummaryItem>> {
    return this._apiService.post<Array<IRHManutencaoCamposSummaryItem>, IRHManutencaoCamposSaveData>({
      url: `${this._path}/campos`,
      body: data
    });
  }
}
