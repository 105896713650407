<pl-form class="encomendas-documento-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.documento.title'"></h4>
  </div>

  <div class="modal-body">
    <documento-faturacao [doc]="doc" [actionPdf]="fnGetPdf"></documento-faturacao>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
