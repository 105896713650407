import {AlteracoesEntidadesModuleComponent} from './components/alteracoesEntidades.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_ALTERACOES_ENTIDADES} from './alteracoesEntidades.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_ALTERACOES_ENTIDADES: IModuleDefinition = {
  name: MODULE_NAME_ALTERACOES_ENTIDADES,
  state: {
    url: '/alteracoesentidades',
    component: AlteracoesEntidadesModuleComponent,
    data: {
      roles: [ROLE.GESTORRH],
      pageTitle: 'global.menu.colaboradoresAlteracoesEntidades',
      icon: 'fa-tasks'
    }
  }
};
