import {Component, Injector, Input, OnInit} from '@angular/core';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IInfoGlobalParams, MODULE_NAME_INFO_GLOBAL_ACRESCIMOS, MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS} from '../../diferimentos.module.interface';
import {HttpResponse} from '@angular/common/http';
import {DiferimentosService} from '../../diferimentos.module.service';
import {
  ETipoOperacaoDiferimentos,
  IJsonInfoDiferimentos,
  IJsonInfoDiferimentosAcrescimos,
  IJsonInfoGlobalDiferimentosAcrescimos,
  IJsonInfoGlobalDiferimentosAcrescimosDetail
} from '../../jsonCalculoDiferimentos.interface';
import {IPlDynamicVisualsSecondaryClickAction, isEmpty, isObject} from 'pl-comps-angular';
import {CalculoDiferimentosInfoModalComponent} from '../../modals/info/calculoDiferimentos.info.modal.component';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnContextMenuPreparing
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import CustomStore from 'devextreme/data/custom_store';

const DIFERIMENTOS_TAB_ID = 'infoDiferimentosTab';
const ACRESCIMOS_TAB_ID = 'infoAcrescimosTab';

@Component({
  selector: 'module-info-global-diferimentos-acrescimos',
  templateUrl: './infoGlobalDiferimentosAcrescimos.module.component.html'
})
export class InfoGlobalDiferimentosAcrescimosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public infoGlobal: IJsonInfoGlobalDiferimentosAcrescimos;

  public readonly dataGridDefinitionDetail: IDevExpressDataGrid;
  public readonly dataGridDefinitionDetailDoc: IDevExpressDataGrid;

  public dataGridDefinitionTotais: IDevExpressDataGrid<IJsonInfoGlobalDiferimentosAcrescimos>;
  public infoGlobalAcrescimos: IJsonInfoGlobalDiferimentosAcrescimos;
  public mainModule: boolean;
  public activeTab: string;

  private readonly _moduleName: string;
  private _firstTimeTabAcrescimos: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _calculoDiferimentosService: DiferimentosService,
    private readonly _cgModalService: CGModalService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    const params: IInfoGlobalParams = <IInfoGlobalParams>this._transition.params();
    this._moduleName = params.moduleName;
    this.mainModule = params.moduleName === MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS;
    this.infoGlobalAcrescimos = {infoList: [], temAcrescimo: false, temDiferimento: false};
    this.activeTab = DIFERIMENTOS_TAB_ID;
    this.dataGridDefinitionDetail = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDocumento', dataType: 'string', caption: 'diferimentosglobal.fields.nDocumento'},
        {dataField: 'valor', dataType: 'double', caption: 'diferimentosglobal.fields.valor', format: {suppressZeros: true}},
        {dataField: 'descricao', dataType: 'string', caption: 'diferimentosglobal.fields.descricao'}
      ],
      export: {filename: 'diferimentosglobal.docsselmes'},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {columnRenderingMode: 'virtual'},
      summary: {
        totalItems: [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateDetalhes',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionDetailDoc = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDocumento', dataType: 'string', caption: 'diferimentosglobal.fields.nDocumento'},
        {dataField: 'valor', dataType: 'double', caption: 'diferimentosglobal.fields.valor', format: {suppressZeros: true}},
        {dataField: 'descricao', dataType: 'string', caption: 'diferimentosglobal.fields.descricao'}
      ],
      export: {filename: 'diferimentosglobal.docscontribuem'},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {columnRenderingMode: 'virtual'},
      summary: {
        totalItems: [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      }
    };
    this._firstTimeTabAcrescimos = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._setDataGridDefinition();

    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(true);
    }
  }

  public async changedTab(tabId: string): Promise<void> {
    this.activeTab = tabId;
    this._setDataGridDefinition();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonInfoDiferimentosAcrescimos>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public onCellDblClick(event: IDevExpressDataGridEventOnCellDblClick<IJsonInfoGlobalDiferimentosAcrescimosDetail>): Promise<void> {
    if (event.rowType === 'data') {
      return this._openModalInfo(event.data.extPocCabID);
    }
    return Promise.resolve();
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonInfoGlobalDiferimentosAcrescimosDetail>): void {
    if (event.target === 'content' && event.row.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsGroup(event.row.data.extPocCabID);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  private _setDataGridDefinition(): void {
    let exportFileName = '';
    let temAcrescimos: boolean = this.infoGlobal.temAcrescimo;
    if (this.mainModule && this.activeTab === ACRESCIMOS_TAB_ID) {
      temAcrescimos = this.infoGlobalAcrescimos.temAcrescimo;
      exportFileName = 'diferimentosglobal.acrescimos';
    }
    const column: IDevExpressDataGridColumn = temAcrescimos
      ? {dataField: 'saldoAcrescimoNoMes', dataType: 'double', caption: 'global.text.saldo', format: {suppressZeros: true}}
      : {dataField: 'saldoDiferimentoNoMes', dataType: 'double', caption: 'global.text.saldo', format: {suppressZeros: true}};

    exportFileName = !isEmpty(exportFileName) ? exportFileName : this._moduleName === MODULE_NAME_INFO_GLOBAL_ACRESCIMOS ? 'diferimentosglobal.acrescimos' : 'diferimentosglobal.diferimentos';
    this.dataGridDefinitionTotais = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ano', dataType: 'string', caption: 'global.text.year'},
        {dataField: 'mesStr', dataType: 'string', caption: 'global.text.month'},
        {dataField: 'valor', dataType: 'double', caption: 'diferimentosglobal.fields.valor', format: {suppressZeros: true}},
        column
      ],
      export: {filename: exportFileName},
      dataSource: new CustomStore({
        load: () => this._getDataSource()
      }),
      masterDetail: {enabled: true, template: 'masterDetailTemplateTotais'},
      height: '80vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {columnRenderingMode: 'virtual'},
      summary: {
        totalItems: [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      }
    };
  }

  private _openModalInfo(extPocCabID: string): Promise<void> {
    return this._calculoDiferimentosService.getInfoDiferimentos(extPocCabID).then((response: HttpResponse<IJsonInfoDiferimentos>) => {
      const modalInstance = this._cgModalService.showVanilla(CalculoDiferimentosInfoModalComponent, {size: 'xxl'});
      const componentInstance: CalculoDiferimentosInfoModalComponent = modalInstance.componentInstance;
      componentInstance.infoDiferimentos = response.body;
    });
  }

  private _generateSecondaryClickActionsGroup(extPocCabID: string): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'diferimentosglobal.infoAction',
        icon: 'fa-info-circle',
        click: () => this._openModalInfo(extPocCabID)
      }
    ];
  }

  private async _getDataSource(): Promise<Array<IJsonInfoDiferimentosAcrescimos>> {
    if (this.mainModule && this.activeTab === ACRESCIMOS_TAB_ID) {
      if (!this.infoGlobalAcrescimos?.infoList?.length && this._firstTimeTabAcrescimos) {
        const response: HttpResponse<IJsonInfoGlobalDiferimentosAcrescimos> = await this._calculoDiferimentosService.getInfoGlobalDiferimentosAcrescimos(ETipoOperacaoDiferimentos.Acrescimo);
        this.infoGlobalAcrescimos = response.body;
        this._firstTimeTabAcrescimos = false;
      }
      return this.infoGlobalAcrescimos.infoList;
    }
    return this.infoGlobal.infoList;
  }
}
