import {merge} from 'lodash-es';
import {AuthService} from '../../../services/auth/auth.service';
import {ColaboradoresGestaoDGEMPSModuleComponent} from './components/colaboradores.gestaodgemps.module.component';
import {EGestaoDGEMPSType} from '../../../components/gestaodgemps/gestaodgemps.interface';
import {GestaoDGEMPSService} from '../../../components/gestaodgemps/gestaodgemps.service';
import {gestaoDGEMPSTypeToPRHFluxo} from '../../../components/gestaodgemps/gestaodgemps.utilities';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR
} from './colaboradores.gestaodgemps.module.interface';
import {RESOLVER_GESTAO_DGEMPS_HOLIDAYS_DATES} from '../../../components/gestaodgemps/gestaodgemps.router.interface';
import {ROLE} from '../../../services/role.const';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

export const MODULE_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR: IModuleDefinition = generateModule(EGestaoDGEMPSType.Abonos, true, {
  name: MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR,
  nameAlias: ['colaboradoresMarcacaoAbonos'],
  state: {
    url: 'abonos',
    component: ColaboradoresGestaoDGEMPSModuleComponent,
    data: {
      pageTitle: 'gestaodgemps.abonos.pageTitle',
      sidebarTitle: 'gestaodgemps.abonos.sidebarTitle'
    }
  }
});

export const MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS: IModuleDefinition = generateModule(EGestaoDGEMPSType.Faltas, false, {
  name: MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS,
  nameAlias: ['colaboradoresColaboradorMarcacaoFaltas'],
  state: {
    url: 'faltas',
    component: ColaboradoresGestaoDGEMPSModuleComponent,
    data: {
      pageTitle: 'gestaodgemps.faltas.pageTitle.colaborador',
      sidebarTitle: 'gestaodgemps.faltas.sidebarTitle.colaborador'
    }
  }
});

export const MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR: IModuleDefinition = generateModule(EGestaoDGEMPSType.Faltas, true, {
  name: MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR,
  nameAlias: ['colaboradoresMarcacaoFaltas'],
  state: {
    url: 'faltas',
    component: ColaboradoresGestaoDGEMPSModuleComponent,
    data: {
      pageTitle: 'gestaodgemps.faltas.pageTitle.gestor',
      sidebarTitle: 'gestaodgemps.faltas.sidebarTitle.gestor'
    }
  }
});

export const MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS: IModuleDefinition = generateModule(EGestaoDGEMPSType.Ferias, false, {
  name: MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS,
  nameAlias: ['colaboradoresMarcacaoFerias'],
  state: {
    url: 'ferias',
    component: ColaboradoresGestaoDGEMPSModuleComponent,
    data: {
      pageTitle: 'gestaodgemps.ferias.pageTitle.colaborador',
      sidebarTitle: 'gestaodgemps.ferias.sidebarTitle.colaborador'
    }
  }
});

export const MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR: IModuleDefinition = generateModule(EGestaoDGEMPSType.Ferias, true, {
  name: MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR,
  nameAlias: ['colaboradoresMarcacaoGestorFerias'],
  state: {
    url: 'ferias',
    component: ColaboradoresGestaoDGEMPSModuleComponent,
    data: {
      pageTitle: 'gestaodgemps.ferias.pageTitle.gestor',
      sidebarTitle: 'gestaodgemps.ferias.sidebarTitle.gestor'
    }
  }
});

function generateModule(type: EGestaoDGEMPSType, managerMode: boolean, base: Partial<IModuleDefinition>): IModuleDefinition {
  const roles: Array<ROLE> = [ROLE.GESTORRH, ROLE.GESTORSERVICO];
  if (!managerMode) {
    roles.push(ROLE.COLABORADOR);
  }
  return <IModuleDefinition>merge<object, Partial<IModuleDefinition>, Partial<IModuleDefinition>>({}, base, {
    name: base.name,
    state: {
      url: `/${managerMode ? 'gestao' : 'marcacao'}/${base.state.url}`,
      data: {
        roles: roles,
        icon: 'fa-calendar'
      },
      resolve: [
        {provide: 'moduleType', useFactory: () => type},
        {provide: 'managerMode', useFactory: () => managerMode},
        {
          provide: 'configStructure',
          deps: [GestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: GestaoDGEMPSService) => (type !== EGestaoDGEMPSType.Ferias ? gestaoDGEMPSService.getConfigStructure(type) : undefined)
        },
        {
          provide: 'configEvents',
          deps: [GestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: GestaoDGEMPSService) => (type !== EGestaoDGEMPSType.Ferias ? gestaoDGEMPSService.getConfigEvents(type) : undefined)
        },
        {
          provide: 'fluxos',
          deps: [GestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: GestaoDGEMPSService) => gestaoDGEMPSService.getFluxos(gestaoDGEMPSTypeToPRHFluxo(type))
        },
        {
          provide: 'empregado',
          deps: [AuthService, GestaoDGEMPSService],
          useFactory: (authService: AuthService, gestaoDGEMPSService: GestaoDGEMPSService): Promise<IJsonDGEMP> => {
            return authService.identity().then((session: TUserSession) => gestaoDGEMPSService.getEmpregado(session.erp.codEmp));
          }
        },
        RESOLVER_GESTAO_DGEMPS_HOLIDAYS_DATES
      ],
      params: {
        servico: {
          type: 'int',
          value: -1
        },
        colaboradoresPendentes: {
          type: 'any',
          value: []
        }
      }
    }
  });
}
