import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonClifo} from '../../jsonClifo.entity.interface';

@Component({
  selector: 'modal-txtfaturacao',
  templateUrl: './txtFaturacao.modal.component.html'
})
export class TxtFaturacaoModalComponent extends CGModalComponent<void> {
  @Input() public clifo: IJsonClifo;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
