import {BehaviorSubject, from, mergeMap, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {setSleekProductId} from '@centralgest/sleekplan-sdk';
import {isEmpty, isNumber, isString} from 'pl-comps-angular';
import {ApiService} from './api/api.service';
import {APP_LAUNCH_MODE} from '../../common/api';
import {CG_ON_PATH_BACK_OFFICE} from '../interfaces/store.interface';
import {EAppLaunchMode} from '../../common/site';
import {IJsonBoolean, IJsonString} from '../../common/interfaces/json';
import {IJsonConfigValue, IJsonRecaptchaConfig} from '../interfaces/jsonConfigValue.interface';
import {IJsonUserFeedbackConfig} from '../interfaces/jsonUserfeedback.interface';
import {isProduction, isTest} from '../../config/constants';
import {TServiceResponse} from './api/api.service.interface';
import {Writeable} from '../../common/interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ConfigSiteService {
  private readonly _path: string;
  private readonly _subjectUserFeedbackConfig: BehaviorSubject<IJsonUserFeedbackConfig>;
  private _observableUserFeedbackConfig: Observable<IJsonUserFeedbackConfig>;
  private _recaptcha: IJsonRecaptchaConfig;
  private _passwordSecureMode: boolean;
  private _cgStoreUrl: string;
  private _termsRGPDVersion: string;
  private _amaliaToken: string;
  private _partnerMillenniumTargetOrigin: string;
  private _promiseRecaptcha: Promise<IJsonRecaptchaConfig>;
  private _promisePasswordSecureMode: Promise<boolean>;
  private _promiseCGStoreUrl: Promise<string>;
  private _promiseTermsRGPDVersion: Promise<string>;
  private _promiseUserFeedbackConfig: TServiceResponse<IJsonUserFeedbackConfig>;
  private _promiseAmaliaToken: Promise<string>;
  private _promisePartnerMillenniumTargetOrigin: Promise<string>;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/configs`;
    this._subjectUserFeedbackConfig = new BehaviorSubject<IJsonUserFeedbackConfig>(undefined);
    this._passwordSecureMode = false;
    this._termsRGPDVersion = '';
  }

  public testeEmail(): TServiceResponse<string> {
    return this._apiService.put<string>({url: `${this._path}/testemail`, body: undefined});
  }

  public recaptchaConfig(force: boolean = false): Promise<IJsonRecaptchaConfig> {
    if (!force && this._recaptcha) {
      return Promise.resolve(this._recaptcha);
    }
    if (!this._promiseRecaptcha) {
      this._promiseRecaptcha = isProduction()
        ? this.getRecaptchaConfig().then((response: HttpResponse<IJsonRecaptchaConfig>) => {
            this._recaptcha = Object.freeze(response.body);
            return this._recaptcha;
          })
        : Promise.resolve(
            Object.freeze({
              active: isTest(),
              enterprise: false,
              publicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
              publicKeyV3: '6LcHtFAbAAAAAOqj8XN9zvFawCK0aUiN0bTsTRTd'
            })
          );
    }
    return this._promiseRecaptcha;
  }

  public getRecaptchaConfig(): TServiceResponse<IJsonRecaptchaConfig> {
    return this._apiService.get<IJsonRecaptchaConfig>({url: `${this._path}/recaptcha`});
  }

  public passwordSecureMode(force: boolean = false): Promise<boolean> {
    if (!force && this._passwordSecureMode) {
      return Promise.resolve(this._passwordSecureMode);
    }
    if (!this._promisePasswordSecureMode) {
      this._promisePasswordSecureMode = this.getPasswordSecureMode().then((response: HttpResponse<IJsonBoolean>) => {
        this._passwordSecureMode = response.body.value;
        return this._passwordSecureMode;
      });
    }
    return this._promisePasswordSecureMode;
  }

  public getPasswordSecureMode(): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({url: `${this._path}/passwordsecuremode`});
  }

  public cgStoreUrl(force: boolean = false): Promise<string> {
    if (APP_LAUNCH_MODE.value === EAppLaunchMode.Hybrid || APP_LAUNCH_MODE.value === EAppLaunchMode.HybridPartial) {
      return Promise.resolve('');
    }
    if (!force && isString(this._cgStoreUrl)) {
      return Promise.resolve(this._cgStoreUrl);
    }
    if (!this._promiseCGStoreUrl) {
      this._cgStoreUrl = undefined;
      this._promiseCGStoreUrl = this._getCGStoreUrl()
        .then((response: HttpResponse<IJsonConfigValue>) => {
          this._cgStoreUrl = response.body.value;
          return this._cgStoreUrl;
        })
        .finally(() => {
          this._promiseCGStoreUrl = undefined;
        });
    }
    return this._promiseCGStoreUrl;
  }

  public cgStoreUrlBackOffice(force: boolean = false): Promise<string> {
    return this.cgStoreUrl(force).then((value: string) => (!isEmpty(value) ? value + CG_ON_PATH_BACK_OFFICE : ''));
  }

  public termsRGPDVersion(force: boolean = false): Promise<string> {
    if (!force && this._termsRGPDVersion) {
      return Promise.resolve(this._termsRGPDVersion);
    }
    if (!this._promiseTermsRGPDVersion) {
      this._promiseTermsRGPDVersion = this.getTermsRGPDVersion().then((response: HttpResponse<string>) => {
        this._termsRGPDVersion = response.body;
        return this._termsRGPDVersion;
      });
    }
    return this._promiseTermsRGPDVersion;
  }

  public getTermsRGPDVersion(): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/termsrgpdversion`});
  }

  public userFeedbackConfig(force: boolean = false): Observable<IJsonUserFeedbackConfig> {
    if (!this._observableUserFeedbackConfig) {
      this._observableUserFeedbackConfig = this._subjectUserFeedbackConfig
        .asObservable()
        .pipe(
          mergeMap((config: IJsonUserFeedbackConfig) => {
            if (!force && config) {
              return of(config);
            }
            return from(this.getUserFeedbackConfig()).pipe(map((response: HttpResponse<IJsonUserFeedbackConfig>) => response.body));
          })
        )
        .pipe(
          tap((config: IJsonUserFeedbackConfig) => {
            if (isNumber(config.productId)) {
              setSleekProductId(config.productId);
            }
          })
        );
    }
    return this._observableUserFeedbackConfig;
  }

  public getUserFeedbackConfig(): TServiceResponse<IJsonUserFeedbackConfig> {
    if (!this._promiseUserFeedbackConfig) {
      this._promiseUserFeedbackConfig = this._apiService
        .get<IJsonUserFeedbackConfig>({url: `${this._path}/userfeedback`})
        .then((response: HttpResponse<IJsonUserFeedbackConfig>) => {
          const config: Writeable<IJsonUserFeedbackConfig> = response.body;
          if (config.websiteUrl?.endsWith('/')) {
            config.websiteUrl = config.websiteUrl.slice(0, -1);
          }
          this._subjectUserFeedbackConfig.next(Object.freeze(config));
          return response;
        })
        .finally(() => {
          this._promiseUserFeedbackConfig = undefined;
        });
    }
    return this._promiseUserFeedbackConfig;
  }

  public amaliaToken(force: boolean = false): Promise<string> {
    if (!force && this._amaliaToken) {
      return Promise.resolve(this._amaliaToken);
    }
    if (!this._promiseAmaliaToken) {
      this._promiseAmaliaToken = this.getAmaliaToken().then((response: HttpResponse<IJsonString>) => {
        this._amaliaToken = response.body.value;
        return this._amaliaToken;
      });
    }
    return this._promiseAmaliaToken;
  }

  public getAmaliaToken(): TServiceResponse<IJsonString> {
    return this._apiService.get<IJsonString>({url: `${this._path}/amaliatoken`});
  }

  public partnerMillenniumTargetOrigin(force: boolean = false): Promise<string> {
    if (!force && this._partnerMillenniumTargetOrigin) {
      return Promise.resolve(this._partnerMillenniumTargetOrigin);
    }
    if (!this._promisePartnerMillenniumTargetOrigin) {
      this._promisePartnerMillenniumTargetOrigin = this.getPartnerMillenniumTargetOrigin().then((response: HttpResponse<string>) => {
        this._partnerMillenniumTargetOrigin = response.body;
        return this._partnerMillenniumTargetOrigin;
      });
    }
    return this._promisePartnerMillenniumTargetOrigin;
  }

  public getPartnerMillenniumTargetOrigin(): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/partnermillenniumtargetorigin`, responseType: 'text'});
  }

  public saveConfigs(configs: Array<IJsonConfigValue>): TServiceResponse<Array<IJsonConfigValue>> {
    return this._apiService.post<Array<IJsonConfigValue>>({
      url: `${this._path}/site/saveconfigs`,
      body: configs
    });
  }

  private _getCGStoreUrl(): TServiceResponse<IJsonConfigValue> {
    return this._apiService.get<IJsonConfigValue>({url: `${this._path}/cgstoreurl`});
  }
}
