import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {merge} from 'lodash-es';
import moment from 'moment';
import {Injectable, OnDestroy} from '@angular/core';
import {deepFreeze, isObject, parseEditNumber, stripCharacters as cgcStripCharacters} from '../utilities/utilities';
import {DeepPartial} from '../interfaces';
import {IPlCompsServiceConfig, TValueType} from '../interface';
import {IPlFormatConfig} from '../format/format.service.interface';
import {IPlLocale} from '../locale/locales.interface';
import {PlFormatService} from '../format/format.service';
import {PlI18nPlNumberService} from '../../i18n/i18n.plNumber.service';
import {PlLocaleService} from '../locale/locale.service';
import {PlTranslateService} from '../../translate/translate.service';
import {TPlEditType} from '../../edit/component/edit.component.interface';

@Injectable({
  providedIn: 'root'
})
export class PlCompsService implements OnDestroy {
  private readonly _subjectConfigurations: BehaviorSubject<IPlCompsServiceConfig>;
  private readonly _subscriptionFormat: Subscription;
  private readonly _subscriptionLocale: Subscription;
  private _format: IPlFormatConfig;
  private _locale: IPlLocale;
  private _observableConfigurations: Observable<IPlCompsServiceConfig>;

  constructor(
    private readonly _plFormatService: PlFormatService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService
  ) {
    this._subjectConfigurations = new BehaviorSubject<IPlCompsServiceConfig>({
      debug: false,
      plEditForm: {
        orientation: 'horizontal',
        modelValueEditTypes: []
      },
      plToolbar: {
        defaultInstanceId: 'CGC_TOOLBAR',
        defaultHyperlinkTarget: '_blank',
        defaultDownloadHyperlinkTarget: '_blank'
      }
    } satisfies IPlCompsServiceConfig);
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this._locale = locale;
    });
  }

  public ngOnDestroy(): void {
    this._subjectConfigurations.complete();
    this._subscriptionFormat.unsubscribe();
    this._subscriptionLocale.unsubscribe();
  }

  public setConfig(configurations: DeepPartial<IPlCompsServiceConfig>): void {
    const updatedConfigurations: IPlCompsServiceConfig = merge({}, this._subjectConfigurations.value, configurations);
    this._subjectConfigurations.next(deepFreeze(updatedConfigurations));
  }

  public stripCharacters(value: string | number, format: {decimalsSeparator: string; decimalsLimit: number} = this._format): string {
    return cgcStripCharacters(value, format);
  }

  public parseNumber(value: string | number, format: {decimalsSeparator: string; decimalsLimit: number} = this._format): number {
    return parseEditNumber(value, format);
  }

  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  public prettyPrintValue(value: any, type: TValueType | TPlEditType = 'text', format: IPlFormatConfig = this._format, properties?: any): string {
    if (isObject(value) && !moment.isMoment(value)) {
      return value.description ? value.description : value.value;
    }
    if (type === 'date' && value) {
      return moment(value).format(format.momentDate);
    }
    if (type === 'datetime' && value) {
      return moment(value).format(format.momentDatetime);
    }
    if (type === 'time' && value) {
      return moment(value).format(format.time);
    }
    if (type === 'boolean') {
      return value ? this._plTranslateService.translate(this._locale.text.true) : this._plTranslateService.translate(this._locale.text.false);
    }
    if (type === 'number' || type === 'integer') {
      if (type === 'integer') {
        format = {...format, decimalsLimit: 0};
      }
      return this._plI18nPlNumberService.formatPlNumber(value, format);
    }
    if (type === 'radiogroup' && properties?.groupItems) {
      return this._plTranslateService.translate(
        properties.groupItems.find((item) => {
          return item[properties.radio?.valueProp || 'value'] === value;
        })[properties.radio?.labelProp || 'label']
      );
    }
    if (type === 'select' && properties?.select?.list) {
      return this._plTranslateService.translate(
        properties.select.list.find((item) => {
          return item[properties.select?.valueProp || 'value'] === value;
        })[properties.select?.labelProp || 'name']
      );
    }
    return value;
  }

  public config(): Observable<IPlCompsServiceConfig> {
    if (!this._observableConfigurations) {
      this._observableConfigurations = this._subjectConfigurations.asObservable();
    }
    return this._observableConfigurations;
  }
}
