<div class="simulaVencimento">
  <pl-form>
    <pl-group>
      <pl-group>
        <label [translate]="'simulacaoVencimento.fields.dgemp'"></label>
        <edit>
          <entity-autocomplete attrName="dgemp" entity="dgemps" [(model)]="dgemp" [properties]="{readonly: editing}" [filter]="'ativo=true'" plAutoFocus [plAutoFocusDisabled]="editing">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'simulacaoVencimento.fields.dataSimulacao'"></label>
        <edit>
          <pl-edit-datepicker
            attrName="dataSimulacao"
            [(model)]="dataSimulacao"
            plAutoFocus
            [plAutoFocusDisabled]="!editing"
            [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'simulacaoVencimento.fields.nDiasUteis'"></label>
        <edit>
          <pl-edit attrName="nDiasUteis" type="cginteger" [(model)]="nDiasUteis"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'simulacaoVencimento.fields.valorLiquido'"></label>
        <edit>
          <pl-edit attrName="valorLiquido" type="number" [(model)]="valorLiquido" [properties]="{validators: {min: {value: 1}}}"></pl-edit>
        </edit>
        <actions>
          <pl-button
            klass="btn-primary btn-sm"
            [onClick]="atualizaValorSimulado"
            [tooltip]="{placement: ['bottom', 'bottom-left'], text: 'simulacaoVencimento.text.simValLiqTooltip'}"
            [disabled]="!dgemp?.codEmp || !nDiasUteis || !valorLiquido || !dataSimulacao"
            data-focus>
            <span [translate]="'simulacaoVencimento.text.simularValor'"></span>
          </pl-button>
        </actions>
      </pl-group>
    </pl-group>
  </pl-form>

  <div class="recibo-simulado entity-detail-form mt-4" *ngIf="simulacao">
    <h5 class="recibo-simulado-title text-center" [translate]="'simulacaoVencimento.text.reciboSimulado'"></h5>

    <pl-group>
      <pl-group>
        <label [translate]="'simulacaoVencimento.text.vencimento'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.vencimento | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'simulacaoVencimento.text.totalIliquido'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.totalIliquido | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'simulacaoVencimento.text.totalDesconto'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.totalDesconto | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'simulacaoVencimento.text.totalLiquido'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.totalLiquido | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'simulacaoVencimento.text.totalEspecie'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.totalEspecie | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'simulacaoVencimento.text.totalAPagar'"></label>
        <edit>
          <div class="form-control-align">{{ simulacao.reciboVencimentoSimulado.totalAPagar | cgcDecimal: 2 }}</div>
        </edit>
      </pl-group>
    </pl-group>

    <dx-data-grid class="mt-3" [cgDxDataGrid]="definition" [dataSource]="simulacao.listAbdesc" cgDxDataGridInstanceName="simulaValorVencimentoTable" (onInitialized)="onInitialized($event)">
    </dx-data-grid>
  </div>
</div>
