import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IAtivosImpressaoHeader} from '../ativosImpressao.module.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {AtivosImpressaoService} from '../ativosImpressao.module.service';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {ETipoExport} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';

@Component({
  selector: 'modal-ativos-impressao',
  templateUrl: './ativosImpressao.modal.component.html'
})
export class AtivosImpressaoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public modelHeader: IAtivosImpressaoHeader;

  public readonly pdfToolbarId: string;
  public reportModel: IJsonReport;
  public pdfUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _ativosImpressaoService: AtivosImpressaoService
  ) {
    super(_injector);
    this.processar = this.processar.bind(this);
    this.pdfToolbarId = 'ativos-impressao-modal-toolbar';
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public processar(): void {
    this.modelHeader.reportName = this.reportModel.name;
    this.modelHeader.tipoExportacao = ETipoExport.PDF;
    this._ativosImpressaoService.getListagemAtivosUrl(this.modelHeader).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.Ativos)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
        }
      });
  }
}
