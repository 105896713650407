import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {downloadStream} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {DocsComerciaisEstatisticasService} from '../../docsComerciaisEstatisticas.module.service';
import {EModuleTipo, ETipoExport, IDocsComerciasEstatisticasFilters, ITipoExport} from '../../docsComerciaisEstatisticas.module.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';

@Component({
  selector: 'docs-comercias-estatisticas-print-modal',
  templateUrl: './docsComerciaisEstatisticas.print.modal.html'
})
export class DocsComerciaisEstatisticasPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public filters: IDocsComerciasEstatisticasFilters;
  @Input() public moduleType: EModuleTipo;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;
  public tipoExport: ETipoExport;
  public tipoExportItems: Array<ITipoExport>;
  public readonly tiposExport: typeof ETipoExport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _docsComerciasEstatisticasService: DocsComerciaisEstatisticasService
  ) {
    super(_injector);
    this.tipoExport = ETipoExport.PDF;
    this.tiposExport = ETipoExport;
    this.tipoExportItems = [
      {value: ETipoExport.PDF, label: 'global.btn.pdf'},
      {value: ETipoExport.EXCEL, label: 'global.btn.excel'}
    ];
  }

  public ngOnInit(): void {
    this.reportType = EReport.DocsComerciaisEstatisticas;
    this._loadDefaultReport();
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
  }

  public async loadPdf(): Promise<void> {
    if (this.tipoExport === ETipoExport.PDF) {
      this.pdfUrl = await this._getPdfUrl();
    } else {
      const params: IDocsComerciasEstatisticasFilters = {...this.filters, reportname: this.reportModel.name, tipoexportacao: this.tipoExport};
      const response: HttpResponse<Blob> = await this._docsComerciasEstatisticasService.getExcel(params, this.moduleType);
      const doc: Blob = response.body;
      if (doc?.size) {
        downloadStream(doc);
      }
    }
  }

  private _getPdfUrl(): Promise<string> {
    const params: IDocsComerciasEstatisticasFilters = {...this.filters, reportname: this.reportModel.name, tipoexportacao: this.tipoExport};
    return this._docsComerciasEstatisticasService.getExcelUrl(params, this.moduleType);
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.gestaoComercial.docsComerciasEstatisticas.reports.reportImpressao,
      title: this._configService.configurations.gestaoComercial.docsComerciasEstatisticas.reports.reportImpressao
    };

    return this._reportsRegistryService
      .get(EReport.DocsComerciaisEstatisticas)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
