<div class="pl-edit-group" [class.has-error]="linha._invalidNContaDebito">
  <entity-autocomplete
    entity="pocs"
    [attrName]="fieldName"
    inputClass="input-sm"
    output="nConta"
    placeholder="global.text.empty"
    [model]="model"
    (modelChange)="modelChanged($event)"
    [filter]="filter"
    [customActions]="customActionsPoc"
    [properties]="properties"
    plAutoFocus
    [plAutoFocusDisabled]="disableAutoFocus || ((linha._index === 0 || linha._index !== docContabilidade.linhas[docContabilidade.linhas.length - 1]?._index) && !preDefinidoContabLinha)">
  </entity-autocomplete>
</div>
