export enum ETipoRetServNIF {
  AdquirenteServicos,
  PrestadorServicos
}

export enum ENIFSituacaoTipo {
  ValidoSemInformacaoActividade,
  NIFInvalidoInexistente,
  ValidoSemActividade,
  ValidoComActividade,
  ValidoComActividadeCessada
}

export interface IJsonNIFs {
  nContribuinte: string;
  codPais: number;
  abrevFiscal: string;
  codRet: string;
  nomeRet: string;
  tipoRetServNIF: ETipoRetServNIF;
  temCativo: boolean;
  taxaCativo: number;
  m30NifPT: string;
  m30NifEmit: string;
  m30PartCapD: number;
  m30PartCapS: number;
  tipoNif: ENIFSituacaoTipo;
}
