import {firstValueFrom, of} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {ENTITY_NAME_PRH_COMUNICACOES_INTERNAS, IPRHComunicacoesInternasEntity} from './pRHComunicacoesInternas.entity.interface';
import {IJsonPRHComIntCab} from './jsonPRHComunicacaoInterna.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_COMUNICACOES_INTERNAS: IPRHComunicacoesInternasEntity = {
  name: ENTITY_NAME_PRH_COMUNICACOES_INTERNAS,
  icon: 'fa-exchange',
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhcomunicacoesinternas.pesquisa',
  metadata: {
    keyName: 'comIntCabID',
    descriptionName: 'titulo',
    fields: [
      {
        name: 'comIntCabID',
        width: '120px',
        caption: 'prhcomunicacoesinternas.fields.comIntCabID',
        placeholder: 'prhcomunicacoesinternas.fields.comIntCabIDPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'titulo',
        caption: 'prhcomunicacoesinternas.fields.titulo',
        placeholder: 'prhcomunicacoesinternas.fields.tituloPlaceholder',
        validators: {required: true, maxlength: 100}
      }
    ],
    order: 'titulo',
    searchFields: 'comIntCabID,titulo'
  },
  autocomplete: {
    rowTemplate: '{{comIntCabID}} - {{.titulo}}',
    output: 'titulo',
    searchFields: 'comIntCabID,titulo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhcomunicacoesinternas'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhcomunicacoesinternas'
      }
    }
  },
  service: function () {
    this.uploadDoc = (comIntCabId: number, file: Blob, filename?: string) => {
      return firstValueFrom(this.uploadDocUrl(comIntCabId, filename)).then((url: string) => {
        const formData: FormData = new FormData();
        formData.append('file', file, filename);
        return this.apiService.upload<IJsonPRHComIntCab>({
          url: url,
          body: formData,
          params: {filename: filename}
        });
      });
    };

    this.uploadDocUrl = (comIntCabId: number, filename?: string) => {
      return buildSessionUrlWithParams(`${this.entityUrl()}/${comIntCabId}/uploaddoc`, {filename: filename});
    };

    this.downloadDoc = (comIntCabId: number) => {
      return firstValueFrom(this.downloadDocUrl(comIntCabId)).then((url: string) => this.apiService.get<Blob>({url: url, responseType: 'blob'}));
    };

    this.downloadDocUrl = (comIntCabId: number) => {
      return of(`${this.entityUrl()}/${comIntCabId}/downloaddoc`);
    };
  }
};
