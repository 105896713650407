<div class="ativosconfiguracoes entity-detail-form">
  <pl-form>
    <pl-tabs>
      <pl-tab caption="ativos.configuracoes.tab.diversos" id="diversos">
        <div *plTabContent>
          <pl-group>
            <label [translate]="'ativos.configuracoes.radicaisContasImobilizado'"></label>
            <edit>
              <pl-edit type="text" attrName="radicaisContasImobilizado" [(model)]="model.radicaisContasImobilizado"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativos.configuracoes.radicalIVA'"></label>
            <edit>
              <pl-edit type="text" attrName="radicalIVA" [(model)]="model.radicalIVA"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativos.configuracoes.taxaIRCEstimada'"></label>
            <edit>
              <pl-edit type="number" attrName="taxaIRCEstimada" [(model)]="model.taxaIRCEstimada"></pl-edit>
            </edit>
          </pl-group>

          <pl-tabs>
            <pl-tab caption="ativos.configuracoes.tab.aquisicao" id="aquisicao">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.aquisicao'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosAquisicao"
                        [model]="model"
                        (selectedKeyChange)="model.aquisicaoNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAquisicaoDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'aquisicaoNDiario', nome: 'nomeAquisicaoDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="descritivosAquisicao"
                        [model]="model"
                        (selectedKeyChange)="model.aquisicaoNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAquisicaoDescritivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'aquisicaoNDescritivo', nome: 'nomeAquisicaoDescritivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.radicaisFornecedorAquisicao'"></label>
                  <edit>
                    <pl-edit type="text" attrName="radicaisFornecedorAquisicao" [(model)]="model.radicaisFornecedorAquisicao"></pl-edit>
                  </edit>
                </pl-group>

                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.impostosDiferidos'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.impostosDiferidosContaPassivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaPassivo"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaPassivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaPassivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaPassivo', nome: 'nomeImpostosDiferidosContaPassivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.impostosDiferidosContaAjustamentos'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaAjustamentos"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaAjustamentos = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaAjustamentos = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaAjustamentos', nome: 'nomeImpostosDiferidosContaAjustamentos'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.impostosDiferidosContaImpostoDiferido'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="impostosDiferidosContaImpostoDiferido"
                      [model]="model"
                      (selectedKeyChange)="model.impostosDiferidosContaImpostoDiferido = $event"
                      (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaImpDiferidos = $event"
                      [properties]="{validators: {required: {value: true}}}"
                      [fieldsMap]="{nConta: 'impostosDiferidosContaImpostoDiferido', nome: 'nomeImpostosDiferidosContaImpDiferidos'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <br />
                <pl-group>
                  <edit>
                    <pl-edit
                      type="boolean"
                      attrName="mostraContabilidadeConversao"
                      [(model)]="model.mostraContabilidadeConversao"
                      [properties]="{label: 'ativos.configuracoes.mostraContabilidadeConversao'}">
                    </pl-edit>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.depreciacoes" id="depreciacoes">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.depreciacoes'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosDepreciacoes"
                        [model]="model"
                        (selectedKeyChange)="model.depreciacoesNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeDepreciacoesDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'depreciacoesNDiario', nome: 'nomeDepreciacoesDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="descritivosDepreciacoes"
                        [model]="model"
                        (selectedKeyChange)="model.depreciacoesNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeDepreciacoesDescritivos = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'depreciacoesNDescritivo', nome: 'nomeDepreciacoesDescritivos'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.depreciacaoDataEncerramento'"></label>
                  <edit>
                    <pl-edit type="date" attrName="depreciacaoDataEncerramento" [(model)]="model.depreciacaoDataEncerramento"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <edit>
                      <pl-edit
                        type="checkbox"
                        attrName="depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao"
                        [(model)]="model.depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao"
                        [properties]="{label: 'ativos.configuracoes.depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao'}">
                      </pl-edit>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <edit>
                      <pl-edit
                        type="checkbox"
                        attrName="depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes"
                        [(model)]="model.depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes"
                        [properties]="{label: 'ativos.configuracoes.depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes'}">
                      </pl-edit>
                    </edit>
                  </pl-group>
                </pl-group>

                <hr />

                <div class="row mt-2">
                  <div class="col-md-12">
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="radio"
                          attrName="depAgrupa"
                          [model]="depAgrupa"
                          (modelChange)="changedDepAgrupa($event)"
                          [properties]="{value: depsAgrupa.AtivoPeriodo, label: 'ativos.configuracoes.radiooptions.ativoperiodo', disabled: model.jaExistemDepreciacoesEfetuadasParaOAno}">
                        </pl-edit>
                        <pl-edit
                          type="radio"
                          attrName="depAgrupa"
                          [model]="depAgrupa"
                          (modelChange)="changedDepAgrupa($event)"
                          [properties]="{
                            value: depsAgrupa.AtivoPeriodoUltimo,
                            label: 'ativos.configuracoes.radiooptions.ativoperiodoult',
                            disabled: model.jaExistemDepreciacoesEfetuadasParaOAno
                          }">
                        </pl-edit>
                        <pl-edit
                          type="radio"
                          attrName="depAgrupa"
                          [model]="depAgrupa"
                          (modelChange)="changedDepAgrupa($event)"
                          [properties]="{value: depsAgrupa.AtivosPeriodo, label: 'ativos.configuracoes.radiooptions.ativosperiodo', disabled: model.jaExistemDepreciacoesEfetuadasParaOAno}">
                        </pl-edit>
                        <pl-edit
                          type="radio"
                          attrName="depAgrupa"
                          [model]="depAgrupa"
                          (modelChange)="changedDepAgrupa($event)"
                          [properties]="{
                            value: depsAgrupa.AtivosPeriodoUltimo,
                            label: 'ativos.configuracoes.radiooptions.ativosperiodoult',
                            disabled: model.jaExistemDepreciacoesEfetuadasParaOAno
                          }">
                        </pl-edit>
                      </edit>
                    </pl-group>
                  </div>
                </div>
                <pl-alert *ngIf="model.jaExistemDepreciacoesEfetuadasParaOAno" type="info" [closeable]="false">
                  <div [innerHTML]="'ativos.configuracoes.infodep' | translate"></div>
                </pl-alert>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.ajustes" id="ajustes">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.ajustes'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.contaContrapartida'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="contaContrapartidaAjustes"
                        [model]="model"
                        (selectedKeyChange)="model.ajustesNConta = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAjustesContaContrapartida = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'ajustesNConta', nome: 'nomeAjustesContaContrapartida'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosAjustes"
                        [model]="model"
                        (selectedKeyChange)="model.ajustesNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAjustesDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'ajustesNDiario', nome: 'nomeAjustesDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="descritivos"
                      attrName="descritivosAjustes"
                      [model]="model"
                      (selectedKeyChange)="model.ajustesNDescritivo = $event"
                      (evtSelectedDescriptionChanged)="model.nomeAjustesDescritivo = $event"
                      [properties]="{validators: {required: {value: true}}}"
                      [fieldsMap]="{nDescrit: 'ajustesNDescritivo', nome: 'nomeAjustesDescritivo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.erros" id="erros">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.errosTitle'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.contaContrapartida'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="contaContrapartidaErros"
                        [model]="model"
                        (selectedKeyChange)="model.errosNConta = $event"
                        (evtSelectedDescriptionChanged)="model.nomeErrosContaContrapartida = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'errosNConta', nome: 'nomeErrosContaContrapartida'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosErros"
                        [model]="model"
                        (selectedKeyChange)="model.errosNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeErrosDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'errosNDiario', nome: 'nomeErrosDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="descritivos"
                      attrName="descritivosErros"
                      [model]="model"
                      (selectedKeyChange)="model.errosNDescritivo = $event"
                      (evtSelectedDescriptionChanged)="model.nomeErrosDescritivo = $event"
                      [properties]="{validators: {required: {value: true}}}"
                      [fieldsMap]="{nDescrit: 'errosNDescritivo', nome: 'nomeErrosDescritivo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.venda" id="venda">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.vendaTitle1'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.vendaRadicalMaisValiaFatura'"></label>
                    <edit>
                      <pl-edit type="text" attrName="vendaNContaMaisValia" [(model)]="model.vendaRadicalMaisValiaFatura"></pl-edit>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.vendaRadicalMenosValiaFatura'"></label>
                    <edit>
                      <pl-edit type="text" attrName="vendaNContaMenosValia" [(model)]="model.vendaRadicalMenosValiaFatura"></pl-edit>
                    </edit>
                  </pl-group>
                </pl-group>

                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.vendaTitle2'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosVenda"
                        [model]="model"
                        (selectedKeyChange)="model.vendaNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeVendaDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'vendaNDiario', nome: 'nomeVendaDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="descritivosVenda"
                        [model]="model"
                        (selectedKeyChange)="model.vendaNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeVendaDescritivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'vendaNDescritivo', nome: 'nomeVendaDescritivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.contaMaisValia'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="contaMaisValia"
                        [model]="model"
                        (selectedKeyChange)="model.vendaNContaMaisValia = $event"
                        (evtSelectedDescriptionChanged)="model.nomeVendaContaMaisValia = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'vendaNContaMaisValia', nome: 'nomeVendaContaMaisValia'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.contaMenosValia'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="contaMenosValia"
                        [model]="model"
                        (selectedKeyChange)="model.vendaNContaMenosValia = $event"
                        (evtSelectedDescriptionChanged)="model.nomeVendaContaMenosValia = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nConta: 'vendaNContaMenosValia', nome: 'nomeVendaContaMenosValia'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.abate" id="abate">
              <div *plTabContent>
                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.abate'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="diariosAbate"
                        [model]="model"
                        (selectedKeyChange)="model.abateNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAbateDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'abateNDiario', nome: 'nomeAbateDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="descritivosAbate"
                        [model]="model"
                        (selectedKeyChange)="model.abateNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeAbateDescritivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'abateNDescritivo', nome: 'nomeAbateDescritivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.abateRadical'"></label>
                  <edit>
                    <pl-edit type="cginteger" attrName="abateRadical" [(model)]="model.abateRadical"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.reavaliacoesnaolegais" id="reavaliacoesnaolegais">
              <div *plTabContent>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="reavaliacaoNDiario"
                        [model]="model"
                        (selectedKeyChange)="model.reavaliacaoNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeReavaliacaoNDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'reavaliacaoNDiario', nome: 'nomeReavaliacaoNDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="reavaliacaoNDescritivo"
                        [model]="model"
                        (selectedKeyChange)="model.reavaliacaoNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeReavaliacaoNDescritivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'reavaliacaoNDescritivo', nome: 'nomeReavaliacaoNDescritivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativos.configuracoes.nContaContrapartida'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="reavaliacaoNContaContrapartida"
                      [model]="model"
                      (selectedKeyChange)="model.reavaliacaoNContaContrapartida = $event"
                      (evtSelectedDescriptionChanged)="model.nomeReavaliacaoNContaContrapartida = $event"
                      [properties]="{validators: {required: {value: true}}}"
                      [filter]="filterMovimento"
                      [fieldsMap]="{nConta: 'reavaliacaoNContaContrapartida', nome: 'nomeReavaliacaoNContaContrapartida'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.impdifreavaliacoesnaolegais'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaPassivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaPassivoReavaliacoes"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaPassivoReavaliacoes = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaPassivoReavaliacoes = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaPassivoReavaliacoes', nome: 'nomeImpostosDiferidosContaPassivoReavaliacoes'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaAjustamentos'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaAjustamentosReavaliacoes"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaAjustamentosReavaliacoes = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaAjustamentosReavaliacoes = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaAjustamentosReavaliacoes', nome: 'nomeImpostosDiferidosContaAjustamentosReavaliacoes'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.ativosbioprod" id="ativosbioprod">
              <div *plTabContent>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.radicalabp'"></label>
                  <edit>
                    <pl-edit type="text" [(model)]="model.abpRadical"></pl-edit>
                  </edit>
                </pl-group>

                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.operacoesjustovalor'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDiario'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="diarios"
                        attrName="abpNDiario"
                        [model]="model"
                        (selectedKeyChange)="model.abpNDiario = $event"
                        (evtSelectedDescriptionChanged)="model.nomeABPNDiario = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDiario: 'abpNDiario', nome: 'nomeABPNDiario'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.codDescritivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="descritivos"
                        attrName="abpNDescritivo"
                        [model]="model"
                        (selectedKeyChange)="model.abpNDescritivo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeABPNDescritivo = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [fieldsMap]="{nDescrit: 'abpNDescritivo', nome: 'nomeABPNDescritivo'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaParaReducao'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="abpNContaReducao"
                        [model]="model"
                        (selectedKeyChange)="model.abpNContaReducao = $event"
                        (evtSelectedDescriptionChanged)="model.nomeABPNContaReducao = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'abpNContaReducao', nome: 'nomeABPNContaReducao'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaParaAumento'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="abpNContaAumento"
                        [model]="model"
                        (selectedKeyChange)="model.abpNContaAumento = $event"
                        (evtSelectedDescriptionChanged)="model.nomeABPNContaAumento = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'abpNContaAumento', nome: 'nomeABPNContaAumento'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <h4 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.impdifoperacaojustovalor'"></label></h4>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaPassivo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaPassivoABP"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaPassivoABP = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaPassivoABP = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaPassivoABP', nome: 'nomeImpostosDiferidosContaPassivoABP'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.nContaAjustamentos'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="pocs"
                        attrName="impostosDiferidosContaAjustamentosABP"
                        [model]="model"
                        (selectedKeyChange)="model.impostosDiferidosContaAjustamentosABP = $event"
                        (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaAjustamentosABP = $event"
                        [properties]="{validators: {required: {value: true}}}"
                        [filter]="filterMovimento"
                        [fieldsMap]="{nConta: 'impostosDiferidosContaAjustamentosABP', nome: 'nomeImpostosDiferidosContaAjustamentosABP'}">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativos.configuracoes.nContaAtivo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="impostosDiferidosContaAtivoABP"
                      [model]="model"
                      (selectedKeyChange)="model.impostosDiferidosContaAtivoABP = $event"
                      (evtSelectedDescriptionChanged)="model.nomeImpostosDiferidosContaAtivoABP = $event"
                      [properties]="{validators: {required: {value: true}}}"
                      [filter]="filterMovimento"
                      [fieldsMap]="{nConta: 'impostosDiferidosContaAtivoABP', nome: 'nomeImpostosDiferidosContaAtivoABP'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.regraAbateVenda" id="regraAbateVenda">
              <div *plTabContent>
                <pl-group>
                  <edit>
                    <pl-edit
                      type="radio"
                      attrName="dataLimiteParaCalculoContabilisticoIgualFiscalTrue"
                      [(model)]="model.dataLimiteParaCalculoContabilisticoIgualFiscal"
                      [properties]="{value: true, label: 'ativos.configuracoes.dataLimiteParaCalculoContabilisticoIgualFiscalTrue'}">
                    </pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <edit>
                    <pl-edit
                      type="radio"
                      attrName="dataLimiteParaCalculoContabilisticoIgualFiscalFalse"
                      [(model)]="model.dataLimiteParaCalculoContabilisticoIgualFiscal"
                      [properties]="{value: false, label: 'ativos.configuracoes.dataLimiteParaCalculoContabilisticoIgualFiscalFalse'}">
                    </pl-edit>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.contabilidadeAnalitica" id="contabilidadeAnalitica">
              <div *plTabContent>
                <pl-group>
                  <edit>
                    <pl-edit type="boolean" attrName="contabilidadeAnaliticaUsa" [(model)]="model.contabilidadeAnaliticaUsa" [properties]="{label: 'ativos.configuracoes.contabilidadeAnaliticaUsa'}">
                    </pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'ativos.configuracoes.contabilidadeAnaliticaMascara'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="mascarasanalitica"
                      attrName="contabilidadeAnaliticaMascara"
                      [model]="model"
                      [selectedKey]="model.contabilidadeAnaliticaMascara"
                      (selectedKeyChange)="model.contabilidadeAnaliticaMascara = $event"
                      [properties]="{outputKey: 'mascara', disabled: !model.contabilidadeAnaliticaUsa}"
                      [fieldsMap]="{mascara: 'contabilidadeAnaliticaMascara'}"
                      [output]="'{{mascara}}'">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <h6 class="page-header"><label [translate]="'ativos.configuracoes.tab.title.contabilidadeanaliticatitle'"></label></h6>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionRadical"
                  cgDxDataGridInstanceName="listaRadical"
                  [dataSource]="dataSourceRadicalList"
                  (onInitNewRow)="onDataGridRadicalInitNewRow($event)">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab caption="ativos.configuracoes.tab.classificadores" id="classificadores">
              <div *plTabContent>
                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.ccusto'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="ccustos"
                        attrName="classificadoresCCusto"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemNCCusto = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresCCusto = $event"
                        [fieldsMap]="{nCCusto: 'novoBemNCCusto', nome: 'nomeClassificadoresCCusto'}"
                        [output]="'{{nCCusto}} - {{nome}}'"
                        [filter]="'encerrado=0'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.zona'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="zonas"
                        attrName="classificadoresZona"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemNZona = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresZona = $event"
                        [fieldsMap]="{nZona: 'novoBemNZona', nome: 'nomeClassificadoresZona'}"
                        [output]="'{{nZona}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.departamento'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="depto"
                        attrName="classificadoresDepto"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemDepartamento = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresDepartamento = $event"
                        [fieldsMap]="{nDepto: 'novoBemDepartamento', nome: 'nomeClassificadoresDepartamento'}"
                        [output]="'{{nDepto}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.subdepartamento'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="subde"
                        attrName="classificadoresSubDepto"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemNSubde = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresSubDepartamento = $event"
                        [fieldsMap]="{nSubDe: 'novoBemNSubde', nome: 'nomeClassificadoresSubDepartamento'}"
                        [output]="'{{nSubDe}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.tipoartigo'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="tiposartigo"
                        attrName="classificadoresTipoArtigo"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemTipoArtigo = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresTipoArtigo = $event"
                        [fieldsMap]="{codTpArt: 'novoBemTipoArtigo', nome: 'nomeClassificadoresTipoArtigo'}"
                        [output]="'{{codTpArt}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.grandefamilia'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="grandesfamilias"
                        attrName="classificadoresGrandesfamilias"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemGrandeFamilia = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresGrandeFamilia = $event"
                        [fieldsMap]="{nDepart: 'novoBemGrandeFamilia', nome: 'nomeClassificadoresGrandeFamilia'}"
                        [output]="'{{nDepart}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.familia'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="familias"
                        attrName="classificadoresFamilias"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemFamilia = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresFamilia = $event"
                        [fieldsMap]="{nFamilia: 'novoBemFamilia', nome: 'nomeClassificadoresFamilia'}"
                        [output]="'{{nFamilia}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.subfamilia'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="subfamilias"
                        attrName="classificadoresSubFamilias"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemNSubfa = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresSubFamilia = $event"
                        [fieldsMap]="{nSubFa: 'novoBemNSubfa', nome: 'nomeClassificadoresSubFamilia'}"
                        [output]="'{{nSubFa}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.contabilidade'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="codigoscontabilisticos"
                        attrName="classificadoresContabilidade"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemCodContab = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresContabilidade = $event"
                        [fieldsMap]="{codcontab: 'novoBemCodContab', nome: 'nomeClassificadoresContabilidade'}"
                        [output]="'{{codcontab}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.classe'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="artigoclasses"
                        attrName="classificadoresClasse"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemClasse = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresClasse = $event"
                        [fieldsMap]="{classe: 'novoBemCodContab', descricao: 'nomeClassificadoresContabilidade'}"
                        [output]="'{{classe}} - {{descricao}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.categoria'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="categoriaartigo"
                        attrName="classificadoresCategoria"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemCategoria = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresCategoria = $event"
                        [fieldsMap]="{categoria: 'novoBemCategoria', descricao: 'nomeClassificadoresCategoria'}"
                        [output]="'{{categoria}} - {{descricao}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'ativos.configuracoes.vendedor'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="vendedores"
                        attrName="classificadoresVendedor"
                        [model]="model"
                        (selectedKeyChange)="model.novoBemNVendedor = $event"
                        (evtSelectedDescriptionChanged)="model.nomeClassificadoresVendedor = $event"
                        [fieldsMap]="{nVendedor: 'novoBemNVendedor', nome: 'nomeClassificadoresVendedor'}"
                        [output]="'{{nVendedor}} - {{nome}}'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <label [translate]="'ativos.configuracoes.processo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="processos"
                      attrName="classificadoresProcesso"
                      [model]="model"
                      (selectedKeyChange)="model.novoBemNRefProcesso = $event"
                      (evtSelectedDescriptionChanged)="model.nomeClassificadoresProcesso = $event"
                      [fieldsMap]="{nRefProcesso: 'novoBemNRefProcesso', nome: 'nomeClassificadoresProcesso'}"
                      [output]="'{{nRefProcesso}} - {{nome}}'">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-tab>
          </pl-tabs>
        </div>
      </pl-tab>

      <pl-tab id="contasDepreciacao" caption="ativos.configuracoes.tab.contasDepreciacao">
        <div *plTabContent>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionContasDep"
            cgDxDataGridInstanceName="listaContasDep"
            [dataSource]="dataSourceContasDep"
            (onInitialized)="onDataGridInitializedContasDep($event)"
            (onDisposing)="onDataGridDisposingContasDep()">
            <div *dxTemplate="let cellInfo of 'editCellContaDepreciacao'">
              <entity-autocomplete
                entity="pocs"
                attrName="contaDep"
                [model]="cellInfo.data"
                (modelChange)="changedCellContaDepreciacao(cellInfo, $event)"
                [fieldsMap]="{nConta: 'contaDepreciacao', nome: 'nomeContaDepreciacao'}"
                [properties]="{allowInvalid: false}">
              </entity-autocomplete>
            </div>
            <div *dxTemplate="let cellInfo of 'editCellContaDepreciacaoAcumulada'">
              <entity-autocomplete
                entity="pocs"
                attrName="contaDepreciacaoAcumulada"
                [model]="cellInfo.data"
                (modelChange)="changedCellContaDepreciacaoAcumulada(cellInfo, $event)"
                [fieldsMap]="{nConta: 'contaDepreciacaoAcumulada', nome: 'nomeContaDepreciacaoAcumulada'}"
                [properties]="{allowInvalid: false}">
              </entity-autocomplete>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab id="contasInvestCurso" caption="ativos.configuracoes.tab.contasInvestCurso">
        <div *plTabContent>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionContasInvestCurso"
            cgDxDataGridInstanceName="listaContasDep"
            [dataSource]="dataSourceContasInvestCurso"
            (onInitialized)="onDataGridInitializedContasInvestCurso($event)"
            (onDisposing)="onDataGridDisposingContasInvestCurso()">
            <div *dxTemplate="let cellInfo of 'editCellCursoContaTransicao'">
              <entity-autocomplete
                entity="pocs"
                attrName="contaTrasicao"
                [model]="cellInfo.data"
                (modelChange)="changedCellContaTransicao(cellInfo, $event)"
                [fieldsMap]="{nConta: 'contaTrasicao'}"
                output="key"
                [properties]="{allowInvalid: false}">
              </entity-autocomplete>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>
    </pl-tabs>
  </pl-form>
</div>
