import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IPlTooltipConfig, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../../services/config/config.service';
import {ICGConfigDecimais} from '../../../../services/config/config.service.interface';
import {IJsonPagamento} from '../../jsonPagamento.entity.interface';
import {EEstadoSEPA, NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT, pagamentoDefault} from '../../pagamentos.entity.interface';
import {PagamentosService} from '../../service/pagamentos.entity.service';
import {PagamentoSepaAnularModalComponent} from '../../modals/sepaanularmodal/pagamento.sepaAnular.modal.component';

@Component({
  selector: 'pagamento',
  templateUrl: './pagamento.component.html'
})
export class PagamentoComponent implements OnInit, OnChanges {
  @Input() public pagamento: IJsonPagamento;
  @Output() public readonly evtRefresh: EventEmitter<void>;

  public readonly tooltip: IPlTooltipConfig;
  public readonly config: ICGConfigDecimais;
  public nDecimaisCambioDefault: number;

  private readonly _defaultPagamento: IJsonPagamento;

  constructor(
    private readonly _pagamentosService: PagamentosService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _configService: ConfigService
  ) {
    this.evtRefresh = new EventEmitter<void>();
    this.tooltip = {
      placement: 'top-right',
      container: 'body',
      text: this._translateService.instant('pagamentos.pagamento.disableRevoke'),
      disabled: true
    };
    this.config = this._configService.configurations.contabilidade.decimais;
    this._defaultPagamento = pagamentoDefault();
  }

  public ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.tooltip.disabled = this.pagamento.cab.origem === 13;

    this.nDecimaisCambioDefault = NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT;
  }

  public ngOnChanges({pagamento}: SimpleChanges): void {
    if (pagamento) {
      this._changedPagamento(pagamento.currentValue);
    }
  }

  public fnPdf = (): Promise<void> => this._pdf();

  public fnAnularPagamento = (): Promise<void> => this._anularPagamento();

  private _changedPagamento(value: IJsonPagamento = this.pagamento): void {
    this.pagamento = merge<object, IJsonPagamento, IJsonPagamento>({}, this._defaultPagamento, value);
  }

  private _pdf(): Promise<void> {
    return this._pagamentosService.getPdf({
      extPocCabID: this.pagamento?.cab?.extPocCabID,
      nConta: this.pagamento?.cab?.nConta,
      nomeConta: this.pagamento?.cab?.nome,
      email: this.pagamento?.cab?.email
    });
  }

  private async _anularPagamento(): Promise<void> {
    if (this.pagamento.cab.estadoSEPA !== EEstadoSEPA.FicheiroGerado) {
      await this._cgModalService.showOkCancel('pagamentos.info.anularpagamento', 'pagamentos.info.temCertezaAnularpagamento');
      await this._anulaPagamentoInternal(this.pagamento.cab.extPocCabID);
      return;
    }

    const modalInstance = this._cgModalService.showVanilla(PagamentoSepaAnularModalComponent, {size: 'lg'});
    const componentInstance: PagamentoSepaAnularModalComponent = modalInstance.componentInstance;
    componentInstance.extPocCabID = this.pagamento.cab.extPocCabID;
    componentInstance.evtRefresh = this.evtRefresh;
    await modalInstance.result;
  }

  private async _anulaPagamentoInternal(extPocCabID: string): Promise<void> {
    await this._pagamentosService.anular(extPocCabID);
    this._refreshList();
    this._plAlertService.success('pagamentos.info.anular');
  }

  private _refreshList(): void {
    this.evtRefresh.emit();
  }
}
