/**
 * CentralGest Amalia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageBodyFaq { 
    /**
     * The faq title
     */
    title?: string;
    /**
     * The source format: text, md, html
     */
    format?: MessageBodyFaq.FormatEnum;
    /**
     * The faq
     */
    source?: string;
    /**
     * The external facId
     */
    externalId?: string;
}
export namespace MessageBodyFaq {
    export type FormatEnum = 'TEXT';
    export const FormatEnum = {
        Text: 'TEXT' as FormatEnum
    };
}


