<div>
  <pl-alert type="error" [closeable]="true" (evtClosed)="showLicenseNotActivatedCGOnError = false" *ngIf="showLicenseNotActivatedCGOnError">
    <div [innerHTML]="'arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrl}"></div>
  </pl-alert>

  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [evaluationMethods]="evaluationMethods"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailSuccess>
      <button type="button" class="btn btn-light btn-sm" (click)="getpdf()"><i class="fa fa-fw fa-file-pdf-o"></i> Ver PDF</button>
    </div>

    <div *entityDetailContent>
      <doccomercial
        [group]="group"
        [model]="model"
        [lastDoc]="lastDoc"
        [type]="type"
        [docComercialStateParams]="stateParams"
        [entityName]="entityName"
        [toolbarInstanceName]="instanceName"
        [callback]="docComercialCallback"
        [maintenanceMode]="maintenanceMode"
        [statesDetailName]="states.detail.name"
        [serviceMethodsOverride]="serviceMethodsOverride"
        [arquivoDigital]="contabilidadeDigital"
        (evtModelChanged)="model = $event"
        (evtLastDocChanged)="lastDoc = $event"
        (evtDropdownActionsEvaluate)="dropdownActionsEvaluate($event)"
        (evtComercialArquivoDigitalAttachmentChange)="comercialArquivoDigitalAttachmentChange($event)"
        (evtSetActionEnabled)="setActionEnabled($event)">
      </doccomercial>
    </div>
  </entity-detail>
</div>
