import {Component} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'importador-saft-direct-tipos-docs-modal',
  templateUrl: './importadorSaftDirect.correspContas.associar.modal.component.html'
})
export class ImportadorSaftDirectCorrespContasAssociarModalComponent extends CGModalComponent<string> {
  public nConta: string;
}
