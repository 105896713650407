import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_SINAL_TAXA_RETENCAO} from './sinalTaxaRetencao.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_SINAL_TAXA_RETENCAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_SINAL_TAXA_RETENCAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'sinalTaxaRetencao.data.negativo'},
    {value: 1, name: 'sinalTaxaRetencao.data.positivo'}
  ]
});
