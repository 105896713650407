import {Injectable} from '@angular/core';
import {IMaintenanceModal} from './modal/maintenance.modal.interface';
import {IStack} from '../../../../common/interfaces/interfaces';
import {TOOLBAR_ID_MAINTENANCE_MODAL} from '../../../../config/constants';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceWindowService implements IStack<IMaintenanceModal> {
  private readonly _instances: Array<IMaintenanceModal>;
  private _instancesCount: number;

  constructor() {
    this._instances = [];
    this._instancesCount = -1;
  }

  public push(instance: IMaintenanceModal): number {
    return this._instances.unshift(instance);
  }

  public pop(): IMaintenanceModal {
    return !this._instances.length ? undefined : this._instances.splice(0, 1)[0];
  }

  public peek(): IMaintenanceModal {
    return !this._instances.length ? undefined : this._instances[0];
  }

  public nextId(): string {
    this._instancesCount++;
    return `${TOOLBAR_ID_MAINTENANCE_MODAL}_${this._instancesCount}`;
  }
}
