<div class="pl-calendar-year-view">
  <pl-scheduler
    [data]="schedulerData"
    [rangeSelect]="rangeSelect"
    [rangeSelectMultiline]="rangeSelectMultiline"
    [datasetDetail]="datasetDetail"
    [datasetDetailOnSelect]="datasetDetailOnSelect"
    [datasetDetailActive]="datasetDetailActive"
    [showTitle]="showTitle"
    [showLabel]="showLabel"
    [selectedRange]="schedulerRange"
    [templateTitle]="templateTitle"
    [templateLabel]="templateLabel"
    [templateHeader]="templateYearViewHeader"
    [templateDataset]="templateYearViewMonth"
    [templateDatasetItem]="templateYearViewDay"
    [templateDatasetHeaderDetail]="templateYearViewMonthHeaderDetail"
    [templateDatasetDetail]="templateYearViewDayDetail"
    (datasetDetailActiveChange)="onDatasetDetailActiveChange($event)"
    (selectedRangeChange)="onSelectedRangeChange($event)"
    (evtCellClick)="onCellClick($event)"
    (evtRangeSelect)="onRangeSelect($event)"
    (evtSelectionChanged)="onSelectionChanged($event)">
  </pl-scheduler>
</div>

<ng-template #templateYearViewHeader let-header="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateHeader || templateHeaderDefault;
      context: {
        $implicit: header,
        item: header,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd,
        weekday: header.meta.weekday,
        weekdayName: header.meta.weekdayName,
        weekdayNameShort: header.meta.weekdayNameShort,
        weekdayNameMin: header.meta.weekdayNameMin
      }
    "></ng-container>
</ng-template>

<ng-template #templateYearViewMonth let-dataset="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateMonth || templateMonthDefault;
      context: {
        $implicit: dataset,
        monthItem: dataset,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd,
        month: dataset.meta.month,
        monthName: dataset.meta.monthName,
        monthNameShort: dataset.meta.monthNameShort
      }
    "></ng-container>
</ng-template>

<ng-template #templateYearViewDay let-datasetItem="item" let-dataset="dataset" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateDay || templateDayDefault;
      context: {
        $implicit: datasetItem,
        day: datasetItem,
        month: dataset,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd
      }
    "></ng-container>
</ng-template>

<ng-template #templateYearViewMonthHeaderDetail let-dataset="dataset">
  <ng-container
    *ngTemplateOutlet="
      templateMonthHeader;
      context: {
        $implicit: dataset,
        month: dataset
      }
    "></ng-container>
</ng-template>

<ng-template #templateYearViewDayDetail let-datasets="datasets" let-dataset="dataset" let-datasetsItems="datasetsItems" let-datasetItem="datasetItem">
  <ng-container
    *ngTemplateOutlet="
      templateDayDetail || templateDayDetailDefault;
      context: {
        months: datasets,
        month: dataset,
        days: datasetsItems,
        day: datasetItem
      }
    "></ng-container>
</ng-template>

<ng-template
  #templateHeaderDefault
  let-header="item"
  let-index="index"
  let-count="count"
  let-first="first"
  let-last="last"
  let-even="even"
  let-odd="odd"
  let-weekday="weekday"
  let-weekdayName="weekdayName"
  let-weekdayNameShort="weekdayNameShort"
  let-weekdayNameMin="weekdayNameMin"
  let-week="week">
  <span [attr.title]="weekdayName">{{ weekdayNameShort }}</span>
</ng-template>

<ng-template
  #templateMonthDefault
  let-monthItem="monthItem"
  let-index="index"
  let-count="count"
  let-first="first"
  let-last="last"
  let-even="even"
  let-odd="odd"
  let-month="month"
  let-monthName="monthName"
  let-monthNameShort="monthNameShort">
  <span>{{ monthItem.label }}</span>
</ng-template>

<ng-template #templateDayDefault let-day="day" let-month="month" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span>{{ day.label }}</span>
</ng-template>

<ng-template #templateDayDetailDefault let-months="months" let-month="month" let-days="days" let-day="day">
  <span>{{ day.label }}</span>
</ng-template>
