import {HttpResponse} from '@angular/common/http';
import {Transition} from '@uirouter/core';
import {DATA_SOURCE_NAME_TIPO_TEXTO_SOLIC} from '../../datasources/tipotextosolic/tipoTextoSolic.datasource.interface';
import {ENTITY_NAME_SOLIC, ISOLICDetailStateParams, ISOLICEntity, ISOLICEntityService, TSOLICRequestData, TSOLICRequestDataWithBody} from './solic.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IJsonSOLIC} from './jsonSolic.entity.interface';
import {ROLE} from '../../services/role.const';
import {SOLICEntityEditComponent} from './components/edit/solic.entity.edit.component';
import {SOLICEntityListComponent} from './components/list/solic.entity.list.component';

export const ENTITY_SOLIC: ISOLICEntity = {
  name: ENTITY_NAME_SOLIC,
  urlSuffix: '?tipotexto',
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.FINANCEIRO],
  searchPlaceholder: 'solic.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.solic',
  metadata: {
    keyName: 'nTexto',
    fields: [
      {
        name: 'tipoTexto',
        type: DATA_SOURCE_NAME_TIPO_TEXTO_SOLIC,
        caption: 'solic.fields.tipoTexto',
        validators: {required: true},
        properties: {
          devExpress: {
            dataGrid: {
              lookup: {
                cgDataSource: DATA_SOURCE_NAME_TIPO_TEXTO_SOLIC
              }
            }
          }
        }
      },
      {name: 'nTexto', type: 'integer', caption: 'solic.fields.nTexto', validators: {required: true}},
      {name: 'tituloTexto', type: 'text', caption: 'solic.fields.tituloTexto', validators: {required: true, maxlength: 50}},
      {name: 'nDiasAtraso', type: 'number', caption: 'solic.fields.nDiasAtraso'},
      {name: 'note', type: 'textarea', caption: 'solic.fields.note'}
    ],
    order: 'tipoTexto',
    searchFields: 'tipoTexto,nTexto,tituloTexto'
  },
  autocomplete: {
    rowTemplate: '{{nTexto}} - {{tituloTexto}}',
    output: 'tituloTexto',
    searchFields: 'nTexto,tituloTexto'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      component: SOLICEntityListComponent
    }
  },
  detail: {
    state: {
      component: SOLICEntityEditComponent,
      params: {
        tipotexto: {
          type: 'int'
        }
      },
      resolve: [
        {
          provide: 'model',
          deps: [EntityServiceBuilder, Transition],
          useFactory: (entityServiceBuilder: EntityServiceBuilder, transition: Transition): Promise<IJsonSOLIC> => {
            const params: ISOLICDetailStateParams = <ISOLICDetailStateParams>transition.params();
            if (params.id) {
              const service: ISOLICEntityService = entityServiceBuilder.build<IJsonSOLIC, ISOLICEntityService>(ENTITY_NAME_SOLIC);
              return service.get({id: params.id, tipotexto: params.tipotexto}).then((response: HttpResponse<IJsonSOLIC>) => response.body);
            }
            return Promise.resolve(undefined);
          }
        }
      ]
    }
  },
  service: function () {
    const superGet = this.get.bind(this);
    const superDelete = this.delete.bind(this);

    this.get = (config: TSOLICRequestData) => {
      const {tipotexto, id} = config;
      return superGet({id: id, params: {tipotexto: tipotexto}, ...config});
    };

    this.delete = (config: TSOLICRequestDataWithBody<unknown>) => {
      const {tipotexto} = config;
      return superDelete({params: {tipotexto: tipotexto}, ...config});
    };
  }
};
