import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_DASHBOARD_RH} from './dashboardRh.module.interface';
import {DashboardRhModuleComponent} from './components/dashboardRh.module.component';

export const MODULE_DASHBOARD_RH: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARD_RH,
  state: {
    url: '/dashboard',
    component: DashboardRhModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    }
  }
};
