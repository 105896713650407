import {Component, Injector, Input} from '@angular/core';
import {IAssistenteConfigPortal, IAssistenteConfigPortalOperation} from '../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'assistente-config-portais',
  templateUrl: './assistenteConfigPortais.module.component.html'
})
export class AssistenteConfigPortaisModuleComponent extends ModuloComponent {
  @Input() public portalsList: Array<IAssistenteConfigPortal>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public executeOperation(operation: IAssistenteConfigPortalOperation): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: operation.moduleName, exactMatch: true});
  }
}
