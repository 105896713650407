import {IEntityDetailStateParams} from '../../components/entity/entity.definition.interface';
import {IJsonLote} from './jsonLote.entity.interface';

export const ENTITY_NAME_LOTES = 'lotes';

export interface ILoteDetailStateParams extends IEntityDetailStateParams {
  defaultData: IJsonLote;
  nArtigo: string;
  nomeArtigo: string;
  model?: IJsonLote;
}
