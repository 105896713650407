import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonConcilMovAb, IJsonConcilMovAbConta, IJsonConcilMovAbJobStatus} from './jsonConcilMovAb.module.interface';
import {TFindContaMovimentoAction} from './concilMovAb.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ConcilMovAbModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/concilmovab`;
  }

  public getListaMovimentos(nConta: string, atePeriodo: string): TServiceResponse<Array<IJsonConcilMovAb>> {
    return this._apiService.get<Array<IJsonConcilMovAb>>({
      url: `${this._path}/listamovimentos`,
      params: {nconta: nConta, ateperiodo: atePeriodo}
    });
  }

  public findContaMovimento(nConta: string, action: TFindContaMovimentoAction, atePeriodo: string): TServiceResponse<IJsonConcilMovAbConta> {
    return this._apiService.get<IJsonConcilMovAbConta>({
      url: `${this._path}/encontracontamovimento/${action}`,
      params: {nconta: nConta, ateperiodo: atePeriodo}
    });
  }

  public justValAutoEmSerieStatus(): TServiceResponse<IJsonConcilMovAbJobStatus> {
    return this._apiService.get<IJsonConcilMovAbJobStatus>({
      url: `${this._path}/justvalautoemseriesstatus`
    });
  }

  public justValAutoEmSerieStopJob(): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: this.justValAutoEmSerieStopJobUrl()
    });
  }

  public justValAutoEmSerieStopJobUrl(): string {
    return `${this._path}/justvalautoemseriesstop`;
  }

  public getJustificarValoresAuto(nConta: string, atePeriodo: string): TServiceResponse<Array<IJsonConcilMovAb>> {
    return this._apiService.get<Array<IJsonConcilMovAb>>({
      url: `${this._path}/justificarvaloresauto`,
      params: {nconta: nConta, ateperiodo: atePeriodo}
    });
  }

  public getJustificarValoresAutoEmSerie(denconta: string, atenconta: string, atePeriodo: string): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._path}/justificarvaloresautoemserie`,
      params: {denconta: denconta, atenconta: atenconta, ateperiodo: atePeriodo}
    });
  }

  public postJustificacaoDeMovimentosAb(nConta: string, listaMovAb: Array<IJsonConcilMovAb>): TServiceResponse<Array<IJsonConcilMovAb>> {
    return this._apiService.post<Array<IJsonConcilMovAb>>({
      url: `${this._path}/justificacaodemovimentosab`,
      body: listaMovAb,
      params: {nconta: nConta}
    });
  }
}
