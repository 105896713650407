import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IEntityService, TEntityServiceRequestData} from '../../../services/entity/entity.service.interface';
import {IJsonUserEmpresa} from '../../../services/account/jsonUserApi.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

export interface IUtilizadoresBasicEntityService extends IEntityService<IJsonUserEmpresa> {
  getWithoutAccesses(config: TEntityServiceRequestData): TServiceResponse<IJsonUserEmpresa>;
}

export interface IUtilizadoresBasicEntity extends IEntityDefinition<IJsonUserEmpresa, IUtilizadoresBasicEntityService> {}

export const ENTITY_NAME_UTILIZADORES_BASICO = 'usersbasic';
