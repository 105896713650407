import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_UNIDADES} from './tipoUnidades.datasource.interface';

export const DATA_SOURCE_TIPO_UNIDADES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_UNIDADES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 1, name: 'tipoUnidadesValue.data.valor'},
    {value: 2, name: 'tipoUnidadesValue.data.hora'},
    {value: 3, name: 'tipoUnidadesValue.data.minuto'},
    {value: 4, name: 'tipoUnidadesValue.data.segundo'},
    {value: 5, name: 'tipoUnidadesValue.data.dia'},
    {value: 6, name: 'tipoUnidadesValue.data.mes'},
    {value: 7, name: 'tipoUnidadesValue.data.ano'},
    {value: 8, name: 'tipoUnidadesValue.data.horautil'},
    {value: 9, name: 'tipoUnidadesValue.data.horabase30'},
    {value: 10, name: 'tipoUnidadesValue.data.diautil'},
    {value: 11, name: 'tipoUnidadesValue.data.diabase30'}
  ]
});
