import {TDate} from '../../../../common/dates';
import {IGTOTarefasBase} from './gto.module.interface';

export enum EGTOTipoTarefa {
  Obrigacoes,
  Pagamentos,
  Outra
}

export enum EGTOPeriodoIVA {
  TODOS,
  Mensal,
  Trimestral
}

export enum EGTOExecuteEstado {
  SemAlteracao,
  Concluir,
  AdicionarJustificacao,
  NaoAplicavel,
  New,
  GuardarValidacao
}

export enum EGTOMostrarFilter {
  DataLimite,
  Values,
  Dias,
  DataExecucao,
  DataLimitePlusNotes,
  ValuesPlusNotes,
  DiasPlusNotes,
  DataExecucaoPlusNotes
}

export enum EGTODataIniPerio {
  Mes,
  MesAnterior,
  DoisMesAnterior,
  AnoAnterior
}

export enum EGTOMODE {
  NEW,
  EDIT
}

export enum EGTOCOPYFOR {
  EMPRESA,
  ANO
}

export enum EGTOTarefasMarcacaoState {
  NaoAplicavel,
  ConcluidoDentroPrazo,
  PrazoADecorrer,
  ConcluidoForaPrazo,
  EmAtraso,
  EmAtrasoJustificado,
  Pendente,
  GuardarValidacao
}

export interface IJsonGTOTarefa {
  nUtilizCriaReg?: number;
  gtoTarefaID: string;
  nomeTarefa: string;
  tipoTarefa: number;
  tipoObrigacao: number;
  ano: number;
  isSystem: boolean;
  nEmpresa: string;
  nEmpresaGab: string;
  isActive: boolean;
  dataIniPerio: number;
  entExtCod: string;
  nResponsavel: number;
  nomeResponsavel?: string;
  gtoTarefaMarc: Array<IJsonGTOTarefaMarc>;
}

export interface IJsonGTOTarefaDataRecord {
  gtoTarefasMarcacaoID: string;
  data: TDate;
}

export interface IJsonGTOTarefaMarc {
  gtoTarefasMarcID: string;
  gtoTarefaID: string;
  data: TDate;
  valor: string;
  nUtilizExec: number;
  dataExec: TDate;
  justificacao: string;
  naoAplicavel: boolean;
  emValidacao: boolean;
  state: EGTOTarefasMarcacaoState;
  nota: string;
  nUtilizNameExec: string;
}

export interface IJsonGTOTarefaMultiItem {
  tarefaNome: string;
  gtoTarefasMarcacaoID: string;
  gtoTarefaID: string;
  data: TDate;
  valor: string;
  state: EGTOTarefasMarcacaoState;
  dataExec: number;
  justificacao: string;
  nUtilizExec: number;
  nResponsavel: number;
  nota: string;
  nUtilizNameExec: string;
}

export interface IJsonGTOTarefaMultiRow {
  nEmpresa: string;
  nomeEmpresa: string;
  data: Array<IJsonGTOTarefaMultiItem>;
}

export interface IJsonGTOTarefaExecuteData {
  gtoTarefasMarcacaoID: string;
  state: EGTOExecuteEstado;
  valor: string;
  justificacao: string;
  execDate: TDate;
  nota: string;
}

export interface IJsonGTOTarefaCopyEmpresasData {
  tarefas: Array<string>;
  empresas: Array<string>;
  fullOverride: boolean;
}

export interface IJsonGTOTarefaCopyAnoData {
  tarefas: Array<string>;
  ano: number;
  fullOverride: boolean;
}

export interface IJsonGTOTarefas extends IGTOTarefasBase {
  list: Array<IJsonGTOTarefa>;
}

export interface IJsonGTOTarefasMulti extends IGTOTarefasBase {
  list: Array<IJsonGTOTarefaMultiRow>;
}

export interface IJsonGTOConfig {
  startGTO: TDate;
  alertNDias: number;
}

export interface IJsonGTOAlerta {
  nEmpresa: string;
  nomeEmpresa: string;
  nomeTarefa: string;
  expireDate: TDate;
}

export interface IJsonGTOCheckDMR {
  needRefresh: boolean;
}

export interface IJsonGTOTarefaNewOrUpdate extends IJsonGTOTarefa {
  dates: Array<IJsonGTOTarefaDataRecord>;
  applyAllEmpresas: boolean;
  entExtCodNome?: string;
}

export interface IJsonGTOCopyEmpresa {
  nEmpresa: string;
  nomeEmpresa: string;
  regimeIRCIRS: string;
  periodoIVA: string;
}

export interface IJsonGTOAlertsRequest {
  isMulti: boolean;
  nempresa: string;
  ano: number;
  mes: number;
  tipoTarefa: number;
  nResponsavel: number;
  periodoIVA: number;
  regimeIRC: number;
  nomeTarefa: string;
}

export interface IJsonGTOLicenseInfo {
  haveContabDigitalLicense: boolean;
  haveGTOLicense: boolean;
  nEmpresasTotal: number;
  nEmpresasConsumidas: number;
  nEmpresasPorConsumir: number;
}

export interface IJsonGTOCheckLicenseEmpresa {
  inList: boolean;
}

export interface IJsonGTOCheckLicenseEmpresas {
  validCount: number;
  empresasToLicense: Array<string>;
  nEmpresasPorConsumir: number;
}
