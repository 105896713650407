export function viewportScrollTo(scrollToOptions: ScrollToOptions): void {
  const html: HTMLHtmlElement = window.document.querySelector<HTMLHtmlElement>('html');
  if (!html) {
    return;
  }
  html.scrollTo(scrollToOptions);
}

export function viewportScrollTop(behavior: ScrollBehavior = 'auto'): void {
  viewportScrollTo({top: 0, behavior: behavior});
}

export function viewportScrollBottom(behavior: ScrollBehavior = 'auto'): void {
  const html: HTMLHtmlElement = window.document.querySelector<HTMLHtmlElement>('html');
  if (!html) {
    return;
  }
  viewportScrollTo({top: html.scrollHeight, behavior: behavior});
}

export function viewportScrollToElement(target: Element, behavior: ScrollBehavior = 'auto', topOffset: number = 0, leftOffset: number = 0): void {
  const html: HTMLHtmlElement = window.document.querySelector<HTMLHtmlElement>('html');
  if (!html || !target) {
    return;
  }
  const targetRect: DOMRect = target.getBoundingClientRect();
  let top: number = targetRect.top;
  if (top < 0) {
    top = html.scrollTop + top;
  }
  top -= topOffset || 0;
  if (top < 0) {
    top = 0;
  }
  let left: number = targetRect.left;
  if (left < 0) {
    left = html.scrollLeft + left;
  }
  left -= leftOffset || 0;
  if (left < 0) {
    left = 0;
  }
  html.scrollTo({top: top, left: left, behavior: behavior});
}
