import {cloneDeep, isEqual, merge, orderBy, toNumber} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  copy,
  downloadStream,
  IPlNavPillCallback,
  IPlTabsCallback,
  IPlTabsEventSelected,
  IPlToolbarItem,
  IPlToolbarMenuItem,
  IPlTooltipConfig,
  isArray,
  isNumber,
  isObject,
  lowercase,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {
  ENavAnexosDecIvaPerio,
  IDecIvaPerioOperacoesIguaisSede,
  IDecIvaPerioOperacoesIguaisSedeIndividual,
  IDecIvaPerioOperacoesIguaisSedeValoresLocal,
  IMetodoSourcePrazoDecl,
  INumeroIdentificacaoROC,
  TDeclaracaoDeIvaRocValidationCallbackData
} from '../contabilidade.declaracaoIva.module.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {
  IJsonDecIvaPerioAnexoRegularizacoes,
  IJsonDecIvaPeriodica,
  IJsonDecIvaPeriodicaAnexoCampo40,
  IJsonDecIvaPeriodicaAnexoCampo41,
  IJsonDecIvaPeriodicaValoresLocal,
  IJsonDecIvaPerioExtratoCampo,
  IJsonPeriodoDecIva
} from '../../../../../interfaces/jsonDeclaracaoIva.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EAreasRegionais} from '../../../../../datasources/areasregionais/areasRegionais.datasource.interface';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../services/contabilidade.declaracaoiva.service';
import {HttpResponse} from '@angular/common/http';
import {DeclaracaoIvaPeriodicaExtratoCampoModalComponent} from '../modals/extratocampo/declaracaoIvaPeriodica.extratoCampo.modal.component';
import {ContabilidadeDeclaracaoivaConfigModalComponent} from '../modals/config/contabilidade.declaracaoIva.config.modal.component';
import {DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent} from '../modals/previsualizadeclaracao/declaracaoIvaPeriodica.preVisualizaDeclaracao.modal.component';
import {DATA_SOURCE_TIPOS_ANEXO} from '../../../../../datasources/tiposanexo/tiposAnexo.datasource';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {round} from '../../../../../../common/utils/utils';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES} from '../../../estatistica/balancetes/contabilidade.balancetes.module.interface';
import {ETipoAnexo} from '../../../../../datasources/tiposanexo/tiposAnexo.datasource.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnInitNewRow} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {MODULE_NAME_DOCUMENTOS_FISCAIS} from '../../documentosFiscais/documentosFiscais.module.interface';

const NUMBER_3 = 3;
const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-contabilidade-declaracaoiva-periodica',
  templateUrl: './contabilidade.declaracaoIvaPeriodica.module.component.html'
})
export class ContabilidadeDeclaracaoIvaPeriodicaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public decIvaPeriodica: IJsonDecIvaPeriodica;

  public readonly dataGridDefinitionCampo40: IDevExpressDataGrid;
  public readonly dataGridDefinitionCampo41: IDevExpressDataGrid;
  public readonly dataGridDefinitionROC: IDevExpressDataGrid;
  public readonly tabCallback: IPlTabsCallback;
  public readonly plNavPillCallback: IPlNavPillCallback;
  public readonly metodoSourcePrazoDecl: Array<IMetodoSourcePrazoDecl>;
  public readonly mtdOutput: string;
  public readonly tooltipValorReembolsoSolicitado: IPlTooltipConfig;

  public activeTab: ENavAnexosDecIvaPerio;
  public typeNavAnexos: typeof ENavAnexosDecIvaPerio;
  public nomePrazoDecl: string;
  public hasAnexoRContinente: boolean;
  public hasAnexoRAcores: boolean;
  public hasAnexoRMadeira: boolean;
  public hasAnexoCampo40: boolean;
  public hasAnexoCampo41: boolean;
  public continente: boolean;
  public acores: boolean;
  public madeira: boolean;
  public showTooltipValorReembolsoSolicitado: boolean;
  public nifsROC: Array<INumeroIdentificacaoROC>;
  public anexoSede: ENavAnexosDecIvaPerio;

  private readonly _mnuPeriodos: IPlToolbarItem<IJsonPeriodoDecIva>;
  private readonly _btnCriarFicheiro: IPlToolbarMenuItem;
  private readonly _btnValidaAt: IPlToolbarItem;
  private readonly _btnSumbmitAt: IPlToolbarMenuItem;
  private readonly _btnConfigWS: IPlToolbarMenuItem;
  private readonly _btnAnular: IPlToolbarMenuItem;
  private readonly _btnPreVisualizar: IPlToolbarMenuItem;
  private readonly _btnAtalhos: IPlToolbarItem;
  private readonly _btnBalancete: IPlToolbarMenuItem;
  private readonly _btnDocumentosFiscais: IPlToolbarMenuItem;
  private readonly _maintenanceInstanceBalancete: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceDocsFiscais: IModuleMaintenanceInstance;
  private _initialDecIvaPeriodica: IJsonDecIvaPeriodica;
  private _promise: Promise<void>;
  private _periodosIVA: Array<IJsonPeriodoDecIva>;
  private _periodo: string;
  private _dataGridInstanceROC: dxDataGrid<INumeroIdentificacaoROC, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _declaracaoIvaService: ContabilidadeDeclaracaoIvaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this.dataGridDefinitionCampo40 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoRegularizacaoCampo40Str', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.tipoRegularizacaoCampo', allowEditing: false},
        {dataField: 'nif', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.nif', allowEditing: true},
        {dataField: 'nPedido', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.nPedido', allowEditing: false},
        {dataField: 'baseIncidencia', dataType: 'double', caption: 'declaracaoiva.anexos.table40e41.fields.baseIncidencia', allowEditing: false},
        {dataField: 'ivaRegularizado', dataType: 'double', caption: 'declaracaoiva.anexos.table40e41.fields.ivaRegularizado', allowEditing: false},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error', allowEditing: false},
        {dataField: 'dataEmissao', dataType: 'date', caption: 'declaracaoiva.anexos.table40e41.fields.dataEmissao', allowEditing: false}
      ],
      dataSource: [],
      editing: {
        mode: 'cell',
        allowUpdating: true
      },
      export: {filename: 'declaracaoiva.anexos.anexoCampo40.desc1'},
      remoteOperations: false
    };
    this.dataGridDefinitionCampo41 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoRegularizacaoCampo41Str', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.tipoRegularizacaoCampo', allowEditing: false},
        {dataField: 'nif', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.nif', allowEditing: true},
        {dataField: 'nPedido', dataType: 'string', caption: 'declaracaoiva.anexos.table40e41.fields.nPedido', allowEditing: false},
        {dataField: 'baseIncidencia', dataType: 'double', caption: 'declaracaoiva.anexos.table40e41.fields.baseIncidencia', allowEditing: false},
        {dataField: 'ivaRegularizado', dataType: 'double', caption: 'declaracaoiva.anexos.table40e41.fields.ivaRegularizado', allowEditing: false},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error', allowEditing: false}
      ],
      dataSource: [],
      editing: {
        mode: 'cell',
        allowUpdating: true
      },
      export: {filename: 'declaracaoiva.anexos.anexoCampo40.desc1'},
      remoteOperations: false
    };
    this.dataGridDefinitionROC = {
      keyExpr: 'id',
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'nif',
          dataType: 'string',
          caption: 'declaracaoiva.anexos.anexoCampo40.nifroc',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('declaracaoiva.anexos.anexoCampo40.modal.roc.naopodeservazio')
            },
            {
              type: 'custom',
              message: this._translateService.instant('declaracaoiva.anexos.anexoCampo40.modal.roc.jaexiste'),
              validationCallback: (options: TDeclaracaoDeIvaRocValidationCallbackData) => this._validateRoc(options)
            }
          ],
          allowEditing: true
        }
      ],
      export: {filename: 'declaracaoiva.anexos.anexoCampo40.certirevisoroficialcontas'},
      remoteOperations: false,
      editing: {
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      toolbar: {
        visible: true,
        items: [
          {location: 'before', text: this._plTranslateService.translate('declaracaoiva.anexos.anexoCampo40.certirevisoroficialcontas')},
          {location: 'after', name: 'addRowButton'}
        ]
      }
    };

    this._mnuPeriodos = {
      groupId: 'pdec',
      id: 'periodos',
      order: 1,
      caption: 'Período',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._btnCriarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'criarficheiro',
      order: this.btnSave.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      class: 'btn-primary',
      caption: 'declaracaoiva.btns.getXML',
      disabled: false,
      tooltip: {
        text: 'declaracaoiva.tooltips.btnGetXML',
        placement: 'bottom',
        disabled: false
      },
      promise: this.promise,
      click: () => this._criarFicheiro()
    };
    this._btnValidaAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      order: this._btnCriarFicheiro.order + 1,
      type: 'dropdown-split',
      class: 'btn-primary',
      caption: 'global.btn.validarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarNaAT(),
      disabled: false,
      tooltip: {
        text: 'declaracaoiva.tooltips.validarNaAT',
        placement: 'bottom',
        disabled: false
      }
    };
    this._btnSumbmitAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      order: this._btnValidaAt.order + 1,
      type: 'button',
      caption: 'global.btn.submeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: false
    };
    this._btnConfigWS = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      order: this._btnSumbmitAt.order + 1,
      type: 'button',
      caption: 'global.btn.configWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      click: () => this._callConfigWs()
    };
    this._btnValidaAt.menu = [this._btnSumbmitAt, this._btnConfigWS];

    this._btnAnular = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'anular',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      caption: 'global.btn.nullify',
      disabled: true,
      promise: this.promise,
      click: () => this._anularDeclaracao()
    };
    this._btnPreVisualizar = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'preVisualizar',
      order: this._btnConfigWS.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: true,
      tooltip: {
        text: 'declaracaoiva.tooltips.guardarPrimeiroParaRealizarOperacao',
        placement: 'bottom',
        disabled: false
      },
      promise: this.promise,
      click: () => {
        this._preVisualizarDeclaracao();
      }
    };
    this._btnBalancete = {
      id: 'balanceteModulo',
      order: this._btnAnular.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-balance-scale"></i>&nbsp;',
      caption: 'declaracaoiva.btns.balancete',
      click: () => {
        this._openBalancetesModule();
      }
    };
    this._btnDocumentosFiscais = {
      id: 'documentosFiscais',
      order: this._btnBalancete.order + 1,
      type: 'button',
      caption: 'declaracaoiva.btns.documentosFiscais',
      iconLeft: '<i class="fa fa-file-text-o"></i>&nbsp;',
      click: () => this._openDocumentosFiscaisModule()
    };
    this._btnAtalhos = {
      id: 'atalhos',
      order: this._btnPreVisualizar.order + 1,
      type: 'dropdown',
      class: 'btn-info',
      caption: 'global.btn.shortcuts',
      iconLeft: '<i class="fa fa-external-link"></i>',
      menu: [this._btnBalancete, this._btnDocumentosFiscais]
    };

    this.tabCallback = {};
    this.plNavPillCallback = {};
    this.tooltipValorReembolsoSolicitado = {text: 'declaracaoiva.anexos.anexoR.valorReembolsoSolicitado', container: 'body', placement: 'left-bottom'};
    this.mtdOutput = '{{nameMetodo}}';
    this.metodoSourcePrazoDecl = [
      {
        valueMetodo: true,
        nameMetodo: 'declaracaoiva.anexos.rosto.quadro0.dentroprazo'
      },
      {
        valueMetodo: false,
        nameMetodo: 'declaracaoiva.anexos.rosto.quadro0.foraprazo'
      }
    ];
    this.typeNavAnexos = ENavAnexosDecIvaPerio;
    this.hasAnexoRContinente = false;
    this.hasAnexoRAcores = false;
    this.hasAnexoRMadeira = false;
    this.hasAnexoCampo40 = false;
    this.hasAnexoCampo41 = false;
    this.continente = false;
    this.acores = false;
    this.madeira = false;
    this.showTooltipValorReembolsoSolicitado = false;
    this._periodo = this._configService.configurations.empresa.periodo;
    this._maintenanceInstanceBalancete = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_BALANCETES);
    this._maintenanceInstanceDocsFiscais = this._moduleMaintenanceService.build(MODULE_NAME_DOCUMENTOS_FISCAIS);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this._buildToolbar();
    this._buildToolbarResponsive(this.isMobile);

    this._periodo = this.decIvaPeriodica.periodo;
    this._loadPeriodos().finally(() => {
      this.toolbar.addButton(this._mnuPeriodos);
      this._evaluateButtons();
    });

    await this._loadDeclaracaoIVAPerio(false);
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public onDataGridInitializedROC(event: IDevExpressDataGridEventOnInitialized<INumeroIdentificacaoROC, string>): void {
    this._dataGridInstanceROC = event.component;
  }

  public onDataGridROCInitNewRow(event: IDevExpressDataGridEventOnInitNewRow<INumeroIdentificacaoROC, string>): void {
    const totalCount: number = event.component.totalCount();
    event.data.id = totalCount + 1;
  }

  public changedTab(event: IPlTabsEventSelected): void {
    if (event.nextId === ENavAnexosDecIvaPerio.Anexos) {
      event.preventDefault();
    } else {
      this.activeTab = <ENavAnexosDecIvaPerio>event.nextId;
    }
  }

  public async removeAnexo(anexo: ENavAnexosDecIvaPerio): Promise<void> {
    await this._cgModalService.showOkCancel(
      'global.text.warning',
      this._plTranslateService.translate('modelo22.messages.avisoAnexos', {
        anexo: this._plTranslateService.translate(`declaracaoiva.tabs.${anexo}`)
      })
    );
    this.evaluateMenuAnexos(anexo, false);
    this.evaluateOperacoesDiferenteDaSede();
    this.evaluateSedeTotais();
  }

  public evaluateSedeTotais(): void {
    this.anexoRCalcTotImpFavSujPassivo(this.anexoSede);
    this.anexoRCalcTotImpFavEstado(this.anexoSede);
  }

  public evaluateMenuAnexos(anexo: ENavAnexosDecIvaPerio, value: boolean): void {
    switch (anexo) {
      case ENavAnexosDecIvaPerio.AnexoRContinente:
        this.hasAnexoRContinente = value;
        if (this.anexoSede !== anexo) {
          this.continente = value;
        }
        if (!value) {
          this.decIvaPeriodica.continente = this._initialDecIvaPeriodica.continente;
          this.decIvaPeriodica.continente = this._emptyDecIvaPeriodicaValoresLocal();
        }
        break;
      case ENavAnexosDecIvaPerio.AnexoRAcores:
        this.hasAnexoRAcores = value;
        if (this.anexoSede !== anexo) {
          this.acores = value;
        }
        if (!value) {
          this.decIvaPeriodica.acores = this._initialDecIvaPeriodica.acores;
          this.decIvaPeriodica.acores = this._emptyDecIvaPeriodicaValoresLocal();
        }
        break;
      case ENavAnexosDecIvaPerio.AnexoRMadeira:
        this.hasAnexoRMadeira = value;
        if (this.anexoSede !== anexo) {
          this.madeira = value;
        }
        if (!value) {
          this.decIvaPeriodica.madeira = this._initialDecIvaPeriodica.madeira;
          this.decIvaPeriodica.madeira = this._emptyDecIvaPeriodicaValoresLocal();
        }
        break;
      case ENavAnexosDecIvaPerio.AnexoCampo40:
        this.hasAnexoCampo40 = value;
        if (!value) {
          this.decIvaPeriodica.anexoCampo40 = this._initialDecIvaPeriodica.anexoCampo40;
          this.decIvaPeriodica.anexoCampo40 = this._emptyDecIvaPeriodicaAnexoCampo40();
        }
        break;
      case ENavAnexosDecIvaPerio.AnexoCampo41:
        this.hasAnexoCampo41 = value;
        if (!value) {
          this.decIvaPeriodica.anexoCampo41 = this._initialDecIvaPeriodica.anexoCampo41;
          this.decIvaPeriodica.anexoCampo41 = this._emptyDecIvaPeriodicaAnexoCampo41();
        }
        break;
      default:
        break;
    }
    if (value) {
      this.activeTab = anexo;
    } else if (this.activeTab === anexo) {
      this.activeTab = ENavAnexosDecIvaPerio.Rosto;
    }
    this._evaluateButtons();
  }

  public metodoPrazoDeclChanged(value: {inputValue: string; item: IMetodoSourcePrazoDecl}): void {
    if (isObject(value.item)) {
      this.decIvaPeriodica.dentroDoPrazo = value.item.valueMetodo;
      this.nomePrazoDecl = value.item.nameMetodo;
    }
  }

  public async openExtratoCampo(campoOficial: string, showColumnsBase: boolean = true): Promise<void> {
    const response: HttpResponse<Array<IJsonDecIvaPerioExtratoCampo>> = await this._declaracaoIvaService.getExtratoCampoDecPeriodica(
      this.decIvaPeriodica.dataDe,
      this.decIvaPeriodica.dataAte,
      campoOficial
    );
    const modalInstance = this._cgModalService.showVanilla(DeclaracaoIvaPeriodicaExtratoCampoModalComponent, {size: 'xxl'});
    const componentInstance: DeclaracaoIvaPeriodicaExtratoCampoModalComponent = modalInstance.componentInstance;
    componentInstance.extratoCampoList = response.body;
    componentInstance.campo = campoOficial;
    componentInstance.showColumnsBase = showColumnsBase;
  }

  public anexoRCalcCampo10(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }

    const btC12: number = isNumber(valoresLocal.baseTributavel12) ? valoresLocal.baseTributavel12 : 0;
    const btC14: number = isNumber(valoresLocal.baseTributavel14) ? valoresLocal.baseTributavel14 : 0;
    const btC15: number = isNumber(valoresLocal.baseTributavel15) ? valoresLocal.baseTributavel15 : 0;
    valoresLocal.baseTributavel10 = btC12 + btC14 + btC15;
    this.anexoRCalcTotBaseTrib(local);
  }

  public anexoRCalcCampo11(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }
    valoresLocal.impostoFavEstado11 = isNumber(valoresLocal.impostoFavEstado13) ? valoresLocal.impostoFavEstado13 : 0;
    this.anexoRCalcTotImpFavEstado(local);
    this.anexoRCalcTotImpFavEstado(this.anexoSede);
  }

  public anexoRCalcTotBaseTrib(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }

    const bt1: number = isNumber(valoresLocal.baseTributavel1) ? valoresLocal.baseTributavel1 : 0;
    const bt5: number = isNumber(valoresLocal.baseTributavel5) ? valoresLocal.baseTributavel5 : 0;
    const bt3: number = isNumber(valoresLocal.baseTributavel3) ? valoresLocal.baseTributavel3 : 0;
    const bt7: number = isNumber(valoresLocal.baseTributavel7) ? valoresLocal.baseTributavel7 : 0;
    const bt8: number = isNumber(valoresLocal.baseTributavel8) ? valoresLocal.baseTributavel8 : 0;
    const bt9: number = isNumber(valoresLocal.baseTributavel9) ? valoresLocal.baseTributavel9 : 0;
    const bt10: number = isNumber(valoresLocal.baseTributavel10) ? valoresLocal.baseTributavel10 : 0;
    const bt16: number = isNumber(valoresLocal.baseTributavel16) ? valoresLocal.baseTributavel16 : 0;
    const bt18: number = isNumber(valoresLocal.baseTributavel18) ? valoresLocal.baseTributavel18 : 0;

    valoresLocal.totalBaseTributavel = bt1 + bt5 + bt3 + bt7 + bt8 + bt9 + bt10 + bt16 + bt18;
  }

  public anexoRCalcTotImpFavSujPassivo(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }

    const ifsp20: number = isNumber(valoresLocal.impostoFavSujeitoPassivo20) ? valoresLocal.impostoFavSujeitoPassivo20 : 0;
    const ifsp21: number = isNumber(valoresLocal.impostoFavSujeitoPassivo21) ? valoresLocal.impostoFavSujeitoPassivo21 : 0;
    const ifsp23: number = isNumber(valoresLocal.impostoFavSujeitoPassivo23) ? valoresLocal.impostoFavSujeitoPassivo23 : 0;
    const ifsp22: number = isNumber(valoresLocal.impostoFavSujeitoPassivo22) ? valoresLocal.impostoFavSujeitoPassivo22 : 0;
    const ifsp24: number = isNumber(valoresLocal.impostoFavSujeitoPassivo24) ? valoresLocal.impostoFavSujeitoPassivo24 : 0;
    const ifsp40: number = isNumber(valoresLocal.impostoFavSujeitoPassivo40) ? valoresLocal.impostoFavSujeitoPassivo40 : 0;
    const ifsp61: number = isNumber(valoresLocal.impostoFavSujeitoPassivo61) ? valoresLocal.impostoFavSujeitoPassivo61 : 0;
    const ifsp65: number = isNumber(valoresLocal.impostoFavSujeitoPassivo65) ? valoresLocal.impostoFavSujeitoPassivo65 : 0;
    const ifsp67: number = isNumber(valoresLocal.impostoFavSujeitoPassivo67) ? valoresLocal.impostoFavSujeitoPassivo67 : 0;
    const ifsp81: number = isNumber(valoresLocal.impostoFavSujeitoPassivo81) ? valoresLocal.impostoFavSujeitoPassivo81 : 0;

    valoresLocal.totalImpostoFavSujeitoPassivo = ifsp20 + ifsp21 + ifsp23 + ifsp22 + ifsp24 + ifsp40 + ifsp61 + ifsp65 + ifsp67 + ifsp81;
    this._anexoOperacoesDiferenteDaSedeChanged(local);
    this.valorReembolsoSolicitado95Changed(valoresLocal.valorReembolsoSolicitado95, valoresLocal);
  }

  public anexoRCalcTotImpFavEstado(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }

    const ife2: number = isNumber(valoresLocal.impostoFavEstado2) ? valoresLocal.impostoFavEstado2 : 0;
    const ife6: number = isNumber(valoresLocal.impostoFavEstado6) ? valoresLocal.impostoFavEstado6 : 0;
    const ife4: number = isNumber(valoresLocal.impostoFavEstado4) ? valoresLocal.impostoFavEstado4 : 0;
    const ife11: number = isNumber(valoresLocal.impostoFavEstado11) ? valoresLocal.impostoFavEstado11 : 0;
    const ife17: number = isNumber(valoresLocal.impostoFavEstado17) ? valoresLocal.impostoFavEstado17 : 0;
    const ife41: number = isNumber(valoresLocal.impostoFavEstado41) ? valoresLocal.impostoFavEstado41 : 0;
    const ife66: number = isNumber(valoresLocal.impostoFavEstado66) ? valoresLocal.impostoFavEstado66 : 0;
    const ife68: number = isNumber(valoresLocal.impostoFavEstado68) ? valoresLocal.impostoFavEstado68 : 0;
    const ife19: number = isNumber(valoresLocal.impostoFavEstado19) ? valoresLocal.impostoFavEstado19 : 0;

    valoresLocal.totalImpostoFavEstado = ife2 + ife6 + ife4 + ife11 + ife17 + ife41 + ife66 + ife68 + ife19;
    this._anexoOperacoesDiferenteDaSedeChanged(local);
    this.valorReembolsoSolicitado95Changed(valoresLocal.valorReembolsoSolicitado95, valoresLocal);
  }

  public anexoRCalcTotQuadro6A(local: ENavAnexosDecIvaPerio): void {
    const valoresLocal: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(local);
    if (!valoresLocal) {
      return;
    }

    const bt97: number = isNumber(valoresLocal.baseTributavel97) ? valoresLocal.baseTributavel97 : 0;
    const bt98: number = isNumber(valoresLocal.baseTributavel98) ? valoresLocal.baseTributavel98 : 0;
    const bt99: number = isNumber(valoresLocal.baseTributavel99) ? valoresLocal.baseTributavel99 : 0;
    const bt100: number = isNumber(valoresLocal.baseTributavel100) ? valoresLocal.baseTributavel100 : 0;
    const bt101: number = isNumber(valoresLocal.baseTributavel101) ? valoresLocal.baseTributavel101 : 0;
    const bt102: number = isNumber(valoresLocal.baseTributavel102) ? valoresLocal.baseTributavel102 : 0;
    const bt103: number = isNumber(valoresLocal.baseTributavel103) ? valoresLocal.baseTributavel103 : 0;
    const bt104: number = isNumber(valoresLocal.baseTributavel104) ? valoresLocal.baseTributavel104 : 0;
    const bt105: number = isNumber(valoresLocal.baseTributavel105) ? valoresLocal.baseTributavel105 : 0;
    const bt107: number = isNumber(valoresLocal.baseTributavel107) ? valoresLocal.baseTributavel107 : 0;

    valoresLocal.totalQuadroA106 = bt97 + bt98 + bt99 + bt100 + bt101 + bt102 + bt103 + bt104 + bt105 + bt107;
  }

  public valorReembolsoSolicitado95Changed(valor95: number, anexoRLocal: IJsonDecIvaPeriodicaValoresLocal): void {
    valor95 = isNumber(valor95) ? valor95 : 0;
    if (valor95 > anexoRLocal.creditoDoImposto94) {
      return;
    }
    anexoRLocal.valorReembolsoSolicitado95 = valor95;
    if (round(anexoRLocal.totalImpostoFavEstado - anexoRLocal.totalImpostoFavSujeitoPassivo) >= 0) {
      anexoRLocal.impostoEntregarEstado93 = round(anexoRLocal.totalImpostoFavEstado - anexoRLocal.totalImpostoFavSujeitoPassivo);
      anexoRLocal.creditoDoImposto94 = 0; // Cred Impisto
      anexoRLocal.valorReembolsoSolicitado95 = 0; // reembolso
      anexoRLocal.excessoAReportar96 = 0; // Excesso a reportar
    } else {
      anexoRLocal.impostoEntregarEstado93 = 0;
      if (!anexoRLocal.totalImpostoFavEstado) {
        anexoRLocal.totalImpostoFavEstado = 0;
      }
      // anexoRLocal.creditoDoImposto94 = round(anexoRLocal.totalImpostoFavEstado - anexoRLocal.totalImpostoFavSujeitoPassivo) * -1;
      anexoRLocal.creditoDoImposto94 = round(anexoRLocal.totalImpostoFavSujeitoPassivo - anexoRLocal.totalImpostoFavEstado);
      if (anexoRLocal.valorReembolsoSolicitado95 > anexoRLocal.creditoDoImposto94) {
        anexoRLocal.valorReembolsoSolicitado95 = anexoRLocal.creditoDoImposto94;
      }
    }

    anexoRLocal.excessoAReportar96 = round(anexoRLocal.creditoDoImposto94 - anexoRLocal.valorReembolsoSolicitado95);

    this.showTooltipValorReembolsoSolicitado = false;
    if (anexoRLocal.valorReembolsoSolicitado95 !== 0 && !this.decIvaPeriodica.temRelacoesDeReembolso) {
      this._plAlertService.warning('declaracaoiva.anexos.anexoR.valorReembolsoSolicitado');
      this.showTooltipValorReembolsoSolicitado = true;
    }
  }

  public evaluateTotalAnexoCampo40(): void {
    const dadosSede: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(this.anexoSede);
    if (!dadosSede) {
      return;
    }
    const sumIvaCampo1: number = this.decIvaPeriodica.anexoCampo40.listaRegularizacoes.reduce((sum: number, linha: IJsonDecIvaPerioAnexoRegularizacoes) => sum + linha.ivaRegularizado, 0);
    const c4: number = isNumber(this.decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750IvaRegularizado) ? this.decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750IvaRegularizado : 0;
    const c4a: number = isNumber(this.decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26IvaRegularizado) ? this.decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26IvaRegularizado : 0;
    const c4b: number = isNumber(this.decIvaPeriodica.anexoCampo40.outrasRegularizacoesIvaRegularizado) ? this.decIvaPeriodica.anexoCampo40.outrasRegularizacoesIvaRegularizado : 0;

    this.decIvaPeriodica.anexoCampo40.valorTotalCampo40 = sumIvaCampo1 + c4 + c4a + c4b;
    dadosSede.impostoFavSujeitoPassivo40 = this.decIvaPeriodica.anexoCampo40.valorTotalCampo40;
  }

  public evaluateTotalAnexoCampo41(): void {
    const dadosSede: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(this.anexoSede);
    if (!dadosSede) {
      return;
    }
    const sumIvaCampo1: number = this.decIvaPeriodica.anexoCampo41.listaRegularizacoes.reduce((sum: number, linha: IJsonDecIvaPerioAnexoRegularizacoes) => sum + linha.ivaRegularizado, 0);
    const c4a: number = isNumber(this.decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26IvaRegularizado) ? this.decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26IvaRegularizado : 0;
    const c4b: number = isNumber(this.decIvaPeriodica.anexoCampo41.outrasRegularizacoesIvaRegularizado) ? this.decIvaPeriodica.anexoCampo41.outrasRegularizacoesIvaRegularizado : 0;
    const c4c: number = isNumber(this.decIvaPeriodica.anexoCampo41.artigo6IvaRegularizado) ? this.decIvaPeriodica.anexoCampo41.artigo6IvaRegularizado : 0;
    const c4d: number = isNumber(this.decIvaPeriodica.anexoCampo41.decretoLei192017IvaRegularizado) ? this.decIvaPeriodica.anexoCampo41.decretoLei192017IvaRegularizado : 0;

    this.decIvaPeriodica.anexoCampo41.valorTotalCampo41 = sumIvaCampo1 + c4a + c4b + c4c + c4d;
    dadosSede.impostoFavEstado41 = this.decIvaPeriodica.anexoCampo41.valorTotalCampo41;
  }

  public evaluateOperacoesDiferenteDaSede(): void {
    const dadosSede: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(this.anexoSede);
    const dadosAnexoContinente: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRContinente);
    const dadosAnexoAcores: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRAcores);
    const dadosAnexoMadeira: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRMadeira);

    // clean the sede anexo to avoid errors when changing or deleting anexos
    this._applyOperacoesDiferenteDaSede(dadosSede, this._emptyDecIvaPeriodicaValoresLocal(), this._emptyDecIvaPeriodicaValoresLocal());

    // has all the anexos (Continente, Acores, Madeira)
    if (this._hasAllAnexos()) {
      switch (this.anexoSede) {
        case ENavAnexosDecIvaPerio.AnexoRContinente:
          this._applyOperacoesDiferenteDaSede(dadosSede, dadosAnexoAcores, dadosAnexoMadeira);
          break;
        case ENavAnexosDecIvaPerio.AnexoRAcores:
          this._applyOperacoesDiferenteDaSede(dadosSede, dadosAnexoContinente, dadosAnexoMadeira);
          break;
        case ENavAnexosDecIvaPerio.AnexoRMadeira:
          this._applyOperacoesDiferenteDaSede(dadosSede, dadosAnexoContinente, dadosAnexoAcores);
          break;
        default:
          return;
      }
    }

    // does not have all the anexos (Continente, Acores, Madeira)
    if (this._hasMoreThenXAnexos(1) && !this._hasAllAnexos()) {
      this._applyOperacoesDiferenteDaSede(dadosSede, this._getDataAnexoNotSedeAndIncluded().local);
    }

    // evaluate the sede totais
    this.evaluateSedeTotais();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _applyDecIvaPeriodica(isInit: boolean = false): void {
    const decIvaPer: IJsonDecIvaPeriodica = cloneDeep(this.decIvaPeriodica);
    this.decIvaPeriodica = merge(
      {
        nifEmpresa: '',
        nomeEmpresa: '',
        nifTOC: '',
        nomeTOC: '',
        periodo: '',
        dentroDoPrazo: false,
        localizacaoOperacoes: [],
        dpIVArbLocSede: EAreasRegionais.Continente,
        actImobiliarias: false,
        inexistenciaOperacoesNoPeriodo: false,
        transaccoesIntraComunitarias: false,
        houveOperacoesDestaNatureza: false,
        existePaginaDeclaracaoIVA: false,
        continente: this._emptyDecIvaPeriodicaValoresLocal(),
        acores: this._emptyDecIvaPeriodicaValoresLocal(),
        madeira: this._emptyDecIvaPeriodicaValoresLocal(),
        anexoCampo40: this._emptyDecIvaPeriodicaAnexoCampo40(),
        anexoCampo41: this._emptyDecIvaPeriodicaAnexoCampo41()
      },
      decIvaPer
    );
    if (isInit) {
      this._initialDecIvaPeriodica = this._normalizeDecIvaPeriodica(this.decIvaPeriodica);
      this.hasAnexoRContinente = this.decIvaPeriodica.dpIVArbLocSede === EAreasRegionais.Continente || this._checkHasAnexoR(this.decIvaPeriodica.continente);
      this.hasAnexoRAcores = this.decIvaPeriodica.dpIVArbLocSede === EAreasRegionais.Acores || this._checkHasAnexoR(this.decIvaPeriodica.acores);
      this.hasAnexoRMadeira = this.decIvaPeriodica.dpIVArbLocSede === EAreasRegionais.Madeira || this._checkHasAnexoR(this.decIvaPeriodica.madeira);
      this.hasAnexoCampo40 = this._checkHasAnexo40();
      this.hasAnexoCampo41 = this._checkHasAnexo41();
      this.activeTab = ENavAnexosDecIvaPerio.Rosto;
    }

    this.continente = false;
    this.acores = false;
    this.madeira = false;
    for (const localizacaoOperacao of this.decIvaPeriodica.localizacaoOperacoes) {
      if (localizacaoOperacao === EAreasRegionais.Continente) {
        this.continente = true;
      }
      if (localizacaoOperacao === EAreasRegionais.Acores) {
        this.acores = true;
      }
      if (localizacaoOperacao === EAreasRegionais.Madeira) {
        this.madeira = true;
      }
    }

    this.nomePrazoDecl = this.decIvaPeriodica.dentroDoPrazo
      ? this._plTranslateService.translate('declaracaoiva.anexos.rosto.quadro0.dentroprazo')
      : this._plTranslateService.translate('declaracaoiva.anexos.rosto.quadro0.foraprazo');

    this._evaluateButtons();
    this._evaluateListaNIFsROC();
    this._applyDataSourceCampos4041();
  }

  private _emptyDecIvaPeriodicaValoresLocal(): IJsonDecIvaPeriodicaValoresLocal {
    return {
      impostoFavSujeitoPassivo81: undefined,
      impostoFavSujeitoPassivo22: undefined,
      impostoFavSujeitoPassivo23: undefined,
      impostoFavEstado68: undefined,
      impostoFavSujeitoPassivo20: undefined,
      baseTributavel8: undefined,
      impostoFavSujeitoPassivo21: undefined,
      baseTributavel9: undefined,
      baseTributavel10: undefined,
      impostoFavEstado11: undefined,
      baseTributavel12: undefined,
      baseTributavel14: undefined,
      baseTributavel15: undefined,
      baseTributavel16: undefined,
      baseTributavel18: undefined,
      impostoFavSujeitoPassivo24: undefined,
      impostoFavSujeitoPassivo61: undefined,
      impostoFavSujeitoPassivo40: undefined,
      impostoFavSujeitoPassivo67: undefined,
      baseTributavel3: undefined,
      totalBaseTributavel: undefined,
      impostoFavEstado13: undefined,
      impostoFavEstado17: undefined,
      impostoFavEstado19: undefined,
      totalImpostoFavSujeitoPassivo: undefined,
      impostoFavSujeitoPassivo65: undefined,
      baseTributavel1: undefined,
      impostoFavEstado66: undefined,
      impostoFavEstado41: undefined,
      impostoFavEstado2: undefined,
      baseTributavel7: undefined,
      totalImpostoFavEstado: undefined,
      baseTributavel5: undefined,
      impostoFavEstado6: undefined,
      impostoFavEstado4: undefined,
      baseTributavel97: undefined,
      baseTributavel98: undefined,
      baseTributavel99: undefined,
      baseTributavel100: undefined,
      baseTributavel101: undefined,
      baseTributavel102: undefined,
      baseTributavel103: undefined,
      baseTributavel104: undefined,
      baseTributavel105: undefined,
      baseTributavel107: undefined,
      efectouOperacoesRevCharge: undefined,
      impostoEntregarEstado93: undefined,
      creditoDoImposto94: undefined,
      valorReembolsoSolicitado95: undefined,
      excessoAReportar96: undefined,
      localizacao: undefined,
      totalQuadroA106: undefined
    };
  }

  private _emptyDecIvaPeriodicaAnexoCampo40(): IJsonDecIvaPeriodicaAnexoCampo40 {
    return {
      outrasRegularizacoesIvaRegularizado: undefined,
      regCujoCreditoNaoSejaSupA750IvaRegularizado: undefined,
      regAbrangidasArtigos23A26BaseIncidencia: undefined,
      outrasRegularizacoesBaseIncidencia: undefined,
      regCujoCreditoNaoSejaSupA750BaseIncidencia: undefined,
      regAbrangidasArtigos23A26IvaRegularizado: undefined,
      valorTotalCampo40: undefined,
      listaNIFsROC: [],
      listaRegularizacoes: []
    };
  }

  private _emptyDecIvaPeriodicaAnexoCampo41(): IJsonDecIvaPeriodicaAnexoCampo41 {
    return {
      outrasRegularizacoesIvaRegularizado: undefined,
      regAbrangidasPelosArt23a26BaseIncidencia: undefined,
      outrasRegularizacoesBaseIncidencia: undefined,
      regAbrangidasPelosArt23a26IvaRegularizado: undefined,
      artigo6BaseIncidencia: undefined,
      decretoLei192017BaseIncidencia: undefined,
      artigo6IvaRegularizado: undefined,
      decretoLei192017IvaRegularizado: undefined,
      valorTotalCampo41: undefined,
      listaRegularizacoes: []
    };
  }

  private _normalizeDecIvaPeriodica(decIvaPeriodica: IJsonDecIvaPeriodica): IJsonDecIvaPeriodica {
    function normalizeValue(value: unknown): unknown {
      if (value === 0) {
        return undefined;
      } else if (isObject(value)) {
        for (const key of Object.keys(value)) {
          const objValue: unknown = value[key];
          value[key] = normalizeValue(objValue);
        }
      } else if (isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const arrayValue: unknown = value[i];
          value[i] = normalizeValue(arrayValue);
        }
      }
      return value;
    }

    const normalizedDecIvaPeriodica: IJsonDecIvaPeriodica = copy(decIvaPeriodica);
    normalizeValue(normalizedDecIvaPeriodica);
    return normalizedDecIvaPeriodica;
  }

  private _checkHasAnexoR(anexoR: IJsonDecIvaPeriodicaValoresLocal): boolean {
    return (
      isNumber(anexoR.impostoFavSujeitoPassivo81) ||
      isNumber(anexoR.impostoFavSujeitoPassivo22) ||
      isNumber(anexoR.impostoFavSujeitoPassivo23) ||
      isNumber(anexoR.impostoFavEstado68) ||
      isNumber(anexoR.impostoFavSujeitoPassivo20) ||
      isNumber(anexoR.baseTributavel8) ||
      isNumber(anexoR.impostoFavSujeitoPassivo21) ||
      isNumber(anexoR.baseTributavel9) ||
      isNumber(anexoR.baseTributavel10) ||
      isNumber(anexoR.impostoFavEstado11) ||
      isNumber(anexoR.baseTributavel12) ||
      isNumber(anexoR.baseTributavel14) ||
      isNumber(anexoR.baseTributavel15) ||
      isNumber(anexoR.baseTributavel16) ||
      isNumber(anexoR.baseTributavel18) ||
      isNumber(anexoR.impostoFavSujeitoPassivo24) ||
      isNumber(anexoR.impostoFavSujeitoPassivo61) ||
      isNumber(anexoR.impostoFavSujeitoPassivo40) ||
      isNumber(anexoR.impostoFavSujeitoPassivo67) ||
      isNumber(anexoR.baseTributavel3) ||
      isNumber(anexoR.totalBaseTributavel) ||
      isNumber(anexoR.impostoFavEstado13) ||
      isNumber(anexoR.impostoFavEstado17) ||
      isNumber(anexoR.impostoFavEstado19) ||
      isNumber(anexoR.totalImpostoFavSujeitoPassivo) ||
      isNumber(anexoR.impostoFavSujeitoPassivo65) ||
      isNumber(anexoR.baseTributavel1) ||
      isNumber(anexoR.impostoFavEstado66) ||
      isNumber(anexoR.impostoFavEstado41) ||
      isNumber(anexoR.impostoFavEstado2) ||
      isNumber(anexoR.baseTributavel7) ||
      isNumber(anexoR.totalImpostoFavEstado) ||
      isNumber(anexoR.baseTributavel5) ||
      isNumber(anexoR.impostoFavEstado6) ||
      isNumber(anexoR.impostoFavEstado4) ||
      isNumber(anexoR.baseTributavel97) ||
      isNumber(anexoR.baseTributavel98) ||
      isNumber(anexoR.baseTributavel99) ||
      isNumber(anexoR.baseTributavel100) ||
      isNumber(anexoR.baseTributavel101) ||
      isNumber(anexoR.baseTributavel102) ||
      isNumber(anexoR.baseTributavel103) ||
      isNumber(anexoR.baseTributavel104) ||
      isNumber(anexoR.baseTributavel105) ||
      isNumber(anexoR.baseTributavel107) ||
      isNumber(anexoR.impostoEntregarEstado93) ||
      isNumber(anexoR.creditoDoImposto94) ||
      isNumber(anexoR.valorReembolsoSolicitado95) ||
      isNumber(anexoR.excessoAReportar96)
    );
  }

  private _checkHasAnexo40(): boolean {
    return (
      isNumber(this.decIvaPeriodica.anexoCampo40.outrasRegularizacoesIvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750IvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26BaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.outrasRegularizacoesBaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750BaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26IvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo40.valorTotalCampo40) ||
      this.decIvaPeriodica.anexoCampo40.listaNIFsROC.length > 0 ||
      this.decIvaPeriodica.anexoCampo40.listaRegularizacoes.length > 0
    );
  }

  private _checkHasAnexo41(): boolean {
    return (
      this.decIvaPeriodica.anexoCampo41.listaRegularizacoes.length > 0 ||
      isNumber(this.decIvaPeriodica.anexoCampo41.outrasRegularizacoesIvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26BaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.outrasRegularizacoesBaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26IvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.artigo6BaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.decretoLei192017BaseIncidencia) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.artigo6IvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.decretoLei192017IvaRegularizado) ||
      isNumber(this.decIvaPeriodica.anexoCampo41.valorTotalCampo41)
    );
  }

  private _anularDeclaracao(): Promise<void> {
    this.promise = this._declaracaoIvaService.anularDeclaracao(this._periodo).then(() => {
      return this._declaracaoIvaService.loadDeclaracaoIVAPerio(this._periodo).then((response: HttpResponse<IJsonDecIvaPeriodica>) => {
        this.decIvaPeriodica = response.body;
        return this._loadPeriodos().finally(() => {
          for (let i = this._mnuPeriodos.menu.length - 1; i >= 0; i--) {
            this._mnuPeriodos.menu[i].active = false;
            if (this._mnuPeriodos.menu[i].data.periodo === this._periodo) {
              this._mnuPeriodos.menu[i].active = true;
              this._mnuPeriodos.caption = this._mnuPeriodos.menu[i].caption;
              this._periodo = this._mnuPeriodos.menu[i].data.periodo;
              break;
            }
          }
          this._applyDecIvaPeriodica(true);
          this._plAlertService.success('declaracaoiva.messages.anuladasuccess');
        });
      });
    });
    return this.promise;
  }

  private _criarFicheiro(): Promise<void> {
    return this._declaracaoIvaService.getXml(this._periodo).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  private _decIvaPeriodicaHasChanges(decIvaPeriodica: IJsonDecIvaPeriodica): boolean {
    const normalizedDecIvaPeriodica: IJsonDecIvaPeriodica = this._normalizeDecIvaPeriodica(decIvaPeriodica);
    return !isEqual(normalizedDecIvaPeriodica, this._initialDecIvaPeriodica);
  }

  private _nifsRocsHasChanges(): boolean {
    const nifsA: Array<string> = this.nifsROC.map((item: INumeroIdentificacaoROC) => {
      return item.nif;
    });
    const nifsB: Array<string> = this.decIvaPeriodica.anexoCampo40.listaNIFsROC;
    return !isEqual(nifsA, nifsB);
  }

  private async _saveDecIvaPeriodica(): Promise<void> {
    // validate apenas se declaração tiver mais anexos do que apenas a sede
    if (this._hasMoreThenXAnexos(1)) {
      // validate se os valores dos campos dos anexos coincidem com os valores do anexo da sede
      const operacoesSedeValoresIguais: IDecIvaPerioOperacoesIguaisSede = this._validateOperacoesSedeValoresIguais();
      if (!operacoesSedeValoresIguais.isValid) {
        this._plAlertService.warning(
          this._translateService.instant('declaracaoiva.messages.validateOperacoesSedeValoresIguais', {
            nomeAnexo: operacoesSedeValoresIguais.nomeAnexo,
            campo1: operacoesSedeValoresIguais.campo1,
            campo2: operacoesSedeValoresIguais.campo2
          })
        );
        return Promise.resolve();
      }
    }

    this.decIvaPeriodica.localizacaoOperacoes = [];
    if (this.continente) {
      this.decIvaPeriodica.localizacaoOperacoes.push(EAreasRegionais.Continente);
    }
    if (this.acores) {
      this.decIvaPeriodica.localizacaoOperacoes.push(EAreasRegionais.Acores);
    }
    if (this.madeira) {
      this.decIvaPeriodica.localizacaoOperacoes.push(EAreasRegionais.Madeira);
    }
    const hasChanges: boolean = this._decIvaPeriodicaHasChanges(this.decIvaPeriodica);
    if (!hasChanges && this.decIvaPeriodica.existePaginaDeclaracaoIVA && !this._nifsRocsHasChanges()) {
      this._plAlertService.success('declaracaoiva.messages.savedsucessfully');
      return Promise.resolve();
    }
    this._convertRocForSaving();
    if (this._dataGridInstanceROC) {
      await this._dataGridInstanceROC.refresh();
    }
    this.promise = this._declaracaoIvaService.postSaveDeclaracaoIvaPerio(this.decIvaPeriodica, this._periodo).then((response: HttpResponse<IJsonDecIvaPeriodica>) => {
      this._plAlertService.success('declaracaoiva.messages.savedsucessfully');
      this.decIvaPeriodica = response.body;
      return this._loadPeriodos().finally(() => {
        for (let i = this._mnuPeriodos.menu.length - 1; i >= 0; i--) {
          this._mnuPeriodos.menu[i].active = false;
          if (this._mnuPeriodos.menu[i].data.periodo === this._periodo) {
            this._mnuPeriodos.menu[i].active = true;
            this._mnuPeriodos.caption = this._mnuPeriodos.menu[i].caption;
            this._periodo = this._mnuPeriodos.menu[i].data.periodo;
          }
        }
        this._applyDecIvaPeriodica(true);
        this.activeTab = ENavAnexosDecIvaPerio.Rosto;
      });
    });
    return this.promise;
  }

  private _convertRocForSaving(): void {
    this.decIvaPeriodica.anexoCampo40.listaNIFsROC = this.nifsROC.map((item: INumeroIdentificacaoROC) => {
      return item.nif;
    });
  }

  private _callConfigWs(): Promise<void> {
    return this._cgModalService.show(ContabilidadeDeclaracaoivaConfigModalComponent);
  }

  private _validarNaAT(): Promise<void> {
    return this._declaracaoIvaService.validarNaAT(this._periodo).then(() => {
      this._plAlertService.success('declaracaoiva.messages.successValidarNaAt');
    });
  }

  private _submeterNaAT(): Promise<void> {
    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'declaracaoiva.messages.submitAtPromptMessage', {
        size: 'md',
        btnCancelText: 'global.btn.no',
        btnOkText: 'global.btn.yes',
        backdrop: 'static',
        keyboard: false
      })
      .then(() =>
        this._declaracaoIvaService.submeterNaAT(this._periodo).then(() =>
          this._cgModalService.showOkCancel('global.text.attention', 'declaracaoiva.messages.successSubmeterNaAt', {
            size: 'md',
            showCancelBtn: false,
            backdrop: 'static',
            keyboard: false,
            showCloseBtn: false
          })
        )
      );
  }

  private async _loadPeriodos(): Promise<void> {
    this._periodosIVA = [];
    this._mnuPeriodos.menu = [];
    const response: HttpResponse<IApiQueryResponse<IJsonPeriodoDecIva>> = await this._declaracaoIvaService.getPeriodosIVA();
    this._periodosIVA = response.body.list;
    for (const periodoIVA of this._periodosIVA) {
      if (periodoIVA) {
        let strGravado = '';
        if (periodoIVA.comDecGravada) {
          strGravado = ` (${<string>this._translateService.instant('declaracaoiva.text.saved')})`;
        }

        this._mnuPeriodos.menu.push({
          id: periodoIVA.periodo,
          caption: `${periodoIVA.periodo} - ${periodoIVA.anoCivil} - ${periodoIVA.nome.split('-')[0]} ${strGravado}`,
          data: periodoIVA,
          active: periodoIVA.periodo === this._periodo,
          click: (menu: IPlToolbarMenuItem<IJsonPeriodoDecIva>) => this._mudaPeriodo(menu)
        });

        if (periodoIVA.periodo === this._periodo) {
          this._mnuPeriodos.caption = `${periodoIVA.periodo} - ${periodoIVA.anoCivil} - ${periodoIVA.nome.split('-')[0]} ${strGravado}`;
        }
      }
    }
  }

  private async _mudaPeriodo(menu: IPlToolbarMenuItem<IJsonPeriodoDecIva>): Promise<void> {
    if (!menu || menu.data.periodo === this._periodo) {
      return;
    }
    const index: number = this._mnuPeriodos.menu.findIndex((item: IPlToolbarMenuItem<IJsonPeriodoDecIva>) => item.id === this._periodo);
    if (index !== -1) {
      this._mnuPeriodos.menu[index].active = false;
    }
    this._mnuPeriodos.caption = menu.caption;
    this._periodo = menu.data.periodo;
    menu.active = true;
    this._evaluateButtons();
    await this._periodoChanged();
  }

  private _periodoChanged(): Promise<void> {
    this.promise = this._declaracaoIvaService.loadDeclaracaoIVAPerio(this._periodo).then((response: HttpResponse<IJsonDecIvaPeriodica>) => {
      this.decIvaPeriodica = response.body;
      this._applyDecIvaPeriodica(true);
    });
    return this.promise;
  }

  private _evaluateButtons(): void {
    this._btnCriarFicheiro.disabled = !this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnCriarFicheiro.tooltip.disabled = this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnValidaAt.disabled = !this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnValidaAt.tooltip.disabled = this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnPreVisualizar.disabled = !this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnPreVisualizar.tooltip.disabled = this.decIvaPeriodica.existePaginaDeclaracaoIVA;
    this._btnAnular.disabled = !this.decIvaPeriodica.existePaginaDeclaracaoIVA;
  }

  private _preVisualizarDeclaracao(): void {
    const tiposAnexo: Array<IDataSourceItem<ETipoAnexo>> = DATA_SOURCE_TIPOS_ANEXO.data.map((tipoAnexo: IDataSourceItem<ETipoAnexo>) => {
      return {...tipoAnexo, name: this._translateService.instant(tipoAnexo.name)};
    });
    const index: number = tiposAnexo.findIndex((value: IDataSourceItem<ETipoAnexo>) => value.value === NUMBER_3);
    if (index !== -1) {
      tiposAnexo.splice(index, 1);
    }
    const modalInstance = this._cgModalService.showVanilla(DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent);
    const componentInstance: DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent = modalInstance.componentInstance;
    componentInstance.periodo = this._periodo;
    componentInstance.tiposAnexo = tiposAnexo;
  }

  private _openBalancetesModule(): void {
    this._maintenanceInstanceBalancete.maintenance({params: {dePeriodo: this.decIvaPeriodica.doPeriodo, periodoAte: this.decIvaPeriodica.atePeriodo}}).then(() => undefined);
  }

  private _openDocumentosFiscaisModule(): Promise<void> {
    return this._maintenanceInstanceDocsFiscais.maintenance({modalOptions: {size: 'xxl'}});
  }

  private _applyDataSourceCampos4041(): void {
    this.dataGridDefinitionCampo40.dataSource = new ArrayStore({
      data: orderBy(this.decIvaPeriodica.anexoCampo40.listaRegularizacoes, 'tipoRegularizacaoCampo40')
    });
    this.dataGridDefinitionCampo41.dataSource = new ArrayStore({
      data: orderBy(this.decIvaPeriodica.anexoCampo41.listaRegularizacoes, 'tipoRegularizacaoCampo41')
    });
  }

  private _validateRoc(options: TDeclaracaoDeIvaRocValidationCallbackData): boolean {
    return !this.nifsROC.find((item: INumeroIdentificacaoROC) => item.nif === options.data.nif && item.id !== options.data.id);
  }

  private _evaluateListaNIFsROC(): void {
    let rocId = 0;
    this.nifsROC = this.decIvaPeriodica.anexoCampo40.listaNIFsROC.map((nif: string) => {
      return {
        id: rocId++,
        nif: nif
      };
    });
  }

  private _checkSedeForAnexo(): void {
    switch (this.decIvaPeriodica.dpIVArbLocSede) {
      case EAreasRegionais.Continente:
        this.anexoSede = ENavAnexosDecIvaPerio.AnexoRContinente;
        break;
      case EAreasRegionais.Acores:
        this.anexoSede = ENavAnexosDecIvaPerio.AnexoRAcores;
        break;
      case EAreasRegionais.Madeira:
        this.anexoSede = ENavAnexosDecIvaPerio.AnexoRMadeira;
        break;
    }
  }

  private _anexoOperacoesDiferenteDaSedeChanged(local: ENavAnexosDecIvaPerio): void {
    if (local !== this.anexoSede) {
      this.evaluateOperacoesDiferenteDaSede();
    }
  }

  private _applyOperacoesDiferenteDaSede(dadosSede: IJsonDecIvaPeriodicaValoresLocal, valoresAnexo1: IJsonDecIvaPeriodicaValoresLocal, valoresAnexo2?: IJsonDecIvaPeriodicaValoresLocal): void {
    dadosSede.impostoFavSujeitoPassivo65 = valoresAnexo1.totalImpostoFavSujeitoPassivo;
    dadosSede.impostoFavEstado66 = valoresAnexo1.totalImpostoFavEstado;
    if (valoresAnexo2) {
      dadosSede.impostoFavSujeitoPassivo67 = valoresAnexo2.totalImpostoFavSujeitoPassivo;
      dadosSede.impostoFavEstado68 = valoresAnexo2.totalImpostoFavEstado;
    }
  }

  private _validateOperacoesSedeValoresIguaisIndividual(
    dadosSede: IJsonDecIvaPeriodicaValoresLocal,
    valoresAnexo1: IJsonDecIvaPeriodicaValoresLocal,
    valoresAnexo2?: IJsonDecIvaPeriodicaValoresLocal
  ): IDecIvaPerioOperacoesIguaisSedeIndividual {
    if (dadosSede.impostoFavSujeitoPassivo65 !== valoresAnexo1.totalImpostoFavSujeitoPassivo || dadosSede.impostoFavEstado66 !== valoresAnexo1.totalImpostoFavEstado) {
      return {isValid: false, anexo1Invalid: true};
    }
    if (valoresAnexo2) {
      if (dadosSede.impostoFavSujeitoPassivo67 !== valoresAnexo2.totalImpostoFavSujeitoPassivo || dadosSede.impostoFavEstado68 !== valoresAnexo2.totalImpostoFavEstado) {
        return {isValid: false, anexo2Invalid: true};
      }
    }
    return {isValid: true};
  }

  private _hasAllAnexos(): boolean {
    return this.hasAnexoRContinente && this.hasAnexoRAcores && this.hasAnexoRMadeira;
  }

  private _hasMoreThenXAnexos(numeroAnexos: number): boolean {
    const countAnexos: number = toNumber(this.hasAnexoRContinente) + toNumber(this.hasAnexoRAcores) + toNumber(this.hasAnexoRMadeira);
    return countAnexos > numeroAnexos;
  }

  private _getDataFromAnexo(localAnexo: ENavAnexosDecIvaPerio): IJsonDecIvaPeriodicaValoresLocal {
    switch (localAnexo) {
      case ENavAnexosDecIvaPerio.AnexoRContinente:
        return this.decIvaPeriodica.continente;
      case ENavAnexosDecIvaPerio.AnexoRAcores:
        return this.decIvaPeriodica.acores;
      case ENavAnexosDecIvaPerio.AnexoRMadeira:
        return this.decIvaPeriodica.madeira;
      default:
        return undefined;
    }
  }

  private _getDataAnexoNotSedeAndIncluded(): IDecIvaPerioOperacoesIguaisSedeValoresLocal {
    const arrayAnexos: Array<ENavAnexosDecIvaPerio> = [ENavAnexosDecIvaPerio.AnexoRContinente, ENavAnexosDecIvaPerio.AnexoRMadeira, ENavAnexosDecIvaPerio.AnexoRAcores];
    // remove anexo sede
    const indexSede: number = arrayAnexos.findIndex((anexoTipo: ENavAnexosDecIvaPerio) => anexoTipo === this.anexoSede);
    if (indexSede !== -1) {
      arrayAnexos.splice(indexSede, 1);
    }

    // remove other anexos not included
    if (!this.hasAnexoRContinente) {
      const indexContinente: number = arrayAnexos.findIndex((anexoTipo: ENavAnexosDecIvaPerio) => anexoTipo === ENavAnexosDecIvaPerio.AnexoRContinente);
      if (indexContinente !== -1) {
        arrayAnexos.splice(indexContinente, 1);
      }
    }

    if (!this.hasAnexoRAcores) {
      const indexAcores: number = arrayAnexos.findIndex((anexoTipo: ENavAnexosDecIvaPerio) => anexoTipo === ENavAnexosDecIvaPerio.AnexoRAcores);
      if (indexAcores !== -1) {
        arrayAnexos.splice(indexAcores, 1);
      }
    }

    if (!this.hasAnexoRMadeira) {
      const indexMadeira: number = arrayAnexos.findIndex((anexoTipo: ENavAnexosDecIvaPerio) => anexoTipo === ENavAnexosDecIvaPerio.AnexoRMadeira);
      if (indexMadeira !== -1) {
        arrayAnexos.splice(indexMadeira, 1);
      }
    }
    return {local: this._getDataFromAnexo(arrayAnexos[0]), nomeAnexo: arrayAnexos[0]};
  }

  private _validateOperacoesSedeValoresIguais(): IDecIvaPerioOperacoesIguaisSede {
    let resultInd: IDecIvaPerioOperacoesIguaisSedeIndividual = {isValid: true};
    const result: IDecIvaPerioOperacoesIguaisSede = {isValid: false, nomeAnexo: undefined, campo1: undefined, campo2: undefined};
    const dadosSede: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(this.anexoSede);
    const dadosAnexoContinente: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRContinente);
    const dadosAnexoAcores: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRAcores);
    const dadosAnexoMadeira: IJsonDecIvaPeriodicaValoresLocal = this._getDataFromAnexo(ENavAnexosDecIvaPerio.AnexoRMadeira);

    // has all the anexos (Continente, Acores, Madeira)
    if (this._hasAllAnexos()) {
      switch (this.anexoSede) {
        case ENavAnexosDecIvaPerio.AnexoRContinente:
          resultInd = this._validateOperacoesSedeValoresIguaisIndividual(dadosSede, dadosAnexoAcores, dadosAnexoMadeira);
          if (!resultInd.isValid) {
            if (resultInd.anexo1Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRAcores');
              result.campo1 = '65';
              result.campo2 = '66';
            } else if (resultInd.anexo2Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRMadeira');
              result.campo1 = '67';
              result.campo2 = '68';
            }
            return {...result, nomeAnexo: lowercase(result.nomeAnexo)};
          }
          break;
        case ENavAnexosDecIvaPerio.AnexoRAcores:
          resultInd = this._validateOperacoesSedeValoresIguaisIndividual(dadosSede, dadosAnexoContinente, dadosAnexoMadeira);
          if (!resultInd.isValid) {
            if (resultInd.anexo1Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRContinente');
              result.campo1 = '65';
              result.campo2 = '66';
            } else if (resultInd.anexo2Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRMadeira');
              result.campo1 = '67';
              result.campo2 = '68';
            }
            return {...result, nomeAnexo: lowercase(result.nomeAnexo)};
          }
          break;
        case ENavAnexosDecIvaPerio.AnexoRMadeira:
          resultInd = this._validateOperacoesSedeValoresIguaisIndividual(dadosSede, dadosAnexoContinente, dadosAnexoAcores);
          if (!resultInd.isValid) {
            if (resultInd.anexo1Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRContinente');
              result.campo1 = '65';
              result.campo2 = '66';
            } else if (resultInd.anexo2Invalid) {
              result.nomeAnexo = this._translateService.instant('declaracaoiva.tabs.anexoRAcores');
              result.campo1 = '67';
              result.campo2 = '68';
            }
            return {...result, nomeAnexo: lowercase(result.nomeAnexo)};
          }
          break;
        default:
          return result;
      }
    }

    // does not have all the anexos (Continente, Acores, Madeira)
    if (!this._hasAllAnexos()) {
      const anexoNotSedeAndIncluded: IDecIvaPerioOperacoesIguaisSedeValoresLocal = this._getDataAnexoNotSedeAndIncluded();
      resultInd = this._validateOperacoesSedeValoresIguaisIndividual(dadosSede, anexoNotSedeAndIncluded.local);
      if (!resultInd.isValid) {
        if (resultInd.anexo1Invalid) {
          result.nomeAnexo = this._translateService.instant(anexoNotSedeAndIncluded.nomeAnexo);
          result.campo1 = '65';
          result.campo2 = '66';
        }
        return {...result, nomeAnexo: lowercase(result.nomeAnexo)};
      }
    }

    result.isValid = true;
    return result;
  }

  private _buildToolbar(): void {
    const btnRefresh: IPlToolbarMenuItem = {
      id: 'refresh',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      caption: 'entity.action.refresh',
      visible: true,
      click: () => this._loadDeclaracaoIVAPerio(true)
    };
    this.btnSave.visible = true;
    this.btnSave.type = 'dropdown-split';
    this.btnSave.order = this._mnuPeriodos.order + 1;
    this.btnSave.click = () => this._saveDecIvaPeriodica();
    this.btnSave.menu = [btnRefresh, this._btnAnular];

    this.toolbar.addButton(this._btnAtalhos);
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    this._btnValidaAt.type = isMobile ? 'button' : 'dropdown-split';
    if (!isMobile) {
      this._btnValidaAt.menu = [this._btnSumbmitAt, this._btnConfigWS];

      this._btnPreVisualizar.class = 'btn-success';
      this._btnCriarFicheiro.class = this._btnValidaAt.class = 'btn-primary';
      this.toolbar.addButton(this._btnCriarFicheiro).addButton(this._btnPreVisualizar).addButton(this._btnValidaAt);
    } else {
      this._btnValidaAt.menu = [];
      this.dropdownActions.menu = [this._btnAnular, this._btnCriarFicheiro, this._btnPreVisualizar, this._btnValidaAt];
      this._btnCriarFicheiro.class = this._btnPreVisualizar.class = this._btnValidaAt.class = undefined;
      this.toolbar.addButton(this.dropdownActions);
    }
  }

  private async _loadDeclaracaoIVAPerio(loadWithService: boolean): Promise<void> {
    if (loadWithService) {
      this.decIvaPeriodica = await this._declaracaoIvaService.loadDeclaracaoIVAPerio(this._periodo).then((response: HttpResponse<IJsonDecIvaPeriodica>) => response.body);
    }
    this._applyDecIvaPeriodica(true);
    this.activeTab = ENavAnexosDecIvaPerio.Rosto;
    this._checkSedeForAnexo();
  }
}
