import {IJsonApuramento, IJsonDocsContasInventario, IJsonVariaveisApuramento} from './jsonApuramentoResultados.module.interface';
import {TPlTableItem} from 'pl-comps-angular';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';

export type TPlTableItemVariaveis = TPlTableItem<IJsonVariaveisApuramento> & {_variaveis: Array<IJsonVariaveisApuramento>};

export enum EApuramentoResultadosSteps {
  Variables = 'variables',
  Documents = 'documents',
  Preview = 'preview',
  Finished = 'finished'
}

export interface IApuramentoResultadosStateParams {
  selected?: string;
  docsGerados?: Array<IDocsApuraResultado>;
}

export interface IDocsApuraResultado {
  caption: string;
  extPocCabID: string;
  nDiario: number;
  nDocInterno: string;
  periodo: string;
}

export interface IApuramento extends IJsonApuramento {
  variaveisapuramento: Array<IJsonVariaveisApuramento>;
}

export interface IDocsContasInventario extends IJsonDocsContasInventario {
  _docContabilidade?: IJsonDocContabilidade;
}
