<div class="ativos-grandereparacao-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="title"></h5>
    </div>

    <div class="modal-body">
      <pl-group *ngIf="associa">
        <label [translate]="'ativos.fields.codAtivoPai'"></label>
        <edit>
          <entity-autocomplete entity="ativos" attrName="ativos" [(selectedKey)]="codAtivoPai" (evtSelectedDescriptionChanged)="nomeAtivoPai = $event" [properties]="{validators: {max: {value: 4}}}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="!associa">
        <label [translate]="'ativos.fields.codAtivoPai'"></label>
        <edit>
          <div class="form-control-align">{{ codAtivoPai }} - {{ nomeAtivoPai }}</div>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-submit" id="saveNewLine" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="associa ? 'ativos.modal.btn.marcarGR' : 'ativos.modal.btn.desmarcarGR'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
