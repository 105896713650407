import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PREVI_TES_AUTO} from '../previTes.module.interface';
import {PreviTesService} from '../previTes.module.service';
import {IJsonPreviTesAutoHeader} from '../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';
import {PreviTesAutoModuleComponent} from './components/previTesAuto.module.component';
import {ROLE} from '../../../../services/role.const';
import {HttpResponse} from '@angular/common/http';

export const MODULE_PREVI_TES_AUTO: IModuleDefinition = {
  name: MODULE_NAME_PREVI_TES_AUTO,
  state: {
    url: `/${MODULE_NAME_PREVI_TES_AUTO}`,
    component: PreviTesAutoModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      pageTitle: 'global.menu.previtesauto'
    },
    resolve: [
      {
        provide: 'previTesAutoHeader',
        deps: [PreviTesService],
        useFactory: (previTesService: PreviTesService): Promise<IJsonPreviTesAutoHeader> => {
          return previTesService.getPreviTesAutoHeaderDefault().then((response: HttpResponse<IJsonPreviTesAutoHeader>) => response.body);
        }
      }
    ]
  }
};
