import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ConfiguracoesEventosModalComponent} from '../../modal/configuracoesEventos.modal.component';
import {EGestaoDGEMPSType} from '../../../../../components/gestaodgemps/gestaodgemps.interface';
import {ENTITY_NAME_PRH_EVENTOS_CONFIG, IPRHEventosConfigEntityService} from '../../../../../entities/prheventosconfig/pRHEventosConfig.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IConfigEventos, ITipoEvento} from '../../configuracoesEventos.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {
  IJsonConfigAbdes,
  IJsonConfigEstrutura,
  IJsonConfigTipo,
  IJsonConfigTipoProcessamento,
  IJsonPRHEventosConfig
} from '../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'colaboradores-configuracoes-eventos',
  templateUrl: './configuracoesEventos.component.html'
})
export class ConfiguracoesEventosComponent implements OnInit {
  public readonly definitionConfigs: IDevExpressDataGrid;
  public readonly tiposEventos: Array<ITipoEvento>;
  public listaConfig: IJsonConfigEstrutura;
  public tipoEvento: ITipoEvento;
  public promise: Promise<void>;

  private readonly _servicePRHEventosConfig: IPRHEventosConfigEntityService;
  private _configs: Array<IConfigEventos>;
  private _dataGridInstance: dxDataGrid<IConfigEventos, IConfigEventos>;

  constructor(
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _translateService: TranslateService
  ) {
    this.definitionConfigs = {
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'prheventosconfig.fields.descricao'},
        {dataField: 'tipoFalta', dataType: 'string', caption: 'prheventosconfig.fields.tipo'},
        {dataField: 'processamento', dataType: 'string', caption: 'prheventosconfig.fields.tipoProcessamento'},
        {dataField: 'abdes', dataType: 'string', caption: 'prheventosconfig.fields.desconto'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: new CustomStore({
        load: () => this._getConfigsList()
      }),
      hoverStateEnabled: true,
      searchPanel: {visible: true},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarActions',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.tiposEventos = [
      {cod: EGestaoDGEMPSType.Faltas, nome: 'prheventosconfig.tipos.faltas'},
      {cod: EGestaoDGEMPSType.Abonos, nome: 'prheventosconfig.tipos.abonos'}
    ];
    this.listaConfig = {listaAbDes: [], listaTipo: [], listaTipoProcessamento: [], tipoEvento: <EGestaoDGEMPSType>-1};
    this.tipoEvento = this.tiposEventos[0];
    this._servicePRHEventosConfig = this._entityServiceBuilder.build<IJsonPRHEventosConfig, IPRHEventosConfigEntityService>(ENTITY_NAME_PRH_EVENTOS_CONFIG);
    this._configs = [];
  }

  public ngOnInit(): void {
    this.evtTipoEventoChanged({inputValue: '', item: this.tipoEvento});
  }

  public onDataGridInitializedConfigEventos(event: IDevExpressDataGridEventOnInitialized<IConfigEventos, IConfigEventos>): void {
    this._dataGridInstance = event.component;
  }

  public evtTipoEventoChanged(value: {inputValue: string; item: ITipoEvento}): Promise<void> {
    this.tipoEvento = value.item;
    return this._servicePRHEventosConfig
      .getConfiguracoesByTipo(this.tipoEvento.cod)
      .then((response: HttpResponse<IJsonConfigEstrutura>) => {
        this._dataGridInstance.beginCustomLoading(undefined);
        this.listaConfig = response.body;
        return this._fillConfigsList(this.tipoEvento.cod);
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public edit(item: IConfigEventos): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfiguracoesEventosModalComponent);
    const componentInstance: ConfiguracoesEventosModalComponent = modalInstance.componentInstance;
    componentInstance.listaConfig = this.listaConfig;
    componentInstance.editing = true;
    componentInstance.tipoEvento = this.tipoEvento;
    componentInstance.configEvento = copy(item);
    return modalInstance.result.then((response: IConfigEventos) => {
      const index = this._configs.findIndex((value: IConfigEventos) => value.codPRHEventosConfig === response.codPRHEventosConfig);
      if (index !== -1) {
        this._configs[index] = response;
      }
      this._dataGridInstance.refresh();
    });
  }

  public delete(codPRHEventosConfig: number): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._cgModalService
      .showOkCancel('prheventosconfig.messages.deleteTitle', 'prheventosconfig.messages.deleteConfirm')
      .then(() => {
        return this._servicePRHEventosConfig
          .delete({id: codPRHEventosConfig})
          .then(() => {
            const index = this._configs.findIndex((value: IConfigEventos) => value.codPRHEventosConfig === codPRHEventosConfig);
            if (index !== -1) {
              this._configs.splice(index, 1);
              this._plAlertService.success('prheventosconfig.messages.deleteSuccess');
            }
            this._dataGridInstance.refresh();
          })
          .catch(() => {
            this._plAlertService.error(this._translateService.instant('prheventosconfig.error'));
          });
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public createConfig(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfiguracoesEventosModalComponent);
    const componentInstance: ConfiguracoesEventosModalComponent = modalInstance.componentInstance;
    componentInstance.listaConfig = this.listaConfig;
    componentInstance.editing = false;
    componentInstance.tipoEvento = this.tipoEvento;
    return modalInstance.result.then((response: IConfigEventos) => {
      this._configs.push(response);
      return this._dataGridInstance.refresh().then(() => undefined);
    });
  }

  private _getConfigsList(): Array<IConfigEventos> {
    const field = this.definitionConfigs.columns.find((tableField: IDevExpressDataGridColumn) => tableField.dataField === 'tipoFalta');
    if (field) {
      this._dataGridInstance.columnOption('tipoFalta', {visible: this.tipoEvento.cod === EGestaoDGEMPSType.Faltas});
    }
    return this._configs;
  }

  private _fillConfigsList(tipoEvento: EGestaoDGEMPSType): Promise<void> {
    this._configs = [];

    // Lista de faltas
    return this._servicePRHEventosConfig.query({pesquisa: `tipoEvento=${tipoEvento}`}).then((response: HttpResponse<IApiQueryResponse<IJsonPRHEventosConfig>>) => {
      this._configs = <Array<IConfigEventos>>response.body.list;
      for (const config of this._configs) {
        config.tipoFalta = '';
        config.abdes = '';
        config.processamento = '';

        // TipoFalta
        const indexTipo = this.listaConfig.listaTipo.findIndex((value: IJsonConfigTipo) => value.codTipo === config.tipo);
        if (indexTipo !== -1) {
          config.tipoFalta = this.listaConfig.listaTipo[indexTipo].descricaoTipo;
        }

        // Processamento
        const indexProce = this.listaConfig.listaTipoProcessamento.findIndex((value: IJsonConfigTipoProcessamento) => value.codTipoProcessamento === config.tipoProcessamento);
        if (indexProce !== -1) {
          config.processamento = this.listaConfig.listaTipoProcessamento[indexProce].descricaoTipoProcessamento;
        }

        // Desconto
        const indexABDES = this.listaConfig.listaAbDes.findIndex((value: IJsonConfigAbdes) => value.nCodAbDes === config.ncodAbdes);
        if (indexABDES !== -1) {
          config.abdes = this.listaConfig.listaAbDes[indexABDES].designacao;
        }
      }

      return this._dataGridInstance.refresh().then(() => undefined);
    });
  }
}
