import {AfterContentChecked, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import type {IPlTabsCallback, IPlTabsEventClosed, IPlTabsEventSelected} from './tab.interface';
import {isDefined, isObject} from '../common/utilities/utilities';
import {PlTabDirective} from './tab.directive';

@Component({
  selector: 'pl-tabs',
  templateUrl: './tabs.component.html',
  standalone: false
})
export class PlTabsComponent implements OnInit, AfterContentChecked {
  @Input() public attrName: string;
  @Input() public activeId: any;
  @Input() public disabled: boolean;
  @Input() public justified: boolean;
  @Input() public stacked: boolean;
  @Input() public pills: boolean;
  @Input() public theme: string;
  @Input() public destroyOnHide: boolean;
  @Input() public closable: boolean;
  @Input() public callback: IPlTabsCallback;
  @Output() public readonly activeIdChange: EventEmitter<any>;
  @Output() public readonly evtSelected: EventEmitter<IPlTabsEventSelected>;
  @Output() public readonly evtClosed: EventEmitter<IPlTabsEventClosed>;

  @ContentChildren(PlTabDirective, {descendants: false}) public readonly tabs: QueryList<PlTabDirective>;

  constructor() {
    this.activeIdChange = new EventEmitter<unknown>();
    this.evtSelected = new EventEmitter<IPlTabsEventSelected>();
    this.evtClosed = new EventEmitter<IPlTabsEventClosed>();
    this.destroyOnHide = true;
  }

  public ngOnInit(): void {
    if (isObject(this.callback)) {
      this.callback.select = (id: unknown) => {
        this.select(id);
      };
      this.callback.has = (id: unknown) => this.has(id);
    }
  }

  public ngAfterContentChecked(): void {
    // Auto correct activeId that might have been set incorrectly as input
    const activeTab: PlTabDirective = this._getTab(this.activeId);
    this.activeId = activeTab ? activeTab.id : this.tabs.length ? this.tabs.first.id : undefined;
  }

  public select(tabId: unknown): void {
    if (this.disabled) {
      return;
    }
    const selectedTab = this._getTab(tabId);
    if (selectedTab && !selectedTab.disabled && this.activeId !== selectedTab.id) {
      let defaultPrevented = false;
      this.evtSelected.emit({
        activeId: this.activeId,
        nextId: selectedTab.id,
        preventDefault: () => {
          defaultPrevented = true;
        }
      });
      if (!defaultPrevented) {
        this.activeId = selectedTab.id;
        this.activeIdChange.emit(this.activeId);
      }
    }
  }

  public close(tab: PlTabDirective): void {
    if (tab.closable) {
      this.evtClosed.emit({tabId: tab.id});
    }
  }

  public has(tabId: unknown): boolean {
    const tab: PlTabDirective = this._getTab(tabId);
    return isDefined(tab);
  }

  private _getTab(id: unknown): PlTabDirective {
    return this.tabs.find((current: PlTabDirective) => current.id === id);
  }
}
