import type {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {CGCModalComponent} from '../modal.component';
import type {IPlLocale} from '../../../common/locale/locales.interface';
import {PlLocaleService} from '../../../common/locale/locale.service';
import {TNgClassSupportedTypes} from '../../../common/angular.interface';
import {isEmpty} from '../../../common/utilities/utilities';
import {TCGCOkCancelModalType} from './okcancel.modal.component.interface';

const OK_ICONS: ReadonlyMap<TCGCOkCancelModalType, string> = new Map<TCGCOkCancelModalType, string>([
  ['prompt', 'fa-check'],
  ['success', 'fa-check'],
  ['danger', 'fa-trash-o'],
  ['delete', 'fa-trash-o']
]);

const OK_CSS_CLASSES: ReadonlyMap<TCGCOkCancelModalType, string> = new Map<TCGCOkCancelModalType, string>([
  ['prompt', 'btn-primary'],
  ['success', 'btn-success'],
  ['danger', 'btn-danger'],
  ['delete', 'btn-danger']
]);

const CANCEL_ICONS: ReadonlyMap<TCGCOkCancelModalType, string> = new Map<TCGCOkCancelModalType, string>([
  ['prompt', 'fa-times'],
  ['success', 'fa-times'],
  ['danger', 'fa-times'],
  ['delete', 'fa-times']
]);

const CANCEL_CSS_CLASSES: ReadonlyMap<TCGCOkCancelModalType, string> = new Map<TCGCOkCancelModalType, string>([
  ['prompt', 'btn-outline-secondary'],
  ['success', 'btn-outline-secondary'],
  ['danger', 'btn-outline-secondary'],
  ['delete', 'btn-outline-secondary']
]);

@Component({
  selector: 'cgc-ok-cancel-modal',
  templateUrl: './okcancel.modal.component.html',
  standalone: false
})
export class CGCModalOkCancelComponent extends CGCModalComponent<void> implements OnInit, OnDestroy {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public type: TCGCOkCancelModalType;
  @Input() public btnOkText: string;
  @Input() public btnOkIcon: string;
  @Input() public btnOkCssClass: TNgClassSupportedTypes;
  @Input() public btnCancelText: string;
  @Input() public btnCancelIcon: string;
  @Input() public btnCancelCssClass: TNgClassSupportedTypes;

  public localeOk: string;
  public localeCancel: string;

  private readonly _subscriptionLocale: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService
  ) {
    super(_injector);
    this.btnOkText = '';
    this.btnCancelText = '';
    this.btnOkIcon = 'fa-check';
    this.btnCancelIcon = 'fa-times';
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.localeOk = locale.btn.ok;
      this.localeCancel = locale.btn.cancel;
    });
  }

  public ngOnInit(): void {
    if (isEmpty(this.type)) {
      this.type = 'prompt';
    }
    if (isEmpty(this.btnOkIcon)) {
      this.btnOkIcon = OK_ICONS.get(this.type);
    }
    if (isEmpty(this.btnOkCssClass)) {
      this.btnOkCssClass = OK_CSS_CLASSES.get(this.type);
    }
    if (isEmpty(this.btnCancelIcon)) {
      this.btnCancelIcon = CANCEL_ICONS.get(this.type);
    }
    if (isEmpty(this.btnCancelCssClass)) {
      this.btnCancelCssClass = CANCEL_CSS_CLASSES.get(this.type);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }
}
