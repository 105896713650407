import {Component, ElementRef, Injector, Input, ViewChild} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ConfigDocsEFaturaSupplierComponent} from '../../components/supplier/configdocsefatura.supplier.component';
import {focusElement} from '../../../../../common/utils/element.utils';

@Component({
  selector: 'config-efatura-docs-por-lancar-supplier',
  templateUrl: './configEfaturaDocPorLanc.supplier.modal.component.html'
})
export class ConfigEfaturaDocPorLancSupplierModalComponent extends CGModalComponent<void> {
  @Input() public nContribuinte: string;
  @Input() public nomeFornecedor: string;
  @ViewChild(ConfigDocsEFaturaSupplierComponent) public readonly configDocsEFaturaSupplier: ConfigDocsEFaturaSupplierComponent;

  private _elementBtnGravar: HTMLElement;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): Promise<void> {
    return this.configDocsEFaturaSupplier.save().then(() => {
      super.close();
    });
  }

  public onConfigDocsEFaturaSupplierKeydown(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (this._elementBtnGravar) {
        const btnGravar: HTMLButtonElement = this._elementBtnGravar.querySelector<HTMLButtonElement>('button');
        focusElement(btnGravar);
      }
    }
  }

  @ViewChild('elementBtnGravar', {read: ElementRef})
  public set elementContaAUtilizar(value: ElementRef<HTMLElement>) {
    this._elementBtnGravar = value?.nativeElement;
  }
}
