import moment from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import type {Properties as ButtonProperties} from 'devextreme/ui/button';
import type {Properties as SelectBoxProperties} from 'devextreme/ui/select_box';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {isArray, isBoolean, isObject, isString, isUndefined, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {gtoIsEntidadeExterna, IGTOConfigModalResult, IGTOConfigModel, IGTOResponsavel} from '../../gto.module.interface';
import {GtoLicenseModalComponent} from '../license/gto.license.modal.component';
import {GtoService} from '../../gto.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridEditCellTemplateData} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnSelectionChanged} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonEmpresaErpBasic} from '../../../../../interfaces/jsonEmpresa.interface';
import {IJsonGTOCheckLicenseEmpresa, IJsonGTOConfig, IJsonGTOLicenseInfo, IJsonGTOTarefa} from '../../jsonGTO.module.interface';

const NDIAS_DEFAULT = 3;
const YEAR_SOURCE_SUBTRACT_NUM = 5;

@Component({
  selector: 'gto-config-modal',
  templateUrl: './gto.config.modal.component.html'
})
export class GtoConfigModalComponent extends CGModalComponent<IGTOConfigModalResult> implements OnInit {
  @Input() public nempresa: string;
  @Input() public nomeEmpresa: string;
  @Input() public centralGestId: number;
  @Input() public storeModePublic: boolean;
  @Input() public licenseInfo: IJsonGTOLicenseInfo;
  @Input() public responsaveis: Array<IGTOResponsavel>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonGTOTarefa, string>;
  public readonly empresasTemplate: string;
  public readonly anosSource: Array<number>;
  public readonly model: IGTOConfigModel;
  public usersTemplate: string;
  public ano: number;
  public selectedRowKeys: Array<string>;
  public promiseConfig: Promise<void>;
  public promiseGlobalConfig: Promise<void>;
  public empresasSource: Array<IJsonEmpresaErpBasic>;

  private _dataGridInstance: dxDataGrid<IJsonGTOTarefa, string>;
  private _doRefresh: boolean;
  private _firstSelectionChange: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _gtoService: GtoService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.storeModePublic = false;
    this.ano = moment().year();
    this.anosSource = [];
    this.empresasSource = [];
    for (let i = this.ano + 1; i > this.ano - YEAR_SOURCE_SUBTRACT_NUM; i--) {
      this.anosSource.push(i);
    }
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.fields.nomeTarefa', allowEditing: false},
        {
          dataField: 'nResponsavel',
          dataType: 'string',
          caption: 'gto.fields.responsavel',
          allowEditing: true,
          showEditorAlways: true,
          editCellTemplate: 'editCellTemplateResponsavel'
        }
      ],
      dataSource: new CustomStore({
        key: 'gtoTarefaID',
        load: () => this._getTasks(this.model.nempresa, this.ano),
        update: (gtoTarefaID: string, values: Partial<IJsonGTOTarefa>): Promise<void> => {
          if (Object.prototype.hasOwnProperty.call(values, 'nResponsavel')) {
            if (!values.nResponsavel) {
              values.nResponsavel = 0;
            }
            return this._updateResponsavelTarefa(gtoTarefaID, values.nResponsavel);
          }
          return Promise.resolve();
        }
      }),
      editing: {
        allowUpdating: true,
        mode: 'cell'
      },
      export: {filename: 'gto.paraEmpresas'},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false},
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              value: this.ano,
              label: 'Ano:',
              items: this.anosSource,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onValueChanged: (e: any) => {
                this.ano = e.value;
                this._reloadTasks().then(() => undefined);
              }
            } satisfies SelectBoxProperties
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              icon: 'repeat',
              hint: this._translateService.instant('gto.hints.inicializaRepoeTarefasPorDefeito'),
              onClick: async () => {
                await this._restoreTasks();
              }
            } satisfies ButtonProperties
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.empresasTemplate = '{{nempresa}} - {{nome}}';
    this.model = {
      nempresa: '',
      nome: '',
      empConfig: {
        startGTO: undefined,
        alertNDias: NDIAS_DEFAULT
      },
      globalConfig: {
        startGTO: undefined,
        alertNDias: NDIAS_DEFAULT
      }
    };
    this._doRefresh = false;
    this._firstSelectionChange = true;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.storeModePublic)) {
      this.storeModePublic = false;
    }
    this.usersTemplate = !this.storeModePublic ? '{{nResponsavel}} - {{nomeResponsavel}}' : '{{nomeResponsavel}}';
    this.model.nempresa = this.nempresa;
    this.model.nome = this.nomeEmpresa;
    this._loadConfigAndTasks().then(() => undefined);
    this._gtoService.getAllEmpresasEE().then((response: HttpResponse<Array<IJsonEmpresaErpBasic>>) => {
      this.empresasSource = response.body;
    });
  }

  public close(): void {
    super.close({
      refresh: this._doRefresh,
      nempresa: this.model.nempresa
    });
  }

  public dismiss(): void {
    this.close();
  }

  public nEmpresaChanged(item: IJsonEmpresaErpBasic): Promise<void> {
    this.model.nempresa = item.nempresa;
    this.model.nome = item.nome;
    return this._loadConfigAndTasks();
  }

  public changedResponsavel(cell: IDevExpressDataGridEditCellTemplateData<IJsonGTOTarefa, string>, user: string | IGTOResponsavel): void {
    let nResponsavel: number;
    let nomeResponsavel: string;
    if (isUndefined(user)) {
      nResponsavel = null;
      nomeResponsavel = null;
    } else if (isString(user)) {
      nResponsavel = Number(user);
      if (Number.isNaN(nResponsavel)) {
        nResponsavel = null;
      } else {
        const responsavel: IGTOResponsavel = this.responsaveis.find((item: IGTOResponsavel) => item.nResponsavel === nResponsavel);
        if (responsavel) {
          user = responsavel;
        }
      }
    }
    if (isObject(user)) {
      user = <IGTOResponsavel>user;
      nResponsavel = user.nResponsavel;
      nomeResponsavel = user.nomeResponsavel;
    }
    this._dataGridInstance.cellValue(cell.rowIndex, 'nResponsavel', nResponsavel);
    this._dataGridInstance.cellValue(cell.rowIndex, 'nomeResponsavel', nomeResponsavel);
    this._dataGridInstance.saveEditData().then(() => undefined);
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonGTOTarefa, string>): void {
    this._dataGridInstance = component;
  }

  public onDataGridSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonGTOTarefa, string>): Promise<void> {
    if (this._firstSelectionChange) {
      this._firstSelectionChange = false;
      return Promise.resolve();
    }
    if (!this.selectedRowKeys.length) {
      return this._tasksUnSelectAll();
    }
    if (this.selectedRowKeys.length === this._dataGridInstance.totalCount()) {
      return this._tasksSelectAll();
    }

    if (!event.currentSelectedRowKeys.length) {
      return this._onActiveTasksSelect(event.currentDeselectedRowKeys[0], false);
    }
    return this._onActiveTasksSelect(event.currentSelectedRowKeys[0], true);
  }

  public readonly fnSaveEmpresaConfig = (): Promise<void> => this._saveEmpresaConfig();

  public readonly fnSaveGlobalConfig = (): Promise<void> => this._saveGlobalConfig();

  private async _loadConfigAndTasks(): Promise<void> {
    await Promise.all([this._loadConfiguration(), this._reloadTasks()]);
    return undefined;
  }

  private _loadConfiguration(): Promise<void> {
    this.promiseConfig = this._gtoService.getConfig(this.model.nempresa).then((response: HttpResponse<IJsonGTOConfig>) => {
      this.model.empConfig = response.body;
    });
    return this.promiseConfig;
  }

  private async _tasksSelectAll(): Promise<void> {
    if (!this.licenseInfo.haveContabDigitalLicense && !this.licenseInfo.haveGTOLicense) {
      this._plAlertService.error('gto.aSuaLicencaNaoTemGTO');
      return;
    }
    if (!this._isEntidadeExterna(this.model.nempresa)) {
      if (!this.licenseInfo.haveContabDigitalLicense) {
        const checkLicenseResponse: HttpResponse<IJsonGTOCheckLicenseEmpresa> = await this._gtoService.checkLicenseEmpresa(this.model.nempresa);
        if (!checkLicenseResponse.body.inList) {
          await this._cgModalService.show(GtoLicenseModalComponent, {size: 'md'});
          await this._gtoService.licenseAddEmpresas([this.model.nempresa]);
        }
      }
    }
    this._doRefresh = true;
    await this._gtoService.toggleActiveMultiple(this.selectedRowKeys, true);
  }

  private async _tasksUnSelectAll(): Promise<void> {
    const keys: string | Array<string> = this._dataGridInstance
      .getDataSource()
      .items()
      .map((item: IJsonGTOTarefa) => item.gtoTarefaID);

    await this._gtoService.toggleActiveMultiple(isArray(keys) ? keys : [keys], false);
    this._doRefresh = true;
  }

  private async _onActiveTasksSelect(gtoTarefaID: string, selected: boolean): Promise<void> {
    if (!this.licenseInfo.haveContabDigitalLicense && !this.licenseInfo.haveGTOLicense) {
      this._plAlertService.error('gto.aSuaLicencaNaoTemGTO');
      return;
    }
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      if (!this._isEntidadeExterna(this.model.nempresa)) {
        if (selected && !this.licenseInfo.haveContabDigitalLicense) {
          const checkLicenseResponse: HttpResponse<IJsonGTOCheckLicenseEmpresa> = await this._gtoService.checkLicenseEmpresa(this.model.nempresa);
          if (!checkLicenseResponse.body.inList) {
            await this._cgModalService.show(GtoLicenseModalComponent, {size: 'md'});
            await this._gtoService.licenseAddEmpresas([this.model.nempresa]);
          }
        }
      }
      await this._gtoService.toggleActive(gtoTarefaID, selected);
      this._doRefresh = true;
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _getTasks(nNmpresa: string, ano: number): Promise<Array<IJsonGTOTarefa>> {
    const response = await this._gtoService.getCompanyTasks(nNmpresa, ano);
    for (const tarefa of response.body) {
      if (!tarefa.nResponsavel) {
        tarefa.nResponsavel = undefined;
        tarefa.nomeResponsavel = undefined;
      }
    }
    this._firstSelectionChange = true;
    this.selectedRowKeys = response.body.filter((item: IJsonGTOTarefa) => item.isActive).map((value: IJsonGTOTarefa) => value.gtoTarefaID);
    if (this.selectedRowKeys.length === 0) {
      this._firstSelectionChange = false;
    }
    return response.body;
  }

  private _reloadTasks(): Promise<void> {
    if (!this._dataGridInstance) {
      return Promise.resolve();
    }
    return this._dataGridInstance.refresh();
  }

  private _saveEmpresaConfig(): Promise<void> {
    this.promiseConfig = this._gtoService.saveConfig(this.model.nempresa, this.model.empConfig).then(() => {
      this._doRefresh = true;
    });
    return this.promiseConfig;
  }

  private _saveGlobalConfig(): Promise<void> {
    this.promiseGlobalConfig = this._gtoService.applyGlobalConfig(this.model.globalConfig).then(() => {
      this._doRefresh = true;
    });
    return this.promiseGlobalConfig;
  }

  private async _updateResponsavelTarefa(tarefaID: string, nResponsavel: number): Promise<void> {
    await this._gtoService.updateTaskNResponsavel(tarefaID, nResponsavel);
    this._doRefresh = true;
  }

  private _isEntidadeExterna(nEmpresa: string): boolean {
    return gtoIsEntidadeExterna(nEmpresa);
  }

  private async _restoreTasks(): Promise<void> {
    await this._cgModalService.showOkCancel(
      'global.text.confirmation',
      this._translateService.instant('gto.messages.temACertezaQuePertendeReporTarefas', {
        nEmpresa: this.model.nempresa,
        ano: this.ano
      })
    );
    if (this._dataGridInstance.totalCount() > 0) {
      await this._cgModalService.showOkCancel('global.text.confirmation', 'gto.messages.jaExistemTarefasCriadas');
    }
    await this._gtoService.restoreTasks(this.model.nempresa, this.ano);
    this._doRefresh = true;
    await this._reloadTasks();
  }
}
