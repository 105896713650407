import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_ARREDONDAMENTO_ABDES, ETipoArredondamentoABDES} from './tipoArredondamentoABDES.datasource.interface';

export const DATA_SOURCE_TIPOS_ARREDONDAMENTO_ABDES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_ARREDONDAMENTO_ABDES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoArredondamentoABDES.NaoArredonda, name: 'tiposArredondamentoABDES.data.NaoArredonda'},
    {value: ETipoArredondamentoABDES.UnidMaisProxima, name: 'tiposArredondamentoABDES.data.UnidMaisProxima'},
    {value: ETipoArredondamentoABDES.DezenaMaisProxima, name: 'tiposArredondamentoABDES.data.DezenaMaisProxima'},
    {value: ETipoArredondamentoABDES.Baixo1, name: 'tiposArredondamentoABDES.data.Baixo1'},
    {value: ETipoArredondamentoABDES.Cima1, name: 'tiposArredondamentoABDES.data.Cima1'},
    {value: ETipoArredondamentoABDES.Baixo10, name: 'tiposArredondamentoABDES.data.Baixo10'},
    {value: ETipoArredondamentoABDES.Cima10, name: 'tiposArredondamentoABDES.data.Cima10'},
    {value: ETipoArredondamentoABDES.ParaDecimaMaisProxima, name: 'tiposArredondamentoABDES.data.ParaDecimaMaisProxima'},
    {value: ETipoArredondamentoABDES.ParaCentesimaMaisProxima, name: 'tiposArredondamentoABDES.data.ParaCentesimaMaisProxima'},
    {value: ETipoArredondamentoABDES.ParaMilesimaMaisProxima, name: 'tiposArredondamentoABDES.data.ParaMilesimaMaisProxima'},
    {value: ETipoArredondamentoABDES.ParaDezenaMaisInferior, name: 'tiposArredondamentoABDES.data.ParaDezenaMaisInferior'},
    {value: ETipoArredondamentoABDES.ParaDezenaMaisSuperior, name: 'tiposArredondamentoABDES.data.ParaDezenaMaisSuperior'},
    {value: ETipoArredondamentoABDES.ParaDecimaMaisInferior, name: 'tiposArredondamentoABDES.data.ParaDecimaMaisInferior'},
    {value: ETipoArredondamentoABDES.ParaDecimaMaisSuperior, name: 'tiposArredondamentoABDES.data.ParaDecimaMaisSuperior'},
    {value: ETipoArredondamentoABDES.ParaCentesimaMaisInferior, name: 'tiposArredondamentoABDES.data.ParaCentesimaMaisInferior'},
    {value: ETipoArredondamentoABDES.ParaCentesimaMaisSuperior, name: 'tiposArredondamentoABDES.data.ParaCentesimaMaisSuperior'},
    {value: ETipoArredondamentoABDES.ParaMilesimaMaisInferior, name: 'tiposArredondamentoABDES.data.ParaMilesimaMaisInferior'},
    {value: ETipoArredondamentoABDES.ParaMilesimaMaisSuperior, name: 'tiposArredondamentoABDES.data.ParaMilesimaMaisSuperior'},
    {value: ETipoArredondamentoABDES.ParaCentenaMaisProxima, name: 'tiposArredondamentoABDES.data.ParaCentenaMaisProxima'}
  ]
});
