import {forkJoin, from, Observable} from 'rxjs';
import type {IPlUploadProperties} from '../../upload.component.interface';
import {isFunction} from '../../../common/utilities/utilities';
import {PlImageResizer} from '../../../common/imageresizer/image.resizer';
import {PlUploadTransformer} from '../upload.transform';

export class PlUploadResizeTransformer extends PlUploadTransformer {
  protected _doTransformFiles(files: Array<File>, properties: Partial<IPlUploadProperties>): Observable<Array<File>> {
    return forkJoin(files.map<Observable<File>>((file: File) => this._resizeFile(file, properties)));
  }

  private _resizeFile(file: File, properties: Partial<IPlUploadProperties>): Observable<File> {
    if (isFunction(properties.resizeFn)) {
      return from(Promise.resolve(properties.resizeFn(file, properties)));
    }
    return PlImageResizer.resizeImageToFile(file, properties);
  }
}
