import {Component, Injector, Input, OnInit} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {EDocsComerciaisClifoStatus, IDocsComerciaisEntityService, IPesquisaDocsFilters} from '../../docsComerciais.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonDocComercial} from '../../jsonDocComercial.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import moment from 'moment';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocsComerciaisGrid} from '../../../../modules/portalclientaccounts/myaccount/documentoscomerciais/documentosComerciais.module.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'documento-pesqDocs-modal',
  templateUrl: './documento.pesqDocs.modal.component.html'
})
export class DocumentoPesqDocsModalComponent extends DocsComerciaisModalComponent implements OnInit {
  @Input() public docStateType: EEntityStateDetailType;

  public readonly docFaTemplate: string = '{{nDocFa}} - {{nome}}';
  public readonly docFaOutput: string = 'nDocFa';
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public filters: IPesquisaDocsFilters;
  public motivo: string;
  public docFaSource: Array<IJsonDocfa>;
  public docsList: Array<IJsonDocComercial>;
  public selectedDoc: IJsonDocComercial;
  public differentGrupoFa: boolean;
  public hasDocFaList: boolean;

  private readonly _docFaService: IDocFasEntityService;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');

    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDoc', dataType: 'string', width: '80px', caption: 'docscomerciais.fields.nDoc'},
        {dataField: 'nome', dataType: 'string', caption: 'docscomerciais.doc.cab.nome'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'estado', alignment: 'center', dataType: 'string', caption: 'docscomerciais.fields.estado'},
        {dataField: 'porPagar', alignment: 'right', dataType: 'number', caption: 'docscomerciais.fields.porPagar'},
        {dataField: 'totalDocumentoME', alignment: 'right', dataType: 'number', caption: 'global.text.total'},
        {dataField: 'abreviaturaMoeda', alignment: 'right', dataType: 'number', caption: 'docscomerciais.fields.nomeMoeda'}
      ],
      dataSource: [],
      remoteOperations: {
        filtering: false,
        grouping: false,
        groupPaging: false,
        paging: true,
        sorting: false,
        summary: false
      },
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      height: '50vh',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocFaturacao'}
    };

    this.filters = {
      deConta: `${this._configService.configurations.clientesFornecedores.radicalClientes}1`,
      ateConta: `${this._configService.configurations.clientesFornecedores.radicalFornecedores}9999999`,
      docfalist: [],
      dataDe: moment()
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        .subtract(4, 'months')
        .startOf('month'),
      dataAte: moment()
    };

    this.docFaSource = [];
    this.filters.docfalist = [];
    this.docsList = [];
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this.differentGrupoFa = false;
    this.hasDocFaList = false;
  }

  public ngOnInit(): void {
    this._docFaService.query().then((response) => {
      this.docFaSource = response.body.list;
      this.filters.docfalist = this.doc.cab.nDocFa ? [this.doc.cab.nDocFa] : this.docFaSource.map((item) => item.nDocFa);
      this.hasDocFaList = true;
      this._refresh();
    });
    if (this.doc.cab.nConta) {
      this.filters.deConta = this.doc.cab.nConta;
      this.filters.ateConta = this.doc.cab.nConta;
    }
  }

  public readonly fnDuplicateDoc: () => void = () => {
    this._duplicateDoc();
  };
  public readonly fnCopyLines: () => void = () => {
    this._copyLines();
  };
  public readonly fnPesquisar: () => void = () => {
    this._pesquisar();
  };

  public close(docModel: IJsonDocComercial): void {
    super.close(docModel);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocsComerciaisGrid>): void {
    if (event.row.isExpanded) {
      this._dataGridInstance.collapseAll(-1);
    } else {
      this._dataGridInstance.collapseAll(-1);
      if (!isObject(event.row)) {
        return;
      }
      devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
    }
  }

  private _onDetail(grid: IDocsComerciaisGrid): Promise<void> {
    return this._docsComerciaisService.get({id: grid.faccbId}).then((response) => {
      grid._thedoc = response.body;
      this.selectedDoc = response.body;
      this.differentGrupoFa = this.selectedDoc.cab.grupoDocfa !== this.doc.cab.grupoDocfa;
    });
  }

  private _refresh(): void {
    if (this.filters && this.hasDocFaList) {
      const querySearchParam = this._generateQueryParams();
      this._docsComerciaisService.query({pesquisa: querySearchParam}).then((response) => {
        this.docsList = response.body.list;
      });
    }
  }

  private _pesquisar(): void {
    this._refresh();
  }

  private _duplicateDoc(): void {
    this.selectedDoc.cab.faccbId = this.doc.cab.faccbId;
    this.selectedDoc.cab.nDocumento = 0;
    this.selectedDoc.cab.nDocumentoDraft = 0;
    this.selectedDoc.cab.terminado = false;
    this.selectedDoc.cab.dataDoc = moment();
    this.selectedDoc.cab.dataDocExterno = moment(this.doc.cab.dataDoc).isSameOrAfter(this.doc.cab.dataDocExterno, 'day')
      ? moment()
      : moment(this.selectedDoc.cab.dataDoc).add(moment(this.doc.cab.dataDocExterno).diff(this.doc.cab.dataDoc, 'day'), 'days');
    this.selectedDoc.cab.dataVenc = moment(this.doc.cab.dataDoc).isSameOrAfter(this.doc.cab.dataVenc, 'day')
      ? moment()
      : moment(this.selectedDoc.cab.dataDoc).add(moment(this.doc.cab.dataVenc).diff(this.doc.cab.dataDoc, 'day'), 'days');
    this.selectedDoc.clifo.action = EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif;
    this.close(this.selectedDoc);
  }

  private _copyLines(): void {
    const linhasCount = this.doc.linhas.length;
    if (linhasCount > 0) {
      // Se a ultima linha for vazia remove
      if (this.doc.linhas[linhasCount - 1].nArtigo === '') {
        this.doc.linhas.splice(linhasCount - 1, 1);
      }
    }
    let nSeq = this.doc.linhas.length;
    for (const linha of this.selectedDoc.linhas) {
      linha.nSeq = nSeq;
      this.doc.linhas.push(linha);
      nSeq++;
    }
    this.close(this.doc);
  }

  private _generateQueryParams(): string {
    let tipoDocQueryParam = '';
    let paramsNDocFa = '';
    const dataDe: string = this.filters.dataDe ? moment(this.filters.dataDe).toISOString() : undefined;
    const dataAte: string = this.filters.dataAte ? moment(this.filters.dataAte).toISOString() : undefined;
    if (dataDe) {
      tipoDocQueryParam = `dataDoc>=${dataDe}`;
    }
    if (dataAte) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&dataDoc<=${dataAte}` : `dataDoc<=${dataAte}`;
    }
    if (this.filters.deConta) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&nconta>=${this.filters.deConta}` : `nconta>=${this.filters.deConta}`;
    }
    if (this.filters.ateConta) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&nconta<=${this.filters.ateConta}` : `nconta<=${this.filters.ateConta}`;
    }
    let n = 0;
    if (this.filters.docfalist.length > 0 && tipoDocQueryParam !== '') {
      tipoDocQueryParam += '&&(';
    }
    while (n < this.filters.docfalist.length) {
      paramsNDocFa = n === 0 ? `ndocfa=${this.filters.docfalist[n].toString()}` : `${paramsNDocFa}||ndocfa=${this.filters.docfalist[n].toString()}`;
      ++n;
    }
    tipoDocQueryParam += paramsNDocFa;
    if ((dataDe || dataAte) && this.filters.docfalist.length > 0) {
      tipoDocQueryParam += ')';
    }
    return tipoDocQueryParam;
  }
}
