import {Component, Injector, OnInit} from '@angular/core';
import {IJsonMeioPagamento} from '../../jsonMeioPagamento.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {ETipoPagamento} from '../../meiosPagamento.entity.interface';
import {PlAlertService} from 'pl-comps-angular';
import {ConfigErpService} from '../../../../services/configErp.service';
import {IJsonConfigERP} from '../../../configserp/jsonConfigERP.entity.interface';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'meios-pagamento-list',
  templateUrl: './meiosPagamento.entity.list.component.html'
})
export class MeiosPagamentoListComponent extends ModuloEntityListComponent<IJsonMeioPagamento> implements OnInit {
  public readonly tiposMeiosPagamento: typeof ETipoPagamento;
  public configPagamentosMeioPagamOmissao: IJsonConfigERP;
  public configRecibosMeioPagamOmissao: IJsonConfigERP;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _configErpService: ConfigErpService
  ) {
    super(_injector);

    this.tiposMeiosPagamento = ETipoPagamento;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.visible = Boolean(this._configService.configurations.acessos.erpcloud.gestaoComercial.editarMeioPagamento);
    this._configErpService.getConfiguration('contabilidade.pagamentos.meioPagamentoOmissao').then((result: HttpResponse<IJsonConfigERP>) => {
      this.configPagamentosMeioPagamOmissao = result.body;
    });

    this._configErpService.getConfiguration('contabilidade.recibos.meioPagamentoOmissao').then((result: HttpResponse<IJsonConfigERP>) => {
      this.configRecibosMeioPagamOmissao = result.body;
    });
  }

  public readonly fnBeforeChangePagamentosPorDefeito = (value: boolean): boolean => this._beforeChangePagamentosPorDefeito(value);
  public readonly fnBeforeChangeRecibosPorDefeito = (value: boolean): boolean => this._beforeChangeRecibosPorDefeito(value);

  public onPagamentosOmissaoChanged(value: boolean, item: IJsonMeioPagamento): Promise<void> {
    this.configPagamentosMeioPagamOmissao.value = item.nCaixa;
    this.promise = this._configErpService.save(this.configPagamentosMeioPagamOmissao, false).then(() => {
      return this.refreshList();
    });
    return this.promise;
  }

  public onRecebimentoOmissaoChanged(value: boolean, item: IJsonMeioPagamento): Promise<void> {
    this.configRecibosMeioPagamOmissao.value = item.nCaixa;
    this.promise = this._configErpService.save(this.configRecibosMeioPagamOmissao, false).then(() => {
      return this.refreshList();
    });
    return this.promise;
  }

  private _beforeChangePagamentosPorDefeito(value: boolean): boolean {
    if (value) {
      this._plAlertService.error('meiosPagamento.table.actions.errorDesactivate');
      return false;
    }
    return true;
  }

  private _beforeChangeRecibosPorDefeito(value: boolean): boolean {
    if (value) {
      this._plAlertService.error('meiosPagamento.table.actions.errorDesactivate');
      return false;
    }
    return true;
  }
}
