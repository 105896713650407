import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isDefinedNotNull, isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {
  ETrfBaNodeRef,
  ETrfOperacaoBancaria,
  ITrfBaItemTree,
  ITRFBAParams,
  ITrfGerarFicheiroModalParams,
  ITrfOpenGerarFicheiroModalParams,
  RADIO_GROUP_TRF_BA_RG_FORMATO
} from '../trfBa.module.interface';
import {ETrfBaRGFormato, IJsonTrfBa, IJsonTrfBaConfig, IJsonTrfBaRegistoFich} from '../jsonTrfBa.module.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {ITreeItem} from '../../../components/treeviewer/treeviewer.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {TrfBaConfigModalComponent} from '../modal/config/trfBa.config.modal.component';
import {TrfBaGeraFicheiroModalComponent} from '../modal/geraficheiro/trfBa.geraFicheiro.modal.component';
import {TrfBaService} from '../trfBa.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import DxTreeList, {InitializedEvent, Node, RowClickEvent, RowExpandedEvent} from 'devextreme/ui/tree_list';

const DGT = 'DGT';
const DGT_SEPA = 'DGT SEPA';
const ENVIADO = 'ENVIADO';
const PORENVIAR = 'PORENVIAR';

@Component({
  selector: 'module-trf-ba',
  templateUrl: './trfBa.module.component.html'
})
export class TrfBaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public trfBaRegistoFich: IJsonTrfBaRegistoFich;

  public readonly radioGroupRGFormato: IRadioGroup<ETrfBaRGFormato>;
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonTrfBa, number>;

  public dataSourceTreePlain: ArrayStore;
  public itemTreeSelected: ITreeItem;
  public rgFormato: ETrfBaRGFormato;
  public showRGFormato: boolean;
  public source: Array<ITreeItem>;

  private readonly _trfbaParams: ITRFBAParams;
  private _dataGridInstance: dxDataGrid<IJsonTrfBa, number>;
  private _dataTreePlainInstance: DxTreeList;

  constructor(
    protected readonly _injector: Injector,
    private readonly _trfBaService: TrfBaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaCaixa', dataType: 'string', caption: 'trfba.table.nContaCaixa', allowEditing: false},
        {dataField: 'nContaForn', dataType: 'string', caption: 'trfba.table.nContaForn', allowEditing: false},
        {dataField: 'codEmpregado', dataType: 'number', caption: 'trfba.table.codEmpregado', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'trfba.table.valor', allowEditing: true},
        {dataField: 'nomeBeneficiario', dataType: 'string', caption: 'trfba.table.nomeBeneficiario', width: '200px', allowEditing: false},
        {dataField: 'nomeFuncionario', dataType: 'string', caption: 'trfba.table.nomeFuncionario', width: '200px', allowEditing: false},
        {dataField: 'nDocInternoLanc', dataType: 'string', caption: 'trfba.table.nDocInternoLanc', allowEditing: false},
        {dataField: 'dataLanc', dataType: 'date', caption: 'trfba.table.dataLanc', allowEditing: false},
        {dataField: 'nDocPago', dataType: 'string', caption: 'trfba.table.nDocPago', allowEditing: false},
        {dataField: 'codBancoEMI', dataType: 'string', caption: 'trfba.table.codBancoEMI', allowEditing: false},
        {dataField: 'codagenciaEMI', dataType: 'string', caption: 'trfba.table.codagenciaEMI', allowEditing: false},
        {dataField: 'ibanContaEMI', dataType: 'string', caption: 'trfba.table.ibanContaEMI', width: '200px', allowEditing: false},
        {dataField: 'swiftEmi', dataType: 'string', caption: 'trfba.table.swiftEmi', allowEditing: false},
        {dataField: 'codBancoDest', dataType: 'string', caption: 'trfba.table.codBancoDest', allowEditing: false},
        {dataField: 'codAgenciaDest', dataType: 'string', caption: 'trfba.table.codAgenciaDest', allowEditing: false},
        {dataField: 'ibanContaDest', dataType: 'string', caption: 'trfba.table.ibanContaDest', width: '200px', allowEditing: false},
        {dataField: 'swiftDest', dataType: 'string', caption: 'trfba.table.swiftDest', allowEditing: false},
        {dataField: 'nomeFicheiro', dataType: 'string', caption: 'trfba.table.nomeFicheiro', width: '200px', allowEditing: false},
        {dataField: 'trfBaID', dataType: 'number', caption: 'trfba.table.trfBaID', allowEditing: false},
        {dataField: 'chaveToPS2', dataType: 'string', caption: 'trfba.table.chaveToPS2', width: '200px', allowEditing: false},
        {dataField: 'operacao', dataType: 'number', caption: 'trfba.table.operacao', customizeText: this._fnCustomizeTextOperacao, allowEditing: false},
        {dataField: 'formatoTransferencia', dataType: 'string', caption: 'trfba.table.formatoTransferencia', width: '200px', allowEditing: false},
        {dataField: 'efectuada', dataType: 'boolean', caption: 'trfba.table.efectuada', allowEditing: false}
      ],
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true
      },
      export: {filename: 'global.menu.trfba'},
      dataSource: new ArrayStore({key: 'trfBaID', data: []}),
      headerFilter: {visible: false},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarBtns',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.radioGroupRGFormato = RADIO_GROUP_TRF_BA_RG_FORMATO;
    this.showRGFormato = false;
    this.itemTreeSelected = undefined;
    this.source = [
      {
        nodeText: this._translateService.instant('trfba.tree.docporenviar'),
        nodeId: PORENVIAR,
        nodeValue: 0,
        childNodes: [],
        hasChildNodes: true
      },
      {
        nodeText: this._translateService.instant('trfba.tree.docenviados'),
        nodeId: ENVIADO,
        nodeValue: 1,
        childNodes: [],
        hasChildNodes: true
      }
    ];
    this.dataSourceTreePlain = new ArrayStore({
      data: this.source,
      key: 'nodeId'
    });
    this._trfbaParams = <ITRFBAParams>this._transition.params();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'configSEPA',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      class: 'btn-primary',
      caption: 'trfba.btn.configSEPA',
      click: () => this._configModal()
    });
    this.rgFormato = ETrfBaRGFormato.PS2XML;
  }

  public async gerarFicheiro(): Promise<void> {
    const trfBaIDs: string = this._dataGridInstance.getSelectedRowKeys().join(',');
    if (isEmpty(trfBaIDs)) {
      this._plAlertService.error('trfba.messages.temselecionar');
      return Promise.resolve();
    }

    let trfBaList: Array<IJsonTrfBa> = [];
    await this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((result: Array<IJsonTrfBa>) => {
        trfBaList = result;
      });

    const lastItem = trfBaList.length < 2;
    const inputs: ITrfGerarFicheiroModalParams = {
      trfBaIDS: trfBaIDs,
      trfBaRegistoFich: this.trfBaRegistoFich,
      rgFormato: this.rgFormato
    };

    if (trfBaList[0].formatoTrasnferencia === DGT || trfBaList[0].formatoTrasnferencia === DGT_SEPA) {
      inputs.showDadosFicheiroUploadDGT = true;
      inputs.showDataLanc = this.trfBaRegistoFich.registoFicheiroDeTransferenciaDGTNoFormatoSEPA;
      inputs.dataLanc = trfBaList[0].dataLanc;
      return this._gerarFicheiroModal({inputs: inputs}, lastItem).finally(() => {
        if (!this.trfBaRegistoFich.registoFicheiroDeTransferenciaDGTNoFormatoSEPA) {
          this._plAlertService.success('trfba.messages.fichHomeBanking');
        }
      });
    }

    if (trfBaList[0].operacao === ETrfOperacaoBancaria.TicketRefeicao) {
      if (this.trfBaRegistoFich.registoFicheiroTicketRefeicaoNoFormatoSEPA) {
        inputs.showDataLanc = true;
        inputs.dataLanc = trfBaList[0].dataLanc;
        return this._gerarFicheiroModal({inputs: inputs}, lastItem);
      }

      await this._trfBaService.geraFicheiroEDescarregar(trfBaIDs, trfBaList[0].dataLanc, '', 0, false, this.rgFormato, this.trfBaRegistoFich);
      return this._afterChangeStatusFile(lastItem);
    }

    if (this.rgFormato === ETrfBaRGFormato.PS2XML) {
      if (this.trfBaRegistoFich.registoFicheiroDeTransferenciasNoFormatoPS2) {
        await this._cgModalService.showOkCancel('global.text.confirmation', 'trfba.messages.fichformPS2');
        await this._trfBaService.geraFicheiroEDescarregar(trfBaIDs, trfBaList[0].dataLanc, '', 0, false, this.rgFormato, this.trfBaRegistoFich);
        return this._afterChangeStatusFile(lastItem);
      }

      inputs.showDataLanc = true;
      inputs.showPrioridade = true;
      inputs.dataLanc = trfBaList[0].dataLanc;
      return this._gerarFicheiroModal({inputs: inputs, showOkCancel: true, showOkCancelMessage: 'trfba.messages.fichformSEPAXML'}, lastItem);
    } else if (this.rgFormato === ETrfBaRGFormato.CGD) {
      inputs.showDataLanc = true;
      inputs.dataLanc = trfBaList[0].dataLanc;
      return this._gerarFicheiroModal({inputs: inputs, showOkCancel: true, showOkCancelMessage: 'trfba.messages.fichformCGD'}, lastItem);
    }

    return Promise.resolve();
  }

  public alterarEstado(): Promise<void> {
    const trfBaIDs: string = this._dataGridInstance.getSelectedRowKeys().join(',');
    if (isEmpty(trfBaIDs)) {
      this._plAlertService.error('trfba.messages.temselecionar');
      return Promise.resolve();
    }

    return this._cgModalService.showOkCancel('global.text.confirmation', 'trfba.messages.confaltestado').then(() => {
      return this._trfBaService.alterarEstadoDeEnvio(trfBaIDs).then(() => {
        return this._afterChangeStatusFile(this._dataGridInstance.totalCount() < 2);
      });
    });
  }

  public deleteTransferencias(): Promise<void> {
    const trfBaIDsList: Array<number> = this._dataGridInstance.getSelectedRowKeys();
    const trfBaIDs: string = trfBaIDsList.join(',');
    if (isEmpty(trfBaIDs)) {
      this._plAlertService.error('trfba.messages.temselecionar');
      return Promise.resolve();
    }

    return this._cgModalService.showOkCancel('global.text.confirmation', 'trfba.messages.deltransf').then(() => {
      return this._trfBaService.deleteTransferencias(trfBaIDs).then(() => {
        this._plAlertService.success('trfba.messages.deltransfsuccess');
        for (const trfBaID of trfBaIDsList) {
          this._dataGridInstance.getDataSource().store().remove(trfBaID);
        }
        if (this._dataGridInstance.totalCount() === 0) {
          const parentNode: Node<ITreeItem, string> = this._dataTreePlainInstance.getNodeByKey(this.itemTreeSelected.parentNodeID);
          if (parentNode) {
            this._deepCollapseRow(parentNode.data.parentNodeID).then(() => {
              return this._dataTreePlainInstance.expandRow(parentNode.data.parentNodeID).then(() => {
                this.itemTreeSelected = undefined;
              });
            });
          }
        }
        return this._dataGridInstance.refresh();
      });
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonTrfBa, number>): void {
    this._dataGridInstance = component;
  }

  public onInitializedTreePlain({component}: InitializedEvent): void {
    this._dataTreePlainInstance = component;
  }

  public async onRowClickTreePlain(event: RowClickEvent<ITreeItem, string>): Promise<void> {
    if (event.rowType === 'data') {
      this.itemTreeSelected = event.data;
      if (this.itemTreeSelected.nodeId === ENVIADO && this._dataTreePlainInstance.isRowExpanded(PORENVIAR)) {
        await this._deepCollapseRow(PORENVIAR);
      } else if (this.itemTreeSelected.nodeId === PORENVIAR && this._dataTreePlainInstance.isRowExpanded(ENVIADO)) {
        await this._deepCollapseRow(ENVIADO);
      }

      if (!event.data.hasChildNodes) {
        this._dataGridInstance.beginCustomLoading(undefined);
        const trfBaItemTree: ITrfBaItemTree = event.data.nodeValue;
        return this._trfBaService
          .getDocsTrfBaNibEmi(trfBaItemTree.estado, trfBaItemTree.formatotransferencia, trfBaItemTree.operacao, trfBaItemTree.data, trfBaItemTree.iban)
          .then((response: HttpResponse<Array<IJsonTrfBa>>) => {
            this.rgFormato = ETrfBaRGFormato.PS2XML;
            this._applyDataSource(response.body);
            this.showRGFormato = response.body[0].showRGFormato;
            this._evaluateColumnOptions();
          })
          .finally(() => {
            this._dataGridInstance.endCustomLoading();
          });
      }
    }
    return Promise.resolve();
  }

  public async onRowExpanded(event: RowExpandedEvent<ITreeItem, string>): Promise<void> {
    event.component.beginCustomLoading(undefined);
    await this._updateItemTreeByKey(this.source, event.key, false);
    await event.component.getDataSource().reload();
    await event.component.refresh();
    event.component.endCustomLoading();
    event.component.repaint();
  }

  private _configModal(): Promise<void> {
    return this._trfBaService.getConfigFicheiro().then((response: HttpResponse<IJsonTrfBaConfig>) => {
      const modalInstance = this._cgModalService.showVanilla(TrfBaConfigModalComponent);
      const componentInstance: TrfBaConfigModalComponent = modalInstance.componentInstance;
      componentInstance.trfBaConfig = response.body;
    });
  }

  private async _gerarFicheiroModal({inputs, showOkCancel, showOkCancelMessage}: ITrfOpenGerarFicheiroModalParams, reloadParentNode: boolean): Promise<void> {
    if (showOkCancel) {
      await this._cgModalService.showOkCancel('global.text.confirmation', showOkCancelMessage);
    }
    const instance: NgbModalRef = this._cgModalService.showVanilla(TrfBaGeraFicheiroModalComponent);
    for (const key of Object.keys(inputs)) {
      instance.componentInstance[key] = inputs[key];
    }
    await instance.result;
    await this._afterChangeStatusFile(reloadParentNode);
  }

  private _afterChangeStatusFile(reloadParentNode: boolean = false): Promise<void> {
    this.showRGFormato = false;
    this._applyDataSource([]);
    this.rgFormato = ETrfBaRGFormato.PS2XML;
    if (reloadParentNode && this.itemTreeSelected) {
      const parentNode: Node<ITreeItem, string> = this._dataTreePlainInstance.getNodeByKey(this.itemTreeSelected.parentNodeID);
      if (parentNode) {
        return this._deepCollapseRow(parentNode.data.parentNodeID).then(() => {
          return this._dataTreePlainInstance.expandRow(parentNode.data.parentNodeID).then(() => {
            this.itemTreeSelected = undefined;
          });
        });
      }
    }
    return Promise.resolve();
  }

  private _evaluateColumnOptions(): void {
    const showFornecedores = this.itemTreeSelected?.nodeValue?.operacao !== ETrfOperacaoBancaria.Ordenados && this.itemTreeSelected?.nodeValue?.operacao !== ETrfOperacaoBancaria.TicketRefeicao;

    this._dataGridInstance.columnOption('nContaForn', 'visible', showFornecedores);
    this._dataGridInstance.columnOption('nContaForn', 'showInColumnChooser', showFornecedores);
    this._dataGridInstance.columnOption('nomeBeneficiario', 'visible', showFornecedores);
    this._dataGridInstance.columnOption('nomeBeneficiario', 'showInColumnChooser', showFornecedores);
    this._dataGridInstance.columnOption('codEmpregado', 'visible', !showFornecedores);
    this._dataGridInstance.columnOption('codEmpregado', 'showInColumnChooser', !showFornecedores);
    this._dataGridInstance.columnOption('nomeFuncionario', 'visible', !showFornecedores);
    this._dataGridInstance.columnOption('nomeFuncionario', 'showInColumnChooser', !showFornecedores);
    this._dataGridInstance.columnOption('valor', 'allowEditing', this.itemTreeSelected && !this.itemTreeSelected.nodeValue.estado);
  }

  private _applyDataSource(data: Array<IJsonTrfBa>): void {
    this.dataGridDefinition.dataSource = new ArrayStore({key: 'trfBaID', data: data});
  }

  private _applyDataSourceTreePlain(item: ITreeItem): Promise<void> {
    if (item.nodeId === ENVIADO || item.nodeId === PORENVIAR) {
      const estado: boolean = item.nodeValue === 1;
      return this._trfBaService.getExpandEstado(estado).then((response: HttpResponse<Array<ITrfBaItemTree>>) => {
        item.childNodes = [];
        for (const value of response.body) {
          value.trfBaNodeRef = ETrfBaNodeRef.Formato;
          item.childNodes.push({
            nodeText: value.formatotransferencia,
            nodeId: `${item.nodeId}${ETrfBaNodeRef.Formato}${value.formatotransferencia}`,
            nodeValue: value,
            childNodes: [],
            parentNodeID: item.nodeId,
            hasChildNodes: true
          });
        }
      });
    }

    if (item.nodeValue.trfBaNodeRef === ETrfBaNodeRef.Formato) {
      return this._trfBaService.getExpandBanco(item.nodeValue.estado, item.nodeValue.formatotransferencia, this._trfbaParams.moduleName).then((response: HttpResponse<Array<ITrfBaItemTree>>) => {
        item.childNodes = [];
        for (const value of response.body) {
          value.trfBaNodeRef = ETrfBaNodeRef.Operacao;
          const text: string = this._translateService.instant(`trfba.tree.operacao.op${value.operacao}`);
          item.childNodes.push({
            nodeText: text,
            nodeId: `${item.nodeId}${ETrfBaNodeRef.Operacao}${text}`,
            nodeValue: value,
            parentNodeID: item.nodeId,
            childNodes: [],
            hasChildNodes: true
          });
        }

        this._dataTreePlainInstance.refresh();
        this._dataTreePlainInstance.endCustomLoading();
      });
    }
    if (item.nodeValue.trfBaNodeRef === ETrfBaNodeRef.Operacao) {
      return this._trfBaService.getExpandOperacao(item.nodeValue.estado, item.nodeValue.formatotransferencia, item.nodeValue.operacao).then((response: HttpResponse<Array<ITrfBaItemTree>>) => {
        item.childNodes = [];
        for (const value of response.body) {
          value.trfBaNodeRef = ETrfBaNodeRef.Data;
          item.childNodes.push({
            nodeText: value.data,
            nodeId: `${item.nodeId}${ETrfBaNodeRef.Data}${value.data}`,
            nodeValue: value,
            parentNodeID: item.nodeId,
            childNodes: [],
            hasChildNodes: true
          });
        }
        this._dataTreePlainInstance.refresh();
        this._dataTreePlainInstance.endCustomLoading();
      });
    }
    if (item.nodeValue.trfBaNodeRef === ETrfBaNodeRef.Data) {
      return this._trfBaService
        .getExpandData(item.nodeValue.estado, item.nodeValue.formatotransferencia, item.nodeValue.operacao, item.nodeValue.data)
        .then((response: HttpResponse<Array<ITrfBaItemTree>>) => {
          item.childNodes = [];
          for (const value of response.body) {
            value.trfBaNodeRef = ETrfBaNodeRef.Iban;
            item.childNodes.push({
              nodeText: value.iban,
              nodeId: `${item.nodeId}${ETrfBaNodeRef.Iban}${value.iban}`,
              nodeValue: value,
              parentNodeID: item.nodeId,
              hasChildNodes: false
            });
          }
          this._dataTreePlainInstance.refresh();
          this._dataTreePlainInstance.endCustomLoading();
        });
    }
    return Promise.resolve();
  }

  private _customizeTextOperacao(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      return this._translateService.instant(`trfba.tree.operacao.op${Number(cellInfo.value)}`);
    }
    return cellInfo.valueText;
  }

  private _updateItemTreeByKey(list: Array<ITreeItem>, key: string, finded: boolean = false): Promise<void> {
    if (!finded) {
      for (const item of list) {
        if (item.nodeId === key) {
          finded = true;
          return this._applyDataSourceTreePlain(item);
        }
        if (item.hasChildNodes && item.childNodes.length > 0) {
          this._updateItemTreeByKey(item.childNodes, key, finded);
        }
      }
    }
    return Promise.resolve();
  }

  private readonly _fnCustomizeTextOperacao: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextOperacao(cellInfo);

  private _getNodeKeys(node: Node): Array<string> {
    let keys = [];
    keys.push(node.key);
    const children = node.children.filter((n) => n.hasChildren);
    children.forEach((item) => {
      keys = keys.concat(this._getNodeKeys(item));
    });
    return keys;
  }

  private _deepCollapseRow(nodeKey: string | number): Promise<unknown> {
    const promises: Array<Promise<unknown>> = [];
    const node: Node<ITreeItem, string> = this._dataTreePlainInstance.getNodeByKey(nodeKey);
    if (isDefinedNotNull(node)) {
      const keys = this._getNodeKeys(node);
      this._dataTreePlainInstance.beginUpdate();
      keys.forEach((key) => {
        promises.push(this._dataTreePlainInstance.collapseRow(key));
      });
    }
    return Promise.allSettled(promises).finally(() => {
      if (this._dataTreePlainInstance) {
        this._dataTreePlainInstance.endUpdate();
      }
    });
  }
}
