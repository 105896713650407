import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IntegracaoSalariosErrorsModalComponent} from '../errors/integracaoSalarios.errors.modal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {IntegracaoSalariosSimuladorModalComponent} from '../simulador/integracaoSalarios.simulador.modal.component';

@Component({
  selector: 'integracao-salarios-error-prompt-modal',
  templateUrl: './integracaoSalarios.error.prompt.modal.component.html'
})
export class IntegracaoSalariosErrorPromptModalComponent extends CGModalComponent<void> {
  @Input() public nEmpresa: string;
  @Input() public nomeEmpresa: string;
  @Input() public errorMessage: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
  }

  public viewErrorsModal(): void {
    const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosErrorsModalComponent, {size: 'xl', backdrop: 'static', keyboard: false});
    const componentInstance: IntegracaoSalariosErrorsModalComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = this.nEmpresa;
  }

  public viewSimulatorModal(): void {
    const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosSimuladorModalComponent, {size: 'xxl'});
    const componentInstance: IntegracaoSalariosSimuladorModalComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = this.nEmpresa;
    componentInstance.nomeEmpresa = this.nomeEmpresa;
  }
}
