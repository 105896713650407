import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {IListagemDiariosModel, MODULE_NAME_PCA_LISTAGEM_DE_DIARIOS} from './listagemDiarios.module.interface';
import {ListagemdiariosComponent} from './components/listagemDiarios.module.component';

export const MODULE_PCA_LISTAGEM_DE_DIARIOS: IModuleDefinition<IListagemDiariosModel> = {
  name: MODULE_NAME_PCA_LISTAGEM_DE_DIARIOS,
  nameAlias: ['dadoscontabilidade'],
  state: {
    url: '/listagemdiarios',
    component: ListagemdiariosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.listagemdiarios'
    }
  }
};
