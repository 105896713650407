import {Injectable, OnDestroy} from '@angular/core';
import type {IPlDynamicVisualsStack} from './dynamicvisuals.stacks.interface';

@Injectable({
  providedIn: 'root'
})
export class PlDynamicVisualsStacksService implements OnDestroy, IPlDynamicVisualsStack {
  private readonly _stacks: Set<IPlDynamicVisualsStack>;

  constructor() {
    this._stacks = new Set<IPlDynamicVisualsStack>();
  }

  public ngOnDestroy(): void {
    this.dismissAll();
  }

  public dismissAll(reason?: any): void {
    for (const stack of this._stacks) {
      stack.dismissAll(reason);
    }
    this._stacks.clear();
  }

  public hasOpenReferences(): boolean {
    for (const stack of this._stacks) {
      if (stack.hasOpenReferences()) {
        return true;
      }
    }
    return false;
  }

  public registerStack(stack: IPlDynamicVisualsStack): void {
    if (!this._stacks.has(stack)) {
      this._stacks.add(stack);
    }
  }

  public deregisterStack(stack: IPlDynamicVisualsStack): void {
    this._stacks.delete(stack);
  }
}
