import {IPlEditComponentOptionsInput} from 'pl-comps-angular';

export type TPreDefinidoContabFormulaType = 'valor' | 'descricao';

export type TPreDefinidoContabFormulaTransformer = (value: string, complex: boolean) => string;

export interface IPreDefinidoContabHeaderVariables {
  periodo: string;
  nomePeriodo: string;
}

export interface IPreDefinidoContabVariable {
  caption: string;

  click(...args: Array<any>): void;
}

export interface IPreDefinidoContabExtraVariables {
  valorPorPagar: string;
}

export interface IPreDefinidoContabOperator {
  caption: string;
  operator?: boolean;

  click(...args: Array<any>): void;
}

export interface IPreDefinidoContabFormulaRepeat {
  properties: Array<unknown>;
}

export interface IPreDefinidoContabFormulaField {
  name: string;
  type: string;
  caption: string;
  repeat?: Array<IPreDefinidoContabFormulaRepeat>;
  properties?: unknown;
  onChange?: string;
}

export interface IPreDefinidoContabFormulaBase {
  fields: Array<IPreDefinidoContabFormulaField>;
}

export interface IPreDefinidoContabFormulaBaseTributavelProperties {
  caption: string;
  fields: Array<IPreDefinidoContabFormulaField>;

  visible(model?): boolean;
}

export interface IPreDefinidoContabFormulaContaCorrenteProperties {
  caption: string;
  fields: Array<IPreDefinidoContabFormulaField>;

  visible(model): boolean;
}

export interface IPreDefinidoContabFormulaGroupObject {
  base: IPreDefinidoContabFormulaBase;
  baseTributavelProperties?: IPreDefinidoContabFormulaBaseTributavelProperties;
  contaCorrenteProperties?: IPreDefinidoContabFormulaContaCorrenteProperties;
}

export interface IPredefinidoContabFormulaOptionsInput extends IPlEditComponentOptionsInput<string> {
  formulaType?: TPreDefinidoContabFormulaType;
}

export interface IPredefinidoContabFormulaDialogParams {
  field: string;
  line: number;
}

export const predefinidoContabFormulaComponentName = 'predefinidocontabformula';
