import {IJsonQuadro5} from './jsonModelo10.module.interface';

export interface IQuadro5 extends IJsonQuadro5 {
  _index?: number;
  _isManuallyAdded?: boolean;
}

export enum ETipModelo10 {
  NewClean = 'NewClean',
  NewFromMOD10 = 'NewFromMOD10',
  NewFromDB = 'NewFromDB'
}

export const MODULE_NAME_MODELO_10 = 'modelo10';
