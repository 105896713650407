<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'portals.modals.sort.title'"></h4>
</div>

<div class="modal-body">
  <dx-list [dataSource]="dataSource" keyExpr="url" [itemTemplate]="'templateListItem'" (onInitialized)="onListInitialized($event)" (onItemReordered)="onListItemReordered()">
    <dxo-item-dragging [data]="dataSource" [allowReordering]="true" [dragDirection]="'vertical'"></dxo-item-dragging>

    <div *dxTemplate="let listItem of 'templateListItem'">
      <div class="d-flex align-items-center">
        <div class="list-item-text flex-grow-1">{{ listItem.text }}</div>
        <div class="list-item-actions">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-sm btn-light action-up" [disabled]="listItem.disableUp" [click]="reorderItem(listItem.index, listItem.index - 1)" plPromise>
              <i cgcIcon="fa-chevron-up"></i>
            </button>

            <button type="button" class="btn btn-sm btn-light action-down" [disabled]="listItem.disableDown" [click]="reorderItem(listItem.index, listItem.index + 1)" plPromise>
              <i cgcIcon="fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </dx-list>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-success" [onClick]="close" [disabled]="loading || closeDisabled"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
  <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
