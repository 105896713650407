<div class="acrescimosencargosferias">
  <pl-tabs [(activeId)]="activeTab" (activeIdChange)="changedTab()">
    <pl-tab [id]="'porEmpresa'" [caption]="'acrescimosencargosferias.porEmpresa'">
      <div *plTabContent>
        <acrescimos-enc-ferias-single [anoEncargo]="anoEncargo" [instanceNameToolbar]="instanceName" [nEmpresa]="nEmpresa" [callback]="acrescimosEncSingleCallback"></acrescimos-enc-ferias-single>
      </div>
    </pl-tab>

    <pl-tab [id]="'multiEmpresa'" [caption]="'acrescimosencargosferias.multiEmpresa'">
      <div *plTabContent>
        <pl-toolbar [instanceId]="toolbarAcrescimoEncargosMultiId"></pl-toolbar>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionMulti"
          cgDxDataGridInstanceName="acrescimosEncargosMultiEmp"
          [dataSource]="dataGridDefinitionMulti.dataSource"
          (selectedRowKeysChange)="onSelectedRowKeysMuliChanged($event)"
          (onInitialized)="onInitialized($event)">
          <div *dxTemplate="let item of 'cellTemplateBtnsMulti'">
            <div ngbDropdown [autoClose]="'outside'" container="body" #dropdown="ngbDropdown" class="col-main-table-actions">
              <button type="button" class="btn btn-link text-info col-main-table-btn-actions" ngbDropdownToggle (click)="getDocsInteg(item.data)"><i class="fa fa-bars"></i></button>

              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem (click)="openModalDetail(item.data.nEmpresa); dropdown.close()" plPromise>
                  <i class="fa fa-info-circle fa-fw">&nbsp;</i><span [translate]="'acrescimosencargosferias.btns.details'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="apagaEncargosMulti(item.data); dropdown.close()" plPromise>
                  <i class="fa fa-times fa-fw"></i>&nbsp;<span [translate]="'global.btn.remove'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="printEncargos(item.data.nEmpresa); dropdown.close()" plPromise>
                  <i class="fa fa-print fa-fw"></i>&nbsp;<span [translate]="'global.btn.print'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="openModalConfig(item.data.nEmpresa); dropdown.close()" plPromise>
                  <i class="fa fa-cog fa-fw"></i>&nbsp;<span [translate]="'global.btn.config'"></span>
                </button>
                <button
                  type="button"
                  ngbDropdownItem
                  ngbDropdown
                  container="body"
                  #subDropdown="ngbDropdown"
                  [disabled]="item.data.estado !== estadoAcresEncFeriasType.Integrado"
                  (mouseenter)="item.data.estado === estadoAcresEncFeriasType.Integrado && subDropdown.open()"
                  (click)="subDropdown.close()">
                  <div ngbDropdownAnchor><i class="fa fa-fw"></i>&nbsp;<span [translate]="'acrescimosencargosferias.btns.consultarLanc'"></span></div>
                  <div class="dropdown" ngbDropdownMenu>
                    <button type="button" *ngFor="let doc of docsContabIntegList" class="dropdown-item" (click)="previewDocContab(doc)">{{ doc.caption }}</button>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div *dxTemplate="let item of 'cellTemplateStatus'">
            <pl-tooltip
              *ngIf="item.data.estado === estadoAcresEncFeriasType.Integrado"
              [config]="{text: 'acrescimosencargosferias.estadoAcrescimoEncFerias.proceInteg', tooltipClass: 'tooltip-success', placement: 'left', container: 'body'}">
              <i *plTooltipContent class="fa fa-fw fa-check-circle text-success"></i>
            </pl-tooltip>
            <pl-tooltip
              *ngIf="item.data.estado === estadoAcresEncFeriasType.Processado"
              [config]="{text: 'acrescimosencargosferias.estadoAcrescimoEncFerias.proceNotInteg', tooltipClass: 'tooltip-warning', placement: 'left', container: 'body'}">
              <i *plTooltipContent class="fa fa-fw fa-exclamation-circle text-warning"></i>
            </pl-tooltip>
            <pl-tooltip
              *ngIf="item.data.estado === estadoAcresEncFeriasType.ComInconsistencias"
              [config]="{text: 'acrescimosencargosferias.estadoAcrescimoEncFerias.comInconsistencias', tooltipClass: 'tooltip-danger', placement: 'left', container: 'body'}">
              <i *plTooltipContent class="fa fa-fw fa-times-circle text-danger"></i>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'cellTemplateDescricaoErro'">
            <pl-tooltip [config]="{text: item.data.descricaoErro, placement: 'left', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-primary btn-xs" [disabled]="!item.data.descricaoErro" (click)="showMessageInfo(item.data.descricaoErro)" plPromise>
                <i class="fa fa-fw fa-info"></i>
              </button>
            </pl-tooltip>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
