import {merge} from 'lodash-es';
import {Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {StateDeclaration} from '@uirouter/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isBoolean, isDefined, isFunction, isObject, isString, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {CGStateService} from '../../../state/cg.state.service';
import {
  EEntityMaintenanceEditMode,
  EEntityMaintenanceListModalOnSelect,
  EEntityMaintenanceListModalResult,
  IEntityMaintenanceEditModalOptions,
  IEntityMaintenanceInstance,
  IEntityMaintenanceInstanceProperties,
  IEntityMaintenanceListModalOptions,
  TEntityMaintenanceActionMaintenanceEditFn,
  TEntityMaintenanceActionMaintenanceListFn
} from './entity.maintenance.interface';
import {EEntityStateDetailType, entityStateNameDetail, entityStateNameNew} from '../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceDetailModalComponent} from '../modal/detail/entity.maintenance.detail.modal.component';
import {EntityMaintenanceListModalComponent} from '../modal/list/entity.maintenance.list.modal.component';
import {EStatusCode} from '../../../../../config/constants';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {ICGModalOptions, TCGModalResult} from '../../../cg/modal/cgmodal.interface';
import {IEntity} from '../../entity.definition.interface';
import {MaintenanceWindowService} from '../maintenance.window.service';
import {ModuloEntityDetailComponent} from '../../../module/entitydetail/module.entitydetail.component';
import {ModuloEntityListComponent} from '../../../module/entitylist/module.entitylist.component';

export class EntityMaintenanceInstance<T extends object = object> implements IEntityMaintenanceInstance<T> {
  private readonly _plTranslateService: PlTranslateService;
  private readonly _cgModalService: CGModalService;
  private readonly _cgStateService: CGStateService;
  private readonly _maintenanceWindowService: MaintenanceWindowService;

  private readonly _entityName: string;
  private readonly _entityTitlePlural: string;
  private readonly _actionMaintenanceList: TEntityMaintenanceActionMaintenanceListFn<T>;
  private readonly _actionMaintenanceEdit: TEntityMaintenanceActionMaintenanceEditFn<T>;
  private readonly _maintenanceHeaderList: string;
  private readonly _maintenanceHeaderEdit: string;
  private _maintenanceAllowList: boolean;
  private _maintenanceAllowNew: boolean;
  private _maintenanceAllowEdit: boolean;
  private _stateMaintenanceList: StateDeclaration;
  private _stateMaintenanceNew: StateDeclaration;
  private _stateMaintenanceEdit: StateDeclaration;
  private _maintenanceHeader: string;
  private _stateMaintenance: StateDeclaration;

  constructor(
    private readonly _injector: Injector,
    private readonly _entity: IEntity,
    private readonly _properties?: IEntityMaintenanceInstanceProperties<T>
  ) {
    this._plTranslateService = this._injector.get<PlTranslateService>(PlTranslateService);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    this._cgStateService = this._injector.get<CGStateService>(CGStateService);
    this._maintenanceWindowService = this._injector.get<MaintenanceWindowService>(MaintenanceWindowService);

    this._entityName = this._entity.name;
    this._entityTitlePlural = this._plTranslateService.translate(`${this._entityName}.title_plural`);
    if (this._entityTitlePlural) {
      this._entityTitlePlural = this._entityTitlePlural.toLowerCase();
    }

    let captionMaintenanceList = 'entity.maintenance.headerSearch';
    let captionMaintenanceEdit = 'entity.maintenance.headerMaintenance';

    if (isObject(this._properties)) {
      this._actionMaintenanceList = this._properties.actionMaintenanceList;
      this._actionMaintenanceEdit = this._properties.actionMaintenanceEdit;
      if (this._properties.captionMaintenanceList) {
        captionMaintenanceList = this._properties.captionMaintenanceList;
      }
      if (this._properties.captionMaintenanceEdit) {
        captionMaintenanceEdit = this._properties.captionMaintenanceEdit;
      }
    }

    this._maintenanceHeaderList = this._plTranslateService.translate(captionMaintenanceList, {entityName: this._entityTitlePlural});
    this._maintenanceHeaderEdit = this._plTranslateService.translate(captionMaintenanceEdit, {entityName: this._entityTitlePlural});

    this.evaluateStates();
  }

  public maintenanceList<S extends ModuloEntityListComponent<T>>(options?: Partial<IEntityMaintenanceListModalOptions<T, S>>): Promise<T> {
    if (isFunction(this._actionMaintenanceList)) {
      return Promise.resolve(this._actionMaintenanceList());
    }
    this.evaluateStates();
    this._maintenanceHeader = this._maintenanceHeaderList;
    this._stateMaintenance = this._stateMaintenanceList;
    const promise: Promise<T> = <Promise<T>>(<unknown>(async () => {
      const modalOptions: ICGModalOptions<T> = merge({}, {size: 'xxl'}, options?.modalOptions);
      const modalInstance: TCGModalResult<T | EEntityMaintenanceListModalResult> = this._cgModalService.showVanilla(EntityMaintenanceListModalComponent, modalOptions);
      const componentInstance: EntityMaintenanceListModalComponent<T, S> = modalInstance.componentInstance;
      componentInstance.entityMaintenanceInstance = this;
      componentInstance.parentMaintenanceModal = this._maintenanceWindowService.peek();
      this._maintenanceWindowService.push(componentInstance);
      componentInstance.toolbarInstanceId = this._maintenanceWindowService.nextId();
      if (isObject(options)) {
        if (isObject(options.params)) {
          componentInstance.params = options.params;
        }
        if (isBoolean(options.preventDocumentKeyup)) {
          componentInstance.preventDocumentKeyup = options.preventDocumentKeyup;
        }
        if (isString(options.filter) && options.filter) {
          componentInstance.filter = options.filter;
        }
        if (isString(options.initialFilter) && options.initialFilter) {
          componentInstance.initialFilter = options.initialFilter;
        }
        if (isFunction(options.onMaintenanceReady)) {
          options.onMaintenanceReady({
            modalInstance: modalInstance,
            componentRefInstance: componentInstance.componentRefInstance,
            result: promise
          });
        }
      }
      let result: T | EEntityMaintenanceListModalResult = await modalInstance.result;
      this._maintenanceWindowService.pop();
      if (result) {
        switch (result) {
          case EEntityMaintenanceListModalResult.UNDEFINED:
            break;
          case EEntityMaintenanceListModalResult.ACTION_NEW:
            result = await this.maintenanceEdit(undefined, options);
            break;
          default:
            if (isObject(options) && options.onSelect === EEntityMaintenanceListModalOnSelect.EDIT) {
              const id: string | number = this.entity.getId(result);
              result = await this.maintenanceEdit(id, options);
            }
            break;
        }
      }
      return result;
    })());
    return promise;
  }

  public maintenanceNew<S extends ModuloEntityDetailComponent<T>>(options?: Partial<IEntityMaintenanceEditModalOptions<T, S>>): Promise<T> {
    return this.maintenanceEdit(undefined, options);
  }

  public maintenanceEdit<S extends ModuloEntityDetailComponent<T>>(id: string | number, options?: Partial<IEntityMaintenanceEditModalOptions<T, S>>): Promise<T> {
    if (isFunction(this._actionMaintenanceEdit)) {
      return Promise.resolve(this._actionMaintenanceEdit(id));
    }
    this.evaluateStates();
    this._maintenanceHeader = this._maintenanceHeaderEdit;
    let modalInstance: NgbModalRef;
    let componentInstance: EntityMaintenanceDetailModalComponent<T, S>;
    const modalOptions: ICGModalOptions<T> = merge({}, {size: 'xxl'}, options?.modalOptions);
    if (!this._maintenanceAllowEdit || (!id && id !== 0)) {
      this._stateMaintenance = this._stateMaintenanceNew;
      modalInstance = this._cgModalService.showVanilla(EntityMaintenanceDetailModalComponent, modalOptions);
      componentInstance = modalInstance.componentInstance;
      componentInstance.type = EEntityStateDetailType.NEW;
    } else {
      this._stateMaintenance = this._stateMaintenanceEdit;
      modalInstance = this._cgModalService.showVanilla(EntityMaintenanceDetailModalComponent, modalOptions);
      componentInstance = modalInstance.componentInstance;
      componentInstance.type = EEntityStateDetailType.EDIT;
      componentInstance.id = id;
    }
    componentInstance.entityMaintenanceInstance = this;
    componentInstance.parentMaintenanceModal = this._maintenanceWindowService.peek();
    this._maintenanceWindowService.push(componentInstance);
    componentInstance.toolbarInstanceId = this._maintenanceWindowService.nextId();
    if (isObject(options)) {
      componentInstance.editOptions = options;
      if (options.params) {
        componentInstance.params = options.params;
      }
      if (isBoolean(options.preventDocumentKeyup)) {
        componentInstance.preventDocumentKeyup = options.preventDocumentKeyup;
      }
      if (options.mode === EEntityMaintenanceEditMode.ReadOnly && componentInstance.type === EEntityStateDetailType.EDIT) {
        componentInstance.type = EEntityStateDetailType.DETAIL;
      }
      if (isFunction(options.onMaintenanceSave)) {
        componentInstance.onMaintenanceSave = (config: IApiRequestConfigWithBody<T>) => {
          return Promise.resolve(
            options.onMaintenanceSave({
              config: config,
              modalInstance: modalInstance,
              componentRefInstance: componentInstance.componentRefInstance,
              result: modalInstance.result
            })
          );
        };
      }
    }
    modalInstance.result
      .then((result: T) => {
        this._maintenanceWindowService.pop();
        return result;
      })
      .catch((reason: unknown) => {
        this._maintenanceWindowService.pop();
        if (
          reason instanceof HttpErrorResponse &&
          reason.status === EStatusCode.NotFound &&
          (componentInstance.type === EEntityStateDetailType.DETAIL || componentInstance.type === EEntityStateDetailType.EDIT)
        ) {
          return this.maintenanceNew(options);
        }
        return Promise.reject(reason);
      });
    return modalInstance.result;
  }

  public maintenanceSelectAndEdit<S extends ModuloEntityListComponent<T>>(options?: Partial<IEntityMaintenanceListModalOptions<T, S>>): Promise<T> {
    return this.maintenanceList({...options, onSelect: EEntityMaintenanceListModalOnSelect.EDIT});
  }

  public evaluateStates(): void {
    this._stateMaintenanceList = this._cgStateService.getRedirectState({stateOrName: this._entityName, exactMatch: true});
    this._maintenanceAllowList = isDefined(this._stateMaintenanceList);
    if (isObject(this._entity.actions)) {
      this._maintenanceAllowNew = this._entity.actions.new;
      if (this._maintenanceAllowNew) {
        this._stateMaintenanceNew = this._cgStateService.getRedirectState({stateOrName: entityStateNameNew(this._entityName)});
        this._maintenanceAllowNew = isDefined(this._stateMaintenanceNew);
      }
      this._maintenanceAllowEdit = this._entity.actions.edit;
      if (this._maintenanceAllowEdit) {
        this._stateMaintenanceEdit = this._cgStateService.getRedirectState({stateOrName: entityStateNameDetail(this._entityName)});
        this._maintenanceAllowEdit = isDefined(this._stateMaintenanceEdit);
      }
    }
  }

  public get maintenanceHeader(): string {
    return this._maintenanceHeader;
  }

  public get maintenanceHeaderList(): string {
    return this._maintenanceHeaderList;
  }

  public get maintenanceHeaderEdit(): string {
    return this._maintenanceHeaderEdit;
  }

  public get maintenanceAllowList(): boolean {
    return this._maintenanceAllowList;
  }

  public get maintenanceAllowNew(): boolean {
    return this._maintenanceAllowNew;
  }

  public get maintenanceAllowEdit(): boolean {
    return this._maintenanceAllowEdit;
  }

  public get stateMaintenance(): StateDeclaration {
    return this._stateMaintenance;
  }

  public get entity(): IEntity {
    return this._entity;
  }

  public get entityName(): string {
    return this._entityName;
  }
}
