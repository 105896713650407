import {Component, Injector} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IJsonDocContabImputacao} from '../../jsonDocComercial.entity.interface';
import {DocumentosService} from '../../service/documentos.entity.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DocumentoImputacoesDocContabModalComponent} from '../imputacoesDocContab/documento.imputacoesDocContab.modal.component';
import {FACCB_DEVOLUCAO_ERROR_CLASS, FACLI_DEVOLUCAO_ERROR_CLASS} from './documento.anular.modal.interface';

@Component({
  selector: 'documento-anular-modal',
  templateUrl: './documento.anular.modal.component.html'
})
export class DocumentoAnularModalComponent extends DocsComerciaisModalComponent {
  public motivo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _documentosService: DocumentosService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.motivo = '';
  }

  public async close(): Promise<void> {
    if (!this.motivo) {
      this._plAlertService.error('docscomerciais.erros.emptyMotivo');
      return Promise.reject(new Error('docscomerciais.erros.emptyMotivo'));
    }

    const responseImputacoes: HttpResponse<Array<IJsonDocContabImputacao>> = await this._documentosService.getImputacoesDocContab(this.doc.cab.faccbId);
    if (responseImputacoes.body.length) {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocumentoImputacoesDocContabModalComponent, {size: 'md'});
      const componentInstance: DocumentoImputacoesDocContabModalComponent = modalInstance.componentInstance;
      componentInstance.list = responseImputacoes.body;
      componentInstance.faccbId = this.doc.cab.faccbId;
      componentInstance.actionAnular = true;
      await modalInstance.result;
    }

    this.disableClose();
    return this.service
      .put({url: `${this.doc.cab.faccbId}/anulardoc`, params: {anulacaofisica: this.doc.cab.nDocumento === 0, motivoanulacao: this.motivo}, reportExceptions: false})
      .then((response) => {
        if (response.body) {
          this.doc.cab = response.body.cab;
        }
        this.enableClose();
        super.close(this.doc);
      })
      .catch((error) => {
        let errorMsg = '';
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          errorMsg = exception
            ? exception.class === FACCB_DEVOLUCAO_ERROR_CLASS || exception.class === FACLI_DEVOLUCAO_ERROR_CLASS
              ? 'docscomerciais.erros.anularDocumentoComNotaCredito'
              : exception.message
            : error.message;
          this._plAlertService.error(errorMsg);
        }
      })
      .finally(() => {
        this.enableClose();
      });
  }
}
