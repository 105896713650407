import dxDataGrid from 'devextreme/ui/data_grid';
import {TDate} from '../../../common/dates';
import {IDevExpressValidationCallbackData} from '../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IJsonVariaveisApuramento} from '../portalcontabilidade/manutencao/apuramentoresultados/jsonApuramentoResultados.module.interface';

export enum EDFRubricaTipo {
  None,
  Rubrica,
  Sep,
  SubRubricaCaption,
  MediaRubricaCaption,
  GrandeRubricaCaption,
  SubRubricaTotal,
  MediaRubricaTotal,
  GrandeRubricaTotal,
  GeralTotal
}

export interface IJsonDemFinSNCTranslatedHeader {
  parametro: string;
  traducao: string;
}

export interface IJsonDemFinSNCNotasItem {
  grupoNota: string;
  subGrupoNota: string;
  descricaoNota: string;
  valueNota: string;
  defaultValueNota: string;
  keyRegistoNota: string;
}

export interface IJsonDemFinSNCNotas {
  notasBalancoActivoNaoCorrenteList: Array<IJsonDemFinSNCNotasItem>;
  notasBalancoActivoCorrenteList: Array<IJsonDemFinSNCNotasItem>;
  notasBalancoCapitalProprioList: Array<IJsonDemFinSNCNotasItem>;
  notasBalancoPassivoNaoCorrenteList: Array<IJsonDemFinSNCNotasItem>;
  notasBalancoPassivoCorrenteList: Array<IJsonDemFinSNCNotasItem>;
  notasDemoResultPorNaturezaList: Array<IJsonDemFinSNCNotasItem>;
  notasDemoResultPorFuncoesList: Array<IJsonDemFinSNCNotasItem>;
  notasAlterCapitalProprioPerioList: Array<IJsonDemFinSNCNotasItem>;
  notasFluxoCaixaActividadesOperacionaisList: Array<IJsonDemFinSNCNotasItem>;
  notasFluxoCaixaActividadesInvestimentoList: Array<IJsonDemFinSNCNotasItem>;
  notasFluxoCaixaActividadesFinaciamentoList: Array<IJsonDemFinSNCNotasItem>;
}

export interface IJsonDemFinSNCRubricaDetalhe {
  nConta: string;
  nomeConta: string;
  tipoConta: string;
  anoN: number;
  anoN1: number;
  temCC: boolean;
}

export interface IJsonDemFinSNCRubricaTrace {
  nContaDe: string;
  nContaAte: string;
  mesPeriodo: string;
  taxonomyCode: number;
  tipoSaldo: number;
  anoN: number;
  anoN1: number;
  detalheList: Array<IJsonDemFinSNCRubricaDetalhe>;
}

export interface IJsonDemFinSNCRubrica {
  descricao: string;
  prop: string;
  formula: string;
  keyNotas: string;
  valueNotas: string;
  tipo: EDFRubricaTipo;
  positionIdx: number;
  defaultNotas: string;
  notas: string;
  anoN: number;
  anoN1: number;
  anoNEdited: boolean;
  anoN1Edited: boolean;
  traceList: Array<IJsonDemFinSNCRubricaTrace>;
  detalheList: Array<IJsonDemFinSNCRubricaDetalhe>;
}

export interface IJsonDemFinSNCRubricaTraceFCItem {
  codFluxo: string;
  designacao: string;
  anoN: number;
  anoN1: number;
}

export interface IJsonDemFinSNCRubricaFC extends IJsonDemFinSNCRubrica {
  traceListFC: Array<IJsonDemFinSNCRubricaTraceFCItem>;
}

export interface IJsonDemFinSNCRubricaEX extends IJsonDemFinSNCRubrica {
  capitalRealizado: number;
  accoesQuotasProprias: number;
  outrosInstrumentosCapProprio: number;
  premiosDeEmissao: number;
  reservasLegais: number;
  outrasReservas: number;
  resultadosTransitados: number;
  ajustamentosEmActivosFinanceirosOutrasVariacoes: number;
  excedentesDeRevalorizacao: number;
  resultadoLiquidoDoPeriodo: number;
  total: number;
  capitalRealizadoEdited: boolean;
  accoesQuotasPropriasEdited: boolean;
  outrosInstrumentosCapProprioEdited: boolean;
  premiosDeEmissaoEdited: boolean;
  reservasLegaisEdited: boolean;
  outrasReservasEdited: boolean;
  resultadosTransitadosEdited: boolean;
  ajustamentosEmActivosFinanceirosOutrasVariacoesEdited: boolean;
  excedentesDeRevalorizacaoEdited: boolean;
  resultadoLiquidoDoPeriodoEdited: boolean;
}

export interface IJsonDemFinSNCRubricaMain {
  me: boolean;
  loadedID: number;
  anoNCaption: string;
  anoN1Caption: string;
  rubricasBalanco: Array<IJsonDemFinSNCRubrica>;
  rubricasDRNat: Array<IJsonDemFinSNCRubrica>;
  rubricasDRFnc: Array<IJsonDemFinSNCRubrica>;
  rubricasFluxosCaixa: Array<IJsonDemFinSNCRubricaFC>;
  rubricasCapPropAnoN: Array<IJsonDemFinSNCRubricaEX>;
  rubricasCapPropAnoN1: Array<IJsonDemFinSNCRubricaEX>;
  demFinSNCVarApuramento: IJsonDemFinSNCVarApuramento;
  demFinSNCHeaderByLang: Array<IJsonDemFinSNCTranslatedHeader>;
}

export interface IJsonDemFinSNCSaved {
  idDemFinSNC: number;
  ano: number;
  data: TDate;
  hora: TDate;
  obs: string;
  me: boolean;
  intercalar: boolean;
  periodoIntercalar: string;
}

export interface IJsonDemFinSNCPeriodo {
  periodo: string;
  nome: string;
}

export interface IJsonDemFinSNCVarApuramento {
  apResultN: Array<IJsonVariaveisApuramento>;
  apResultN1: Array<IJsonVariaveisApuramento>;
}

export interface IJsonDemFinSNCLanguages {
  tipoLingua: number;
  nome: string;
}

export interface IJsonDemFinSNCInitialData {
  anosSource: Array<number>;
  empresaME: boolean;
  languages: Array<IJsonDemFinSNCLanguages>;
}

export type TJsonDemFinSNCRubricaValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, IJsonDemFinSNCRubrica, IJsonDemFinSNCRubrica>;
export type TJsonDemFinSNCNotasItemValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, IJsonDemFinSNCNotasItem, IJsonDemFinSNCNotasItem>;
