import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {IJsonPeriodo} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {ROLE} from '../../../../services/role.const';
import {MapaExploracaoComponent} from './components/mapaExploracao.module.component';
import {ENTITY_NAME_PERIODOS} from '../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {EmpresasService} from '../../../../services/empresas/empresas.service';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../../entities/empresas/empresas.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {MODULE_NAME_MAPA_EXPLORACAO} from './mapaExploracao.module.interface';

export const MODULE_MAPA_EXPLORACAO: IModuleDefinition = {
  name: MODULE_NAME_MAPA_EXPLORACAO,
  state: {
    url: '/mapaexploracao',
    component: MapaExploracaoComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.mapaExploracao'
    },
    resolve: [
      {
        provide: 'periodos',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPeriodo>> => {
          const servicePeriodos: IEntityService<IJsonPeriodo> = entityServiceBuilder.build<IJsonPeriodo>(ENTITY_NAME_PERIODOS);
          return servicePeriodos.query().then((response: THttpQueryResponse<IJsonPeriodo>) => response.body.list);
        }
      },
      {
        provide: 'empresaMesContab',
        deps: [EmpresasService],
        useFactory: (entityServiceBuilder: EmpresasService): Promise<IJsonPerioMudaPrimeiroMesContab> => {
          return entityServiceBuilder.getPerioMudaPrimeiroMesContab().then((response: HttpResponse<IJsonPerioMudaPrimeiroMesContab>) => response.body);
        }
      }
    ]
  }
};
