import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {CGC_GLOBAL_EVENT_CHANGED_LOCALE, TPlLocales} from './locale.interface';
import {IPlLocale} from './locales.interface';
import {PlGlobalEventsService} from '../globalevents/global.events.service';

import localeEN from './locales/en';
import localeES from './locales/es';
import localeFR from './locales/fr';
import localePT from './locales/pt-PT';

@Injectable({
  providedIn: 'root'
})
export class PlLocaleService implements OnDestroy {
  private readonly _locales: TPlLocales;
  private readonly _localeId: BehaviorSubject<string>;
  private readonly _localeData: BehaviorSubject<IPlLocale>;
  private _observableLocaleId: Observable<string>;
  private _observableLocaleData: Observable<IPlLocale>;

  constructor(
    @Inject(LOCALE_ID) private readonly _defaultLocaleId: string,
    private readonly _plGlobalEventsService: PlGlobalEventsService
  ) {
    this._locales = new Map<string, IPlLocale>([
      ['en', localeEN],
      ['es', localeES],
      ['fr', localeFR],
      ['pt-PT', localePT]
    ]);
    this._localeId = new BehaviorSubject<string>(this._defaultLocaleId);
    this._localeData = new BehaviorSubject<IPlLocale>(this._locales.get(this._localeId.value));
  }

  public ngOnDestroy(): void {
    this._localeId.complete();
    this._localeData.complete();
  }

  public applyLocale(localeId: string): boolean {
    if (!this._locales.has(localeId)) {
      return false;
    }
    this._localeId.next(localeId);
    const localeData: IPlLocale = this._locales.get(localeId);
    this._localeData.next(localeData);
    this._plGlobalEventsService.broadcast(CGC_GLOBAL_EVENT_CHANGED_LOCALE, {language: localeId, locale: localeData});
    return true;
  }

  public localeId(): Observable<string> {
    if (!this._observableLocaleId) {
      this._observableLocaleId = this._localeId.asObservable();
    }
    return this._observableLocaleId;
  }

  public locale(): Observable<IPlLocale> {
    if (!this._observableLocaleData) {
      this._observableLocaleData = this._localeData.asObservable();
    }
    return this._observableLocaleData;
  }
}
