import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAdiantamentoConfig} from '../../jsonAdiantamentos.module.interface';
import {AdiantamentosAutoConfigModalComponent} from '../autoconfig/adiantamentos.autoConfig.modal.component';
import {copy} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AdiantamentosService} from '../../adiantamentos.module.service';
import {IJsonArtigo} from '../../../../entities/artigos/jsonArtigo.entity.interface';

@Component({
  selector: 'modal-adiantamentos-configs',
  templateUrl: './adiantamentos.configs.modal.component.html'
})
export class AdiantamentosConfigsModalComponent extends CGModalComponent<IJsonAdiantamentoConfig> {
  @Input() public configAdiantamentos: IJsonAdiantamentoConfig;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _adiantamentosService: AdiantamentosService
  ) {
    super(_injector);
    this.configAdiantamentos = {
      familiaBase: undefined,
      nomeFamiliaBase: '',
      familia: undefined,
      nomeFamilia: '',
      nArtigoAdiantamento: '',
      nomeNArtigoAdiantamento: '',
      nArtigoRegAdiantamento: '',
      nomeNArtigoRegAdiantamento: '',
      infoFamilia: '',
      infoArtigoAdiantamento: '',
      infoArtigoRegAdiantamento: '',
      codIvaVenda: undefined,
      nomeCodIvaVenda: '',
      taxaIvaVenda: 0,
      codIvaCompra: undefined,
      nomeCodIvaCompra: '',
      taxaIvaCompra: 0
    };
    this.autoConfigModal = this.autoConfigModal.bind(this);
  }

  public close(): Promise<void> {
    return this._adiantamentosService.saveAdiantamentosConfig(this.configAdiantamentos).then(() => {
      super.close(this.configAdiantamentos);
    });
  }

  public artigoChanged(item: IJsonArtigo): void {
    this.configAdiantamentos.codIvaVenda = item.codIvaVenda;
    this.configAdiantamentos.nomeCodIvaVenda = item.nomeIvaVenda;
    this.configAdiantamentos.taxaIvaVenda = item.taxaIvaVenda;
    this.configAdiantamentos.codIvaCompra = item.codIvaCompra;
    this.configAdiantamentos.nomeCodIvaCompra = item.nomeIvaCompra;
    this.configAdiantamentos.taxaIvaCompra = item.taxaIvaCompra;
  }

  public autoConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AdiantamentosAutoConfigModalComponent);
    const componentInstance: AdiantamentosAutoConfigModalComponent = modalInstance.componentInstance;
    componentInstance.configAdiantamentos = this.configAdiantamentos ? copy(this.configAdiantamentos) : undefined;
    componentInstance.fromModalConfig = true;
    return modalInstance.result.then((result: IJsonAdiantamentoConfig) => {
      this.configAdiantamentos = result;
    });
  }
}
