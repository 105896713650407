import {EAreasRegionais} from '../../datasources/areasregionais/areasRegionais.datasource.interface';
import {EInvoiceType} from '../../datasources/invoicetype/invoiceType.datasource.interface';
import {ETiposIVA} from '../../datasources/tiposiva/tiposIVA.datasource.interface';
import {IJsonGDocAnexo} from '../../interfaces/jsonGDocAnexo.interface';
import {TDate} from '../../../common/dates';

export enum EDocOCRCabStatus {
  Automatico,
  ClassificadoPorUser,
  AEsperaDeOCR,
  LancadoAutomaticamente
}

export enum ETipoOrdenacaoVisualizador {
  NifData,
  DataNif,
  DataRececao,
  NomeData,
  DataNome,
  NomeFicheiro
}

export enum EEntidadeHabitual {
  Desconhecido,
  Clientes,
  Fornecedores
}

export enum ECDigitalOrigPeriodoLancAuto {
  TabelaEmpresa,
  DataDoc
}

export enum ETipoDocumentosIdentificadosContab {
  Nenhum,
  JaComAnexo,
  IdentificacaoDireta
}

export interface IJsonContabDigitalFile {
  docID: string;
  nome: string;
  folderID: string;
  folderPath: string;
  filePath: string;
  advancedDescription: string;
}

export interface IJsonContabDigitalFolder {
  folderID: string;
  folderParentID: string;
  name: string;
  folderPath: string;
  hasSubFolders: boolean;
  hasSubFiles: boolean;
  files: Array<IJsonContabDigitalFile>;
}

export interface IJsonContabDigitalFolderWithChildren extends IJsonContabDigitalFolder {
  childFolders: Array<IJsonContabDigitalFolderWithChildren>;
}

export interface IJsonDocOCR {
  cab: IJsonDocOCRCab;
  linhasIVA: Array<IJsonDocOCRLinhaIva>;
}

export interface IJsonDocOCRCab {
  docOCRCabID: string;
  docID: string;
  nif: string;
  temNIFEmpresa: boolean;
  dataDoc: TDate;
  nDocumento: string;
  totalIVA: number;
  totalBase: number;
  total: number;
  totaisObtidos: number;
  totaisOK: boolean;
  status: EDocOCRCabStatus;
  associadoADocContab: boolean;
  fiscalmenteRelevante: boolean;
  invoiceType: EInvoiceType;
  gDoc: IJsonGDocAnexo;
  validadoEFatura: boolean;
  preDefinidosID: number;
  temQRCode: boolean;
  valorRetencao: number;
  nContaEntidade: string;
  idDocOCRCabRepetidoNIFDoc: string;
  faccbId?: number;
}

export interface IJsonDocOCRLinhaIva {
  docOCRLinIVAID: string;
  docOCRCabID: string;
  tipoTaxa: ETiposIVA;
  areaRegional: EAreasRegionais;
  valorBase: number;
  valorIVA: number;
  taxa: number;
}

export interface IJsonDocDigitalizado extends IJsonDocOCRCab {
  folderID: string;
  folder: string;
  fileName: string;
  entidade: string;
  classificadoPor: string;
  nDocInterno: string;
  nDocumentoSAFT: string;
  erro: string;
  invoiceTypeStr: string;
  extPocCabID: string;
  dataRececao: TDate;
  isDataSuperiorAoMes: boolean;
  preDefinidoNome: string;
  extPocCabIDDocConciliacao: string;
  tipoDocsIdentContab: ETipoDocumentosIdentificadosContab;
}

export interface IJsonContabDigitalConfigs {
  anexaVerbete: boolean;
  reportVerbete: string;
  anexaDocPassaParaProximoNDoc: boolean;
  syncFolders: Array<IJsonContabDigitalConfigsPasta>;
  lastFolder: string;
  abreSempreVisualizador: boolean;
  ordenacaoVisualizador: ETipoOrdenacaoVisualizador;
  dataLancamIgualDataDoc: boolean;
  usaEFaturaOCR: boolean;
  recolhaAnexaAuto: boolean;
  searchEFaturaSoNdocParcial: boolean;
  searchEFaturaSoMesmoDiario: boolean;
  removePaginasBrancoAuto: boolean;
  lancaDocsAuto: boolean;
  modoLancarDocsAutoDisponivel: boolean;
  origemPeriodoLancAuto: ECDigitalOrigPeriodoLancAuto;
  usaDiarioConfigSugestLancam: boolean;
  listaNIFsDuplicarQRCode: Array<IJsonContabDigitalNIFDuplicarQRCode>;
}

export interface IJsonContabDigitalConfigsPasta {
  nomePasta: string;
  nDiario: number;
  nomeDiario: string;
  nDescritivo: number;
  nomeDescritivo: string;
  visivel: boolean;
  entidadeHabitual: EEntidadeHabitual;
  nCodRepcc: number;
  nomeCodRepcc: string;
  eFaturaOCR: boolean;
  fazOCR: boolean;
  sugereBanking: boolean;
  nDocfaFT: number;
  nomeDocfaFT: string;
  nDocfaFR: number;
  nomeDocfaFR: string;
  nDocfaNC: number;
  nomeDocfaNC: string;
  nDocfaNCPagaNoAto: number;
  nomeDocfaNCPagaNoAto: string;
  nCCusto: string;
  nomeCCusto: string;
  nRefProcesso: string;
  nomeProcesso: string;
}

export interface IJsonContabDigitalPreDefinidoConfig {
  nif: string;
  docsIDs: Array<string>;
  preDefinido: IJsonContabDigitalPreDefinido;
}

export interface IJsonContabDigitalNIFDuplicarQRCode {
  nif: string;
  descricao: string;
}

export interface IJsonContabDigitalDadosLicenca {
  nEmpresasTotal: number;
  nEmpresasConsumidas: number;
  nEmpresasPorConsumir: number;
}

export interface IJsonContabDigitalChaveSincronizacao {
  chaveSincronizacao: string;
}

export interface IJsonContabDigitalGDocViewerRecolhaFolder {
  folderID: string;
  folderParentID: string;
  folderName: string;
  folderPath: string;
  isFolderFromNaoClassificados: boolean;
  isFolderFromClassificados: boolean;
}

export interface IJsonContabDigitalGDocViewerRecolhaFolderAttachments extends IJsonContabDigitalGDocViewerRecolhaFolder {
  anexos: Array<IJsonDocOCR>;
}

export interface IJsonContabDigitalGDocViewerRecolhaSearch {
  nif?: string;
  nDocExterno?: string;
  dataDoc?: TDate;
  totalBase?: number;
  totalIVA?: number;
  totalDoc?: number;
}

export interface IJsonContabDigitalGDocViewerRecolhaSearchResult extends IJsonContabDigitalGDocViewerRecolhaFolderAttachments {
  oneHundredPercentMatch: boolean;
}

export interface IJsonContabDigitalPreDefinido {
  fatura: number;
  faturaSimplificada: number;
  faturaRecibo: number;
  notaDebito: number;
  notaCredito: number;
  vendaDinheiro: number;
  talaoVenda: number;
  talaoDevolucao: number;
  alienacaoAtivos: number;
  devolucaoAtivos: number;
  premio: number;
  estorno: number;
  imputacaoSeguradoras: number;
  imputacaoSegLider: number;
  recibo: number;
  pagamentoEstado: number;
  levantamento: number;
  pagamento: number;
  deposito: number;
  cheque: number;
  transferencia: number;
  nomeFatura: string;
  nomeFaturaSimplificada: string;
  nomeFaturaRecibo: string;
  nomeNotaDebito: string;
  nomeNotaCredito: string;
  nomeVendaDinheiro: string;
  nomeTalaoVenda: string;
  nomeTalaoDevolucao: string;
  nomeAlienacaoAtivos: string;
  nomeDevolucaoAtivos: string;
  nomePremio: string;
  nomeEstorno: string;
  nomeImputacaoSeguradoras: string;
  nomeImputacaoSegLider: string;
  nomeRecibo: string;
  nomePagamentoEstado: string;
  nomeLevantamento: string;
  nomePagamento: string;
  nomeDeposito: string;
  nomeCheque: string;
  nomeTransferencia: string;
  eFaturaFatura: boolean;
  eFaturaFaturaSimplificada: boolean;
  eFaturaFaturaRecibo: boolean;
  eFaturaNotaDebito: boolean;
  eFaturaNotaCredito: boolean;
  eFaturaVendaDinheiro: boolean;
  lancaDocsAuto: boolean;
  lancaDocsAutoCaption: string;
  nomeFornecedor: string;
}

export interface IJsonContabDigitalSemDocs {
  extPocCabID: string;
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  debito: number;
  credito: number;
  nDocExterno: string;
  dataDoc: TDate;
  dataDocExterno: TDate;
  descritivo: string;
  temDocDigital: boolean;
  descricao: string;
  contrib: string;
  contaCorrente: string;
  nomeContaCorrente: string;
}

export interface IJsonContabDigitalLancadoRobo {
  docsDigitalizados: Array<IJsonDocDigitalizado>;
  errorList: Array<string>;
  idsErrorList: Array<string>;
}

export interface IJsonContabDigitalAdicionarVerbeteData {
  extPocCabID: string;
  docOCRCabID?: string;
  folderID?: string;
}

export interface IJsonContabDigitalAssociaDoc {
  docID: string;
  folderID: string;
  extPocCabIDDocConciliacao: string;
  periodo: string;
  nDocInterno: string;
  nDiario: number;
  tipoDocumentosIdentificadosContab: ETipoDocumentosIdentificadosContab;
  fiscalmenteRelevante: boolean;
}

export interface IJsonContabDigitalAssociaDocSemelhante {
  nDocumento: string;
  nDocExterno: string;
  nContrib: string;
  descricao: string;
  extPocCabID: string;
  dataDocExt: TDate;
}
