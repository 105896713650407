import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {ProviderLike} from '@uirouter/core';
import {isBoolean, isObject} from 'pl-comps-angular';
import {ConfigService} from '../config/config.service';
import {ContabilidadeDigitalService} from './contabilidadedigital.service';
import {IJsonContabDigitalConfigs} from './jsonContabDigital.interface';

export const RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL: ProviderLike = {
  provide: 'empresaTemContabilidadeDigital',
  deps: [ConfigService],
  useFactory: (configService: ConfigService): Promise<boolean> => {
    return firstValueFrom(configService.configurationsAsObservable().pipe(take(1))).then((configurations) => configurations.empresa.temContabilidadeDigital);
  }
};

export const RESOLVER_CONTABILIDADE_DIGITAL: ProviderLike = {
  provide: 'contabilidadeDigital',
  deps: ['empresaTemContabilidadeDigital', ContabilidadeDigitalService],
  useFactory: (empresaTemContabilidadeDigital: boolean, contabilidadeDigitalService: ContabilidadeDigitalService): Promise<boolean> => {
    if (!empresaTemContabilidadeDigital) {
      return Promise.resolve(false);
    }
    return firstValueFrom(contabilidadeDigitalService.configs.getConfigs().pipe(take(1))).then((configs: IJsonContabDigitalConfigs) => {
      if (isObject(configs)) {
        return isBoolean(configs.abreSempreVisualizador) ? configs.abreSempreVisualizador : false;
      }
      return false;
    });
  }
};
