import {Component, Injector} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'motivo-anulacao-modal',
  templateUrl: './motivoAnulacao.modal.component.html'
})
export class MotivoAnulacaoModalComponent extends CGModalComponent<string> {
  public motivo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public close(): void {
    if (!this.motivo) {
      this._plAlertService.error('docscomerciais.erros.emptyMotivo');
      return;
    }
    super.close(this.motivo);
  }
}
