<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title"><strong>Rúbrica</strong>: {{ rubricaTitle }}</h4>
    </div>
    <div class="card-body">
      <pl-table [definition]="tableDef" [source]="tableSource" theme="style2" [properties]="tableOptions"></pl-table>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
