<div class="tirs">
  <entity-detail entityName="tirs" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, validators: {required: {value: true}}}">
        <pl-group>
          <label [translate]="'tirs.fields.nTabIrs'"></label>
          <edit>
            <pl-edit
              type="text"
              attrName="nTabIrs"
              [model]="model.nTabIrs"
              (modelChange)="nTabIrsChanged($event)"
              [properties]="{validators: {maxlength: {value: 4}}, disabled: type !== stateTypes.NEW}"
              plAutoFocus
              [plAutoFocusDisabled]="type !== stateTypes.NEW">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'tirs.fields.designaBreve'"></label>
          <edit>
            <pl-edit type="text" attrName="designaBreve" [(model)]="model.designaBreve"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'tirs.fields.designaCompl'"></label>
          <edit>
            <pl-edit type="text" attrName="designaCompl" [(model)]="model.designaCompl"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'tirs.fields.dtulTabela'"></label>
            <edit>
              <pl-edit type="date" attrName="dataVigor" [(model)]="model.dataVigor"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'tirs.fields.datasTaxasIrs'"></label>
            <edit>
              <pl-select
                [model]="currentDataTaxaIrs"
                [source]="datasEscaloes"
                (evtChanged)="dateDropDownChanged($event.item)"
                [rowTemplate]="'label'"
                [output]="'label'"
                [clearable]="false"></pl-select>
            </edit>
          </pl-group>
        </pl-group>

        <div class="tirs-table text-nowrap mt-3">
          <h6 [translate]="'tirs.table.title'"></h6>
          <dx-data-grid
            [cgDxDataGrid]="definitionEscaloesIRS"
            cgDxDataGridInstanceName="escaloesIRS"
            [dataSource]="tableIRSDT"
            (onInitialized)="onDataGridInitializedEscaloesIRS($event)"
            (onInitNewRow)="onDataGridNewRow($event)">
          </dx-data-grid>
        </div>
      </pl-form>
    </div>
  </entity-detail>
</div>
