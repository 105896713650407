import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {cgcFilter, isNumber, isObject} from 'pl-comps-angular';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {
  emptyUserInstallation,
  IUserInstallation,
  IUserInstallationAccess,
  IUserInstallationAccessCompany,
  IUserInstallationEventChangedConfigErpValue,
  IUserInstallationEventChangedConfigsErpValue,
  IUserInstallationEventChangedFilterValue,
  IUserInstallationEventChangedSelectAllCompanies,
  IUserInstallationEventChangedSelectCompany,
  IUserInstallationPolicy,
  IUserInstallationPolicyConfigERP,
  IUserInstallationPolicyLoadingError
} from './utilizadores.installation.interface';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {ICGUserAccessEvtChanged} from '../accesses/utilizadores.accesses.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEmpresa} from '../../../entities/empresas/empresas.entity.interface';
import {IJsonConfigERPUser} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {USER_INSTALLATIONS_COMPANY_GLOBAL, USER_INSTALLATIONS_PORTAL_GLOBAL} from './utilizadores.installations.interface';
import {UtilizadoresInstallationsService} from './utilizadores.installations.service';

const FILTER_VALUE_CACHE = 10;

@Component({
  selector: 'user-installation',
  templateUrl: './utilizadores.installation.component.html'
})
export class UtilizadoresInstallationComponent implements OnInit, OnChanges {
  @Input() public installation: IUserInstallation;
  @Input() public disabled: boolean;
  @Output() public readonly evtChangedFilterValue: EventEmitter<IUserInstallationEventChangedFilterValue>;
  @Output() public readonly evtChangedSelectAllCompanies: EventEmitter<IUserInstallationEventChangedSelectAllCompanies>;
  @Output() public readonly evtChangedSelectCompany: EventEmitter<IUserInstallationEventChangedSelectCompany>;
  @Output() public readonly evtChangedConfigErpValue: EventEmitter<IUserInstallationEventChangedConfigErpValue>;
  @Output() public readonly evtChangedConfigsErpValue: EventEmitter<IUserInstallationEventChangedConfigsErpValue>;
  @Output() public readonly evtChangedPolicies: EventEmitter<IUserInstallation>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly companyGlobal: string;
  public loadingAccesses: boolean;
  public loadingAccessesSuccess: Array<string>;
  public loadingAccessesErrors: Array<IUserInstallationPolicyLoadingError>;
  public loadingAccessesValue: number;
  public activeId: string;

  private readonly _filterValueCache: Map<string, Array<string>>;
  private readonly _filterValueCacheKeys: Array<string>;

  constructor(
    private readonly _utilizadoresInstallationsService: UtilizadoresInstallationsService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedFilterValue = new EventEmitter<IUserInstallationEventChangedFilterValue>();
    this.evtChangedSelectAllCompanies = new EventEmitter<IUserInstallationEventChangedSelectAllCompanies>();
    this.evtChangedSelectCompany = new EventEmitter<IUserInstallationEventChangedSelectCompany>();
    this.evtChangedConfigErpValue = new EventEmitter<IUserInstallationEventChangedConfigErpValue>();
    this.evtChangedConfigsErpValue = new EventEmitter<IUserInstallationEventChangedConfigsErpValue>();
    this.evtChangedPolicies = new EventEmitter<IUserInstallation>();
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nempresa', dataType: 'string', caption: 'usersbasic.text.nEmpresa', alignment: 'center', width: '100px'},
        {dataField: 'nome', dataType: 'string', caption: 'usersbasic.text.nomeEmpresa', headerAlignment: 'left', alignment: 'left'},
        {
          type: 'buttons',
          headerAlignment: 'left',
          alignment: 'left',
          width: '85px',
          headerCellTemplate: 'templateSelectionHeader',
          buttons: [{template: 'templateSelection'}],
          allowResizing: false
        }
      ],
      dataSource: [],
      keyExpr: 'nempresa',
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      paging: {enabled: true},
      remoteOperations: false,
      searchPanel: {
        visible: true,
        placeholder: this._translateService.instant('usersbasic.text.filterCompanies')
      },
      selection: {mode: 'none'}
    };
    this.companyGlobal = USER_INSTALLATIONS_COMPANY_GLOBAL;
    this.disabled = false;
    this.loadingAccesses = false;
    this.loadingAccessesSuccess = [];
    this.loadingAccessesErrors = [];
    this.activeId = USER_INSTALLATIONS_PORTAL_GLOBAL;
    this._filterValueCache = new Map<string, Array<string>>();
    this._filterValueCacheKeys = [];
  }

  public ngOnInit(): void {
    if (!isObject(this.installation)) {
      this.installation = emptyUserInstallation();
    }
    if (this.installation.dataSource.filterValue) {
      this.dataGridDefinition.searchPanel.text = this.installation.dataSource.filterValue;
    }
    if (isNumber(this.installation.dataSource.pageIndex)) {
      this.dataGridDefinition.paging.pageIndex = this.installation.dataSource.pageIndex;
    }
    if (isNumber(this.installation.dataSource.pageSize)) {
      this.dataGridDefinition.paging.pageSize = this.installation.dataSource.pageSize;
    }
  }

  public ngOnChanges({installation}: SimpleChanges): void {
    if (installation) {
      if (!isObject(installation.currentValue)) {
        this.installation = emptyUserInstallation();
      }

      const access: IUserInstallationAccess = this.installation.accesses.values.get(this.activeId);
      if (access) {
        this._evaluateFilterValue(access);
      }
    }
  }

  public changedNavPill(value: string): void {
    if (!this.dataGridDefinition.searchPanel.focusOnInit) {
      this.dataGridDefinition.searchPanel.focusOnInit = true;
    }
    this.activeId = value;
    const currentAccess: IUserInstallationAccess = this.installation.accesses.values.get(this.activeId);
    if (currentAccess) {
      this._evaluateFilterValue(currentAccess);
    }
  }

  public changedFilterValue(value: string): void {
    this.installation.dataSource.filterValue = this.dataGridDefinition.searchPanel.text = value;
    const access: IUserInstallationAccess = this.installation.accesses.values.get(this.activeId);
    this._evaluateFilterValue(access, value);
    this.evtChangedFilterValue.emit({
      installation: this.installation,
      value: value,
      access: access
    });
  }

  public changedSelectAllCompanies(value: boolean, access: IUserInstallationAccess): void {
    this.evtChangedSelectAllCompanies.emit({
      installation: this.installation,
      value: value,
      access: access
    });
  }

  public changedSelectCompany(value: boolean, company: IUserInstallationAccessCompany, access: IUserInstallationAccess, evaluate: boolean = true): void {
    this.evtChangedSelectCompany.emit({
      installation: this.installation,
      value: value,
      access: access,
      company: company,
      evaluate: evaluate
    });
  }

  public changedConfigErpValue(nEmpresa: string, event: ICGUserAccessEvtChanged): void {
    this.evtChangedConfigErpValue.emit({
      installation: this.installation,
      nEmpresa: nEmpresa,
      event: event
    });
  }

  public changedConfigsErpValue(nEmpresa: string): void {
    this.evtChangedConfigsErpValue.emit({
      installation: this.installation,
      nEmpresa: nEmpresa
    });
  }

  public loadPolicies(): void {
    if (this.loadingAccesses || !this.installation.policiesNeedLoading) {
      return;
    }
    this.loadingAccesses = true;
    this.loadingAccessesErrors = [];
    this.loadingAccessesValue = 1;
    let loadingAccessesValue = 1;
    const promises: Array<Promise<void>> = [];
    let errored = false;
    for (const nEmpresa of this.installation.policies.keys) {
      if (nEmpresa === USER_INSTALLATIONS_COMPANY_GLOBAL || this.loadingAccessesSuccess.includes(nEmpresa)) {
        continue;
      }
      promises.push(
        this._utilizadoresInstallationsService
          .getUserAccesses(this.installation.userId, nEmpresa)
          .then((userAccesses: Array<IJsonConfigERPUser>) => {
            this.loadingAccessesSuccess.push(nEmpresa);
            for (const politica of userAccesses) {
              for (const politicaEmpresa of politica.empresas) {
                const policy: IUserInstallationPolicy = this.installation.policies.values.get(politicaEmpresa.nempresa);
                if (policy) {
                  policy.loadingAccessesErrored = false;
                  const policyValue: IUserInstallationPolicyConfigERP = policy.configsErpMap.values.get(politica.name);
                  if (policyValue) {
                    policyValue.value = politicaEmpresa.value;
                    policyValue.originalValue = policyValue.value;
                  }
                }
              }
            }
          })
          .catch((reason: HttpErrorResponse) => {
            if (!errored) {
              errored = true;
            }
            const policy: IUserInstallationPolicy = this.installation.policies.values.get(nEmpresa);
            if (policy) {
              policy.loadingAccessesErrored = true;
            }
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            this.loadingAccessesErrors.push({nEmpresa: nEmpresa, message: exception.message});
          })
          .finally(() => {
            loadingAccessesValue++;
            this.loadingAccessesValue = Math.ceil((loadingAccessesValue * 100) / this.installation.policies.keys.length);
          })
      );
    }
    Promise.all(promises).then(() => {
      if (!errored) {
        this.installation.policiesNeedLoading = false;
      }
      this.loadingAccesses = false;
      this.evtChangedPolicies.emit(this.installation);
    });
  }

  public changedPageIndex(value: number): void {
    this.installation.dataSource.pageIndex = this.dataGridDefinition.paging.pageIndex = value;
  }

  public changedPageSize(value: number): void {
    this.installation.dataSource.pageSize = this.dataGridDefinition.paging.pageSize = value;
  }

  private _evaluateFilterValue(access: IUserInstallationAccess, value: string = this.installation.dataSource.filterValue): void {
    let filteredKeys: Array<string>;

    if (!value) {
      filteredKeys = access.keys.slice();
    } else {
      value = value.toLowerCase();
      filteredKeys = this._filterValueCache.get(value);
      if (!filteredKeys) {
        filteredKeys = cgcFilter(Array.from(access.values.values()), value).map<string>((accessCompany: IUserInstallationAccessCompany) => accessCompany.nempresa);
        this._filterValueCache.set(value, filteredKeys);
        this._filterValueCacheKeys.push(value);
        if (this._filterValueCache.size > FILTER_VALUE_CACHE) {
          this._filterValueCache.delete(this._filterValueCacheKeys.shift());
        }
      }
    }

    access.filteredKeys = filteredKeys;

    const source: Array<IEmpresa> = [];
    for (const key of access.filteredKeys) {
      source.push(access.values.get(key));
    }

    this.dataGridDefinition.dataSource = source;
  }
}
