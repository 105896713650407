<div class="avencas-edit">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <entity-detail
    entityName="contratosservicos"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [evaluationMethods]="evaluationMethods"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [(ngForm)]="form" [properties]="{orientation: 'horizontal', readonly: type === stateTypes.DETAIL, validators: {required: {value: true}}}">
        <pl-group>
          <label [translate]="'contratosservicos.fields.cab.nClasseCtr'"></label>
          <edit>
            <entity-autocomplete
              attrName="classesContratosServicos"
              entity="classescontratosservicos"
              [model]="classecontratoavenca"
              (modelChange)="onClasseContratoChanged($event)"
              [output]="'{{nClasseCtr}} - {{nomeClasseCtr}}'"
              outputKey="nClasseCtr"
              [properties]="{readonly: type !== stateTypes.NEW}"
              plAutoFocus
              [plAutoFocusDisabled]="type !== stateTypes.NEW">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'contratosservicos.fields.cab.nTipoContrato'"></label>
          <edit>
            <entity-autocomplete
              attrName="contratosTipos"
              entity="contratostiposervicos"
              [model]="contratotipoavenca"
              (modelChange)="onTipoContratoChanged($event)"
              [validateFn]="fnOnTipoContratoValidate"
              [output]="'{{nTipoContrato}} - {{nome}}'"
              outputKey="nTipoContrato"
              [filter]="filterTipoContrato"
              [properties]="{readonly: type !== stateTypes.NEW}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group *ngIf="type !== stateTypes.NEW">
            <label [translate]="'contratosservicos.fields.cab.nContrato'"></label>
            <edit>
              <pl-edit type="integer" attrName="nContrato" [(model)]="model.cab.nContrato" [properties]="{disabled: true, validators: {maxlength: {value: 11}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'contratosservicos.fields.cab.dataCtr'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataContrato" [(model)]="model.cab.dataCtr"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'contratosservicos.fields.cab.descricao'"></label>
          <edit>
            <pl-edit type="text" attrName="descricao" [(model)]="model.cab.descricao" [properties]="{validators: {maxlength: {value: 90}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'contratosservicos.fields.cab.dataInicio'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataInicioContrato" [(model)]="model.cab.dataInicio"></pl-edit-datepicker>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'contratosservicos.fields.cab.dataFim'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataFimContrato" [(model)]="model.cab.dataFim"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'contratosservicos.fields.cab.nClifo'"></label>
          <edit>
            <entity-autocomplete
              attrName="cliente"
              entity="clientes"
              [model]="model.cab"
              (modelChange)="onClifoChanged($event)"
              [selectedKey]="model.cab.nClifo"
              (selectedKeyChange)="model.cab.nClifo = $event"
              (evtSelectedDescriptionChanged)="model.cab.clifoNome = $event"
              [fieldsMap]="{nConta: 'nClifo', nome: 'clifoNome'}"
              output="nome"
              filter="desactivado<>1">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group *ngIf="optionShowGrupoConta">
            <label [translate]="'contratosservicos.fields.cab.idIdeChav'"></label>
            <edit>
              <entity-autocomplete
                attrName="idide"
                entity="idide"
                [model]="model.cab"
                (selectedKeyChange)="model.cab.idIdeChav = $event"
                (evtSelectedDescriptionChanged)="model.cab.idideDescricao = $event"
                [fieldsMap]="{descricao: 'idideDescricao'}"
                [properties]="{validators: {required: {value: false}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="optionShowMoeda">
            <label [translate]="'contratosservicos.fields.cab.codMoeda'"></label>
            <edit>
              <entity-autocomplete
                attrName="moeda"
                entity="moeda"
                [model]="model.cab"
                (selectedKeyChange)="model.cab.codMoeda = $event"
                (evtSelectedDescriptionChanged)="model.cab.moedaNome = $event"
                [fieldsMap]="{nomeMoeda: 'moedaNome'}"
                [properties]="{validators: {required: {value: false}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group *ngIf="optionShowCondComerciais">
            <label [translate]="'contratosservicos.fields.cab.codCondComerciais'"></label>
            <edit>
              <entity-autocomplete
                attrName="condcomerciais"
                entity="condicoescomerciais"
                [model]="model.cab"
                (selectedKeyChange)="model.cab.codCondComerciais = $event"
                (evtSelectedDescriptionChanged)="model.cab.condComerciaisNome = $event"
                [fieldsMap]="{descricao: 'condComerciaisNome'}"
                [properties]="{validators: {required: {value: false}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group class="refExterna-edit" *ngIf="optionShowRefExterna">
            <label [translate]="'contratosservicos.fields.cab.refExterna'"></label>
            <edit>
              <pl-edit type="text" attrName="refExterna" [(model)]="model.cab.refExterna" [properties]="{validators: {required: {value: false}, maxlength: {value: 30}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-tabs>
          <pl-tab id="tabLinhas">
            <div *plTabTitle><i class="fa fa-list fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'contratos.tabs.linhas'"></span></div>

            <div *plTabContent>
              <div class="table-responsive">
                <cdk-virtual-scroll-viewport
                  [itemSize]="virtualScrollRowHeight"
                  [minBufferPx]="virtualScrollMinBufferPx"
                  [maxBufferPx]="virtualScrollMaxBufferPx"
                  [style.height.px]="model.linhas.length >= virtualScrollRows ? virtualScrollHeightPx : virtualScrollRows + virtualScrollHeaderHeight + virtualScrollRowHeight * model.linhas.length">
                  <table class="table table-hover table-sm table-artigos" [plTableNavigation] [onCheckLine]="fnCheckLine" [onLastCell]="fnAddLine">
                    <thead>
                      <tr>
                        <th [translate]="'contratosservicos.fields.linhas.nArtigo'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.nomeArtigo'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.qtd1'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.prVenda'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.d1'" *ngIf="optionShowD1"></th>
                        <th [translate]="'contratosservicos.fields.linhas.codIva'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.taxaIva'"></th>
                        <th [translate]="'contratosservicos.fields.linhas.nArmazem'" *ngIf="optionShowArmazem"></th>
                        <th [translate]="'contratosservicos.fields.linhas.cunimo'" *ngIf="optionShowUnidadeMovimento"></th>
                        <th [translate]="'contratosservicos.fields.linhas.nVendedor'" *ngIf="optionShowVendedor"></th>
                        <th [translate]="'contratosservicos.fields.linhas.ccusto'" *ngIf="optionShowNCCusto"></th>
                        <th [translate]="'contratosservicos.fields.linhas.valorLiquido'"></th>
                        <th *ngIf="type !== stateTypes.DETAIL">
                          <button type="button" class="btn btn-primary btn-xs" (click)="addLine()">
                            <i class="fa fa-fw fa-plus"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *cdkVirtualFor="let item of model.linhas; trackBy: trackByIndex; index as _index">
                        <td style="min-width: 180px; width: 15%">
                          <entity-autocomplete
                            entity="artigos"
                            attrName="artigo"
                            [selectedKey]="item.nArtigo"
                            (modelChange)="artigoChanged($event, item)"
                            [output]="'nArtigo'"
                            [filter]="'artDesactivado=false&artBloqueado=false'"
                            [properties]="{allowInvalid: false}"
                            plAutoFocus
                            [plAutoFocusDisabled]="preventAutoFocus">
                          </entity-autocomplete>
                        </td>
                        <!--NARTIGO-->

                        <td style="min-width: 300px; width: 30%">
                          <pl-edit type="text" attrName="nomeArtigo" [model]="item.nomeArtigo" (modelChange)="item.nomeArtigo = $event"></pl-edit>
                        </td>
                        <!--NOME ARTIGO-->

                        <td style="min-width: 180px; width: 11%">
                          <pl-edit
                            type="number"
                            attrName="quantidade"
                            [model]="item.qtd1"
                            (modelChange)="onQdt1Changed($event, item)"
                            [inputClass]="'text-end'"
                            [properties]="propertiesEditDecimalsQtdLimit">
                          </pl-edit>
                        </td>
                        <!--QTD-->

                        <td style="min-width: 200px; width: 12%">
                          <pl-edit
                            type="number"
                            attrName="precoVenda"
                            [model]="item.prVenda"
                            (modelChange)="onPrVendaChanged($event, item)"
                            [inputClass]="'text-end'"
                            [properties]="propertiesEditDecimalsValoresUnitariosLimit">
                          </pl-edit>
                        </td>
                        <!--PRECO-->

                        <td style="min-width: 200px; width: 12%" *ngIf="optionShowD1">
                          <pl-edit
                            type="number"
                            attrName="D1"
                            [model]="item.d1"
                            (modelChange)="onD1Changed($event, item)"
                            [inputClass]="'text-end'"
                            [properties]="propertiesEditDecimalsValoresUnitariosLimit">
                          </pl-edit>
                        </td>
                        <!--D1-->

                        <td style="min-width: 150px; width: 6%">
                          <entity-autocomplete entity="ivas" [model]="item.codIva" [(selectedKey)]="item.codIva" [outputKey]="'codIva'" [output]="'codIva'" (modelChange)="codIvaChanged($event, item)">
                          </entity-autocomplete>
                        </td>
                        <!--CODIVA-->

                        <td style="min-width: 80px; width: 6%">
                          <pl-edit type="text" [inputClass]="'text-end'" [model]="item.taxaIva" [properties]="{disabled: true}"></pl-edit>
                        </td>
                        <!--TAXAIVA-->

                        <td style="min-width: 150px" *ngIf="optionShowArmazem">
                          <entity-autocomplete entity="armazens" attrName="armazem" [output]="'nArmazem'" [outputKey]="'nArmazem'" [(selectedKey)]="item.nArmazem"></entity-autocomplete>
                        </td>
                        <!--NARMAZEM-->

                        <td style="min-width: 150px" *ngIf="optionShowUnidadeMovimento">
                          <entity-autocomplete
                            entity="unidadesmovimento"
                            attrName="codUnidMov"
                            [model]="item.cunimo"
                            [output]="'codUni'"
                            [outputKey]="'codUni'"
                            [(selectedKey)]="item.cunimo"
                            [getDataConfig]="{params: {nArtigo: item.nArtigo}}">
                          </entity-autocomplete>
                        </td>
                        <!--CODUNIDMOV-->

                        <td style="min-width: 150px" *ngIf="optionShowVendedor">
                          <entity-autocomplete
                            entity="vendedores"
                            attrName="vendedor"
                            [model]="item.nVendedor"
                            [output]="'nVendedor'"
                            [outputKey]="'nVendedor'"
                            [(selectedKey)]="item.nVendedor"
                            inputClass="input-sm">
                          </entity-autocomplete>
                        </td>
                        <!--NVENDEDOR-->

                        <td style="min-width: 200px" *ngIf="optionShowNCCusto">
                          <entity-autocomplete
                            entity="ccustos"
                            [model]="item.ccusto"
                            [(selectedKey)]="item.ccusto"
                            [outputKey]="'nCCusto'"
                            [output]="'nCCusto'"
                            inputClass="input-sm"
                            [filter]="'encerrado=0'">
                          </entity-autocomplete>
                        </td>
                        <!--NCCUSTO-->

                        <td style="min-width: 150px">
                          <pl-edit type="number" [(model)]="item.valorLiquido" [inputClass]="'text-end'" [properties]="propertiesEditDecimalsValoresUnitariosDisabledLimit"></pl-edit>
                        </td>
                        <!--LIQUIDO-->

                        <td class="actions-buttons" *ngIf="type !== stateTypes.DETAIL" style="min-width: 10px; width: 5%">
                          <button type="button" class="btn btn-danger btn-xs" (click)="remove(_index)">
                            <i class="fa fa-fw fa-trash-o"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </cdk-virtual-scroll-viewport>
              </div>

              <div class="row">
                <div class="col-md-6 resumo-wrapper" style="margin-left: auto">
                  <hr class="d-lg-none d-xl-none" style="border-top-width: 2px" />
                  <div class="resumo">
                    <h4 class="page-header-cg" [translate]="'contratos.totais.resumo'"></h4>
                    <dl class="total-doc">
                      <dt [translate]="'global.text.total'"></dt>
                      <dd>{{ this.totalContrato | cgcDecimal }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </div>
  </entity-detail>
</div>
