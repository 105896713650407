import 'moment';
import 'moment/locale/pt';
import 'moment/locale/fr.js';

import {enableMapSet} from 'immer';
import {NgModuleRef, NgZone} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {UIRouter, UrlService} from '@uirouter/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import devExtremeConfig from 'devextreme/core/config';
import {loadMessages, locale as devextremeLocale} from 'devextreme/localization';

import {APP_LOCALE} from './config/constants';
import {AppModule} from './app.module';
import {CGStateService} from './core/components/state/cg.state.service';
import {DEV_EXTREME_LICENSE_KEY} from '../environments/constants';
import {LOCALE_CURRENCY_CODES_MAP, LOCALE_DEFAULT_CURRENCY_CODE} from './common/i18n/locales';

import localeDataAngularEN from '@angular/common/locales/en';
import localeDataAngularES from '@angular/common/locales/es';
import localeDataAngularFR from '@angular/common/locales/fr';
import localeDataAngularPTPT from '@angular/common/locales/pt-PT';

import localeDataDevExtremeEN from 'devextreme/localization/messages/en.json';
import localeDataDevExtremeES from 'devextreme/localization/messages/es.json';
import localeDataDevExtremeFR from 'devextreme/localization/messages/fr.json';
import localeDataDevExtremePT from 'devextreme/localization/messages/pt.json';

const locale = APP_LOCALE.value;

let localeDataAngular: unknown;
let localeDataDevExtreme: unknown;

switch (locale) {
  case 'en':
    localeDataAngular = localeDataAngularEN;
    localeDataDevExtreme = localeDataDevExtremeEN;
    break;
  case 'es':
    localeDataAngular = localeDataAngularES;
    localeDataDevExtreme = localeDataDevExtremeES;
    break;
  case 'fr':
    localeDataAngular = localeDataAngularFR;
    localeDataDevExtreme = localeDataDevExtremeFR;
    break;
  case 'pt-PT':
    localeDataAngular = localeDataAngularPTPT;
    localeDataDevExtreme = localeDataDevExtremePT;
    break;
}

// Angular locale data
registerLocaleData(localeDataAngular, locale);

// Dev express locale data
loadMessages(localeDataDevExtreme);
devextremeLocale(locale);
devExtremeConfig({licenseKey: DEV_EXTREME_LICENSE_KEY, defaultCurrency: LOCALE_CURRENCY_CODES_MAP.get(locale) || LOCALE_DEFAULT_CURRENCY_CODE});

// PDFJS necessary path definitions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>window).pdfWorkerSrc = '/assets/scripts/pdf.worker.mjs';

// ImmerJS
enableMapSet();

platformBrowserDynamic()
  .bootstrapModule(AppModule, {ngZoneEventCoalescing: false, ngZoneRunCoalescing: false})
  .then((module: NgModuleRef<AppModule>) => {
    const ngZone: NgZone = module.injector.get<NgZone>(NgZone);
    ngZone.run(() => {
      // Init `CGStateService` constructor so it will catch first ui-router transition
      module.injector.get<CGStateService>(CGStateService);

      // Start ui-router url handling
      const urlService: UrlService = module.injector.get<UIRouter>(UIRouter).urlService;
      urlService.listen();
      urlService.sync();
    });
  })
  .catch((error: unknown) => {
    console.error(error);
  });
