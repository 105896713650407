import {Component, Injector} from '@angular/core';
import {DocContabilidadeFieldInput} from '../docContabilidade.field.input';
import {EDocContabilidadeField} from '../../docContabilidade.interface';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'doccontabilidade-data-doc',
  templateUrl: './docContabilidade.dataDoc.component.html'
})
export class DocContabilidadeDataDocComponent extends DocContabilidadeFieldInput<TDate> {
  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.DATA_DOC, _injector);
  }

  public modelChanged(value: TDate): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.dataDoc
    });
  }
}
