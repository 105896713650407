import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHAgenciasAgencia, IJsonRHAgenciasConta} from './jsonRHAgencias.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHAgenciasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhagencias`;
  }

  public getAgencias(codbanco: string, onlyiban: boolean = false, onlyativos: boolean = true): TServiceResponse<Array<IJsonRHAgenciasAgencia>> {
    return this._apiService.get<Array<IJsonRHAgenciasAgencia>>({
      url: `${this._path}/bancos/${codbanco}/agencias`,
      params: {
        onlyiban: onlyiban,
        onlyativos: onlyativos
      }
    });
  }

  public saveAgencia(codbanco: string, agencia: IJsonRHAgenciasAgencia): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHAgenciasAgencia>({
      url: `${this._path}/bancos/${codbanco}/agencias`,
      body: agencia
    });
  }

  public updateAgencia(codbanco: string, codagencia: string, agencia: IJsonRHAgenciasAgencia): TServiceResponse<void> {
    return this._apiService.put<void, IJsonRHAgenciasAgencia>({
      url: `${this._path}/bancos/${codbanco}/agencias/${codagencia}`,
      body: agencia
    });
  }

  public saveConta(codbanco: string, codagencia: string, conta: IJsonRHAgenciasConta): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHAgenciasConta>({
      url: `${this._path}/bancos/${codbanco}/agencias/${codagencia}/contas`,
      body: conta
    });
  }

  public updateConta(codbanco: string, codagencia: string, iban: string, conta: IJsonRHAgenciasConta): TServiceResponse<void> {
    return this._apiService.put<void, IJsonRHAgenciasConta>({
      url: `${this._path}/bancos/${codbanco}/agencias/${codagencia}/contas/${iban}`,
      body: conta
    });
  }

  public deleteAgencia(codbanco: string, codagencia: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/bancos/${codbanco}/agencias/${codagencia}`
    });
  }

  public deleteConta(codbanco: string, codagencia: string, iban: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/bancos/${codbanco}/agencias/${codagencia}/contas/${iban}`
    });
  }
}
