import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {
  ETipoDiferimento,
  ETipoOperacaoDiferimentos,
  IJsonCalculoDiferimentos,
  IJsonCalculoDiferimentosConfig,
  IJsonInfoDiferimentos,
  IJsonInfoGlobalDiferimentosAcrescimos
} from './jsonCalculoDiferimentos.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IHeaderCalculoDiferimentos, IMapaDiferimentosAcrescimosGeral} from './diferimentos.module.interface';
import {TDate} from '../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class DiferimentosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/calculodiferimentos`;
  }

  public calculaDiferimentos(calculoDiferimentos: IHeaderCalculoDiferimentos): TServiceResponse<Array<IJsonCalculoDiferimentos>> {
    return this._apiService.get<Array<IJsonCalculoDiferimentos>>({
      url: this._path,
      params: {
        tipodiferimento: calculoDiferimentos.tipodiferimento,
        dataini: calculoDiferimentos.dataini,
        datafim: calculoDiferimentos.datafim,
        valor: calculoDiferimentos.valor,
        ncasasarredondamento: calculoDiferimentos.ncasasarredondamento
      }
    });
  }

  public getInfoDiferimentos(extpoccabid: string): TServiceResponse<IJsonInfoDiferimentos> {
    return this._apiService.get<IJsonInfoDiferimentos>({url: `${this._path}/infodiferimentos`, params: {extpoccabid: extpoccabid}});
  }

  public getInfoGlobalDiferimentosAcrescimos(tipooperacao: ETipoOperacaoDiferimentos): TServiceResponse<IJsonInfoGlobalDiferimentosAcrescimos> {
    return this._apiService.get<IJsonInfoGlobalDiferimentosAcrescimos>({url: `${this._path}/infoglobal`, params: {tipooperacao: tipooperacao}});
  }

  public getMapaDiferimentosAcrescimos(tipoDiferimento: ETipoDiferimento, tipoOperacao: ETipoOperacaoDiferimentos, ano: number): TServiceResponse<Array<IMapaDiferimentosAcrescimosGeral>> {
    return this._apiService.get<Array<IMapaDiferimentosAcrescimosGeral>>({url: `${this._path}/mapa`, params: {tipodiferimento: tipoDiferimento, tipooperacao: tipoOperacao, ano: ano}});
  }

  public validateDiferimento(dataini: TDate, datafim: TDate): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._path}/validate`,
      params: {
        dataini: dataini,
        datafim: datafim
      }
    });
  }

  public getConfigsCustDif(): TServiceResponse<IJsonCalculoDiferimentosConfig> {
    return this._apiService.get<IJsonCalculoDiferimentosConfig>({url: `${this._path}/configs`});
  }

  public saveConfigsCustDif(custdif: IJsonCalculoDiferimentosConfig): TServiceResponse<void> {
    return this._apiService.put<void, IJsonCalculoDiferimentosConfig>({url: `${this._path}/configs`, body: custdif});
  }
}
