import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IEFaturaComunicaDocumento} from '../../eFatura.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

const DATAGRID_INSTANCE_NAME = 'comunicaDocsModalDataGrid';
const MAIN_DATAGRID_KEY_ID = 'nDocNormalizado';

@Component({
  selector: 'comunica-docs-modal',
  templateUrl: './comunicaDocs.modal.component.html'
})
export class ComunicaDocsModalComponent extends CGModalComponent<Array<number>> implements OnInit {
  @Input() public docsList: Array<IEFaturaComunicaDocumento>;

  public readonly definition: IDevExpressDataGrid;
  public readonly dataGridInstanceName: string;
  private _dataGridInstance: dxDataGrid;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.dataGridInstanceName = DATAGRID_INSTANCE_NAME;
    this._isFirstTime = true;
    this.definition = {
      keyExpr: MAIN_DATAGRID_KEY_ID,
      selection: {
        mode: 'multiple',
        showCheckBoxesMode: 'always'
      },
      columns: [
        {dataField: 'tipoSAFT', caption: 'efatura.table.tipoSAFT'},
        {dataField: 'nDocNormalizado', caption: 'efatura.table.docNumber'},
        {dataField: 'nif', caption: 'efatura.table.nif'},
        {dataField: 'nomeClifo', caption: 'efatura.table.nomeClifo'},
        {
          dataField: 'valorIVA',
          caption: 'efatura.table.valorIVA',
          dataType: 'currency',
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          dataField: 'valorTributavel',
          caption: 'efatura.table.valorTributavel',
          dataType: 'currency',
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          dataField: 'valorParaSAFT',
          caption: 'efatura.table.valorParaSAFT',
          dataType: 'currency',
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          dataField: 'valorTotal',
          caption: 'efatura.table.valorTotal',
          dataType: 'currency',
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {dataField: 'estadoText', caption: 'efatura.table.estado'},
        {dataField: 'comunicadoText', caption: 'efatura.table.comunicado'},
        {dataField: 'erroNumero', caption: 'efatura.table.erros'}
      ]
    };
  }

  public ngOnInit(): void {
    for (const doc of this.docsList) {
      doc.estadoText = this._plTranslateService.translate(doc.estado);
      doc.comunicadoText = this._plTranslateService.translate(doc.comunicado);
    }
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this._isFirstTime) {
      this._dataGridInstance.selectAll();
      this._isFirstTime = false;
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public close(): void {
    const docsFaccabIDs: Array<number> = this._dataGridInstance.getSelectedRowsData().map<number>((item: IEFaturaComunicaDocumento) => item.faccbId);
    super.close(docsFaccabIDs);
  }
}
