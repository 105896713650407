import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MovimentosPendentesFornecedoresModuleComponent} from './components/movimentos.pendentes.fornecedores.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_FORNCEDORES: IModuleDefinition = {
  name: 'listagensMovimentosPendentesFornecedores',
  state: {
    url: '/listagens/movimentospendentes/fornecedores',
    component: MovimentosPendentesFornecedoresModuleComponent,
    data: {
      roles: [ROLE.ERP, ROLE.FINANCEIRO],
      sidebarTitle: 'global.text.movimentosPendentes'
    }
  }
};
