import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlTabsCallback, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAssistenteTrabAndMascaras, IJsonConfigRHDGEMPRequiredFields, IJsonMascaraCCustoPorCategoria} from '../../jsonRHConfiguracoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

const TAB_ID_ASSISTENTETRABALHADORINTEGSALARIOS = 'integracaosalarios';
const TAB_ID_ASSISTENTETRABALHADORMASCARAABONO = 'mascaraanaliticaabono';
const TAB_ID_ASSISTENTETRABALHADORMASCARADESCONTO = 'mascaraanaliticadesconto';

@Component({
  selector: 'module-rh-integracaosalarios',
  templateUrl: './rhIntegracaoSalarios.module.component.html'
})
export class RHIntegracaoSalariosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public model: IJsonConfigRHDGEMPRequiredFields;

  public readonly rhassistentetrabalhadorTabCallback: IPlTabsCallback;
  public assistenteTrabalhadorMascaraAbono: Array<IJsonMascaraCCustoPorCategoria>;
  public assistenteTrabalhadorMascaraDesconto: Array<IJsonMascaraCCustoPorCategoria>;
  public activeTab: string;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.assistenteTrabalhadorMascaraAbono = [];
    this.assistenteTrabalhadorMascaraDesconto = [];
    this.rhassistentetrabalhadorTabCallback = {};
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        funcao: false,
        integraSalarios: false,
        reparticaoCentroCusto: false,
        codCCT: false,
        operacaoSeccao: false,
        nivelQualif: false,
        pocRemuLiq: false,
        categoria: false,
        centroCusto: false,
        subDepartamento: false,
        integraRU: false,
        situacaoProfissao: false,
        departamento: false,
        codZona: false,
        classeTrab: false,
        profissao: false,
        integraSalariosPorDefeito: false,
        integraSalariosPorOrgaoSocial: false,
        validaMascara: false,
        mascaraAnalitica: ''
      };
    }
    this.btnSave.click = () => this._saveAssistente();
    this.btnSave.visible = true;
    this.toolbar.addButton({
      order: this.btnSave.order + 1,
      id: 'btnRepor',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      class: 'btn-light',
      caption: 'rhintegracaosalarios.btn.repor',
      click: () => this._reporAssistente()
    });
  }

  public changedTab(tabId: string): void {
    if (tabId === TAB_ID_ASSISTENTETRABALHADORINTEGSALARIOS) {
      this.activeTab = TAB_ID_ASSISTENTETRABALHADORINTEGSALARIOS;
    } else if (tabId === TAB_ID_ASSISTENTETRABALHADORMASCARAABONO) {
      this.activeTab = TAB_ID_ASSISTENTETRABALHADORMASCARAABONO;
      if (this.assistenteTrabalhadorMascaraAbono.length < 1) {
        this.promise = this._rhConfiguracoesService.getAssistenteTrabalhadorMascaraAbono().then((response: HttpResponse<Array<IJsonMascaraCCustoPorCategoria>>) => {
          this.assistenteTrabalhadorMascaraAbono = response.body;
        });
      }
    } else if (tabId === TAB_ID_ASSISTENTETRABALHADORMASCARADESCONTO) {
      this.activeTab = TAB_ID_ASSISTENTETRABALHADORMASCARADESCONTO;
      if (this.assistenteTrabalhadorMascaraDesconto.length < 1) {
        this.promise = this._rhConfiguracoesService.getAssistenteTrabalhadorMascaraDesconto().then((response: HttpResponse<Array<IJsonMascaraCCustoPorCategoria>>) => {
          this.assistenteTrabalhadorMascaraDesconto = response.body;
        });
      }
    }
  }

  public integrachanged(value: boolean): void {
    if (!value && !this.model.integraSalariosPorDefeito && !this.model.integraSalariosPorOrgaoSocial) {
      this.mascaraAnaliticaChanged(undefined);
      this.assistenteTrabalhadorMascaraDesconto = [];
      this.assistenteTrabalhadorMascaraAbono = [];
      this._cgModalService.showOkCancel('global.text.info', 'rhintegracaosalarios.message.retiraropcaointegsal', {
        size: 'md',
        showCloseBtn: false,
        showOkBtn: true,
        showCancelBtn: false
      });
      this.model.integraSalarios = value;
      this.model.pocRemuLiq = value;
      this.model.classeTrab = false;
      this.model.funcao = false;
      this.model.operacaoSeccao = false;
      this.model.codZona = false;
      this.model.departamento = false;
      this.model.subDepartamento = false;
      this.model.centroCusto = false;
      this.model.reparticaoCentroCusto = false;
    }
    if (value && !this.model.pocRemuLiq) {
      this.model.pocRemuLiq = value;
      this.model.integraSalarios = value;
    }
  }

  public integraSalPorDefChanged(value: boolean): void {
    this.model.integraSalariosPorDefeito = value;
    if (this.model.integraSalariosPorOrgaoSocial) {
      this.model.integraSalariosPorOrgaoSocial = false;
    }
    this.integrachanged(value);
  }

  public integraSalPorOrgSocChanged(value: boolean): void {
    this.model.integraSalariosPorOrgaoSocial = value;
    if (this.model.integraSalariosPorDefeito) {
      this.model.integraSalariosPorDefeito = false;
    }
    this.integrachanged(value);
  }

  public mascaraAnaliticaChanged(value: string): void {
    this.model.mascaraAnalitica = value;
    this.model.validaMascara = Boolean(this.model.mascaraAnalitica);
    this.model.centroCusto = true;
  }

  public integraRUChanged(value: boolean): void {
    this.model.profissao = value;
    this.model.situacaoProfissao = value;
    this.model.codCCT = value;
    this.model.nivelQualif = value;
    this.model.categoria = value;
  }

  public dadosadicionaisChanged(event: boolean): void {
    if (!this.model.integraSalarios || !this.model.pocRemuLiq) {
      if (event) {
        this.model.integraSalarios = event;
        this.model.pocRemuLiq = event;
      }
    }
  }

  private _saveAssistente(): Promise<void> {
    const assistenteTrabAndMascaras: IJsonAssistenteTrabAndMascaras = {
      assistenteTrabalhadorMascaraAbono: this.assistenteTrabalhadorMascaraAbono,
      assistenteTrabalhadorMascaraDesconto: this.assistenteTrabalhadorMascaraDesconto,
      assistenteTrabalhador: this.model
    };
    return this._rhConfiguracoesService.updateRequiredFields(assistenteTrabAndMascaras).then((response: HttpResponse<IJsonAssistenteTrabAndMascaras>) => {
      this.model = response.body.assistenteTrabalhador;
      this.assistenteTrabalhadorMascaraAbono = response.body.assistenteTrabalhadorMascaraAbono;
      this.assistenteTrabalhadorMascaraDesconto = response.body.assistenteTrabalhadorMascaraDesconto;
      this._plAlertService.success('rhintegracaosalarios.message.savedsuccessfully');
    });
  }

  private _reporAssistente(): Promise<void> {
    return this._rhConfiguracoesService.getRequiredFields().then((response: HttpResponse<IJsonConfigRHDGEMPRequiredFields>) => {
      this.assistenteTrabalhadorMascaraAbono = [];
      this.assistenteTrabalhadorMascaraDesconto = [];
      this.activeTab = TAB_ID_ASSISTENTETRABALHADORINTEGSALARIOS;
      this.rhassistentetrabalhadorTabCallback.select(TAB_ID_ASSISTENTETRABALHADORINTEGSALARIOS);
      this.model = response.body;
      this._plAlertService.success('rhintegracaosalarios.message.resetsuccessfully');
    });
  }
}
