import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonTxtcl} from './jsonTxtcl.entity.interface';
import {ENTITY_NAME_TXTCL} from './txtcl.entity.interface';
import {TxtclEntityEditComponent} from './components/edit/txtcl.entity.edit.component';

export const ENTITY_TXTCL: IEntityDefinition<IJsonTxtcl> = {
  name: ENTITY_NAME_TXTCL,
  roles: [ROLE.ERP],
  searchPlaceholder: 'txtcl.pesquisa',
  metadata: {
    keyName: '{{nClifo}}/{{nTexto}}',
    descriptionName: '{{nClifo}} - {{nTexto}}',
    fields: [
      {
        name: 'nClifo',
        type: 'string',
        caption: 'txtcl.fields.nClifo',
        placeholder: 'txtcl.fields.nClifoPlaceholder',
        entity: {name: 'clifos', keyTarget: 'nClifo', outputTarget: 'clifoNome'},
        validators: {required: true}
      },
      {
        name: 'nTexto',
        type: 'cgsmallint',
        caption: 'txtcl.fields.nTexto',
        placeholder: 'txtcl.fields.nTextoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'titulo',
        type: 'string',
        caption: 'txtcl.fields.titulo',
        placeholder: 'txtcl.fields.tituloPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {
        name: 'note',
        type: 'string',
        caption: 'txtcl.fields.note',
        placeholder: 'txtcl.fields.notePlaceholder',
        validators: {required: true}
      }
    ],
    order: 'nClifo, nTexto',
    searchFields: 'nClifo,nTexto,titulo,note'
  },
  autocomplete: {
    rowTemplate: '{{nClifo}} - {{titulo}}',
    output: '{{nClifo}} - {{titulo}}',
    searchFields: 'nClifo,titulo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.txtcl'
      }
    }
  },
  detail: {
    state: {
      component: TxtclEntityEditComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.txtcl'
      }
    }
  }
};
