import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPOS_EMPRESA} from './tiposEmpresa.datasource.interface';
import {ETipoEmpresa} from '../../interfaces/jsonEmpresa.interface';

export const DATA_SOURCE_TIPOS_EMPRESA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_EMPRESA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoEmpresa.EmpNomeIndivid, name: 'tiposEmpresa.data.nomeIndividual'},
    {value: ETipoEmpresa.SociedQuotas, name: 'tiposEmpresa.data.sociedadeQuotas'},
    {value: ETipoEmpresa.SociedAnonima, name: 'tiposEmpresa.data.sociedadeAnonima'},
    {value: ETipoEmpresa.SociedCooperativa, name: 'tiposEmpresa.data.sociedadeCooperativa'},
    {value: ETipoEmpresa.Associacao, name: 'tiposEmpresa.data.associacao'},
    {value: ETipoEmpresa.ServicosMunicipalizados, name: 'tiposEmpresa.data.servicosMunicipalizados'},
    {value: ETipoEmpresa.SociedadeUnipessoal, name: 'tiposEmpresa.data.sociedadeUnipessoal'},
    {value: ETipoEmpresa.AgrupamentoComplementarEmpresas, name: 'tiposEmpresa.data.agrupComplementarEmrpesas'},
    {value: ETipoEmpresa.CamaraMunicipal, name: 'tiposEmpresa.data.camaraMunicipal'},
    {value: ETipoEmpresa.EmpresaMunicipal, name: 'tiposEmpresa.data.empresaMunicipal'},
    {value: ETipoEmpresa.InstitutoPublico, name: 'tiposEmpresa.data.institutoPublico'},
    {value: ETipoEmpresa.FederacaoDesportiva, name: 'tiposEmpresa.data.federacaoDesportiva'},
    {value: ETipoEmpresa.SociedadeAnonimaDesportiva, name: 'tiposEmpresa.data.sociedadeAnonimaDesportiva'},
    {value: ETipoEmpresa.EscolaSuperior, name: 'tiposEmpresa.data.escolaSuperior'},
    {value: ETipoEmpresa.EstabelecimentoEstavelSocEstrangeira, name: 'tiposEmpresa.data.estabelecimentoEstavelSocEstrangeira'}
  ]
});
