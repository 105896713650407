<ul class="table-legend mt-3">
  <ng-container *ngFor="let item of legend">
    <li *ngIf="!item.hide">
      <div class="table-legend-badge" [ngClass]="item.badgeCSSClass" [style.background-color]="item.badgeBackgroundColor"></div>
      <div class="table-legend-caption">
        <span [translate]="item.caption"></span>
      </div>
    </li>
  </ng-container>
</ul>
