<div class="intfac-modal">
  <div class="modal-header">
    <button type="button" (click)="dismiss()" class="btn-close"></button>
    <h4 class="modal-title" [translate]="'intfac.modal.title'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="intFacModal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()">
      <div *dxTemplate="let row of 'templateMasterDetail'">
        <div class="error-color"><i class="fa fa-fw fa-times-circle mr-1" aria-hidden="true"></i>{{ row.data.error }}</div>
      </div>
    </dx-data-grid>

    <cg-table-legend [legend]="intFacDataGridLegend"></cg-table-legend>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
