import {Injectable} from '@angular/core';
import {isNumber, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../services/api/api.service';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {EFaturaConfigDocsService} from './eFaturaConfigDocs.module.service';
import {IJsonEFaturaConfigDocs, IJsonEFaturaConfigDocsCabecalho, IJsonEFaturaConfigDocsSupplier} from './jsonEFaturaConfigDocs.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EFaturaConfigDocsUIService extends EFaturaConfigDocsService {
  constructor(
    protected readonly _apiService: ApiService,
    protected readonly _plAlertService: PlAlertService,
    protected readonly _cgModalService: CGModalService
  ) {
    super(_apiService);
  }

  public async saveEFaturaConfigDocs(configDocs: IJsonEFaturaConfigDocs): TServiceResponse<IJsonEFaturaConfigDocs> {
    await this._checkBeforeSave(configDocs);
    const response = await super.saveEFaturaConfigDocs(configDocs);
    this._plAlertService.success('configEfaturaDocPorLanc.success');
    return response;
  }

  public async saveEFaturaConfigDocsSupplier(nif: string, nomeFornecedor: string, configDocsSupplier: IJsonEFaturaConfigDocsSupplier): TServiceResponse<IJsonEFaturaConfigDocsSupplier> {
    await this._checkBeforeSave(configDocsSupplier);
    const response = await super.saveEFaturaConfigDocsSupplier(nif, nomeFornecedor, configDocsSupplier);
    this._plAlertService.success('configEfaturaDocPorLanc.success');
    return response;
  }

  private async _checkBeforeSave(configDocsHeader: IJsonEFaturaConfigDocsCabecalho): Promise<void> {
    if (
      isNumber(configDocsHeader.notaCredito) &&
      configDocsHeader.notaCredito !== 0 &&
      (configDocsHeader.notaCredito === configDocsHeader.fatura ||
        configDocsHeader.notaCredito === configDocsHeader.faturaRecibo ||
        configDocsHeader.notaCredito === configDocsHeader.notaDebito ||
        configDocsHeader.notaCredito === configDocsHeader.reciboVerde ||
        configDocsHeader.notaCredito === configDocsHeader.faturaSimplificada ||
        configDocsHeader.notaCredito === configDocsHeader.docConferencia ||
        configDocsHeader.notaCredito === configDocsHeader.faturaEstrangeiro)
    ) {
      await this._cgModalService.showOkCancel('configEfaturaDocPorLanc.text.mesmoPredefinido.title', 'configEfaturaDocPorLanc.text.mesmoPredefinido.message');
    }
  }
}
