import {Ng2StateDeclaration} from '@uirouter/angular';
import {CompanyStatusComponent} from './companystatus.component';
import {RESOLVER_SESSION} from '../../../../config/uirouter/uirouter.resolvers';
import {STATE_NAME_COMPANY_STATUS} from './companystatus.interface';
import {STATE_NAME_SITE} from '../../../../common/site';

export const STATE_COMPANY_STATUS: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_COMPANY_STATUS,
  url: `/${STATE_NAME_COMPANY_STATUS}?{statusInstance}`,
  component: CompanyStatusComponent,
  data: {
    disableRecover: true
  },
  params: {
    statusInstance: {
      type: 'string',
      value: ''
    }
  },
  resolve: [RESOLVER_SESSION]
};
