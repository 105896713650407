import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ConfigService} from '../../../../../../services/config/config.service';
import {DocContabilidadeService} from '../docContabilidade.service';
import {ICGConfigDecimais} from '../../../../../../services/config/config.service.interface';
import {IDocContabilidade, IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {DocsContabilidadeViewDocModalComponent} from '../modals/viewdoc/docsContabilidade.viewdoc.modal.component';

@Component({
  selector: 'doccontabilidade-totais',
  templateUrl: './docContabilidade.totais.component.html'
})
export class DocContabilidadeTotaisComponent implements OnChanges {
  @Input() public doccontabilidade: IDocContabilidade;
  @Input() public selectedAccount: IDocContabilidadeLinha;
  @Input() public configDecimais: ICGConfigDecimais;
  @Input() public showOpenDocButton: boolean;

  public config: ICGConfigDecimais;

  constructor(
    private readonly _configService: ConfigService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _cgModalService: CGModalService
  ) {
    this.showOpenDocButton = false;
  }

  public ngOnChanges({configDecimais}: SimpleChanges): void {
    if (configDecimais) {
      this.config = configDecimais.currentValue || this._configService.configurations.contabilidade.decimais;
    }
  }

  public isMoedaPresent(): boolean {
    return this._docContabilidadeService.isMoedaPresent(this.doccontabilidade);
  }

  public async openDocImputacao(extPocCabID: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocsContabilidadeViewDocModalComponent, {size: 'xxl'});
    const componentInstance: DocsContabilidadeViewDocModalComponent = modalInstance.componentInstance;
    componentInstance.extPocCabID = extPocCabID;
    await modalInstance.result;
  }
}
