import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {ERadioTipoDeclaracao, IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtSemRetencao} from './jsonModelo30.module.interface';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';

export enum ETipModelo30 {
  NewClean = 'NewClean',
  NewFromDB = 'NewFromDB'
}

export enum ETiposValidar {
  Default,
  AT
}

export enum EModelo30Q8DataGridLegendColors {
  ExisteLinhasParaMesmoContribuinte = 'existe-linhas-mesmo-contribuinte'
}

export interface IJsonValidarModeloErrors {
  nomeCampo?: string;
  mensagemOriginal?: string;
  mensagem?: string;
  tipoMensagemStr?: string;
}

export interface IJsonModeloErrors {
  tipoValidacao: ETiposValidar;
  mensagemTipoHeader?: string;
  erros: Array<IJsonValidarModeloErrors>;
}

export interface IJsonGuiasPagamento {
  guiaPagamento: number;
  valorTotal: number;
}

export interface IModelo30VerificarLancamentos {
  contribuintesComRetencao: Array<IJsonContribuintesNaoPtComRetencao>;
  contribuintesSemRetencao: Array<IJsonContribuintesNaoPtSemRetencao>;
}

export const RADIO_GROUP_TIPO_DECLARACAO: IRadioGroup<ERadioTipoDeclaracao> = deepFreeze<IRadioGroup<ERadioTipoDeclaracao>>({
  groupItems: [
    {value: ERadioTipoDeclaracao.Primeira, label: 'modelo30.groups.q5.primeira'},
    {value: ERadioTipoDeclaracao.Substituicao, label: 'modelo30.groups.q5.sub'}
  ]
});

export const TABLE_LEGEND_MODELO_30_Q8: TTableLegend = deepFreeze([
  {
    caption: 'modelo30.groups.q8.dataGrid.legends.existeLinhasParaMesmoContribuinte',
    badgeCSSClass: EModelo30Q8DataGridLegendColors.ExisteLinhasParaMesmoContribuinte
  }
]);

export const MODULE_NAME_MODELO_30 = 'modelo30';
