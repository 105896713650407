<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'cargveic.adicionarReboque'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'cargveic.fields.matriculaReboque'"></label>
      <edit>
        <pl-edit type="text" attrName="matriculaReboque" [(model)]="model.matriculaReboque" [properties]="{validators: {maxlength: {value: 30}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'cargveic.fields.pesoTara'"></label>
      <edit>
        <pl-edit type="number" attrName="pesoTara" [(model)]="model.pesoTara"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-ok" data-focus [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
