import {ContabilidadeExtratosGridComponent} from './components/contabilidade.extratosGrid.module.component';
import {IContabilidadeExtratosGrid, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from './contabilidade.extratosGrid.module.interface';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_ESTATISTICA_EXTRATOS_GRID: IModuleDefinition<IContabilidadeExtratosGrid> = {
  name: MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID,
  state: {
    url: '/extratosporconta',
    component: ContabilidadeExtratosGridComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.ERP, ROLE.CLIENTCONNECT],
      pageTitle: 'global.menu.extratosgrid'
    },
    params: {
      nConta: {
        type: 'string',
        value: ''
      },
      process: {
        type: 'bool',
        value: false
      },
      dePeriodo: {
        type: 'string',
        value: ''
      },
      atePeriodo: {
        type: 'string',
        value: ''
      }
    },
    resolve: [RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL]
  }
};
