import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {IClifo} from '../../clifos.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'clifos-avisodadosrepetidos-modal',
  templateUrl: './clifos.avisoDadosRepetidos.modal.component.html'
})
export class ClifosAvisoDadosRepetidosModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public readonly clifosList: Array<IClifo>;
  public dataGridDefinition: IDevExpressDataGrid;

  protected _dataGridInstance: dxDataGrid;

  constructor(protected _injector: Injector) {
    super(_injector);

    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'nConta',
          dataType: 'string',
          caption: 'clifos.fields.nConta',
          allowEditing: false,
          allowFiltering: false,
          allowSearch: false
        },
        {
          dataField: 'nome',
          dataType: 'string',
          caption: 'clifos.fields.nome',
          allowEditing: false,
          allowFiltering: false,
          allowSearch: false
        },
        {
          dataField: 'nContribuint',
          dataType: 'string',
          caption: 'clifos.fields.nContribuint',
          allowEditing: false,
          allowFiltering: false,
          allowSearch: false
        }
      ],
      dataSource: []
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.clifosList;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this.dataGridInstance.endCustomLoading();
  }

  public get dataGridInstance(): dxDataGrid {
    return this._dataGridInstance;
  }
}
