import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonJustosImpedimentos, IJsonModelo30, IJsonModelo30Params, IJsonModelo30Q8List, IJsonRegimesTributacao} from './jsonModelo30.module.interface';
import {IJsonValidarModeloErrors} from './modelo30.module.interface';

@Injectable({
  providedIn: 'root'
})
export class Modelo30Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/modelo30`;
  }

  public getModelo30(param?: IJsonModelo30Params): TServiceResponse<IJsonModelo30> {
    return this._apiService.get<IJsonModelo30>({
      url: this._path,
      params: {
        tipmodelo: param?.tipmodelo,
        periodo: param?.periodo,
        tipodeclaracao: param?.tipodeclaracao
      }
    });
  }

  public getJustosImpedimentos(): TServiceResponse<Array<IJsonJustosImpedimentos>> {
    return this._apiService.get<Array<IJsonJustosImpedimentos>>({
      url: `${this._path}/justosimpedimentos`
    });
  }

  public getRegimesTributacao(): TServiceResponse<Array<IJsonRegimesTributacao>> {
    return this._apiService.get<Array<IJsonRegimesTributacao>>({
      url: `${this._path}/regimestributacao`
    });
  }

  public criarFicheiro(modelo: IJsonModelo30): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonModelo30>({
      url: `${this._path}/criarficheiro`,
      body: modelo,
      responseType: 'blob'
    });
  }

  public validarModelo(modelo: IJsonModelo30): TServiceResponse<Array<IJsonValidarModeloErrors>> {
    return this._apiService.post<Array<IJsonValidarModeloErrors>, IJsonModelo30>({
      url: `${this._path}/validar`,
      body: modelo
    });
  }

  public validarDeclaracaoAT(ficheiro: IJsonModelo30): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo30>({
      url: `${this._path}/at/validar`,
      body: ficheiro,
      reportExceptions: false
    });
  }

  public submeterDeclaracaoAT(ficheiro: IJsonModelo30): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo30>({
      url: `${this._path}/at/submeter`,
      body: ficheiro,
      reportExceptions: false
    });
  }

  public saveNGuia(extPocLigaID: string, nGuia: string): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/savenguia`,
      params: {
        extpocligaid: extPocLigaID,
        nguia: nGuia
      }
    });
  }

  public getContribuintesNaoPtComRetencao(periodo: string): TServiceResponse<Array<IJsonModelo30Q8List>> {
    return this._apiService.get<Array<IJsonModelo30Q8List>>({
      url: `${this._path}/contribuintesnaoptcomretencao`,
      params: {
        periodo: periodo
      }
    });
  }

  public getContribuintesNaoPtSemRetencao(periodo: string): TServiceResponse<Array<IJsonModelo30Q8List>> {
    return this._apiService.get<Array<IJsonModelo30Q8List>>({
      url: `${this._path}/contribuintesnaoptsemretencao`,
      params: {
        periodo: periodo
      }
    });
  }
}
