import type dxDataGrid from 'devextreme/ui/data_grid';
import type {LoadOptions} from 'devextreme/data';
import {HttpResponse} from '@angular/common/http';
import {isArray, isObject} from 'pl-comps-angular';
import type {IDevExpressDataGridLoadResult, IDevExpressDataGridSummary} from '../devexpress.datagrid.interface';
import type {IDevExpressDataGridPaging} from './devexpress.datagrid.utilities.interface';
import type {IApiQueryResponse} from '../../../../services/api/api.service.interface';

export class DevExpressDatagridPaging<TRowData = unknown, TKey = unknown> {
  private _requireTotalCount: boolean;
  private _hasSummaries: boolean;
  private _take: number;
  private _pageSize: number;

  constructor(public readonly dataGridInstance: dxDataGrid<TRowData, TKey>) {
    this._requireTotalCount = true;
    this._hasSummaries = false;
    this._take = -1;
    this._pageSize = -1;
  }

  public paginate(loadOptions: LoadOptions): IDevExpressDataGridPaging {
    const summary: IDevExpressDataGridSummary = <IDevExpressDataGridSummary>this.dataGridInstance.option('summary');
    this._hasSummaries = (isArray(summary?.totalItems) && summary.totalItems.length > 0) || (isArray(summary?.groupItems) && summary.groupItems.length > 0);
    const page: number = this._hasSummaries || this.dataGridInstance.pageIndex() < 0 ? -1 : this.dataGridInstance.pageIndex() + 1;
    const perPage: number = this._hasSummaries || this.dataGridInstance.pageSize() <= 0 ? -1 : this.dataGridInstance.pageSize();
    this._requireTotalCount = loadOptions.requireTotalCount === true;
    this._take = loadOptions.take;
    this._pageSize = perPage;
    return {page: page, perPage: perPage};
  }

  public processResult<S = TRowData>(result: IDevExpressDataGridLoadResult<S> | IApiQueryResponse<S> | Array<S> | HttpResponse<IApiQueryResponse<S> | Array<S>>): IDevExpressDataGridLoadResult<S> {
    let loadResult: IDevExpressDataGridLoadResult<S> = {data: [], totalCount: 0};
    const resultData: IDevExpressDataGridLoadResult<S> | Array<S> | IApiQueryResponse<S> = result instanceof HttpResponse ? result.body : result;
    if (isArray(resultData)) {
      loadResult.data = resultData;
      loadResult.totalCount = resultData.length;
    } else if (isObject(resultData)) {
      if (Object.prototype.hasOwnProperty.call(resultData, 'list') && Object.prototype.hasOwnProperty.call(resultData, 'total')) {
        loadResult.data = (<IApiQueryResponse<S>>resultData).list;
        loadResult.totalCount = (<IApiQueryResponse<S>>resultData).total;
      } else if (Object.prototype.hasOwnProperty.call(resultData, 'data') && Object.prototype.hasOwnProperty.call(resultData, 'totalCount')) {
        loadResult = <IDevExpressDataGridLoadResult<S>>resultData;
      }
      if (!this._requireTotalCount && !this._hasSummaries && this._take !== this._pageSize) {
        loadResult.data = loadResult.data.slice(Math.max(0, this._pageSize - this._take));
      }
    }
    return loadResult;
  }
}
