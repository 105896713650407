import {ROLE} from '../../services/role.const';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {HttpResponse} from '@angular/common/http';
import {MODULE_NAME_UTL_VIAT_PATRONAL} from './utlViatPatronal.module.interface';
import {ETipoEscolhaUtlViatPatronal, ETipoOrdenacao, IJsonUtlViatPatronal} from './jsonUtlViatPatronal.module.interface';
import {UtlViatPatronalService} from './utlViatPatronal.module.service';
import {UtlViatPatronalModuleComponent} from './components/utlViatPatronal.module.component';

export const MODULE_UTL_VIAT_PATRONAL: IModuleDefinition = {
  name: MODULE_NAME_UTL_VIAT_PATRONAL,
  state: {
    url: `/${MODULE_NAME_UTL_VIAT_PATRONAL}`,
    component: UtlViatPatronalModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.utlviatpatronal'
    },
    resolve: [
      {
        provide: 'utlViatPatronalList',
        deps: [UtlViatPatronalService],
        useFactory: (utlViatPatronalService: UtlViatPatronalService): Promise<Array<IJsonUtlViatPatronal>> => {
          return utlViatPatronalService.getViatPatronal(ETipoEscolhaUtlViatPatronal.Ativas, ETipoOrdenacao.CodEmp).then((response: HttpResponse<Array<IJsonUtlViatPatronal>>) => response.body);
        }
      }
    ]
  }
};
