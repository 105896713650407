<div class="entidadeexterna">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <div class="entity-detail-form">
        <pl-group [properties]="{readonly: type === 'detail'}">
          <label [translate]="'entidadeexterna.fields.nEmpresaGabinete'"></label>
          <edit>
            <pl-autocomplete
              attrName="empresasGabinete"
              [source]="empresasGabinete"
              [model]="empresaGabineteView"
              (evtSelected)="evtEmpresasGabineteChanged($event)"
              [allowInvalid]="false"
              [properties]="{validators: {required: {value: true}}}"
              [rowTemplate]="'nome'"
              [output]="'nome'">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </div>

      <pl-form [model]="model" [definition]="formDefinition" [template]="formTemplate" [properties]="{readonly: type === 'detail'}"></pl-form>
    </div>
  </entity-detail>
</div>
