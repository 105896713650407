import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IAnalisadorTesConfiguration,
  IAnalisadorTesLanco,
  IAnalisadorTesLancoUpdateByNConta,
  IAnalisadorTesMultipleTesLancoItem,
  IAnalisadorTesRecord,
  IJsonAnalisadorTesFACCBInfo,
  IJsonAnalisadorTesValoresAntItem,
  IJsonAnalisadorTesValoresAntSaveItem
} from './analisadorTes.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AnalisadorTesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/analisadorTes`;
  }

  public getTreeRowData(parentIds: Array<string>, fromDate: TDate, toDate: TDate, saldoInicial: number): TServiceResponse<Array<IAnalisadorTesRecord>> {
    return this._apiService.post<Array<IAnalisadorTesRecord>, Array<string>>({
      url: `${this._path}/treeRowData`,
      body: parentIds,
      params: {
        dateDe: fromDate,
        dateAte: toDate,
        saldoInicial: saldoInicial
      }
    });
  }

  public synchronizeData(): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/synchronizeData`
    });
  }

  public getSaldoInicial(ateData: TDate): TServiceResponse<number> {
    return this._apiService.get<number>({
      url: `${this._path}/saldoInicial`,
      params: {
        ateData: ateData
      }
    });
  }

  public getConfiguration(): TServiceResponse<IAnalisadorTesConfiguration> {
    return this._apiService.get<IAnalisadorTesConfiguration>({
      url: `${this._path}/configuration`
    });
  }

  public saveConfiguration(data: IAnalisadorTesConfiguration): TServiceResponse<void> {
    return this._apiService.post<void, IAnalisadorTesConfiguration>({
      url: `${this._path}/configuration`,
      body: data
    });
  }

  public getTesLanco(tesLancoId: string): TServiceResponse<IAnalisadorTesLanco> {
    return this._apiService.get<IAnalisadorTesLanco>({
      url: `${this._path}/teslanco/${tesLancoId}`
    });
  }

  public updateTesLanco(tesLancoId: string, data: IAnalisadorTesLanco): TServiceResponse<void> {
    return this._apiService.put<void, IAnalisadorTesLanco>({
      url: `${this._path}/teslanco/${tesLancoId}`,
      body: data
    });
  }

  public updateTesLancoByNConta(tesRubricaId: number, nconta: string, data: IAnalisadorTesLancoUpdateByNConta): TServiceResponse<void> {
    return this._apiService.put<void, IAnalisadorTesLancoUpdateByNConta>({
      url: `${this._path}/teslancobynconta/${tesRubricaId}/${nconta}`,
      body: data
    });
  }

  public deleteTesLancoByNConta(nconta: string): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/teslancobynconta/${nconta}`
    });
  }

  public updateMultipleTesLanco(data: Array<IAnalisadorTesMultipleTesLancoItem>): TServiceResponse<void> {
    return this._apiService.put<void, Array<IAnalisadorTesMultipleTesLancoItem>>({
      url: `${this._path}/multiple/teslanco`,
      body: data
    });
  }

  public deleteTesLanco(tesLancoId: string): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/teslanco/${tesLancoId}`
    });
  }

  public deleteMultipleTesLanco(tesLancoIds: Array<string>): TServiceResponse<void> {
    return this._apiService.delete<void, Array<string>>({
      url: `${this._path}/multiple/teslanco`,
      body: tesLancoIds
    });
  }

  public getFullData(fromDate: TDate, toDate: TDate, saldoInicial: number): TServiceResponse<Array<IAnalisadorTesRecord>> {
    return this._apiService.get({
      url: `${this._path}/fullTreeRowData`,
      params: {
        dateDe: fromDate,
        dateAte: toDate,
        saldoInicial: saldoInicial
      }
    });
  }

  public stopMS(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopMSUrl()
    });
  }

  public stopMSUrl(): string {
    return `${this._path}/stop-ms`;
  }

  public getExtPocCabIDByTesLancoId(tesLancoId: string): TServiceResponse<string> {
    return this._apiService.get<string>({
      url: `${this._path}/extPocCabId`,
      params: {tesLancoId: tesLancoId}
    });
  }

  public getFaccbInfoByTesLancoId(tesLancoId: string): TServiceResponse<IJsonAnalisadorTesFACCBInfo> {
    return this._apiService.get<IJsonAnalisadorTesFACCBInfo>({
      url: `${this._path}/faccbInfo`,
      params: {tesLancoId: tesLancoId}
    });
  }

  public getTesLancoValoresAntList(data: TDate): TServiceResponse<Array<IJsonAnalisadorTesValoresAntItem>> {
    return this._apiService.get<Array<IJsonAnalisadorTesValoresAntItem>>({
      url: `${this._path}/valores-anteriores`,
      params: {data: data}
    });
  }

  public saveTesLancoValoresAnt(list: Array<IJsonAnalisadorTesValoresAntSaveItem>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonAnalisadorTesValoresAntSaveItem>>({
      url: `${this._path}/valores-anteriores`,
      body: list
    });
  }
}
