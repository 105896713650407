import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  EATAutoFaturacaoEnquadramento,
  EATEstadoSerie,
  IJsonDocfaNumATConsulta,
  IJsonDocfaNumATRegisto,
  IJsonDocfaNumATResultado,
  SUCCESS_CODE_SERIE_FINALIZADA
} from '../../../jsonDocfaNum.entity.interface';
import {DocfasNumsService} from '../../../docfasNums.entity.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {DocfaNumsFinalizarAtModalComponent} from '../finalizarserie/docfaNumsFinalizarAt.modal.component';
import {EStatusCode} from '../../../../../../config/constants';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {AT_WEB_SERVICE_CLASS, ERROR_AUTH_SUBSTRING} from '../../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {ETipoAutoFaturacao} from '../../../../docfas/jsonDocFa.entity.interface';
import {ISelectKeyPair} from '../../../../docfas/docFas.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonClifo} from '../../../../clifos/jsonClifo.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_CLIFOS} from '../../../../clifos/clifos.entity.interface';

@Component({
  selector: 'comunicacaoSeriesAt-modal',
  templateUrl: './comunicacaoSeriesAt.modal.component.html'
})
export class ComunicacaoSeriesATModalComponent extends CGModalComponent<IJsonDocfaNumATConsulta> implements OnInit {
  @Input() public idDocFaNum: number;
  @Input() public autoFaturacao: ETipoAutoFaturacao;
  @Input() public model: IJsonDocfaNumATConsulta;

  public docFaNumAtResultado: IJsonDocfaNumATResultado;
  public isRegisterExecuted: boolean;
  public isAutoFaturacao: boolean;
  public isAutoFaturacaoComAcordo: boolean;
  public isAutoFaturacaoSemAcordo: boolean;
  public enquadramentoList: Array<ISelectKeyPair>;
  public readonly typeEstadoSerie: typeof EATEstadoSerie;

  private readonly _clifosService: IEntityService<IJsonClifo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docfasNumsService: DocfasNumsService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.typeEstadoSerie = EATEstadoSerie;

    this._clifosService = this._entityServiceBuilder.build(ENTITY_NAME_CLIFOS);

    this.model = {
      sucesso: false,
      motivoEstado: '',
      numInicialSeq: 0,
      justificacao: '',
      tipoSerie: '',
      dataRegisto: '',
      seqUltimoDocEmitido: 0,
      numCertSWFatur: 0,
      serie: '',
      classeDoc: '',
      nifComunicou: '',
      tipoDoc: '',
      meioProcessamento: '',
      dataEstado: '',
      estado: '',
      codValidacaoSerie: '',
      dataInicioPrevUtiliz: '',
      numeroCertificado: '',
      enquadramento: EATAutoFaturacaoEnquadramento.None,
      atAfNifTerceiro: '',
      nConta: ''
    };
    this.isRegisterExecuted = false;
    this.isAutoFaturacao = false;
    this.isAutoFaturacaoComAcordo = false;
    this.isAutoFaturacaoSemAcordo = false;
    this.enquadramentoList = [
      {value: EATAutoFaturacaoEnquadramento.None, name: 'docfasNums.modal.comunicacao.selecionar'},
      {value: EATAutoFaturacaoEnquadramento.Cortica, name: 'docfasNums.modal.comunicacao.enquadramentoDesperdicios'},
      {value: EATAutoFaturacaoEnquadramento.Desperdicios, name: 'docfasNums.modal.comunicacao.enquadramentoCortica'}
    ];
  }

  public ngOnInit(): void {
    this.isAutoFaturacaoComAcordo = this.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo;
    this.isAutoFaturacaoSemAcordo = this.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresSemAcordo;
    this.isAutoFaturacao = this.isAutoFaturacaoComAcordo || this.isAutoFaturacaoSemAcordo;
    this._getNifNContaAutoFaturacaoComAcordo();
  }

  public close(): void {
    super.close(this.model);
  }

  public getAutoFaturacaoMessage1(): string {
    if (!this.isAutoFaturacao) {
      return '';
    }
    return this.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo
      ? 'docfasNums.modal.comunicacao.autoFaturacaoComAcordoMessage1'
      : 'docfasNums.modal.comunicacao.autoFaturacaoSemAcordoMessage1';
  }

  public getAutoFaturacaoMessage2(): string {
    if (!this.isAutoFaturacao) {
      return '';
    }
    return this.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo
      ? 'docfasNums.modal.comunicacao.autoFaturacaoComAcordoMessage2'
      : 'docfasNums.modal.comunicacao.autoFaturacaoSemAcordoMessage2';
  }

  public getAutoFaturacaoMessage3(): string {
    if (!this.isAutoFaturacao) {
      return '';
    }
    return this.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo
      ? 'docfasNums.modal.comunicacao.autoFaturacaoComAcordoMessage3'
      : 'docfasNums.modal.comunicacao.autoFaturacaoSemAcordoMessage3';
  }

  public getEnquadramentoTooltip(): string {
    if (!this.isAutoFaturacaoSemAcordo || this.model.enquadramento === EATAutoFaturacaoEnquadramento.None) {
      return '';
    }
    return this.model.enquadramento === EATAutoFaturacaoEnquadramento.Desperdicios ? 'docfasNums.modal.comunicacao.enquadramentoDesperdicios' : 'docfasNums.modal.comunicacao.enquadramentoCortica';
  }

  public readonly fnRegistarWebService: () => Promise<HttpResponse<IJsonDocfaNumATRegisto>> = () => this._registarWebService();
  public readonly fnRegistarManualmente: () => Promise<HttpResponse<IJsonDocfaNumATRegisto>> = () => this._registarManualmente();
  public readonly fnFinalizarSerie: () => Promise<void | IJsonDocfaNumATResultado> = () => this._finalizarSerie();

  private _registarWebService(): Promise<HttpResponse<IJsonDocfaNumATRegisto>> {
    return this._docfasNumsService
      .registaSerieATPorWebService(this.idDocFaNum, this.model.numInicialSeq, this.model.dataInicioPrevUtiliz, this.model.codValidacaoSerie)
      .then((result) => {
        if (result.status === EStatusCode.OK && result.body?.codValidacaoSerie !== '') {
          this.model.estado = EATEstadoSerie.Ativa;
          this.model.sucesso = true;
          this.model.codValidacaoSerie = result.body.codValidacaoSerie;
          this.isRegisterExecuted = true;
        }
        return result;
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            if (exception.class === AT_WEB_SERVICE_CLASS && exception.message.includes(ERROR_AUTH_SUBSTRING)) {
              return this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'}).result.then(() => {
                return this._registarWebService();
              });
            }
            this._plAlertService.error(exception.message);
          }
        }
        return undefined;
      });
  }

  private _registarManualmente(): Promise<HttpResponse<IJsonDocfaNumATRegisto>> {
    return this._docfasNumsService
      .registaSerieATManual(this.idDocFaNum, this.model.numInicialSeq, this.model.dataInicioPrevUtiliz, this.model.codValidacaoSerie, this.model.nConta, this.model.enquadramento)
      .then((result) => {
        if (result.status === EStatusCode.OK) {
          this.model.estado = EATEstadoSerie.Ativa;
          this.model.sucesso = true;
          this.isRegisterExecuted = true;
        }
        return result;
      });
  }

  private _finalizarSerie(): Promise<void | IJsonDocfaNumATResultado> {
    const instance = this._cgModalService.showVanilla(DocfaNumsFinalizarAtModalComponent, {size: 'md'});
    instance.componentInstance.serie = this.model.serie;
    instance.componentInstance.seqUltimoDocEmitido = this.model.seqUltimoDocEmitido;
    return instance.result.then((result: string) => {
      if (!result) {
        this._plAlertService.error(this._translateService.instant('docfasNums.modal.comunicacao.finalizar.erroJustificacaoVazia'));
        return undefined;
      }
      return this._docfasNumsService.finalizaSerieAT(this.idDocFaNum, this.model.seqUltimoDocEmitido, result).then((data) => {
        this.docFaNumAtResultado = data.body;
        if (data.body.retornoCodigo === SUCCESS_CODE_SERIE_FINALIZADA) {
          this.model.sucesso = true;
        } else {
          this.model.sucesso = !this.model.sucesso ? data.body.sucesso : this.model.sucesso;
        }
      });
    });
  }

  private _getNifNContaAutoFaturacaoComAcordo(): void {
    if (this.isAutoFaturacaoComAcordo && this.model.estado === EATEstadoSerie.None && this.model.nConta && !this.model.atAfNifTerceiro) {
      this._clifosService.get({id: this.model.nConta, reportExceptions: false}).then((response) => {
        if (response) {
          this.model.atAfNifTerceiro = response.body.nContribuint;
        }
      });
    }
  }
}
