import {assign, merge} from 'lodash-es';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import dxDataGrid, {ToolbarItem} from 'devextreme/ui/data_grid';
import {Component, ContentChild, ContentChildren, EventEmitter, Injector, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef} from '@angular/core';
import {IPlFilterPanelEvtFiltered, IPlTableField, isDefined, isDefinedNotNull, isEmpty, isFunction, isNumber, isObject, isString, TCGTableDataReceivedFunction, TPlTableItem} from 'pl-comps-angular';
import {appendValueToQueryFilter} from '../../../../common/utils/utils';
import {
  DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS,
  DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_DETAIL,
  IEntity,
  IEntityDevExpressDataGrid,
  IEntityListServiceMethodsOverride,
  IEntityMetadataField
} from '../entity.definition.interface';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridProcessResult, devExpressDataGridSortToOrder} from '../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {EntityListDataGridTemplateDirective} from './entity.list.datagrid.template.directive';
import {EntityListDatagridToolbarItemTemplateDirective} from './entity.list.datagrid.toolbar.item.template.directive';
import {EntityListHeaderActionsDirective} from './entity.list.headeractions.directive';
import {EntityListItemActionsDirective} from './entity.list.itemactions.directive';
import {EntityListItemDetailDirective} from './entity.list.itemdetail.directive';
import {EntityRegistryService} from '../entity.registry.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumn,
  IDevExpressDataGridLoadResult,
  TDevExpressDataGridColumnCalculateFilterExpressionFn
} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared,
  IDevExpressDataGridEventOnSaving,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridState} from '../../devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {IEntityListProperties, IEntityListTemplateContext, TEntityListAfterRequestFn, TEntityListBeforeRequestFn, TEntityListOnFilterFn} from './entity.list.component.interface';
import {IEntityService, IEntityServiceQueryRequestConfig, TEntityServiceSourceFunction} from '../../../services/entity/entity.service.interface';
import {IApiQueryParams, IApiQueryResponse, THttpQueryResponse} from '../../../services/api/api.service.interface';
import {TDevExpressComparisonOperator, TDevExpressFilterExpression} from '../../devexpress/datalayer/filtering/devexpress.datalayer.filtering.interface';
import {TDevExpressDataGridColumnCalculateFilterExpressionTarget, TDevExpressDataGridSelectedFilterOperation} from '../../devexpress/datagrid/devexpress.datagrid.types.interface';

@Component({
  selector: 'entity-list',
  templateUrl: './entity.list.component.html'
})
export class EntityListComponent implements OnInit, OnChanges {
  @Input() public entityName: string;
  @Input() public maintenanceMode: boolean;
  @Input() public dataGridInstance: dxDataGrid;
  @Input() public dataGridProperties: IDevExpressDataGrid;
  @Input() public dataGridState: IDevExpressDataGridState;
  @Input() public searchValue: string;
  @Input() public order: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public selected: TPlTableItem<any>;
  @Input() public fields: string;
  @Input() public filter: string;
  @Input() public initialFilter: string;
  @Input() public filterCollapsed: boolean;
  @Input() public filterField: string | IPlTableField;
  @Input() public beforeRequest: TEntityListBeforeRequestFn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public afterRequest: TEntityListAfterRequestFn<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public dataReceived: TCGTableDataReceivedFunction<any>;
  @Input() public toolbarInstanceId: string;
  @Input() public serviceMethodsOverride: IEntityListServiceMethodsOverride;
  @Input() public onFiltered: TEntityListOnFilterFn;
  @Input() public properties: IEntityListProperties;
  @Output() public readonly searchValueChange: EventEmitter<string>;
  @Output() public readonly filterChange: EventEmitter<string>;
  @Output() public readonly filterCollapsedChange: EventEmitter<boolean>;
  @Output() public readonly evtOnRefresh: EventEmitter<Promise<void>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridPreparing: EventEmitter<IEntityDevExpressDataGrid<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridColumnsPreparing: EventEmitter<Array<IDevExpressDataGridColumn<any, any>>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridOnInitialized: EventEmitter<IDevExpressDataGridEventOnInitialized<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridOnContentReady: EventEmitter<IDevExpressDataGridEventOnContentReady<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridCellClick: EventEmitter<IDevExpressDataGridEventOnCellClick<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridCellDblClick: EventEmitter<IDevExpressDataGridEventOnCellDblClick<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridCellPrepared: EventEmitter<IDevExpressDataGridEventOnCellPrepared<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridRowPrepared: EventEmitter<IDevExpressDataGridEventOnRowPrepared<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridSaving: EventEmitter<IDevExpressDataGridEventOnSaving<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridOnSelectionChanged: EventEmitter<IDevExpressDataGridEventOnSelectionChanged<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridOnEditorPreparing: EventEmitter<IDevExpressDataGridEventOnEditorPreparing<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridOnEditingStart: EventEmitter<IDevExpressDataGridEventOnEditingStart<any, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtDataGridContextMenuPreparing: EventEmitter<IDevExpressDataGridEventOnContextMenuPreparing<any, any>>;
  @Output() public readonly evtDataGridStateLoad: EventEmitter<IDevExpressDataGridState>;
  @Output() public readonly evtDataGridStateChanged: EventEmitter<IDevExpressDataGridState>;
  @Output() public readonly evtDataGridStateCleared: EventEmitter<void>;

  public readonly dataGridTemplateMasterDetail: string;
  public entity: IEntity;
  public dataGrid: IEntityDevExpressDataGrid;
  public dataGridColumns: Array<IDevExpressDataGridColumn>;
  public dataSource: CustomStore;
  public customDataGridTemplate: boolean;
  public filterModel: string;
  public fixedFilterValue: string;
  public pageIndex: number;
  public pageSize: number;
  public hasActions: boolean;
  public hasDetail: boolean;
  public options: IEntityListProperties;

  private _service: IEntityService;
  private _templateEntityListDataGrid: TemplateRef<IEntityListTemplateContext>;
  private _templateEntityListHeaderActions: TemplateRef<IEntityListTemplateContext>;
  private _templateEntityListItemActions: TemplateRef<IEntityListTemplateContext>;
  private _templateEntityListItemDetail: TemplateRef<IEntityListTemplateContext>;
  private _templateEntityListDataGridToolbar: QueryList<EntityListDatagridToolbarItemTemplateDirective>;
  private _dataGridPaging: DevExpressDatagridPaging;
  private _changedFilter: boolean;

  constructor(
    private readonly _injector: Injector,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.searchValueChange = new EventEmitter<string>();
    this.filterChange = new EventEmitter<string>();
    this.filterCollapsedChange = new EventEmitter<boolean>();
    this.evtOnRefresh = new EventEmitter<Promise<void>>();
    this.evtDataGridPreparing = new EventEmitter<IEntityDevExpressDataGrid>();
    this.evtDataGridColumnsPreparing = new EventEmitter<Array<IDevExpressDataGridColumn>>();
    this.evtDataGridOnInitialized = new EventEmitter<IDevExpressDataGridEventOnInitialized>();
    this.evtDataGridOnContentReady = new EventEmitter<IDevExpressDataGridEventOnContentReady>();
    this.evtDataGridCellClick = new EventEmitter<IDevExpressDataGridEventOnCellClick>();
    this.evtDataGridCellDblClick = new EventEmitter<IDevExpressDataGridEventOnCellDblClick>();
    this.evtDataGridCellPrepared = new EventEmitter<IDevExpressDataGridEventOnCellPrepared>();
    this.evtDataGridRowPrepared = new EventEmitter<IDevExpressDataGridEventOnRowPrepared>();
    this.evtDataGridSaving = new EventEmitter<IDevExpressDataGridEventOnSaving>();
    this.evtDataGridOnSelectionChanged = new EventEmitter<IDevExpressDataGridEventOnSelectionChanged>();
    this.evtDataGridOnEditorPreparing = new EventEmitter<IDevExpressDataGridEventOnEditorPreparing>();
    this.evtDataGridOnEditingStart = new EventEmitter<IDevExpressDataGridEventOnEditingStart>();
    this.evtDataGridContextMenuPreparing = new EventEmitter<IDevExpressDataGridEventOnContextMenuPreparing>();
    this.evtDataGridStateLoad = new EventEmitter<IDevExpressDataGridState>();
    this.evtDataGridStateChanged = new EventEmitter<IDevExpressDataGridState>();
    this.evtDataGridStateCleared = new EventEmitter<void>();
    this.dataGridTemplateMasterDetail = DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_DETAIL;
    this.filterCollapsed = true;
    this.customDataGridTemplate = false;
    this.filterModel = '';
    this.fixedFilterValue = '';
    this.pageIndex = 0;
    this.options = {};
    this._changedFilter = false;
  }

  public ngOnInit(): void {
    this.entity = this._entityRegistryService.getEntity(this.entityName);
    this._handleChanges();
    if (!isEmpty(this.filter)) {
      this.fixedFilterValue = this.filter;
    }
    if (!isEmpty(this.initialFilter)) {
      this.filter = isEmpty(this.filter) ? this.initialFilter : `${this.filter}&${this.initialFilter}`;
    }
    if (!isEmpty(this.filter)) {
      this.filterModel = this.filter;
    }
    this._service = this._entityServiceBuilder.build(this.entity);
    this._service.invokeEntity(this._injector);
  }

  public ngOnChanges({dataGridProperties, filter, properties, beforeRequest, afterRequest}: SimpleChanges): void {
    if (filter && !filter.isFirstChange()) {
      this.fixedFilterValue = this.filter ? this.filter.replace(this.filterModel, '') : '';
    }
    if (properties && !properties.isFirstChange()) {
      this._changedProperties(properties.currentValue);
    }
    if (beforeRequest && !beforeRequest.isFirstChange()) {
      this._changedBeforeRequest(beforeRequest.currentValue);
    }
    if (afterRequest && !afterRequest.isFirstChange()) {
      this._changedAfterRequest(afterRequest.currentValue);
    }
    if (dataGridProperties && !dataGridProperties.isFirstChange()) {
      this._changedDataGridProperties(dataGridProperties.currentValue);
    }
  }

  public refresh(): Promise<void> {
    let promise: Promise<void>;
    if (this.dataGridInstance) {
      // Pass `-1` to collapse all master rows
      this.dataGridInstance.collapseAll(-1);
      promise = this.dataGridInstance.refresh();
      this.evtOnRefresh.emit(promise);
    } else {
      promise = Promise.resolve();
    }
    return promise;
  }

  public filterCollapse(): void {
    this.filterCollapsed = !this.filterCollapsed;
    this.filterCollapsedChange.emit(this.filterCollapsed);
  }

  public setSearchValue(value: string): void {
    this.searchValue = value;
    if (this.dataGridInstance) {
      this.dataGridInstance.searchByText(this.searchValue);
    } else if (!isObject(this.dataGrid.searchPanel)) {
      this.dataGrid.searchPanel = {text: this.searchValue};
    } else if (isEmpty(this.dataGrid.searchPanel.text)) {
      this.dataGrid.searchPanel.text = this.searchValue;
    }
    this.searchValueChange.emit(this.searchValue);
  }

  public filtered(event: IPlFilterPanelEvtFiltered<string>): void {
    Promise.resolve(isFunction(this.onFiltered) ? this.onFiltered(event) : undefined).then((transformedEvent: void | IPlFilterPanelEvtFiltered<string>) => {
      if (transformedEvent) {
        event = transformedEvent;
      }
      this.filterModel = event.serializedFilters || '';
      if (this.fixedFilterValue && !this.filterModel.includes(this.fixedFilterValue)) {
        if (!this.filterModel) {
          this.filterModel = this.fixedFilterValue;
        } else {
          this.filterModel = `${this.fixedFilterValue}&${this.filterModel}`;
        }
      }
      this.filter = this.filterModel;
      if (this.filter.startsWith('&')) {
        this.filter = this.filter.slice(1);
      }
      this.filterChange.emit(this.filter);
      this._changedFilter = true;
      return this.refresh();
    });
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    if (!this.dataGridInstance) {
      this.dataGridInstance = event.component;
      this._setupDataGridToolbar();
    }
    this.evtDataGridOnInitialized.emit(event);
  }

  public onDataGridContentReady(event: IDevExpressDataGridEventOnContentReady): void {
    this.evtDataGridOnContentReady.emit(event);
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick): void {
    this.evtDataGridCellClick.emit(event);
  }

  public onDataGridCellDblClick(event: IDevExpressDataGridEventOnCellDblClick): void {
    this.evtDataGridCellDblClick.emit(event);
  }

  public onDataGridCellPrepared(event: IDevExpressDataGridEventOnCellPrepared): void {
    this.evtDataGridCellPrepared.emit(event);
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing): void {
    this.evtDataGridContextMenuPreparing.emit(event);
  }

  public onDataGridRowPrepared(event: IDevExpressDataGridEventOnRowPrepared): void {
    this.evtDataGridRowPrepared.emit(event);
  }

  public onDataGridSaving(event: IDevExpressDataGridEventOnSaving): void {
    this.evtDataGridSaving.emit(event);
  }

  public onDataGridSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged): void {
    this.evtDataGridOnSelectionChanged.emit(event);
  }

  public onDataGridEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing): void {
    this.evtDataGridOnEditorPreparing.emit(event);
  }

  public onDataGridEditingStart(event: IDevExpressDataGridEventOnEditingStart): void {
    this.evtDataGridOnEditingStart.emit(event);
  }

  public onDataGridStateLoad(event: IDevExpressDataGridState): void {
    if (isObject(this.dataGridState)) {
      merge(event, this.dataGridState);
    }
    this.evtDataGridStateLoad.emit(event);
  }

  public onDataGridStateChanged(event: IDevExpressDataGridState): void {
    this.evtDataGridStateChanged.emit(event);
  }

  public onDataGridStateCleared(): void {
    this.evtDataGridStateCleared.emit();
  }

  public get templateEntityListDataGrid(): TemplateRef<IEntityListTemplateContext> {
    return this._templateEntityListDataGrid;
  }

  @ContentChild(EntityListDataGridTemplateDirective, {read: TemplateRef})
  public set templateEntityListDataGrid(value: TemplateRef<IEntityListTemplateContext>) {
    this._templateEntityListDataGrid = value;
    this.customDataGridTemplate = isDefined(this._templateEntityListDataGrid);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get templateEntityListHeaderActions(): TemplateRef<IEntityListTemplateContext> {
    return this._templateEntityListHeaderActions;
  }

  @ContentChild(EntityListHeaderActionsDirective, {read: TemplateRef})
  public set templateEntityListHeaderActions(value: TemplateRef<IEntityListTemplateContext>) {
    this._templateEntityListHeaderActions = value;
    this.hasActions = isDefined(this._templateEntityListHeaderActions) || isDefined(this._templateEntityListItemActions);
    this._evaluateColumnButtons();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get templateEntityListItemActions(): TemplateRef<IEntityListTemplateContext> {
    return this._templateEntityListItemActions;
  }

  @ContentChild(EntityListItemActionsDirective, {read: TemplateRef})
  public set templateEntityListItemActions(value: TemplateRef<IEntityListTemplateContext>) {
    this._templateEntityListItemActions = value;
    this.hasActions = isDefined(this._templateEntityListHeaderActions) || isDefined(this._templateEntityListItemActions);
    this._evaluateColumnButtons();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get templateEntityListItemDetail(): TemplateRef<IEntityListTemplateContext> {
    return this._templateEntityListItemDetail;
  }

  @ContentChild(EntityListItemDetailDirective, {read: TemplateRef})
  public set templateEntityListItemDetail(value: TemplateRef<IEntityListTemplateContext>) {
    this._templateEntityListItemDetail = value;
    this.hasDetail = isDefined(this._templateEntityListItemDetail);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get templateEntityListDataGridToolbar(): QueryList<EntityListDatagridToolbarItemTemplateDirective> {
    return this._templateEntityListDataGridToolbar;
  }

  @ContentChildren(EntityListDatagridToolbarItemTemplateDirective, {descendants: false})
  public set templateEntityListDataGridToolbar(value: QueryList<EntityListDatagridToolbarItemTemplateDirective>) {
    this._cleanupDataGridToolbar();
    this._templateEntityListDataGridToolbar = value;
    this._setupDataGridToolbar();
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedBeforeRequest();
    this._changedAfterRequest();
    this._changedDataGridProperties();
  }

  private _changedProperties(value: IEntityListProperties = this.properties): void {
    this.options = merge({}, this.options, value);
  }

  private _changedBeforeRequest(value: TEntityListBeforeRequestFn = this.beforeRequest): void {
    let val: TEntityListBeforeRequestFn = value;
    if (!isFunction(val)) {
      val = this.options.beforeRequest;
    }
    this.beforeRequest = val;
  }

  private _changedAfterRequest(value: TEntityListAfterRequestFn<unknown> = this.afterRequest): void {
    let val: TEntityListAfterRequestFn<unknown> = value;
    if (!isFunction(val)) {
      val = this.options.afterRequest;
    }
    this.afterRequest = val;
  }

  private _changedDataGridProperties(value: IDevExpressDataGrid = this.dataGridProperties): void {
    this.dataGrid = merge<object, IEntityDevExpressDataGrid, IDevExpressDataGrid>({}, this.entity.devExpress.dataGrid, value);
    this.evtDataGridPreparing.emit(this.dataGrid);
    this.dataSource = new CustomStore({
      key: this.entity.metadata.keyName,
      loadMode: 'processed',
      load: this._onLoadDataGrid.bind(this)
    });
    if (this.dataGrid.paging?.enabled) {
      if (isNumber(this.dataGrid.paging.pageIndex)) {
        this.pageIndex = this.dataGrid.paging.pageIndex;
      }
      if (isNumber(this.dataGrid.paging.pageSize)) {
        this.pageSize = this.dataGrid.paging.pageSize;
      }
    }
    const listFields: Array<IEntityMetadataField> = this.entity.getMetadata('list', this.fields).fields;
    this.dataGridColumns = this.dataGrid.getMetadataColumns('list', this.fields);
    const searchFields: Array<string> = this.dataGrid.getMetadataColumns('search').map<string>((searchField: IDevExpressDataGridColumn) => searchField.dataField);
    if (searchFields.length) {
      for (const dataGridField of this.dataGridColumns) {
        if (dataGridField.name === DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS) {
          continue;
        }
        dataGridField.allowSearch = searchFields.includes(dataGridField.dataField);
        if (dataGridField.allowSearch) {
          const listField: IEntityMetadataField = listFields.find((field: IEntityMetadataField) => field.name === dataGridField.dataField);
          const hasTrueValue: boolean = isDefinedNotNull(listField?.properties?.trueValue);
          const hasFalseValue: boolean = isDefinedNotNull(listField?.properties?.falseValue);
          if (hasTrueValue || hasFalseValue) {
            const originalCalculateFilterExpressionFn: TDevExpressDataGridColumnCalculateFilterExpressionFn = dataGridField.calculateFilterExpression;
            dataGridField.calculateFilterExpression = function (
              filterValue: unknown,
              selectedFilterOperation: TDevExpressDataGridSelectedFilterOperation,
              target: TDevExpressDataGridColumnCalculateFilterExpressionTarget
            ): TDevExpressFilterExpression {
              if (target === 'filterRow') {
                if (filterValue === true && hasTrueValue) {
                  filterValue = listField.properties.trueValue;
                }
                if (filterValue === false && hasFalseValue) {
                  filterValue = listField.properties.falseValue;
                }
              }
              if (isFunction(originalCalculateFilterExpressionFn)) {
                return originalCalculateFilterExpressionFn.call(this, filterValue, selectedFilterOperation, target);
              }
              return [this.dataField, <TDevExpressComparisonOperator>selectedFilterOperation, filterValue];
            };
          }
        }
      }
    }
    this.evtDataGridColumnsPreparing.emit(this.dataGridColumns);
    this.dataGridColumns = this.dataGridColumns.filter((dataGridField: IDevExpressDataGridColumn) => {
      return dataGridField.visible !== false || dataGridField.showInColumnChooser !== false || dataGridField.name === DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS;
    });
  }

  private _onLoad(params: IEntityServiceQueryRequestConfig): Promise<Array<unknown> | IApiQueryResponse<unknown>> {
    if (!isObject(params)) {
      params = {};
    }
    if (!params.ordena) {
      params.ordena = this.entity.metadata.order;
    }
    if (this._changedFilter) {
      this._changedFilter = false;
      params.pagina = 1;
    }
    if (!params.campospesq) {
      params.campospesq = this.entity.metadata.searchFields;
    }
    if (this.filter) {
      params.filtro = appendValueToQueryFilter(params.filtro, this.filter);
    }
    if (isFunction(this.beforeRequest)) {
      const result: IApiQueryParams = this.beforeRequest(params);
      if (isObject(result)) {
        assign(params, result);
      }
    }
    let loadFn: TEntityServiceSourceFunction<object>;
    if (isFunction(this.options.loadFn)) {
      loadFn = this.options.loadFn;
    } else if (isObject(this.serviceMethodsOverride)) {
      if (isString(this.serviceMethodsOverride.query) && isFunction(this._service[this.serviceMethodsOverride.query])) {
        loadFn = this._service[this.serviceMethodsOverride.query].bind(this._service);
      } else if (isFunction(this.serviceMethodsOverride.query)) {
        loadFn = this.serviceMethodsOverride.query;
      }
    }
    if (!loadFn) {
      loadFn = this._service.query.bind(this._service);
    }
    if (isObject(this.entity.list?.state?.data?.getRequestConfig)) {
      params = {...params, ...this.entity.list.state.data.getRequestConfig};
    }
    return Promise.resolve(loadFn(params)).then((response: THttpQueryResponse<unknown>) => {
      if (isFunction(this.afterRequest)) {
        return Promise.resolve(this.afterRequest(response)).then((transformedResponse: Array<unknown> | IApiQueryResponse<unknown>) => {
          return isDefined(transformedResponse) ? transformedResponse : response.body;
        });
      }
      return response.body;
    });
  }

  private _onLoadDataGrid(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult> {
    let search: string = this.searchValue || '';
    if (loadOptions.filter) {
      const queryFilter: string = devExpressDataGridFiltersToQueryFilter(this.dataGridInstance, loadOptions.filter);
      if (queryFilter) {
        search += search ? `&(${queryFilter})` : queryFilter;
      }
    }
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this.dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    return this._onLoad({
      pesquisa: search,
      ordena: order,
      skip: this.entity.actions.modernPagination ? loadOptions?.skip : undefined,
      take: this.entity.actions.modernPagination ? loadOptions?.take : undefined,
      pagina: page,
      porpagina: perPage
    }).then((response: Array<unknown> | IApiQueryResponse<unknown>) => {
      if (!this.entity.actions.modernPagination) {
        return this._dataGridPaging.processResult(response);
      }
      return devExpressDataGridProcessResult(response);
    });
  }

  private _evaluateColumnButtons(): void {
    const visible: boolean = this.hasActions;
    if (!this.dataGridInstance) {
      const column: IDevExpressDataGridColumn = this.dataGridColumns.find((dataGridColumn: IDevExpressDataGridColumn) => dataGridColumn.name === DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS);
      if (column) {
        column.visible = visible;
      }
    } else {
      this.dataGridInstance.columnOption(DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS, 'visible', visible);
    }
  }

  private _setupDataGridToolbar(): void {
    if (!this._templateEntityListDataGridToolbar?.length || !this.dataGridInstance) {
      return;
    }
    let toolbarItems: Array<ToolbarItem> = <Array<ToolbarItem>>this.dataGridInstance.option('toolbar.items');
    if (!toolbarItems?.length) {
      toolbarItems = [];
    }
    let index = 0;
    let added = false;
    for (const entityListDataGridToolbar of this._templateEntityListDataGridToolbar) {
      if (!entityListDataGridToolbar.item.name) {
        continue;
      }
      toolbarItems.splice(index, 0, entityListDataGridToolbar.item);
      index++;
      added = true;
    }
    if (added) {
      this.dataGridInstance.option('toolbar.items', toolbarItems);
    }
  }

  private _cleanupDataGridToolbar(): void {
    if (!this._templateEntityListDataGridToolbar?.length || !this.dataGridInstance) {
      return;
    }
    const toolbarItems: Array<ToolbarItem> = <Array<ToolbarItem>>this.dataGridInstance.option('toolbar.items');
    if (!toolbarItems?.length) {
      return;
    }
    let removed = false;
    for (const entityListDataGridToolbar of this._templateEntityListDataGridToolbar) {
      const index = toolbarItems.findIndex((toolbarItem: ToolbarItem) => toolbarItem.name === entityListDataGridToolbar.item.name);
      if (index !== -1) {
        toolbarItems.splice(index, 1);
        removed = true;
      }
    }
    if (removed) {
      this.dataGridInstance.option('toolbar.items', toolbarItems);
    }
  }
}
