<div class="modal-saft-contab-import">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'saftcontab.modals.importMatriz.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="generic-importer">
      <pl-nav-wizard instanceId="generic-importer" [definition]="navWizardDefinition" [callback]="navWizardCallback" [onFinalize]="fnFinalize">
        <pl-nav-wizard-step [stepId]="wizardStepIdDownloadModel" caption="components.genericImporter.steps.downloadModel.title" icon="fa-download">
          <div *plNavWizardStepContent>
            <p [translate]="'saftcontab.modals.importMatriz.content.1'"></p>
            <p [translate]="'saftcontab.modals.importMatriz.content.2'"></p>
            <p [translate]="'saftcontab.modals.importMatriz.content.3'"></p>

            <pl-button klass="btn-sm btn-info" [onClick]="downloadModel" plAutoFocus>
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'saftcontab.modals.importMatriz.btn.modClean'"></span>
            </pl-button>

            <pl-button klass="btn-sm btn-info" [onClick]="downloadModelFull">
              <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'saftcontab.modals.importMatriz.btn.modFilled'"></span>
            </pl-button>

            <p class="text-danger">
              <strong [translate]="'components.genericImporter.steps.downloadModel.content.4'"></strong>
            </p>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step
          [stepId]="wizardStepIdUploadModel"
          caption="components.genericImporter.steps.uploadModel.title"
          icon="fa-upload"
          [valid]="uploadedFilledModel"
          [validator]="fnValidatorStepUploadModel"
          [hideNext]="uploadingFilledModel">
          <div *plNavWizardStepContent>
            <p [translate]="'components.genericImporter.steps.uploadModel.content.1'" [translateParams]="{type: textType}"></p>
            <p [translate]="'components.genericImporter.steps.uploadModel.content.2'"></p>
            <pl-upload
              class="generic-importer-uploader"
              [url]="uploadUrl"
              acceptedFiles=".xlsx"
              [hideActions]="true"
              [maxFiles]="1"
              [maxFileSize]="1"
              [uploadMultiple]="false"
              [params]="uploadParams"
              responseType="blob"
              [callback]="uploadCallback"
              (evtAcceptedFile)="uploadingFilledModel = true"
              (evtUploadedFile)="onUploadedFilledModel($event)"
              (evtUploadErrored)="uploadingFilledModel = false">
            </pl-upload>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step
          [stepId]="wizardStepIdAnalyzeErrors"
          caption="components.genericImporter.steps.analyzeErrors.title"
          icon="fa-exclamation-circle"
          [visible]="visibleStepAnalyzeErrors"
          [validator]="fnValidatorStepAnalyzeErrors"
          [hideNext]="true">
          <div *plNavWizardStepContent>
            <p>
              <strong class="text-danger" [translate]="'components.genericImporter.steps.analyzeErrors.content.1.1'"> </strong>&nbsp;<span
                [innerHTML]="'components.genericImporter.steps.analyzeErrors.content.1.2' | translate: {type: textType}"></span>
            </p>
            <p [translate]="'components.genericImporter.steps.analyzeErrors.content.2'"></p>
            <button type="button" class="btn btn-sm btn-info" (click)="downloadErrors()">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'components.genericImporter.actions.downloadErrors'"></span>
            </button>
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!downloadedErrors" (click)="retry()">
              <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'components.genericImporter.actions.retry'"></span>
            </button>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step [stepId]="wizardStepIdSuccess" caption="components.genericImporter.steps.success.title" icon="fa-check">
          <div *plNavWizardStepContent>
            <p [translate]="'components.genericImporter.steps.success.content.1'" [translateParams]="{type: textType}"></p>
            <p [translate]="'components.genericImporter.steps.success.content.2'" [translateParams]="{type: textType}"></p>
          </div>
        </pl-nav-wizard-step>
      </pl-nav-wizard>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
