import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ICrAbDGrid, IJsonCRABD} from '../../../crabd/jsonCrAbD.entity.interface';
import {ENTITY_NAME_CRABD} from '../../../crabd/crabd.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';

@Component({
  selector: 'editar-cruzamentos-abdes',
  templateUrl: './editarCruzamentos.modal.component.html'
})
export class EditarCruzamentosModalComponent extends CGModalComponent<ICrAbDGrid> implements OnInit {
  @Input() public cruzamento: ICrAbDGrid;
  @Input() public new: boolean;
  @Input() public abono: boolean;

  public readonly outputAbdes: string;
  public filterIRS: string;

  private readonly _serviceCrAbD: IEntityService<IJsonCRABD>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
    this._serviceCrAbD = this._entityServiceBuilder.build(ENTITY_NAME_CRABD);
  }

  public ngOnInit(): void {
    this.filterIRS = `grupoDescontoCategoria=${this.cruzamento.categoriaAbDescDestino}`;
  }

  public close(): void {
    if (!this.cruzamento.entSuporteEmpregado && !this.cruzamento.entSuportePatronato) {
      this._plAlertService.error('generateAbdes.editarcruzamentos.temseleentidade');
      return;
    }
    if (isEmpty(this.cruzamento.nomeABDESDestino) || isEmpty(this.cruzamento.nCodDestino)) {
      this._plAlertService.error('generateAbdes.editarcruzamentos.teminserirdesc');
      return;
    }
    if (this.new) {
      super.close(this.cruzamento);
    } else {
      const url: string = this.abono ? `crabonos/${this.cruzamento.nCodOrigem}` : `crdescontos/${this.cruzamento.nCodOrigem}`;
      this._serviceCrAbD.put({url: url, body: this.cruzamento}).then(() => {
        this._plAlertService.success('crabd.updated');
        super.close(this.cruzamento);
      });
    }
  }
}
