import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../services/auth/auth.service';
import {CGModalComponent} from '../../components/cg/modal/cgmodal.component';
import {DATA_SOURCE_TIPOS_COMUNICACAO_AT} from '../../datasources/tipocomunicacaoat/tiposComunicacaoAt.datasource';
import {EFaturaLoginService} from '../../services/efaturalogin/eFaturaLogin.service';
import {EMPTY_PASSWORD} from '../../../config/constants';
import {ETipoComunicacaoAt} from '../../datasources/tipocomunicacaoat/tiposComunicacaoAt.datasource.interface';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {IJsonEFaturaLogin} from '../../services/efaturalogin/eFaturaLogin.service.interface';
import {ROLE} from '../../services/role.const';

const DEFAULT_PASSWORD = '**********';

@Component({
  selector: 'efaturalogin-modal',
  templateUrl: './eFaturaLogin.modal.component.html'
})
export class EFaturaLoginModalComponent extends CGModalComponent<IJsonEFaturaLogin> implements OnInit {
  @Input() public username: string;
  @Input() public tipoComunicacao: number;

  public readonly tiposComunicacao: ReadonlyArray<IDataSourceItem<ETipoComunicacaoAt>>;
  public readonly model: IJsonEFaturaLogin;
  public promise: Promise<void>;
  public isAdmin: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _eFaturaLoginService: EFaturaLoginService
  ) {
    super(_injector);
    this.tiposComunicacao = DATA_SOURCE_TIPOS_COMUNICACAO_AT.data;
    this.model = {username: '', password: EMPTY_PASSWORD, tipoComunicacao: 0};
    this._authService.hasAnyAuthority([ROLE.ADMIN, ROLE.ADMINBASIC]).then((hasAuthority: boolean) => {
      this.isAdmin = hasAuthority;
    });
  }

  public ngOnInit(): void {
    if (this.username) {
      this.model.username = this.username;
      if (this.tipoComunicacao) {
        this.model.tipoComunicacao = this.tipoComunicacao;
      }
    } else {
      this.promise = this._eFaturaLoginService.get().then((response) => {
        this.model.username = response.body.username ? response.body.username : `${this._configService.configurations.empresa.ncontribuemp}/UserId`;
        this.model.password = response.body.password ? response.body.password : '';
        this.model.tipoComunicacao = response.body.tipoComunicacao ? response.body.tipoComunicacao : 0;
      });
    }
  }

  public close(): Promise<void> {
    if (!this.model.username) {
      this._plAlertService.error('efaturalogin.messages.errorUsernameEmpty');
      return Promise.resolve();
    }
    if (!this.model.password) {
      this._plAlertService.error('efaturalogin.messages.errorPasswordEmpty');
      return Promise.resolve();
    }

    if (this.model.password === DEFAULT_PASSWORD) {
      super.close(this.model);
      return Promise.resolve();
    }

    return this._eFaturaLoginService.set(this.model).then((response: HttpResponse<IJsonEFaturaLogin>) => {
      this.model.username = response.body.username ? response.body.username : this.username;
      this.model.password = response.body.password;
      this.model.tipoComunicacao = response.body.tipoComunicacao;
      super.close(this.model);
    });
  }
}
