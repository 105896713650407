import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonDashboardAtivoInconsistencias, IJsonDashboardAtivoResumo} from './jsonDashboardAtivos.module.interface';

const TOP_TEN = 10;

@Injectable({
  providedIn: 'root'
})
export class DashboardAtivosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dashboards`;
  }

  public getAtivosInconsistencias(): TServiceResponse<IJsonDashboardAtivoInconsistencias> {
    return this._apiService.get<IJsonDashboardAtivoInconsistencias>({
      url: `${this._path}/ativos/inconsistencias`
    });
  }

  public ativosResumo(ano: number = 0, force: boolean = false, top: number = TOP_TEN): TServiceResponse<IJsonDashboardAtivoResumo> {
    return this._request('ativos', 'resumo', top, ano, force);
  }

  private _request(endpoint: string, subendpoint: string, top: number, ano: number, force: boolean): TServiceResponse<IJsonDashboardAtivoResumo> {
    return this._apiService.get<IJsonDashboardAtivoResumo>({
      url: `${this._path}/${endpoint}/${subendpoint}`,
      params: {top: top, ano: ano, force: force}
    });
  }
}
