import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {EAtivosAquisicaoBaseCalculo} from '../../entities/ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {DATA_SOURCE_NAME_ATIVOS_BASE_CALCULO} from './ativosBaseCalculo.datasource.interface';

export const DATA_SOURCE_ATIVOS_BASE_CALCULO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ATIVOS_BASE_CALCULO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EAtivosAquisicaoBaseCalculo.InicioUtilizacao, name: 'ativosAquisicao.radioOptions.inicioUtilizacao'},
    {value: EAtivosAquisicaoBaseCalculo.BaseAnual, name: 'ativosAquisicao.radioOptions.baseAnual'}
  ]
});
