import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subscription, timer} from 'rxjs';
import {Logger} from 'pl-comps-angular';

const TIMEOUT_STOP_TRANSCRIPT = 2000;

@Component({
  selector: 'cg-speech-to-text',
  templateUrl: './speechToText.component.html'
})
export class CGSpeechToTextComponent implements OnInit, OnDestroy {
  @Output() public readonly evtSendMessage: EventEmitter<string>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recognition: any;
  public message: string;
  public recognizing: boolean;

  private readonly _subscriptionsRecognition: Array<Subscription>;
  private _subscriptionStopTranscript: Subscription;
  private _ignoreTranscriptOnEnd: boolean;

  constructor(private readonly _logger: Logger) {
    this.evtSendMessage = new EventEmitter<string>();
    this.recognizing = false;
    this._subscriptionsRecognition = [];
    this._ignoreTranscriptOnEnd = false;
  }

  public ngOnInit(): void {
    this._setupTranscript();
  }

  public ngOnDestroy(): void {
    if (this._subscriptionStopTranscript) {
      this._subscriptionStopTranscript.unsubscribe();
    }
    if (this._subscriptionsRecognition) {
      for (const subscription of this._subscriptionsRecognition) {
        subscription.unsubscribe();
      }
    }
  }

  public onClickSendMessage(): void {
    this.evtSendMessage.emit(this.message);
    this.message = '';
  }

  public toggleTranscript(): void {
    if (!this.recognition) {
      this._logger.error('Speech recognition not initialized');
      return;
    }
    if (this.recognizing) {
      this.recognition.stop();
      return;
    }
    this.recognizing = true;
    this.recognition.lang = 'pt-PT';
    this.recognition.start();
    this._ignoreTranscriptOnEnd = false;
  }

  private _setupTranscript(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const speechRecognition = (<any>window).SpeechRecognition || (<any>window).webkitSpeechRecognition;
    if (!speechRecognition) {
      this._logger.error('Speech recognition not supported in this browser');
      return;
    }

    this.recognition = new speechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = false;

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'error').subscribe((event: {error: string}) => {
        this._logger.error('Speech recognition error:', event.error);
        if (this._subscriptionStopTranscript) {
          this._subscriptionStopTranscript.unsubscribe();
        }
        if (event.error === 'not-allowed') {
          this._ignoreTranscriptOnEnd = true;
          this._logger.error('Microphone access is not allowed. Please enable microphone access in your browser settings');
        }
      })
    );

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'end').subscribe(() => {
        this.recognizing = false;
        if (!this._ignoreTranscriptOnEnd && this.message) {
          this.onClickSendMessage();
        }
      })
    );

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'result').subscribe((event: {results: SpeechRecognitionResultList; resultIndex: number}) => {
        if (!event.results) {
          this.recognition.onend = null;
          this.recognition.stop();
          return;
        }

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (!transcript) {
            continue;
          }
          if (event.results[i].isFinal) {
            if (!this.message) {
              this.message = '';
            } else if (!this.message.endsWith(' ')) {
              this.message += ' ';
            }
            this.message += transcript;
          }
        }

        if (this._subscriptionStopTranscript) {
          this._subscriptionStopTranscript.unsubscribe();
        }
        this._subscriptionStopTranscript = timer(TIMEOUT_STOP_TRANSCRIPT).subscribe(() => {
          if (this.recognizing && this.recognition) {
            this.recognition.stop();
          }
        });
      })
    );
  }
}
