import {Component, Injector, OnInit} from '@angular/core';
import {TPlToolbarItem} from 'pl-comps-angular';
import {ExtractosArtigosService} from '../extractosArtigos.module.service';
import {IExtractoArtigoModuleParams, IJsonExtractoArtigoFilter} from '../jsonExtractoArtigo.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

const BTN_PROCESS_XLS = 'btnGenerateXls';

@Component({
  selector: 'module-extratos-artigos',
  templateUrl: './extractosArtigos.module.component.html'
})
export class ExtractosartigosModuleComponent extends ModuloComponent implements OnInit {
  public nArtigo: string;
  public filters: IJsonExtractoArtigoFilter;

  private readonly _btnExportXLS: TPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _extractosArtigosService: ExtractosArtigosService
  ) {
    super(_injector);
    const {nArtigo}: IExtractoArtigoModuleParams = <unknown>this._transition.params();
    this.nArtigo = nArtigo;
    this._btnExportXLS = {
      id: BTN_PROCESS_XLS,
      type: 'download',
      order: 1,
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      disabled: true,
      caption: 'global.btn.processExcel',
      download: {
        url: undefined
      }
    };
  }
  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnExportXLS);
  }

  public evtFiltersChanged(filters: IJsonExtractoArtigoFilter): void {
    this.filters = filters;
    this._extractosArtigosService
      .getExtractosArtigosXlsUrl(
        this.filters.nArtigoDe,
        this.filters.nArtigoAte,
        this.filters.nArmazemDe,
        this.filters.nArmazemAte,
        this.filters.dateDe,
        this.filters.dateAte,
        this.filters.calcMovAntesDepoisData
      )
      .subscribe((url) => {
        this._btnExportXLS.download.url = url;
        this._btnExportXLS.disabled = this.filters.canProcessXl;
      });
  }
}
