import {merge} from 'lodash-es';
import {Directive, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {isArray, isDefinedNotNull} from '../../../common/utilities/utilities';
import type {IPlEditComponentOptionsInput, IPlEditComponentOptionsInputGroup} from '../../component/edit.component.interface';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';

@Directive()
export abstract class PlEditInputsGroupComponent<T, S extends IPlEditComponentOptionsInput<T>> extends PlEditInputComponent<T, IPlEditComponentOptionsInputGroup<T, S>> implements OnInit, OnChanges {
  @Input() public groupItems: Array<S>;

  protected constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._changedGroupItems();
    this.setValidators(this._requiredValidator());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {groupItems} = changes;
    if (groupItems && !groupItems.isFirstChange()) {
      this._changedGroupItems(groupItems.currentValue);
    }
  }

  public updateComponent(properties: IPlEditComponentOptionsInputGroup<T, S>): void {
    super.updateComponent(properties);
    this._changedGroupItems(this.groupItems);
  }

  public render(value: T = this.value): Promise<void> {
    this.model = value;
    this.modelChange.emit(this.model);
    return Promise.resolve();
  }

  public fnTrackBy(index: number): number {
    return index;
  }

  protected _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return isDefinedNotNull(this.model) ? undefined : {required: true};
      }
      return undefined;
    };
  }

  protected _changedGroupItems(value: Array<S> = this.groupItems): void {
    let val: Array<S> = value;
    if (!isArray(val)) {
      val = this.options.groupItems;
    }
    if (!isArray(val)) {
      val = [];
    }
    this.groupItems = val.slice();
    for (let i = 0; i < this.groupItems.length; i++) {
      const groupItem: S = this.groupItems[i];
      this.groupItems[i] = merge({}, groupItem, this.options, {validate: false});
    }
  }
}
