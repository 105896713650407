import {ROLE} from '../../services/role.const';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {HttpResponse} from '@angular/common/http';
import {RendHabitPermModuleComponent} from './components/rendHabitPerm.module.component';
import {ETipoEscolhaRendHabitPerm, ETipoOrdenacao, IJsonRendHabitPerm} from './jsonRendHabitPerm.module.interface';
import {MODULE_NAME_HABITACAO_PERMANENTE} from './rendHabitPerm.module.interface';
import {RendHabitPermService} from './rendHabitPerm.module.service';

export const MODULE_HABITACAO_PERMANENTE: IModuleDefinition = {
  name: MODULE_NAME_HABITACAO_PERMANENTE,
  state: {
    url: `/${MODULE_NAME_HABITACAO_PERMANENTE}`,
    component: RendHabitPermModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rendHabitPerm'
    },
    resolve: [
      {
        provide: 'rendHabitPermList',
        deps: [RendHabitPermService],
        useFactory: (rendHabitPermService: RendHabitPermService): Promise<Array<IJsonRendHabitPerm>> => {
          return rendHabitPermService.getRendHabitPerm(ETipoEscolhaRendHabitPerm.Ativas, ETipoOrdenacao.CodEmp).then((response: HttpResponse<Array<IJsonRendHabitPerm>>) => response.body);
        }
      }
    ]
  }
};
