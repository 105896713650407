import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_SAFT_CONTAB} from './saftContab.module.interface';
import {RESOLVER_IMPORTACAO_SAFT_MAX_FILE_SIZE} from '../../../common/resolvers/saft.resolvers';
import {RESOLVER_LICENCA_STORE_MODE} from '../../services/config/config.service.router';
import {RESOLVER_SESSION} from '../../../config/uirouter/uirouter.resolvers';
import {ROLE} from '../../services/role.const';
import {SaftContabModuleComponent} from './components/saftContab.module.component';
import {SaftContabService} from './saftContab.module.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CGExceptionService} from '../../components/exceptions/exceptions.service';

export const MODULE_SAFT_CONTAB: IModuleDefinition = {
  name: MODULE_NAME_SAFT_CONTAB,
  state: {
    url: `/${MODULE_NAME_SAFT_CONTAB}`,
    component: SaftContabModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.saftcontab'
    },
    resolve: [
      RESOLVER_SESSION,
      RESOLVER_LICENCA_STORE_MODE,
      RESOLVER_IMPORTACAO_SAFT_MAX_FILE_SIZE,
      {
        provide: 'currentStatus',
        deps: [SaftContabService],
        useFactory: (saftContabService: SaftContabService) => saftContabService.getJobStatus()
      },
      {
        provide: 'balanceteSaldadoMessage',
        deps: [SaftContabService, CGExceptionService],
        useFactory: (saftContabService: SaftContabService, cgExceptionService: CGExceptionService) =>
          saftContabService
            .balanceteSaldado()
            .then(() => '')
            .catch((error: HttpErrorResponse) => {
              const exception = cgExceptionService.get(error);
              return exception.message;
            })
      }
    ]
  }
};
