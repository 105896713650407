import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22} from '../../../../../jsonModelo22.module.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';

@Component({
  selector: 'modal-modelo22-newTaxaDerrama',
  templateUrl: './modelo22.newTaxaDerrama.modal.component.html'
})
export class Modelo22NewTaxaDerramaModalComponent extends CGModalComponent<IJsonModelo22> {
  @Input() public modelo22: IJsonModelo22;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
      super.close(response.body);
    });
  }
}
