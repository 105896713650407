import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

const ET_SECONDS_PER_COMPANY = 3;
const SEGUNDOS_PER_MINUTO = 60;

@Component({
  selector: 'proce-salarios-progress-modal',
  templateUrl: './proceSalarios.progress.modal.component.html'
})
export class ProceSalariosProgressModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public processMessage: string;
  @Input() public numEmpresas: number;

  public tempoEstimado: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tempoEstimado = '';
  }

  public ngOnInit(): void {
    const totalSegundos = this.numEmpresas * ET_SECONDS_PER_COMPANY;
    if (totalSegundos > SEGUNDOS_PER_MINUTO) {
      const minutos = Math.floor(totalSegundos / SEGUNDOS_PER_MINUTO);
      const segundos = totalSegundos % SEGUNDOS_PER_MINUTO;
      this.tempoEstimado = `${minutos} minuto(s) ${segundos} segundo(s)`;
    } else {
      this.tempoEstimado = `${this.numEmpresas * ET_SECONDS_PER_COMPANY} segundos`;
    }
  }
}
