<div class="pl-tabs" [ngClass]="theme">
  <ul class="nav" [ngClass]="{'nav-justified': justified, 'nav-stacked': stacked, 'nav-tabs': !pills, 'nav-pills': pills}">
    <li *ngFor="let tab of tabs" class="nav-item">
      <div
        class="nav-link"
        role="tab"
        [id]="tab.id"
        [class.active]="tab.id === activeId"
        [class.disabled]="tab.disabled"
        [class.closable]="tab.closable"
        [attr.tabindex]="tab.disabled ? '-1' : undefined"
        [attr.aria-controls]="!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : undefined"
        [attr.aria-expanded]="tab.id === activeId"
        [attr.aria-disabled]="tab.disabled"
        (click)="select(tab.id); $event.preventDefault()">
        <span [plTranslate]="tab.caption"></span>
        <ng-container *ngTemplateOutlet="tab.templateTitle?.templateRef"></ng-container>
        <button *ngIf="tab.closable" type="button" class="btn-close" (click)="close(tab)"></button>
      </div>
    </li>
  </ul>

  <div class="tab-content">
    <ng-container *ngFor="let tab of tabs">
      <div
        *ngIf="!destroyOnHide || tab.id === activeId"
        class="tab-pane"
        role="tabpanel"
        [id]="tab.id + '-panel'"
        [class.active]="tab.id === activeId"
        [attr.aria-labelledby]="tab.id"
        [attr.aria-expanded]="tab.id === activeId">
        <ng-container *ngTemplateOutlet="tab.templateContent?.templateRef"></ng-container>
      </div>
    </ng-container>
  </div>
</div>
