<div>
  <div class="modal-header">
    <ng-container [ngSwitch]="flagDocumentosClassificados">
      <h4 *ngSwitchCase="true" [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.exportfolder.documentosClassificados'"></h4>
      <h4 *ngSwitchCase="false" [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.exportfolder.documentosNaoClassificados'"></h4>
    </ng-container>
  </div>

  <div class="modal-body">
    <div class="col-12">
      <contabilidadedigital-treeviewer [folderID]="startingFolderId" [showFiles]="false" [checkHasSubFiles]="true" [callback]="callback" (evtFolderChanged)="changedFolder($event)">
      </contabilidadedigital-treeviewer>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="button">
    <button type="button" class="btn btn-sm btn-primary" data-focus (click)="close()" [disabled]="flagDisabled">
      <i class="fa fa-check" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </button>

    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
