<div class="modal-calc-diferimentos-doc-contabilidade">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'global.menu.calculodiferimentos'"></h5>
    <pl-button klass="btn-light btn-sm action-config" (evtClicked)="openConfigModal()" [disabled]="closeDisabled" plAutoFocus>
      <i class="fa fa-fw fa-cog"></i>&nbsp;<span [translate]="'global.btn.config'"></span>
    </pl-button>
  </div>

  <div class="modal-body">
    <calc-diferimentos [diferimentosParams]="diferimentosParams" (evtHeaderChanged)="evtHeaderChanged($event)" (evtOutOffFocusDataFim)="evtOutOffFocusDataFim()"></calc-diferimentos>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button
      klass="btn-success btn-sm action-close"
      #aplicarContab
      [onClick]="close"
      [disabled]="closeDisabled || !this.diferimentosParams?.docBalanceado || !this.diferimentosParams?.valor"
      plAutoFocus>
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span>
    </pl-button>

    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()" [disabled]="closeDisabled" plAutoFocus>
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </pl-button>
  </div>
</div>
