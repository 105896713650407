import {Component, Input} from '@angular/core';
import {isFunction} from 'pl-comps-angular';

@Component({
  selector: 'cg-panel-multiple-selection-execute',
  templateUrl: './panelmultipleselectionexecute.component.html'
})
export class CGPanelMultipleSelectionExecuteComponent {
  @Input() public labelTitle: string;
  @Input() public labelAction: string;
  @Input() public count: number;
  @Input() public executeFn: () => void | Promise<unknown>;

  public readonly fnExecute = (): Promise<void> => this._execute();

  private _execute(): Promise<void> {
    if (isFunction(this.executeFn)) {
      return Promise.resolve(this.executeFn()).then(() => undefined);
    }
    return Promise.resolve();
  }
}
