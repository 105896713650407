import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonFimPeriodo, IJsonFimPeriodoEnc} from '../../jsonFimPeriodoEnc.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'fim-periodoenc-list',
  templateUrl: './fimPeriodoEnc.entity.list.component.html'
})
export class FimPeriodoEncListComponent extends ModuloEntityListComponent<IJsonFimPeriodo> {
  public readonly definition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definition = {
      keyExpr: 'nDiario',
      columns: [
        {dataField: 'nDiario', dataType: 'string', caption: 'diarios.fields.nDiarioPlaceholder'},
        {dataField: 'nome', dataType: 'string', caption: 'diarios.fields.nomePlaceholder'}
      ],
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true,
      headerFilter: {visible: false}
    };
  }

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IJsonFimPeriodoEnc>): Promise<void> {
    return this.service.get<IJsonFimPeriodoEnc>({id: data.periodo}).then((response: HttpResponse<IJsonFimPeriodoEnc>) => {
      data.diarios = response.body.diarios;
    });
  }

  public readonly fnEdit = (fimPeriodo: IJsonFimPeriodo) => (): Promise<void> => this._edit(fimPeriodo);

  private _edit(item: IJsonFimPeriodo): Promise<void> {
    if (item?.periodo) {
      return this._stateService.go(this.states.detail.name, {id: item.periodo}).then(() => undefined);
    }
    return Promise.resolve();
  }
}
