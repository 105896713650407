import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {PlTranslateService} from 'pl-comps-angular';
import {IFctEmitirDocPromptResult} from '../../fct.module.interface';

@Component({
  selector: 'fct-prompt-modal',
  templateUrl: './fct.prompt.modal.component.html'
})
export class FctPromptModalComponent extends CGModalComponent<IFctEmitirDocPromptResult> implements OnInit {
  @Input() public numSelectedRecords: number;
  public promptMessage: string;
  public model: IFctEmitirDocPromptResult;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.model = {
      downloadDoc: true,
      sendEmail: true
    };
  }

  public ngOnInit(): void {
    this.promptMessage = this._plTranslateService.translate('fct.messages.modalPromptMessage', {numEmpSelected: this.numSelectedRecords});
  }
}
