<div class="editar-cruzamentos-abdes entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="close()"></button>
    <h4 class="modal-title" [translate]="'generateAbdes.editarcruzamentos.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-center">
      <span [translate]="'generateAbdes.editarcruzamentos.subtitle'" [translateParams]="{categoria: cruzamento.nomeCategoria}"></span>
    </div>

    <pl-group>
      <label [translate]="'generateAbdes.editarcruzamentos.desconto'"></label>
      <edit>
        <entity-autocomplete
          entity="abdes"
          attrName="desconto"
          [model]="cruzamento"
          (selectedKeyChange)="cruzamento.nCodDestino = $event"
          (evtSelectedDescriptionChanged)="cruzamento.nomeABDESDestino = $event"
          [fieldsMap]="{nCodABDESC: 'nCodDestino', designacaoBreve: 'nomeABDESDestino'}"
          [rowTemplate]="outputAbdes"
          [output]="outputAbdes"
          [filter]="filterIRS"
          outputKey="nCodABDESC"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <div class="card">
      <h6 class="card-header p-1" [translate]="'generateAbdes.editarcruzamentos.temcruzent'"></h6>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <pl-edit
              type="checkbox"
              attrName="empregado"
              [model]="cruzamento.entSuporteEmpregado"
              (modelChange)="cruzamento.entSuporteEmpregado = $event"
              [properties]="{label: 'generateAbdes.editarcruzamentos.empregado'}">
            </pl-edit>
          </div>

          <div class="col">
            <div class="d-flex">
              <label class="me-2"><span [translate]="'generateAbdes.editarcruzamentos.incidencia'"></span>:</label>
              <div class="flex-grow-1">
                <pl-edit type="number" [(model)]="cruzamento.incidenciaEmpregado" [properties]="{disabled: !cruzamento.entSuporteEmpregado}"></pl-edit>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <pl-edit
              type="checkbox"
              attrName="patronato"
              [model]="cruzamento.entSuportePatronato"
              (modelChange)="cruzamento.entSuportePatronato = $event"
              [properties]="{label: 'generateAbdes.editarcruzamentos.patronato'}">
            </pl-edit>
          </div>

          <div class="col">
            <div class="d-flex">
              <label class="me-2"><span [translate]="'generateAbdes.editarcruzamentos.incidencia'"></span>:</label>
              <div class="flex-grow-1">
                <pl-edit type="number" [(model)]="cruzamento.incidenciaPatronato" [properties]="{disabled: !cruzamento.entSuportePatronato}"></pl-edit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary action-close" (click)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

    <button type="button" class="btn btn-danger action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</div>
