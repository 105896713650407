import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {PlPipesModule} from '../pipes/pipes.module';
import {CGCIconDirective} from '../icon';

import {PlSidebarComponent} from './sidebar.component';
import {PlSidebarTitleComponent} from './components/sidebar.title.component';

export * from './sidebar.interface';
export * from './sidebar.component';
export * from './components/sidebar.title.component';

const DECLARATIONS = [PlSidebarComponent, PlSidebarTitleComponent];

@NgModule({
  imports: [CommonModule, FormsModule, PlPipesModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlSidebarModule {}
