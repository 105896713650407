<div class="modal-header ativosimpressao-dep-modal">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'ativosimpressao.modal.ativoimpressaodepmodaltitle'"></h5>
</div>

<div class="modal-body">
  <pl-tabs>
    <pl-tab id="depresumo" caption="ativosimpressao.modal.depresumo">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDepResumo" cgDxDataGridInstanceName="depResumoTable" [dataSource]="dataGridDefinitionDepResumo.dataSource"></dx-data-grid>
      </div>
    </pl-tab>
    <pl-tab id="depDetalhe" caption="ativosimpressao.modal.depDetalhe">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDepDetalhe" cgDxDataGridInstanceName="depDetalheTable" [dataSource]="dataGridDefinitionDepDetalhe.dataSource"></dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'ativosimpressao.btn.ok'"></span></pl-button>
</div>
