import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_METODO_CALC_SALARIO} from './metodoCalcSalario.datasource.interface';
import {EDHEmpMetodoCalcSalario} from '../../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_METODO_CALC_SALARIO: IDataSource<EDHEmpMetodoCalcSalario> = deepFreeze({
  name: DATA_SOURCE_NAME_METODO_CALC_SALARIO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EDHEmpMetodoCalcSalario.None, name: 'dgemps.salaryCalculationMethod.0', label: 'dgemps.salaryCalculationMethod.0'},
    {value: EDHEmpMetodoCalcSalario.Vencimento, name: 'dgemps.salaryCalculationMethod.1', label: 'dgemps.salaryCalculationMethod.1'},
    {value: EDHEmpMetodoCalcSalario.HorasTrabalho, name: 'dgemps.salaryCalculationMethod.2', label: 'dgemps.salaryCalculationMethod.2'}
  ]
});
