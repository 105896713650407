export interface IMoedaNormasISO {
  codigo: string;
  paisNomeMoeda: string;
  nomeMoeda: string;
  extensoUnidade: string;
  extensoNUnidades?: string;
  extensoSubUnidade?: string;
}

export const DATA_SOURCE_NAME_MOEDA_NORMAS_ISO = 'moedaNormasIso';
