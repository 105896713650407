import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDelphiNumberTypes, IPlToolbarItem, IPlTooltipConfig, isDefinedNotNull, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ComplementosExcepcoesModalComponent} from '../../rhcessacontratotrabalho/modals/complementosexpcecoes/complementosExcepcoes.modal.component';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IAnosCalculo} from '../../../ativos/ativosdepreciacao/ativosDepreciacao.module.interface';
import {IExcluidosAbonosDescontos} from '../../rhcessacontratotrabalho/rhCessaContratoTrabalho.module.interface';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {IJsonIMPENCAR} from '../jsonImpEncar.module.interface';
import {IJsonListaExcl, IJsonListaExclProcessamento} from '../../rhfolfer/jsonRHFolfer.module.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ImpencarService} from '../impEncar.module.service';
import {ImpFFERPOCService} from '../../impfferpoc/impFFERPOC.module.service';
import {MAX_STRING} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment, {Moment} from 'moment';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {TDate} from '../../../../../common/dates';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const BTN_PROCESS_PDF = 'processarPDF';

@Component({
  selector: 'module-impencar',
  templateUrl: './impEncar.module.component.html'
})
export class ImpencarModuleComponent extends ModuloComponent implements OnInit {
  public readonly metodoSource: Array<IAnosCalculo>;
  public readonly empresasTemplate: string;
  public readonly tooltipExcepcoesProcessamento: IPlTooltipConfig;
  public header: IJsonIMPENCAR;
  public empresas: Array<IJsonErpUser>;
  public empresasSource: Array<IJsonErpUser>;
  public exclProcessamento: IJsonListaExclProcessamento;
  public report: IJsonReport;
  public promiseProcessamento: Promise<void>;
  public pdfUrl: string;
  public listaDateProcDistinct: Array<Moment>;

  private readonly _btnProcessPdf: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _impencarService: ImpencarService,
    private readonly _impFFERPOCService: ImpFFERPOCService,
    private readonly _cgModalService: CGModalService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tooltipExcepcoesProcessamento = {text: 'impencar.infos.tooltipExcepcoesProcessamento', placement: 'bottom', container: 'body'};
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.empresas = [];
    this.listaDateProcDistinct = [];

    this._impFFERPOCService.getDatasProcessamentos().then((response: HttpResponse<Array<TDate>>) => {
      this.listaDateProcDistinct = response.body.map((data: TDate) => moment(data));
    });

    this.header = {
      dataDe: moment().startOf('month'),
      dataAte: moment().endOf('month'),
      codEmpDe: 0,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      codEncargoDe: '',
      codEncargoAte: MAX_STRING
    };

    this._btnProcessPdf = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => {
        this._previsualizar();
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.exclProcessamento = {
      ateData: undefined,
      deData: undefined,
      listaExcluidos: [],
      listaPossiveis: []
    };
    this.toolbar.addButton(this._btnProcessPdf);
    this._loadEmpresas();
    this._loadDefaultReport();
  }

  public openExclusionsProcessamento(): Promise<void> {
    if (this.empresas.length !== 1 || (this.empresas.length === 1 && this.empresas[0].nEmpresa !== this.session.erp.nEmpresa)) {
      this._plAlertService.error('rhimpvenct.messages.exclprocessamentoemp');
      return Promise.resolve();
    }
    this.exclProcessamento.deData = this.header.dataDe;
    this.exclProcessamento.ateData = this.header.dataAte;
    this.promiseProcessamento = this._impencarService.postExclusoesProcessamento(this.exclProcessamento).then((response: HttpResponse<IJsonListaExclProcessamento>) => {
      this.exclProcessamento.listaPossiveis = response.body.listaPossiveis;
      this.exclProcessamento.listaExcluidos = response.body.listaExcluidos;
      return this.openExclusions();
    });
    return this.promiseProcessamento.finally(() => {
      this.promiseProcessamento = undefined;
    });
  }

  public openExclusions(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComplementosExcepcoesModalComponent);
    const componentInstance: ComplementosExcepcoesModalComponent = modalInstance.componentInstance;
    componentInstance.excludedListPossiveis = this.exclProcessamento.listaPossiveis.slice();
    componentInstance.excludedListExcluidos = this.exclProcessamento.listaExcluidos.slice();
    return modalInstance.result.then((result: IExcluidosAbonosDescontos) => {
      this.exclProcessamento.listaPossiveis = result.possiveis;
      this.exclProcessamento.listaExcluidos = result.excluidos;
    });
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_PDF);
    }
  }

  private _previsualizar(): void {
    const listEmpresas = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join('-');
    const listExclProcessamento =
      this.empresas.length !== 1 && this.empresas[0].nEmpresa !== this.session.erp.nEmpresa ? '' : this.exclProcessamento.listaExcluidos.map((value: IJsonListaExcl) => value.value).join('-');
    this._impencarService.getListagemIMPENCARUrl(this.header, listEmpresas, listExclProcessamento, this.report.name).subscribe((url: string) => {
      this.pdfUrl = url;
      this._cardPanel.collapse();
    });
  }

  private _loadEmpresas(): boolean {
    const empresassel: Array<IJsonErpUser> = [...this.session.erps];
    let idx = -1;
    const foundItem = empresassel.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresassel.splice(idx, 1);
      this.empresas = [foundItem];
    }
    this.empresasSource = empresassel;
    return true;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ImpENCAR)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      });
  }
}
