import {TDate} from '../../../common/dates';

export interface IListagemContratosFilters {
  declifo: string;
  ateclifo: string;
  dencontrato: number;
  atencontrato: number;
  classes: Array<string>;
  tipos: Array<string>;
  dtdeini: TDate;
  dtateini: TDate;
  dtdefim: TDate;
  dtatefim: TDate;
  dtdedatactr: TDate;
  dtatedatactr: TDate;
  reportname: string;
}

export const ENTITY_NAME_CONTRATOS = 'contratos';

export const ENTITY_NAME_AVENCAS = 'contratosservicos';
