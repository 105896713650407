<pl-nav-wizard [definition]="definitionNavWizard" [beforeStepChange]="fnTransfereContas">
  <pl-nav-wizard-step [stepId]="steps.INTRO" icon="fa-home" caption="trfLancContas.stepIntroCaption">
    <div *plNavWizardStepContent [innerHTML]="'trfLancContas.stepIntroMessage' | translate"></div>
  </pl-nav-wizard-step>

  <pl-nav-wizard-step [stepId]="steps.CONTAS" icon="fa-th-list" caption="trfLancContas.stepContasCaption" [hidePrevious]="true">
    <div *plNavWizardStepContent class="entity-detail-form">
      <pl-form>
        <pl-group>
          <label [translate]="'trfLancContas.fields.nContaOrigem'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="nContaOrigem"
              [model]="model"
              (modelChange)="changedContaOrigem($event)"
              [fieldsMap]="{nConta: 'nContaOrigem', nome: 'nomeContaOrigem'}"
              [output]="'{{nConta}} - {{nome}}'"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group [properties]="{disabled: !model.nContaOrigem, validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'trfLancContas.fields.nContaDestino'"></label>
            <edit>
              <pl-edit-text attrName="nContaDestino" [model]="model.nContaDestino" (modelChange)="changedContaDestino($event)" [properties]="{modelOptions: {debounce: 500}}"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'trfLancContas.fields.nomeContaDestino'"></label>
            <edit>
              <pl-edit type="text" attrName="nomeContaDestino" [(model)]="model.nomeContaDestino"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="contaDestinoJaExiste">
          <edit>
            <div class="text-danger" [innerHTML]="'trfLancContas.messages.contadestjaexiste' | translate"></div>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-edit type="boolean" attrName="manterContaOrigem" [(model)]="model.manterContaOrigem" [properties]="{label: 'trfLancContas.fields.manterContaOrigem'}"></pl-edit>
          </edit>
        </pl-group>

        <dx-data-grid [cgDxDataGrid]="definition" cgDxDataGridInstanceName="trflanccontas" [dataSource]="model.dependencias"></dx-data-grid>
      </pl-form>
    </div>
  </pl-nav-wizard-step>

  <pl-nav-wizard-step [stepId]="steps.FINALIZAR" icon="fa-cog" caption="trfLancContas.stepFinalizeCaption" [hideFinalize]="true" [hidePrevious]="false">
    <p *plNavWizardStepContent [translate]="'trfLancContas.stepFinalizeMessage'"></p>
  </pl-nav-wizard-step>
</pl-nav-wizard>
