import {Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {getPathValue, IPlNavPillEventSelected, isDefined, isFunction, isObject} from 'pl-comps-angular';
import {CONTABILIDADE_PREDEFINIDOS_DEFAULT_CAB_VISUAL_PROPERTIES} from '../linhas/predefinidocontablinhas.component.interface';
import {
  EPreDefinidoContabOrigemDataDoc,
  EPreDefinidoContabOrigemDataLancamento,
  EPreDefinidoContabOrigemDataVenc,
  EPreDefinidoContabTipoValorCabDiario,
  EPreDefinidoContabTipoValorCabPeriodo,
  EPreDefinidoContabTipoValorDescricao,
  EPreDefinidoContabTipoValorDescritivo,
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabCabecalho,
  TPredefinidoFieldCabecalho,
  TPredefinidoVisibilidadeField
} from '../../jsonPreDefinidosContab.module.interface';
import {IPreDefinidoContabCabCallback, IPreDefinidoContabCabPanel, IPreDefinidoContabCabPanelField, IPreDefinidoContabCabVisualProperty} from './predefinidocontabcab.component.interface';

@Component({
  selector: 'predefinidocontabcab',
  templateUrl: './predefinidocontabcab.component.html'
})
export class PredefinidoContabCabComponent implements OnInit, OnChanges, DoCheck {
  @Input() public predefinido: IJsonPreDefinidoContab;
  @Input() public valid: boolean;
  @Input() public callback: IPreDefinidoContabCabCallback;
  @Output() public readonly validChange: EventEmitter<boolean>;

  public readonly visualProperties: ReadonlyArray<IPreDefinidoContabCabVisualProperty>;
  public panels: Array<IPreDefinidoContabCabPanel>;
  public cabecalho: IJsonPreDefinidoContabCabecalho;

  private readonly _visitedPanels: Set<string>;
  private _previousCGBanking: boolean;

  constructor() {
    this.validChange = new EventEmitter<boolean>();
    this.valid = false;
    this.visualProperties = CONTABILIDADE_PREDEFINIDOS_DEFAULT_CAB_VISUAL_PROPERTIES;
    this._visitedPanels = new Set<string>(['periodo']);
    this._previousCGBanking = false;
  }

  public ngOnInit(): void {
    this._initPanels();
    this.cabecalho = this.predefinido.cabecalho;
  }

  public ngOnChanges({predefinido, callback}: SimpleChanges): void {
    if (predefinido && !predefinido.isFirstChange()) {
      const predefinidoContabilidade: IJsonPreDefinidoContab = this.predefinido;
      if (isObject(predefinidoContabilidade)) {
        this._checkChangedCGBanking();
        this.cabecalho = predefinidoContabilidade.cabecalho;
      }
    }
    if (callback) {
      const cb: IPreDefinidoContabCabCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.validatePanels = () => {
          this._validatePanels();
        };
        cb.resetPanels = () => {
          this._resetPanels();
        };
      }
    }
  }

  public ngDoCheck(): void {
    this._checkChangedCGBanking();
  }

  public fieldValueChanged(cabecalhoFieldName: TPredefinidoFieldCabecalho, fieldName: TPredefinidoVisibilidadeField, value: unknown): void {
    // @ts-expect-error Typescript has a limitation where this line resolves to `never` type
    this.cabecalho[cabecalhoFieldName].visibilidadeProperties[fieldName] = value;
  }

  public fieldChanged(field: IPreDefinidoContabCabPanelField): void {
    if (isFunction(field.onChange)) {
      field.onChange();
    }
  }

  public changedPanel(event: IPlNavPillEventSelected): void {
    this._visitedPanels.add(event.nextId);
    this._setIsValid(this._visitedPanels.size >= this.panels.length);
  }

  public getPanelClass(panel: IPreDefinidoContabCabPanel, index: number): string {
    let css = 'predefinidocontabcab-panel';
    if (!index || this._visitedPanels.has(panel.name)) {
      css += ' complete';
    }
    return css;
  }

  public isFieldVisible(field: IPreDefinidoContabCabPanelField): boolean {
    if (isFunction(field.visible)) {
      const result = field.visible();
      return isDefined(result) ? result : true;
    }
    return true;
  }

  private _initPanels(): void {
    this.panels = [
      {name: 'periodo', caption: 'predefinidocontabcab.periodo', fields: this._getDefinition('periodo')},
      {name: 'diario', caption: 'predefinidocontabcab.diario', fields: this._getDefinition('diario')},
      {name: 'nDocInterno', caption: 'predefinidocontabcab.nDocInterno', fields: this._getDefinition('nDocInterno')},
      {name: 'dataLancamento', caption: 'predefinidocontabcab.dataLancamento', fields: this._getDefinition('dataLancamento')},
      {name: 'dataVencimento', caption: 'predefinidocontabcab.dataVencimento', fields: this._getDefinition('dataVencimento')},
      {name: 'dataDoc', caption: 'predefinidocontabcab.dataDoc', fields: this._getDefinition('dataDoc')},
      {name: 'nContribuinte', caption: 'predefinidocontabcab.nContribuinte', fields: this._getDefinition('nContribuinte')},
      {name: 'nDocExterno', caption: 'predefinidocontabcab.nDocExterno', fields: this._getDefinition('nDocExterno')},
      {name: 'descricao', caption: 'predefinidocontabcab.descricao', fields: this._getDefinition('descricao')},
      {name: 'descritivo', caption: 'predefinidocontabcab.descritivo', fields: this._getDefinition('descritivo')}
    ];
  }

  private _getDefinition(field: TPredefinidoFieldCabecalho): Array<IPreDefinidoContabCabPanelField> {
    switch (field) {
      case 'periodo':
        return [
          {
            name: 'tipoValorPeriodo',
            type: 'select',
            caption: 'predefinidocontabcab.fields.tipoValorPeriodo',
            default: EPreDefinidoContabTipoValorCabPeriodo.TabelaEmpresa,
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabTipoValorCabPeriodo.TabelaEmpresa, name: 'predefinidocontabcab.enums.tipoValorPeriodo.0'},
                  {value: EPreDefinidoContabTipoValorCabPeriodo.DataSistema, name: 'predefinidocontabcab.enums.tipoValorPeriodo.1'},
                  {
                    value: EPreDefinidoContabTipoValorCabPeriodo.DataDocEFatura,
                    name: !this.predefinido?.cgBanking ? 'predefinidocontabcab.enums.tipoValorPeriodo.2' : 'predefinidocontabcab.dataTransacaoBanco'
                  }
                ]
              }
            }
          }
        ];
      case 'diario':
        return [
          {
            name: 'tipoValorDiario',
            type: 'select',
            caption: 'predefinidocontabcab.fields.tipoValorDiario',
            default: EPreDefinidoContabTipoValorCabDiario.NaoDefinido,
            onChange: () => {
              if (this._getModelValue('diario.tipoValorDiario') === EPreDefinidoContabTipoValorCabDiario.NaoDefinido) {
                this._setModelValue('diario.valorFixo', undefined);
              }
            },
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabTipoValorCabDiario.NaoDefinido, name: 'predefinidocontabcab.enums.tipoValorDiario.0'},
                  {value: EPreDefinidoContabTipoValorCabDiario.Fixo, name: 'predefinidocontabcab.enums.tipoValorDiario.1'}
                ]
              }
            }
          },
          {
            name: 'valorFixo',
            type: 'diarios',
            caption: 'predefinidocontabcab.fields.valorFixoDiario',
            visible: () => this._getModelValue('diario.tipoValorDiario') === EPreDefinidoContabTipoValorCabDiario.Fixo,
            properties: {inlineMode: true, entity: {output: 'key'}}
          }
        ];
      case 'dataDoc':
        return [
          {
            name: 'origem',
            type: 'select',
            caption: 'predefinidocontabcab.fields.origem',
            default: EPreDefinidoContabOrigemDataLancamento.UltimoDiaPeriodo,
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabOrigemDataDoc.NaoDefinido, name: 'predefinidocontabcab.enums.origemDataDoc.0'},
                  {
                    value: EPreDefinidoContabOrigemDataDoc.DataDocEFatura,
                    name: !this.predefinido?.cgBanking ? 'predefinidocontabcab.enums.origemDataDoc.1' : 'predefinidocontabcab.dataTransacaoBanco'
                  }
                ]
              }
            }
          }
        ];
      case 'dataVencimento':
        return [
          {
            name: 'origem',
            type: 'select',
            caption: 'predefinidocontabcab.fields.origem',
            default: EPreDefinidoContabOrigemDataVenc.NaoDefinido,
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabOrigemDataVenc.NaoDefinido, name: 'predefinidocontabcab.enums.origemDataVencimento.0'},
                  {value: EPreDefinidoContabOrigemDataVenc.CondPagamentoClifoEFatura, name: 'predefinidocontabcab.enums.origemDataVencimento.1'}
                ]
              }
            }
          }
        ];
      case 'dataLancamento':
        return [
          {
            name: 'origem',
            type: 'select',
            caption: 'predefinidocontabcab.fields.origem',
            default: EPreDefinidoContabOrigemDataDoc.NaoDefinido,
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabOrigemDataLancamento.UltimoDiaPeriodo, name: 'predefinidocontabcab.enums.origemDataLancamento.0'},
                  {value: EPreDefinidoContabOrigemDataLancamento.DataAtual, name: 'predefinidocontabcab.enums.origemDataLancamento.1'},
                  {
                    value: EPreDefinidoContabOrigemDataLancamento.DataDocEFatura,
                    name: !this.predefinido?.cgBanking ? 'predefinidocontabcab.enums.origemDataLancamento.2' : 'predefinidocontabcab.dataTransacaoBanco'
                  }
                ]
              }
            }
          }
        ];
      case 'nDocExterno':
        return [
          {
            name: 'incrementaAutomaticamente',
            type: 'boolean',
            caption: 'predefinidocontabcab.fields.incrementaAutomaticamente',
            default: false,
            onChange: () => {
              if (this._getModelValue('nDocExterno.incrementaAutomaticamente') === true) {
                this._setModelValue('nDocExterno.valorFixo', undefined);
              }
            }
          },
          {
            name: 'valorFixo',
            type: 'text',
            caption: 'predefinidocontabcab.fields.valorFixoNDocExterno',
            default: '',
            visible: () => this._getModelValue('nDocExterno.incrementaAutomaticamente') !== true
          }
        ];
      case 'descricao':
        return [
          {
            name: 'tipoValorDescricao',
            type: 'select',
            caption: 'predefinidocontabcab.fields.origemValor',
            default: EPreDefinidoContabTipoValorDescricao.Fixo,
            onChange: () => {
              if (this._getModelValue('descricao.tipoValorDescricao') !== EPreDefinidoContabTipoValorDescricao.Fixo) {
                this._setModelValue('descricao.valorFixo', undefined);
              }
            },
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabTipoValorDescricao.NaoDefinido, name: 'predefinidocontabcab.enums.valorDescricao.0'},
                  {value: EPreDefinidoContabTipoValorDescricao.Fixo, name: 'predefinidocontabcab.enums.valorDescricao.1'}
                ]
              }
            }
          },
          {
            name: 'valorFixo',
            type: 'text',
            caption: 'predefinidocontabcab.fields.valorFixoDescricao',
            default: '',
            visible: () => this._getModelValue('descricao.tipoValorDescricao') === EPreDefinidoContabTipoValorDescricao.Fixo
          }
        ];
      case 'descritivo':
        return [
          {
            name: 'tipoValorDescritivo',
            type: 'select',
            caption: 'predefinidocontabcab.fields.origemValor',
            default: EPreDefinidoContabTipoValorDescritivo.Fixo,
            onChange: () => {
              if (this._getModelValue('descritivo.tipoValorDescritivo') !== EPreDefinidoContabTipoValorDescritivo.Fixo) {
                this._setModelValue('descritivo.valorFixo', undefined);
              }
            },
            properties: {
              select: {
                list: [
                  {value: EPreDefinidoContabTipoValorDescritivo.NaoDefinido, name: 'predefinidocontabcab.enums.valorDescritivo.0'},
                  {value: EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento, name: 'predefinidocontabcab.enums.valorDescritivo.1'},
                  {value: EPreDefinidoContabTipoValorDescritivo.Fixo, name: 'predefinidocontabcab.enums.valorDescritivo.2'}
                ]
              }
            }
          },
          {
            name: 'valorFixo',
            type: 'descritivos',
            caption: 'predefinidocontabcab.fields.valorFixoDescritivo',

            visible: () => this._getModelValue('descritivo.tipoValorDescritivo') === EPreDefinidoContabTipoValorDescritivo.Fixo,
            properties: {inlineMode: true, entity: {output: 'key'}}
          }
        ];
      default:
        return [];
    }
  }

  private _checkChangedCGBanking(): void {
    if (this.predefinido?.cgBanking !== this._previousCGBanking) {
      this._initPanels();
      this._previousCGBanking = this.predefinido?.cgBanking;
    }
  }

  private _validatePanels(): void {
    this._setPanelsStatus(true);
  }

  private _resetPanels(): void {
    this._setPanelsStatus(false);
  }

  private _setPanelsStatus(value: boolean): void {
    this._visitedPanels.clear();
    if (!value) {
      this._setIsValid(false);
    } else {
      this._setIsValid(true);
      const visitedPanels: Array<string> = this.panels.map((panel) => panel.name);
      for (const visitedPanel of visitedPanels) {
        this._visitedPanels.add(visitedPanel);
      }
    }
  }

  private _getModelObject(path: string): object {
    return getPathValue(this.cabecalho, path);
  }

  private _getModelValue(path: string): unknown {
    const property = path.substring(path.lastIndexOf('.') + 1 || 0, path.length);
    return this._getModelObject(path)[property];
  }

  private _setModelValue(path: string, value: unknown): unknown {
    const property = path.substring(path.lastIndexOf('.') + 1 || 0, path.length);
    const object = this._getModelObject(path);
    if (isDefined(object)) {
      object[property] = value;
      return object[property];
    }
    return undefined;
  }

  private _setIsValid(value: boolean): void {
    this.valid = value;
    this.validChange.emit(this.valid);
  }
}
