import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {IPlToolbarItem, isEmpty, isNumber, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {AtivosVendaContabModalComponent} from '../modal/contab/ativosVenda.contab.modal.component';
import {AtivosVendaService} from '../ativosVenda.module.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAtivosVenda, IJsonAtivoVenda, IJsonAtivoVendaConta} from '../jsonAtivosVenda.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {round} from '../../../../../common/utils/utils';
import {EDistribuiValorAtivosVenda, IAtivosVendaParams, IAtivoVenda, IAtivoVendaContab, RADIO_GROUP_ATIVO_VENDA_DIST_VALOR, TAtivoVendaStatus} from '../ativosVenda.module.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventRowDraggingOnAdd
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {HttpResponse} from '@angular/common/http';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const NUMBER_TWO = 2;
const FORMAT_VALUE = '#,##0.00';

@Component({
  selector: 'module-ativos-venda',
  templateUrl: './ativosVenda.module.component.html'
})
export class AtivosVendaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public ativosporvender: Array<IJsonAtivoVenda>;

  public readonly pocsOutput: string;
  public readonly radioGroupTemplateDistValorVenda: IRadioGroup<EDistribuiValorAtivosVenda>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridPorVenderFields: Array<IDevExpressDataGridColumn<IAtivoVenda, number>>;
  public readonly dataGridAVenderFields: Array<IDevExpressDataGridColumn<IAtivoVenda, number>>;

  public isFromAtivos: boolean;
  public selectedAtivoPorVender: IAtivoVenda;
  public selectedAtivoAAvender: IAtivoVenda;
  public ativosvenda: IJsonAtivosVenda;
  public distribuiValor: EDistribuiValorAtivosVenda;
  public contaDoCliente: string;
  public listContaMaisMenosVal: Array<IJsonAtivoVendaConta>;
  public listContaDoIva: Array<IJsonAtivoVendaConta>;
  public valorTotal: number;
  public valorVendaTotal: number;

  public emptyAtivosPorVenderTable: boolean;
  public emptyAtivosAVenderTable: boolean;

  private _dataGridInstancePorVender: dxDataGrid;
  private _dataGridInstanceAVender: dxDataGrid;
  private _dataGridStore: ArrayStore;
  private _btnRealizaVendas: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosVendaService: AtivosVendaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.pesquisafaturavenda = this.pesquisafaturavenda.bind(this);
    this.dataGridDefinition = {
      export: {filename: 'global.menu.ativosvenda'},
      height: '60vh',
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      columnAutoWidth: true,
      scrolling: {rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {
            column: 'valorVenda',
            displayFormat: '{0}',
            skipEmptyValues: true,
            summaryType: 'sum',
            valueFormat: 'double'
          },
          {
            column: 'valorAtivoBrutoContab',
            displayFormat: '{0}',
            skipEmptyValues: true,
            summaryType: 'sum',
            valueFormat: 'double'
          },
          {
            column: 'valorDepreciacoesAcumuladasContab',
            displayFormat: '{0}',
            skipEmptyValues: true,
            summaryType: 'sum',
            valueFormat: 'double'
          }
        ]
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    const formatValue = FORMAT_VALUE;
    const capCodAtivo: string = this._plTranslateService.translate('ativosvenda.table.codAtivo');
    const capDesignacao: string = this._plTranslateService.translate('ativosvenda.table.designacao');
    const capRefExterna: string = this._plTranslateService.translate('ativosvenda.table.refExterna');
    const capDataAquisicao: string = this._plTranslateService.translate('ativosvenda.table.dataAquisicao');
    const capValorAtivoBrutoContab: string = this._plTranslateService.translate('ativosvenda.table.valorAtivoBrutoContab');
    const capValorDepreciacoesAcumuladasContab: string = this._plTranslateService.translate('ativosvenda.table.valorDepreciacoesAcumuladasContab');
    const capValorVenda: string = this._plTranslateService.translate('ativosvenda.table.valorVenda');
    this.dataGridPorVenderFields = [
      {
        dataField: 'codAtivo',
        dataType: 'number',
        caption: capCodAtivo,
        width: 95
      },
      {dataField: 'designacao', dataType: 'string', caption: capDesignacao},
      {
        dataField: 'refExterna',
        dataType: 'string',
        caption: capRefExterna,
        width: 90
      },
      {
        dataField: 'dataAquisicao',
        dataType: 'date',
        caption: capDataAquisicao,
        width: 95
      },
      {
        dataField: 'valorAtivoBrutoContab',
        dataType: 'number',
        caption: capValorAtivoBrutoContab,
        format: formatValue,
        alignment: 'right',
        width: 120
      },
      {
        dataField: 'valorDepreciacoesAcumuladasContab',
        dataType: 'number',
        caption: capValorDepreciacoesAcumuladasContab,
        format: formatValue,
        alignment: 'right',
        width: 120
      },
      {
        dataField: 'status',
        dataType: 'string',
        visible: false,
        showInColumnChooser: false,
        allowSearch: false
      },
      {
        dataField: 'valorVenda',
        dataType: 'number',
        allowSearch: false,
        visible: false,
        showInColumnChooser: false
      }
    ];
    this.dataGridAVenderFields = [
      {
        dataField: 'codAtivo',
        dataType: 'number',
        caption: capCodAtivo,
        allowEditing: false,
        width: 95
      },
      {
        dataField: 'designacao',
        dataType: 'string',
        caption: capDesignacao,
        allowEditing: false
      },
      {
        dataField: 'valorVenda',
        dataType: 'number',
        caption: capValorVenda,
        allowSearch: false,
        format: formatValue,
        alignment: 'right',
        allowEditing: true,
        width: 120
      },
      {
        dataField: 'refExterna',
        dataType: 'string',
        caption: capRefExterna,
        allowEditing: false,
        width: 90
      },
      {
        dataField: 'dataAquisicao',
        dataType: 'date',
        caption: capDataAquisicao,
        allowEditing: false,
        width: 95
      },
      {
        dataField: 'valorAtivoBrutoContab',
        dataType: 'number',
        caption: capValorAtivoBrutoContab,
        format: formatValue,
        alignment: 'right',
        allowEditing: false,
        width: 120
      },
      {
        dataField: 'valorDepreciacoesAcumuladasContab',
        dataType: 'number',
        caption: capValorDepreciacoesAcumuladasContab,
        format: formatValue,
        alignment: 'right',
        allowEditing: false,
        width: 120
      },
      {
        dataField: 'status',
        dataType: 'string',
        visible: false,
        showInColumnChooser: false,
        allowSearch: false,
        allowEditing: false
      }
    ];
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.selectedAtivoPorVender = undefined;
    this.selectedAtivoAAvender = undefined;
    this.listContaDoIva = [];
    this.listContaMaisMenosVal = [];
    this.contaDoCliente = '';
    this.valorTotal = 0;
    this.distribuiValor = EDistribuiValorAtivosVenda.PropLiquido;
    this.valorVendaTotal = 0;
    const params: IAtivosVendaParams = <IAtivosVendaParams>this._transition.params();
    this.isFromAtivos = params.isfromativos;
    this.radioGroupTemplateDistValorVenda = RADIO_GROUP_ATIVO_VENDA_DIST_VALOR;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.ativosvenda) {
      this.ativosvenda = this._emptyAtivosVenda();
    }
    this._btnRealizaVendas = {
      id: 'realizarvenda',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosvenda.btn.realizarvenda',
      disabled: true,
      click: () => this._realizarVenda()
    };
    this.toolbar.addButton(this._btnRealizaVendas);
    if (this.isFromAtivos) {
      this.pesquisafaturavenda();
    }
    this._initAtivosVendaStore(true);
  }

  public allRight(): Promise<void> {
    return this._dataGridInstancePorVender
      .getDataSource()
      .store()
      .load()
      .then((dataSourcePorVender: Array<IAtivoVenda>) => {
        const values: Partial<IAtivoVenda> = {status: 'aVender'};
        for (const porVender of dataSourcePorVender) {
          this._updateDataGridStoreStatus(porVender.codAtivo, values);
        }
        this.executeCalc();
        this._dataGridInstancePorVender?.deselectAll();
        this._dataGridInstanceAVender?.deselectAll();
        this.selectedAtivoPorVender = undefined;
        this.selectedAtivoAAvender = undefined;
      });
  }

  public selectedRight(): Promise<void> {
    if (this.selectedAtivoPorVender) {
      this._dataGridInstancePorVender.beginCustomLoading(undefined);
      this._dataGridInstanceAVender.beginCustomLoading(undefined);
      const values: Partial<IAtivoVenda> = {status: 'aVender'};
      this._updateDataGridStoreStatus(this.selectedAtivoPorVender.codAtivo, values).then(() => {
        this.executeCalc();
      });
      this.selectedAtivoPorVender = undefined;
    }
    return Promise.resolve();
  }

  public selectedLeft(): Promise<void> {
    if (this.selectedAtivoAAvender) {
      this._dataGridInstancePorVender.beginCustomLoading(undefined);
      this._dataGridInstanceAVender.beginCustomLoading(undefined);
      const values: Partial<IAtivoVenda> = {status: 'porVender'};
      this.selectedAtivoAAvender.valorVenda = 0;
      this._updateDataGridStoreStatus(this.selectedAtivoAAvender.codAtivo, values).then(() => {
        return this.executeCalc();
      });
      this.selectedAtivoAAvender = undefined;
    }
    return Promise.resolve();
  }

  public allLeft(): Promise<void> {
    this._dataGridInstancePorVender.beginCustomLoading(undefined);
    this._dataGridInstanceAVender.beginCustomLoading(undefined);
    const dataSourceAVender: Array<IAtivoVenda> = this._dataGridInstanceAVender.getDataSource().items();
    for (const venda of dataSourceAVender) {
      venda.valorVenda = 0;
    }
    const values: Partial<IAtivoVenda> = {status: 'porVender'};
    for (const aVender of dataSourceAVender) {
      this._updateDataGridStoreStatus(aVender.codAtivo, values);
    }
    this.selectedAtivoPorVender = undefined;
    this.selectedAtivoAAvender = undefined;
    this._dataGridInstancePorVender?.deselectAll();
    this._dataGridInstanceAVender?.deselectAll();
    this.valorVendaTotal = 0;
    return Promise.resolve();
  }

  public executeCalc(): Promise<void> {
    switch (this.distribuiValor) {
      case EDistribuiValorAtivosVenda.PropLiquido:
        this._calculaByValorLiquido();
        break;
      case EDistribuiValorAtivosVenda.PropBruto:
        this._calculaByValorBruto();
        break;
      case EDistribuiValorAtivosVenda.Manualmente:
        this._calculaByManual();
    }
    return Promise.resolve();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized, porVender: boolean): void {
    if (porVender) {
      this._dataGridInstancePorVender = component;
    } else {
      this._dataGridInstanceAVender = component;
    }
  }

  public onCellClick({row}: IDevExpressDataGridEventOnCellClick<IAtivoVenda>): void {
    if (row.data.status === 'porVender') {
      this.selectedAtivoPorVender = row.data;
    } else {
      this.selectedAtivoAAvender = row.data;
    }
  }

  public onCellDblClick({data}: IDevExpressDataGridEventOnCellDblClick<IAtivoVenda>): Promise<void> {
    if (!this.ativosvenda.contaCliente && !this.ativosvenda.listContaMaisMenosValia?.length) {
      return Promise.resolve();
    }

    if (data.status === 'porVender') {
      this.selectedAtivoPorVender = data;
      return this.selectedRight();
    }

    this.selectedAtivoAAvender = data;
    return this.selectedLeft();
  }

  public onContentReady(): void {
    this.emptyAtivosPorVenderTable = this._dataGridInstancePorVender?.totalCount() === 0;
    this.emptyAtivosAVenderTable = this._dataGridInstanceAVender?.totalCount() === 0;
    const valorVendaTot: number = this._dataGridInstanceAVender?.getTotalSummaryValue('valorVenda');
    this.valorVendaTotal = isNumber(valorVendaTot) ? round(valorVendaTot, NUMBER_TWO) : 0;

    this._validateBtnRealizaVenda();
    this._dataGridInstancePorVender.endCustomLoading();
    this._dataGridInstanceAVender.endCustomLoading();
  }

  public readonly fnOnAddVendaTable = (event: IDevExpressDataGridEventRowDraggingOnAdd<TAtivoVendaStatus, IAtivoVenda>): Promise<void> => this._onAddVendaTable(event);

  public async pesquisafaturavenda(): Promise<void> {
    try {
      const response: HttpResponse<Array<IAtivoVendaContab>> = await this._ativosVendaService.getAtivosPesquisaContab();
      const instance = this._cgModalService.showVanilla(AtivosVendaContabModalComponent);
      const componentInstance: AtivosVendaContabModalComponent = instance.componentInstance;
      componentInstance.ativoVendaContabList = response.body;
      const result: IJsonAtivosVenda = await instance.result;

      if (!isEmpty(result.contaCliente)) {
        this.contaDoCliente = `${result.contaCliente} - ${result.nomeContaCliente}`;
      }
      if (response.body?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
      this.listContaMaisMenosVal = result.listContaMaisMenosValia;
      this.listContaDoIva = result.listContaIva;
      this.valorTotal = result.valorSemIva + result.valorDoIva;

      this._initAtivosVendaStore();
      this.ativosvenda = result;
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _realizarVenda(): Promise<void> {
    if (round(this._dataGridInstanceAVender?.getTotalSummaryValue('valorVenda'), NUMBER_TWO) !== this.ativosvenda.valorSemIva) {
      this._plAlertService.error('ativosvenda.tooltip.valorNaoValido');
      return Promise.reject(new Error('ativosvenda.tooltip.valorNaoValido'));
    }
    const listaAtivosAAvender: Array<IAtivoVenda> = this._dataGridInstanceAVender.getDataSource().items();
    const venda: IJsonAtivosVenda = {
      valorSemIva: this.ativosvenda.valorSemIva,
      contaCliente: this.ativosvenda.contaCliente,
      valorDoIva: this.ativosvenda.valorDoIva,
      nomeContaCliente: this.ativosvenda.nomeContaCliente,
      dataVenda: this.ativosvenda.dataVenda,
      coefCorrMonet: this.ativosvenda.coefCorrMonet,
      extPocCabId: this.ativosvenda.extPocCabId,
      listContaMaisMenosValia: this.ativosvenda.listContaMaisMenosValia,
      listContaIva: this.ativosvenda.listContaIva,
      listaAtivos: listaAtivosAAvender
    };
    return this._ativosVendaService.postRegistaVenda(venda).then(() => {
      this._plAlertService.success('ativosvenda.message.success');
      for (const iAtivoVenda of listaAtivosAAvender) {
        this._dataGridStore.remove(iAtivoVenda.codAtivo);
      }
      this.ativosvenda = this._emptyAtivosVenda();
      this.valorTotal = 0;
      this.contaDoCliente = '';
      this.listContaMaisMenosVal = [];
      this.listContaDoIva = [];
      this.selectedAtivoAAvender = undefined;
      return this._dataGridInstanceAVender.refresh();
    });
  }

  private _validateBtnRealizaVenda(): void {
    this._btnRealizaVendas.disabled = this.emptyAtivosAVenderTable || this.valorVendaTotal !== this.ativosvenda.valorSemIva;
    this._btnRealizaVendas.tooltip = {
      placement: 'bottom',
      text: 'ativosvenda.tooltip.valorNaoValido',
      disabled: this.valorVendaTotal === this.ativosvenda.valorSemIva,
      tooltipClass: 'tooltip-danger'
    };
  }

  private _calculaByValorLiquido(): void {
    this._dataGridStore.load().then((listaAtivosAAvender: Array<IAtivoVenda>) => {
      let soma = 0;
      let liquido = 0;
      for (const item of listaAtivosAAvender) {
        if (item.status === 'aVender') {
          liquido = item.valorAtivoBrutoContab - item.valorDepreciacoesAcumuladasContab;
          soma += liquido;
        }
      }

      let atribuido = 0;
      let peso = 0;
      if (soma > 0) {
        for (const item of listaAtivosAAvender) {
          if (item.status === 'aVender') {
            liquido = item.valorAtivoBrutoContab - item.valorDepreciacoesAcumuladasContab;

            peso = (liquido * 100) / soma;
            item.valorVenda = (this.ativosvenda.valorSemIva * peso) / 100;
            atribuido += item.valorVenda;

            if (item.codAtivo === listaAtivosAAvender[listaAtivosAAvender.length - 1].codAtivo) {
              item.valorVenda += this.ativosvenda.valorSemIva - atribuido;
            }

            this._dataGridStore.update(item.codAtivo, item);
          }
        }
      }
    });
  }

  private _calculaByValorBruto(): void {
    this._dataGridStore.load().then((listaAtivosAAvender: Array<IAtivoVenda>) => {
      let soma = 0;
      for (const item of listaAtivosAAvender) {
        if (item.status === 'aVender') {
          soma += item.valorAtivoBrutoContab;
        }
      }

      let atribuido = 0;
      let peso = 0;
      if (soma > 0) {
        for (const item of listaAtivosAAvender) {
          if (item.status === 'aVender') {
            peso = (item.valorAtivoBrutoContab * 100) / soma;
            item.valorVenda = round((this.ativosvenda.valorSemIva * peso) / 100, NUMBER_TWO);
            atribuido += item.valorVenda;

            if (item.codAtivo === listaAtivosAAvender[listaAtivosAAvender.length - 1].codAtivo) {
              item.valorVenda += round(this.ativosvenda.valorSemIva - atribuido, NUMBER_TWO);
            }
            this._dataGridStore.update(item.codAtivo, item);
          }
        }
      }
    });
  }

  private _calculaByManual(): void {
    this._dataGridStore.load().then((listaAtivos: Array<IAtivoVenda>) => {
      const ativosAVender: Array<IAtivoVenda> = listaAtivos.filter((item: IAtivoVenda) => item.status === 'aVender');
      if (ativosAVender.length === 1) {
        ativosAVender[0].valorVenda = this.ativosvenda.valorSemIva;
        this._dataGridStore.update(ativosAVender[0].codAtivo, ativosAVender[0]);
      }
    });
  }

  private _initAtivosVendaStore(isFirst = false): void {
    this.selectedAtivoPorVender = undefined;
    this.selectedAtivoAAvender = undefined;
    this.emptyAtivosAVenderTable = true;
    this.valorVendaTotal = 0;
    const ativoVendas: Array<IAtivoVenda> = this.ativosporvender.map<IAtivoVenda>((ativoVenda: IJsonAtivoVenda) => {
      return {
        codAtivo: ativoVenda.codAtivo,
        designacao: ativoVenda.designacao,
        refExterna: ativoVenda.refExterna,
        dataAquisicao: ativoVenda.dataAquisicao,
        valorAtivoBrutoContab: ativoVenda.valorAtivoBrutoContab,
        valorDepreciacoesAcumuladasContab: ativoVenda.valorDepreciacoesAcumuladasContab,
        valorVenda: ativoVenda.valorVenda,
        status: 'porVender'
      };
    });

    this._dataGridStore = new ArrayStore({
      key: 'codAtivo',
      data: ativoVendas
    });
    this.dataGridDefinition.dataSource = {
      store: this._dataGridStore,
      reshapeOnPush: true
    };
    if (!isFirst) {
      this._dataGridInstancePorVender?.refresh();
      this._dataGridInstanceAVender?.refresh();
    }
    this._validateBtnRealizaVenda();
  }

  private _onAddVendaTable(event: IDevExpressDataGridEventRowDraggingOnAdd<TAtivoVendaStatus, IAtivoVenda>): Promise<void> {
    if (!this.ativosvenda?.contaCliente && !this.ativosvenda?.listContaMaisMenosValia?.length) {
      return Promise.resolve();
    }

    this._dataGridInstanceAVender?.beginCustomLoading('');
    this._dataGridInstancePorVender?.beginCustomLoading('');
    const key: number = event.itemData.codAtivo;
    const values: Partial<IAtivoVenda> = {status: event.toData};
    return this._updateDataGridStoreStatus(key, values).then(() => {
      return this.executeCalc();
    });
  }

  private _updateDataGridStoreStatus(key: number, values: Partial<IAtivoVenda>): Promise<void> {
    return this._dataGridStore.update(key, values).then(() => {
      this._dataGridStore.push([
        {
          type: 'update',
          key,
          data: values
        }
      ]);
    });
  }

  private _emptyAtivosVenda(): IJsonAtivosVenda {
    return {
      coefCorrMonet: '',
      contaCliente: '',
      dataVenda: moment(),
      extPocCabId: '',
      listaAtivos: [],
      valorDoIva: 0,
      valorSemIva: 0,
      nomeContaCliente: '',
      listContaMaisMenosValia: [],
      listContaIva: []
    };
  }
}
