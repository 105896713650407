import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {EncomendasModuleComponent} from './components/encomendas.module.component';
import {MODULE_NAME_ENCOMENDAS} from './encomendas.module.interface';

export const MODULE_ENCOMENDAS: IModuleDefinition = {
  name: MODULE_NAME_ENCOMENDAS,
  state: {
    url: `/${MODULE_NAME_ENCOMENDAS}`,
    component: EncomendasModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pluginsRoles: [ROLE.ERPADVANCED],
      pageTitle: 'global.menu.encomendas'
    }
  }
};
