import {IJsonTrfLancContas} from './jsonTrfLancContas.module.interface';

export interface ITrfLancContasModuleStateParams {
  nContaOrigem?: string;
}

export interface ITrfLancContas extends IJsonTrfLancContas {
  nomeContaOrigem: string;
}

export const MODULE_NAME_TRFLANCCONTAS = 'trfLancContasModule';
