import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {
  EFctPedidoReembolsoEstadoContrato,
  EFctSearchTipoDocumento,
  EFctTipoDocumento,
  IFctDecMensalRemunProcessExistentesFCT,
  IFctDocumentos,
  IFctEmpresa,
  IFctEnviarDocPagam,
  IFctObterDetalhes,
  IFctObterValor,
  IFctPedidoReembolso,
  IFctPedidoReembolsoSubmit
} from './fct.module.interface';
import {TDate} from '../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class FctService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/fct`;
  }

  public getEmpresas(): TServiceResponse<Array<IFctEmpresa>> {
    return this._apiService.get<Array<IFctEmpresa>>({url: `${this._path}/empresas`});
  }

  public getAllEmpresas(): TServiceResponse<Array<IFctEmpresa>> {
    return this._apiService.get<Array<IFctEmpresa>>({url: `${this._path}/all-empresas`});
  }

  public getDadosTodosTrabalhadoresComFCT(): TServiceResponse<Array<IFctDecMensalRemunProcessExistentesFCT>> {
    return this._apiService.get<Array<IFctDecMensalRemunProcessExistentesFCT>>({url: `${this._path}/dadosTodosTrabalhadoresComFCT`});
  }

  public obterValor(nempresa: string): TServiceResponse<IFctObterValor> {
    return this._apiService.get<IFctObterValor>({
      url: `${this._path}/valor/${nempresa}`,
      reportExceptions: false
    });
  }

  public enviarDocPagamentoEmail(nempresa: string, email: string): TServiceResponse<IFctEnviarDocPagam> {
    return this._apiService.get<IFctEnviarDocPagam>({
      url: `${this._path}/enviarDocPagamentoEmail/${nempresa}?email=${email}`,
      reportExceptions: false
    });
  }

  public emitirDocumento(nempresa: string, tipoDocumento: EFctTipoDocumento): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      params: {
        tipoDocumento: tipoDocumento
      },
      url: `${this._path}/emitir/${nempresa}`,
      responseType: 'blob',
      reportExceptions: false
    });
  }

  public obterDetalhes(nempresa: string): TServiceResponse<IFctObterDetalhes> {
    return this._apiService.get<IFctObterDetalhes>({
      url: `${this._path}/detalhes/${nempresa}`,
      reportExceptions: false
    });
  }

  public obterDocumentos(nempresa: string, startDate: TDate, endDate: TDate, tipoSearchDocumento: EFctSearchTipoDocumento): TServiceResponse<IFctDocumentos> {
    return this._apiService.get<IFctDocumentos>({
      params: {
        startDate: startDate,
        endDate: endDate,
        tipoDocumento: tipoSearchDocumento
      },
      url: `${this._path}/documentos/${nempresa}`,
      reportExceptions: false
    });
  }

  public obterDocumento(nempresa: string, startDate: TDate, endDate: TDate, tipoSearchDocumento: EFctSearchTipoDocumento, searchIdDoc: string, hash: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      params: {
        startDate: startDate,
        endDate: endDate,
        tipoDocumento: tipoSearchDocumento,
        searchIdDoc: searchIdDoc,
        hash: hash
      },
      url: `${this._path}/documento/${nempresa}`,
      responseType: 'blob'
    });
  }

  public obterDocumentoLast(nempresa: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/documento-last/${nempresa}`,
      responseType: 'blob'
    });
  }

  public setFCTCredentials(nempresa: string, niss: string, pw: string): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/credentials/${nempresa}`,
      params: {niss: niss, pw: pw}
    });
  }

  public getListTrabalhadoresPedidoReembolso(
    nempresa: string,
    nissTrabalhador: string,
    dataCessInicial: TDate,
    dataCessFinal: TDate,
    estadoContrato: EFctPedidoReembolsoEstadoContrato
  ): TServiceResponse<IFctPedidoReembolso> {
    return this._apiService.get<IFctPedidoReembolso>({
      params: {
        nissTrabalhador: nissTrabalhador,
        dataCessInicial: dataCessInicial,
        dataCessFinal: dataCessFinal,
        estadoContrato: estadoContrato
      },
      url: `${this._path}/pedido-reembolso/${nempresa}/trabalhadores`,
      reportExceptions: false
    });
  }

  public efetuarPedidoReembolso(
    nempresa: string,
    rkeys: Array<string>,
    iban: string,
    nissTrabalhador: string,
    dataCessInicial: TDate,
    dataCessFinal: TDate,
    estadoContrato: EFctPedidoReembolsoEstadoContrato
  ): TServiceResponse<IFctPedidoReembolsoSubmit> {
    return this._apiService.post<IFctPedidoReembolsoSubmit, Array<string>>({
      params: {
        iban: iban,
        nissTrabalhador: nissTrabalhador,
        dataCessInicial: dataCessInicial,
        dataCessFinal: dataCessFinal,
        estadoContrato: estadoContrato
      },
      body: rkeys,
      url: `${this._path}/pedido-reembolso/${nempresa}/efetuar`,
      reportExceptions: false
    });
  }
}
