import moment from 'moment';
import {catchError, timeout} from 'rxjs/operators';
import {from, fromEvent, mergeMap, Observable, Subscription, TimeoutError, timer} from 'rxjs';
import {Component, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Logger} from 'pl-comps-angular';
import {AppService} from '../../../../../services/app/app.service';
import {HealthService} from '../../../../../services/health/health.service';
import {IAppStatus} from '../../../../../services/app/app.service.interface';
import {TDate} from '../../../../../../common/dates';

/* eslint-disable @typescript-eslint/no-magic-numbers */

const DELAY_TIME: number = moment.duration(5, 'seconds').asMilliseconds();
const CHECK_STATUS_PERIOD: number = moment.duration(30, 'seconds').asMilliseconds();
const CHECK_STATUS_TIMEOUT: number = moment.duration(5, 'seconds').asMilliseconds();
const AUTO_HIDE_TIMEOUT: number = moment.duration(10, 'seconds').asMilliseconds();

/* eslint-enable @typescript-eslint/no-magic-numbers */

@Component({
  selector: 'cg-app-status-disconnected',
  templateUrl: './cg.app.status.disconnected.component.html',
  exportAs: 'appStatusDisconnected'
})
export class CGAppStatusDisconnectedComponent implements OnDestroy {
  public showToast: boolean;
  public disconnected: boolean;
  public offline: boolean;
  public restored: boolean;
  public lastChecked: TDate;

  private readonly _document: Document;
  private readonly _subscriptionAppStatus: Subscription;
  private readonly _subscriptionOffline: Subscription;
  private readonly _subscriptionOnline: Subscription;
  private _subscriptionDisconnected: Subscription;
  private _subscriptionCheckStatus: Subscription;
  private _subscriptionAutoHide: Subscription;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _logger: Logger,
    private readonly _appService: AppService,
    private readonly _healthService: HealthService
  ) {
    this.showToast = false;
    this.disconnected = false;
    this.offline = false;
    this.restored = false;
    this._document = <Document>document;

    this._subscriptionAppStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._evaluateStatus(status.disconnected);
    });

    if (this._document?.defaultView) {
      const window: Window = this._document.defaultView;

      this._subscriptionOffline = fromEvent(window, 'offline', {passive: true}).subscribe(() => {
        this.offline = true;
      });

      this._subscriptionOnline = fromEvent(window, 'online', {passive: true}).subscribe(() => {
        this.offline = false;
      });
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionAppStatus.unsubscribe();
    if (this._subscriptionOffline) {
      this._subscriptionOffline.unsubscribe();
    }
    if (this._subscriptionOnline) {
      this._subscriptionOnline.unsubscribe();
    }
    this._clearSubscriptionDisconnected();
    this._stopCheckStatus();
    this._clearAutoHide();
  }

  public show(): void {
    this.showToast = true;
    this.lastChecked = moment();
    this._clearAutoHide();
  }

  public hide(): void {
    this.showToast = false;
  }

  private _evaluateStatus(disconnected: boolean): void {
    if (disconnected === this.disconnected) {
      return;
    }

    if (disconnected) {
      if (!this._subscriptionDisconnected) {
        this._subscriptionDisconnected = timer(DELAY_TIME).subscribe(() => {
          this._subscriptionDisconnected = undefined;
          this.show();
          this._startCheckStatus();
        });
      }
    } else {
      this._clearSubscriptionDisconnected();
      this._stopCheckStatus();

      if (this.disconnected) {
        this._clearAutoHide();

        this._subscriptionAutoHide = timer(AUTO_HIDE_TIMEOUT).subscribe(() => {
          this.hide();
          this._subscriptionAutoHide = undefined;
        });
      }
    }

    this.disconnected = disconnected;
  }

  private _startCheckStatus(): void {
    if (this._subscriptionCheckStatus) {
      return;
    }

    const checkStatus = (): Observable<HttpResponse<unknown>> =>
      timer(CHECK_STATUS_PERIOD)
        .pipe(mergeMap(() => from(this._healthService.live({reportExceptions: false})).pipe(timeout(CHECK_STATUS_TIMEOUT))))
        .pipe(
          catchError((error: HttpErrorResponse | TimeoutError) => {
            this._logger.error('Error checking status', error);
            this.lastChecked = moment();
            return checkStatus();
          })
        );

    this._subscriptionCheckStatus = checkStatus().subscribe((response: HttpResponse<unknown>) => {
      if (response.ok) {
        this._evaluateStatus(false);
      }
    });
  }

  private _stopCheckStatus(): void {
    if (this._subscriptionCheckStatus) {
      this._subscriptionCheckStatus.unsubscribe();
      this._subscriptionCheckStatus = undefined;
    }
  }

  private _clearSubscriptionDisconnected(): void {
    if (this._subscriptionDisconnected) {
      this._subscriptionDisconnected.unsubscribe();
      this._subscriptionDisconnected = undefined;
    }
  }

  private _clearAutoHide(): void {
    if (this._subscriptionAutoHide) {
      this._subscriptionAutoHide.unsubscribe();
      this._subscriptionAutoHide = undefined;
    }
  }
}
