<div class="rh-copia-abonos-descontos-infoavisoerros">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'rhcopiaabonosdescontos.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <div [translate]="'rhcopiaabonosdescontos.fmt.abonoDescontoACopiar'" [translateParams]="{abdesCopia: relatorioLoad.abdesCopia}"></div>

    <div class="d-flex flex-column">
      <span
        class="modal-dialog-centered"
        [translate]="'rhcopiaabonosdescontos.fmt.empresaDeOrigem'"
        [translateParams]="{codEmpresaOrigem: relatorioLoad.codEmpresaOrigem, nomeEmpresaOrigem: relatorioLoad.nomeEmpresaOrigem}"></span>
      <span
        class="modal-dialog-centered"
        [translate]="'rhcopiaabonosdescontos.fmt.empresaDeDestino'"
        [translateParams]="{codEmpresaDestino: relatorioLoad.codEmpresaDestino, nomeEmpresaDestino: relatorioLoad.nomeEmpresaDestino}"></span>
    </div>

    <h5 class="modal-title" [translate]="'global.text.info'"></h5>

    <div [innerHTML]="relatorioLoad.mensagemHtml"></div>

    <div *ngIf="painelGravarCancelar">
      <pl-edit type="radiogroup" attrName="headerEstadoUso" [(model)]="gerarUtilizarGrupo" [properties]="radioGroupGerarUtilizarGrupo"></pl-edit>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button *ngIf="painelGravarCancelar" klass="btn-success action-dismiss" [onClick]="close" [disabled]="closeDisabled">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="painelGravarCancelar ? 'global.btn.cancel' : 'global.btn.close'"></span>
    </pl-button>
  </div>
</div>
