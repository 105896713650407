<div class="ativos-new">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <ativos
        [stateType]="ativosStateTypes.NEW"
        [(model)]="model"
        [onFinalize]="finalize"
        [contabValorAquisicao]="contabValorAquisicao"
        [ativoContab]="ativoContab"
        [ativoInvestimentosEmCurso]="ativoInvestimentosEmCurso"
        [docExtPocCabId]="docExtPocCabId"
        [valorInvEmCursoDisponivelAnoN]="valorInvEmCursoDisponivelAnoN"
        [valorInvEmCursoDisponivelAnoN1]="valorInvEmCursoDisponivelAnoN1"
        (evtCanSaveNewAtivoChanged)="canSaveNewAtivoChanged($event)">
      </ativos>
    </div>
  </entity-detail>
</div>
