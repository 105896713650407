import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ETTipodeIntegracao, IIntegracaoSalariosConfig, IIntegracaoSalariosTipoIntegracaoRadioItem} from '../../integracaoSalarios.module.interface';
import {IntegracaoSalariosService} from '../../integracaoSalarios.module.service';

const TIPO_INTEG_TAB_ID = 'integ-salarios-config-tipo-integ';
const CONFIG_ACERTOS_SS_TAB_ID = 'integ-salarios-config-acertos-ss';
const CONFIG_APOIOS_COVID19_TAB_ID = 'integ-salarios-config-apoios-covid19';

@Component({
  selector: 'integracao-salarios-config-modal',
  templateUrl: './integracaoSalarios.config.modal.component.html'
})
export class IntegracaoSalariosConfigModalComponent extends CGModalComponent<IIntegracaoSalariosConfig> implements OnInit {
  @Input() public nEmpresa: string;
  @Input() public configInput: IIntegracaoSalariosConfig;
  @Input() public showNDocExt: boolean;

  public readonly tiposDeIntegracao: typeof ETTipodeIntegracao;
  public readonly tipoIntegTabId: string;
  public readonly configAcertosSegSocTabId: string;
  public readonly configApoiosCOVID19: string;
  public readonly tiposIntegracaoSource: Array<IIntegracaoSalariosTipoIntegracaoRadioItem>;
  public configModel: IIntegracaoSalariosConfig;
  public okBtnCaption: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _integracaoSalariosService: IntegracaoSalariosService
  ) {
    super(_injector);
    this.tiposDeIntegracao = ETTipodeIntegracao;
    this.tipoIntegTabId = TIPO_INTEG_TAB_ID;
    this.configAcertosSegSocTabId = CONFIG_ACERTOS_SS_TAB_ID;
    this.configApoiosCOVID19 = CONFIG_APOIOS_COVID19_TAB_ID;
    this.tiposIntegracaoSource = [
      {label: 'integracaoSalarios.tipoIntegUmDocPorEmpregado', value: ETTipodeIntegracao.PorEmpregado},
      {label: 'integracaoSalarios.tipoIntegUmDocPorProcessamento', value: ETTipodeIntegracao.PorProcessamento}
    ];
    this.configModel = {
      tipoDeIntegracao: ETTipodeIntegracao.PorEmpregado,
      nContaSegSocialSaldoExcedente: '',
      nContaSegSocialSaldoInsuficiente: '',
      codEstado: '',
      nDescritivo: 0,
      nDocExterno: '',
      nDiario: 0,
      descricao: '',
      codEstadoDesc: '',
      nContaSegSocialSaldoExcedenteDesc: '',
      nContaSegSocialSaldoInsuficienteDesc: '',
      nContaCovid19ApoioFamilia: '',
      nContaCovid19ApoioFamiliaDesc: '',
      nContaCovid19LayoffSimplSuspencao: '',
      nContaCovid19LayoffSimplSuspencaoDesc: '',
      nContaCovid19LayoffSimplParcial: '',
      nContaCovid19LayoffSimplParcialDesc: '',
      nContaCovid19ApoioFamiliaPerc: 0,
      nContaCovid19LayoffSimplSuspencaoPerc: 0,
      nContaCovid19LayoffSimplParcialPerc: 0,
      nDescritivoDesc: '',
      nDiarioDesc: '',
      errorList: []
    };
  }

  public ngOnInit(): void {
    this.okBtnCaption = this.showNDocExt ? 'integracaoSalarios.integrar' : 'global.btn.ok';
    this.configModel = {...this.configInput};
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._integracaoSalariosService
      .saveConfig(this.nEmpresa, this.configModel)
      .then(() => {
        this.enableClose();
        super.close(this.configModel);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this.enableClose();
      });
  }
}
