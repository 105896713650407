import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {downloadStream, EDateMonth, IPlToolbarItem, IPlToolbarMenuItem, isEmpty, PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IOfficeReportingPagina} from '../officeReporting.module.interface';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {HttpResponse} from '@angular/common/http';
import {IJsonEmpresaAno} from '../../../../interfaces/jsonEmpresa.interface';
import {OfficeReportingService} from '../officeReporting.module.service';
import moment from 'moment';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../entities/periodos/periodos.entity.interface';
import {IJsonPeriodo, IJsonPeriodoDatasPeriodoTributacao} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX} from '../../../../components/devexpress/widget/devexpress.selectors.interface';
import DxCheckbox from 'devextreme/ui/check_box';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridState} from '../../../../components/devexpress/datagrid/state/devexpress.datagrid.state.interface';

const ID_INVENTARIOS_ANOS_ANTE = 11;
const ID_INVENTARIOS = 12;

@Component({
  selector: 'module-office-reporting',
  templateUrl: './officeReporting.module.component.html'
})
export class OfficeReportingModuleComponent extends ModuloComponent implements OnInit {
  @Input() public paginasCaption: Array<string>;
  @Input() public anos: Array<IJsonEmpresaAno>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  public ano: number;
  public selectedRowKeys: Array<number>;

  private readonly _paginasDataSource: Array<IOfficeReportingPagina>;
  private readonly _periodosService: IPeriodosEntityService;
  private readonly _btnExportPDF: IPlToolbarItem;
  private _dataGridInstance: dxDataGrid<IOfficeReportingPagina, number>;
  private _mnuAnos: IPlToolbarItem;
  private _mnuMes: IPlToolbarItem;
  private _meses: Array<IPlToolbarMenuItem>;
  private _selectedMes: IPlToolbarMenuItem;
  private _disabledInventarios: boolean;
  private _mesEmCurso: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _officeReportingService: OfficeReportingService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._periodosService = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [{dataField: 'pagina', dataType: 'string', caption: 'officereporting.fields.pagina'}],
      dataSource: [],
      export: {filename: 'officereporting.fields.pagina'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      height: '75vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      searchPanel: {visible: true},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false
    };
    this.paginasCaption = [];
    this._paginasDataSource = [];
    this.ano = this._configService.configurations.empresa.anoEmCursoIRC;
    this._btnExportPDF = {
      id: 'exportpdf',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-download fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.exportPdf',
      click: () => {
        this._exportPDF();
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnExportPDF);
    let promise: Promise<void>;
    if (!isEmpty(this._configService.configurations.empresa.periodo)) {
      promise = this._periodosService.obterDatasPeriodoTributacao(this._configService.configurations.empresa.periodo).then((response: HttpResponse<IJsonPeriodoDatasPeriodoTributacao>) => {
        this.ano = moment(response.body.deData).year();
        this._mesEmCurso = moment(response.body.deData).month() + 1;
      });
    }
    Promise.resolve(promise).then(() => {
      const anos: Array<IPlToolbarMenuItem> = this.anos.map<IPlToolbarMenuItem>(({ano}: IJsonEmpresaAno) => {
        const itemAno: IPlToolbarMenuItem = {
          caption: String(ano),
          click: (menuItem: IPlToolbarMenuItem) => {
            this._changedAno(menuItem);
          }
        };
        return itemAno;
      });
      const todos: string = this._translateService.instant('global.text.all');
      const mesesSource: Array<IDataSourceItem<EDateMonth>> = [{value: <EDateMonth>-1, label: todos, name: todos}, ...DATA_SOURCE_MESES.data];
      this._meses = mesesSource.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EDateMonth>) => {
        const month: number = value;
        const itemMes: IPlToolbarMenuItem = {
          id: String(month),
          caption: this._translateService.instant(name),
          active: month === this._mesEmCurso,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._changedMes(menuItem);
          }
        };
        if (itemMes.active) {
          this._selectedMes = itemMes;
        }
        return itemMes;
      });
      this._mnuAnos = {order: 1, id: 'listMnuAnos', caption: this._getCaptionAno(), type: 'dropdown', menu: anos};
      this._mnuMes = {order: 2, id: 'listMnuMes', caption: this._getCaptionMes(), type: 'dropdown', menu: this._meses};
      this.toolbar.addButton(this._mnuAnos).addButton(this._mnuMes);
    });

    let id = 0;
    for (const pagina of this.paginasCaption) {
      if (pagina !== 'Variaveis' && pagina !== 'Empresa') {
        this._paginasDataSource.push({_id: id, pagina: pagina});
      }
      id++;
    }
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: '_id',
      data: this._paginasDataSource
    });
    this._officeReportingService.fetchSelectedPaginas().then((paginas: Array<number>) => {
      this.selectedRowKeys = paginas;
      this._disabledInventarios = this.selectedRowKeys.findIndex((pagina: number) => pagina > ID_INVENTARIOS) > -1;
    });
  }

  public onDataGridStateLoad(state: IDevExpressDataGridState): void {
    state.selectedRowKeys = this.selectedRowKeys;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IOfficeReportingPagina, number>): void {
    this._dataGridInstance = component;
  }

  public onDataGridCellPrepared({rowType, cellElement, row}: IDevExpressDataGridEventOnCellPrepared<IOfficeReportingPagina>): void {
    if (rowType === 'data' && this._disabledInventarios && (row.data._id === ID_INVENTARIOS_ANOS_ANTE || row.data._id === ID_INVENTARIOS)) {
      this._disableCheckboxs(cellElement, true);
    }
  }

  public selectedRowKeysChange(): void {
    const keysToSelect: Array<number> = [];
    let disableInventarios = false;
    for (const key of this.selectedRowKeys) {
      if (key > ID_INVENTARIOS) {
        if (!this.selectedRowKeys.includes(ID_INVENTARIOS_ANOS_ANTE)) {
          keysToSelect.push(ID_INVENTARIOS_ANOS_ANTE);
        }
        if (!this.selectedRowKeys.includes(ID_INVENTARIOS)) {
          keysToSelect.push(ID_INVENTARIOS);
        }
        disableInventarios = true;
        break;
      }
    }

    if (this._disabledInventarios !== disableInventarios) {
      this._disabledInventarios = disableInventarios;
      const indexInventAnosAnt: number = this._dataGridInstance.getRowIndexByKey(ID_INVENTARIOS_ANOS_ANTE);
      if (indexInventAnosAnt > -1) {
        const cellElementInventAnosAnt: HTMLElement = this._dataGridInstance.getCellElement(indexInventAnosAnt, 0);
        this._disableCheckboxs(cellElementInventAnosAnt, this._disabledInventarios);
      }
      const indexInvent: number = this._dataGridInstance.getRowIndexByKey(ID_INVENTARIOS);
      if (indexInvent > -1) {
        const cellElementInvent: HTMLElement = this._dataGridInstance.getCellElement(indexInvent, 0);
        this._disableCheckboxs(cellElementInvent, this._disabledInventarios);
      }
    }

    if (keysToSelect.length) {
      this.selectedRowKeys = this.selectedRowKeys.concat(keysToSelect);
    }

    this._officeReportingService.persistSelectedPaginas(this.selectedRowKeys);
  }

  private _exportPDF(): void {
    this._dataGridInstance.beginCustomLoading(undefined);
    this._btnExportPDF.promise = this._officeReportingService
      .evolucaoDoNegocioToPDF(this.ano, this._mesEmCurso, this.selectedRowKeys)
      .then((blob: HttpResponse<Blob>) => {
        if (!blob) {
          this._plAlertService.error(this._translateService.instant('officereporting.messages.erroDownload'));
        } else {
          downloadStream(blob);
        }
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
        return undefined;
      });
  }

  private _disableCheckboxs(cellElement: HTMLElement, disableCheckBox: boolean): DxCheckbox {
    if (cellElement) {
      const elementSelectCheckbox: HTMLElement = cellElement.querySelector(SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX);
      if (elementSelectCheckbox) {
        const dxCheckbox: DxCheckbox = <DxCheckbox>DxCheckbox.getInstance(elementSelectCheckbox);
        if (dxCheckbox) {
          if (disableCheckBox) {
            dxCheckbox.option('value', true);
          }
          dxCheckbox.option('disabled', disableCheckBox);
          return dxCheckbox;
        }
      }
    }
    return undefined;
  }

  private _changedAno(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this.ano)) {
      this.ano = Number(menuItem.caption);
      this._mnuAnos.caption = this._getCaptionAno();
    }
  }

  private _changedMes(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._mesEmCurso)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = Number(this._selectedMes.id);
      this._mnuMes.caption = this._getCaptionMes();
    }
  }

  private _getCaptionAno(): string {
    return this._translateService.instant('officereporting.ano', {value: this.ano});
  }

  private _getCaptionMes(): string {
    const nomeMesEmCurso = this._meses[this._mesEmCurso].caption;
    return this._translateService.instant('officereporting.mes', {nameMonth: nomeMesEmCurso});
  }
}
