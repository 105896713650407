import {TDate} from '../../../common/dates';
import {EResidenciaFiscal} from '../portalrh/dgempsfull/jsonDGEMPFull.interface';
import {ETipoAbonoDesconto} from '../../entities/abdes/abdes.entity.interface';

export enum EConfigRHEstadoItem {
  None,
  Added,
  Modified,
  Deleted
}

export interface IJsonConfigRHDadosFicha {
  motivoRegisto: string;
  designaBreveMotivoRegisto: string;
  designaComplMotivoRegisto: string;
  situacaoEmprego: string;
  designaBreveSituacaoEmprego: string;
  nCodABVencimento: string;
  designaBreveABVencimento: string;
  nCodABSubFerias: string;
  designaBreveABSubFerias: string;
  nCodABSubNatal: string;
  designaBreveABSubNatal: string;
  nCodABSubAlimentacao: string;
  designaBreveABSubAlimentacao: string;
  nCodABDiuturnidades: string;
  designaBreveABDiuturnidades: string;
  nCodDescIRS: string;
  designaBreveDescIRS: string;
  localTrabalho: number;
  nomeLocalTrabalho: string;
  tipoHorarioTrabalho: string;
  designaBreveTipoHorarioTrabalho: string;
  nMaxDiasUteisFerias: number;
  nDiasTemDireitoFerias: number;
  nMaxDiasUteisNatal: number;
  nDiasTemDireitoNatal: number;
  residenciaFiscal: EResidenciaFiscal;
  nCodABFerias: string;
  designaBreveABFerias: string;
  temRendJovensTrab: boolean;
  dtIniRendJovensTrab: TDate;
  temRendResNaoHabit: boolean;
  dtIniRendResNaoHabit: TDate;
  localObterRendimento: number;
}

export interface IJsonConfigRHManutencaoCessacaoContrato {
  nCodAbonoIndemnizacaoCaducidade: string;
  nCodAbonoIndemnizacaoDespedimento: string;
  nCodAbonoIndemnizacaoGlobal: string;
  motivoCessacao: string;
  situacaoCessacao: string;
  nCodAbonoSubFeriasProp: string;
  nCodAbonoFeriasProp: string;
  nomeNCodAbonoIndemnizacaoCaducidade: string;
  nomeNCodAbonoIndemnizacaoDespedimento: string;
  nomeNCodAbonoIndemnizacaoGlobal: string;
  nomeMotivoCessacao: string;
  nomeSituacaoCessacao: string;
  nomeNCodAbonoSubFeriasProp: string;
  nomeNCodAbonoFeriasProp: string;
}

export interface IJsonConfigRHDGEMPRequiredFields {
  funcao: boolean;
  integraSalarios: boolean;
  reparticaoCentroCusto: boolean;
  codCCT: boolean;
  operacaoSeccao: boolean;
  nivelQualif: boolean;
  pocRemuLiq: boolean;
  categoria: boolean;
  centroCusto: boolean;
  subDepartamento: boolean;
  integraRU: boolean;
  situacaoProfissao: boolean;
  departamento: boolean;
  codZona: boolean;
  classeTrab: boolean;
  profissao: boolean;
  integraSalariosPorDefeito: boolean;
  integraSalariosPorOrgaoSocial: boolean;
  validaMascara: boolean;
  mascaraAnalitica: string;
}

export interface IJsonMascaraCCustoPorCategoria {
  categoria: number;
  descCategoria: string;
  temMascaraAnalitica: boolean;
}

export interface IJsonConfigRHGozoFerias {
  usaMarcacaoGozoFerias: boolean;
  nCodAbdesDescontoGozoFeriasAnoAtual: string;
  nomeNCodAbdesDescontoGozoFeriasAnoAtual: string;
  nCodAbdesDescontoGozoFeriasAnoAnterior: string;
  nomeNCodAbdesDescontoGozoFeriasAnoAnterior: string;
  motivoRegistoFerias: string;
  nomeMotivoRegistoFerias: string;
}

export interface IJsonAssistenteTrabAndMascaras {
  assistenteTrabalhadorMascaraAbono: Array<IJsonMascaraCCustoPorCategoria>;
  assistenteTrabalhadorMascaraDesconto: Array<IJsonMascaraCCustoPorCategoria>;
  assistenteTrabalhador: IJsonConfigRHDGEMPRequiredFields;
}

export interface IJsonConfigRHDadosDGEMP {
  hasDadosFicha: boolean;
  defaultOutrosDados: IJsonConfigRHDadosFicha;
  hasRequiredFields: boolean;
  requiredFields: IJsonConfigRHDGEMPRequiredFields;
}

export interface IJsonConfigRHTrfbaTicket {
  codBanco: string;
  nomeBanco: string;
  codOpTrfBa: string;
  nClienteBanco: string;
  nCartaoTrfBaIBAN: boolean;
  cartaoDifBanco: boolean;
  configTrfBaTicket: boolean;
  tipoFichTrf: number;
  estado: EConfigRHEstadoItem;
}

export interface IJsonConfigRHExclusoesTransf {
  tipo: ETipoAbonoDesconto;
  codAbDesc: string;
  designaBreve: string;
  estado: EConfigRHEstadoItem;
}

export interface IJsonConfigRHTransfBancarias {
  ficheiroDeTransferenciasNoFormatoPS2: boolean;
  ficheiroDeTransferenciaDGTNoFormatoSEPA: boolean;
  exportacaoTXTSeparacaoNibs: boolean;
  formatoSEPATicketRefeicao: boolean;
  exclusoesTransferencias: Array<IJsonConfigRHExclusoesTransf>;
  listaTrfBaTicket: Array<IJsonConfigRHTrfbaTicket>;
}

export interface IJsonProcessamentoSalariosFields {
  usaFiltroFuncaoNosTiposProcessamento: boolean;
  calcNDiasParaAdmissaoEmpEmDiasBase30: boolean;
}

export interface IJsonProcessamentoSalariosDuodecimos {
  acertoDuodecimosFaltouMesInteiro: boolean;
  efetuaAcertoDuodecimos: boolean;
  percentagemEmSubFerias: number;
  percentagemEmSubNatal: number;
}

export interface IJsonProcessamentoSalariosReciboVenc {
  reciboVencimentosMostraGraficoCustos: boolean;
  reciboVencimentosMostraGraficoCustosAcumuladosAnuais: boolean;
}

export interface IJsonProcessamentoSalariosConfig {
  processamentoSalariosFields: IJsonProcessamentoSalariosFields;
  processamentoSalariosDuodecimos: IJsonProcessamentoSalariosDuodecimos;
  processamentoSalariosReciboVenc: IJsonProcessamentoSalariosReciboVenc;
}

export interface IJsonSegSocialFolhaFerias {
  ffUsaTipodeRemunEspecificaFichSeguradora: boolean;
  ffFichSeguradoraEnvioFichPelaExclAbDescCampo: boolean;
}

export interface IJsonConfigRMMG {
  dataAtualizacao: TDate;
  gerenteSelecionado: boolean;
}
