import {Observable} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {JSONSchema, StorageMap} from '@ngx-pwa/local-storage';
import {copy, isArray, isObject} from 'pl-comps-angular';
import {mapToObject, setToArray} from '../../../common/data';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CGLocalStorageService implements OnDestroy {
  constructor(private readonly _storageMap: StorageMap) {}

  public ngOnDestroy(): void {
    this.clear().subscribe();
  }

  public getItem<T = unknown>(key: string, schema: JSONSchema): Observable<T> {
    return this._storageMap.get<T>(key, schema);
  }

  public setItem(key: string, data: unknown, schema?: JSONSchema): Observable<void> {
    const transformedData: unknown = this.transformData(copy(data));
    return this._storageMap.set(key, transformedData, schema);
  }

  public removeItem(key: string): Observable<void> {
    return this._storageMap.delete(key);
  }

  public has(key: string): Observable<boolean> {
    return this._storageMap.has(key);
  }

  public clear(): Observable<void> {
    return this._storageMap.clear();
  }

  public transformData(value: unknown): unknown {
    if (moment.isMoment(value)) {
      return value.toJSON();
    } else if (value instanceof Map) {
      return this.transformData(mapToObject(value));
    } else if (value instanceof Set) {
      return this.transformData(setToArray(value));
    } else if (isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        value[i] = this.transformData(value[i]);
      }
    } else if (isObject(value)) {
      for (const key of Object.keys(value)) {
        value[key] = this.transformData(value[key]);
      }
    }
    return value;
  }
}
