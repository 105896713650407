<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [innerText]="modalTitle | translate"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'dmrf.fields.nContrib'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.nContrib" [properties]="{validators: {required: {value: true}}}" plAutoFocus></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'dmrf.fields.nDocExterno'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.nDocExterno"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-12">
        <pl-group>
          <label [translate]="'dmrf.fields.zona'"></label>
          <edit>
            <pl-edit type="dmrfzona" [(model)]="model.zona"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-md-12">
        <pl-group>
          <label [translate]="'dmrf.fields.rubrica'"></label>
          <edit>
            <pl-edit type="dmrfrubrica" [(model)]="model.rubrica" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-md-6">
        <pl-group>
          <label [translate]="'dmrf.fields.retencao'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.valorRetencao" [properties]="{disallowClear: true}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-sm btn-primary" [disabled]="closeDisabled" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-sm btn-light" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
