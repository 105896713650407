import {CalculoDiferimentosModuleComponent} from './components/calculoDiferimentos.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_CALCULO_DIFERIMENTOS} from '../diferimentos.module.interface';
import {ROLE} from '../../../services/role.const';
import {HttpResponse} from '@angular/common/http';
import {DiferimentosService} from '../diferimentos.module.service';
import {IJsonCalculoDiferimentosConfig} from '../jsonCalculoDiferimentos.interface';

export const MODULE_CALCULO_DIFERIMENTOS: IModuleDefinition = {
  name: MODULE_NAME_CALCULO_DIFERIMENTOS,
  state: {
    url: `/${MODULE_NAME_CALCULO_DIFERIMENTOS}`,
    component: CalculoDiferimentosModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pageTitle: 'global.menu.calculodiferimentos'
    },
    params: {
      fromComercial: {
        type: 'bool',
        value: false
      },
      fromContabilidade: {
        type: 'bool',
        value: false
      },
      docBalanceado: {
        type: 'bool',
        value: false
      },
      dataIniDiferimento: {
        type: 'any',
        value: null
      },
      dataFimDiferimento: {
        type: 'any',
        value: null
      },
      valor: {
        type: 'json', // `json` type to handle double number type properly
        value: null
      }
    },
    resolve: [
      {
        provide: 'diferimentosConfig',
        deps: [DiferimentosService],
        useFactory: (calculoDiferimentosService: DiferimentosService): Promise<IJsonCalculoDiferimentosConfig> =>
          calculoDiferimentosService.getConfigsCustDif().then((response: HttpResponse<IJsonCalculoDiferimentosConfig>) => response.body)
      }
    ]
  }
};
