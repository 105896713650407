import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_NIFS} from '../../nifs.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ENIFSituacaoTipo, IJsonNIFs} from '../../jsonNifs.entity.interface';

@Component({
  selector: 'registar-nif-modal',
  templateUrl: './registarNIF.modal.component.html'
})
export class RegistarNifModalComponent extends CGModalComponent<IJsonNIFs> implements OnInit {
  @Input() public nContribuinte: string;

  public readonly nif: IJsonNIFs;
  public readonly type: EEntityStateDetailType;

  private readonly _serviceNifs: IEntityService<IJsonNIFs>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.nif = {
      nContribuinte: this.nContribuinte,
      codRet: '',
      nomeRet: '',
      codPais: undefined,
      tipoRetServNIF: undefined,
      abrevFiscal: undefined,
      temCativo: false,
      taxaCativo: 0,
      m30NifPT: undefined,
      m30NifEmit: undefined,
      m30PartCapD: 0,
      m30PartCapS: 0,
      tipoNif: ENIFSituacaoTipo.ValidoSemInformacaoActividade
    };
    this.type = EEntityStateDetailType.NEW;
    this._serviceNifs = this._entityServiceBuilder.build(ENTITY_NAME_NIFS);
  }

  public ngOnInit(): void {
    this.nif.nContribuinte = this.nContribuinte;
  }

  public close(): Promise<void> {
    return this._serviceNifs.post({body: this.nif}).then(() => {
      this._plAlertService.success(this._translateService.instant('nifs.registado', {nif: this.nif.nContribuinte}));
      super.close(this.nif);
    });
  }
}
