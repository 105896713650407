import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IPlTooltipConfig, PlEditInputTypeComponent} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {IJsonPreDefinidoContabLinha} from '../../jsonPreDefinidosContab.module.interface';
import {IPredefinidoContabFormulaOptionsInput, predefinidoContabFormulaComponentName, TPreDefinidoContabFormulaType} from './predefinidocontabformula.component.interface';
import {PredefinidoContabFormulaDialogModalComponent} from './dialogmodal/predefinidocontabformula.dialog.modal.component';
import {PredefinidoContabLinhasComponent} from '../linhas/predefinidocontablinhas.component';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  selector: predefinidoContabFormulaComponentName,
  templateUrl: './predefinidocontabformula.component.html'
})
export class PredefinidoContabFormulaComponent extends PlEditInputTypeComponent<string, IPredefinidoContabFormulaOptionsInput> implements OnInit, OnChanges {
  @Input() public formulaType: TPreDefinidoContabFormulaType;

  public tooltip: IPlTooltipConfig;

  private _complex: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _predefinidoContabLinhasComponent: PredefinidoContabLinhasComponent,
    private readonly _cgModalService: CGModalService
  ) {
    super('string', _injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {formulaType} = changes;
    if (formulaType && !formulaType.isFirstChange()) {
      this._changedType(formulaType.currentValue);
    }
  }

  public openDialog(): Promise<void> {
    if (this.options.disabled) {
      return Promise.resolve();
    }
    const instance = this._cgModalService.showVanilla(PredefinidoContabFormulaDialogModalComponent, {size: 'lg'});
    const componentInstance: PredefinidoContabFormulaDialogModalComponent = instance.componentInstance;
    componentInstance.formula = this.value;
    componentInstance.params = {
      field: this._plTranslateService.translate(`predefinidocontablinhas.fields.${this._predefinidoContabLinhasComponent.selectedCell.name}`),
      line: this._predefinidoContabLinhasComponent.selectedLine._index + 1
    };
    componentInstance.complex = this._complex;
    componentInstance.predefinido = this._predefinidoContabLinhasComponent.predefinido;
    componentInstance.linhas = this._predefinidoContabLinhasComponent.linhas;
    componentInstance.selectedLine = this._predefinidoContabLinhasComponent.selectedLine;
    componentInstance.headings = this._predefinidoContabLinhasComponent.tableBodyDefinition;
    componentInstance.transformer = (value: string, complex: boolean) => this._transform(value, complex);
    return instance.result.then((formula: string) => {
      this.value = formula;
      return this.render();
    });
  }

  private _handleChanges(): void {
    this._changedType();
  }

  private _changedType(value: TPreDefinidoContabFormulaType = this.formulaType): void {
    this.formulaType = value || this.options.formulaType || 'valor';
    if (this.formulaType !== 'valor' && this.formulaType !== 'descricao') {
      this.formulaType = 'valor';
    }
    this._complex = this.formulaType === 'valor';
    this.tooltip = {disabled: !this._complex, text: 'predefinidocontablinhas.titles.hintFormula'};
  }

  private _transform(value: string, complex: boolean): string {
    if (this._predefinidoContabLinhasComponent) {
      const property: keyof IJsonPreDefinidoContabLinha = complex ? 'valor' : 'descricao';
      const definition = this._predefinidoContabLinhasComponent.tableBodyDefinition.find((bodyDefinition) => bodyDefinition.name === property);
      if (definition) {
        return this._predefinidoContabLinhasComponent.assignFormulaValue(definition.originalHeader, definition.header, value);
      }
    }
    return value;
  }
}
