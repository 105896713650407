import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_PERIOCIDADE, ETipoPeriocidade} from './tipoPeriocidade.datasource.interface';

export const DATA_SOURCE_TIPOS_PERIOCIDADE: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_PERIOCIDADE,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoPeriocidade.Sempre, name: 'tipoPeriocidade.data.Sempre'},
    {value: ETipoPeriocidade.Mensal, name: 'tipoPeriocidade.data.Mensal'},
    {value: ETipoPeriocidade.Anual, name: 'tipoPeriocidade.data.Anual'},
    {value: ETipoPeriocidade.Quinzenal, name: 'tipoPeriocidade.data.Quinzenal'},
    {value: ETipoPeriocidade.Semanal, name: 'tipoPeriocidade.data.Semanal'}
  ]
});
