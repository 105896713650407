import {Component, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {KEYCODES, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../../../components/cg/modal/cgmodal.service';
import {DocContabilidadeService} from '../../docContabilidade.service';
import {DocsContabilidadeEditReguralizacaoCampo40CancelModalComponent} from './cancelPrompt/docsContabilidade.edit.regularizacaoCampo40.cancel.modal.component';
import {focusElement} from '../../../../../../../../common/utils/element.utils';
import {ICGConfigContabilidade} from '../../../../../../../services/config/config.service.interface';
import {IDocContabilidadeLinha, IRegularizacaoCampo40} from '../../../../docsContabilidade.interface';
import {IJsonRegularizacaoCampo40} from '../../../../jsonDocContabilidade.interface';
import moment from 'moment';
import {round} from '../../../../../../../../common/utils/utils';
import {IDevExpressDataGrid} from '../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'docscontabilidade-edit-regularizacaocampo40-modal',
  templateUrl: './docsContabilidade.edit.regularizacaoCampo40.modal.component.html'
})
export class DocsContabilidadeEditRegularizacaoCampo40ModalComponent extends CGModalComponent<IJsonRegularizacaoCampo40> implements OnInit {
  @Input() public linha: IDocContabilidadeLinha;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonRegularizacaoCampo40, IJsonRegularizacaoCampo40>;

  public form: UntypedFormGroup;
  public ngForm: FormGroupDirective;
  public model: IRegularizacaoCampo40;
  public totalValue: number;
  public maxValue: number;

  private readonly _config: ICGConfigContabilidade;
  private readonly _valorDecimais: number;

  private _elementNDocumento: HTMLElement;
  private _elementSubmit: HTMLElement;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _docContabilidadeService: DocContabilidadeService
  ) {
    super(_injector);
    this._config = this._configService.configurations.contabilidade;
    this._valorDecimais = this._config.decimais.valor;

    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocumento', caption: 'docscontabilidade.nDocumento', dataType: 'string'},
        {dataField: 'data', caption: 'docscontabilidade.doc.cab.dataLancamento', dataType: 'date'},
        {dataField: 'valor', caption: 'docscontabilidade.doc.linhas.valor', dataType: 'double', format: {decimalsLimit: this._valorDecimais}},
        {dataField: 'valorIVA', caption: 'docscontabilidade.doc.linhas.valorTaxa', dataType: 'double', format: {decimalsLimit: this._valorDecimais}},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          alignment: 'center',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 100
        }
      ],
      columnAutoWidth: true,
      hoverStateEnabled: true,
      remoteOperations: false,
      showBorders: true
    };
  }

  public ngOnInit(): void {
    this.model = {
      nDocumento: '',
      valor: this.linha.baseTributavel,
      valorIVA: this.linha.valorTaxa,
      valorRegistado: 0,
      valorRegistadoIVA: 0,
      data: moment(),
      linhasRegularizacaoCampo40: []
    };
    this.totalValue = this.linha.baseTributavel;
    this._evaluateRegularizacao();
    this.maxValue = this.totalValue;
  }

  public close(): void {
    if (this.form.valid) {
      super.close(this.model);
    }
  }

  public beforeDismiss(): boolean | Promise<boolean> {
    if (this.model.valorRegistado !== this.totalValue) {
      return new Promise<boolean>((resolve, reject) => {
        this._cgModalService
          .show(DocsContabilidadeEditReguralizacaoCampo40CancelModalComponent)
          .then(() => {
            resolve(true);
          })
          .catch(reject);
      });
    }
    return true;
  }

  public valorChanged(value: number): void {
    this.model.valor = value;
    this.model.valorIVA = round((this.model.valor * this.linha.taxaIvaEmVigor) / 100, this._valorDecimais);
  }

  public valorIVAChanged(value: number): void {
    if (this.linha.taxaIvaEmVigor !== 0) {
      this.model.valorIVA = value;
      this.model.valor = round((this.model.valorIVA * 100) / this.linha.taxaIvaEmVigor, this._valorDecimais);
    }
  }

  public registarValor(): void {
    if (this.model.valor <= 0) {
      this._plAlertService.error(this._plTranslateService.translate('docscontabilidade.valorTemDeSerSuperior0'));
      return;
    }
    let valorTotal = 0;
    for (const regularizacao of this.model.linhasRegularizacaoCampo40) {
      valorTotal += regularizacao.valor;
    }
    if (round(valorTotal + this.model.valor, this._valorDecimais) > this.linha.baseTributavel) {
      this._plAlertService.error(
        this._plTranslateService.translate('docscontabilidade.valorSomadoJaExistente', {
          valor: this.model.valor,
          valorExistente: valorTotal,
          valorBase: this.linha.baseTributavel
        })
      );
      return;
    }
    const index: number = this.model.linhasRegularizacaoCampo40.findIndex((x: IJsonRegularizacaoCampo40) => {
      return x.nDocumento === this.model.nDocumento;
    });
    if (index !== -1) {
      focusElement(this._elementNDocumento);
      this._plAlertService.error(this._plTranslateService.translate('docscontabilidade.nDocumentoRegularicacaoJaExiste', {nDocumento: this.model.nDocumento}));
    } else {
      this.model.linhasRegularizacaoCampo40.push({
        nDocumento: this.model.nDocumento,
        valor: this.model.valor,
        valorIVA: this.model.valorIVA,
        data: this.model.data,
        valorRegistado: 0,
        valorRegistadoIVA: 0
      });
      this.model.valorRegistado += this.model.valor;
      this.model.valorRegistadoIVA += this.model.valorIVA;
      this.model.valor = this.linha.baseTributavel - this.model.valorRegistado;
      this.model.valorIVA = this.linha.valorTaxa - this.model.valorRegistadoIVA;
      this._setMaxValue();
      setTimeout(() => {
        const toFocus: HTMLElement = this.model.valorRegistado !== this.totalValue ? this._elementNDocumento : this._elementSubmit;
        focusElement(toFocus);
      });
    }
  }

  public registarValorFromLista(linha: IDocContabilidadeLinha): void {
    for (const regularizacao of linha.listaRegularizacaoCampo40) {
      this.model.nDocumento = regularizacao.nDocumento;
      this.model.data = regularizacao.data;
      this.model.valor = regularizacao.valor;
      this.model.valorIVA = regularizacao.valorIVA;
      this.registarValor();
    }
  }

  public removeLine(index: number): void {
    this.model.valorRegistado -= this.model.linhasRegularizacaoCampo40[index].valor;
    this.model.valorRegistadoIVA -= this.model.linhasRegularizacaoCampo40[index].valorIVA;
    this.model.valor = this.linha.baseTributavel - this.model.valorRegistado;
    this.model.valorIVA = this.linha.valorTaxa - this.model.valorRegistadoIVA;

    this._setMaxValue();
    this.model.linhasRegularizacaoCampo40.splice(index, 1);
  }

  public changeValorTaxa(event?: KeyboardEvent): void {
    if (event) {
      if (event.key !== KEYCODES.ENTER) {
        return;
      }
      // Prevent plFormNavigation from messing up UX
      event.preventDefault();
      event.stopPropagation();
    }
    this._docContabilidadeService.editValorModal(this.model, 'valorRetido', 'docscontabilidade.montanteRetido').then((response: IRegularizacaoCampo40) => {
      this.model = response;
      focusElement(<HTMLElement>event.target);
    });
  }

  @ViewChild('elementNDocumento', {read: ElementRef})
  public set elementNDocumento(value: ElementRef<HTMLElement>) {
    this._elementNDocumento = value?.nativeElement;
  }

  @ViewChild('elementSubmit', {read: ElementRef})
  public set elementSubmit(value: ElementRef<HTMLElement>) {
    this._elementSubmit = value?.nativeElement;
  }

  private _setMaxValue(): void {
    this.maxValue = this.totalValue - this.model.valorRegistado;
  }

  private _evaluateRegularizacao(): void {
    this.registarValorFromLista(this.linha);
  }
}
