import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, Injector, Input} from '@angular/core';
import {IJsonDocComercial} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {DocumentosService} from '../../../../../entities/docscomerciais/service/documentos.entity.service';

@Component({
  selector: 'encomendas-documento-modal',
  templateUrl: './encomendas.documento.modal.component.html'
})
export class EncomendasDocumentoModalComponent extends CGModalComponent<void> {
  @Input() public doc: IJsonDocComercial;

  constructor(
    protected readonly _injector: Injector,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
  }

  public fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }
}
