<div class="pl-colorpicker">
  <div class="pl-colorpicker-edit input-group pl-input-group">
    <div
      class="pl-colorpicker-component circle"
      [colorPicker]="value"
      [style.background]="value"
      [cpWidth]="options.width"
      [cpHeight]="options.height"
      [cpToggle]="options.toggle"
      [cpDisabled]="options.disabled"
      [cpColorMode]="options.colorMode"
      [cpOutputFormat]="options.outputFormat"
      [cpAlphaChannel]="options.alphaChannel"
      [cpFallbackColor]="options.fallbackColor"
      [cpPosition]="options.position"
      [cpPositionOffset]="options.positionOffset"
      [cpPositionRelativeToArrow]="options.positionRelativeToArrow"
      [cpPresetLabel]="options.presetLabel"
      [cpPresetColors]="options.presetColors"
      [cpDisableInput]="options.disableInput"
      [cpDialogDisplay]="options.dialogDisplay"
      [cpIgnoredElements]="options.ignoredElements"
      [cpSaveClickOutside]="options.saveClickOutside"
      [cpOKButton]="options.okButton"
      [cpOKButtonText]="options.okButtonText"
      [cpOKButtonClass]="options.okButtonClass"
      [cpCancelButton]="options.cancelButton"
      [cpCancelButtonText]="options.cancelButtonText"
      [cpCancelButtonClass]="options.cancelButtonClass"
      [cpAddColorButton]="options.addColorButton"
      [cpAddColorButtonText]="options.addColorButtonText"
      [cpAddColorButtonClass]="options.addColorButtonClass"
      [cpRemoveColorButtonClass]="options.removeColorButtonClass"
      [cpMaxPresetColorsLength]="options.maxPresetColorsLength"
      [cpPresetEmptyMessage]="options.presetEmptyMessage"
      [cpPresetEmptyMessageClass]="options.presetEmptyMessageClass"
      [cpUseRootViewContainer]="options.useRootViewContainer"
      (colorPickerOpen)="eventColorPickerValue('open', $event)"
      (colorPickerClose)="eventColorPickerValue('close', $event)"
      (colorPickerChange)="render($event)"
      (colorPickerCancel)="eventColorPickerCancel()"
      (colorPickerSelect)="eventColorPickerValue('select', $event)"
      (cpToggleChange)="eventColorPickerToggleChange($event)"
      (cpInputChange)="eventColorPickerInputChange($event)"
      (cpSliderChange)="eventColorPickerSlider('sliderChange', $event)"
      (cpSliderDragStart)="eventColorPickerSlider('sliderDragStart', $event)"
      (cpSliderDragEnd)="eventColorPickerSlider('sliderDragEnd', $event)"
      (cpPresetColorsChange)="eventColorPickerPresetsColorsChange($event)"></div>
  </div>
  <div *ngIf="currentLabel" class="pl-colorpicker-label" [plTranslate]="currentLabel"></div>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>
