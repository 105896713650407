import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {AfterContentChecked, ContentChildren, Directive, ElementRef, Injector, OnDestroy, OnInit, QueryList, ViewChild} from '@angular/core';
import type {IPlEditComponentOptionsInputText} from '../../component/edit.component.interface';
import {isDefined, isFunction} from '../../../common/utilities/utilities';
import {PlEditInputComponent} from './edit.input.component';
import {PlEditInputGroupDirective} from '../inputgroup/edit.input.group.directive';

@Directive()
export class PlEditInputTypeComponent<T, S extends IPlEditComponentOptionsInputText<T> = IPlEditComponentOptionsInputText<T>>
  extends PlEditInputComponent<T, S>
  implements OnInit, OnDestroy, AfterContentChecked
{
  public hasGroup: boolean;

  protected _itemGroups: QueryList<PlEditInputGroupDirective>;
  protected _subscriptionItemGroups: Subscription;
  protected _inputField: HTMLInputElement;

  protected constructor(
    public type: string,
    protected readonly _injector: Injector
  ) {
    super(_injector);
    this._defaultOptions = Object.freeze<S>(
      merge({}, this._defaultOptions, {
        selectOnFocus: true
      })
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._checkHasGroup();
  }

  public ngAfterContentChecked(): void {
    if (this._plEdit?.itemGroups?.length && !this.itemGroups?.length) {
      this.itemGroups = this._plEdit.itemGroups;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionItemGroups) {
      this._subscriptionItemGroups.unsubscribe();
    }
  }

  public updateComponent(properties: S): void {
    super.updateComponent(properties);
    if (properties && properties !== this.options) {
      this._checkHasGroup();
    }
  }

  public clearViewValue(): Promise<void> {
    return super.clearViewValue().then(() => {
      this.inputFocus();
    });
  }

  public inputFocus(): boolean {
    if (this._inputField) {
      this._inputField.focus();
      return true;
    }
    return false;
  }

  public inputSelectAll(): boolean {
    if (this.inputFocus() && isFunction(this._inputField.select)) {
      this._inputField.select();
      return true;
    }
    return false;
  }

  public onInputFocus(event: FocusEvent): void {
    super.onInputFocus(event);
    // Select all on focus if enabled
    if (!event.defaultPrevented && this.options.selectOnFocus !== false) {
      this.inputSelectAll();
    }
  }

  public get inputField(): HTMLInputElement {
    return this._inputField;
  }

  public get itemGroups(): QueryList<PlEditInputGroupDirective> {
    return this._itemGroups;
  }

  @ContentChildren(PlEditInputGroupDirective, {descendants: true})
  public set itemGroups(value: QueryList<PlEditInputGroupDirective>) {
    if (this._itemGroups && this._itemGroups !== value) {
      if (this._subscriptionItemGroups) {
        this._subscriptionItemGroups.unsubscribe();
        this._subscriptionItemGroups = undefined;
      }
    }
    this._itemGroups = value;
    if (this._itemGroups) {
      this._subscriptionItemGroups = this._itemGroups.changes.subscribe(() => {
        this._checkHasGroup();
      });
    }
    this._checkHasGroup();
  }

  @ViewChild('inputElement')
  public set inputFieldInit(value: ElementRef<HTMLInputElement>) {
    this._inputField = value?.nativeElement;
  }

  protected _checkHasGroup(): void {
    this.hasGroup = this._isOptionBoolean('hasGroup') ? this.options.hasGroup : isDefined(this._itemGroups) && this._itemGroups.length > 0;
  }
}
