import moment, {Moment, unitOfTime} from 'moment';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IPlEditComponentOptionsInputSelect, isArray, isNumber} from 'pl-comps-angular';
import {EGestaoDGEMPSView} from '../gestaodgemps.interface';
import {gestaoDGEMPSDefaultViewDate, gestaoDGEMPSViewUnit} from '../gestaodgemps.utilities';
import {IJsonPRHServico} from '../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {normalizeDate} from '../../../../common/utils/moment.utils';

const DEFAULT_SERVICOS: ReadonlyArray<IJsonPRHServico> = Object.freeze([{codServico: 0, nome: 'global.text.notDefined'}]);

@Component({
  selector: 'gestao-dgemps-header',
  templateUrl: './gestaodgemps.header.component.html'
})
export class GestaoDGEMPSHeaderComponent implements OnChanges {
  @Input() public view: EGestaoDGEMPSView;
  @Input() public viewDate: Moment;
  @Input() public servicos: Array<IJsonPRHServico>;
  @Input() public servico: IJsonPRHServico;
  @Input() public showView: boolean;
  @Input() public showServicos: boolean;
  @Input() public showPending: boolean;
  @Input() public disabledPendingPrevious: boolean;
  @Input() public disabledPendingNext: boolean;
  @Input() public loading: boolean;
  @Output() public readonly viewChange: EventEmitter<EGestaoDGEMPSView>;
  @Output() public readonly viewDateChange: EventEmitter<Moment>;
  @Output() public readonly servicoChange: EventEmitter<IJsonPRHServico>;
  @Output() public readonly evtPendingPrevious: EventEmitter<void>;
  @Output() public readonly evtPendingNext: EventEmitter<void>;

  public readonly views: typeof EGestaoDGEMPSView;
  public readonly propertiesSelectServico: IPlEditComponentOptionsInputSelect<IJsonPRHServico>;
  public titlePrevious: string;
  public titleNext: string;

  constructor() {
    this.servicos = DEFAULT_SERVICOS.slice();
    this.showView = true;
    this.showServicos = true;
    this.showPending = false;
    this.disabledPendingPrevious = true;
    this.disabledPendingNext = true;
    this.loading = false;
    this.viewChange = new EventEmitter<EGestaoDGEMPSView>();
    this.viewDateChange = new EventEmitter<Moment>();
    this.servicoChange = new EventEmitter<IJsonPRHServico>();
    this.evtPendingPrevious = new EventEmitter<void>();
    this.evtPendingNext = new EventEmitter<void>();
    this.views = EGestaoDGEMPSView;
    this.view = EGestaoDGEMPSView.Year;
    this.propertiesSelectServico = {
      disallowClear: true,
      select: {
        valueProp: 'codServico',
        labelProp: 'nome'
      }
    };
  }

  public ngOnChanges({view, viewDate, servicos}: SimpleChanges): void {
    if (view) {
      this._changedView(view.currentValue);
    }
    if (viewDate) {
      this._changedViewDate(viewDate.currentValue);
    }
    if (servicos && !isArray(this.servicos)) {
      this.servicos = DEFAULT_SERVICOS.slice();
    }
  }

  public viewDatePrevious(): void {
    this.setViewDate(this.viewDate.clone().subtract(1, this._evaluateUnit()));
  }

  public viewDateToday(): void {
    this.setViewDate(normalizeDate(moment()).startOf(this._evaluateUnit()));
  }

  public viewDateNext(): void {
    this.setViewDate(this.viewDate.clone().add(1, this._evaluateUnit()));
  }

  public pendingPrevious(): void {
    this.evtPendingPrevious.emit();
  }

  public pendingNext(): void {
    this.evtPendingNext.emit();
  }

  public setView(value: EGestaoDGEMPSView): void {
    if (value !== this.view) {
      this.view = value;
      this.viewChange.emit(this.view);
    }
  }

  public setViewDate(value: Moment): void {
    this.viewDate = value;
    this.viewDateChange.next(this.viewDate);
  }

  public setServico(codServico: number): void {
    this.servico = codServico || codServico === 0 ? this.servicos.find((servico: IJsonPRHServico) => servico.codServico === codServico) : undefined;
    this.servicoChange.emit(this.servico);
  }

  private _changedView(value: EGestaoDGEMPSView = this.view): void {
    this.view = isNumber(value) ? value : EGestaoDGEMPSView.Year;
    switch (this.view) {
      case EGestaoDGEMPSView.Year:
        this.titlePrevious = 'gestaodgemps.header.previousYear';
        this.titleNext = 'gestaodgemps.header.nextYear';
        break;
      case EGestaoDGEMPSView.Month:
        this.titlePrevious = 'gestaodgemps.header.previousMonth';
        this.titleNext = 'gestaodgemps.header.nextMonth';
        break;
    }
  }

  private _changedViewDate(value: Moment = this.viewDate): void {
    this.viewDate = moment.isMoment(value) ? value.clone() : gestaoDGEMPSDefaultViewDate();
  }

  private _evaluateUnit(): unitOfTime.Base {
    return gestaoDGEMPSViewUnit(this.view);
  }
}
