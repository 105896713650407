import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, PlTranslateService, TPlAlertStickyType} from 'pl-comps-angular';
import {FormGroup} from '@angular/forms';
import {IJsonModeloConfigWS} from '../modelo.configWS.module.interface';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ObrigacoesAcessoriasService} from '../obrigacoesAcessorias.module.service';

@Component({
  selector: 'modal-modelo-configws',
  templateUrl: './modelo.configWS.modal.component.html'
})
export class ModeloConfigwsModalComponent extends CGModalComponent<void> implements OnInit {
  public model: IJsonModeloConfigWS;
  public alertType: TPlAlertStickyType;
  public alertMessage: string;
  public form: FormGroup;
  public promise: Promise<IJsonModeloConfigWS>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _obrigacoesAcessoriasService: ObrigacoesAcessoriasService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.model = {
      passwordCC: '',
      usernameCC: '',
      servicoInvocadoPorCC: false,
      password: '',
      ccComPoderesDeclarativosContribuinte: false,
      username: '',
      isConfigured: false
    };

    this.promise = this._obrigacoesAcessoriasService.getConfigWS().then((response: HttpResponse<IJsonModeloConfigWS>) => response.body);
    Promise.resolve(this.promise)
      .then((response: IJsonModeloConfigWS) => {
        this.model = response;
        this.model.password = '';
        this.model.passwordCC = '';
        if (this.model.isConfigured) {
          this.alertType = 'success';
          this.alertMessage = this._plTranslateService.translate('obrigacoesAcessorias.modal.configws.configured');
        } else {
          this.alertType = 'warning';
          this.alertMessage = this._plTranslateService.translate('obrigacoesAcessorias.modal.configws.notConfigured');
        }
        if (!this.model.username) {
          this.model.username = `${this._configService.configurations.empresa.ncontribuemp}/0`;
        }
        if (!this.model.usernameCC) {
          this.model.usernameCC = this._configService.configurations.empresa.ncontribtoc;
        }
      })
      .finally(() => {
        this.promise = null;
      });
  }

  public close(): void {
    if (this.form.valid) {
      this.disableClose();

      this.promise = this._obrigacoesAcessoriasService.saveConfigWS(this.model).then((response: HttpResponse<IJsonModeloConfigWS>) => response.body);
      Promise.resolve(this.promise)
        .then(() => {
          this.enableClose();
          this._plAlertService.success('obrigacoesAcessorias.modal.configws.configsaved');
          super.close();
        })
        .catch(() => {
          this.enableClose();
        });
    }
  }

  public servicoInvocadoPorCCChanged(value: boolean): void {
    this.model.servicoInvocadoPorCC = value;
    if (!this.model.servicoInvocadoPorCC) {
      this.model.ccComPoderesDeclarativosContribuinte = false;
    }
  }
}
