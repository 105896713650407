import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlUploadCallback, IPlUploadFile, PlAlertService} from 'pl-comps-angular';
import {RHQPIRCTService} from '../../rhQPIRCT.module.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRHQPIRCTImpQPIRCT} from '../../jsonRHQPIRCT.module.interface';

@Component({
  selector: 'modal-rhqpirct-import-file',
  templateUrl: './rhQPIRCT.importFile.modal.component.html'
})
export class RHQPIRCTImportFileModalComponent extends CGModalComponent<IJsonRHQPIRCTImpQPIRCT> {
  public readonly callback: IPlUploadCallback;
  public uploadUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _rhQPIRCTService: RHQPIRCTService
  ) {
    super(_injector);
    this.callback = {};
    this._getImportarFicheiroExcelUrl();
  }

  public onFileUpload(file: IPlUploadFile): void {
    this._plAlertService.success('rhqpirct.messages.filesuccess');
    this.close((<HttpResponse<IJsonRHQPIRCTImpQPIRCT>>file.upload.response).body);
  }

  private _getImportarFicheiroExcelUrl(): void {
    this._rhQPIRCTService.postImportFileCSV().subscribe((url: string) => {
      this.uploadUrl = url;
    });
  }
}
