import {Component, Injector, Input} from '@angular/core';
import {IJsonModelo22AnexoE} from '../../../../jsonModelo22.module.interface';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {isNumber, PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'modal-modelo22-anexoe',
  templateUrl: './modelo22.anexoE.modal.component.html'
})
export class Modelo22AnexoEModalComponent extends CGModalComponent<IJsonModelo22AnexoE> {
  @Input() public anexoE: IJsonModelo22AnexoE;

  public rendimento: number;
  public t: number;
  public materiacol: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.rendimento = 0;
    this.t = 0;
    this.materiacol = 0;
  }

  public close(): void {
    if (!isNumber(this.rendimento) || this.rendimento < 1) {
      this._plAlertService.error('modelo22.messages.rendimentovalido');
      return;
    }
    if (!isNumber(this.t) || this.t < 1) {
      this._plAlertService.error('modelo22.messages.tvalido');
      return;
    }
    this.anexoE.quadro03.campo0621List.push({
      rendimentos: this.rendimento,
      rendimentosT: this.t,
      materiaColectavel: this.materiacol
    });
    super.close(this.anexoE);
  }
}
