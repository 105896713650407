import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosImportConsistenciasContas} from '../../jsonAtivosImport.entity.interface';
import {IAtivosImportInconsistencias} from '../../ativosImport.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-ativos-import-concistenciascontas',
  templateUrl: './ativosImport.consistenciasContas.modal.component.html'
})
export class AtivosImportConsistenciasContasModalComponent extends CGModalComponent<void> {
  @Input() public ativosconsistencias: Array<IJsonAtivosImportConsistenciasContas>;
  public consistenciasByGroup: Array<IAtivosImportInconsistencias>;
  public tableDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.consistenciasByGroup = [];
    this.tableDefinition = {
      columns: [
        {dataField: 'erro', dataType: 'boolean', caption: 'global.text.error'},
        {dataField: 'nConta', dataType: 'string', caption: 'ativosimport.modal.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'ativosimport.modal.fields.nomeConta'},
        {dataField: 'valorContabilidade', dataType: 'number', caption: 'ativosimport.modal.fields.valorContabilidade'},
        {dataField: 'valorAtivos', dataType: 'number', caption: 'ativosimport.modal.fields.valorAtivos'},
        {dataField: 'diferenca', dataType: 'number', caption: 'ativosimport.modal.fields.diferenca'},
        {dataField: 'tipo', dataType: 'string', caption: 'ativosimport.modal.tipo', groupIndex: 0}
      ],
      searchPanel: {visible: false},
      filterRow: {visible: false},
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      columnFixing: {enabled: false},
      pager: {visible: false},
      paging: {enabled: false},
      scrolling: {mode: 'virtual'},
      grouping: {contextMenuEnabled: false, autoExpandAll: false},
      groupPanel: {allowColumnDragging: false, visible: false}
    };
  }
}
