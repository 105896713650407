import {filter, orderBy} from 'lodash-es';
import {isArray, isString, isUndefinedOrNull} from './utilities';
import type {TOrderByFieldsIteratees, TOrderByFieldsOrder} from './utilities.interface';

/**
 * @description Paginates a collection given a current page number and a per page number
 */
export function paginate<T = any>(collection: Array<T>, page: number, perPage: number): Array<T> {
  if (isArray(collection) && collection.length && page > 0 && perPage > 0) {
    const index = (page - 1) * perPage;
    return collection.slice(index, index + perPage);
  }
  return collection;
}

/**
 * @description Orders a collection given a `fields` argument.
 * The `fields` argument supports multiple fields when delimited by a comma.
 * A given field supports ascending/descending ordering when supplied with a
 * space separated "asc"/"desc" argument following the field name.
 */
export function orderByFields<T>(collection: Array<T>, fields?: string): Array<T> {
  if (!isArray(collection)) {
    return [];
  }
  if (!collection.length) {
    return [];
  }
  const orderByFieldsIteratees: TOrderByFieldsIteratees = fieldsToOrderByIteratees(fields);
  const iteratees: Array<keyof T> = <Array<keyof T>>orderByFieldsIteratees[0];
  const orders: Array<TOrderByFieldsOrder> = orderByFieldsIteratees[1];
  if (!iteratees.length) {
    return collection;
  }
  return orderBy(collection, iteratees, orders);
}

/**
 * @description Transforms a `fields` string value to a tuple of iteratees
 * and sort arguments that can be used by `orderByFields` method.
 */
export function fieldsToOrderByIteratees(fields: string): TOrderByFieldsIteratees {
  const iteratees: Array<string> = [];
  const orders: Array<TOrderByFieldsOrder> = [];
  fields = (fields || '').trim();
  if (fields) {
    const fieldsOrders: Array<string> = fields.split(',');
    for (const field of fieldsOrders) {
      const orderItemFragments: Array<string> = field.split(' ');
      if (orderItemFragments.length > 0) {
        iteratees.push(orderItemFragments[0]);
        let order: TOrderByFieldsOrder = 'asc';
        if (orderItemFragments.length > 1 && orderItemFragments[1] === 'desc') {
          order = 'desc';
        }
        orders.push(order);
      }
    }
  }
  return [iteratees, orders];
}

/**
 * @description Filters the array using ``search`` value using a case insensitive
 * any value type method
 */
export function filterSearchInsensitive<T>(collection: Array<T>, search: string): Array<T> {
  search = search.toLowerCase();
  return filter(collection, (value) => {
    for (const prop of Object.keys(value)) {
      if (isUndefinedOrNull(value[prop])) {
        continue;
      }
      let val: string = isString(value[prop]) ? value[prop] : String(value[prop]);
      val = val.toLowerCase();
      if (val.includes(search)) {
        return true;
      }
    }
    return false;
  });
}
