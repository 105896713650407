<pl-form>
  <div class="modal-header">
    <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.modalTitleEdit'"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title" [translate]="'importadorSaftDirect.strings.taxasIvaSaftLabel'"></h6>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.taxCountryRegion'"></label>
              <edit>
                <pl-edit type="text" [model]="model.taxCountryRegion" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.taxCode'"></label>
              <edit>
                <pl-edit type="text" [model]="model.taxCode" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.taxPercentage'"></label>
              <edit>
                <pl-edit type="text" [model]="model.taxPercentage" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title" [translate]="'importadorSaftDirect.strings.taxasIvaCGLabel'"></h6>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.codIva'"></label>
              <edit>
                <entity-autocomplete
                  entity="ivas"
                  [model]="model"
                  (modelChange)="codIvaChange($event)"
                  [filter]="filterByTax"
                  [properties]="{allowInvalid: false}"
                  output="key"
                  plAutoFocus></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.taxaIva'"></label>
              <edit>
                <pl-edit type="text" [model]="model.taxaIva" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.digitosIva'"></label>
              <edit>
                <pl-edit type="text" [model]="model.digitosIva" (modelChange)="digitosIvaChange($event)"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary btn-sm action-close" [onClick]="close" data-focus>
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span>
    </pl-button>

    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span></pl-button>
  </div>
</pl-form>
