import {HttpResponse} from '@angular/common/http';
import {AtivosConfiguracoesModuleComponent} from './components/ativosConfiguracoes.module.component';
import {AtivosConfiguracoesService} from './ativosConfiguracoes.module.service';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_ATIVOS_CONFIGURACOES} from './ativosConfiguracoes.module.interface';
import {ROLE} from '../../../services/role.const';
import {IJsonAtivosconfiguracoes} from './jsonAtivosConfiguracoes.module.interface';

export const MODULE_ATIVOS_CONFIGURACOES: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_CONFIGURACOES,
  state: {
    url: `/${MODULE_NAME_ATIVOS_CONFIGURACOES}`,
    component: AtivosConfiguracoesModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosconfiguracoes',
      icon: 'fa-cogs'
    },
    resolve: [
      {
        provide: 'model',
        deps: [AtivosConfiguracoesService],
        useFactory: (ativosConfiguracoesService: AtivosConfiguracoesService) => ativosConfiguracoesService.get().then((response: HttpResponse<IJsonAtivosconfiguracoes>) => response.body)
      }
    ]
  }
};
