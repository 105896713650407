import {Component, Injector} from '@angular/core';
import {PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ECalendarioTipoDiaStr} from '../../../../../../../entities/calendarios/jsonCalendarios.entity.interface';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../../../../../../../components/gestaodgemps/modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {gestaoDGEMPSMarcarHoraFim, gestaoDGEMPSMarcarHoraInicio} from '../../../../../../../components/gestaodgemps/gestaodgemps.utilities';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../../../../../../components/gestaodgemps/gestaodgemps.interface';
import {IJsonDGEMPCalendario} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHMarcarFerias} from '../../../../../../../interfaces/jsonPRHFerias.interface';
import moment, {Moment} from 'moment';
import {PortalClientAccountService} from '../../../../../../../services/portalclientaccount/portalclientaccount.service';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'pca-rh-gestao-dgemps-ferias-marcar-modal',
  templateUrl: './pca.rh.gestaoDGEMPS.ferias.marcar.modal.component.html'
})
export class PcaRhGestaoDGEMPSFeriasMarcarModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent {
  public allDay: boolean;
  public horaInicio: TDate;
  public horaFim: TDate;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector, _plI18nService, _plTranslateService);

    this.allDay = true;
    this.horaInicio = gestaoDGEMPSMarcarHoraInicio();
    this.horaFim = gestaoDGEMPSMarcarHoraFim();
  }

  public close(): Promise<void> {
    this.disableClose();

    const {viewDate, startDate, endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();

    const fnMarcarFerias = (): Promise<void> => {
      const calendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarFeria(viewDate, startDate, endDate, calendario).then(fnMarcarFerias);
    };

    return fnMarcarFerias()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private _marcarFeria(viewDate: Moment, startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario): Promise<unknown> {
    const ferias: IJsonPRHMarcarFerias = {
      cab: undefined,
      linhas: [],
      motivo: undefined,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    for (const dataAtual = moment(startDate); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
      const dataCalendario: IJsonDGEMPCalendario = calendario.listaCalendario.find((day: IJsonDGEMPCalendario) => dataAtual.isSame(day.data, 'day'));
      if (!dataCalendario || dataCalendario.tipoDiaStr === ECalendarioTipoDiaStr.DiaUtil) {
        const horaInicio: Moment = !this.allDay ? moment(this.horaInicio) : dataAtual.clone().startOf('day');
        const horaFim: Moment = !this.allDay ? moment(this.horaFim) : dataAtual.clone().endOf('day');

        const dataInicio: Moment = viewDate.clone().set({
          hours: horaInicio.hours(),
          minutes: horaInicio.minutes(),
          seconds: horaInicio.seconds(),
          milliseconds: horaInicio.milliseconds()
        });
        const dataFim: Moment = viewDate.clone().set({
          hours: horaFim.hours(),
          minutes: horaFim.minutes(),
          seconds: horaFim.seconds(),
          milliseconds: horaFim.milliseconds()
        });

        ferias.linhas.push({
          idTarefaCab: undefined,
          posicao: undefined,
          idFerias: undefined,
          data: dataAtual.clone(),
          horaInicio: dataInicio,
          horaFim: dataFim,
          ocupacaoId: undefined
        });
      }
    }

    return this._portalClientAccountService.marcarFeriasEmpregado(calendario.codEmp, ferias).then(() => {
      this._successfulCalendarios.add(calendario);
      for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
        if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
          this.marcarData.calendarios.splice(i, 1);
        }
      }
    });
  }
}
