import {IJsonAtivosDepreciacoesDetalhadasImpressao, IJsonAtivosImpressaoHeader} from './jsonAtivosImpressao.module.interface';
import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {EAtivosMapasOficiaisEstadoUso} from '../ativosmapasoficiais/ativosMapasOficiais.module.interface';
import {deepFreeze} from 'pl-comps-angular';

export interface IAtivosImpressaoHeader extends IJsonAtivosImpressaoHeader {
  reportName: string;
  tipoExportacao: number;
}

export interface IAtivosImpressaoLinha extends IJsonAtivosDepreciacoesDetalhadasImpressao, IAtivosImpressaoLinhaFormula {}

export interface IAtivosImpressaoLinhaFormula {
  _docContabilidade?: IJsonDocContabilidade;
}

export const RADIO_GROUP_ATIVOS_ESTADO_USO_TEMPLATE: IRadioGroup<EAtivosMapasOficiaisEstadoUso> = deepFreeze<IRadioGroup<EAtivosMapasOficiaisEstadoUso>>({
  groupItems: [
    {value: EAtivosMapasOficiaisEstadoUso.Indiferente, label: 'ativosimpressao.radioOptions.indiferente'},
    {value: EAtivosMapasOficiaisEstadoUso.Usado, label: 'ativosimpressao.radioOptions.usado'},
    {value: EAtivosMapasOficiaisEstadoUso.Novo, label: 'ativosimpressao.radioOptions.novo'}
  ]
});

export const MODULE_NAME_ATIVOS_IMPRESSAO = 'ativosimpressao';
