import {NgModule} from '@angular/core';
import {CGCTranslatePipe} from './translate.pipe';
import {PlTranslateDirective} from './translate.directive';

export * from './translate.service';
export * from './translate.pipe';
export * from './translate.directive';

const DECLARATIONS = [
  PlTranslateDirective,
  CGCTranslatePipe
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlTranslateModule {
}
