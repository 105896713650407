import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';

import {IJsonIvaMotivosTaxa} from './jsonIvaMotivosTaxa.entity.interface';
import {ENTITY_NAME_IVA_MOTIVOS_TAXA} from './ivaMotivosTaxa.entity.interface';

export const ENTITY_IVA_MOTIVOS_TAXA: IEntityDefinition<IJsonIvaMotivosTaxa> = {
  name: ENTITY_NAME_IVA_MOTIVOS_TAXA,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'ivamotivostaxa.pesquisa',
  metadata: {
    keyName: 'ivaMotivosTaxaId',
    fields: [
      {
        name: 'ivaMotivosTaxaId',
        type: 'cginteger',
        caption: 'ivamotivostaxa.fields.ivaMotivosTaxaId',
        placeholder: 'ivamotivostaxa.fields.ivaMotivosTaxaIdPlaceholder',
        validators: {required: true, min: 101}
      },
      {
        name: 'ivaMotivosTaxaCod',
        caption: 'ivamotivostaxa.fields.ivaMotivosTaxaCod',
        placeholder: 'ivamotivostaxa.fields.ivaMotivosTaxaCodPlaceholder',
        validators: {maxlength: 10}
      },
      {
        name: 'descricao',
        caption: 'ivamotivostaxa.fields.descricao',
        placeholder: 'ivamotivostaxa.fields.descricaoPlaceholder',
        validators: {required: true, maxlength: 60}
      },
      {
        name: 'normaAplicavel',
        caption: 'ivamotivostaxa.fields.normaAplicavel',
        placeholder: 'ivamotivostaxa.fields.normaAplicavelPlaceholder',
        validators: {maxlength: 150}
      }
    ],
    order: 'ivaMotivosTaxaId',
    searchFields: 'ivaMotivosTaxaId,ivaMotivosTaxaCod,descricao'
  },
  autocomplete: {
    rowTemplate: '{{ivaMotivosTaxaId}} - {{descricao}} ({{ivaMotivosTaxaCod}} / {{normaAplicavel}})',
    output: '{{ivaMotivosTaxaId}} - {{descricao}} ({{ivaMotivosTaxaCod}} / {{normaAplicavel}})',
    outputDescription: 'descricao',
    searchFields: 'ivaMotivosTaxaId,ivaMotivosTaxaCod,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
