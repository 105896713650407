<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.configImportModalTitle'"></h4>
</div>

<div class="modal-body" [plPromise]="mainPromise">
  <pl-form>
    <div class="row">
      <div class="col-md-12">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.template'"></label>
          <edit>
            <pl-autocomplete [model]="templateName" [source]="templateSource" [allowInvalid]="true" (modelChange)="templateChange($event)"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-12">
        <h4 class="main-title" [translate]="'conciliacaoBancaria.configDebitoCreditoNaPerspectivaBancoTitle'"></h4>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.numPrimeiraLinhaDados'"></label>
          <edit>
            <pl-edit type="cginteger" [(model)]="model.linhaInicial"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.colunaSaldo'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaSaldo"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.colunaDataMovimento'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaDataMovimento"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.colunaDataValor'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaDataValor"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.colunaDescrição'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaDescricao"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-md-6">
        <pl-group>
          <edit class="radiogroup-group-vertical">
            <label [translate]="'conciliacaoBancaria.comoEObtidoValorMovimentoNoExtrato'"></label>
            <pl-edit type="radiogroup" [(model)]="model.tipoMovimento" [properties]="{groupItems: valorMovimItems}"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-md-6">
        <ng-container *ngIf="model.tipoMovimento === valorMovPropColuna">
          <pl-group>
            <label [translate]="'conciliacaoBancaria.fields.colunaValorDoMovimento'"></label>
            <edit>
              <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaValor"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <edit>
              <label [translate]="'conciliacaoBancaria.fields.seValorNegativoInverteSinalELancaA'"></label>
              <pl-edit type="radiogroup" [model]="model.valorNegativoLancaCreditoIndex" (modelChange)="onValorNegativoChange($event)" [properties]="{groupItems: debitoCreditoItems}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <edit>
              <label [translate]="'conciliacaoBancaria.fields.seValorPositivoNaoInverteSinalELancaA'"></label>
              <pl-edit type="radiogroup" [model]="model.valorPositivoLancaCreditoIndex" (modelChange)="onValorPositivoChange($event)" [properties]="{groupItems: debitoCreditoItems}"></pl-edit>
            </edit>
          </pl-group>
        </ng-container>

        <ng-container *ngIf="model.tipoMovimento === valorMovColunasSeparadas">
          <div class="row">
            <div class="col-md-6">
              <pl-group>
                <label [translate]="'conciliacaoBancaria.fields.colunaValorADebito'"></label>
                <edit>
                  <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaValorDebito"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-md-6">
              <pl-group>
                <label [translate]="'conciliacaoBancaria.fields.colunaValorACredito'"></label>
                <edit>
                  <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaValorCredito"></pl-edit>
                </edit>
              </pl-group>
            </div>

            <div class="col-md-6">
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="model.inverteSinal" [properties]="{label: 'conciliacaoBancaria.fields.inverteSinalDoValorADebito'}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="model.tipoMovimento === valorMovUmaColunaENoutra">
          <div class="row">
            <div class="col-md-6">
              <pl-group>
                <label [translate]="'conciliacaoBancaria.fields.colunaValorDoMovimento'"></label>
                <edit>
                  <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaValor"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-md-6">
              <pl-group>
                <label [translate]="'conciliacaoBancaria.fields.colunaDoTipoDeMovimento'"></label>
                <edit>
                  <pl-edit type="select" [properties]="{select: {list: cboSource}}" [(model)]="model.colunaTipoMovimento"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <pl-group>
                <label [translate]="'conciliacaoBancaria.fields.caraterParaDebito'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="model.caraterDebito" [properties]="{validators: {maxlength: {value: 1}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-md-6">
              <pl-group>
                <edit>
                  <label [translate]="'conciliacaoBancaria.fields.caraterParaCredito'"></label>
                  <pl-edit type="text" [(model)]="model.caraterCredito" [properties]="{validators: {maxlength: {value: 1}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </pl-form>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-danger" (click)="removeTemplate()"><i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span></button>
  <button type="button" class="btn btn-success" (click)="saveTemplate()"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
