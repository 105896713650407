import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {
  EDebDiretosSEPAFicheiroEmitidosType,
  IJsonDebDiretosSEPAAcesso,
  IJsonDebDiretosSEPACliente,
  IJsonDebDiretosSEPAConfiguracao,
  IJsonDebDiretosSEPAEmissaoListaAIntervirItem,
  IJsonDebDiretosSEPAEmissaoPreload,
  IJsonDebDiretosSEPAErroLiquidacaoRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosAllRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosRecord,
  IJsonDebDiretosSEPAImportProcessResults,
  IJsonDebDiretosSEPAImportResults,
  IJsonDebDiretosSEPANotificarItem,
  IJsonDebDiretosSEPARecord,
  IJsonDebDiretosSEPARecordLog,
  IJsonEmissaoGeraFicheirosXMLData,
  IJsonGerarFicheirosXMLResults
} from './debDiretosSEPA.module.interface';
import {isArray} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DebDiretosSEPAModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/ddsepa`;
  }

  public getDebDiretosSEPARecords(loteFicheiro: string, liquidado: boolean): TServiceResponse<Array<IJsonDebDiretosSEPARecord>> {
    return this._apiService.get<Array<IJsonDebDiretosSEPARecord>>({
      url: this._path,
      params: {
        loteFicheiro: loteFicheiro,
        liquidado: liquidado
      }
    });
  }

  public getFicheirosEmitidos(tipo: EDebDiretosSEPAFicheiroEmitidosType, search: string, page: number, perpage: number): TServiceQueryResponse<IJsonDebDiretosSEPAFicheirosEmitidosRecord> {
    return this._apiService.get({
      url: `${this._path}/ficheirosemitidos`,
      params: {
        tipo: tipo,
        pesquisa: search,
        pagina: page,
        porpagina: perpage
      }
    });
  }

  public getAllFicheirosEmitidos(search: string, page: number, perpage: number): TServiceQueryResponse<IJsonDebDiretosSEPAFicheirosEmitidosAllRecord> {
    return this._apiService.get({
      url: `${this._path}/ficheirosemitidos-all`,
      params: {
        pesquisa: search,
        pagina: page,
        porpagina: perpage
      }
    });
  }

  public getConfiguracao(): TServiceResponse<IJsonDebDiretosSEPAConfiguracao> {
    return this._apiService.get<IJsonDebDiretosSEPAConfiguracao>({url: `${this._path}/configuracao`});
  }

  public getConfiguracaoGeral(): TServiceResponse<IJsonDebDiretosSEPAConfiguracao> {
    return this._apiService.get<IJsonDebDiretosSEPAConfiguracao>({url: `${this._path}/configuracaogeral`});
  }

  public getConfiguracaoContaCredora(): TServiceResponse<IJsonDebDiretosSEPAConfiguracao> {
    return this._apiService.get<IJsonDebDiretosSEPAConfiguracao>({url: `${this._path}/configuracaocontacredora`});
  }

  public getConfiguracaoClientes(
    page: number,
    perpage: number,
    search: string = '',
    ordena: string = '',
    semAutorizacaoDebitosDiretos: boolean = false
  ): TServiceQueryResponse<IJsonDebDiretosSEPACliente> {
    return this._apiService.get({
      url: `${this._path}/configuracaoclientes`,
      params: {
        pesquisa: search,
        pagina: page,
        porpagina: perpage,
        ordena: ordena,
        semautorizacaodebitosdiretos: semAutorizacaoDebitosDiretos
      }
    });
  }

  public getTotalClientesSemAutorizacaoAnexada(): TServiceResponse<number> {
    return this._apiService.get<number>({url: `${this._path}/totalclientessemautorizacaoanexada`});
  }

  public saveConfiguracaoGeral(config: IJsonDebDiretosSEPAConfiguracao): TServiceResponse<void> {
    return this._apiService.post<void, IJsonDebDiretosSEPAConfiguracao>({url: `${this._path}/configuracaogeral`, body: config});
  }

  public getUserAccess(): TServiceResponse<IJsonDebDiretosSEPAAcesso> {
    return this._apiService.get<IJsonDebDiretosSEPAAcesso>({url: `${this._path}/configuracao/acesso`});
  }

  public uploadUrlDocumentAutorizacao(nConta: string): string {
    return `${this._getPathConfigAutorizacaoConta(nConta)}/upload`;
  }

  public uploadDocumentAutorizacao(file: File, nConta: string): TServiceResponse<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this._apiService.post<void, FormData>({
      url: `${this._getPathConfigAutorizacaoConta(nConta)}/upload`,
      body: formData
    });
  }

  public generateImportUploadUrl(): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/import/upload`, {});
  }

  public getDocumentoAutorizacao(nConta: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: this._getPathConfigAutorizacaoConta(nConta), responseType: 'blob'});
  }

  public deleteDocumentoAutorizacao(nConta: string): TServiceResponse<void> {
    return this._apiService.delete({url: this._getPathConfigAutorizacaoConta(nConta)});
  }

  public gerarNumAutorizacao(nConta: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._getPathConfigAutorizacaoConta(nConta)}/gerar`});
  }

  public temDocumentoAutorizacao(nConta: string): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._getPathConfigAutorizacaoConta(nConta)}/tem-documento-autorizacao`});
  }

  public saveConfiguracaoClientes(list: Array<IJsonDebDiretosSEPACliente>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonDebDiretosSEPACliente>>({url: `${this._path}/configuracao/clientes`, body: list});
  }

  public disponivelParaNovaEmissao(list: number | Array<number>): TServiceResponse<void> {
    if (!isArray(list)) {
      list = [list];
    }
    return this._apiService.post<void, Array<number>>({url: `${this._path}/disponivelParaNovaEmissao`, body: list});
  }

  public enviarEmail(list: number | Array<number>): TServiceResponse<void> {
    if (!isArray(list)) {
      list = [list];
    }
    return this._apiService.post<void, Array<number>>({url: `${this._path}/enviar-emails`, body: list});
  }

  public stopJob(): TServiceResponse<void> {
    return this._apiService.post({url: `${this._path}/stop-job`});
  }

  public getDebDiretosSEPARecordLog(faccbId: number): TServiceResponse<Array<IJsonDebDiretosSEPARecordLog>> {
    return this._apiService.get<Array<IJsonDebDiretosSEPARecordLog>>({url: `${this._path}/logs`, params: {faccbId: faccbId}});
  }

  public getDebDiretosSEPARecordsEmissao(date: TDate): TServiceResponse<Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>> {
    return this._apiService.get<Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>>({url: `${this._path}/emissao/dados`, params: {date: date}});
  }

  public getDebDiretosSEPAErrosLiquidacao(): TServiceResponse<Array<IJsonDebDiretosSEPAErroLiquidacaoRecord>> {
    return this._apiService.get<Array<IJsonDebDiretosSEPAErroLiquidacaoRecord>>({url: `${this._path}/erros-liquidacao`});
  }

  public getDebDiretosSEPAEmissaoPreload(): TServiceResponse<IJsonDebDiretosSEPAEmissaoPreload> {
    return this._apiService.get<IJsonDebDiretosSEPAEmissaoPreload>({url: `${this._path}/emissao/preload`});
  }

  public downloadFicheiroXml(loteFicheiro: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/emissao/download`, responseType: 'blob', params: {loteFicheiro: loteFicheiro}});
  }

  public getNextDiaUtilByData(date: TDate): TServiceResponse<TDate> {
    return this._apiService.get<TDate>({url: `${this._path}/nextDiaUtilByData`, params: {date: date}});
  }

  public emissaoGerarFicheirosXML(data: IJsonEmissaoGeraFicheirosXMLData): TServiceResponse<IJsonGerarFicheirosXMLResults> {
    return this._apiService.post<IJsonGerarFicheirosXMLResults, IJsonEmissaoGeraFicheirosXMLData>({
      url: `${this._path}/emissao/gerar`,
      body: data,
      reportExceptions: false
    });
  }

  public emissaoNotificarDevedores(list: Array<string>): TServiceResponse<void> {
    return this._apiService.post<void, Array<string>>({
      url: `${this._path}/emissao/notificar`,
      body: list
    });
  }

  public getListagemUrl(dataLiquidacaoDe: TDate, dataLiquidacaoAte: TDate, reportName: string, loteFicheiro: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report/pdf`, {
      dataLiquidacaoDe: dataLiquidacaoDe,
      dataLiquidacaoAte: dataLiquidacaoAte,
      reportName: reportName,
      loteFicheiro: loteFicheiro
    });
  }

  public getListagemClienteUrl(nConta: string, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report/cliente/pdf`, {reportName: reportName, nConta: nConta});
  }

  public doImportFile(): TServiceResponse<IJsonDebDiretosSEPAImportResults> {
    return this._apiService.post<IJsonDebDiretosSEPAImportResults, void>({url: `${this._path}/import/run`, reportExceptions: false});
  }

  public processImportFile(): TServiceResponse<IJsonDebDiretosSEPAImportProcessResults> {
    return this._apiService.post<IJsonDebDiretosSEPAImportProcessResults, void>({url: `${this._path}/import/process`, reportExceptions: false});
  }

  public changeCodMeioPagamento(oldCod: number, newCod: number): TServiceResponse<void> {
    return this._apiService.post<void, number>({
      url: `${this._path}/configuracao/meio-pagamento/${oldCod}/change`,
      body: newCod,
      reportExceptions: false
    });
  }

  public changeIbanMeioPagamento(codMeioPagamento: number, newIban: string): TServiceResponse<void> {
    return this._apiService.post<void, string>({
      url: `${this._path}/configuracao/meio-pagamento/${codMeioPagamento}/iban/change`,
      body: newIban
    });
  }

  public getClientesPorNotificar(loteFicheiro: string): TServiceResponse<Array<IJsonDebDiretosSEPANotificarItem>> {
    return this._apiService.get<Array<IJsonDebDiretosSEPANotificarItem>>({url: `${this._path}/clientes-notificar`, params: {loteFicheiro: loteFicheiro}});
  }

  public notificarClientesPorNotificar(loteFicheiro: string, list: Array<string>): TServiceResponse<void> {
    return this._apiService.post<void, Array<string>>({
      url: `${this._path}/clientes-notificar`,
      params: {loteFicheiro: loteFicheiro},
      body: list
    });
  }

  public enviarEmailDDSepaCliente(nConta: string, email: string, reportName: string): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/envair-email-cliente`, params: {nConta: nConta, email: email, reportName: reportName}});
  }

  public totalDocumentoPorLiquidarAteData(nConta: string, data: TDate): TServiceResponse<number> {
    return this._apiService.get<number>({url: `${this._path}/total-docs-aberto-data`, params: {nConta: nConta, date: data}});
  }

  public anularLoteFicheiro(loteFicheiro: string): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/anular-lote`,
      params: {loteFicheiro: loteFicheiro}
    });
  }

  private _getPathConfigAutorizacaoConta(nConta: string): string {
    return `${this._path}/configuracao/autorizacao/${nConta}`;
  }
}
