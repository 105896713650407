import {Component, Injector} from '@angular/core';
import {isNumber} from 'pl-comps-angular';
import {ECampoCalculadoME} from '../../../../jsonDocContabilidade.interface';
import {EDocContabilidadeField} from '../../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../../docsContabilidade.interface';
import {DocContabilidadeFieldInputNumber} from '../docContabilidade.field.input.number';

@Component({
  selector: 'doccontabilidade-valorme',
  templateUrl: './docContabilidade.valorME.component.html'
})
export class DocContabilidadeValorMEComponent extends DocContabilidadeFieldInputNumber {
  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.VALOR_ME, _injector, undefined, ECampoCalculadoME.ValorME);
  }

  public modelChanged(value: number): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.valorME
    });
  }

  protected _decimais(): number {
    let decimais: number = this.docContabilidade.nDecimaisValorME;
    if (!isNumber(decimais)) {
      decimais = this.configurations.contabilidade.decimais.valorME;
    }
    return decimais;
  }

  protected _disabled(linha: IDocContabilidadeLinha): boolean {
    return super._disabled(linha) || (this._isMoedaPresent() && this._isLocked(ECampoCalculadoME.ValorME));
  }

  protected _disallowInput(): boolean {
    return false;
  }

  protected _hasGroup(): boolean {
    return true;
  }
}
