import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_REG_IVA} from './regIVA.datasource.interface';

export const DATA_SOURCE_REG_IVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_REG_IVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 1, name: 'regIRC.data.normal'},
    {value: 2, name: 'regIRC.data.isencao'},
    {value: 3, name: 'regIRC.data.pequenosRetalhistas'},
    {value: 4, name: 'regIRC.data.regimeNormalBensSegMao'},
    {value: 5, name: 'regIRC.data.regimeNormalRevendaCombustiveis'},
    {value: 6, name: 'regIRC.data.regimeNormalIvaCaixa'}
  ]
});
