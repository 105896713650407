import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonEFaturaConfigDocs, IJsonEFaturaConfigDocsCabecalho, IJsonEFaturaConfigDocsSupplier} from './jsonEFaturaConfigDocs.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EFaturaConfigDocsService {
  private readonly _path: string;

  constructor(protected readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/efaturaconfigdocs`;
  }

  public getEFaturaConfigsDocs(): TServiceResponse<IJsonEFaturaConfigDocs> {
    return this._apiService.get<IJsonEFaturaConfigDocs>({url: `${this._path}/configdocs`});
  }

  public getEFaturaConfigsDocsSupplier(nif: string): TServiceResponse<IJsonEFaturaConfigDocsSupplier> {
    return this._apiService.get<IJsonEFaturaConfigDocsSupplier>({url: `${this._path}/configdocs/${nif}`});
  }

  public saveEFaturaConfigDocs(configDocs: IJsonEFaturaConfigDocs): TServiceResponse<IJsonEFaturaConfigDocs> {
    this._beforeSave(configDocs);
    return this._apiService.post<IJsonEFaturaConfigDocs>({url: `${this._path}/configdocs`, body: configDocs});
  }

  public saveEFaturaConfigDocsSupplier(nif: string, nomeFornecedor: string, configDocsSupplier: IJsonEFaturaConfigDocsSupplier): TServiceResponse<IJsonEFaturaConfigDocsSupplier> {
    this._beforeSave(configDocsSupplier);
    return this._apiService.post<IJsonEFaturaConfigDocsSupplier>({url: `${this._path}/configdocs/${nif}`, body: configDocsSupplier, params: {nomefornecedor: nomeFornecedor}});
  }

  private _beforeSave(value: IJsonEFaturaConfigDocsCabecalho): void {
    if (!value.docConferencia) {
      value.docConferencia = 0;
    }
    if (!value.fatura) {
      value.fatura = 0;
    }
    if (!value.faturaEstrangeiro) {
      value.faturaEstrangeiro = 0;
    }
    if (!value.faturaRecibo) {
      value.faturaRecibo = 0;
    }
    if (!value.faturaSimplificada) {
      value.faturaSimplificada = 0;
    }
    if (!value.notaCredito) {
      value.notaCredito = 0;
    }
    if (!value.notaDebito) {
      value.notaDebito = 0;
    }
    if (!value.reciboVerde) {
      value.reciboVerde = 0;
    }
  }
}
