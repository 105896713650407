import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {IntegracaoSalariosMultiComponent} from './components/integracaoSalariosMulti.module.component';
import {MODULE_NAME_INTEGRACAO_SALARIOS_MULTI} from './integracaoSalariosMulti.module.interface';

export const MODULE_INTEGRACAO_SALARIOS_MULTI: IModuleDefinition = {
  name: MODULE_NAME_INTEGRACAO_SALARIOS_MULTI,
  state: {
    url: `/${MODULE_NAME_INTEGRACAO_SALARIOS_MULTI}`,
    component: IntegracaoSalariosMultiComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.integracaoSalariosMulti'
    }
  }
};
