import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_NOTIFICATION_CENTER} from './notificationCenter.module.interface';
import {NotificationCenterModuleComponent} from './components/notificationCenter.module.component';

export const MODULE_NOTIFICATION_CENTER: IModuleDefinition = {
  name: MODULE_NAME_NOTIFICATION_CENTER,
  state: {
    url: '/notifications',
    component: NotificationCenterModuleComponent,
    data: {
      pageTitle: 'notificationcenter.title'
    },
    params: {
      newNotificationsIds: {
        type: 'any',
        value: []
      }
    }
  }
};
