<div class="predefinidocontabcab">
  <pl-navpill (evtSelected)="changedPanel($event)">
    <pl-navpill-panel *ngFor="let panel of panels; index as i" [id]="panel.name" [caption]="panel.caption" [captionClass]="getPanelClass(panel, i)">
      <div *plNavPillPanelContent>
        <div class="row">
          <div class="col-12">
            <h5 class="mb-4" [translate]="panel.caption"></h5>
          </div>
        </div>

        <div class="row">
          <div class="col-12 entity-detail-form">
            <pl-group *ngIf="panel.fields.length">
              <edit>
                <h6 class="mb-2" [translate]="'predefinidocontabcab.titles.fieldProperties'"></h6>
              </edit>
            </pl-group>

            <ng-container *ngFor="let field of panel.fields">
              <pl-group *ngIf="isFieldVisible(field)">
                <label [translate]="field.caption"></label>
                <edit>
                  <pl-edit
                    [type]="field.type"
                    [attrName]="field.name"
                    [model]="cabecalho[panel.name][field.name]"
                    (modelChange)="cabecalho[panel.name][field.name] = $event; fieldChanged(field)"
                    [properties]="field.properties">
                  </pl-edit>
                </edit>
              </pl-group>
            </ng-container>

            <hr *ngIf="panel.fields.length" />

            <pl-group>
              <edit>
                <h6 class="mb-2" [translate]="'predefinidocontabcab.titles.visualProperties'"></h6>
              </edit>
            </pl-group>

            <pl-group *ngFor="let field of visualProperties">
              <label [translate]="field.caption"></label>
              <edit>
                <pl-edit
                  [type]="field.type"
                  [attrName]="field.name"
                  [model]="cabecalho[panel.name].visibilidadeProperties[field.name]"
                  (modelChange)="fieldValueChanged(panel.name, field.name, $event)">
                </pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </pl-navpill-panel>
  </pl-navpill>

  <hr />

  <div>
    <h5><span [translate]="'predefinidoscontabilidade.titles.legend'"></span>:</h5>
    <ul class="list-legend predefinidocontabcab">
      <li class="predefinidocontabcab-panel complete"><span> - </span><span [translate]="'predefinidoscontabilidade.titles.verified'"></span></li>
      <li class="predefinidocontabcab-panel"><span> - </span><span [translate]="'predefinidoscontabilidade.titles.notVerified'"></span></li>
    </ul>
  </div>
</div>
