<div class="modelo22-anexoE">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh" [plPromise]="promise">
      <pl-navigator-item caption="modelo22.anexoE.quadro03.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoE.quadro03.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"></div>
              <div class="col-lg-3">
                <label [translate]="'modelo22.anexoE.quadro03.rend'"></label>
              </div>
              <div class="col-lg-1"></div>
              <div class="col-lg-3">
                <label [translate]="'modelo22.anexoE.quadro03.matcole'"></label>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field1-16'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo1"
                    [model]="anexoE.quadro03.campo01"
                    (modelChange)="anexoE.quadro03.campo01 = $event; calcAnexoE('16')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,04 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">16</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo16" [model]="anexoE.quadro03.campo16" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field2-17'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo2"
                    [model]="anexoE.quadro03.campo02"
                    (modelChange)="anexoE.quadro03.campo02 = $event; calcAnexoE('17')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,04 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">17</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo17" [model]="anexoE.quadro03.campo17" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field3-18'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo3"
                    [model]="anexoE.quadro03.campo03"
                    (modelChange)="anexoE.quadro03.campo03 = $event; calcAnexoE('18')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,75 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">18</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo18" [model]="anexoE.quadro03.campo18" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field4-19'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo4"
                    [model]="anexoE.quadro03.campo04"
                    (modelChange)="anexoE.quadro03.campo04 = $event; calcAnexoE('19')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,10 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">19</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo19" [model]="anexoE.quadro03.campo19" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field5-20'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo5"
                    [model]="anexoE.quadro03.campo05"
                    (modelChange)="anexoE.quadro03.campo05 = $event; calcAnexoE('20')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,10 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">20</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo20" [model]="anexoE.quadro03.campo20" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.table.desc'"></label></div>
              <div class="col-lg-7">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition621" cgDxDataGridInstanceName="campo0621List" [dataSource]="dataGridDefinition621.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtn621'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoEQuadro3(item.rowIndex)" plPromise>
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>

                <div class="mt-3">
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro03SomaControlo" cgDxDataGridInstanceName="Modelo22AnexoEQuadro03SomaControlo" [dataSource]="anexoEQuadro3TotArray">
                  </dx-data-grid>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field7-22'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">7</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo7"
                    [model]="anexoE.quadro03.campo07"
                    (modelChange)="anexoE.quadro03.campo07 = $event; calcAnexoE('22')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">22</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo22" [model]="anexoE.quadro03.campo22" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field8-23'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">8</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo8"
                    [model]="anexoE.quadro03.campo08"
                    (modelChange)="anexoE.quadro03.campo08 = $event; calcAnexoE('23')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">23</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo23" [model]="anexoE.quadro03.campo23" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field9-24'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">9</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo9"
                    [model]="anexoE.quadro03.campo09"
                    (modelChange)="anexoE.quadro03.campo09 = $event; calcAnexoE('24')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">24</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo24" [model]="anexoE.quadro03.campo24" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field10-25'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo10"
                    [model]="anexoE.quadro03.campo10"
                    (modelChange)="anexoE.quadro03.campo10 = $event; calcAnexoE('25')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">25</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo25" [model]="anexoE.quadro03.campo25" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field11-26'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo11"
                    [model]="anexoE.quadro03.campo11"
                    (modelChange)="anexoE.quadro03.campo11 = $event; calcAnexoE('26')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">26</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo26" [model]="anexoE.quadro03.campo26" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field12-27'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">12</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo12"
                    [model]="anexoE.quadro03.campo12"
                    (modelChange)="anexoE.quadro03.campo12 = $event; calcAnexoE('27')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">27</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo27" [model]="anexoE.quadro03.campo27" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field13-28'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">13</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo13"
                    [model]="anexoE.quadro03.campo13"
                    (modelChange)="anexoE.quadro03.campo13 = $event; calcAnexoE('28')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 1,00 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">28</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo28" [model]="anexoE.quadro03.campo28" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field32-33'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">32</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo32"
                    [model]="anexoE.quadro03.campo32"
                    (modelChange)="anexoE.quadro03.campo32 = $event; calcAnexoE('33')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,35 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">33</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo33" [model]="anexoE.quadro03.campo33" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field35-36'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">35</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo35"
                    [model]="anexoE.quadro03.campo35"
                    (modelChange)="anexoE.quadro03.campo35 = $event; calcAnexoE('36')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,50 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">36</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo36" [model]="anexoE.quadro03.campo36" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field14-29'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">14</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo14"
                    [model]="anexoE.quadro03.campo14"
                    (modelChange)="anexoE.quadro03.campo14 = $event; calcAnexoE('29')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,04 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">29</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo29" [model]="anexoE.quadro03.campo29" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field15-30'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">15</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo15"
                    [model]="anexoE.quadro03.campo15"
                    (modelChange)="anexoE.quadro03.campo15 = $event; calcAnexoE('30')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">30</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo30" [model]="anexoE.quadro03.campo30" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field37-38'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">37</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo37"
                    [model]="anexoE.quadro03.campo37"
                    (modelChange)="anexoE.quadro03.campo37 = $event; calcAnexoE('38')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,95 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">38</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo38" [model]="anexoE.quadro03.campo38" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field39-46'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">39</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo39"
                    [model]="anexoE.quadro03.campo39"
                    (modelChange)="anexoE.quadro03.campo39 = $event; calcAnexoE('46')"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-1"><label>X 0,15 =</label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">46</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo46" [model]="anexoE.quadro03.campo46" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoE.quadro03.field40'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">40</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo40" [model]="anexoE.quadro03.campo40" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
              <div class="col-lg-1"></div>
              <div class="col-lg-3"></div>
            </div>

            <div class="row mt-2">
              <div class="col text-right"><label [translate]="'modelo22.anexoE.quadro03.field41'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">41</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo41" [model]="anexoE.quadro03.campo41" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoE.quadro03.field34'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">34</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo34"
                    [model]="anexoE.quadro03.campo34"
                    (modelChange)="anexoE.quadro03.campo34 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoE.quadro03.field31'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">31</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoEQuadro03Campo31"
                    [model]="anexoE.quadro03.campo31"
                    (modelChange)="anexoE.quadro03.campo31 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoE.quadro03.field42'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">42</div>
                  <pl-edit type="currency" attrName="anexoEQuadro03Campo42" [model]="anexoE.quadro03.campo42" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
                <span [translate]="'modelo22.anexoE.quadro03.field3142desc'"></span>
              </div>
            </div>
          </div>

          <div class="mt-2"><label [translate]="'modelo22.anexoE.quadro03.tdesc'"></label></div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoE.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoE.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <label [translate]="'modelo22.anexoE.quadro04.campo43'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">43</div>
              <pl-edit type="date" attrName="anexoEQuadro04Campo43" [model]="anexoE.quadro04" (modelChange)="anexoE.quadro04 = $event; emitModelo22()"></pl-edit>
            </div>
          </section>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
