<div class="cg-app-status-disconnected">
  <div class="toast cg-toast fade show toast-disconnected" role="alert" aria-live="assertive" aria-atomic="true" [class.show]="showToast" [class.mb-3]="showToast">
    <div class="toast-header gap-2">
      <i cgcIcon="fa-wifi"></i>
      <strong [translate]="disconnected ? 'application.disconnected.title' : 'application.disconnected.restoredTitle'"></strong>
      <div class="ms-auto" [ngSwitch]="disconnected">
        <i *ngSwitchCase="true" cgcIcon="fa-circle-o-notch fa-spin" style="font-size: 1.1rem"></i>
        <button *ngSwitchCase="false" type="button" class="btn-close" (click)="hide()"></button>
      </div>
    </div>

    <div class="toast-body">
      <div [translate]="disconnected ? (!offline ? 'application.disconnected.description' : 'application.disconnected.offline') : 'application.disconnected.restoredDescription'"></div>
      <div *ngIf="disconnected" class="mt-1 text-right">
        <small [translate]="'application.maintenance.lastChecked'" [translateParams]="{time: lastChecked | cgcTime: 'HH:mm'}"></small>
      </div>
    </div>

    <div class="cg-toast-backdrop" [class.bg-danger]="disconnected" [class.bg-success]="!disconnected"></div>
  </div>
</div>
