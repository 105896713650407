import {APP_INITIALIZER, EnvironmentProviders, Injector, makeEnvironmentProviders} from '@angular/core';
import {configApplication} from './app.config';
import {configCacheBuster} from './cachebuster.config';
import {configDataSources} from './datasources.config';
import {configEdits} from './edits.config';
import {configEntitiesMaps} from './entities.maps.config';
import {configEntities} from './entities.config';
import {configModules} from './modules.config';
import {configNgBootstrapModalConfig, configNgBootstrapTooltipConfig} from './ng-bootstrap.config';
import {configReportsMaps} from './reports.maps.config';
import {configReports} from './reports.config';
import {configRuntime} from './runtime.config';
import {configTests} from './tests.config';
import {configUIRouterApp} from './uirouter.config';
import {isTest} from './constants';

async function init(injector: Injector): Promise<void> {
  await Promise.allSettled([configRuntime(injector), configApplication(injector)]);

  configNgBootstrapModalConfig(injector);
  configNgBootstrapTooltipConfig(injector);
  configCacheBuster(injector);
  configEdits(injector);
  configModules(injector);
  configEntities(injector);
  configEntitiesMaps(injector);
  configDataSources(injector);
  configReports(injector);
  configReportsMaps(injector);
  configUIRouterApp(injector);

  if (isTest()) {
    configTests(injector);
  }
}

export function provideAppInitialization(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Injector],
      useFactory: (injector: Injector) => () => init(injector)
    }
  ]);
}
