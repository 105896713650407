import {Component, Injector} from '@angular/core';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-phone',
  templateUrl: './edit.phone.component.html',
  standalone: false,
  exportAs: 'cgcEditPhone'
})
export class PlEditPhoneComponent extends PlEditInputTypeComponent<string> {
  constructor(protected readonly _injector: Injector) {
    super('text', _injector);
  }

  public getReadableValue(value: string): string {
    if (!value) {
      return value;
    }
    let result = '';
    let count = 0;
    for (let i = value.length; i > 0; i--) {
      let char = value[i - 1];
      count++;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (count % 3 === 0) {
        char = ` ${char}`;
      }
      result = char + result;
    }
    return result.trim();
  }
}
