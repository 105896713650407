export enum ETipoAnexo {
  Continente,
  Madeira,
  Acores,
  DecRecapitulativa,
  Campo40,
  Campo41
}

export const DATA_SOURCE_NAME_TIPOS_ANEXO = 'tiposAnexo';
