import {Component, Injector, OnInit} from '@angular/core';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IPlFormDefinition, isDefinedNotNull} from 'pl-comps-angular';
import {IJsonTxtar} from '../../jsonTxtar.entity.interface';

@Component({
  selector: 'txtar-edit',
  templateUrl: './txtar.entity.edit.component.html'
})
export class TxtarEntityEditComponent extends ModuloEntityDetailComponent<IJsonTxtar> implements OnInit {
  public definition: IPlFormDefinition;
  public isDetailState: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isDetailState = this.type === EEntityStateDetailType.DETAIL;
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.isDetailState = stateType === EEntityStateDetailType.DETAIL;
    const entityDef = {...this.entity[stateType].definition};
    ['nArtigo', 'nTexto'].forEach((field) => {
      const item = entityDef.fields.find((f) => f.name === field);
      if (isDefinedNotNull(item)) {
        item.properties.disabled = stateType !== EEntityStateDetailType.NEW;
      }
    });

    this.definition = entityDef;
    return super.onUpdate(stateType);
  }
}
