import {Component, EventEmitter, Input, Output} from '@angular/core';
import {configErpBooleanToValue} from '../../../../common/data';
import {ETypeKind} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {IUserInstallationPolicyConfigERP} from '../installations/utilizadores.installation.interface';

@Component({
  selector: 'cg-user-accesses-item',
  templateUrl: './utilizadores.accesses.item.component.html'
})
export class CGUserAccessesItemComponent {
  @Input() public attrName: string;
  @Input() public config: IUserInstallationPolicyConfigERP;
  @Input() public label: string;
  @Input() public disabled: boolean;
  @Output() public readonly evtValueChanged: EventEmitter<unknown>;

  public readonly typeKind: typeof ETypeKind;

  constructor() {
    this.evtValueChanged = new EventEmitter<unknown>();
    this.typeKind = ETypeKind;
    this.label = '';
  }

  public changedValue(value: boolean): void {
    this.evtValueChanged.emit(configErpBooleanToValue(this.config, value));
  }
}
