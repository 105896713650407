import moment from 'moment';
import {firstValueFrom} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {EDelphiNumberTypes, EMonth, isUndefinedOrNull} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {
  CONTA_CLIENTES_MAX,
  CONTA_CLIENTES_MIN,
  CONTA_FORNECEDORES_MAX,
  CONTA_FORNECEDORES_MIN,
  EModuleTipo,
  IDocComercialEstatisticaItem,
  IDocsComerciasEstatisticasFilters,
  MAX_NCCUSTO,
  MAX_STRING
} from './docsComerciaisEstatisticas.module.interface';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IDocsComerciaisEstatisticasConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {IApiQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocsComerciaisEstatisticasService extends ConfigOptionsInstanceService<boolean, IDocsComerciaisEstatisticasConfigOptions, IConfigOptionsGroupErp> {
  private readonly _path: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService
  ) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS);
    this._path = `${this._apiService.path.restapi}/docscomerciaisestatisticas`;
  }

  public getEstatisticasArtigos(filters: IDocsComerciasEstatisticasFilters, moduleType: EModuleTipo): Promise<IApiQueryResponse<IDocComercialEstatisticaItem>> {
    return this._apiService
      .get<IApiQueryResponse<IDocComercialEstatisticaItem>>({
        url: this._path,
        params: this._prepareFilters(filters, moduleType)
      })
      .then((response) => response.body);
  }

  public getExcelUrl(params: IDocsComerciasEstatisticasFilters, moduleType: EModuleTipo): Promise<string> {
    return firstValueFrom(buildSessionUrlWithParams(`${this._path}/report`, this._prepareFilters(params, moduleType)));
  }

  public getExcel(params: IDocsComerciasEstatisticasFilters, moduleType: EModuleTipo): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/report`,
      responseType: 'blob',
      params: this._prepareFilters(params, moduleType)
    });
  }

  private _prepareFilters(filters: IDocsComerciasEstatisticasFilters, moduleType: EModuleTipo): IDocsComerciasEstatisticasFilters {
    if (isUndefinedOrNull(filters.ncontade)) {
      filters.ncontade = this._getNContaMinMaxConst(moduleType, 'MIN');
    }

    if (isUndefinedOrNull(filters.ncontaate)) {
      filters.ncontaate = this._getNContaMinMaxConst(moduleType, 'MAX');
    }

    if (isUndefinedOrNull(filters.nartigode)) {
      filters.nartigode = '';
    }

    if (isUndefinedOrNull(filters.nartigoate)) {
      filters.nartigoate = MAX_STRING;
    }

    if (isUndefinedOrNull(filters.tipoartigode)) {
      filters.tipoartigode = 0;
    }

    if (isUndefinedOrNull(filters.tipoartigoate)) {
      filters.tipoartigoate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.ngrfamiliade)) {
      filters.ngrfamiliade = 0;
    }

    if (isUndefinedOrNull(filters.ngrfamiliaate)) {
      filters.ngrfamiliaate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.nfamiliade)) {
      filters.nfamiliade = 0;
    }

    if (isUndefinedOrNull(filters.nfamiliaate)) {
      filters.nfamiliaate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.nsubfamiliade)) {
      filters.nsubfamiliade = 0;
    }

    if (isUndefinedOrNull(filters.nsubfamiliaate)) {
      filters.nsubfamiliaate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.classede)) {
      filters.classede = 0;
    }

    if (isUndefinedOrNull(filters.classeate)) {
      filters.classeate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.categoriade)) {
      filters.categoriade = 0;
    }

    if (isUndefinedOrNull(filters.categoriaate)) {
      filters.categoriaate = EDelphiNumberTypes.MaxInteger;
    }

    if (isUndefinedOrNull(filters.datade)) {
      filters.datade = moment();
    }

    if (isUndefinedOrNull(filters.dataate)) {
      filters.dataate = moment().month(EMonth.December).endOf('month');
    }

    if (isUndefinedOrNull(filters.nvendedorde)) {
      filters.nvendedorde = 0;
    }

    if (isUndefinedOrNull(filters.nvendedorate)) {
      filters.nvendedorate = EDelphiNumberTypes.MaxSmallInt;
    }

    if (isUndefinedOrNull(filters.codzonade)) {
      filters.codzonade = 0;
    }

    if (isUndefinedOrNull(filters.codzonaate)) {
      filters.codzonaate = EDelphiNumberTypes.MaxSmallInt;
    }

    if (isUndefinedOrNull(filters.ccustode)) {
      filters.ccustode = '';
    }

    if (isUndefinedOrNull(filters.ccustoate)) {
      filters.ccustoate = MAX_NCCUSTO;
    }

    if (isUndefinedOrNull(filters.nrefprocessode)) {
      filters.nrefprocessode = '';
    }

    if (isUndefinedOrNull(filters.nrefprocessoate)) {
      filters.nrefprocessoate = MAX_STRING;
    }

    if (isUndefinedOrNull(filters.socommovimstock)) {
      filters.socommovimstock = false;
    }

    if (isUndefinedOrNull(filters.docfalist)) {
      filters.docfalist = '';
    }

    if (isUndefinedOrNull(filters.ididechavde)) {
      filters.ididechavde = '';
    }

    if (isUndefinedOrNull(filters.ididechavate)) {
      filters.ididechavate = MAX_STRING;
    }

    if (isUndefinedOrNull(filters.cliclde)) {
      filters.cliclde = '';
    }

    if (isUndefinedOrNull(filters.cliclate)) {
      filters.cliclate = MAX_STRING;
    }

    return filters;
  }

  private _getNContaMinMaxConst(moduleType: EModuleTipo, c: 'MIN' | 'MAX'): string {
    return c === 'MIN' ? (moduleType === EModuleTipo.VENDAS ? CONTA_CLIENTES_MIN : CONTA_FORNECEDORES_MIN) : moduleType === EModuleTipo.VENDAS ? CONTA_CLIENTES_MAX : CONTA_FORNECEDORES_MAX;
  }
}
