import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonVendedor} from './jsonVendedor.entity.interface';
import {ENTITY_NAME_VENDEDORES} from './vendedores.entity.interface';

export const ENTITY_VENDEDORES: IEntityDefinition<IJsonVendedor> = {
  name: ENTITY_NAME_VENDEDORES,
  roles: [ROLE.ERP, ROLE.ATIVOS],
  searchPlaceholder: 'vendedores.pesquisa',
  metadata: {
    keyName: 'nVendedor',
    fields: [
      {
        name: 'nVendedor',
        width: '120px',
        caption: 'vendedores.fields.nVendedor',
        placeholder: 'vendedores.fields.nVendedorPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'nome', caption: 'vendedores.fields.nome', placeholder: 'vendedores.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'nVendedor,nome'
  },
  autocomplete: {
    rowTemplate: '{{nVendedor}} - {{nome}}',
    output: 'nome',
    searchFields: 'nVendedor,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.vendedores'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.vendedores'
      }
    }
  }
};
