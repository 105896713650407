import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'importadorEfatura-lancarDocsEmSerie-modal',
  templateUrl: './importadorEfatura.lancarDocsEmSerie.modal.component.html'
})
export class ImportadorEfaturaLancarDocsEmSerieModalComponent extends CGModalComponent<void> {
  @Input() public naoLancadosCount: number;
  @Input() public lancadosCount: number;
  @Input() public processing: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.naoLancadosCount = 0;
    this.lancadosCount = 0;
    this.processing = true;
  }
}
