import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IntegracaoSalariosService} from '../../integracaoSalarios.module.service';
import {EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND, IIntegracaoSalariosError} from '../../integracaoSalarios.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {EStatusCode} from '../../../../../../config/constants';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'integracao-salarios-errors-modal',
  templateUrl: './integracaoSalarios.errors.modal.component.html'
})
export class IntegracaoSalariosErrorsModalComponent extends CGModalComponent<void> {
  @Input() public nEmpresa: string;

  public readonly dataGridDefinition: IDevExpressDataGrid<IIntegracaoSalariosError>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _integracaoSalariosService: IntegracaoSalariosService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'valor', dataType: 'double', caption: 'integracaoSalarios.fields.valor'},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error'},
        {dataField: 'codEmpregado', dataType: 'number', caption: 'integracaoSalarios.fields.codEmp'},
        {dataField: 'tipoVencimentosEncargos', dataType: 'string', caption: 'integracaoSalarios.fields.tipoVencimentosEncargos'},
        {dataField: 'data', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'nCodAbdesc', dataType: 'string', caption: 'integracaoSalarios.fields.nCodAbdesc'}
      ],
      dataSource: new CustomStore({
        load: () => this._getIntegSalariosErrorsTableSource()
      }),
      remoteOperations: false
    };
  }

  private _getIntegSalariosErrorsTableSource(): Promise<Array<IIntegracaoSalariosError>> {
    return this._integracaoSalariosService.getIntegSalariosErrors(this.nEmpresa).catch((reason) => {
      if (isDefinedNotNull(reason)) {
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        if (exception.status === EStatusCode.InternalServerError && exception.class === EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND) {
          this._cgModalService.showOkCancelVanilla('integracaoSalarios.jobTimeoutModalTitle', 'integracaoSalarios.jobTimeoutModalMessage', {
            size: 'md',
            showCancelBtn: false,
            backdrop: 'static',
            keyboard: false
          });
        } else {
          this._plAlertService.error(exception.message);
        }
      }
      return [];
    });
  }
}
