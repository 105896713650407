import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_ORIGEM_DATA_VENCIMENTO} from './origemDataVencimento.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_ORIGEM_DATA_VENCIMENTO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ORIGEM_DATA_VENCIMENTO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'origemDataVencimento.data.tabela'},
    {value: 1, name: 'origemDataVencimento.data.condicaoPagamento'}
  ]
});
