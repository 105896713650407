<div class="contabilidadedigital-genericviewer contabilidadedigital-flatviewer" [ngSwitch]="loading || !!promise">
  <div class="contabilidadedigital-genericviewer-loading contabilidadedigital-flatviewer-loading" *ngSwitchCase="true">
    <i class="fa fa-spin fa-circle-o-notch" aria-hidden="true"></i>
  </div>

  <div class="contabilidadedigital-flatviewer-content" *ngSwitchCase="false">
    <div class="contabilidadedigital-genericviewer-current-folder contabilidadedigital-flatviewer-current-folder" *ngIf="hideBreadcrumbs !== true && currentFolderPath.length > 1">
      <nav aria-label="contabilidadedigital-genericviewer-current-folder">
        <ol class="breadcrumb">
          <li class="contabilidadedigital-flatviewer-current-folder-back" (click)="setSelectedView(currentFolderPath[currentFolderPath.length - 2].folderID)">
            <i class="fa fa-arrow-left" aria-hidden="true" *ngIf="currentFolderPath.length > 1"></i>
          </li>
          <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-item" *ngFor="let folderPath of currentFolderPath">
            <button type="button" class="btn btn-link" (click)="setSelectedView(folderPath.folderID)">{{ folderPath.name }}</button>
          </li>
        </ol>
      </nav>
    </div>

    <div class="contabilidadedigital-flatviewer-folders" *ngIf="selectedView?.folders?.length">
      <!--<div class="contabilidadedigital-flatviewer-title contabilidadedigital-flatviewer-folders-title" [translate]="'arquivodigital.flatviewer.titles.folders'"></div>-->

      <div class="contabilidadedigital-flatviewer-item contabilidadedigital-flatviewer-folder" *ngFor="let folderItem of selectedView?.folders" (click)="selectView(folderItem)">
        <i
          class="fa fa-fw contabilidadedigital-flatviewer-icon contabilidadedigital-flatviewer-folder-icon"
          aria-hidden="true"
          [ngClass]="showFiles && (folderItem.hasSubFiles || folderItem.files.length > 0) ? 'fa-folder' : 'fa-folder-o'">
        </i>

        <span
          class="contabilidadedigital-flatviewer-title contabilidadedigital-flatviewer-folder-title"
          [class.selected]="folderItem === selectedView"
          [attr.title]="folderItem.name | translate"
          [translate]="folderItem.name">
        </span>
      </div>
    </div>

    <div class="contabilidadedigital-flatviewer-files" *ngIf="showFiles && selectedView?.files?.length">
      <div class="contabilidadedigital-flatviewer-title contabilidadedigital-flatviewer-files-title" [translate]="'arquivodigital.flatviewer.titles.files'"></div>

      <div class="contabilidadedigital-flatviewer-item contabilidadedigital-flatviewer-file" *ngFor="let fileItem of selectedView?.files">
        <i class="fa fa-fw fa-file-pdf-o contabilidadedigital-flatviewer-icon contabilidadedigital-flatviewer-file-icon" aria-hidden="true"></i>
        <span
          class="contabilidadedigital-flatviewer-title contabilidadedigital-flatviewer-file-title"
          [class.selected]="fileItem === selectedFile"
          [attr.title]="fileItem.advancedDescription | translate"
          [translate]="advancedDescription ? fileItem.advancedDescription : fileItem.nome"
          (click)="selectFile(fileItem)">
        </span>
      </div>
    </div>

    <div class="contabilidadedigital-flatviewer-content-loading" *ngIf="loadingView">
      <div class="contabilidadedigital-genericviewer-loading contabilidadedigital-flatviewer-loading">
        <i class="fa fa-spin fa-circle-o-notch" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
