import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {ETipoEscolhaPenhoras} from '../../rhPenhoras.entity.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IJsonRHPenhoras} from '../../jsonRHPenhoras.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';

@Component({
  selector: 'rhpenhoras-list',
  templateUrl: './rhPenhoras.entity.list.component.html'
})
export class RhPenhorasListComponent extends ModuloEntityListComponent<IJsonRHPenhoras> implements OnInit {
  private readonly _dtTiposEscolha: Array<IDataSourceItem<ETipoEscolhaPenhoras>>;
  private _tiposEscolha: Array<IPlToolbarMenuItem>;
  private _defaultTipoEscolha: number;
  private _mnuTipoEscolha: IPlToolbarItem;
  private _selectedTipoEscolha: IPlToolbarMenuItem;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._tiposEscolha = [];
    this._dtTiposEscolha = [
      {
        value: ETipoEscolhaPenhoras.Ativas,
        name: 'Ativas'
      },
      {
        value: ETipoEscolhaPenhoras.SuspensasLiquidadas,
        name: 'Suspensas/Liquidadas'
      },
      {
        value: ETipoEscolhaPenhoras.Todas,
        name: 'Todas'
      }
    ];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._defaultTipoEscolha = ETipoEscolhaPenhoras.Ativas;
    this._tiposEscolha = this._dtTiposEscolha.map<IPlToolbarMenuItem>((item: IDataSourceItem<ETipoEscolhaPenhoras>) => {
      const escolha: number = item.value;
      const itemEscolha: IPlToolbarMenuItem = {
        id: String(escolha),
        caption: item.name,
        active: escolha === this._defaultTipoEscolha,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._tipoEscolhaChanged(menuItem);
        }
      };
      if (itemEscolha.active) {
        this._selectedTipoEscolha = itemEscolha;
      }
      return itemEscolha;
    });
    this._mnuTipoEscolha = {order: 101, id: 'rhpenhorasListMnuTipoEscolha', caption: this._getTipoEscolhaCaption(), type: 'dropdown', menu: this._tiposEscolha};
    this.toolbar.addButton(this._mnuTipoEscolha);
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  private _tipoEscolhaChanged(menuItem: IPlToolbarMenuItem, refresh: boolean = true): void {
    if (menuItem.id !== String(this._defaultTipoEscolha)) {
      if (this._selectedTipoEscolha) {
        this._selectedTipoEscolha.active = false;
      }
      this._selectedTipoEscolha = menuItem;
      this._selectedTipoEscolha.active = true;
      this._defaultTipoEscolha = Number(menuItem.id);
      this._mnuTipoEscolha.caption = this._getTipoEscolhaCaption();
      if (refresh) {
        this.refreshList();
      }
    }
  }

  private _getTipoEscolhaCaption(): string {
    const nomeTipoEscolhaEmCurso = this._tiposEscolha[this._defaultTipoEscolha].caption;
    return this._translateService.instant('rhpenhoras.toolbar.tipoEscolha', {nameTipoEscolha: nomeTipoEscolhaEmCurso});
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa += '&';
    }
    queryRequestConfig.pesquisa += `tipoescolha=${this._defaultTipoEscolha}`;
    return queryRequestConfig;
  }
}
