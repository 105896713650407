import {TDate} from '../../../common/dates';

export enum EAtivosAquisicaoBaseCalculo {
  BaseAnual,
  InicioUtilizacao
}

export enum EAtivosAquisicaoMetodoCalculo {
  QuotasConstantes,
  QuotasDecrescentes
}

export enum EAtivosAquisicaoTipoTributacaoAutonoma {
  None
}

export interface IJsonPlanning {
  ano: number;
  dataDe: TDate;
  dataAte: TDate;
  gravado: boolean;
  contabValorAquisicao: number;
  contabValorResidual: number;
  contabTaxa: number;
  contabPercReducao: number;
  contabDepreciacao: number;
  fiscalValorAquisicao: number;
  fiscalValorResidual: number;
  fiscalTaxa: number;
  fiscalPercReducao: number;
  fiscalDepreciacao: number;
  mesde: number;
  mesate: number;
}

export interface IJsonAtivosAquisicao {
  ativosAquisicaoID: string;
  codAtivo: number;
  codAmortizacao: string;
  nMapaAmortizacao: string;
  dataAquisicao: TDate;
  dataUtilizacao: TDate;
  nDocExterno: string;
  dataDocExterno: TDate;
  nomeFornecedor: string;
  quantidade: number;
  usado: boolean;
  contabValorAquisicao: number;
  contabTaxaAmortiza: number;
  contabNumAnosUteis: number;
  contabPercReducao: number;
  contabDataDispUso: TDate;
  contabBaseCalculo: EAtivosAquisicaoBaseCalculo;
  contabMetodoCalculo: EAtivosAquisicaoMetodoCalculo;
  contabValorResidual: number;
  fiscalValorAquisicao: number;
  fiscalTaxaAmortiza: number;
  fiscalNumAnosUteis: number;
  fiscalPercReducao: number;
  fiscalDataInicioUtil: TDate;
  fiscalBaseCalculo: EAtivosAquisicaoBaseCalculo;
  fiscalMetodoCalculo: EAtivosAquisicaoMetodoCalculo;
  fiscalValorResidual: number;
  fiscalTribAutonTipo: EAtivosAquisicaoTipoTributacaoAutonoma;
  nContaDoAtivo: string;
  nContaAmortAcumu: string;
  nContaAmortExerc: string;
  nContaFornecedor: string;
  nContaIVADedutivel: string;
  iVADeduzido: number;
  codIVAAquisicao: number;
  codRubOrcam: string;
  nCCusto: string;
  nCodRepCC: number;
  nZona: number;
  nDepto: number;
  nSubde: string;
  tipoArtigo: number;
  nGrFamilia: number;
  nFamilia: number;
  nSubfa: number;
  codContab: string;
  classe: number;
  categoria: number;
  nVendedor: number;
  nRefProcesso: string;
  nSeccao: string;
  nomeAmortizacao: string;
  nomeCCusto: string;
  nomeCategoria: string;
  nomeClasse: string;
  nomeContaDoAtivo: string;
  nomeContaFornecedor: string;
  nomeContab: string;
  nomeDepto: string;
  nomeFamilia: string;
  nomeGrFamilia: string;
  nomeMapaAmortizacao: string;
  nomeProcesso: string;
  nomeSubDe: string;
  nomeSubFa: string;
  nomeTipoArtigo: string;
  nomeVendedores: string;
  nomeZona: string;
  nomeSeccao: string;
  nomeRepcc: string;
  nomeContaAmortExerc: string;
  nomeContaAmortAcumu: string;
  nomeContaIva: string;
  nomeCodIva: string;
  planningList: Array<IJsonPlanning>;
  podeEditar: boolean;
  podeEditarContas: boolean;
}
