<div class="modelo30 entity-detail-form">
  <div *ngIf="errors.erros.length > 0" [plAnimationShake]="errors.erros.length > 0">
    <pl-alert type="danger" *ngIf="errors.erros.length > 0" (evtClosed)="errors.erros.length = 0">
      <div [translate]="errors.tipoValidacao !== eTiposValidar.Default ? errors.mensagemTipoHeader : 'modelo30.errors.errorHeader'"></div>
      <hr class="mt-1 mb-1" />
      <div *ngFor="let message of errors.erros">
        <ng-container [ngSwitch]="!!message.mensagem">
          <ng-container *ngSwitchCase="true">
            <strong [innerHTML]="message.nomeCampo + ': ' + message.mensagem"></strong>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <strong [innerHTML]="message.mensagemOriginal"></strong>
          </ng-container>
        </ng-container>
      </div>
    </pl-alert>
  </div>

  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo30.groups.q1.title" [order]="1" [id]="1">
        <div *plNavigatorItemCaption><span class="number-title-box">01</span>&nbsp;<span [translate]="'modelo30.groups.q1.title'"></span></div>
        <div *plNavigatorItemContent>
          <label [translate]="'modelo30.groups.q1.c01'"></label>
          <div class="d-flex">
            <div class="input-box-numbers">1</div>
            <pl-edit type="text" attrName="q1c01" [(model)]="modelo30.q1c01" [properties]="{disabled: true}"></pl-edit>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q2.title" [order]="2" [id]="2">
        <div *plNavigatorItemCaption><span class="number-title-box">02</span>&nbsp;<span [translate]="'modelo30.groups.q2.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="row">
            <div class="col-12">
              <label [translate]="'modelo30.groups.q2.c02'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">2</div>
                <pl-edit type="text" attrName="q2c02" [(model)]="modelo30.q2c02" [properties]="{disabled: true}"></pl-edit>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="d-flex flex-xl-row flex-column" style="gap: 1rem">
                <div class="me-xl-4">
                  <pl-group>
                    <pl-edit-checkbox
                      [model]="this.q02c02CasoJustoImpedimento"
                      (modelChange)="q02c02CasoJustoImpedimentoChanged($event)"
                      [properties]="{label: 'modelo30.groups.q2.casoJustoImpedimento'}"
                      [attrName]="'q02c02CasoJustoImpedimento'"></pl-edit-checkbox>
                  </pl-group>
                </div>

                <div class="d-flex flex-md-row flex-column" style="gap: 1rem">
                  <div>
                    <label [translate]="'modelo30.groups.q2.c02a'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers">2a</div>
                      <pl-autocomplete
                        [source]="justosImpedimentos"
                        attrName="q02C06"
                        [model]="modelo30.q2c02a"
                        (modelChange)="q2c02aChanged($event)"
                        [rowTemplate]="templateTiposImpedimento"
                        [output]="outputTiposImpedimento"
                        [allowInvalid]="false"
                        [allowEmpty]="true"
                        [properties]="{disabled: !q02c02CasoJustoImpedimento}">
                      </pl-autocomplete>
                    </div>
                  </div>

                  <div>
                    <label [translate]="'modelo30.groups.q2.c02b'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers">2b</div>
                      <pl-edit type="date" attrName="q2c02b" [model]="modelo30.q2c02b" [properties]="{disabled: !q02c02CasoJustoImpedimento}"></pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q3.title" [order]="3" [id]="3">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span [translate]="'modelo30.groups.q3.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-md-row flex-column" style="gap: 1rem">
                <div>
                  <label [translate]="'global.text.year'"></label>
                  <div class="d-flex">
                    <div class="input-box-numbers">3</div>
                    <pl-edit type="text" attrName="q3c03" [(model)]="modelo30.q3c03" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
                <div>
                  <label [translate]="'global.text.month'"></label>
                  <div class="d-flex">
                    <div class="input-box-numbers">3a</div>
                    <pl-edit type="text" attrName="q3c03a" [(model)]="modelo30.q3c03a" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q4.title" [order]="4" [id]="4">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span [translate]="'modelo30.groups.q4.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo30.groups.q4.c04'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">4</div>
              <pl-edit type="text" attrName="q4c04" [(model)]="modelo30.q4c04" [properties]="{disabled: true}"></pl-edit>
              <pl-tooltip [config]="{text: 'modelo30.btns.btnRepFinancas', container: 'body', placement: 'right'}" *ngIf="modelo30.codRepFinancas">
                <pl-button *plTooltipContent klass="btn btn-primary" id="codRepFinancas" (evtClicked)="openRepFinancas()">
                  <i class="fa fa-fw fa-cog"></i>
                </pl-button>
              </pl-tooltip>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q5.title" [order]="5" [id]="5">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo30.groups.q5.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo30.groups.q5.c05'"></label>
            <pl-group>
              <edit>
                <pl-edit type="radiogroup" attrName="q5c05" [(model)]="modelo30.q5c05" [properties]="tiposDeclaracao"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q6.title" [order]="6" [id]="6">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo30.groups.q6.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c06'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">06</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c06"
                    [model]="modelo30.q6c06"
                    (modelChange)="modelo30.q6c06 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c07'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">07</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c07"
                    [model]="modelo30.q6c07"
                    (modelChange)="modelo30.q6c07 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c08'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">08</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c08"
                    [model]="modelo30.q6c08"
                    (modelChange)="modelo30.q6c08 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c09'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">09</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c09"
                    [model]="modelo30.q6c09"
                    (modelChange)="modelo30.q6c09 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c10'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c10"
                    [model]="modelo30.q6c10"
                    (modelChange)="modelo30.q6c10 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c11'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c11"
                    [model]="modelo30.q6c11"
                    (modelChange)="modelo30.q6c11 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c12'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">12</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c12"
                    [model]="modelo30.q6c12"
                    (modelChange)="modelo30.q6c12 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c13'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">13</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c13"
                    [model]="modelo30.q6c13"
                    (modelChange)="modelo30.q6c13 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c14'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">14</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c14"
                    [model]="modelo30.q6c14"
                    (modelChange)="modelo30.q6c14 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c40'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">40</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c40"
                    [model]="modelo30.q6c40"
                    (modelChange)="modelo30.q6c40 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo30.groups.q6.c41'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">41</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c41"
                    [model]="modelo30.q6c41"
                    (modelChange)="modelo30.q6c41 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'global.text.other'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">16</div>
                  <pl-edit
                    type="currency"
                    attrName="q6c16"
                    [model]="modelo30.q6c16"
                    (modelChange)="modelo30.q6c16 = $event; calcQuadro6Field17()"
                    [inputClass]="'text-right'"
                    [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'global.text.total'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">17</div>
                  <pl-edit type="currency" attrName="q6c17" [model]="modelo30.q6c17" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q7.title" [order]="7" [id]="7">
        <div *plNavigatorItemCaption><span class="number-title-box">07</span>&nbsp;<span class="nav-item-title" [translate]="'modelo30.groups.q7.title'"></span></div>
        <div *plNavigatorItemContent>
          <dx-data-grid [cgDxDataGrid]="dataGridQ07" cgDxDataGridInstanceName="modelo30Q07" [dataSource]="modelo30.q7List">
            <div *dxTemplate="let item of 'dataGridTemplateIndex'">
              {{ item.rowIndex + 1 }}
            </div>
          </dx-data-grid>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo30.groups.q8.title" [order]="8" [id]="8">
        <div *plNavigatorItemCaption><span class="number-title-box">08</span>&nbsp;<span class="nav-item-title" [translate]="'modelo30.groups.q8.title'"></span></div>
        <div *plNavigatorItemContent>
          <div *ngIf="q08ExisteMontanteSemGuias">
            <pl-alert [plAnimationShake]="q08ExisteMontanteSemGuias" [closeable]="true" [type]="'warning'" (evtClosed)="q08ExisteMontanteSemGuias = false">
              <div [innerHTML]="'modelo30.errors.q08MontandeSemGuias' | translate"></div>
            </pl-alert>
          </div>
          <div *ngIf="q08ExisteLinhasDuplicadas && !q08ExisteMontanteSemGuias">
            <pl-alert [type]="'info'">
              <div [innerHTML]="'modelo30.errors.q08LinhasDuplicadas' | translate"></div>
            </pl-alert>
          </div>

          <dx-data-grid
            [cgDxDataGrid]="dataGridQ08"
            cgDxDataGridInstanceName="modelo30Q08"
            [dataSource]="modelo30.q8List"
            (onInitialized)="onInitializedQ08($event)"
            (onCellPrepared)="onCellPrepared($event)"
            (onSaved)="onSavedQ08()"
            (onRowUpdated)="onRowUpdatedQ08($event)"
            (onContentReady)="onContentReadyQ08()">
            <div *dxTemplate="let item of 'dataGridTemplateIndex'">
              {{ item.rowIndex + 1 }}
            </div>

            <div *dxTemplate="let item of 'cellTemplateBtnsEdit'">
              <dx-button [hint]="'global.btn.edit' | translate" (click)="editManutencaoNif(item.data)" icon="fa fa-fw fa-pencil text-primary" [stylingMode]="'text'"></dx-button>
            </div>
          </dx-data-grid>

          <div class="bancos-extrato-module-legend">
            <cg-table-legend [legend]="tableLegend"></cg-table-legend>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
