import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDeclaracao, IJsonRENDI} from './jsonRendi.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IRendisEntityService extends IEntityService<IJsonRENDI> {
  getDeclaracoesList(): TServiceResponse<Array<IJsonDeclaracao>>;
}

export interface IRendisEntity extends IEntityDefinition<IJsonRENDI, IRendisEntityService> {}

export const ENTITY_NAME_RENDIS = 'rendis';
