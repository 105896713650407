import {Observable, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlToolbarInstance, IPlToolbarItemDefinition, IPlToolbarMenuItem} from 'pl-comps-angular';
import {IJsonProcessamentoSegurancaSocial} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IPCARHMapasServiceRefreshedEvent} from './pcaRHMapas.module.service.interface';
import {PortalClientAccountService} from '../../../services/portalclientaccount/portalclientaccount.service';
import {DATE_MONTHS_LENGTH, TOOLBAR_GROUP_ID_PCA} from '../../../../config/constants';
import {monthName} from '../../../../common/dates';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PcaRHMapasService implements OnDestroy {
  private readonly _subjectOnRefresh: Subject<IPCARHMapasServiceRefreshedEvent>;
  private readonly _dropdownAnos: IPlToolbarItemDefinition<number>;
  private readonly _dropdownMeses: IPlToolbarItemDefinition<number>;
  private _processamentos: Array<IJsonProcessamentoSegurancaSocial>;
  private _observableOnRefresh: Observable<IPCARHMapasServiceRefreshedEvent>;

  constructor(private readonly _portalClientAccountService: PortalClientAccountService) {
    this._subjectOnRefresh = new Subject<IPCARHMapasServiceRefreshedEvent>();
    this._dropdownAnos = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcrrhmapasanos',
      order: 1,
      caption: 'Anos',
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._dropdownMeses = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcrrhmapasmeses',
      order: 2,
      caption: 'Meses',
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._processamentos = [];
  }

  public ngOnDestroy(): void {
    this._subjectOnRefresh.complete();
  }

  public init(toolbarInstance: IPlToolbarInstance): Promise<void> {
    this._initToolbar(toolbarInstance);
    return this._portalClientAccountService.getProcessamentosSegurancaSocial().then((response) => {
      this._processamentos = response.body;
      if (this._processamentos.length) {
        for (const processamento of this._processamentos) {
          if (this._findAno(processamento.ano) === -1) {
            this._dropdownAnos.menu.push({
              caption: processamento.ano.toString(),
              data: processamento.ano,
              click: (menuItem: IPlToolbarMenuItem<number>) => {
                this._mudaAno(menuItem);
              }
            });
          }
        }
        this._dropdownAnos.menu.sort((a: IPlToolbarMenuItem<number>, b: IPlToolbarMenuItem<number>) => b.data - a.data);
        this._mudaAno(this._dropdownAnos.menu[0]);
      }
    });
  }

  public onRefresh(): Observable<IPCARHMapasServiceRefreshedEvent> {
    if (!this._observableOnRefresh) {
      this._observableOnRefresh = this._subjectOnRefresh.asObservable();
    }
    return this._observableOnRefresh;
  }

  public showDropdownAnos(): void {
    this._dropdownAnos.visible = true;
  }

  public hideDropdownAnos(): void {
    this._dropdownAnos.visible = false;
  }

  public showDropdownMeses(): void {
    this._dropdownMeses.visible = true;
  }

  public hideDropdownMeses(): void {
    this._dropdownMeses.visible = false;
  }

  private _initToolbar(toolbarInstance: IPlToolbarInstance): void {
    toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID_PCA);
    toolbarInstance.addButton(this._dropdownAnos);
    toolbarInstance.addButton(this._dropdownMeses);
  }

  private _mudaAno(menuItem: IPlToolbarMenuItem<number>): void {
    this._dropdownAnos.caption = menuItem.caption;
    this._dropdownAnos.data = menuItem.data;
    this._dropdownMeses.menu = [];

    for (let i = 1; i <= DATE_MONTHS_LENGTH; i++) {
      this._dropdownMeses.menu.push({
        caption: monthName(i - 1),
        data: i,
        click: (toolbarMenuItem: IPlToolbarMenuItem<number>) => {
          this._mudaMes(toolbarMenuItem);
        }
      });
    }

    this._dropdownMeses.disabled = true;
    if (this._dropdownMeses.menu.length) {
      this._mudaMes(this._dropdownMeses.menu[moment().month()]);
      this.showDropdownMeses();
    } else {
      this.hideDropdownMeses();
    }
    this._dropdownMeses.disabled = false;
  }

  private _mudaMes(menuItem: IPlToolbarMenuItem<number>): void {
    this._dropdownMeses.caption = menuItem.caption;
    this._refresh(this._dropdownAnos.data, menuItem.data);
  }

  private _refresh(ano: number, mes: number): void {
    this._subjectOnRefresh.next({ano: ano, mes: mes});
  }

  private _findAno(ano: number): number {
    for (let i = 0; i < this._dropdownAnos.menu.length; i++) {
      if (this._dropdownAnos.menu[i].data === ano) {
        return i;
      }
    }
    return -1;
  }
}
