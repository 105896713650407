import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonFaturacaoPublica, IJsonFaturacaoPublicaAnexos, IJsonFaturacaoPublicaConfig, IJsonFaturacaoPublicaDoc, IJsonFaturacaoPublicaHistorico} from './jsonFaturacaoPublica.module.interface';
import {MODULE_NAME_FATURACAO_PUBLICA} from './faturacaoPublica.module.interface';
import {IJsonEmailClifo} from '../../components/emailsCliFo/jsonEmailCliFo.interface';
import {IApiQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturacaoPublicaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_FATURACAO_PUBLICA}`;
  }

  public loadDocumentos(): TServiceResponse<IJsonFaturacaoPublica> {
    return this._apiService.get<IJsonFaturacaoPublica>({url: this._path});
  }

  public comunicarDocumentos(faccbids: string, feaestado: number): TServiceResponse<IJsonFaturacaoPublica> {
    return this._apiService.post<IJsonFaturacaoPublica>({
      url: `${this._path}/comunicardocumentos`,
      params: {
        faccbids: faccbids,
        feaestado: feaestado
      }
    });
  }

  public marcarComoComunicadoManual(faccbid: number, feaestado: number, observacoes: string): TServiceResponse<IJsonFaturacaoPublica> {
    return this._apiService.put<IJsonFaturacaoPublica>({
      url: `${this._path}/marcarcomocomunicadomanual`,
      params: {
        faccbid: faccbid,
        feaestado: feaestado,
        observacoes: observacoes
      }
    });
  }

  public historicoDocumento(ndocfa: number, nnumer: number, ndocumento: number): TServiceResponse<Array<IJsonFaturacaoPublicaHistorico>> {
    return this._apiService.get<Array<IJsonFaturacaoPublicaHistorico>>({
      url: `${this._path}/historicodocumento`,
      params: {
        ndocfa: ndocfa,
        nnumer: nnumer,
        ndocumento: ndocumento
      }
    });
  }

  public docsComunicados(): TServiceResponse<IApiQueryResponse<IJsonFaturacaoPublicaDoc>> {
    return this._apiService.get<IApiQueryResponse<IJsonFaturacaoPublicaDoc>>({url: `${this._path}/comunicados`});
  }

  public getConfigFaturacaoPublica(): TServiceResponse<Array<IJsonFaturacaoPublicaConfig>> {
    return this._apiService.get<Array<IJsonFaturacaoPublicaConfig>>({url: `${this._path}/configuracao`});
  }

  public postConfigFaturacaoPublica(faturacaopublicaconfig: Array<IJsonFaturacaoPublicaConfig>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonFaturacaoPublicaConfig>>({url: `${this._path}/configuracao`, body: faturacaopublicaconfig});
  }

  public getAnexosAsFile(faccbid: number, tipoDocumento: number): TServiceResponse<Blob> {
    return this._apiService.put<Blob>({
      url: `${this._path}/comunicarmanualmente`,
      params: {
        faccbid: faccbid,
        tipoficheiro: tipoDocumento,
        forcegeraanexos: true
      },
      responseType: 'blob'
    });
  }

  public getFilesNames(faccbid: number): TServiceResponse<IJsonFaturacaoPublicaAnexos> {
    return this._apiService.get<IJsonFaturacaoPublicaAnexos>({url: `${this._path}/filesnames`, params: {faccbid: faccbid, forcegeraanexos: true}});
  }

  public sendMail(mailContent: IJsonEmailClifo, faccbid: number, feaestado: number): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._path}/sendmail`,
      params: {
        body: mailContent.body,
        subject: mailContent.subject,
        emails: mailContent.emails,
        faccbid: faccbid,
        feaestado: feaestado
      }
    });
  }

  public getMailContent(faccbid: number): TServiceResponse<IJsonEmailClifo> {
    return this._apiService.get<IJsonEmailClifo>({
      url: `${this._path}/emailcontent`,
      params: {
        faccbid: faccbid
      }
    });
  }
}
