import {Component, Injector, Input, OnInit} from '@angular/core';
import {EMonth, isDefined, isDefinedNotNull, isEmpty, isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import moment from 'moment';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {GtoService} from '../../gto.module.service';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {TranslateService} from '@ngx-translate/core';
import {EGTODataIniPerio, EGTOMODE, EGTOTipoTarefa, IJsonGTOTarefaNewOrUpdate} from '../../jsonGTO.module.interface';
import {GTO_MIN_DATE, IGTOMonthsDataSourceItem, IGTOMonthsModel, IGTOMonthsModelItem, TGTOMonthsValidateParams} from '../../gto.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {monthNames} from '../../../../../../common/dates';

const YEAR_SOURCE_SUBTRACT_NUM = 5;
const MONTH_DAY_PERFIX = 'Dia';

@Component({
  selector: 'gto-edit-task-modal',
  templateUrl: './gto.createEditTask.modal.component.html'
})
export class GtoCreateEditTaskModalComponent extends CGModalComponent<IJsonGTOTarefaNewOrUpdate> implements OnInit {
  @Input() public gtoTarefaId: string;
  @Input() public nempresa: string;
  @Input() public nomeEmpresa: string;
  @Input() public entExtCod: string;
  @Input() public entExtNome: string;
  @Input() public nUtilizador: number;

  public readonly months: typeof EMonth;
  public readonly monthNames: Array<string>;
  public readonly modes: typeof EGTOMODE;
  public readonly monthOutput: string;
  public readonly monthRowTemplate: string;

  public mode: EGTOMODE;
  public anosSource: Array<number>;
  public tipoTarefaSource: Array<unknown>;
  public dataIniPerioSource: Array<unknown>;
  public model: IJsonGTOTarefaNewOrUpdate;
  public modalTitle: string;
  public monthsModel: IGTOMonthsModel;
  public empresaTitle: string;
  public storeModePublic: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gtoService: GtoService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.monthOutput = '{{value}}';
    this.monthRowTemplate = '{{name}}';
    this.months = EMonth;
    this.monthNames = monthNames();
    this.modes = EGTOMODE;
    this.mode = EGTOMODE.NEW;
    this.empresaTitle = '';
    this.storeModePublic = false;
    const yearNow = moment().year();
    this.model = {
      ano: yearNow,
      dataIniPerio: EGTODataIniPerio.Mes,
      tipoTarefa: EGTOTipoTarefa.Obrigacoes,
      nomeTarefa: '',
      gtoTarefaID: '',
      applyAllEmpresas: false,
      dates: [],
      entExtCod: '',
      isActive: true,
      isSystem: false,
      nEmpresa: '',
      nEmpresaGab: '',
      nResponsavel: this.nUtilizador,
      tipoObrigacao: 0,
      nUtilizCriaReg: 0,
      nomeResponsavel: '',
      gtoTarefaMarc: []
    };
    this.monthsModel = {
      m1: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m2: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m3: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m4: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m5: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m6: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m7: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m8: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m9: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m10: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m11: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined},
      m12: {source: [], selectedDay: undefined, gtoTarefasMarcacaoID: '', properties: undefined}
    };
    this.anosSource = [];
    for (let i = yearNow + 1; i > yearNow - YEAR_SOURCE_SUBTRACT_NUM; i--) {
      this.anosSource.push(i);
    }
    this.tipoTarefaSource = [
      {
        value: EGTOTipoTarefa.Obrigacoes,
        name: this._translateService.instant('gto.obrigacoes')
      },
      {
        value: EGTOTipoTarefa.Pagamentos,
        name: this._translateService.instant('gto.pagamentos')
      },
      {
        value: EGTOTipoTarefa.Outra,
        name: this._translateService.instant('gto.outra')
      }
    ];
    this.dataIniPerioSource = [
      {
        value: EGTODataIniPerio.Mes,
        name: this._translateService.instant('global.text.month')
      },
      {
        value: EGTODataIniPerio.MesAnterior,
        name: this._translateService.instant('gto.mes1anterior')
      },
      {
        value: EGTODataIniPerio.DoisMesAnterior,
        name: this._translateService.instant('gto.mes2anterior')
      },
      {
        value: EGTODataIniPerio.AnoAnterior,
        name: this._translateService.instant('gto.ano1anterior')
      }
    ];
    this._fillMonthsModelSources(yearNow);
  }

  public ngOnInit(): void {
    this.model.nEmpresa = this.nempresa;
    this.empresaTitle = `${this.nempresa} - ${this.nomeEmpresa}`;
    this.mode = isDefinedNotNull(this.gtoTarefaId) ? EGTOMODE.EDIT : EGTOMODE.NEW;
    this.modalTitle = this.mode === EGTOMODE.NEW ? 'gto.createNewTaskTitle' : 'gto.editTaskTitle';
    if (this.mode === EGTOMODE.EDIT) {
      this._gtoService.getTask(this.gtoTarefaId).then((response) => {
        this.model = {applyAllEmpresas: false, dates: [], ...response.body};
        this.model.gtoTarefaMarc.forEach((marcacao) => {
          const momDate = moment(marcacao.data);
          const monthNum: number = momDate.month() + 1;
          const day = momDate.date();
          this.monthsModel[`m${monthNum}`].selectedDay = {value: day, name: `${MONTH_DAY_PERFIX} ${day}`};
          this.monthsModel[`m${monthNum}`].gtoTarefasMarcacaoID = marcacao.gtoTarefasMarcID;
        });
      });
    } else if (isDefined(this.entExtCod) && !isEmpty(this.entExtCod)) {
      this.model.entExtCod = this.entExtCod;
      this.model.entExtCodNome = this.entExtNome;
    }
  }

  public onYearChange(year: number): void {
    this.model.ano = year;
    this._fillMonthsModelSources(year);
  }

  public readonly fnCloseModal: () => Promise<void> = () => this._closeModal();

  public readonly fnDeleteTask: () => Promise<void> = () => this._deleteTask();

  public readonly fnValidateMonth =
    (source: Array<IGTOMonthsDataSourceItem>) =>
    (params: TGTOMonthsValidateParams): boolean =>
      this._validateMonth(source, params);

  protected _onChangedConfigurations(): void {
    super._onChangedConfigurations();
    this.storeModePublic = this.configurations.licenca.storeModePublic;
  }

  private _closeModal(): Promise<void> {
    this.model.dates = [];

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = 1; i <= 12; i++) {
      const mObj: IGTOMonthsModelItem = this.monthsModel[`m${i}`];
      if (isDefinedNotNull(mObj.selectedDay)) {
        this.model.dates.push({
          data: new Date(this.model.ano, i - 1, mObj.selectedDay.value),
          gtoTarefasMarcacaoID: mObj.gtoTarefasMarcacaoID
        });
      } else {
        this.model.dates.push({
          data: GTO_MIN_DATE,
          gtoTarefasMarcacaoID: mObj.gtoTarefasMarcacaoID
        });
      }
    }
    return this._gtoService
      .createOrUpdateTask(this.model)
      .then(() => {
        this.close(this.model);
      })
      .catch((reason) => {
        if (isDefinedNotNull(reason)) {
          const exception: ICGExceptionError = this._cgExceptionService.get(reason);
          this._plAlertService.error(exception.message);
        }
      });
  }

  private _fillMonthsModelSources(year: number): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = 1; i <= 12; i++) {
      const numDays = moment()
        .year(year)
        .month(i - 1)
        .endOf('month')
        .daysInMonth();
      const month: IGTOMonthsModelItem = this.monthsModel[`m${i}`];
      month.source = [];
      for (let day = 1; day <= numDays; day++) {
        month.source.push({value: day, name: `${MONTH_DAY_PERFIX} ${day}`});
      }
      month.properties = {
        placeholder: ' ',
        validators: {
          selectedDay: {
            message: 'gto.invalidSelectedDay',
            validate: this.fnValidateMonth(month.source)
          }
        }
      };
    }
  }

  private _validateMonth(source: Array<IGTOMonthsDataSourceItem>, {modelValue}: TGTOMonthsValidateParams): boolean {
    if (isEmpty(modelValue) || isObject(modelValue)) {
      return true;
    }
    const month: IGTOMonthsDataSourceItem = source.find((sourceItem: IGTOMonthsDataSourceItem) => String(sourceItem.value) === modelValue);
    return isDefined(month);
  }

  private _deleteTask(): Promise<void> {
    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'gto.temACertezaQuePertendeApagarEstaTarefa', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false
      })
      .then(() =>
        this._gtoService.deleteTask(this.model.gtoTarefaID).then(() => {
          this._plAlertService.success('gto.tarefaEliminadaComSucesso');
          this.close();
        })
      );
  }
}
