import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {copy, isEmpty, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IJsonGestaoComunicacoesTopico, IJsonGestaoComunicacoesUtilizador, IJsonGestaoComunicacoesUtilizadoRes} from '../../../jsonGestaoComunicacoesTopico.module.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonComCategoria} from '../../../../../../entities/comcategoria/jsonComCategoria.entity.interface';
import {ENTITY_NAME_COM_CATEGORIA} from '../../../../../../entities/comcategoria/comCategoria.entity.interface';
import moment from 'moment';
import {GestaoComunicacoesTopicoService} from '../../../gestaoComunicacoesTopico.module.service';
import {IJsonGestaoComunicacoesMensagem} from '../../../jsonGestaoComunicacoesMensagem.module.interface';
import {FormGroupDirective} from '@angular/forms';
import {IJsonGestaoComunicacoesEstado} from '../../../jsonGestaoComunicacoesEstado.module.interface';
import {GestaoComunicacoesEstadoService} from '../../../gestaoComunicacoesEstado.module.service';

@Component({
  selector: 'gestao-comunicacoes-novo-topico-modal',
  templateUrl: './gestaoComunicacoes.topico.modal.component.html'
})
export class GestaoComunicacoesTopicoModalComponent extends CGModalComponent<IJsonGestaoComunicacoesTopico> implements OnInit {
  @Input() public estadoId: string;
  @Input() public nEmpresaSelected: string;
  @Input() public contabilistaView: boolean;
  @Input() public datagridView: boolean;

  public readonly utilizadorTemplate: string;

  public model: IJsonGestaoComunicacoesTopico;
  public mensagem: string;
  public utilizador: IJsonGestaoComunicacoesUtilizador;
  public categoriasSource: Array<IJsonComCategoria>;
  public estadosSource: Array<IJsonGestaoComunicacoesEstado>;
  public utilizadoresSource: Array<IJsonGestaoComunicacoesUtilizador>;
  public form: FormGroupDirective;
  public searchCategoriasParam: string;
  public promiseLoading: Promise<void>;
  public fileSelected: File;

  private readonly _serviceComCategoria: IEntityService<IJsonComCategoria>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _gestaoComunicacoesEstadosService: GestaoComunicacoesEstadoService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceComCategoria = this._entityServiceBuilder.build<IJsonComCategoria>(ENTITY_NAME_COM_CATEGORIA);
    this.utilizadorTemplate = '{{nUtilizador}} - {{nome}}';
    this.categoriasSource = [];
    this.estadosSource = [];
    this.utilizadoresSource = [];
    this.fileSelected = undefined;
    this.utilizador = this._emptyUtilizador();
  }

  public async ngOnInit(): Promise<void> {
    this.searchCategoriasParam = `nEmpresa=${this.nEmpresaSelected}|nEmpresa=000`;
    this.model = {...this._emptyTopico(), comEstadoId: this.estadoId, nEmpresa: this.nEmpresaSelected, utilizadoresPara: [this.utilizador]};

    this.promiseLoading = (async () => {
      const responseUsers: IJsonGestaoComunicacoesUtilizadoRes = (await this._gestaoComunicacoesTopicoService.getUtilizadores(this.contabilistaView, this.nEmpresaSelected)).body;
      this.utilizadoresSource = copy(responseUsers.list);

      if (responseUsers.list.length === 1) {
        this.utilizador = responseUsers.list[0];
        this.model.utilizadoresPara = [this.utilizador];
      }
      if (responseUsers.lastUser.nome) {
        this.utilizador = responseUsers.lastUser;
        this.model.utilizadoresPara = [this.utilizador];
      }

      this.categoriasSource = (await this._serviceComCategoria.query({pesquisa: this.searchCategoriasParam})).body.list;
      if (this.categoriasSource.length === 1) {
        this.changedCategoria(this.categoriasSource[0]);
      }

      if (this.datagridView) {
        this.estadosSource = (await this._gestaoComunicacoesEstadosService.getEstados()).body.list;
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async close(): Promise<void> {
    this.model = (await this._gestaoComunicacoesTopicoService.createTopico(this.model)).body;

    if (this.mensagem) {
      const message: IJsonGestaoComunicacoesMensagem = {
        ...this._emptyMensagem(),
        comTopicoId: this.model.comTopicoId,
        message: this.mensagem
      };
      await this._gestaoComunicacoesTopicoService.createTopicoMessage(this.model.comTopicoId, message, this.contabilistaView);

      // update model depois de criar mensagem
      this.model.ultimaMensagem = this.mensagem;
      this.model.stampUltimaMensagem = moment();
    }

    if (this.fileSelected) {
      await this._gestaoComunicacoesTopicoService.createTopicoMessageFile(this.model.comTopicoId, this.fileSelected, this.contabilistaView);
    }

    if (!this.mensagem && !this.fileSelected) {
      this.model.ultimaMensagem = this._plTranslateService.translate('gestaoComunicacoes.common.semMensagens');
    }

    this._plAlertService.success('gestaoComunicacoes.modal.topico.mensagens.success');
    super.close({
      ...this.model,
      utilizadoresPara: [this.utilizador]
    });
  }

  public evtSubmitFile(file: File): void {
    this.fileSelected = file;
  }

  public evtSubmitMessage(message: string): void {
    this.mensagem = message;
  }

  public changedCategoria(value: IJsonComCategoria): void {
    this.model.comCategoriaId = value?.comCategoriaId;
    this.model.categoriaNome = value?.nome;
  }

  public changedEstado(value: IJsonGestaoComunicacoesEstado): void {
    this.model.comEstadoId = value?.comEstadoId;
    this.model.estadoNome = value?.nome;
  }

  public changedUtilizador(value: IJsonGestaoComunicacoesUtilizador): void {
    this.utilizador.nUtilizador = value?.nUtilizador;
    this.utilizador.nome = value?.nome;

    if (!isEmpty(value)) {
      this.model.utilizadoresPara = [value];
    }
  }

  private _emptyMensagem(): IJsonGestaoComunicacoesMensagem {
    return {
      comTopicoId: undefined,
      comentarioId: undefined,
      message: '',
      nUtilizador: undefined,
      stampCreated: moment(),
      docId: undefined,
      fileName: undefined,
      tamanho: undefined,
      isMessage: true,
      isMyMessage: true,
      isRead: true,
      mimeType: undefined
    };
  }

  private _emptyTopico(): IJsonGestaoComunicacoesTopico {
    return {
      assunto: '',
      categoriaCor: undefined,
      categoriaNome: '',
      comCategoriaId: undefined,
      comEstadoId: undefined,
      comTopicoId: undefined,
      estadoNome: '',
      isContabilista: this.contabilistaView,
      lidoCliente: false,
      lidoContab: false,
      nEmpresa: undefined,
      ordem: 0,
      stampLidoCliente: moment(),
      stampLidoContab: moment(),
      stampUltimaMensagem: moment(),
      ultimaMensagem: undefined,
      utilizadoresPara: [{...this._emptyUtilizador()}],
      hasUnreadMessages: 0,
      utilizadorDe: {...this._emptyUtilizador()},
      ultMsgNomeUtilizador: undefined,
      revision: 0
    };
  }

  private _emptyUtilizador(): IJsonGestaoComunicacoesUtilizador {
    return {
      nUtilizador: undefined,
      nome: ''
    };
  }
}
