<div class="rhprocsegsocialconsultenvios-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhprocsegsocialconsultenvios.table.modal.title'"></h5>
    <pl-button klass="btn-primary btn-sm" id="guardar" [onClick]="fnSaveInfoFichFolhaFerias" [disabled]="!canSave">
      <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="procSegSocialConsultEnvios"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onEditorPreparing)="onEditorPreparing($event)">
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
