<div class="refeicoes-scheduler" [plPromise]="promise">
  <div class="refeicoes-scheduler-header">
    <div class="refeicoes-scheduler-header-local">
      <div class="refeicoes-scheduler-header-local-label"><span [translate]="'refeicoes.text.local'"></span>:</div>

      <div class="refeicoes-scheduler-header-local-edit">
        <entity-autocomplete entity="prhlocais" [model]="local" (modelChange)="changedLocal($event)" [properties]="{allowInvalid: false}"></entity-autocomplete>
      </div>

      <div class="colaboradores-marcar-refeicoes-module-header-local-default">
        <i class="fa fa-fw" [class.fa-star-o]="local?.codLocal !== localPreferido?.codLocal" [class.fa-star]="local?.codLocal === localPreferido?.codLocal" (click)="setLocalPorDefeito()"></i>
      </div>
    </div>

    <div class="refeicoes-scheduler-header-month">
      <h3>{{ viewDate | cgcDate: 'MMMM yyyy' }}</h3>
    </div>

    <div class="refeicoes-scheduler-header-loading">
      <pl-spinner type="round" *ngIf="loading"></pl-spinner>
    </div>
  </div>
</div>
