import {deepFreeze} from 'pl-comps-angular';
import {EGerarUtilizarGrupo} from '../../rhCopiaAbonosDescontos.module.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';

export const RADIO_GROUP_GERAR_UTILIZAR_GRUPO_TEMPLATE: IRadioGroup<EGerarUtilizarGrupo> = deepFreeze<IRadioGroup<EGerarUtilizarGrupo>>({
  groupItems: [
    {value: EGerarUtilizarGrupo.GerarNovo, label: 'rhcopiaabonosdescontos.modal.radio.gerarNovo'},
    {value: EGerarUtilizarGrupo.UtilizarExistente, label: 'rhcopiaabonosdescontos.modal.radio.utilizarExistente'}
  ]
});
