<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'global.text.warning'"></h4>
</div>

<div class="modal-body">
  <p [translate]="'pocs.text.avisoAlteracaoCC1'"></p>
  <p [translate]="'pocs.text.avisoAlteracaoCC2'"></p>
  <p [translate]="'pocs.text.avisoAlteracaoCC3'"></p>
  <pl-edit type="checkbox" attrName="tomeiconhecimento" [(model)]="tomeiConhecimento" [properties]="{label: 'pocs.text.pretendeContinuar'}"></pl-edit>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-submit" [disabled]="!tomeiConhecimento" (click)="close()">
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.confirm'"></span>
  </button>

  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
