<pl-edit-number [attrName]="fieldName" inputClass="input-sm" [(model)]="linha.valorTaxa" [properties]="properties">
  <div *inputGroup="{klass: 'btn-light btn-enabled', disabled: locked, tabindex: -1}">
    <div class="spin-btns-container">
      <i class="fa fa-angle-up" [title]="'docscontabilidade.text.incValorIva' | translate" (click)="addValorTaxa(0.01)" plDelegateEventDownwardsClick aria-hidden="true"></i>

      <i class="fa fa-angle-down" [title]="'docscontabilidade.text.decValorIva' | translate" (click)="addValorTaxa(-0.01)" plDelegateEventDownwardsClick aria-hidden="true"></i>
    </div>
  </div>

  <div *inputGroup="{klass: 'btn-light btn-enabled', disabled: locked, tabindex: -1}">
    <i class="fa fa-pencil" [title]="'docscontabilidade.text.editValorIva' | translate" (click)="changeValorTaxa()" plDelegateEventDownwardsClick aria-hidden="true"></i>
  </div>
</pl-edit-number>
