<div class="modal-header">
  <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.modalTitleEdit'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <div class="row">
      <div class="col-md-12">
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.dataLancamento'"></label>
          <edit>
            <pl-edit type="date" [(model)]="model" [properties]="{validators: {checkDate: checkDateValidator}}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm action-close" [onClick]="close" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span>
  </pl-button>

  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span></pl-button>
</div>
