import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22ContasDef} from '../../../../../jsonModelo22.module.interface';
import {IJsonNumber} from '../../../../../../../../../common/interfaces/json';
import {IDevExpressDataGrid} from '../../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Modelo22ContasDefCopyModalComponent} from '../contasdefcopy/modelo22.contasDefCopy.modal.component';
import {CGModalService} from '../../../../../../../../components/cg/modal/cgmodal.service';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'modal-modelo22-contasdefall',
  templateUrl: './modelo22.contasDefAll.modal.component.html'
})
export class Modelo22ContasDefAllModalComponent extends CGModalComponent<IJsonNumber> implements OnInit {
  @Input() public contasDefAll: Array<IJsonModelo22ContasDef>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'campo', dataType: 'string', caption: 'modelo22.modal.rosto.contasdefall.table.campo'},
        {dataField: 'nContaDe', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaDe'},
        {dataField: 'nContaAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaAte'},
        {dataField: 'perioAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.perioAte'},
        {dataField: 'sinal', dataType: 'boolean', caption: 'modelo22.modal.rosto.contasdef.table.sinal'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.modal.rosto.contasdef.table.valor'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.modal.rosto.contasdefall.title'},
      remoteOperations: false
    };
    this.fnOpenContasDefCopy = this.fnOpenContasDefCopy.bind(this);
    this.fnReporConfig = this.fnReporConfig.bind(this);
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.contasDefAll;
  }

  public fnOpenContasDefCopy(): Promise<void> {
    return this._cgModalService.showVanilla(Modelo22ContasDefCopyModalComponent).result;
  }

  public fnReporConfig(): Promise<void> {
    return this._modelo22Service.getReporContasDef().then((response: HttpResponse<Array<IJsonModelo22ContasDef>>) => {
      this.dataGridDefinition.dataSource = response.body;
      this._plAlertService.success('modelo22.messages.contasdef.reposicaocomsucesso');
    });
  }
}
