<pl-autocomplete
  [attrName]="attrName"
  [model]="outputValue"
  (modelChange)="changedOutputValue($event)"
  [inputClass]="inputClass"
  [allowInvalid]="options?.allowInvalid"
  [allowEmpty]="options?.allowEmpty"
  [filterFields]="filterFields"
  [output]="entityOutput"
  [placeholder]="placeholder"
  [rowTemplate]="rowTemplate"
  [showFilter]="showFilter"
  [source]="fnGetData"
  [validateFn]="fnValidate"
  [cacheValues]="cacheValues"
  [cacheValuesInstanceId]="cacheValuesInstanceId"
  [cacheValuesAutoReload]="cacheValuesAutoReload"
  [loadingDelayDuration]="loadingDelayDuration"
  [properties]="{
    disabled: options?.disabled,
    disallowInput: options?.disallowInput,
    disallowClear: options?.disallowClear,
    events: options?.events,
    onSelect: fnSelect,
    raw: options?.raw,
    readonly: options?.readonly,
    validate: options?.validate,
    validators: options?.validators,
    showToggle: options?.showToggle,
    cssClassDropdownWrapper: options?.cssClassDropdownWrapper
  }"
  (evtInputValueChanged)="changedInputValue($event)">
  <ng-container *ngIf="showActions && (!options?.disabled || options?.hideActionsOnDisabled === false)">
    <ng-container *ngIf="quickCreateEnabled">
      <div *inputGroup="{klass: 'hollow-form quick-create-btn'}">
        <span (click)="fnMaintenanceNew()" plDelegateEventDownwardsClick>+</span>
      </div>
    </ng-container>

    <ng-container *inputGroup="{klass: 'hollow-form'}">
      <div
        class="item-group-dropdown"
        tabindex="-1"
        ngbDropdown
        [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']"
        [container]="options?.maintenanceProperties?.dropdownContainer || 'body'"
        (openChange)="openChanged($event)">
        <div class="item-group-dropdown-toggle" ngbDropdownToggle data-capture-click>
          <i class="pl-icon-more-vertical" aria-hidden="true"></i>
        </div>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngIf="maintenanceAllowList" [click]="fnMaintenanceList" plPromise>
            <i class="fa fa-fw fa-search" aria-hidden="true"></i>&nbsp;<span>{{ maintenanceHeaderList }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="maintenanceAllowEdit" [click]="fnMaintenanceEdit" plPromise>
            <i class="fa fa-fw fa-pencil-square" aria-hidden="true"></i>&nbsp;<span>{{ maintenanceHeaderEdit }}</span>
          </button>
          <ng-container *ngFor="let customEntityAction of customEntityActions">
            <ng-container *ngIf="customEntityAction._isVisible">
              <button type="button" class="dropdown-item" [click]="customEntityAction.action" plPromise>
                <ng-container *ngIf="customEntityAction.icon"><i class="fa fa-fw" [ngClass]="customEntityAction.icon" aria-hidden="true"></i>&nbsp;</ng-container>
                <span [translate]="customEntityAction.caption"></span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let itemGroup of itemGroups">
    <ng-container *inputGroup="itemGroup.config">
      <ng-container *ngTemplateOutlet="itemGroup.templateRef"></ng-container>
    </ng-container>
  </ng-container>
</pl-autocomplete>
