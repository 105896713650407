<div class="ativosnotacredito-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosnotacredito.title'"></h5>
  </div>

  <div class="modal-body">
    <section class="d-flex">
      <div>
        <label class="me-2"><span [translate]="'ativosnotacredito.header.fornecedor'"></span>:&nbsp;</label>
        <span>{{ fornecedorDesc }}</span>
      </div>
    </section>
    <section class="d-flex flex-column align-items-end">
      <div>
        <label class="me-2"><span [translate]="'ativosnotacredito.header.valoraquisicao'"></span>:&nbsp;</label>
        <span>{{ valorAquisicao }}</span>
      </div>
      <div>
        <label class="me-2"><span [translate]="'ativosnotacredito.header.valornotacredito'"></span>:&nbsp;</label>
        <span>{{ valorNotaCredito }}</span>
      </div>
      <div>
        <label class="me-2"><span [translate]="'ativosnotacredito.header.novovaloraquisicao'"></span>:&nbsp;</label>
        <span>{{ novoValorAquisicao }}</span>
      </div>
    </section>

    <pl-button klass="btn-primary btn-sm" id="pesquisa" (evtClicked)="openMaintenanceDocsContabilidade()">
      <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'ativosnotacredito.btn.pesquisanota'"></span>
    </pl-button>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="ativosNotaCreditoModal" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
