import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_GTO_TIPO_TAREFA} from '../gto.datasources.interface';
import {EGTOTipoTarefa} from '../../../modules/portalcontabilidade/gto/jsonGTO.module.interface';

export const DATA_SOURCE_GTO_TIPO_TAREFA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_GTO_TIPO_TAREFA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: '{{name}}'
  },
  data: [
    {value: -1, name: 'gto.datasources.tipotarefa.todos'},
    {value: EGTOTipoTarefa.Obrigacoes, name: 'gto.datasources.tipotarefa.obrigacoes'},
    {value: EGTOTipoTarefa.Pagamentos, name: 'gto.datasources.tipotarefa.pagamentos'},
    {value: EGTOTipoTarefa.Outra, name: 'gto.datasources.tipotarefa.outra'}
  ]
});
