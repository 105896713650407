import {ENTITY_NAME_TIPO_HORARIO} from './tipoHorario.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonThor} from './jsonTipoHorario.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_TIPO_HORARIO: IEntityDefinition<IJsonThor> = {
  name: ENTITY_NAME_TIPO_HORARIO,
  roles: [ROLE.RH],
  searchPlaceholder: 'tipohorario.pesquisa',
  pageTitle: 'global.menu.tipohorario',
  metadata: {
    keyName: 'nTipoHorario',
    fields: [
      {name: 'nTipoHorario', type: 'string', caption: 'tipohorario.fields.nTipoHorario', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', type: 'string', caption: 'tipohorario.fields.designaBreve', validators: {required: true, maxlength: 20}},
      {name: 'designaCompl', type: 'string', caption: 'tipohorario.fields.designaCompl', validators: {maxlength: 40}},
      {name: 'tipoHorarioId', type: 'cginteger', caption: 'tipohorario.fields.tipoHorarioId', visible: false}
    ],
    order: 'nTipoHorario',
    searchFields: 'nTipoHorario,designaBreve,designaCompl'
  },
  autocomplete: {
    rowTemplate: '{{nTipoHorario}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nTipoHorario,designaBreve,designaCompl'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
