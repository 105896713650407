<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'integracaoSalarios.errosIntegSalariosModalTitle'"></h5>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="errosIntegSalariosModal" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
