<div class="row listagens">
  <div class="col-sm-9" [plPromise]="promise">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="vendasArtigos"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()">
    </dx-data-grid>
  </div>

  <div class="col-sm-3">
    <div class="pl-listagens">
      <div class="card card-tile primary">
        <div class="card-header d-flex">
          <span class="fa fa-user fa-2x"></span>
          <span class="total-listagens ms-auto" [translate]="'vendascliente.totalvendas'"></span>
        </div>

        <div class="card-footer clearfix">
          <h4 class="float-end">{{ listagensArtigos.total | cgcCurrency: 2 }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
