import type {MomentInput} from 'moment';

export enum EPlCookiesConsentItemType {
  HTTP = 'HTTP',
  HTML = 'HTML'
}

export interface IPlCookiesConsentConfig {
  readonly consent: IPlCookiesConsent;
  readonly hideHeader: boolean;
  readonly hideNecessary: boolean;
  readonly hidePreferences: boolean;
  readonly hideStatistics: boolean;
  readonly hideMarketing: boolean;
  readonly hideUnclassified: boolean;
  readonly hideDetails: boolean;
  readonly hideFooter: boolean;
}

export interface IPlCookiesConsent {
  readonly necessary?: IPlCookiesConsentGroupMandatory;
  readonly preferences?: IPlCookiesConsentGroup;
  readonly statistics?: IPlCookiesConsentGroup;
  readonly marketing?: IPlCookiesConsentGroup;
  readonly unclassified?: IPlCookiesConsentGroup;
  readonly lastUpdatedOn?: MomentInput;
  readonly consented?: boolean;
}

export interface IPlCookiesConsentGroupMandatory {
  readonly items: ReadonlyArray<IPlCookiesConsentItem>;
}

export interface IPlCookiesConsentGroup extends IPlCookiesConsentGroupMandatory {
  readonly accepted?: boolean;
}

export interface IPlCookiesConsentItem {
  readonly name: string;
  readonly provider: string;
  readonly purpose: string;
  readonly type: EPlCookiesConsentItemType;
  readonly providerHyperlink?: string;
  readonly expires?: boolean;
  readonly expireTime?: number | 'SESSION'; // Number in milliseconds
}
