import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosNotaCreditoService} from '../../services/ativosNotaCredito.service';
import {AtivosNotaCreditoSubModalModalComponent} from './submodal/ativosNotaCredito.modal.subModal.component';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {IJsonNotaCredito, IJsonNotaCreditoNovoValor, IJsonNotaCreditoPesquisaContab} from './jsonAtivosNotaCredito.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-ativos-nota-credito',
  templateUrl: './ativosNotaCredito.modal.component.html'
})
export class AtivosNotaCreditoModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public ativo: IJsonAtivos;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public fornecedorDesc: string;
  public valorAquisicao: number;
  public valorNotaCredito: number;
  public novoValorAquisicao: number;
  public notaCreditoPesquisaContab: Array<IJsonNotaCreditoPesquisaContab>;
  public notaCredito: IJsonNotaCredito;

  private _extPocCabId: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosNotaCreditoService: AtivosNotaCreditoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaDebito', dataType: 'string', caption: 'ativosnotacredito.table.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'ativosnotacredito.table.nContaCredito', width: 200},
        {dataField: 'valor', dataType: 'double', caption: 'ativosnotacredito.table.valor'},
        {dataField: 'descricao', dataType: 'string', caption: 'ativosnotacredito.table.descricao', width: 200}
      ],
      dataSource: [],
      export: {filename: 'ativosnotacredito.title'},
      remoteOperations: false
    };
    if (!this.notaCredito) {
      this.notaCredito = {
        valorAquisicaoNovo: 0,
        valorNotaCredito: 0,
        extPocCabId: '',
        notaCreditoContab: []
      };
    }
    this._extPocCabId = '';
  }

  public ngOnInit(): void {
    this.fornecedorDesc = `${this.ativo.aquisicao.nContaFornecedor} - ${this.ativo.aquisicao.nomeFornecedor}`;
    this.valorAquisicao = this.ativo.aquisicao.contabValorAquisicao;
    this.valorNotaCredito = 0;
    this.novoValorAquisicao = 0;
  }

  public close(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'ativosnotacredito.message.temcerteza').then(() => {
      const notaCreditoNovoValor: IJsonNotaCreditoNovoValor = {
        ativo: this.ativo,
        extPocCabId: this._extPocCabId,
        valorAquisicaoNovo: this.novoValorAquisicao,
        valorNotaCredito: this.valorNotaCredito
      };
      return this._ativosNotaCreditoService.postAssociaNotaDeCreditoDeReducaoDeValorAquisicao(notaCreditoNovoValor).then((response: HttpResponse<IJsonAtivos>) => {
        this._plAlertService.success('ativosnotacredito.message.success');
        super.close(response.body);
      });
    });
  }

  public openMaintenanceDocsContabilidade(): Promise<void> {
    return this._ativosNotaCreditoService.getPesquisaNotaCreditoContab().then((response: HttpResponse<Array<IJsonNotaCreditoPesquisaContab>>) => {
      const modalInstance = this._cgModalService.showVanilla(AtivosNotaCreditoSubModalModalComponent);
      const componentInstance: AtivosNotaCreditoSubModalModalComponent = modalInstance.componentInstance;
      componentInstance.codAtivo = this.ativo.codAtivo;
      componentInstance.notaCreditoList = response.body;
      return modalInstance.result.then((resAddDoc: IJsonNotaCredito) => {
        this.valorNotaCredito = resAddDoc.valorNotaCredito;
        this.novoValorAquisicao = resAddDoc.valorAquisicaoNovo;
        this._extPocCabId = resAddDoc.extPocCabId;
        this.dataGridDefinition.dataSource = resAddDoc.notaCreditoContab;
      });
    });
  }
}
