import {ENTITY_NAME_FUNCOES} from './funcoes.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonFuncoes} from './jsonFuncoes.entity.interface';

export const ENTITY_FUNCOES: IEntityDefinition<IJsonFuncoes> = {
  name: ENTITY_NAME_FUNCOES,
  roles: [ROLE.RH],
  searchPlaceholder: 'funcoes.pesquisa',
  pageTitle: 'global.menu.funcoes',
  metadata: {
    keyName: 'nFuncao',
    fields: [
      {name: 'nFuncao', caption: 'funcoes.fields.nFuncao', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', caption: 'funcoes.fields.designaBreve', validators: {required: true, maxlength: 40}},
      {name: 'designaComp', caption: 'funcoes.fields.designaComp', validators: {maxlength: 100}},
      {name: 'idFunc', type: 'cginteger', caption: 'funcoes.fields.idFunc', visible: false},
      {name: 'periocidade', type: 'cgsmallint', caption: 'funcoes.fields.periocidade', visible: false}
    ],
    order: 'nFuncao',
    searchFields: 'nFuncao,designaBreve,designaComp,idFunc,periocidade'
  },
  autocomplete: {
    rowTemplate: '{{nFuncao}} - {{designaBreve}}',
    output: 'designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
