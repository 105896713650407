import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, PlAlertService} from 'pl-comps-angular';
import {IJsonAdiantamentoConfig} from '../../jsonAdiantamentos.module.interface';
import {AdiantamentosService} from '../../adiantamentos.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonArtigo} from '../../../../entities/artigos/jsonArtigo.entity.interface';

const STEP_INDEX_FAMILIA = 1;
const STEP_INDEX_ARTIGOS = 2;

@Component({
  selector: 'modal-adiantamentos-auto-config',
  templateUrl: './adiantamentos.autoConfig.modal.component.html'
})
export class AdiantamentosAutoConfigModalComponent extends CGModalComponent<IJsonAdiantamentoConfig> implements OnInit {
  @Input() public configAdiantamentos: IJsonAdiantamentoConfig;
  @Input() public fromModalConfig: boolean;

  public readonly definition: IPlNavWizardDefinition;

  public selectedStep: IPlNavWizardStep;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _adiantamentosService: AdiantamentosService
  ) {
    super(_injector);
    this.definition = {
      items: []
    };
    this.fromModalConfig = false;
  }

  public ngOnInit(): void {
    const initAdiantConfig: IJsonAdiantamentoConfig = {
      familiaBase: undefined,
      nomeFamiliaBase: '',
      familia: undefined,
      nomeFamilia: '',
      nArtigoAdiantamento: '',
      nomeNArtigoAdiantamento: '',
      nArtigoRegAdiantamento: '',
      nomeNArtigoRegAdiantamento: '',
      infoFamilia: '',
      infoArtigoRegAdiantamento: '',
      infoArtigoAdiantamento: '',
      codIvaVenda: undefined,
      nomeCodIvaVenda: '',
      taxaIvaVenda: 0,
      codIvaCompra: undefined,
      nomeCodIvaCompra: '',
      taxaIvaCompra: 0
    };
    this.configAdiantamentos = {...initAdiantConfig, ...this.configAdiantamentos};
  }

  public close(): Promise<void> {
    return this._adiantamentosService.autoConfigArtigos(this.configAdiantamentos).then(() => {
      let promise: Promise<void>;
      if (!this.fromModalConfig) {
        promise = this._adiantamentosService.saveAdiantamentosConfig(this.configAdiantamentos).then(() => undefined);
      }
      return Promise.resolve(promise).then(() => {
        this.configAdiantamentos.familiaBase = this.configAdiantamentos.familia;
        this.configAdiantamentos.nomeFamiliaBase = this.configAdiantamentos.nomeFamilia;
        super.close(this.configAdiantamentos);
      });
    });
  }

  public artigoChanged(item: IJsonArtigo): void {
    this.configAdiantamentos.codIvaVenda = item.codIvaVenda;
    this.configAdiantamentos.nomeCodIvaVenda = item.nomeIvaVenda;
    this.configAdiantamentos.taxaIvaVenda = item.taxaIvaVenda;
    this.configAdiantamentos.codIvaCompra = item.codIvaCompra;
    this.configAdiantamentos.nomeCodIvaCompra = item.nomeIvaCompra;
    this.configAdiantamentos.taxaIvaCompra = item.taxaIvaCompra;
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_FAMILIA] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_ARTIGOS]))) {
      if (!this.configAdiantamentos.familiaBase) {
        this._plAlertService.error('adiantamentos.modals.autoConfig.steps.familiaBase.familiaEmpty');
        return Promise.resolve(false);
      }
      this._adiantamentosService.loadAutoConfigArtigos(this.configAdiantamentos).then((response: HttpResponse<IJsonAdiantamentoConfig>) => {
        this.configAdiantamentos = response.body;
      });
    }
    return Promise.resolve(true);
  }
}
