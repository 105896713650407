import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {isFunction, TValueOrPromise} from 'pl-comps-angular';
import {ETypeKind} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IConfigsErpEditConfigERP} from '../configedit/configs.erp.edit.component.interface';

@Component({
  selector: 'config-value',
  templateUrl: './configs.erp.editvalue.component.html'
})
export class ConfigValueComponent implements OnChanges {
  @Input() public object: IConfigsErpEditConfigERP;
  @Input() public onValidate: (value: unknown) => TValueOrPromise<unknown>;

  public readonly typeKinds: typeof ETypeKind;
  public type: string;

  constructor() {
    this.typeKinds = ETypeKind;
  }

  public ngOnChanges({object}: SimpleChanges): void {
    if (object) {
      this._setObjectType(object.currentValue);
    }
  }

  public onValueChange(value: unknown): void {
    this.object.value = value;
    this._onValidateValue();
  }

  public readonly fnOnValidateValue = (): void | Promise<unknown> => this._onValidateValue();

  private _onValidateValue(): void | Promise<unknown> {
    if (isFunction(this.onValidate)) {
      return Promise.resolve(this.onValidate(this.object));
    }
    return undefined;
  }

  private _setObjectType(object: IConfigsErpEditConfigERP): void {
    if (object.cguid) {
      this.type = object.cguid;
      return;
    }
    let type: string;

    switch (object.kind) {
      case ETypeKind.Integer:
        type = 'integer';
        break;
      default:
        type = 'text';
    }
    this.type = type;
  }
}
