import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DemFinSNCService} from '../../demFinSNC.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDemFinSNCRubricaMain, IJsonDemFinSNCSaved} from '../../jsonDemFinSNC.module.interface';
import {isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDemFinSNCRubricaSaved, TABLE_LEGEND_DEM_FIN_SNC_MODAL_LEGEND} from '../../demFinSNC.module.interface';
import {merge} from 'lodash-es';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';

@Component({
  selector: 'modal-demfinsnc-list-saved',
  templateUrl: './demFinSNC.listSaved.modal.component.html'
})
export class DemFinSNCListSavedModalComponent extends CGModalComponent<IDemFinSNCRubricaSaved> implements OnInit {
  @Input() public listSaved: Array<IJsonDemFinSNCSaved>;
  @Input() public idDemFinSNC: number;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly demFinSNCModalLegend: TTableLegend;

  public selectedLine: IJsonDemFinSNCSaved;
  public disableBtns: boolean;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _demFinSNCService: DemFinSNCService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ano', dataType: 'number', caption: 'global.text.year'},
        {dataField: 'data', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'hora', dataType: 'date', format: 'longTime', caption: 'demfinsnc.modal.listsaved.hora'},
        {dataField: 'obs', dataType: 'string', caption: 'demfinsnc.modal.listsaved.obs'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsListSaved'}
      ],
      dataSource: [],
      export: {filename: 'demfinsnc.modal.titlelistsaved'},
      keyExpr: 'idDemFinSNC',
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'templateToolbarListSaved',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.idDemFinSNC = 0;
    this.disableBtns = false;
    this.demFinSNCModalLegend = TABLE_LEGEND_DEM_FIN_SNC_MODAL_LEGEND;
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.listSaved;
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._demFinSNCService
      .getDemFinSNCByIDSaved(this.selectedLine.idDemFinSNC)
      .then((response: HttpResponse<IJsonDemFinSNCRubricaMain>) => {
        this.enableClose();
        const demFinSNCRubricaSaved: IDemFinSNCRubricaSaved = merge({}, response.body, {
          ano: this.selectedLine.ano,
          obs: this.selectedLine.obs,
          isProcessaIntercalares: this.selectedLine.intercalar,
          periodoIntercalares: this.selectedLine.periodoIntercalar
        });
        super.close(demFinSNCRubricaSaved);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDemFinSNCSaved>): void {
    if (event.rowType === 'data' && isNumber(this.idDemFinSNC) && this.idDemFinSNC === event.data.idDemFinSNC) {
      event.cellElement.classList.add('cyan-legend-color');
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonDemFinSNCSaved>): void {
    if (event.rowType === 'data') {
      this.selectedLine = event.data;
    }
  }

  public onCellDblClick(event: IDevExpressDataGridEventOnCellDblClick<IJsonDemFinSNCSaved>): Promise<void> {
    if (event.rowType === 'data') {
      this.selectedLine = event.data;
      if (this.selectedLine) {
        this.disableBtns = true;
        this._dataGridInstance.beginCustomLoading(undefined);
        return this.close().finally(() => {
          this._dataGridInstance.endCustomLoading();
          this.disableBtns = false;
        });
      }
    }
    return Promise.resolve();
  }

  public readonly fnDeleteSavedItem: (item: IJsonDemFinSNCSaved) => () => Promise<void> = (item: IJsonDemFinSNCSaved) => (): Promise<void> => this._deleteSavedItem(item);

  private _deleteSavedItem(item: IJsonDemFinSNCSaved): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'demfinsnc.modal.message.delidsaved').then(() => {
      return this._demFinSNCService.deleteDemFinSNCByIDSaved(item.idDemFinSNC).then(() => {
        const index: number = this.listSaved.findIndex((value: IJsonDemFinSNCSaved) => value.idDemFinSNC === item.idDemFinSNC);
        if (index !== -1) {
          if (this.selectedLine.idDemFinSNC === this.listSaved[index].idDemFinSNC) {
            this.selectedLine = undefined;
          }
          this.listSaved.splice(index, 1);
          this.dataGridDefinition.dataSource = this.listSaved;
          this._plAlertService.success('demfinsnc.modal.message.delversaodemfinant');
        }
      });
    });
  }
}
