import {IJsonApuraIva} from './jsonApuraIva.module.interface';
import {UntypedFormGroup} from '@angular/forms';

export type TApuraIvaSaveModalResult = 'save' | 'doc';

export enum EWizardApuramentoIVA {
  STEP_FILTRO_APURA,
  STEP_APURA_PERIODO,
  STEP_FIM_PERIODO
}

export interface IApuraIvaModel extends IJsonApuraIva {
  descritivo: number;
  descricao: string;
  nomeDescritivo: string;
  nomeDiario: string;
}

export interface IApuraIvaForms {
  step1: UntypedFormGroup;
  step2: UntypedFormGroup;
  step3: UntypedFormGroup;
}
