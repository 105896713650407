import {ContabilidadeSvatComponent} from './components/contabilidade.svat.module.component';
import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';

export const MODULE_PCA_MANUTENCAO_SVAT: IModuleDefinition = {
  name: 'svat',
  state: {
    url: '/svat',
    component: ContabilidadeSvatComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.svat'
    }
  }
};
