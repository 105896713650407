import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_PRH_ENT_ALTERS} from '../../../../../entities/prhentalters/pRHEntAlers.entity.interface';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../../entities/prhservicos/pRHServicos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPRHEntAlter} from '../../../../../entities/prhentalters/jsonPRHEntAlter.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment from 'moment';
import {EDgempsFichaChangableFields, IDgempsFichaModel} from '../dgempsFicha.module.interface';
import {ApiUtilsService} from '../../../../../services/apiutils/apiutils.service';

@Component({
  selector: 'dgemps-ficha',
  templateUrl: './dgempsFicha.module.component.html'
})
export class DgempsFichaComponent extends ModuloComponent implements OnInit {
  @Input() public model: IDgempsFichaModel;

  public papel: string;
  public servico: string;
  public pedidoEfectuado: boolean;
  public globalPromise: Promise<void>;
  public ibanValid: boolean;

  private readonly _relServicoEmp: IEntityService<IJsonPRHServicoEmpregado>;
  private readonly _servicoEmp: IPRHServicosEntityService;
  private readonly _servicePRHEntAlters: IEntityService<IJsonPRHEntAlter>;
  private _colaboradorIni: IDgempsFichaModel;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _apiUtilsService: ApiUtilsService
  ) {
    super(_injector);
    this.ibanValid = true;
    this._relServicoEmp = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
    this._servicoEmp = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._servicePRHEntAlters = this._entityServiceBuilder.build(ENTITY_NAME_PRH_ENT_ALTERS);
    this.papel = 'Papel não definido';
    this.servico = 'Não definido';
    this.pedidoEfectuado = false;
    this.globalPromise = Promise.resolve();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._colaboradorIni = copy(this.model);
    this._servicoEmp.get({id: this.model.codServicoPRH}).then((response) => {
      this.servico = response.body.nome;
      this._relServicoEmp.query({pesquisa: `codEmp=${this.model.codEmp}`}).then((papeis) => {
        let papelaux = '';
        for (const item of papeis.body.list) {
          if (item.codServico === this.model.codServicoPRH) {
            papelaux = papelaux === '' ? item.papel.nome : `${papelaux} / ${item.papel.nome}`;
          }
        }
        if (papelaux) {
          this.papel = papelaux;
        }
      });
    });
  }

  public ibanChanged(iban: string): void {
    this.model.iban = iban;
    if (isDefinedNotNull(iban)) {
      this.globalPromise = this._apiUtilsService.validateIban(iban).then((response) => {
        this.ibanValid = response.body.valid;
        this.model.swift = response.body.swift;
      });
    }
  }

  public readonly fnAlterarCampos: () => Promise<void> = () => this._alterarCampos();

  private _alterarCampos(): Promise<void> {
    return new Promise<void>((resolve) => {
      const promises: Array<Promise<void>> = [];
      let auxPromise = Promise.resolve();
      if (this._colaboradorIni.iban !== this.model.iban) {
        auxPromise = new Promise((resolve1, reject) => {
          this._apiUtilsService.validateIban(this.model.iban).then((response) => {
            this.ibanValid = response.body.valid;
            if (response.body.valid) {
              promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.IBAN, this.model.iban, this._colaboradorIni.iban));
              resolve1();
            } else {
              this._plAlertService.error(this._translateService.instant('prhentalters.ibanInvalido', {iban: this.model.iban}));
              reject();
            }
          });
        });
      }

      auxPromise
        .then(() => {
          if (this._colaboradorIni.nome !== this.model.nome) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NOME, this.model.nome, this._colaboradorIni.nome));
          }
          if (this._colaboradorIni.apelido !== this.model.apelido) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.APELIDO, this.model.apelido, this._colaboradorIni.apelido));
          }
          if (this._colaboradorIni.morada !== this.model.morada) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.MORADA, this.model.morada, this._colaboradorIni.morada));
          }
          if (this._colaboradorIni.rua !== this.model.rua) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.RUA, this.model.rua, this._colaboradorIni.rua));
          }
          if (this._colaboradorIni.codPostal !== this.model.codPostal) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.COD_POSTAL, this.model.codPostal, this._colaboradorIni.codPostal));
          }
          if (this._colaboradorIni.nomeLocalida !== this.model.nomeLocalida) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NOME_LOCALIDA, this.model.nomeLocalida, this._colaboradorIni.nomeLocalida));
          }
          if (this._colaboradorIni.email !== this.model.email) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.EMAIL, this.model.email, this._colaboradorIni.email));
          }
          if (this._colaboradorIni.nTelefone !== this.model.nTelefone) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.NTELEFONE, this.model.nTelefone, this._colaboradorIni.nTelefone));
          }
          if (this._colaboradorIni.telemovelPart1 !== this.model.telemovelPart1) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.TELEMOVELPART1, this.model.telemovelPart1, this._colaboradorIni.telemovelPart1));
          }
          if (this._colaboradorIni.swift !== this.model.swift) {
            promises.push(this._postPRHEntAlter(EDgempsFichaChangableFields.SWIFT, this.model.swift, this._colaboradorIni.swift));
          }
          if (promises.length) {
            Promise.allSettled(promises).then((results) => {
              const haveRejected = isDefinedNotNull(results.find((r) => r.status === 'rejected'));
              if (haveRejected) {
                this._plAlertService.warning('prhentalters.foiEnviadoPedidoDeAlteracaoDadosMasAlgunsComErro');
              } else {
                this._plAlertService.success('prhentalters.foiEnviadoPedidoDeAlteracaoDados');
              }
              resolve();
            });
          } else {
            this._plAlertService.info('prhentalters.naoExistemAlteracoesParaGuardar');
            resolve();
          }
        })
        .catch(resolve);
    });
  }

  private _postPRHEntAlter(campo: EDgempsFichaChangableFields, valor: string, valorAntigo: string): Promise<void> {
    const rootProp = [EDgempsFichaChangableFields.SWIFT, EDgempsFichaChangableFields.IBAN].includes(campo) ? 'dgempsfull' : 'dgemps';
    const entidade = [EDgempsFichaChangableFields.SWIFT, EDgempsFichaChangableFields.IBAN].includes(campo) ? 'DHEMP' : 'DGEMP';
    return this._servicePRHEntAlters
      .post({
        body: {
          codPrhEntAlter: -1,
          nomeEmp: undefined,
          entidade: entidade,
          campo: campo,
          descricaoCampo: this._translateService.instant(`${rootProp}.fields.${campo}`),
          tipo: 0,
          valor: valor,
          estado: 0,
          codEmp: this.model.codEmp,
          codEmpAlterou: this.model.codEmp,
          dataAlterou: moment(),
          codEmpAprovou: 0,
          dataAprovou: moment(),
          valorAntigo: valorAntigo
        }
      })
      .then(() => undefined)
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this._plAlertService.error(this._translateService.instant('prhentalters.erroDoCampo', {campo: this._translateService.instant(`${rootProp}.fields.${campo}`)}));
      });
  }
}
