import {toInteger} from 'pl-comps-angular';
import {IJsonDashboards, IJsonDashboardsValor} from '../../interfaces/jsonDashboards.interface';
import {monthNameShort} from '../../../common/dates';

export enum ETipoDashboard {
  DIA,
  MES,
  ANO,
  TOTAL
}

export interface IDashboardsERPValor extends IJsonDashboardsValor {
  mes?: string;
  label?: string;
  labelEx?: string;
  nomeEx?: string;
}

export interface IDashboardsERP extends IJsonDashboards {
  valores: Array<IDashboardsERPValor>;
}

export function parseDashboardsERP(dashboards: IJsonDashboards): IDashboardsERP {
  return {
    ...dashboards,
    valores: dashboards.valores.map<IDashboardsERPValor>((item: IJsonDashboardsValor) => {
      const month: number = toInteger(item.codigo) - 1; // no JS o jan é 0
      const mes: string = monthNameShort(month).toLowerCase();
      const valor: IDashboardsERPValor = {
        ...item,
        codigo: String(month),
        mes: mes,
        label: mes,
        labelEx: ''
      };
      const aux = item.nome.split('/');
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (aux.length > 1 && Number(aux[1]) > 1900) {
        valor.labelEx = `${valor.label} ${aux[1]}`;
        valor.nomeEx = `${valor.label} ${aux[1]}`;
      }
      return valor;
    })
  };
}
