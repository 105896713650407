export enum EConfigsPCAPeriodo {
  Todos,
  AtePeriodoAtivoEmpresa,
  AteUltimoPeriodoEncerrado
}

export interface IConfigsPCAPeriodoItem {
  value: EConfigsPCAPeriodo;
  name: string;
}

export const MODULE_NAME_CONFIGS_PCA = 'configsPCA';
