import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_TRIBUT_AUTONOMAS} from '../tributAutonomas.module.interface';
import {TributAutonomasModuleService} from '../tributAutonomas.module.service';
import {IJsonTributAutonoAnaliseHeader} from '../jsonTributAutonomas.module.interface';
import {HttpResponse} from '@angular/common/http';
import {TributAutonomasModuleComponent} from './components/tributAutonomas.module.component';

export const MODULE_TRIBUT_AUTONOMAS_ANALISE: IModuleDefinition = {
  name: MODULE_NAME_TRIBUT_AUTONOMAS,
  state: {
    url: `/${MODULE_NAME_TRIBUT_AUTONOMAS}`,
    component: TributAutonomasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.tributautonomas'
    },
    params: {
      nConta: {
        type: 'string',
        value: ''
      }
    },
    resolve: [
      {
        provide: 'header',
        deps: [TributAutonomasModuleService],
        useFactory: (tributAutonomasModuleService: TributAutonomasModuleService): Promise<IJsonTributAutonoAnaliseHeader> => {
          return tributAutonomasModuleService.loadDefaultAnaliseHeader().then((response: HttpResponse<IJsonTributAutonoAnaliseHeader>) => response.body);
        }
      }
    ]
  }
};
