const KEY_STR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const MATCH_BASE64 = 'data:image/jpeg;base64,';
const REGEX_MATCH_BASE64 = new RegExp(MATCH_BASE64);

/* eslint-disable no-bitwise, @typescript-eslint/no-magic-numbers */
export class ExifRestore {
  public static restore(originalFileBase64: string, resizedFileBase64: string): string {
    if (!REGEX_MATCH_BASE64.exec(originalFileBase64)) {
      return resizedFileBase64;
    }
    const rawImage = ExifRestore._decode64(originalFileBase64.replace(REGEX_MATCH_BASE64, ''));
    const segments = ExifRestore._slice2Segments(rawImage);
    const image = ExifRestore._exifManipulation(resizedFileBase64, segments);
    return MATCH_BASE64 + ExifRestore._encode64(image);
  }

  private static _encode64(input: Uint8Array): string {
    let output = '';
    let chr1: string | number;
    let chr2: string | number;
    let chr3: string | number;
    let enc1: string | number;
    let enc2: string | number;
    let enc3: string | number;
    let enc4: string | number;
    let i = 0;
    for (; ;) {
      chr1 = input[i++];
      chr2 = input[i++];
      chr3 = input[i++];
      enc1 = chr1 >> 2;
      enc2 = (chr1 & 3) << 4 | chr2 >> 4;
      enc3 = (chr2 & 15) << 2 | chr3 >> 6;
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      }
      else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output = output + KEY_STR.charAt(enc1) + KEY_STR.charAt(enc2) + KEY_STR.charAt(enc3) + KEY_STR.charAt(enc4);
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
      if (!(i < input.length)) {
        break;
      }
    }
    return output;
  }

  private static _decode64(input: string): Array<number> {
    let chr1: string | number;
    let chr2: string | number;
    let chr3: string | number;
    let enc1: string | number;
    let enc2: string | number;
    let enc3: string | number;
    let enc4: string | number;
    let i = 0;
    const buffer: Array<number> = [];
    // Remove all characters that are not A-Z, a-z, 0-9, +, /, or =
    const base64test = /[^A-Za-z0-9+/=]/g;
    if (base64test.exec(input)) {
      // eslint-disable-next-line no-console
      console.warn([
        'There were invalid base64 characters in the input text.',
        'Valid base64 characters are A-Z, a-z, 0-9, \'+\', \'/\' and \'=\'.',
        'Expect errors in decoding.'
      ].join('\n'));
    }
    input = input.replace(/[^A-Za-z0-9+/=]/g, '');
    for (; ;) {
      enc1 = KEY_STR.indexOf(input.charAt(i++));
      enc2 = KEY_STR.indexOf(input.charAt(i++));
      enc3 = KEY_STR.indexOf(input.charAt(i++));
      enc4 = KEY_STR.indexOf(input.charAt(i++));
      chr1 = enc1 << 2 | enc2 >> 4;
      chr2 = (enc2 & 15) << 4 | enc3 >> 2;
      chr3 = (enc3 & 3) << 6 | enc4;
      buffer.push(chr1);
      if (enc3 !== 64) {
        buffer.push(chr2);
      }
      if (enc4 !== 64) {
        buffer.push(chr3);
      }
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
      if (!(i < input.length)) {
        break;
      }
    }
    return buffer;
  }

  private static _slice2Segments(rawImageArray: Array<number>): Array<Array<number>> {
    let head = 0;
    const segments: Array<Array<number>> = [];
    for (; ;) {
      if (rawImageArray[head] === 255 && rawImageArray[head + 1] === 218) {
        break;
      }
      if (rawImageArray[head] === 255 && rawImageArray[head + 1] === 216) {
        head += 2;
      }
      else {
        const length = rawImageArray[head + 2] * 256 + rawImageArray[head + 3];
        const endPoint = head + length + 2;
        const seg = rawImageArray.slice(head, endPoint);
        segments.push(seg);
        head = endPoint;
      }
      if (head > rawImageArray.length) {
        break;
      }
    }
    return segments;
  }

  private static _exifManipulation(resizedFileBase64: string, segments: Array<Array<number>>): Uint8Array {
    const exifArray = ExifRestore._getExifArray(segments);
    const newImageArray = ExifRestore._insertExif(resizedFileBase64, exifArray);
    return new Uint8Array(newImageArray);
  }

  private static _getExifArray(segments: Array<Array<number>>): Array<number> {
    let x = 0;
    while (x < segments.length) {
      const segment: Array<number> = segments[x];
      if (segment[0] === 255 && segment[1] === 225) {
        return segment;
      }
      x++;
    }
    return [];
  }

  private static _insertExif(resizedFileBase64: string, exifArray: Array<number>): Array<number> {
    const imageData: string = resizedFileBase64.replace(REGEX_MATCH_BASE64, '');
    const buffer: Array<number> = ExifRestore._decode64(imageData);
    const separatePoint: number = buffer.indexOf(255, 3);
    const mae: Array<number> = buffer.slice(0, separatePoint);
    const ato: Array<number> = buffer.slice(separatePoint);
    const array: Array<number> = mae
      .concat(exifArray)
      .concat(ato);
    return array;
  }
}

/* eslint-enable no-bitwise, @typescript-eslint/no-magic-numbers */
