import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {PlPromisesModule} from '../promises/promises.module';
import {PlTranslateModule} from '../translate/translate.module';
import {CGCIconDirective} from '../icon';

import {PlDashboardComponent} from './dashboard.component';

export * from './dashboard.component.interface';
export * from './dashboard.component';

const DECLARATIONS = [PlDashboardComponent];

@NgModule({
  imports: [CommonModule, NgbTooltipModule, PlPromisesModule, PlTranslateModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlDashboardModule {}
