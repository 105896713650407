import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IJsonEFaturaCirculacaoLogin} from './eFaturaCirculacaoLogin.service.interface';
import {TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EFaturaCirculacaoLoginService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/efaturacirculacaologin`;
  }

  public get(): TServiceResponse<IJsonEFaturaCirculacaoLogin> {
    return this._apiService.get<IJsonEFaturaCirculacaoLogin>({url: this._path});
  }

  public set(data: IJsonEFaturaCirculacaoLogin): TServiceResponse<IJsonEFaturaCirculacaoLogin> {
    return this._apiService.post<IJsonEFaturaCirculacaoLogin>({url: this._path, body: data});
  }
}
