import {ConfigsErpClifosModuleComponent} from './components/configs.clifos.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_CLIFOS} from './configs.clifos.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONFIGS_CLIFOS: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_CLIFOS,
  state: {
    url: '/configs/erp/clifos',
    component: ConfigsErpClifosModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'configsErp.items.clientesFornecedoresTitle'
    }
  }
};
