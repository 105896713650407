import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonCGASI} from './jsonCGASit.entity.interface';
import {ENTITY_NAME_CGASI} from './cgasit.entity.interface';

export const ENTITY_CGASI: IEntityDefinition<IJsonCGASI> = {
  name: ENTITY_NAME_CGASI,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.cgasi',
  searchPlaceholder: 'cgasi.pesquisa',
  metadata: {
    keyName: 'codCGASit',
    fields: [
      {name: 'codCGASit', caption: 'cgasi.fields.codCGASit', validators: {required: true, maxlength: 4}},
      {name: 'nomeCGASit', caption: 'cgasi.fields.nomeCGASit', validators: {required: true, maxlength: 75}},
      {name: 'tipoCodCGASit', type: 'cgsmallint', caption: 'cgasi.fields.tipoCodCGASit'},
      {name: 'obs', caption: 'cgasi.fields.obs', validators: {maxlength: 150}}
    ],
    order: 'codCGASit',
    searchFields: 'codCGASit,nomeCGASit'
  },
  autocomplete: {
    rowTemplate: '{{codCGASit}} - {{nomeCGASit}}',
    output: 'codCGASit',
    searchFields: 'codCGASit,nomeCGASit'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
