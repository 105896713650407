<div class="demfinsnc-preview-pdf-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'demfinsnc.modal.titlepreviewpdf'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-group>
      <label [translate]="'global.text.listing'"></label>
      <edit>
        <pl-edit type="reports_dem_fin_snc" attrName="listagem" [model]="reportModel" (modelChange)="reportChanged($event)" [properties]="propertiesReport"></pl-edit>
      </edit>
    </pl-group>

    <cg-pdf-viewer [pdfSrc]="pdfSrc" [filename]="reportName" [contentType]="reportContentType"></cg-pdf-viewer>
  </div>

  <div class="modal-footer">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
