<div class="meios-pagamento-list" [plPromise]="promise">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [toolbarInstanceId]="instanceName"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <div ngbDropdown container="body" class="col-main-table-actions">
        <pl-tooltip [config]="{text: 'meiosPagamento.table.actions.alterarPorDefeito', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
        </pl-tooltip>
        <div ngbDropdownMenu class="meios-pagamento">
          <div><span [translate]="'meiosPagamento.table.actions.porDefeitoPara'"></span></div>
          <div *ngIf="item.data.tipoMovimento !== tiposMeiosPagamento.Recebimento">
            <pl-edit
              type="checkbox"
              [model]="item.data.porDefeito === 'Pagamentos' || item.data.porDefeito === 'Pagamentos | Recibos'"
              (modelChange)="onPagamentosOmissaoChanged($event, item.data)"
              [properties]="{label: 'meiosPagamento.table.actions.pagamentos', events: {beforeChange: fnBeforeChangePagamentosPorDefeito}}">
            </pl-edit>
          </div>
          <div *ngIf="item.data.tipoMovimento !== tiposMeiosPagamento.Pagamento">
            <pl-edit
              type="checkbox"
              [model]="item.data.porDefeito === 'Recibos' || item.data.porDefeito === 'Pagamentos | Recibos'"
              (modelChange)="onRecebimentoOmissaoChanged($event, item.data)"
              [properties]="{label: 'meiosPagamento.table.actions.recibos', events: {beforeChange: fnBeforeChangeRecibosPorDefeito}}">
            </pl-edit>
          </div>
        </div>
      </div>
    </div>
  </entity-list>
</div>
