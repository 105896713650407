import {Subscription} from 'rxjs';
import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarInstance, IPlToolbarItem, isBoolean, isObject, PlAlertService, PlDocumentService, PlToolbarService, PlTranslateService, timeout} from 'pl-comps-angular';
import {ClientConnectService} from '../../../../../services/clientconnect/clientconnect.service';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {ContabilidadeDigitalUIService} from '../../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {ClientConnectUIService} from '../../../../../services/clientconnect/clientconnect.ui.service';
import {ContabilidadeDigitalServiceDocuments} from '../../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {
  IContabilidadeDigitalGenericViewerCallback,
  IContabilidadeDigitalGenericViewerEvtFileItemChanged,
  IContabilidadeDigitalGenericViewerEvtFolderItemChanged,
  IContabilidadeDigitalGenericViewerFile,
  IContabilidadeDigitalGenericViewerFolder,
  IContabilidadeDigitalGenericViewerFolderPath
} from '../../../common/viewers/contabilidadedigital.genericviewer.interface';
import {CGExceptionService} from '../../../../exceptions/exceptions.service';
import {CGModalService} from '../../../../cg/modal/cgmodal.service';
import {EFolderDoc} from '../../../../../entities/gdoc/gdoc.entity.interface';
import {ContabilidadeDigitalClientConnectDeleteDocsModalComponent} from '../modals/deletedocsmodal/contabilidadedigital.clientconnect.deletedocsmodal.component';
import {ICGExceptionError} from '../../../../exceptions/exceptions.service.interface';
import {ContabilidadeDigitalClientConnectAddDocsModalComponent} from '../modals/adddocsmodal/contabilidadedigital.clientconnect.adddocsmodal.component';
import {ContabilidadeDigitalClientConnectInspectDocModalComponent} from '../modals/inspectdocmodal/contabilidadedigital.clientconnect.inspectdocmodal.component';

const TOOLBAR_GROUP_ID = 'contabilidade-digital-client-connect-native-toolbar';
const CAPTION_BTN_ADD_DOCS = 'arquivodigital.clientconnect.actions.addDocs';
const CAPTION_BTN_CAPTURE_DOC = 'arquivodigital.clientconnect.actions.captureDoc';
const CAPTION_BTN_DELETE_DOC = 'arquivodigital.clientconnect.actions.deleteDoc.btn';
let TOOLBAR_ID = 0;

@Component({
  selector: 'contabilidade-digital-client-connect-native',
  templateUrl: './contabilidadedigital.clientconnect.native.component.html',
  providers: [
    {provide: ContabilidadeDigitalService, useExisting: ClientConnectService},
    {provide: ContabilidadeDigitalUIService, useExisting: ClientConnectUIService}
  ]
})
export class ContabilidadeDigitalClientConnectNativeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public toolbarInstanceId: string;

  public readonly documentsService: ContabilidadeDigitalServiceDocuments;
  public readonly callback: IContabilidadeDigitalGenericViewerCallback;
  public readonly defaultFolderId: string;
  public readonly upwardsUntilFolderId: string;
  public docviewerToolbarId: string;
  public flatViewerView: IContabilidadeDigitalGenericViewerFolder;
  public docID: string;
  public currentFolderPath: Array<IContabilidadeDigitalGenericViewerFolderPath>;
  public error: string;
  public criticalError: boolean;
  public treeViewerScrollToTopVisible: boolean;
  public isMobile: boolean;

  private readonly _btnAddDocs: IPlToolbarItem;
  private readonly _btnCaptureDocs: IPlToolbarItem;
  private readonly _btnDeleteDoc: IPlToolbarItem;
  private readonly _subscriptionIsMobile: Subscription;
  private _toolbar: IPlToolbarInstance;
  private _toolbarOwned: boolean;
  private _selectedFolder: IContabilidadeDigitalGenericViewerFolder;
  private _selectedFile: IContabilidadeDigitalGenericViewerFile;
  private _allowSendFiles: boolean;
  private _allowDeleteFile: boolean;
  private _elementGenericViewer: HTMLElement;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgModalService: CGModalService,
    private readonly _clientConnectUIService: ClientConnectUIService
  ) {
    this.documentsService = this._clientConnectUIService.documents;
    this.callback = {};
    this.currentFolderPath = [];
    this.criticalError = false;
    this.treeViewerScrollToTopVisible = false;
    this.defaultFolderId = EFolderDoc.DefaultFolderContabNaoClassificados;
    this.upwardsUntilFolderId = EFolderDoc.DefaultFolderContabilidade;
    this.docviewerToolbarId = `${TOOLBAR_GROUP_ID}-doc-viewer-${++TOOLBAR_ID}`;
    this._btnAddDocs = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'add-docs',
      type: 'button',
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>',
      caption: CAPTION_BTN_ADD_DOCS,
      visible: true,
      disabled: true,
      tooltip: {
        disabled: false,
        text: 'arquivodigital.clientconnect.tooltips.addDocs'
      },
      click: () => this._addDocs()
    };
    this._btnCaptureDocs = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'capture-doc',
      type: 'button',
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-camera"></i>',
      caption: CAPTION_BTN_CAPTURE_DOC,
      visible: true,
      disabled: true,
      tooltip: {
        disabled: false,
        text: 'arquivodigital.clientconnect.tooltips.captureDoc'
      },
      click: () => this._captureDoc()
    };
    this._btnDeleteDoc = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'delete-doc',
      type: 'button',
      class: 'btn-danger',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      caption: CAPTION_BTN_DELETE_DOC,
      visible: true,
      disabled: true,
      tooltip: {
        disabled: false,
        text: 'arquivodigital.clientconnect.tooltips.deleteDoc'
      },
      click: () => this._deleteDoc()
    };
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
      this._btnAddDocs.caption = !this.isMobile ? CAPTION_BTN_ADD_DOCS : '';
      this._btnCaptureDocs.caption = !this.isMobile ? CAPTION_BTN_CAPTURE_DOC : '';
      this._btnCaptureDocs.visible = this.isMobile;
      this._btnDeleteDoc.caption = !this.isMobile ? CAPTION_BTN_DELETE_DOC : '';
    });
    this._toolbarOwned = false;
    this._allowSendFiles = false;
    this._allowDeleteFile = false;
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({toolbarInstanceId}: SimpleChanges): void {
    if (toolbarInstanceId && !toolbarInstanceId.isFirstChange()) {
      this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionIsMobile.unsubscribe();
    this._cleanupToolbar();
  }

  public setFolder(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): void {
    this.callback.setSelectedFolder(folderIDOrItem);
  }

  public changedFolder(event: IContabilidadeDigitalGenericViewerEvtFolderItemChanged): void {
    this._selectedFolder = event.folder;
    this._selectedFile = undefined;
    this._evaluateActions();
    this._evaluateCurrentFolderPath();
  }

  public changedFile(event: IContabilidadeDigitalGenericViewerEvtFileItemChanged): void {
    this._selectedFile = event.file;
    this._selectedFolder = this._selectedFile.parentFolder;
    this.docID = this._selectedFile.docID;
    this._evaluateActions();
    this._evaluateCurrentFolderPath();
    if (this.isMobile) {
      this._inspectDoc(this.docID);
    }
  }

  public onActionAdd(): Promise<void> {
    return this._addDocs();
  }

  public onActionCapture(): Promise<void> {
    return this._captureDoc();
  }

  public onActionDeleteDocs(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabilidadeDigitalClientConnectDeleteDocsModalComponent);
    const componentInstance: ContabilidadeDigitalClientConnectDeleteDocsModalComponent = modalInstance.componentInstance;
    componentInstance.folderId = this._selectedFolder.folderID;
    componentInstance.foldersNamePath = this._getFoldersNamePath();
    return modalInstance.result.then(() => {
      let promise: Promise<void> = this._clientConnectUIService.deleteFolderDocuments(this._selectedFolder.folderID).then(() => {
        promise = this.callback.refreshFolder(this._selectedFolder.parentFolder).then(() => undefined);
        this._setSelectedFolderPromise(promise);
        return promise;
      });
      this._setSelectedFolderPromise(promise);
      return promise;
    });
  }

  public onActionDeleteDoc(): Promise<void> {
    return this._deleteDoc();
  }

  public responseErrored(error: HttpErrorResponse): void {
    this.error = undefined;
    const exception: ICGExceptionError = this._cgExceptionService.get(error);
    if (exception) {
      this.error = exception.message;
      this.criticalError = exception.class === 'EContabDigitalLicenseException';
    }
  }

  public treeViewerScrolled(event: Event): void {
    const elementGenericViewer: HTMLElement = <HTMLElement>event.target;
    this.treeViewerScrollToTopVisible = elementGenericViewer.scrollTop > 0;
  }

  public treeViewerScrollToTop(): void {
    if (this._elementGenericViewer) {
      this._elementGenericViewer.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  @ViewChild('elementTreeViewer')
  public set elementTreeViewer(value: ElementRef<HTMLElement>) {
    this._elementGenericViewer = value?.nativeElement;
  }

  private _handleChanges(): void {
    this._changedToolbarInstanceId();
  }

  private _changedToolbarInstanceId(value: string = this.toolbarInstanceId): void {
    this._cleanupToolbar();
    this._toolbarOwned = !this._plToolbarService.isRegistered(value);
    this._toolbar = this._plToolbarService.getInstance(value);
    this._toolbar.removeGroupId(TOOLBAR_GROUP_ID).addButton(this._btnAddDocs).addButton(this._btnCaptureDocs).addButton(this._btnDeleteDoc);
  }

  private _evaluateActions(): void {
    this._allowSendFiles = isObject(this._selectedFolder) && !this._selectedFolder.hasSubFolders;
    // Verificar se não é `Documentos Classificados`
    const isClassificado: boolean = this._clientConnectUIService.isFolderFromDocsClassificados(this._selectedFolder);
    if (isClassificado) {
      this._allowSendFiles = false;
    }
    this._btnAddDocs.disabled = !this._allowSendFiles;
    this._btnAddDocs.tooltip.disabled = !this._btnAddDocs.disabled;
    this._btnCaptureDocs.disabled = this._btnAddDocs.disabled;
    this._btnCaptureDocs.tooltip.disabled = this._btnAddDocs.tooltip.disabled;
    this._allowDeleteFile = isObject(this._selectedFile) && !isClassificado;
    this._btnDeleteDoc.disabled = !this._allowDeleteFile;
    this._btnDeleteDoc.tooltip.disabled = !this._btnDeleteDoc.disabled;
  }

  private _evaluateCurrentFolderPath(): void {
    this.currentFolderPath = this._getFoldersPath();
  }

  private _getFoldersPath(): Array<IContabilidadeDigitalGenericViewerFolderPath> {
    return this._clientConnectUIService.getFoldersPath(this._selectedFolder);
  }

  private _getFoldersNamePath(): Array<string> {
    return this._clientConnectUIService.getFoldersNamePath(this._selectedFolder);
  }

  private _addDocs(openFileDialogOnInit: boolean = false): Promise<void> {
    if (!this._allowSendFiles) {
      return Promise.resolve();
    }
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeDigitalClientConnectAddDocsModalComponent, {size: 'lg'});
    const componentInstance: ContabilidadeDigitalClientConnectAddDocsModalComponent = modalInstance.componentInstance;
    componentInstance.documentsService = this.documentsService;
    componentInstance.folderId = this._selectedFolder.folderID;
    componentInstance.foldersNamePath = this._getFoldersNamePath();
    componentInstance.openFileDialogOnInit = openFileDialogOnInit;
    return new Promise<void>((resolve) => {
      let needsRefresh = false;
      modalInstance.result
        .then(() => {
          needsRefresh = true;
        })
        .catch((reason: unknown) => {
          if (isBoolean(reason)) {
            needsRefresh = reason;
          }
        })
        .finally(() => {
          let promise: Promise<void>;
          if (needsRefresh) {
            this._plAlertService.success('arquivodigital.clientconnect.adddocsmodal.success');
            promise = this.callback.refreshFolder(this._selectedFolder.parentFolder).then(() => undefined);
            this._setSelectedFolderPromise(promise);
          }
          Promise.resolve(promise).finally(resolve);
        });
    });
  }

  private _captureDoc(): Promise<void> {
    return this._addDocs(true);
  }

  private _deleteDoc(): Promise<void> {
    if (!this._allowDeleteFile) {
      return Promise.resolve();
    }
    const title: string = this._plTranslateService.translate('arquivodigital.clientconnect.actions.deleteDoc.title', {name: this._selectedFile.nome});
    return this._cgModalService.showOkCancel(title, 'arquivodigital.clientconnect.actions.deleteDoc.message').then(() => {
      return this.documentsService
        .delete(this._selectedFile.docID, this._selectedFile.folderID)
        .then(() => {
          this._plAlertService.success('arquivodigital.clientconnect.text.successDeleteDoc');
          this._selectedFile = undefined;
          const promise: Promise<void> = this.callback.refreshFolder(this._selectedFolder.parentFolder).then(() => undefined);
          this._setSelectedFolderPromise(promise);
          return promise;
        })
        .then(() => {
          timeout().then(() => {
            this._evaluateActions();
          });
        });
    });
  }

  private _inspectDoc(docID: string): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabilidadeDigitalClientConnectInspectDocModalComponent, {size: 'xxl'});
    const componentInstance: ContabilidadeDigitalClientConnectInspectDocModalComponent = modalInstance.componentInstance;
    componentInstance.documentsService = this.documentsService;
    componentInstance.docID = docID;
    return modalInstance.result;
  }

  private _setSelectedFolderPromise(value: Promise<void>): void {
    if (this._selectedFolder) {
      this._selectedFolder.promise = value;
      this._selectedFolder.promise.finally(() => {
        this._selectedFolder.promise = undefined;
      });
    }
  }

  private _cleanupToolbar(): void {
    if (this._toolbar) {
      if (this._toolbarOwned) {
        this._plToolbarService.unRegisterInstance(this._toolbar);
        this._toolbarOwned = false;
      } else {
        this._toolbar.removeGroupId(TOOLBAR_GROUP_ID);
      }
      this._toolbar = undefined;
    }
  }
}
