import {HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {EnvironmentProviders, Injectable, makeEnvironmentProviders} from '@angular/core';

export function provideHammerConfig(): EnvironmentProviders {
  return makeEnvironmentProviders([{provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig}]);
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  constructor() {
    super();
    this.overrides = {};
  }
}
