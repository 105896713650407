import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonIrsDt} from './jsonIrsDt.entity.interface';
import {ENTITY_NAME_IRSDT} from './irsDt.entity.interface';

export const ENTITY_IRSDT: IEntityDefinition<IJsonIrsDt> = {
  name: ENTITY_NAME_IRSDT,
  roles: [ROLE.RH],
  searchPlaceholder: 'irsdt.pesquisa',
  metadata: {
    keyName: 'tabIRSDT',
    fields: [{name: 'tabIRSDT', caption: 'irsdt.fields.tabIRSDT', validators: {required: true}}],
    order: 'tabIRSDT',
    searchFields: 'tabIRSDT'
  },
  autocomplete: {
    rowTemplate: '{{tabIRSDT}}',
    output: 'tabIRSDT',
    searchFields: 'tabIRSDT'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.tirs'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.tirs'
      }
    }
  }
};
