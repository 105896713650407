import {Component, Injector} from '@angular/core';
import {isUndefinedOrNull} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {AtFaturasRecibosVerdesService} from '../../atFaturasRecibosVerdes.service';
import {IEntityAutocompleteCustomActionDefinition} from '../../../../../components/entity/entity.autocomplete.definition.interface';
import {MODULE_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../../manutencao/predefinidos/preDefinidosContab.module.interface';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {IJsonFRVConfig} from '../../jsonATFaturasRecibosVerdes.interface';

@Component({
  selector: 'at-frv-config-modal',
  templateUrl: './atFaturasRecibosVerdes.config.modal.html'
})
export class AtFaturasRecibosVerdesConfigModalComponent extends CGModalComponent<void> {
  public readonly promise: Promise<void>;
  public model: IJsonFRVConfig;

  public readonly customActionPreDefinido: IEntityAutocompleteCustomActionDefinition;
  private _maintenancePreDefinidos: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atFaturasRecibosVerdesService: AtFaturasRecibosVerdesService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this.model = {preDefinidoId: undefined, preDefinidoStr: undefined};
    this.promise = this._atFaturasRecibosVerdesService.getConfig().then((response: IJsonFRVConfig) => {
      this.model = response;
    });

    this.customActionPreDefinido = {
      caption: 'docscontabilidade.text.maintenancePreDefinidos',
      action: (selectedKey: number) => {
        if (!this._maintenancePreDefinidos) {
          this._maintenancePreDefinidos = this._moduleMaintenanceService.build(MODULE_NAME_CONTABILIDADE_PREDEFINIDOS);
        }
        return this._maintenancePreDefinidos.maintenance({params: {editPreDefinidosID: selectedKey}});
      }
    };
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._atFaturasRecibosVerdesService
      .saveConfig(this.model.preDefinidoId)
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public onPreDefinidoChanged(value: number): void {
    this.model.preDefinidoId = isUndefinedOrNull(value) ? 0 : value;
  }
}
