import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_PROC_AUTO_TIPO} from './procAutoTipo.datasource.interface';
import {EProcAutoTipo} from '../../entities/classescontratos/jsonClasseContrato.entity.interface';

export const DATA_SOURCE_PROC_AUTO_TIPO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_PROC_AUTO_TIPO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EProcAutoTipo.None, name: 'contratostiposervicos.datasource.none'},
    {value: EProcAutoTipo.PrimeiroDiaUtil, name: 'contratostiposervicos.datasource.primeiroDiaUtil'},
    {value: EProcAutoTipo.UltimoDiaUtil, name: 'contratostiposervicos.datasource.ultimoDiaUtil'},
    {value: EProcAutoTipo.PrimeiroDiaDoPeriodo, name: 'contratostiposervicos.datasource.primeiroDiaDoPeriodo'},
    {value: EProcAutoTipo.UltimoDiaPeriodo, name: 'contratostiposervicos.datasource.ultimoDiaPeriodo'},
    {value: EProcAutoTipo.DiaFixo, name: 'contratostiposervicos.datasource.diaFixo'}
  ]
});
