<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'contratosservicos.modal.faturacaoAuto.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <div class="card">
        <div class="card-header">
          <span [translate]="'contratosservicos.modal.faturacaoAuto.cards.configurar'"></span>
        </div>
        <div class="card-body">
          <pl-group>
            <label [translate]="'contratosservicos.fields.cab.nClasseCtr'"></label>
            <edit>
              <entity-autocomplete
                attrName="classesContratosServicos"
                entity="classescontratosservicos"
                [model]="classes"
                (modelChange)="onClasseContratoChanged($event)"
                [(selectedKey)]="nClasseCtr"
                [output]="'{{nClasseCtr}} - {{nomeClasseCtr}}'"
                outputKey="nClasseCtr"
                [properties]="{disabled: temParamClasseContrato}"
                plAutoFocus
                [plAutoFocusDisabled]="temParamClasseContrato">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'contratosservicos.fields.cab.nTipoContrato'"></label>
            <edit>
              <entity-autocomplete
                attrName="contratosTipos"
                entity="contratostiposervicos"
                [model]="tipos"
                (modelChange)="onContratoTipoAvenca($event)"
                [validateFn]="fnOnTipoContratoValidate"
                [output]="'{{nTipoContrato}} - {{nome}}'"
                outputKey="nTipoContrato"
                [filter]="filterTipoContrato"
                plAutoFocus
                [plAutoFocusDisabled]="!temParamClasseContrato">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-warning action-next" (click)="close()" [disabled]="closeDisabled" data-focus plPromise>
      <i class="fa fa-fw fa-step-forward"></i>&nbsp;<span [translate]="'contratosservicos.btn.configurar'"></span>
    </button>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()" [disabled]="closeDisabled">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </button>
  </div>
</pl-form>
