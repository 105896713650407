<div class="modal-body">
  <div class="finalized-icon"><i class="fa fa-check-circle-o" aria-hidden="true"></i></div>
  <div [translate]="'importadorSaftDirect.messages.finalizedMessageModal'"></div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm action-close" (evtClicked)="close()" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.messages.finalizedCloseModal'"></span>
  </pl-button>
</div>
