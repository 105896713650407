import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_VALIDACAO_NIF} from './tipoValidacaoNif.datasource.interface';
import {EControloIvaTipoValidacaoNif} from '../../entities/controloiva/jsonControloIVA.entity.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_VALIDACAO_NIF: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_VALIDACAO_NIF,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EControloIvaTipoValidacaoNif.NA, name: 'tipoValidacaoNif.data.notApplicable'},
    {value: EControloIvaTipoValidacaoNif.ObrigaNifValido, name: 'tipoValidacaoNif.data.requiresValidNIF'},
    {value: EControloIvaTipoValidacaoNif.ObrigaNIfValidoPermiteCF, name: 'tipoValidacaoNif.data.requiresValidNIFAllowsInexistent'},
    {value: EControloIvaTipoValidacaoNif.NaoObrigaNIF, name: 'tipoValidacaoNif.data.doesNotRequireNIF'}
  ]
});
