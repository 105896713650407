import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  ETipoPerfilBalancetes,
  IColumnBalanceteTotal,
  IDynamicBalancete,
  IHeaderFieldBalancete,
  IYearBalancete
} from '../../../modules/portalcontabilidade/estatistica/balancetes/contabilidade.balancetes.module.interface';
import {IDevExpressDataGrid} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridExportExcelCustomizeCellOptions} from '../../devexpress/datagrid/export/devexpress.datagrid.export.interface';
import {ETipoContaContabilidade} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IPlDynamicVisualsSecondaryClickAction, isMobile, isNumber, isObject} from 'pl-comps-angular';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IExtratosDTStateParams, MODULE_NAME_EXTRATOS_DT} from '../../../modules/portalcontabilidade/estatistica/extratosdt/extratosDT.module.interface';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../modules/portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO} from '../../../modules/portalcontabilidade/estatistica/movimentosemaberto/contabilidade.movimentosEmAberto.module.interface';
import {ENTITY_NAME_POCS} from '../../../entities/pocs/pocs.entity.interface';
import {ENTITY_NAME_CLIFOS} from '../../../entities/clifos/clifos.entity.interface';
import {IModuleMaintenanceInstance} from '../../entity/maintenance/module/module.maintenance.interface';
import {IEntityMaintenanceInstance} from '../../entity/maintenance/entity/entity.maintenance.interface';
import {ModuleMaintenanceService} from '../../entity/maintenance/module/module.maintenance.service';
import {EntityMaintenanceService} from '../../entity/maintenance/entity/entity.maintenance.service';
import {IJsonBalancete, IJsonBalanceteAnos, IJsonBalanceteCompl} from '../../../modules/portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';
import {IDictionary} from '../../../../common/interfaces/interfaces';
import {groupBy} from 'lodash-es';
import {round} from '../../../../common/utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {PlanosContasAlternativosExtratoModalComponent} from '../../../entities/planoscontasalternativos/modals/extrato/planosContasAlternativos.extrato.modal.component';
import {devExpressDataGridNormalizeFilterExpressionAccents} from '../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ITableLegend} from '../../tablelegend/tablelegend.component.interface';

const ROUND_PRECISION = 2;
const CONST_TODAS_COLUNAS = 0;
const CONST_COLUNAS_SALDO = 1;
const CONST_CLASS_LEGEND = 'tipocontacontab';
const CONST_TIPOCONTA_MOVIMENTO = 0;

@Component({
  selector: 'balancetes-grid',
  templateUrl: './balancetes.grid.component.html'
})
export class BalancetesGridComponent implements OnInit, OnChanges {
  @Input() public balanceteComplList: Array<IJsonBalanceteCompl>;
  @Input() public titleTableEmpresa: string;
  @Input() public myAccounting: boolean;
  @Input() public planoContasAlt: boolean;
  @Input() public dePeriodo: string;
  @Input() public atePeriodo: string;
  @Input() public pocAltCabID: string;

  public readonly keysTipoContaContab: ReadonlyArray<keyof ETipoContaContabilidade>;
  public readonly tipoContaContab: typeof ETipoContaContabilidade;
  public readonly tipoContaMovimento: number;
  public readonly normalizeFilterExpressionAccentsFn: Function;
  public readonly balancetesLegend: Array<ITableLegend>;

  public dataGridDefinition: IDevExpressDataGrid;
  public tipoPerfil: ETipoPerfilBalancetes;
  public yearListBalancete: Array<IYearBalancete>;
  public dynamicInterface: Array<IDynamicBalancete>;
  public totalCollumnName: Array<IColumnBalanceteTotal>;
  public fixedColumns: boolean;

  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceHistoricoExtratos: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceMovAberto: IModuleMaintenanceInstance;
  private readonly _maintenanceInstancePocs: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceClifos: IEntityMaintenanceInstance;
  private _dataGridInstance: dxDataGrid;

  constructor(
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this._maintenanceInstanceHistoricoExtratos = this._moduleMaintenanceService.build(MODULE_NAME_EXTRATOS_DT);
    this._maintenanceInstanceMovAberto = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO);
    this._maintenanceInstancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this._maintenanceInstanceClifos = this._entityMaintenanceService.build(ENTITY_NAME_CLIFOS);
    this.tipoContaContab = ETipoContaContabilidade;
    this.balancetesLegend = [];
    this.keysTipoContaContab = Object.freeze(<Array<keyof ETipoContaContabilidade>>Object.keys(this.tipoContaContab));
    for (const key of this.keysTipoContaContab) {
      this.balancetesLegend.push({
        caption: `balancetes.modal.${CONST_CLASS_LEGEND}.${<ETipoContaContabilidade>this.tipoContaContab[key]}`,
        badgeCSSClass: `${CONST_CLASS_LEGEND}-${<ETipoContaContabilidade>this.tipoContaContab[key]}`
      });
    }
    this.yearListBalancete = [];
    this.dynamicInterface = [];
    this.totalCollumnName = [];
    this.tipoPerfil = CONST_TODAS_COLUNAS;
    this.titleTableEmpresa = '';
    this.myAccounting = false;
    this.planoContasAlt = false;
    this.dePeriodo = '';
    this.atePeriodo = '';
    this.pocAltCabID = '';
    this.tipoContaMovimento = CONST_TIPOCONTA_MOVIMENTO;
    this.normalizeFilterExpressionAccentsFn = devExpressDataGridNormalizeFilterExpressionAccents;
  }

  public ngOnInit(): void {
    this.fixedColumns = !isMobile();
    this.dataGridDefinition = {
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      allowColumnResizing: true,
      export: {
        filename: () => `Balancetes_${this.titleTableEmpresa}`,
        propertiesExcel: {
          customizeCell: ({gridCell, excelCell}: IDevExpressDataGridExportExcelCustomizeCellOptions) => {
            if (gridCell.rowType === 'data') {
              switch ((<IDynamicBalancete>gridCell.data).tipo) {
                case Number(ETipoContaContabilidade.SubTotal):
                  excelCell.font = {color: {argb: '2196F3'}};
                  break;
                case Number(ETipoContaContabilidade.Razao):
                  excelCell.font = {color: {argb: '46be8a'}};
                  break;
                case Number(ETipoContaContabilidade.Classe):
                  excelCell.font = {color: {argb: 'f96868'}, bold: true};
                  break;
                default:
                  break;
              }
            }
          }
        }
      },
      height: '70vh',
      hoverStateEnabled: true,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      searchPanel: {visible: true},
      showBorders: true,
      showColumnLines: true,
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      sorting: {mode: 'none'},
      keyExpr: 'nConta',
      remoteOperations: false,
      repaintChangesOnly: true,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templatePerfilBalancete',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    for (const balanceteCompl of this.balanceteComplList) {
      balanceteCompl.tipo = isNumber(balanceteCompl.tipo) ? balanceteCompl.tipo : 0;
    }
    this._setAnosBalanceteGrid();
  }

  public ngOnChanges({balanceteComplList}: SimpleChanges): void {
    if (balanceteComplList) {
      this.balanceteComplList = balanceteComplList.currentValue;

      for (const balanceteCompl of this.balanceteComplList) {
        balanceteCompl.tipo = isNumber(balanceteCompl.tipo) ? balanceteCompl.tipo : 0;
      }
      this._setAnosBalanceteGrid();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IDynamicBalancete>): void {
    if (event.rowType === 'data') {
      event.cellElement.classList.add(`${CONST_CLASS_LEGEND}-${event.data.tipo}`);
    }

    if (event.rowType === 'header') {
      event.cellElement.style.textAlign = 'center';
    }
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IDynamicBalancete>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data) && !this.myAccounting) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data.nConta, event.row.data.nomeConta, event.row.data.temCC, event.row.data.tipo);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public tipoPerfilChanged(value: ETipoPerfilBalancetes): void {
    this.tipoPerfil = value;
    this._setAnosBalanceteGrid(true);
  }

  public openMaintenanceInstancePocs(nconta: string): void {
    this._maintenanceInstancePocs.maintenanceEdit(nconta);
  }

  public openMaintenanceInstanceExtratosGrid(nconta: string): void {
    this._maintenanceInstanceExtratosGrid.maintenance({params: {nConta: nconta, dePeriodo: this.dePeriodo, atePeriodo: this.atePeriodo}});
  }

  public openMaintenanceInstanceHistoricoExtratos(nconta: string, nomeconta: string, temCC: boolean): void {
    const params: IExtratosDTStateParams = {nConta: nconta, nomeConta: nomeconta, temCC: temCC};
    this._maintenanceInstanceHistoricoExtratos.maintenance({params: params});
  }

  public openMaintenanceInstanceClifos(nconta: string): void {
    this._maintenanceInstanceClifos.maintenanceEdit(nconta);
  }

  public openMaintenanceInstanceMovAberto(nconta: string): void {
    this._maintenanceInstanceMovAberto.maintenance({params: {deConta: nconta, ateConta: nconta}});
  }

  public openExtratoPlanoAlt(nconta: string, acumulado: boolean): void {
    const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosExtratoModalComponent);
    const componentInstance: PlanosContasAlternativosExtratoModalComponent = modalInstance.componentInstance;
    componentInstance.pocAltCabID = this.pocAltCabID;
    componentInstance.nconta = nconta;
    componentInstance.dePeriodo = this.dePeriodo;
    componentInstance.atePeriodo = this.atePeriodo;
    componentInstance.acumulado = acumulado;
  }

  private _setAnosBalanceteGrid(tipoPerfilChanged: boolean = false): void {
    this.yearListBalancete = [];
    this.dynamicInterface = [];
    this.totalCollumnName = [];

    const listAnos: Array<IJsonBalanceteAnos> = [];
    for (const balancete of this.balanceteComplList) {
      for (const listaAno of balancete.listaAnos) {
        listAnos.push(listaAno);
      }

      this.dynamicInterface.push({
        nConta: balancete.nConta,
        nContaPai: balancete.nContaPai,
        nomeConta: balancete.nomeConta,
        temCC: balancete.temCC,
        tipo: balancete.tipo
      });
    }

    const groupByYear: IDictionary<Array<IJsonBalanceteAnos>> = groupBy<IJsonBalanceteAnos>(listAnos, 'ano');
    if (Object.keys(groupByYear).length > 1 && !tipoPerfilChanged) {
      this.tipoPerfil = CONST_COLUNAS_SALDO;
    }

    for (const year of Object.keys(groupByYear)) {
      const list: Array<IJsonBalanceteAnos> = groupByYear[year];

      const yearCaption: string = this._translateService.instant('balancetes.modal.table.descano', {
        ano: year,
        periodoDesc: list[0].descricao,
        doperiodo: list[0].periodoDe,
        ateperiodo: list[0].periodoAte
      });

      const nameFieldpeSiodoDebitoCalc = `${year}periodoDebitoCalc`;
      const nameFieldperSodoCreditoCalc = `${year}periodoCreditoCalc`;
      const nameFieldpSriodoSaldoCalc = `${year}periodoSaldoCalc`;
      const nameFieldacumSladoDebitoCalc = `${year}acumuladoDebitoCalc`;
      const nameFieldacumuSadoCreditoCalc = `${year}acumuladoCreditoCalc`;
      const nameFieldSaldoDebitoCalc = `${year}saldoDebitoCalc`;
      const nameFieldsSldoCreditoCalc = `${year}saldoCreditoCalc`;
      const namSFieldsaldoCalc = `${year}saldoCalc`;

      const headerFieldBalancete: Array<IHeaderFieldBalancete> =
        this.tipoPerfil === ETipoPerfilBalancetes.TODAS
          ? [
              {
                caption: 'balancetes.modal.table.movperiodo',
                fields: [
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.debito'),
                    _dataType: 'number',
                    _dataField: nameFieldpeSiodoDebitoCalc
                  },
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.credito'),
                    _dataType: 'number',
                    _dataField: nameFieldperSodoCreditoCalc
                  },
                  {
                    _caption: this._translateService.instant('global.text.saldo'),
                    _dataType: 'number',
                    _dataField: nameFieldpSriodoSaldoCalc
                  }
                ]
              },
              {
                caption: 'balancetes.modal.table.movacumu',
                fields: [
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.debito'),
                    _dataType: 'number',
                    _dataField: nameFieldacumSladoDebitoCalc
                  },
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.credito'),
                    _dataType: 'number',
                    _dataField: nameFieldacumuSadoCreditoCalc
                  }
                ]
              },
              {
                caption: 'global.text.saldo',
                fields: [
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.saldodebito'),
                    _dataType: 'number',
                    _dataField: nameFieldSaldoDebitoCalc
                  },
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.saldocredito'),
                    _dataType: 'number',
                    _dataField: nameFieldsSldoCreditoCalc
                  },
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.saldogeral'),
                    _dataType: 'number',
                    _dataField: namSFieldsaldoCalc
                  }
                ]
              }
            ]
          : [
              {
                caption: 'global.text.saldo',
                fields: [
                  {
                    _caption: this._translateService.instant('balancetes.modal.table.header.saldogeral'),
                    _dataType: 'number',
                    _dataField: namSFieldsaldoCalc
                  }
                ]
              }
            ];

      const totalPeriodoDebitoCalc = !list[0].totais.totalPeriodoDebitoCalc ? 0 : list[0].totais.totalPeriodoDebitoCalc;
      const totalPeriodoCreditoCalc = !list[0].totais.totalPeriodoCreditoCalc ? 0 : list[0].totais.totalPeriodoCreditoCalc;
      const totalPeriodoSaldoCalc = !list[0].totais.totalPeriodoSaldoCalc ? 0 : list[0].totais.totalPeriodoSaldoCalc;
      const totalAcumuladoDebitoCalc = !list[0].totais.totalAcumuladoDebitoCalc ? 0 : list[0].totais.totalAcumuladoDebitoCalc;
      const totalAcumuladoCreditoCalc = !list[0].totais.totalAcumuladoCreditoCalc ? 0 : list[0].totais.totalAcumuladoCreditoCalc;
      const totalSaldoDebitoCalc = !list[0].totais.totalSaldoDebitoCalc ? 0 : list[0].totais.totalSaldoDebitoCalc;
      const totalSaldoCreditoCalc = !list[0].totais.totalSaldoCreditoCalc ? 0 : list[0].totais.totalSaldoCreditoCalc;
      const totalSaldoCalc = !list[0].totais.totalSaldoCalc ? 0 : list[0].totais.totalSaldoCalc;

      this.totalCollumnName.push(
        {
          column: nameFieldpeSiodoDebitoCalc,
          value: String(round(totalPeriodoDebitoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldperSodoCreditoCalc,
          value: String(round(totalPeriodoCreditoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldpSriodoSaldoCalc,
          value: String(round(totalPeriodoSaldoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldacumSladoDebitoCalc,
          value: String(round(totalAcumuladoDebitoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldacumuSadoCreditoCalc,
          value: String(round(totalAcumuladoCreditoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldSaldoDebitoCalc,
          value: String(round(totalSaldoDebitoCalc, ROUND_PRECISION))
        },
        {
          column: nameFieldsSldoCreditoCalc,
          value: String(round(totalSaldoCreditoCalc, ROUND_PRECISION))
        },
        {
          column: namSFieldsaldoCalc,
          value: String(round(totalSaldoCalc, ROUND_PRECISION))
        }
      );

      const listBalancete: Array<IJsonBalancete> = [];

      for (const item of list) {
        for (const balancete of item.listaBalancetes) {
          listBalancete.push(balancete);
        }
      }

      this.yearListBalancete.unshift({ano: list[0].ano, headerFields: headerFieldBalancete, caption: yearCaption, listaBalancetes: listBalancete});
    }

    for (let i = 0; i <= this.dynamicInterface.length - 1; i++) {
      for (const yearBalancete of this.yearListBalancete) {
        if (this.tipoPerfil === ETipoPerfilBalancetes.TODAS) {
          this.dynamicInterface[i][`${yearBalancete.ano}periodoDebitoCalc`] = yearBalancete.listaBalancetes[i].periodoDebitoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}periodoCreditoCalc`] = yearBalancete.listaBalancetes[i].periodoCreditoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}periodoSaldoCalc`] = yearBalancete.listaBalancetes[i].periodoSaldoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}acumuladoDebitoCalc`] = yearBalancete.listaBalancetes[i].acumuladoDebitoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}acumuladoCreditoCalc`] = yearBalancete.listaBalancetes[i].acumuladoCreditoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}saldoDebitoCalc`] = yearBalancete.listaBalancetes[i].saldoDebitoCalc;
          this.dynamicInterface[i][`${yearBalancete.ano}saldoCreditoCalc`] = yearBalancete.listaBalancetes[i].saldoCreditoCalc;
        }
        this.dynamicInterface[i][`${yearBalancete.ano}saldoCalc`] = yearBalancete.listaBalancetes[i].saldoCalc;
      }
    }
  }

  private _generateSecondaryClickActions(nConta: string, nomeConta: string, temCC: boolean, tipo: number): Array<IPlDynamicVisualsSecondaryClickAction> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [];
    if (!this.planoContasAlt) {
      actions.push({
        caption: 'balancetes.btn.dropdown.planocontas',
        click: () => {
          this.openMaintenanceInstancePocs(nConta);
        }
      });
      if (tipo === CONST_TIPOCONTA_MOVIMENTO) {
        actions.push(
          {
            caption: 'balancetes.btn.dropdown.extratosgrid',
            click: () => {
              this.openMaintenanceInstanceExtratosGrid(nConta);
            }
          },
          {
            caption: 'balancetes.btn.dropdown.historicoextratos',
            click: () => {
              this.openMaintenanceInstanceHistoricoExtratos(nConta, nomeConta, temCC);
            }
          }
        );
      }
      if (temCC) {
        actions.push(
          {
            caption: 'balancetes.btn.dropdown.clifos',
            click: () => {
              this.openMaintenanceInstanceClifos(nConta);
            }
          },
          {
            caption: 'balancetes.btn.dropdown.movaberto',
            click: () => {
              this.openMaintenanceInstanceMovAberto(nConta);
            }
          }
        );
      }
    } else if (this.planoContasAlt && tipo === CONST_TIPOCONTA_MOVIMENTO) {
      actions.push(
        {
          caption: 'balancetes.btn.dropdown.extratoPlanoAlt',
          click: () => {
            this.openExtratoPlanoAlt(nConta, false);
          }
        },
        {
          caption: 'balancetes.btn.dropdown.acumuPlanoAlt',
          click: () => {
            this.openExtratoPlanoAlt(nConta, true);
          }
        }
      );
    }
    return actions;
  }
}
