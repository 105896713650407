<pl-form (ngFormChange)="form = $event" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'users.password.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label><span [translate]="'users.password.newpassword'"></span>:</label>
      <edit>
        <pl-edit-password
          attrName="password"
          [(model)]="model.password"
          [properties]="{
            validators: {
              required: {value: true},
              minlength: {value: 5},
              maxlength: {value: 50}
            }
          }"
          plAutoFocus>
        </pl-edit-password>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'users.password.confirmpassword'"></span>:</label>
      <edit>
        <pl-edit-password
          attrName="confirmpassword"
          [(model)]="model.confirm"
          [properties]="{
            validators: {
              required: {value: true},
              minlength: {value: 5},
              maxlength: {value: 50},
              equals: {value: 'password', message: 'users.password.errorEqualsPassword'}
            }
          }">
        </pl-edit-password>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'users.actions.change'"></span></button>
    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
