<div class="importxls entity-detail-form">
  <pl-alert type="info" [closeable]="false">
    <i class="fa fa-info-circle me-1" aria-hidden="true"></i>
    <span [translate]="'importxls.headerinfo'"></span>
  </pl-alert>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="importxls" [dataSource]="dataGridDefinition.dataSource">
    <div *dxTemplate="let item of 'cellTemplateBtn'">
      <button type="button" class="btn btn-info btn-xs" (click)="openImportModal(item.data)"><i class="fa fa-fw fa-download"></i></button>
    </div>
  </dx-data-grid>
</div>
