import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PlSchedulerComponent} from './scheduler.component';

export * from './scheduler.component.interface';
export * from './scheduler.component';

const DECLARATIONS = [PlSchedulerComponent];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: [DECLARATIONS]
})
export class PlSchedulerModule {}
