import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {IExportacaoComprasFilters} from './exportacaoCompras.module.interface';
import {IJsonExportacaoCompras, IJsonExportacaoComprasConfig, IJsonGeofoliaFamilia} from './jsonExportacaoCompras.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ExportacaoComprasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/exportacaocomprasgeofolia`;
  }

  public getExportacaoCompras(filters: IExportacaoComprasFilters): TServiceResponse<Array<IJsonExportacaoCompras>> {
    return this._apiService.get<Array<IJsonExportacaoCompras>>({
      url: this._path,
      params: {
        datade: filters.datade,
        dataate: filters.dataate,
        documentosporexportar: filters.documentosporexportar
      }
    });
  }

  public getConfig(): TServiceResponse<IJsonExportacaoComprasConfig> {
    return this._apiService.get<IJsonExportacaoComprasConfig>({
      url: `${this._path}/config`
    });
  }

  public saveConfig(config: IJsonExportacaoComprasConfig): TServiceResponse<IJsonExportacaoComprasConfig> {
    return this._apiService.post<IJsonExportacaoComprasConfig>({
      url: `${this._path}/config`,
      body: config
    });
  }

  public getGeofoliaFamilias(): TServiceResponse<Array<IJsonGeofoliaFamilia>> {
    return this._apiService.get<Array<IJsonGeofoliaFamilia>>({
      url: `${this._path}/geofoliafamilias`
    });
  }

  public exportFicheiro(filters: IExportacaoComprasFilters, selectedDocs: Array<string>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<string>>({
      url: `${this._path}/exportficheiro`,
      params: {
        datade: filters.datade,
        dataate: filters.dataate,
        documentosporexportar: filters.documentosporexportar
      },
      body: selectedDocs,
      responseType: 'blob'
    });
  }

  public clearDocsExportados(filters: IExportacaoComprasFilters, selectedDocs: Array<string>): TServiceResponse<Array<IJsonExportacaoCompras>> {
    return this._apiService.post<Array<IJsonExportacaoCompras>, Array<string>>({
      url: `${this._path}/clearDocsExportados`,
      params: {
        datade: filters.datade,
        dataate: filters.dataate,
        documentosporexportar: filters.documentosporexportar
      },
      body: selectedDocs
    });
  }
}
