import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {TerceirosComponent} from './components/terceiros.module.component';

export const MODULE_TERCEIROS: IModuleDefinition = {
  name: 'terceiros',
  state: {
    url: '/terceiros',
    component: TerceirosComponent,
    params: {
      clifo: {
        type: 'string',
        squash: true,
        value: null
      }
    },
    data: {
      roles: [ROLE.ERP],
      icon: 'fa-address-book-o',
      pageTitle: 'global.menu.terceiros'
    }
  }
};
