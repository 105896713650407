export enum EPortalColaboradorNotificacao {
  DESATIVADO,
  DIARIA,
  DIA_SEMANA
}

export interface IPortalColaboradorNotificacaoSourceItem {
  estado: EPortalColaboradorNotificacao;
  caption: string;
}

export interface IJsonRHConflitosConfig {
  geralPermiteMarcarFeriasComConflitos: boolean;
}

export interface IJsonRHConflitoItem {
  idColaborador1: number;
  idColaborador2: number;
}

export interface IJsonRHConflitosConfigColaboradorItem extends IJsonRHConflitoItem {
  idConflito: string;
  nomeColaborador1: string;
  nomeColaborador2: string;
}

export interface IJsonRHConflitosCreateConflitoData {
  codEmp: number;
  withList: Array<number>;
}

export interface IJsonRHConflitosCheckResult {
  permiteMarcar: boolean;
  temConflito: boolean;
}

export const MODULE_NAME_CONFIGS_PORTAL_COLABORADOR = 'configsportalcolaborador';
export const MODULE_NAME_RH_CONFLITOS = 'rhconflitos';
