import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ProviderLike} from '@uirouter/core';
import {EDateMonth} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {IAtivosDepreciacaoHeader} from './ativosDepreciacao.module.interface';
import {IJsonAnosCalculo, IJsonAtivosDepreciacao, IJsonAtivosDeprecicaoLinha} from './jsonAtivosDepreciacao.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosDepreciacaoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosdepreciacao`;
  }

  public getAnosCalculo(): Promise<Array<IJsonAnosCalculo>> {
    return this._apiService.get<Array<IJsonAnosCalculo>>({url: `${this._path}/anosparaprocessar`}).then((response: HttpResponse<Array<IJsonAnosCalculo>>) => response.body);
  }

  public ativosConfiguracoesGet(header: IAtivosDepreciacaoHeader): TServiceResponse<IJsonAtivosDepreciacao> {
    return this._apiService.get<IJsonAtivosDepreciacao>({
      url: this._path,
      params: {
        anoDeCalculo: header.anoDeCalculo,
        codAtivoDe: header.codAtivoDe,
        codAtivoAte: header.codAtivoAte,
        dataAquisicaoDe: header.dataAquisicaoDe,
        dataAquisicaoAte: header.dataAquisicaoAte,
        pesquisaAnosAnteriores: header.pesquisaAnosAnteriores
      }
    });
  }

  public postContabPercReducaoCalc(header: IAtivosDepreciacaoHeader, pordepreciarlinha: IJsonAtivosDeprecicaoLinha): TServiceResponse<IJsonAtivosDeprecicaoLinha> {
    return this._apiService.post<IJsonAtivosDeprecicaoLinha>({
      url: this._path,
      params: {
        anoDeCalculo: header.anoDeCalculo,
        codAtivoDe: header.codAtivoDe,
        codAtivoAte: header.codAtivoAte,
        dataAquisicaoDe: header.dataAquisicaoDe,
        dataAquisicaoAte: header.dataAquisicaoAte,
        pesquisaAnosAnteriores: header.pesquisaAnosAnteriores
      },
      body: pordepreciarlinha
    });
  }

  public ativosConfiguracoesPut(header: IAtivosDepreciacaoHeader, ativosDepreciacao: IJsonAtivosDepreciacao, mes: EDateMonth): TServiceResponse<IJsonAtivosDepreciacao> {
    return this._apiService.put<IJsonAtivosDepreciacao>({
      url: this._path,
      params: {
        anoDeCalculo: header.anoDeCalculo,
        codAtivoDe: header.codAtivoDe,
        codAtivoAte: header.codAtivoAte,
        mes: mes,
        dataAquisicaoDe: header.dataAquisicaoDe,
        dataAquisicaoAte: header.dataAquisicaoAte,
        pesquisaAnosAnteriores: header.pesquisaAnosAnteriores
      },
      body: ativosDepreciacao
    });
  }

  public ativosConfiguracoesDelete(header: IAtivosDepreciacaoHeader, ativosDepreciacao: IJsonAtivosDepreciacao): TServiceResponse<IJsonAtivosDepreciacao> {
    return this._apiService.delete<IJsonAtivosDepreciacao>({
      url: this._path,
      params: {
        anoDeCalculo: header.anoDeCalculo,
        codAtivoDe: header.codAtivoDe,
        codAtivoAte: header.codAtivoAte,
        dataAquisicaoDe: header.dataAquisicaoDe,
        dataAquisicaoAte: header.dataAquisicaoAte,
        pesquisaAnosAnteriores: header.pesquisaAnosAnteriores
      },
      body: ativosDepreciacao
    });
  }
}

export const RESOLVER_ATIVOS_DEPRECIACAO_ANOS_CALCULO: ProviderLike = {
  provide: 'anosCalculo',
  deps: [AtivosDepreciacaoService],
  useFactory: (ativosDepreciacaoService: AtivosDepreciacaoService): Promise<Array<IJsonAnosCalculo>> => ativosDepreciacaoService.getAnosCalculo()
};
