<div class="user-installations" [plPromise]="promise">
  <pl-alert type="info" class="mt-4" *ngIf="!installation">
    <div [translate]="'usersbasic.text.selectInstallation'"></div>
  </pl-alert>

  <div class="user-installations-content mt-4" *ngIf="installation && installations.values.get(installation)">
    <user-installation
      [installation]="installations.values.get(installation)"
      [disabled]="disabled"
      (evtChangedFilterValue)="changedFilterValue($event)"
      (evtChangedSelectAllCompanies)="changedSelectAllCompanies($event)"
      (evtChangedSelectCompany)="changedSelectCompany($event)"
      (evtChangedConfigErpValue)="changedConfigErpValue($event)"
      (evtChangedConfigsErpValue)="changedConfigsErpValue($event)"
      (evtChangedPolicies)="changedPolicies($event)">
    </user-installation>
  </div>
</div>
