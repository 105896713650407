import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_GRUPO_ARTIGO} from './grupoArtigo.datasource.interface';
import {EGrupoArtigo} from '../../entities/artigos/jsonArtigo.entity.interface';

export const DATA_SOURCE_GRUPO_ARTIGO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_GRUPO_ARTIGO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EGrupoArtigo.Geral, name: 'artigos.datasource.grupoartigo.Geral'},
    {value: EGrupoArtigo.Mercadoria, name: 'artigos.datasource.grupoartigo.Mercadoria'},
    {value: EGrupoArtigo.Produto, name: 'artigos.datasource.grupoartigo.Produto'},
    {value: EGrupoArtigo.MateriaPrima, name: 'artigos.datasource.grupoartigo.MateriaPrima'},
    {value: EGrupoArtigo.Servico, name: 'artigos.datasource.grupoartigo.Servico'},
    {value: EGrupoArtigo.ServicoExterno, name: 'artigos.datasource.grupoartigo.ServicoExterno'},
    {value: EGrupoArtigo.Componente, name: 'artigos.datasource.grupoartigo.Componente'},
    {value: EGrupoArtigo.Equipamento, name: 'artigos.datasource.grupoartigo.Equipamento'},
    {value: EGrupoArtigo.Ferramenta, name: 'artigos.datasource.grupoartigo.Ferramenta'},
    {value: EGrupoArtigo.MaoDeObra, name: 'artigos.datasource.grupoartigo.MaoDeObra'},
    {value: EGrupoArtigo.ArtigoReferencia, name: 'artigos.datasource.grupoartigo.ArtigoReferencia'},
    {value: EGrupoArtigo.MaterialSubsidiario, name: 'artigos.datasource.grupoartigo.MaterialSubsidiario'},
    {value: EGrupoArtigo.Embalagem, name: 'artigos.datasource.grupoartigo.Embalagem'},
    {value: EGrupoArtigo.Veiculo, name: 'artigos.datasource.grupoartigo.Veiculo'},
    {value: EGrupoArtigo.Motorista, name: 'artigos.datasource.grupoartigo.Motorista'},
    {value: EGrupoArtigo.TipoTarefa, name: 'artigos.datasource.grupoartigo.TipoTarefa'},
    {value: EGrupoArtigo.TarefaEspecifica, name: 'artigos.datasource.grupoartigo.TarefaEspecifica'},
    {value: EGrupoArtigo.TarefaGeral, name: 'artigos.datasource.grupoartigo.TarefaGeral'},
    {value: EGrupoArtigo.Transportadora, name: 'artigos.datasource.grupoartigo.Transportadora'},
    {value: EGrupoArtigo.MaquinaMotriz, name: 'artigos.datasource.grupoartigo.MaquinaMotriz'},
    {value: EGrupoArtigo.Configuracao, name: 'artigos.datasource.grupoartigo.Configuracao'},
    {value: EGrupoArtigo.ArtigoTara, name: 'artigos.datasource.grupoartigo.ArtigoTara'},
    {value: EGrupoArtigo.Infraestrutura, name: 'artigos.datasource.grupoartigo.Infraestrutura'},
    {value: EGrupoArtigo.Utensilio, name: 'artigos.datasource.grupoartigo.Utensilio'},
    {value: EGrupoArtigo.ParteInfraestrutura, name: 'artigos.datasource.grupoartigo.ParteInfraestrutura'},
    {value: EGrupoArtigo.Adiantamentos, name: 'artigos.datasource.grupoartigo.Adiantamentos'},
    {value: EGrupoArtigo.AlienacaoDeImobilizado, name: 'artigos.datasource.grupoartigo.AlienacaoDeImobilizado'}
  ]
});
