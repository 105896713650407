<div class="pl-calendar-month-view">
  <pl-scheduler
    [data]="schedulerData"
    [rangeSelect]="rangeSelect"
    [rangeSelectMultiline]="false"
    [datasetDetail]="datasetDetail"
    [datasetDetailOnSelect]="datasetDetailOnSelect"
    [datasetDetailActive]="datasetDetailActive"
    [showTitle]="showTitle"
    [showLabel]="showLabel"
    [selectedRange]="schedulerRange"
    [templateTitle]="templateTitle"
    [templateLabel]="templateMonthViewLabel"
    [templateHeader]="templateMonthViewHeader"
    [templateDataset]="templateMonthViewDataset"
    [templateDatasetItem]="templateMonthViewDay"
    [templateDatasetHeaderDetail]="templateDatasetHeaderDetail"
    [templateDatasetDetail]="templateMonthViewDayDetail"
    (datasetDetailActiveChange)="onDatasetDetailActiveChange($event)"
    (selectedRangeChange)="onSelectedRangeChange($event)"
    (evtCellClick)="onCellClick($event)"
    (evtRangeSelect)="onRangeSelect($event)"
    (evtSelectionChanged)="onSelectionChanged($event)">
  </pl-scheduler>
</div>

<ng-template #templateMonthViewLabel>
  <ng-container *ngTemplateOutlet="templateLabel || templateLabelDefault"></ng-container>
</ng-template>

<ng-template #templateMonthViewHeader let-header="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateHeader || templateHeaderDefault;
      context: {
        $implicit: header,
        item: header,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd,
        day: header.meta.day
      }
    "></ng-container>
</ng-template>

<ng-template #templateMonthViewDataset let-dataset="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateDataset || templateDatasetDefault;
      context: {
        $implicit: dataset,
        dataset: dataset,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd
      }
    "></ng-container>
</ng-template>

<ng-template #templateMonthViewDay let-day="item" let-dataset="dataset" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <ng-container
    *ngTemplateOutlet="
      templateDay || templateDayDefault;
      context: {
        $implicit: day,
        day: day,
        dataset: dataset,
        index: index,
        count: count,
        first: first,
        last: last,
        even: even,
        odd: odd
      }
    "></ng-container>
</ng-template>

<ng-template #templateMonthViewDayDetail let-datasets="datasets" let-dataset="dataset" let-datasetsItems="datasetsItems" let-datasetItem="datasetItem">
  <ng-container
    *ngTemplateOutlet="
      templateDayDetail || templateDayDetailDefault;
      context: {
        datasets: datasets,
        dataset: dataset,
        days: datasetsItems,
        day: datasetItem
      }
    "></ng-container>
</ng-template>

<ng-template #templateLabelDefault>{{ datasetsLabel }}</ng-template>

<ng-template #templateHeaderDefault let-header="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd" let-day="day">
  <span>{{ day }}</span>
</ng-template>

<ng-template #templateDatasetDefault let-dataset="dataset" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span [attr.title]="dataset.label">{{ dataset.label }}</span>
</ng-template>

<ng-template #templateDayDefault let-day="day" let-dataset="dataset" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span>{{ day.label }}</span>
</ng-template>

<ng-template #templateDayDetailDefault let-datasets="datasets" let-dataset="dataset" let-days="days" let-day="day">
  <span>{{ day.label }}</span>
</ng-template>
