<div class="contabilidadedigital-treeviewer-node" (mouseover)="folderItem.hovering = true" (focus)="folderItem.hovering = true" (mouseleave)="folderItem.hovering = false">
  <div
    class="contabilidadedigital-treeviewer-node-item hyperlink"
    [class.collapsed]="folderItem.collapsed"
    (click)="selectFolder(folderItem); toggleFolderItemCollapse(folderItem)"
    (dblclick)="folderDoubleClicked(folderItem)">
    <i
      class="fa fa-fw fa-angle-down contabilidadedigital-treeviewer-node-item-collapse"
      aria-hidden="true"
      [class.hide-element]="(showFiles && !folderItem.hasSubFolders && !folderItem.files.length) || (!showFiles && !folderItem.hasSubFolders)"></i>

    <i
      class="fa fa-fw contabilidadedigital-treeviewer-node-item-icon"
      aria-hidden="true"
      [ngClass]="showFiles && (folderItem.hasSubFiles || folderItem.files.length > 0) ? 'fa-folder-open' : 'fa-folder-open-o'">
    </i>

    <span class="contabilidadedigital-treeviewer-node-item-title" [class.selected]="folderItem === selectedFolder" [attr.title]="folderItem.name | translate" [plTranslate]="folderItem.name"></span>

    <span *ngIf="showCountFolderFiles && folderItem && folderItem.files && folderItem.files.length > 0">({{ folderItem.files.length }})</span>

    <i
      class="fa fa-fw fa-plus-circle contabilidadedigital-treeviewer-node-item-action contabilidadedigital-treeviewer-node-item-action-add"
      aria-hidden="true"
      *ngIf="!folderItem.promise && allowSendFiles && folderItem.hovering && !disableActions && !disableActionAddDoc"
      [attr.title]="'arquivodigital.treeviewer.actions.addDoc' | translate"
      (click)="actionAdd($event, folderItem)"></i>

    <i
      class="fa fa-fw fa-camera contabilidadedigital-treeviewer-node-item-action contabilidadedigital-treeviewer-node-item-action-capture"
      aria-hidden="true"
      *ngIf="!folderItem.promise && allowSendFiles && folderItem.hovering && mobile && !disableActions && !disableActionCaptureDoc"
      [attr.title]="'arquivodigital.treeviewer.actions.captureDoc' | translate"
      (click)="actionCapture($event, folderItem)"></i>

    <i
      class="fa fa-fw fa-trash contabilidadedigital-treeviewer-node-item-action contabilidadedigital-treeviewer-node-item-action-delete-docs"
      aria-hidden="true"
      *ngIf="!folderItem.promise && allowDeleteFiles && folderItem.hovering && !disableActions && !disableActionDeleteDocs"
      [attr.title]="'arquivodigital.treeviewer.actions.deleteDocs' | translate"
      (click)="actionDeleteDocs($event, folderItem)">
    </i>

    <i class="fa fa-fw fa-spin fa-circle-o-notch contabilidadedigital-treeviewer-node-item-promise" aria-hidden="true" *ngIf="folderItem.promise"></i>
  </div>

  <div class="contabilidadedigital-treeviewer-node-sub-items" [ngbCollapse]="folderItem.collapsed">
    <div class="contabilidadedigital-treeviewer-sub-nodes">
      <div class="contabilidadedigital-treeviewer-sub-node" *ngFor="let folderItem of folderItem.folders">
        <contabilidadedigital-treeviewer-node
          [folderItem]="folderItem"
          [selectedFolder]="selectedFolder"
          [selectedFile]="selectedFile"
          [showFiles]="showFiles"
          [showCountFolderFiles]="showCountFolderFiles"
          [advancedDescription]="advancedDescription"
          [mobile]="mobile"
          [disableActions]="disableActions"
          [disableActionAddDoc]="disableActionAddDoc"
          [disableActionCaptureDoc]="disableActionCaptureDoc"
          [disableActionDeleteDocs]="disableActionDeleteDocs"
          [disableActionDeleteDoc]="disableActionDeleteDoc"
          (evtSelectedFolder)="changedSelectedFolder($event)"
          (evtFolderCollapseChanged)="changedFolderItemCollapse($event)"
          (evtFolderDoubleClicked)="folderDoubleClicked($event)"
          (evtFileDoubleClicked)="fileDoubleClicked($event)"
          (evtSelectedFile)="changedSelectedFile($event)"
          (evtActionAddDoc)="actionAdd($event.event, $event.folder)"
          (evtActionCaptureDoc)="actionCapture($event.event, $event.folder)"
          (evtActionDeleteDocs)="actionDeleteDocs($event.event, $event.folder)"
          (evtActionDeleteDoc)="actionDeleteDoc($event.event, $event.file)">
        </contabilidadedigital-treeviewer-node>
      </div>
    </div>

    <div class="contabilidadedigital-treeviewer-files" *ngIf="showFiles">
      <div
        class="contabilidadedigital-treeviewer-file hyperlink"
        *ngFor="let fileItem of folderItem.files"
        (mouseover)="fileItem.hovering = true"
        (focus)="folderItem.hovering = true"
        (mouseleave)="fileItem.hovering = false">
        <i class="fa fa-fw fa-file-pdf-o contabilidadedigital-treeviewer-file-icon" aria-hidden="true"></i>
        <span
          class="contabilidadedigital-treeviewer-file-title"
          [class.selected]="fileItem === selectedFile"
          [attr.title]="fileItem.advancedDescription | translate"
          [translate]="advancedDescription ? fileItem.advancedDescription : fileItem.nome"
          (click)="selectFile(fileItem)"
          (dblclick)="fileDoubleClicked(fileItem)">
        </span>
        <i
          class="fa fa-fw fa-trash contabilidadedigital-treeviewer-file-action-delete"
          aria-hidden="true"
          *ngIf="!folderItem.promise && fileItem.hovering && !disableActions && !disableActionDeleteDoc"
          [attr.title]="'arquivodigital.treeviewer.actions.deleteDoc' | translate"
          (click)="actionDeleteDoc($event, fileItem)">
        </i>
      </div>
    </div>
  </div>
</div>
