<div [plPromise]="mainPromise">
  <div class="blocked-by-other-user-container" *ngIf="isBlocked">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5><span [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></span>:</h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <div *ngIf="!isBlocked">
    <h4 class="mainTitle" [translate]="getMainTitle()"></h4>
    <pl-tabs (evtSelected)="mainTabChanged()">
      <pl-tab [caption]="getTabIntegrarCaption()" [id]="tabIdIntegrar">
        <div *plTabContent>
          <pl-form [submit]="fnSearchPorInteg" plPromise>
            <div class="row">
              <div class="col-md-12">
                <pl-group>
                  <edit>
                    <pl-edit type="boolean" [model]="sPorIntegModel.todosProcs" (modelChange)="porIntegTodosProcsChange($event)" [properties]="{label: 'integracaoSalarios.labelTodosProcs'}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-4">
                <pl-group>
                  <label [translate]="'integracaoSalarios.fields.tipoProcessamento'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="rhtipoprocess"
                      [model]="sPorIntegModel"
                      (modelChange)="tipoProcessamentoChange($event, false)"
                      [fieldsMap]="{tipoProcessamento: 'tipoProce', descricao: 'descricaoTipoProc'}"
                      [properties]="{disabled: sPorIntegModel.todosProcs, validators: {required: {value: !sPorIntegModel.todosProcs}}}"
                      [output]="tipoProcessamentoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-4">
                <pl-group>
                  <label [translate]="'global.text.toDate'"></label>
                  <edit>
                    <pl-edit type="date" [(model)]="sPorIntegModel.dataAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                  <actions>
                    <pl-button type="submit" klass="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
                  </actions>
                </pl-group>
              </div>
            </div>
          </pl-form>

          <hr />

          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionPorIntegrar"
            cgDxDataGridInstanceName="integSalariosPorIntegrar"
            [dataSource]="dataGridDefinitionPorIntegrar.dataSource"
            [(selectedRowKeys)]="selectedRowKeysPorIntegrar"
            (onInitialized)="onInitializedPorIntegrar($event)"
            (onContentReady)="onContentReady()"
            (onCellPrepared)="onCellPrepared($event)">
            <div *dxTemplate="let item of 'toolbarTemplatePorIntegrar'">
              <pl-toolbar [instanceId]="toolbarPorIntegrarInstId"></pl-toolbar>
            </div>
            <div *dxTemplate="let item of 'cellTemplatePorIntegrarErro'">
              <i [ngClass]="item.data.erroImage" aria-hidden="true"></i>
            </div>
            <div *dxTemplate="let item of 'masterDetailPorIntegrar'">
              <pl-alert type="error" *ngIf="item.data.erro.length"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="item.data.erro"></span></pl-alert>
            </div>
          </dx-data-grid>

          <hr />

          <div class="text-end">
            <pl-button type="button" klass="btn btn-light me-2" (evtClicked)="execInteg(true)">
              <i class="fa fa-magic" aria-hidden="true"></i>&nbsp;<span [translate]="'integracaoSalarios.simularInteg'"></span>
            </pl-button>
            <pl-button type="button" klass="btn btn-success" (evtClicked)="execInteg(false)">
              <i class="fa fa-play-circle-o" aria-hidden="true"></i>&nbsp;<span [translate]="'integracaoSalarios.execInteg'"></span>
            </pl-button>
          </div>
        </div>
      </pl-tab>

      <pl-tab caption="integracaoSalarios.tabRemoverIntegracao" [id]="tabIdRemover">
        <div *plTabContent>
          <pl-form [submit]="fnSearchRemoveInteg" plPromise>
            <div class="row">
              <div class="col-md-12">
                <pl-group>
                  <edit>
                    <pl-edit
                      type="boolean"
                      [model]="sRemoverIntegModel.todosProcs"
                      (modelChange)="removerIntegTodosProcsChange($event)"
                      [properties]="{label: 'integracaoSalarios.labelTodosProcs'}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <pl-group>
                  <label [translate]="'integracaoSalarios.fields.tipoProcessamento'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="rhtipoprocess"
                      [model]="sRemoverIntegModel"
                      (modelChange)="tipoProcessamentoChange($event, true)"
                      [fieldsMap]="{descricao: 'descricaoTipoProc'}"
                      [properties]="{disabled: sRemoverIntegModel.todosProcs, validators: {required: {value: !sRemoverIntegModel.todosProcs}}}"
                      [output]="tipoProcessamentoOutput">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-3">
                <pl-group>
                  <label [translate]="'integracaoSalarios.dataDe'"></label>
                  <edit>
                    <pl-edit type="date" [(model)]="sRemoverIntegModel.dataDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-3">
                <pl-group>
                  <label [translate]="'global.text.to'"></label>
                  <edit>
                    <pl-edit type="date" [(model)]="sRemoverIntegModel.dataAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                  </edit>
                  <actions *ngIf="config.tipoDeIntegracao === tTipoDeIntegracao.PorProcessamento">
                    <pl-button type="submit" klass="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i><span [translate]="'global.btn.search'"></span></pl-button>
                  </actions>
                </pl-group>
              </div>
            </div>
            <div class="row" *ngIf="config.tipoDeIntegracao === tTipoDeIntegracao.PorEmpregado">
              <div class="col-md-3">
                <pl-group>
                  <label [translate]="'integracaoSalarios.codEmpDe'"></label>
                  <edit>
                    <pl-edit
                      type="cginteger"
                      [(model)]="sRemoverIntegModel.codEmpDe"
                      [properties]="{validators: {required: {value: config.tipoDeIntegracao === tTipoDeIntegracao.PorEmpregado}}}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-3">
                <pl-group>
                  <label [translate]="'global.text.to'"></label>
                  <edit>
                    <pl-edit
                      type="cginteger"
                      [(model)]="sRemoverIntegModel.codEmpAte"
                      [properties]="{validators: {required: {value: config.tipoDeIntegracao === tTipoDeIntegracao.PorEmpregado}}}"></pl-edit>
                  </edit>
                  <actions>
                    <pl-button type="submit" klass="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
                  </actions>
                </pl-group>
              </div>
            </div>
          </pl-form>

          <hr />

          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionRemoverInteg"
            cgDxDataGridInstanceName="integSalariosRemoverInteg"
            [dataSource]="dataGridDefinitionRemoverInteg.dataSource"
            [(selectedRowKeys)]="selectedRowKeysRemoverInteg"
            (onInitialized)="onInitializedRemoverInteg($event)"
            (onContentReady)="onContentReady()"
            (onCellPrepared)="onCellPrepared($event)">
            <div *dxTemplate="let item of 'toolbarTemplateRemoverInteg'">
              <pl-toolbar [instanceId]="toolbarRemoverIntegInstId"></pl-toolbar>
            </div>
            <div *dxTemplate="let item of 'cellTemplateRemoverIntegErro'">
              <i [ngClass]="item.data.erroImage" aria-hidden="true"></i>
            </div>
            <div *dxTemplate="let item of 'masterDetailRemoverInteg'">
              <pl-alert type="error" *ngIf="item.data.erro.length"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="item.data.erro"></span></pl-alert>
            </div>
          </dx-data-grid>

          <hr />

          <div class="text-end">
            <pl-button type="button" klass="btn btn-danger" (evtClicked)="removeInteg()">
              <i class="fa fa-trash" aria-hidden="true"></i>&nbsp;<span [translate]="'integracaoSalarios.removeInteg'"></span>
            </pl-button>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>
</div>
