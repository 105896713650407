import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {ProviderLike, StateDeclaration, Transition} from '@uirouter/core';
import {AppService} from '../../core/services/app/app.service';
import {AuthService} from '../../core/services/auth/auth.service';
import {ConfigSiteService} from '../../core/services/configsite.service';
import {EAppLaunchMode} from '../../common/site';
import {IAppStatus} from '../../core/services/app/app.service.interface';
import {IAuthRestoreStateData} from '../../core/services/auth/auth.service.interface';
import {IJsonPortal} from '../../core/entities/portal/jsonPortal.entity.interface';
import {TUserSession} from '../../core/services/account/jsonUserApi.interface';

export function resolverAuthorizeFactory(authService: AuthService, transition: Transition): Promise<void> {
  const {name, data}: StateDeclaration = transition.to();
  const restoreStateData: IAuthRestoreStateData<unknown> = {
    stateName: name,
    params: transition.params(),
    data: data
  };
  return authService.authenticate(false, restoreStateData);
}

export const RESOLVER_AUTHORIZE: ProviderLike = {
  provide: 'authorize',
  deps: [AuthService, Transition],
  useFactory: resolverAuthorizeFactory
};

export function resolverSessionFactory(authService: AuthService): Promise<TUserSession> {
  return authService.identity();
}

export const RESOLVER_SESSION: ProviderLike = {
  provide: 'session',
  deps: [AuthService],
  useFactory: resolverSessionFactory
};

export function resolverUserIsAdministratorFactory(authService: AuthService): Promise<boolean> {
  return authService.isAdmin();
}

export const RESOLVER_USER_IS_ADMINISTRATOR: ProviderLike = {
  provide: 'administrator',
  deps: [AuthService],
  useFactory: resolverUserIsAdministratorFactory
};

export function resolverUserPortalsFactory(authService: AuthService): Promise<Array<IJsonPortal>> {
  return authService.userPortals();
}

export const RESOLVER_USER_PORTALS: ProviderLike = {
  provide: 'portals',
  deps: [AuthService],
  useFactory: resolverUserPortalsFactory
};

export function resolverPasswordSecureModeFactory(configSiteService: ConfigSiteService): Promise<boolean> {
  return configSiteService.passwordSecureMode();
}

export const RESOLVER_PASSWORD_SECURE_MODE: ProviderLike = {
  provide: 'passwordSecureMode',
  deps: [ConfigSiteService],
  useFactory: resolverPasswordSecureModeFactory
};

export function resolverCGStoreUrlFactory(configSiteService: ConfigSiteService): Promise<string> {
  return configSiteService.cgStoreUrl();
}

export const RESOLVER_CG_STORE_URL: ProviderLike = {
  provide: 'cgStoreUrl',
  deps: [ConfigSiteService],
  useFactory: resolverCGStoreUrlFactory
};

export function resolverCGStoreUrlBackOfficeFactory(configSiteService: ConfigSiteService): Promise<string> {
  return configSiteService.cgStoreUrlBackOffice();
}

export const RESOLVER_CG_STORE_URL_BACKOFFICE: ProviderLike = {
  provide: 'cgStoreUrlBackOffice',
  deps: [ConfigSiteService],
  useFactory: resolverCGStoreUrlBackOfficeFactory
};

export function resolverLaunchModeFactory(appService: AppService): Promise<EAppLaunchMode> {
  return firstValueFrom(appService.status().pipe(take(1))).then((status: IAppStatus) => status.launchMode);
}

export const RESOLVER_LAUNCH_MODE: ProviderLike = {
  provide: 'launchMode',
  deps: [AppService],
  useFactory: resolverLaunchModeFactory
};
