import {AssistConfigPortaisPortalClientConnectUtilizadorModuleComponent} from './components/assistConfigPortais.portalClientConnect.utilizador.module.component';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {EPortal} from '../../../../../../../common/enums/portals.enums';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR} from './assistConfigPortais.portalClientConnect.utilizador.module.interface';
import {ROLE} from '../../../../../../services/role.const';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR,
  state: {
    url: '/assistenteconfigportais/portalclientconnect/utilizador',
    component: AssistConfigPortaisPortalClientConnectUtilizadorModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'global.menu.assistenteConfigPortaisPortalClientConnectUtilizador'
    },
    resolve: [
      {
        provide: 'portalRoleClientConnect',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<ROLE> => authService.getAndGeneratePortalRole(EPortal.CLIENTCONNECT)
      }
    ]
  }
};
