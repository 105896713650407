import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_REMUNERADO, ETipoRemunerado} from './tipoRemunerado.datasource.interface';

export const DATA_SOURCE_TIPOS_REMUNERADO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_REMUNERADO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoRemunerado.NA, name: 'tipoRemunerado.data.notApplicable'},
    {value: ETipoRemunerado.Sim, name: 'tipoRemunerado.data.yes'},
    {value: ETipoRemunerado.Nao, name: 'tipoRemunerado.data.no'}
  ]
});
