import {Subscription} from 'rxjs';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {IPlToolbarItem} from 'pl-comps-angular';
import {EPcaTipoExportacao, IPcaContabilidadePeriodo, IPcaContabilidadeServiceRefreshedEvent} from '../../pca.contabilidade.module.service.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PcaContabilidadeService} from '../../pca.contabilidade.module.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonBalanceteAno, IJsonBalanceteCompl} from '../../../../portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';

@Component({
  selector: 'pca-contabilidade-balancetes',
  templateUrl: './pca.balancetes.module.component.html'
})
export class PcaBalancetesModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public balanceteComplList: Array<IJsonBalanceteCompl>;
  public pdfUrl: string;
  public isTableView: boolean;

  private readonly _btnProcess: IPlToolbarItem;
  private readonly _btnProcessGrid: IPlToolbarItem;
  private _subscription: Subscription;
  private _ano: IJsonBalanceteAno;
  private _periodo: IPcaContabilidadePeriodo;

  constructor(
    protected readonly _injector: Injector,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
    this._btnProcess = {
      id: 'processar',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-primary',
      caption: 'balancetes.btn.processar',
      disabled: false,
      click: () => {
        this._processar();
      }
    };
    this._btnProcessGrid = {
      id: 'processarGrelha',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-table"></i>',
      class: 'btn-success',
      caption: 'balancetes.btn.processargrelha',
      disabled: false,
      click: () => {
        this._processarGrelha();
      }
    };
    this.pdfUrl = '';
    this.isTableView = true;
    this.balanceteComplList = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcess).addButton(this._btnProcessGrid);
    this._subscription = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
    this._pcaContabilidadeService.init(this.toolbar);
    this._pcaContabilidadeService.showDropdownPeriodos();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private _onNotify({ano, periodo}: IPcaContabilidadeServiceRefreshedEvent): void {
    this._ano = ano;
    this._periodo = periodo;
    if (this.isTableView) {
      this._processarGrelha();
    } else {
      this._processar();
    }
  }

  private _processar(): void {
    this._portalClientAccountService.getBalancetesUrl(this._ano.ano, this._periodo.periodoSimples, EPcaTipoExportacao.Pdf).subscribe((url: string) => {
      this.pdfUrl = url;
    });

    this.isTableView = false;
  }

  private _processarGrelha(): void {
    this._portalClientAccountService.getBalancetesGrid(this._ano.ano, this._periodo.periodoSimples).then((response: HttpResponse<Array<IJsonBalanceteCompl>>) => {
      this.balanceteComplList = response.body;
    });

    this.isTableView = true;
  }
}
