import {DATA_SOURCE_NAME_TIPO_DESCRITIVO} from '../../datasources/tipodescritivo/tipoDescritivo.datasource.interface';
import {ENTITY_NAME_DESCRITIVOS} from './descritivos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonDescritivo} from './jsonDescritivo.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_DESCRITIVOS: IEntityDefinition<IJsonDescritivo> = {
  name: ENTITY_NAME_DESCRITIVOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'descritivos.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.descritivos',
  metadata: {
    keyName: 'nDescrit',
    fields: [
      {
        name: 'nDescrit',
        type: 'cgsmallint',
        width: '120px',
        caption: 'descritivos.fields.nDescrit',
        placeholder: 'descritivos.fields.nDescritPlaceholder',
        validators: {required: true}
      },
      {name: 'nome', caption: 'descritivos.fields.nome', placeholder: 'descritivos.fields.nomePlaceholder', validators: {required: true, maxlength: 25}},
      {name: 'descricao', caption: 'descritivos.fields.descricao', placeholder: 'descritivos.fields.descricaoPlaceholder', validators: {required: true, maxlength: 40}},
      {
        name: 'tipoDescritivo',
        caption: 'descritivos.fields.tipoDescritivo',
        type: DATA_SOURCE_NAME_TIPO_DESCRITIVO,
        validators: {required: true},
        properties: {
          devExpress: {
            dataGrid: {
              lookup: {
                cgDataSource: DATA_SOURCE_NAME_TIPO_DESCRITIVO
              }
            }
          }
        }
      }
    ],
    order: 'nome',
    searchFields: 'nDescrit,nome,descricao,tipoDescritivo',
    listFields: 'nDescrit,nome,descricao,tipoDescritivo'
  },
  autocomplete: {
    rowTemplate: '{{nDescrit}} - {{nome}}',
    output: '{{nDescrit}} - {{nome}}',
    outputDescription: 'nome',
    searchFields: 'nDescrit,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        icon: '',
        pageTitle: 'global.menu.descritivos'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        icon: '',
        pageTitle: 'global.menu.descritivos'
      }
    }
  }
};
