import {Injectable, Injector, Type} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EGestaoDGEMPSType, IGestaoDGEMPSEvent} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {EPRHFluxoIndiceTipo, IJsonPRHFluxo} from '../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../../../../components/gestaodgemps/modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {GestaoDGEMPSService} from '../../../../components/gestaodgemps/gestaodgemps.service';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP, IJsonDGEMPMarcacoes} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {Moment} from 'moment';
import {PcaRhGestaoDGEMPSAbonosMarcarModalComponent} from './modals/marcar/abonos/pca.rh.gestaoDGEMPS.abonos.marcar.modal.component';
import {PcaRhGestaoDGEMPSFaltasMarcarModalComponent} from './modals/marcar/faltas/pca.rh.gestaoDGEMPS.faltas.marcar.modal.component';
import {PcaRhGestaoDGEMPSFeriasMarcarModalComponent} from './modals/marcar/ferias/pca.rh.gestaoDGEMPS.ferias.marcar.modal.component';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';
import {THttpQueryResponse, TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PCARHGestaoDGEMPSService extends GestaoDGEMPSService {
  private _portalClientAccountService: PortalClientAccountService;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public getConfigStructure(type: EGestaoDGEMPSType): Promise<IJsonConfigEstrutura> {
    return this.portalClientAccountService.getConfiguracoesByTipo(type).then((response: HttpResponse<IJsonConfigEstrutura>) => response.body);
  }

  public getConfigEvents(type?: EGestaoDGEMPSType): Promise<Array<IJsonPRHEventosConfig>> {
    return this.portalClientAccountService.getPrhEventosConfig(type).then((response: THttpQueryResponse<IJsonPRHEventosConfig>) => response.body.list);
  }

  public getServicosEmpregados(codEmp: number): Promise<Array<IJsonPRHServicoEmpregado>> {
    return this.portalClientAccountService.getServicosEmpregado(codEmp).then((response: THttpQueryResponse<IJsonPRHServicoEmpregado>) => response.body.list);
  }

  public getListaMarcacoesAno(codEmp: number, dataDe: Moment, dataAte: Moment): Promise<IJsonDGEMPMarcacoes> {
    return this.portalClientAccountService.getListaMarcacoes(codEmp, dataDe, dataAte).then((response: HttpResponse<IJsonDGEMPMarcacoes>) => response.body);
  }

  public getListaMarcacoesMes(type: EGestaoDGEMPSType, codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment): Promise<IJsonPRHServicoMarcacoes> {
    let promise: TServiceResponse<IJsonPRHServicoMarcacoes>;
    switch (type) {
      case EGestaoDGEMPSType.Abonos:
        promise = this.portalClientAccountService.getMarcacoesAbonos(codServico, dataDe, dataAte, dataContext);
        break;
      case EGestaoDGEMPSType.Faltas:
        promise = this.portalClientAccountService.getMarcacoesFaltas(codServico, dataDe, dataAte, dataContext);
        break;
      case EGestaoDGEMPSType.Ferias:
        promise = this.portalClientAccountService.getMarcacoesFerias(codServico, dataDe, dataAte, dataContext);
        break;
    }
    return promise.then((response: HttpResponse<IJsonPRHServicoMarcacoes>) => response.body);
  }

  public getListaEmpregados(codServico: number): Promise<Array<IJsonDGEMP>> {
    return this.portalClientAccountService.getPrhServicoListaEmpregados(codServico).then((response: HttpResponse<Array<IJsonDGEMP>>) => response.body);
  }

  public getFluxos(tipo: EPRHFluxoIndiceTipo): Promise<Array<IJsonPRHFluxo>> {
    return this.portalClientAccountService.getPrhFluxosByTipo(tipo).then((response: THttpQueryResponse<IJsonPRHFluxo>) => {
      return response.body.list.sort((a: IJsonPRHFluxo, b: IJsonPRHFluxo) => a.posicao - b.posicao);
    });
  }

  public getDatasMarcacoesPendentes(tipo: EPRHFluxoIndiceTipo, codServico: number, dataContext: Moment): Promise<IJsonPRHServicoDatasMarcacoesPendentes> {
    return this.portalClientAccountService.getDatasMarcacoesPendentes(tipo, codServico, dataContext).then((response: HttpResponse<IJsonPRHServicoDatasMarcacoesPendentes>) => response.body);
  }

  public deleteTarefa(event: IGestaoDGEMPSEvent): Promise<void> {
    let promise: TServiceResponse<void>;
    switch (event.tipoEvento) {
      case EGestaoDGEMPSType.Abonos:
        promise = this.portalClientAccountService.deleteAbono(event.codEmp, event.idTarefaCab, <string>event.codIntegracao);
        break;
      case EGestaoDGEMPSType.Faltas:
        promise = this.portalClientAccountService.deleteFalta(event.codEmp, event.idTarefaCab, <number>event.codIntegracao);
        break;
      case EGestaoDGEMPSType.Ferias:
        promise = this.portalClientAccountService.deleteDiaFerias(event.codEmp, event.idTarefaCab, <number>event.codIntegracao);
        break;
    }
    return promise.then(() => undefined);
  }

  public getMarcarModalComponent(type: EGestaoDGEMPSType): Type<GestaoDGEMPSMarcarAbstractModalComponent> {
    switch (type) {
      case EGestaoDGEMPSType.Abonos:
        return PcaRhGestaoDGEMPSAbonosMarcarModalComponent;
      case EGestaoDGEMPSType.Faltas:
        return PcaRhGestaoDGEMPSFaltasMarcarModalComponent;
      case EGestaoDGEMPSType.Ferias:
        return PcaRhGestaoDGEMPSFeriasMarcarModalComponent;
      default:
        return undefined;
    }
  }

  public get portalClientAccountService(): PortalClientAccountService {
    if (!this._portalClientAccountService) {
      this._portalClientAccountService = this._injector.get<PortalClientAccountService>(PortalClientAccountService);
    }
    return this._portalClientAccountService;
  }
}
