import {Ng2StateDeclaration} from '@uirouter/angular';
import {StateService, TargetState, Transition, UIInjector} from '@uirouter/core';
import {fromJson} from 'pl-comps-angular';
import {
  EFatEletronicaConfCertificadoPill,
  EFatEletronicaConfTab,
  IFatEletronicaConfStateParams
} from '../../../modules/faturacaoeletronicaconfiguracoes/faturacaoEletronicaConfiguracoes.module.interface';
import {IOAuth2DigitalSignStateParams, IOAuth2DigitalSignStoredState, STATE_NAME_OAUTH2_DIGITAL_SIGN, STATE_URL_OAUTH2_DIGITAL_SIGN} from './oauth2.digitalsign.state.interface';
import {STATE_NAME_PORTAL} from '../../../services/portals/portals.service.interface';

export const STATE_OAUTH2_DIGITAL_SIGN: Ng2StateDeclaration = {
  name: STATE_NAME_OAUTH2_DIGITAL_SIGN,
  url: `/${STATE_URL_OAUTH2_DIGITAL_SIGN}?code&state`,
  redirectTo: stateDigitalSignRedirectTo,
  data: {
    disableRecover: true
  },
  params: {
    code: {type: 'string', value: '', squash: true},
    state: {type: 'string', value: '', squash: true}
  }
};

export function stateDigitalSignRedirectTo(transition: Transition): string | TargetState {
  const params: IOAuth2DigitalSignStateParams = <IOAuth2DigitalSignStateParams>transition.params();
  if (!params.state) {
    return STATE_NAME_PORTAL;
  }
  const state: IOAuth2DigitalSignStoredState = fromJson(window.sessionStorage.getItem(params.state) || null);
  if (!state) {
    return STATE_NAME_PORTAL;
  }
  const fatEletronicaConfStateParams: IFatEletronicaConfStateParams = {
    tab: EFatEletronicaConfTab.Certificado,
    certificadoPill: EFatEletronicaConfCertificadoPill.DigitalSign,
    digitalSign: {
      nomeAutorizador: state.nomeAutorizador,
      code: params.code,
      safetyKey: state.safetyKey,
      authenticate: state.authenticate,
      ambientePreProducao: state.ambientePreProducao,
      idAutorizador: state.idAutorizador,
      authorizerSecretDigitalSign: state.authorizerSecretDigitalSign
    }
  };
  const injector: UIInjector = transition.injector();
  const stateService: StateService = injector.get<StateService>(StateService);
  return stateService.target(state.stateName, fatEletronicaConfStateParams, {location: 'replace'});
}
