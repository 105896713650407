import {Subscription} from 'rxjs';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {isNumber, isObject} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EClassificadorArtigoTemplate} from '../../../../../common/enums/erp.enums';
import {EConfigOptionsInstanceName, IConfigOption, IFamiliasConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {ECriterio, EMtdCalcPrVen, IMetodoSource} from '../../../../interfaces/jsonArtigosTemplate.interface';
import {EGroupName} from '../../../../../config/constants';
import {IConfigOptionsInstance} from '../../../../services/config/options/config.options.instance.interface';
import {IJsonFamilia} from '../../jsonFamilia.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ROLE} from '../../../../services/role.const';

@Component({
  selector: 'familias-edit',
  templateUrl: './familias.entity.edit.component.html'
})
export class FamiliasEditComponent extends ModuloEntityDetailComponent<IJsonFamilia> implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly classArtTemplate: typeof EClassificadorArtigoTemplate;
  public readonly metodoSourceCalcPrVenda: Array<IMetodoSource>;
  public readonly metodoSourceCriterio: Array<IMetodoSource>;
  public readonly mtdOutput: string;
  public readonly tipoClassificador: number;
  public readonly tipoartigoOutput: string;
  public readonly grFamiliaOutput: string;
  public readonly subfamiliaOutput: string;
  public readonly unidadesMovimentoOutput: string;
  public readonly ivasOutput: string;
  public canEdit: boolean;
  public optionShowContasVendaCompra: boolean;
  public nomeCodCalcPrVen: string;
  public nomeCriterio: string;

  private readonly _subscriptionConfigOptions: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.classArtTemplate = EClassificadorArtigoTemplate;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.FAMILIAS;
    this.configOptionsGroupName = EGroupName.ERP;
    this.tipoartigoOutput = '{{codTpArt}} - {{nome}}';
    this.grFamiliaOutput = '{{nDepart}} - {{nome}}';
    this.subfamiliaOutput = '{{nSubFa}} - {{nome}}';
    this.unidadesMovimentoOutput = '{{codUni}} - {{nome}}';
    this.ivasOutput = '{{codIva}} - {{nome}}';
    this.mtdOutput = '{{valueMetodo}} - {{nameMetodo}}';
    this.nomeCodCalcPrVen = '';
    this.nomeCriterio = '';
    this.metodoSourceCalcPrVenda = [
      {
        valueMetodo: EMtdCalcPrVen.MargemPrecoVenda,
        nameMetodo: 'familias.metodos.margemprecovenda'
      },
      {
        valueMetodo: EMtdCalcPrVen.MargemPrecoCompra,
        nameMetodo: 'familias.metodos.margemprecocompra'
      }
    ];
    this.metodoSourceCriterio = [
      {
        valueMetodo: ECriterio.CustoMedioPonderado,
        nameMetodo: 'familias.metodos.customedioponderado'
      },
      {
        valueMetodo: ECriterio.Lifo,
        nameMetodo: 'familias.metodos.lifo'
      },
      {
        valueMetodo: ECriterio.Fifo,
        nameMetodo: 'familias.metodos.fifo'
      },
      {
        valueMetodo: ECriterio.CustoPadrao,
        nameMetodo: 'familias.metodos.custopadrao'
      },
      {
        valueMetodo: ECriterio.CustoMedio,
        nameMetodo: 'familias.metodos.customedio'
      },
      {
        valueMetodo: ECriterio.LoteEspecifico,
        nameMetodo: 'familias.metodos.loteespecifico'
      }
    ];
    this.tipoClassificador = this._configService.configurations.gestaoComercial.Stocks.ClassificacaoArtigoTemplate;
    const value: number = this._configService.configurations.acessos.erpcloud.gestaoComercial.editarContaArtigoFamilia;
    this.canEdit = isNumber(value) && value > 0;

    const configOptionsInstance: IConfigOptionsInstance<boolean, IFamiliasConfigOptions> = this._configOptionsService.getGroupOptions(this.configOptionsGroupName).get(this.configOptionsInstanceName);
    this._subscriptionConfigOptions = configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IFamiliasConfigOptions>) => {
      const showContasVendaCompra: IConfigOption<boolean> = configOptions.get('showContasVendaCompra');
      this.optionShowContasVendaCompra = showContasVendaCompra.value;
      if (!showContasVendaCompra.dirty) {
        this._authService.hasAuthority(ROLE.CONTABILIDADE).then((hasAuthority: boolean) => {
          if (hasAuthority && !this.optionShowContasVendaCompra) {
            configOptionsInstance.setOption('showContasVendaCompra', true);
          }
        });
      }
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        nFamilia: undefined,
        nome: '',
        artigosTemplate: undefined
      };
    }
    if (!this.model.artigosTemplate) {
      this.model.artigosTemplate = {
        codCalcPrVen: undefined,
        codIva: undefined,
        codIvaCompra: undefined,
        codValoriz: ECriterio.Fifo,
        movimentaStock: false,
        nContaPoc: '',
        nContaPocComp: '',
        nDepart: undefined,
        nFamilia: undefined,
        nSubfa: undefined,
        nUnidFisicas: 0,
        naoPExistNeg: 0,
        nomeContaPoc: '',
        nomeContaPocComp: '',
        nomeDepart: '',
        nomeFamilia: '',
        nomeIva: '',
        nomeIvaCompra: '',
        nomeSubfa: '',
        nomeTipoArtigo: '',
        nomeUniMov: '',
        qtdPorUnid1: 0,
        tipoArtigo: undefined,
        uniMov: undefined
      };
    }
    if (isNumber(this.model.artigosTemplate.codCalcPrVen)) {
      const indexCalcPrVen = this.metodoSourceCalcPrVenda.findIndex((value: IMetodoSource) => value.valueMetodo === this.model.artigosTemplate.codCalcPrVen);
      if (indexCalcPrVen !== -1) {
        this.nomeCodCalcPrVen = this._translateService.instant(this.metodoSourceCalcPrVenda[indexCalcPrVen].nameMetodo);
      }
    }
    if (isNumber(this.model.artigosTemplate.codValoriz)) {
      const indexCriterio = this.metodoSourceCriterio.findIndex((value: IMetodoSource) => value.valueMetodo === this.model.artigosTemplate.codValoriz);
      if (indexCriterio !== -1) {
        this.nomeCriterio = this._translateService.instant(this.metodoSourceCriterio[indexCriterio].nameMetodo);
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
  }

  public metodoCalcVendaChanged(value: {inputValue: string; item: IMetodoSource}): void {
    if (isObject(value.item) && value.item.valueMetodo !== this.model.artigosTemplate.codCalcPrVen) {
      this.model.artigosTemplate.codCalcPrVen = value.item.valueMetodo;
      this.nomeCodCalcPrVen = value.item.nameMetodo;
    } else if (isNumber(Number(value.inputValue))) {
      const index = this.metodoSourceCalcPrVenda.findIndex((source: IMetodoSource) => source.valueMetodo === Number(value.inputValue));
      if (index !== -1) {
        const mtdCalcPrVen: Array<IMetodoSource> = this.metodoSourceCalcPrVenda.slice();
        this.model.artigosTemplate.codCalcPrVen = mtdCalcPrVen[index].valueMetodo;
        this.nomeCodCalcPrVen = mtdCalcPrVen[index].nameMetodo;
      }
    }
  }

  public metodoCriterioChanged(value: {inputValue: string; item: IMetodoSource}): void {
    if (isObject(value.item) && value.item.valueMetodo !== this.model.artigosTemplate.codValoriz) {
      this.model.artigosTemplate.codValoriz = value.item.valueMetodo;
      this.nomeCodCalcPrVen = value.item.nameMetodo;
    } else if (isNumber(Number(value.inputValue))) {
      const index = this.metodoSourceCriterio.findIndex((source: IMetodoSource) => source.valueMetodo === Number(value.inputValue));
      if (index !== -1) {
        const mtdCriterio: Array<IMetodoSource> = this.metodoSourceCriterio.slice();
        this.model.artigosTemplate.codValoriz = mtdCriterio[index].valueMetodo;
        this.nomeCriterio = mtdCriterio[index].nameMetodo;
      }
    }
  }
}
