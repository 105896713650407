import {VerificacaoAcumuladosComponent} from './components/verificacaoAcumulados.module.component';
import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';

export const MODULE_PCA_MANUTENCAO_VERIFICAO_ACUMULADOS: IModuleDefinition = {
  name: 'verificacaoacumulados',
  state: {
    url: '/verificacaoacumulados',
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.verificacaoacumulados'
    },
    component: VerificacaoAcumuladosComponent
  }
};
