import {HttpResponse} from '@angular/common/http';
import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {copy, EDelphiNumberTypes, IPlToolbarItem, isEmpty, isNumber, isObject, isString, PlAlertService, toInteger} from 'pl-comps-angular';
import {IJsonString} from '../../../../../../common/interfaces/json';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridSummaryCalculateCustomSummaryOptions,
  TDevExpressDataGridSummaryCalculateCustomSummaryFn
} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnOptionChanged
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridState} from '../../../../../components/devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {devExpressDataGridExpandDetailHandler, devExpressDataGridStateSortToOrder} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {AuthService} from '../../../../../services/auth/auth.service';
import {CONTABILIDADE_DIGITAL_SERVICE_PROVIDER, CONTABILIDADE_DIGITAL_SERVICE_UI_PROVIDER} from '../../../../../services/contabilidadedigital/contabilidadedigital.providers';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ROLE} from '../../../../../services/role.const';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {ENTITY_NAME_POCS} from '../../../../../entities/pocs/pocs.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../../../docscontabilidade/docsContabilidade.interface';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {IContabilidadeExtratosGrid, IContabilidadeExtratosGridModel, IContabilidadeExtratosGridParams, IContabilidadeExtratosGridTableItem} from '../contabilidade.extratosGrid.module.interface';
import {ContabilidadeExtratosGridModalComponent} from '../modal/contabilidade.extratosGrid.modal.component';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {EConfigOptionsInstanceName, IExtratosDeContabilidadeConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {Subscription} from 'rxjs';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {FormGroupDirective} from '@angular/forms';

const LENGTH_YEAR = 4;
const NUMBER_3 = 3;
const ORDER = 'periodo,nDiario,nDocInterno';
const BTN_PROCESS_PDF = 'showPdf';

@Component({
  selector: 'contabilidade-extratosgrid',
  templateUrl: './contabilidade.extratosGrid.module.component.html',
  providers: [CONTABILIDADE_DIGITAL_SERVICE_PROVIDER, CONTABILIDADE_DIGITAL_SERVICE_UI_PROVIDER]
})
export class ContabilidadeExtratosGridComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public empresaTemContabilidadeDigital: boolean;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly outputPeriodo: string;
  public readonly filter: string;
  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public form: FormGroupDirective;
  public header: IContabilidadeExtratosGridModel;
  public dataGridDefinition: IDevExpressDataGrid;
  public extratosGrid: Array<IContabilidadeExtratosGridTableItem>;
  public showPeriodos: boolean;
  public conta: Partial<IJsonPOC>;
  public fieldPorPagarVisible: boolean;
  public totalDebito: number;
  public totalCredito: number;
  public totalSaldo: number;
  public totalPorPagar: number;
  public promise: Promise<void>;
  public promiseFindPreviousNConta: Promise<void>;
  public promiseFindNextNConta: Promise<void>;
  public optionShowDiario: boolean;
  public nome: string;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _servicePocs: IEntityService<IJsonPOC>;
  private readonly _servicePeriodos: IPeriodosEntityService;
  private readonly _btnProcessarPDF: IPlToolbarItem;
  private readonly _params: IContabilidadeExtratosGridParams;
  private readonly _isFromParamsAi: boolean;
  private _elementExtratosTotal: HTMLElement;
  private _periodos: Array<IJsonPeriodo>;
  private _dataGridInstance: dxDataGrid;
  private _dataGridOrder: string;
  private _cardPanel: CGCardPanelComponent;
  private _subscriptionConfigOptions: Subscription;
  private _header: IContabilidadeExtratosGridModel;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _renderer: Renderer2
  ) {
    super(_injector);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.EXTRATOS_CONTABILIDADE;
    this.configOptionsGroupName = EGroupName.CONTABILIDADE;
    this.extratosGrid = [];
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.nome = 'paizinho';
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.callbackDocViewer = {};
    this._params = <IContabilidadeExtratosGridParams>this._transition.params();
    this._isFromParamsAi = !isEmpty(this._params.nConta);
    this.header = {
      nConta: this._isFromParamsAi ? this._params.nConta : undefined,
      nomeConta: undefined,
      dePeriodo: this._params.dePeriodo,
      dePeriodoNome: undefined,
      atePeriodo: this._params.atePeriodo,
      atePeriodoNome: undefined,
      loadAllAnos: 1,
      loadOnlyAno: 0,
      dePeriodoAno: undefined,
      deNDiario: 0,
      ateNDiario: EDelphiNumberTypes.MaxSmallInt
    };
    this.showPeriodos = false;
    this.conta = {};
    this.fieldPorPagarVisible = false;
    this.totalCredito = 0;
    this.totalDebito = 0;
    this.totalSaldo = 0;
    this.totalPorPagar = 0;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
    this._servicePeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._dataGridOrder = ORDER;

    this.promise = (async () => {
      try {
        this.showPeriodos = await this._authService.hasAuthority(ROLE.CONTABILIDADE);
        await this._loadPeriodos(!isEmpty(this._params.atePeriodo));
        if (this._params.nConta) {
          const response: HttpResponse<IJsonPOC> = await this._servicePocs.get({id: this._params.nConta, params: {multiano: true}});
          if (response) {
            this.conta = response.body;
            this.header.nConta = this.conta.nConta;
            this.header.nomeConta = this.conta.nome;
            await this._extratos();
          }
        }
      } catch (reason: unknown) {
        this._logger.error(reason);
      } finally {
        this._evaluateFieldPorPagarVisible();
      }
    })();

    this._btnProcessarPDF = {
      id: BTN_PROCESS_PDF,
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: true,
      tooltip: {
        text: 'global.text.pleaseSearchFirst',
        placement: ['bottom', 'bottom-left']
      },
      click: () => {
        this._showPDFModal().then(() => undefined);
      }
    };
    this.search = this.search.bind(this);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    const configOptionsInstance = this._configOptionsService.getOptionsContabilidade().get(this.configOptionsInstanceName);
    this._subscriptionConfigOptions = configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IExtratosDeContabilidadeConfigOptions>) => {
      this.optionShowDiario = configOptions.get('showDiario').value;
    });
    this.dataGridDefinition = {
      keyExpr: 'nLanc',
      columns: [
        {dataField: 'perioDiarioDoc', caption: 'components.contabilidade.extratosgrid.linhas.perioDiarioDoc', dataType: 'string', allowSorting: false},
        {dataField: 'nDocExterno', caption: 'components.contabilidade.extratosgrid.linhas.nDocExterno', dataType: 'string'},
        {dataField: 'dataVenc', caption: 'components.contabilidade.extratosgrid.linhas.dataVenc', dataType: 'date'},
        {dataField: 'dataDocExt', caption: 'components.contabilidade.extratosgrid.linhas.dataDoc', dataType: 'date'},
        {dataField: 'descricao', caption: 'components.contabilidade.extratosgrid.linhas.descricao', dataType: 'string', width: 145},
        {dataField: 'descritivo', caption: 'components.contabilidade.extratosgrid.linhas.descritivo', dataType: 'string'},
        {dataField: 'stampInsert', caption: 'components.contabilidade.extratosgrid.linhas.stampInsert', dataType: 'datetime'},
        {
          dataField: 'valorDebito',
          caption: 'components.contabilidade.extratosgrid.linhas.valorDebito',
          dataType: 'double',
          calculateCellValue: (rowData: IContabilidadeExtratosGridTableItem) => (isNumber(rowData.valorDebito) && rowData.valorDebito !== 0 ? rowData.valorDebito : '')
        },
        {
          dataField: 'valorCredito',
          caption: 'components.contabilidade.extratosgrid.linhas.valorCredito',
          dataType: 'double',
          calculateCellValue: (rowData: IContabilidadeExtratosGridTableItem) => (isNumber(rowData.valorCredito) && rowData.valorCredito !== 0 ? rowData.valorCredito : '')
        },
        {dataField: 'saldo', caption: 'global.text.saldo', dataType: 'double', allowSorting: false},
        {
          dataField: 'porPagar',
          caption: 'components.contabilidade.extratosgrid.linhas.porPagar',
          dataType: 'double',
          visible: this.fieldPorPagarVisible,
          showInColumnChooser: this.fieldPorPagarVisible,
          calculateCellValue: (rowData: IContabilidadeExtratosGridTableItem) => (isNumber(rowData.porPagar) && rowData.porPagar !== 0 ? rowData.porPagar : '')
        },
        {
          dataField: 'temDocDigital',
          caption: 'docscontabilidade.fields.temDocDigital',
          dataType: 'boolean',
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSearch: false,
          allowSorting: false,
          visible: this.empresaTemContabilidadeDigital,
          showInColumnChooser: this.empresaTemContabilidadeDigital
        },
        {
          dataField: 'periodo',
          caption: 'components.contabilidade.extratosgrid.linhas.periodo',
          dataType: 'string',
          visible: false
        },
        {
          dataField: 'nDiario',
          caption: 'components.contabilidade.extratosgrid.linhas.diario',
          dataType: 'string',
          visible: false
        },
        {
          dataField: 'nDocumento',
          caption: 'components.contabilidade.extratosgrid.linhas.ndocInterno',
          dataType: 'string',
          visible: false
        }
      ],
      export: {filename: 'global.menu.extratosgrid'},
      height: '65vh',
      masterDetail: {enabled: true, template: 'detailTemplate'},
      paging: {enabled: false},
      scrolling: {mode: 'standard', rowRenderingMode: 'virtual'},
      sorting: {mode: 'single'},
      summary: {
        calculateCustomSummary: this._fnCalculateCustomSummary,
        totalItems: [
          {column: 'valorDebito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorCredito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'saldo', summaryType: 'custom', name: 'totalSaldo', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'porPagar', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      },
      filterRow: {visible: false}
    };
    this._evaluateMaintenanceModeFullscreen();
    this.toolbar.addButton(this._btnProcessarPDF);
    await this._loadFromAi();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public changedConta(value: string | IJsonPOC): void {
    if (!value) {
      delete this.conta.nConta;
      delete this.conta.nome;
      delete this.conta.cc;
    } else if (isString(value)) {
      this.conta.nConta = value;
      delete this.conta.nome;
      delete this.conta.cc;
    } else {
      this.conta = value;
    }
    this.header.nConta = this.conta.nConta;
    this.header.nomeConta = this.conta.nome;
    this._evaluateFieldPorPagarVisible();
    this._evaluateBtnProcessarPDF();
  }

  public async changedDePeriodo(value: string, sysChange: boolean = false): Promise<void> {
    this.header.dePeriodo = value;
    this.header.dePeriodoAno = '';
    if (this.header.dePeriodo) {
      let dePeriodoAno: string = this.header.dePeriodo.substring(0, LENGTH_YEAR);
      if (dePeriodoAno) {
        dePeriodoAno = `&periodo=%${dePeriodoAno}%`;
        this.header.dePeriodoAno = dePeriodoAno;
      }
      if (!sysChange) {
        this.header.atePeriodo = `${value.substring(0, LENGTH_YEAR)}123`;
      }
      const ano = toInteger(value.substring(0, LENGTH_YEAR));
      if (ano === this._configService.configurations.empresa.anoEmCursoIRC || ano === this._configService.configurations.empresa.anoEmCursoIRC + 1) {
        this.header.loadOnlyAno = 0;
        this.header.loadAllAnos = 0;
      } else {
        this.header.loadOnlyAno = ano;
        this.header.loadAllAnos = 1;
      }
      await this._checkPeriodos();
    }
    this.header.dePeriodoNome = this._getDescriptionFromPeriodo(this.header.dePeriodo);
    this.header.atePeriodoNome = this._getDescriptionFromPeriodo(this.header.atePeriodo);
    this.header = {...this.header};
    this._evaluateBtnProcessarPDF();
  }

  public async changedAtePeriodo(value: string): Promise<void> {
    this.header.atePeriodo = value;
    const periodoDe = toInteger(this.header.dePeriodo);
    const periodoAte = toInteger(this.header.atePeriodo);

    if (periodoAte < periodoDe) {
      this.header.dePeriodo = value;
    }

    this.header.dePeriodoNome = this._getDescriptionFromPeriodo(this.header.dePeriodo);
    this.header.atePeriodoNome = this._getDescriptionFromPeriodo(this.header.atePeriodo);

    this.header = {...this.header};

    if (this.header.dePeriodo && this.header.atePeriodo) {
      await this._checkPeriodos();
    }
    this._evaluateBtnProcessarPDF();
  }

  public async findNextNConta(findNext: boolean): Promise<void> {
    try {
      const response: HttpResponse<IJsonString> = await this._contabilidadeEstatisticaService.getContaAfterOrBefore(this.header.nConta, findNext);
      if (isEmpty(response.body.value)) {
        return;
      }
      const responsePocs: HttpResponse<IJsonPOC> = await this._servicePocs.get({id: response.body.value, params: {multiano: true}});
      this.conta = responsePocs.body;
      this.header.nConta = this.conta.nConta;
      this.header.nomeConta = this.conta.nome;
      await this._extratos();
    } catch (error) {
      console.error(error);
    } finally {
      if (!findNext) {
        this.promiseFindPreviousNConta = Promise.resolve();
      } else {
        this.promiseFindNextNConta = Promise.resolve();
      }
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public async onCellClick(event: IDevExpressDataGridEventOnCellClick<IContabilidadeExtratosGridTableItem>): Promise<void> {
    await devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
  }

  public async onOptionChanged(event: IDevExpressDataGridEventOnOptionChanged): Promise<void> {
    if (event.fullName.endsWith('sortOrder') || event.fullName.endsWith('sortIndex')) {
      const state: IDevExpressDataGridState = event.component.state();
      const order: string = devExpressDataGridStateSortToOrder(state);
      await this._extratos(order);
    }
  }

  public onDataGridStateLoad(event: IDevExpressDataGridState): void {
    this._dataGridOrder = devExpressDataGridStateSortToOrder(event) || ORDER;
  }

  public deNDIarioChanged(): void {
    if (isNumber(this.header.deNDiario) && this.header.deNDiario > 0) {
      this.header.ateNDiario = this.header.deNDiario;
    }
  }

  public ateNDIarioChanged(ndiario: number): void {
    this.header.ateNDiario = ndiario;
    if (!ndiario) {
      setTimeout(() => {
        this.header.ateNDiario = EDelphiNumberTypes.MaxSmallInt;
      });
    }
  }

  public async search(): Promise<void> {
    if (isEmpty(this.conta?.nConta)) {
      this._plAlertService.error('components.contabilidade.extratosgrid.contaInvalida');
      return;
    }
    await this._extratos();
  }

  @ViewChild('elementExtratosTotais')
  public set elementModalOpen(value: ElementRef<HTMLElement>) {
    this._elementExtratosTotal = value?.nativeElement;
    if (this.maintenanceMode && this._elementExtratosTotal) {
      setTimeout(() => {
        const elementModal: HTMLElement = this._elementExtratosTotal.closest('div.modal-body');
        if (elementModal) {
          const modal = this._elementExtratosTotal.closest('.maintenance-modal');
          const modalFooter = modal.querySelector('.modal-footer');
          const refChild = modalFooter.firstElementChild;
          this._renderer.insertBefore(modalFooter, this._elementExtratosTotal, refChild);
        }
      });
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  protected _onConfigurationsChanged(): void {
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    if (this._dataGridInstance && this._dataGridInstance.columnOption('temDocDigital', 'visible') !== this.empresaTemContabilidadeDigital) {
      this._dataGridInstance.columnOption('temDocDigital', 'visible', this.empresaTemContabilidadeDigital);
      this._dataGridInstance.columnOption('temDocDigital', 'showInColumnChooser', this.empresaTemContabilidadeDigital);
    }
    this._evaluateMaintenanceModeFullscreen();
  }

  private _evaluateMaintenanceModeFullscreen(): void {
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(this.empresaTemContabilidadeDigital);
    }
  }

  private async _loadPeriodos(sysChange: boolean = false): Promise<void> {
    const response: THttpQueryResponse<IJsonPeriodo> = await this._servicePeriodos.query();
    this._periodos = response.body.list;
    if (!this.header.dePeriodo && !this.header.atePeriodo) {
      if (this._periodos.length) {
        this.header = {
          ...this.header,
          dePeriodoNome: this._periodos[0].nome,
          dePeriodo: this._periodos[0].periodo,
          atePeriodo: this._periodos[this._periodos.length - 1].periodo,
          atePeriodoNome: this._periodos[this._periodos.length - 1].nome
        };
        await this.changedDePeriodo(this.header.dePeriodo, true);
      }
    } else {
      await this.changedDePeriodo(this.header.dePeriodo, sysChange);
    }
  }

  private async _checkPeriodos(): Promise<void> {
    if (this.header.dePeriodo && this.header.atePeriodo) {
      return this._servicePeriodos.obterAnoDosPeriodos(this.header.dePeriodo, this.header.atePeriodo).then(() => undefined);
    }
    return Promise.resolve();
  }

  private async _extratos(order: string = this._dataGridOrder): Promise<void> {
    order = order.replace(/\bdataVenc\b/, 'dataVencimento').replace(/\bdescritivo\b/, 'nDescritivo');
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      const response: THttpQueryResponse<IContabilidadeExtratosGrid> = await this._contabilidadeEstatisticaService.extratos(this.header, '', order);
      for (const extrato of response.body.list) {
        extrato.perioDiarioDoc = `${extrato.periodo}.${extrato.nDiario}.${extrato.nDocumento.trim()}`;
      }
      this._header = copy(this.header);
      this.extratosGrid = response.body.list;
      this._countTotalImputar(this.extratosGrid);
      this._evaluateFieldPorPagarVisible();
      if (this.extratosGrid?.length) {
        this._cardPanel.collapse();
        this._evaluateBtnProcessarPDF(false);
        setTimeout(() => {
          this.toolbar.focusItem(BTN_PROCESS_PDF);
        }, 0);
      } else {
        this._cardPanel.focusFirstElement();
        this._evaluateBtnProcessarPDF();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch {
      this._evaluateBtnProcessarPDF();
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private _countTotalImputar(extratos: Array<IContabilidadeExtratosGrid>): void {
    this.totalCredito = 0;
    this.totalDebito = 0;
    this.totalSaldo = 0;
    this.totalPorPagar = 0;
    if (extratos.length) {
      for (const extrato of extratos) {
        if (extrato.valorDebito) {
          this.totalDebito += extrato.valorDebito;
        }
        if (extrato.valorCredito) {
          this.totalCredito += extrato.valorCredito;
        }
        if (extrato.porPagar) {
          this.totalPorPagar += extrato.porPagar;
        }
      }
      this.totalSaldo = extratos[extratos.length - 1].saldo;
    }
  }

  private _showPDFModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeExtratosGridModalComponent);
    const componentInstance: ContabilidadeExtratosGridModalComponent = modalInstance.componentInstance;
    componentInstance.header = this._header;
    return modalInstance.result;
  }

  private _getDescriptionFromPeriodo(periodoKey: string): string {
    periodoKey = periodoKey.substring(periodoKey.length - NUMBER_3);
    return this._periodos.find((periodo) => periodo.periodo.substring(periodo.periodo.length - NUMBER_3) === periodoKey).nome;
  }

  private _evaluateFieldPorPagarVisible(): void {
    this.fieldPorPagarVisible = isObject(this.conta) && this.conta.cc === true;
    if (this._dataGridInstance) {
      this._dataGridInstance.columnOption('porPagar', {
        visible: this.fieldPorPagarVisible,
        showInColumnChooser: this.fieldPorPagarVisible
      });
    }
  }

  private async _onDetail(item: IContabilidadeExtratosGridTableItem): Promise<void> {
    const result = await this._serviceDocsContabilidade.get({id: item.extPocCabId, params: {ano: item.ano}});
    item._thedoc = result.body;
    if (this.empresaTemContabilidadeDigital) {
      item._contabDigitalDoc = {
        extPocCabID: item.extPocCabId,
        periodo: item.periodo,
        nDiario: item.nDiario,
        nDocInterno: item.nDocInterno,
        dataDoc: item.dataDoc
      };
    }
  }

  private _calculateCustomSummary(summary: IDevExpressDataGridSummaryCalculateCustomSummaryOptions<number>): void {
    if (summary.name === 'totalSaldo' && summary.summaryProcess === 'finalize') {
      summary.totalValue = this.totalSaldo;
    }
  }

  private async _loadFromAi(): Promise<void> {
    if (this._isFromParamsAi) {
      await Promise.resolve(this.promise).then(async () => {
        if (!isEmpty(this._params.nConta)) {
          this.conta.nConta = this._params.nConta;
          this.changedConta(this._params.nConta);
          await this.search();

          if (this._params.process) {
            await this._showPDFModal();
          }
        }
      });
    }
  }

  private readonly _fnCalculateCustomSummary: TDevExpressDataGridSummaryCalculateCustomSummaryFn<number> = (summary: IDevExpressDataGridSummaryCalculateCustomSummaryOptions<number>) => {
    this._calculateCustomSummary(summary);
  };

  private _evaluateBtnProcessarPDF(disabled: boolean = true): void {
    this._btnProcessarPDF.disabled = disabled;
    this._btnProcessarPDF.tooltip.disabled = !disabled;
  }
}
