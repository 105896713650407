import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_QPCNP} from './qpcnp.entity.interface';
import {IJsonQPCNP} from './jsonQPCNP.entity.interface';

export const ENTITY_QPCNP: IEntityDefinition<IJsonQPCNP> = {
  name: ENTITY_NAME_QPCNP,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.qpcnp',
  searchPlaceholder: 'qpcnp.pesquisa',
  metadata: {
    keyName: 'codNacProfissao',
    fields: [
      {name: 'codNacProfissao', caption: 'qpcnp.fields.codNacProfissao', placeholder: 'qpcnp.fields.codNacProfissao'},
      {name: 'nome', caption: 'qpcnp.fields.nome', placeholder: 'qpcnp.fields.nome'},
      {name: 'tipo', caption: 'qpcnp.fields.tipo', placeholder: 'qpcnp.fields.tipo'}
    ],
    order: 'codNacProfissao',
    searchFields: 'codNacProfissao,nome,tipo',
    listFields: 'codNacProfissao,nome,tipo'
  },
  autocomplete: {
    rowTemplate: '{{codNacProfissao}} - {{nome}}',
    output: 'codNacProfissao',
    searchFields: 'codNacProfissao,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    delete: false,
    search: true,
    filter: true
  }
};
