import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem, isEmpty, isNumber, PlAlertService} from 'pl-comps-angular';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {
  EEstadoAcrescimoEncFerias,
  IJsonAcrescimoEncargoComFeriasCabConfig,
  IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas,
  IJsonAcrescimoEncargoComFeriasLinMulti,
  IJsonAcrescimosEncFeriasDocContabIntegrado
} from '../jsonAcrescimosEncargosFerias.module.interface';
import ArrayStore from 'devextreme/data/array_store';
import {AcrescimosEncargosFeriasService} from '../acrescimosEncargosFerias.module.service';
import {HttpResponse} from '@angular/common/http';
import {minDateCG} from '../../../../common/utils/utils';
import {AcrescimosEncargosFeriasConfigModalComponent} from '../modals/config/acrescimosEncargosFerias.config.modal.component';
import {AcrescimosEncargosFeriasGerarEncargosMultiModalComponent} from '../modals/gerarencargosmulti/acrescimosEncargosFerias.gerarEncargosMulti.modal.component';
import {AcrescimosEncargosFeriasMultiempresadetailModalComponent} from '../modals/multiempresadetail/acrescimosEncargosFerias.multiempresadetail.modal.component';
import moment from 'moment';
import {IAcrescimoEncFeriasSingleCallback} from '../acrescimosEncargosFerias.module.interface';
import {AcrescimosEncargosFeriasPrintModalComponent} from '../modals/print/acrescimosEncargosFerias.print.modal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent} from '../modals/integcontabmultierrors/acrescimosEncargosFerias.integContabMultiErrors.modal.component';

const TOOLBAR_GROUP_ID_CONTAB_MULTI = 'acrescimosencargosferias-contab-multi';
const TOOLBAR_GROUP_ID_ACTIONS_MULTI = 'acrescimosencargosferias-actions-multi';
const TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI = 'TOOLBAR_ACRESCIMOS_ENCARGOS_MULTI';
const TAB_ID_POR_EMPRESA = 'porEmpresa';
const TAB_ID_MULTI_EMPRESA = 'multiEmpresa';

@Component({
  selector: 'module-acrescimos-encargos-ferias',
  templateUrl: './acrescimosEncargosFerias.module.component.html'
})
export class AcrescimosEncargosFeriasModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinitionMulti: IDevExpressDataGrid;
  public readonly acrescimosEncSingleCallback: IAcrescimoEncFeriasSingleCallback;

  public docsContabIntegList: Array<IJsonAcrescimosEncFeriasDocContabIntegrado>;
  public estadoAcresEncFeriasType: typeof EEstadoAcrescimoEncFerias;
  public toolbarAcrescimoEncargosMultiId: string;
  public activeTab: string;
  public anoEncargo: number;
  public nEmpresa: string;

  private readonly _btnCarregar: IPlToolbarMenuItem;
  private readonly _btnAssistenteEncargosMulti: IPlToolbarMenuItem;
  private readonly _btnAnularEncargosSingle: IPlToolbarMenuItem;
  private readonly _btnLancarDocumentoMulti: IPlToolbarMenuItem;
  private readonly _btnApagarDocumentoMulti: IPlToolbarMenuItem;
  private readonly _btnVerificarInconsistenciasMulti: IPlToolbarMenuItem;
  private readonly _menuBtnContabilidade: IPlToolbarItem;
  private readonly _menuBtnActions: IPlToolbarItem;
  private readonly _btnConfig: IPlToolbarMenuItem;

  private readonly _docsContabilidadeMaintenanceInstance: IEntityMaintenanceInstance;

  private _mnuAnos: IPlToolbarItem;
  private _dataGridInstanceMulti: dxDataGrid;
  private _selectedAno: IPlToolbarMenuItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _acrescimosEncargosService: AcrescimosEncargosFeriasService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this._docsContabilidadeMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.acrescimosEncSingleCallback = {};
    this.docsContabIntegList = [];
    this.estadoAcresEncFeriasType = EEstadoAcrescimoEncFerias;
    this._btnCarregar = {
      id: 'carregar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-refresh fa-fw"></i>',
      caption: 'global.btn.load',
      class: 'btn-success',
      click: () => this._carregarAcrescimosEncargos()
    };
    this._btnAnularEncargosSingle = {
      id: 'anular-encargos',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-times fa-fw"></i>',
      caption: 'global.btn.nullify',
      class: 'btn-danger',
      click: () => this._anularAcrescimosEncargos()
    };
    this._btnAssistenteEncargosMulti = {
      id: 'assistente-encargos',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-plus fa-fw"></i>',
      caption: 'acrescimosencargosferias.btns.assistente',
      class: 'btn-success',
      visible: false,
      click: () => this._assistenteEncargosMulti()
    };
    this._btnConfig = {
      id: 'config',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      caption: 'global.btn.config',
      class: 'btn-light',
      click: () => this.openModalConfig(this.configurations.empresa.nEmpresa)
    };
    this._btnLancarDocumentoMulti = {
      groupId: TOOLBAR_GROUP_ID_CONTAB_MULTI,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}-lancar-documento-multi`,
      iconLeft: '<i class="fa fa-file-o fa-fw"></i>&nbsp;',
      caption: 'acrescimosencargosferias.btns.lancarDocumentoMulti',
      click: () => this._lancarDocumentoMulti()
    };
    this._btnApagarDocumentoMulti = {
      groupId: TOOLBAR_GROUP_ID_CONTAB_MULTI,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}-apagar-documento-multi`,
      iconLeft: '<i class="fa fa-times fa-fw"></i>&nbsp;',
      caption: 'acrescimosencargosferias.btns.apagarDocumentoMulti',
      click: () => this._apagarDocumentoMulti()
    };
    this._menuBtnContabilidade = {
      groupId: TOOLBAR_GROUP_ID_CONTAB_MULTI,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}-menu-contab`,
      order: 2,
      type: 'dropdown',
      caption: 'acrescimosencargosferias.btns.integraContab',
      class: 'btn-success',
      menu: [this._btnLancarDocumentoMulti, this._btnApagarDocumentoMulti],
      disabled: true
    };
    this._btnVerificarInconsistenciasMulti = {
      groupId: TOOLBAR_GROUP_ID_ACTIONS_MULTI,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}-verificar-inconsistencias-multi`,
      iconLeft: '<i class="fa fa-check-square fa-fw"></i>&nbsp;',
      caption: 'acrescimosencargosferias.btns.verificarInconsistenciasMulti',
      click: () => this._verificarInconsistenciasMulti()
    };
    this._menuBtnActions = {
      groupId: TOOLBAR_GROUP_ID_ACTIONS_MULTI,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}-menu-actions`,
      order: 3,
      type: 'dropdown',
      caption: 'global.btn.actions',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-bars"></i>',
      menu: [this._btnVerificarInconsistenciasMulti],
      disabled: true
    };
    this.dataGridDefinitionMulti = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'acrescimosencargosferias.fields.dados',
          columns: [
            {dataField: 'nEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.fields.nEmpresa'},
            {dataField: 'nomeEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.fields.nomeEmpresa'},
            {dataField: 'anoEncargo', dataType: 'number', caption: 'global.text.year'},
            {dataField: 'tipoID', dataType: 'number', caption: 'acrescimosencargosferias.fields.tipoID', customizeText: this._fnCustomizeTextTipo, alignment: 'center'},
            {dataField: 'estadoID', dataType: 'number', caption: 'acrescimosencargosferias.fields.estadoID', cellTemplate: 'cellTemplateStatus', alignment: 'center'},
            {dataField: 'dataIntegracao', dataType: 'date', caption: 'acrescimosencargosferias.fields.dataIntegracao'},
            {dataField: 'infoErro', dataType: 'number', caption: 'global.text.error'},
            {
              dataField: 'descricaoErro',
              dataType: 'string',
              caption: 'acrescimosencargosferias.fields.descricaoErro',
              cellTemplate: 'cellTemplateDescricaoErro',
              alignment: 'center'
            }
          ]
        },
        {
          caption: 'acrescimosencargosferias.fields.totaisEncargos',
          columns: [
            {dataField: 'valorAcertoManual', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorAcertoManual'},
            {dataField: 'valorSubFerias', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorSubFerias'},
            {dataField: 'valorFerias', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorFerias'},
            {dataField: 'valorEncargosSS', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorEncargosSS'},
            {dataField: 'valorEncargoAcTrab', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorEncargoAcTrab'},
            {dataField: 'valorOutrosEncargos', dataType: 'double', caption: 'global.text.other'},
            {dataField: 'total', dataType: 'double', caption: 'global.text.total'}
          ]
        },
        {type: 'buttons', caption: 'global.text.actions', cellTemplate: 'cellTemplateBtnsMulti'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.acrescimosencargoscomferias'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always', selectAllMode: 'allPages'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'valorAcertoManual', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorSubFerias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorFerias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorEncargosSS', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorEncargoAcTrab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorOutrosEncargos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'total', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.activeTab = TAB_ID_POR_EMPRESA;
    this.nEmpresa = this.configurations.empresa.nEmpresa;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const anoEmCursoIRC: number = this.configurations.empresa.anoEmCursoIRC;
    this.anoEncargo = anoEmCursoIRC;
    const anos: Array<IPlToolbarMenuItem> = [];
    const itemYear: IPlToolbarMenuItem = {
      caption: String(anoEmCursoIRC),
      active: true,
      click: (menuItem: IPlToolbarMenuItem) => {
        this._changedAno(menuItem);
      }
    };
    anos.push(itemYear, {
      caption: String(anoEmCursoIRC + 1),
      active: false,
      click: (menuItem: IPlToolbarMenuItem) => {
        this._changedAno(menuItem);
      }
    });
    this._selectedAno = itemYear;

    this._mnuAnos = {order: 1, id: 'listMnuAnos', caption: this._getCaptionAno(), class: 'btn-primary', type: 'dropdown', menu: anos};

    const toolbarInstanceMulti: IPlToolbarInstance = this._plToolbarService.getInstance(this.toolbarAcrescimoEncargosMultiId);
    toolbarInstanceMulti.setItems([this._menuBtnContabilidade, this._menuBtnActions]);

    this.toolbar.addButton(this._mnuAnos).addButton(this._btnCarregar).addButton(this._btnAnularEncargosSingle).addButton(this._btnAssistenteEncargosMulti).addButton(this._btnConfig);
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarAcrescimoEncargosMultiId = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_MULTI}`;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.toolbarAcrescimoEncargosMultiId);
  }

  public changedTab(): void {
    this._btnAnularEncargosSingle.visible = this.activeTab !== TAB_ID_MULTI_EMPRESA;
    this._btnConfig.visible = this.activeTab !== TAB_ID_MULTI_EMPRESA;
    this._btnAssistenteEncargosMulti.visible = this.activeTab === TAB_ID_MULTI_EMPRESA;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceMulti = component;
  }

  public onSelectedRowKeysMuliChanged(selectedRowKeys: Array<string>): void {
    this._menuBtnContabilidade.disabled = !selectedRowKeys.length;
    this._menuBtnActions.disabled = !selectedRowKeys.length;
  }

  public showMessageInfo(info: string): void {
    this._cgModalService.showOkCancel('global.text.info', info, {size: 'md', showCancelBtn: false, showCloseBtn: true, showOkBtn: false});
  }

  public openModalDetail(nEmpresa: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasMultiempresadetailModalComponent);
    const componentInstance: AcrescimosEncargosFeriasMultiempresadetailModalComponent = modalInstance.componentInstance;
    componentInstance.anoEncargo = this.anoEncargo;
    componentInstance.nEmpresa = nEmpresa;
    return modalInstance.result.then((needRefresh: boolean) => {
      if (needRefresh) {
        return this._carregaAcrescimosEncMulti();
      }
      return Promise.resolve();
    });
  }

  public printEncargos(nEmpresa: string): void {
    const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasPrintModalComponent, {size: 'xl'});
    const componentInstance: AcrescimosEncargosFeriasPrintModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = nEmpresa;
    componentInstance.anoEncargo = this.anoEncargo;
  }

  public apagaEncargosMulti(item: IJsonAcrescimoEncargoComFeriasLinMulti): Promise<void> {
    this._dataGridInstanceMulti.beginCustomLoading(undefined);
    return this._acrescimosEncargosService
      .apagaEncargosMulti([item])
      .then((responseAcrescimos: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        this._applyAcrescimosEncargosMulti(responseAcrescimos.body);
      })
      .finally(() => {
        this._dataGridInstanceMulti.endCustomLoading();
      });
  }

  public previewDocContab(item: IJsonAcrescimosEncFeriasDocContabIntegrado): void {
    this._docsContabilidadeMaintenanceInstance.maintenanceEdit(item.extPocCabID, {preventDocumentKeyup: false});
  }

  public getDocsInteg(item: IJsonAcrescimoEncargoComFeriasLinMulti): Promise<void> {
    this.docsContabIntegList = [];
    if (item.estado !== EEstadoAcrescimoEncFerias.Integrado) {
      return Promise.resolve();
    }

    return this._acrescimosEncargosService.docsContabInteg(item.anoEncargo, item.nEmpresa).then((response: HttpResponse<Array<IJsonAcrescimosEncFeriasDocContabIntegrado>>) => {
      this.docsContabIntegList = response.body;
    });
  }

  public openModalConfig(nEmpresa: string): Promise<void> {
    return this._acrescimosEncargosService.acrescimoEncargoCabConfig(nEmpresa, this.anoEncargo).then((response: HttpResponse<IJsonAcrescimoEncargoComFeriasCabConfig>) => {
      const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasConfigModalComponent);
      const componentInstance: AcrescimosEncargosFeriasConfigModalComponent = modalInstance.componentInstance;
      componentInstance.configEncargos = response.body.config;
      componentInstance.cabEncargos = response.body.cab;
      componentInstance.anoEncargo = this.anoEncargo;
      componentInstance.nEmpresa = nEmpresa;
      return modalInstance.result.then((needRefresh: boolean) => {
        if (needRefresh) {
          this._carregarAcrescimosEncargos();
        }
      });
    });
  }

  private _carregarAcrescimosEncargos(): Promise<void> {
    if (this.activeTab === TAB_ID_MULTI_EMPRESA) {
      return this._carregaAcrescimosEncMulti();
    }
    return this.acrescimosEncSingleCallback.carregar(this.anoEncargo);
  }

  private _anularAcrescimosEncargos(): Promise<void> {
    return this.acrescimosEncSingleCallback.anular();
  }

  private _carregaAcrescimosEncMulti(): Promise<void> {
    this._dataGridInstanceMulti.beginCustomLoading(undefined);
    return this._acrescimosEncargosService
      .getEncargosListMulti(this.anoEncargo)
      .then((responseAcrescimos: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        this._applyAcrescimosEncargosMulti(responseAcrescimos.body);
      })
      .finally(() => {
        this._dataGridInstanceMulti.endCustomLoading();
      });
  }

  private _assistenteEncargosMulti(): Promise<void> {
    return this._acrescimosEncargosService.getEmpresasEncargosListMulti(this.anoEncargo).then((response: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>>) => {
      const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasGerarEncargosMultiModalComponent);
      const componentInstance: AcrescimosEncargosFeriasGerarEncargosMultiModalComponent = modalInstance.componentInstance;
      componentInstance.listEmpresas = response.body;
      return modalInstance.result.then((list: Array<IJsonAcrescimoEncargoComFeriasLinMulti>) => {
        this._applyAcrescimosEncargosMulti(list);
      });
    });
  }

  private _lancarDocumentoMulti(): Promise<void> {
    const selectedRows: Array<IJsonAcrescimoEncargoComFeriasLinMulti> = this._dataGridInstanceMulti.getSelectedRowsData();
    if (selectedRows.length < 1) {
      this._plAlertService.error('acrescimosencargosferias.messages.naoSelEmp');
      return Promise.resolve();
    }

    this._dataGridInstanceMulti.beginCustomLoading(undefined);
    return this._acrescimosEncargosService
      .lancarDocumentoMulti(selectedRows)
      .then((responseAcrescimos: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        this._integContabMultiErrorsModal(responseAcrescimos.body, true);
        return this._carregaAcrescimosEncMulti();
      })
      .finally(() => {
        this._dataGridInstanceMulti.endCustomLoading();
      });
  }

  private _apagarDocumentoMulti(): Promise<void> {
    const selectedRows: Array<IJsonAcrescimoEncargoComFeriasLinMulti> = this._dataGridInstanceMulti.getSelectedRowsData();
    if (selectedRows.length < 1) {
      this._plAlertService.error('acrescimosencargosferias.messages.naoSelEmp');
      return Promise.resolve();
    }

    this._dataGridInstanceMulti.beginCustomLoading(undefined);
    return this._acrescimosEncargosService
      .apagarDocumentoMulti(selectedRows)
      .then((responseAcrescimos: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        this._integContabMultiErrorsModal(responseAcrescimos.body, false);
        return this._carregaAcrescimosEncMulti();
      })
      .finally(() => {
        this._dataGridInstanceMulti.endCustomLoading();
      });
  }

  private _integContabMultiErrorsModal(linhas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>, integ: boolean): void {
    const linhasErro: Array<IJsonAcrescimoEncargoComFeriasLinMulti> = linhas.filter((item: IJsonAcrescimoEncargoComFeriasLinMulti) => !isEmpty(item.multiLinhaErro) || !isEmpty(item.descricaoErro));
    if (linhasErro?.length > 0) {
      for (const linha of linhas) {
        linha.multiLinhaErro = isEmpty(linha.multiLinhaErro) && !isEmpty(linha.descricaoErro) ? linha.descricaoErro : linha.multiLinhaErro;
      }
      const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent);
      const componentInstance: AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent = modalInstance.componentInstance;
      componentInstance.listEmpresas = linhas;
    } else {
      this._plAlertService.success(integ ? 'acrescimosencargosferias.messages.docsIntegSuccess' : 'acrescimosencargosferias.messages.docsDeletedSuccess');
    }
  }

  private _verificarInconsistenciasMulti(): Promise<void> {
    const selectedRows: Array<IJsonAcrescimoEncargoComFeriasLinMulti> = this._dataGridInstanceMulti.getSelectedRowsData();
    if (selectedRows.length < 1) {
      this._plAlertService.error('acrescimosencargosferias.messages.naoSelEmp');
      return Promise.resolve();
    }

    this._dataGridInstanceMulti.beginCustomLoading(undefined);
    return this._acrescimosEncargosService
      .verificarInconsistenciasMulti(selectedRows)
      .then((responseAcrescimos: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        this._applyAcrescimosEncargosMulti(responseAcrescimos.body);
      })
      .finally(() => {
        this._dataGridInstanceMulti.endCustomLoading();
      });
  }

  private _changedAno(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this.anoEncargo)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this.anoEncargo = Number(this._selectedAno.caption);
      this._mnuAnos.caption = this._getCaptionAno();
      const promise = this._carregarAcrescimosEncargos();
      this._btnCarregar.promise = promise;
      this._btnAnularEncargosSingle.promise = promise;
      this._btnConfig.promise = promise;
    }
  }

  private _getCaptionAno(): string {
    return this._translateService.instant('toolbar.year', {value: this.anoEncargo});
  }

  private _applyAcrescimosEncargosMulti(list: Array<IJsonAcrescimoEncargoComFeriasLinMulti>): void {
    for (const item of list) {
      if (item.dataIntegracao && moment(item.dataIntegracao).isSameOrBefore(minDateCG(), 'date')) {
        item.dataIntegracao = undefined;
      }
    }
    this.dataGridDefinitionMulti.dataSource = new ArrayStore({key: 'rhEncFeriasAnoID', data: list});
  }

  private _customizeTextTipo(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && isNumber(cellInfo.value)) {
      return this._translateService.instant(`acrescimosencargosferias.cab.tiposProce.tipoproce${cellInfo.value}`);
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeTextTipo: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextTipo(cellInfo);
}
