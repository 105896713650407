import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {CGC_TOASTS_DEFAULT_OPTIONS, CGC_TOASTS_DEFAULT_PROPERTIES} from './toasts.config.di';
import {ICGCToastNoticeProperties, ICGCToastProperties} from '../toasts.interface';
import {ICGCToastOptions} from '../service/toasts.service.interface';

const DEFAULT_AUTO_HIDE_DELAY = 5000;

@Injectable({
  providedIn: 'root'
})
export class CGCToastsConfigService implements OnDestroy {
  private readonly _subjectDefaultProperties: BehaviorSubject<ICGCToastProperties>;
  private readonly _subjectDefaultOptions: BehaviorSubject<ICGCToastOptions>;
  private _observableDefaultProperties: Observable<ICGCToastProperties>;
  private _observableDefaultOptions: Observable<ICGCToastOptions>;

  constructor(@Inject(CGC_TOASTS_DEFAULT_PROPERTIES) @Optional() defaultProperties: ICGCToastNoticeProperties, @Inject(CGC_TOASTS_DEFAULT_OPTIONS) @Optional() defaultOptions: ICGCToastOptions) {
    this._subjectDefaultProperties = new BehaviorSubject<ICGCToastProperties>({
      type: 'info',
      autoOpen: true,
      autoClose: true,
      autoCloseDelay: DEFAULT_AUTO_HIDE_DELAY,
      autoCloseMouseReset: true,
      closeOnClick: true,
      destroyOnClose: true,
      ...defaultProperties
    });

    this._subjectDefaultOptions = new BehaviorSubject<ICGCToastOptions>({
      preventDuplicates: true,
      ...defaultOptions
    });
  }

  public ngOnDestroy(): void {
    this._subjectDefaultProperties.complete();
    this._subjectDefaultOptions.complete();
  }

  public setDefaultProperties(properties: ICGCToastNoticeProperties): this {
    this._subjectDefaultProperties.next(Object.freeze({...this._subjectDefaultProperties.value, ...properties}));
    return this;
  }

  public setDefaultOptions(options: ICGCToastOptions): this {
    this._subjectDefaultOptions.next(Object.freeze({...this._subjectDefaultOptions.value, ...options}));
    return this;
  }

  public get defaultProperties(): Observable<ICGCToastProperties> {
    if (!this._observableDefaultProperties) {
      this._observableDefaultProperties = this._subjectDefaultProperties.asObservable();
    }
    return this._observableDefaultProperties;
  }

  public get defaultOptions(): Observable<ICGCToastOptions> {
    if (!this._observableDefaultOptions) {
      this._observableDefaultOptions = this._subjectDefaultOptions.asObservable();
    }
    return this._observableDefaultOptions;
  }
}
