import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {sortBy} from 'lodash-es';
import {IPlCalendarYearViewRange, IPlCalendarYearViewScheduler, isArray, ngClassAdd} from 'pl-comps-angular';
import moment, {Moment, MomentInput} from 'moment';
import {evaluateDGEMPName, IJsonDGEMP, IJsonDGEMPFerias, IJsonDGEMPMarcacoes} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IDevExpressDataGrid} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {EGestaoDGEMPSType, EGestaoDGEMPSView, IGestaoDGEMPSCalendario, IGestaoDGEMPSEvent} from '../../gestaodgemps.interface';
import {gestaoDGEMPSEvaluateDayCSSClass, gestaoDGEMPSPrettyFeriasMarcadas} from '../../gestaodgemps.utilities';
import {GestaoDGEMPSViewComponent} from '../gestaodgemps.view.component';
import {IGestaoDGEMPSViewDayEvent, IGestaoDGEMPSViewFetchMarcacoes, IGestaoDGEMPSViewRefreshMarcacoes} from '../gestaodgemps.view.component.interface';
import {
  IGestaoDGEMPSYearViewDay,
  IGestaoDGEMPSYearViewDayClick,
  IGestaoDGEMPSYearViewDGEMP,
  IGestaoDGEMPSYearViewEvent,
  IGestaoDGEMPSYearViewRangeSelect,
  IGestaoDGEMPSYearViewSelectionChanged
} from './gestaodgemps.year.view.component.interface';

const SEARCH_PANEL_WIDTH = 325;
const CSS_CLASS_HIGHLIGHT = 'fw-bold';

@Component({
  selector: 'gestao-dgemps-year-view',
  templateUrl: './gestaodgemps.year.view.component.html'
})
export class GestaoDGEMPSYearViewComponent extends GestaoDGEMPSViewComponent implements OnInit, OnChanges {
  @Input() public colaboradoresPendentes: Array<number>;
  @Input() public selectedColaboradoresKeys: Array<number>;
  @Output() public readonly selectedColaboradoresKeysChange: EventEmitter<Array<number>>;

  public readonly dataGridColaboradores: IDevExpressDataGrid<IGestaoDGEMPSYearViewDGEMP, number>;
  public readonly yearEventsWithAttachment: WeakSet<IGestaoDGEMPSYearViewDay>;
  public colaboradores: Array<IGestaoDGEMPSYearViewDGEMP>;
  public yearEvents: Array<IGestaoDGEMPSYearViewEvent>;
  public yearSelectedRange: IPlCalendarYearViewRange;
  public feriasEmpregado: string;

  private readonly _feriasColaboradores: Map<number, string>;
  private readonly _colaboradoresPendentes: Set<number>;
  private _dataGridColaboradoresInstance: dxDataGrid<IGestaoDGEMPSYearViewDGEMP, number>;
  private _preventColaboradoresSelectionChanged: boolean;
  private _deselectingColaboradores: boolean;
  private _previousViewDate: Moment;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.selectedColaboradoresKeysChange = new EventEmitter<Array<number>>();
    this.dataGridColaboradores = {
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'gestaodgemps.fields.codEmp', width: 80, visible: false},
        {dataField: 'nome', caption: 'dgemps.fields.nome', cellTemplate: 'templateNomeEmp'},
        {dataField: 'nomeCompleto', caption: 'dgemps.fields.nomeCompleto', sortOrder: 'asc', visible: false}
      ],
      keyExpr: 'codEmp',
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      pager: {displayMode: 'compact'},
      remoteOperations: false,
      searchPanel: {visible: true, width: SEARCH_PANEL_WIDTH},
      selection: {
        allowSelectAll: false,
        mode: 'multiple',
        showCheckBoxesMode: 'always'
      },
      toolbar: {
        items: [{name: 'searchPanel', location: 'before'}, 'columnChooserButton']
      }
    };
    this.yearEventsWithAttachment = new WeakSet<IGestaoDGEMPSYearViewDay>();
    this.colaboradores = [];
    this.selectedColaboradoresKeys = [];
    this.yearEvents = [];
    this._feriasColaboradores = new Map<number, string>();
    this._colaboradoresPendentes = new Set<number>();
    this._preventColaboradoresSelectionChanged = false;
    this._deselectingColaboradores = false;
    this._postMarcarTarefaFn = this._postMarcarTarefa.bind(this);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    if (this.type === EGestaoDGEMPSType.Ferias) {
      this.dataGridColaboradores.columns.push({
        caption: 'gestaodgemps.fields.marcacoes',
        dataType: 'string',
        width: 160,
        calculateCellValue: (colaborador: IGestaoDGEMPSYearViewDGEMP): string => {
          if (this._feriasColaboradores.size) {
            const feriasColaborador: string = this._feriasColaboradores.get(colaborador.codEmp);
            if (feriasColaborador) {
              return feriasColaborador;
            }
          }
          return '-/-';
        }
      });
    }
    this._evaluateCalendarios();
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {type, viewDate, servico}: SimpleChanges = changes;
    const changedType: boolean = type && !type.isFirstChange();
    const changedServico: boolean = servico && !servico.isFirstChange();
    let changedViewDate = false;
    if (viewDate && !viewDate.isFirstChange()) {
      const newViewDate = moment(viewDate.currentValue);
      if (!this._previousViewDate || !newViewDate.isSame(this._previousViewDate, 'year')) {
        changedViewDate = true;
      }
      this._previousViewDate = newViewDate;
    }
    if (changedServico) {
      this._evaluateCalendarios();
    } else if (changedViewDate) {
      this._changedViewDate(viewDate.currentValue);
      this.hideEventsDetail();
      this._refreshCalendarios();
    } else if (changedType) {
      this._refreshCalendarios();
    }
  }

  public override setViewDate(value: MomentInput): void {
    if (!moment(value).isSame(this.viewDate, 'year')) {
      super.setViewDate(value);
    }
  }

  public onDataGridColaboradoresInitialized(event: IDevExpressDataGridEventOnInitialized<IGestaoDGEMPSYearViewDGEMP, number>): void {
    this._dataGridColaboradoresInstance = event.component;
  }

  public onDataGridColaboradoresCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IGestaoDGEMPSYearViewDGEMP, number>): void {
    if (event.rowType === 'data' && event.column && !event.column.type) {
      if (this._colaboradoresPendentes.has(event.data.codEmp)) {
        event.cellElement.classList.add(CSS_CLASS_HIGHLIGHT);
      } else {
        event.cellElement.classList.remove(CSS_CLASS_HIGHLIGHT);
      }
    }
  }

  public onDataGridColaboradoresCellClick(event: IDevExpressDataGridEventOnCellClick<IGestaoDGEMPSYearViewDGEMP, number>): void {
    if (event.rowType === 'data' && !event.column.type) {
      if (!event.component.isRowSelected(event.key)) {
        event.component.selectRows([event.key], this._multiSelect);
      } else {
        event.component.deselectRows([event.key]);
      }
    }
  }

  public onDataGridColaboradoresSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IGestaoDGEMPSYearViewDGEMP, number>): void {
    if (this._preventColaboradoresSelectionChanged) {
      this._preventColaboradoresSelectionChanged = false;
      return;
    }
    const promises: Array<Promise<unknown>> = [];
    if (!this._multiSelect) {
      if (!this._deselectingColaboradores) {
        const previousSelectedRowKeys: Array<number> = event.selectedRowKeys.filter((selectedRowKey: number) => !event.currentSelectedRowKeys.includes(selectedRowKey));
        if (previousSelectedRowKeys.length) {
          this._deselectingColaboradores = true;
          promises.push(event.component.deselectRows(previousSelectedRowKeys));
        }
      } else {
        this._deselectingColaboradores = false;
      }
    }
    this.selectedColaboradoresKeys = event.selectedRowKeys;
    this.selectedColaboradoresKeysChange.emit(this.selectedColaboradoresKeys);
    if (!this.selectedColaboradoresKeys.length) {
      this.hideEventsDetail();
    }
    if (event.currentDeselectedRowKeys.length) {
      for (const [dateKey, empregados] of this._empregadosByDate) {
        for (const codEmp of event.currentDeselectedRowKeys) {
          empregados.delete(codEmp);
        }
        if (!empregados.size) {
          this._empregadosByDate.delete(dateKey);
        }
      }
      this._refreshConflitos(false);
      promises.push(
        ...event.currentDeselectedRowKeys.map<Promise<void>>((codEmp: number) => {
          return this._colaboradorSelectionChanged(codEmp, false);
        })
      );
    }
    if (event.currentSelectedRowKeys.length) {
      promises.push(
        ...event.currentSelectedRowKeys.map<Promise<void>>((codEmp: number) => {
          return this._colaboradorSelectionChanged(codEmp, true);
        })
      );
    }
    Promise.all(promises).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public beforeViewRender(event: IPlCalendarYearViewScheduler<IGestaoDGEMPSEvent>): void {
    const isFaltas: boolean = this.type === EGestaoDGEMPSType.Faltas;
    for (const month of event.datasets) {
      for (const day of month.items) {
        if (!day.meta?.events.length) {
          continue;
        }
        const cssClass = gestaoDGEMPSEvaluateDayCSSClass(day.meta.events, (dayEvent: IGestaoDGEMPSViewDayEvent) => {
          if (isFaltas && dayEvent.meta.docID) {
            this.yearEventsWithAttachment.add(day);
          }
        });
        if (cssClass) {
          if (!day.cssClass) {
            day.cssClass = [];
          }
          day.cssClass = ngClassAdd(day.cssClass, cssClass);
        }
      }
    }
  }

  public onYearDayClick(day: IGestaoDGEMPSYearViewDayClick): void {
    if (!this._getActiveCalendarios().length || (day.holiday && this.type === EGestaoDGEMPSType.Ferias)) {
      return;
    }
    if (!day.events.length) {
      const momentDate: Moment = moment(day.date);
      if (this.colaboradorMarcarFerias && momentDate.isBefore(this._today, 'date')) {
        return;
      }
      this._marcarTarefa({
        startDate: momentDate,
        endDate: momentDate,
        events: []
      });
    } else if (this.colaboradorMarcarFerias) {
      const event: IGestaoDGEMPSEvent = day.events[0].meta;
      if (event.allDay && event.status.desmarcavel) {
        day.preventDetail();
        this._desmarcarTarefa(event).then(() => {
          this._refreshFeriasColaboradores([event.codEmp]);
        });
      }
    }
  }

  public onYearRangeSelect({start, end, events}: IGestaoDGEMPSYearViewRangeSelect): void {
    if (this.colaboradorMarcarFerias && start.isBefore(this._today, 'date')) {
      return;
    }
    this.onRangeSelect({
      startDate: start,
      endDate: end,
      events: this._getEvents(events)
    });
  }

  public onYearSelectionChanged({start, end, events}: IGestaoDGEMPSYearViewSelectionChanged): void {
    this.onSelectionChanged({
      startDate: start,
      endDate: end,
      events: this._getEvents(events)
    });
  }

  public onYearDetailRefresh(): void {
    if (!this.yearSelectedRange) {
      return;
    }
    if (this.type === EGestaoDGEMPSType.Ferias && this.manager) {
      this._refreshFeriasColaboradores();
    }
    this.onDetailRefresh({startDate: this.yearSelectedRange.start, endDate: this.yearSelectedRange.end}).then(() => {
      const updatedEvents: Map<number, IGestaoDGEMPSEvent> = new Map<number, IGestaoDGEMPSEvent>();
      for (const yearEvent of this.yearEvents) {
        updatedEvents.set(yearEvent.meta.idTarefaCab, yearEvent.meta);
      }
      for (let i = 0; i < this.detailEvents.length; i++) {
        const event: IGestaoDGEMPSEvent = this.detailEvents[i];
        const updatedEvent: IGestaoDGEMPSEvent = updatedEvents.get(event.idTarefaCab);
        if (updatedEvent) {
          this.detailEvents[i] = updatedEvent;
        }
      }
      this.detailEvents = this.detailEvents.slice();
    });
  }

  public override get view(): EGestaoDGEMPSView {
    return EGestaoDGEMPSView.Year;
  }

  protected override _changedType(value: EGestaoDGEMPSType = this.type): void {
    super._changedType(value);
    if (!this._dataGridColaboradoresInstance) {
      this.dataGridColaboradores.selection.allowSelectAll = this._multiSelect;
    } else {
      this._dataGridColaboradoresInstance.option('selection.allowSelectAll', this._multiSelect);
    }
  }

  protected override _fetchMarcacoes({codEmp, startDate, endDate}: IGestaoDGEMPSViewFetchMarcacoes): Promise<IJsonDGEMPMarcacoes> {
    return this._gestaoDGEMPSService.getListaMarcacoesAno(codEmp, startDate, endDate);
  }

  protected override _refreshMarcacoes({codEmp, startDate, endDate, fullYear, marcacoes}: IGestaoDGEMPSViewRefreshMarcacoes<IJsonDGEMPMarcacoes>): void {
    if (fullYear) {
      const feriasColaborador: string = this._dgempFeriasToEmpregadoFerias(marcacoes.dgEmp);
      if (this.type === EGestaoDGEMPSType.Ferias && this.manager) {
        this._setFeriasColaborador(codEmp, feriasColaborador);
      } else if (this.colaboradorMarcarFerias && codEmp === this.empregado.codEmp) {
        this.feriasEmpregado = feriasColaborador;
      }
    }

    // Remove previous events
    const previousEvents: Array<number> = this.yearEvents.map((yearEvent: IGestaoDGEMPSYearViewEvent) => yearEvent.meta.idTarefaCab);
    this.yearEvents = this.yearEvents.filter((event: IGestaoDGEMPSYearViewEvent) => event.meta.codEmp !== codEmp || !event.meta.date.isBetween(startDate, endDate, 'date', '[]'));

    const calendario: IGestaoDGEMPSCalendario = this.calendarios.find((item: IGestaoDGEMPSCalendario) => item.codEmp === codEmp);
    if (calendario) {
      switch (this.type) {
        case EGestaoDGEMPSType.Abonos:
          calendario.listaMarcadas = marcacoes.listaAbonosMarcadas;
          calendario.listaIntegradas = marcacoes.listaAbonosJaIntegradas;
          if (fullYear) {
            if (marcacoes.numAbonosPendentes > 0) {
              this._colaboradoresPendentes.add(codEmp);
            } else {
              this._colaboradoresPendentes.delete(codEmp);
            }
          }
          break;
        case EGestaoDGEMPSType.Faltas:
          calendario.listaMarcadas = marcacoes.listaFaltasMarcadas;
          calendario.listaIntegradas = marcacoes.listaFaltasJaIntegradas;
          if (fullYear) {
            if (marcacoes.numFaltasPendentes > 0) {
              this._colaboradoresPendentes.add(codEmp);
            } else {
              this._colaboradoresPendentes.delete(codEmp);
            }
          }
          break;
        case EGestaoDGEMPSType.Ferias:
          calendario.listaMarcadas = marcacoes.listaFeriasMarcadas;
          calendario.listaIntegradas = marcacoes.listaFeriasJaIntegradas;
          if (fullYear) {
            if (marcacoes.numFeriasPendentes > 0) {
              this._colaboradoresPendentes.add(codEmp);
            } else {
              this._colaboradoresPendentes.delete(codEmp);
            }
          }
          break;
      }
      calendario.listaCalendario = marcacoes.listaCalendario;
      if (calendario.selected) {
        this._addCalendarioEvents(calendario);
        this.yearEvents = sortBy(this.yearEvents, (event: IGestaoDGEMPSYearViewEvent) => {
          return [event.meta.codEmp, event.meta.idTarefaCab];
        });
        if (this.detailActive) {
          this._refreshConflitos();
          if (isArray(this.detailEvents) && this.detailEvents.length) {
            const currentEvents: Array<number> = this.yearEvents.map((yearEvent: IGestaoDGEMPSYearViewEvent) => yearEvent.meta.idTarefaCab);
            this.detailEvents = this.detailEvents.filter((event: IGestaoDGEMPSEvent) => currentEvents.includes(event.idTarefaCab));
            const newEvents: Array<IGestaoDGEMPSEvent> = [];
            for (const yearEvent of this.yearEvents) {
              if (!previousEvents.includes(yearEvent.meta.idTarefaCab) && yearEvent.meta.date.isBetween(this.detailStart, this.detailEnd, 'date', '[]')) {
                newEvents.push(yearEvent.meta);
              }
            }
            if (newEvents.length) {
              this.detailEvents = this.detailEvents.concat(newEvents);
              this.detailEvents = sortBy(this.detailEvents, ['codEmp', 'idTarefaCab']);
            }
          }
        }
      }
      if (fullYear && this._dataGridColaboradoresInstance) {
        this._dataGridColaboradoresInstance.repaint();
      }
    }
  }

  protected override _addCalendarioEvent(calendario: IGestaoDGEMPSCalendario, event: IGestaoDGEMPSEvent): void {
    this.yearEvents.push({
      date: event.date.clone(),
      meta: event
    });
  }

  private async _evaluateCalendarios(): Promise<void> {
    this.calendarios = [];

    if (this.manager) {
      this.yearEvents = [];
      this._empregadosByDate.clear();
      if (this.selectedColaboradoresKeys?.length) {
        this.selectedColaboradoresKeys = [];
      }
    }

    this._setLoading(true);

    if (!this.manager || !this.servico) {
      if (!this.servico) {
        this.colaboradores = [];
      } else if (this.empregado) {
        if (!this.colaboradores.length || this.colaboradores[0] !== this.empregado) {
          this.colaboradores = this._convertDGEMPS([this.empregado]);
        }
      }
    } else {
      this.colaboradores = this._convertDGEMPS(await this._gestaoDGEMPSService.getListaEmpregados(this.servico.codServico));
    }

    if (this.colaboradores.length) {
      this.selectedColaboradoresKeys = [];
      const selectedColaboradoresKeys: Array<number> = [];
      this.calendarios = this.colaboradores.map<IGestaoDGEMPSCalendario>((colaborador: IGestaoDGEMPSYearViewDGEMP) => {
        const calendario: IGestaoDGEMPSCalendario = {
          codEmp: colaborador.codEmp,
          nomeEmp: colaborador.nome,
          nomeCompleto: colaborador.nome,
          listaMarcadas: [],
          listaIntegradas: [],
          listaCalendario: [],
          selected: this.colaboradores.length === 1 || this.colaboradoresPendentes?.includes(colaborador.codEmp)
        };
        if (calendario.selected) {
          selectedColaboradoresKeys.push(colaborador.codEmp);
        }
        return calendario;
      });
      this.colaboradoresPendentes = undefined;
      await this._refreshCalendarios();
      if (selectedColaboradoresKeys.length) {
        this._preventColaboradoresSelectionChanged = true;
        this.selectedColaboradoresKeys = selectedColaboradoresKeys;
      }
    }

    this._setLoading(false);
  }

  private _convertDGEMPS(colaboradores: Array<IJsonDGEMP>): Array<IGestaoDGEMPSYearViewDGEMP> {
    return colaboradores.map<IGestaoDGEMPSYearViewDGEMP>((colaborador: IJsonDGEMP) => {
      return {
        ...colaborador,
        nome: evaluateDGEMPName(colaborador),
        nomeCompleto: colaborador.nome
      };
    });
  }

  private async _colaboradorSelectionChanged(codEmp: number, selected: boolean): Promise<void> {
    const colaborador: IGestaoDGEMPSYearViewDGEMP = await this._dataGridColaboradoresInstance.byKey(codEmp);
    if (!colaborador) {
      return;
    }
    const calendario: IGestaoDGEMPSCalendario = this.calendarios.find((item: IGestaoDGEMPSCalendario) => item.codEmp === colaborador.codEmp);
    if (!calendario) {
      return;
    }
    calendario.selected = selected;
    if (calendario.selected) {
      await this._refreshCalendario(calendario.codEmp).then(() => {
        this._refreshConflitos();
      });
    } else {
      this.yearEvents = this.yearEvents.filter((event: IGestaoDGEMPSYearViewEvent) => event.meta.codEmp !== calendario.codEmp);
      this.detailEvents = this.detailEvents?.filter((event: IGestaoDGEMPSEvent) => event.codEmp !== calendario.codEmp);
      this._colaboradoresPendentes.delete(codEmp);
      this._dataGridColaboradoresInstance.repaint();
      if (this.type === EGestaoDGEMPSType.Ferias && this.manager) {
        this._feriasColaboradores.delete(calendario.codEmp);
        this._refreshDataGridColaborador(codEmp);
      }
    }
  }

  private _getEvents(yearEvents: Array<IGestaoDGEMPSYearViewEvent>): Array<IGestaoDGEMPSEvent> {
    return yearEvents.map((yearEvent: IGestaoDGEMPSYearViewEvent) => yearEvent.meta);
  }

  private _getEmpregadoFerias(codEmp: number): Promise<string> {
    return this._fetchMarcacoes({codEmp: codEmp, startDate: this._startOfYear, endDate: this._endOfYear}).then((marcacoes: IJsonDGEMPMarcacoes) => {
      return this._dgempFeriasToEmpregadoFerias(marcacoes.dgEmp);
    });
  }

  private _setFeriasColaborador(codEmp: number, feriasColaborador: string): void {
    this._feriasColaboradores.set(codEmp, feriasColaborador);
    this._refreshDataGridColaborador(codEmp);
  }

  private _refreshConflitos(refreshEvents: boolean = true): void {
    let changed = false;
    for (const yearEvent of this.yearEvents) {
      const conflito: boolean = this._evaluateConfito(moment(yearEvent.date));
      if (conflito === yearEvent.meta.status.conflito) {
        continue;
      }
      if (!changed) {
        changed = true;
      }
      yearEvent.meta.status.conflito = conflito;
    }
    if (refreshEvents && changed) {
      this.yearEvents = this.yearEvents.slice();
    }
  }

  private _refreshFeriasColaboradores(codEmps: Array<number> = this._dataGridColaboradoresInstance.getSelectedRowKeys()): void {
    for (const codEmp of codEmps) {
      this._getEmpregadoFerias(codEmp).then((feriasColaborador: string) => {
        if (this.type === EGestaoDGEMPSType.Ferias && this.manager) {
          this._setFeriasColaborador(codEmp, feriasColaborador);
        } else if (this.colaboradorMarcarFerias && codEmp === this.empregado.codEmp) {
          this.feriasEmpregado = feriasColaborador;
        }
      });
    }
  }

  private _refreshDataGridColaborador(codEmp: number): void {
    if (this._dataGridColaboradoresInstance) {
      this._dataGridColaboradoresInstance.repaintRows([this._dataGridColaboradoresInstance.getRowIndexByKey(codEmp)]);
    }
  }

  private _dgempFeriasToEmpregadoFerias(dgempFerias: IJsonDGEMPFerias): string {
    return gestaoDGEMPSPrettyFeriasMarcadas(this._translateService, dgempFerias);
  }

  private _postMarcarTarefa(successfulCalendarios: ReadonlySet<IGestaoDGEMPSCalendario>): void {
    const successfulCodEmps: Array<number> = Array.from(successfulCalendarios.values()).map((calendario: IGestaoDGEMPSCalendario) => calendario.codEmp);
    this._refreshFeriasColaboradores(successfulCodEmps);
  }
}
