export enum EPortal {
  ADMIN = 'admin',
  ERP = 'erp',
  PCA = 'pca',
  COLABORADOR = 'colaborador',
  GESTOR_EMENTAS = 'gestorementas',
  GESTOR_RH = 'gestorrh',
  GESTOR_SERVICO = 'gestorservico',
  CONTABILIDADE = 'contabilidade',
  CONFIGURACOES = 'configuracoes',
  RH = 'rh',
  ATIVOS = 'ativos',
  CLIENTCONNECT = 'clientconnect',
  FINANCEIRO = 'financeiro'
}

export enum EPortalType {
  System,
  Custom
}
