import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonAnaliticaConfig} from './jsonAnaliticaConfig.module.interface';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AnaliticaConfigService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/analiticaconfig`;
  }

  public getAnaliticaConfig(): Promise<IJsonAnaliticaConfig> {
    return this._apiService.get<IJsonAnaliticaConfig>({url: this._path}).then((response: HttpResponse<IJsonAnaliticaConfig>) => response.body);
  }

  public postAnaliticaConfig(radicais: IJsonAnaliticaConfig): TServiceResponse<IJsonAnaliticaConfig> {
    return this._apiService.post<IJsonAnaliticaConfig>({
      url: this._path,
      body: radicais
    });
  }
}
