import {Injectable} from '@angular/core';
import {isFunction, isNumber, isString} from '../common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class PlTranslateService {
  private _translateFn: (value: string | number, ...args: Array<any>) => string;

  public setTranslateFn(fn: (value: string | number, ...args: Array<any>) => string): void {
    if (isFunction(fn)) {
      this._translateFn = fn;
    }
  }

  public translate(value: string | number, ...parameters: Array<any>): string {
    if (!value && <any>value !== 0) {
      return '';
    }
    let translatedValue: string = <string>value;
    if (isFunction(this._translateFn)) {
      translatedValue = this._translateFn(value, ...parameters);
    }
    return isString(translatedValue) || isNumber(translatedValue)
      ? translatedValue === '[object Object]' || translatedValue === ''
        ? <string>value
        : translatedValue
      : '';
  }
}
