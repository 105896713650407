import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isObject} from 'pl-comps-angular';
import {ConciliacaoBancariaService} from '../../../modules/portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module.service';
import {
  EConciliacaoBancariaBancosTreeViewerNodeType,
  IConciliacaoBancariaBancosTreeViewerCallback,
  IConciliacaoBancariaBancosTreeViewerItemData
} from './conciliacaobancaria.bancos.treeviewer.component.interface';
import {EConciliacaoBancariaEstadoConciliacaoCab, IConciliacaoBancariaBancoCab} from '../../../modules/portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module.interface';
import {ITree, ITreeItem} from '../../treeviewer/treeviewer.interface';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from '../../../services/config/config.service';
import {orderBy} from 'lodash-es';

const NUMBER_4 = 4;

@Component({
  selector: 'conciliacao-bancaria-bancos-tree-viewer',
  templateUrl: './conciliacaobancaria.bancos.treeviewer.component.html'
})
export class ConciliacaoBancariaBancosTreeViewerComponent implements OnChanges {
  @Input() public model: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>;
  @Input() public disabled: boolean;
  @Input() public collapseOnSelect: boolean;
  @Input() public callback: IConciliacaoBancariaBancosTreeViewerCallback;
  @Output() public readonly modelChange: EventEmitter<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>;
  @Output() public readonly evtDoubleClickedItem: EventEmitter<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>;

  public tree: ITree;
  public promise: Promise<void>;

  constructor(
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _tranlateService: TranslateService,
    private readonly _configService: ConfigService
  ) {
    this.collapseOnSelect = false;
    this.callback = {};
    this.modelChange = new EventEmitter<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>();
    this.evtDoubleClickedItem = new EventEmitter<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>();
    this.tree = {nodes: []};
    this._reload();
  }

  public ngOnChanges({callback}: SimpleChanges): void {
    if (callback) {
      const cb: IConciliacaoBancariaBancosTreeViewerCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.generateNodeId = (nConta: string, periodo: string) => this._generateNodeId(nConta, periodo);
        cb.reload = () => this._reload();
      }
    }
  }

  public changedSelectedNode(node: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>): void {
    this.model = node;
    this.modelChange.emit(this.model);
  }

  private _generateNodeId(nConta: string, periodo?: string): string {
    return nConta && periodo ? `${nConta}-${periodo}` : nConta;
  }

  private _reload(): Promise<void> {
    this.promise = this._conciliacaoBancariaService.bancoCabList().then((response: HttpResponse<Array<IConciliacaoBancariaBancoCab>>) => {
      this._bancoCabListToTree(response.body);
    });
    return this.promise;
  }

  private _bancoCabListToTree(bancoCabList: Array<IConciliacaoBancariaBancoCab>): void {
    bancoCabList = orderBy(bancoCabList, ['nConta', 'periodo'], 'asc');
    const folders: Map<string, ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>> = new Map<string, ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>();
    for (const bancoCab of bancoCabList) {
      const folderId: string = this._generateNodeId(bancoCab.nConta);
      let folder: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData> = folders.get(folderId);
      if (!folder) {
        folder = {
          nodeId: folderId,
          nodeText: `${bancoCab.nConta} - ${bancoCab.nomeNConta}`,
          nodeIcon: 'fa-university',
          nodeValue: {
            ...bancoCab,
            nodeType: EConciliacaoBancariaBancosTreeViewerNodeType.Folder
          },
          childNodes: []
        };
        folders.set(folderId, folder);
      }

      const folderItems: Array<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>> = folder.childNodes;
      const year = Number(bancoCab.periodo.substring(0, NUMBER_4));
      const nodeItem: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData> = {
        nodeId: this._generateNodeId(bancoCab.nConta, bancoCab.periodo),
        nodeText: bancoCab.nomeConciliacao,
        nodeIcon: 'fa-file-text-o',
        nodeClass: bancoCab.estado === EConciliacaoBancariaEstadoConciliacaoCab.Conciliado ? 'text-success' : 'text-danger',
        nodeValue: {
          ...bancoCab,
          nodeType: EConciliacaoBancariaBancosTreeViewerNodeType.Item
        }
      };

      if (year < this._configService.configurations.empresa.anoEmCursoIRC) {
        const indexOutrosAnos: number = folderItems.findIndex((itemOutrosAnos: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>) => itemOutrosAnos.nodeId === `${bancoCab.nConta}OutrosAnos`);
        if (indexOutrosAnos === -1) {
          folderItems.push({
            nodeId: `${bancoCab.nConta}OutrosAnos`,
            nodeText: this._tranlateService.instant('conciliacaoBancaria.outrosanos'),
            nodeIcon: 'fa-university',
            nodeValue: {
              ...bancoCab,
              nodeType: EConciliacaoBancariaBancosTreeViewerNodeType.Folder
            },
            childNodes: [
              {
                nodeId: `${bancoCab.nConta}OutrosAnos${year}`,
                nodeText: `${year}`,
                nodeIcon: 'fa-university',
                nodeValue: {
                  ...bancoCab,
                  nodeType: EConciliacaoBancariaBancosTreeViewerNodeType.Folder
                },
                childNodes: [nodeItem]
              }
            ]
          });
        } else if (indexOutrosAnos !== -1) {
          const indexAno: number = folderItems[indexOutrosAnos].childNodes.findIndex(
            (itemAno: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>) => itemAno.nodeId === `${bancoCab.nConta}OutrosAnos${year}`
          );
          if (indexAno === -1) {
            folderItems[indexOutrosAnos].childNodes.push({
              nodeId: `${bancoCab.nConta}OutrosAnos${year}`,
              nodeText: `${year}`,
              nodeIcon: 'fa-university',
              nodeValue: {
                ...bancoCab,
                nodeType: EConciliacaoBancariaBancosTreeViewerNodeType.Folder
              },
              childNodes: [nodeItem]
            });
          } else if (indexAno !== -1) {
            folderItems[indexOutrosAnos].childNodes[indexAno].childNodes.push(nodeItem);
          }
        }
      } else {
        folderItems.push(nodeItem);
      }
    }

    const nodes: Array<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>> = Array.from(folders.values());

    for (const node of nodes) {
      this._sortNodes(node.childNodes);
    }

    this.tree = {nodes: nodes};
  }

  private _sortNodes(nodes: Array<ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>>): void {
    nodes.sort((a: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>, b: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>) => {
      return b.nodeValue.periodo.localeCompare(a.nodeValue.periodo);
    });
    for (const node of nodes) {
      if (node.childNodes?.length) {
        this._sortNodes(node.childNodes);
      }
    }
  }
}
