import {Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../../../components/cg/modal/cgmodal.service';
import {DEFAULT_TIMEOUT} from '../../../../../../../../config/constants';
import {DocsContabilidadeService} from '../../../../service/docsContabilidade.service';
import {EntityAutocompleteComponent} from '../../../../../../../components/cg/autocomplete/entity.autocomplete.component';
import {focusElement} from '../../../../../../../../common/utils/element.utils';
import {IContaAnalitica, IDocContabilidade, IDocContabilidadeCCustoResult, IDocContabilidadeLinha, IDocsContabilidadeEditCCustoType} from '../../../../docsContabilidade.interface';
import {IJsonContaAnalitica} from '../../../../jsonDocContabilidade.interface';
import {IJsonManal} from '../../../../../../../entities/mascarasanalitica/jsonMascaraAnalitica.entity.interface';
import {IMascaraAnalitica} from '../../../../../../../entities/mascarasanalitica/mascaraAnalitica.entity.interface';
import {IRestCommandLinhaGenerateContaAnalitica} from '../../../../restDocsContabilidadeCommands.interface';
import {MascarasAnaliticaService} from '../../../../../../../entities/mascarasanalitica/mascarasAnalitica.entity.service';
import {ENTITY_NAME_CODIGOS_CONTABILISTICOS} from '../../../../../../../entities/codigoscontabilisticos/codigosContabilisticos.entity.interface';
import {ENTITY_NAME_DEPTO} from '../../../../../../../entities/depto/depto.entity.interface';
import {ENTITY_NAME_PROCESSOS} from '../../../../../../../entities/processos/processos.entity.interface';
import {ENTITY_NAME_VENDEDORES} from '../../../../../../../entities/vendedores/vendedores.entity.interface';

@Component({
  selector: 'docscontabilidade-edit-ccusto-modal',
  templateUrl: './docsContabilidade.edit.cCusto.modal.component.html'
})
export class DocsContabilidadeEditCCustoModalComponent extends CGModalComponent<IDocContabilidadeCCustoResult> implements OnInit {
  @Input() public linha: IDocContabilidadeLinha;
  @Input() public doc: IDocContabilidade;

  public readonly outputMascarasAnalitica: string;
  public readonly types: Array<IDocsContabilidadeEditCCustoType>;
  public form: UntypedFormGroup;
  public ngForm: FormGroupDirective;
  public model: IContaAnalitica;
  public totalValue: number;
  public maxValue: number;
  public promise: Promise<void>;
  public disableApply: boolean;

  private readonly _promises: Array<Promise<void>>;
  private _elementsType: Array<HTMLElement>;
  private _elementSubmit: HTMLElement;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _mascarasAnaliticaService: MascarasAnaliticaService
  ) {
    super(_injector);
    this.outputMascarasAnalitica = '{{mascara}} - {{descricaoMascara}}';
    const prefix = 'mascarasanalitica.enum.definitionFaturacao.';
    this.types = [
      {
        label: `${prefix}ccusto`,
        entity: 'ccustos',
        keyTarget: 'ccusto',
        description: 'ccustoNome',
        condition: 'temCCusto',
        fieldsMap: {nCCusto: 'ccusto', nome: 'ccustoNome'}
      },
      {
        label: `${prefix}zone`,
        entity: 'zonas',
        keyTarget: 'zona',
        description: 'zonaNome',
        condition: 'temZona',
        fieldsMap: {nZona: 'zona', nome: 'zonaNome'}
      },
      {
        label: `${prefix}department`,
        entity: ENTITY_NAME_DEPTO,
        keyTarget: 'departamento',
        description: 'departamentoNome',
        condition: 'temDepartamento',
        fieldsMap: {nDepto: 'departamento', nome: 'departamentoNome'}
      },
      {
        label: `${prefix}subDepartment`,
        entity: 'subdepartamentos',
        keyTarget: 'subdepartamento',
        description: 'subdepartamentoNome',
        condition: 'temSubDepartamento',
        fieldsMap: {nSubDe: 'subdepartamento', nome: 'subdepartamentoNome'}
      },
      {
        label: `${prefix}family`,
        entity: 'familias',
        keyTarget: 'familia',
        description: 'familiaNome',
        condition: 'temFamilia',
        fieldsMap: {nFamilia: 'familia', nome: 'familiaNome'}
      },
      {
        label: `${prefix}bigFamily`,
        entity: 'grandesfamilias',
        keyTarget: 'grandefamilia',
        description: 'grandefamiliaNome',
        condition: 'temGrandeFamilia',
        fieldsMap: {nDepart: 'grandefamilia', nome: 'grandefamiliaNome'}
      },
      {
        label: `${prefix}subFamily`,
        entity: 'subfamilias',
        keyTarget: 'subfamilia',
        description: 'subfamiliaNome',
        condition: 'temSubFamilia',
        fieldsMap: {nSubFa: 'subfamilia', nome: 'subfamiliaNome'}
      },
      {
        label: `${prefix}accountingCode`,
        entity: ENTITY_NAME_CODIGOS_CONTABILISTICOS,
        keyTarget: 'codcontabilistico',
        description: 'codcontabilisticoNome',
        condition: 'temCodContabilistico',
        fieldsMap: {codcontab: 'codcontabilistico', nome: 'codcontabilisticoNome'}
      },
      {
        label: `${prefix}itemType`,
        entity: 'tiposartigo',
        keyTarget: 'tipoartigo',
        description: 'tipoartigoNome',
        condition: 'temTipoArtigo',
        fieldsMap: {codTpArt: 'tipoartigo', nome: 'tipoartigoNome'}
      },
      {
        label: `${prefix}class`,
        entity: 'artigoclasses',
        keyTarget: 'artigoclasse',
        description: 'artigoclasseNome',
        condition: 'temClasse',
        fieldsMap: {classe: 'artigoclasse', descricao: 'artigoclasseNome'}
      },
      {
        label: `${prefix}category`,
        entity: 'artigocategorias',
        keyTarget: 'artigocategoria',
        description: 'artigocategoriaNome',
        condition: 'temCategoria',
        fieldsMap: {classe: 'artigocategoria', nome: 'artigocategoriaNome'}
      },
      {
        label: `${prefix}seller`,
        entity: ENTITY_NAME_VENDEDORES,
        keyTarget: 'vendedor',
        description: 'vendedorNome',
        condition: 'temVendedor',
        fieldsMap: {nVendedor: 'vendedor', nome: 'vendedorNome'}
      },
      {
        label: `${prefix}process`,
        entity: ENTITY_NAME_PROCESSOS,
        keyTarget: 'processo',
        description: 'processoNome',
        condition: 'temProcesso',
        fieldsMap: {nRefProcesso: 'processo', nome: 'processoNome'}
      }
    ];
    this.disableApply = false;
    this._promises = [];
    this._elementsType = [];
  }

  public ngOnInit(): void {
    this.totalValue = this.linha.valor;
    this.maxValue = this.totalValue;
    this.model = {
      manalID: undefined,
      mascara: '',
      artigocategoria: undefined,
      artigoclasse: undefined,
      ccusto: '',
      codcontabilistico: '',
      conta: '',
      contaGerada: undefined,
      departamento: undefined,
      descricaoMascara: '',
      familia: undefined,
      grandefamilia: undefined,
      linhasCCusto: [],
      processo: '',
      subdepartamento: '',
      subfamilia: undefined,
      tipoartigo: undefined,
      valor: this.totalValue,
      valorRegistado: 0,
      vendedor: undefined,
      zona: undefined,
      temCategoria: false,
      temCCusto: false,
      temClasse: false,
      temCodContabilistico: false,
      temDepartamento: false,
      temFamilia: false,
      temGrandeFamilia: false,
      temProcesso: false,
      temSubDepartamento: false,
      temSubFamilia: false,
      temTipoArtigo: false,
      temVendedor: false,
      temZona: false
    };
    this.disableClose();
    this.promise = this._mascarasAnaliticaService
      .getMascaraPorDefeito()
      .then((response: IMascaraAnalitica) => {
        let elementToFocus = 0;
        if (response) {
          this.loadMascara(response);
          elementToFocus = 1;
        }
        window.setTimeout(() => focusElement(this._elementsType[elementToFocus]));
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public async close(): Promise<void> {
    if (!this.model.linhasCCusto.length) {
      return;
    }
    this.disableClose();
    try {
      if (this.model.valorRegistado !== this.totalValue) {
        await this._cgModalService.showOkCancel('docscontabilidade.ccusto.badTotalTitle', 'docscontabilidade.ccusto.badTotalMessage');
      }
      await Promise.all(this._promises);
      this.doc = await this._docsContabilidadeService.linhaAdicionarContasAnalitica(this.model.linhasCCusto, this.linha._index, this.doc);

      const result: IDocContabilidadeCCustoResult = {
        contaAnalitica: this.model,
        docContabilidade: this.doc
      };

      this.enableClose();

      super.close(result);
    } catch {
      this.enableClose();
      focusElement(this._elementsType[1]);
    }
  }

  public loadMascara(manal: IJsonManal): void {
    if (!manal?.mascara) {
      return;
    }
    this.model = {
      manalID: manal.manalID,
      mascara: manal.mascara,
      artigocategoria: this.model.artigocategoria,
      artigoclasse: this.model.artigoclasse,
      ccusto: this.model.ccusto,
      codcontabilistico: this.model.codcontabilistico,
      conta: this.model.conta,
      contaGerada: this.model.contaGerada,
      departamento: this.model.departamento,
      descricaoMascara: manal.descricao,
      familia: this.model.familia,
      grandefamilia: this.model.grandefamilia,
      linhasCCusto: [],
      processo: this.model.processo,
      subdepartamento: this.model.subdepartamento,
      subfamilia: this.model.subfamilia,
      tipoartigo: this.model.tipoartigo,
      valor: this.totalValue,
      valorRegistado: 0,
      vendedor: this.model.vendedor,
      zona: this.model.zona,
      temCategoria: manal.mascara.includes('Y'),
      temCCusto: manal.mascara.includes('C'),
      temClasse: manal.mascara.includes('X'),
      temCodContabilistico: manal.mascara.includes('B'),
      temDepartamento: manal.mascara.includes('D'),
      temFamilia: manal.mascara.includes('F'),
      temGrandeFamilia: manal.mascara.includes('G'),
      temProcesso: manal.mascara.includes('P'),
      temSubDepartamento: manal.mascara.includes('S'),
      temSubFamilia: manal.mascara.includes('K'),
      temTipoArtigo: manal.mascara.includes('T'),
      temVendedor: manal.mascara.includes('V'),
      temZona: manal.mascara.includes('Z')
    };
  }

  public addPromise(value: Promise<void>): void {
    this._promises.push(value);
    Promise.resolve(value).finally(() => {
      this._promises.pop();
    });
  }

  public removeLine(index: number): void {
    this.model.valorRegistado -= this.model.linhasCCusto[index].valor;
    this.model.valor = this.totalValue - this.model.valorRegistado;
    this._setMaxValue();
    this.model.linhasCCusto.splice(index, 1);
  }

  public readonly fnRegistarValor = (): Promise<void> => this._registarValor();

  @ViewChildren(EntityAutocompleteComponent, {read: ElementRef})
  public set elementsType(value: QueryList<ElementRef<HTMLElement>>) {
    this._elementsType = value.toArray().map((item: ElementRef<HTMLElement>) => item.nativeElement);
  }

  @ViewChild('elementSubmit', {read: ElementRef})
  public set elementSubmit(value: ElementRef<HTMLElement>) {
    this._elementSubmit = value?.nativeElement;
  }

  private _registarValor(): Promise<void> {
    if (this.form.invalid) {
      return Promise.resolve();
    }
    if (this.model.valor <= 0) {
      this._plAlertService.error('docscontabilidade.valorTemDeSerSuperior0');
      return Promise.resolve();
    }
    this.disableApply = true;
    return Promise.all(this._promises)
      .then(() => {
        const promise: Promise<void> = this._docsContabilidadeService
          .linhaGenerateContaAnalitica(
            this.linha._index,
            this.doc,
            this.model.manalID,
            this.model.ccusto,
            this.model.zona,
            this.model.departamento,
            this.model.subdepartamento,
            this.model.familia,
            this.model.codcontabilistico,
            this.model.tipoartigo,
            this.model.grandefamilia,
            this.model.subfamilia,
            this.model.artigoclasse,
            this.model.artigocategoria,
            this.model.vendedor,
            this.model.processo,
            this.model.contaGerada
          )
          .then((response: IRestCommandLinhaGenerateContaAnalitica) => {
            const index = this.model.linhasCCusto.findIndex((contaAnalitica: IJsonContaAnalitica) => {
              return contaAnalitica.conta === response.contaGerada;
            });
            if (index !== -1) {
              this._plAlertService.error(this._plTranslateService.translate('docscontabilidade.contaAImputarJaExiste', {nConta: response.contaGerada}));
            } else {
              this.model.linhasCCusto.push({
                conta: response.contaGerada,
                manalID: this.model.manalID,
                artigocategoria: this.model.artigocategoria,
                artigoclasse: this.model.artigoclasse,
                ccusto: this.model.ccusto,
                codcontabilistico: this.model.codcontabilistico,
                departamento: this.model.departamento,
                familia: this.model.familia,
                grandefamilia: this.model.grandefamilia,
                processo: this.model.processo,
                subdepartamento: this.model.subdepartamento,
                subfamilia: this.model.subfamilia,
                tipoartigo: this.model.tipoartigo,
                valor: this.model.valor,
                vendedor: this.model.vendedor,
                zona: this.model.zona
              });
              this.model.valorRegistado += this.model.valor;
              this.model.valor = this.totalValue - this.model.valorRegistado;
              this._setMaxValue();
              this._handleEvents();
            }
            this.disableApply = false;
          })
          .catch(() => {
            this.disableApply = false;
          });
        this.addPromise(promise);
        return promise;
      })
      .catch(() => {
        this.disableApply = false;
      });
  }

  private _handleEvents(): void {
    if (this.model.valorRegistado !== this.totalValue) {
      this.model = {
        manalID: this.model.manalID,
        mascara: this.model.mascara,
        descricaoMascara: this.model.descricaoMascara,
        linhasCCusto: this.model.linhasCCusto,
        conta: this.model.conta,
        contaGerada: this.model.contaGerada,
        valor: this.model.valor,
        valorRegistado: this.model.valorRegistado,
        artigocategoria: undefined,
        artigocategoriaNome: undefined,
        artigoclasse: undefined,
        artigoclasseNome: undefined,
        ccusto: undefined,
        ccustoNome: undefined,
        codcontabilistico: undefined,
        codcontabilisticoNome: undefined,
        departamento: undefined,
        departamentoNome: undefined,
        familia: undefined,
        familiaNome: undefined,
        grandefamilia: undefined,
        grandefamiliaNome: undefined,
        processo: undefined,
        processoNome: undefined,
        subdepartamento: undefined,
        subdepartamentoNome: undefined,
        subfamilia: undefined,
        subfamiliaNome: undefined,
        tipoartigo: undefined,
        tipoartigoNome: undefined,
        vendedor: undefined,
        vendedorNome: undefined,
        zona: undefined,
        zonaNome: undefined,
        temCategoria: this.model.temCategoria,
        temCCusto: this.model.temCCusto,
        temClasse: this.model.temClasse,
        temCodContabilistico: this.model.temCodContabilistico,
        temDepartamento: this.model.temDepartamento,
        temFamilia: this.model.temFamilia,
        temGrandeFamilia: this.model.temGrandeFamilia,
        temProcesso: this.model.temProcesso,
        temSubDepartamento: this.model.temSubDepartamento,
        temSubFamilia: this.model.temSubFamilia,
        temTipoArtigo: this.model.temTipoArtigo,
        temVendedor: this.model.temVendedor,
        temZona: this.model.temZona
      };
      focusElement(this._elementsType[1]);
    } else {
      setTimeout(() => {
        focusElement(this._elementSubmit);
      }, DEFAULT_TIMEOUT);
    }
  }

  private _setMaxValue(): void {
    this.maxValue = this.totalValue - this.model.valorRegistado;
  }
}
