import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService} from '@uirouter/core';
import {PlAlertService} from 'pl-comps-angular';
import {AccountComponent} from '../account.state.component';
import {AuthService} from '../../../services/auth/auth.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../config/constants';
import {IJsonRecaptchaConfig} from '../../../interfaces/jsonConfigValue.interface';
import {IResetPasswordModel} from './resetpassword.state.interface';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';

@Component({
  selector: 'reset-password',
  templateUrl: './resetpassword.state.component.html'
})
export class ResetPasswordComponent implements OnInit {
  @Input() public recaptchaConfig: IJsonRecaptchaConfig;

  public readonly form: UntypedFormGroup;
  public recaptchaActive: boolean;

  constructor(
    private readonly _stateService: StateService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _accountComponent: AccountComponent
  ) {
    this.recaptchaActive = true;
    this.form = this._formBuilder.group({
      username: this._formBuilder.control('', [Validators.required])
    });
  }

  public ngOnInit(): void {
    this.recaptchaActive = this.recaptchaConfig.active;
    if (this.recaptchaActive) {
      this.form.addControl('recaptcha', this._formBuilder.control('', [Validators.required]));
    }
  }

  public readonly fnResetPassword: () => Promise<void> = () => this._resetPassword();

  private _resetPassword(): Promise<void> {
    this._accountComponent.setHasError(false);
    if (this.form.invalid) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    const model: IResetPasswordModel = this.form.value;
    return this._authService
      .resetPassword(model.username)
      .then(() => {
        this._plAlertService.success('resetpassword.success');
        return this._goLogin();
      })
      .catch((reason: HttpErrorResponse) => {
        const exception = this._cgExceptionService.get(reason);
        this._accountComponent.setHasError(true);
        this._accountComponent.errorMessage = exception.message;
        if (this.recaptchaActive) {
          this.form.get('recaptcha').setValue('');
        }
      });
  }

  private _goLogin(): Promise<void> {
    return this._stateService.go(STATE_NAME_LOGIN).then(() => undefined);
  }
}
