import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {GrupoDocfaConfiguracoesModuleComponent} from './components/grupoDocfaConfiguracoes.module.component';
import {MODULE_NAME_GRUPO_DOCFA_CONFIGURACOES} from './grupoDocfaConfiguracoes.module.interface';

export const MODULE_GRUPO_DOCFA_CONFIGURACOES: IModuleDefinition = {
  name: MODULE_NAME_GRUPO_DOCFA_CONFIGURACOES,
  state: {
    url: `/${MODULE_NAME_GRUPO_DOCFA_CONFIGURACOES}`,
    component: GrupoDocfaConfiguracoesModuleComponent,
    data: {
      roles: [ROLE.FINANCEIRO],
      sidebarTitle: 'portals.sidebar.modules.grupodocfaconfiguracoes'
    },
    params: {
      group: {
        type: 'any'
      }
    }
  }
};
