import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';
import {TDate} from '../../../../../common/dates';
import moment from 'moment';

@Component({
  selector: 'modal-transferencias-armazens-processar',
  templateUrl: './transferenciasArmazens.processar.modal.component.html'
})
export class TransferenciasArmazensProcessarModalComponent extends CGModalComponent<void> {
  @Input() public listatransfarmazem: Array<IJsonTransfArmazem>;
  public data: TDate;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService
  ) {
    super(_injector);
    this.data = moment();
  }

  public close(): Promise<void> {
    return this._transferenciasArmazensService.processarTransfArmazens(this.data, this.listatransfarmazem).then(() => {
      return super.close();
    });
  }
}
