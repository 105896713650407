import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, PlAlertService} from 'pl-comps-angular';
import {AssistenteConfigPortaisService} from '../../../../assistente.configPortais.module.service';
import {CGExceptionService} from '../../../../../../../components/exceptions/exceptions.service';
import {ENTITY_NAME_EMPRESAS, IEmpresa} from '../../../../../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {ICGExceptionError} from '../../../../../../../components/exceptions/exceptions.service.interface';
import {IEntityService} from '../../../../../../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {emptyUserNew, IJsonErpUser, IJsonUser, IJsonUserNew} from '../../../../../../../services/account/jsonUserApi.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../../../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';
import {ROLE} from '../../../../../../../services/role.const';
import {THttpQueryResponse, TServiceQueryResponse} from '../../../../../../../services/api/api.service.interface';

@Component({
  selector: 'module-assist-config-portais-utilizador',
  templateUrl: './assistConfigPortais.utilizador.module.component.html'
})
export class AssistConfigPortaisUtilizadorModuleComponent extends ModuloComponent implements OnInit {
  @Input() public portalRolePCA: ROLE;
  @Input() public empresasGabinete: Array<IEmpresa>;

  public readonly user: Partial<IJsonUser>;
  public centralGestId: number;
  public enviaEmail: boolean;
  public associarEmpregado: boolean;
  public empresa: IEmpresa;
  public empregado: IJsonDGEMP;
  public filterEmpresas: string;
  public filterEmpregados: string;

  private readonly _serviceEmpresas: IEntityService<IEmpresa>;
  private _nEmpresaGabinete: string;
  private _nEmpresaCliente: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _assistenteConfigPortaisService: AssistenteConfigPortaisService
  ) {
    super(_injector);
    this.user = {active: true};
    this.enviaEmail = true;
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresa>(ENTITY_NAME_EMPRESAS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
    this.btnSave.visible = true;
    this.btnSave.click = this._save.bind(this);

    this.centralGestId = this.session.erp.centralGestId;
    this.filterEmpresas = this._assistenteConfigPortaisService.generateFilterEmpresas(this.centralGestId, this.session.erp.nEmpresa, this.session.erps);
    const isEmpresaUserGabinete: boolean = this.empresasGabinete.findIndex((empresa: IEmpresa) => empresa.nempresa === this.session.erp.nEmpresa) > -1;
    if (isEmpresaUserGabinete) {
      this._nEmpresaGabinete = this.session.erp.nEmpresa;
      this._getEmpresas(this.filterEmpresas).then((response: THttpQueryResponse<IEmpresa>) => {
        if (response.body.list.length) {
          this.changedEmpresa(response.body.list[0]);
        }
      });
    }
  }

  public back(): Promise<void> {
    const moduleName: string = this._assistenteConfigPortaisService.getModuleSelectPortal();
    return this._cgStateService.redirectToState({stateOrName: moduleName});
  }

  public changedEmpresa(empresa: IEmpresa): void {
    this.empresa = empresa;
    this._nEmpresaCliente = this.empresa ? this.empresa.nempresa : undefined;
    this.filterEmpregados = this._nEmpresaCliente ? `nempresa=${this._nEmpresaCliente}` : undefined;
    if (this.empregado) {
      this.empregado = undefined;
    }
  }

  private _getEmpresas(search: string): TServiceQueryResponse<IEmpresa> {
    return this._serviceEmpresas.query({pesquisa: search, params: {centralGestId: this.centralGestId}});
  }

  private _save(): Promise<void> {
    if (!this._nEmpresaGabinete) {
      this._plAlertService.error('assistenteconfigportais.info.empresagabinetenaoselec');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (!this._nEmpresaCliente) {
      this._plAlertService.error('assistenteconfigportais.info.empresaclientenaoselec');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    const newUser = this._generateNewUser();
    return this._assistenteConfigPortaisService
      .setNewConfigurationUserEmpresaPortal(newUser, this.session.erp.name, this._nEmpresaGabinete, this._nEmpresaCliente)
      .then(() => {
        this._plAlertService.success('assistenteconfigportais.info.savesucess');
        return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS});
      })
      .catch((error) => {
        const exception: ICGExceptionError = this._cgExceptionService.get(error);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('assistenteconfigportais.info.errorsave');
        }
      });
  }

  private _generateNewUser(): IJsonUserNew {
    const erp: IJsonErpUser = {
      cgID: undefined,
      centralGestId: this.session.erp.centralGestId,
      name: this.session.erp.name,
      apiUrl: this.session.erp.apiUrl,
      nEmpresa: this._nEmpresaCliente,
      nUtilizador: this.session.erp.nUtilizador,
      ativo: true,
      nomeEmpresa: this.session.erp.nomeEmpresa,
      nomeUtilizadorCG: this.session.erp.nomeUtilizadorCG,
      codEmp: this.associarEmpregado && this.empregado ? this.empregado.codEmp : undefined,
      nomeEmpregado: this.associarEmpregado && this.empregado ? this.empregado.nome : undefined,
      rolesAcess: [
        {role: ROLE.PCA}, // role API
        {role: copy(this.portalRolePCA)}
      ],
      rolesNotAcess: []
    };
    return {
      ...emptyUserNew(),
      username: this.user.username,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      active: this.user.active,
      erps: [erp],
      lasterps: [],
      erp: erp,
      roles: erp.rolesAcess,
      sendEmail: this.enviaEmail
    };
  }
}
