<div class="modelo22-prejuizos-modal entity-detail-form">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'modelo22.modal.prejuizosfiscais.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.prejuizosfiscais.anoprej'"></label>
          <edit>
            <pl-edit type="cginteger" attrName="anoPrejuizo" [(model)]="anoPrejuizo" [properties]="{validate: false}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.modal.prejuizosfiscais.valprej'"></label>
          <edit>
            <pl-edit type="number" attrName="valPrejuizo" [(model)]="valPrejuizo"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label></label>
          <edit>
            <button type="button" class="btn btn-primary btn-sm" (click)="addLinePrejuizo()" [disabled]="!anoPrejuizo || !valPrejuizo" plPromise>
              <i class="fa fa-fw fa-pencil-square" aria-hidden="true"></i><span [translate]="'modelo22.modal.prejuizosfiscais.adicionarprej'"></span>
            </button>
          </edit>
        </pl-group>
      </pl-group>

      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="prejuizosFiscais" [dataSource]="dataGridDefinition.dataSource">
        <div *dxTemplate="let item of 'cellTemplateBtnPrejFiscas'">
          <button type="button" class="btn btn-danger btn-xs action-remove-line" (click)="removeLinePrejuizos(item.data)" plPromise>
            <i class="fa fa-fw fa-minus"></i>
          </button>
        </div>
        <div *dxTemplate="let item of 'masterDetailTemplatePrejuizosFiscais'">
          <pl-group>
            <pl-group>
              <label [translate]="'modelo22.modal.prejuizosfiscais.anoded'"></label>
              <edit>
                <pl-edit type="cginteger" attrName="anoPrejuizo" [(model)]="anoDeducao" [properties]="{validate: false}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'modelo22.modal.prejuizosfiscais.valded'"></label>
              <edit>
                <pl-edit type="number" attrName="valPrejuizo" [(model)]="valDeducao"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label></label>
              <edit>
                <button type="button" class="btn btn-primary btn-sm" (click)="addLineDeducao(item.data)" [disabled]="!anoDeducao || !valDeducao" plPromise>
                  <i class="fa fa-fw fa-pencil-square" aria-hidden="true"></i><span [translate]="'modelo22.modal.prejuizosfiscais.adicionarprej'"></span>
                </button>
              </edit>
            </pl-group>
          </pl-group>

          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetail" cgDxDataGridInstanceName="prejuizosFiscaisDeducoes" [dataSource]="item.data.deducoes">
            <div *dxTemplate="let itemDeducoes of 'cellTemplateBtnDeducoes'">
              <button type="button" class="btn btn-danger btn-xs action-remove-line" (click)="removeLineDeducoes(item.data, itemDeducoes.data)" plPromise>
                <i class="fa fa-fw fa-minus"></i>
              </button>
            </div>
          </dx-data-grid>
        </div>
      </dx-data-grid>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

      <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
