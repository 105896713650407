import {Injectable} from '@angular/core';
import {ISimpleLoginCredentials} from './simpleLogin.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {ApiService} from '../../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SimpleLoginService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/simplelogin`;
  }

  public getAtCredentials(): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.get<ISimpleLoginCredentials>({url: `${this._path}/at`});
  }

  public saveAtCredentials(data: ISimpleLoginCredentials): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.post<ISimpleLoginCredentials>({url: `${this._path}/at`, body: data});
  }

  public getSDDCredentials(): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.get<ISimpleLoginCredentials>({url: `${this._path}/ssd`});
  }

  public saveSDDCredentials(data: ISimpleLoginCredentials): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.post<ISimpleLoginCredentials>({url: `${this._path}/ssd`, body: data});
  }

  public getViaCTTCredentials(): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.get<ISimpleLoginCredentials>({url: `${this._path}/viactt`});
  }

  public saveViaCTTCredentials(data: ISimpleLoginCredentials): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.post<ISimpleLoginCredentials>({url: `${this._path}/viactt`, body: data});
  }

  public getEFaturaCredentials(): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.get<ISimpleLoginCredentials>({url: `${this._path}/efatura`});
  }

  public saveEFaturaCredentials(data: ISimpleLoginCredentials): TServiceResponse<ISimpleLoginCredentials> {
    return this._apiService.post<ISimpleLoginCredentials>({url: `${this._path}/efatura`, body: data});
  }
}
