<div class="apuramentoresultados">
  <pl-nav-wizard [(model)]="selectedStep" [definition]="definitionNavWizard" [beforeStepChange]="fnBeforeChangedStep" [onFinalize]="fnFinalize">
    <pl-nav-wizard-step [stepId]="steps.Variables" caption="apurainventperma.module.steps.inventario" icon="fa-list-alt">
      <div *plNavWizardStepContent>
        <div class="row">
          <div class="col-sm-12 col-lg-8">
            <dx-data-grid [cgDxDataGrid]="definitionContas" cgDxDataGridInstanceName="apuraStockContas" [dataSource]="contasApuraStock" (onCellClick)="onCellClickApuraStockContas($event)">
              <div *dxTemplate="let item of 'detail'; let itemIndex = index">
                <dx-data-grid [cgDxDataGrid]="definitionContasDetail" [dataSource]="item.data.valoresInventario" (onRowUpdated)="onRowUpdatedChangeValorParentRow(item.data)"></dx-data-grid>
              </div>
            </dx-data-grid>
          </div>
          <div class="col-sm-12 col-lg-1"></div>
          <div class="col-sm-12 col-lg-3">
            <dx-data-grid
              [cgDxDataGrid]="definitionInfoApuramento"
              cgDxDataGridInstanceName="apuraStockInfoApuramento"
              [dataSource]="infoApuramento"
              (onCellPrepared)="onCellPreparedInfoApuramento($event)"
              (onContextMenuPreparing)="onContextMenuPreparingInfoApuramento($event)">
              <div *dxTemplate="let row of 'dataGridTemplateActions'">
                <a *ngIf="row.data.existeDocDaNaturezaNoPeriodo" (click)="openRowActions(row.data, $event)">
                  <i class="fa fa-fw fa-cogs" aria-hidden="true"></i>
                </a>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Documents" caption="apurainventperma.module.steps.documents" icon="fa-search">
      <div *plNavWizardStepContent class="entity-detail-form">
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <div class="row">
            <pl-group class="col-sm-3">
              <label [translate]="'docscontabilidade.fields.periodo'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="periodo"
                  [model]="paramApuraStockDados"
                  (selectedKeyChange)="paramApuraStockDados.nPeriodo = $event; changedPeriodoValidate($event)"
                  (evtSelectedDescriptionChanged)="paramApuraStockDados.nomePeriodo = $event"
                  [fieldsMap]="{periodo: 'nPeriodo', nome: 'nomePeriodo'}"
                  [outputKey]="'periodo'"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group class="col-sm-3">
              <label [translate]="'docscontabilidade.fields.nDiario'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  attrName="diario"
                  [model]="paramApuraStockDados"
                  (selectedKeyChange)="paramApuraStockDados.nDiario = $event"
                  (evtSelectedDescriptionChanged)="paramApuraStockDados.nomeDiario = $event"
                  [fieldsMap]="{nDiario: 'nDiario', nome: 'nomeDiario'}"
                  [outputKey]="'nDiario'">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group class="col-sm-3">
              <label [translate]="'apuramentoresultados.fields.descritivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  attrName="descritivo"
                  [model]="paramApuraStockDados"
                  (selectedKeyChange)="paramApuraStockDados.nDescritivo = $event"
                  (evtSelectedDescriptionChanged)="paramApuraStockDados.nomeDescritivo = $event"
                  [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nomeDescritivo'}"
                  [outputKey]="'nDescrit'">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group class="col-sm-3">
              <label [translate]="'apuramentoresultados.fields.dataDoc'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDoc" [(model)]="paramApuraStockDados.dataDoc"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Preview" caption="apurainventperma.module.steps.preview" icon="fa-file">
      <dx-data-grid *plNavWizardStepContent [cgDxDataGrid]="definitionPreVisualDocumentos" cgDxDataGridInstanceName="preVisualDocumentos" [dataSource]="valores"></dx-data-grid>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
