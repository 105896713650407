<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.buttons.notificar'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource" cgDxDataGridInstanceName="dataGridPorNotificar" (onInitialized)="onDataGridInitialized($event)">
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary" [click]="fnNotificar" plPromise><i class="fa fa-fw fa-bell"></i>&nbsp;<span [translate]="'debDiretosSEPA.buttons.notificar'"></span></button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
