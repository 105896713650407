import {ENTITY_NAME_SECCOES} from './seccoes.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonSeccoes} from './jsonSeccoes.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_SECCOES: IEntityDefinition<IJsonSeccoes> = {
  name: ENTITY_NAME_SECCOES,
  roles: [ROLE.RH, ROLE.ATIVOS],
  searchPlaceholder: 'seccoes.pesquisa',
  pageTitle: 'global.menu.seccoes',
  metadata: {
    keyName: 'nSeccao',
    fields: [
      {name: 'nSeccao', width: '200px', caption: 'seccoes.fields.nSeccao', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'seccoes.fields.designacaoBreve', validators: {required: true}},
      {name: 'designacaoCompleta', caption: 'seccoes.fields.designacaoCompleta', validators: {required: true}}
    ],
    order: 'nSeccao',
    searchFields: 'nSeccao,designacaoBreve,designacaoCompleta'
  },
  autocomplete: {
    rowTemplate: '{{nSeccao}} - {{designacaoBreve}}',
    output: 'designacaoBreve',
    searchFields: 'nSeccao,designacaoBreve,designacaoCompleta'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
