<pl-form [(formInstance)]="form" [properties]="{orientation: 'horizontal', labelClass: 'col-sm-5 col-lg-4 text-start', editClass: 'col-sm-7 col-lg-8', disabled: !!promise}" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title">Configurações do WebService</h4>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-alert [type]="alertType" *ngIf="alertMessage">
      <div [innerHTML]="alertMessage"></div>
    </pl-alert>

    <ng-container *ngIf="forDrIva && !model.isConfigured">
      <pl-button klass="btn-light btn-sm mb-2" (evtClicked)="showLoginModal()"><i class="fa fa-fw fa-cog"></i>&nbsp;<span [translate]="'declaracaoiva.btns.configATLogin'"></span></pl-button>
    </ng-container>

    <ng-container *ngIf="!forDrIva">
      <pl-group>
        <label>Username do contribuinte</label>
        <edit>
          <pl-edit type="text" attrName="username" [(model)]="model.username"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label>Password do contribuinte</label>
        <edit>
          <pl-edit type="password" attrName="password" [(model)]="model.password" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>
    </ng-container>

    <pl-group>
      <label>Serviço invocado por Contabilista Certificado (CC)</label>
      <edit>
        <pl-edit type="boolean" attrName="servicoInvocadoPorCC" [model]="model.servicoInvocadoPorCC" (modelChange)="servicoInvocadoPorCCChanged($event)"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label>CC com plenos poderes declarativos para o Contrib.</label>
      <edit>
        <pl-edit type="boolean" attrName="ccComPoderesDeclarativosContribuinte" [(model)]="model.ccComPoderesDeclarativosContribuinte" [properties]="{disabled: !model.servicoInvocadoPorCC}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label>Username do CC</label>
      <edit>
        <pl-edit type="text" attrName="usernameCC" [(model)]="model.usernameCC" [properties]="{disabled: !model.servicoInvocadoPorCC, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label>Password do CC</label>
      <edit>
        <pl-edit type="password" attrName="passwordCC" [(model)]="model.passwordCC" [properties]="{disabled: !model.servicoInvocadoPorCC, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary action-save"><i class="fa fa-fw fa-save"></i><span [translate]="'global.btn.save'"></span></button>

    <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
