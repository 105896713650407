import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlAnimationCollapseComponent} from './animation.collapse.component';

export * from './animation.collapse.component';

const DECLARATIONS = [PlAnimationCollapseComponent];

@NgModule({
  imports: [CommonModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlAnimationCollapseModule {}
