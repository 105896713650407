import {IPlPdfCallback, IPlPdfEvtConfigureToolbar, IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {IJsonContabDigitalGDocViewerRecolhaFolderAttachments, IJsonDocOCR} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IContabDigitalDocViewerRecolhaSelectAttachmentOptions} from '../../contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';

export interface IArquivoDigitalDocViewerCallback {
  pdf?: IPlPdfCallback;

  loadLastFolder?(): Promise<void>;

  reset?(): void;

  getSelectedAttachment?(): IJsonDocOCR;

  getNextAttachment?(): IJsonDocOCR;

  deleteSelectedAttachment?(deleteFromDB: boolean, properties?: IContabDigitalDocViewerRecolhaSelectAttachmentOptions): Promise<void>;

  clear?(): Promise<void>;
}

export interface IArquivoDigitalDocViewerEvtConfigureToolbar extends IPlPdfEvtConfigureToolbar {
  btnRotateLeft: IPlToolbarMenuItem;
  btnRotateRight: IPlToolbarMenuItem;
  mnuRotate: IPlToolbarItem;
}

export enum EArquivoDigitalDocViewerState {
  Default,
  Editing,
  Searching
}

export enum EArquivoDigitalDocViewerAddDocsMode {
  CurrentFolder,
  ChooseFolder
}

export interface IArquivoDigitalDocViewerEvtChangedAttachment {
  folder: IJsonContabDigitalGDocViewerRecolhaFolderAttachments;
  attachment: IJsonDocOCR;
  doInitDocumento?: boolean;
}
