import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPlFilterPanelEvtFiltered, IPlTableField, isObject, Logger} from 'pl-comps-angular';
import {EntityFilterService} from './entity.filter.service';
import {EntityRegistryService} from '../entity.registry.service';
import {IEntity, IEntityMetadataField} from '../entity.definition.interface';

const focusableTypes: Array<string> = ['INPUT', 'SELECT', 'TEXTAREA'];

@Component({
  selector: 'cg-entity-filter',
  templateUrl: './entity.filter.component.html'
})
export class CGEntityFilterComponent implements OnInit, OnChanges {
  @Input() public entity: string | IEntity;
  @Input() public collapsed: boolean;
  @Input() public allowCollapse: boolean;
  @Input() public selectedFilter: string | IPlTableField;
  @Input() public filterModel: string;
  @Output() public readonly collapsedChange: EventEmitter<boolean>;
  @Output() public readonly evtFiltered: EventEmitter<IPlFilterPanelEvtFiltered<string>>;

  public fields: Array<IEntityMetadataField>;

  private readonly _element: HTMLElement;
  private _entity: IEntity;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _entityFilterService: EntityFilterService
  ) {
    this.collapsedChange = new EventEmitter<boolean>();
    this.evtFiltered = new EventEmitter<IPlFilterPanelEvtFiltered<string>>();
    this.allowCollapse = true;
    this._element = this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this._entity = isObject(this.entity) ? <IEntity>this.entity : this._entityRegistryService.getEntity(<string>this.entity, false);
    this.fields = this._entityFilterService.buildFields(this._entity);
    if (!this.selectedFilter) {
      this.selectedFilter = this._entity.metadata.filterField;
    }
  }

  public ngOnChanges({collapsed}: SimpleChanges): void {
    if (collapsed && collapsed.currentValue === false) {
      this._focusEdit();
    }
  }

  public collapse(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
    if (!this.collapsed) {
      this._focusEdit();
    }
  }

  public filtered(event: IPlFilterPanelEvtFiltered<string>): void {
    this.evtFiltered.emit(event);
  }

  private _focusEdit(): void {
    setTimeout(() => {
      const input: HTMLInputElement = this._element.querySelector<'input'>('input');
      if (input) {
        try {
          const activeElement: Element = document.activeElement;
          if (!activeElement || !focusableTypes.includes(document.activeElement.tagName)) {
            input.focus();
          }
        } catch (exception: unknown) {
          this._logger.error(exception);
        }
      }
    });
  }
}
