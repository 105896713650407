<div class="rhrelatoriounico-container">
  <div class="blocked-by-other-user-container" *ngIf="isBlocked">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>

    <div class="block-inline">
      <h5 [translate]="'rhrelatoriounico.messages.processModalMessageByOtherUser'"></h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-tabs *ngIf="!isBlocked" [theme]="'style3'" [activeId]="tabActiveId" (evtSelected)="onTabChanged($event)">
    <pl-tab caption="rhrelatoriounico.tabProcess" [id]="tabProcess">
      <div *plTabContent>
        <ng-container *ngIf="currentStep === rhrelatoriounicoSteps.Configuration">
          <p class="text-danger"><strong [translate]="'rhrelatoriounico.warningMessage'"></strong></p>

          <div class="rhrelatoriounico-block">
            <div>
              <h4 style="border-bottom-color: transparent">&nbsp;</h4>
            </div>

            <pl-button type="button" klass="btn-success" [onClick]="fnVisualProcess"> <i class="fa fa-bolt" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.process'"></span> </pl-button>
          </div>
        </ng-container>

        <div class="row" *ngIf="currentStep === rhrelatoriounicoSteps.Processing">
          <div class="col-md-12">
            <div class="rhrelatoriounico-pb-container">
              <div class="rhrelatoriounico-pb-label" [translate]="'rhrelatoriounico.processingMessage'"></div>

              <div class="progress">
                <div
                  class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [attr.aria-valuenow]="100"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  style="width: 100%"></div>
              </div>

              <div class="row">
                <div class="col-md-10">
                  <div class="rhrelatoriounico-pb-label-op" [translate]="statusDescription"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === rhrelatoriounicoSteps.Errors">
          <div>
            <h5 class="mb-1"><span [translate]="'rhrelatoriounico.erros'"></span>:</h5>
          </div>

          <pl-alert type="error" [closeable]="false" *ngFor="let error of statusErrorList">
            <i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<strong [translate]="error.nome"></strong>:&nbsp;<span [translate]="error.descricao"></span>
          </pl-alert>

          <pl-button klass="btn-light" type="button" (evtClicked)="closeErrorPanel()">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <span [translate]="'rhrelatoriounico.buttons.fechar'"></span>
          </pl-button>
        </div>
      </div>
    </pl-tab>

    <pl-tab caption="rhrelatoriounico.tabDownload" [id]="tabDownload" [disabled]="!currentStatus.ficheiroProcessado">
      <div *plTabContent>
        <label class="d-block" [translate]="'rhrelatoriounico.fileReady'"></label>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'rhrelatoriounico.dataCriacao'"></span>:<strong>{{ currentStatus.ficheiroProcessadoDate | cgcDateTime }}</strong>
        </div>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'rhrelatoriounico.tamanho'"></span>: <strong>{{ formatBytes(currentStatus.ficheiroProcessadoSize) }}</strong>
        </div>

        <a class="btn btn-link" target="_blank" (click)="doDownload()"> <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'rhrelatoriounico.buttons.download'"></span> </a>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'rhrelatoriounico.utilizador'"></span>: <strong>{{ currentStatus.userStarted }}</strong>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
