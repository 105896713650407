import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHAtualizaFeriasEmpService} from '../rhAtualizaFeriasEmp.module.service';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnSaved} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonRHAtualizaFeriasEmpregado} from '../jsonRHAtualizaFeriasEmp.module.interface';
import {copy, PlAlertService} from 'pl-comps-angular';
import ArrayStore from 'devextreme/data/array_store';
import {HttpResponse} from '@angular/common/http';
import {TDate} from '../../../../../common/dates';
import {TAtualizaFeriasEmpValidationCallbackData, TAtualizaFeriasEmpValidationCallbackValue} from '../rhAtualizaFeriasEmp.module.interface';
import {RHAtualizaFeriasEmpDataCadastroModalComponent} from '../modals/rhAtualizaFeriasEmp.dataCadastro.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'module-rhatualizaferiasemp',
  templateUrl: './rhAtualizaFeriasEmp.module.component.html'
})
export class RHAtualizaFeriasEmpModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonRHAtualizaFeriasEmpregado, number>;

  public ano: number;

  private _dataGridInstance: dxDataGrid<IJsonRHAtualizaFeriasEmpregado, number>;
  private _initialAtualizaFeriasEmp: Array<IJsonRHAtualizaFeriasEmpregado>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhAtualizaFeriasEmpService: RHAtualizaFeriasEmpService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._initialAtualizaFeriasEmp = [];
    this.ano = moment().year();
    this.dataGridDefinition = {
      columns: [
        {
          caption: 'rhatualizaferiasemp.fields.empregado',
          fixed: true,
          columns: [
            {dataField: 'codEmp', dataType: 'number', caption: 'rhatualizaferiasemp.fields.codEmp', allowEditing: false},
            {dataField: 'nomeEmpregado', dataType: 'string', caption: 'rhatualizaferiasemp.fields.nomeEmpregado', allowEditing: false}
          ]
        },
        {
          caption: this._translateService.instant('rhatualizaferiasemp.fields.feriasano', {ano: this.ano - 1}),
          columns: [
            {dataField: 'anoAnteriorNDiasFeriasAnoAnterior', dataType: 'double', caption: 'rhatualizaferiasemp.fields.anoAnteriorNDiasFeriasAnoAnterior', allowEditing: false},
            {dataField: 'anoAnteriorNDiasFeriasAnoAtual', dataType: 'double', caption: 'global.text.year', allowEditing: false},
            {dataField: 'anoAnteriorGozadasNDiasFeriasAnoAnterior', dataType: 'double', caption: 'rhatualizaferiasemp.fields.anoAnteriorGozadasNDiasFeriasAnoAnterior', allowEditing: false},
            {dataField: 'anoAnteriorGozadasNDiasFeriasAnoAtual', dataType: 'double', caption: 'rhatualizaferiasemp.fields.anoAnteriorGozadasNDiasFeriasAnoAtual', allowEditing: false}
          ]
        },
        {
          caption: this._translateService.instant('rhatualizaferiasemp.fields.feriasano', {ano: this.ano}),
          columns: [
            {
              dataField: 'nDiasFeriasAnoAnterior',
              dataType: 'double',
              caption: 'rhatualizaferiasemp.fields.nDiasFeriasAnoAnterior',
              validationRules: [
                {
                  type: 'custom',
                  message: this._translateService.instant('rhatualizaferiasemp.messages.valNegativo'),
                  validationCallback: (options: TAtualizaFeriasEmpValidationCallbackValue) => this._validateYear(options)
                }
              ]
            },
            {
              dataField: 'nDiasFeriasAnoAtual',
              dataType: 'double',
              caption: 'global.text.year',
              validationRules: [
                {
                  type: 'custom',
                  message: this._translateService.instant('rhatualizaferiasemp.messages.valNegativo'),
                  validationCallback: (options: TAtualizaFeriasEmpValidationCallbackValue) => this._validateYear(options)
                }
              ]
            },
            {
              dataField: 'dataMotivoCadastro',
              dataType: 'date',
              caption: 'rhatualizaferiasemp.fields.dataMotivoCadastro',
              validationRules: [
                {
                  type: 'custom',
                  message: this._translateService.instant('rhatualizaferiasemp.messages.datainferior'),
                  validationCallback: (options: TAtualizaFeriasEmpValidationCallbackData) => this._validateData(options)
                }
              ]
            }
          ]
        },
        {
          caption: this._translateService.instant('rhatualizaferiasemp.fields.feriasano', {ano: this.ano}),
          columns: [{dataField: 'livre', dataType: 'string', caption: 'rhatualizaferiasemp.fields.livre', alignment: 'center', cellTemplate: 'cellTemplateLivre', allowEditing: false}]
        }
      ],
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateBtn',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.ano = moment().year();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'search',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => this._pesquisaFeriasEmp()
    });
    this.toolbar.addButton({
      id: 'save',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-save"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      click: () => this._atualizaFeriasEmp()
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonRHAtualizaFeriasEmpregado, number>): void {
    this._dataGridInstance = component;
  }

  public onSaved({changes}: IDevExpressDataGridEventOnSaved<IJsonRHAtualizaFeriasEmpregado, number>): void {
    this._dataGridInstance.selectRows([changes[0].key], true);
  }

  public openModalDataCadastro(): Promise<void> {
    const selectedKeys: Array<number> = this._dataGridInstance.getSelectedRowKeys();
    if (!selectedKeys.length) {
      this._plAlertService.error('rhatualizaferiasemp.messages.temselecionaremp');
      return Promise.resolve();
    }

    const modalInstance = this._cgModalService.showVanilla(RHAtualizaFeriasEmpDataCadastroModalComponent, {size: 'md'});
    return modalInstance.result.then((date: TDate) => {
      for (const selectedKey of selectedKeys) {
        this._dataGridInstance
          .getDataSource()
          .store()
          .update(selectedKey, {dataMotivoCadastro: date})
          .then(() => {
            this._dataGridInstance.getDataSource().reload();
          });
      }
    });
  }

  private _pesquisaFeriasEmp(): Promise<void> {
    return this._rhAtualizaFeriasEmpService.getFeriasEmpregados(this.ano).then((response: HttpResponse<Array<IJsonRHAtualizaFeriasEmpregado>>) => {
      this._applyDataSource(response.body);
    });
  }

  private _atualizaFeriasEmp(): Promise<void> {
    const selectedKeys: Array<number> = this._dataGridInstance.getSelectedRowKeys();
    if (!selectedKeys.length) {
      this._plAlertService.error('rhatualizaferiasemp.messages.temselecionaremp');
      return Promise.resolve();
    }
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((list: Array<IJsonRHAtualizaFeriasEmpregado>) => {
        for (const selectedKey of selectedKeys) {
          const index: number = list.findIndex((item: IJsonRHAtualizaFeriasEmpregado) => item.codEmp === selectedKey);
          if (index !== -1) {
            list[index].selected = true;
          }
        }
        return this._rhAtualizaFeriasEmpService.postFeriasEmpregado(list).then(() => {
          this._plAlertService.success('rhatualizaferiasemp.messages.savedsuccess');
          for (const selectedKey of selectedKeys) {
            const index: number = list.findIndex((item: IJsonRHAtualizaFeriasEmpregado) => item.codEmp === selectedKey);
            if (index !== -1) {
              list[index].livre = '1';
            }
          }
          this._applyDataSource(copy(list));
        });
      });
  }

  private _validateData(options: TAtualizaFeriasEmpValidationCallbackData): boolean {
    const initialDate: TDate = this._initialAtualizaFeriasEmp.find((item: IJsonRHAtualizaFeriasEmpregado) => item.codEmp === options.data.codEmp).dataMotivoCadastro;
    return moment(moment(options.data.dataMotivoCadastro).format('YYYYMMDD')).isSameOrAfter(moment(initialDate).format('YYYYMMDD'));
  }

  private _validateYear(options: TAtualizaFeriasEmpValidationCallbackValue): boolean {
    return !(options.value < 0);
  }

  private _applyDataSource(list: Array<IJsonRHAtualizaFeriasEmpregado>): void {
    this._initialAtualizaFeriasEmp = copy(list);
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: 'codEmp',
      data: list
    });
  }
}
