import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {ETipoContaContabilidade} from '../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {
  IJsonPlanoContasAlternativoCab,
  IJsonPlanoContasAlternativoCubo,
  IJsonPlanoContasAlternativoExtrato,
  IJsonPlanoContasAlternativoLin,
  IJsonPlanoContasAlternativoRadicais
} from './jsonPlanoContasAlternativo.entity.interface';

export enum EPlanoContasAltCuboLayout {
  SALDO_GERAL,
  SALDO_CRED_DEB
}

export interface IFiltersPlanoContasAltCubo {
  deConta: string;
  ateConta: string;
  dePeriodo: string;
  dePeriodoNome?: string;
  atePeriodo: string;
  atePeriodoNome?: string;
  pocAltCabID: string;
  nomePocAlt: string;
  escondeRubricasSemMovimento: boolean;
}

export interface IPlanosContasAlternativosEntityService extends IEntityService<IJsonPlanoContasAlternativoCab> {
  getModImportPlanoAlt(): TServiceResponse<Blob>;

  importPlanoAlt(pocAltCabID: string, file: File): TServiceResponse<Array<IJsonPlanoContasAlternativoLin>>;

  exportPlanoAlt(pocAltCabID: string): TServiceResponse<Blob>;

  cuboPlanoContasAlt(
    pocAltCabID: string,
    periodoDe: string,
    periodoAte: string,
    contaDe: string,
    contaAte: string,
    escondeRubricasSemMovimento: boolean
  ): TServiceResponse<Array<IJsonPlanoContasAlternativoCubo>>;

  extratoPlanoContasAlt(pocAltCabID: string, periodoDe: string, periodoAte: string, nConta: string, acumulado: boolean): TServiceResponse<Array<IJsonPlanoContasAlternativoExtrato>>;

  fillRadicals(radicais: Array<IJsonPlanoContasAlternativoRadicais>): TServiceResponse<Array<IJsonPlanoContasAlternativoRadicais>>;

  updatePCAltLinha(pocAltCabID: string, pcaltLinha: IJsonPlanoContasAlternativoLin): TServiceResponse<IJsonPlanoContasAlternativoLin>;

  deletePCAltLinha(pocAltCabID: string, pocAltLinID: string): TServiceResponse<void>;
}

export interface IPlanosContasAlternativosEntity extends IEntityDefinition<IJsonPlanoContasAlternativoCab, IPlanosContasAlternativosEntityService> {}

export interface IPlanoContasAltCuboLayout {
  layout: EPlanoContasAltCuboLayout;
  caption: string;
}

export const RADIO_GROUP_TIPO_CONTA_ALT: IRadioGroup<ETipoContaContabilidade> = deepFreeze<IRadioGroup<ETipoContaContabilidade>>({
  groupItems: [
    {value: ETipoContaContabilidade.Movimento, label: 'planoscontasalternativos.fields.tipoConta.movimento'},
    {value: ETipoContaContabilidade.SubTotal, label: 'planoscontasalternativos.fields.tipoConta.subtotal'},
    {value: ETipoContaContabilidade.Razao, label: 'planoscontasalternativos.fields.tipoConta.razao'},
    {value: ETipoContaContabilidade.Classe, label: 'planoscontasalternativos.fields.tipoConta.classe'}
  ]
});

export const ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS = 'planoscontasalternativos';
