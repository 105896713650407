import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../translate/translate.module';
import {PlTabsComponent} from './tabs.component';
import {PlTabDirective} from './tab.directive';
import {PlTabContentDirective} from './tab.content.directive';
import {PlTabTitleDirective} from './tab.title.directive';

export * from './tab.interface';
export * from './tabs.component';
export * from './tab.directive';
export * from './tab.content.directive';
export * from './tab.title.directive';

const DECLARATIONS = [
  PlTabsComponent,
  PlTabDirective,
  PlTabContentDirective,
  PlTabTitleDirective
];

@NgModule({
  imports: [
    CommonModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlTabsModule {
}
