import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRhCessaContratoTrabalho, IJsonRhCessaContratoTrabalhoEncerrar, IJsonRhCessaContratoTrabalhoForm} from './jsonRHCessaContratoTrabalho.module.interface';
import {IRhCessaContratoTrabalhoGravar} from './rhCessaContratoTrabalho.module.interface';
import {IJsonListaExcl} from '../rhfolfer/jsonRHFolfer.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RhCessaContratoTrabalhoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhcessacontratotrabalho`;
  }

  public getExcludedAbonos(): TServiceResponse<Array<IJsonListaExcl>> {
    return this._apiService.get<Array<IJsonListaExcl>>({
      url: `${this._path}/excluedabonos`
    });
  }

  public postSimularValor(cessacontratotrabalho: IJsonRhCessaContratoTrabalho): TServiceResponse<IJsonRhCessaContratoTrabalho> {
    return this._apiService.post<IJsonRhCessaContratoTrabalho>({
      url: `${this._path}/simularvalor`,
      body: cessacontratotrabalho
    });
  }

  public postCarregaByCodEmp(cessacontratotrabalhoform: IJsonRhCessaContratoTrabalhoForm): TServiceResponse<IJsonRhCessaContratoTrabalhoForm> {
    return this._apiService.post<IJsonRhCessaContratoTrabalhoForm>({
      url: `${this._path}/carregaporcodemp`,
      body: cessacontratotrabalhoform
    });
  }

  public postDadosCessacaoContTrab(cessacontratotrabalhoform: IJsonRhCessaContratoTrabalhoForm): TServiceResponse<Array<IJsonRhCessaContratoTrabalhoEncerrar>> {
    return this._apiService.post<Array<IJsonRhCessaContratoTrabalhoEncerrar>, IJsonRhCessaContratoTrabalhoForm>({
      url: `${this._path}/dadoscessacaoconttrab`,
      body: cessacontratotrabalhoform
    });
  }

  public postRecalcularComplementos(listaexcluidos: string, cessacontratotrabalhoform: IJsonRhCessaContratoTrabalhoForm): TServiceResponse<IJsonRhCessaContratoTrabalhoForm> {
    return this._apiService.post<IJsonRhCessaContratoTrabalhoForm>({
      url: `${this._path}/recalcularcomplementos`,
      body: cessacontratotrabalhoform,
      params: {listaexcluidos: listaexcluidos}
    });
  }

  public postGravaCessacaoContrato(rhressacontratotrabalhogravar: IRhCessaContratoTrabalhoGravar): TServiceResponse<void> {
    return this._apiService.post<void, IRhCessaContratoTrabalhoGravar>({
      url: `${this._path}/gravarcessacaocontrato`,
      body: rhressacontratotrabalhogravar
    });
  }

  public postValidarNovaLinhaEncerrar(cessacontratotrabalhoencerrar: IJsonRhCessaContratoTrabalhoEncerrar): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRhCessaContratoTrabalhoEncerrar>({
      url: `${this._path}/validarnovalinhaencerrar`,
      body: cessacontratotrabalhoencerrar
    });
  }
}
