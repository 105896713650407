import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaSeguradoraComponent} from './components/mapaSeguradora.module.component';

export const MODULE_PCA_RH_MAPA_SEGURADORA: IModuleDefinition = {
  name: 'pcaMapaSeguradora',
  state: {
    url: '/rh/mapa/seguradora',
    component: MapaSeguradoraComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaMapaSeguradora'
    }
  }
};
