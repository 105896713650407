import {isEqual} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_TIPO_ABONODESC} from '../../../../datasources/tipoabonodesc/tipoAbonoDesc.datasource';
import {EConfigRHEstadoItem, IJsonConfigRHExclusoesTransf, IJsonConfigRHTransfBancarias, IJsonConfigRHTrfbaTicket} from '../../jsonRHConfiguracoes.module.interface';
import {ETipoAbonoDesconto} from '../../../../entities/abdes/abdes.entity.interface';
import {IJsonABDES} from '../../../../entities/abdes/jsonABDES.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';
import {TransferenciaBancariaModalComponent} from '../modals/transferenciaBancaria.modal.component';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Properties} from 'devextreme/ui/button';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';

const TAB_ID_TICKETS = 'tickets';
const TAB_ID_EXCLUSOES = 'exclusoes';

@Component({
  selector: 'module-rh-transferenciasbancarias',
  templateUrl: './rhTransferenciasBancarias.module.component.html'
})
export class RHTransferenciasBancariasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public model: IJsonConfigRHTransfBancarias;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionExcluded: IDevExpressDataGrid;
  public readonly tipoAbonoDesconto: typeof ETipoAbonoDesconto;

  public activeTab: string;
  public codigoTipo: ETipoAbonoDesconto;
  public abonoDesconto: Partial<IJsonABDES>;

  private _modelBackUp: IJsonConfigRHTransfBancarias;
  private _dxDataGridInstance: dxDataGrid<IJsonConfigRHTrfbaTicket | IJsonConfigRHExclusoesTransf, IJsonConfigRHTrfbaTicket | IJsonConfigRHExclusoesTransf>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codBanco', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.codBanco'},
        {dataField: 'codOpTrfBa', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.codOpTrfBa'},
        {dataField: 'nClienteBanco', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.nClienteBanco'},
        {dataField: 'nCartaoTrfBaIBAN', dataType: 'boolean', caption: 'rhtransferenciasbancarias.fields.nCartaoTrfBaIBAN'},
        {dataField: 'cartaoDifBanco', dataType: 'boolean', caption: 'rhtransferenciasbancarias.fields.cartaoDifBanco'},
        {dataField: 'configTrfBaTicket', dataType: 'boolean', caption: 'rhtransferenciasbancarias.fields.configTrfBaTicket'},
        {dataField: 'tipoFichTrf', dataType: 'number', caption: 'rhtransferenciasbancarias.fields.tipoFichTrf'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      export: {filename: 'rhtransferenciasbancarias.tab.listabancos'},
      headerFilter: {visible: false},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('global.btn.addLine'),
              onClick: () => this._adicionarLinha()
            } satisfies Properties
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionExcluded = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipo', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.tipo', customizeText: this._fnCustomizeTextTipo},
        {dataField: 'codAbDesc', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.abonodesconto'},
        {dataField: 'designaBreve', dataType: 'string', caption: 'rhtransferenciasbancarias.fields.designacao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnExcluded'}
      ],
      export: {filename: 'rhtransferenciasbancarias.tab.exclusaoabonos'},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.activeTab = TAB_ID_TICKETS;
    this.codigoTipo = ETipoAbonoDesconto.Abono;
    this.tipoAbonoDesconto = ETipoAbonoDesconto;
    this.abonoDesconto = this._emptyModel();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.visible = true;
    this.btnCancel.visible = true;
    this.btnSave.click = this._updateConfigs.bind(this);
    this.btnCancel.click = this._resetConfigs.bind(this);
    this.btnSave.disabled = true;
    this.btnCancel.disabled = true;
    this._modelBackUp = copy(this.model);
    this.dataGridDefinition.dataSource = new CustomStore({load: () => this._loadTrfBaTicket()});
    this.dataGridDefinitionExcluded.dataSource = new CustomStore({load: () => this._loadExclusoesTransf()});
  }

  public adicionarAbonoDesconto(): Promise<void> {
    if ((this.codigoTipo !== ETipoAbonoDesconto.Abono && this.codigoTipo !== ETipoAbonoDesconto.Desconto) || isEmpty(this.abonoDesconto.codAbDesc)) {
      this._plAlertService.error('rhtransferenciasbancarias.message.abdescempty');
      return Promise.resolve();
    }
    if (this.model.exclusoesTransferencias.findIndex((elem: IJsonConfigRHExclusoesTransf) => elem.tipo === this.codigoTipo && elem.codAbDesc === this.abonoDesconto.codAbDesc) !== -1) {
      this._plAlertService.error('rhtransferenciasbancarias.message.abdescExclusionRepeated');
      return Promise.resolve();
    }
    this.model.exclusoesTransferencias.push({
      tipo: this.codigoTipo,
      codAbDesc: this.abonoDesconto.codAbDesc,
      designaBreve: this.abonoDesconto.designacaoBreve,
      estado: EConfigRHEstadoItem.Added
    });
    this._checkActionsDisabled();
    this.abonoDesconto = this._emptyModel();
    return this._dxDataGridInstance.refresh();
  }

  public eliminarAbonosDescontos(index: number): void {
    this.model.exclusoesTransferencias.splice(index, 1);
    this._checkActionsDisabled();
    this._dxDataGridInstance.refresh();
  }

  public formatoTicketRefeicaoChanged(value: boolean): void {
    this.model.formatoSEPATicketRefeicao = value;
    this._checkActionsDisabled();
  }

  public codigoTipoChanged(value: number): void {
    this.codigoTipo = value;
    this.abonoDesconto = this._emptyModel();
  }

  public formatoPSChanged(value: boolean): void {
    this.model.ficheiroDeTransferenciasNoFormatoPS2 = value;
    this._checkActionsDisabled();
  }

  public separacaoNibsChanged(value: boolean): void {
    this.model.exportacaoTXTSeparacaoNibs = value;
    this._checkActionsDisabled();
  }

  public dgtFormatoSepaChanged(value: boolean): void {
    this.model.ficheiroDeTransferenciaDGTNoFormatoSEPA = value;
    this._checkActionsDisabled();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonConfigRHTrfbaTicket | IJsonConfigRHExclusoesTransf, IJsonConfigRHTrfbaTicket | IJsonConfigRHExclusoesTransf>): void {
    this._dxDataGridInstance = component;
  }

  public readonly fnEditarLinha = (item: IJsonConfigRHTrfbaTicket, index: number) => (): Promise<void> => this._editarlinha(item, index);

  private _emptyModel(): IJsonABDES {
    return {
      abonoDesc: 0,
      abonoDescDesativado: false,
      calcCustHora: false,
      codAbDesc: '',
      codCGASit: '',
      codRubrOrcAbon: '',
      codRubrOrcEnc: '',
      codSSCol: '',
      coefHExtra: 0,
      colMapaCX: 0,
      colQuadroP: 0,
      contaEncCRE: '',
      contaEncDEB: '',
      contaMovCred: '',
      contaMovDeb: '',
      designacaoBreve: '',
      designacaoCompleta: '',
      diaHoraExtra: 0,
      entiSuporte: 0,
      grupoAbono: 0,
      grupoAbonoNome: '',
      grupoDesconto: 0,
      grupoDescontoNome: '',
      horaDiaValor: 0,
      mapaSeguro: 0,
      mapaSindic: 0,
      nCodABDESC: '',
      nHorasDescontoProporcional: 0,
      nHorasDiaProvDesc: 0,
      naoImpDecIRS114: 0,
      percInciSegSocial: 0,
      percLimValorHExtra: 0,
      perctDescSeguro: 0,
      procFerias: 0,
      procNatal: 0,
      tipoArredondamento: 0,
      tipoDescontoProporcional: 0,
      tipoHoraExtr: 0,
      tipoRendimento: '',
      tipoValorUnitRecolha: 0,
      usaSPReducaoRemuneratoria: false,
      valorFixo: 0,
      valorLimIncidencia: 0,
      perfilSistema: 0
    };
  }

  private _editarlinha(item: IJsonConfigRHTrfbaTicket, index: number): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(TransferenciaBancariaModalComponent);
    const componentInstance: TransferenciaBancariaModalComponent = modalInstance.componentInstance;
    componentInstance.transferencia = copy(item);
    componentInstance.edit = true;
    return modalInstance.result.then((ticketlist: IJsonConfigRHTrfbaTicket) => {
      this.model.listaTrfBaTicket[index] = ticketlist;
      this._checkActionsDisabled();
      this._dxDataGridInstance.refresh();
    });
  }

  private _adicionarLinha(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(TransferenciaBancariaModalComponent);
    const componentInstance: TransferenciaBancariaModalComponent = modalInstance.componentInstance;
    componentInstance.edit = false;
    return modalInstance.result.then((ticketlistnew: IJsonConfigRHTrfbaTicket) => {
      this.model.listaTrfBaTicket.push(ticketlistnew);
      this._checkActionsDisabled();
      this._dxDataGridInstance.refresh();
    });
  }

  private _updateConfigs(): Promise<void> {
    return this._rhConfiguracoesService.updateConfigTransfBancarias(this.model).then((response: HttpResponse<IJsonConfigRHTransfBancarias>) => {
      this.model = response.body;
      this._modelBackUp = copy(this.model);
      this.btnSave.disabled = true;
      this.btnCancel.disabled = true;
      this._plAlertService.success('rhtransferenciasbancarias.message.savedSuccess');
    });
  }

  private _resetConfigs(): void {
    this.model = copy<IJsonConfigRHTransfBancarias>(this._modelBackUp);
    this.btnSave.disabled = true;
    this.btnCancel.disabled = true;
    if (this.activeTab === TAB_ID_TICKETS) {
      this.dataGridDefinition.dataSource = new ArrayStore({data: this.model.listaTrfBaTicket});
    } else if (this.activeTab === TAB_ID_EXCLUSOES) {
      this.dataGridDefinitionExcluded.dataSource = new ArrayStore({data: this.model.exclusoesTransferencias});
    }
  }

  private _checkActionsDisabled(): void {
    const doesEqual: boolean = isEqual(this.model, this._modelBackUp);
    this.btnSave.disabled = doesEqual;
    this.btnCancel.disabled = doesEqual;
  }

  private _loadTrfBaTicket(): Array<IJsonConfigRHTrfbaTicket> {
    return this.model.listaTrfBaTicket;
  }

  private _loadExclusoesTransf(): Array<IJsonConfigRHExclusoesTransf> {
    return this.model.exclusoesTransferencias;
  }

  private _customizeTextTipo(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row') {
      const tipo: ETipoAbonoDesconto = !cellInfo.value ? ETipoAbonoDesconto.Abono : <ETipoAbonoDesconto>cellInfo.value;
      return this._translateService.instant(DATA_SOURCE_TIPO_ABONODESC.data[tipo]?.name);
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeTextTipo: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextTipo(cellInfo);
}
