<div class="mascarasanalitica">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="formDefinition" [properties]="{readonly: type === 'detail'}"></pl-form>

      <pl-tabs>
        <pl-tab id="definition" caption="mascarasanalitica.tabs.definition">
          <div *plTabContent>
            <div class="row" cdkDropListGroup>
              <div class="col-sm-7">
                <div class="info">
                  <h5 [translate]="'mascarasanalitica.tabs.definitionTitle'"></h5>
                </div>

                <div class="row list-header">
                  <div class="col-sm-1"></div>
                  <div class="col-sm-5">
                    <span [translate]="'mascarasanalitica.table.description'" [translateParams]="{title: 'mascarasanalitica.table.description'}"></span>
                  </div>
                  <div class="col-sm-2">
                    <span [translate]="'mascarasanalitica.table.char'" [translateParams]="{title: 'mascarasanalitica.table.char'}"></span>
                  </div>
                  <div class="col-sm-3">
                    <span [translate]="'mascarasanalitica.table.subTotal'" [translateParams]="{title: 'mascarasanalitica.table.subTotal'}"></span>
                  </div>
                  <div class="col-sm-1"></div>
                </div>

                <div
                  class="list-tree-model"
                  cdkDropList
                  cdkDropListOrientation="vertical"
                  [cdkDropListData]="definitionModel"
                  (cdkDropListDropped)="dropped($event)"
                  [class.list-tree-model-empty]="!definitionModel.length">
                  <div class="row list-tree-body" *ngFor="let node of definitionModel" cdkDrag cdkDragHandle [cdkDragData]="node" [cdkDragDisabled]="!evaluateDelete(node)">
                    <div class="col-sm-1">
                      <i class="fa fa-arrows fa-fw" *ngIf="evaluateDelete(node)"></i>
                    </div>
                    <div class="col-sm-5">
                      <span [translate]="node.name" [translateParams]="{title: 'mascarasanalitica.table.description'}"></span>
                    </div>
                    <div class="col-sm-2">
                      <span>{{ node.char }}</span>
                    </div>
                    <div class="col-sm-3">
                      <pl-edit
                        type="boolean"
                        [model]="node.isIndice"
                        (modelChange)="node.isIndice = $event; changeSubTotal()"
                        *ngIf="evaluateDelete(node, true)"
                        [properties]="{disabled: !evaluateDelete(node)}">
                      </pl-edit>
                    </div>
                    <div class="col-sm-1">
                      <button type="button" class="btn btn-danger btn-xs" data-nodrag *ngIf="evaluateDelete(node)" (click)="remove(node)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="d-block d-md-none-block" />

              <div class="col-sm-5" *ngIf="callback.isEditing()">
                <div class="info">
                  <h5 [translate]="'mascarasanalitica.tabs.definitionNamesTitle'"></h5>
                </div>

                <div class="row list-header">
                  <div class="col-sm-1"></div>
                  <div class="col-sm-5">
                    <span [translate]="'mascarasanalitica.table.description'"></span>
                  </div>
                  <div class="col-sm-2">
                    <span [translate]="'mascarasanalitica.table.char'"></span>
                  </div>
                  <div class="col-sm-4"></div>
                </div>

                <div class="list-tree-model" cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="listDefinition">
                  <div class="row list-tree-body" *ngFor="let node of listDefinition" cdkDrag [cdkDragData]="node" cdkDragHandle>
                    <div class="col-sm-1">
                      <button type="button" class="btn btn-success btn-xs" (click)="addDefinition(node)">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div class="col-sm-5">
                      <span [translate]="node.name" [translateParams]="{title: 'mascarasanalitica.table.char'}"></span>
                    </div>
                    <div class="col-sm-2">
                      <span>{{ node.char }}</span>
                    </div>
                    <div class="col-sm-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </pl-tab>

        <pl-tab id="names" caption="mascarasanalitica.tabs.definitionNames">
          <div *plTabContent>
            <div class="row" cdkDropListGroup>
              <div class="col-sm-7">
                <div class="info">
                  <h5 [translate]="'mascarasanalitica.tabs.nameTitle'"></h5>
                </div>

                <div class="row list-header">
                  <div class="col-sm-1"></div>
                  <div class="col-sm-5">
                    <span [translate]="'mascarasanalitica.table.description'"></span>
                  </div>
                  <div class="col-sm-4">
                    <span [translate]="'mascarasanalitica.table.type'"></span>
                  </div>
                  <div class="col-sm-1"></div>
                  <div class="col-sm-1"></div>
                </div>

                <div
                  class="list-tree-model"
                  cdkDropList
                  cdkDropListOrientation="vertical"
                  [class.list-tree-model-empty]="!nameModel.length"
                  [cdkDropListData]="nameModel"
                  (cdkDropListDropped)="droppedListNames($event)">
                  <div class="row list-tree-body" *ngFor="let node of nameModel; index as i" cdkDrag [cdkDragData]="node" cdkDragHandle>
                    <div class="col-sm-1">
                      <i class="fa fa-arrows" *ngIf="callback.isEditing()"></i>
                    </div>
                    <div class="col-sm-5">
                      <span [translate]="node.content" [translateParams]="{title: 'mascarasanalitica.table.description'}"></span>
                    </div>
                    <div class="col-sm-4">
                      <span [translate]="node.type" [translateParams]="{title: 'mascarasanalitica.table.type'}"></span>
                    </div>
                    <div class="col-sm-1">
                      <button type="button" class="btn btn-primary btn-xs" *ngIf="callback.isEditing() && node.tipoCampo === -1" (click)="changeText(node)">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
                    <div class="col-sm-1">
                      <button type="button" class="btn btn-danger btn-xs" *ngIf="callback.isEditing()" (click)="removeName(i)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="d-block d-md-none-block" />

              <div class="col-sm-5" *ngIf="callback.isEditing()">
                <div class="info">
                  <h5 [translate]="'mascarasanalitica.tabs.nameItemsTitle'"></h5>
                </div>

                <div class="row list-header">
                  <div class="col-sm-1"></div>
                  <div class="col-sm-11">
                    <span [translate]="'mascarasanalitica.table.description'"></span>
                  </div>
                </div>

                <div class="list-tree-model" cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="nameModel" (cdkDropListDropped)="droppedListNames($event)">
                  <div class="row list-tree-body" *ngFor="let node of listNames" cdkDrag [cdkDragData]="node" cdkDragHandle>
                    <div class="col-sm-1">
                      <button type="button" class="btn btn-success btn-xs" (click)="addListName(node)">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                    <div class="col-sm-11">
                      <span [translate]="node.content" [translateParams]="{title: 'mascarasanalitica.table.description'}"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </entity-detail>
</div>
