import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {ETipoEscolhaRendHabitPerm, ETipoOrdenacao, IJsonRendHabitPerm} from './jsonRendHabitPerm.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IRendHabitPerm} from './rendHabitPerm.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RendHabitPermService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rendhabitperm`;
  }

  public getRendHabitPerm(estado: ETipoEscolhaRendHabitPerm, tipoOrdenacao: ETipoOrdenacao): TServiceResponse<Array<IJsonRendHabitPerm>> {
    return this._apiService.get<Array<IJsonRendHabitPerm>>({
      url: this._path,
      params: {
        estado: estado,
        tipoordenacao: tipoOrdenacao
      }
    });
  }

  public postRendHabitPerm(estado: ETipoEscolhaRendHabitPerm, tipoOrdenacao: ETipoOrdenacao, rendHabitPerm: IRendHabitPerm): TServiceResponse<Array<IJsonRendHabitPerm>> {
    return this._apiService.post<Array<IJsonRendHabitPerm>, IJsonRendHabitPerm>({
      url: this._path,
      body: rendHabitPerm,
      params: {
        estado: estado,
        tipoordenacao: tipoOrdenacao
      }
    });
  }

  public deleteRendHabitPerm(rendHabitPermId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: this._path, params: {rendhabitpermid: rendHabitPermId}});
  }
}
