import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPeriodo, IJsonPeriodoDatasPeriodoTributacao} from './jsonPeriodo.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {TDate} from '../../../common/dates';

export interface IPeriodosEntityService extends IEntityService<IJsonPeriodo> {
  obterDatasPeriodoTributacao(nPeriodo: string): TServiceResponse<IJsonPeriodoDatasPeriodoTributacao>;

  obterProximoPeriodoContab(nPeriodo: string): TServiceResponse<IJsonPeriodo>;

  obterAnoDosPeriodos(dePeriodo: string, atePeriodo: string): TServiceResponse<number>;

  obterPeriodoFromDate(date: TDate): TServiceResponse<IJsonPeriodo>;
}

export interface IPeriodosEntity extends IEntityDefinition<IJsonPeriodo, IPeriodosEntityService> {}

export const ENTITY_NAME_PERIODOS = 'periodos';
