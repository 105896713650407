<div class="bancos-extrato-module-authorizations-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'bancosextrato.authorizationsmodal.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="entity-detail-form">
      <pl-group [properties]="{orientation: 'horizontal'}">
        <label><span [translate]="'bancosextrato.authorizationsmodal.fields.externalId'"></span>:</label>
        <edit>
          <div class="form-control-align">{{ externalId }}</div>
        </edit>
      </pl-group>
    </div>

    <dx-data-grid [cgDxDataGrid]="dataGrid" [dataSource]="dataGridSource" (onInitialized)="onDataGridInitialized($event)" (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
      <div *dxTemplate="let row of 'dataGridTemplateActions'">
        <button
          type="button"
          class="btn btn-xs btn-danger action-delete-consent"
          [click]="fnDeleteConsent(row.data.credentialsId)"
          plPromise
          [attr.title]="'bancosextrato.authorizationsmodal.text.deleteConsent' | translate">
          <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
