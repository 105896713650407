import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonCRABD} from '../../jsonCrAbD.entity.interface';
import {IJsonCRNEG} from '../../../crneg/jsonCRNEG.entity.interface';

@Component({
  selector: 'excecoescrabd-modal',
  templateUrl: './excecoesCrabd.modal.component.html'
})
export class ExcecoesCrAbDModalComponent extends CGModalComponent<Array<IJsonCRNEG>> {
  @Input() public excecoesList: Array<IJsonCRNEG>;
  @Input() public cruzamento: IJsonCRABD;
  @Input() public allDGEMPS: Array<IJsonCRNEG>;

  public readonly dgempsTemplate: string = '{{nCodEmp}} - {{nomeEmp}}';

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): void {
    super.close(this.excecoesList);
  }
}
