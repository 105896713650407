import {IJsonDocOCRCab} from './jsonContabDigital.interface';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../api/api.service.interface';

export enum EContabilidadeDigitalDocumentCommandType {
  ObterDadosEFatura = 'ObterDadosEFatura',
  AtribuirTipoDocumento = 'AtribuirTipoDocumento',
  UnirDocs = 'UnirDocs',
  SepararDoc = 'SepararDoc',
  CopiarDoc = 'CopiarDoc',
  RemovePages = 'RemovePages',
  Rotate = 'Rotate',
  MoverParaDocsNaoClassificados = 'MoverParaDocsNaoClassificados'
}

export enum EContabDigitalDocumentRotateTo {
  LEFT = 'left',
  RIGHT = 'right'
}

export type TCommandContabDigitalDocumentResponse<T extends object> = TServiceResponse<T>;

export type TCommandContabDigitalDocumentResponseJsonDocOCRCab = TCommandContabDigitalDocumentResponse<IJsonDocOCRCab>;

export interface IContabDigitalGetFolderStructureParams {
  loadFiles?: boolean;
  checkHasSubFiles?: boolean;
  anoEmCursoIRC?: boolean;
}

export interface IContabDigitalGetFolderStructureUpwardsParams extends IContabDigitalGetFolderStructureParams {
  includeSelf?: boolean;
}

export interface IContabDigitalGDocViewerRecolhaDoc {
  extPocCabID: string;
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  dataDoc: TDate;
}

export interface IContabDigitalGDocViewerRecolhaSearchParams {
  byDataOrValor?: boolean;
  byDataValor?: boolean;
  byValor?: boolean;
}

export interface IContabilidadeDigitalDocumentosDigitaisParams {
  nif: string;
  nDocExterno: string;
  showDocsClassificados: boolean;
}
