import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL} from './tipoResumoMensal.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_RESUMO_MENSAL: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoResumoMensal.data.naoUsa'},
    {value: 1, name: 'tipoResumoMensal.data.aPartirDoUltimoDia'},
    {value: 2, name: 'tipoResumoMensal.data.aPartirDoUltimoDiaUtil'},
    {value: 3, name: 'tipoResumoMensal.data.aPartirDoPrimeiroDiaDoMesSeguinte'},
    {value: 4, name: 'tipoResumoMensal.data.aPartirDoPrimeiroDiaUtilDoMesSeguinte'}
  ]
});
