import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IJsonProcSegSocialProcessExistentesParams,
  IJsonProcSeguradoraFolhaFerias,
  IJsonProcSeguradoraFolhaFeriasOuErros,
  IJsonSegSocialProcessExistentes,
  IJsonSeguradoraFolhaFeriasMPCX
} from './jsonProcSeguradoraFolhaFerias.module.interface';
import {HttpResponse} from '@angular/common/http';
import {ISeguradoraFFMPCXProcSegSocial} from './procSeguradoraFolhaFerias.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IEmailSendResult} from '../../../../common/interfaces/email.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProcSeguradoraFolhaFeriasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/procseguradorafolhaferias`;
  }

  public postProcessExistence(params: IJsonProcSegSocialProcessExistentesParams): Promise<Array<IJsonSegSocialProcessExistentes>> {
    return new Promise<Array<IJsonSegSocialProcessExistentes>>((resolve, reject) => {
      this._apiService
        .post<Array<IJsonSegSocialProcessExistentes>, IJsonProcSegSocialProcessExistentesParams>({
          url: `${this._path}/procexistentes`,
          body: params
        })
        .then((response: HttpResponse<Array<IJsonSegSocialProcessExistentes>>) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public postInfoSeguradoraFolhaFerias(procseguradorafolhaferias: IJsonProcSeguradoraFolhaFerias): Promise<Array<IJsonSeguradoraFolhaFeriasMPCX>> {
    return new Promise<Array<IJsonSeguradoraFolhaFeriasMPCX>>((resolve, reject) => {
      this._apiService
        .post<Array<IJsonSeguradoraFolhaFeriasMPCX>, IJsonProcSeguradoraFolhaFerias>({
          url: `${this._path}/infoseguradorafolhaferias`,
          body: procseguradorafolhaferias
        })
        .then((response: HttpResponse<Array<IJsonSeguradoraFolhaFeriasMPCX>>) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public postEnviarFicheiroSeguradoraFolhaFeriasPorEmail(procseguradorafolhaferias: IJsonProcSeguradoraFolhaFerias): TServiceResponse<IEmailSendResult> {
    return this._apiService.post<IEmailSendResult, IJsonProcSeguradoraFolhaFerias>({
      url: `${this._path}/enviarficheiroseguradorafolhaferiasporemail`,
      body: procseguradorafolhaferias
    });
  }

  public postProceSeguradoraFFerias(processexistenteslist: Array<IJsonSegSocialProcessExistentes>, processaNovamente: boolean): Promise<IJsonProcSeguradoraFolhaFeriasOuErros> {
    return new Promise<IJsonProcSeguradoraFolhaFeriasOuErros>((resolve, reject) => {
      this._apiService
        .post<IJsonProcSeguradoraFolhaFeriasOuErros, Array<IJsonSegSocialProcessExistentes>>({
          url: `${this._path}/proceseguradorafferias`,
          body: processexistenteslist,
          params: {
            processanovamente: processaNovamente
          }
        })
        .then((response: HttpResponse<IJsonProcSeguradoraFolhaFeriasOuErros>) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public postGuardarInfoSeguradoraFolhaFerias(infofichff: ISeguradoraFFMPCXProcSegSocial): TServiceResponse<void> {
    return this._apiService.post<void, ISeguradoraFFMPCXProcSegSocial>({
      url: `${this._path}/guardarinfo`,
      body: infofichff
    });
  }

  public postGerarFicheiroSeguradoraFolhaFerias(procseguradorafolhaferias: Array<IJsonProcSeguradoraFolhaFerias>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<IJsonProcSeguradoraFolhaFerias>>({
      url: `${this._path}/gerarficheiroseguradorafolhaferias`,
      responseType: 'blob',
      body: procseguradorafolhaferias
    });
  }

  public postListagemSeguradoraFolhaFerias(procseguradorafolhaferias: Array<IJsonProcSeguradoraFolhaFerias>, reportName: string): TServiceResponse<ArrayBuffer> {
    return this._apiService.post<ArrayBuffer, Array<IJsonProcSeguradoraFolhaFerias>>({
      url: `${this._path}/report`,
      body: procseguradorafolhaferias,
      responseType: 'arraybuffer',
      params: {
        reportName: reportName
      }
    });
  }
}
