import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonGestoComunicacoesConfig} from '../../jsonGestaoComunicacoesTopico.module.interface';
import {PlAlertService} from 'pl-comps-angular';
import {GestaoComunicacoesTopicoService} from '../../gestaoComunicacoesTopico.module.service';

@Component({
  selector: 'gestao-comunicacoes-configuracao-modal',
  templateUrl: './gestaoComunicacoes.configuracao.modal.component.html'
})
export class GestaoComunicacoesConfiguracaoModalComponent extends CGModalComponent<void> {
  @Input() public configEmpresas: IJsonGestoComunicacoesConfig;

  public readonly templateEmpresa: string;
  public readonly templateUtilizador: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.templateEmpresa = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.templateUtilizador = '{{nUtilizador}} - {{nomeUtilizador}}';
  }

  public async close(): Promise<void> {
    await this._gestaoComunicacoesTopicoService.saveConfig(this.configEmpresas);
    this._plAlertService.success('gestaoComunicacoes.modal.config.mensagens.success');
    super.close();
  }
}
