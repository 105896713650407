import moment from 'moment';
import {Subscription} from 'rxjs';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDelphiNumberTypes, fromJson, isDefinedNotNull, isEmpty, toJson} from 'pl-comps-angular';
import {IJsonDGEMP, IJsonEstadosProcessamento} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IPCARHSalariosServiceRefreshedEvent} from '../../pcaRHSalarios.module.service.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PcaRHSalariosService} from '../../pcaRHSalarios.module.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {RecibosVencimentosEmailModalComponent} from '../modals/email/recibosVencimentos.email.modal.component';
import {IRecibosVencimento, IRecibosVencimentoParams, IReportsComSuporteGraficoStorage} from '../recibosVencimentos.module.interface';
import {ProtecaoPasswordModalComponent} from '../modals/protecaopassword/protecaoPassword.modal.component';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {IReportInstance} from '../../../../../components/reports/input/reports.input.component.interface';
import {RHConfiguracoesService} from '../../../../rhconfiguracoes/rhConfiguracoes.module.service';
import {IJsonProcessamentoSalariosConfig} from '../../../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {CGLocalStorageGroupService} from '../../../../../services/storage/localstoragegroup.service';
import {SCHEMA_STRING} from '../../../../../../common/schemas';
import {EGroupName} from '../../../../../../config/constants';

const DEFAULT_REPORT_NAME = 'RECIBA5.FR3';
const STORAGE_KEY = 'reportsComSuporteGrafico';
const MAX_STORAGE_DAYS = 30;

@Component({
  selector: 'module-recibosvencimentos',
  templateUrl: './recibosVencimentos.module.component.html'
})
export class RecibosVencimentosModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public subscription: Subscription;
  public state: IJsonEstadosProcessamento;
  public todosColaboradores: boolean;
  public mostraGrafico: boolean;
  public dgemp: IJsonDGEMP;
  public nProcessamento: number;
  public pdfUrl: string;
  public reportModel: IJsonReport;
  public showMostraGrafico: boolean;

  private readonly _params: IRecibosVencimentoParams;
  private _reportInstance: IReportInstance;
  private _processamentosalariosconfig: IJsonProcessamentoSalariosConfig;
  private _reportsComSuporte: Array<IJsonReport>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _pcaRHSalariosService: PcaRHSalariosService,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _cgModalService: CGModalService,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService
  ) {
    super(_injector);
    this._reportsComSuporte = [];
    this.showMostraGrafico = false;
    this._params = <IRecibosVencimentoParams>this._transition.params();
    this.state = {
      gravado: false,
      integradoContabilidade: false,
      processadoSegSocial: false,
      reciboImpresso: false
    };
    this.todosColaboradores = true;
    this.mostraGrafico = false;
    this.dgemp = undefined;
    this.nProcessamento = undefined;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'pcarhsendemail',
      order: 999,
      type: 'button',
      class: 'btn-primary',
      visible: true,
      caption: 'global.btn.email',
      iconLeft: '<i class="fa fa-fw fa-envelope"></i>',
      click: () => this._sendEmail()
    });
    this._init();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public changedTodos(value: boolean): void {
    this.todosColaboradores = value;
    if (this.todosColaboradores) {
      this._refresh();
    }
  }

  public changedMostraGrafico(value: boolean): void {
    this.mostraGrafico = value;
    this._processamentosalariosconfig.processamentoSalariosReciboVenc.reciboVencimentosMostraGraficoCustos = this.mostraGrafico;
    this._processamentosalariosconfig.processamentoSalariosReciboVenc.reciboVencimentosMostraGraficoCustosAcumuladosAnuais = this.mostraGrafico;
    this._rhConfiguracoesService.updateProcessamentoSalarios(this._processamentosalariosconfig).then(() => {
      this._refresh(true);
    });
  }

  public changedDGEMP(value: IJsonDGEMP): void {
    this.dgemp = value;
    this._refresh();
    this._portalClientAccountService.getEstadosProcessamentoSalario(this.dgemp.codEmp, this.nProcessamento).then((response: HttpResponse<IJsonEstadosProcessamento>) => {
      this.state = response.body;
    });
  }

  public changedReport(report: IJsonReport): void {
    this.reportModel = report;
    this._checkVisibilityMostraGrafico();
    this._refresh();
  }

  public isEmbed(): boolean {
    return this._params.codEmp !== -1 && this._params.nProcessamento !== -1;
  }

  private _refresh(force: boolean = false): void {
    const deEmp: number = this.isEmbed() ? this._params.codEmp : this.todosColaboradores ? 0 : this.dgemp.codEmp;
    const ateEmp: number = this.isEmbed() ? this._params.codEmp : this.todosColaboradores ? EDelphiNumberTypes.MaxSmallInt : this.dgemp.codEmp;
    this._portalClientAccountService.getRecibosVencimentosUrl(deEmp, ateEmp, this.nProcessamento, this.reportModel.name).subscribe((url: string) => {
      const d = new Date();
      this.pdfUrl = !force ? url : `${url}&cache=${d.getTime()}`;
    });
  }

  private _loadReport(): Promise<void> {
    this._reportInstance = this._reportsRegistryService.get(EReport.RecibosSal);
    return this._reportInstance.query().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        let index: number;
        index = reports.findIndex((report: IJsonReport) => report.name === this._configService.configurations.pca.reportrecibosal);
        if (index !== -1) {
          this.reportModel = reports[index];
        } else {
          index = reports.findIndex((report: IJsonReport) => report.name === DEFAULT_REPORT_NAME);
          if (index !== -1) {
            this.reportModel = reports[index];
          } else {
            this.reportModel = reports[0];
          }
        }
      }
    });
  }

  private _sendEmail(): Promise<void> {
    const self: RecibosVencimentosModuleComponent = this;
    return this._cgModalService.show(ProtecaoPasswordModalComponent).then(() => {
      const modalInstance = self._cgModalService.showVanilla(RecibosVencimentosEmailModalComponent);
      const componentInstance: RecibosVencimentosEmailModalComponent = modalInstance.componentInstance;
      componentInstance.dgemp = self.dgemp;
      componentInstance.todosColaboradores = self.todosColaboradores;
      componentInstance.reportName = self.reportModel.name;
      componentInstance.nProcessamento = self.nProcessamento;
      return modalInstance.result.then((result: IRecibosVencimento) => {
        self.changedTodos(result.todosColaboradores);
        if (self.todosColaboradores) {
          self.dgemp = result.dgemp;
        } else {
          self.changedDGEMP(result.dgemp);
        }
      });
    });
  }

  private _init(): void {
    this._cgLocalStorageGroupService.getItem<string>(STORAGE_KEY, SCHEMA_STRING, EGroupName.RECURSOSHUMANOS).subscribe((value: string) => {
      let p = Promise.resolve();
      if (!isEmpty(value)) {
        const storageValue: IReportsComSuporteGraficoStorage = fromJson(value);
        if (moment(storageValue.date).diff(moment(), 'days') >= MAX_STORAGE_DAYS) {
          p = this._updateReportsComSuporteGrafico();
        } else {
          this._reportsComSuporte = storageValue.list;
        }
      } else {
        p = this._updateReportsComSuporteGrafico();
      }
      this._rhConfiguracoesService.getProcessamentoSalarios().then((config) => {
        this._processamentosalariosconfig = config.body;
        this.mostraGrafico = this._processamentosalariosconfig.processamentoSalariosReciboVenc.reciboVencimentosMostraGraficoCustos;
        this._loadReport().then(() => {
          p.then(() => {
            if (!this.isEmbed()) {
              this.subscription = this._pcaRHSalariosService.onRefresh().subscribe((event: IPCARHSalariosServiceRefreshedEvent) => {
                this.nProcessamento = this._params.nProcessamento !== -1 ? this._params.nProcessamento : event.processamento.nProcessamento;
                this._checkVisibilityMostraGrafico();
                this._refresh();
              });
              this._pcaRHSalariosService.init(this.toolbar).then(() => {
                if (this._params.nProcessamento > -1) {
                  this._pcaRHSalariosService.selectProcessamento(this._params.nProcessamento);
                }
              });
            } else {
              this.nProcessamento = this._params.nProcessamento;
              this._checkVisibilityMostraGrafico();
              this._refresh();
            }
          });
        });
      });
    });
  }

  private _updateReportsComSuporteGrafico(): Promise<void> {
    return this._portalClientAccountService.getReportsSupportGrafico().then((response) => {
      this._reportsComSuporte = response.body;
      const storageValue: IReportsComSuporteGraficoStorage = {
        list: this._reportsComSuporte,
        date: moment().toJSON()
      };
      this._cgLocalStorageGroupService.setItem(STORAGE_KEY, toJson(storageValue), SCHEMA_STRING, EGroupName.RECURSOSHUMANOS).subscribe();
    });
  }

  private _checkVisibilityMostraGrafico(): void {
    this.showMostraGrafico = isDefinedNotNull(this._reportsComSuporte.find((item) => item.name.toLowerCase() === this.reportModel.name.toLowerCase()));
  }
}
