import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_DMRF_RUBRICA, EDMRFRubrica} from './dmrfRubrica.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_DMRF_RUBRICA: IDataSource<EDMRFRubrica> = deepFreeze({
  name: DATA_SOURCE_NAME_DMRF_RUBRICA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EDMRFRubrica.R000, name: ' '},
    {value: EDMRFRubrica.R102, name: 'dmrf.rubrica.102'},
    {value: EDMRFRubrica.R103, name: 'dmrf.rubrica.103'},
    {value: EDMRFRubrica.R104, name: 'dmrf.rubrica.104'},
    {value: EDMRFRubrica.R105, name: 'dmrf.rubrica.105'},
    {value: EDMRFRubrica.R106, name: 'dmrf.rubrica.106'},
    {value: EDMRFRubrica.R107, name: 'dmrf.rubrica.107'},
    {value: EDMRFRubrica.R108, name: 'dmrf.rubrica.108'},
    {value: EDMRFRubrica.R109, name: 'dmrf.rubrica.109'},
    {value: EDMRFRubrica.R110, name: 'dmrf.rubrica.110'},
    {value: EDMRFRubrica.R112, name: 'dmrf.rubrica.112'},
    {value: EDMRFRubrica.R114, name: 'dmrf.rubrica.114'},
    {value: EDMRFRubrica.R115, name: 'dmrf.rubrica.115'},
    {value: EDMRFRubrica.R116, name: 'dmrf.rubrica.116'},
    {value: EDMRFRubrica.R117, name: 'dmrf.rubrica.117'},
    {value: EDMRFRubrica.R118, name: 'dmrf.rubrica.118'},
    {value: EDMRFRubrica.R199, name: 'dmrf.rubrica.199'},
    {value: EDMRFRubrica.R201, name: 'dmrf.rubrica.201'},
    {value: EDMRFRubrica.R202, name: 'dmrf.rubrica.202'},
    {value: EDMRFRubrica.R203, name: 'dmrf.rubrica.203'},
    {value: EDMRFRubrica.R204, name: 'dmrf.rubrica.204'},
    {value: EDMRFRubrica.R205, name: 'dmrf.rubrica.205'},
    {value: EDMRFRubrica.R206, name: 'dmrf.rubrica.206'},
    {value: EDMRFRubrica.R207, name: 'dmrf.rubrica.207'},
    {value: EDMRFRubrica.R208, name: 'dmrf.rubrica.208'},
    {value: EDMRFRubrica.R209, name: 'dmrf.rubrica.209'},
    {value: EDMRFRubrica.R210, name: 'dmrf.rubrica.210'},
    {value: EDMRFRubrica.R211, name: 'dmrf.rubrica.211'},
    {value: EDMRFRubrica.R212, name: 'dmrf.rubrica.212'},
    {value: EDMRFRubrica.R213, name: 'dmrf.rubrica.213'},
    {value: EDMRFRubrica.R299, name: 'dmrf.rubrica.299'}
  ]
});
