<div class="avisos-cobranca-grid entity-detail-form">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <pl-alert type="warning" [closeable]="false" *ngIf="proceInterrompido">
    <span [innerHTML]="'avisoscobranca.messages.procseminterrompido' | translate"></span>
    <button type="button" class="btn btn-sm btn-primary ms-4" (click)="getAvisosCobranca(true)">
      <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'avisoscobranca.btn.pesqinterrompido'"></span>
    </button>
  </pl-alert>

  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'avisoscobranca.fields.nContaDe'"></label>
            <edit>
              <entity-autocomplete
                entity="clientes"
                attrName="nContaDe"
                [selectedKey]="filters.ncontade"
                (selectedKeyChange)="onNContaDeChanged($event)"
                output="key"
                plAutoFocus></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="clientes" attrName="nContaAte" [(selectedKey)]="filters.ncontaate" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'avisoscobranca.fields.dataVencimentoDe'"></label>
            <edit>
              <pl-edit type="date" attrName="dataVencDe" [(model)]="filters.datavencimentode"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" attrName="dataVencAte" [(model)]="filters.datavencimentoate"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'avisoscobranca.fields.dataDocDe'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDocDe" [(model)]="filters.datadocde"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDocAte" [(model)]="filters.datadocate"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="showVendedor">
            <pl-group>
              <label [translate]="'avisoscobranca.fields.nVendedorDe'"></label>
              <edit>
                <entity-autocomplete entity="vendedores" attrName="nVendedorDe" [(selectedKey)]="filters.nvendedorde" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="vendedores" attrName="nVendedorAte" [(selectedKey)]="filters.nvendedorate" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="showCondPagamento">
          <pl-group>
            <label [translate]="'avisoscobranca.fields.nCondPagamDe'"></label>
            <edit>
              <entity-autocomplete entity="condicoespagamento" attrName="nCondPagamDe" [(selectedKey)]="filters.ncondpagamde" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="condicoespagamento" attrName="nCondPagamAte" [(selectedKey)]="filters.ncondpagamate" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="avisoscobranca"
    [dataSource]="dataGridDefinition.dataSource"
    [(selectedRowKeys)]="selectedRowKeys"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onCellClick)="onCellClick($event)"
    (onContextMenuPreparing)="onContextMenuPreparing($event)"
    (onSelectionChanged)="onSelectionChanged($event)">
    <div *dxTemplate="let item of 'cellTemplateEmailDestino'">
      <div class="d-flex align-items-center">
        <span class="me-1">{{ item.value }}</span>
        <div class="ms-auto">
          <pl-tooltip [config]="{text: 'avisoscobranca.actions.alterarEmailPontual', tooltipClass: 'tooltip-warning', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-warning me-auto" (click)="openAlterarEmailPontual(item.data)" plPromise>
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </pl-tooltip>
        </div>
      </div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateActions'">
      <div class="d-flex align-items-center">
        <pl-tooltip [config]="{text: 'global.btn.pdf', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="openPDFAvisosCobranca([item.data.nConta], [item.data])" plPromise>
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          </button>
        </pl-tooltip>
        <div ngbDropdown container="body" style="cursor: pointer">
          <div ngbDropdownToggle><i class="pl-icon-more-vertical" aria-hidden="true"></i></div>
          <div ngbDropdownMenu>
            <button type="button" ngbDropdownItem (click)="openCliente(item.data.nConta)">
              <span [translate]="'avisoscobranca.actions.cliente'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openExtratoConta(item.data.nConta)">
              <span [translate]="'avisoscobranca.actions.extratoconta'"></span>
            </button>
            <div class="dropdown-divider"></div>
            <button type="button" ngbDropdownItem (click)="mudaEstadoNaoPodeFaturar(item.data.nConta, item.data.clifoNaoPodeFacturar)">
              <span [translate]="item.data.clifoNaoPodeFacturar ? 'avisoscobranca.actions.podefaturar' : 'avisoscobranca.actions.naopodefaturar'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openAlterarEmailPontual(item.data)">
              <span [translate]="'avisoscobranca.actions.alterarEmailPontual'"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let item of 'masterDetailTemplate'">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetail" [dataSource]="item.data.movAbList" (onContextMenuPreparing)="onContextMenuPreparing($event)" (onCellClick)="onCellClickMovAb($event)">
        <div *dxTemplate="let item of 'toolbarTemplate'">
          <span [translate]="'avisoscobranca.actions.movaberto'"></span>
        </div>
        <div *dxTemplate="let item of 'detailTemplateDocComercialContab'">
          <div *ngIf="!hasContabilidade && hasErp">
            <div *ngIf="item.data.faccbId && item.data.faccbId > 0">
              <documento-faturacao [doc]="item.data._theDocErp" [actionPdf]="fnGetPdf"></documento-faturacao>
            </div>
            <div *ngIf="!item.data.faccbId || item.data.faccbId <= 0">
              <pl-alert type="error" [closeable]="false"><span [translate]="'Não é possível apresentar o detalhe deste documento'"></span></pl-alert>
            </div>
          </div>

          <div *ngIf="hasContabilidade && !hasErp">
            <split-viewer-doccontab-digital
              [(docContabilidade)]="item.data._theDocContab"
              [contabDigitalDoc]="item.data._contabDigitalDoc"
              [showToolbar]="true"
              [maintenanceMode]="true"
              [readonlyDigital]="true"
              [highlightNLanc]="item.data.nLanc">
            </split-viewer-doccontab-digital>
          </div>

          <div *ngIf="hasContabilidade && hasErp">
            <pl-tabs>
              <pl-tab id="tabDocContabilidade" caption="Contabilidade">
                <div *plTabContent>
                  <split-viewer-doccontab-digital
                    [docContabilidade]="item.data._theDocContab"
                    [contabDigitalDoc]="item.data._contabDigitalDoc"
                    [maintenanceMode]="true"
                    [readonlyDigital]="true"
                    [highlightNLanc]="item.data.nLanc">
                  </split-viewer-doccontab-digital>
                </div>
              </pl-tab>
              <pl-tab id="tabDocComercial" caption="Comercial" *ngIf="item.data.faccbId && item.data.faccbId > 0">
                <div *plTabContent>
                  <documento-faturacao [doc]="item.data._theDocErp" [actionPdf]="fnGetPdf"></documento-faturacao>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </div>
      </dx-data-grid>
    </div>
  </dx-data-grid>

  <div class="bancos-extrato-module-legend">
    <cg-table-legend [legend]="tableLegend"></cg-table-legend>
  </div>
</div>
