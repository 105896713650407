import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {inject} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {API_PATH} from '../common/api';
import {APP_API_KEY, APP_USER_FEEDBACK_TOKEN} from '../common/app';
import {AuthService} from '../core/services/auth/auth.service';
import {HTTP_HEADER_X_AUTH_NEMPRESA, HTTP_HEADER_X_AUTH_TOKEN, HTTP_HEADER_X_AUTH_USER_FEEDBACK_TOKEN} from '../config/constants';

export function interceptorRequest(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (request.url.startsWith(API_PATH.host)) {
    if (request.method === 'DELETE') {
      const headers = request.headers.set('Content-Type', 'application/json');
      request = request.clone({headers: headers});
    }

    const authService: AuthService = inject(AuthService);
    const nEmpresa: string = authService.identityValue?.erp?.nEmpresa;
    if (nEmpresa) {
      request = request.clone({headers: request.headers.set(HTTP_HEADER_X_AUTH_NEMPRESA, nEmpresa)});
    }

    if (APP_API_KEY.value) {
      request = request.clone({headers: request.headers.set(HTTP_HEADER_X_AUTH_TOKEN, APP_API_KEY.value)});
    }
  }
  return next(request).pipe(
    tap((response: HttpEvent<unknown>) => {
      if (response.type === HttpEventType.Response || response.type === HttpEventType.ResponseHeader) {
        const userFeedbackToken: string = response.headers.get(HTTP_HEADER_X_AUTH_USER_FEEDBACK_TOKEN) || '';
        if (userFeedbackToken) {
          APP_USER_FEEDBACK_TOKEN.next(userFeedbackToken);
        }
      }
    })
  );
}
