import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_MOEDA_NORMAS_ISO, IMoedaNormasISO} from './moedaNormasIso.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_MOEDA_NORMAS_ISO: IDataSource<string, IMoedaNormasISO> = deepFreeze({
  name: DATA_SOURCE_NAME_MOEDA_NORMAS_ISO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{codigo}} - {{nomeMoeda}} ({{paisNomeMoeda}})',
    output: 'codigo',
    outputKey: 'codigo'
  },
  data: [
    {
      codigo: 'AED',
      paisNomeMoeda: 'United Arab Emirates (Dirham)',
      nomeMoeda: 'United Arab Emirates Dirham',
      extensoUnidade: 'Dirham',
      extensoNUnidades: 'Dirhams',
      extensoSubUnidade: 'Fils'
    },
    {
      codigo: 'AFN',
      paisNomeMoeda: 'Afghanistan (Afghani)',
      nomeMoeda: 'Afghan Afghani',
      extensoUnidade: 'Afghani',
      extensoNUnidades: 'Afghanis',
      extensoSubUnidade: 'Puls'
    },
    {codigo: 'ALL', paisNomeMoeda: 'Albania (Lek)', nomeMoeda: 'Albanian Lek', extensoUnidade: 'Lek', extensoNUnidades: 'Lekë', extensoSubUnidade: 'Qindarkës'},
    {codigo: 'AMD', paisNomeMoeda: 'Armenia (Dram)', nomeMoeda: 'Armenian Dram', extensoUnidade: 'Dram', extensoNUnidades: 'Drams', extensoSubUnidade: 'Lumas'},
    {
      codigo: 'ANG',
      paisNomeMoeda: 'Netherlands Antilles (Guilder)',
      nomeMoeda: 'Netherlands Antillean Guilder',
      extensoUnidade: 'Guilder',
      extensoNUnidades: 'Guilders',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'AOA', paisNomeMoeda: 'Angola (Kwanza)', nomeMoeda: 'Kwanza', extensoUnidade: 'Kwanza', extensoNUnidades: 'Kwanzas', extensoSubUnidade: 'Cêntimos'},
    {codigo: 'ARS', paisNomeMoeda: 'Argentina (Peso)', nomeMoeda: 'Peso Argentino', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centavos'},
    {
      codigo: 'AUD',
      paisNomeMoeda: 'Australia (Dollar)',
      nomeMoeda: 'Australian Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'AWG', paisNomeMoeda: 'Aruba (Guilder)', nomeMoeda: 'Aruban Guilder', extensoUnidade: 'Guilder', extensoNUnidades: 'Guilders', extensoSubUnidade: 'Cents'},
    {
      codigo: 'AZN',
      paisNomeMoeda: 'Azerbaijan (New Manat)',
      nomeMoeda: 'Azerbaijan New Manat',
      extensoUnidade: 'New Manat',
      extensoNUnidades: 'New Manats',
      extensoSubUnidade: 'qəpiks'
    },
    {
      codigo: 'BAM',
      paisNomeMoeda: 'Bosnia and Herzegovina (Convertible Marka)',
      nomeMoeda: 'Bosnia and Herzegovina convertible Mark',
      extensoUnidade: 'Convertible Mark',
      extensoNUnidades: 'Convertible Marks',
      extensoSubUnidade: 'Fenings'
    },
    {codigo: 'BBD', paisNomeMoeda: 'Barbados (Dollar)', nomeMoeda: 'Barbadian Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {codigo: 'BDT', paisNomeMoeda: 'Bangladesh (Taka)', nomeMoeda: 'Bangladeshi Taka', extensoUnidade: 'Taka', extensoNUnidades: 'Takas', extensoSubUnidade: 'Poishas'},
    {codigo: 'BGN', paisNomeMoeda: 'Bulgaria (Lev)', nomeMoeda: 'Bulgarian Lev', extensoUnidade: 'Lev', extensoNUnidades: 'Levas', extensoSubUnidade: 'Stotinkas'},
    {codigo: 'BHD', paisNomeMoeda: 'Bahrain (Dinar)', nomeMoeda: 'Bahraini Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: 'Fils'},
    {codigo: 'BIF', paisNomeMoeda: 'Burundi (Franc)', nomeMoeda: 'Franc Burundi', extensoUnidade: 'Franc', extensoNUnidades: 'Francs', extensoSubUnidade: 'Centimes'},
    {codigo: 'BMD', paisNomeMoeda: 'Bermuda (Dollar)', nomeMoeda: 'Bermudian Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'BND',
      paisNomeMoeda: 'Brunei (Darussalam Dollar)',
      nomeMoeda: 'Brunei Darussalam Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'BOB',
      paisNomeMoeda: 'Bolivia (Boliviano)',
      nomeMoeda: 'Boliviano',
      extensoUnidade: 'Boliviano',
      extensoNUnidades: 'Bolivianos',
      extensoSubUnidade: 'Centavos'
    },
    {codigo: 'BRL', paisNomeMoeda: 'Brazil (Real)', nomeMoeda: 'Real', extensoUnidade: 'Real', extensoNUnidades: 'Reais', extensoSubUnidade: 'Centavos'},
    {codigo: 'BSD', paisNomeMoeda: 'Bahamas (Dollar)', nomeMoeda: 'Bahamian dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'BTN',
      paisNomeMoeda: 'Bhutan (Ngultrum)',
      nomeMoeda: 'Bhutanese Ngultrum',
      extensoUnidade: 'Ngultrum',
      extensoNUnidades: 'Ngultrums',
      extensoSubUnidade: 'Chetrums'
    },
    {codigo: 'BWP', paisNomeMoeda: 'Botswana (Pula)', nomeMoeda: 'Botswana Pula', extensoUnidade: 'Pula', extensoNUnidades: 'Pulas', extensoSubUnidade: 'Thebes'},
    {codigo: 'BYR', paisNomeMoeda: 'Belarus (Ruble)', nomeMoeda: 'Belarus Ruble', extensoUnidade: 'Ruble', extensoNUnidades: 'Ruble', extensoSubUnidade: 'Kapeykas'},
    {codigo: 'BZD', paisNomeMoeda: 'Belize (Dollar)', nomeMoeda: 'Belize Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {codigo: 'CAD', paisNomeMoeda: 'Canada (Dollar)', nomeMoeda: 'Canada Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'CDF',
      paisNomeMoeda: 'Congo/Kinshasa (Franc)',
      nomeMoeda: 'Franc Congolese',
      extensoUnidade: 'Franc',
      extensoNUnidades: 'Francs',
      extensoSubUnidade: 'Centimes'
    },
    {codigo: 'CHF', paisNomeMoeda: 'Switzerland (Franc)', nomeMoeda: 'Franc Suisse', extensoUnidade: 'Franc', extensoNUnidades: 'Francs', extensoSubUnidade: 'Centimes'},
    {codigo: 'CLP', paisNomeMoeda: 'Chile (Peso)', nomeMoeda: 'Peso Chileno', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centavos'},
    {
      codigo: 'CNY',
      paisNomeMoeda: 'China (Yuan Renminbi)',
      nomeMoeda: 'Yuan Renminbi',
      extensoUnidade: 'Yuan Renminbi',
      extensoNUnidades: 'Yuan Renminbis',
      extensoSubUnidade: 'Jiǎo(1/10)/Fēn(1/100)'
    },
    {codigo: 'COP', paisNomeMoeda: 'Colombia (Peso)', nomeMoeda: 'Peso Columbiano', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centavos'},
    {codigo: 'CRC', paisNomeMoeda: 'Costa Rica (Colon)', nomeMoeda: 'Colon', extensoUnidade: 'Colon', extensoNUnidades: 'Colons', extensoSubUnidade: 'Cêntimos'},
    {
      codigo: 'CUC',
      paisNomeMoeda: 'Cuba (Convertible Peso)',
      nomeMoeda: 'Peso Cubano Convertible',
      extensoUnidade: 'Peso Convertible',
      extensoNUnidades: 'Pesos Convertibles',
      extensoSubUnidade: 'Centavos'
    },
    {codigo: 'CUP', paisNomeMoeda: 'Cuba (Peso)', nomeMoeda: 'Peso Cubano', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centavos'},
    {
      codigo: 'CVE',
      paisNomeMoeda: 'Cape Verde (Escudo)',
      nomeMoeda: 'Escudo de Cabo Verde',
      extensoUnidade: 'Escudo',
      extensoNUnidades: 'Escudos',
      extensoSubUnidade: 'Centavos'
    },
    {
      codigo: 'CZK',
      paisNomeMoeda: 'Czech Republic (Koruna)',
      nomeMoeda: 'Czech Koruna',
      extensoUnidade: 'Koruna',
      extensoNUnidades: 'Korunas',
      extensoSubUnidade: 'haléř'
    },
    {codigo: 'DJF', paisNomeMoeda: 'Djibouti (Franc)', nomeMoeda: 'Franc Djibouti', extensoUnidade: 'Franc', extensoNUnidades: 'Francs', extensoSubUnidade: 'Centimes'},
    {codigo: 'DKK', paisNomeMoeda: 'Denmark (Krone)', nomeMoeda: 'Dansk Krone', extensoUnidade: 'Krone', extensoNUnidades: 'Krones', extensoSubUnidade: 'øre'},
    {
      codigo: 'DOP',
      paisNomeMoeda: 'Dominican Republic (Peso)',
      nomeMoeda: 'Peso Dominicano',
      extensoUnidade: 'Peso',
      extensoNUnidades: 'Pesos',
      extensoSubUnidade: 'Centavos'
    },
    {codigo: 'DZD', paisNomeMoeda: 'Algeria (Dinar)', nomeMoeda: 'Algerian Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: 'Santeems'},
    {codigo: 'EGP', paisNomeMoeda: 'Egypt (Pound)', nomeMoeda: 'Egyptian Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Piastre'},
    {codigo: 'ERN', paisNomeMoeda: 'Eritrea (Nakfa)', nomeMoeda: 'Eritrean Nakfa', extensoUnidade: 'Nakfa', extensoNUnidades: 'Nakfas', extensoSubUnidade: 'Cents'},
    {codigo: 'ETB', paisNomeMoeda: 'Ethiopia (Birr)', nomeMoeda: 'Ethiopian Birr', extensoUnidade: 'Birr', extensoNUnidades: 'Birrs', extensoSubUnidade: 'Santim'},
    {codigo: 'EUR', paisNomeMoeda: 'Euro Member Countries (EURO)', nomeMoeda: 'EURO', extensoUnidade: 'EURO', extensoNUnidades: 'EUROS', extensoSubUnidade: 'CÊNTIMOS'},
    {codigo: 'FJD', paisNomeMoeda: 'Fiji (Dollar)', nomeMoeda: 'Fijian Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'FKP',
      paisNomeMoeda: 'Falkland Islands (Malvinas) (Pound)',
      nomeMoeda: 'Falkland Islands Pound',
      extensoUnidade: 'Pound',
      extensoNUnidades: 'Pounds',
      extensoSubUnidade: 'Pence'
    },
    {codigo: 'GBP', paisNomeMoeda: 'United Kingdom (Pound)', nomeMoeda: 'Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Pence'},
    {codigo: 'GEL', paisNomeMoeda: 'Georgia (Lari)', nomeMoeda: 'Georgian Lari', extensoUnidade: 'Lari', extensoNUnidades: 'Laris', extensoSubUnidade: 'Tetris'},
    {codigo: 'GGP', paisNomeMoeda: 'Guernsey (Pound)', nomeMoeda: 'Guernsey Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Pence'},
    {codigo: 'GHS', paisNomeMoeda: 'Ghana (Cedi)', nomeMoeda: 'Ghana Cedi', extensoUnidade: 'Cedi', extensoNUnidades: 'Cedis', extensoSubUnidade: 'Ghana Pesewas'},
    {codigo: 'GIP', paisNomeMoeda: 'Gibraltar (Pound)', nomeMoeda: 'Gibraltar Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Pence'},
    {codigo: 'GMD', paisNomeMoeda: 'Gambia (Dalasi)', nomeMoeda: 'Gambia Dalasi', extensoUnidade: 'Dalasi', extensoNUnidades: 'Dalasis', extensoSubUnidade: 'Bututs'},
    {codigo: 'GNF', paisNomeMoeda: 'Guinea (Franc)', nomeMoeda: 'Franc Guinea', extensoUnidade: 'Franc', extensoNUnidades: 'Francs', extensoSubUnidade: 'Centimes'},
    {codigo: 'GTQ', paisNomeMoeda: 'Guatemala (Quetzal)', nomeMoeda: 'Quetzal', extensoUnidade: 'Quetzal', extensoNUnidades: 'Quetzales', extensoSubUnidade: 'Centavos'},
    {codigo: 'GYD', paisNomeMoeda: 'Guyana (Dollar)', nomeMoeda: 'Guyana Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'HKD',
      paisNomeMoeda: 'Hong Kong (Dollar)',
      nomeMoeda: 'Hong Kong Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'HNL',
      paisNomeMoeda: 'Honduras (Lempira)',
      nomeMoeda: 'Lempira Hondureño',
      extensoUnidade: 'Lempira',
      extensoNUnidades: 'Lempiras',
      extensoSubUnidade: 'Centavos'
    },
    {codigo: 'HRK', paisNomeMoeda: 'Croatia (Kuna)', nomeMoeda: 'Kuna', extensoUnidade: 'Kuna', extensoNUnidades: 'Kunas', extensoSubUnidade: 'Lipas'},
    {codigo: 'HTG', paisNomeMoeda: 'Haiti (Gourde)', nomeMoeda: 'Haitian Gourde', extensoUnidade: 'Gourde', extensoNUnidades: 'Gourdes', extensoSubUnidade: 'Centimes'},
    {codigo: 'HUF', paisNomeMoeda: 'Hungary (Forint)', nomeMoeda: 'Hungarian Forint', extensoUnidade: 'Forint', extensoNUnidades: 'Forint', extensoSubUnidade: 'Fillérs'},
    {
      codigo: 'IDR',
      paisNomeMoeda: 'Indonesia (Rupiah)',
      nomeMoeda: 'Indonesian Rupiah',
      extensoUnidade: 'Rupiah',
      extensoNUnidades: 'Rupiahs',
      extensoSubUnidade: 'Sens'
    },
    {codigo: 'ILS', paisNomeMoeda: 'Israel (Shekel)', nomeMoeda: 'Shekel', extensoUnidade: 'Shekel', extensoNUnidades: 'Shekels', extensoSubUnidade: ''},
    {
      codigo: 'IMP',
      paisNomeMoeda: 'Isle of Man (Pound)',
      nomeMoeda: 'Isle of Man Pound',
      extensoUnidade: 'Pound',
      extensoNUnidades: 'Pounds',
      extensoSubUnidade: 'Pence'
    },
    {codigo: 'INR', paisNomeMoeda: 'India (Rupee)', nomeMoeda: 'Indian Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Paisa'},
    {codigo: 'IQD', paisNomeMoeda: 'Iraq (Dinar)', nomeMoeda: 'Iraqi Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: 'Fils'},
    {codigo: 'IRR', paisNomeMoeda: 'Iran (Rial)', nomeMoeda: 'Iranian Rial', extensoUnidade: 'Rial', extensoNUnidades: 'Rials', extensoSubUnidade: ''},
    {codigo: 'ISK', paisNomeMoeda: 'Iceland (Krona)', nomeMoeda: 'Króna', extensoUnidade: 'Króna', extensoNUnidades: 'krónur', extensoSubUnidade: ''},
    {codigo: 'JEP', paisNomeMoeda: 'Jersey (Pound)', nomeMoeda: 'Jersey Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Pence'},
    {codigo: 'JMD', paisNomeMoeda: 'Jamaica (Dollar)', nomeMoeda: 'Jamaica Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {codigo: 'JOD', paisNomeMoeda: 'Jordan (Dinar)', nomeMoeda: 'Jordanian Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: 'Qirsh'},
    {codigo: 'JPY', paisNomeMoeda: 'Japan (Yen)', nomeMoeda: 'Yen', extensoUnidade: 'Yen', extensoNUnidades: 'Yen', extensoSubUnidade: 'Sen'},
    {
      codigo: 'KES',
      paisNomeMoeda: 'Kenya (Shilling)',
      nomeMoeda: 'Kenyan Shilling',
      extensoUnidade: 'Shilling',
      extensoNUnidades: 'Shillings',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'KGS', paisNomeMoeda: 'Kyrgyzstan (Som)', nomeMoeda: 'Kyrgyzstani Som', extensoUnidade: 'Som', extensoNUnidades: 'Som', extensoSubUnidade: 'Tyiyn'},
    {codigo: 'KHR', paisNomeMoeda: 'Cambodia (Riel)', nomeMoeda: 'Riel', extensoUnidade: 'Riel', extensoNUnidades: 'Riel', extensoSubUnidade: 'Sen'},
    {codigo: 'KMF', paisNomeMoeda: 'Comoros (Franc)', nomeMoeda: 'Franc Comorien', extensoUnidade: 'Franc', extensoNUnidades: 'Francs', extensoSubUnidade: 'Centimes'},
    {codigo: 'KPW', paisNomeMoeda: 'Korea (North) (Won)', nomeMoeda: 'North Korean Won', extensoUnidade: 'Won', extensoNUnidades: 'Won', extensoSubUnidade: 'Chon'},
    {codigo: 'KRW', paisNomeMoeda: 'Korea (South) (Won)', nomeMoeda: 'South Korean Won', extensoUnidade: 'Won', extensoNUnidades: 'Won', extensoSubUnidade: 'Jeon'},
    {codigo: 'KWD', paisNomeMoeda: 'Kuwait (Dinar)', nomeMoeda: 'Kuwaiti Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: 'Fils'},
    {
      codigo: 'KYD',
      paisNomeMoeda: 'Cayman Islands (Dollar)',
      nomeMoeda: 'Cayman Islands Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'KZT', paisNomeMoeda: 'Kazakhstan (Tenge)', nomeMoeda: 'Kazakhstani Tenge', extensoUnidade: 'Tenge', extensoNUnidades: 'Tenge', extensoSubUnidade: 'Tïın'},
    {codigo: 'LAK', paisNomeMoeda: 'Laos (Kip)', nomeMoeda: 'Lao kip', extensoUnidade: 'kip', extensoNUnidades: 'kip', extensoSubUnidade: 'Att'},
    {codigo: 'LBP', paisNomeMoeda: 'Lebanon (Pound)', nomeMoeda: 'Lebanese Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Piastre'},
    {codigo: 'LKR', paisNomeMoeda: 'Sri Lanka (Rupee)', nomeMoeda: 'Sri Lankan Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Cents'},
    {codigo: 'LRD', paisNomeMoeda: 'Liberia (Dollar)', nomeMoeda: 'Liberia Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {codigo: 'LSL', paisNomeMoeda: 'Lesotho (Loti)', nomeMoeda: 'Loti', extensoUnidade: 'Loti', extensoNUnidades: 'Maloti', extensoSubUnidade: 'Lisente'},
    {codigo: 'LTL', paisNomeMoeda: 'Lithuania (Litas)', nomeMoeda: 'Lithuanian Litas', extensoUnidade: 'Litas', extensoNUnidades: 'Litai ', extensoSubUnidade: 'Centai'},
    {codigo: 'LVL', paisNomeMoeda: 'Latvia (Lat)', nomeMoeda: 'Latvian Lats', extensoUnidade: 'Lats', extensoNUnidades: 'Lati', extensoSubUnidade: 'Santīmu'},
    {codigo: 'LYD', paisNomeMoeda: 'Libya (Dinar)', nomeMoeda: 'Libyan Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinars', extensoSubUnidade: ''},
    {
      codigo: 'MAD',
      paisNomeMoeda: 'Morocco (Dirham)',
      nomeMoeda: 'Moroccan Dirham',
      extensoUnidade: 'Dirham',
      extensoNUnidades: 'Dirhams',
      extensoSubUnidade: 'Santimat'
    },
    {codigo: 'MDL', paisNomeMoeda: 'Moldova (Leu)', nomeMoeda: 'Moldovan Leu', extensoUnidade: 'Leu', extensoNUnidades: 'Lei', extensoSubUnidade: 'Bani'},
    {codigo: 'MGA', paisNomeMoeda: 'Madagascar (Ariary)', nomeMoeda: 'Ariary', extensoUnidade: 'Ariary', extensoNUnidades: 'Ariary', extensoSubUnidade: ''},
    {codigo: 'MKD', paisNomeMoeda: 'Macedonia (Denar)', nomeMoeda: 'Macedonian Denar', extensoUnidade: 'Denar', extensoNUnidades: 'Denari', extensoSubUnidade: 'Deni'},
    {codigo: 'MMK', paisNomeMoeda: 'Myanmar (Burma) (Kyat)', nomeMoeda: 'Kyat', extensoUnidade: 'Kyat', extensoNUnidades: 'Kyat', extensoSubUnidade: 'Pyas'},
    {codigo: 'MNT', paisNomeMoeda: 'Mongolia (Tughrik)', nomeMoeda: 'Tughrik', extensoUnidade: 'Tughrik', extensoNUnidades: 'Tögrög', extensoSubUnidade: 'Möngö'},
    {codigo: 'MOP', paisNomeMoeda: 'Macau (Pataca)', nomeMoeda: 'Pataca', extensoUnidade: 'Pataca', extensoNUnidades: 'Patacas', extensoSubUnidade: 'Avos'},
    {codigo: 'MRO', paisNomeMoeda: 'Mauritania (Ouguiya)', nomeMoeda: 'Ouguiya', extensoUnidade: 'Ouguiya', extensoNUnidades: 'Ouguiya', extensoSubUnidade: ''},
    {codigo: 'MUR', paisNomeMoeda: 'Mauritius (Rupee)', nomeMoeda: 'Mauritian Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Cents'},
    {
      codigo: 'MVR',
      paisNomeMoeda: 'Maldives (Maldive Islands) (Rufiyaa)',
      nomeMoeda: 'Rufiyaa',
      extensoUnidade: 'Rufiyaa',
      extensoNUnidades: 'Rufiyaa',
      extensoSubUnidade: 'Laari'
    },
    {codigo: 'MWK', paisNomeMoeda: 'Malawi (Kwacha)', nomeMoeda: 'Malawian Kwacha', extensoUnidade: 'Kwacha', extensoNUnidades: 'Kwacha', extensoSubUnidade: 'Tambala'},
    {codigo: 'MXN', paisNomeMoeda: 'Mexico (Peso)', nomeMoeda: 'Peso Mexicano', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centavos'},
    {
      codigo: 'MYR',
      paisNomeMoeda: 'Malaysia (Ringgit)',
      nomeMoeda: 'Malaysian Ringgit',
      extensoUnidade: 'Ringgit',
      extensoNUnidades: 'Ringgit',
      extensoSubUnidade: 'Sen'
    },
    {codigo: 'MZN', paisNomeMoeda: 'Mozambique (Metical)', nomeMoeda: 'Metical', extensoUnidade: 'Metical', extensoNUnidades: 'Meticais', extensoSubUnidade: 'Centavos'},
    {codigo: 'NAD', paisNomeMoeda: 'Namibia (Dollar)', nomeMoeda: 'Namibian Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {codigo: 'NGN', paisNomeMoeda: 'Nigeria (Naira)', nomeMoeda: 'Naira', extensoUnidade: 'Naira', extensoNUnidades: 'Naira', extensoSubUnidade: 'Kobo'},
    {codigo: 'NIO', paisNomeMoeda: 'Nicaragua (Cordoba)', nomeMoeda: 'Córdoba', extensoUnidade: 'Córdoba', extensoNUnidades: 'Córdobas', extensoSubUnidade: 'Centavos'},
    {codigo: 'NOK', paisNomeMoeda: 'Norway (Krone)', nomeMoeda: 'Norsk Krone', extensoUnidade: 'Krone', extensoNUnidades: 'Krones', extensoSubUnidade: 'øre'},
    {codigo: 'NPR', paisNomeMoeda: 'Nepal (Rupee)', nomeMoeda: 'Nepalese Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Paisa'},
    {
      codigo: 'NZD',
      paisNomeMoeda: 'New Zealand (Dollar)',
      nomeMoeda: 'New Zealand Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'OMR', paisNomeMoeda: 'Oman (Rial)', nomeMoeda: 'Omani Rial', extensoUnidade: 'Rial', extensoNUnidades: 'Rials', extensoSubUnidade: 'Baisa'},
    {codigo: 'PAB', paisNomeMoeda: 'Panama (Balboa)', nomeMoeda: 'Balboa', extensoUnidade: 'Balboa', extensoNUnidades: 'Balboas', extensoSubUnidade: 'Centésimos'},
    {
      codigo: 'PEN',
      paisNomeMoeda: 'Peru (Nuevo Sol)',
      nomeMoeda: 'Nuevo Sol',
      extensoUnidade: 'Nuevo Sol',
      extensoNUnidades: 'Nuevos Soles',
      extensoSubUnidade: 'Céntimos'
    },
    {codigo: 'PGK', paisNomeMoeda: 'Papua New Guinea (Kina)', nomeMoeda: 'Kina', extensoUnidade: 'Kina', extensoNUnidades: 'Kina', extensoSubUnidade: 'Toea'},
    {codigo: 'PHP', paisNomeMoeda: 'Philippines (Peso)', nomeMoeda: 'Philippine Peso', extensoUnidade: 'Peso', extensoNUnidades: 'Peso', extensoSubUnidade: 'Céntimo'},
    {codigo: 'PKR', paisNomeMoeda: 'Pakistan (Rupee)', nomeMoeda: 'Pakistani Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Paisa'},
    {codigo: 'PLN', paisNomeMoeda: 'Poland (Zloty)', nomeMoeda: 'Zloty', extensoUnidade: 'Złoty', extensoNUnidades: 'Złoty', extensoSubUnidade: 'Grosz'},
    {codigo: 'PYG', paisNomeMoeda: 'Paraguay (Guarani)', nomeMoeda: 'Guaraní', extensoUnidade: 'Guaraní', extensoNUnidades: 'Guaraníes', extensoSubUnidade: 'Céntimos'},
    {codigo: 'QAR', paisNomeMoeda: 'Qatar (Riyal)', nomeMoeda: 'Qatari Riyal', extensoUnidade: 'Riyal', extensoNUnidades: 'Riyal', extensoSubUnidade: 'Dirham'},
    {codigo: 'RON', paisNomeMoeda: 'Romania (New Leu)', nomeMoeda: 'Romanian Leu', extensoUnidade: 'Leu', extensoNUnidades: 'Lei', extensoSubUnidade: 'Bani'},
    {codigo: 'RSD', paisNomeMoeda: 'Serbia (Dinar)', nomeMoeda: 'Serbian Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinar', extensoSubUnidade: 'Para'},
    {codigo: 'RUB', paisNomeMoeda: 'Russia (Ruble)', nomeMoeda: 'Russian Ruble', extensoUnidade: 'Ruble', extensoNUnidades: 'Rubles', extensoSubUnidade: 'Kopek'},
    {
      codigo: 'RWF',
      paisNomeMoeda: 'Rwanda (Franc)',
      nomeMoeda: 'Franc Rwandais',
      extensoUnidade: 'Rwandais',
      extensoNUnidades: 'Rwandais',
      extensoSubUnidade: 'Centimes'
    },
    {codigo: 'SAR', paisNomeMoeda: 'Saudi Arabia (Riyal)', nomeMoeda: 'Saudi Riyal', extensoUnidade: 'Riyal', extensoNUnidades: 'Riyal', extensoSubUnidade: 'Halalas'},
    {
      codigo: 'SBD',
      paisNomeMoeda: 'Solomon Islands (Dollar)',
      nomeMoeda: 'Solomon Islands Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'SCR', paisNomeMoeda: 'Seychelles (Rupee)', nomeMoeda: 'Seychellois Rupee', extensoUnidade: 'Rupee', extensoNUnidades: 'Rupees', extensoSubUnidade: 'Cents'},
    {codigo: 'SDG', paisNomeMoeda: 'Sudan (Pound)', nomeMoeda: 'Sudan Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Pence'},
    {codigo: 'SEK', paisNomeMoeda: 'Sweden (Krona)', nomeMoeda: 'Svensk Krona', extensoUnidade: 'Krona', extensoNUnidades: 'Kronor', extensoSubUnidade: 'öre'},
    {
      codigo: 'SGD',
      paisNomeMoeda: 'Singapore (Dollar)',
      nomeMoeda: 'Singapore Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'SHP',
      paisNomeMoeda: 'Saint Helena (Pound)',
      nomeMoeda: 'Saint Helena Pound',
      extensoUnidade: 'Pound',
      extensoNUnidades: 'Pounds',
      extensoSubUnidade: 'Pence'
    },
    {codigo: 'SLL', paisNomeMoeda: 'Sierra Leone (Leone)', nomeMoeda: 'Leone', extensoUnidade: 'Leone', extensoNUnidades: 'Leones', extensoSubUnidade: 'Cents'},
    {
      codigo: 'SOS',
      paisNomeMoeda: 'Somalia (Shilling)',
      nomeMoeda: 'Somalia Shilling',
      extensoUnidade: 'Shilling',
      extensoNUnidades: 'Shillings',
      extensoSubUnidade: 'Senti'
    },
    {codigo: 'SPL*', paisNomeMoeda: 'Seborga (Luigino)', nomeMoeda: 'Luigino', extensoUnidade: 'Luigino', extensoNUnidades: 'Luigino', extensoSubUnidade: 'Centesimos'},
    {
      codigo: 'SRD',
      paisNomeMoeda: 'Suriname (Dollar)',
      nomeMoeda: 'Surinamese Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'STD',
      paisNomeMoeda: 'São Tomé and Príncipe (Dobra)',
      nomeMoeda: 'Dobra',
      extensoUnidade: 'Dobra',
      extensoNUnidades: 'Dobras',
      extensoSubUnidade: 'Cêntimos'
    },
    {codigo: 'SVC', paisNomeMoeda: 'El Salvador (Colon)', nomeMoeda: 'Colón', extensoUnidade: 'Colón', extensoNUnidades: 'Colones', extensoSubUnidade: 'Centavos'},
    {codigo: 'SYP', paisNomeMoeda: 'Syria (Pound)', nomeMoeda: 'Syrian Pound', extensoUnidade: 'Pound', extensoNUnidades: 'Pounds', extensoSubUnidade: 'Piastre'},
    {
      codigo: 'SZL',
      paisNomeMoeda: 'Swaziland (Lilangeni)',
      nomeMoeda: 'Lilangeni',
      extensoUnidade: 'Lilangeni',
      extensoNUnidades: 'Emalangeni',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'THB', paisNomeMoeda: 'Thailand (Baht)', nomeMoeda: 'Baht', extensoUnidade: 'Baht', extensoNUnidades: 'Baht', extensoSubUnidade: 'Satang'},
    {codigo: 'TJS', paisNomeMoeda: 'Tajikistan (Somoni)', nomeMoeda: 'Somoni', extensoUnidade: 'Somoni', extensoNUnidades: 'Somoni', extensoSubUnidade: 'Diram'},
    {
      codigo: 'TMT',
      paisNomeMoeda: 'Turkmenistan (Manat)',
      nomeMoeda: 'Turkmenistan Manat',
      extensoUnidade: 'Manat',
      extensoNUnidades: 'Manat',
      extensoSubUnidade: 'Tenge'
    },
    {codigo: 'TND', paisNomeMoeda: 'Tunisia (Dinar)', nomeMoeda: 'Tunisian Dinar', extensoUnidade: 'Dinar', extensoNUnidades: 'Dinar', extensoSubUnidade: 'Milim'},
    {codigo: 'TOP', paisNomeMoeda: "Tonga (Pa'anga)", nomeMoeda: "Pa'anga", extensoUnidade: "Pa'anga", extensoNUnidades: "Pa'anga", extensoSubUnidade: 'Seniti'},
    {codigo: 'TRY', paisNomeMoeda: 'Turkey (Lira)', nomeMoeda: 'Turkish Lira', extensoUnidade: 'Lira', extensoNUnidades: 'Lira', extensoSubUnidade: 'Kuruş'},
    {
      codigo: 'TTD',
      paisNomeMoeda: 'Trinidad and Tobago (Dollar)',
      nomeMoeda: 'Trinidad and Tobago Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'TVD', paisNomeMoeda: 'Tuvalu (Dollar)', nomeMoeda: 'Tuvaluan Dollar', extensoUnidade: 'Dollar', extensoNUnidades: 'Dollars', extensoSubUnidade: 'Cents'},
    {
      codigo: 'TWD',
      paisNomeMoeda: 'Taiwan (New Dollar)',
      nomeMoeda: 'New Taiwan Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'TZS',
      paisNomeMoeda: 'Tanzania (Shilling)',
      nomeMoeda: 'Tanzanian Shilling',
      extensoUnidade: 'Shilling',
      extensoNUnidades: 'Shillings',
      extensoSubUnidade: 'Senti'
    },
    {codigo: 'UAH', paisNomeMoeda: 'Ukraine (Hryvna)', nomeMoeda: 'Hryvna', extensoUnidade: 'Hryvna', extensoNUnidades: 'Hryvni', extensoSubUnidade: 'Kopiyky'},
    {
      codigo: 'UGX',
      paisNomeMoeda: 'Uganda (Shilling)',
      nomeMoeda: 'Ugandan Shilling',
      extensoUnidade: 'Shilling',
      extensoNUnidades: 'Shillings',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'USD',
      paisNomeMoeda: 'United States (Dollar)',
      nomeMoeda: 'US Dollar',
      extensoUnidade: 'US Dollar',
      extensoNUnidades: 'US Dollars',
      extensoSubUnidade: 'Cents'
    },
    {codigo: 'UYU', paisNomeMoeda: 'Uruguay (Peso)', nomeMoeda: 'Peso Uruguayo', extensoUnidade: 'Peso', extensoNUnidades: 'Pesos', extensoSubUnidade: 'Centésimos'},
    {codigo: 'UZS', paisNomeMoeda: 'Uzbekistan (Som)', nomeMoeda: 'Uzbekistani Som', extensoUnidade: 'Som', extensoNUnidades: 'Som', extensoSubUnidade: 'Tiyin'},
    {codigo: 'VEF', paisNomeMoeda: 'Venezuela (Bolivar)', nomeMoeda: 'Bolívar', extensoUnidade: 'Bolívar', extensoNUnidades: 'Bolívares', extensoSubUnidade: 'Céntimos'},
    {codigo: 'VND', paisNomeMoeda: 'Viet Nam (Dong)', nomeMoeda: 'Vietnamese Dong', extensoUnidade: 'Dong', extensoNUnidades: 'Dong', extensoSubUnidade: 'Xu'},
    {codigo: 'VUV', paisNomeMoeda: 'Vanuatu (Vatu)', nomeMoeda: 'Vatu', extensoUnidade: 'Vatu', extensoNUnidades: 'Vatu ', extensoSubUnidade: ''},
    {codigo: 'WST', paisNomeMoeda: 'Samoa (Tala)', nomeMoeda: 'Tala', extensoUnidade: 'Tala', extensoNUnidades: 'Tala', extensoSubUnidade: 'Sene'},
    {
      codigo: 'XAF',
      paisNomeMoeda: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
      nomeMoeda: 'Franc CFA',
      extensoUnidade: 'Franc',
      extensoNUnidades: 'Francs',
      extensoSubUnidade: 'Centimes'
    },
    {
      codigo: 'XCD',
      paisNomeMoeda: 'East Caribbean (Dollar)',
      nomeMoeda: 'East Caribbean Dollar',
      extensoUnidade: 'Dollar',
      extensoNUnidades: 'Dollars',
      extensoSubUnidade: 'Cents'
    },
    {
      codigo: 'XDR',
      paisNomeMoeda: 'International Monetary Fund (IMF) Special Drawing Rights',
      nomeMoeda: 'Special Drawing Rights',
      extensoUnidade: 'Special Drawing Rights',
      extensoNUnidades: 'Special Drawing Rights',
      extensoSubUnidade: ''
    },
    {
      codigo: 'XOF',
      paisNomeMoeda: 'Communauté Financière Africaine (BCEAO) (Franc)',
      nomeMoeda: 'Franc CFA',
      extensoUnidade: 'Franc',
      extensoNUnidades: 'Francs',
      extensoSubUnidade: 'Centimes'
    },
    {
      codigo: 'XPF',
      paisNomeMoeda: 'Comptoirs Français du Pacifique (CFP) (Franc)',
      nomeMoeda: 'Franc CFP',
      extensoUnidade: 'Franc',
      extensoNUnidades: 'Francs',
      extensoSubUnidade: 'Centimes'
    },
    {codigo: 'YER', paisNomeMoeda: 'Yemen (Rial)', nomeMoeda: 'Yemeni Rial', extensoUnidade: 'Rial', extensoNUnidades: 'Rials', extensoSubUnidade: 'Fils'},
    {codigo: 'ZAR', paisNomeMoeda: 'South Africa (Rand)', nomeMoeda: 'Rand', extensoUnidade: 'Rand', extensoNUnidades: 'Rand', extensoSubUnidade: 'Cents'},
    {codigo: 'ZMW', paisNomeMoeda: 'Zambia (Kwacha)', nomeMoeda: 'Zambian Kwacha', extensoUnidade: 'Kwacha', extensoNUnidades: 'Kwacha', extensoSubUnidade: 'Ngwee'},
    {codigo: 'ZWD', paisNomeMoeda: 'Zimbabwe (Dollar)', nomeMoeda: 'Zimbabwean Dollar', extensoUnidade: 'Dollar'}
  ]
});
