import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_MAPA_DIFERIMENTOS_ACRESCIMOS} from '../diferimentos.module.interface';
import {MapaDiferimentosAcrescimosModuleComponent} from './components/mapaDiferimentosAcrescimos.module.component';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../entities/empresas/empresas.entity.interface';
import {HttpResponse} from '@angular/common/http';

export const MODULE_MAPA_DIFERIMENTOS_ACRESCIMOS: IModuleDefinition = {
  name: MODULE_NAME_MAPA_DIFERIMENTOS_ACRESCIMOS,
  state: {
    url: `/${MODULE_NAME_MAPA_DIFERIMENTOS_ACRESCIMOS}`,
    component: MapaDiferimentosAcrescimosModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP, ROLE.RH],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      sidebarTitle: `portals.sidebar.modules.${MODULE_NAME_MAPA_DIFERIMENTOS_ACRESCIMOS}`
    },
    resolve: [
      RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
      {
        provide: 'empresaMesContab',
        deps: [EmpresasService],
        useFactory: (entityServiceBuilder: EmpresasService): Promise<IJsonPerioMudaPrimeiroMesContab> => {
          return entityServiceBuilder.getPerioMudaPrimeiroMesContab().then((response: HttpResponse<IJsonPerioMudaPrimeiroMesContab>) => response.body);
        }
      }
    ]
  }
};
