import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IUtlViatPatronal} from '../utlViatPatronal.module.interface';
import {ETipoEscolhaUtlViatPatronal, ETipoOrdenacao, IJsonUtlViatPatronalIncidencias} from '../jsonUtlViatPatronal.module.interface';
import {ETipoCategoria} from '../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {ETipoAbonoDesconto} from '../../../entities/abdes/abdes.entity.interface';
import moment from 'moment';
import {UtlViatPatronalService} from '../utlViatPatronal.module.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'modal-utl-viat-patronal',
  templateUrl: './utlViatPatronal.modal.component.html'
})
export class UtlViatPatronalModalComponent extends CGModalComponent<Array<IUtlViatPatronal>> {
  @Input() public estado: ETipoEscolhaUtlViatPatronal;
  @Input() public tipoOrdenacao: ETipoOrdenacao;
  @Input() public utlViatPatronal: IUtlViatPatronal;

  public readonly rhtipoprocessOutput: string;
  public readonly filterAbonoCategOutput: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _utlViatPatronalService: UtlViatPatronalService
  ) {
    super(_injector);
    this.rhtipoprocessOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.filterAbonoCategOutput = `grupoAbonoCategoria=${ETipoCategoria.UtlViaturaPatronal}`;
    if (!this.utlViatPatronal) {
      this.utlViatPatronal = {
        nome: '',
        abdesNome: '',
        codABDESC: '',
        processaVenc: false,
        rhTipoProce: '',
        abonoDesc: ETipoAbonoDesconto.Abono,
        rhUltViatPatronalID: '',
        nCODABDESC: '',
        codEmp: undefined,
        vAquisicaoViatura: 0,
        vMercadoViatura: 0,
        anoProcessamento: moment().year(),
        mesInicioProce: moment().month(),
        observacoes: '',
        suspende: false,
        dataSuspensao: moment(),
        obsSupencao: '',
        tipoProcessamento: undefined,
        valorIncidenciaSS: 0,
        valorIncidenciaIRS: 0,
        _empDesc: '',
        _abonoDesc: ''
      };
    }
  }

  public close(): Promise<void> {
    return this._utlViatPatronalService.postUtlViatPatronal(this.estado, this.tipoOrdenacao, this.utlViatPatronal).then((response: HttpResponse<Array<IUtlViatPatronal>>) => {
      return super.close(response.body);
    });
  }

  public valorViaturaChanged(): Promise<void> {
    return this._utlViatPatronalService
      .calculaIncidencias(this.utlViatPatronal.vAquisicaoViatura, this.utlViatPatronal.vMercadoViatura)
      .then((response: HttpResponse<IJsonUtlViatPatronalIncidencias>) => {
        this.utlViatPatronal.valorIncidenciaSS = response.body.incidenciaSegSocial;
        this.utlViatPatronal.valorIncidenciaIRS = response.body.incidenciaIRS;
      });
  }

  public suspendeChanged(value: boolean): void {
    this.utlViatPatronal.suspende = value;
    if (value) {
      this.utlViatPatronal.dataSuspensao = moment();
    }
  }
}
