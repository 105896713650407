import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../translate/translate.module';
import {PlDatepickerComponent} from './datepicker.component';

export * from './datepicker.component.interface';
export * from './datepicker.component';

const DECLARATIONS = [PlDatepickerComponent];

@NgModule({
  imports: [CommonModule, PlTranslateModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlDatepickerModule {}
