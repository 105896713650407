import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IPlEditComponentOptionsInputNumber, IPlMediaDevicesCameraCaptureProperties, IPlTooltipConfig, isEmpty, isNumber, isString, PlTranslateService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EClassificadorArtigoTemplate} from '../../../../../common/enums/erp.enums';
import {EConfigOptionsInstanceName, IArtigosConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EGroupName, EStatusCode} from '../../../../../config/constants';
import {EGrupoArtigo, IJsonArtigo, IJsonArtigoArmazemStock} from '../../jsonArtigo.entity.interface';
import {EmpresaService} from '../../../../services/empresa/empresa.service';
import {ENTITY_NAME_IVAS} from '../../../ivas/ivas.entity.interface';
import {ENTITY_NAME_POCS} from '../../../pocs/pocs.entity.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IArtigosEntityService, IArtigosEvaluateConfigOptionInvisibleParams, IArtigosParams} from '../../artigos.entity.interface';
import {IEntityAutocompleteCustomActionDefinition} from '../../../../components/entity/entity.autocomplete.definition.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonFamilia} from '../../../familias/jsonFamilia.entity.interface';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';
import {IJsonPOC} from '../../../pocs/jsonPOC.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ROLE} from '../../../../services/role.const';
import {round} from '../../../../../common/utils/utils';
import {IApiRequestConfigWithBody, TServiceResponse} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonLote} from '../../../lotes/jsonLote.entity.interface';
import {ENTITY_NAME_LOTES} from '../../../lotes/lotes.entity.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonTipoArtigo} from '../../../tiposartigo/jsonTipoArtigo.entity.interface';
import {LotesService} from '../../../lotes/lotes.entity.service';
import {ENTITY_NAME_ARTAR, IARTAREntityService} from '../../../artars/artars.entity.interface';
import {EModuleTipo} from '../../../../modules/listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';

export const DECIMALS_DEFAULT_PRECOS = 3;
export const DECIMALS_CALC_PRECOS = 5;

const RESIZE_WIDTH = 150;
const RESIZE_HEIGHT = 150;
const MAX_WIDTH = 500;

@Component({
  selector: 'artigos-edit',
  templateUrl: './artigos.entity.edit.component.html'
})
export class ArtigosEditComponent extends ModuloEntityDetailComponent<IJsonArtigo, IArtigosEntityService> implements OnInit, OnDestroy {
  @Input() public cgStoreUrlBackOffice: string;

  public readonly roleComercialAdvanced: ROLE;
  public readonly classArtTemplate: typeof EClassificadorArtigoTemplate;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly customActionsConta: Array<IEntityAutocompleteCustomActionDefinition>;
  public readonly tooltipMostraTodasContas: IPlTooltipConfig;
  public readonly dataGridDefinitionClifo: IDevExpressDataGrid<IJsonArtigoArmazemStock>;
  public readonly tiposEstatistica: typeof EModuleTipo;

  public dataGridDefinitionArmazem: IDevExpressDataGrid<IJsonArtigoArmazemStock>;
  public propertiesEditDecimalsPrecoLimit: IPlEditComponentOptionsInputNumber;
  public optionShowPrecoBaseCusto: boolean;
  public optionShowCategoria: boolean;
  public optionShowPropriedade: boolean;
  public hasRoleContabilidade: boolean;
  public taxaAtual: number;
  public contaVendaOriginalFamilia: string;
  public contaCompraOriginalFamilia: string;
  public sujeitaAsLeisVigoramEmPortugal: boolean;
  public filterNContaPocVenda: string;
  public filterNContaPocCompra: string;
  public mostraTodasContasVenda: boolean;
  public mostraTodasContasCompra: boolean;
  public messageContaPocFormadaVenda: string;
  public messageContaPocFormadaCompra: string;
  public decimalsQTD: number;
  public tooltipTaxaIvaVenda: string;
  public tooltipTaxaIvaCompra: string;
  public tooltipTipoProdInvt: string;
  public lotesAtivos: boolean;
  public lotesfilter: string;
  public comercialAdvanced: boolean;
  public licencaStoreModePublic: boolean;
  public promiseArtigoPicture: Promise<void>;
  public artigoPicture: File;
  public propertiesCaptureImage: IPlMediaDevicesCameraCaptureProperties;
  public originalArtigoPicture: File;

  private readonly _subscriptionConfigOptions: Subscription;
  private readonly _familiasEntityService: IEntityService<IJsonFamilia>;
  private readonly _ivasService: IEntityService<IJsonIva>;
  private readonly _pocsService: IEntityService<IJsonPOC>;
  private readonly _tiposArtigoService: IEntityService<IJsonTipoArtigo>;
  private readonly _serviceARTAR: IARTAREntityService;
  private readonly _maintenanceInstanceFamilias: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceLotes: IEntityMaintenanceInstance;
  private readonly _artigosParams: IArtigosParams;
  private readonly _subscriptionOnCancel: Subscription;

  private _dataGridInstanceLotes: dxDataGrid;
  private _dataGridInstanceDetalArmazem: dxDataGrid;
  private _ivaVenda: IJsonIva;
  private _ivaCompra: IJsonIva;
  private _contaPocVenda: IJsonPOC;
  private _contaPocCompra: IJsonPOC;
  private _changedArtigoPicture: boolean;
  private _deletedArtigoPicture: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _authService: AuthService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _empresaService: EmpresaService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _lotesService: LotesService
  ) {
    super(_injector);
    this.roleComercialAdvanced = ROLE.ERPADVANCED;
    this.classArtTemplate = EClassificadorArtigoTemplate;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.ARTIGOS;
    this.configOptionsGroupName = EGroupName.ERP;
    this.tiposEstatistica = EModuleTipo;
    this.customActionsConta = [
      {
        caption: 'artigos.actions.familias',
        icon: 'fa-fw fa-pencil-square',
        action: () => {
          this.openFamiliasMaintenance();
        }
      }
    ];
    this.tooltipMostraTodasContas = {text: 'artigos.message.mostraTodas'};
    this.mostraTodasContasVenda = false;
    this.mostraTodasContasCompra = false;
    this._maintenanceInstanceFamilias = this._entityMaintenanceService.build('familias');
    this._maintenanceInstanceLotes = this._entityMaintenanceService.build(ENTITY_NAME_LOTES);
    this._familiasEntityService = this._entityServiceBuilder.build<IJsonFamilia>('familias');
    this._ivasService = this._entityServiceBuilder.build<IJsonIva>(ENTITY_NAME_IVAS);
    this._pocsService = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
    this._tiposArtigoService = this._entityServiceBuilder.build<IJsonTipoArtigo>('tiposartigo');
    this._serviceARTAR = this._entityServiceBuilder.build(ENTITY_NAME_ARTAR);
    const configOptionsInstance = this._configOptionsService.getOptionsErp().get(this.configOptionsInstanceName);
    this._artigosParams = <IArtigosParams>this._transition.params();
    this._subscriptionConfigOptions = configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IArtigosConfigOptions>) => {
      this.optionShowPrecoBaseCusto = configOptions.get('showPrecoBaseCusto').value;
      this.optionShowCategoria = configOptions.get('showCategoria').value;
      this.optionShowPropriedade = configOptions.get('showPropriedade').value;
    });
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((hasAuthority: boolean) => {
      this.hasRoleContabilidade = hasAuthority;
    });
    this._empresaService.sujeitaAsLeisVigoramEmPortugal().then((value: boolean) => {
      this.sujeitaAsLeisVigoramEmPortugal = value;
      this.evaluateFilterNContaPocVenda();
      this.evaluateFilterNContaPocCompra();
    });
    this.dataGridDefinitionClifo = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nArmazem', dataType: 'number', caption: 'artigos.fields.contaExt'},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'artigos.fields.artigoExt'},
        {dataField: 'qtd', dataType: 'number', caption: 'artigos.fields.descContaExt'}
      ],
      export: {filename: 'artigos.text.detalheArmazem'},
      keyExpr: 'nArmazem',
      remoteOperations: false,
      toolbar: {
        items: ['exportButton', 'columnChooserButton', 'addRowButton']
      }
    };
    this._authService.hasAuthority(this.roleComercialAdvanced).then((hasAuthority: boolean) => {
      this.comercialAdvanced = hasAuthority;
    });
    this._resetPictureVars();
    this._subscriptionOnCancel = this.evtOnCancel().subscribe(() => {
      if (this.originalArtigoPicture) {
        this.artigoPicture = this.originalArtigoPicture;
        this._resetPictureVars();
      }
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        armazens: undefined,
        categoria: 0,
        classe: 0,
        codCalcPrecoVenda: 0,
        codIvaCompra: 0,
        codIvaVenda: 0,
        codvaloriz: 0,
        descricaoCategoria: '',
        descricaoClasse: '',
        movimStock: false,
        nArtigo: '',
        nContaPoc: '',
        nContaPocCompra: '',
        nFamilia: 0,
        nGrFamilia: 0,
        nIec: 0,
        nSubFa: 0,
        nTpArt: 0,
        nome: '',
        nomeCalcPrecoVenda: '',
        nomeContaPoc: '',
        nomeContaPocCompra: '',
        nomeFamilia: '',
        nomeGrFamilia: '',
        nomeIec: '',
        nomeIvaCompra: '',
        nomeIvaVenda: '',
        nomeSubFa: '',
        nomeTpArt: '',
        nomeUnidadeMov: '',
        nomecodvaloriz: '',
        pesoPorUnida: 0,
        precoComIva: 0,
        precoSemIva: 0,
        qtdPorUnid1: 0,
        qtdPorUnid2: 0,
        qtdTotal: 0,
        segNArtigo: '',
        segNome: '',
        stampUpdate: undefined,
        stockArmazens: undefined,
        taxaIvaCompra: 0,
        taxaIvaVenda: 0,
        temLote: false,
        temRetencaoNaFonte: false,
        unidadeMov: 0,
        volumePorUnida: 0,
        grupoArtigo: EGrupoArtigo.Geral,
        artDesactivado: false,
        artBloqueado: false,
        precoBaseCusto: 0,
        nDecimaisPrecos: DECIMALS_DEFAULT_PRECOS,
        precoCompUlt: 0,
        dataPrVendUl: undefined,
        dataPrCompra: undefined,
        prVendaUlti: 0,
        diasValidade: 0,
        temDataValidade: false,
        naoPermiteExistNeg: false,
        qtdTotalStockEncCliente: 0,
        qtdTotalStockEncForn: 0,
        qtdTotalStockPotencial: 0
      };
    }
    this.decimalsQTD = this.configurations.gestaoComercial.decimais.quantidades;

    this.taxaAtual = 0;
    if (this.type !== this.stateTypes.NEW) {
      this.changedFamilia(this.model.nFamilia, true);
    } else if (!isEmpty(this._artigosParams.nArtigo)) {
      this.model.nArtigo = this._artigosParams.nArtigo;
    }

    this.propertiesEditDecimalsPrecoLimit = {
      decimalsLimit: this.model.nDecimaisPrecos ? this.model.nDecimaisPrecos : DECIMALS_DEFAULT_PRECOS
    };

    this.toolbar.addButton({
      id: 'artigos-navigation',
      order: 1,
      type: 'button-group',
      visible: this.type === EEntityStateDetailType.DETAIL,
      items: [
        {
          id: 'artigos-navigation-first',
          order: 1,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-double-left"></i>',
          class: 'btn-primary',
          click: () => this._goToFirstArtigo(),
          title: 'clifos.navigation.tooltipFirst'
        },
        {
          id: 'artigos-navigation-previous',
          order: 2,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-left"></i>',
          class: 'btn-primary',
          click: () => this._goToPreviousArtigo(),
          title: 'clifos.navigation.tooltipPrevious'
        },
        {
          id: 'artigos-navigation-next',
          order: 3,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-right"></i>',
          class: 'btn-primary',
          click: () => this._goToNextArtigo(),
          title: 'clifos.navigation.tooltipNext'
        },
        {
          id: 'artigos-navigation-last',
          order: 4,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-double-right"></i>',
          class: 'btn-primary',
          click: () => this._goToLastArtigo(),
          title: 'clifos.navigation.tooltipLast'
        }
      ]
    });

    this.tooltipTaxaIvaVenda = this._translateService.instant('artigos.fields.taxaIvaTip', {taxa: this.model.taxaIvaVenda});
    this.tooltipTaxaIvaCompra = this._translateService.instant('artigos.fields.taxaIvaTip', {taxa: this.model.taxaIvaCompra});
    this._setTooltipTiposArtigo();

    this.lotesAtivos = true;
    this.lotesfilter = `nArtigo=${this.model.nArtigo}&terminado=0`;

    this.dataGridDefinitionArmazem = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nArmazem', dataType: 'number', caption: 'artigos.fields.nArmazem', allowEditing: false},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'artigos.fields.nomeArmazem', allowEditing: false},
        {dataField: 'qtd', dataType: 'number', caption: 'artigos.fields.qtd', allowEditing: false},
        {dataField: 'qtdStockPotencial', dataType: 'double', caption: 'artigos.fields.qtdStockPotencial', allowEditing: false},
        {dataField: 'qtdStockMinimo', dataType: 'double', caption: 'artigos.fields.qtdStockMinimo', allowEditing: this.type === EEntityStateDetailType.EDIT},
        {dataField: 'qtdStockMaximo', dataType: 'double', caption: 'artigos.fields.qtdStockMaximo', allowEditing: this.type === EEntityStateDetailType.EDIT},
        {dataField: 'qtdStockEncCliente', dataType: 'double', caption: 'artigos.fields.qtdStockEncCliente', visible: false, allowEditing: false},
        {dataField: 'qtdStockEncForn', dataType: 'double', caption: 'artigos.fields.qtdStockEncForn', visible: false, allowEditing: false}
      ],
      selection: {mode: 'none'},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      },
      export: {filename: 'artigos.text.detalheArmazem'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            locateInMenu: 'auto',
            template: 'templateToolbarDetArmazens'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this.propertiesCaptureImage = {
      resizeWidth: RESIZE_WIDTH,
      resizeHeight: RESIZE_HEIGHT,
      resizeMethod: 'crop',
      defaultFileName: this._translateService.instant('artigos.text.fileNamePicture', {nArtigo: this.model.nArtigo}),
      videoWidth: MAX_WIDTH,
      uploadProperties: {
        maxFileSize: 2,
        acceptedFiles: '.jpg,.gif,.png,.bmp,.jpeg'
      },
      resizeOverlay: true
    };

    this.promiseArtigoPicture = this._getArtigoPicture(this.model.nArtigo).then((value: File) => {
      this.artigoPicture = value;
      this._resetPictureVars();
      if (!this.originalArtigoPicture) {
        this.originalArtigoPicture = this.artigoPicture;
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
    this._subscriptionOnCancel.unsubscribe();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      if (this.type === EEntityStateDetailType.NEW || this.type === EEntityStateDetailType.EDIT) {
        if (this.model?.taxaIvaVenda) {
          this.taxaAtual = this.model.taxaIvaVenda;
        }
      }

      if (this.type !== EEntityStateDetailType.NEW && this._dataGridInstanceDetalArmazem) {
        this._dataGridInstanceDetalArmazem.columnOption('qtdStockMinimo', 'allowEditing', this.type === EEntityStateDetailType.EDIT);
        this._dataGridInstanceDetalArmazem.columnOption('qtdStockMaximo', 'allowEditing', this.type === EEntityStateDetailType.EDIT);
        this._dataGridInstanceDetalArmazem.repaint();
      }
    });
  }

  public save(config?: IApiRequestConfigWithBody<IJsonArtigo>): Promise<IJsonArtigo> {
    return new Promise<IJsonArtigo>((resolve, reject) => {
      super
        .save(config)
        .then((response: IJsonArtigo) => {
          if (this._previousType === EEntityStateDetailType.EDIT && this._deletedArtigoPicture) {
            this.service
              .deletePicture(response.nArtigo)
              .then(() => {
                this._resetPictureVars();
              })
              .finally(() => {
                resolve(response);
              });
          } else if (this._changedArtigoPicture && this.artigoPicture) {
            this.service
              .setPicture(response.nArtigo, this.artigoPicture)
              .then(() => {
                this._resetPictureVars();
              })
              .finally(() => {
                resolve(response);
              });
          } else {
            resolve(response);
          }
        })
        .catch(reject);
    });
  }

  public changedFamilia(value: number, fromOnInit: boolean): Promise<void> {
    if (!fromOnInit) {
      this.model.nFamilia = value;
    }
    return this._familiasEntityService.get({id: value}).then((response: HttpResponse<IJsonFamilia>) => {
      if (response.body?.artigosTemplate) {
        if (!fromOnInit) {
          const template = response.body.artigosTemplate;
          this.model = {
            ...this.model,
            nTpArt: template.tipoArtigo,
            nomeTpArt: template.nomeTipoArtigo,
            nGrFamilia: template.nDepart,
            nomeGrFamilia: template.nomeDepart,
            nSubFa: template.nSubfa,
            nomeSubFa: template.nomeSubfa,
            codIvaVenda: template.codIva,
            nomeIvaVenda: template.nomeIva,
            codIvaCompra: template.codIvaCompra,
            nomeIvaCompra: template.nomeIvaCompra,
            unidadeMov: template.uniMov,
            nomeUnidadeMov: template.nomeUniMov,
            qtdPorUnid1: template.qtdPorUnid1,
            codvaloriz: template.codValoriz,
            movimStock: template.movimentaStock,
            nContaPoc: template.nContaPoc,
            nomeContaPoc: template.nomeContaPoc,
            nContaPocCompra: template.nContaPocComp,
            nomeContaPocCompra: template.nomeContaPocComp,
            naoPermiteExistNeg: template.naoPExistNeg === -1
          };
        }
        this.contaCompraOriginalFamilia = response.body.artigosTemplate.nContaPocComp;
        this.contaVendaOriginalFamilia = response.body.artigosTemplate.nContaPoc;
        this.changedIVAVenda(this.model.codIvaVenda);
        this.changedIVACompra(this.model.codIvaCompra);
        this.changedNContaPocVenda(this.model.nContaPoc);
        this.changedNContaPocCompra(this.model.nContaPocCompra);
      }
    });
  }

  public changedIVAVenda(ivaVenda: string | number | IJsonIva): void {
    if (isEmpty(ivaVenda)) {
      this._changedIVAVenda(undefined);
    } else if (isString(ivaVenda) || isNumber(ivaVenda)) {
      this._ivasService
        .get({id: ivaVenda})
        .then((response: HttpResponse<IJsonIva>) => {
          this._changedIVAVenda(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          this._logger.error(reason);
          this._changedIVAVenda(undefined);
        });
    } else {
      this._changedIVAVenda(ivaVenda);
    }
  }

  public changedIVACompra(ivaCompra: string | number | IJsonIva): void {
    if (isEmpty(ivaCompra)) {
      this._changedIVACompra(undefined);
    } else if (isString(ivaCompra) || isNumber(ivaCompra)) {
      this._ivasService
        .get({id: ivaCompra})
        .then((response: HttpResponse<IJsonIva>) => {
          this._changedIVACompra(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          this._logger.error(reason);
          this._changedIVACompra(undefined);
        });
    } else {
      this._changedIVACompra(ivaCompra);
    }
  }

  public calcPrecoSemIva(value: number): void {
    this.model.precoSemIva = value;
    this.model.precoComIva = round(this.model.precoSemIva * (1 + this.taxaAtual / 100), DECIMALS_CALC_PRECOS);
  }

  public calcPrecoComIva(value: number): void {
    this.model.precoComIva = value;
    this.model.precoSemIva = round(this.model.precoComIva / (1 + this.taxaAtual / 100), DECIMALS_CALC_PRECOS);
  }

  public changedNContaPocVenda(nContaPocVenda: string | IJsonPOC): void {
    if (isEmpty(nContaPocVenda)) {
      this._changedNContaPocVenda(undefined);
    } else if (isString(nContaPocVenda)) {
      this._pocsService
        .get({id: nContaPocVenda})
        .then((response: HttpResponse<IJsonPOC>) => {
          this._changedNContaPocVenda(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          this._logger.error(reason);
          this._changedNContaPocVenda(undefined);
        });
    } else {
      this._changedNContaPocVenda(nContaPocVenda);
    }
  }

  public changedNContaPocCompra(nContaPocCompra: string | IJsonPOC): void {
    if (isEmpty(nContaPocCompra)) {
      this._changedNContaPocCompra(undefined);
    } else if (isString(nContaPocCompra)) {
      this._pocsService
        .get({id: nContaPocCompra})
        .then((response: HttpResponse<IJsonPOC>) => {
          this._changedNContaPocCompra(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          this._logger.error(reason);
          this._changedNContaPocCompra(undefined);
        });
    } else {
      this._changedNContaPocCompra(nContaPocCompra);
    }
  }

  public evaluateFilterNContaPocVenda(): void {
    this.filterNContaPocVenda = this.sujeitaAsLeisVigoramEmPortugal && !this.mostraTodasContasVenda ? `(tipo=${ETipoContaContabilidade.SubTotal})&(nconta=%7%)` : undefined;
  }

  public evaluateFilterNContaPocCompra(): void {
    this.filterNContaPocCompra =
      this.sujeitaAsLeisVigoramEmPortugal && !this.mostraTodasContasCompra
        ? `(tipo=${ETipoContaContabilidade.SubTotal})&((nconta=311)|(nconta=3121)|(nconta=3122)|(nconta=3123)|(nconta=3124)|(nconta=3125)|(nconta=313)|(nconta=432)|(nconta=433)|(nconta=434)|` +
          '(nconta=435)|(nconta=436)|(nconta=437)|(nconta=621)|(nconta=6221)|(nconta=6222)|(nconta=6223)|(nconta=6224)|(nconta=6225)|(nconta=6226)|(nconta=6228)|(nconta=6231)|(nconta=6232)|' +
          '(nconta=6233)|(nconta=6234)|(nconta=624)|(nconta=6241)|(nconta=62421111)|(nconta=6242112)|(nconta=6242113)|(nconta=624212)|(nconta=624213)|(nconta=6243)|(nconta=6248)|(nconta=6251)|' +
          '(nconta=6252)|(nconta=6253)|(nconta=6258)|(nconta=6261)|(nconta=6262)|(nconta=6263)|(nconta=6264)|(nconta=6265)|(nconta=6266)|(nconta=6267)|(nconta=6268))'
        : undefined;
  }

  public openFamiliasMaintenance(): void {
    this._maintenanceInstanceFamilias.maintenanceEdit(this.model.nFamilia);
  }

  public onDataGridInitializedLotes(event: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceLotes = event.component;
  }

  public onDataGridLotesCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonLote>): void {
    if (event.column.name === 'entityListButtons') {
      return;
    }

    if (!this.comercialAdvanced) {
      return;
    }

    this._maintenanceInstanceLotes.maintenanceEdit(event.data.nLote).then(() => {
      this._dataGridInstanceLotes.refresh();
    });
  }

  public onDataGridLotesColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'nArtigo') {
        field.visible = false;
        field.showInColumnChooser = false;
      }
      if (field.dataField === 'nomeArtigo') {
        field.visible = false;
        field.showInColumnChooser = false;
      }
    }
  }

  public tiposArtigoChanged(event: IJsonTipoArtigo): void {
    if (event) {
      this.model.nTpArt = event.codTpArt;
      this.model.nomeTpArt = event.nome;
      this._setTooltipTiposArtigo(event);
    } else {
      this.model.nTpArt = undefined;
      this.model.nomeTpArt = '';
    }
  }

  public onInitializedDetalhesPorArmazem({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceDetalArmazem = component;
  }

  public onSavedMovStock(event: IDevExpressDataGridEventOnSaved<IJsonArtigoArmazemStock>): Promise<void> {
    event.component.beginCustomLoading(undefined);
    return this._serviceARTAR
      .atualizaQuantidadesStock(this.model.nArtigo, event.changes[0].data.nArmazem, event.changes[0].data.qtdStockMaximo, event.changes[0].data.qtdStockMinimo)
      .then(() => undefined)
      .finally(() => {
        event.component.endCustomLoading();
      });
  }

  public onCellPreparedDetalhesPorArmazem(event: IDevExpressDataGridEventOnCellPrepared<IJsonArtigoArmazemStock>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'qtdStockMinimo' || event.column.dataField === 'qtdStockMaximo') {
        if (this.type !== this.stateTypes.EDIT) {
          event.cellElement.classList.add('table-not-editable-cell');
        } else {
          event.cellElement.classList.add('datagrid-editable-cell');
        }
      }
    }
  }

  public novoLote(): void {
    this._maintenanceInstanceLotes.maintenanceNew({params: {model: {nArtigo: this.model.nArtigo, nomeArtigo: this.model.nome}, nArtigo: this.model.nArtigo, nomeArtigo: this.model.nome}}).then(() => {
      this._dataGridInstanceLotes.refresh();
    });
  }

  public changedArtigoPicture(value: File): void {
    this.artigoPicture = value;
    this._changedArtigoPicture = true;
    if (!this.artigoPicture && this.originalArtigoPicture) {
      this._deletedArtigoPicture = true;
    }
  }

  public readonly fnVerSoLotesTerminados = () => (): Promise<void> => this._verSoLotesTerminados();

  public readonly fnEditarLote = (item: IJsonLote) => (): Promise<void> => this._editarLote(item);

  public readonly fnTerminarLote = (item: IJsonLote) => (): Promise<void> => this._terminarLote(item);

  public readonly fnEvaluateConfigOptionInvisible = (params: IArtigosEvaluateConfigOptionInvisibleParams): boolean => this._evaluateConfigOptionInvisible(params);

  public get tipoClassificador(): number {
    return this.configurations.gestaoComercial.Stocks.ClassificacaoArtigoTemplate;
  }

  public get canEditContaArtigoFamilia(): boolean {
    const value: number = this.configurations.acessos.erpcloud.gestaoComercial.editarContaArtigoFamilia;
    return isNumber(value) && value > 0;
  }

  protected _onConfigurationsChanged(): void {
    this.licencaStoreModePublic = this.configurations.licenca.modoCGOn;
  }

  private async _setTooltipTiposArtigo(tipoArtigo: IJsonTipoArtigo = undefined): Promise<void> {
    if (!tipoArtigo && isNumber(this.model.nTpArt) && this.model.nTpArt > 0) {
      await this._tiposArtigoService.get({id: this.model.nTpArt}).then((response: HttpResponse<IJsonTipoArtigo>) => {
        tipoArtigo = response.body;
      });
    }

    this.tooltipTipoProdInvt = tipoArtigo ? this._translateService.instant('artigos.text.tipoprod', {cod: tipoArtigo.productCategoryCode, desc: tipoArtigo.productCategoryDescricao}) : '';
  }

  private _evaluateConfigOptionInvisible({key}: IArtigosEvaluateConfigOptionInvisibleParams): boolean {
    return key === 'showContasVendaCompra' && this.type === EEntityStateDetailType.NEW;
  }

  private _changedIVAVenda(ivaVenda: IJsonIva): void {
    this._ivaVenda = ivaVenda;
    if (!ivaVenda) {
      this.model = {...this.model, codIvaVenda: undefined, nomeIvaVenda: undefined};
      this.taxaAtual = 0;
    } else {
      this.model = {...this.model, codIvaVenda: ivaVenda.codIva, nomeIvaVenda: ivaVenda.nome};
      this.taxaAtual = ivaVenda.taxaActual;
    }
    this.calcPrecoSemIva(this.model.precoSemIva);
    this._evaluateMessageContaPocFormadaVenda();
  }

  private _changedIVACompra(ivaCompra: IJsonIva): void {
    this._ivaCompra = ivaCompra;
    this.model = !ivaCompra ? {...this.model, codIvaCompra: undefined, nomeIvaCompra: undefined} : {...this.model, codIvaCompra: ivaCompra.codIva, nomeIvaCompra: ivaCompra.nome};
    this._evaluateMessageContaPocFormadaCompra();
  }

  private _changedNContaPocVenda(contaPocVenda: IJsonPOC): void {
    this._contaPocVenda = contaPocVenda;
    this.model = !contaPocVenda ? {...this.model, nContaPoc: undefined, nomeContaPoc: undefined} : {...this.model, nContaPoc: contaPocVenda.nConta, nomeContaPoc: contaPocVenda.nome};
    this._evaluateMessageContaPocFormadaVenda();
  }

  private _changedNContaPocCompra(contaPocCompra: IJsonPOC): void {
    this._contaPocCompra = contaPocCompra;
    this.model = !contaPocCompra
      ? {...this.model, nContaPocCompra: undefined, nomeContaPocCompra: undefined}
      : {...this.model, nContaPocCompra: contaPocCompra.nConta, nomeContaPocCompra: contaPocCompra.nome};
    this._evaluateMessageContaPocFormadaCompra();
  }

  private _evaluateMessageContaPocFormadaVenda(): void {
    this.messageContaPocFormadaVenda = this._buildMessageContaPocFormada(this._ivaVenda, this._contaPocVenda, false);
  }

  private _evaluateMessageContaPocFormadaCompra(): void {
    this.messageContaPocFormadaCompra = this._buildMessageContaPocFormada(this._ivaCompra, this._contaPocCompra, true);
  }

  private _buildMessageContaPocFormada(iva: IJsonIva, contaPoc: IJsonPOC, compra: boolean): string {
    if (iva && contaPoc) {
      const message: string =
        contaPoc.tipo === ETipoContaContabilidade.Movimento ? 'artigos.message.contaPocFormadaMovimento' : !compra ? 'artigos.message.contaPocFormadaVenda' : 'artigos.message.contaPocFormadaCompra';
      const nContaFormada: string = contaPoc.tipo === ETipoContaContabilidade.Movimento ? contaPoc.nConta : contaPoc.nConta + (iva.codAlternInfac || String(iva.codIva));
      return this._translateService.instant(message, {nConta: nContaFormada});
    }
    return '';
  }

  private async _navigate(callback: () => TServiceResponse<IJsonArtigo>): Promise<void> {
    if (this.type !== EEntityStateDetailType.DETAIL) {
      return;
    }

    const artigo = await callback();

    await this._stateService.go(this._transition.to(), {id: artigo.body.nArtigo});
  }

  private _goToFirstArtigo(): Promise<void> {
    return this._navigate(() => this.service.getFirst());
  }

  private _goToPreviousArtigo(): Promise<void> {
    return this._navigate(() => this.service.getPrev(this.model.nArtigo));
  }

  private _goToNextArtigo(): Promise<void> {
    return this._navigate(() => this.service.getNext(this.model.nArtigo));
  }

  private _goToLastArtigo(): Promise<void> {
    return this._navigate(() => this.service.getLast());
  }

  private _verSoLotesTerminados(): Promise<void> {
    this.lotesAtivos = !this.lotesAtivos;
    this.lotesfilter = this.lotesAtivos ? `nArtigo=${this.model.nArtigo}&terminado=0` : `nArtigo=${this.model.nArtigo}&terminado=1`;
    return this._dataGridInstanceLotes.refresh();
  }

  private _editarLote(lote?: IJsonLote): Promise<void> {
    return this._maintenanceInstanceLotes.maintenanceEdit(lote.nLote).then(() => {
      return this._dataGridInstanceLotes.refresh();
    });
  }

  private _terminarLote(lote?: IJsonLote): Promise<void> {
    return this._cgModalService
      .showOkCancel('lotes.okCancel.terminar.title', this._plTranslateService.translate('lotes.okCancel.terminar.message', {lote: lote.nLoteEspecifico, nartigo: lote.nArtigo}), {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa-lock',
        btnOkText: 'lotes.list.actions.terminar'
      })
      .then(() => {
        return this._lotesService
          .terminarLote(lote.nLote)
          .then(() => {
            this._dataGridInstanceLotes.refresh();
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
          });
      });
  }

  private _getArtigoPicture(nArtigo: string): Promise<File> {
    return this.service.getPicture(nArtigo, {reportExceptions: false}).then((response: HttpResponse<File>) => {
      if (response.status === EStatusCode.OK) {
        return response.body;
      }
      return undefined;
    });
  }

  private _resetPictureVars(): void {
    this._changedArtigoPicture = false;
    this._deletedArtigoPicture = false;
  }
}
