import {IJsonManal, IJsonManalPocNomes} from './jsonMascaraAnalitica.entity.interface';

export enum EMascaraAnaliticaTipoMascara {
  Facturacao,
  Salarios
}

export enum EMascaraAnaliticaTipoCamposFacturacao {
  Constante0 = '0',
  Constante1 = '1',
  Constante2 = '2',
  Constante3 = '3',
  Constante4 = '4',
  Constante5 = '5',
  Constante6 = '6',
  Constante7 = '7',
  Constante8 = '8',
  Constante9 = '9',
  CentroCusto = 'C',
  Zona = 'Z',
  Departamento = 'D',
  SubDepartamento = 'S',
  Familia = 'F',
  GrandeFamilia = 'G',
  SubFamilia = 'K',
  CodContab = 'B',
  TipoArtigo = 'T',
  Classe = 'X',
  Categoria = 'Y',
  Vendedor = 'V',
  Processo = 'P'
}

export enum EMascaraAnaliticaTipoCamposSalarios {
  Constante0 = '0',
  Constante1 = '1',
  Constante2 = '2',
  Constante3 = '3',
  Constante4 = '4',
  Constante5 = '5',
  Constante6 = '6',
  Constante7 = '7',
  Constante8 = '8',
  Constante9 = '9',
  CentroCusto = 'C',
  CodContab = 'B',
  ClasseTrabalhador = 'X',
  Funcao = 'F',
  CCustoFuncionario = 'D',
  CategoriaFamilia = 'P',
  Zona = 'Z',
  Departamento = 'V'
}

export enum EMascaraAnaliticaListNameType {
  FIELD = 'mascarasanalitica.table.field',
  TEXT = 'mascarasanalitica.table.text'
}

export interface IMascaraAnalitica extends IJsonManal {
  manalPocNomes: Array<IJsonManalPocNomes>;
}

export interface IMascaraAnaliticaDefinition {
  char: string;
  name: string;
  id?: number;
  indice?: number;
  isConstant?: boolean;
  isIndice?: boolean;
}

export interface IMascaraAnaliticaPocNomes extends IJsonManalPocNomes {
  char: string;
  content: string;
  type: EMascaraAnaliticaListNameType;
}

export const ENTITY_NAME_MASCARAS_ANALITICA = 'mascarasanalitica';
