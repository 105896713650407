import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ENTITY_NAME_FORNECEDORES, IClifo, IClifosEntityService} from '../../../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_NATUREZAS_CONTAB} from '../../../../../entities/naturezascontab/naturezasContab.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IImportadorEfaturaItem} from '../../importadorEfatura.module.interface';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonNaturezaContab} from '../../../../../entities/naturezascontab/jsonNaturezaContab.entity.interface';
import {EFacPublicaModelo} from '../../../../faturacaopublica/jsonFaturacaoPublica.module.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'create-new-conta-corrente',
  templateUrl: './createNewContaCorrente.modal.component.html'
})
export class CreateNewContaCorrenteModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public importadorEfaturaItem: IImportadorEfaturaItem;
  public model: IClifo;

  private readonly _serviceNaturezasContab: IEntityService<IJsonNaturezaContab>;
  private readonly _serviceClifo: IClifosEntityService;
  private readonly _maintenanceInstanceFornecedores: IEntityMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    if (!this.model) {
      this.model = {
        abrevFiscal: '',
        codCondComerciais: undefined,
        codPagamento: 0,
        codPais: 0,
        codPostal: '',
        codRet: '',
        email: '',
        fax: '',
        iban: '',
        id: '',
        localidade: '',
        lugar: '',
        naturezaContab: undefined,
        nConta: '',
        nContribuint: '',
        nIdAltern: 0,
        nome: '',
        nomeCondComerciais: '',
        nomePagamento: '',
        nomePais: '',
        observacoes: '',
        observacoes2: '',
        rua: '',
        segNome: '',
        situacaoActual: '',
        telefone: '',
        facPublicaModelo: EFacPublicaModelo.ND,
        telemovel: undefined,
        nomeRet: undefined
      };
    }
    this._maintenanceInstanceFornecedores = this._entityMaintenanceService.build(ENTITY_NAME_FORNECEDORES);
    this._serviceNaturezasContab = this._entityServiceBuilder.build<IJsonNaturezaContab>(ENTITY_NAME_NATUREZAS_CONTAB);
    this._serviceClifo = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_FORNECEDORES);
  }

  public ngOnInit(): void {
    this.model.nContribuint = this.importadorEfaturaItem.nifEmitente;
    this.model.nome = this.importadorEfaturaItem.nomeEmitente.substring(0, 100);
    this._serviceNaturezasContab.query({pesquisa: `clifoType=${ENTITY_NAME_FORNECEDORES}`}).then((response: THttpQueryResponse<IJsonNaturezaContab>) => {
      if (response.body.list.length) {
        this.model.naturezaContab = response.body.list[0];
        this._serviceClifo.proximaconta(this.model.naturezaContab.radical, this.model.naturezaContab.nDigitosSequencia).then((responseProximaConta: HttpResponse<string>) => {
          this.model.nConta = responseProximaConta.body;
        });
      }
    });
  }

  public naturezaContabChange(event: IJsonNaturezaContab): void {
    this._serviceClifo.proximaconta(event.radical, event.nDigitosSequencia).then((response: HttpResponse<string>) => {
      this.model.nConta = response.body;
    });
  }

  public close(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._serviceClifo
        .post({body: this.model})
        .then((response: HttpResponse<IJsonClifo>) => {
          const title = 'importadorEfatura.modal.createNewContaCorrente.confirmModal.confirmTitle';
          const message = 'importadorEfatura.modal.createNewContaCorrente.confirmModal.confirmMessage';
          this._cgModalService
            .showOkCancel(title, message)
            .then(() => {
              this._maintenanceInstanceFornecedores.maintenanceEdit(response.body.id).finally(() => {
                resolve();
                super.close();
              });
            })
            .catch(() => {
              resolve();
              super.close();
            });
        })
        .catch((reason: HttpErrorResponse) => {
          reject(reason);
        });
    });
  }
}
