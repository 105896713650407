import {Observable, Subscriber} from 'rxjs';
import {HttpHeaders, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {ERadix} from '../enums';
import type {IFileDialogOptions, IToFormDataOptions} from './files.interface';
import {isArray, isBlob, isBoolean, isDate, isEmpty, isFile, isFormData, isNull, isObject, isString, isUndefined, toInteger} from '../utilities/utilities';
import {isMoment} from '../dates/moment.utils';

const headerContentDisposition = 'Content-Disposition';
const headerContentType = 'Content-Type';
const hexFF = 0xff;
const compressedFileExtensions = [
  '.0',
  '.000',
  '.7Z',
  '.7Z.001',
  '.7Z.002',
  '.A00',
  '.A01',
  '.A02',
  '.ACE',
  '.AGG',
  '.AIN',
  '.ALZ',
  '.APZ',
  '.AR',
  '.ARC',
  '.ARCHIVER',
  '.ARDUBOY',
  '.ARH',
  '.ARI',
  '.ARJ',
  '.ARK',
  '.ASR',
  '.B1',
  '.B64',
  '.B6Z',
  '.BA',
  '.BDOC',
  '.BH',
  '.BNDL',
  '.BOO',
  '.BUNDLE',
  '.BZ',
  '.BZ2',
  '.BZA',
  '.BZIP',
  '.BZIP2',
  '.C00',
  '.C01',
  '.C02',
  '.C10',
  '.CAR',
  '.CB7',
  '.CBA',
  '.CBR',
  '.CBT',
  '.CBZ',
  '.CDZ',
  '.COMPPKG.HAUPTWERK.RAR',
  '.COMPPKG_HAUPTWERK_RAR',
  '.CP9',
  '.CPGZ',
  '.CPT',
  '.CTX',
  '.CTZ',
  '.CXARCHIVE',
  '.CZIP',
  '.DAF',
  '.DAR',
  '.DD',
  '.DEB',
  '.DGC',
  '.DIST',
  '.DL_',
  '.DZ',
  '.ECS',
  '.ECSBX',
  '.EDZ',
  '.EFW',
  '.EGG',
  '.EPI',
  '.F',
  '.F3Z',
  '.FDP',
  '.FP8',
  '.FZBZ',
  '.FZPZ',
  '.GCA',
  '.GMZ',
  '.GZ',
  '.GZ2',
  '.GZA',
  '.GZI',
  '.GZIP',
  '.HA',
  '.HBC',
  '.HBC2',
  '.HBE',
  '.HKI',
  '.HKI1',
  '.HKI2',
  '.HKI3',
  '.HPK',
  '.HPKG',
  '.HYP',
  '.IADPROJ',
  '.ICE',
  '.IPG',
  '.IPK',
  '.ISH',
  '.ISX',
  '.ITA',
  '.IZE',
  '.J',
  '.JAR.PACK',
  '.JGZ',
  '.JIC',
  '.JSONLZ4',
  '.KGB',
  '.KZ',
  '.LAYOUT',
  '.LBR',
  '.LEMON',
  '.LHA',
  '.LHZD',
  '.LIBZIP',
  '.LNX',
  '.LPKG',
  '.LQR',
  '.LZ',
  '.LZH',
  '.LZM',
  '.LZMA',
  '.LZO',
  '.LZX',
  '.MBZ',
  '.MD',
  '.MINT',
  '.MOU',
  '.MPKG',
  '.MZP',
  '.MZP',
  '.NEX',
  '.NPK',
  '.NZ',
  '.OAR',
  '.OPK',
  '.OZ',
  '.P01',
  '.P19',
  '.P7Z',
  '.PA',
  '.PACK.GZ',
  '.PACKAGE',
  '.PAE',
  '.PAK',
  '.PAQ6',
  '.PAQ7',
  '.PAQ8',
  '.PAQ8F',
  '.PAQ8L',
  '.PAQ8P',
  '.PAR',
  '.PAR2',
  '.PAX',
  '.PBI',
  '.PCV',
  '.PEA',
  '.PET',
  '.PF',
  '.PIM',
  '.PIT',
  '.PIZ',
  '.PKG',
  '.PKG.TAR.XZ',
  '.PRS',
  '.PSZ',
  '.PUP',
  '.PUP',
  '.PUZ',
  '.PVMZ',
  '.PWA',
  '.QDA',
  '.R0',
  '.R00',
  '.R01',
  '.R02',
  '.R03',
  '.R04',
  '.R1',
  '.R2',
  '.R21',
  '.R30',
  '.RAR',
  '.REV',
  '.RK',
  '.RNC',
  '.RP9',
  '.RPM',
  '.RSS',
  '.RTE',
  '.RZ',
  '.S00',
  '.S01',
  '.S02',
  '.S09',
  '.S7Z',
  '.SAR',
  '.SBX',
  '.SBX',
  '.SDC',
  '.SDN',
  '.SEA',
  '.SEN',
  '.SFG',
  '.SFS',
  '.SFX',
  '.SH',
  '.SHAR',
  '.SHK',
  '.SHR',
  '.SIFZ',
  '.SIT',
  '.SITX',
  '.SMPF',
  '.SNAPPY',
  '.SNB',
  '.SPD',
  '.SPL',
  '.SPM',
  '.SPT',
  '.SQX',
  '.SQZ',
  '.SREP',
  '.STPROJ',
  '.SY_',
  '.TAR.BZ2',
  '.TAR.GZ',
  '.TAR.GZ2',
  '.TAR.LZ',
  '.TAR.LZMA',
  '.TAR.XZ',
  '.TAR.Z',
  '.TAZ',
  '.TBZ',
  '.TBZ2',
  '.TCX',
  '.TG',
  '.TGZ',
  '.TLZ',
  '.TLZMA',
  '.TRS',
  '.TX_',
  '.TXZ',
  '.TZ',
  '.TZST',
  '.UC2',
  '.UFS.UZIP',
  '.UHA',
  '.UZIP',
  '.VEM',
  '.VFS',
  '.VIP',
  '.VOCA',
  '.VPK',
  '.VSI',
  '.WA',
  '.WAFF',
  '.WAR',
  '.WDZ',
  '.WHL',
  '.WLB',
  '.WOT',
  '.WUX',
  '.XAPK',
  '.XAR',
  '.XEF',
  '.XEZ',
  '.XIP',
  '.XMCDZ',
  '.XX',
  '.XZ',
  '.XZM',
  '.Y',
  '.YZ',
  '.YZ1',
  '.Z',
  '.Z01',
  '.Z02',
  '.Z03',
  '.Z04',
  '.ZAP',
  '.ZFSENDTOTARGET',
  '.ZI',
  '.ZI_',
  '.ZIP',
  '.ZIPX',
  '.ZIX',
  '.ZL',
  '.ZOO',
  '.ZPI',
  '.ZSPLIT',
  '.ZST',
  '.ZW',
  '.ZZ'
];

export function toFormData(value: any, options?: IToFormDataOptions, formData?: FormData): FormData {
  if (isFormData(value)) {
    return value;
  }
  options = {
    booleanAsInteger: false,
    indices: true,
    nullAsUndefined: true,
    ...options
  };
  if (!isFormData(formData)) {
    formData = new FormData();
  }
  const valueToFormData = (val: any, parentKey?: string): void => {
    if (isUndefined(val)) {
      return;
    }
    if (isNull(val)) {
      if (!options.nullAsUndefined) {
        formData.append(parentKey, '');
      }
    } else if (isBoolean(val)) {
      if (options.booleanAsInteger) {
        formData.append(parentKey, String(val ? 1 : 0));
      } else {
        formData.append(parentKey, String(val));
      }
    } else if (isArray(val)) {
      if (val.length) {
        for (let index = 0; index < val.length; index++) {
          const item: any = val[index];
          const key = `${parentKey}[${options.indices ? index : ''}]`;
          valueToFormData(item, key);
        }
      }
    } else if (isMoment(val) || isDate(val)) {
      formData.append(parentKey, val.toISOString());
    } else if (isObject(val) && !isFile(val) && !isBlob(val)) {
      for (let prop of Object.keys(val)) {
        const propValue: any = val[prop];
        if (isArray(propValue)) {
          while (prop.length > 2 && prop.endsWith('[]')) {
            prop = prop.substring(0, prop.length - 2);
          }
        }
        const key: string = parentKey ? `${parentKey}[${prop}]` : prop;
        valueToFormData(propValue, key);
      }
    } else {
      formData.append(parentKey, val);
    }
  };
  valueToFormData(value);
  return formData;
}

export function newFile(fileBits: BlobPart | Array<BlobPart>, fileName: string, options?: FilePropertyBag): File {
  const bits: Array<BlobPart> = isArray(fileBits) ? fileBits : [fileBits];
  try {
    return new File(bits, fileName, options);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (ignored: unknown) {
    let file: any = new Blob(bits, options);
    file.name = fileName;
    file.lastModified = Date.now();
    file.lastModifiedDate = new Date(file.lastModified);
    file = Object.setPrototypeOf(file, File.prototype);
    return <File>file;
  }
}

export function fileExtensionToMimeType(fileExtension: string): string {
  if (fileExtension.startsWith('.')) {
    fileExtension = fileExtension.slice(1);
  }
  switch (fileExtension) {
    case 'aac':
      return 'audio/aac';
    case 'abw':
      return 'application/x-abiword';
    case 'arc':
      return 'application/x-freearc';
    case 'avi':
      return 'video/x-msvideo';
    case 'azw':
      return 'application/vnd.amazon.ebook';
    case 'bin':
      return 'application/octet-stream';
    case 'bmp':
      return 'image/bmp';
    case 'bz':
      return 'application/x-bzip';
    case 'bz2':
      return 'application/x-bzip2';
    case 'csh':
      return 'application/x-csh';
    case 'css':
      return 'text/css';
    case 'csv':
      return 'text/csv';
    case 'doc':
      return 'application/msword';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'eot':
      return 'application/vnd.ms-fontobject';
    case 'epub':
      return 'application/epub+zip';
    case 'gz':
      return 'application/gzip';
    case 'gif':
      return 'image/gif';
    case 'htm':
    case 'html':
      return 'text/html';
    case 'ico':
      return 'image/vnd.microsoft.icon';
    case 'ics':
      return 'text/calendar';
    case 'jar':
      return 'application/java-archive';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'js':
    case 'mjs':
      return 'text/javascript';
    case 'json':
      return 'application/json';
    case 'jsonld':
      return 'application/ld+json';
    case 'mid':
    case 'midi':
      return 'audio/midi';
    case 'mp3':
      return 'audio/mpeg';
    case 'mpeg':
      return 'video/mpeg';
    case 'mpkg':
      return 'application/vnd.apple.installer+xml';
    case 'odp':
      return 'application/vnd.oasis.opendocument.presentation';
    case 'ods':
      return 'application/vnd.oasis.opendocument.spreadsheet';
    case 'odt':
      return 'application/vnd.oasis.opendocument.text';
    case 'oga':
      return 'audio/ogg';
    case 'ogv':
      return 'video/ogg';
    case 'ogx':
      return 'application/ogg';
    case 'otf':
      return 'font/otf';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    case 'php':
      return 'application/php';
    case 'ppt':
      return 'application/vnd.ms-powerpoint';
    case 'pptx':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'rar':
      return 'application/x-rar-compressed';
    case 'rtf':
      return 'application/rtf';
    case 'sh':
      return 'application/x-sh';
    case 'svg':
      return 'image/svg+xml';
    case 'swf':
      return 'application/x-shockwave-flash';
    case 'tar':
      return 'application/x-tar';
    case 'tif':
    case 'tiff':
      return 'image/tiff';
    case 'ts':
      return 'video/mp2t';
    case 'ttf':
      return 'font/ttf';
    case 'txt':
      return 'text/plain';
    case 'vsd':
      return 'application/vnd.visio';
    case 'wav':
      return 'audio/wav';
    case 'weba':
      return 'audio/webm';
    case 'webm':
      return 'video/webm';
    case 'webp':
      return 'image/webp';
    case 'woff':
      return 'font/woff';
    case 'woff2':
      return 'font/woff2';
    case 'xhtml':
      return 'application/xhtml+xml';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xml':
      return 'text/xml';
    case 'xul':
      return 'application/vnd.mozilla.xul+xml';
    case 'zip':
      return 'application/zip';
    case '3gp':
      return 'video/3gpp';
    case '3g2':
      return 'video/3gpp2';
    case '7z':
      return 'application/x-7z-compressed';
    default:
      return fileExtension;
  }
}

export function isFileOfCompressedType(file: File): boolean {
  const filename: string = file.name;
  const index = filename.lastIndexOf('.');
  if (index === -1) {
    return false;
  }
  const extension: string = filename.slice(index + 1, filename.length).toUpperCase();
  return compressedFileExtensions.includes(extension);
}

export function dataURIToBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString: string = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString: string = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array: Uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0, end = byteString.length, asc = end >= 0; asc ? i <= end : i >= end; asc ? i++ : i--) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob
  return new Blob([arrayBuffer], {type: mimeString});
}

export function blobToDataURI(blob: Blob): Observable<string> {
  return new Observable<string>((subscriber: Subscriber<string>) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const dataURL: string = <string>fileReader.result;
      subscriber.next(dataURL);
    };
    fileReader.onerror = (error: ProgressEvent<FileReader>) => {
      subscriber.error(error);
    };
    fileReader.onabort = (event: ProgressEvent<FileReader>) => {
      subscriber.error(event);
      subscriber.complete();
    };
    fileReader.readAsDataURL(blob);
  });
}

export function downloadStream(value: Blob | File | HttpResponse<Blob | File>, filename?: string): void {
  let response: HttpResponse<Blob | File>;
  if (value instanceof HttpResponse) {
    response = value;
    value = response.body;
  }
  if (!isBlob(value)) {
    return;
  }
  if (isEmpty(filename)) {
    if (isFile(value)) {
      filename = value.name;
    } else if (response) {
      filename = extractContentDispositionFilename(response.headers);
      if (!filename) {
        const end: number = response.url.indexOf('?');
        filename = response.url.slice(response.url.lastIndexOf('/') + 1, end !== -1 ? end : response.url.length);
      }
    }
  }
  saveAs(value, filename, {autoBom: true});
}

export function extractContentDispositionFilename(value: string | HttpHeaders | HttpResponseBase): string {
  if (value instanceof HttpResponseBase) {
    value = value.headers.get(headerContentDisposition);
  } else if (value instanceof HttpHeaders) {
    value = value.get(headerContentDisposition);
  }
  if (!isEmpty(value)) {
    value = getFilenameFromContentDispositionHeader(value);
    if (value.includes('%')) {
      try {
        value = decodeURIComponent(value);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (ignored: unknown) {
        // ignore exception if any
      }
    }
    return value;
  }
  return '';
}

export function extractContentType(value: string | HttpHeaders | HttpResponseBase): string {
  if (value instanceof HttpResponseBase) {
    value = value.headers.get(headerContentType);
  } else if (value instanceof HttpHeaders) {
    value = value.get(headerContentType);
  }
  if (isString(value) && value) {
    return value.substring(0, value.indexOf(';'));
  }
  return '';
}

/* eslint-disable no-control-regex */

export function getFilenameFromContentDispositionHeader(contentDisposition: string): string {
  let needsEncodingFixup = true;

  // filename*=ext-value ("ext-value" from RFC 5987, referenced by RFC 6266).
  let regExpExecArray: RegExpExecArray = toParamRegExp('filename\\*', 'i').exec(contentDisposition);
  if (regExpExecArray) {
    let filename = rfc2616Unquote(regExpExecArray[1]);
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    filename = unescape(filename);
    filename = rfc5987Decode(filename);
    filename = rfc2047Decode(filename);
    return fixupEncoding(filename);
  }

  // Continuations (RFC 2231 section 3, referenced by RFC 5987 section 3.1).
  // filename*n*=part
  // filename*n=part
  const param: string = rfc2231GetParam(contentDisposition);
  if (param) {
    // RFC 2047, section
    const filename = rfc2047Decode(param);
    return fixupEncoding(filename);
  }

  // filename=value (RFC 5987, section 4.1).
  regExpExecArray = toParamRegExp('filename', 'i').exec(contentDisposition);
  if (regExpExecArray) {
    let filename = rfc2616Unquote(regExpExecArray[1]);
    filename = rfc2047Decode(filename);
    return fixupEncoding(filename);
  }

  function toParamRegExp(attributePattern: string, flags: string): RegExp {
    return new RegExp(
      `(?:^|;)\\s*${attributePattern}\\s*=\\s*` +
        // Captures: value = token | quoted-string
        // (RFC 2616, section 3.6 and referenced by RFC 6266 4.1)
        '(' +
        '[^";\\r\\n][^;\\r\\n]*' +
        '|' +
        '"(?:[^"\\\\]|\\\\"?)+"?' +
        ')',
      flags
    );
  }

  function textDecode(encoding: string, value: string): string {
    if (encoding) {
      if (!/^[\x00-\xFF]+$/.test(value)) {
        return value;
      }
      try {
        const decoder: TextDecoder = new TextDecoder(encoding, {fatal: true});
        // eslint-disable-next-line no-bitwise
        const bytes: Array<number> = Array.from<string, number>(value, (ch: string) => ch.charCodeAt(0) & hexFF);
        value = decoder.decode(new Uint8Array(bytes));
        needsEncodingFixup = false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (exception: unknown) {
        // TextDecoder constructor threw - unrecognized encoding.
        // Or TextDecoder API is not available (in IE / Edge).
        if (/^utf-?8$/i.test(encoding)) {
          // UTF-8 is commonly used, try to support it in another way:
          try {
            // eslint-disable-next-line @typescript-eslint/no-deprecated
            value = decodeURIComponent(escape(value));
            needsEncodingFixup = false;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (ignored: unknown) {
            // ignored
          }
        }
      }
    }
    return value;
  }

  function fixupEncoding(value: string): string {
    if (needsEncodingFixup && /[\x80-\xff]/.test(value)) {
      // Maybe multibyte UTF-8.
      value = textDecode('utf-8', value);
      if (needsEncodingFixup) {
        // Try iso-8859-1 encoding.
        value = textDecode('iso-8859-1', value);
      }
    }
    return value;
  }

  function rfc2231GetParam(value: string): string {
    // Iterate over all filename*n= and filename*n*= with n being an integer
    // of at least zero. Any non-zero number must not start with '0'.
    const iter: RegExp = toParamRegExp('filename\\*((?!0\\d)\\d+)(\\*?)', 'ig');
    const matches = [];
    let match: RegExpExecArray = iter.exec(value);
    while (match !== null) {
      const [, n, quot, part] = match;
      const index: number = toInteger(n);
      if (index in matches) {
        // Ignore anything after the invalid second filename*0.
        if (index === 0) {
          break;
        }
        continue;
      }
      matches[index] = [quot, part];
      match = iter.exec(value);
    }
    const parts = [];
    for (let i = 0; i < matches.length; ++i) {
      if (!(i in matches)) {
        // Numbers must be consecutive. Truncate when there is a hole.
        break;
      }
      // eslint-disable-next-line prefer-const
      let [quot, part] = matches[i];
      part = rfc2616Unquote(part);
      if (quot) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        part = unescape(part);
        if (i === 0) {
          part = rfc5987Decode(part);
        }
      }
      parts.push(part);
    }
    return parts.join('');
  }

  function rfc2616Unquote(value: string): string {
    if (value.startsWith('"')) {
      const parts: Array<string> = value.slice(1).split('\\"');
      // Find the first unescaped " and terminate there.
      for (let i = 0; i < parts.length; ++i) {
        const quoteIndex: number = parts[i].indexOf('"');
        if (quoteIndex !== -1) {
          parts[i] = parts[i].slice(0, quoteIndex);
          parts.length = i + 1; // Truncates and stop the iteration.
        }
        parts[i] = parts[i].replace(/\\(.)/g, '$1');
      }
      value = parts.join('"');
    }
    return value;
  }

  function rfc5987Decode(extensionValue: string): string {
    // Decodes "ext-value" from RFC 5987.
    const encodingEnd: number = extensionValue.indexOf("'");
    if (encodingEnd === -1) {
      // Some servers send "filename*=" without encoding 'language' prefix,
      // e.g. in https://github.com/Rob--W/open-in-browser/issues/26
      // Let's accept the value like Firefox (57) (Chrome 62 rejects it).
      return extensionValue;
    }
    const encoding: string = extensionValue.slice(0, encodingEnd);
    const language: string = extensionValue.slice(encodingEnd + 1);
    // Ignore language (RFC 5987 section 3.2.1, and RFC 6266 section 4.1).
    const value: string = language.replace(/^[^']*'/, '');
    return textDecode(encoding, value);
  }

  function rfc2047Decode(value: string): string {
    // RFC 2047-decode the result. Firefox tried to drop support for it, but
    // backed out because some servers use it - https://bugzil.la/875615
    // Firefox's condition for decoding is here: https://searchfox.org/mozilla-central/rev/4a590a5a15e35d88a3b23dd6ac3c471cf85b04a8/netwerk/mime/nsMIMEHeaderParamImpl.cpp#742-748

    // We are more strict and only recognize RFC 2047-encoding if the value
    // starts with "=?", since then it is likely that the full value is
    // RFC 2047-encoded.

    // Firefox also decodes words even where RFC 2047 section 5 states:
    // "An 'encoded-word' MUST NOT appear within a 'quoted-string'."
    if (!value.startsWith('=?') || /[\x00-\x19\x80-\xff]/.test(value)) {
      return value;
    }
    // RFC 2047, section 2.4
    // encoded-word = "=?" charset "?" encoding "?" encoded-text "?="
    // charset = token (but let's restrict to characters that denote a
    //       possibly valid encoding).
    // encoding = q or b
    // encoded-text = any printable ASCII character other than ? or space.
    //        ... but Firefox permits ? and space.
    return value.replace(/[=]\?([\w-]*)\?([QqBb])\?((?:[^?]|\?(?!=))*)\?=/g, (substring, charset, encoding, text) => {
      if (encoding === 'q' || encoding === 'Q') {
        // RFC 2047 section 4.2.
        text = text.replace(/_/g, ' ');
        text = text.replace(/[=]([0-9a-fA-F]{2})/g, (substring2: string, hex: string) => {
          return String.fromCharCode(parseInt(hex, ERadix.Hexadecimal));
        });
        return textDecode(charset, text);
      } // else encoding is b or B - base64 (RFC 2047 section 4.1)
      try {
        text = window.atob(text);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (ignored: unknown) {
        // ignored
      }
      return textDecode(charset, text);
    });
  }

  return '';
}

/* eslint-enable no-control-regex */

export function fileDialog(options?: IFileDialogOptions): FileList {
  const elementInputFile: HTMLInputElement = window.document.createElement('input');
  elementInputFile.type = 'file';
  elementInputFile.classList.add('d-none');
  if (isObject(options)) {
    if (!isEmpty(options.accept)) {
      elementInputFile.accept = options.accept;
    }
    if (!isEmpty(options.capture)) {
      (<any>elementInputFile).capture = isBoolean(options.capture) && options.capture ? true : options.capture;
    }
    if (!isEmpty(options.files)) {
      elementInputFile.files = options.files;
    }
    if (isBoolean(options.multiple)) {
      elementInputFile.multiple = options.multiple;
    }
  }
  window.document.body.appendChild(elementInputFile);
  elementInputFile.click();
  const result: FileList = elementInputFile.files;
  elementInputFile.remove();
  return result;
}
