import {NgModule} from '@angular/core';
import {PlRecaptchaFormsModule} from './forms/recaptcha.forms.module';

export * from './recaptcha.interface';
export * from './recaptcha.service';
export * from './recaptcha.tokens';
export * from './v2/recaptcha.v2.module';
export * from './v3/recaptcha.v3.module';
export * from './forms/recaptcha.forms.module';

@NgModule({
  imports: [
    PlRecaptchaFormsModule
  ],
  exports: [
    PlRecaptchaFormsModule
  ]
})
export class PlRecaptchaModule {
}
