<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'empresas.messages.uploadLogo'"></h4>
  </div>

  <div class="modal-body">
    <pl-upload
      [url]="uploadUrl"
      [maxFileSize]="maxFileSize"
      [acceptedFiles]="acceptedFiles"
      [maxFiles]="1"
      [autoProcessQueue]="false"
      [hideGlobalActions]="true"
      [hideActionUpload]="true"
      [hideActionRetry]="true"
      [hideActionCancel]="true"
      [callback]="plUploadCallback"
      [resizeWidth]="resizeWidth"
      [zip]="true">
    </pl-upload>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
