<div class="rhsaltotrf-dadostransf-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhsaltotrf.modal.title'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-group>
      <edit>
        <label [translate]="'rhsaltotrf.modal.fields.dataTransf'"></label>
        <pl-edit type="date" [(model)]="dataTransf"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'rhsaltotrf.modal.fields.nContaPoc'"></label>
      <edit>
        <entity-autocomplete
          entity="pocs"
          attrName="nContaPoc"
          [selectedKey]="nContaPoc"
          (selectedKeyChange)="nContaPoc = $event; nContaPocChanged()"
          (evtSelectedDescriptionChanged)="nomeContaPoc = $event"
          [properties]="{validators: {required: {value: true}}}"
          [fieldsMap]="{nConta: 'nContaPoc', nome: 'nomeContaPoc'}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <div class="mt-3 mb-4">
      <div *ngIf="showDadosTransf">
        <div class="text-center">
          <label>{{ dadosTransf.bancoDesc }}</label>
        </div>
        <ul class="list-group list-group-horizontal">
          <li class="list-group-item">{{ dadosTransf.bancoEmi }}</li>
          <li class="list-group-item">{{ dadosTransf.agenciaEmi }}</li>
          <li class="list-group-item flex-grow-1 text-center">{{ dadosTransf.ibanEmi }}</li>
          <li class="list-group-item">{{ dadosTransf.swiftEmi }}</li>
          <li class="list-group-item">{{ dadosTransf.moedaEmi }}</li>
        </ul>
      </div>
    </div>

    <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="definition.dataSource" (onInitialized)="onInitialized($event)">
      <div *dxTemplate="let item of 'actions'" class="text-center">
        <pl-tooltip [config]="{text: 'rhsaltotrf.messages.corrigecartao', placement: 'left', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-primary btn-xs" (click)="corrigeCartao(item.data)" plPromise>
            <i class="fa fa-fw fa-check"></i>
          </button>
        </pl-tooltip>
        <pl-tooltip [config]="{text: 'rhsaltotrf.messages.removetrans', placement: 'left', container: 'body', tooltipClass: 'tooltip-danger'}">
          <button type="button" *plTooltipContent class="btn btn-danger btn-xs" (click)="removeTransf(item.data)" plPromise>
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </pl-tooltip>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success btn-sm action-save" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
