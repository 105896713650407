import {IApiJobStatus} from '../../../states/account/companystatus/companystatus.interface';

export enum EFimAnoState {
  Inactive = 'fasInactive',
  Scheduled = 'fasScheduled',
  Started = 'fasStarted',
  Ended = 'fasEnded'
}

export interface IJsonFimAnoStatus extends IApiJobStatus<EFimAnoState> {}
