import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22Rosto} from '../../../../../jsonModelo22.module.interface';
import {IRostoQuadro14List} from '../../../../../modelo22.module.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';

@Component({
  selector: 'modal-modelo22-rosto-quadro14',
  templateUrl: './modelo22.rosto.quadro14.modal.component.html'
})
export class Modelo22RostoQuadro14ModalComponent extends CGModalComponent<IJsonModelo22Rosto> {
  @Input() public rosto: IJsonModelo22Rosto;

  public promise: Promise<void>;
  public codPais: string;
  public tipoRendimentos: string;
  public saldoNaoDeduzido: number;
  public impostoPago: number;
  public fraccaoImposto: number;
  public creditoDeImposto: number;
  public deducaoEfetuada: number;
  public saldoTransita: number;
  public periodo: number;
  public saldoCaducado: number;
  public paisComCDT: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _modelo22Service: Modelo22Service
  ) {
    super(_injector);
    this.codPais = '';
    this.tipoRendimentos = '';
    this.saldoNaoDeduzido = 0;
    this.fraccaoImposto = 0;
    this.creditoDeImposto = 0;
    this.deducaoEfetuada = 0;
    this.saldoTransita = 0;
    this.periodo = 0;
    this.saldoCaducado = 0;
  }

  public close(): Promise<void> {
    const index = this.rosto.quadro14.list.findIndex((value: IRostoQuadro14List) => value.codPais === this.codPais);
    if (index !== -1) {
      if (this.rosto.quadro14.list[index].tipoRendimentos === this.tipoRendimentos) {
        this._plAlertService.error('modelo22.messages.paisTipoRendAlredyExists');
        return Promise.resolve();
      }
    }
    this.rosto.quadro14.list.push({
      tipoRendimentos: this.tipoRendimentos,
      fraccaoImposto: this.fraccaoImposto,
      impostoPago: this.impostoPago,
      saldoNaoDeduzido: this.saldoNaoDeduzido,
      creditoDeImposto: this.creditoDeImposto,
      codPais: this.codPais,
      saldoTransita: this.saldoTransita,
      deducaoEfetuada: this.deducaoEfetuada,
      periodo: this.periodo,
      saldoCaducado: this.saldoCaducado,
      paisComCDT: this.paisComCDT
    });
    this.promise = this._modelo22Service.calculaRosto(this.rosto).then((response: HttpResponse<IJsonModelo22Rosto>) => {
      super.close(response.body);
    });
    return this.promise;
  }
}
