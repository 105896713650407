import {Ng2StateDeclaration} from '@uirouter/angular';
import {DisconnectedComponent} from './disconnected.state.component';
import {STATE_NAME_DISCONNECTED} from './disconnected.state.interface';
import {STATE_NAME_SITE} from '../../../../common/site';

export const STATE_DISCONNECTED: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_DISCONNECTED,
  url: `/${STATE_NAME_DISCONNECTED}`,
  component: DisconnectedComponent,
  data: {
    pageTitle: 'disconnected.title',
    disableRecover: true
  }
};
