import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQPTCO} from './jsonQPTCO.entity.interface';
import {ENTITY_NAME_QPTCO} from './qptco.entity.interface';

export const ENTITY_QPTCO: IEntityDefinition<IJsonQPTCO> = {
  name: ENTITY_NAME_QPTCO,
  roles: [ROLE.RH],
  searchPlaceholder: 'qptco.pesquisa',
  metadata: {
    keyName: 'codTContrato',
    fields: [
      {name: 'codTContrato', type: 'string', width: '120px', caption: 'qptco.fields.codTContrato', validators: {required: true, maxlength: 4}},
      {name: 'nome', caption: 'qptco.fields.nome', validators: {required: true, maxlength: 20}}
    ],

    order: 'codTContrato',
    searchFields: 'codTContrato,nome',
    listFields: 'codTContrato,nome',
    detailFields: 'codTContrato,nome'
  },
  autocomplete: {
    rowTemplate: '{{codTContrato}} - {{nome}}',
    output: 'nome',
    searchFields: 'codTContrato,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qptco'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qptco'
      }
    }
  },
  new: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qptco'
      }
    }
  }
};
