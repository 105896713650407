<div class="ativospesquisacontabilidade-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativos.actions.regcontab'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="ativosPesquisaContabilidadeModal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
        <split-viewer-doccontab-digital
          [(docContabilidade)]="item.data._docContabilidade"
          [contabDigitalDoc]="item.data._contabDigitalDoc"
          [initialSizeLeft]="60"
          [showToolbar]="true"
          [maintenanceMode]="true"
          [readonlyDigital]="true"
          (evtActionEdited)="evtDocumentoContabilidadeAction()"
          (evtActionDeleted)="evtDocumentoContabilidadeAction()">
        </split-viewer-doccontab-digital>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" [onClick]="close"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
