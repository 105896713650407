<pl-edit type="boolean" *ngIf="object.kind === typeKinds.Enumeration && !object.readOnly" [model]="object.value" (modelChange)="onValueChange($event)"></pl-edit>

<pl-inline-edit
  *ngIf="object.kind !== typeKinds.Enumeration"
  [type]="type"
  [(model)]="object.value"
  [onValidate]="fnOnValidateValue"
  [properties]="{readonly: object.readOnly, inlineMode: true, entity: {output: 'key'}, filter: object.filter}">
</pl-inline-edit>

<span class="text-success" *ngIf="object.kind === typeKinds.Enumeration && object.readOnly && object.value">
  <i class="fa fa-fw fa-check"></i>
</span>

<span class="text-danger" *ngIf="object.kind === typeKinds.Enumeration && object.readOnly && !object.value">
  <i class="fa fa-fw fa-times"></i>
</span>
