import {Subscription} from 'rxjs';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlPdfEvtConfigureToolbar, Logger} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {ConfigOptionsService} from '../config/options/config.options.service';
import {ConfigService} from '../config/config.service';
import {ContabilidadeDigitalConfigStoreService} from './helperservices/contabilidadedigital.configstore.service';
import {ContabilidadeDigitalServiceConfigs} from './contabilidadedigital.service.configs';
import {ContabilidadeDigitalServiceDocuments} from './contabilidadedigital.service.documents';
import {ContabilidadeDigitalServiceGDocViewerRecolha} from './contabilidadedigital.service.gdocviewer.recolha';
import {DEFAULT_FOLDER_CONTAB_CLASSIFICADOS} from '../../modules/contabilidadedigital/clientconnect/contabDigital.clientConnect.module.interface';
import {IApiRequestConfig, TServiceResponse} from '../api/api.service.interface';
import {ICGConfigurations} from '../config/config.service.interface';
import {
  IContabDigitalGestaoDocsDigitalizadosExportFilters,
  IContabDigitalGestaoDocsDigitalizadosExportStatus,
  IDocsDigitalizadosHeader
} from '../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';
import {IContabDigitalGetFolderStructureParams, IContabDigitalGetFolderStructureUpwardsParams} from './contabilidadedigital.interface';
import {IContabilidadeDigitalGenericViewerFolder} from '../../components/arquivodigital/common/viewers/contabilidadedigital.genericviewer.interface';
import {IJsonContabDigitalDadosLicenca, IJsonContabDigitalFolder, IJsonContabDigitalFolderWithChildren, IJsonDocDigitalizado, IJsonDocOCRLinhaIva} from './jsonContabDigital.interface';
import {IJsonBoolean} from '../../../common/interfaces/json';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeDigitalService<
  TDocuments extends ContabilidadeDigitalServiceDocuments = ContabilidadeDigitalServiceDocuments,
  TConfigs extends ContabilidadeDigitalServiceConfigs = ContabilidadeDigitalServiceConfigs
> implements OnDestroy
{
  protected readonly _logger: Logger;
  protected readonly _apiService: ApiService;
  protected readonly _configStoreService: ContabilidadeDigitalConfigStoreService;
  protected readonly _configService: ConfigService;
  protected readonly _configOptionsService: ConfigOptionsService;

  protected readonly _path: string;
  protected readonly _documents: TDocuments;
  protected readonly _configs: TConfigs;
  protected readonly _gDocViewerRecolha: ContabilidadeDigitalServiceGDocViewerRecolha;

  private readonly _subscriptionConfigurations: Subscription;
  private _configTemContabilidadeDigital: boolean;
  private _configTemContabilidadeDigitalClientConnect: boolean;
  private _configModoCGOn: boolean;

  constructor(protected readonly _injector: Injector) {
    this._logger = this._injector.get<Logger>(Logger);
    this._apiService = this._injector.get<ApiService>(ApiService);
    this._configStoreService = this._injector.get<ContabilidadeDigitalConfigStoreService>(ContabilidadeDigitalConfigStoreService);
    this._configService = this._injector.get<ConfigService>(ConfigService);
    this._configOptionsService = this._injector.get<ConfigOptionsService>(ConfigOptionsService);

    this._path = this._buildPath();
    this._documents = this._buildDocuments();
    this._configs = this._buildConfigs();
    this._gDocViewerRecolha = new ContabilidadeDigitalServiceGDocViewerRecolha(this._apiService, this._path);

    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._configTemContabilidadeDigital = configurations.empresa.temContabilidadeDigital;
      this._configTemContabilidadeDigitalClientConnect = configurations.empresa.temContabilidadeDigitalClientConnect;
      this._configModoCGOn = configurations.licenca.modoCGOn;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
  }

  public getFolderStructure(folderId: string = '', params?: IContabDigitalGetFolderStructureParams, config?: IApiRequestConfig): TServiceResponse<Array<IJsonContabDigitalFolder>> {
    params = {loadFiles: true, checkHasSubFiles: true, anoEmCursoIRC: false, ...params};
    return this._apiService.get<Array<IJsonContabDigitalFolder>>({
      url: `${this._path}/folderstructure`,
      params: {
        ...params,
        folderId: folderId
      },
      ...config
    });
  }

  public getFolderStructureUpwards(
    folderId: string,
    untilFolderId: string = '',
    params?: IContabDigitalGetFolderStructureUpwardsParams,
    config?: IApiRequestConfig
  ): TServiceResponse<Array<IJsonContabDigitalFolderWithChildren>> {
    params = {includeSelf: false, loadFiles: true, checkHasSubFiles: true, anoEmCursoIRC: false, ...params};
    return this._apiService.get<Array<IJsonContabDigitalFolderWithChildren>>({
      url: `${this._path}/folderstructureupwards`,
      params: {
        ...params,
        folderId: folderId,
        untilFolderId: untilFolderId
      },
      ...config
    });
  }

  public temDocumentosDigitais(): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({url: `${this._path}/temdocumentosdigitais`});
  }

  public getDadosLicenca(): TServiceResponse<IJsonContabDigitalDadosLicenca> {
    return this._apiService.get<IJsonContabDigitalDadosLicenca>({url: `${this._path}/dadoslicenca`});
  }

  public getDadosLicencaClientConnect(): TServiceResponse<IJsonContabDigitalDadosLicenca> {
    return this._apiService.get<IJsonContabDigitalDadosLicenca>({url: `${this._path}/dadoslicencaclientconnect`});
  }

  public activateContabilidadeDigital(): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/activate`}).then((response: HttpResponse<void>) => {
      return this._configService
        .refresh()
        .then(() => response)
        .catch((reason: unknown) => {
          this._logger.error(reason);
          return response;
        });
    });
  }

  public activateClientConnect(): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/activateclientconnect`}).then((response: HttpResponse<void>) => {
      return this._configService
        .refresh()
        .then(() => response)
        .catch((reason: unknown) => {
          this._logger.error(reason);
          return response;
        });
    });
  }

  public getDocsDigitalizados(docsDigitalizadosHeader: IDocsDigitalizadosHeader, conciliacaoMode: boolean): TServiceResponse<Array<IJsonDocDigitalizado>> {
    return this._apiService
      .get<Array<IJsonDocDigitalizado>>({
        url: `${this._path}/documents`,
        params: {
          nifde: docsDigitalizadosHeader.nifDe,
          nifate: docsDigitalizadosHeader.nifAte,
          ndocumentode: docsDigitalizadosHeader.docExternoDe,
          ndocumentoate: docsDigitalizadosHeader.docExternoAte,
          datadocumentode: docsDigitalizadosHeader.dataDocExternoDe,
          datadocumentoate: docsDigitalizadosHeader.dataDocExternoAte,
          datainsercaode: docsDigitalizadosHeader.dataRececaoDe,
          datainsercaoate: docsDigitalizadosHeader.dataRececaoAte,
          mostrardocclassificados: docsDigitalizadosHeader.mostrarDocClassificados,
          mostraapenaslancrobot: docsDigitalizadosHeader.mostraApenasLancRobot,
          conciliacaomode: conciliacaoMode
        }
      })
      .then((response: HttpResponse<Array<IJsonDocDigitalizado>>) => {
        return response.clone({body: this.documents.sortDocsDigitalizados(response.body)});
      });
  }

  public getDocDigitalizado(docOCRCabID: string): TServiceResponse<IJsonDocDigitalizado> {
    return this._apiService.get<IJsonDocDigitalizado>({
      url: `${this._path}/documents/docdigitalizado`,
      params: {
        dococrcabid: docOCRCabID
      }
    });
  }

  public getDocDigitalizadoLinhasIVA(docOCRCabID: string): TServiceResponse<Array<IJsonDocOCRLinhaIva>> {
    return this._apiService.get<Array<IJsonDocOCRLinhaIva>>({
      url: `${this._path}/documents/docdigitalizadolinhasiva`,
      params: {
        dococrcabid: docOCRCabID
      }
    });
  }

  public isFolderFromDocsClassificados(folderItem: IContabilidadeDigitalGenericViewerFolder): boolean {
    if (!folderItem) {
      return false;
    }
    if (folderItem.folderID === DEFAULT_FOLDER_CONTAB_CLASSIFICADOS) {
      return true;
    }
    return this.isFolderFromDocsClassificados(folderItem.parentFolder);
  }

  public deleteFolderDocuments(folderId: string): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/folders/${folderId}/documents`});
  }

  public getAnosAnteriores(): TServiceResponse<Array<number>> {
    return this._apiService.get<Array<number>>({url: `${this._path}/anosanteriores`});
  }

  public export(filterModel: IContabDigitalGestaoDocsDigitalizadosExportFilters): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/exportarquivo/export`,
      params: {
        ano: filterModel.ano,
        periodoDe: filterModel.periodoDe,
        periodoAte: filterModel.periodoAte,
        diarioDe: filterModel.diarioDe,
        diarioAte: filterModel.diarioAte,
        exportaNaoClassificados: filterModel.exportaNaoClassificados,
        exportaApenasRelevantes: filterModel.exportaApenasRelevantes,
        nifDe: filterModel.nifDe,
        nifAte: filterModel.nifAte,
        folderId: filterModel.folderId
      }
    });
  }

  public exportStatus(): TServiceResponse<IContabDigitalGestaoDocsDigitalizadosExportStatus> {
    return this._apiService.get<IContabDigitalGestaoDocsDigitalizadosExportStatus>({url: `${this._path}/exportarquivo/status`});
  }

  public downloadExportedFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/exportarquivo/download`, responseType: 'blob'});
  }

  public exportCancel(): TServiceResponse<void> {
    return this._apiService.post<void>({url: this.exportCancelUrl()});
  }

  public exportCancelUrl(): string {
    return `${this._path}/exportarquivo/cancel`;
  }

  public configureToolbar(event: IPlPdfEvtConfigureToolbar): void {
    event.mnuPages.visible = false;
    event.mnuBtnZoomIn.class = 'btn-sm btn-light';
    event.mnuBtnZoomOut.class = 'btn-sm btn-light';
    event.mnuZoom.class = 'btn-sm btn-light';
    event.btnPresentationMode.class = 'btn-sm btn-light';
    event.btnDownload.class = 'btn-sm btn-light';
    event.btnDownload.caption = '';
    event.btnDownload.title = 'arquivodigital.docviewerrecolha.title.download';
    event.btnPrint.visible = false;
  }

  public get documents(): TDocuments {
    return this._documents;
  }

  public get configs(): TConfigs {
    return this._configs;
  }

  public get gDocViewerRecolha(): ContabilidadeDigitalServiceGDocViewerRecolha {
    return this._gDocViewerRecolha;
  }

  protected _buildPath(): string {
    return `${this._apiService.path.restapi}/contabilidadedigital`;
  }

  protected _buildDocuments(): TDocuments {
    return <TDocuments>new ContabilidadeDigitalServiceDocuments(this._apiService, this._path);
  }

  protected _buildConfigs(): TConfigs {
    return <TConfigs>new ContabilidadeDigitalServiceConfigs(this._apiService, this._configStoreService, this._path);
  }

  protected get _temContabilidadeDigital(): boolean {
    return this._configTemContabilidadeDigital;
  }

  protected get _temContabilidadeDigitalClientConnect(): boolean {
    return this._configTemContabilidadeDigitalClientConnect;
  }

  protected get _modoCGOn(): boolean {
    return this._configModoCGOn;
  }
}
