import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Injectable, Injector, OnDestroy, Type} from '@angular/core';
import type {IPlDynamicVisualsSecondaryClickAction} from '../component/dynamicvisuals.secondaryclick.component.interface';
import type {IPlDynamicVisualsSecondaryClickReferenceOptions} from '../dynamicvisuals.secondaryclick.interface';
import {PlDynamicVisualsReference} from '../../../stacks/stack/reference/dynamicvisuals.reference';
import {PlDynamicVisualsSecondaryClickComponent} from '../component/dynamicvisuals.secondaryclick.component';
import {PlDynamicVisualsSecondaryClickConfigService} from './dynamicvisuals.secondaryclick.config.service';
import {PlDynamicVisualsService} from '../../../stacks/stack/dynamicvisuals.service';
import {PlDynamicVisualsStackService} from '../../../stacks/stack/dynamicvisuals.stack.service';

@Injectable({
  providedIn: 'root'
})
export class PlDynamicVisualsSecondaryClickService extends PlDynamicVisualsService implements OnDestroy {
  private readonly _subscriptionConfig: Subscription;
  private _properties: IPlDynamicVisualsSecondaryClickReferenceOptions;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _stack: PlDynamicVisualsStackService,
    private readonly _config: PlDynamicVisualsSecondaryClickConfigService
  ) {
    super(_injector, _stack);
    this._subscriptionConfig = this._config.get().subscribe((config: IPlDynamicVisualsSecondaryClickReferenceOptions) => {
      this._properties = config;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfig.unsubscribe();
  }

  public openMenuVanilla(
    hostElement: HTMLElement,
    actions: Array<IPlDynamicVisualsSecondaryClickAction>,
    options?: IPlDynamicVisualsSecondaryClickReferenceOptions,
    component: Type<PlDynamicVisualsSecondaryClickComponent> = PlDynamicVisualsSecondaryClickComponent
  ): PlDynamicVisualsReference<void> {
    const config: IPlDynamicVisualsSecondaryClickReferenceOptions = merge({}, this._properties, options);
    const instance: PlDynamicVisualsReference = super.openVanilla(component, config);
    const componentInstance: PlDynamicVisualsSecondaryClickComponent = instance.componentInstance;
    componentInstance.hostElement = hostElement;
    componentInstance.autoClose = config.autoClose;
    componentInstance.placement = config.placement;
    componentInstance.cssClass = config.cssClass;
    componentInstance.persistent = config.persistent;
    componentInstance.actions = actions;
    return <PlDynamicVisualsReference<void>>instance;
  }

  public openMenu(
    hostElement: HTMLElement,
    actions: Array<IPlDynamicVisualsSecondaryClickAction>,
    options?: IPlDynamicVisualsSecondaryClickReferenceOptions,
    component: Type<PlDynamicVisualsSecondaryClickComponent> = PlDynamicVisualsSecondaryClickComponent
  ): Promise<void> {
    return this.openMenuVanilla(hostElement, actions, options, component).result;
  }
}
