import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isBoolean} from 'pl-comps-angular';
import {EContabDigitalDocViewerRecolhaValuesSource} from '../contabilidadedigital.docviewer.recolha.component.interface';
import {IJsonDocOCR} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'contabilidade-digital-doc-viewer-recolha-footer',
  templateUrl: './contabilidadedigital.docviewer.recolha.footer.component.html'
})
export class ContabilidadeDigitalDocViewerRecolhaFooterComponent implements OnInit, OnChanges {
  @Input() public docOCR: IJsonDocOCR;
  @Input() public showLinhasIVA: boolean;
  @Input() public showCollapse: boolean;
  @Input() public collapsed: boolean;
  @Output() public readonly collapsedChange: EventEmitter<boolean>;

  public valuesSource: typeof EContabDigitalDocViewerRecolhaValuesSource;
  public attachmentValuesSource: EContabDigitalDocViewerRecolhaValuesSource;

  constructor() {
    this.showCollapse = true;
    this.collapsedChange = new EventEmitter<boolean>();
    this.valuesSource = EContabDigitalDocViewerRecolhaValuesSource;
    this.attachmentValuesSource = EContabDigitalDocViewerRecolhaValuesSource.OCR;
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({docOCR, showLinhasIVA, showCollapse, collapsed}: SimpleChanges): void {
    if (docOCR && !docOCR.isFirstChange()) {
      this._evaluateValuesSource();
    }
    if (showLinhasIVA && !showLinhasIVA.isFirstChange()) {
      this._changedShowLinhasIVA(showLinhasIVA.currentValue);
    }
    if (showCollapse && !showCollapse.isFirstChange()) {
      this._changedShowCollapse(showCollapse.currentValue);
    }
    if (collapsed && !collapsed.isFirstChange()) {
      this._changedCollapsed(collapsed.currentValue);
    }
  }

  public toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
  }

  private _handleChanges(): void {
    this._changedShowLinhasIVA();
    this._changedShowCollapse();
    this._changedCollapsed();
    this._evaluateValuesSource();
  }

  private _changedShowLinhasIVA(value: boolean = this.showLinhasIVA): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.showLinhasIVA = val;
  }

  private _changedShowCollapse(value: boolean = this.showCollapse): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showCollapse = val;
  }

  private _changedCollapsed(value: boolean = this.collapsed): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.collapsed = val;
  }

  private _evaluateValuesSource(): void {
    if (!this.docOCR?.cab) {
      this.attachmentValuesSource = EContabDigitalDocViewerRecolhaValuesSource.OCR;
    } else if (this.docOCR.cab.validadoEFatura) {
      this.attachmentValuesSource = EContabDigitalDocViewerRecolhaValuesSource.EFatura;
    } else if (this.docOCR.cab.temQRCode) {
      this.attachmentValuesSource = EContabDigitalDocViewerRecolhaValuesSource.QrCode;
    }
  }
}
