<div class="modal-header">
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body">
  <pl-table [definition]="tableDefinition" [source]="fnGetTableSource" [properties]="tableProperties"></pl-table>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
