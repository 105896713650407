import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, IPlTabsEventSelected, IPlToolbarItemDefinition, IPlToolbarMenuItem, isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {EIesAnexosTypes, EIesState, EIesStep, IIesError, IIesFilters, IIesStatus} from '../ies.module.interface';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IesService} from '../ies.module.service';
import {IJsonEmpresaAno} from '../../../interfaces/jsonEmpresa.interface';
import {isTest} from '../../../../config/constants';
import {ModuloComponent} from '../../../components/module/module.component';
import {STATE_NAME_DISCONNECTED} from '../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../states/account/login/login.state.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {formatFileBytes} from '../../../../common/utils/file.utils';

const TIMEOUT_MS = 2000;
const AT_IES_ENVIAR_DECLARACAO_URL = 'https://oa.portaldasfinancas.gov.pt/ies/entregarIESForm.action';

@Component({
  selector: 'ies',
  templateUrl: './ies.module.component.html'
})
export class IesComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public initStatus: IIesStatus;

  public readonly dataGridDefinition: IDevExpressDataGrid<IIesError>;
  public readonly iesSteps: typeof EIesStep;
  public readonly tabProcess: string;
  public readonly tabDownload: string;
  public readonly filename: string;

  public model: IIesFilters;
  public mesesSource: Array<unknown>;
  public currentStep: EIesStep;
  public statusDescription: string;
  public isBlocked: boolean;
  public tabActiveId: string;
  public currentStatus: IIesStatus;

  private _timeoutId: number;
  private _deRegisterOnStartFn: Function;
  private readonly _btnEnviarDeclaracao: IPlToolbarItemDefinition;
  private readonly _btnDropdownYears: IPlToolbarItemDefinition<IJsonEmpresaAno>;
  private readonly _btnProcess: IPlToolbarItemDefinition;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _empresasService: EmpresasService,
    private readonly _iesService: IesService,
    private readonly _plAlertService: PlAlertService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.tabProcess = 'tabProcessamento';
    this.tabDownload = 'tabDownload';
    this.iesSteps = EIesStep;
    this.filename = '';
    this.dataGridDefinition = {
      columns: [
        {dataField: 'anexo', dataType: 'string', caption: 'ies.fields.anexo'},
        {dataField: 'campo', dataType: 'string', caption: 'ies.fields.campo'},
        {dataField: 'descricao', dataType: 'string', caption: 'ies.fields.descricao'},
        {dataField: 'mensagem', dataType: 'string', caption: 'ies.fields.mensagem'}
      ],
      dataSource: new CustomStore({
        load: () => this._getTableSource()
      }),
      remoteOperations: false
    };
    this._btnDropdownYears = {
      id: 'dropdownyears',
      order: 0,
      caption: '-',
      type: 'dropdown',
      menu: null
    };
    this._btnProcess = {
      id: 'btnprocessar',
      order: 1,
      type: 'button',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-bolt"></i>&nbsp;',
      caption: 'ies.processar',
      click: () => this._visualProcess()
    };
    this._btnEnviarDeclaracao = {
      id: 'gerarficheiro',
      order: 2,
      type: 'link',
      iconLeft: '<i class="fa fa-paper-plane"></i>&nbsp;',
      class: 'btn btn-sm btn-light',
      caption: 'ies.enviarDeclaracao',
      disabled: true,
      href: {
        value: AT_IES_ENVIAR_DECLARACAO_URL,
        target: '_blank'
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.currentStatus = this.initStatus;
    this.toolbar.addButton(this._btnDropdownYears);
    this.toolbar.addButton(this._btnProcess);
    this.toolbar.addButton(this._btnEnviarDeclaracao);
    this.tabActiveId = this.tabProcess;
    this._btnDropdownYears.menu = [];
    this._empresasService.getAnos(this.session.erp.nEmpresa).then((response: THttpQueryResponse<IJsonEmpresaAno>) => {
      if (response.body.list.length) {
        this._btnDropdownYears.caption = response.body.list[0].ano.toString();
        response.body.list.forEach((value: IJsonEmpresaAno) => {
          this._btnDropdownYears.menu.push({
            caption: String(value.ano),
            data: value,
            click: (anoItem: IPlToolbarMenuItem<IJsonEmpresaAno>) => {
              this.model.ano = anoItem.data.ano;
              this._btnDropdownYears.caption = String(anoItem.data.ano);
            }
          });
        });
      }
    });

    if (this.currentStatus.state === EIesState.Timeout) {
      this._showTimeoutModal();
      this._init();
    } else if (this.currentStatus.state === EIesState.Error) {
      this.currentStep = EIesStep.Errors;
    } else if (this.currentStatus.state === EIesState.Ended) {
      this._init(this.tabDownload);
    } else if (this.currentStatus.state === EIesState.Inactive) {
      this._init();
    } else {
      this.isBlocked = this.currentStatus.userStartedId !== this.session.userId;
      if (!this.isBlocked) {
        this.currentStep = EIesStep.Processing;
        this._startProcessChecker();
      }
    }

    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearProcessChecker();
    this._deRegisterOnStart();
    this._iesService.cancelProcess();
  }

  public closeErrorPanel(): void {
    this._init();
  }

  public formatBytes(bytes: number): string {
    return formatFileBytes(bytes);
  }

  public onTabChanged(event: IPlTabsEventSelected): void {
    this.tabActiveId = String(event.nextId);
    this._btnDropdownYears.disabled = this.tabActiveId === this.tabDownload;
    this._btnProcess.disabled = this.tabActiveId === this.tabDownload;
  }

  public doDownload(): Promise<void> {
    return this._iesService.downloadFile().then((blob) => {
      if (!blob) {
        this._plAlertService.error(this._translateService.instant('ies.erroDownload'));
      } else {
        downloadStream(blob);
      }
    });
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._iesService.cancelProcessUrl());
  }

  private _init(activeTab: string = this.tabProcess): void {
    this.currentStep = EIesStep.Configuration;
    this.statusDescription = '';
    this.model = {
      ano: this._configService.configurations.empresa.anoEmCursoIRC,
      anexos: {
        rosto: {
          type: EIesAnexosTypes.Rosto,
          checked: true,
          name: 'Rosto',
          description: ''
        },
        anexoASNC: {
          type: EIesAnexosTypes.AnexoASNC,
          checked: true,
          name: 'Anexo A',
          description: 'Entidades residentes que exercem, a título principal, actividade comercial, industrial ou agrícola e entidades não residentes com estabelecimento estável'
        },
        anexoD: {
          type: EIesAnexosTypes.AnexoD,
          checked: false,
          name: 'Anexo D',
          description: 'Entidades residentes que não exercem, a título principal, actividade comercial, industrial ou agrícola'
        },
        anexoE: {
          type: EIesAnexosTypes.AnexoE,
          checked: false,
          name: 'Anexo E',
          description: 'Elementos Contabilísticos e Fiscais (entidades não residentes sem estabelecimento estável)'
        },
        anexoF: {
          type: EIesAnexosTypes.AnexoF,
          checked: false,
          name: 'Anexo F',
          description: 'Benefícios Fiscais'
        },
        anexoG: {
          type: EIesAnexosTypes.AnexoG,
          checked: false,
          name: 'Anexo G',
          description: 'Regimes Especiais'
        },
        anexoH: {
          type: EIesAnexosTypes.AnexoH,
          checked: false,
          name: 'Anexo H',
          description: 'Operações com Não Residentes'
        },
        anexoI: {
          type: EIesAnexosTypes.AnexoI,
          checked: false,
          name: 'Anexo I',
          description: 'Sujeitos passivos com contabilidade organizada'
        },
        anexoL: {
          type: EIesAnexosTypes.AnexoL,
          checked: true,
          name: 'Anexo L',
          description: 'Elementos Contabilísticos e Fiscais'
        },
        anexoM: {
          type: EIesAnexosTypes.AnexoM,
          checked: false,
          name: 'Anexo M',
          description: 'Operações realizadas em espaço diferente da sede (DL n.o 347/85, de 23 de Agosto)'
        },
        anexoN: {
          type: EIesAnexosTypes.AnexoN,
          checked: false,
          name: 'Anexo N',
          description: 'Regimes especiais'
        },
        anexoO: {
          type: EIesAnexosTypes.AnexoO,
          checked: true,
          name: 'Anexo O',
          description: 'Mapa Recapitulativo de Clientes'
        },
        anexoP: {
          type: EIesAnexosTypes.AnexoP,
          checked: true,
          name: 'Anexo P',
          description: 'Mapa Recapitulativo de Fornecedores'
        },
        anexoQ: {
          type: EIesAnexosTypes.AnexoQ,
          checked: false,
          name: 'Anexo Q',
          description: 'Elementos Contabilísticos e Fiscais'
        },
        anexoRSNC: {
          type: EIesAnexosTypes.AnexoRSNC,
          checked: true,
          name: 'Anexo R',
          description: 'Entidades residentes que exercem, a título principal, actividade comercial, industrial ou agrícola, entidades não residentes com estabelecimento estável e EIRL'
        }
      }
    };

    if (this._configService.configurations.empresa.ncontribuemp.charAt(0) in ['1', '2']) {
      this.model.anexos.anexoI.checked = true;
      this.model.anexos.anexoASNC.checked = false;
      this.model.anexos.anexoRSNC.checked = false;
    } else {
      this.model.anexos.anexoASNC.checked = true;
      this.model.anexos.anexoRSNC.checked = true;
    }

    this._btnEnviarDeclaracao.disabled = true;
    this._btnDropdownYears.disabled = false;
    this._btnProcess.disabled = false;

    if (activeTab.length) {
      this.tabActiveId = activeTab;
      if (activeTab === this.tabDownload) {
        this._btnEnviarDeclaracao.disabled = false;
        this._btnDropdownYears.disabled = true;
        this._btnProcess.disabled = true;
      }
    }
  }

  private _visualProcess(): Promise<void> {
    if (!this.currentStatus.ficheiroProcessado) {
      return this._processar();
    }
    return this._cgModalService
      .showOkCancel('ies.promptReprocessTitle', 'ies.promptReprocessMessage', {
        size: 'md',
        btnOkText: 'ies.buttons.yes',
        btnCancelText: 'ies.buttons.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => this._processar());
  }

  private _processar(): Promise<void> {
    this.currentStatus.ficheiroProcessado = false;
    this._btnEnviarDeclaracao.disabled = true;
    return this._iesService.processar(this.model.ano, this._getSelectedAnexos()).then(() => {
      this.currentStep = EIesStep.Processing;
      this._startProcessChecker();
    });
  }

  private _requestCheckerGetStatus(): void {
    this._iesService.getStatus().then((response: HttpResponse<IIesStatus>) => {
      this.currentStatus = response.body;
      if (this.currentStatus.state === EIesState.Timeout) {
        this._showTimeoutModal();
        this._clearProcessChecker();
        this._init();
      } else if (this.currentStatus.state === EIesState.Error) {
        this.currentStep = EIesStep.Errors;
        this._clearProcessChecker();
      } else if (this.currentStatus.state === EIesState.Ended) {
        if (this.currentStatus.ficheiroProcessado) {
          this._init(this.tabDownload);
        } else {
          this.currentStep = EIesStep.Errors;
          this.tabActiveId = this.tabProcess;
        }
        this._clearProcessChecker();
      } else {
        this.statusDescription = this.currentStatus.description;
        this._timeoutId = window.setTimeout(() => {
          this._requestCheckerGetStatus();
        }, TIMEOUT_MS);
      }
    });
  }

  private _startProcessChecker(): void {
    this._clearProcessChecker();
    this._requestCheckerGetStatus();
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService.showOkCancel('ies.jobTimeoutModalTitle', 'ies.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'ies.buttons.reiniciar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _clearProcessChecker(): void {
    if (isNumber(this._timeoutId)) {
      window.clearTimeout(this._timeoutId);
      this._timeoutId = undefined;
    }
  }

  private _getTableSource(): Array<IIesError> {
    return this.currentStatus.errorList;
  }

  private _getSelectedAnexos(): Array<number> {
    const anexos: Array<number> = [];
    const keys = Object.keys(this.model.anexos);
    keys.forEach((anexo) => {
      if (this.model.anexos[anexo].checked) {
        anexos.push(this.model.anexos[anexo].type);
      }
    });
    return anexos;
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this.currentStatus.ficheiroProcessado) {
        return this._cgModalService.showOkCancel('ies.leavePromptTitle', 'ies.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }
}
