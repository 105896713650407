<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.contaPendentesBanco'"></h4>
</div>

<div class="modal-body">
  <pl-group>
    <label [translate]="'conciliacaoBancaria.fields.radicalConta'"></label>
    <edit>
      <pl-edit type="text" [model]="radicalConta" (modelChange)="radicalContaChanged($event)"></pl-edit>
    </edit>
  </pl-group>

  <pl-group>
    <label [translate]="'conciliacaoBancaria.fields.nconta'"></label>
    <edit>
      <pl-edit type="text" [model]="conta" [properties]="{readonly: true}"></pl-edit>
    </edit>
  </pl-group>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-success" (click)="saveRadicalConta()"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
