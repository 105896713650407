import {Component, EventEmitter, Input, Output} from '@angular/core';
import {isBoolean} from 'pl-comps-angular';
import {ITreeItem} from './treeviewer.interface';

@Component({
  selector: 'tree-viewer-node',
  templateUrl: './treeviewer.node.component.html'
})
export class TreeViewerNodeComponent {
  @Input() public treeItem: ITreeItem;
  @Input() public selectedItem: ITreeItem;
  @Input() public disabled: boolean;
  @Input() public collapseOnSelect: boolean;
  @Input() public selectOnCollapse: boolean;
  @Output() public readonly evtSelectedItem: EventEmitter<ITreeItem>;
  @Output() public readonly evtDoubleClickedItem: EventEmitter<ITreeItem>;

  constructor() {
    this.evtSelectedItem = new EventEmitter<ITreeItem>();
    this.evtDoubleClickedItem = new EventEmitter<ITreeItem>();
    this.collapseOnSelect = false;
    this.selectOnCollapse = false;
  }

  public selectItem(treeItem: ITreeItem): void {
    if (this.disabled) {
      return;
    }
    if (this.collapseOnSelect) {
      this._collapseItem(treeItem);
    }
    this.evtSelectedItem.emit(treeItem);
  }

  public collapse(): boolean {
    this.treeItem.collapsed = this.treeItem.collapsed !== undefined ? !this.treeItem.collapsed : false;

    if (this.selectOnCollapse) {
      this.evtSelectedItem.emit(this.treeItem);
    }
    return this.treeItem.collapsed;
  }

  public doubleClickedItem(treeItem: ITreeItem): void {
    if (this.disabled) {
      return;
    }
    if (!this.collapseOnSelect) {
      this._collapseItem(treeItem);
    }
    this.evtDoubleClickedItem.emit(treeItem);
  }

  private _collapseItem(treeItem: ITreeItem): void {
    treeItem.collapsed = isBoolean(treeItem.collapsed) ? !treeItem.collapsed : false;
  }
}
