import {Duration} from 'moment';
import {Pipe, PipeTransform} from '@angular/core';
import {momentDurationFormat} from '../common/dates/moment.duration.format';

@Pipe({
  name: 'cgcMomentDurationFormat',
  standalone: true
})
export class CGCMomentDurationFormatPipe implements PipeTransform {
  public transform(value: Duration, units?: string | Array<keyof Duration> | ReadonlyArray<keyof Duration>): string {
    return momentDurationFormat(value, units);
  }
}
