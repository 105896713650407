import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, isNumber, isObject, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IDataSourceItem} from '../../../../../../../components/datasource/datasources.interface';
import {IJsonModelo22AnexoD} from '../../../../jsonModelo22.module.interface';
import {Modelo22Service} from '../../../../modelo22.module.service';
import moment from 'moment';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'modal-modelo22-anexod-quadro7',
  templateUrl: './modelo22.anexoD.quadro7.modal.component.html'
})
export class Modelo22AnexoDQuadro7ModalComponent extends CGModalComponent<IJsonModelo22AnexoD> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoD: IJsonModelo22AnexoD;
  public readonly dsTemplate: string;

  public dsDiploma: Array<IDataSourceItem<number>>;
  public is71: boolean;
  public is71A: boolean;
  public is73: boolean;
  public is73A: boolean;
  public is74: boolean;
  public is74A: boolean;
  public is76: boolean;
  public is76A: boolean;
  public is79: boolean;
  public is710: boolean;
  public is710A: boolean;
  public is77: boolean;

  public diplomaView: string;
  public diploma: number;
  public nif: number;
  public nIdentificacao: string;
  public periodo: number;
  public saldoCaducado: number;
  public dotacaoDoPeriodo: number;
  public deducaoNoPeriodo: number;
  public saldoQueTransita: number;
  public saldoNaoDeduzido: number;
  public montanteBeneficio: number;
  public deducaoUtilizada: number;
  public dataInicio: TDate;
  public dataConclusao: TDate;
  public valorIncentivo: number;
  public deducaoDoPeriodo: number;
  public valorAReembolsar: number;
  public valorARepor: number;
  public saldo: number;
  public dotacao: number;

  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dsTemplate = '{{value}} - {{name}}';
    this.dsDiploma = [];
    this.diplomaView = '';
    this.diploma = undefined;
    this.nif = undefined;
    this.nIdentificacao = '';
    this.periodo = undefined;
    this.saldoCaducado = 0;
    this.dotacaoDoPeriodo = 0;
    this.deducaoNoPeriodo = 0;
    this.saldoQueTransita = 0;
    this.saldoNaoDeduzido = 0;
    this.deducaoUtilizada = 0;
    this.dataInicio = moment();
    this.dataConclusao = moment();
    this.valorIncentivo = 0;
    this.deducaoDoPeriodo = 0;
    this.valorAReembolsar = 0;
    this.valorARepor = 0;
    this.saldo = 0;
    this.dotacao = 0;
  }

  public ngOnInit(): void {
    this.is71 = this.newLineFor === '71';
    this.is71A = this.newLineFor === '71A';
    this.is73 = this.newLineFor === '73';
    this.is73A = this.newLineFor === '73A';
    this.is74 = this.newLineFor === '74';
    this.is74A = this.newLineFor === '74A';
    this.is76 = this.newLineFor === '76';
    this.is76A = this.newLineFor === '76A';
    this.is79 = this.newLineFor === '79';
    this.is710 = this.newLineFor === '710';
    this.is710A = this.newLineFor === '710A';
    this.is77 = this.newLineFor === '77';
    this._setDSDiploma();
  }

  public evtDiplomaChanged(value: {inputValue: string; item: IDataSourceItem<number>}): void {
    if (isObject(value.item)) {
      this.diplomaView = `${value.item.value} - ${value.item.name}`;
      this.diploma = value.item.value;
    }
  }

  public close(): Promise<void> {
    if (this.is71) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro07.campo071List.push({
          diploma: this.diploma,
          nif: this.nif,
          periodo: this.periodo,
          saldoCaducado: this.saldoCaducado,
          saldoNaoDeduzido: this.saldoNaoDeduzido,
          dotacaoDoPeriodo: this.dotacaoDoPeriodo,
          deducaoNoPeriodo: this.deducaoNoPeriodo,
          saldoQueTransita: this.saldoQueTransita
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is71A) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.anexoD.quadro07.campo071AList.push({
        saldoQueTransita: this.saldoQueTransita,
        diploma: this.diploma,
        deducaoUtilizada: this.deducaoUtilizada,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        dotacaoDoPeriodo: this.dotacaoDoPeriodo,
        periodo: this.periodo,
        saldoCaducado: this.saldoCaducado
      });
      super.close(this.anexoD);
    }
    if (this.is73) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro07.campo073List.push({
          diploma: this.diploma,
          nif: this.nif,
          periodo: this.periodo,
          saldoCaducado: this.saldoCaducado,
          saldoNaoDeduzido: this.saldoNaoDeduzido,
          dotacaoDoPeriodo: this.dotacaoDoPeriodo,
          deducaoNoPeriodo: this.deducaoNoPeriodo,
          saldoQueTransita: this.saldoQueTransita
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is73A) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.anexoD.quadro07.campo073AList.push({
        saldoQueTransita: this.saldoQueTransita,
        diploma: this.diploma,
        deducaoUtilizada: this.deducaoUtilizada,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        dotacaoDoPeriodo: this.dotacaoDoPeriodo,
        periodo: this.periodo,
        saldoCaducado: this.saldoCaducado
      });
      super.close(this.anexoD);
    }
    if (this.is74) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro07.campo074List.push({
          diploma: this.diploma,
          nif: this.nif,
          periodo: this.periodo,
          saldoCaducado: this.saldoCaducado,
          saldoNaoDeduzido: this.saldoNaoDeduzido,
          dotacaoDoPeriodo: this.dotacaoDoPeriodo,
          deducaoNoPeriodo: this.deducaoNoPeriodo,
          saldoQueTransita: this.saldoQueTransita
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is74A) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.anexoD.quadro07.campo074AList.push({
        saldoQueTransita: this.saldoQueTransita,
        diploma: this.diploma,
        deducaoUtilizada: this.deducaoUtilizada,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        dotacaoDoPeriodo: this.dotacaoDoPeriodo,
        periodo: this.periodo,
        saldoCaducado: this.saldoCaducado
      });
      super.close(this.anexoD);
    }
    if (this.is76) {
      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro07.campo076List.push({
          nif: this.nif,
          periodo: this.periodo,
          saldoNaoDeduzido: this.saldoNaoDeduzido,
          dotacaoDoPeriodo: this.dotacaoDoPeriodo,
          deducaoDoPeriodo: this.deducaoDoPeriodo,
          saldoQueTransita: this.saldoQueTransita
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is76A) {
      this.anexoD.quadro07.campo076AList.push({
        saldoQueTransita: this.saldoQueTransita,
        deducaoUtilizada: this.deducaoUtilizada,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        dotacaoDoPeriodo: this.dotacaoDoPeriodo,
        periodo: this.periodo
      });
      super.close(this.anexoD);
    }
    if (this.is79) {
      if (isEmpty(this.nIdentificacao)) {
        this._plAlertService.error('modelo22.messages.nIdentificacaovazio');
        return Promise.resolve();
      }
      if (isEmpty(this.dataInicio)) {
        this._plAlertService.error('modelo22.messages.datavazia');
        return Promise.resolve();
      }

      this.anexoD.quadro07.campo079List.push({
        nIdentificacao: this.nIdentificacao,
        dataInicio: this.dataInicio,
        dataConclusao: this.dataConclusao,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        valorIncentivo: this.valorIncentivo,
        deducaoDoPeriodo: this.deducaoDoPeriodo,
        saldoQueTransita: this.saldoQueTransita,
        valorAReembolsar: this.valorAReembolsar,
        valorARepor: this.valorARepor
      });
      super.close(this.anexoD);
    }
    if (this.is710) {
      if (isEmpty(this.nIdentificacao)) {
        this._plAlertService.error('modelo22.messages.nIdentificacaovazio');
        return Promise.resolve();
      }

      this.anexoD.quadro07.campo0710List.push({
        nIdentificacao: this.nIdentificacao,
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        montanteBeneficio: this.montanteBeneficio,
        deducaoDoPeriodo: this.deducaoDoPeriodo,
        saldoQueTransita: this.saldoQueTransita
      });
      super.close(this.anexoD);
    }
    if (this.is710A) {
      this.anexoD.quadro07.campo0710AList.push({
        saldoNaoDeduzido: this.saldoNaoDeduzido,
        montanteBeneficio: this.montanteBeneficio,
        deducaoDoPeriodo: this.deducaoDoPeriodo,
        saldoQueTransita: this.saldoQueTransita
      });
      super.close(this.anexoD);
    }
    if (this.is77) {
      if (!isNumber(this.diploma) || this.diploma === 0) {
        this._plAlertService.error('modelo22.messages.diplomavazio');
        return Promise.resolve();
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro07.campo077List.push({
          nif: this.nif,
          diploma: this.diploma,
          periodo: this.periodo,
          saldo: this.saldo,
          dotacao: this.dotacao
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  private _setDSDiploma(): void {
    if (this.is71) {
      this.dsDiploma.push({
        // 001
        value: 1,
        name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.1')
      });
    }
    if (this.is71 || this.is71A) {
      this.dsDiploma.push(
        {
          // 002
          value: 2,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.2')
        },
        {
          // 003
          value: 3,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.3')
        },
        {
          // 004
          value: 4,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.4')
        },
        {
          // 005
          value: 5,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.5')
        },
        {
          // 006
          value: 6,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.6')
        }
      );
    }
    if (this.is73) {
      this.dsDiploma.push({value: 731, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.731')});
    }
    if (this.is73 || this.is73A) {
      this.dsDiploma.push(
        {value: 732, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.732')},
        {value: 733, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.733')},
        {value: 734, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.734')}
      );
    }
    if (this.is74 || this.is74A) {
      this.dsDiploma.push(
        {value: 741, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.741')},
        {value: 742, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.742')},
        {value: 743, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.743')}
      );
    }
    if (this.is77) {
      this.dsDiploma.push(
        {
          // 001
          value: 1,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.1')
        },
        {
          // 002
          value: 2,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.2')
        },
        {
          // 003
          value: 3,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.3')
        },
        {
          // 004
          value: 4,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.4')
        },
        {
          // 005
          value: 5,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.5')
        },
        {
          // 006
          value: 6,
          name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.6')
        },
        {value: 731, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.731')},
        {value: 732, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.732')},
        {value: 733, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.733')},
        {value: 734, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.734')},
        {value: 741, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.741')},
        {value: 742, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.742')},
        {value: 743, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.dsdiploma.743')}
      );
    }
  }
}
