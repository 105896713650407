<div class="gto-module">
  <pl-tabs [theme]="'style3'" [(activeId)]="activeTab" [destroyOnHide]="false" (activeIdChange)="activeIdChanged($event)">
    <pl-tab [id]="tabIdPorEmpresa" caption="gto.tabs.title.porEmpresa">
      <div *plTabContent>
        <gto-por-empresa
          [sourceAnos]="anosList"
          [sourceResponsaveis]="responsaveis"
          [centralGestId]="centralGestId"
          [storeModePublic]="licencaStoreModePublic"
          [callback]="gtoPorEmpresaCallback"
          [(gtoSingleFilters)]="gtoSingleFilters">
        </gto-por-empresa>
      </div>
    </pl-tab>

    <pl-tab [id]="tabIdMultiEmpresa" caption="gto.tabs.title.multiEmpresa">
      <div *plTabContent>
        <gto-multi-empresa [sourceAnos]="anosList" [sourceResponsaveis]="responsaveis" [centralGestId]="centralGestId" [storeModePublic]="licencaStoreModePublic" [callback]="gtoMultiEmpresaCallback">
        </gto-multi-empresa>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
