<config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

<div class="entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="refresh" plPromise>
        <pl-group>
          <pl-group plAutoFocus>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_movimentosemaberto" attrName="listagem" [(model)]="reportModel"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label>Conta {{ tipo }}:</label>
            <edit>
              <entity-autocomplete entity="pocs" (modelChange)="onCCSelected($event)" [filter]="filtro" [placeholder]="'Pesquisar conta ' + tipo"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="optionShowGrupoConta">
            <label [translate]="'movimentosaberto.label.grupoConta'"></label>
            <edit>
              <entity-autocomplete entity="idide" attrName="idIdeChav" [outputKey]="'idIdeChav'" [(selectedKey)]="grupoConta" [output]="'{{idIdeChav}} - {{descricao}}'"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group class="align-self-end">
            <edit>
              <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-search fa-fw"></i><span [translate]="'global.btn.search'"></span></pl-button>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>
  <br />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
