<div class="rhgozoferias entity-detail-form">
  <pl-group>
    <edit>
      <pl-edit type="checkbox" attrName="usaFiltroFuncaoNosTiposProcessamento" [(model)]="model.usaMarcacaoGozoFerias" [properties]="{label: 'gozoferias.usaMarcacaoGozoFerias'}"></pl-edit>
    </edit>
  </pl-group>

  <pl-group>
    <label [translate]="'gozoferias.nCodAbdesDescontoGozoFeriasAnoAnterior'"></label>
    <edit>
      <entity-autocomplete
        entity="descontos"
        [model]="model"
        (selectedKeyChange)="model.nCodAbdesDescontoGozoFeriasAnoAnterior = $event"
        (evtSelectedDescriptionChanged)="model.nomeNCodAbdesDescontoGozoFeriasAnoAnterior = $event"
        [properties]="{disabled: !model.usaMarcacaoGozoFerias}"
        [fieldsMap]="{nCodABDESC: 'nCodAbdesDescontoGozoFeriasAnoAnterior', designacaoBreve: 'nomeNCodAbdesDescontoGozoFeriasAnoAnterior'}"
        [rowTemplate]="outputAbdes"
        [output]="outputAbdes"
        outputKey="nCodABDESC">
      </entity-autocomplete>
    </edit>
  </pl-group>

  <pl-group>
    <label [translate]="'gozoferias.nCodAbdesDescontoGozoFeriasAnoAtual'"></label>
    <edit>
      <entity-autocomplete
        entity="descontos"
        [model]="model"
        (selectedKeyChange)="model.nCodAbdesDescontoGozoFeriasAnoAtual = $event"
        (evtSelectedDescriptionChanged)="model.nomeNCodAbdesDescontoGozoFeriasAnoAtual = $event"
        [properties]="{disabled: !model.usaMarcacaoGozoFerias}"
        [fieldsMap]="{nCodABDESC: 'nCodAbdesDescontoGozoFeriasAnoAtual', designacaoBreve: 'nomeNCodAbdesDescontoGozoFeriasAnoAtual'}"
        [rowTemplate]="outputAbdes"
        [output]="outputAbdes"
        outputKey="nCodABDESC">
      </entity-autocomplete>
    </edit>
  </pl-group>

  <pl-group>
    <label [translate]="'gozoferias.motivoRegistoFerias'"></label>
    <edit>
      <entity-autocomplete
        entity="tiposregistocadastro"
        attrName="motivoRegistoFerias"
        [model]="model"
        (selectedKeyChange)="model.motivoRegistoFerias = $event"
        (evtSelectedDescriptionChanged)="model.nomeMotivoRegistoFerias = $event"
        [properties]="{disabled: !model.usaMarcacaoGozoFerias}"
        [fieldsMap]="{nMotivoMov: 'motivoRegistoFerias', designaCompl: 'nomeMotivoRegistoFerias'}"
        [outputDescription]="'designaCompl'"
        [rowTemplate]="outputMotReg"
        [output]="outputMotReg">
      </entity-autocomplete>
    </edit>
  </pl-group>
</div>
