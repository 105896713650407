import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IJsonEmpresaAno} from '../../interfaces/jsonEmpresa.interface';
import {IJsonPeriodo} from '../../entities/periodos/jsonPeriodo.entity.interface';
import {IApiQueryResponse, TServiceQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {IJsonPerioMudaPrimeiroMesContab} from '../../entities/empresas/empresas.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/empresas`;
  }

  public getLogotipoUrl(nEmpresa: string): Observable<string> {
    return of(`${this._path}/${nEmpresa}/imagem`);
  }

  public getUploadLogotipoUrl(nEmpresa: string): Observable<string> {
    return of(`${this._path}/${nEmpresa}/uploadlogotipo`);
  }

  public deleteLogotipo(nEmpresa: string): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/${nEmpresa}/deletelogotipo`});
  }

  public getLogo(nEmpresa: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/${nEmpresa}/imagem`});
  }

  public getAnos(nEmpresa: string): TServiceQueryResponse<IJsonEmpresaAno> {
    return this._apiService.get<IApiQueryResponse<IJsonEmpresaAno>>({url: `${this._path}/${nEmpresa}/anos`});
  }

  public getPeriodosIVA(nEmpresa: string, ano: number): TServiceQueryResponse<IJsonPeriodo> {
    return this._apiService.get<IApiQueryResponse<IJsonPeriodo>>({url: `${this._path}/${nEmpresa}/${ano}/periodosIVA`});
  }

  public getPerioMudaPrimeiroMesContab(): TServiceResponse<IJsonPerioMudaPrimeiroMesContab> {
    return this._apiService.get<IJsonPerioMudaPrimeiroMesContab>({url: `${this._path}/periomudaprimeiromescontab`});
  }

  public postPerioMudaPrimeiroMesContab(perioMudaPrimeiroMesContab: IJsonPerioMudaPrimeiroMesContab): TServiceResponse<IJsonPerioMudaPrimeiroMesContab> {
    return this._apiService.post<IJsonPerioMudaPrimeiroMesContab>({url: `${this._path}/periomudaprimeiromescontab`, body: perioMudaPrimeiroMesContab});
  }
}
