import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {MeiosPagamentoEditComponent} from './components/edit/meiosPagamento.entity.edit.component';
import {ENTITY_NAME_MEIOS_PAGAMENTO} from './meiosPagamento.entity.interface';
import {MeiosPagamentoListComponent} from './components/list/meiosPagamento.entity.list.component';
import {IJsonMeioPagamento} from './jsonMeioPagamento.entity.interface';

export const ENTITY_MEIOS_PAGAMENTO: IEntityDefinition<IJsonMeioPagamento> = {
  name: ENTITY_NAME_MEIOS_PAGAMENTO,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'meiosPagamento.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.meiosPagamento',
  metadata: {
    keyName: 'nCaixa',
    fields: [
      {name: 'nCaixa', type: 'cgsmallint', width: '60px', caption: 'meiosPagamento.fields.nCaixa'},
      {name: 'nome', caption: 'meiosPagamento.fields.nome', validators: {required: true}},
      {name: 'porDefeito', type: 'string', caption: 'meiosPagamento.fields.porDefeito', canOrder: false}
    ],
    order: 'nCaixa',
    searchFields: 'nCaixa,nome,descricao'
  },
  autocomplete: {
    rowTemplate: '{{nCaixa}} - {{nome}}',
    output: 'nome',
    searchFields: 'nCaixa,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.meiosPagamento'
      },
      component: MeiosPagamentoListComponent
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.meiosPagamento'
      },
      component: MeiosPagamentoEditComponent
    }
  }
};
