import {ENTITY_NAME_TOKENS, IToken} from './tokens.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_TOKENS: IEntityDefinition<IToken> = {
  name: ENTITY_NAME_TOKENS,
  url: 'manager',
  roles: [ROLE.ADMIN],
  searchPlaceholder: 'tokens.pesquisa',
  metadata: {
    keyName: 'token',
    fields: [
      {name: 'token', width: '60px', caption: 'tokens.fields.token', validators: {required: true}},
      {name: 'validUntil', width: '60px', caption: 'tokens.fields.validUntil', type: 'datetime', validators: {required: true}}
    ],
    order: 'token',
    searchFields: 'token'
  },
  autocomplete: {
    rowTemplate: '{{token}}',
    output: 'token',
    searchFields: 'token'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: false
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ADMIN],
        pageTitle: 'global.menu.tokens'
      }
    }
  }
};
