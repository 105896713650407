import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {FaturacaoEletronicaConfiguracoesService} from '../../service/faturacaoEletronicaConfiguracoes.module.service';

@Component({
  selector: 'faturacao-eletronica-configuracoes-post-add-safe-certificado-modal',
  templateUrl: './faturacaoEletronicaConfiguracoes.postAddSafeCertificado.modal.component.html'
})
export class FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nomeDaConta: string;
  @Input() public accessToken: string;
  @Input() public safetyKey: string;
  @Input() public expiresIn: number;
  @Input() public ambientePreProducao: boolean;
  public modalTitle: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _faturacaoEletronicaConfiguracoesService: FaturacaoEletronicaConfiguracoesService
  ) {
    super(_injector);
    this.disableClose();
  }

  public ngOnInit(): void {
    this.modalTitle = !this.ambientePreProducao ? 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.title' : 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.titlePreProduction';
    this.execute();
  }

  public execute(): Promise<void> {
    this.disableClose();
    return this._faturacaoEletronicaConfiguracoesService
      .postAddSAFECertificate(this.nomeDaConta, this.accessToken, this.safetyKey, this.expiresIn, this.ambientePreProducao)
      .then(() => {
        this.enableClose();
        this.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
