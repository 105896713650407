<div class="rhsegsocialfolhaferias entity-detail-form">
  <fieldset>
    <legend [translate]="'rhmanutencao.segsocialfolhaferias.title'"></legend>

    <pl-group>
      <edit>
        <pl-edit
          type="checkbox"
          attrName="ffUsaTipodeRemunEspecificaFichSeguradora"
          [(model)]="rhsegsocialfolhaferias.ffUsaTipodeRemunEspecificaFichSeguradora"
          [properties]="{label: 'rhmanutencao.segsocialfolhaferias.cardseguradora.usaTipodeRemunEspecificaFichSeguradora'}">
        </pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <edit>
        <pl-edit
          type="checkbox"
          attrName="ffFichSeguradoraEnvioFichPelaExclAbDescCampo"
          [(model)]="rhsegsocialfolhaferias.ffFichSeguradoraEnvioFichPelaExclAbDescCampo"
          [properties]="{label: 'rhmanutencao.segsocialfolhaferias.cardseguradora.fichSeguradoraEnvioFichPelaExclAbDescCampo'}">
        </pl-edit>
      </edit>
    </pl-group>
  </fieldset>
</div>
