import {Component, Input} from '@angular/core';
import {IPlFormSubmitEvent} from 'pl-comps-angular';
import {CGModalComponent} from '../../cg/modal/cgmodal.component';
import {IPortalUITreeEditNodeModalResult} from './portal.uitree.editnode.modal.component.interface';

@Component({
  selector: 'portal-ui-tree-editnode-modal',
  templateUrl: './portal.uitree.editnode.modal.component.html'
})
export class PortalUITreeEditNodeModalComponent extends CGModalComponent<IPortalUITreeEditNodeModalResult> {
  @Input() public moduleName: string;
  @Input() public pageTitle: string;
  @Input() public sidebarTitle: string;
  @Input() public icon: string;

  public save({instance}: IPlFormSubmitEvent<void>): void {
    if (!instance.valid) {
      return;
    }
    this.close({
      pageTitle: this.pageTitle,
      sidebarTitle: this.sidebarTitle,
      icon: this.icon
    });
  }
}
