import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {
  EModeloTributAutonoma,
  IJsonTributAutonoAnaliseHeader,
  IJsonTributAutonomaAnalise,
  IJsonTributAutonomaHeader,
  IJsonTributAutonomaSave,
  IJsonTributAutonomaSearch
} from './jsonTributAutonomas.module.interface';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TributAutonomasModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/tributautonomas`;
  }

  public loadDefaultHeader(): TServiceResponse<IJsonTributAutonomaHeader> {
    return this._apiService.get<IJsonTributAutonomaHeader>({url: `${this._path}/header`});
  }

  public atualizaModeloTributAutonoma(modeloTributAutonoma: EModeloTributAutonoma, prejuizoFiscal: boolean): TServiceResponse<void> {
    return this._apiService.get<void>({url: `${this._path}/atualizamodelo`, params: {modelotributautonoma: modeloTributAutonoma, prejuizofiscal: prejuizoFiscal}});
  }

  public copiaConfiguracaoParaOutrasEmpresas(empresas: string, header: IJsonTributAutonomaHeader): TServiceResponse<void> {
    return this._apiService.post<void, IJsonTributAutonomaHeader>({url: `${this._path}/copia`, params: {empresas: empresas}, body: header});
  }

  public searchClassificacaoContas(header: IJsonTributAutonomaHeader): TServiceResponse<IJsonTributAutonomaSearch> {
    return this._apiService.post<IJsonTributAutonomaSearch, IJsonTributAutonomaHeader>({url: `${this._path}/search`, body: header});
  }

  public saveClassificacaoContas(tributautonosave: IJsonTributAutonomaSave): TServiceResponse<void> {
    return this._apiService.post<void, IJsonTributAutonomaSave>({url: `${this._path}/save`, body: tributautonosave});
  }

  public loadDefaultAnaliseHeader(): TServiceResponse<IJsonTributAutonoAnaliseHeader> {
    return this._apiService.get<IJsonTributAutonoAnaliseHeader>({url: `${this._path}/analise/header`});
  }

  public searchClassificacaoContasAnalise(header: IJsonTributAutonoAnaliseHeader): TServiceResponse<Array<IJsonTributAutonomaAnalise>> {
    return this._apiService.post<Array<IJsonTributAutonomaAnalise>, IJsonTributAutonoAnaliseHeader>({url: `${this._path}/analise/search`, body: header});
  }

  public printAnaliseUrl(header: IJsonTributAutonoAnaliseHeader, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/analise/report`, {
      contasde: header.contasDe,
      contasate: header.contasAte,
      periodode: header.periodoDe,
      periodoate: header.periodoAte,
      prejuizofiscal: header.prejuizoFiscal,
      reportname: reportName
    });
  }
}
