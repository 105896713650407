import {IJsonAtivosImport, IJsonAtivosImportAjustes, IJsonAtivosImportConsistenciasContas} from './jsonAtivosImport.entity.interface';
import {IJsonDocContabilidade} from '../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {TDate} from '../../../common/dates';
import {IDevExpressDataGridEventOnRowClick} from '../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

export enum ECamposParaSelecao {
  Designacao,
  NMapaAmortizacao,
  CodAmortizacao,
  ViatLigArt11DR,
  TaxaAmortizacao,
  FiscalTaxaAmortizacao,
  DataAquisicao,
  DataInicioUtiliza,
  NContaFornecedor,
  ValorAquisicao,
  ValorAquisicaoFiscal,
  NContaDoAtivo,
  PercReducao,
  FiscalPercReducao,
  BaseCalculo,
  MetodoCalculo,
  DepreciacaoAcumulada,
  FiscalDepreciacaoAcumulada,
  DataUltProcessamento,
  Usado,
  Qtd,
  RefExterna,
  Estado,
  DataAbate,
  ValorVenda,
  ValorResidual,
  NCCusto,
  NZona,
  NDepto,
  NSubde,
  TipoArtigo,
  NGrFamilia,
  NFamilia,
  NSubfa,
  CodContab,
  Classe,
  Categoria,
  NVendedor,
  NRefProcesso,
  NSeccao
}

export enum ECamposParaSelecaoReavaliacao {
  DL126 = '40,41,42',
  DL24 = '43,44,45',
  DL219 = '46,47,48',
  DL399G = '49,50,51',
  DL118B = '52,53,54',
  DL111 = '55,56,57',
  DL49 = '58,59,60',
  DL264 = '61,62,63',
  DL31 = '64,65,66'
}

export enum ENavTabsAtivosImport {
  PorImportar = 'porImportar',
  Importados = 'importados'
}

export enum EAtivosImportStatus {
  Inactive = 'aisInactive',
  Scheduled = 'aisScheduled',
  Started = 'aisStarted',
  Ended = 'aisEnded',
  Timeout = 'aisTimeout',
  Error = 'aisError'
}

export interface ICampoExport {
  _value?: number;
  _valueStr?: string;
  _name: string;
  _selected: boolean;
  _required: boolean;
}

export interface IAtivosImport extends IJsonAtivosImport {
  _selected?: boolean;
}

export interface IAtivosImportInconsistencias {
  tipo: string;
  inconsistencias: Array<IJsonAtivosImportConsistenciasContas>;
  _collapsed: boolean;
}

export interface IAtivosImportAjusteContasDoc extends IJsonAtivosImportAjustes {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IAtivosImportAjusteContasDocumentoEvento {
  data: IAtivosImportAjusteContasDoc;
  event: IDevExpressDataGridEventOnRowClick;
}

export interface IAtivosImportStatus {
  requestDate: TDate;
  state: EAtivosImportStatus;
  userStartedId: number;
  position: number;
  description: string;
  max: number;
}

export interface IAtivosImportDataGrid {
  caption: string;
  dataField?: string;
  dataType?: string;
  alignment?: string;
  width?: number;
  format?: string;
  allowSearch?: boolean;
  allowHeaderFiltering?: boolean;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  cellTemplate?: string;
  headerTemplate?: string;
  fields?: Array<IAtivosImportDataGrid>;
}

export const ENTITY_NAME_ATIVOS_IMPORT = 'ativosimport';
