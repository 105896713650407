import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {EEntidadeHabitual} from '../../services/contabilidadedigital/jsonContabDigital.interface';
import {DATA_SOURCE_NAME_ENTIDADE_HABITUAL} from './entidadeHabitual.datasource.interface';

export const DATA_SOURCE_ENTIDADE_HABITUAL: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ENTIDADE_HABITUAL,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EEntidadeHabitual.Desconhecido, name: 'entidadehabitual.desconhecido'},
    {value: EEntidadeHabitual.Clientes, name: 'entidadehabitual.clientes'},
    {value: EEntidadeHabitual.Fornecedores, name: 'entidadehabitual.fornecedores'}
  ]
});
