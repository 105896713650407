import {HttpResponse} from '@angular/common/http';
import {DATA_SOURCE_NAME_SINAL_TAXA_RETENCAO} from '../../datasources/sinaltaxaretencao/sinalTaxaRetencao.datasource.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRegimesTributacao} from '../../modules/portalcontabilidade/modelo30/jsonModelo30.module.interface';
import {IJsonTxRet} from './jsonTxRet.entity.interface';
import {Modelo30Service} from '../../modules/portalcontabilidade/modelo30/modelo30.module.service';
import {ROLE} from '../../services/role.const';
import {TaxasRetencaoEditComponent} from './components/edit/taxasRetencao.entity.edit.component';
import {ENTITY_NAME_TAXAS_RETENCAO} from './taxasRetencao.entity.interface';

export const ENTITY_TAXAS_RETENCAO: IEntityDefinition<IJsonTxRet> = {
  name: ENTITY_NAME_TAXAS_RETENCAO,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.RH],
  searchPlaceholder: 'taxasretencao.pesquisa',
  pageTitle: 'global.menu.taxasretencao',
  metadata: {
    keyName: 'codRet',
    fields: [
      {name: 'codRet', width: '150px', caption: 'taxasretencao.fields.codRet', validators: {required: true}},
      {name: 'nomeRet', caption: 'taxasretencao.fields.nomeRet', validators: {required: true}},
      {name: 'taxaRet', type: 'double', caption: 'taxasretencao.fields.taxaRet', properties: {decimalsLimit: 2}, validators: {min: 0, max: 100}},
      {name: 'tipoCodRet', caption: 'taxasretencao.fields.tipoCodRet'},
      {name: 'sinal', caption: 'taxasretencao.fields.sinal', type: DATA_SOURCE_NAME_SINAL_TAXA_RETENCAO},
      {name: 'tipRendiment', caption: 'taxasretencao.fields.tipRendiment'},
      {
        name: 'nomeTipRendiment',
        caption: 'taxasretencao.fields.nomeTipRendiment',
        entity: {
          name: 'rendis',
          keyTarget: 'tipRendiment',
          output: '{{tipRendiment}} - {{nome}}',
          outputTarget: 'nomeTipRendiment',
          fieldsMap: {tipRendiment: 'tipRendiment', nome: 'nomeTipRendiment'}
        }
      },
      {name: 'nContaRetencao', caption: 'taxasretencao.fields.nContaRetencao'},
      {
        name: 'nomeContaRetencao',
        caption: 'taxasretencao.fields.nomeContaRetencao',
        entity: {
          name: 'pocs',
          keyTarget: 'nContaRetencao',
          output: '{{nConta}} - {{nome}}',
          outputTarget: 'nomeContaRetencao',
          fieldsMap: {nConta: 'nContaRetencao', nome: 'nomeContaRetencao'},
          filter: 'registaRetencao=1'
        }
      }
    ],
    order: 'codRet',
    listFields: 'codRet,nomeRet,taxaRet',
    detailFields: 'codRet,nomeRet,taxaRet'
  },
  autocomplete: {
    rowTemplate: '{{codRet}} - {{nomeRet}} ({{taxaRet}})',
    output: 'nomeRet',
    searchFields: 'codRet,nomeRet'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: TaxasRetencaoEditComponent,
      resolve: [
        {
          provide: 'regimesTributacao',
          deps: [Modelo30Service],
          useFactory: (modelo30Service: Modelo30Service): Promise<Array<IJsonRegimesTributacao>> => {
            return modelo30Service.getRegimesTributacao().then((response: HttpResponse<Array<IJsonRegimesTributacao>>) => response.body);
          }
        }
      ]
    }
  }
};
