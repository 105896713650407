import {Subject} from 'rxjs';
import {isFunction, isPromiseLike} from '../../../../common/utilities/utilities';
import {PlDynamicVisualsActiveReference} from './dynamicvisuals.active.reference';
import {PlDynamicVisualsContentReference} from './dynamicvisuals.content.reference';
import {TValueOrPromise} from '../../../../common/utilities/utilities.interface';

export class PlDynamicVisualsReference<T = unknown> extends PlDynamicVisualsActiveReference<T> {
  public readonly result: Promise<T>;

  private readonly _closed: Subject<void>;
  private _resolve: (result?: T) => void;
  private _reject: (reason?: unknown) => void;

  constructor(
    protected readonly _contentReference: PlDynamicVisualsContentReference,
    protected readonly _beforeDismiss?: () => TValueOrPromise<boolean>
  ) {
    super();
    this._closed = new Subject<void>();
    this.result = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(undefined, () => {
      // avoid reporting errors of not catching this promise
    });
  }

  public close(result?: T): void {
    this._resolve(result);
    this._cleanup();
  }

  public dismiss(reason?: unknown): void {
    if (!isFunction(this._beforeDismiss)) {
      this._dismiss(reason);
      return;
    }
    const dismiss: boolean | Promise<boolean> = this._beforeDismiss();
    if (isPromiseLike(dismiss)) {
      dismiss.then(
        (result: boolean) => {
          if (result !== false) {
            this._dismiss(reason);
          }
        },
        () => {
          // reject, cancel dismiss
        }
      );
    } else if (dismiss !== false) {
      this._dismiss(reason);
    }
  }

  public closed(): Subject<void> {
    return this._closed;
  }

  public get componentInstance(): any {
    return this._contentReference?.componentRef?.instance;
  }

  public get componentElement(): HTMLElement {
    return this._contentReference?.componentRef?.location?.nativeElement;
  }

  protected _dismiss(reason?: any): void {
    this._reject(reason);
    this._cleanup();
  }

  protected _cleanup(): void {
    if (this._contentReference.viewRef.destroyed) {
      return;
    }
    if (this._contentReference?.viewRef) {
      this._contentReference.viewRef.destroy();
    }
    if (this.componentElement?.parentElement) {
      this.componentElement.parentElement.removeChild(this.componentElement);
    }
    this._closed.next();
    this._closed.complete();
  }
}
