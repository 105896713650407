import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {IJsonPeriodo} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ConfigService} from '../../../../services/config/config.service';
import {ContabilidadeEstatisticaService} from '../contabilidadeEstatistica.module.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {IPlToolbarItem, KEYCODES, toInteger} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IReportInstance} from '../../../../components/reports/input/reports.input.component.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const DEFAULT_REPORT_NAME = 'Extract05.FR3';
const PERIODO_YEAR_LENGTH = 4;
const BTN_ID_PROCESSAR = 'processar';

@Component({
  selector: 'extratos-de-contas',
  templateUrl: './contabilidade.extratosDeContas.module.component.html'
})
export class ContabilidadeExtratosComponent extends ModuloComponent implements OnInit {
  public deConta: string;
  public ateConta: string;
  public pdfUrl: string;
  public reportModel: IJsonReport;
  public atePeriodo: IJsonPeriodo;
  public dePeriodo: IJsonPeriodo;
  public loadAllAnos: number;
  public loadOnlyAno: number;

  private readonly _reportService: IReportInstance;
  private readonly _entityPeriodos: IEntityService<IJsonPeriodo>;
  private readonly _btnProcessar: IPlToolbarItem;
  private readonly _btnProcessXml: IPlToolbarItem;
  private _atePeriodo: string;
  private _dePeriodo: string;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _config: ConfigService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);

    this._reportService = this._reportsRegistryService.get(EReport.ExtratosPorConta);
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo>('periodos');

    this._btnProcessXml = {
      id: 'btn-processarexcel',
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      caption: 'global.btn.processExcel',
      disabled: true,
      download: {
        url: undefined
      }
    };

    this._btnProcessar = {
      id: BTN_ID_PROCESSAR,
      order: 2,
      type: 'dropdown-split',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => {
        this._processar();
      },
      menu: [this._btnProcessXml]
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.deConta = '1';
    this.ateConta = '89999999';

    this.toolbar.addButton(this._btnProcessar);
    this._loadPeriodos();
    this._loadDefaultReport();

    this.loadAllAnos = 1;
    this.loadOnlyAno = 0;
  }

  public deContaChanged(value: string): void {
    this.deConta = value;
    this.ateConta = value;
    this._filtersChanged();
  }

  public ateContaChanged(value: string): void {
    this.ateConta = value;
    this._filtersChanged();
  }

  public atePeriodoChanged(value: IJsonPeriodo): void {
    this.atePeriodo = value;
    this._atePeriodo = value.periodo;
    if (value) {
      const ano = toInteger(value.periodo.substring(0, PERIODO_YEAR_LENGTH));
      if (ano === this._config.configurations.empresa.anoEmCursoIRC || ano === this._config.configurations.empresa.anoEmCursoIRC + 1) {
        this.loadOnlyAno = 0;
        this.loadAllAnos = 0;
      } else {
        this.loadOnlyAno = ano;
        this.loadAllAnos = 1;
      }
    }
    this._filtersChanged();
  }

  public dePeriodoChanged(value: IJsonPeriodo): void {
    this.dePeriodo = value;
    this._dePeriodo = value.periodo;
    if (value) {
      const ano = toInteger(value.periodo.substring(0, PERIODO_YEAR_LENGTH));
      if (ano === this._config.configurations.empresa.anoEmCursoIRC || ano === this._config.configurations.empresa.anoEmCursoIRC + 1) {
        this.loadOnlyAno = 0;
        this.loadAllAnos = 0;
      } else {
        this.loadOnlyAno = ano;
        this.loadAllAnos = 1;
      }
    }
    this._filtersChanged();
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: DEFAULT_REPORT_NAME,
      title: DEFAULT_REPORT_NAME
    };
    return this._reportService.query().then((reports: Array<IJsonReport>) => {
      if (!reports.length) {
        return;
      }
      let foundDefault = false;
      for (const report of reports) {
        if (report.name === this.reportModel.name) {
          this.reportModel = {name: report.name, title: report.title};
          foundDefault = true;
          break;
        }
      }
      if (!foundDefault) {
        this.reportModel = reports[0];
      }
    });
  }

  private _loadPeriodos(): Promise<void> {
    return this._entityPeriodos.query().then((response: THttpQueryResponse<IJsonPeriodo>) => {
      if (!this.dePeriodo && !this.atePeriodo) {
        if (response.body.list.length) {
          this.dePeriodo = response.body.list[0];
          this.atePeriodo = response.body.list[response.body.list.length - 1];
          this.dePeriodoChanged(this.dePeriodo);
          this.atePeriodoChanged(this.atePeriodo);
        }
      } else {
        this.dePeriodoChanged(this.dePeriodo);
        this.atePeriodoChanged(this.atePeriodo);
      }
    });
  }

  private _processar(): void {
    this._contabilidadeEstatisticaService.getExtratosDeContasUrl(this.deConta, this.ateConta, this._dePeriodo, this._atePeriodo, this.reportModel.name, 0).subscribe((url: string) => {
      this.pdfUrl = url;
    });
    this._cardPanel.collapse();
  }

  private _filtersChanged(): void {
    this._contabilidadeEstatisticaService.getExtratosDeContasUrl(this.deConta, this.ateConta, this._dePeriodo, this._atePeriodo, this.reportModel.name, 1).subscribe((url: string) => {
      this._btnProcessXml.disabled = false;
      this._btnProcessXml.download.url = url;
    });
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_ID_PROCESSAR);
    }
  }
}
