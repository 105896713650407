import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAdiantamentoConfig} from '../../jsonAdiantamentos.module.interface';
import {AdiantamentosAutoConfigModalComponent} from '../autoconfig/adiantamentos.autoConfig.modal.component';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AdiantamentosService} from '../../adiantamentos.module.service';
import {IAdiantamentosRegisto, IAdiantamentosRegistoResult} from '../../adiantamentos.module.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonIva} from '../../../../entities/ivas/jsonIva.entity.interface';
import {ENTITY_NAME_CLIENTES, TClifos} from '../../../../entities/clifos/clifos.entity.interface';
import {round} from '../../../../../common/utils/utils';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {TranslateService} from '@ngx-translate/core';

const DIVIDE_VALUE = 100;

@Component({
  selector: 'modal-adiantamentos-registar',
  templateUrl: './adiantamentos.registar.modal.component.html'
})
export class AdiantamentosRegistarModalComponent extends CGModalComponent<IAdiantamentosRegistoResult> implements OnInit {
  @Input() public adiantamentoConfig: IJsonAdiantamentoConfig;
  @Input() public adiantRegisto: IAdiantamentosRegisto;
  @Input() public clifoEntName: TClifos;
  @Input() public atualDoc: boolean;

  public readonly nDocFaOutput: string;
  public readonly clifosOutput: string;
  public readonly ivasOutput: string;
  public readonly decimals: number;

  public docfaNumFilter: string;
  public docFilter: string;
  public clifoCaption: string;
  public description: string;
  public configured: boolean;
  public disableNConta: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _adiantamentosService: AdiantamentosService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.nDocFaOutput = '{{nDocFa}} - {{nome}}';
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this.ivasOutput = '{{codIva}} - {{nome}}';
    this.adiantRegisto = {
      nDocFa: undefined,
      nomeDocFa: '',
      nNumer: undefined,
      nConta: '',
      nomeNConta: '',
      codIva: undefined,
      nomeCodIva: '',
      taxaIva: 0,
      valorSemIva: 0,
      valorComIva: 0,
      grupoDocFa: undefined,
      nDoc: '',
      faccbId: undefined
    };
    this.decimals = this.configurations.gestaoComercial.decimais.valoresLinha;
    this.autoConfigModal = this.autoConfigModal.bind(this);
  }

  public ngOnInit(): void {
    this.docfaNumFilter = this.adiantRegisto?.nDocFa ? `nDocFa=${this.adiantRegisto.nDocFa}&encerrado=0&visivelERPCloud=1` : undefined;
    const grupoDocFaFilter: EGrupoDoc = !this.clifoEntName ? undefined : this.clifoEntName === ENTITY_NAME_CLIENTES ? EGrupoDoc.VendasEfectivas : EGrupoDoc.ComprasEfectivas;
    this.docFilter = grupoDocFaFilter ? `grupoDocfa=${Number(grupoDocFaFilter)}` : undefined;
    this.adiantRegisto.codIva = this.adiantRegisto.codIva === 0 && isEmpty(this.adiantRegisto.nomeCodIva) ? undefined : this.adiantRegisto.codIva >= 0 ? this.adiantRegisto.codIva : undefined;
    this.adiantRegisto.nDocFa = this.adiantRegisto.nDocFa > 0 ? this.adiantRegisto.nDocFa : undefined;
    this.adiantRegisto.nNumer = this.adiantRegisto.nNumer > 0 ? this.adiantRegisto.nNumer : undefined;

    this.description =
      !isEmpty(this.adiantRegisto.faccbId) && this.adiantRegisto.grupoDocFa !== EGrupoDoc.VendasEfectivas && this.adiantRegisto.grupoDocFa !== EGrupoDoc.ComprasEfectivas
        ? this._translateService.instant('adiantamentos.modals.registar.emitAdiantDoc', {doc: this.adiantRegisto.nDoc})
        : 'adiantamentos.modals.registar.emitAdiant';
    let promise: Promise<void>;
    if (!this.adiantamentoConfig) {
      promise = this._adiantamentosService.adiantamentosConfig().then((response: HttpResponse<IJsonAdiantamentoConfig>) => {
        this.adiantamentoConfig = response.body;
      });
    }
    Promise.resolve(promise).then(() => {
      this.configured = !isEmpty(this.adiantamentoConfig.nArtigoAdiantamento) && !isEmpty(this.adiantamentoConfig.familia);
    });
    this.disableNConta =
      (this.atualDoc && !isEmpty(this.adiantRegisto.nConta)) ||
      (!isEmpty(this.adiantRegisto.nConta) && this.adiantRegisto.grupoDocFa !== EGrupoDoc.VendasEfectivas && this.adiantRegisto.grupoDocFa !== EGrupoDoc.ComprasEfectivas);
    this.clifoCaption = `${this.clifoEntName}.title_singular`;
  }

  public async close(): Promise<void> {
    if (!this.adiantamentoConfig?.nArtigoAdiantamento) {
      this._plAlertService.error('adiantamentos.messages.sistemNotConfigured');
      return;
    }
    if (!this.adiantRegisto.nDocFa || !this.adiantRegisto.nNumer || isEmpty(this.adiantRegisto.nConta)) {
      this._plAlertService.error('adiantamentos.messages.configDocFields');
      return;
    }
    if (this.adiantRegisto.valorSemIva <= 0) {
      this._plAlertService.error('adiantamentos.messages.valueInvalid');
      return;
    }
    let faccbID = 0;
    if (!this.atualDoc && this.adiantRegisto.faccbId) {
      this.disableClose();
      try {
        faccbID = (await this._adiantamentosService.registarAdiantamento(this.adiantRegisto)).body.value;
      } finally {
        this.enableClose();
      }
    }
    super.close({adiantamento: this.adiantRegisto, faccbID, config: this.adiantamentoConfig});
  }

  public autoConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AdiantamentosAutoConfigModalComponent);
    const componentInstance: AdiantamentosAutoConfigModalComponent = modalInstance.componentInstance;
    componentInstance.configAdiantamentos = undefined;
    componentInstance.fromModalConfig = true;
    return modalInstance.result.then((result: IJsonAdiantamentoConfig) => {
      this.configured = !isEmpty(result.nArtigoAdiantamento) && !isEmpty(result.familia);
      this.adiantamentoConfig = result;
    });
  }

  public changeCodIva(value: IJsonIva): void {
    this.adiantRegisto.taxaIva = value.taxaActual;
    this.calcValComIva();
  }

  public calcValComIva(): void {
    this.adiantRegisto.valorComIva = round(this.adiantRegisto.valorSemIva * (1 + this.adiantRegisto.taxaIva / DIVIDE_VALUE), this.decimals);
  }

  public calcValSemIva(): void {
    this.adiantRegisto.valorSemIva = round(this.adiantRegisto.valorComIva / (1 + this.adiantRegisto.taxaIva / DIVIDE_VALUE), this.decimals);
  }
}
