import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ETipoAbonoDesconto} from '../../../../../entities/abdes/abdes.entity.interface';
import {ETipoResultadoFerias, IJsonRhCessaContratoTrabalhoEncerrar, IJsonRhCessaContratoTrabalhoForm} from '../../jsonRHCessaContratoTrabalho.module.interface';
import {generateUUID} from '../../../../../../common/utils/utils';
import moment from 'moment';
import {RhCessaContratoTrabalhoService} from '../../rhCessaContratoTrabalho.module.service';

@Component({
  selector: 'modal-addabonodesconto',
  templateUrl: './addAbonoDesconto.modal.component.html'
})
export class AddAbonoDescontoModalComponent extends CGModalComponent<IJsonRhCessaContratoTrabalhoEncerrar> implements OnInit {
  @Input() public cessaContTrabForm: IJsonRhCessaContratoTrabalhoForm;
  public readonly tipoAbonoDesconto: typeof ETipoAbonoDesconto;

  public model: IJsonRhCessaContratoTrabalhoEncerrar;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhcessaContratoTrabalhoService: RhCessaContratoTrabalhoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.model = {
      idLinha: generateUUID(),
      valor: 0,
      codABDESC: '',
      codEMP: 0,
      resultadoFerias: ETipoResultadoFerias.AnoAtual,
      abonoDesc: ETipoAbonoDesconto.Abono,
      data: moment(),
      observacoes: '',
      nCodABDESC: '',
      descricaoAbono: '',
      quantidade: 1,
      orderbyProc: 0,
      infoProcessamentoGerado: ''
    };
    this.tipoAbonoDesconto = ETipoAbonoDesconto;
  }

  public ngOnInit(): void {
    this.model.codEMP = this.cessaContTrabForm.codEmp;
    this.model.data = this.cessaContTrabForm.dataCessacao;
  }

  public close(): Promise<void> {
    this.model.nCodABDESC = String(this.model.abonoDesc) + this.model.codABDESC;
    return this._rhcessaContratoTrabalhoService.postValidarNovaLinhaEncerrar(this.model).then(() => {
      this._plAlertService.success('rhcessacontratotrabalho.infos.abonodescsuccess');
      super.close(this.model);
    });
  }

  public abonoDescChanged(): void {
    this.model.codABDESC = '';
    this.model.descricaoAbono = '';
  }
}
