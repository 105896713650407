import {ColaboradoresVisualizarRefeicoesModuleComponent} from './components/visualizaRefeicoes.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_VISUALIZAR_REFEICOES: IModuleDefinition = {
  name: 'colaboradoresVisualizaRefeicoes',
  state: {
    url: '/refeicoes/visualizar',
    component: ColaboradoresVisualizarRefeicoesModuleComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTOREMENTAS],
      pageTitle: 'global.menu.visualizarRefeicoes'
    }
  }
};
