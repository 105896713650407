<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscontabilidade.viewDocTitle'" [translateParams]="{nDocumento: doc?.nDocumento || ''}"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <documento-contabilidade *ngIf="doc" [(doc)]="doc" [showToolbar]="false"></documento-contabilidade>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
