import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAnalisadorTesValoresAntItem, IJsonAnalisadorTesValoresAntSaveItem} from '../../analisadorTes.module.interface';
import CustomStore from 'devextreme/data/custom_store';
import {merge} from 'lodash-es';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {AnalisadorTesService} from '../../analisadorTes.module.service';
import {PlAlertService} from 'pl-comps-angular';
import moment, {Moment} from 'moment';

@Component({
  selector: 'analisador-tesouraria-valores-anteriores-modal',
  templateUrl: './analisadorTes.valoresAnteriores.modal.html'
})
export class AnalisadorTesValoresAnterioresModalComponent extends CGModalComponent<void> {
  @Input() public dateSourceList: Array<IJsonAnalisadorTesValoresAntItem>;

  public newDataTesAll: Moment;
  public readonly definitionsDataGrid: IDevExpressDataGrid;
  public readonly newDataTesAllMinDate: Moment;
  private _dataGridInstance: dxDataGrid;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _analisadorTesService: AnalisadorTesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dateSourceList = [];
    this._isFirstTime = true;
    this.newDataTesAllMinDate = moment();
    this.newDataTesAll = moment();
    this.definitionsDataGrid = {
      editing: {
        mode: 'cell',
        startEditAction: 'click',
        selectTextOnEditStart: true,
        allowUpdating: true
      },
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      },
      columns: [
        {dataField: 'nomeRubrica', dataType: 'string', caption: 'analisadorTes.strings.rubrica', allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'analisadorTes.strings.descricao', allowEditing: false},
        {dataField: 'nDocString', dataType: 'string', caption: 'analisadorTes.strings.documento', allowEditing: false},
        {dataField: 'nConta', dataType: 'string', caption: 'analisadorTes.strings.nConta', allowEditing: false},
        {dataField: 'nomeConta', dataType: 'string', caption: 'analisadorTes.strings.nomeConta', allowEditing: false},
        {dataField: 'valor', dataType: 'number', caption: 'analisadorTes.strings.valor', allowEditing: false},
        {dataField: 'data', dataType: 'date', caption: 'global.text.date', allowEditing: false},
        {
          dataField: 'newDataTes',
          dataType: 'date',
          caption: 'analisadorTes.strings.novaData',
          allowEditing: true,
          editorOptions: {
            min: moment()
          }
        }
      ],
      dataSource: new CustomStore({
        key: 'tesLancoID',
        load: () => this._getsTableSource(),
        update: (tesLancoID: string, values: Partial<IJsonAnalisadorTesValoresAntItem>) => {
          const record: IJsonAnalisadorTesValoresAntItem = this.dateSourceList.find((item: IJsonAnalisadorTesValoresAntItem) => item.tesLancoID === tesLancoID);
          if (record) {
            merge(record, values);
          }
          return Promise.resolve();
        }
      }),
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      filterRow: {visible: false}
    };
  }

  public readonly fnSaveModal = (): Promise<void> => this._saveModal();

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this._isFirstTime) {
      this._dataGridInstance.selectAll();
      this._isFirstTime = false;
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public applyNewDataTesAll(): void {
    this.dateSourceList.forEach((item) => {
      item.newDataTes = this.newDataTesAll;
    });
  }

  private _getsTableSource(): Array<IJsonAnalisadorTesValoresAntItem> {
    return this.dateSourceList;
  }

  private _saveModal(): Promise<void> {
    const selArray: Array<IJsonAnalisadorTesValoresAntSaveItem> = this._dataGridInstance.getSelectedRowsData().map((item: IJsonAnalisadorTesValoresAntItem) => {
      const selItem: IJsonAnalisadorTesValoresAntSaveItem = {tesLancoID: item.tesLancoID, newDataTes: item.newDataTes};
      return selItem;
    });

    return this._analisadorTesService.saveTesLancoValoresAnt(selArray).then(() => {
      this._plAlertService.success('analisadorTes.messages.datasAlteradasComSucesso');
      this.close();
    });
  }
}
