import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {PCAMovimentosAbertoComponent} from './components/movimentosAberto.module.component';

export const MODULE_PCA_MYACCOUNT_MOVIMENTOS_ABERTO: IModuleDefinition = {
  name: 'pcaMovimentosAbertoCliente',
  state: {
    url: '/movimentosaberto',
    component: PCAMovimentosAbertoComponent,
    data: {
      roles: [ROLE.PCA],
      sidebarTitle: 'portals.sidebar.modules.pcaMovimentosAbertoCliente'
    }
  }
};
