import {IJsonCRABD, IJsonCRABDExcecoes} from './jsonCrAbD.entity.interface';

export enum ESiglasCategAbonos {
  SG,
  V,
  D,
  P,
  C,
  SR,
  AC,
  HE,
  FE,
  SFE,
  FEP,
  SN,
  TN,
  IH,
  ST,
  FAP,
  IC,
  ID,
  IG,
  DL,
  RPL,
  IVA,
  VIR,
  SAF,
  TVR,
  PE,
  VE,
  UVP,
  GB,
  CO
}

export enum ESiglasCategDescontos {
  SG,
  IRS,
  SS,
  CGA,
  IS,
  SIN,
  SEG,
  FA,
  FE,
  PU,
  RIT,
  SR,
  O,
  SE,
  ADSE,
  PE,
  FCT,
  FGCT,
  PPA,
  IFAP
}

export interface ICRABD extends IJsonCRABD {
  abonoDesc: string;
  designacaoCategoria: string;
  nomeExcecao: string;
}

export interface ICRABDEntidadeSuporte {
  percentagem: number;
  entSuporte: number;
}

export interface ICRABDEditStateParams {
  abdes?: string;
  id?: string;
  crabd?: IJsonCRABDExcecoes;
}

export const ENTITY_NAME_CRABD = 'crabd';

export const ENTITY_NAME_CRUZAMENTOS_ABONOS = 'crabonos';

export const ENTITY_NAME_CRUZAMENTOS_DESCONTOS = 'crdescontos';
