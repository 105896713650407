import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'mapa-pagamentos-process-modal',
  templateUrl: './mapaPagamentos.process.modal.component.html'
})
export class MapaPagamentosProcessModalComponent extends CGModalComponent<void> {
  @Input() public message: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.message = 'A preparar...';
  }
}
