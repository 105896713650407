import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IJsonCondicaoComercial} from '../../jsonCondicaoComercial.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'condicoescomerciais-edit',
  templateUrl: './condicoesComerciais.entity.edit.component.html'
})
export class CondicoescomerciaisEditComponent extends ModuloEntityDetailComponent<IJsonCondicaoComercial> {
  public formInstance: UntypedFormGroup;

  public onOrigemCondPagamentoChange(value: number): void {
    this.model.origemCondPagamento = value;
    if (value) {
      this.model.condPagamento = undefined;
    }
  }

  public onOrigemDataVencimentoChange(value: number): void {
    this.model.origemDataVencimento = value;
    if (value) {
      this.model.numDiasDataVenc = undefined;
      this.model.tipoResumoMensal = undefined;
    }
  }
}
