import {Injectable, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {StateService} from '@uirouter/core';
import {isEmpty, PlAlertService, PlTranslateService, toJson} from 'pl-comps-angular';
import {BancosExtratoModuleActivateLicenseModalComponent} from '../modals/activatelicense/bancosExtrato.activateLicense.modal.component';
import {BancosExtratoService} from './bancosExtrato.module.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IJsonCGBankingLicense} from '../jsonBancosExtrato.module.interface';
import {STATE_NAME_TINK_LINK} from '../../../states/oauth2/entidadebancaria/oauth2.entidadebancaria.state.interface';
import {EEntidadeBancariaProvider, IEntidadeBancariaAddNewAccountState, IJsonEntidadeBancariaAddNewAccount} from '../../../interfaces/jsonEntidadeBancaria.interface';

@Injectable({
  providedIn: 'root'
})
export class BancosExtratoUiService extends BancosExtratoService {
  private _promiseAccount: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
  }

  public consumeLicense(): Promise<void> {
    if (this._modoCGOn) {
      const message: string = this._plTranslateService.translate('bancosextrato.errors.activateCGBankingCGOnMode');
      this._plAlertService.error(message);
      return Promise.reject(new Error(message));
    }
    return this.getCGBankingLicense().then((response: HttpResponse<IJsonCGBankingLicense>) => {
      const dadosLicenca: IJsonCGBankingLicense = response.body;
      const modalInstance = this._cgModalService.showVanilla(BancosExtratoModuleActivateLicenseModalComponent, {size: 'lg'});
      const componentInstance: BancosExtratoModuleActivateLicenseModalComponent = modalInstance.componentInstance;
      componentInstance.dadosLicenca = dadosLicenca;
      return modalInstance.result;
    });
  }

  public checkConsumeLicense(): Promise<void> {
    if (this._temCGBanking) {
      return Promise.resolve();
    }
    return this.consumeLicense();
  }

  public newBankAccount(state: IEntidadeBancariaAddNewAccountState, params?: Omit<IJsonEntidadeBancariaAddNewAccount, 'redirectUrl'>): Promise<void> {
    if (this._promiseAccount) {
      return this._promiseAccount;
    }

    const newAccount: IJsonEntidadeBancariaAddNewAccount = {...params, redirectUrl: state.provider === EEntidadeBancariaProvider.UnifiedPost ? this._generateRedirectUrl(state) : undefined};
    this._promiseAccount = this.getNewBankAccount(newAccount)
      .then((response: HttpResponse<string>) => {
        let newBankAccountUrl: string = response.body;
        if (isEmpty(newBankAccountUrl)) {
          const message: string = this._plTranslateService.translate('bancosextrato.errors.emptyNewBankAccountUrl');
          this._plAlertService.error(message);
          return Promise.reject(new Error(message));
        }
        if (state.provider !== EEntidadeBancariaProvider.UnifiedPost) {
          newBankAccountUrl = this._handleRedirectUrl(newBankAccountUrl, state);
        }
        window.location.href = newBankAccountUrl;
        return Promise.resolve();
      })
      .finally(() => {
        this._promiseAccount = undefined;
      });

    return this._promiseAccount;
  }

  public renewBankAccount(url: string, state: IEntidadeBancariaAddNewAccountState): void {
    if (isEmpty(url)) {
      const message: string = this._plTranslateService.translate('bancosextrato.errors.emptyRenewBankAccountUrl');
      this._plAlertService.error(message);
      throw new Error(message);
    }
    url = this._handleRedirectUrl(url, state);
    window.location.href = url;
  }

  private _generateRedirectUrl(state: IEntidadeBancariaAddNewAccountState): string {
    return `${this._stateService.href(STATE_NAME_TINK_LINK, undefined, {absolute: true})}?state=${encodeURIComponent(toJson(state))}`;
  }

  private _handleRedirectUrl(url: string, state: IEntidadeBancariaAddNewAccountState): string {
    const redirectUrl: URL = new URL(url);
    redirectUrl.searchParams.set('state', encodeURIComponent(toJson(state)));
    redirectUrl.searchParams.set('redirect_uri', this._stateService.href(STATE_NAME_TINK_LINK, undefined, {absolute: true}));
    return redirectUrl.toJSON();
  }
}
