import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPOS_IVA, ETiposIVA} from './tiposIVA.datasource.interface';

export const DATA_SOURCE_TIPOS_IVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_IVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETiposIVA.Isenta, name: 'tiposIVA.data.isenta'},
    {value: ETiposIVA.Reduzida, name: 'tiposIVA.data.reduzida'},
    {value: ETiposIVA.Normal, name: 'tiposIVA.data.normal'},
    {value: ETiposIVA.Intermedia, name: 'tiposIVA.data.intermedia'},
    {value: ETiposIVA.NaoDeduz, name: 'tiposIVA.data.naoDeduz'},
    {value: ETiposIVA.Gasoleo50, name: 'tiposIVA.data.gasoleo50'},
    {value: ETiposIVA.IsentoCEE, name: 'tiposIVA.data.isentoCEE'},
    {value: ETiposIVA.IsentoForaCEE, name: 'tiposIVA.data.isentoForaCEE'}
  ]
});
