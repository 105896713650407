<entity-detail
  [entityName]="entityName"
  [(model)]="model"
  [type]="type"
  [maintenanceMode]="maintenanceMode"
  [callback]="callback"
  [serviceMethodsOverride]="serviceMethodsOverride"
  (evtUpdate)="onUpdate($event)">
  <div *entityDetailContent>
    <pl-form [(formInstance)]="form">
      <pl-group>
        <pl-group>
          <label><span [translate]="'recibos.recibo.cliente'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="clientes"
              attrName="clientes"
              [model]="modelClifo"
              (modelChange)="changedNConta($event)"
              [output]="clienteOutput"
              [properties]="{readonly: !edittable}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label><span [translate]="'recibos.recibo.data'"></span>:</label>
          <edit>
            <pl-edit type="date" [attrName]="'cabdata'" [model]="model.cab.data" (modelChange)="changedData($event)" [properties]="{readonly: !edittable, events: {keydown: keyDownValorRecebido}}">
            </pl-edit>
            <div class="form-control-danger" *ngIf="invalidHeaderDate">
              <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i>
              <span class="btn" [translate]="'recibos.erros.invalidHeaderDate'"></span>
            </div>
          </edit>
        </pl-group>
      </pl-group>

      <div [hidden]="isloading || model.linhas.length || !model.cab.nConta" [plAnimationShake]="model.linhas" [translate]="'recibos.info.semMovimentos'"></div>

      <br />

      <div class="recibos-table" *ngIf="model.linhas.length">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="recibimentosLinhas"
          [dataSource]="model.linhas"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onCellClick)="onCellClick($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onRowExpanding)="onRowExpand($event)"
          (onSaved)="onSaved($event)">
          <div *dxTemplate="let item of 'headerCellTemplateBtns'">
            <pl-tooltip [config]="{text: 'recibos.btn.receberTudo', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-success btn-xs action-receber-tudo-grelha" name="receberTudoGrelha" (click)="receberTudo()">
                <i class="fa fa-check fa-fw"></i>
              </button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'recibos.btn.limpaTudo', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-danger btn-xs action-limpar-todos-valores" name="limparTudoGrelha" (click)="limpaValoresLinhas()">
                <i class="fa fa-eraser fa-fw"></i>
              </button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'recibos.btn.distribuirValores', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-primary btn-xs action-distribuir-valor-automaticamente" name="distribuirAutomaticamente" (click)="distribuiValor()">
                <i class="fa fa-calculator fa-fw"></i>
              </button>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'cellTemplateBtns'">
            <pl-tooltip [config]="{text: 'recibos.btn.receberTudo', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-success btn-xs action-receber-tudo-linha" title="Receber tudo" name="receberTudoLinha" (click)="receberTudoLinha(item.data)">
                <i class="fa fa-check fa-fw"></i>
              </button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'recibos.btn.limpaValor', container: 'body'}">
              <button type="button" *plTooltipContent class="btn btn-danger btn-xs action-limpar-valor" name="limparValorLinha" (click)="limpaLinha(item.data)">
                <i class="fa fa-eraser fa-fw"></i>
              </button>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'cellTemplateValorPor'">
            <span class="cell-template-text-recebimentos">{{ item.data.valor | cgcCurrency: config.contabilidade.decimais.valor : item.data.abreviaturaMoeda : 'code' }}</span>
          </div>

          <div *dxTemplate="let item of 'cellTemplateValorPorReceber'">
            <span class="cell-template-text-recebimentos">{{ item.data.valorPorReceber | cgcCurrency: config.contabilidade.decimais.valor : item.data.abreviaturaMoeda : 'code' }}</span>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplate'">
            <documento-faturacao [doc]="item.data._thedoc" [actionPdf]="fnGetPdf"></documento-faturacao>
          </div>
        </dx-data-grid>

        <div style="display: flex; justify-content: space-between">
          <div class="resumo">
            <h4 class="page-header-cg" style="text-align: left" [translate]="'recibos.info.aReceber'"></h4>
            <dl class="page-invoice-amount">
              <dt style="text-align: left"><span [translate]="'recibos.info.naoVencido'"></span>:</dt>
              <dd>{{ totalNaoVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt style="text-align: left"><span [translate]="'recibos.info.vencido'" style="text-align: left"></span>:</dt>
              <dd>{{ totalVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt style="text-align: left"><span [translate]="'global.text.total'" style="text-align: left"></span>:</dt>
              <dd>{{ totalVencido + totalNaoVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
            </dl>
          </div>

          <div class="float-end resumo text-end">
            <h4 class="page-header-cg" [translate]="'documentoFaturacao.summary'"></h4>
            <dl class="page-invoice-amount">
              <ng-container *ngIf="modelReciboDistribuir.tipoDistribuicao === tipoDistribuicao.Manual">
                <dt><span [translate]="'recibos.text.amountToReceive'"></span>:</dt>
                <dd>{{ valorDistribuir | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
              </ng-container>

              <dt><span [translate]="'recibos.recibo.cab.totalReceber'"></span>:</dt>
              <dd>{{ model.cab.totalReceber | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt><span [translate]="'recibos.recibo.cab.totalDescontos'"></span>:</dt>
              <dd>{{ model.cab.totalDescontos | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <ng-container *ngIf="model.cab.temRetencao">
                <dt><span [translate]="'recibos.recibo.cab.totalRetencao'"></span>:</dt>
                <dd>{{ model.cab.totalRetencao | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
              </ng-container>

              <dt><span [translate]="'global.text.total'"></span>:</dt>
              <dd>{{ model.cab.total | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </pl-form>
  </div>
</entity-detail>
