import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_CONTINENTES, ELocalObtencaoRendimentos} from './continentes.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_CONTINENTES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_CONTINENTES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ELocalObtencaoRendimentos.Continente, name: 'continentes.data.portugal'},
    {value: ELocalObtencaoRendimentos.RegiaoAcores, name: 'continentes.data.acores'},
    {value: ELocalObtencaoRendimentos.RegiaoMadeira, name: 'continentes.data.madeira'},
    {value: ELocalObtencaoRendimentos.Estrangeiro, name: 'continentes.data.estrangeiro'}
  ]
});
