import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ECamposParaSelecao, ECamposParaSelecaoReavaliacao, ICampoExport} from '../../ativosImport.entity.interface';
import {AtivosImportService} from '../../ativosImport.service';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, isEmpty} from 'pl-comps-angular';

@Component({
  selector: 'modal-ativos-import-folhaimport',
  templateUrl: './ativosImport.folhaImport.modal.component.html'
})
export class AtivosImportFolhaImportModalComponent extends CGModalComponent<void> implements OnInit {
  public colunasExport: Array<ICampoExport>;
  public reavaliacoesExport: Array<ICampoExport>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImportService: AtivosImportService
  ) {
    super(_injector);
    this.colunasExport = [];
    this.reavaliacoesExport = [];
  }

  public ngOnInit(): void {
    this._setCamposExport();
  }

  public close(): Promise<void> {
    const listaColunas: string = this.colunasExport
      .filter((coluna: ICampoExport) => coluna._selected)
      .map<string>((coluna: ICampoExport) => String(coluna._value))
      .join(',');
    const listaReavaliacoes: string = this.reavaliacoesExport
      .filter((coluna: ICampoExport) => coluna._selected)
      .map<string>((coluna: ICampoExport) => coluna._valueStr)
      .join(',');

    let lista: string = listaColunas;
    if (!isEmpty(listaReavaliacoes)) {
      lista = `${lista},${listaReavaliacoes}`;
    }

    return this._ativosImportService.getExcelCriarFolhaUrl(lista).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      super.close();
    });
  }

  private _setCamposExport(): void {
    this.colunasExport = [
      {
        _value: ECamposParaSelecao.Designacao,
        _name: 'ativosimport.modal.camposexport.designacao',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.NMapaAmortizacao,
        _name: 'ativosimport.modal.camposexport.mapa',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.CodAmortizacao,
        _name: 'ativosimport.modal.camposexport.CodAmortizacao',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.ViatLigArt11DR,
        _name: 'ativosimport.modal.camposexport.ViatLigArt11DR',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.TaxaAmortizacao,
        _name: 'ativosimport.modal.camposexport.TaxaAmortizacao',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.FiscalTaxaAmortizacao,
        _name: 'ativosimport.modal.camposexport.FiscalTaxaAmortizacao',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.DataAquisicao,
        _name: 'ativosimport.modal.camposexport.DataAquisicao',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.DataInicioUtiliza,
        _name: 'ativosimport.modal.camposexport.DataInicioUtiliza',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NContaFornecedor,
        _name: 'ativosimport.modal.camposexport.NContaFornecedor',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.ValorAquisicao,
        _name: 'ativosimport.modal.camposexport.ValorAquisicao',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.ValorAquisicaoFiscal,
        _name: 'ativosimport.modal.camposexport.ValorAquisicaoFiscal',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NContaDoAtivo,
        _name: 'ativosimport.modal.camposexport.NContaDoAtivo',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.PercReducao,
        _name: 'ativosimport.modal.camposexport.PercReducao',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.FiscalPercReducao,
        _name: 'ativosimport.modal.camposexport.FiscalPercReducao',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.BaseCalculo,
        _name: 'ativosimport.modal.camposexport.BaseCalculo',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.MetodoCalculo,
        _name: 'ativosimport.modal.camposexport.MetodoCalculo',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.DepreciacaoAcumulada,
        _name: 'ativosimport.modal.camposexport.DepreciacaoAcumulada',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.FiscalDepreciacaoAcumulada,
        _name: 'ativosimport.modal.camposexport.FiscalDepreciacaoAcumulada',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.DataUltProcessamento,
        _name: 'ativosimport.modal.camposexport.DataUltProcessamento',
        _selected: true,
        _required: true
      },
      {
        _value: ECamposParaSelecao.Usado,
        _name: 'ativosimport.modal.camposexport.Usado',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.Qtd,
        _name: 'ativosimport.modal.camposexport.Qtd',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.RefExterna,
        _name: 'ativosimport.modal.camposexport.RefExterna',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.Estado,
        _name: 'ativosimport.modal.camposexport.Estado',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.DataAbate,
        _name: 'ativosimport.modal.camposexport.DataAbate',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.ValorVenda,
        _name: 'ativosimport.modal.camposexport.ValorVenda',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.ValorResidual,
        _name: 'ativosimport.modal.camposexport.ValorResidual',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NCCusto,
        _name: 'ativosimport.modal.camposexport.NCCusto',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NZona,
        _name: 'ativosimport.modal.camposexport.NZona',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NDepto,
        _name: 'ativosimport.modal.camposexport.NDepto',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NSubde,
        _name: 'ativosimport.modal.camposexport.NSubde',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.TipoArtigo,
        _name: 'ativosimport.modal.camposexport.TipoArtigo',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NGrFamilia,
        _name: 'ativosimport.modal.camposexport.NGrFamilia',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NFamilia,
        _name: 'ativosimport.modal.camposexport.NFamilia',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NSubfa,
        _name: 'ativosimport.modal.camposexport.NSubfa',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.CodContab,
        _name: 'ativosimport.modal.camposexport.CodContab',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.Classe,
        _name: 'ativosimport.modal.camposexport.Classe',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.Categoria,
        _name: 'ativosimport.modal.camposexport.Categoria',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NVendedor,
        _name: 'ativosimport.modal.camposexport.NVendedor',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NRefProcesso,
        _name: 'ativosimport.modal.camposexport.NRefProcesso',
        _selected: false,
        _required: false
      },
      {
        _value: ECamposParaSelecao.NSeccao,
        _name: 'ativosimport.modal.camposexport.NSeccao',
        _selected: false,
        _required: false
      }
    ];
    this.reavaliacoesExport = [
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL126,
        _name: 'ativosimport.modal.camposexport.DL126',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL24,
        _name: 'ativosimport.modal.camposexport.DL24',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL219,
        _name: 'ativosimport.modal.camposexport.DL219',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL399G,
        _name: 'ativosimport.modal.camposexport.DL399G',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL118B,
        _name: 'ativosimport.modal.camposexport.DL118B',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL111,
        _name: 'ativosimport.modal.camposexport.DL111',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL49,
        _name: 'ativosimport.modal.camposexport.DL49',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL264,
        _name: 'ativosimport.modal.camposexport.DL264',
        _selected: false,
        _required: false
      },
      {
        _valueStr: ECamposParaSelecaoReavaliacao.DL31,
        _name: 'ativosimport.modal.camposexport.DL31',
        _selected: false,
        _required: false
      }
    ];
  }
}
