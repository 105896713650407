import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {IRadioGroup} from '../../../../../../../common/interfaces/interfaces';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {EAvisosCobrancaEmissora, IMetodoSourceRmissora, RADIO_GROUP_EMISSORA} from '../../../avisosCobranca.module.interface';
import {AvisoscobrancaService} from '../../../avisosCobranca.module.service';
import {IJsonConfigAvisosCobranca} from '../../../jsonAvisosCobranca.module.interface';
import {IJsonReport} from '../../../../../../entities/reports/jsonReport.interface';

@Component({
  selector: 'modal-avisos-cobranca-configs',
  templateUrl: './avisosCobranca.configs.modal.component.html'
})
export class AvisosCobrancaConfigsModalComponent extends CGModalComponent<IJsonConfigAvisosCobranca> implements OnInit {
  @Input() public config: IJsonConfigAvisosCobranca;
  @Input() public hasEasyPay: boolean;

  public readonly metodoSourceEmissora: Array<IMetodoSourceRmissora>;
  public readonly radioGroupEmissora: IRadioGroup<EAvisosCobrancaEmissora>;
  public readonly mtdOutput: string;
  public nomeEmissora: string;
  public modelReport: IJsonReport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _avisosCobrancaService: AvisoscobrancaService,
    private readonly _translateSerice: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.mtdOutput = '{{nameMetodo}}';
    this.metodoSourceEmissora = [
      {
        valueMetodo: EAvisosCobrancaEmissora.Desativado,
        nameMetodo: this._translateSerice.instant('avisoscobranca.modal.emissora.desativado')
      },
      {
        valueMetodo: EAvisosCobrancaEmissora.EasyPayOnline,
        nameMetodo: this._translateSerice.instant('avisoscobranca.modal.emissora.easypay')
      }
    ];
    this.nomeEmissora = this._translateSerice.instant('avisoscobranca.modal.emissora.easypay');
    this.radioGroupEmissora = RADIO_GROUP_EMISSORA;
  }

  public ngOnInit(): void {
    this.nomeEmissora =
      this.config.emissoraAtiva === EAvisosCobrancaEmissora.Desativado
        ? this._translateSerice.instant('avisoscobranca.modal.emissora.desativado')
        : this._translateSerice.instant('avisoscobranca.modal.emissora.easypay');
    this.modelReport = {name: this.config.defaultReport, title: this.config.defaultReport};
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._avisosCobrancaService
      .saveConfigAvisosCobranca(this.config)
      .then(() => {
        this._plAlertService.success('avisoscobranca.messages.savedsuccessfully');
        super.close(this.config);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public changedReport(report: IJsonReport): void {
    if (isObject(report)) {
      this.config.defaultReport = report.name;
    }
  }
}
