export interface IJsonRHTipoProcessamento {
  tipoProcessamento: number;
  descricao: string;
  dadosVencimento: boolean;
  dadosManuais: boolean;
  dadosFixos: boolean;
  subsidioRefeicao: boolean;
  diuturnidades: boolean;
  subsidioNatal: boolean;
  subsidioFerias: boolean;
  valorCaducidadeCTR: boolean;
  tipoPeriodicidade: ERHTipoProcessamentoTipoPeriocidade;
  desativado: boolean;
  funcao: string;
  nomeFuncao: string;
}

export enum ERHTipoProcessamentoTipoPeriocidade {
  Sempre,
  Mensal,
  Anual,
  Quinzenal,
  Semanal
}
