import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IJsonTrDocsCabsWizardCabResumoErro} from '../../components/cabsWizard/trdocs.cabsWizard.interface';

@Component({
  selector: 'trdocs-cabs-erros-modal',
  templateUrl: './trdocs.cabs.erros.modal.component.html'
})
export class TrdocsCabsErrosModalComponent extends CGModalComponent<void> {
  @Input() public resumoErrosSource: Array<IJsonTrDocsCabsWizardCabResumoErro>;

  public dataGridResumoErrosDefinition: IDevExpressDataGrid<IJsonTrDocsCabsWizardCabResumoErro, string>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);

    this.dataGridResumoErrosDefinition = {
      columnHidingEnabled: false,
      columns: [{dataField: 'erro', dataType: 'string', caption: 'global.text.error'}],
      dataSource: new CustomStore({
        load: () => {
          return this.resumoErrosSource;
        }
      }),
      filterRow: {visible: false},
      headerFilter: {visible: false}
    };
  }

  public close(): void {
    super.close();
  }
}
