import {NgModule} from '@angular/core';
import {PlCookiesConsentModule} from './cookiesconsent/cookiesconsent.module';

export * from './cookiesconsent/cookiesconsent.module';

@NgModule({
  imports: [
    PlCookiesConsentModule
  ],
  exports: [
    PlCookiesConsentModule
  ]
})
export class PlRGPDModule {
}
