import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';

export enum ETipoArredondamento {
  SemArredondamento,
  ArredondamentoAoEuro
}

export type TServiceHttpQueryResponseMapaExploracao = HttpResponse<IEntityServiceQueryResponseMapaExploracao>;

export interface IJsonMapaExploracao {
  tipo: number;
  conta: string;
  nomeconta: string;
  saldo01: number;
  saldo02: number;
  saldo03: number;
  saldo04: number;
  saldo05: number;
  saldo06: number;
  saldo07: number;
  saldo08: number;
  saldo09: number;
  saldo10: number;
  saldo11: number;
  saldo12: number;
  total: number;
}

export interface IJsonMapaExploracaoTotais {
  saldo01: number;
  saldo02: number;
  saldo03: number;
  saldo04: number;
  saldo05: number;
  saldo06: number;
  saldo07: number;
  saldo08: number;
  saldo09: number;
  saldo10: number;
  saldo11: number;
  saldo12: number;
  total: number;
}

export interface IEntityServiceQueryResponseMapaExploracao extends IApiQueryResponse<IJsonMapaExploracao> {
  saldo: number;
  ano: number;
  mesInicial: number;
  nomeEmpresa: string;
  tipoArredondamento: number;
  totaisExploracao: IJsonMapaExploracaoTotais;
}

export interface IJsonConfigMapaExploracao {
  tipoArredondamento: ETipoArredondamento;
}
