import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IJsonGestaoComunicacoesTopico} from '../../jsonGestaoComunicacoesTopico.module.interface';
import {copy, isEmpty, PlTranslateService} from 'pl-comps-angular';
import {GestaoComunicacoesTopicoModalComponent} from '../../modais/topico/new/gestaoComunicacoes.topico.modal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {GestaoComunicacoesTopicoService} from '../../gestaoComunicacoesTopico.module.service';
import {IJsonGestaoComunicacoesMensagem} from '../../jsonGestaoComunicacoesMensagem.module.interface';
import {generateGestaoComunicacoesDate, generateUtilizadorShow} from '../../gestaoComunicacoes.module.interface';
import moment from 'moment';
import {CGGestaoComunincacaoMessageContainerComponent} from '../../../../../components/gestaocomunicacoes/messagesContainer/messagesContainer.component';
import {CGGestaoComunincacaoMessageInputComponent} from '../../../../../components/gestaocomunicacoes/messageInput/messageInput.component';

const INTERVAL_TIMEOUT = 5000; // 5 seconds

@Component({
  selector: 'module-gestao-comunicacoes-cliente',
  templateUrl: './gestaoComunicacoesCliente.module.component.html'
})
export class GestaoComunicacoesClienteModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public clienteTopicos: Array<IJsonGestaoComunicacoesTopico>;

  @ViewChild('containerMessages') public containerMessages: CGGestaoComunincacaoMessageContainerComponent;
  @ViewChild('inputMessage') public inputMessage: CGGestaoComunincacaoMessageInputComponent;
  public topicoSelected: IJsonGestaoComunicacoesTopico;
  public mensagens: Array<IJsonGestaoComunicacoesMensagem>;
  public clienteTopicosCopy: Array<IJsonGestaoComunicacoesTopico>;
  public filterClienteTopicos: string;
  public message: string;
  public promiseLoading: Promise<void>;

  private _selectedFile: File;
  private _intervalId: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.mensagens = [];
    this.clienteTopicosCopy = [];
    this._selectedFile = undefined;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.clienteTopicosCopy = copy(this.clienteTopicos);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    // if (this.toolbar) {
    //   this._buildToolbarResponsive(value);
    // }
  }

  public async handleClienteTopicoChange(topico: IJsonGestaoComunicacoesTopico): Promise<void> {
    this.promiseLoading = (async () => {
      this.topicoSelected = topico;
      this.topicoSelected._utilizadorShow = generateUtilizadorShow(this.topicoSelected, false);
      this.topicoSelected.hasUnreadMessages = 0;
      await this._getMessagesGroupAndScroll(this.topicoSelected.comTopicoId);

      await this._clearInterval();
      this._intervalId = setInterval(async () => {
        const nNewMessages: number = (await this._gestaoComunicacoesTopicoService.getHasNewMessages(this.topicoSelected.comTopicoId, false)).body.nMessages;
        if (nNewMessages > 0) {
          await this._getMessagesGroupAndScroll(this.topicoSelected.comTopicoId);
        }
      }, INTERVAL_TIMEOUT);
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async filterClienteTopicosChanged(event: string): Promise<void> {
    this.filterClienteTopicos = event;
    if (isEmpty(this.filterClienteTopicos)) {
      this.promiseLoading = (async () => {
        this.clienteTopicos = (await this._gestaoComunicacoesTopicoService.getClienteTopicos()).body;
        this.clienteTopicosCopy = copy(this.clienteTopicos);
      })().finally(() => {
        this.promiseLoading = undefined;
      });
      return this.promiseLoading;
    }

    this.clienteTopicos = this.clienteTopicosCopy.filter(
      (topico: IJsonGestaoComunicacoesTopico) =>
        topico.assunto?.toLowerCase().includes(this.filterClienteTopicos.toLowerCase()) || topico.ultimaMensagem?.toLowerCase().includes(this.filterClienteTopicos.toLowerCase())
    );
    return Promise.resolve();
  }

  public async onClickAddTopico(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesTopicoModalComponent, {size: 'lg'});
    const componentInstance: GestaoComunicacoesTopicoModalComponent = modalInstance.componentInstance;
    componentInstance.estadoId = undefined;
    componentInstance.nEmpresaSelected = this.session.erp.nEmpresa;
    componentInstance.contabilistaView = false;
    componentInstance.datagridView = false;

    await modalInstance.result;
    await this._getAndApplyClienteTopicos();
    await this.handleClienteTopicoChange(this.clienteTopicos[0]);
  }

  public async onClickTopicoClose(): Promise<void> {
    this.topicoSelected = undefined;
    await this._clearInterval();
  }

  public async evtSubmitedMessage(message: string): Promise<void> {
    this.message = message;
    await this._sendMessage();
  }

  public async evtSubmitFile(file: File): Promise<void> {
    await this._sendFile(file);
  }

  private async _getAndApplyClienteTopicos(): Promise<void> {
    this.clienteTopicos = (await this._gestaoComunicacoesTopicoService.getClienteTopicos()).body;
  }

  private async _getMessagesGroupAndScroll(topicoId: string): Promise<void> {
    this.mensagens = (await this._gestaoComunicacoesTopicoService.getTopicoMessages(topicoId, false)).body.reverse();
    this.containerMessages.groupMessages();
    this.inputMessage.focusMessageInput();
  }

  private async _clearInterval(): Promise<void> {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this.message = '';
    }
    return Promise.resolve();
  }

  private _reorderUpdateClienteTopicos(topico: IJsonGestaoComunicacoesTopico, newMessage: string, reorderToIndex: number = 0): void {
    const currentIndex: number = this.clienteTopicos.findIndex((item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topico.comTopicoId);
    if (currentIndex === -1 || reorderToIndex < 0 || reorderToIndex >= this.clienteTopicos.length) {
      return; // Invalid index or topic not found
    }

    // update assunto and showDate
    this.clienteTopicos[currentIndex].ultimaMensagem = newMessage;
    this.clienteTopicos[currentIndex]._dateShow = generateGestaoComunicacoesDate(moment(), true, this._plTranslateService);

    const [removedTopico] = this.clienteTopicos.splice(currentIndex, 1);
    this.clienteTopicos.splice(reorderToIndex, 0, removedTopico);
  }

  private async _sendMessage(): Promise<void> {
    const messageObj: IJsonGestaoComunicacoesMensagem = {
      comTopicoId: this.topicoSelected.comTopicoId,
      message: this.message,
      comentarioId: undefined,
      nUtilizador: undefined,
      stampCreated: moment(),
      isMyMessage: true,
      isMessage: true,
      isRead: true,
      tamanho: undefined,
      fileName: undefined,
      docId: undefined,
      mimeType: undefined
    };
    await this._gestaoComunicacoesTopicoService.createTopicoMessage(this.topicoSelected.comTopicoId, messageObj, false);
    this._reorderUpdateClienteTopicos(this.topicoSelected, messageObj.message);
    this.containerMessages.readAndAddNewMessage(messageObj);
    this.inputMessage.focusMessageInput();
    this.message = '';
  }

  private async _sendFile(file: File): Promise<void> {
    this._selectedFile = file;
    const documentoCreated: IJsonGestaoComunicacoesMensagem = (await this._gestaoComunicacoesTopicoService.createTopicoMessageFile(this.topicoSelected.comTopicoId, this._selectedFile, false)).body;

    const newMessage: IJsonGestaoComunicacoesMensagem = {
      comentarioId: undefined,
      comTopicoId: this.topicoSelected.comTopicoId,
      docId: documentoCreated.docId,
      fileName: this._selectedFile.name,
      isMessage: false,
      isMyMessage: true,
      isRead: true,
      message: undefined,
      nUtilizador: documentoCreated.nUtilizador,
      stampCreated: moment(),
      tamanho: this._selectedFile.size,
      mimeType: documentoCreated.mimeType
    };
    this._reorderUpdateClienteTopicos(this.topicoSelected, newMessage.fileName);
    this.containerMessages.readAndAddNewMessage(newMessage);
    this.inputMessage.focusMessageInput();
    this._selectedFile = undefined;
  }
}
