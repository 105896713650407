import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ISelectKeyPair} from '../../docFas.entity.interface';
import {ESAFTTipoDoc, ETipoAutoFaturacao, IJsonDocfa, IJsonDocfaNewFromBase} from '../../jsonDocFa.entity.interface';
import {ESubTipoMovimentoSAFT} from '../../../docscomerciais/docsComerciais.entity.interface';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';

const AUTOFATURACAO_STR1 = 'AUTOFATURAÇÃO';
const AUTOFATURACAO_STR2 = 'AUTO-FATURAÇÃO';

@Component({
  selector: 'new-doc-fas',
  templateUrl: './docFas.new.modal.component.html'
})
export class DocfasNewModalComponent extends CGModalComponent<IJsonDocfaNewFromBase> implements OnInit {
  @Input() public docFasBase: IJsonDocfa;
  @Input() public tipoAutoFaturacao: ETipoAutoFaturacao;
  public readonly docfasOutput: string;
  public newDocFas: IJsonDocfaNewFromBase;
  public docFas: IJsonDocfa;
  public showSAFTTipoDoc: boolean;
  public entregaEfetivaSobreDocGlobal: boolean;
  public isVisibleEntregaEfetivaSobreDocGlobal: boolean;
  public isAutoFaturacao: boolean;
  public filterNDocfaBase: string;
  public saftTipoDocList: Array<ISelectKeyPair>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.docfasOutput = '{{nDocFa}} - {{nome}} ({{descricao}})';

    this.newDocFas = {
      nDocfasBase: undefined,
      nDocfasNew: undefined,
      nomeNew: '',
      descricaoNew: '',
      saftSubTipoMovimento: ESubTipoMovimentoSAFT.NaoAplicavel,
      autoFaturacao: ETipoAutoFaturacao.None,
      saftTipoDoc: ESAFTTipoDoc.SAFTFactura
    };

    this.docFas = {
      nDocFa: undefined,
      nome: '',
      descricao: '',
      identificaCarga: false,
      faturaComIvaIncluido: false,
      integraStocks: false,
      integraNaContabilidade: false,
      integraEmContasCorrentes: false,
      tipoMovimentoStock: 0,
      reportDefeito: '',
      decimais: undefined,
      grupoDocfa: 0,
      fluxoMonetario: 0,
      nDocfaDestino: 0,
      docfanumList: [],
      faturacaoPublica: false,
      obrigatorioMeiosPagam: false,
      nViasDefeito: 1,
      autoFaturacao: ETipoAutoFaturacao.None,
      ndiarioContab: 0,
      ndescritiContab: 0
    };

    this.showSAFTTipoDoc = false;
    this.entregaEfetivaSobreDocGlobal = false;
    this.isVisibleEntregaEfetivaSobreDocGlobal = false;
    this.isAutoFaturacao = false;
    this.filterNDocfaBase = '';
    this.saftTipoDocList = [
      {value: ESAFTTipoDoc.SAFTFactura, name: 'docfas.modal.new.fatura'},
      {value: ESAFTTipoDoc.SAFTFaturaRecibo, name: 'docfas.modal.new.faturaRecibo'}
    ];
  }

  public ngOnInit(): void {
    if (this.docFasBase) {
      this.docFas = {
        ...this.docFasBase
      };
      this.newDocFas.nDocfasBase = this.docFas.nDocFa;
      this.newDocFas.nomeNew = this.docFas.nome;
      this.newDocFas.descricaoNew = this.docFas.descricao;
      if (this.docFas && (this.docFas.codigoSAFTTipoDoc !== '00' || !this.docFas.codigoSAFTTipoDoc)) {
        this.showSAFTTipoDoc = true;
      }
      this._checkVisibleEntregaEfetivaSobreDocGlobal();
    }
    if (isDefinedNotNull(this.tipoAutoFaturacao)) {
      this.newDocFas.autoFaturacao = this.tipoAutoFaturacao;
      this.isAutoFaturacao = this.newDocFas.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo || this.newDocFas.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresSemAcordo;
      if (this.isAutoFaturacao) {
        this.newDocFas.nomeNew = this._translateService.instant(this.getAutoFacturacaoTitle()).toUpperCase();
        this.newDocFas.descricaoNew = this.newDocFas.nomeNew;
        this.filterNDocfaBase = `grupoDocfa=${EGrupoDoc.ComprasEfectivas}&autoFaturacao=0`;
      }
    }
  }

  public onDocFasBaseChange(docFas: IJsonDocfa): void {
    this.showSAFTTipoDoc = false;
    this.newDocFas.nDocfasBase = docFas.nDocFa;
    if (!this.isAutoFaturacao) {
      this.newDocFas.nomeNew = docFas.nome;
      this.newDocFas.descricaoNew = docFas.descricao;
    }
    this.docFas = docFas;
    if (this.docFas && (this.docFas.codigoSAFTTipoDoc !== '00' || !this.docFas.codigoSAFTTipoDoc)) {
      this.showSAFTTipoDoc = true;
    }
    this._checkVisibleEntregaEfetivaSobreDocGlobal();
  }

  public onEntregaEfetivaSobreDocGlobalChange(checked: boolean): void {
    this.entregaEfetivaSobreDocGlobal = checked;
    this.newDocFas.saftSubTipoMovimento = checked ? ESubTipoMovimentoSAFT.EntregaEfectivaSobreDocumentoGlobal : ESubTipoMovimentoSAFT.NaoAplicavel;
  }

  public close(): void | Promise<void> {
    if (this.isAutoFaturacao && !this.newDocFas.nomeNew.toUpperCase().includes(AUTOFATURACAO_STR1) && !this.newDocFas.nomeNew.toUpperCase().includes(AUTOFATURACAO_STR2)) {
      this._plAlertService.error('docfas.errors.autofaturacaoNome');
      return Promise.resolve();
    }
    return super.close(this.newDocFas);
  }

  public getAutoFacturacaoTitle(): string {
    return this.newDocFas.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo ? 'docfas.autofaturacaoComAcordo' : 'docfas.autofaturacaoSemAcordo';
  }

  private _checkVisibleEntregaEfetivaSobreDocGlobal(): void {
    this.isVisibleEntregaEfetivaSobreDocGlobal = ['FT', 'FR', 'GR'].includes(this.docFas.codigoSAFTTipoDoc.toUpperCase()) && this.docFas.autoFaturacao === ETipoAutoFaturacao.None;
    if (!this.isVisibleEntregaEfetivaSobreDocGlobal) {
      this.entregaEfetivaSobreDocGlobal = false;
    }
  }
}
