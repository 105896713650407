<div class="ivas">
  <entity-detail
    entityName="ivas"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label [translate]="'ivas.fields.codIva'"></label>
          <edit>
            <pl-edit type="integer" [(model)]="model.codIva" attrName="codIva" [properties]="{validators: {min: {value: 0}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label [translate]="'ivas.fields.nome'"></label>
          <edit>
            <pl-edit-text [(model)]="model.nome" attrName="nome"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label [translate]="'ivas.fields.taxaActual'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.taxaActual" attrName="taxaAtual" [properties]="{disabled: type !== stateTypes.NEW}"></pl-edit>
          </edit>
          <actions>
            <button type="button" class="btn btn-primary" [click]="fnEditTaxaIva" [plTranslate]="'ivas.novaTaxaIVA'" name="btnNovaTaxa" plPromise [disabled]="type !== stateTypes.EDIT"></button>
          </actions>
        </pl-group>

        <pl-group>
          <label [translate]="'ivas.fields.dataTaxaActual'"></label>
          <edit>
            <pl-edit type="date" [(model)]="model.dataTaxaActual" attrName="dataTaxaActual" [properties]="{readonly: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label [translate]="'ivas.fields.percDedGasoleo'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.percDedGasoleo" attrName="percDedGasoleo"></pl-edit>
          </edit>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label [translate]="'ivas.fields.percDedProRataPlaceholder'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.percDedProRata" attrName="percDedProRata"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="contabilidade" [properties]="{validators: {maxlength: {value: 3}}}">
          <label>
            <span [translate]="'ivas.fields.codAlternInfac'"></span>
            <pl-tooltip class="tooltip-info-label" [config]="{text: 'ivas.tooltip.codAlternInfacTooltip', placement: ['right']}">
              <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <pl-edit type="string" [(model)]="model.codAlternInfac" attrName="codAlternInfac"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ivas.fields.tipoTaxa'"></label>
          <edit>
            <pl-edit type="tipoTaxaIva" [(model)]="model.tipoTaxa" attrName="tipoTaxa"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ivas.fields.areaRegional'"></label>
          <edit>
            <pl-edit type="areasRegionais" [(model)]="model.areaRegional" attrName="areaRegional"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ivas.fields.tipoDeducao'"></label>
          <edit>
            <pl-edit type="tipoDeducaoTaxa" [(model)]="model.tipoDeducao" attrName="tipoDeducao"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ivas.fields.ivaMotivosTaxaId'"></label>
          <edit>
            <entity-autocomplete
              entity="ivamotivostaxa"
              attrName="ivaMotivosTaxaId"
              [model]="model"
              [(selectedKey)]="model.ivaMotivosTaxaId"
              [outputKey]="'ivaMotivosTaxaId'"
              [fieldsMap]="{
                descricao: 'ivaMotivosTaxaDescricao',
                ivaMotivosTaxaCod: 'ivaMotivosTaxaCod',
                normaAplicavel: 'ivaMotivosTaxaNormaAplicavel'
              }">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'global.text.active'"></label>
          <edit>
            <pl-edit type="boolean" attrName="activo" [(model)]="model.activo"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
