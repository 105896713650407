import {Directive, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IDocsComerciaisEntityService} from '../docsComerciais.entity.interface';
import {IJsonDocComercial} from '../jsonDocComercial.entity.interface';

@Directive()
export abstract class DocsComerciaisModalComponent extends CGModalComponent<IJsonDocComercial> {
  @Input() public doc: IJsonDocComercial;
  @Input() public service: IDocsComerciaisEntityService;
}
