import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ImportadorSaftDirectComponent} from './components/importadorSaftDirect.module.component';
import {ImportadorSaftDirectService} from './importadorSaftDirect.module.service';
import {MODULE_NAME_IMPORTADOR_SAFT_DIRECT} from './importadorSaftDirect.module.interface';
import {RESOLVER_IMPORTACAO_SAFT_MAX_FILE_SIZE} from '../../../../common/resolvers/saft.resolvers';
import {RESOLVER_LICENCA_STORE_MODE, RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../../services/config/config.service.router';
import {RESOLVER_SESSION} from '../../../../config/uirouter/uirouter.resolvers';
import {ROLE} from '../../../services/role.const';

export const MODULE_IMPORTADOR_SAFT_DIRECT: IModuleDefinition = {
  name: MODULE_NAME_IMPORTADOR_SAFT_DIRECT,
  state: {
    url: '/importadorSaftDirect',
    component: ImportadorSaftDirectComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.importadorSaftDirect'
    },
    resolve: [
      RESOLVER_SESSION,
      RESOLVER_LICENCA_STORE_MODE,
      RESOLVER_LICENCA_STORE_MODE_PUBLIC,
      RESOLVER_IMPORTACAO_SAFT_MAX_FILE_SIZE,
      {
        provide: 'currentStatus',
        deps: [ImportadorSaftDirectService],
        useFactory: (importadorSaftDirectService: ImportadorSaftDirectService) => importadorSaftDirectService.getJobStatus()
      }
    ]
  }
};
