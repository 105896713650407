import {Component, Injector, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_MEIOS_PAGAMENTO, ETipo, ETipoPagamento} from '../../../meiospagamento/meiosPagamento.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonMeioPagamento} from '../../../meiospagamento/jsonMeioPagamento.entity.interface';
import {IJsonPagamento, IJsonPagamentoMeioPagamento} from '../../jsonPagamento.entity.interface';
import {IPagamentosSaveModalResult, TPagamentosSaveModalSaveFn} from './pagamentos.save.modal.component.interface';
import {convertLineBreaksToHTML, round} from '../../../../../common/utils/utils';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {EOptionsSEPA} from '../../service/pagamentos.entity.service.interface';
import {PagamentosService} from '../../service/pagamentos.entity.service';
import {IPlTooltipConfig, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {ROLE} from '../../../../services/role.const';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pagamentos-save-modal',
  templateUrl: './pagamentos.save.modal.component.html'
})
export class PagamentosSaveModalComponent extends CGModalComponent<IPagamentosSaveModalResult> implements OnInit {
  @Input() public pagamento: IJsonPagamento;
  @Input() public saveFn: TPagamentosSaveModalSaveFn;
  @Input() public meioPagamentos: Array<IJsonPagamentoMeioPagamento>;
  @Input() public abreviaturaMoeda: string;

  public readonly errorMessages: Array<string>;
  public readonly valorDecimais: number;
  public readonly meioPagamentoOmissao: number;
  public readonly tipoPagamento: typeof ETipo;
  public promise: Promise<void>;
  public loteAuto: boolean;
  public sepaDisabled: boolean;
  public sepaTooltipMessage: string;

  public comercialAdvanced: boolean;
  public licencaStoreModePublic: boolean;
  public portalName: string;
  public cgStoreUrlBackOffice: string;
  public sepaTooltipConfig: IPlTooltipConfig;
  public temCodMoedaDiferentes: boolean;

  private readonly _serviceMeiosPagamento: IEntityService<IJsonMeioPagamento>;
  private _maintenanceMeiosPagamento: IEntityMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _pagamentosService: PagamentosService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _authService: AuthService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.tipoPagamento = ETipo;
    this.errorMessages = [];
    this.valorDecimais = this._configService.configurations.contabilidade.decimais.valor;
    this.meioPagamentoOmissao = this._configService.configurations.contabilidade.pagamentos.meioPagamentoOmissao;
    this._serviceMeiosPagamento = this._entityServiceBuilder.build(ENTITY_NAME_MEIOS_PAGAMENTO);
    this.loteAuto = false;

    this.licencaStoreModePublic = this.configurations.licenca.modoCGOn;
    this.portalName = this._translateService.instant('portals.items.erp')?.toLowerCase();

    this._configSiteService.cgStoreUrlBackOffice().then((url) => {
      this.cgStoreUrlBackOffice = url;
    });
    this._authService.hasAuthority(ROLE.ERPADVANCED).then((response) => {
      this.comercialAdvanced = response;
      if (this.sepaTooltipConfig) {
        this.sepaTooltipConfig.disabled = !this.sepaDisabled && this.comercialAdvanced;
      }
    });

    this.sepaTooltipConfig = {
      placement: 'top',
      disabled: !this.sepaDisabled && this.comercialAdvanced
    };
  }

  public ngOnInit(): void {
    // Valores por defeito em meios de this.pagamento
    this.pagamento.totalCaixa = 0;
    this.pagamento.troco = 0;

    this.temCodMoedaDiferentes = false;
    if (this.pagamento.linhas.length) {
      let codMoeda: number;
      for (const linha of this.pagamento.linhas) {
        if (linha.valorAPagar !== 0) {
          if (codMoeda === undefined) {
            codMoeda = linha.codMoeda;
            this.abreviaturaMoeda = linha.abreviaturaMoeda;
            continue;
          }
          if (linha.codMoeda !== codMoeda) {
            this.temCodMoedaDiferentes = true;
            this.errorMessages.push(this._plTranslateService.translate('pagamentos.erros.moedasdiferentesnosrecibos'));
            break;
          }
        }
      }
    }
    this._getMeiosPagamento();
  }

  public clearErros(): void {
    this.errorMessages.splice(0, this.errorMessages.length);
  }

  public receberTudoLinha(index: number): void {
    const meioPagamento: IJsonPagamentoMeioPagamento = this.pagamento.meiosPagamento[index];
    if (meioPagamento && this.pagamento.totalCaixa < this.pagamento.cab.total) {
      meioPagamento.valor = round(this.pagamento.cab.total - this.pagamento.totalCaixa + meioPagamento.valor, this.valorDecimais);
      this.calcularResumo(meioPagamento.valor, meioPagamento);
    }
  }

  public limpaLinha(index: number): void {
    const meioPagamento: IJsonPagamentoMeioPagamento = this.pagamento.meiosPagamento[index];
    if (meioPagamento) {
      meioPagamento.valor = 0;
      this.calcularResumo(meioPagamento.valor, meioPagamento);
    }
  }

  public manutencaoMeioPagamento(meioPagamento: IJsonPagamentoMeioPagamento): Promise<void> {
    return this._maintenanceMeiosPagamentos()
      .maintenanceEdit(meioPagamento.nCaixa)
      .then(() => {
        this._getMeiosPagamento();
      });
  }

  public calcularResumo(value: number, meioPagamento: IJsonPagamentoMeioPagamento): void {
    this.loteAuto =
      (meioPagamento.tipoMovimento === ETipoPagamento.Indiferente || meioPagamento.tipoMovimento === ETipoPagamento.Pagamento) &&
      meioPagamento.tipo === ETipo.TrfBancaria &&
      round(meioPagamento.valor, this.valorDecimais) === round(this.pagamento.cab.total, this.valorDecimais);

    if (value) {
      meioPagamento.valor = value;
    }
    this.pagamento.totalCaixa = 0;
    this.pagamento.troco = 0;

    for (const meio of this.pagamento.meiosPagamento) {
      if (meio.nCaixa === meioPagamento.nCaixa) {
        meio.valor = value;
      }
      if (meio.valor) {
        this.pagamento.totalCaixa += round(meio.valor, this.valorDecimais);
      }
    }

    // Calcular troco
    if (this.pagamento.totalCaixa > this.pagamento.cab.total) {
      this.pagamento.troco = this.pagamento.totalCaixa - this.pagamento.cab.total;
    }

    this.sepaDisabled = !this._isSepaAvailable();
    this.sepaTooltipConfig.disabled = !this.sepaDisabled && this.comercialAdvanced;
  }

  public readonly fnSave = (value: 'save' | 'pdf' | 'saveCreateSepa' | 'saveGestaoSepa') => (): Promise<void> => this._save(value);

  @ViewChild('templateTooltipGestaoSepa')
  public set templateTooltipGestaoSepa(value: TemplateRef<void>) {
    this.sepaTooltipConfig.templateRef = value;
  }

  private _showError(errorMessage: string): void {
    this.errorMessages.push(convertLineBreaksToHTML(errorMessage));
  }

  private _save(value: 'save' | 'pdf' | 'saveCreateSepa' | 'saveGestaoSepa'): Promise<void> {
    this.clearErros();
    if (!this._isDadosValid()) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this.disableClose();
    const lotAutoInt = Number(this.loteAuto);
    const optionSepa = value === 'saveCreateSepa' ? EOptionsSEPA.GuardarEGerarFicheiroSEPA : EOptionsSEPA.GuardarERegistarNaGestaoDeFicheirosSEPA;
    return Promise.resolve(
      value !== 'saveCreateSepa' && value !== 'saveGestaoSepa' ? this.saveFn() : this._pagamentosService.post(0, lotAutoInt, optionSepa, this.pagamento).then((response) => response.body)
    )
      .then((response: IJsonPagamento) => {
        this.enableClose();
        if (value === 'saveGestaoSepa') {
          this._plAlertService.success('pagamentos.saveModal.success.saveGestaoSepa');
        }
        this.close({type: value, pagamento: response});
      })
      .catch((reason: HttpErrorResponse) => {
        this.enableClose();
        this._logger.error(reason);
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        if (exception.message) {
          this._showError(exception.message);
        }
      });
  }

  private _isDadosValid(): boolean {
    let isValid = true;
    if (this.pagamento.meiosPagamento.length === 0) {
      isValid = false;
      this._showError('pagamentos.saveModal.erros.naoDefiniuMeioPagamento');
    }
    if (this.pagamento.cab.total !== this.pagamento.totalCaixa) {
      isValid = false;
      this._showError('pagamentos.saveModal.erros.naoSatisfazValorDoc');
    }
    return isValid;
  }

  private _isSepaAvailable(): boolean {
    const meiosPagamentoInUse = this.pagamento.meiosPagamento.filter((value) => {
      return value.valor > 0;
    });
    if (meiosPagamentoInUse.length === 0) {
      this.sepaTooltipMessage = this._plTranslateService.translate('pagamentos.saveModal.erros.naoDefiniuMeioPagamento');
      return false;
    } else if (meiosPagamentoInUse.length > 1) {
      this.sepaTooltipMessage = this._plTranslateService.translate('pagamentos.saveModal.erros.trfSepaSoPodeTerUmMeio');
      return false;
    } else if (meiosPagamentoInUse.length) {
      const meioPagamento = meiosPagamentoInUse[0];
      if (meioPagamento.tipo !== ETipo.TrfBancaria) {
        this.sepaTooltipMessage = this._plTranslateService.translate('pagamentos.saveModal.erros.trfSepaMeioPagamentoNaoValido');
        return false;
      }
      if (!meioPagamento.iban || meioPagamento.iban === '') {
        this.sepaTooltipMessage = this._plTranslateService.translate('pagamentos.saveModal.erros.trfSepaMeioPagamentoSemIban');
        return false;
      }
      if (!meioPagamento.swift || meioPagamento.swift === '') {
        this.sepaTooltipMessage = this._plTranslateService.translate('pagamentos.saveModal.erros.trfSepaMeioPagamentoSemSwift');
        return false;
      }
    }
    return true;
  }

  private _setValorOmissao(): void {
    if (this.meioPagamentoOmissao) {
      for (const meioPagamento of this.pagamento.meiosPagamento) {
        if (meioPagamento.nCaixa === this.meioPagamentoOmissao) {
          meioPagamento.valor = this.pagamento.cab.total;
          this.pagamento.totalCaixa = meioPagamento.valor;
          this.loteAuto =
            (meioPagamento.tipoMovimento === ETipoPagamento.Indiferente || meioPagamento.tipoMovimento === ETipoPagamento.Pagamento) &&
            meioPagamento.tipo === ETipo.TrfBancaria &&
            round(meioPagamento.valor, this.valorDecimais) === round(this.pagamento.cab.total, this.valorDecimais);
          break;
        }
      }
    } else if (this.pagamento.meiosPagamento.length) {
      this.pagamento.meiosPagamento[0].valor = this.pagamento.cab.total;
      this.pagamento.totalCaixa = this.pagamento.meiosPagamento[0].valor;
      this.loteAuto =
        (this.pagamento.meiosPagamento[0].tipoMovimento === ETipoPagamento.Indiferente || this.pagamento.meiosPagamento[0].tipoMovimento === ETipoPagamento.Pagamento) &&
        this.pagamento.meiosPagamento[0].tipo === ETipo.TrfBancaria &&
        round(this.pagamento.meiosPagamento[0].valor, this.valorDecimais) === round(this.pagamento.cab.total, this.valorDecimais);
    }
  }

  private _maintenanceMeiosPagamentos(): IEntityMaintenanceInstance {
    if (!this._maintenanceMeiosPagamento) {
      this._maintenanceMeiosPagamento = this._entityMaintenanceService.build(ENTITY_NAME_MEIOS_PAGAMENTO);
    }
    return this._maintenanceMeiosPagamento;
  }

  private _getMeiosPagamento(): void {
    this.promise = this._serviceMeiosPagamento.query({pesquisa: `(tipomovimento=0|tipomovimento=2)&tipo<>${ETipo.Cheque}`}).then((response: THttpQueryResponse<IJsonMeioPagamento>) => {
      // Setting default values to 0
      this.pagamento.meiosPagamento = response.body.list.map<IJsonPagamentoMeioPagamento>((meioPagamento: IJsonMeioPagamento) => {
        return {
          nCaixa: meioPagamento.nCaixa,
          nomeCaixa: meioPagamento.nome,
          valor: 0,
          tipo: meioPagamento.tipo,
          tipoMovimento: meioPagamento.tipoMovimento,
          iban: meioPagamento.nib,
          swift: meioPagamento.swift
        };
      });

      this._setValorOmissao();
      this.sepaDisabled = !this._isSepaAvailable();
      this.sepaTooltipConfig.disabled = !this.sepaDisabled && this.comercialAdvanced;
    });
  }
}
