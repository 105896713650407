import {Component, Injector} from '@angular/core';
import {IPlPaginationEvtPaginationChanged, isString, paginate, PlAlertService, uppercase} from 'pl-comps-angular';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth} from '../../components/configedit/configs.erp.edit.component.interface';
import {IConfigsErpComercialEditGroup} from '../configs.comercial.module.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {normalize} from '../../../../../../common/data';

const DEFAULT_PER_PAGE = 10;
const CONFIG_TYPES = ['documentosComerciais.tipos'];

@Component({
  selector: 'configs-erp-comercial',
  templateUrl: './configs.comercial.module.component.html'
})
export class ConfigsErpComercialComponent extends ConfigsErpBaseModuleComponent {
  public configList: Array<IConfigsErpComercialEditGroup>;
  public activeGroup: IConfigsErpComercialEditGroup;
  public perPage: number;
  public page: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.perPage = DEFAULT_PER_PAGE;
    this.page = 1;
    if (this.configList.length) {
      this.changedGroup(this.configList[0]);
    }
  }

  public changedGroup(groupOrId: string | IConfigsErpComercialEditGroup): void {
    this.activeGroup = isString(groupOrId) ? this.configList.find((groupItem: IConfigsErpComercialEditGroup) => groupItem.id === groupOrId) : groupOrId;
    this.page = 1;
    this._paginateActiveGroup();
  }

  public pageChanged(event: IPlPaginationEvtPaginationChanged): void {
    this.page = event.page || 1;
    this.perPage = event.perPage || DEFAULT_PER_PAGE;
    this._paginateActiveGroup();
  }

  public setDefaultDoc(value: number, group: IConfigsErpComercialEditGroup): void {
    group.documentoPorDefeito = value;
    for (const item of group.items) {
      if (item.name.includes('documentoPorDefeito')) {
        item.value = group.documentoPorDefeito;
        this.saveField(item);
        return;
      }
    }
  }

  public saveField(configItem: IJsonConfigERP): Promise<IJsonConfigERP> {
    return this._configErpService.save(configItem);
  }

  public readonly fnBeforeChange = (value: boolean): boolean => this._beforeChange(value);

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Single;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpComercialEditGroup> {
    const groupsMap: Map<string, IConfigsErpComercialEditGroup> = new Map<string, IConfigsErpComercialEditGroup>();
    for (const item of rawConfigurations) {
      for (const type of CONFIG_TYPES) {
        // Select only the items within each defined type
        if (item.name.startsWith(`${type}.`)) {
          /* Object pattern was selected over array pattern to hold each
           * group information (the reason behind this is, we would have
           * to iterate through the whole array each time to check for already
           * existing groups)
           */
          const groupStartIndex: number = item.name.indexOf('.', type.length) + 1;
          const groupEndIndex: number = item.name.indexOf('.', groupStartIndex);
          const groupProperty: string = item.name.substring(groupStartIndex, groupEndIndex);

          let group: IConfigsErpComercialEditGroup = groupsMap.get(groupProperty);
          if (!group) {
            group = {
              id: undefined,
              title: this._normalizeGrupoDoc(groupProperty),
              items: [],
              pagedItems: [],
              documentoPorDefeito: -1
            };
            group.id = uppercase(group.title);
            groupsMap.set(groupProperty, group);
          }

          const itemStartIndex: number = item.name.indexOf(groupProperty) + groupProperty.length + 1;
          const itemEndIndex: number = item.name.indexOf('.visivel');

          const nDoc: number = parseFloat(item.name.substring(itemStartIndex, itemEndIndex));
          const visible: boolean = item.name.includes('.visivel');
          const index: number = group.items.length;

          if (item.name.includes('documentoPorDefeito')) {
            group.documentoPorDefeito = <number>item.value;
          }

          group.items.push({
            ...item,
            saveField: this.fnSaveField,
            index: index,
            nDoc: nDoc,
            visible: visible
          });

          break;
        }
      }
    }
    const groups: Array<IConfigsErpComercialEditGroup> = Array.from(groupsMap.values());
    for (const group of groups) {
      group.pagedItems = group.items.slice();
    }
    return groups;
  }

  private _paginateActiveGroup(): void {
    if (!this.activeGroup) {
      return;
    }
    this.activeGroup.pagedItems = paginate(this.activeGroup.items, this.page, this.perPage);
  }

  private _beforeChange(newValue: boolean): boolean {
    if (!newValue) {
      this._plAlertService.warning('configsErp.groups.comercial.requiredDefaultDoc');
      return false;
    }
    return true;
  }

  private _normalizeGrupoDoc(groupProperty: string): string {
    switch (groupProperty) {
      case this._translateService.instant('configsErp.items.documentosComerciais.tipos.propostasaClientes.groupProperty'):
        return this._translateService.instant('configsErp.items.documentosComerciais.tipos.propostasaClientes.otherTitle');
      case this._translateService.instant('configsErp.items.documentosComerciais.tipos.consultasaFornecedores.groupProperty'):
        return this._translateService.instant('configsErp.items.documentosComerciais.tipos.consultasaFornecedores.otherTitle');
      case this._translateService.instant('configsErp.items.documentosComerciais.tipos.saidasDiversas.groupProperty'):
        return this._translateService.instant('configsErp.items.documentosComerciais.tipos.saidasDiversasTitle');
      default:
        return normalize(groupProperty);
    }
  }
}
