<pl-form [(formInstance)]="form" [properties]="{labelClass: 'col-sm-5', editClass: 'col-sm-3', disabled: promise !== null}" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'obrigacoesAcessorias.modal.configws.title'"></h4>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-alert [type]="alertType" *ngIf="alertMessage?.length">
      <div [innerHTML]="alertMessage"></div>
    </pl-alert>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.username'"></label>
      <edit>
        <pl-edit type="text" attrName="username" [(model)]="model.username"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.password'"></label>
      <edit>
        <pl-edit type="password" attrName="password" [(model)]="model.password" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.servicoInvCC'"></label>
      <edit>
        <pl-edit type="boolean" attrName="servicoInvocadoPorCC" [model]="model.servicoInvocadoPorCC" (modelChange)="servicoInvocadoPorCCChanged($event)"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.ccPowers'"></label>
      <edit>
        <pl-edit type="boolean" attrName="ccComPoderesDeclarativosContribuinte" [(model)]="model.ccComPoderesDeclarativosContribuinte" [properties]="{disabled: !model.servicoInvocadoPorCC}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.usernameCC'"></label>
      <edit>
        <pl-edit type="text" attrName="usernameCC" [(model)]="model.usernameCC" [properties]="{disabled: !model.servicoInvocadoPorCC, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'obrigacoesAcessorias.modal.configws.passwordCC'"></label>
      <edit>
        <pl-edit type="password" attrName="passwordCC" [(model)]="model.passwordCC" [properties]="{disabled: !model.servicoInvocadoPorCC, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-success action-save"><i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
    <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
