<div class="bancos-extrato-module-unifiedpost-account-modal">
  <pl-form [submit]="close" plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'bancosextrato.unifiedPostModal.title'"></h5>
    </div>

    <div class="modal-body entity-detail-form">
      <pl-group>
        <label [translate]="'bancosextrato.unifiedPostModal.fields.iban'"></label>
        <edit>
          <pl-edit-text attrName="iban" [(model)]="iban" plAutoFocus></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.unifiedPostModal.fields.nometitular'"></label>
        <edit>
          <pl-edit-text attrName="nomeTitular" [(model)]="nomeTitular"></pl-edit-text>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-primary action-submit" [disabled]="closeDisabled"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.add'"></span></pl-button>

      <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
