import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'importador-saft-direct-inprocess-modal',
  templateUrl: './importadorSaftDirect.inProcess.modal.component.html'
})
export class ImportadorSaftDirectInProcessModalComponent extends CGModalComponent<'load' | 'reset'> {
  @Input() public modalMessage: string;
}
