import {merge} from 'lodash-es';
import {from, Observable, of} from 'rxjs';
import {finalize, map, mergeMap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {isBoolean, isObject} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {ContabilidadeDigitalConfigStoreService} from './helperservices/contabilidadedigital.configstore.service';
import {ERegContabDigitalNifEDPViaVerde} from '../../components/arquivodigital/modals/config/arquivoDigital.config.modal.interface';
import {IJsonContabDigitalChaveSincronizacao, IJsonContabDigitalConfigs, IJsonContabDigitalNIFDuplicarQRCode} from './jsonContabDigital.interface';
import {IJsonString} from '../../../common/interfaces/json';
import {TServiceResponse} from '../api/api.service.interface';

let observableFetchEmpresaTemPastasCriadas: Observable<boolean>;

export class ContabilidadeDigitalServiceConfigs {
  private readonly _path: string;
  private _promise: TServiceResponse<IJsonContabDigitalConfigs>;

  constructor(
    protected readonly _apiService: ApiService,
    protected readonly _store: ContabilidadeDigitalConfigStoreService,
    basePath: string
  ) {
    this._path = `${basePath}/configs`;
  }

  public get(): TServiceResponse<IJsonContabDigitalConfigs> {
    return this._apiService.get<IJsonContabDigitalConfigs>({url: this._path});
  }

  public save(configs: Partial<IJsonContabDigitalConfigs>): TServiceResponse<IJsonContabDigitalConfigs> {
    const configurations: IJsonContabDigitalConfigs = merge({}, this._store.getValue(), configs);
    return this._apiService.put<IJsonContabDigitalConfigs>({url: this._path, body: configurations}).then((response: HttpResponse<IJsonContabDigitalConfigs>) => {
      this._store.set(response.body);
      return response;
    });
  }

  public saveLastFolder(folderId: string): TServiceResponse<void> {
    return this._apiService.put<void, void>({url: `${this._path}/lastfolder`, body: undefined, params: {folderId: folderId}}).then((response: HttpResponse<void>) => {
      this._store.saveLastFolder(folderId);
      return response;
    });
  }

  public getConfigs(force: boolean = false): Observable<IJsonContabDigitalConfigs> {
    if (!force && isObject(this._store.getValue())) {
      return this._store.get();
    }
    if (!this._promise) {
      this._promise = this.get().finally(() => {
        this._promise = undefined;
      });
    }
    return from<TServiceResponse<IJsonContabDigitalConfigs>>(this._promise).pipe(
      mergeMap<HttpResponse<IJsonContabDigitalConfigs>, Observable<IJsonContabDigitalConfigs>>((response: HttpResponse<IJsonContabDigitalConfigs>) => {
        for (const syncFolder of response.body.syncFolders) {
          if (!syncFolder.nCodRepcc) {
            syncFolder.nCodRepcc = 0;
            syncFolder.nomeCodRepcc = '';
          }
        }
        this._store.set(response.body);
        return this.getConfigs(false);
      })
    );
  }

  public getClientConnectSynchronizationKey(): TServiceResponse<IJsonContabDigitalChaveSincronizacao> {
    return this._apiService.get<IJsonContabDigitalChaveSincronizacao>({url: `${this._path}/keyclientconnect`});
  }

  public getEmpresaTemPastasCriadas(): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/empresatempastascriadas`}).then((response: HttpResponse<boolean>) => {
      this._store.setEmpresaTemPastasCriadas(response.body);
      return response;
    });
  }

  public empresaTemPastasCriadas(force: boolean = false): Observable<boolean> {
    const fetchEmpresaTemPastasCriadas = (): Observable<boolean> => {
      if (!observableFetchEmpresaTemPastasCriadas) {
        observableFetchEmpresaTemPastasCriadas = from(this.getEmpresaTemPastasCriadas())
          .pipe(map((response: HttpResponse<boolean>) => response.body))
          .pipe(
            finalize(() => {
              observableFetchEmpresaTemPastasCriadas = undefined;
            })
          );
      }
      return observableFetchEmpresaTemPastasCriadas;
    };

    if (force) {
      return fetchEmpresaTemPastasCriadas();
    }

    return this._store.empresaTemPastasCriadas().pipe(
      mergeMap((empresaTemPastasCriadas: boolean) => {
        if (isBoolean(empresaTemPastasCriadas)) {
          return of(empresaTemPastasCriadas);
        }
        return fetchEmpresaTemPastasCriadas();
      })
    );
  }

  public getDescricaoNIFConfigs(nif: string): TServiceResponse<IJsonString> {
    return this._apiService.get<IJsonString>({url: `${this._path}/obterdescricaonif`, params: {nif: nif}});
  }

  public getConfigsNIFEDPViaVerde(edpviaverde: ERegContabDigitalNifEDPViaVerde): TServiceResponse<Array<IJsonContabDigitalNIFDuplicarQRCode>> {
    return this._apiService.get<Array<IJsonContabDigitalNIFDuplicarQRCode>>({url: `${this._path}/nifsedpviaverde`, params: {edpviaverde: edpviaverde}});
  }
}
