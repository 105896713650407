<div class="cg-card-panel card cg-card-pesquisa">
  <div class="card-header card-header-pesquisa">
    <div class="card-header-caption" (click)="toggleCollapse()">
      <a class="btn-link" [translate]="caption"></a>
      <i class="fa fa-fw fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsed"></i>
    </div>

    <div class="card-header-thumb" *ngIf="!hideThumb" [title]="hintThumbCardBody | translate" (click)="toggleThumb()">
      <i class="fa fa-fw fa-thumb-tack" [class.unfixed-thumb-color]="!fixedCardBody"></i>
      <div [class.unfixed-thumb-tack]="!fixedCardBody"></div>
    </div>
  </div>

  <pl-animation-collapse [collapsed]="collapsed">
    <div class="card-body" #elementBody>
      <ng-container *ngTemplateOutlet="templateContent?.templateRef"></ng-container>
    </div>
  </pl-animation-collapse>
</div>
