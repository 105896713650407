import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonInfoDadosRemuneracao} from '../jsonRHGestaoFicheirosDMRAT.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-rhgestaoficheirosdmrat',
  templateUrl: './rhGestaoFicheirosDMRAT.modal.component.html'
})
export class RHGestaoFicheirosDMRATModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public infoDados: IJsonInfoDadosRemuneracao;

  public readonly dataGridDefinitionDadosAutomaticos: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosAutomaticosDetail: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosManuais: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisValorRendimentos: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisRetencaoIRS: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisContribObrigatorias: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisQuotizacaoSindicais: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisRetencaoSobretaxa: IDevExpressDataGrid;
  public readonly dataGridDefinitionDadosGlobaisRendiAnosAnt: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinitionDadosAutomaticos = {
      columnHidingEnabled: false,
      columns: [
        {cellTemplate: 'cellTemplateErrors'},
        {dataField: 'nif', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codemp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'nome', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'abonosRendimentosSujeitos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.abonosRendimentosSujeitos'},
        {dataField: 'abonosRendimentosNaoSujeitos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.abonosRendimentosNaoSujeitos'},
        {dataField: 'abonosOutros', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.abonosOutros'},
        {dataField: 'descontosContribuicoesObrigatorias', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosContribuicoesObrigatorias'},
        {dataField: 'descontosIRS', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosIRS'},
        {dataField: 'descontosSindicatos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosSindicatos'},
        {dataField: 'descontosSobretaxa', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosSobretaxa'},
        {dataField: 'descontosOutros', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosOutros'},
        {dataField: 'rendimentosAnoAnterior', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.rendimentosAnoAnterior'},
        {dataField: 'liquidoCalculado', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.liquidoCalculado'},
        {dataField: 'liquidoVencimentos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.liquidoVencimentos'},
        {dataField: 'erroDescricao', dataType: 'string', caption: 'global.text.error'}
      ],
      dataSource: [],
      export: {filename: 'rhgestaoficheirosdmrat.tab.dadosAutomaticos'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateResumoDadosFuncionario'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosAutomaticosDetail = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'enviaparaDMR', dataType: 'boolean', caption: 'rhgestaoficheirosdmrat.modal.table.enviaparaDMR'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'valorInciden', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.valorInciden'},
        {dataField: 'valorNaoSujeito', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.valorNaoSujeito'},
        {dataField: 'irsRetido', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.irsRetido'},
        {dataField: 'descobrigSSocial', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descobrigSSocial'},
        {dataField: 'quotizSindical', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.quotizSindical'},
        {dataField: 'sobretaxaExtraord', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.sobretaxaExtraord'},
        {dataField: 'outrosDescontos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.outrosDescontos'},
        {dataField: 'outrosAbonos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.outrosAbonos'},
        {dataField: 'rendimentosAnoAnterior', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.rendimentosAnoAnteriorTable'},
        {dataField: 'ncodAbdes', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.ncodAbdes'},
        {dataField: 'descricaoAbono', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descricaoAbono'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.dadosAutomaticos'},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosManuais = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nif', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codemp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'nome', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'abonosRendimentosSujeitos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.abonosRendimentosSujeitos'},
        {dataField: 'abonosRendimentosNaoSujeitos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.abonosRendimentosNaoSujeitos'},
        {dataField: 'descontosContribuicoesObrigatorias', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosContribuicoesObrigatorias'},
        {dataField: 'descontosIRS', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosIRS'},
        {dataField: 'descontosSindicatos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosSindicatos'},
        {dataField: 'descontosSobretaxa', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descontosSobretaxa'},
        {dataField: 'liquidoCalculado', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.liquidoCalculado'},
        {dataField: 'liquidoVencimentos', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.liquidoVencimentos'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.dadosManuais'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisControlo = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nifSujeitoPassivo', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'nome', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {
          caption: 'rhgestaoficheirosdmrat.modal.table.rendianosanteriores',
          columns: [
            {dataField: 'rendAnoAnterior_Ano', dataType: 'number', caption: 'global.text.year'},
            {dataField: 'rendAnoAnterior_Valores', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.valores'}
          ]
        },
        {dataField: 'rendimentodoano', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.rendimentosano'},
        {dataField: 'tipoRendimento', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.tipoRendi'},
        {dataField: 'localObtencaoRend', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.localorigemrendi'},
        {dataField: 'retencaoIRS', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.descontosIRS'},
        {
          caption: 'rhgestaoficheirosdmrat.modal.table.descontosContribuicoesObrigatorias',
          columns: [
            {dataField: 'contribuicosObrigatorias_Valores', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.valores'},
            {dataField: 'contribuicosObrigatorias_NIF1', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nipc1'},
            {dataField: 'contribuicosObrigatorias_NIF2', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nipc2'},
            {dataField: 'contribuicosObrigatorias_NIF3', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nipc3'}
          ]
        },
        {dataField: 'quotizacaoSindical', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.quotizSindical'},
        {dataField: 'retencaoSobretaxa', dataType: 'double', caption: 'rhgestaoficheirosdmrat.modal.table.sobretaxaExtraord'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.controlo'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisValorRendimentos = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'valorInciden', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.valorInciden'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.valorRendimentos'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisRetencaoIRS = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'irsRetido', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.irsRetido'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.retencaoIRS'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisContribObrigatorias = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'descobrigSSocial', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.descobrigSSocial'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.contribObrigatorias'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisQuotizacaoSindicais = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'quotizSindical', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.quotizSindical'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.quotizacaoSindicais'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisRetencaoSobretaxa = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'sobretaxaExtraord', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.sobretaxaExtraord'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.retencaoSobretaxa'},
      remoteOperations: false
    };
    this.dataGridDefinitionDadosGlobaisRendiAnosAnt = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemRendiDescr', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.origemRendiDescr'},
        {dataField: 'ncontribuint', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nif'},
        {dataField: 'codEmp', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.codemp'},
        {dataField: 'titular', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.nome'},
        {dataField: 'dataVencimen', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'tipRendiment', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.tipRendiment'},
        {dataField: 'rendimentosAnoAnterior', dataType: 'number', caption: 'rhgestaoficheirosdmrat.modal.table.rendimentosAnoAnteriorTable'},
        {dataField: 'descrInsManual', dataType: 'string', caption: 'rhgestaoficheirosdmrat.modal.table.descrInsManual'}
      ],
      export: {filename: 'rhgestaoficheirosdmrat.tab.rendiAnosAnt'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinitionDadosAutomaticos.dataSource = this.infoDados.dadosAutomaticos;
    this.dataGridDefinitionDadosManuais.dataSource = this.infoDados.dadosManuais;
    this.dataGridDefinitionDadosGlobaisControlo.dataSource = this.infoDados.dadosGlobaisControlo;
    this.dataGridDefinitionDadosGlobaisValorRendimentos.dataSource = this.infoDados.dadosGlobaisValorRendimentos;
    this.dataGridDefinitionDadosGlobaisRetencaoIRS.dataSource = this.infoDados.dadosGlobaisRetencaoIrs;
    this.dataGridDefinitionDadosGlobaisContribObrigatorias.dataSource = this.infoDados.dadosGlobaisContribObrigatoria;
    this.dataGridDefinitionDadosGlobaisQuotizacaoSindicais.dataSource = this.infoDados.dadosGlobaisQuotizacoesSindicais;
    this.dataGridDefinitionDadosGlobaisRetencaoSobretaxa.dataSource = this.infoDados.dadosGlobaisRetencaoSobretaxa;
    this.dataGridDefinitionDadosGlobaisRendiAnosAnt.dataSource = this.infoDados.dadosGlobaisRendimentosAnoAnt;
  }
}
