import {IEntityService} from '../../services/entity/entity.service.interface';
import {IApiRequestConfigWithBody, TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonDocfaNum} from './jsonDocfaNum.entity.interface';

export type TDocfaNumERequestDataWithBody<T = void> = IApiRequestConfigWithBody<T>;

export interface IDocfaNumEntityService extends IEntityService<IJsonDocfaNum> {
  post<TResponse = IJsonDocfaNum, TRequest = TResponse>(config?: TDocfaNumERequestDataWithBody<TRequest>, paraTodosDoGrupo?: boolean): TServiceResponse<TResponse>;
}

export interface IDOCFASNUMSEntity extends IEntityDefinition<IJsonDocfaNum, IDocfaNumEntityService> {}

export const ERROR_SERIE_NAO_COMUNICADA = 'A série não está comunicada na AT! Tem de estar comunicada e no estado ativo!';

export const ENTITY_NAME_DOCFAS_NUMS = 'docfasnums';
