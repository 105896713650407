<div class="site-standalone-container">
  <h1 [translate]="'noauthority.title'"></h1>

  <div class="alert alert-danger" plAnimationShake><strong [translate]="'noauthority.title'"></strong>&nbsp;<span [translate]="'noauthority.message'"></span></div>

  <div class="site-standalone-container-actions">
    <button type="button" class="btn btn-light" [click]="back" plPromise><i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span></button>

    <ng-container *ngIf="!partnerMode && !hybridMode">
      <button type="button" class="btn btn-light" [click]="mudaEmpresa" plPromise><i class="fa fa-fw fa-institution"></i>&nbsp;<span [translate]="'empresabloqueada.changeCompany'"></span></button>

      <button type="button" class="btn btn-primary" [click]="login" plPromise><i class="fa fa-fw fa-sign-in"></i>&nbsp;<span [translate]="'global.menu.account.login'"></span></button>
    </ng-container>
  </div>
</div>
