<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'loginWsSS.title'"></h4>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <fieldset>
      <legend [translate]="'loginWsSS.dadosEmpresa'"></legend>

      <div>
        <pl-group>
          <label [translate]="'loginWsSS.fields.niss'"></label>
          <edit>
            <pl-edit-text style="width: 100%" [(model)]="model.nissEmpresa" [properties]="{disabled: isDisabledNissEmpresa}" plAutoFocus>
              <ng-container *ngIf="showWarning">
                <div *inputGroup="{klass: 'hollow-form'}">
                  <pl-tooltip [config]="{text: warningTooltipText, placement: 'right', container: 'body'}">
                    <i *plTooltipContent class="fa fa-fw fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                  </pl-tooltip>
                </div>
              </ng-container>
            </pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'loginWsSS.fields.password'"></label>
          <edit>
            <pl-edit-password [(model)]="model.passwordEmpresa"></pl-edit-password>
          </edit>
        </pl-group>
      </div>
    </fieldset>

    <fieldset>
      <legend class="card-title" [translate]="'loginWsSS.dadosMandatario'"></legend>

      <div>
        <pl-group>
          <label [translate]="'loginWsSS.fields.niss'"></label>
          <edit>
            <pl-edit-text [(model)]="model.nissMandatario"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'loginWsSS.fields.password'"></label>
          <edit>
            <pl-edit-password [(model)]="model.passwordMandatario"></pl-edit-password>
          </edit>
        </pl-group>
      </div>
    </fieldset>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn btn-primary btn-sm"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
