<pl-navpill attrName="navPillCategorias" (evtSelected)="categoriaSelected($event)">
  <pl-navpill-panel *ngFor="let categoria of categorias" [caption]="categoria.name" [id]="categoria.value.toString()">
    <div *plNavPillPanelContent class="entity-detail-form" [plPromise]="promise">
      <pl-form>
        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.categoria'"></label>
          <edit>
            <pl-edit [type]="'tipoCategoriaDesc'" [(model)]="model.nCategoriaAbDes"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.tipoArredondamento'"></label>
          <edit>
            <pl-edit [type]="'tipoArrendodamentoABDES'" [(model)]="model.tipoArredondamento"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'perfilCategoriaDesconto.fields.tipoRecolha'"></label>
            <edit>
              <pl-edit [type]="'tipoValorUnitarioRecolher'" [(model)]="model.tipoRecolha"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'perfilCategoriaDesconto.fields.tipoArredondamento'"></label>
            <edit>
              <pl-edit type="text" [(model)]="model.nHorasDiaProvDesc"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.codRem'"></label>
          <edit>
            <entity-autocomplete
              entity="sscol"
              [model]="model"
              [(selectedKey)]="model.codRem"
              (evtSelectedDescriptionChanged)="model.nomeCodRem = $event"
              [fieldsMap]="{codSSCol: 'codRem', designacao: 'nomeCodRem'}"
              output="description">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.codUnidade'"></label>
          <edit>
            <entity-autocomplete
              entity="rhunidades"
              [model]="model"
              [(selectedKey)]="model.unidadeDefeito"
              (evtSelectedDescriptionChanged)="model.nomeUnidadeDefeito = $event"
              [fieldsMap]="{codUnidade: 'unidadeDefeito', nome: 'nomeUnidadeDefeito'}"
              output="description">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.tipoRendimento'"></label>
          <edit>
            <entity-autocomplete
              entity="rendis"
              [model]="model"
              (selectedKeyChange)="model.tipoRendimento = $event"
              (evtSelectedDescriptionChanged)="model.nomeTipoRendimento = $event"
              [fieldsMap]="{tipRendiment: 'tipoRendimento', nome: 'nomeTipoRendimento'}"
              output="description"
              outputKey="tipRendiment">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.contaMovDeb'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaDebitoEmp" [fieldsMap]="{nConta: 'nContaDebitoEmp'}" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.contaMovCred'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaCreditoEmp" [fieldsMap]="{nConta: 'nContaCreditoEmp'}" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.contaEncDEB'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaDebitoPatronato" [fieldsMap]="{nConta: 'nContaDebitoPatronato'}" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaDesconto.fields.contaEncCRE'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaCreditoPatronato" [fieldsMap]="{nConta: 'nContaCreditoPatronato'}" output="key"></entity-autocomplete>
          </edit>
        </pl-group>

        <dx-data-grid class="mt-4" [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="perfisCategoriasDescontos" [dataSource]="model.cruzamentos"></dx-data-grid>
      </pl-form>
    </div>
  </pl-navpill-panel>
</pl-navpill>
