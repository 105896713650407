import {AssistConfigPortaisEmpresaModuleComponent} from './components/assistConfigPortais.empresa.module.component';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ENTITY_NAME_ERPS} from '../../../../../../entities/erps/erps.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {EPortal} from '../../../../../../../common/enums/portals.enums';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonErp} from '../../../../../../interfaces/jsonUserManager.interface';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPRESA} from './assistConfigPortais.empresa.module.interface';
import {ROLE} from '../../../../../../services/role.const';
import {THttpQueryResponse} from '../../../../../../services/api/api.service.interface';
import {TUserSession} from '../../../../../../services/account/jsonUserApi.interface';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPRESA: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPRESA,
  state: {
    url: '/assistenteconfigportais/portalclientaccounts/empresa',
    component: AssistConfigPortaisEmpresaModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'assistenteconfigportais.pages.empresa'
    },
    resolve: [
      {
        provide: 'portalRolePCA',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<ROLE> => authService.getAndGeneratePortalRole(EPortal.PCA)
      },
      {
        provide: 'instalacoes',
        deps: ['session', EntityServiceBuilder],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonErp>> => {
          const serviceErps: IEntityService<IJsonErp> = entityServiceBuilder.build<IJsonErp>(ENTITY_NAME_ERPS);
          return serviceErps.query().then((response: THttpQueryResponse<IJsonErp>) => response.body.list);
        }
      }
    ]
  }
};
