export enum ESitEmprego {
  Todos,
  Ativo,
  Inativo
}

export enum ETipoSubsidio {
  SubFerias,
  SubNatal,
  SubAlimentacaoPreProce
}

export const MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS = 'rhrecolhaseriesubsidiosferias';
export const MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL = 'rhrecolhaseriesubsidiosnatal';
export const MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO = 'rhrecolhaseriesubsidiosalimentacao';

export interface IRecolhaSerieSubsidiosParams {
  moduleName: string;
}
