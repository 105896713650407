import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IContabDigitalSemDocs} from '../../contabDigital.documentosDigitais.module.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDocContabilidade} from '../../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

const HIDING_PRIORITY_1 = 1;
const HIDING_PRIORITY_2 = 2;
const HIDING_PRIORITY_3 = 3;
const HIDING_PRIORITY_4 = 4;
const HIDING_PRIORITY_5 = 5;
const HIDING_PRIORITY_6 = 6;
const HIDING_PRIORITY_7 = 7;
const HIDING_PRIORITY_8 = 8;
const HIDING_PRIORITY_9 = 9;
const HIDING_PRIORITY_10 = 10;
const HIDING_PRIORITY_11 = 11;
const HIDING_PRIORITY_12 = 12;
const HIDING_PRIORITY_13 = 13;
const HIDING_PRIORITY_14 = 14;

@Component({
  selector: 'modal-docsdigitais-docs-contab-sem-doc-digital',
  templateUrl: './docsDigitais.docsContabSemDocDigital.modal.component.html'
})
export class DocsDigitaisDocsContabSemDocDigitalModalComponent extends CGModalComponent<void> implements OnInit {
  public dataGridDefinition: IDevExpressDataGrid;
  public periodoDe: string;
  public periodoAte: string;
  public diarioDe: number;
  public diarioAte: number;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.periodo', hidingPriority: HIDING_PRIORITY_1},
        {dataField: 'nDiario', dataType: 'number', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.nDiario', hidingPriority: HIDING_PRIORITY_2},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.nDocInterno', hidingPriority: HIDING_PRIORITY_3},
        {dataField: 'debito', dataType: 'double', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.debito', hidingPriority: HIDING_PRIORITY_4},
        {dataField: 'credito', dataType: 'double', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.credito', hidingPriority: HIDING_PRIORITY_5},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.nDocExterno', hidingPriority: HIDING_PRIORITY_6},
        {dataField: 'descricao', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.descricao', hidingPriority: HIDING_PRIORITY_7, visible: false},
        {dataField: 'contrib', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.contrib', hidingPriority: HIDING_PRIORITY_8, visible: false},
        {
          dataField: 'contaCorrente',
          dataType: 'string',
          caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.contaCorrente',
          hidingPriority: HIDING_PRIORITY_9,
          visible: false
        },
        {
          dataField: 'nomeContaCorrente',
          dataType: 'string',
          caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.nomeContaCorrente',
          hidingPriority: HIDING_PRIORITY_10,
          visible: false
        },
        {dataField: 'dataDoc', dataType: 'date', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.dataDoc', hidingPriority: HIDING_PRIORITY_11},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.dataDocExterno', hidingPriority: HIDING_PRIORITY_12},
        {dataField: 'descritivo', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.table.descritivo', hidingPriority: HIDING_PRIORITY_13},
        {dataField: 'temDocDigital', dataType: 'boolean', caption: 'docscontabilidade.fields.temDocDigital', hidingPriority: HIDING_PRIORITY_14}
      ],
      dataSource: [],
      keyExpr: 'extPocCabID',
      export: {filename: 'arquivodigital.gestaodocsdigitalizados.docscontabsemdocdigital.title'},
      height: '60vh',
      masterDetail: {enabled: true, template: 'templateMasterDetail'},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.periodoDe = '';
    this.periodoAte = '';
    this.diarioDe = undefined;
    this.diarioAte = undefined;
  }

  public pesquisaDocsContabSemDocDigital(): Promise<void> {
    let contrib: false;
    let contaCorrente: false;
    let nomeContaCorrente: false;
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
      contrib = this._dataGridInstance.columnOption('contrib', 'visible');
      contaCorrente = this._dataGridInstance.columnOption('contaCorrente', 'visible');
      nomeContaCorrente = this._dataGridInstance.columnOption('nomeContaCorrente', 'visible');
    }

    return this._contabilidadeDigitalService.documents
      .contabDigitalSemDocs(this.periodoDe, this.periodoAte, this.diarioDe, this.diarioAte, contrib, contaCorrente, nomeContaCorrente)
      .then((response: HttpResponse<Array<IContabDigitalSemDocs>>) => {
        this.dataGridDefinition.dataSource = response.body;
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IContabDigitalSemDocs>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      return this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        event.data._docContabilidade = response.body;
      });
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
