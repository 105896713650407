import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IIntegracaoSalariosMultiEmpresa, IIntegracaoSalariosMultiProcResult, IIntegracaoSalariosMultiStatus} from './integracaoSalariosMulti.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoSalariosMultiService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhintegracaosalariosmulti`;
  }

  public getJobStatus(): Promise<IIntegracaoSalariosMultiStatus> {
    return new Promise<IIntegracaoSalariosMultiStatus>((resolve, reject) => {
      this._apiService
        .get<IIntegracaoSalariosMultiStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public resetJobUrl(stop: boolean): string {
    return `${this._path}/reset-job?stop=${stop}`;
  }

  public resetJob(stop: boolean = false): TServiceResponse<void> {
    return this._apiService.post({
      url: this.resetJobUrl(stop),
      body: null
    });
  }

  public process(ids: Array<string>, year: number, month: number): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/process/${year}/${month}`,
      body: ids
    });
  }

  public getEmpresas(): Promise<Array<IIntegracaoSalariosMultiEmpresa>> {
    return new Promise<Array<IIntegracaoSalariosMultiEmpresa>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosMultiEmpresa>>({
          url: `${this._path}/get-empresas`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getProcResults(): Promise<Array<IIntegracaoSalariosMultiProcResult>> {
    return new Promise<Array<IIntegracaoSalariosMultiProcResult>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosMultiProcResult>>({
          url: `${this._path}/process-results`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }
}
