<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.historymodal.title'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGrid" [cgDxDataGridColumns]="dataGrid.columns" [dataSource]="dataGrid.dataSource" (onInitialized)="onDataGridInitialized($event)"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
