import {HttpResponse} from '@angular/common/http';
import {DgempsFichaComponent} from './components/dgempsFicha.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {AuthService} from '../../../../services/auth/auth.service';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {IJsonDGEMPFull} from '../../../portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDgempsFichaModel} from './dgempsFicha.module.interface';

export const MODULE_DGEMPS_FICHA: IModuleDefinition = {
  name: 'dgempsficha',
  state: {
    url: '/ficha',
    component: DgempsFichaComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTOREMENTAS, ROLE.GESTORRH, ROLE.GESTORSERVICO],
      icon: 'fa-id-card-o',
      pageTitle: 'global.menu.dgempsficha'
    },
    resolve: [
      {
        provide: 'model',
        deps: ['session', EntityServiceBuilder, AuthService],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<IDgempsFichaModel> => {
          const service: IDGEMPSFullEntityService = entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
          return service.getFull({id: session.erp.codEmp}).then((response: HttpResponse<IJsonDGEMPFull>) => {
            return {
              codEmp: response.body.codEmp,
              nome: response.body.nome,
              apelido: response.body.apelido,
              morada: response.body.morada,
              codPostal: response.body.codPostal,
              nomeLocalida: response.body.nomeLocalida,
              email: response.body.email,
              nTelefone: response.body.nTelefone,
              telemovelPart1: response.body.telemovelPart1,
              codServicoPRH: response.body.codServicoPRH,
              nomeServicoPRH: response.body.nomeServicoPRH,
              categoriaActual: response.body.categoriaActual,
              nomeCategoria: response.body.nomeCategoria,
              rua: response.body.rua,
              ativo: response.body.ativo,
              numContrib: response.body.numContrib,
              iban: response.body.dhemp.iban,
              swift: response.body.dhemp.swift
            };
          });
        }
      }
    ]
  }
};
