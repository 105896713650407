<div class="transferencias-armazens-processar-modal entity-detail-form">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'global.menu.transferenciasarmazens'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <edit>
          <label [translate]="'transferenciasarmazens.modal.fields.data'"></label>
          <pl-edit type="date" [(model)]="data"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
      </pl-button>

      <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
