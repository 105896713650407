import {ICalendariosGetParams} from '../../../entities/calendarios/calendarios.entity.interface';

export interface IColaboradoresGestaoDGEMPSModuleStateParams {
  servico?: number;
  colaboradoresPendentes?: Array<number>;
}

export const MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR = 'gestaoAbonos';
export const MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS = 'marcacaoFaltas';
export const MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR = 'gestaoFaltas';
export const MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS = 'marcacaoFerias';
export const MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR = 'gestaoFerias';

export const COLABORADORES_GESTAO_DGEMPS_GET_CALENDARIO_PARAMS: Readonly<Partial<ICalendariosGetParams>> = Object.freeze({
  holidaysOnly: true
});
