import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {FormGroupDirective} from '@angular/forms';
import {copy, PlAlertService} from 'pl-comps-angular';
import {CONFIG_SITE_MAIL_SMTP_PASSWORD, EConfigSiteGroup, IConfigSiteValue} from '../configsSite.module.interface';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {EMPTY_PASSWORD, FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'configs-site',
  templateUrl: './configsSite.module.component.html'
})
export class ConfigsSiteModuleComponent extends ModuloComponent implements OnInit {
  @Input() public configsEmail: Array<IConfigSiteValue>;

  public form: FormGroupDirective;

  constructor(
    protected readonly _injector: Injector,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.btnSave.visible = true;
    this.btnSave.click = () => this._save();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.configsEmail = this.configsEmail.filter((config: IConfigSiteValue) => config.group === EConfigSiteGroup.Email);
    this.toolbar.addButton({
      id: 'testEmail',
      type: 'button',
      order: this.btnSave.order + 1,
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-success',
      caption: 'configsSite.testMail',
      click: () => this._testEmail()
    });
  }

  private _save(): Promise<void> {
    if (!this.form.valid) {
      this._plAlertService.error('configsSite.error');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    const configsToSave: Array<IConfigSiteValue> = copy(this.configsEmail);

    // send password only if altered
    const passwordIndex: number = configsToSave.findIndex((config: IConfigSiteValue) => config.configId.toLowerCase() === CONFIG_SITE_MAIL_SMTP_PASSWORD && config.value === EMPTY_PASSWORD);
    if (passwordIndex !== -1) {
      configsToSave.splice(passwordIndex, 1);
    }

    return this._configSiteService.saveConfigs(configsToSave).then(() => {
      this._plAlertService.success('entity.state.saved');
    });
  }

  private _testEmail(): Promise<void> {
    return this._configSiteService.testeEmail().then((response: HttpResponse<string>) => {
      this._plAlertService.success(response.body);
    });
  }
}
