import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EMPTY_PASSWORD} from '../../../../../config/constants';
import {IFctCredentials} from '../../fct.module.interface';

@Component({
  selector: 'fct-credentials-modal',
  templateUrl: './fct.credentials.modal.component.html'
})
export class FctCredentialsModalComponent extends CGModalComponent<IFctCredentials> {
  @Input() public nomeEmpresa: string;

  public readonly model: IFctCredentials;
  public promise: Promise<void>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.model = {niss: '', password: EMPTY_PASSWORD};
  }

  public close(): void {
    super.close(this.model);
  }
}
