import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IPlToolbarItem} from 'pl-comps-angular';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {PcaContabilidadeService} from '../../pca.contabilidade.module.service';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {EPcaTipoExportacao, IPcaContabilidadeServiceRefreshedEvent} from '../../pca.contabilidade.module.service.interface';
import {Subscription} from 'rxjs';

@Component({
  selector: 'pca-contabilidade-extratos',
  templateUrl: './extratos.module.component.html'
})
export class PCAContabilidadeExtratosComponent extends ModuloComponent implements OnInit, OnDestroy {
  public pdfUrl: string;
  public conta: IJsonPOC;

  private _ano: number;
  private _periodo: string;
  private _subscription: Subscription;
  private readonly _btnProcessXml: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _pcaContabilidadeService: PcaContabilidadeService
  ) {
    super(_injector);
    this.pdfUrl = '';
    this.conta = null;

    this._btnProcessXml = {
      id: 'btn-excel',
      order: 4,
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'balancetes.btn.processarexcel',
      disabled: false,
      download: {
        url: undefined
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessXml);
    this._subscription = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public onCCSelected(value: IJsonPOC): void {
    this.conta = value;
    this._refresh();
  }

  private _onNotify({ano, periodo}: IPcaContabilidadeServiceRefreshedEvent): void {
    this._ano = ano.ano;
    this._periodo = periodo.periodoSimples;
    this._refresh();
  }

  private _refresh(): void {
    if (!this.conta?.nConta) {
      return;
    }
    this._portalClientAccountService.getExtractosUrl(this.conta.nConta, this._ano, this._periodo, EPcaTipoExportacao.Pdf).subscribe((url: string) => {
      this.pdfUrl = url;
    });
    this._portalClientAccountService.getExtractosUrl(this.conta.nConta, this._ano, this._periodo, EPcaTipoExportacao.Xls).subscribe((url: string) => {
      this._btnProcessXml.download.url = url;
      this._btnProcessXml.disabled = false;
    });
  }
}
