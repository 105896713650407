import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {PlTranslateModule} from '../translate/translate.module';
import {PlTooltipComponent} from './tooltip.component';
import {PlTooltipContentDirective} from './tooltip.content.directive';
import {PlTooltipTemplateDirective} from './tooltip.template.directive';

export * from './tooltip.interface';
export * from './tooltip.component';
export * from './tooltip.content.directive';
export * from './tooltip.template.directive';

const DECLARATIONS = [
  PlTooltipComponent,
  PlTooltipContentDirective,
  PlTooltipTemplateDirective
];

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlTooltipModule {
}
