<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'analisadorTes.strings.valoresAnt'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="definitionsDataGrid" [dataSource]="definitionsDataGrid.dataSource" (onInitialized)="onDataGridInitialized($event)" (onContentReady)="onContentReady()"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <div class="me-auto d-inline-flex align-items-center gap-2">
    <label><span [translate]="'analisadorTes.strings.novaDataTesParaTodos'"></span>:</label>
    <pl-edit type="date" [(model)]="newDataTesAll" [properties]="{minimumDate: newDataTesAllMinDate}"></pl-edit>
    <pl-button klass="btn btn-sm btn-success" (evtClicked)="applyNewDataTesAll()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>
  </div>
  <pl-button klass="btn-sm btn-primary" [onClick]="fnSaveModal"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
