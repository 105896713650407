import {Ng2StateDeclaration} from '@uirouter/angular';
import {STATE_NAME_PARTNERS} from './partners.state.interface';
import {STATE_NAME_SITE} from '../../../common/site';

export const STATE_PARTNER: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_PARTNERS,
  url: '/partners',
  abstract: true
};
