import {Ng2StateDeclaration} from '@uirouter/angular';
import {EmpresaBloqueadaComponent} from './empresabloqueada.state.component';
import {STATE_NAME_EMPRESA_BLOQUEADA} from './empresabloqueada.state.interface';
import {STATE_NAME_SITE} from '../../../../common/site';

export const STATE_EMPRESA_BLOQUEADA: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_EMPRESA_BLOQUEADA,
  url: `/${STATE_NAME_EMPRESA_BLOQUEADA}`,
  component: EmpresaBloqueadaComponent,
  data: {
    disableRecover: true
  }
};
