import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHCalculaRendimentoLiquido, IJsonRHCalculaRendimentoLiquidoEmpProce, IJsonRHCalculaRendimentoLiquidoProcessamento} from './jsonRHCalculoRendimentoLiquido.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RhCalculoRendimentoLiquidoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhcalcularendimentoliquido`;
  }

  public getListaProcessamentos(ano: number): TServiceResponse<Array<IJsonRHCalculaRendimentoLiquidoProcessamento>> {
    return this._apiService.get<Array<IJsonRHCalculaRendimentoLiquidoProcessamento>>({
      url: `${this._path}/listaprocessamentos`,
      params: {ano: ano}
    });
  }

  public postCalculaRendimentoLiquido(rhcalcularendimentoliquido: IJsonRHCalculaRendimentoLiquido): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHCalculaRendimentoLiquido>({
      url: this._path,
      body: rhcalcularendimentoliquido
    });
  }

  public postListaEmpregadosProce(rhcalcularendimentoliquidoprocessamento: IJsonRHCalculaRendimentoLiquidoProcessamento): TServiceResponse<Array<IJsonRHCalculaRendimentoLiquidoEmpProce>> {
    return this._apiService.post<Array<IJsonRHCalculaRendimentoLiquidoEmpProce>, IJsonRHCalculaRendimentoLiquidoProcessamento>({
      url: `${this._path}/listaempregadosproce`,
      body: rhcalcularendimentoliquidoprocessamento
    });
  }
}
