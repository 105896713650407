import {isUndefined} from 'lodash-es';
import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonPRHServicoEmpregado} from '../../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {IJsonPRHServico} from '../../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IServicoDGEMP} from '../../servicos.module.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../../entities/prhservicos/pRHServicos.entity.interface';

@Component({
  selector: 'associa-servicos',
  templateUrl: './associaServicos.module.component.html'
})
export class AssociarServicosComponent extends ModuloComponent implements OnInit {
  public readonly multiselectTemplate: string = '{{codEmp}} - {{nome}}';
  public colaboradoresDisponiveis: Array<IServicoDGEMP>;
  public colaboradoresSelecionados: Array<IJsonPRHServicoEmpregado>;
  public servico: IJsonPRHServico;
  public servicoFilter: IJsonPRHServico;

  private readonly _prhservicosService: IPRHServicosEntityService;
  private readonly _dgempsService: IDGEMPSEntityService;
  private readonly _btnGravar: IPlToolbarItem;
  private _codServico: number;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._prhservicosService = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._dgempsService = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
    this._btnGravar = {
      id: 'newComInt',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      class: 'btn-primary',
      caption: 'Gravar',
      click: () => this._gravarServicoEmp()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._btnGravar);

    this.toolSearch.visible = false;

    this.colaboradoresDisponiveis = [];
    this.colaboradoresSelecionados = [];
    this._initData();

    this._prhservicosService.query().then((response) => {
      this.servicoFilter = response.body.list[0];
      this.selectServicoFilter(this.servicoFilter);
    });
  }

  public selectServicoFilter(item: IJsonPRHServico): void {
    if (!item) {
      return;
    }

    if (item?.codServico <= 0 && item?.nome === '') {
      return;
    }

    this._codServico = item?.codServico;

    this._prhservicosService.getListaEmpregados(this._codServico).then((response) => {
      this.colaboradoresDisponiveis = [];
      for (const empregado of response.body) {
        this.colaboradoresDisponiveis.push({
          codEmp: empregado.codEmp,
          nome: empregado.nome
        });
      }

      for (const colaboradorSelecionado of this.colaboradoresSelecionados) {
        for (let m = this.colaboradoresDisponiveis.length - 1; m > -1; m--) {
          if (colaboradorSelecionado.codEmp === this.colaboradoresDisponiveis[m].codEmp) {
            this.colaboradoresDisponiveis.splice(m, 1);
          }
        }
      }
    });
  }

  private _initData(clearActual: boolean = true, clearDestino: boolean = true, clearSelecionados: boolean = false): void {
    this._codServico = 0;
    if (clearActual) {
      this.servicoFilter = {codServico: 0, nome: ''};
    }
    if (clearDestino) {
      this.servico = {codServico: 0, nome: ''};
    }
    if (clearSelecionados) {
      this.colaboradoresSelecionados = [];
    }
  }

  private _gravarServicoEmp(): Promise<void> {
    if (isUndefined(this.servicoFilter?.codServico) || (this.servicoFilter?.codServico === 0 && this.servicoFilter?.nome === '')) {
      this._plAlertService.error('prhservicoempregados.errors.servicoAtualNaoSelecionado');
      return Promise.reject(new Error('prhservicoempregados.errors.servicoAtualNaoSelecionado'));
    }

    if (isUndefined(this.servico?.codServico) || (this.servico?.codServico === 0 && this.servico?.nome === '')) {
      this._plAlertService.error('prhservicoempregados.errors.servicoDestinoNaoSelecionado');
      return Promise.reject(new Error('prhservicoempregados.errors.servicoDestinoNaoSelecionado'));
    }

    if (this.servicoFilter?.codServico === this.servico?.codServico) {
      this._plAlertService.error('prhservicoempregados.errors.servicosAtualDestinoIguais');
      return Promise.reject(new Error('prhservicoempregados.errors.servicosAtualDestinoIguais'));
    }

    if (this.colaboradoresSelecionados?.length === 0) {
      this._plAlertService.error('prhservicoempregados.errors.naoExistemEmpregadosSelecionados');
      return Promise.reject(new Error('prhservicoempregados.errors.naoExistemEmpregadosSelecionados'));
    }

    if (this.servico && this.servico.codServico > -1 && this.colaboradoresSelecionados.length > 0) {
      return Promise.all(
        this.colaboradoresSelecionados.map<Promise<void>>((item: IJsonPRHServicoEmpregado) => {
          item.codServico = this.servico.codServico;
          return this._putDGEMP(item.codEmp, this.servico.codServico);
        })
      ).then(() => {
        // Clear arrays
        this.colaboradoresDisponiveis.length = 0;
        this.colaboradoresSelecionados.length = 0;
        this._plAlertService.success('prhservicoempregados.saved');
        this._initData(false, true, true);
      });
    }
    return Promise.resolve();
  }

  private _putDGEMP(codEmp: number, codServico: number): Promise<void> {
    return this._dgempsService.put({url: `${codEmp}/associaservico`, params: {codservico: codServico}}).then(() => undefined);
  }
}
