<div class="ativos-import-folhaimport-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.folhaimport'"></h5>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-header">
        <span [translate]="'ativosimport.modal.colunasfich'"></span>
      </div>
      <div class="coluna-import">
        <div class="coluna-import-item" *ngFor="let coluna of colunasExport">
          <pl-edit type="checkbox" [model]="coluna._selected" (modelChange)="coluna._selected = $event" [properties]="{label: coluna._name, disabled: coluna._required}"></pl-edit>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <span [translate]="'ativosimport.modal.reavaliacoes'"></span>
      </div>
      <div class="coluna-import">
        <div class="coluna-import-item" *ngFor="let coluna of reavaliacoesExport">
          <pl-edit type="checkbox" [model]="coluna._selected" (modelChange)="coluna._selected = $event" [properties]="{label: coluna._name, disabled: coluna._required}"></pl-edit>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
