import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_TRDOCS_CABS_WIZARD} from './trDocsCabsWizard.module.interface';
import {TrDocsCabsWizardModuleComponent} from './components/trDocsCabsWizard.module.component';

export const MODULE_TRDOCS_CABS_WIZARD: IModuleDefinition = {
  name: MODULE_NAME_TRDOCS_CABS_WIZARD,
  state: {
    url: `/${MODULE_NAME_TRDOCS_CABS_WIZARD}`,
    component: TrDocsCabsWizardModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pluginsRoles: [ROLE.ERPADVANCED],
      pageTitle: 'global.menu.trdocs'
    }
  }
};
