import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {
  ERHManutencaoCamposEntityMapEnum,
  ERHManutencaoCamposEntityMapModelValueEnum,
  IRHManutencaoCamposField,
  IRHManutencaoCamposProceItem,
  IRHManutencaoCamposSaveData,
  IRHManutencaoCamposSummaryItem,
  IRHManutencaoCamposUIFields
} from '../rhManutencaoCampos.module.interface';
import moment from 'moment';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {RHManutencaoCamposService} from '../rhManutencaoCampos.module.service';
import {IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardStep, isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

const WIZARD_STEP_ONE_ID = 'wizardStepOneId';
const DATAGRID_HISTORY_EMP_BAND_INDEX = 1;
const DATAGRID_PROCE_DATA_BAND_INDEX = 2;

@Component({
  selector: 'rhmanutencaocampos-module-component',
  templateUrl: './rhManutencaoCampos.module.component.html'
})
export class RHManutencaoCamposModuleComponent extends ModuloComponent implements OnInit {
  @Input() public availableFields: Array<IRHManutencaoCamposField>;

  public model: IRHManutencaoCamposSaveData;
  public proceDataGridDefinition: IDevExpressDataGrid;
  public relatorioDataGridDefinition: IDevExpressDataGrid;
  public proceDataSource: Array<IRHManutencaoCamposProceItem>;
  public relatorioDataSource: Array<IRHManutencaoCamposSummaryItem>;
  public modelFields: IRHManutencaoCamposUIFields;
  public plWizardCallback: IPlNavWizardCallback;
  public readonly wizStepOneId: string;
  public readonly definitionNavWizard: IPlNavWizardDefinition;

  private _proceDataGridInstance: dxDataGrid;
  private readonly _dataGridColumns: Array<IDevExpressDataGridColumn>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _rhManutencaoCamposService: RHManutencaoCamposService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.wizStepOneId = WIZARD_STEP_ONE_ID;
    this.model = {
      dataDe: moment().subtract(1, 'month').startOf('month'),
      dataAte: moment().subtract(1, 'month').endOf('month'),
      newFields: [],
      selProceKeys: []
    };
    this.proceDataSource = [];
    this._dataGridColumns = [
      {
        caption: 'rhmanutencaocampos.processamento',
        fixed: true,
        fixedPosition: 'left',
        columns: [
          {dataField: 'codEmp', caption: 'rhmanutencaocampos.fields.codEmp'},
          {dataField: 'nome', caption: 'rhmanutencaocampos.fields.nome'},
          {dataField: 'nProcessamento', caption: 'rhmanutencaocampos.fields.nProcessamento'},
          {dataField: 'dataProcessamento', caption: 'rhmanutencaocampos.fields.dataProcessamento', dataType: 'date'}
        ]
      },
      {
        caption: 'rhmanutencaocampos.infoHistoricoEmpregado',
        columns: [
          {dataField: 'funcaoHstEmprString', caption: 'rhmanutencaocampos.fields.funcao', visible: false},
          {dataField: 'nZonaHstEmprString', caption: 'rhmanutencaocampos.fields.nZona', visible: false},
          {dataField: 'seccaoHstEmprString', caption: 'rhmanutencaocampos.fields.seccao', visible: false},
          {dataField: 'ccustoHstEmprString', caption: 'rhmanutencaocampos.fields.ccusto', visible: false},
          {dataField: 'nPOCRemuLiqHstEmprString', caption: 'rhmanutencaocampos.fields.nPOCRemuLiq', visible: false},
          {dataField: 'departamentoHstEmprString', caption: 'rhmanutencaocampos.fields.departamento', visible: false},
          {dataField: 'classeTrabHstEmprString', caption: 'rhmanutencaocampos.fields.classeTrab', visible: false},
          {dataField: 'nSubDeHstEmprString', caption: 'rhmanutencaocampos.fields.nSubDe', visible: false}
        ]
      },
      {
        caption: 'rhmanutencaocampos.dadosProcessamento',
        columns: [
          {dataField: 'funcaoString', caption: 'rhmanutencaocampos.fields.funcao', visible: false},
          {dataField: 'nZonaString', caption: 'rhmanutencaocampos.fields.nZona', visible: false},
          {dataField: 'seccaoString', caption: 'rhmanutencaocampos.fields.seccao', visible: false},
          {dataField: 'ccustoString', caption: 'rhmanutencaocampos.fields.ccusto', visible: false},
          {dataField: 'nPOCRemuLiqString', caption: 'rhmanutencaocampos.fields.nPOCRemuLiq', visible: false},
          {dataField: 'departamentoString', caption: 'rhmanutencaocampos.fields.departamento', visible: false},
          {dataField: 'classeTrabString', caption: 'rhmanutencaocampos.fields.classeTrab', visible: false},
          {dataField: 'nSubDeString', caption: 'rhmanutencaocampos.fields.nSubDe', visible: false}
        ]
      }
    ];
    this.proceDataGridDefinition = {
      keyExpr: 'key',
      columns: this._dataGridColumns,
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      }
    };
    this.modelFields = {};
    this.relatorioDataGridDefinition = {
      columns: [
        {
          width: 80,
          dataField: 'ico',
          caption: '',
          alignment: 'center',
          cellTemplate: 'relatorioIconTmpl'
        },
        {dataField: 'descricaoErro', caption: 'rhmanutencaocampos.fields.descricaoErro'},
        {dataField: 'codEmp', caption: 'rhmanutencaocampos.fields.codEmp'},
        {dataField: 'nomeEmp', caption: 'rhmanutencaocampos.fields.nome'},
        {dataField: 'nProcessamento', caption: 'rhmanutencaocampos.fields.nProcessamento'}
      ]
    };
    this.relatorioDataSource = [];
    this.definitionNavWizard = {
      items: []
    };
    this.plWizardCallback = {};
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._buildModelFields();
  }

  public onProceInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._proceDataGridInstance = component;
  }

  public onModelChangeCheckbox(value: boolean, key: string): void {
    this.modelFields[key].checked = value;
    let gridCol = this._getProceDataGridColByFieldName(this.modelFields[key].fieldName);
    if (isDefinedNotNull(gridCol)) {
      this._proceDataGridInstance.columnOption(gridCol.dataField, 'visible', value);
    }
    gridCol = this._getHstEmprDataGridColByFieldName(this.modelFields[key].fieldName);
    if (isDefinedNotNull(gridCol)) {
      this._proceDataGridInstance.columnOption(gridCol.dataField, 'visible', value);
    }
    if (!value) {
      this.modelFields[key].entityModel = undefined;
    }
  }

  public readonly fnSearchProcessamentos = (): Promise<void> => this._searchProcessamentos();
  public readonly fnValidateNewFields: () => Promise<boolean> = () => this._validateNewFields();
  public readonly fnAtualizarProcessamentos = (): void => {
    this._atualizarProcessamentos();
  };
  public readonly fnFinalize = (): void => {
    this._finalize();
  };

  private _getProceDataGridColByFieldName(name: string): IDevExpressDataGridColumn {
    return this._dataGridColumns[DATAGRID_PROCE_DATA_BAND_INDEX].columns.find((col) => {
      const formattedName = `${name.toLowerCase()}string`;
      return col.dataField.toLowerCase() === formattedName;
    });
  }

  private _getHstEmprDataGridColByFieldName(name: string): IDevExpressDataGridColumn {
    return this._dataGridColumns[DATAGRID_HISTORY_EMP_BAND_INDEX].columns.find((col) => {
      const formattedName = `${name.toLowerCase()}hstemprstring`;
      return col.dataField.toLowerCase() === formattedName;
    });
  }

  private _atualizarProcessamentos(): void {
    const selProce: Array<string> = this._proceDataGridInstance.getSelectedRowKeys();
    selProce.forEach((key) => {
      const sourceItem = this.proceDataSource.find((item) => item.key === key);
      for (const property of Object.keys(this.modelFields)) {
        if (this.modelFields[property].checked && isDefinedNotNull(this.modelFields[property].entityModel)) {
          const modelKey: string = ERHManutencaoCamposEntityMapModelValueEnum[this.modelFields[property].fieldName];
          if (isDefinedNotNull(this.modelFields[property].entityModel[modelKey])) {
            const gridCol = this._getProceDataGridColByFieldName(this.modelFields[property].fieldName);
            sourceItem[gridCol.dataField] = this.modelFields[property].entityModel[modelKey];
          }
        }
      }
    });
  }

  private _searchProcessamentos(): Promise<void> {
    if (moment(this.model.dataDe).isAfter(this.model.dataAte)) {
      this._plAlertService.error('rhmanutencaocampos.dataProcFinalNaoPodeInferiorInicial');
      return Promise.resolve();
    }
    return this._rhManutencaoCamposService.getProcessamentos(this.model.dataDe, this.model.dataAte).then((response) => {
      this.proceDataSource = response.body;
    });
  }

  private _validateNewFields(): Promise<boolean> {
    this.model.newFields = [];
    for (const property of Object.keys(this.modelFields)) {
      if (this.modelFields[property].checked) {
        if (isUndefinedOrNull(this.modelFields[property].entityModel)) {
          this._plAlertService.error(this._translateService.instant('rhmanutencaocampos.valorCampoNaoPodeSerNulo', {campo: this.modelFields[property].entityLabel}));
          return Promise.resolve(false);
        }
        const modelKey: string = ERHManutencaoCamposEntityMapModelValueEnum[this.modelFields[property].fieldName];

        if (isUndefinedOrNull(this.modelFields[property].entityModel[modelKey])) {
          this._plAlertService.error(this._translateService.instant('rhmanutencaocampos.naoFoiEncontradoValorNoModel', {campo: this.modelFields[property].fieldName}));
          return Promise.resolve(false);
        }

        this.model.newFields.push({
          nome: this.modelFields[property].fieldName,
          descricao: this.modelFields[property].entityLabel,
          novoValor: this.modelFields[property].entityModel[modelKey]
        });
      }
    }

    if (!this.model.newFields.length) {
      this._plAlertService.error('rhmanutencaocampos.nenhumCampoSelecionadoParaAtualizar');
      return Promise.resolve(false);
    }

    this.model.selProceKeys = this._proceDataGridInstance.getSelectedRowKeys();

    if (!this.model.selProceKeys.length) {
      this._plAlertService.error('rhmanutencaocampos.nenhumProceSelecionadoParaAtualizar');
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'rhmanutencaocampos.promptMessage', {size: 'md'})
        .then(() => {
          this._rhManutencaoCamposService
            .saveCampos(this.model)
            .then((response) => {
              this.relatorioDataSource = response.body;
              resolve(true);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private _finalize(): void {
    this._buildModelFields();
    this.definitionNavWizard.items.forEach((step: IPlNavWizardStep) => {
      step.visited = false;
      step.setIncomplete();
    });
    this.plWizardCallback.setStep(WIZARD_STEP_ONE_ID);
    this.proceDataSource = [];
    this.relatorioDataSource = [];
  }

  private _buildModelFields(): void {
    this.modelFields = {};
    this.availableFields.forEach((field) => {
      this.modelFields[field.nome.toLowerCase()] = {
        fieldName: field.nome,
        checked: false,
        value: undefined,
        entityModel: undefined,
        entityName: ERHManutencaoCamposEntityMapEnum[field.nome],
        entityLabel: field.descricao
      };
    });
  }
}
