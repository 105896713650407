import {TDate} from '../../../common/dates';
import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';

export enum ETipoCertificadoFaturaEletronica {
  CertificadoPFX,
  SAFETestes,
  SAFE,
  DigitalSignTestes,
  DigitalSign
}

export interface IJsonFaturacaoEletronicaConfig {
  faturacaoEletronicaAtiva: boolean;
  acessoTiposDocumento: boolean;
  acessoClientes: boolean;
  tipoCertificado: ETipoCertificadoFaturaEletronica;
  modoFuncionamentoDocfas: number;
  tiposDocumento: Array<IJsonFatEletronicaConfDocfa>;
  modoFuncionamentoClientes: number;
  clientes: Array<IJsonFatEletronicaConfCliente>;
  faturacaoEletronicaEstadoStr?: string;
}

export interface IJsonFatEletronicaConfDocfa {
  nDocFa: number;
  sujeitoFaturaDigital: boolean;
  nome: string;
  estado: string;
  reportDefault: string;
}

export interface IJsonFatEletronicaConfCliente {
  nConta: string;
  nome: string;
  estado: string;
  emails: string;
  emailGeral: string;
  faturacaoElectronica: boolean;
}

export interface IJsonFatEletronicaConfSafe {
  certificados: Array<IJsonFatEletronicaConfSafeCertificado>;
  pendentes: Array<IJsonFatEletronicaConfSafeCertificado>;
}

export interface IJsonFatEletronicaConfSafeCertificado {
  nomeDaConta: string;
  nUtilizador: number;
  nomeUtilizador: string;
  accountExpiration: TDate;
  state: string;
  authorizationCode?: string;
}

export interface IJsonFatEletronicaConfDigitalSign {
  nomeAutorizador: string;
  idAutorizador: string;
  nUtilizador: number;
  nomeUtilizador: string;
  accountExpiration: TDate;
  usoGlobal: boolean;
  error: boolean;
  errorDescription: string;
}

export interface IJsonFatEletronicaConfSafeAddCertificate {
  nomeDaConta: string;
  accessToken: string;
  safetyKey: string;
  expiresIn: number;
}

export interface IJsonFatEletronicaConfSafeObterAtributos {
  nomeDaConta: string;
  validaAte: TDate;
}

export interface IJsonFatEletronicaConfDigitalSignAddAuthorizer {
  nomeAutorizador: string;
  code: string;
  safetyKey: string;
}

export interface IJsonFatEletronicaConfDigitalSignUpdateAuthorizer {
  nomeAutorizador: string;
  idAutorizador: string;
  secret: string;
  usoGlobal: boolean;
}

export interface IJsonFatEletronicaUrlResponse {
  url: string;
}

export enum EModoFuncionamentoDocfas {
  TodosOsDocfasSaftFtFrNdNc = 0,
  SoDocfasSelecionados = 1
}

export const RADIO_GROUP_MODO_FUNCIONAMENTO_DOCFAS: IRadioGroup<EModoFuncionamentoDocfas> = deepFreeze<IRadioGroup<EModoFuncionamentoDocfas>>({
  groupItems: [
    {value: EModoFuncionamentoDocfas.TodosOsDocfasSaftFtFrNdNc, label: 'faturacaoeletronicaconfiguracoes.radioOptions.docfas.todos'},
    {value: EModoFuncionamentoDocfas.SoDocfasSelecionados, label: 'faturacaoeletronicaconfiguracoes.radioOptions.docfas.selecionados'}
  ]
});

export enum EModoFuncionamentoClientes {
  TodosOsClientes = 0,
  SoClientesSelecionados = 1
}

export enum ECheckSAFE {
  True = 0,
  False = 1
}

export const RADIO_GROUP_MODO_FUNCIONAMENTO_CLIENTES: IRadioGroup<EModoFuncionamentoClientes> = deepFreeze<IRadioGroup<EModoFuncionamentoClientes>>({
  groupItems: [
    {value: EModoFuncionamentoClientes.TodosOsClientes, label: 'faturacaoeletronicaconfiguracoes.radioOptions.clientes.todos'},
    {value: EModoFuncionamentoClientes.SoClientesSelecionados, label: 'faturacaoeletronicaconfiguracoes.radioOptions.clientes.selecionados'}
  ]
});

export const RADIO_GROUP_CHECK_SAFE: IRadioGroup<boolean> = deepFreeze<IRadioGroup<boolean>>({
  groupItems: [
    {value: true, label: 'Sim'},
    {value: false, label: 'Não'}
  ]
});
