import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAtivosDepreciacaoDataPosterior} from '../../jsonAtivosDepreciacao.module.interface';

@Component({
  selector: 'modal-ativos-depreciacao-dataspost',
  templateUrl: './ativosDepreciacao.datasPoste.modal.component.html'
})
export class AtivosDepreciacaoDatasPosteModalComponent extends CGModalComponent<void> {
  @Input() public ativosDataUtilEmAnosPost: Array<IJsonAtivosDepreciacaoDataPosterior>;

  public readonly definitionAtivosDataUtilEmAnosPost: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definitionAtivosDataUtilEmAnosPost = {
      columns: [
        {dataField: 'codAtivo', dataType: 'string', caption: 'ativosdepreciacao.modal.table.codAtivo', allowGrouping: false},
        {dataField: 'designacao', dataType: 'string', caption: 'ativosdepreciacao.modal.table.designacao'},
        {dataField: 'contabBaseCalculoStr', dataType: 'string', caption: 'ativosdepreciacao.modal.table.contabBaseCalculoStr'},
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'ativosdepreciacao.modal.table.dataAquisicao'},
        {dataField: 'contabDataDispUso', dataType: 'date', caption: 'ativosdepreciacao.modal.table.contabDataDispUso'}
      ],
      keyExpr: 'codAtivo',
      searchPanel: {visible: true}
    };
  }
}
