import {Observable, of} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';
import {ILogger} from 'pl-comps-angular';
import appLocaleEN from '../../../i18n/locale-en.json';
import appLocaleES from '../../../i18n/locale-es.json';
import appLocaleFR from '../../../i18n/locale-fr.json';
import appLocalePT from '../../../i18n/locale-pt-pt.json';

export class CGTranslationLoader extends TranslateLoader {
  constructor(private readonly _logger: ILogger) {
    super();
  }

  public getTranslation(locale: string): Observable<object> {
    switch (locale) {
      case 'en':
        return of(appLocaleEN);
      case 'es':
        return of(appLocaleES);
      case 'fr':
        return of(appLocaleFR);
      case 'pt-PT':
        return of(appLocalePT);
      default:
        this._logger.warn(`Tried to set unsupported locale: ${locale}`);
        return of(appLocalePT);
    }
  }
}
