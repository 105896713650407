<div class="rhagencias-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhsaltotrf.config.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="d-flex mb-4">
      <div class="me-2">
        <pl-edit type="checkbox" attrName="normal" [model]="onlyAtivos" (modelChange)="onlyAtivos = $event; refreshTableBancos()" [properties]="{label: 'rhagencias.onlyativos'}"></pl-edit>
      </div>
      <pl-edit type="checkbox" attrName="abatido" [model]="onlyIBAN" (modelChange)="onlyIBAN = $event; refreshTableBancos()" [properties]="{label: 'rhagencias.onlyiban'}"></pl-edit>
    </div>

    <entity-list
      entityName="bancos"
      [maintenanceMode]="maintenanceMode"
      fields="codBanco,nome"
      (evtDataGridOnInitialized)="evtDataGridOnInitialized($event)"
      [beforeRequest]="fnBeforeRequest"
      [afterRequest]="fnAfterRequest"
      (evtDataGridCellClick)="onDataGridCellClick($event)">
      <div *entityListHeaderActions>
        <button type="button" class="btn btn-primary btn-xs" [title]="'rhagencias.btn.addBanco' | translate" (click)="openNewBanco()">
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </div>
      <div *entityListItemActions="let itemBanco">
        <div class="button-group">
          <button type="button" class="btn btn-warning btn-xs" [title]="'rhagencias.btn.editBanco' | translate" (click)="openEditBanco(itemBanco.data)">
            <i class="fa fa-fw fa-pencil-square-o"></i>
          </button>
          <button type="button" class="btn btn-danger btn-xs" [title]="'rhagencias.btn.deleteBanco' | translate" (click)="deleteBanco(itemBanco.data)">
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </div>
      </div>
      <div *entityListItemDetail="let itemBanco">
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionAgencias" [dataSource]="itemBanco.data.agenciasList" (onCellClick)="onCellClickAgencias($event)">
          <div *dxTemplate="let itemAgencia of 'headerCellTemplateBtns'">
            <button type="button" class="btn btn-primary btn-xs" [title]="'rhagencias.btn.addAgencia' | translate" (click)="openNewAgencia(itemBanco.data)">
              <i class="fa fa-fw fa-plus"></i>
            </button>
          </div>
          <div *dxTemplate="let itemAgencia of 'cellTemplateBtns'">
            <div class="button-group">
              <button type="button" class="btn btn-warning btn-xs" [title]="'rhagencias.btn.editAgencia' | translate" (click)="openEditAgencia(itemBanco.data, itemAgencia.data)">
                <i class="fa fa-fw fa-pencil-square-o"></i>
              </button>
              <button type="button" class="btn btn-danger btn-xs" [title]="'rhagencias.btn.deleteConta' | translate" (click)="deleteAgencia(itemBanco.data, itemAgencia.data)">
                <i class="fa fa-fw fa-trash"></i>
              </button>
            </div>
          </div>

          <div *dxTemplate="let itemAgencia of 'masterDetailTemplateAgencias'">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionContas" [dataSource]="itemAgencia.data.contasList">
              <div *dxTemplate="let itemContas of 'headerCellTemplateBtns'">
                <button type="button" class="btn btn-primary btn-xs" [title]="'rhagencias.btn.addConta' | translate" (click)="openNewConta(itemAgencia.data)">
                  <i class="fa fa-fw fa-plus"></i>
                </button>
              </div>

              <div *dxTemplate="let itemContas of 'cellTemplateBtns'">
                <div class="button-group">
                  <button type="button" class="btn btn-warning btn-xs" [title]="'rhagencias.btn.editAgencia' | translate" (click)="openEditConta(itemAgencia.data, itemContas.data)">
                    <i class="fa fa-fw fa-pencil-square-o"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-xs" [title]="'rhagencias.btn.deleteConta' | translate" (click)="deleteConta(itemAgencia.data, itemContas.data)">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </div>
            </dx-data-grid>
          </div>
        </dx-data-grid>
      </div>
    </entity-list>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
