<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'alterarCamposSerie.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.nArmazem'"></label>
      <edit>
        <entity-autocomplete
          entity="armazens"
          attrName="armazem"
          [model]="linhaValoresSerie.nArmazem"
          [output]="'nArmazem'"
          [outputKey]="'nArmazem'"
          [(selectedKey)]="linhaValoresSerie.nArmazem"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.nArmazemSelected"></pl-edit-checkbox>
      </actions>
    </pl-group>
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.nCCusto'"></label>
      <edit>
        <entity-autocomplete entity="ccustos" [model]="linhaValoresSerie.nCCusto" [(selectedKey)]="linhaValoresSerie.nCCusto" [outputKey]="'nCCusto'" [output]="'nCCusto'" [filter]="'encerrado=0'">
        </entity-autocomplete>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.nCCustoSelected"></pl-edit-checkbox>
      </actions>
    </pl-group>
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.nRefProcesso'"></label>
      <edit>
        <entity-autocomplete
          entity="processos"
          [model]="linhaValoresSerie.nrefprocesso"
          [(selectedKey)]="linhaValoresSerie.nrefprocesso"
          (evtSelectedDescriptionChanged)="linhaValoresSerie.nrefprocesso = $event"
          [outputKey]="'nrefprocesso'"
          [output]="'nrefprocesso'"
          [outputDescription]="'nRefProcesso'"
          [fieldsMap]="{nRefProcesso: 'nrefprocesso'}">
        </entity-autocomplete>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.nrefprocessoSelected"></pl-edit-checkbox>
      </actions>
    </pl-group>
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.nVendedor'"></label>
      <edit>
        <entity-autocomplete
          entity="vendedores"
          attrName="vendedor"
          [model]="linhaValoresSerie.nVendedor"
          [output]="'nVendedor'"
          [outputKey]="'nVendedor'"
          [(selectedKey)]="linhaValoresSerie.nVendedor">
        </entity-autocomplete>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.nVendedorSelected"></pl-edit-checkbox>
      </actions>
    </pl-group>
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.desconto'"></label>
      <edit>
        <pl-edit type="number" attrName="d1" [(model)]="linhaValoresSerie.d1"></pl-edit>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.d1Selected"></pl-edit-checkbox>
      </actions>
    </pl-group>
    <pl-group>
      <label [translate]="'docscomerciais.doc.linhas.codIva'"></label>
      <edit>
        <entity-autocomplete entity="ivas" [model]="linhaValoresSerie.codIva" [(selectedKey)]="linhaValoresSerie.codIva" (modelChange)="onCodIvaChanged($event)" outputKey="codIva" output="codIva">
        </entity-autocomplete>
      </edit>
      <actions>
        <pl-edit-checkbox [(model)]="linhaValoresSerie.codIvaSelected"></pl-edit-checkbox>
      </actions>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-success action-save" (click)="close()" plPromise data-focus><i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
