import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, isEmpty, PlAlertService} from 'pl-comps-angular';
import {IJsonAtivosVenda, IJsonAtivoVendaConta} from '../../../../modules/ativos/ativosvenda/jsonAtivosVenda.module.interface';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {AtivosVendaService} from '../../../../modules/ativos/ativosvenda/ativosVenda.module.service';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {IJsonAquisicaoPesquisaContab} from '../../../../modules/ativos/ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocContabilidade} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IAtivoVendaContab} from '../../../../modules/ativos/ativosvenda/ativosVenda.module.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

const STEP_INDEX_LISTA_DOCUMENTOS = 0;
const STEP_INDEX_DADOS_VENDA = 1;

@Component({
  selector: 'modal-ativos-venda',
  templateUrl: './ativosVenda.modal.component.html'
})
export class AtivosVendaModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public ativo: IJsonAtivos;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly definition: IPlNavWizardDefinition;

  public selected: IJsonAquisicaoPesquisaContab;
  public selectedStep: IPlNavWizardStep;
  public ativosvenda: IJsonAtivosVenda;
  public contaDoCliente: string;
  public listContaMaisMenosVal: Array<IJsonAtivoVendaConta>;
  public listContaDoIva: Array<IJsonAtivoVendaConta>;
  public valorTotal: number;
  public canSubmit: boolean;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  private _dataGridInstance: dxDataGrid;
  private _hasContabilidadeDigital: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosVendaService: AtivosVendaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosaquisicaocontab.table.nome', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosaquisicaocontab.table.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosaquisicaocontab.table.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosaquisicaocontab.table.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'string', caption: 'ativosaquisicaocontab.table.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado'}
      ],
      dataSource: [],
      export: {filename: 'ativos.modal.registodevendaativo'},
      keyExpr: 'extPocCabID',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
    this.definition = {
      items: []
    };
    if (!this.ativosvenda) {
      this.ativosvenda = {
        coefCorrMonet: '',
        contaCliente: '',
        dataVenda: moment(),
        extPocCabId: '',
        listaAtivos: [],
        valorDoIva: 0,
        valorSemIva: 0,
        nomeContaCliente: '',
        listContaIva: [],
        listContaMaisMenosValia: []
      };
    }
    this.canSubmit = false;
    this._getContaPesq();
  }

  public ngOnInit(): void {
    this._hasContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
  }

  public close(): Promise<void> {
    this.ativosvenda.listaAtivos.push({
      codAtivo: this.ativo.codAtivo,
      dataAquisicao: this.ativo.aquisicao.dataAquisicao,
      designacao: this.ativo.designacao,
      refExterna: this.ativo.refExterna,
      valorAtivoBrutoContab: this.ativo.valorAquisicaoContab,
      valorDepreciacoesAcumuladasContab: this.ativo.valorDepreciacaoContab,
      valorVenda: this.ativosvenda.valorSemIva
    });
    return this._ativosVendaService.postRegistaVenda(this.ativosvenda).then((response: HttpResponse<IJsonAtivos>) => {
      this._plAlertService.success('ativosvenda.message.success');
      return super.close(response.body);
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivoVendaContab>): void {
    if (event.rowType !== 'data') {
      return;
    }
    this.selected = event.data;
    devExpressDataGridExpandDetailHandler(event, () =>
      this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        event.data._docContabilidade = response.body;
        if (this._hasContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: response.body.extPocCabID,
            periodo: response.body.periodo,
            nDiario: response.body.nDiario,
            nDocInterno: response.body.nDocInterno,
            dataDoc: response.body.dataDoc
          };
        }
      })
    );
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_LISTA_DOCUMENTOS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_DADOS_VENDA]))) {
      if (this.selected) {
        return this._initStep2().then(() => true);
      }
      this._plAlertService.error('ativosvenda.error.invalidStep');
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }

  private _initStep2(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._ativosVendaService
      .postDadosparaformvenda(this.selected, this.ativo.codAtivo)
      .then((response: HttpResponse<IJsonAtivosVenda>) => {
        if (!isEmpty(response.body.contaCliente)) {
          this.contaDoCliente = `${response.body.contaCliente} - ${response.body.nomeContaCliente}`;
        }
        this.listContaMaisMenosVal = response.body.listContaMaisMenosValia;
        this.listContaDoIva = response.body.listContaIva;

        this.valorTotal = response.body.valorSemIva + response.body.valorDoIva;
        this.canSubmit = true;
        this.ativosvenda = response.body;
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  private _getContaPesq(): Promise<void> {
    return this._ativosVendaService.getAtivosPesquisaContab().then((response: HttpResponse<Array<IAtivoVendaContab>>) => {
      this.dataGridDefinition.dataSource = response.body;
    });
  }
}
