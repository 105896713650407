import {isObject} from 'pl-comps-angular';
import {Transition} from '@uirouter/core';
import {DocsContabilidadeEditComponent} from './components/edit/docsContabilidade.edit.component';
import {DocsContabilidadeListComponent} from './components/list/docsContabilidade.list.component';
import {DocsContabilidadeService} from './service/docsContabilidade.service';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntity, IDocsContabilidadeStateParams} from './docsContabilidade.interface';
import {ETipoContaContabilidade} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonDocContabilidade} from './jsonDocContabilidade.interface';
import {IJsonEmpresaAno} from '../../../interfaces/jsonEmpresa.interface';
import {IPortalStates} from '../../../services/portals/portals.service.interface';
import {isTest} from '../../../../config/constants';
import {RESOLVER_CG_STORE_URL} from '../../../../config/uirouter/uirouter.resolvers';
import {RESOLVER_CONTABILIDADE_DIGITAL, RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ROLE} from '../../../services/role.const';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

export const ENTITY_DOCS_CONTABILIDADE: IDocsContabilidadeEntity = {
  name: ENTITY_NAME_DOCS_CONTABILIDADE,
  roles: [ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'docscontabilidade.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.docscontabilidade',
  metadata: {
    keyName: 'extPocCabID',
    descriptionName: 'nDocumento',
    fields: [
      {name: 'extPocCabID', caption: 'docscontabilidade.fields.extPocCabID'},
      {
        name: 'periodo',
        caption: 'docscontabilidade.fields.periodo',
        entity: {name: 'periodos', keyTarget: 'periodo', outputTarget: 'nome'},
        validators: {required: true, maxlength: 7}
      },
      {name: 'nomePeriodo', caption: 'docscontabilidade.fields.periodo', entity: {name: 'periodos', keyTarget: 'periodo', outputTarget: 'nomePeriodo'}},
      {
        name: 'nDiario',
        caption: 'docscontabilidade.fields.nDiario',
        entity: {name: 'diarios', keyTarget: 'nDiario', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      },
      {name: 'nomeDiario', caption: 'docscontabilidade.fields.nDiario', entity: {name: 'diarios', keyTarget: 'nDiario', outputTarget: 'nome'}},
      {name: 'nDocInterno', caption: 'docscontabilidade.fields.nDocInterno', validators: {maxlength: 25}},
      {
        name: 'codDescritivo',
        caption: 'docscontabilidade.fields.codDescritivo',
        entity: {name: 'descritivos', keyTarget: 'nDescrit', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      },
      {
        name: 'nomeDescritivo',
        caption: 'docscontabilidade.fields.codDescritivo',
        entity: {name: 'descritivos', keyTarget: 'codDescritivo', outputTarget: 'nomeDescritivo'}
      },
      {name: 'codMoeda', caption: 'docscontabilidade.fields.codMoeda', entity: {name: 'moedas', keyTarget: 'codMoeda', outputTarget: 'nome'}},
      {name: 'nomeMoeda', caption: 'docscontabilidade.fields.codMoeda', entity: {name: 'moedas', keyTarget: 'codMoeda', outputTarget: 'nomeMoeda'}},
      {name: 'dataLancamento', type: 'date', caption: 'docscontabilidade.fields.dataLancamento', validators: {required: true}},
      {name: 'dataVencimento', type: 'date', caption: 'docscontabilidade.fields.dataVencimento', validators: {required: true}},
      {name: 'dataDoc', type: 'date', caption: 'docscontabilidade.fields.dataDoc'},
      {name: 'documentoExterno', caption: 'docscontabilidade.fields.documentoExterno', validators: {maxlength: 60}},
      {name: 'descricao', caption: 'docscontabilidade.fields.descricao', validators: {maxlength: 50}},
      {name: 'nContribuinte', caption: 'docscontabilidade.fields.nContribuinte', validators: {maxlength: 25}},
      {name: 'totalCreditoGeral', type: 'number', caption: 'docscontabilidade.fields.totalCreditoGeral'},
      {name: 'totalDebitoGeral', type: 'number', caption: 'docscontabilidade.fields.totalDebitoGeral'},
      {name: 'campoErroInformativo', caption: 'global.text.error'},
      {name: 'nDocumento', caption: 'docscontabilidade.fields.nDocumentoFilter', readonly: true, properties: {devExpress: {dataGrid: {caption: 'docscontabilidade.fields.nDocumento'}}}},
      {
        name: 'nconta',
        caption: 'docscontabilidade.fields.nconta',
        readonly: true,
        entity: {name: 'pocs', keyTarget: 'nConta', outputTarget: 'nConta'},
        properties: {filter: `tipo=${ETipoContaContabilidade.Movimento}`}
      },
      {name: 'anulado', type: 'boolean', caption: 'docscontabilidade.fields.anulado', readonly: true},
      {name: 'stampUpdate', type: 'datetime', caption: 'docscontabilidade.fields.stampUpdate'},
      {name: 'valor', type: 'number', caption: 'docscontabilidade.fields.valor'},
      {name: 'nUtilizador', type: 'string', caption: 'docscontabilidade.fields.nUtilizador', properties: {devExpress: {dataGrid: {visible: false}}}},
      {name: 'temDocDigital', type: 'boolean', caption: 'docscontabilidade.fields.temDocDigital'}
    ],
    order: 'stampUpdate desc',
    listFields: 'nDocumento,dataLancamento,dataVencimento,dataDoc,documentoExterno,descricao,anulado,stampUpdate,nUtilizador,temDocDigital',
    filterFields:
      'nDocumento,valor,nconta,documentoExterno,totalDebitoGeral,totalCreditoGeral,dataLancamento,dataVencimento,dataDoc,descricao,nDiario,codDescritivo,anulado,periodo,nContribuinte,nUtilizador,temDocDigital',
    filterField: 'valor'
  },
  autocomplete: {
    rowTemplate: '{{periodo}}.{{nDiario}}.{{nDocInterno}}',
    output: '{{periodo}}.{{nDiario}}.{{nDocInterno}}',
    searchFields: 'nDocumento,dataLancamento,dataVencimento,dataDoc'
  },
  actions: {
    new: true,
    edit: true,
    detail: false,
    delete: false,
    search: false,
    filter: true
  },
  list: {
    state: {
      component: DocsContabilidadeListComponent,
      params: {
        redirect: {
          type: 'bool',
          value: true,
          squash: true
        }
      },
      urlSuffix: isTest() ? '?redirect' : '',
      redirectTo: redirectToDocsContabilidadeList,
      resolve: [
        {
          provide: 'anosEmpresa',
          deps: ['session', EmpresasService],
          useFactory: (session: TUserSession, empresasService: EmpresasService): Promise<Array<IJsonEmpresaAno>> => {
            return empresasService
              .getAnos(session.erp.nEmpresa)
              .then((response: THttpQueryResponse<IJsonEmpresaAno>) => response.body.list)
              .catch(() => []);
          }
        }
      ]
    }
  },
  detail: {
    state: {
      component: DocsContabilidadeEditComponent,
      params: {
        id: {
          type: 'string',
          value: null,
          dynamic: true
        }
      },
      resolve: [RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL, RESOLVER_CONTABILIDADE_DIGITAL, RESOLVER_CG_STORE_URL]
    }
  },
  serviceInjectable: [
    DocsContabilidadeService,
    function (docsContabilidadeService: DocsContabilidadeService): void {
      this.sendEmail = docsContabilidadeService.sendEmail.bind(docsContabilidadeService);

      this.sendCommand = docsContabilidadeService.sendCommand.bind(docsContabilidadeService);

      this.podeUltrapassarToleranciaIVA = docsContabilidadeService.podeUltrapassarToleranciaIVA.bind(docsContabilidadeService);
    }
  ],
  eventsInjectable: [
    DocsContabilidadeService,
    function (docsContabilidadeService: DocsContabilidadeService): void {
      this.onLoad = (docContabilidade: IJsonDocContabilidade) => {
        if (isObject(docContabilidade) && Object.prototype.hasOwnProperty.call(docContabilidade, 'extPocCabID')) {
          docsContabilidadeService.handleCommandResponse(docContabilidade, true, true, true);
        }
      };
    }
  ]
};

export async function redirectToDocsContabilidadeList(transition: Transition): Promise<string> {
  const params: IDocsContabilidadeStateParams = <IDocsContabilidadeStateParams>transition.params();
  if (params.redirect === false) {
    return undefined;
  }
  const states: IPortalStates = await transition.injector().getAsync<IPortalStates>('states');
  return states.new.name;
}
