import {Component, Injector, OnInit} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditConfigERP} from '../../components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP, IJsonRHPortalColaboradorNotificationConfig} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {EPortalColaboradorNotificacao, IJsonRHConflitosConfigColaboradorItem} from '../configs.portalcolaborador.module.interface';
import {copy, ERadix, EWeekDay, PlAlertService} from 'pl-comps-angular';
import moment from 'moment';
import {Writeable} from '../../../../../../common/interfaces/interfaces';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {DATA_SOURCE_DIAS_SEMANA} from '../../../../../datasources/diassemana/diassemana.datasource';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Properties} from 'devextreme/ui/button';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {RhConflitosConflitoModalComponent} from '../modals/rhconflitos.conflito.modal.component';
import {ConfigsPortalColaboradorModuleService} from '../configs.portalcolaborador.module.service';
import {parseInt} from 'lodash-es';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_PRHTIPOCOM} from '../../../../../entities/prhtipocom/prhtipocom.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPRHTipoCom} from '../../../../../entities/prhtipocom/jsonrhtipocom.entity.interface';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';

const TOOLBAR_INSTANCE_ID_PRHTIPOCOM = 'TOOLBAR_PRHTIPOCOM';

@Component({
  selector: 'module-configs-erp-rh',
  templateUrl: './configs.portalcolaborador.module.component.html'
})
export class ConfigsErpPortalColaboradorModuleComponent extends ConfigsErpBaseModuleComponent implements OnInit {
  public readonly portalColaboradorNotificacao: typeof EPortalColaboradorNotificacao;
  public readonly groupItemsSource: ReadonlyArray<IDataSourceItem<boolean>>;
  public dataGridConfigPortalColaborador: IDevExpressDataGrid;
  public notificacaoModel: IJsonRHPortalColaboradorNotificationConfig;
  public diasSemanaSource: Array<Writeable<IDataSourceItem<EWeekDay>>>;
  public notificacaoSource: Array<IDataSourceItem<EPortalColaboradorNotificacao>>;
  public permiteCheck: boolean;
  public dataGridColaboradoresDefinition: IDevExpressDataGrid;
  public dataGridPRHTipoComDefinition: IDevExpressDataGrid;
  public promisePermite: TServiceResponse<void>;
  public toolbarPRHTipoComId: string;

  private readonly _prhtipocomService: IEntityService<IJsonPRHTipoCom>;
  private readonly _entityMaintenanceInstancePRHTipoCom: IEntityMaintenanceInstance<IJsonPRHTipoCom>;
  private _dataGridInstanceColaboradores: dxDataGrid<IJsonRHConflitosConfigColaboradorItem, string>;
  private _dataGridInstancePRHTipoCom: dxDataGrid<IJsonPRHTipoCom, number>;
  private _conflitosList: Array<IJsonRHConflitosConfigColaboradorItem>;
  private _dataGridPagingPRHTipoCom: DevExpressDatagridPaging<IJsonPRHTipoCom, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _configsPortalColaboradorModuleService: ConfigsPortalColaboradorModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this._prhtipocomService = this._entityServiceBuilder.build(ENTITY_NAME_PRHTIPOCOM);
    this._entityMaintenanceInstancePRHTipoCom = this._entityMaintenanceService.build(ENTITY_NAME_PRHTIPOCOM);
    this.portalColaboradorNotificacao = EPortalColaboradorNotificacao;
    this.notificacaoModel = {
      notificacao: EPortalColaboradorNotificacao.DESATIVADO,
      hora: moment().set({hours: 8, minutes: 0, seconds: 0}),
      dia: EWeekDay.Monday
    };
    this.diasSemanaSource = copy(<Array<Writeable<IDataSourceItem<EWeekDay>>>>DATA_SOURCE_DIAS_SEMANA.data);
    for (const mes of this.diasSemanaSource) {
      mes.name = this._translateService.instant(mes.name);
    }
    this.notificacaoSource = [
      {value: EPortalColaboradorNotificacao.DESATIVADO, name: this._translateService.instant('configsErp.items.portalColaborador.desativado')},
      {value: EPortalColaboradorNotificacao.DIARIA, name: this._translateService.instant('configsErp.items.portalColaborador.diaria')},
      {value: EPortalColaboradorNotificacao.DIA_SEMANA, name: this._translateService.instant('configsErp.items.portalColaborador.diaDaSemana')}
    ];
    this.groupItemsSource = [
      {value: true, label: 'configsErp.items.portalColaborador.conflitos.permite'},
      {value: false, label: 'configsErp.items.portalColaborador.conflitos.naoPermite'}
    ];
    this.dataGridColaboradoresDefinition = {
      columns: [
        {
          dataField: 'idColaborador1',
          caption: 'configsErp.items.portalColaborador.conflitos.fields.colaborador1',
          dataType: 'string',
          groupIndex: 0,
          allowGrouping: false,
          showInColumnChooser: false,
          groupCellTemplate: 'groupCellTemplateCol',
          calculateDisplayValue: (rowData: IJsonRHConflitosConfigColaboradorItem): string => {
            return `${rowData.idColaborador1} - ${rowData.nomeColaborador1}`;
          }
        },
        {
          dataField: 'idColaborador2',
          caption: 'configsErp.items.portalColaborador.conflitos.fields.colaborador2',
          dataType: 'string',
          calculateDisplayValue: (rowData: IJsonRHConflitosConfigColaboradorItem): string => {
            return `${rowData.idColaborador2} - ${rowData.nomeColaborador2}`;
          }
        },
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          width: '60px',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      dataSource: new CustomStore({
        key: 'idConflito',
        load: () => {
          return this._configsPortalColaboradorModuleService.getConflitos().then((response) => {
            this._conflitosList = response.body;
            return response.body;
          });
        }
      }),
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('configsErp.items.portalColaborador.conflitos.criarConflito'),
              onClick: () => this._showNewConflictModal()
            } satisfies Properties
          }
        ]
      }
    };
    this._conflitosList = [];
    this.dataGridPRHTipoComDefinition = {
      columns: [
        {
          dataField: 'codPRHTipoCom',
          dataType: 'number',
          caption: 'prhtipocom.fields.codPRHTipoCom'
        },
        {
          dataField: 'nome',
          dataType: 'string',
          caption: 'prhtipocom.fields.nome'
        },
        {
          dataField: 'isSystem',
          dataType: 'boolean',
          caption: 'prhtipocom.fields.sistema'
        },
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          width: '90px',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      dataSource: new CustomStore({
        key: 'codPRHTipoCom',
        load: (loadOptions) => {
          let search: string;
          if (loadOptions.filter) {
            search = devExpressDataGridFiltersToQueryFilter(this._dataGridInstancePRHTipoCom, loadOptions.filter);
          }
          const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
          if (!this._dataGridPagingPRHTipoCom) {
            this._dataGridPagingPRHTipoCom = new DevExpressDatagridPaging(this._dataGridInstancePRHTipoCom);
          }
          const {page, perPage} = this._dataGridPagingPRHTipoCom.paginate(loadOptions);
          return this._prhtipocomService.query({pesquisa: search, ordena: order, pagina: page, porpagina: perPage}).then((response) => {
            return response.body.list;
          });
        }
      }),
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('configsErp.items.portalColaborador.prhtipocom.criarTipoCom'),
              onClick: () => this._newPRHTipoCom()
            } satisfies Properties
          }
        ]
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._configsPortalColaboradorModuleService.getConfig().then((response) => {
      this.permiteCheck = response.body.geralPermiteMarcarFeriasComConflitos;
    });
    this.dataGridConfigPortalColaborador = {
      columns: [
        {dataField: 'description', caption: 'entity.detail.field', dataType: 'string', allowEditing: false},
        {caption: 'entity.detail.value', dataType: 'string', editCellTemplate: 'cellTemplateValues', showEditorAlways: true, allowEditing: true, alignment: 'center'}
      ],
      allowColumnReordering: false,
      allowColumnResizing: true,
      columnAutoWidth: true,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      editing: {mode: 'cell', allowUpdating: true},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: true},
      showBorders: true,
      showColumnLines: true,
      sorting: {mode: 'none'}
    };
    this._configErpService.getRHPortalColaboradorNotificationConfig().then((response) => {
      this.notificacaoModel = response.body;
    });
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarPRHTipoComId = `${this.instanceName}_${TOOLBAR_INSTANCE_ID_PRHTIPOCOM}`;
  }

  public onInitializedColaboradores({component}: IDevExpressDataGridEventOnInitialized<IJsonRHConflitosConfigColaboradorItem, string>): void {
    this._dataGridInstanceColaboradores = component;
  }

  public onInitializedPRHTipoCom({component}: IDevExpressDataGridEventOnInitialized<IJsonPRHTipoCom, number>): void {
    this._dataGridInstancePRHTipoCom = component;
  }

  public permiteModelChange(value: boolean): void {
    this.permiteCheck = value;
    this.promisePermite = this._configsPortalColaboradorModuleService.saveConfig({geralPermiteMarcarFeriasComConflitos: this.permiteCheck});
  }

  public async deleteConflito(idConflito: string): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'configsErp.items.portalColaborador.conflitos.temACertezaApagarEsteConflito');
    await this._configsPortalColaboradorModuleService.deleteConflito(idConflito);
    await this._dataGridInstanceColaboradores.refresh();
  }

  public async deletePRHTipoCom(codPRHTipoCom: string): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'configsErp.items.portalColaborador.prhtipocom.temACertezaApagarEsteTipoCom', {size: 'md', type: 'delete'});
    await this._prhtipocomService.delete({id: codPRHTipoCom});
    await this._dataGridInstancePRHTipoCom.refresh();
  }

  public async editPRHTipoCom(codPRHTipoCom: string): Promise<void> {
    await this._entityMaintenanceInstancePRHTipoCom.maintenanceEdit(codPRHTipoCom, {modalOptions: {size: 'lg'}});
    await this._dataGridInstancePRHTipoCom.refresh();
  }

  public async apagarConflitosPorEmp(cellDisplay: string): Promise<void> {
    await this._cgModalService.showOkCancel(
      'global.text.confirmation',
      this._translateService.instant('configsErp.items.portalColaborador.conflitos.temACertezaApagarTodosConflitosDoEmpregado', {
        empregado: cellDisplay
      })
    );
    const codEmp = parseInt(cellDisplay.split('-').shift().trim(), ERadix.Decimal);
    await this._configsPortalColaboradorModuleService.deleteConflitosDoColaboradoro(codEmp);
    await this._dataGridInstanceColaboradores.refresh();
  }

  public readonly fnOnValidateReport = (): Promise<IJsonConfigERP> => this._onValidateReport();

  public readonly fnSaveConfig: () => Promise<void> = () => this._saveConfig();

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Multiple;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP> {
    return rawConfigurations
      .filter((item) => item.name === 'portalcolaborador.reportrecibosal')
      .map<IConfigsErpEditConfigERP>((item: IJsonConfigERP, index: number) => {
        item.cguid = 'reports_recibossal';
        const transformedItem: IConfigsErpEditConfigERP = {...item, saveField: this.fnSaveField, index: index};
        return transformedItem;
      });
  }

  private _onValidateReport(): Promise<IJsonConfigERP> {
    const object: IConfigsErpEditConfigERP = <IConfigsErpEditConfigERP>this.configList.find((item: IConfigsErpEditConfigERP) => item.name === 'portalcolaborador.reportrecibosal');
    return this.saveField(object);
  }

  private _saveConfig(): Promise<void> {
    return this._configErpService.saveRHPortalColaboradorNotificationConfig(this.notificacaoModel).then(() => {
      this._plAlertService.success('configsErp.items.portalColaborador.configGuardadaComSucesso');
    });
  }

  private _showNewConflictModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RhConflitosConflitoModalComponent);
    const componentInstance: RhConflitosConflitoModalComponent = modalInstance.componentInstance;
    componentInstance.conflitosList = this._conflitosList;
    return modalInstance.result.then(() => {
      this._dataGridInstanceColaboradores.refresh();
    });
  }

  private async _newPRHTipoCom(): Promise<void> {
    await this._entityMaintenanceInstancePRHTipoCom.maintenanceNew({modalOptions: {size: 'lg'}});
    await this._dataGridInstancePRHTipoCom.refresh();
  }
}
