import {IJsonPRHEntAlter} from '../../../entities/prhentalters/jsonPRHEntAlter.entity.interface';

export interface IColaboradoresAltEntidades {
  codEmp: number;
  nome: string;
  lista: Array<IJsonPRHEntAlter>;
  detailOpen: boolean;
}

export const MODULE_NAME_ALTERACOES_ENTIDADES = 'colaboradoresAlteracoesEntidades';
