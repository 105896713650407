import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_OFFICE_REPORTING} from './officeReporting.module.interface';
import {OfficeReportingModuleComponent} from './components/officeReporting.module.component';
import {OfficeReportingService} from './officeReporting.module.service';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {IJsonEmpresaAno} from '../../../interfaces/jsonEmpresa.interface';

export const MODULE_OFFICE_REPORTING: IModuleDefinition = {
  name: MODULE_NAME_OFFICE_REPORTING,
  state: {
    url: `/${MODULE_NAME_OFFICE_REPORTING}`,
    component: OfficeReportingModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.officereporting'
    },
    resolve: [
      {
        provide: 'paginasCaption',
        deps: [OfficeReportingService],
        useFactory: (officeReportingService: OfficeReportingService): Promise<Array<string>> => {
          return officeReportingService.evolucaoDoNegocioPaginas().then((response: HttpResponse<Array<string>>) => response.body);
        }
      },
      {
        provide: 'anos',
        deps: ['session', EmpresasService],
        useFactory: (session: TUserSession, empresasService: EmpresasService): Promise<Array<IJsonEmpresaAno>> => {
          return empresasService.getAnos(session.erp.nEmpresa).then((response: THttpQueryResponse<IJsonEmpresaAno>) => response.body.list);
        }
      }
    ]
  }
};
