import {ICGHelpTopic, ICGHelpTopicItem} from './helptopics.modal.component.interface';

export const CG_HELP_TOPIC_ITEM_TUTORIAL_VIDEOS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'helptopics.titles.tutorialVideos',
  icon: 'fa-video-camera',
  href: 'https://www.centralgest.com/videos/tutorial/'
});

export const CG_HELP_TOPIC_ITEM_FORMATIONS_VIDEOS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'helptopics.titles.formationsVideos',
  icon: 'fa-video-camera',
  href: 'https://www.centralgest.com/videos/formacao/'
});

export const CG_HELP_TOPIC_ITEM_FORUM_CG: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'helptopics.titles.forumCG',
  icon: 'fa-link',
  href: 'https://forum.centralgest.com/'
});

export const CG_HELP_TOPIC_ITEM_FACEBOOK_USER_GROUP: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'helptopics.titles.facebookUserGroup',
  icon: 'fa-facebook-square',
  href: 'https://www.facebook.com/groups/174368649864031'
});

export const CG_HELP_TOPIC_OTHER: Readonly<ICGHelpTopic> = Object.freeze<ICGHelpTopic>({
  title: 'global.text.other',
  collapsed: false,
  items: [CG_HELP_TOPIC_ITEM_TUTORIAL_VIDEOS, CG_HELP_TOPIC_ITEM_FORMATIONS_VIDEOS, CG_HELP_TOPIC_ITEM_FORUM_CG, CG_HELP_TOPIC_ITEM_FACEBOOK_USER_GROUP]
});
