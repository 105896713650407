<div class="ativos-import-criaficheiroexcel-modal" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.importaficheiro'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <pl-upload [url]="uploadUrl" [maxFiles]="1" acceptedFiles=".xlsx,.xls" (evtUploadedFile)="onFileUpload()"></pl-upload>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
