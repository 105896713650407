import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAcrescimoEncargoComFeriasAnuais} from '../../jsonAcrescimosEncargosFerias.module.interface';
import {IDevExpressChart} from '../../../../components/devexpress/charts/devexpress.charts.interface';
import {TranslateService} from '@ngx-translate/core';
import {IAcrescimoEncargoComFeriasAnuaisChart} from '../../acrescimosEncargosFerias.module.interface';

const FONT_FAMILY = 'Nunito Sans';
const CHART_COLOR = '#FFAA66';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-resumo-anual',
  templateUrl: './acrescimosEncargosFerias.resumoanual.modal.component.html'
})
export class AcrescimosEncargosFeriasResumoanualModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public resumoAnual: IJsonAcrescimoEncargoComFeriasAnuais;

  public readonly chartResumoAnual: IDevExpressChart<'BarSeries'>;

  public resumoAnualChart: Array<IAcrescimoEncargoComFeriasAnuaisChart>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.chartResumoAnual = {
      commonSeriesSettings: {
        argumentField: 'caption',
        type: 'bar',
        ignoreEmptyPoints: true
      },
      title: {
        horizontalAlignment: 'center',
        text: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.title'),
        font: {
          size: 18,
          weight: 800,
          family: FONT_FAMILY
        }
      },
      series: [{valueField: 'value', color: CHART_COLOR, name: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.totaisAno')}],
      legend: {
        verticalAlignment: 'top',
        horizontalAlignment: 'center',
        itemTextPosition: 'right',
        font: {
          weight: 700,
          family: FONT_FAMILY
        },
        margin: {
          top: 0,
          bottom: 15
        }
      },
      tooltip: {
        enabled: true,
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        arrowLength: 10,
        contentTemplate: 'customTooltip'
      }
    };
  }

  public ngOnInit(): void {
    this.resumoAnualChart = [
      {caption: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.acertoManual'), value: this.resumoAnual.valorAcertoManual},
      {caption: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.subferias'), value: this.resumoAnual.valorSubFerias},
      {caption: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.ferias'), value: this.resumoAnual.valorFerias},
      {caption: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.segSocial'), value: this.resumoAnual.valorEncargosSS},
      {caption: this._translateService.instant('acrescimosencargosferias.modals.resumoAnual.acTrab'), value: this.resumoAnual.valorEncargoAcTrab}
    ];
  }
}
