<div class="row">
  <div class="col"><label [translate]="mod22Translate"></label></div>
  <div class="col-lg-3">
    <div class="d-flex">
      <div [ngClass]="classBoxNumbers">{{ mod22Field }}</div>
      <pl-edit
        type="currency"
        [attrName]="mod22AttrName"
        [model]="mod22FieldValue"
        (modelChange)="mod22FieldValue = $event; fieldValueChanged()"
        [properties]="{modelOptions: {updateOn: 'blur'}}"
        [inputClass]="'text-right'">
        <ng-container *ngIf="titleContasDef">
          <div *inputGroup="{klass: 'hollow-form'}">
            <i class="fa fa-cogs text-info" [title]="titleContasDef" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef()"></i>
          </div>
        </ng-container>
      </pl-edit>
    </div>
  </div>
</div>
