import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {IJsonAtivosReavaliacoesNaoLegais, IJsonAtivosReavaliacoesNaoLegalmente} from './jsonAtivosReavaliacoesNaoLegais.interface';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosReavaliacoesNaoLegaisService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosreavaliacoesnaolegais`;
  }

  public getReavaliacaoNaoLegal(codAtivo: number): TServiceResponse<IJsonAtivosReavaliacoesNaoLegais> {
    return this._apiService.get<IJsonAtivosReavaliacoesNaoLegais>({
      url: this._path,
      params: {
        codativo: codAtivo
      }
    });
  }

  public getReavaliacoesNaoLegalmente(codAtivo: number): TServiceResponse<Array<IJsonAtivosReavaliacoesNaoLegalmente>> {
    return this._apiService.get<Array<IJsonAtivosReavaliacoesNaoLegalmente>>({
      url: `${this._path}/reavaliacoesnaolegalmente`,
      params: {
        codativo: codAtivo
      }
    });
  }

  public saveReavaliacaoNaoLegal(codAtivo: number, ativosreavaliacoesnaolegais: IJsonAtivosReavaliacoesNaoLegais): TServiceResponse<void> {
    return this._apiService.post<void, IJsonAtivosReavaliacoesNaoLegais>({
      url: this._path,
      body: ativosreavaliacoesnaolegais,
      params: {
        codativo: codAtivo
      }
    });
  }
}
