import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonSitEm} from './jsonSituacaoEmpregado.entity.interface';
import {ENTITY_NAME_SITUACAO_EMPREGADO} from './situacaoEmpregado.entity.interface';

export const ENTITY_SITUACAO_EMPREGADO: IEntityDefinition<IJsonSitEm> = {
  name: ENTITY_NAME_SITUACAO_EMPREGADO,
  roles: [ROLE.RH],
  searchPlaceholder: 'situacaoempregado.pesquisa',
  pageTitle: 'global.menu.situacaoempregado',
  metadata: {
    keyName: 'nSitEmpreg',
    fields: [
      {name: 'nSitEmpreg', type: 'string', caption: 'situacaoempregado.fields.nSitEmpreg', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', type: 'string', caption: 'situacaoempregado.fields.designaBreve', validators: {required: true}},
      {name: 'designaCompl', type: 'string', caption: 'situacaoempregado.fields.designaCompl'},
      {name: 'processaVenc', type: 'boolean', caption: 'situacaoempregado.fields.processaVenc'}
    ],

    order: 'nSitEmpreg',
    searchFields: 'nSitEmpreg,designaBreve,designaCompl,processaVenc',
    listFields: 'nSitEmpreg,designaBreve,designaCompl,processaVenc'
  },
  autocomplete: {
    rowTemplate: '{{nSitEmpreg}} - {{designaBreve}}',
    output: '{{nSitEmpreg}} - {{designaBreve}}',
    outputDescription: 'designaBreve',
    searchFields: 'nSitEmpreg,designaBreve,designaCompl,processaVenc'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
