import {merge} from 'lodash-es';
import {asyncScheduler} from 'rxjs';
import {AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {EMouseEventButton} from '../../../common/constants';
import {isEmpty, isFunction} from '../../../common/utilities/utilities';
import {IPlEditComponentOptionsInputRadio} from './edit.radio.component.interface';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

const KLASS_EVALUATE_TRUE = 'pl-radio-true';
const KLASS_EVALUATE_FALSE = 'pl-radio-false';
let UNIQUE_ID = 0;

@Component({
  selector: 'pl-edit-radio',
  templateUrl: './edit.radio.component.html',
  standalone: false,
  exportAs: 'cgcEditRadio'
})
export class PlEditRadioComponent<T = unknown> extends PlEditInputTypeComponent<T, IPlEditComponentOptionsInputRadio<T>> implements OnInit, OnChanges, AfterViewInit {
  @Input() public label: string;
  @Input() public radioValue: any;

  public readonly uniqueId: number;
  public evaluatedStatus: string;
  public evaluatedRadioValue: T;
  public evaluatedLabel: string;

  constructor(protected readonly _injector: Injector) {
    super('radio', _injector);
    this.uniqueId = ++UNIQUE_ID;
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputRadio<T>>(
      merge({}, this._defaultOptions, {
        label: undefined,
        value: undefined
      })
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._subscriptionValueChanges.unsubscribe();
    this._handleChanges();
    this.setValidators(this._requiredValidator());
    this._evaluateStatus();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {label, radioValue} = changes;
    if (label && !label.isFirstChange()) {
      this._changedLabel(label.currentValue);
    }
    if (radioValue && !radioValue.isFirstChange()) {
      this._changedRadioValue(radioValue.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._setValue(this.value);
  }

  public updateComponent(properties: IPlEditComponentOptionsInputRadio): void {
    super.updateComponent(properties);
    this._handleChanges();
  }

  public updateValue(value: any): void {
    super.updateValue(value);
    this._setValue(value);
    this._evaluateStatus();
  }

  public async render(): Promise<void> {
    if (this.options.disabled || this.options.readonly) {
      return;
    }
    const value: T = this.evaluatedRadioValue;
    await super.render(value);
    asyncScheduler.schedule(() => {
      this._setValue(value);
    });
  }

  public onClick(event: MouseEvent): void {
    if (isFunction(this.options.events?.click)) {
      this.options.events.click(this.value, event);
    }
    if (event.button !== EMouseEventButton.Main) {
      return;
    }
    if (!event.defaultPrevented) {
      event.preventDefault();
    }
    this.inputValueChanged(this.evaluatedRadioValue);
  }

  public onKeydownEnter(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.inputValueChanged(this.evaluatedRadioValue);
  }

  private _handleChanges(): void {
    this._changedLabel();
    this._changedRadioValue();
  }

  private _changedLabel(value: string = this.label): void {
    this.evaluatedLabel = value || this.options.label || undefined;
    if (this.evaluatedLabel) {
      this.evaluatedLabel = this._plTranslateService.translate(this.evaluatedLabel);
    }
  }

  private _changedRadioValue(value: any = this.radioValue): void {
    let val: any = value;
    if (isEmpty(val)) {
      val = this.options.value;
    }
    if (isEmpty(val)) {
      val = undefined;
    }
    this.evaluatedRadioValue = val;
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return !isEmpty(this.value) ? undefined : {required: true};
      }
      return undefined;
    };
  }

  private _setValue(value: T): void {
    this.value = value;
    if (this._inputField) {
      this._renderer.setProperty(this._inputField, 'checked', this.value === this.evaluatedRadioValue);
    }
  }

  private _evaluateStatus(): void {
    this.evaluatedStatus = this.value === this.evaluatedRadioValue ? KLASS_EVALUATE_TRUE : KLASS_EVALUATE_FALSE;
  }
}
