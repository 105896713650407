import {Component, Injector, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {isObject} from 'pl-comps-angular';
import {EMascaraAnaliticaTipoMascara} from '../../../mascarasanalitica/mascaraAnalitica.entity.interface';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IJsonReparticoesCCusto} from '../../jsonReparticoesCCusto.entity.interface';
import {IReparticoesCCustoEntityService} from '../../reparticoesCCusto.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'reparticoes-ccusto-edit',
  templateUrl: './reparticoesCCusto.entity.edit.component.html'
})
export class ReparticoesCCustoEditComponent extends ModuloEntityDetailComponent<IJsonReparticoesCCusto, IReparticoesCCustoEntityService> implements OnInit {
  public formGroup: UntypedFormGroup;

  public readonly tipoMascaraAnalitica: EMascaraAnaliticaTipoMascara;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tipoMascaraAnalitica = this._transition.to().data.tipoMascara;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.service.setOnBefore<IJsonReparticoesCCusto>((request: IApiRequestConfigWithBody<IJsonReparticoesCCusto>) => {
      if (request.params instanceof HttpParams) {
        request.params.set('tipomascara', String(this.tipoMascaraAnalitica));
      } else {
        if (!isObject(<unknown>request.params)) {
          (<unknown>request.params) = {};
        }
        (<{tipomascara: EMascaraAnaliticaTipoMascara}>request.params).tipomascara = this.tipoMascaraAnalitica;
      }
      return request;
    });
  }

  public changedFormGroup(value: UntypedFormGroup): void {
    this.formGroup = value;
  }
}
