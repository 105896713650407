import {HttpResponse} from '@angular/common/http';
import {ContabilidadeDeclaracaoIvaRecapitulativaModuleComponent} from './contabilidade.declaracaoIva.recapitulativa.module.component';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../services/contabilidade.declaracaoiva.service';
import {IJsonDecIvaRecapitulativa} from '../../../../../interfaces/jsonDeclaracaoIva.interface';
import {IModuleDefinition} from '../../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA} from '../contabilidade.declaracaoIva.module.interface';
import {ROLE} from '../../../../../services/role.const';

export const MODULE_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA: IModuleDefinition = {
  name: MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA,
  state: {
    url: `/${MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA}`,
    component: ContabilidadeDeclaracaoIvaRecapitulativaModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.declaracaoivarecapitulativa'
    },
    resolve: [
      {
        provide: 'decIvaRecapitulativa',
        deps: [ContabilidadeDeclaracaoIvaService],
        useFactory: (declaracaoIvaService: ContabilidadeDeclaracaoIvaService): Promise<IJsonDecIvaRecapitulativa> => {
          return declaracaoIvaService.loadDeclaracaoIVARecap().then((response: HttpResponse<IJsonDecIvaRecapitulativa>) => response.body);
        }
      }
    ]
  }
};
