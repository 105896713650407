<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'recibos.imprimirModal.title'" [translateParams]="{nomeConta: nomeConta}"></h4>
</div>

<div class="modal-body" [ngSwitch]="viewPdf">
  <div *ngSwitchCase="true">
    <cg-pdf-viewer [pdfSrc]="url"></cg-pdf-viewer>
  </div>

  <div class="entity-detail-form" *ngSwitchDefault>
    <pl-form [(ngForm)]="form" [properties]="{orientation: 'horizontal'}">
      <pl-group>
        <label [translate]="'recibos.imprimirModal.fields.numeroDeVias'"></label>
        <edit>
          <pl-edit type="rowselect" [(model)]="nCopias" [properties]="{start: 1, end: 10}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'recibos.imprimirModal.fields.emailCliente'"></label>
        <edit>
          <pl-edit type="text" [(model)]="email" [properties]="{placeholder: 'recibos.imprimirModal.placeholder.email'}"></pl-edit>
        </edit>
        <actions>
          <button type="button" class="btn btn-primary" [click]="sendEmail" [disabled]="form?.invalid || !email" plPromise>
            <i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'recibos.imprimirModal.actions.send'"></span>
          </button>
        </actions>
      </pl-group>

      <pl-group>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-autocomplete
            [source]="reportsSource"
            attrName="listagem"
            [model]="report"
            (evtSelected)="reportChanged($event)"
            [rowTemplate]="rowTemplateListagem"
            [output]="'title'"
            [properties]="{disallowClear: true}"></pl-autocomplete>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-switch" (click)="switchView()">
    <i class="fa fa-fw" [class.fa-file-pdf-o]="!viewPdf" [class.fa-chevron-left]="viewPdf"></i>
    &nbsp;<span [translate]="viewPdf ? 'global.btn.back' : 'global.btn.pdf'"></span>
  </button>

  <button type="button" class="btn btn-light action-close" (click)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
