import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';

export enum EConfigsErpDepth {
  Single = 1,
  Multiple
}

export interface IConfigsErpEditGroup {
  id?: string;
  title: string;
  items: Array<IConfigsErpEditConfigERP>;
}

export interface IConfigsErpEditConfigERP extends IJsonConfigERP {
  index: number;
  filter?: string;

  saveField(configItem: IJsonConfigERP): Promise<unknown>;
}
