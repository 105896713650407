import {toJson} from 'pl-comps-angular';

export interface IStoreGetSupportUrlParams {
  idContrato: number;
  tokenPriority: 'auth' | 'auth-cgon';
  nEmpresa?: string;
  faqsUrl?: string;
}

export const CG_ON_COMPANY_URL = 'https://www.centralgestcloud.com';
export const CG_ON_PATH_BACK_OFFICE = '/backoffice';
export const CG_ON_SUPPORT_URL_PRIORITY_CLOUD = 'auth';
export const CG_ON_SUPPORT_URL_PRIORITY_STORE = 'auth-cgon';

export function generateSupportUrl(storeUrl: string, idContrato: number, nEmpresa: string, faqsUrl?: string): string {
  const params: IStoreGetSupportUrlParams = {
    idContrato: idContrato,
    tokenPriority: CG_ON_SUPPORT_URL_PRIORITY_CLOUD,
    nEmpresa: nEmpresa,
    faqsUrl: faqsUrl
  };
  if (!storeUrl.endsWith('/#')) {
    if (storeUrl.endsWith('/')) {
      storeUrl += '#';
    } else {
      storeUrl += '/#';
    }
  }
  return `${storeUrl}/redirect?to=support&params=${encodeURIComponent(window.btoa(toJson(params)))}`;
}
