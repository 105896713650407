<div class="trdocs-cabs-wizard">
  <div *ngIf="!hasComercialAdvanced" class="mb-2">
    <cg-blocked-plugin [pluginRoles]="roleComercialAdvanced"></cg-blocked-plugin>
  </div>

  <pl-nav-wizard
    #navWizard="navWizard"
    *ngIf="hasComercialAdvanced"
    [definition]="navWizardDefinition"
    (modelChange)="wizardSelectedStep = $event"
    [callback]="navWizardCallback"
    [properties]="propertiesNavWizard"
    [hideFooter]="wizardHideFooter"
    (evtStepChange)="stepChanged($event)"
    [beforeStepChange]="fnBeforeChangedStep"
    [onFinalize]="finalize">
    <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Pesquisa" caption="trDocsCabsWizard.steps.pesquisa" icon="fa fa-fw fa-search" [hidePrevious]="true">
      <div *plNavWizardStepContent>
        <cg-card-panel class="mb-4" #cardPanel="cgCardPanel" [collapsed]="!isOnInit" [caption]="'global.text.searchfilter'">
          <div *cgCardPanelContent>
            <pl-form class="px-2 py-1" formInstanceName="formSearch" (formInstanceChange)="formSearch = $event">
              <pl-group>
                <label [translate]="'trDocsCabsWizard.captions.tipoDocumento'"></label>
                <edit>
                  <pl-select
                    [(model)]="filterModel.nDocFaList"
                    [multiple]="true"
                    [source]="docFaSource"
                    [multipleCheckbox]="true"
                    [rowTemplate]="docFaTemplate"
                    [valueField]="'nDocFa'"
                    [searchFn]="nDocFaSearchFn"
                    [output]="docFaOutput"
                    [plPromise]="docFaPromise"
                    [properties]="{readonly: docFaReadOnly, validators: {required: {value: true}}}"
                    appendTo="body"></pl-select>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="clifoTypeName"></label>
                  <edit>
                    <entity-autocomplete entity="clifos" attrName="nConta" [selectedKey]="filterModel.nConta" (selectedKeyChange)="nContaChange($event)" output="key"></entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'trDocsCabsWizard.captions.dataDoc'"></label>
                  <edit>
                    <pl-edit-datepicker [(model)]="filterModel.dataDoc" [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'trDocsCabsWizard.captions.ateDataDoc'"></label>
                  <edit>
                    <pl-edit-datepicker [(model)]="filterModel.dataDocAte" [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
                  </edit>
                </pl-group>
                <pl-group class="align-self-end">
                  <edit>
                    <pl-edit
                      type="checkbox"
                      attrName="onlyPossibleToTransform"
                      [(model)]="filterModel.onlyPossibleToTransform"
                      [properties]="{label: 'trDocsCabsWizard.captions.onlyPossibleToTransform'}">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <div class="row">
                <div class="col-12">
                  <pl-button type="button" [attrName]="'searchButton'" klass="btn btn-sm btn-primary" [onClick]="fnSearch" [plPromise]="docFaPromise">
                    <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                  </pl-button>
                </div>
              </div>
            </pl-form>
          </div>
        </cg-card-panel>

        <dx-data-grid
          [cgDxDataGrid]="dataGridCabsDefinition"
          cgDxDataGridInstanceName="TrDocCabsTable"
          [dataSource]="dataGridCabsDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onRowPrepared)="onDataGridRowPrepared($event)"
          (onContentReady)="onContentReady()"
          (onCellClick)="onCellClick($event)">
          <div *dxTemplate="let cell of 'transformadoCellTemplate'">
            <div *ngIf="cell.data.transformado">
              <pl-tooltip [config]="{text: 'trDocsCabsWizard.tooltips.documentoTransformado', container: 'body'}">
                <i *plTooltipContent class="fa fa-fw text-danger fa-exclamation-triangle" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </div>

          <div *dxTemplate="let cell of 'toolbarModoAgrupamento'">
            <div style="display: flex; gap: 3px; align-items: center">
              <label><span [translate]="'trDocsCabsWizard.captions.regraTransformacao'"></span>:</label>
              <span>
                <pl-edit
                  inputClass="input-sm input-modo-agrupamento"
                  type="select"
                  attrName="modoAgrupamento"
                  [model]="proceTrDoc.config.modoAgrupamento"
                  (modelChange)="onModoAgrupamentoChange($event)"
                  [properties]="{select: {list: modoAgrupamentoSource}, disallowClear: true}"></pl-edit>
              </span>
            </div>
          </div>

          <div *dxTemplate="let item of 'detailTemplateDocumento'">
            <documento-faturacao [doc]="item.data._fullJsonDocComercial" [actionPdf]="fnGetPdf" [actionGetDoc]="fnGetDoc"></documento-faturacao>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Identificacao" caption="trdoc.modal.linhas.steps.ident" icon="fa-file-text-o" [captionBtnGoForward]="getBtnGoForwardCaption()">
      <div *plNavWizardStepContent>
        <pl-form formInstanceName="formIdentificacao" (formInstanceChange)="formIdentificacao = $event">
          <pl-group>
            <pl-group>
              <label [translate]="'trdocconfig.fields.nDocfaDestino'"></label>
              <edit>
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocfaDestino"
                  [model]="docFaDestino"
                  (modelChange)="onDocFaChanged($event)"
                  [(selectedKey)]="proceTrDoc.config.nDocfaDestino"
                  [properties]="{validators: {required: {value: true}}}"
                  [output]="docfasOutput"
                  [filter]="nDocfaDestinoFilter"
                  outputKey="nDocFa"
                  outputDescription="nome"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'trdocconfig.fields.nNumerDestino'"></label>
              <edit>
                <entity-autocomplete
                  attrName="nNumerDestino"
                  entity="docfasnums"
                  [filter]="docfaNumFilter"
                  [model]="proceTrDoc.config.nNumerDestino"
                  [properties]="{validators: {required: {value: true}}}"
                  [output]="'nNumer'"
                  [outputKey]="'nNumer'"
                  [(selectedKey)]="proceTrDoc.config.nNumerDestino">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-form>

        <div class="d-flex align-items-center" style="gap: 15px">
          <div>
            <label>
              <span [translate]="'trdoc.modal.linhas.label.aposTransformarEncerrarDoc'"></span>
              <pl-tooltip [config]="{text: 'trdocconfig.tooltips.aposTransformarEncerrarDocsFinalizar', placement: ['right', 'bottom'], container: 'body'}">
                <i *plTooltipContent class="fa fa-info-circle text-info ms-1" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
          </div>
          <div>
            <div class="d-flex align-items-center" style="gap: 5px">
              <div>
                <edit>
                  <pl-edit type="checkbox" [(model)]="proceTrDoc.config.encerraDocumentoOrigem" [properties]="{label: 'trdoc.modal.linhas.label.origem'}"></pl-edit>
                </edit>
              </div>
            </div>
          </div>
        </div>

        <ng-container>
          <dx-data-grid
            [cgDxDataGrid]="dataGridIdentificacaoDefinition"
            (onInitialized)="onGridIdentificacaoInitialized($event)"
            (onContentReady)="onGridIdentificacaoContentReady()"
            [dataSource]="dataGridIdentificacaoDefinition.dataSource">
            <div *dxTemplate="let cell of 'editCellTemplateTexto'">
              <div class="d-flex" style="gap: 5px">
                <textarea rows="2" class="form-control form-control-sm" [(ngModel)]="cell.data.referenciaTextoBase"></textarea>
                <div>
                  <div ngbDropdown [container]="'body'">
                    <button type="button" class="btn btn-xs btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                      <i><span [translate]="'trdocconfig.actions.variables'"></span></i>
                    </button>
                    <div ngbDropdownMenu>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.nomeDoc'" (click)="applyVariable('@NomeDoc', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.nDoc'" (click)="applyVariable('@NDoc', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.dtDoc'" (click)="applyVariable('@DtDoc', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.docExt'" (click)="applyVariable('@DocExt', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.processo'" (click)="applyVariable('@Processo', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.contrato'" (click)="applyVariable('@Contrato', cell.data)"></button>
                      <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.obs1'" (click)="applyVariable('@OBS1', cell.data)"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dx-data-grid>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      [stepId]="stepsNavTrDocs.Lotes"
      caption="trdoc.modal.linhas.steps.lotes"
      icon="fas fa-list-ul"
      [visible]="showLotes"
      [captionBtnGoForward]="'trDocsCabsWizard.captions.processar'">
      <div *plNavWizardStepContent>
        <div class="trdocs-cabs-wizard-lotes-navigation">
          <div>
            <pl-button klass="btn-sm btn-warning action-preenche-auto-lotes" (evtClicked)="preencheAutomaticamenteLotes()">
              <i class="fa fa-list-ul"></i>&nbsp;<span [translate]="'trdoc.modal.linhas.btn.preencherAuto'"></span>
            </pl-button>
          </div>
          <div>
            <div class="d-flex align-items-center" style="margin: auto; max-width: max-content">
              <button type="button" [disabled]="lotesHelper.model.currentIndex === 0" (click)="lotesNavFirst()" class="btn btn-link btn-sm">
                <i class="fa fa-fw fa-fast-backward" aria-hidden="true"></i>
              </button>
              <button type="button" [disabled]="lotesHelper.model.currentIndex === 0" (click)="lotesNavPrev()" class="btn btn-link btn-sm">
                <i class="fa fa-fw fa-step-backward" aria-hidden="true"></i>
              </button>
              <div><span [translate]="getLotesNavKeyCaption()"></span>:<b class="ms-2" [translate]="lotesHelper.model.currentKeyValue"></b></div>
              <button type="button" [disabled]="lotesHelper.model.currentIndex === lotesHelper.model.sources.size - 1" (click)="lotesNavNext()" class="btn btn-link btn-sm">
                <i class="fa fa-fw fa-step-forward" aria-hidden="true"></i>
              </button>
              <button type="button" [disabled]="lotesHelper.model.currentIndex === lotesHelper.model.sources.size - 1" (click)="lotesNavLast()" class="btn btn-link btn-sm">
                <i class="fa fa-fw fa-fast-forward" aria-hidden="true"></i>
              </button>
              <small>({{ lotesHelper.model.currentIndex + 1 }} de {{ lotesHelper.model.sources.size }})</small>
            </div>
          </div>

          <div style="width: 146px"></div>
        </div>

        <div class="dataGridLinhasLotes">
          <dx-data-grid
            [cgDxDataGrid]="dataGridLinhasLotesDefinition"
            [dataSource]="dataGridLinhasLotesDefinition.dataSource"
            cgDxDataGridInstanceName="gridProcessamentoLinhasLotes"
            (onInitialized)="onGridLinhasLotesInitialized($event)"
            (onContentReady)="onGridLinhasLotesContentReady()"
            (onCellPrepared)="onCellPreparedDataGridLinhasLotes($event)"
            (onSelectionChanged)="onGridLinhasLotesSelectionChanged($event)">
            <div *dxTemplate="let item of 'masterDetailGridLinhasLotes'">
              <div *ngIf="item.data.error?.length" class="alert alert-sm alert-error mb-0" style="border: 0; font-size: 12px">
                <i class="fa fa-fw fa-times-circle me-2" aria-hidden="true"></i><span [translate]="item.data.error"></span>
              </div>
            </div>
          </dx-data-grid>
        </div>
        <hr />
        <dx-data-grid
          #gridLotes
          [cgDxDataGrid]="dataGridLotesDefinition"
          [dataSource]="dataGridLotesDefinition.dataSource"
          cgDxDataGridInstanceName="gridProcessamentoLotes"
          (onInitialized)="onGridLotesInitialized($event)"
          (onContentReady)="onGridLotesContentReady()"
          (onToolbarPreparing)="onGridLotesToolbarPreparing($event)"
          (onFocusedCellChanged)="onGridLotesFocusedCellChanged($event)">
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      [actions]="stepsNavTrDocsResumoActions"
      [stepId]="stepsNavTrDocs.Resumo"
      caption="trdoc.modal.linhas.steps.resumo"
      icon="fas fa-eye"
      [hidePrevious]="true"
      [hideFinalize]="embed"
      [captionBtnFinalize]="'global.btn.close'">
      <div *plNavWizardStepContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridResumoDefinition"
          cgDxDataGridInstanceName="dataGridResumoTable"
          (onInitialized)="onGridResumoInitialized($event)"
          [dataSource]="dataGridResumoDefinition.dataSource">
          <div *dxTemplate="let item of 'templateBtnToolbar'">
            <div class="d-flex align-items-center" style="gap: 15px">
              <pl-tooltip *ngIf="resumoErrosSource.length" [config]="{text: 'trDocsCabsWizard.tooltips.existemErrosATranformDocs', container: 'body'}">
                <pl-button *plTooltipContent klass="btn-sm btn-danger" [onClick]="fnShowResumoErrosModal">
                  <i class="fa fa-fw fa-times-circle me-1" aria-hidden="true"></i>
                  <span class="me-1" [translate]="'trDocsCabsWizard.captions.existemErrosATranformDocs'" [translateParams]="{count: resumoErrosSource.length}"></span>
                </pl-button>
              </pl-tooltip>
            </div>
          </div>

          <div *dxTemplate="let item of 'cellTemplateActions'">
            <ng-container *ngIf="!item.data.cab.terminado">
              <dx-button
                class="dx-button-icon-fontawesome"
                [hint]="'trDocsCabsWizard.tooltips.editarDocumento' | translate"
                (onClick)="editarDocumento(item.data)"
                icon="fa fa-fw fa-pencil"
                [stylingMode]="'text'"></dx-button>
              <dx-button
                class="dx-button-icon-fontawesome"
                [hint]="'trDocsCabsWizard.tooltips.finalizarDocumento' | translate"
                (click)="encerrarDocumento(item.data.cab.faccbId)"
                icon="fa fa-fw fa-check"
                [stylingMode]="'text'"></dx-button>
            </ng-container>
          </div>

          <div *dxTemplate="let item of 'detailTemplateDocumento'">
            <documento-faturacao [doc]="item.data" [actionPdf]="fnGetPdf" [actionGetDoc]="fnGetDoc"></documento-faturacao>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
