import {EMPTY, from, isObservable, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, mergeMap, takeWhile} from 'rxjs/operators';
import {IActionQueueParams, TActionQueueFn, TActionQueueResult} from './action.queue.interface';
import {ILogger} from '../../../logger/logger.interface';
import {isFunction, isPromiseLike, isUndefinedOrNull} from '../../utilities/utilities';

/**
 * {@link ActionQueue} will be executed following the "First In First Out" (FIFO) principle.
 *
 * You **MUST** subscribe to {@link results} observable before {@link queueAction queueing an action} or you will miss
 * at least the first result.
 *
 * You **MUST** call {@link dispose} after you've finished work with {@link ActionQueue} to
 * clean up used resources.
 *
 * @typeParam T - Actions result type
 */
export class ActionQueue<T = unknown> {
  private _disposed: boolean;
  private _queue: Array<[Observable<T>, IActionQueueParams]>;
  private _subjectResults: Subject<T>;
  private _executing: boolean;
  private _results: Observable<T>;
  private _subscriptionExecution: Subscription;
  private _promiseLoading: Promise<void>;
  private _promiseLoadingResolveFn: () => void;
  private _promiseLoadingRejectFn: () => void;

  constructor(private readonly _logger: ILogger) {
    this._disposed = false;
    this._executing = false;
  }

  public queueAction<TValue extends T, TState = unknown>(action: TActionQueueFn<TValue>, params?: IActionQueueParams<TState>): void {
    if (this._disposed) {
      throw new Error('Tried to queue action after ActionQueue has already been disposed');
    }
    if (!isFunction(action)) {
      throw new TypeError(`Expected \`action\` argumento to be a Function but received: ${typeof action}`);
    }
    const result: TActionQueueResult<T> = action();
    if (isUndefinedOrNull(result)) {
      return;
    }
    this.queueResult(result, params);
  }

  public queueResult<TValue extends T, TState = unknown>(result: TActionQueueResult<TValue>, params?: IActionQueueParams<TState>): void {
    if (this._disposed) {
      throw new Error('Tried to queue action after ActionQueue has already been disposed');
    }
    if (isUndefinedOrNull(result)) {
      return;
    }
    if (!isObservable(result)) {
      if (isPromiseLike(result)) {
        result = from(result);
      } else {
        result = of(result);
      }
    }
    if (!this._queue) {
      this._queue = [];
    }
    this._queue.push([result, params]);
    if (!this._subscriptionExecution || this._subscriptionExecution.closed) {
      this._executing = true;
      this._promiseLoading = new Promise<void>((resolve, reject) => {
        this._promiseLoadingResolveFn = resolve;
        this._promiseLoadingRejectFn = reject;
      });
      this._subscriptionExecution = this._flush().subscribe({
        complete: () => {
          this._executing = false;
          this._subscriptionExecution = undefined;
          this._promiseLoadingResolveFn();
          this._promiseLoadingResolveFn = undefined;
          this._promiseLoadingRejectFn = undefined;
        }
      });
    }
  }

  public executionPromise(): Promise<void> {
    return this._promiseLoading && !this._disposed ? this._promiseLoading : Promise.resolve();
  }

  public executing(): boolean {
    return this._executing;
  }

  public pendingExecutions(): number {
    if (this._disposed) {
      return -1;
    }
    return this._queue.length;
  }

  public cancelExecution(): void {
    if (this._executing) {
      this._executing = false;
      this._queue.length = 0;
      if (this._subscriptionExecution) {
        this._subscriptionExecution.unsubscribe();
        this._subscriptionExecution = undefined;
      }
      if (this._promiseLoadingResolveFn) {
        this._promiseLoadingResolveFn();
      }
    }
  }

  public dispose(): void {
    if (this._disposed) {
      return;
    }
    this._disposed = true;
    this.cancelExecution();
    if (this._promiseLoadingRejectFn) {
      this._promiseLoadingRejectFn();
    }
  }

  public get results(): Observable<T> {
    if (!this._results) {
      if (!this._subjectResults) {
        this._subjectResults = new Subject<T>();
      }
      this._results = this._subjectResults.asObservable();
    }
    return this._results;
  }

  private _flush(): Observable<unknown> {
    if (!this._executing) {
      return EMPTY;
    }
    const value: [Observable<T>, IActionQueueParams] = this._queue.shift();
    if (!value) {
      return EMPTY;
    }
    const [observable, params]: [Observable<T>, IActionQueueParams] = value;
    return observable
      .pipe(takeWhile(() => !this._disposed))
      .pipe(
        mergeMap((result: T) => {
          if (!this._executing) {
            return EMPTY;
          }
          if (isFunction(params?.next)) {
            try {
              params.next(result, params.state);
            } catch (error: unknown) {
              this._logger.error(error);
            }
          }
          if (this._subjectResults) {
            this._subjectResults.next(result);
          }
          return this._flush();
        })
      )
      .pipe(
        catchError((reason: unknown) => {
          if (!this._executing) {
            return EMPTY;
          }
          if (isFunction(params?.error)) {
            try {
              params.error(reason, params.state);
            } catch (error: unknown) {
              this._logger.error(error);
            }
          } else {
            this._logger.error(reason);
          }
          return this._flush();
        })
      );
  }
}
