import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IConfigChanged} from '../../../../components/configoptions/config.options.component.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {EGroupName} from '../../../../../config/constants';
import moment from 'moment';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IContratosListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {ContratosService} from '../../contratos.entity.service';
import {IContratoCabWithData, IJsonContrato, IJsonContratoCab} from '../../jsonContrato.entity.interface';
import {ENTITY_NAME_CONTRATOS} from '../../contrato.entity.interface';

@Component({
  selector: 'contratos-list',
  templateUrl: './contratos.entity.list.component.html'
})
export class ContratosListComponent extends ModuloEntityListComponent<IJsonContratoCab> implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public dataGridProperties: IDevExpressDataGrid;

  private readonly _filterAtivos: string;
  private _optionShowNaoAtivosOnly: boolean;
  private _subscriptionConfigOptions: Subscription;
  private _searchAux: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contratosService: ContratosService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.dataGridProperties = {allowColumnResizing: true};
    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONTRATOS_LIST;
    this.configOptionsGroupName = EGroupName.ERP;
    const dataFim = moment().subtract(1, 'days');
    this._filterAtivos = `terminado!=1&&dataFim>${dataFim.toJSON()}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._searchAux = this.searchValue;
    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IContratosListConfigOptions>) => {
        this._optionShowNaoAtivosOnly = configOptions.get('showNaoAtivosOnly').value;
        if (this._optionShowNaoAtivosOnly) {
          this.searchValue = `${this._searchAux}&${this._filterAtivos}`;
        }
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<IContratoCabWithData>): Promise<void> {
    const {data} = event;
    return this.service.get<IJsonContrato>({id: data.refCtrCab}).then((response: HttpResponse<IJsonContrato>) => {
      data._contrato = response.body;
      return super.onDetail(event);
    });
  }

  public onConfigChange(value: IConfigChanged): Promise<void> {
    this.searchValue = '';
    if (value.value) {
      this.searchValue = `${this._searchAux}&${this._filterAtivos}`;
    } else {
      this.searchValue = this._searchAux;
    }
    return this.refreshList();
  }

  public onRevertConfigOptionsToDefault(): Promise<void> {
    this.searchValue = '';
    if (!this._optionShowNaoAtivosOnly) {
      this.searchValue = this._searchAux;
    } else {
      this.searchValue = `${this._searchAux}&${this._filterAtivos}`;
    }
    return this.refreshList();
  }

  public onSearchValueChange(searchValue: string): Promise<void> {
    this.searchValue = '';
    if (this._optionShowNaoAtivosOnly) {
      this.searchValue = `${searchValue}&${this._filterAtivos}`;
    } else {
      this.searchValue = searchValue;
      this._searchAux = this.searchValue;
    }
    return this.refreshList();
  }

  public readonly fnEditContrato = (contrato: IJsonContrato): Promise<void> => this._editContrato(contrato);

  public readonly fnReabrirContrato = (contrato: IJsonContrato): Promise<IJsonContrato> => this._reabrirContrato(contrato);

  public readonly fnTerminarContrato = (contrato: IJsonContrato): Promise<IJsonContrato> => this._terminarContrato(contrato);

  public readonly fnApagarContrato = (contrato: IJsonContrato): Promise<void> => this._apagarContrato(contrato);

  private _editContrato(contrato: IJsonContrato): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_CONTRATOS, stateType: EEntityStateDetailType.EDIT, params: {id: contrato.cab.refCtrCab}});
  }

  private _reabrirContrato(contrato: IJsonContrato): Promise<IJsonContrato> {
    return this._contratosService.reabrirContrato(contrato).then((result) => {
      this.refreshList();
      return result;
    });
  }

  private _terminarContrato(contrato: IJsonContrato): Promise<IJsonContrato> {
    return this._contratosService.terminarContrato(contrato).then((result) => {
      this.refreshList();
      return result;
    });
  }

  private _apagarContrato(contrato: IJsonContrato): Promise<void> {
    return this._contratosService.apagarContrato(contrato.cab.refCtrCab).then((result) => {
      this.refreshList();
      return result;
    });
  }
}
