import {ENTITY_NAME_CCUSTOS} from './cCustos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCCusto} from './jsonCCusto.entity.interface';
import {ROLE} from '../../services/role.const';
import {CCustosNewComponent} from './components/new/cCustos.entity.new.component';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ENTITY_NAME_MASCARAS_ANALITICA, IMascaraAnalitica} from '../mascarasanalitica/mascaraAnalitica.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonManal} from '../mascarasanalitica/jsonMascaraAnalitica.entity.interface';
import {IApiQueryResponse} from '../../services/api/api.service.interface';

export const ENTITY_CCUSTOS: IEntityDefinition<IJsonCCusto> = {
  name: ENTITY_NAME_CCUSTOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'ccustos.pesquisa',
  pageTitle: 'global.menu.ccustos',
  metadata: {
    keyName: 'nCCusto',
    fields: [
      {name: 'nCCusto', width: '120px', caption: 'ccustos.fields.nCCusto', placeholder: 'ccustos.fields.nCCustoPlaceholder', validators: {required: true, maxlength: 10}},
      {name: 'nome', caption: 'ccustos.fields.nome', placeholder: 'ccustos.fields.nomePlaceholder', validators: {required: true, maxlength: 50}},
      {name: 'encerrado', type: 'boolean', caption: 'ccustos.fields.encerrado', properties: {trueValue: -1, falseValue: 0}}
    ],
    order: 'nome',
    searchFields: 'nCCusto,nome,encerrado'
  },
  autocomplete: {
    rowTemplate: '{{nCCusto}} - {{nome}}',
    output: 'nome',
    outputKey: 'nCCusto',
    outputDescription: 'nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  new: {
    state: {
      component: CCustosNewComponent,
      resolve: [
        {
          provide: 'mascaraCC',
          deps: [EntityServiceBuilder],
          useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<string> => {
            return entityServiceBuilder
              .build<IMascaraAnalitica>(ENTITY_NAME_MASCARAS_ANALITICA)
              .query({pagina: 1, porpagina: 1})
              .then((response: HttpResponse<IApiQueryResponse<IJsonManal>>) => {
                if (response.body.total === 1) {
                  return response.body.list[0].mascara;
                }
                return '';
              });
          }
        }
      ]
    }
  }
};
