<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-select">
  <ng-select
    [items]="source"
    [placeholder]="placeholder || options.placeholder"
    [disabled]="readonly || options.readonly || disabled || options.disabled"
    [bindLabel]="labelField || options.labelField"
    [bindValue]="valueField || options.valueField"
    [multiple]="multiple || options.multiple"
    [groupBy]="groupBy || options.groupBy"
    [searchFn]="searchFn || options.searchFn"
    [clearable]="clearable || options.clearable"
    [dropdownPosition]="dropdownPosition || options.dropdownPosition"
    [maxSelectedItems]="maxSelectedItems || options.maxSelectedItems"
    [searchable]="searchable || options.searchable"
    [clearAllText]="clearAllText || options.clearAllText"
    [loadingText]="loadingText || options.loadingText"
    [notFoundText]="notFoundText || options.notFoundText"
    [hideSelected]="hideSelected || options.hideSelected"
    [selectableGroup]="selectableGroup || options.selectableGroup"
    [closeOnSelect]="closeOnSelect || options.closeOnSelect"
    [openOnEnter]="openOnEnter || options.openOnEnter"
    [appendTo]="appendTo || options.appendTo"
    [selectableGroupAsModel]="selectableGroupAsModel || options.selectableGroupAsModel"
    [(ngModel)]="value"
    (change)="render($event)">
    <ng-template let-item="item" let-item$="item$" let-searchTerm="searchTerm" ng-option-tmp>
      <div>
        <input *ngIf="multipleCheckbox" type="checkbox" autocapitalize="off" autocomplete="off" [ngModel]="item$.selected" />
        <span [innerHTML]="getRow(item) | cgcHighlight: searchTerm" [title]="getRow(item)"></span>
      </div>
    </ng-template>

    <ng-container *ngIf="groupBy">
      <ng-template let-item="item" let-item$="item$" ng-optgroup-tmp>
        <div>
          <input *ngIf="selectableGroup" type="checkbox" autocapitalize="off" autocomplete="off" [ngModel]="item$.selected" />
          <span [innerHTML]="getGroupByItem(item)"></span>
        </div>
      </ng-template>
    </ng-container>
  </ng-select>
</div>
