import {ApiService} from '../api/api.service';
import {
  IJsonContabDigitalGDocViewerRecolhaFolder,
  IJsonContabDigitalGDocViewerRecolhaFolderAttachments,
  IJsonContabDigitalGDocViewerRecolhaSearch,
  IJsonContabDigitalGDocViewerRecolhaSearchResult,
  IJsonDocOCR
} from './jsonContabDigital.interface';
import {IContabDigitalGDocViewerRecolhaSearchParams} from './contabilidadedigital.interface';
import {HttpParams} from '@angular/common/http';
import {isObject} from 'pl-comps-angular';
import {TServiceResponse} from '../api/api.service.interface';

export class ContabilidadeDigitalServiceGDocViewerRecolha {
  private readonly _path: string;

  constructor(
    private readonly _apiService: ApiService,
    basePath: string
  ) {
    this._path = `${basePath}/gdocviewer`;
  }

  public folder(folderId: string): TServiceResponse<IJsonContabDigitalGDocViewerRecolhaFolder> {
    return this._apiService.get<IJsonContabDigitalGDocViewerRecolhaFolder>({url: `${this._path}/${folderId}`});
  }

  public folderAttachments(folderId: string): TServiceResponse<IJsonContabDigitalGDocViewerRecolhaFolderAttachments> {
    return this._apiService.get<IJsonContabDigitalGDocViewerRecolhaFolderAttachments>({url: `${this._path}/${folderId}/attachments`});
  }

  public search(search: IJsonContabDigitalGDocViewerRecolhaSearch, searchParams?: IContabDigitalGDocViewerRecolhaSearchParams): TServiceResponse<IJsonContabDigitalGDocViewerRecolhaSearchResult> {
    let params: HttpParams;
    if (isObject(searchParams)) {
      params = new HttpParams();
      if (searchParams.byDataOrValor) {
        params = params.set('byDataOrValor', true);
      }
      if (searchParams.byDataValor) {
        params = params.set('byDataValor', true);
      }
      if (searchParams.byValor) {
        params = params.set('byValor', true);
      }
    }
    return this._apiService.post<IJsonContabDigitalGDocViewerRecolhaSearchResult, IJsonContabDigitalGDocViewerRecolhaSearch>({
      url: `${this._path}/search`,
      body: search,
      params: params
    });
  }

  public getAttachment(docId: string): TServiceResponse<IJsonDocOCR> {
    return this._apiService.get<IJsonDocOCR>({url: `${this._path}/attachments/${docId}`});
  }
}
