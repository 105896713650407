import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {TDate} from '../../dates';

@Pipe({
  name: 'cgDateFromNow'
})
export class CGDateFromNowPipe implements PipeTransform {
  public transform(value: TDate, withoutSuffix?: boolean): string {
    return moment(value).fromNow(withoutSuffix);
  }
}
