import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_PASSWORD_RECIBO, ETipoPasswordRecibo} from './tipoPasswordRecibo.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_PASSWORD_RECIBO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_PASSWORD_RECIBO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoPasswordRecibo.Nao, name: 'tipoPasswordRecibo.data.nao'},
    {value: ETipoPasswordRecibo.IgualNIF, name: 'tipoPasswordRecibo.data.igualNIF'},
    {value: ETipoPasswordRecibo.IgualNumeroCC, name: 'tipoPasswordRecibo.data.igualNumeroCC'},
    {value: ETipoPasswordRecibo.IgualNumeroSS, name: 'tipoPasswordRecibo.data.igualNumeroSS'},
    {value: ETipoPasswordRecibo.IgualCodigoFuncionario, name: 'tipoPasswordRecibo.data.igualCodigoFuncionario'}
  ]
});
