import {Component, Injector} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'module-colaboradores-configuracoes-eventos',
  templateUrl: './configuracoesEventos.module.component.html'
})
export class ConfiguracoesEventosModuleComponent extends ModuloComponent {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
