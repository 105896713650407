<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-table" [ngClass]="{loading: isLoading}">
  <div class="table-responsive">
    <pl-spinner></pl-spinner>

    <ng-container [ngSwitch]="disableVirtualScroll">
      <pl-virtual-scrolling
        *ngSwitchCase="false"
        [items]="bodyValues"
        [rowHeight]="virtualScroll?.rowHeight"
        [minBufferPx]="virtualScroll?.minBufferPx"
        [maxBufferPx]="virtualScroll?.maxBufferPx"
        [scaleOffset]="virtualScroll?.scaleOffset"
        [disabled]="virtualScroll?.disabled"
        [callback]="virtualScroll?.callback">
        <div *plVirtualScrollingContent="let dataList = items">
          <ng-container *ngTemplateOutlet="templateTable; context: {items: dataList}"></ng-container>
        </div>
      </pl-virtual-scrolling>

      <div *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="templateTable; context: {items: bodyValues}"></ng-container>
      </div>
    </ng-container>
  </div>

  <pl-pagination
    *ngIf="options.hidePagination !== true"
    class="pl-table-pagination"
    [instanceId]="instanceId"
    [page]="page"
    [perPage]="perPage"
    [total]="data.total"
    [boundaryLinks]="options.boundaryLinks"
    [directionLinks]="options.directionLinks"
    [lines]="options.lines"
    [paginationRange]="options.paginationRange"
    [showPagination]="options.showPagination"
    [useUrl]="options.useUrl"
    (evtPaginationChanged)="changedPagination($event)">
  </pl-pagination>
</div>

<ng-template #templateTable let-dataList="items">
  <table [ngClass]="[tableClass, options.border ? 'table-bordered' : '', theme]">
    <thead *ngIf="!options.hideHeader">
      <tr>
        <th *ngIf="hasDetail && !options.hideDetail" class="action-detail"></th>

        <th *ngIf="hasActions && actionsAlign !== 'right' && options.hideActions !== true" class="actions" nowrap>
          <ng-container *ngTemplateOutlet="templateHeaderActions?.templateRef; context: {tableData: data}"></ng-container>
        </th>

        <ng-container *ngFor="let field of evaluatedFields">
          <ng-container *ngIf="field.visible !== false">
            <th class="table-header-item" [ngClass]="field._thClass" [attr.width]="field.width ? field.width : 'auto'" [ngSwitch]="field._canSort">
              <a *ngSwitchDefault rel="noopener noreferrer" (click)="sort(field)">
                <span>{{ field._caption }}</span
                >&nbsp;
                <i class="fa" [class.fa-sort-alpha-asc]="field._isOrderAsc" [class.fa-sort-alpha-desc]="field._isOrderDesc"></i>
              </a>
              <span *ngSwitchCase="false">{{ field._caption }}</span>
            </th>
          </ng-container>
        </ng-container>

        <th *ngIf="hasActions && actionsAlign === 'right' && options.hideActions !== true" class="actions" nowrap>
          <ng-container *ngTemplateOutlet="templateHeaderActions?.templateRef; context: {tableData: data}"></ng-container>
        </th>
      </tr>
    </thead>

    <tfoot *ngIf="footerValues.length" class="pl-table-footer">
      <tr *ngFor="let row of footerValues" [ngClass]="row._rowClass">
        <td *ngIf="hasDetail && !options.hideDetail" class="action-detail"></td>

        <td *ngIf="hasActions && actionsAlign !== 'right' && options.hideActions !== true" class="actions" nowrap></td>

        <ng-container *ngFor="let field of evaluatedFields; let index = index">
          <ng-container *ngIf="field.visible !== false">
            <td [ngClass]="row._values.get(field.name).tdClass" [attr.colspan]="field.colspan" [innerHTML]="row._values.get(field.name).value"></td>
          </ng-container>
        </ng-container>

        <td *ngIf="hasActions && actionsAlign === 'right' && options.hideActions !== true" class="actions" nowrap></td>
      </tr>
    </tfoot>

    <ng-container *ngIf="data.total">
      <tbody *plVirtualFor="let item of dataList; trackBy: trackByFn; index as itemIndex">
        <tr [ngClass]="item._rowClass" [plEventsListener]="definition.rowsEvents">
          <td
            *ngIf="hasDetail && !options.hideDetail"
            class="action-detail"
            [class.has-detail]="item._hasDetail !== false && !item.empty"
            (mousedown)="onDetailMouseDown($event)"
            (mouseup)="onDetailMouseUp(item, itemIndex, undefined, -1, item._hasDetail === false, $event)">
            <div>
              <i *ngIf="!item.empty && item._hasDetail !== false" class="fa fa-angle-right" aria-hidden="true" [plAnimationRotate]="item._detailOpen"></i>
              <div *ngIf="item._promise" class="pl-loading-loader">
                <div class="circle-spinner">
                  <div class="spinner"></div>
                </div>
              </div>
            </div>
          </td>

          <td *ngIf="!item.empty && hasActions && actionsAlign !== 'right' && options.hideActions !== true" class="actions" nowrap>
            <div>
              <ng-container *ngTemplateOutlet="templateItemActions?.templateRef; context: {$implicit: item, rowItem: item, index: itemIndex, tableData: data}"></ng-container>
            </div>
          </td>

          <ng-container *ngFor="let field of evaluatedFields; index as columnIndex">
            <ng-container *ngIf="field.visible !== false">
              <td
                #fieldValue
                plEventsListenerIgnore="mousedown,mouseup"
                [ngClass]="item._values.get(field.name).tdClass"
                [class.pl-table-onselect]="!item.empty"
                [attr.colspan]="field.colspan"
                [attr.width]="field.width ? field.width : 'auto'"
                [attr.title]="!field.type || field.type === 'text' || field.type === 'string' ? fieldValue.textContent : ''"
                [plEventsListener]="field.events || definition.columnsEvents"
                [ngSwitch]="item.empty"
                (mousedown)="onColumnMouseDown(field, $event)"
                (mouseup)="onColumnMouseUp(item, itemIndex, field, columnIndex, false, $event)"
                (contextmenu)="onColumnContextMenu($event)">
                <div *ngSwitchDefault class="pl-table-field-value" [ngClass]="item._values.get(field.name).valueClass" [innerHTML]="item._values.get(field.name).value"></div>
                <div *ngSwitchCase="true" class="pl-table-field-value">&nbsp;</div>
              </td>
            </ng-container>
          </ng-container>

          <td *ngIf="!item.empty && hasActions && actionsAlign === 'right' && options.hideActions !== true" class="actions" nowrap>
            <div>
              <ng-container *ngTemplateOutlet="templateItemActions?.templateRef; context: {$implicit: item, rowItem: item, index: itemIndex, tableData: data}"></ng-container>
            </div>
          </td>
        </tr>

        <tr class="detail">
          <td class="detail" [attr.colspan]="evaluatedFields.length + 2">
            <div *ngIf="hasDetail && item._hasDetail !== false && item._detailOpen" class="detail">
              <ng-container *ngIf="item._detailOpen">
                <ng-container *ngTemplateOutlet="templateItemDetail?.templateRef; context: {$implicit: item, rowItem: item, index: itemIndex, tableData: data}"></ng-container>
              </ng-container>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-container>

    <tbody *ngIf="!data.total && options.noDataPlaceholder && options.noDataPlaceholderMessage">
      <tr class="pl-table-nodata-placeholder-tr">
        <td class="pl-table-nodata-placeholder" colspan="9999" [plTranslate]="options.noDataPlaceholderMessage"></td>
      </tr>
    </tbody>
  </table>
</ng-template>
