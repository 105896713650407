import {Injectable} from '@angular/core';
import {IJsonBoolean} from '../../../common/interfaces/json';
import {ApiService} from '../../services/api/api.service';
import {TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonExportMaskNameFilesType, IJsonExportMaskNameFilesTypeField, IJsonMascaraNomeFicheirosExportConfig} from './jsonDocsComerciaisConfigNomeFileExport.module.interface';

@Injectable({
  providedIn: 'root'
})
export class DocsComerciaisConfigNomeFileExportService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/mascarasnomeficheirosexportconfig`;
  }

  public getConfiguracoes(): TServiceQueryResponse<IJsonMascaraNomeFicheirosExportConfig> {
    return this._apiService.query<IJsonMascaraNomeFicheirosExportConfig>({url: this._path});
  }

  public get(key: string): TServiceResponse<IJsonMascaraNomeFicheirosExportConfig> {
    return this._apiService.get<IJsonMascaraNomeFicheirosExportConfig>({
      url: `${this._path}/${key}`
    });
  }

  public save(config: IJsonMascaraNomeFicheirosExportConfig): TServiceResponse<IJsonMascaraNomeFicheirosExportConfig> {
    return this._apiService.post<IJsonMascaraNomeFicheirosExportConfig>({url: this._path, body: config});
  }

  public update(key: string, config: IJsonMascaraNomeFicheirosExportConfig): TServiceResponse<IJsonMascaraNomeFicheirosExportConfig> {
    return this._apiService.put<IJsonMascaraNomeFicheirosExportConfig>({
      url: `${this._path}/${key}`,
      body: config
    });
  }

  public delete(key: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/${key}`});
  }

  public getTiposConfiguracao(): TServiceQueryResponse<IJsonExportMaskNameFilesType> {
    return this._apiService.query<IJsonExportMaskNameFilesType>({url: `${this._path}/tiposconfiguracao`});
  }

  public getTiposCampos(tipo: number): TServiceQueryResponse<IJsonExportMaskNameFilesTypeField> {
    return this._apiService.query<IJsonExportMaskNameFilesTypeField>({url: `${this._path}/tiposcampos/${tipo}`});
  }

  public getCaracteresValidos(caracteres: string): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({
      url: `${this._path}/caracteresvalidos`,
      params: {
        caracteres: caracteres
      }
    });
  }
}
