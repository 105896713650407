import {firstValueFrom, Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {EDelphiNumberTypes} from 'pl-comps-angular';
import {ConfigOptionsService} from '../../../services/config/options/config.options.service';
import {ContratosService} from '../../../entities/contratos/contratos.entity.service';
import {EConfigOptionsInstanceName, IContratosPrintConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {ENTITY_NAME_CLIFOS} from '../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_CONTRATOS_TIPOS} from '../../../entities/contratostipos/contratosTipos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonClasseContratoSelect} from '../../../entities/classescontratos/jsonClasseContrato.entity.interface';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonContratoTipo, IJsonContratoTipoSelect} from '../../../entities/contratostipos/jsonContratoTipo.entity.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {IListagemContratosFilters} from '../../../entities/contratos/contrato.entity.interface';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../common/utils/utils';
import {MAX_STRING} from '../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'module-contratos-print',
  templateUrl: './contratos.print.module.component.html'
})
export class ContratosPrintModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public defaultReport: IJsonReport;
  @Input() public classesContratos: Array<IJsonClasseContratoSelect>;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public report: IJsonReport;
  public filters: IListagemContratosFilters;
  public contratoTipoSource: Array<IJsonContratoTipoSelect>;
  public contratoTipoList: Array<string>;
  public classesCtrList: Array<string>;
  public pdfUrl: string;
  public optionShowClasses: boolean;

  private readonly _serviceContratoTipo: IEntityService<IJsonContratoTipo>;
  private readonly _serviceClifos: IEntityService<IJsonClifo>;
  private readonly _subscriptionConfigOptions: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contratosService: ContratosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);

    this._serviceContratoTipo = this._entityServiceBuilder.build(ENTITY_NAME_CONTRATOS_TIPOS);
    this._serviceClifos = this._entityServiceBuilder.build(ENTITY_NAME_CLIFOS);

    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONTRATOS_PRINT;
    this.configOptionsGroupName = EGroupName.ERP;
    this.optionShowClasses = false;

    this.pdfUrl = '';
    this.contratoTipoSource = [];
    this.classesContratos = [];

    this.filters = {
      declifo: '',
      ateclifo: MAX_STRING,
      dencontrato: 0,
      atencontrato: EDelphiNumberTypes.MaxSmallInt,
      classes: [],
      tipos: [],
      dtdeini: MIN_DATE_CG,
      dtateini: MAX_DATE_CG,
      dtdefim: MIN_DATE_CG,
      dtatefim: MAX_DATE_CG,
      dtdedatactr: MIN_DATE_CG,
      dtatedatactr: MAX_DATE_CG,
      reportname: ''
    };

    this.report = {
      name: '',
      title: ''
    };

    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IContratosPrintConfigOptions>) => {
        this.optionShowClasses = configOptions.get('showClasses').value;
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: 'previsualizar',
      type: 'button',
      order: 1,
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-success',
      caption: 'avisoscobranca.btn.previsualizar',
      align: 'left',
      click: () => this._preview()
    });

    if (this.defaultReport) {
      this.report = this.defaultReport;
      this.filters.reportname = this.report.name;
    }

    if (this.classesContratos.length) {
      for (const item of this.classesContratos) {
        item.selected = true;
      }
    }

    this.onClasseSelectChange();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this._subscriptionConfigOptions.unsubscribe();
  }

  public onReportChange(report: IJsonReport): void {
    this.filters.reportname = report.name;
  }

  public onClasseSelectChange(): void {
    let classeQueryStr = '';
    for (const classe of this.classesContratos) {
      if (classe.selected) {
        if (classeQueryStr) {
          classeQueryStr += `||nClasseCtr=${classe.nClasseCtr}`;
        } else {
          classeQueryStr += `nClasseCtr=${classe.nClasseCtr}`;
        }
      }
    }
    if (classeQueryStr) {
      classeQueryStr += '&classeTipo=0';
      this._serviceContratoTipo.query({pesquisa: classeQueryStr}).then((response) => {
        this.contratoTipoSource = response.body.list;
        for (const item of this.contratoTipoSource) {
          item.selected = true;
        }
      });
    } else {
      this.contratoTipoSource = [];
    }
  }

  public onDeClifoChanged(value: string): void {
    this.filters.declifo = value;
    this._serviceClifos.get({id: value, reportExceptions: false}).then(() => {
      this.filters.ateclifo = value;
    });
  }

  private _preview(): Promise<void> {
    this.contratoTipoList = [];
    this.classesCtrList = [];
    for (const item of this.contratoTipoSource) {
      if (item.selected) {
        this.contratoTipoList.push(item.nTipoContrato);
      }
    }
    for (const item of this.classesContratos) {
      if (item.selected) {
        this.classesCtrList.push(item.nClasseCtr);
      }
    }
    this.filters.tipos = this.contratoTipoList;
    this.filters.classes = this.classesCtrList;
    return firstValueFrom(this._contratosService.getContratosPDFUrl(this.filters)).then((url: string) => {
      this.pdfUrl = url;
    });
  }
}
