import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EPagamentoTipoDistribuicao, IPagamentoDistribuirValor} from '../../pagamentos.entity.interface';
import {focusElement} from '../../../../../common/utils/element.utils';

@Component({
  selector: 'pagamentos-distribuivalor-modal',
  templateUrl: './pagamentos.distribuiValor.modal.component.html'
})
export class PagamentosDistribuiValorModalComponent extends CGModalComponent<IPagamentoDistribuirValor> {
  public readonly tipoDistribuicao: typeof EPagamentoTipoDistribuicao;

  public model: IPagamentoDistribuirValor;

  constructor(protected readonly _injector: Injector) {
    super(_injector);

    this.tipoDistribuicao = EPagamentoTipoDistribuicao;
    this.model = {
      valor: 0,
      tipoDistribuicao: EPagamentoTipoDistribuicao.Automatico
    };
  }

  public close(): void {
    super.close(this.model);
  }

  public tipoDistribuicaoChanged(tipo: EPagamentoTipoDistribuicao): void {
    this.model.tipoDistribuicao = tipo;
    focusElement(this._element.querySelector<HTMLElement>('input[name="valorpagamento"]'));
  }
}
