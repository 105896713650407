import {ContabilidadeBalancetesComponent} from './components/contabilidade.balancetes.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES, MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS} from './contabilidade.balancetes.module.interface';

export const MODULE_PCA_ESTATISTICA_BALANCETES: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_PCA_ESTATISTICA_BALANCETES,
  altMode: false,
  sidebarTitle: 'portals.sidebar.modules.balancetes'
});
export const MODULE_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS,
  altMode: true,
  sidebarTitle: 'portals.sidebar.modules.balancetesalternativos'
});

function generateModule({moduleName, altMode, sidebarTitle}: {moduleName: string; altMode: boolean; sidebarTitle: string}): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleName}`,
      component: ContabilidadeBalancetesComponent,
      data: {
        roles: [ROLE.CONTABILIDADE],
        pluginsRoles: altMode ? [ROLE.CONTABILIDADEPREMIUM] : [],
        sidebarTitle: sidebarTitle
      },
      params: {
        dePeriodo: {
          type: 'string',
          value: ''
        },
        periodoAte: {
          type: 'string',
          value: ''
        },
        deConta: {
          type: 'string',
          value: ''
        },
        contaAte: {
          type: 'string',
          value: ''
        },
        fromModelo22: {
          type: 'bool',
          value: false
        },
        date: {
          type: 'string',
          value: ''
        }
      },
      resolve: [{provide: 'altMode', useFactory: () => altMode}]
    }
  };
}
