import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonModelo22AnexoA} from '../../../../jsonModelo22.module.interface';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-modelo22-anexoa',
  templateUrl: './modelo22.anexoA.modal.component.html'
})
export class Modelo22AnexoAModalComponent extends CGModalComponent<IJsonModelo22AnexoA> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoA: IJsonModelo22AnexoA;

  public distrito: string;
  public taxaDerama: number;
  public massaSalarial: number;
  public racio: number;
  public areaInstalacao: number;
  public potencia: number;
  public totalProduzido: number;
  public producao: number;
  public derrama: number;
  public primeiroAno: boolean;

  public isA: boolean;
  public isB: boolean;
  public isC: boolean;
  public isD: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.distrito = '';
    this.taxaDerama = 0;
    this.massaSalarial = 0;
    this.racio = 0;
    this.areaInstalacao = 0;
    this.potencia = 0;
    this.totalProduzido = 0;
    this.producao = 0;
    this.derrama = 0;
    this.primeiroAno = false;
  }

  public ngOnInit(): void {
    this.isA = this.newLineFor === 'A';
    this.isB = this.newLineFor === 'B';
    this.isC = this.newLineFor === 'C';
    this.isD = this.newLineFor === 'D';
  }

  public close(): void {
    if (this.isA) {
      this.anexoA.quadro04.listaA.push({
        distrito: this.distrito,
        taxaDerama: this.taxaDerama,
        massaSalarial: this.massaSalarial,
        racioReparticao: this.racio,
        derramaCalculada: this.derrama
      });
    }
    if (this.isB) {
      this.anexoA.quadro04.listaB.push({
        distrito: this.distrito,
        taxaDerama: this.taxaDerama,
        massaSalarial: this.massaSalarial,
        racioReparticao: this.racio,
        derramaCalculada: this.derrama
      });
    }
    if (this.isC) {
      this.anexoA.quadro04.listaC.push({
        distrito: this.distrito,
        taxaDerama: this.taxaDerama,
        massaSalarial: this.massaSalarial,
        primeiroAno: this.primeiroAno,
        areaInstalacao: this.areaInstalacao,
        potencia: this.potencia,
        totalProduzido: this.totalProduzido,
        racioMunicipio: this.racio,
        derramaCalculada: this.derrama
      });
    }
    if (this.isD) {
      this.anexoA.quadro04.listaD.push({
        distrito: this.distrito,
        taxaDerama: this.taxaDerama,
        massaSalarial: this.massaSalarial,
        primeiroAno: this.primeiroAno,
        areaInstalacao: this.areaInstalacao,
        racioMunicipio: this.racio,
        derramaCalculada: this.derrama,
        producao: this.producao
      });
    }
    super.close(this.anexoA);
  }
}
