<div class="ativosmapasoficiais entity-detail-form">
  <cg-card-panel>
    <div *cgCardPanelContent>
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosmapasoficiais.header.nMapa'"></label>
            <edit>
              <pl-edit type="mapasOficiais" [model]="header.nMapa" (modelChange)="changedMapa($event)"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_ativos_mapas_oficiais" attrName="listagem" [model]="reportModel" (modelChange)="reportChanged($event)" [properties]="{subfolder: header.nMapa}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'ativosmapasoficiais.header.natureza'"></label>
            <edit>
              <pl-edit type="natureza" [model]="header.natureza" (modelChange)="header.natureza = $event"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'ativosmapasoficiais.print.estadouso'"></label>
              <edit>
                <pl-edit-radio-group type="radiogroup" attrName="headerEstadoUso" [(model)]="header.estadouso" [properties]="radioGroupEstadoUso"></pl-edit-radio-group>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'ativosmapasoficiais.print.metodos'"></label>
              <edit>
                <pl-edit-radio-group type="radiogroup" attrName="headerMetodo" [(model)]="header.metodo" [properties]="radioGroupMetodo"></pl-edit-radio-group>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'ativosmapasoficiais.header.anoEconomico'"></label>
            <edit>
              <pl-autocomplete
                [source]="metodoSource"
                [model]="header.anoEconomico"
                (modelChange)="anoEconomicoChanged($event)"
                [rowTemplate]="anoDeCalculoTemplate"
                [allowInvalid]="false"
                output="ano">
              </pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'ativosmapasoficiais.header.registoMes'"></label>
            <edit>
              <pl-edit type="meses" [model]="header.mes" (modelChange)="header.mes = $event; evaluateDatesTo()"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <fieldset class="mt-3">
          <legend [translate]="'ativosmapasoficiais.card.selecao'"></legend>

          <pl-group>
            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.codAtivoDe'"></label>
              <edit>
                <entity-autocomplete entity="ativos" attrName="codAtivoDe" (selectedKeyChange)="header.codAtivoDe = $event" [output]="ativoOutput"></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="ativos" attrName="codAtivoAte" [model]="ativosAte" (selectedKeyChange)="header.codAtivoAte = $event" [output]="ativoOutput"></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.nContaDe'"></label>
              <edit>
                <entity-autocomplete entity="pocs" attrName="nContaDe" [model]="contaDe" (selectedKeyChange)="header.nContaDe = $event"></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="pocs" attrName="nContaAte" [model]="contaAte" (selectedKeyChange)="header.nContaAte = $event"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.nCCustoDe'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="nCCustoDe" (selectedKeyChange)="header.nCCustoDe = $event" [output]="ccustoOutput" [filter]="'encerrado=0'"></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="nCCustoAte" [model]="cCustoAte" (selectedKeyChange)="header.nCCustoAte = $event" [output]="ccustoOutput" [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.codAmortDe'"></label>
              <edit>
                <entity-autocomplete
                  entity="amortizacao"
                  attrName="codAmortDe"
                  [model]="amortizacaoDe"
                  (selectedKeyChange)="header.codAmortDe = $event"
                  [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}}"
                  [output]="amortizacaoOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="amortizacao"
                  attrName="codAmortAte"
                  [model]="amortizacaoAte"
                  (selectedKeyChange)="header.codAmortAte = $event"
                  [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}}"
                  [output]="amortizacaoOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'ativosmapasoficiais.header.situacao'"></label>
            <edit>
              <pl-edit
                type="checkbox"
                attrName="contabBaseCalculo"
                [model]="header.radioAll"
                (modelChange)="header.radioAll = $event; radioAllChanged($event)"
                [properties]="{label: 'ativosmapasoficiais.radioOptions.todos'}">
              </pl-edit>
              <pl-edit
                type="checkbox"
                attrName="normal"
                [model]="header.normal"
                (modelChange)="header.normal = $event; normalAbatidoVendidoChanged($event)"
                [properties]="{label: 'ativosmapasoficiais.radioOptions.normal'}">
              </pl-edit>
              <pl-edit
                type="checkbox"
                attrName="abatido"
                [model]="header.abatido"
                (modelChange)="header.abatido = $event; normalAbatidoVendidoChanged($event)"
                [properties]="{label: 'ativosmapasoficiais.radioOptions.abatido'}">
              </pl-edit>
              <pl-edit
                type="checkbox"
                attrName="vendido"
                [model]="header.vendido"
                (modelChange)="header.vendido = $event; normalAbatidoVendidoChanged($event)"
                [properties]="{label: 'ativosmapasoficiais.radioOptions.vendido'}">
              </pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <pl-group>
                <label [translate]="'ativosmapasoficiais.header.dataAquisicaoDe'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="header.dataAquisicaoDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="header.dataAquisicaoAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group *ngIf="header.radioAll || (!header.abatido && !header.vendido)">
              <pl-group>
                <label [translate]="'ativosmapasoficiais.header.dataAbateDe'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="header.dataAbateDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="header.dataAbateAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
        </fieldset>
      </pl-form>
    </div>
  </cg-card-panel>
</div>
