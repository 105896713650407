import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';

export interface IJsonFaturacaoContrato {
  classeContrato: string;
  tipoContrato: string;
  nContrato: number;
  versao: number;
  descricao: string;
  nClifo: string;
  nomeClifo: string;
  nFaturasEmAberto: number;
  dataUltimoPagamento: TDate;
  dataUltimaFatura: TDate;
  obsUltimaFatura: string;
  temDebitoDirectoStr: string;
  dataLeitura: TDate;
  valorLeituraAnterior: number;
  valorLeituraActual: number;
  nUnidadesLida: number;
  temErro: boolean;
  erroStr: string;
  podefacturarLeituraaZero: boolean;
  refCtrCab: number;
  totalLiquido: number;
  contratoAsString?: string;
}

export interface IJsonContratoFaturadoDocSel extends IJsonContratoFaturadoDoc {
  selecionado?: boolean;
}

export interface IJsonContratoFaturadoDoc {
  nClasseCtr: string;
  nTipoContrato: string;
  nContrato: number;
  descricao: string;
  nDocfa: number;
  nNumer: number;
  nConta: string;
  contaNome: string;
  nDocumentoDraft: number;
  nDocumento: number;
  dataDoc: TDate;
  nDocAsString: string;
  faccbId: number;
  contratoAsString: string;
  email: string;
  emailEnviado?: boolean;
  docJaImprimido?: boolean;
}

export interface IJsonContratoFaturadoErro {
  nClasseCtr: string;
  nTipoContrato: string;
  nContrato: number;
  descricao: string;
  nConta: string;
  contaNome: string;
  erro: string;
  contratoAsString: string;
}

export interface IJsonContratoFaturado {
  docsGerados: Array<IJsonContratoFaturadoDocSel>;
  erros: Array<IJsonContratoFaturadoErro>;
}

export interface IFaturacaoAvencasHeader {
  nClasseCtr: string;
  nomeClasseCtr: string;
  nZonaDe: number;
  nZonaAte: number;
  nContaDe: string;
  nContaAte: string;
  dataDoc: TDate;
  dataVenc: TDate;
  dataVencActiva: boolean;
  dataDocExterno: TDate;
  dataInicTransp: TDate;
  horaInicTransp: TDate;
  origemTxtContrato: number;
  txtCtrNoCabecalho: boolean;
  txtCtrNaLinha: boolean;
  txtContrato: string;
}

export interface IFaturacaoContratosParams {
  moduleNameRetroceder: string;
}

export const NZONA_DE = 0;
export const NZONA_ATE = 32767;
export const NCONTA_DE = '0';
export const NCONTA_ATE = '9999999';

export const MODULE_NAME_FATURACAO_CONTRATOS = 'faturacaoContratos';
export const MODULE_URL_FATURACAO_CONTRATOS = 'faturacaoContratos';

export enum EFaturacaoContratosTableLegendColors {
  ContratoComErro = 'contrato-com-erro'
}

export const TABLE_LEGEND_FATURACAO_CONTRATOS: TTableLegend = deepFreeze([{caption: 'faturacaoContratos.legend.contratoComErro', badgeCSSClass: EFaturacaoContratosTableLegendColors.ContratoComErro}]);

export enum ETipoOrigemTxtContrato {
  TipoDeAvenca = 0,
  Manual = 1
}

export interface ITipoOrigemTxtContrato {
  value: ETipoOrigemTxtContrato;
  label: string;
}

export interface IFaturacaoAvencasProcessParams {
  refCtrCabList: string;
  dataDoc: TDate;
  dataVenc: TDate;
  utilizaDtVenc: boolean;
  dataDocExterno: TDate;
  dataInicTransp: TDate;
  horaInicTransp: TDate;
  txtCtrNoCabecalho: boolean;
  txtCtrNaLinha: boolean;
  txtContrato: string;
  skipControlaEstados: boolean;
  origemPeloTipoContrato: boolean;
}
