<div class="modelo22-anexod-quadro7-tablelas78-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexod.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="is78">
        <label [translate]="'modelo22.modal.anexod.codBeneficio'"></label>
        <edit>
          <pl-autocomplete [source]="dsBeneficio" [model]="codigoBeneficioView" (evtSelected)="evtBeneficioDSChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.regiao' + newLineFor"></label>
        <edit>
          <pl-autocomplete [source]="dsRegiao78" [model]="regiaoView" (evtSelected)="evtRegiao78DSChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A1">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.regiao' + newLineFor"></label>
        <edit>
          <pl-autocomplete [source]="dsRegiao78A1" [model]="regiaoView" (evtSelected)="evtRegiao78A1DSChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78 || is78A1">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.cae' + newLineFor"></label>
        <edit>
          <entity-autocomplete entity="caes" attrName="cae" [(selectedKey)]="cae" output="key"></entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.montanteDasAplicacoes'"></label>
          <edit>
            <pl-edit type="number" attrName="montanteDasAplicacoes" [(model)]="montanteDasAplicacoes"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.irc'"></label>
          <edit>
            <pl-edit type="number" attrName="irc" [(model)]="irc"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imiIMTSelo'"></label>
          <edit>
            <pl-edit type="number" attrName="imiIMTSelo" [(model)]="imiIMTSelo"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.naoFiscais'"></label>
          <edit>
            <pl-edit type="number" attrName="naoFiscais" [(model)]="naoFiscais"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78">
        <label [translate]="'global.text.total'"></label>
        <edit>
          <pl-edit type="number" attrName="total" [(model)]="total"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A1 || is78A2 || is78A3">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.numeroLinha'"></label>
        <edit>
          <pl-edit type="number" attrName="numeroLinha" [(model)]="numeroLinha"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A1">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.tipo'"></label>
          <edit>
            <pl-autocomplete [source]="dsTipo" [model]="tipoView" (evtSelected)="evtTipoDSChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate"></pl-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.numProjecto'"></label>
          <edit>
            <pl-edit type="text" attrName="numProjecto" [(model)]="numProjecto"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A1">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.dataInicio'"></label>
          <edit>
            <pl-edit type="date" attrName="dataInicio" [(model)]="dataInicio"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.dataFim'"></label>
          <edit>
            <pl-edit type="date" attrName="dataFim" [(model)]="dataFim"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A1">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.tipologia'"></label>
          <edit>
            <pl-autocomplete [source]="dsTipologia" [model]="tipologiaView" (evtSelected)="evtTipologiaDSChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.identificacao'"></label>
          <edit>
            <pl-autocomplete
              [source]="dsIdentificacao"
              [model]="identificacaoView"
              (evtSelected)="evtIdentificacaoDSChanged($event)"
              [allowInvalid]="false"
              [rowTemplate]="dsTemplate"
              [output]="dsTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A1">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.montante'"></label>
          <edit>
            <pl-edit type="number" attrName="montante" [(model)]="montante"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.montanteActualizado'"></label>
          <edit>
            <pl-edit type="number" attrName="montanteActualizado" [(model)]="montanteActualizado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A2 || is78A3">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.aplicacoesMontante' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="aplicacoesMontante" [(model)]="aplicacoesMontante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A2">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.aplicacoesActualizado'"></label>
        <edit>
          <pl-edit type="number" attrName="aplicacoesActualizado" [(model)]="aplicacoesActualizado"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A2">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.financeiroUsufruido'"></label>
          <edit>
            <pl-edit type="number" attrName="financeiroUsufruido" [(model)]="financeiroUsufruido"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.financeiroActualizado'"></label>
          <edit>
            <pl-edit type="number" attrName="financeiroActualizado" [(model)]="financeiroActualizado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A2">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.ircUtilizado'"></label>
          <edit>
            <pl-edit type="number" attrName="ircUtilizado" [(model)]="ircUtilizado"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.ircActualizado'"></label>
          <edit>
            <pl-edit type="number" attrName="ircActualizado" [(model)]="ircActualizado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A2">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imiUtilizado'"></label>
          <edit>
            <pl-edit type="number" attrName="imiUtilizado" [(model)]="imiUtilizado"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imiActualizado'"></label>
          <edit>
            <pl-edit type="number" attrName="imiActualizado" [(model)]="imiActualizado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A2">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imtUtilizado'"></label>
          <edit>
            <pl-edit type="number" attrName="imtUtilizado" [(model)]="imtUtilizado"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.seloUtilizado'"></label>
          <edit>
            <pl-edit type="number" attrName="seloUtilizado" [(model)]="seloUtilizado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A3">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.financeiroMontante'"></label>
          <edit>
            <pl-edit type="number" attrName="financeiroMontante" [(model)]="financeiroMontante"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.ircMontante'"></label>
          <edit>
            <pl-edit type="number" attrName="ircMontante" [(model)]="ircMontante"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A3">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imiMontante'"></label>
          <edit>
            <pl-edit type="number" attrName="imiMontante" [(model)]="imiMontante"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.imtMontante'"></label>
          <edit>
            <pl-edit type="number" attrName="imtMontante" [(model)]="imtMontante"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.seloMontante'"></label>
          <edit>
            <pl-edit type="number" attrName="seloMontante" [(model)]="seloMontante"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is78A2 || is78A3">
        <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.montanteTotal'"></label>
        <edit>
          <pl-edit type="number" attrName="montanteTotal" [(model)]="montanteTotal"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is78A3">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.intensidade'"></label>
          <edit>
            <pl-edit type="number" attrName="intensidade" [(model)]="intensidade"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.tabelas78.montanteAInscrever'"></label>
          <edit>
            <pl-edit type="number" attrName="montanteAInscrever" [(model)]="montanteAInscrever"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
