import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonRecibo} from '../../../../entities/recibos/jsonRecibo.entity.interface';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonExtratosDT} from './jsonExtratosDT.module.interface';
import {TDate} from '../../../../../common/dates';

export enum EExtratosDTTipo {
  Geral,
  Clientes,
  Fornecedores
}

export interface IExtratosDT extends IJsonExtratosDT {
  _docContabilidade?: IJsonDocContabilidade;
  _docComercial?: IJsonDocComercial;
  _docContabDigital?: IContabDigitalGDocViewerRecolhaDoc;
  _recibos?: Array<IJsonRecibo>;
}

export interface IExtratosDTStateParams {
  nConta?: string;
  nomeConta?: string;
  temCC?: boolean;
}

export interface IExtratosDTOutputParams {
  nConta: string;
  deData: TDate;
  ateDate: TDate;
  semResgistos: boolean;
}

export interface IExtratosDTCallback {
  refresh?(): Promise<void>;
}

export interface IExtratosDTClifo {
  nConta: string;
  nomeConta: string;
}

export const MODULE_NAME_EXTRATOS_DT = 'extratosDT';
export const MODULE_NAME_EXTRATOS_DT_CLIENTES = 'extratosDTClientes';
export const MODULE_NAME_EXTRATOS_DT_FORNECEDORES = 'extratosDTFornecedores';
