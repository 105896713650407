<div class="pca-rh-gestao-dgemps-module">
  <pl-tabs [justified]="justified">
    <pl-tab id="marcacao">
      <div *plTabTitle>
        <i class="fa fa-fw" [ngClass]="iconMarcacao"></i>&nbsp;<span>{{ titleMarcacao }}</span>
      </div>

      <div *plTabContent>
        <gestao-dgemps
          [type]="moduleType"
          [manager]="true"
          [toolbarInstanceName]="instanceName"
          [holidaysDates]="holidaysDates"
          [configStructure]="configStructure"
          [configEvents]="configEvents"
          [fluxos]="fluxos"
          [empregado]="empregado"
          [codServico]="codServico"
          [forceDelete]="true">
        </gestao-dgemps>
      </div>
    </pl-tab>

    <pl-tab id="resumo">
      <div *plTabTitle><i class="fa fa-fw fa-list-ol"></i>&nbsp;<span [translate]="'gestaodgemps.summary.title'"></span></div>

      <div *plTabContent>
        <div class="pca-rh-gestao-dgemps-module-summary">
          <div class="pca-rh-gestao-dgemps-module-summary-title">
            <i class="fa fa-fw fa-picture-o"></i>
            <strong>{{ titleSummary }}</strong
            >&nbsp;
            <span class="badge rounded-pill text-bg-light" [translate]="'gestaodgemps.summary.integradas'" [translateParams]="{value: totalIntegrados}"></span>
          </div>
        </div>

        <br />

        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="sumarioMarcacoes" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
          <div *dxTemplate="let cell of 'templateToolbar'">
            <div class="dx-toolbar-autocomplete-inline">
              <label [translate]="'gestaodgemps.fields.daData'"></label>&nbsp;
              <pl-edit-datepicker attrName="date-filter-from" [model]="dateFilterFrom" (modelChange)="changedDateFilterFrom($event)" style="padding-right: 6px"></pl-edit-datepicker>

              <label [translate]="'global.text.to'"></label>&nbsp;
              <pl-edit-datepicker attrName="date-filter-to" [model]="dateFilterTo" (modelChange)="changedDateFilterTo($event)"></pl-edit-datepicker>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
