<div class="configs-erp-rh-module">
  <pl-navpill attrName="configs-erp-rh-module-nav">
    <pl-navpill-panel [id]="'geral'">
      <div *plNavPillPanelTitle><i class="fa fa-fw fa-cog me-1" aria-hidden="true"></i><span [translate]="'global.text.general'"></span></div>
      <div *plNavPillPanelContent>
        <dx-data-grid [cgDxDataGrid]="dataGridConfigPortalColaborador" cgDxDataGridInstanceName="configPortalColaborador" [dataSource]="configList">
          <div *dxTemplate="let cellTemplateInfo of 'cellTemplateValues'">
            <div class="configs-erp-rh-module-inline-edit">
              <pl-inline-edit
                *ngIf="cellTemplateInfo.data.name === 'portalcolaborador.reportrecibosal'"
                [type]="cellTemplateInfo.data.cguid || cellTemplateInfo.data.kind"
                [(model)]="cellTemplateInfo.data.value"
                [onValidate]="fnOnValidateReport"
                [properties]="{readonly: cellTemplateInfo.data.readOnly, inlineMode: true, entity: {output: 'key'}}">
              </pl-inline-edit>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel [id]="'notificacao'">
      <div *plNavPillPanelTitle><i class="fa fa-fw fa-bell me-1" aria-hidden="true"></i><span [translate]="'configsErp.items.portalColaborador.notificacao'"></span></div>
      <div *plNavPillPanelContent>
        <div class="d-flex" style="gap: 15px">
          <pl-group>
            <label [translate]="'configsErp.items.portalColaborador.notificacao'"></label>
            <edit>
              <pl-edit type="select" [(model)]="notificacaoModel.notificacao" [properties]="{select: {list: notificacaoSource}, disallowClear: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group *ngIf="notificacaoModel.notificacao !== portalColaboradorNotificacao.DESATIVADO">
            <label [translate]="'configsErp.items.portalColaborador.hora'"></label>
            <edit>
              <pl-edit-timepicker [(model)]="notificacaoModel.hora"></pl-edit-timepicker>
            </edit>
          </pl-group>
          <pl-group *ngIf="notificacaoModel.notificacao === portalColaboradorNotificacao.DIA_SEMANA">
            <label [translate]="'configsErp.items.portalColaborador.diaDaSemana'"></label>
            <edit>
              <pl-edit type="select" [(model)]="notificacaoModel.dia" [properties]="{select: {list: diasSemanaSource}, disallowClear: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label>&nbsp;</label>
            <edit>
              <pl-button type="button" klass="btn btn-sm btn-primary" [onClick]="fnSaveConfig"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
            </edit>
          </pl-group>
        </div>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel [id]="'conflitos'">
      <div *plNavPillPanelTitle><i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i><span [translate]="'configsErp.items.portalColaborador.conflitos.title'"></span></div>
      <div *plNavPillPanelContent>
        <fieldset class="mb-2" [plPromise]="promisePermite">
          <legend [translate]="'configsErp.items.portalColaborador.conflitos.permiteMarcarFeriasComConflitos'"></legend>
          <pl-group>
            <edit>
              <pl-edit type="radiogroup" [model]="permiteCheck" (modelChange)="permiteModelChange($event)" [properties]="{groupItems: groupItemsSource}"></pl-edit>
            </edit>
          </pl-group>
        </fieldset>
        <fieldset>
          <legend [translate]="'configsErp.items.portalColaborador.conflitos.colaboradores'"></legend>
          <dx-data-grid
            [cgDxDataGrid]="dataGridColaboradoresDefinition"
            cgDxDataGridInstanceName="prhConflitosColaboradores"
            (onInitialized)="onInitializedColaboradores($event)"
            [dataSource]="dataGridColaboradoresDefinition.dataSource">
            <div *dxTemplate="let cellInfo of 'groupCellTemplateCol'">
              <div class="d-flex align-items-center" style="gap: 15px">
                <div>
                  <strong style="font-size: 13px; color: #2196f3">{{ cellInfo.displayValue }}</strong>
                </div>
                <div>
                  <pl-tooltip [config]="{text: 'configsErp.items.portalColaborador.conflitos.tooltips.apagarTodosConflitosDoEmpregado', container: 'body'}">
                    <dx-button *plTooltipContent class="dx-button-icon-devexpress" (click)="apagarConflitosPorEmp(cellInfo.displayValue)" icon="trash" [stylingMode]="'text'" plPromise></dx-button>
                  </pl-tooltip>
                </div>
              </div>
            </div>

            <div *dxTemplate="let item of 'cellTemplateActions'">
              <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.delete' | translate" (click)="deleteConflito(item.data.idConflito)" icon="trash" [stylingMode]="'text'"></dx-button>
            </div>
          </dx-data-grid>
        </fieldset>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel [id]="'tiposComunicacao'">
      <div *plNavPillPanelTitle><i class="fa fa-fw fa-bullhorn me-1" aria-hidden="true"></i><span [translate]="'prhtipocom.title_plural'"></span></div>
      <div *plNavPillPanelContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridPRHTipoComDefinition"
          cgDxDataGridInstanceName="prhTipoComDataGrid"
          (onInitialized)="onInitializedPRHTipoCom($event)"
          [dataSource]="dataGridPRHTipoComDefinition.dataSource">
          <div *dxTemplate="let item of 'cellTemplateActions'">
            <ng-container *ngIf="!item.data.isSystem">
              <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.edit' | translate" (click)="editPRHTipoCom(item.data.codPRHTipoCom)" icon="edit" [stylingMode]="'text'"></dx-button>
              <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.delete' | translate" (click)="deletePRHTipoCom(item.data.codPRHTipoCom)" icon="trash" [stylingMode]="'text'"></dx-button>
            </ng-container>
          </div>
        </dx-data-grid>
      </div>
    </pl-navpill-panel>
  </pl-navpill>
</div>
