import {Injectable} from '@angular/core';
import {copy, IPlEditComponentOptionsInputAutocomplete, isUndefined, Logger, PlEditRegistryService} from 'pl-comps-angular';
import {dataSourceInputComponentName, IDataSource} from './input/datasource.input.component.interface';
import {IDataSourceItem} from './datasources.interface';

@Injectable({
  providedIn: 'root'
})
export class DataSourcesRegistryService {
  private readonly _dataSources: Map<string, IDataSource<unknown, unknown>>;

  constructor(
    private readonly _logger: Logger,
    private readonly _plEditRegistryService: PlEditRegistryService
  ) {
    this._dataSources = new Map<string, IDataSource<unknown>>();
  }

  public register(dataSource: IDataSource<unknown, unknown>): void {
    if (!dataSource) {
      return;
    }
    const properties: IPlEditComponentOptionsInputAutocomplete = {...dataSource.autocomplete};
    properties.source = dataSource.data.slice();
    this._plEditRegistryService.map(dataSourceInputComponentName, dataSource.name, properties);
    this._dataSources.set(dataSource.name, dataSource);
  }

  public get<T = number, S = IDataSourceItem<T>>(name: string): IDataSource<T, S> {
    const dataSource: IDataSource<unknown, unknown> = this._dataSources.get(name);
    if (isUndefined(dataSource)) {
      this._logger.error(`Data source '${name}' não encontrada`);
      return undefined;
    }
    return copy(<IDataSource<T, S>>dataSource);
  }

  public getAll(): Array<IDataSource<unknown, unknown>> {
    const dataSources: Array<IDataSource<unknown, unknown>> = [];
    this._dataSources.forEach((dataSource: IDataSource<unknown, unknown>) => {
      dataSources.push(copy(dataSource));
    });
    return dataSources;
  }
}
