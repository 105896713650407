import {ColaboradoresHomeComponent} from './components/home.module.component';
import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_COLABORADORES_HOME: IModuleDefinition = {
  name: 'colaboradoreshome',
  state: {
    url: '/home',
    component: ColaboradoresHomeComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTOREMENTAS, ROLE.GESTORSERVICO],
      icon: 'fa-home',
      pageTitle: 'global.menu.colaboradoreshome'
    }
  }
};
