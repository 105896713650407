import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonPlanoContasAlternativoExtrato} from '../../jsonPlanoContasAlternativo.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {HttpResponse} from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import {ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS, IPlanosContasAlternativosEntityService} from '../../planosContasAlternativos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';

@Component({
  selector: 'planos-contas-alternativos-extrato-modal',
  templateUrl: './planosContasAlternativos.extrato.modal.component.html'
})
export class PlanosContasAlternativosExtratoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public pocAltCabID: string;
  @Input() public nconta: string;
  @Input() public dePeriodo: string;
  @Input() public atePeriodo: string;
  @Input() public acumulado: boolean;

  public dataGridDefinition: IDevExpressDataGrid;
  public captionTitle: string;

  private readonly _servicePlanosContasAlt: IPlanosContasAlternativosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._servicePlanosContasAlt = this._entityServiceBuilder.build(ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS);
    this.acumulado = false;
  }

  public ngOnInit(): void {
    this.dataGridDefinition = {
      columnAutoWidth: true,
      columns: [
        {dataField: 'nconta', dataType: 'string', caption: 'planoscontasalternativos.modals.extrato.fields.nconta'},
        {dataField: 'nome', dataType: 'string', caption: 'planoscontasalternativos.modals.extrato.fields.nome', width: 400},
        {dataField: 'data', dataType: 'date', caption: 'global.text.date', visible: !this.acumulado, showInColumnChooser: !this.acumulado},
        {dataField: 'periodo', dataType: 'string', caption: 'planoscontasalternativos.modals.extrato.fields.periodo', visible: !this.acumulado, showInColumnChooser: !this.acumulado},
        {dataField: 'nDiario', dataType: 'number', caption: 'planoscontasalternativos.modals.extrato.fields.nDiario', visible: !this.acumulado, showInColumnChooser: !this.acumulado},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'planoscontasalternativos.modals.extrato.fields.nDocInterno', visible: !this.acumulado, showInColumnChooser: !this.acumulado},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'planoscontasalternativos.modals.extrato.fields.nDocExterno', visible: !this.acumulado, showInColumnChooser: !this.acumulado},
        {dataField: 'debito', dataType: 'double', caption: 'planoscontasalternativos.modals.extrato.fields.debito'},
        {dataField: 'credito', dataType: 'double', caption: 'planoscontasalternativos.modals.extrato.fields.credito'},
        {dataField: 'saldo', dataType: 'double', caption: 'global.text.saldo', visible: this.acumulado, showInColumnChooser: this.acumulado}
      ],
      dataSource: new CustomStore({
        load: this.source.bind(this)
      }),
      export: {
        filename: this.acumulado ? 'planoscontasalternativos.modals.extrato.titleAcumu' : 'planoscontasalternativos.modals.extrato.titleExtrato'
      },
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'debito', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'credito', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'saldo', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.captionTitle = this.acumulado ? 'planoscontasalternativos.modals.extrato.titleAcumu' : 'planoscontasalternativos.modals.extrato.titleExtrato';
  }

  public source(): Promise<Array<IJsonPlanoContasAlternativoExtrato>> {
    return this._servicePlanosContasAlt
      .extratoPlanoContasAlt(this.pocAltCabID, this.dePeriodo, this.atePeriodo, this.nconta, this.acumulado)
      .then((response: HttpResponse<Array<IJsonPlanoContasAlternativoExtrato>>) => {
        return response.body;
      });
  }
}
