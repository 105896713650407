import {HttpResponse} from '@angular/common/http';
import {ETipModelo25, MODULE_NAME_MODELO_25} from './modelo25.module.interface';
import {IJsonModelo25, IJsonModelo25Config, IJsonTiposDonativos} from './jsonModelo25.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {Modelo25ModuleComponent} from './components/modelo25.module.component';
import {Modelo25Service} from './modelo25.module.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_MODELO_25: IModuleDefinition = {
  name: MODULE_NAME_MODELO_25,
  state: {
    url: '/modelo25',
    component: Modelo25ModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.RH],
      pageTitle: 'global.menu.modelo25'
    },
    resolve: [
      {
        provide: 'modelo25',
        deps: [Modelo25Service],
        useFactory: (modelo25Service: Modelo25Service): Promise<IJsonModelo25> => {
          return modelo25Service.getModelo25(ETipModelo25.NewFromDB).then((response: HttpResponse<IJsonModelo25>) => response.body);
        }
      },
      {
        provide: 'modeloConfig',
        deps: [Modelo25Service],
        useFactory: (modelo25Service: Modelo25Service): Promise<IJsonModelo25Config> => {
          return modelo25Service.getModelo25Config().then((response: HttpResponse<IJsonModelo25Config>) => response.body);
        }
      },
      {
        provide: 'tiposDonativos',
        deps: [Modelo25Service],
        useFactory: (modelo25Service: Modelo25Service): Promise<Array<IJsonTiposDonativos>> => {
          return modelo25Service.getModelo25TiposDonativos().then((response: HttpResponse<Array<IJsonTiposDonativos>>) => response.body);
        }
      }
    ]
  }
};
