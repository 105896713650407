import {arrow, createPopperLite, flip, Instance, Modifier, Options, Placement as PopperPlacement, preventOverflow} from '@popperjs/core';
import {inject} from '@angular/core';
import type {IPlPositioning, IPlPositioningOptions, TPlPositioningPlacement} from './positioning.interface';
import {PlRTL} from './rtl';

const placementSeparator = /\s+/;
const spacesRegExp = /  +/gi;
const popperStartPrimaryPlacement = /^left/;
const popperEndPrimaryPlacement = /^right/;
const popperStartSecondaryPlacement = /^start/;
const popperEndSecondaryPlacement = /^end/;
/* eslint-disable @typescript-eslint/naming-convention */
const bootstrapPopperMatches = {
  top: ['top'],
  bottom: ['bottom'],
  start: ['left', 'right'],
  left: ['left'],
  end: ['right', 'left'],
  right: ['right'],
  'top-start': ['top-start', 'top-end'],
  'top-left': ['top-start'],
  'top-end': ['top-end', 'top-start'],
  'top-right': ['top-end'],
  'bottom-start': ['bottom-start', 'bottom-end'],
  'bottom-left': ['bottom-start'],
  'bottom-end': ['bottom-end', 'bottom-start'],
  'bottom-right': ['bottom-end'],
  'start-top': ['left-start', 'right-start'],
  'left-top': ['left-start'],
  'start-bottom': ['left-end', 'right-end'],
  'left-bottom': ['left-end'],
  'end-top': ['right-start', 'left-start'],
  'right-top': ['right-start'],
  'end-bottom': ['right-end', 'left-end'],
  'right-bottom': ['right-end']
};

/* eslint-enable @typescript-eslint/naming-convention */

export function getPopperClassPlacement(placement: TPlPositioningPlacement, isRTL: boolean): PopperPlacement {
  const [leftClass, rightClass] = bootstrapPopperMatches[placement];
  return isRTL ? rightClass || leftClass : leftClass;
}

export function getBootstrapBaseClassPlacement(baseClass: string, placement: PopperPlacement): string {
  const [primary, secondary] = placement.split('-');
  const newPrimary = primary.replace(popperStartPrimaryPlacement, 'start').replace(popperEndPrimaryPlacement, 'end');
  let classnames = [newPrimary];
  if (secondary) {
    let newSecondary = secondary;
    if (primary === 'left' || primary === 'right') {
      newSecondary = newSecondary.replace(popperStartSecondaryPlacement, 'top').replace(popperEndSecondaryPlacement, 'bottom');
    }
    classnames.push(<TPlPositioningPlacement>`${newPrimary}-${newSecondary}`);
  }
  if (baseClass) {
    classnames = classnames.map((classname) => `${baseClass}-${classname}`);
  }
  return classnames.join(' ');
}

/*
 * Accept the placement array and applies the appropriate placement dependent on the viewport.
 * Returns the applied placement.
 * In case of auto placement, placements are selected in order
 *   'top', 'bottom', 'start', 'end',
 *   'top-start', 'top-end',
 *   'bottom-start', 'bottom-end',
 *   'start-top', 'start-bottom',
 *   'end-top', 'end-bottom'.
 * */
export function getPopperOptions({placement, baseClass}: IPlPositioningOptions, rtl: PlRTL): Partial<Options> {
  const placementVals: Array<TPlPositioningPlacement> = Array.isArray(placement) ? placement : <Array<TPlPositioningPlacement>>placement.split(placementSeparator);

  // No need to consider left and right here, as start and end are enough, and it is used for 'auto' placement only
  const allowedPlacements = ['top', 'bottom', 'start', 'end', 'top-start', 'top-end', 'bottom-start', 'bottom-end', 'start-top', 'start-bottom', 'end-top', 'end-bottom'];

  // replace auto placement with other placements
  let hasAuto = placementVals.findIndex((val) => val === 'auto');
  if (hasAuto >= 0) {
    for (const obj of allowedPlacements) {
      // Implicit conversion `==` is intended
      // eslint-disable-next-line eqeqeq,no-eq-null
      if (placementVals.find((val) => val.search(`^${obj}`) !== -1) == null) {
        placementVals.splice(hasAuto++, 1, <TPlPositioningPlacement>obj);
      }
    }
  }

  const popperPlacements = placementVals.map((placementValue) => {
    return getPopperClassPlacement(placementValue, rtl.isRTL());
  });

  const mainPlacement = popperPlacements.shift();

  const bsModifier: Partial<Modifier<any, any>> = {
    name: 'bootstrapClasses',
    enabled: Boolean(baseClass),
    phase: 'write',
    fn({state}) {
      const bsClassRegExp = new RegExp(`${baseClass}(-[a-z]+)*`, 'gi');

      const popperElement: HTMLElement = state.elements.popper;
      const popperPlacement = state.placement;

      let className = popperElement.className;

      // Remove old bootstrap classes
      className = className.replace(bsClassRegExp, '');

      // Add current placements
      className += ` ${getBootstrapBaseClassPlacement(baseClass || '', popperPlacement)}`;

      // Remove multiple spaces
      className = className.trim().replace(spacesRegExp, ' ');

      // Reassign
      popperElement.className = className;
    }
  };

  return {
    placement: mainPlacement,
    modifiers: [
      bsModifier,
      flip,
      preventOverflow,
      arrow,
      {
        enabled: true,
        name: 'flip',
        options: {
          fallbackPlacements: popperPlacements
        }
      },
      {
        enabled: true,
        name: 'preventOverflow',
        phase: 'main',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fn: function () {}
      }
    ]
  };
}

function noop<T>(arg: T): T {
  return arg;
}

export function positioning(): IPlPositioning {
  let popperInstance: Instance | null = null;
  const rtl = inject(PlRTL);

  return {
    createPopper(positioningOption: IPlPositioningOptions) {
      if (!popperInstance) {
        const updatePopperOptions = positioningOption.updatePopperOptions || noop;
        const popperOptions = updatePopperOptions(getPopperOptions(positioningOption, rtl));
        popperInstance = createPopperLite(positioningOption.hostElement, positioningOption.targetElement, popperOptions);
      }
    },
    update() {
      if (popperInstance) {
        popperInstance.update();
      }
    },
    setOptions(positioningOption: IPlPositioningOptions) {
      if (popperInstance) {
        const updatePopperOptions = positioningOption.updatePopperOptions || noop;
        const popperOptions = updatePopperOptions(getPopperOptions(positioningOption, rtl));
        popperInstance.setOptions(popperOptions);
      }
    },
    destroy() {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
    }
  };
}
