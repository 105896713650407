import {AprovacoesRefeicoesComponent} from './components/aprovacoesRefeicoes.module.component';
import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';

export const MODULE_APROVACOES_APROVAR_REFEICOES: IModuleDefinition = {
  name: 'colaboradoresAprovacoesRefeicoes',
  state: {
    url: '/aprovacoesrefeicoes',
    component: AprovacoesRefeicoesComponent,
    data: {
      roles: [ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
      pageTitle: 'global.menu.colaboradoresAprovacoesRefeicoes'
    }
  }
};
