import {Directive, TemplateRef} from '@angular/core';
import {IPlVirtualScrollingContentTemplateContext} from './virtual.scrolling.interface';

@Directive({
  selector: 'ng-template[plVirtualScrollingContent]',
  standalone: false
})
export class PlVirtualScrollingContentDirective<T> {
  constructor(public readonly templateRef: TemplateRef<IPlVirtualScrollingContentTemplateContext<T>>) {}
}
