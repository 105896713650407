import {Component, Input, OnInit} from '@angular/core';
import {IPlFilterPanelFilters} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {IEFaturaDocumento} from '../../../importadorefatura/service/importadorEfatura.service.interface';
import {IPesquisaDocsLinhaDocContabilidadeCab} from '../../../../../components/contabilidade/pesquisaDocsLinha/pesquisaDocsLinha.component.interface';

@Component({
  selector: 'module-conciliacao-e-faturas-pesq-doc-possivel-modal',
  templateUrl: './conciliacaoEFaturas.module.pesquisaDocPossivel.modal.html'
})
export class ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent extends CGModalComponent<IPesquisaDocsLinhaDocContabilidadeCab> implements OnInit {
  @Input() public doc: IEFaturaDocumento;

  public filters: IPlFilterPanelFilters;
  public assocDoc: IPesquisaDocsLinhaDocContabilidadeCab;

  public ngOnInit(): void {
    this.filters = {
      nContribuinte: this.doc.nifEmitente,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VALOR: this.doc.valorTotal,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      DC: EDebitoCredito.Credito
    };
  }

  public close(): void {
    if (this.assocDoc) {
      super.close(this.assocDoc);
    }
  }
}
