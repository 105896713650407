import {Component, Injector, Input, OnInit} from '@angular/core';
import {isObject} from 'lodash-es';
import {
  dataSourceRHDependentes,
  dataSourceRHLocalTributacaoRendimento,
  dataSourceRHNaturezaRendimento,
  dataSourceRHSituacaoCivilTitular,
  dataSourceRHTipoDeficiencia,
  ENTITY_NAME_DGEMPS_FULL,
  ERHDependentes,
  ERHLocalTributacaoRendimento,
  ERHNaturezaRendimento,
  ERHSituacaoCivilTitular,
  ERHTipoDeficiencia,
  IDGEMPSFullEntityService,
  IRHDataSourceItem
} from '../../dgempsFull.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonDGEMPSelecaoTabelaIRS} from '../../jsonDGEMPFull.interface';
import moment from 'moment';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {ENTITY_NAME_IRSDT, IDataIRS} from '../../../../../entities/irsdt/irsDt.entity.interface';
import {ENTITY_NAME_TIRS, IDataEscalao, ITIRSEntityService} from '../../../../../entities/tirs/tirs.entity.interface';
import {IJsonTirs} from '../../../../../entities/tirs/jsonTirs.entity.interface';
import {IJsonDate} from '../../../../../../common/interfaces/json';
import {IJsonIrsDt} from '../../../../../entities/irsdt/jsonIrsDt.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const formatDate = 'DD-MM-YYYY';

@Component({
  selector: 'selecao-tabelairs',
  templateUrl: './dgempsFull.selecaoTabelaIrs.modal.component.html'
})
export class SelecaoTabelaIRSModalComponent extends CGModalComponent<IJsonDGEMPSelecaoTabelaIRS> implements OnInit {
  @Input() public selecaoTabelaIRS: IJsonDGEMPSelecaoTabelaIRS;

  public promise: Promise<void>;
  public residenciaData: IRHDataSourceItem<ERHLocalTributacaoRendimento>;
  public situacaoData: IRHDataSourceItem<ERHSituacaoCivilTitular>;
  public tipoRendimentoData: IRHDataSourceItem<ERHNaturezaRendimento>;
  public declaranteDeficienteData: IRHDataSourceItem<ERHTipoDeficiencia>;
  public dependentesData: IRHDataSourceItem<ERHDependentes>;
  public dependentesDeficienteData: IRHDataSourceItem<ERHDependentes>;
  public outputDesignacaoIRS: string;
  public outputParcelaAbaterDepende: string;
  public dataEmVigorDataSource: Array<IDataIRS>;
  public currentDataEmVigor: IDataIRS;

  public readonly residenciaDataSource: ReadonlyArray<IRHDataSourceItem<ERHLocalTributacaoRendimento>>;
  public readonly situacaoDataSource: ReadonlyArray<IRHDataSourceItem<ERHSituacaoCivilTitular>>;
  public readonly tipoRendimentoDataSource: ReadonlyArray<IRHDataSourceItem<ERHNaturezaRendimento>>;
  public readonly declaranteDeficienteDataSource: ReadonlyArray<IRHDataSourceItem<ERHTipoDeficiencia>>;
  public readonly dependentesDataSource: ReadonlyArray<IRHDataSourceItem<ERHDependentes>>;
  public readonly dependentesDeficienteDataSource: ReadonlyArray<IRHDataSourceItem<ERHDependentes>>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;
  private readonly _serviceTirs: ITIRSEntityService;
  private readonly _serviceIRSDT: IEntityService<IJsonIrsDt>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);

    this.dataEmVigorDataSource = [];
    this.currentDataEmVigor = undefined;

    this.residenciaDataSource = dataSourceRHLocalTributacaoRendimento(this._translateService);
    this.residenciaDataSource = this.residenciaDataSource.slice(1, this.residenciaDataSource.length);

    this.situacaoDataSource = dataSourceRHSituacaoCivilTitular(this._translateService);
    this.situacaoDataSource = this.situacaoDataSource.slice(1, this.situacaoDataSource.length);

    this.tipoRendimentoDataSource = dataSourceRHNaturezaRendimento(this._translateService);
    this.tipoRendimentoDataSource = this.tipoRendimentoDataSource.slice(1, this.tipoRendimentoDataSource.length);

    this.declaranteDeficienteDataSource = dataSourceRHTipoDeficiencia(this._translateService);
    this.dependentesDataSource = dataSourceRHDependentes(this._translateService);
    this.dependentesDeficienteDataSource = dataSourceRHDependentes(this._translateService);

    this.selecaoTabelaIRS = {
      dataReferencia: moment(),
      residencia: ERHLocalTributacaoRendimento.RHLTRNone,
      situacao: ERHSituacaoCivilTitular.RHSCTNone,
      tipoRendimento: ERHNaturezaRendimento.RHNRNone,
      declaranteDeficiente: ERHTipoDeficiencia.RHTDNone,
      dependentes: ERHDependentes.RHDSemDependentes,
      dependentesDeficiente: ERHDependentes.RHDSemDependentes,
      nTabIRS: '',
      designacaoIRS: '',
      parcelaAbaterDepende: 0
    };

    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
    this._serviceTirs = this._entityServiceBuilder.build<IJsonTirs, ITIRSEntityService>(ENTITY_NAME_TIRS);
    this._serviceIRSDT = this._entityServiceBuilder.build(ENTITY_NAME_IRSDT);

    this.outputDesignacaoIRS = '';
    this.outputParcelaAbaterDepende = '';

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'valorILIQ', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.valorILIQ'},
        {dataField: 'taxaMarginalMaxima', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.taxaMarginalMaxima'},
        {dataField: 'parcelaAbaterFormulaInfo', dataType: 'string', caption: 'dgempsfull.gridIRSDTEmVigor.fields.parcelaAbaterFormulaInfo'},
        {dataField: 'parcelaAbaterDepende', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.parcelaAbaterDepende'},
        {dataField: 'txEfetivaMensalEscal', dataType: 'string', caption: 'dgempsfull.gridIRSDTEmVigor.fields.txEfetivaMensalEscal'}
      ],
      dataSource: [],
      paging: {enabled: false},
      export: {enabled: false},
      remoteOperations: false,
      columnChooser: {enabled: false},
      allowColumnReordering: false,
      filterRow: {visible: false}
    };
  }

  public ngOnInit(): void {
    this.residenciaData = dataSourceRHLocalTributacaoRendimento(this._translateService)[this.selecaoTabelaIRS.residencia];
    this.situacaoData = dataSourceRHSituacaoCivilTitular(this._translateService)[this.selecaoTabelaIRS.situacao];
    this.tipoRendimentoData = dataSourceRHNaturezaRendimento(this._translateService)[this.selecaoTabelaIRS.tipoRendimento];
    this.declaranteDeficienteData = dataSourceRHTipoDeficiencia(this._translateService)[this.selecaoTabelaIRS.declaranteDeficiente];
    this.dependentesData = dataSourceRHDependentes(this._translateService)[this.selecaoTabelaIRS.dependentes];
    this.dependentesDeficienteData = dataSourceRHDependentes(this._translateService)[this.selecaoTabelaIRS.dependentesDeficiente];

    this._getCodigoTabelaIRS();
  }

  public close(): void {
    super.close(this.selecaoTabelaIRS);
  }

  public dismiss(): void {
    super.close(undefined);
  }

  public async residenciaChange(item: IRHDataSourceItem<ERHLocalTributacaoRendimento>): Promise<void> {
    if (isObject(item)) {
      this.residenciaData = item;
      this.selecaoTabelaIRS.residencia = this.residenciaData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async situacaoChange(item: IRHDataSourceItem<ERHSituacaoCivilTitular>): Promise<void> {
    if (isObject(item)) {
      this.situacaoData = item;
      this.selecaoTabelaIRS.situacao = this.situacaoData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async tipoRendimentoChange(item: IRHDataSourceItem<ERHNaturezaRendimento>): Promise<void> {
    if (isObject(item)) {
      this.tipoRendimentoData = item;
      this.selecaoTabelaIRS.tipoRendimento = this.tipoRendimentoData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async declaranteDeficienteChange(item: IRHDataSourceItem<ERHTipoDeficiencia>): Promise<void> {
    if (isObject(item)) {
      this.declaranteDeficienteData = item;
      this.selecaoTabelaIRS.declaranteDeficiente = this.declaranteDeficienteData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async dependentesChange(item: IRHDataSourceItem<ERHDependentes>): Promise<void> {
    if (isObject(item)) {
      this.dependentesData = item;
      this.selecaoTabelaIRS.dependentes = this.dependentesData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async dependentesDeficienteChange(item: IRHDataSourceItem<ERHDependentes>): Promise<void> {
    if (isObject(item)) {
      this.dependentesDeficienteData = item;
      this.selecaoTabelaIRS.dependentesDeficiente = this.dependentesDeficienteData.value;
      await this._getCodigoTabelaIRS();
    }
  }

  public async dataEmVigorChange(item: IDataIRS): Promise<void> {
    this.dataGridDefinition.dataSource = [];
    if (isObject(item)) {
      await this._serviceIRSDT.query({pesquisa: `NTABIRS=${this.selecaoTabelaIRS.nTabIRS}&TABIRSDT=${<string>item.value}`, ordena: 'VALORILIQ'}).then((response) => {
        if (response.body) {
          this.dataGridDefinition.dataSource = response.body.list;
        }
      });
    }
  }

  private _getCodigoTabelaIRS(): Promise<void> {
    const params: IJsonDGEMPSelecaoTabelaIRS = {
      dataReferencia: moment(),
      residencia: this.residenciaData.value,
      situacao: this.situacaoData.value,
      tipoRendimento: this.tipoRendimentoData.value,
      declaranteDeficiente: this.declaranteDeficienteData.value,
      dependentes: this.dependentesData.value,
      dependentesDeficiente: this.dependentesDeficienteData.value
    };

    if (this.promise) {
      return this.promise;
    }

    this.disableClose();
    this.promise = this._serviceDGEMPSFull
      .getCodigoTabelaIRS(params)
      .then((response: HttpResponse<IJsonDGEMPSelecaoTabelaIRS>) => {
        if (response.body.nTabIRS !== '' && response.body.designacaoIRS !== '') {
          this.selecaoTabelaIRS = response.body;
          this.outputDesignacaoIRS = this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.tabelaDesignacaoIRS', {designacaoIRS: response.body.designacaoIRS});

          this.outputParcelaAbaterDepende =
            response.body.parcelaAbaterDepende > 0
              ? this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.parcelaAbaterDepende', {parcelaAbaterDepende: response.body.parcelaAbaterDepende})
              : '';
        } else {
          this.outputDesignacaoIRS = this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.tabelaNaoDeterminada');
          this.outputParcelaAbaterDepende = '';
        }

        this._serviceTirs.distinctDatasEscaloes(this.selecaoTabelaIRS.nTabIRS).then((response2: HttpResponse<Array<IJsonDate>>) => {
          if (response2.body) {
            this.dataEmVigorDataSource = response2.body.reverse().map((item: IDataEscalao) => {
              item.label = moment(item.value).format(formatDate);
              return item;
            });
            this.currentDataEmVigor = this.dataEmVigorDataSource.length ? this.dataEmVigorDataSource[0] : undefined;
            this.dataEmVigorChange(this.currentDataEmVigor);
          }
        });
      })
      .finally(() => {
        this.promise = undefined;
        this.enableClose();
      });

    return this.promise;
  }
}
