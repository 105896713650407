<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div
  *ngIf="(options.visible !== false && !subgroup.childNodes.length) || !subgroup.textContent.trim().length"
  class="pl-edit-group"
  [ngClass]="klass"
  [attr.data-name]="attrName"
  [formGroup]="formGroup">
  <div class="pl-group-label" [ngClass]="klassLabel">
    <ng-content select="label"></ng-content>
  </div>

  <div class="pl-group-edit" [ngClass]="klassEdit">
    <ng-content select="edit"></ng-content>
  </div>

  <div *ngIf="hideActions !== true && options.hideActions !== true" class="pl-group-actions" [ngClass]="klassActions">
    <ng-content select="actions"></ng-content>
  </div>
</div>

<div #subgroup class="sub-group" [ngClass]="klassSubGroup">
  <ng-content></ng-content>
</div>
