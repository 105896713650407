import {ComunicacoesInternasComponent} from './components/comunicacoesInternas.module.component';
import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_COMUNICACOES_INTERNAS: IModuleDefinition = {
  name: 'colaboradorescomunicacoesinternas',
  state: {
    url: '/comunicacoesinternas',
    component: ComunicacoesInternasComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTORRH],
      icon: 'fa-exchange',
      pageTitle: 'global.menu.colaboradorescomunicacoesinternas'
    }
  }
};
