import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_RH_ESTATISTICAS} from './rhEstatisticas.module.interface';
import {RhEstatisticasModuleComponent} from './components/rhEstatisticas.module.component';

export const MODULE_RH_ESTATISTICAS: IModuleDefinition = {
  name: MODULE_NAME_RH_ESTATISTICAS,
  state: {
    url: `/${MODULE_NAME_RH_ESTATISTICAS}`,
    component: RhEstatisticasModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhestatisticas'
    },
    params: {
      ano: {
        type: 'int',
        value: -1,
        squash: true,
        dynamic: true
      },
      mes: {
        type: 'int',
        value: -1,
        squash: true,
        dynamic: true
      },
      nEmpresa: {
        type: 'string',
        value: '',
        squash: true,
        dynamic: true
      }
    }
  }
};
