<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'efaturacirculacaologin.title'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="logSeriesAT"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()">
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
