<div class="rhrecolhadados">
  <entity-detail [entityName]="entityName" [(model)]="model.rem" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <div [plPromise]="promise">
        <ng-container [ngSwitch]="type">
          <div *ngSwitchCase="stateTypes.NEW">
            <pl-nav-wizard [definition]="definition" [callback]="plWizardCallback" [beforeStepChange]="fnBeforeStepChange" (evtStepChange)="stepChanged($event)">
              <pl-nav-wizard-step caption="rhrecolhadados.steps.tiporecolha" icon="fa-list-alt">
                <div *plNavWizardStepContent>
                  <div class="col-md-12">
                    <div class="row">
                      <pl-edit type="radio" attrName="isByCodEmp" [model]="isByCodEmp" (modelChange)="isByCodEmp = $event" [properties]="{value: true, label: 'rhrecolhadados.radio.byemp'}"></pl-edit>
                      <pl-edit
                        type="radio"
                        attrName="contabBaseCalculo"
                        [model]="isByCodEmp"
                        (modelChange)="isByCodEmp = $event"
                        [properties]="{value: false, label: 'rhrecolhadados.radio.byabondesc'}">
                      </pl-edit>
                    </div>
                  </div>

                  <hr />

                  <pl-form>
                    <ng-container *ngTemplateOutlet="templateProcess"></ng-container>
                  </pl-form>
                </div>
              </pl-nav-wizard-step>

              <pl-nav-wizard-step caption="rhrecolhadados.steps.dadosrecolha" icon="fa-id-card-o" [properties]="{hideFinalize: true}">
                <pl-form *plNavWizardStepContent>
                  <ng-container *ngTemplateOutlet="isByCodEmp ? templateEmp : templateAbonDesc"></ng-container>
                  <ng-container *ngTemplateOutlet="isByCodEmp ? templateAbonDesc : templateEmp"></ng-container>
                  <ng-container *ngTemplateOutlet="templateBody"></ng-container>
                </pl-form>
              </pl-nav-wizard-step>
            </pl-nav-wizard>
          </div>

          <div *ngSwitchDefault>
            <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
              <ng-container *ngTemplateOutlet="templateProcess"></ng-container>
              <ng-container *ngTemplateOutlet="templateEmp"></ng-container>
              <ng-container *ngTemplateOutlet="templateAbonDesc"></ng-container>
              <ng-container *ngTemplateOutlet="templateBody"></ng-container>
            </pl-form>
          </div>
        </ng-container>
      </div>
    </div>
  </entity-detail>
</div>

<ng-template #templateProcess>
  <pl-form (formInstanceChange)="formProcess = $event" [properties]="{readonly: type === stateTypes.DETAIL}">
    <pl-group *ngIf="isRecolhaDadosFixos === ddFixosTrue">
      <label [translate]="'rhrecolhadados.fields.todosProcessamentos'"></label>
      <edit>
        <pl-edit attrName="todosProcessamentos" type="boolean" [model]="model.rem.todosProcessamentos" (modelChange)="setTodosProcessos($event)"></pl-edit>
      </edit>
    </pl-group>
    <pl-group *ngIf="!(isRecolhaDadosFixos === ddFixosTrue && model.rem.todosProcessamentos)">
      <label [translate]="'rhrecolhadados.fields.tipoProcessamento'"></label>
      <edit>
        <entity-autocomplete
          entity="rhtipoprocess"
          attrName="rhtipoprocess"
          [properties]="{
            validators: {required: {value: isRecolhaDadosFixos === ddFixosFalse}},
            disabled: isRecolhaDadosFixos === ddFixosTrue && model.rem.todosProcessamentos,
            allowEmpty: false,
            allowInvalid: false
          }"
          [model]="model.rem"
          (selectedKeyChange)="model.rem.tipoProcessamento = $event"
          (evtSelectedDescriptionChanged)="model.rem.nomeTipoProcessamento = $event"
          [fieldsMap]="{descricao: 'nomeTipoProcessamento'}"
          [output]="rhtipoprocessOutput">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</ng-template>

<ng-template #templateEmp>
  <pl-form formInstanceName="formEmp" [properties]="{readonly: type === stateTypes.DETAIL}">
    <pl-group>
      <label [translate]="'rhrecolhadados.fields.codEMP'"></label>
      <edit>
        <entity-autocomplete
          entity="dgempsfull"
          attrName="codEMP"
          [model]="model.rem"
          (modelChange)="getValoresByCodEmp()"
          (selectedKeyChange)="model.rem.codEMP = $event"
          (evtSelectedDescriptionChanged)="model.rem.nomeEmpr = $event"
          [fieldsMap]="{codEmp: 'codEMP', nome: 'nomeEmpr'}"
          [properties]="{modelOptions: {updateOn: 'blur'}}"
          [filter]="'ativo=true'"
          output="nome">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</ng-template>

<ng-template #templateAbonDesc>
  <pl-form formInstanceName="formAbonDesc" [properties]="{readonly: type === stateTypes.DETAIL}">
    <pl-group>
      <pl-group>
        <label [translate]="'rhrecolhadados.fields.abonoDesc'"></label>
        <edit>
          <pl-edit type="tipoAbonoDesc" attrName="abonoDesc" [model]="model.rem.abonoDesc" (modelChange)="model.rem.abonoDesc = $event; abonoDescChanged()"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhrecolhadados.fields.codABDESC'"></label>
        <edit>
          <div [ngSwitch]="model.rem.abonoDesc">
            <entity-autocomplete
              *ngSwitchDefault
              entity="abonos"
              attrName="codABDESC"
              [model]="model.rem"
              (selectedKeyChange)="model.rem.codABDESC = $event"
              (evtSelectedDescriptionChanged)="model.rem.nomeAbonoDesconto = $event"
              (modelChange)="getValoresByCodEmp()"
              [fieldsMap]="{codAbDesc: 'codABDESC', designacaoBreve: 'nomeAbonoDesconto'}">
            </entity-autocomplete>

            <entity-autocomplete
              *ngSwitchCase="tipoAbonoDesconto.Desconto"
              entity="descontos"
              attrName="codABDESC"
              [model]="model.rem"
              (selectedKeyChange)="model.rem.codABDESC = $event"
              (evtSelectedDescriptionChanged)="model.rem.nomeAbonoDesconto = $event"
              (modelChange)="getValoresByCodEmp()"
              [fieldsMap]="{codAbDesc: 'codABDESC', designacaoBreve: 'nomeAbonoDesconto'}">
            </entity-autocomplete>
          </div>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</ng-template>

<ng-template #templateBody>
  <pl-form formInstanceName="formBody" [properties]="{readonly: type === stateTypes.DETAIL}">
    <pl-group>
      <pl-group>
        <label *ngIf="isRecolhaDadosFixos === ddFixosFalse" [translate]="'rhrecolhadados.fields.dataDados'"></label>
        <label *ngIf="isRecolhaDadosFixos === ddFixosTrue" [translate]="'rhrecolhadados.fields.dataDadosFixos'"></label>
        <edit>
          <pl-edit type="date" attrName="data" [model]="model.rem.data" (modelChange)="onDataEventoChange($event)" [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label *ngIf="isRecolhaDadosFixos === ddFixosFalse" [translate]="'rhrecolhadados.fields.dataParaProcess'"></label>
        <label *ngIf="isRecolhaDadosFixos === ddFixosTrue" [translate]="'global.text.to'"></label>
        <edit>
          <pl-edit type="date" attrName="dataProcess" [(model)]="model.rem.dataProcess"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
    <hr class="mt-4" />
    <pl-group>
      <pl-group>
        <label [translate]="'rhrecolhadados.fields.quantidade'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="quantidade"
            [model]="model.rem.quantidade"
            (modelChange)="model.rem.quantidade = $event; quantidadeChanged()"
            [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: decimalsLimit.qtdDecimais}">
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group *ngIf="model.rem.empregadoMoedaEstrangeira">
        <label [translate]="'rhrecolhadados.fields.codMOEDA'"></label>
        <edit>
          <entity-autocomplete
            entity="moeda"
            attrName="codMOEDA"
            [model]="model.rem"
            (selectedKeyChange)="model.rem.codMOEDA = $event"
            (evtSelectedDescriptionChanged)="model.rem.nomeMoeda = $event"
            [fieldsMap]="{codMoeda: 'codMOEDA', nomeMoeda: 'nomeMoeda'}"
            output="nomeMoeda">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rhrecolhadados.fields.tipoALUNIT'"></label>
        <edit>
          <pl-edit type="tipoUnidades" attrName="tipoALUNIT" [(model)]="model.rem.unidade" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'rhrecolhadados.fields.valorUNIT'"></label>
        <edit>
          <pl-edit type="number" attrName="valorUNIT" [(model)]="model.rem.valorUNIT" [properties]="{readonly: true, disabled: true, decimalsLimit: decimalsLimit.valorUnitarioDecimais}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group [properties]="{disabled: !!model.rem.empregadoMoedaEstrangeira && isLocked(0)}">
        <label [translate]="'rhrecolhadados.fields.valor'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="valor"
            [model]="model.rem.valor"
            (modelChange)="model.rem.valor = $event; valorChanged()"
            [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: decimalsLimit.valorDecimais}">
            <ng-container *ngIf="model.rem.empregadoMoedaEstrangeira">
              <div *inputGroup="{klass: getBtnClass(0), tabindex: -1}">
                <i class="fa" plDelegateEventDownwardsClick [ngClass]="getLockClass(0)" (click)="doLock(0)"></i>
              </div>
            </ng-container>
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'rhrecolhadados.fields.vALINCIDESC'"></label>
        <edit>
          <pl-edit type="number" attrName="vALINCIDESC" [(model)]="model.rem.vALINCIDESC" [properties]="{disabled: model.rem.abonoDesc === valAbono}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group *ngIf="model.rem.empregadoMoedaEstrangeira">
      <pl-group [properties]="{disabled: isLocked(2)}">
        <label [translate]="'rhrecolhadados.fields.cambioMEParaMC'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="cambioMEParaMC"
            [(model)]="model.rem.cambioMEParaMC"
            (modelChange)="model.rem.cambioMEParaMC = $event; cambioChanged()"
            [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: decimalsLimit.cambioDecimais}">
            <div *inputGroup="{klass: getBtnClass(2), tabindex: -1}">
              <i class="fa" plDelegateEventDownwardsClick [ngClass]="getLockClass(2)" (click)="doLock(2)"></i>
            </div>
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group [properties]="{disabled: isLocked(1)}">
        <label [translate]="'rhrecolhadados.fields.valorME'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="valorME"
            [(model)]="model.rem.valorME"
            (modelChange)="model.rem.valorME = $event; valorMEChanged()"
            [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: decimalsLimit.valorMEDecimais}"
            plAutoFocus
            [plAutoFocusDisabled]="!isLocked(2) && isLocked(1)">
            <div *inputGroup="{klass: getBtnClass(1), tabindex: -1}">
              <i class="fa" plDelegateEventDownwardsClick [ngClass]="getLockClass(1)" (click)="doLock(1)"></i>
            </div>
          </pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group *ngIf="isRecolhaDadosFixos === ddFixosFalse">
      <label [translate]="'rhrecolhadados.fields.descricao'"></label>
      <edit>
        <pl-edit type="text" attrName="descricao" [(model)]="model.rem.descricao"></pl-edit>
      </edit>
    </pl-group>

    <br />

    <pl-tabs>
      <pl-tab>
        <div *plTabTitle><i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>&nbsp;<span [translate]="'rhrecolhadados.tabs.infoemp'"></span></div>

        <div *plTabContent>
          <div class="row">
            <div class="col-md-4">
              <h5 class="mb-3" [translate]="'rhrecolhadados.tabs.title.horasempregado'"></h5>
              <pl-group>
                <label [translate]="'rhrecolhadados.fields.htrabDia'"></label>
                <edit>
                  <pl-edit type="number" attrName="htrabDia" [(model)]="model.rem.htrabDia" [properties]="{readonly: true}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhrecolhadados.fields.htrabSemana'"></label>
                <edit>
                  <pl-edit type="number" attrName="htrabSemana" [(model)]="model.rem.htrabSemana" [properties]="{readonly: true}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhrecolhadados.fields.htrabMes'"></label>
                <edit>
                  <pl-edit type="number" attrName="htrabMes" [(model)]="model.rem.htrabMes" [properties]="{readonly: true}"></pl-edit>
                </edit>
              </pl-group>
            </div>

            <div class="col-md-8" *ngIf="type !== stateTypes.DETAIL">
              <h5 class="mb-4" [translate]="'rhrecolhadados.tabs.title.valoresuniemp'"></h5>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinition"
                cgDxDataGridInstanceName="valoresUnitariosEmpregado"
                [dataSource]="model.dadosValoresUni"
                (onEditingStart)="onEditingStart($event)"
                (onSaved)="onSaved($event)">
                <div *dxTemplate="let item of 'editCellTemplateSel'">
                  <pl-edit type="boolean" attrName="seleccionado" [model]="item.data.seleccionado" (modelChange)="item.data.seleccionado = $event; selectedChanged(item.data)"></pl-edit>
                </div>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </pl-form>
</ng-template>
