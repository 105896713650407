<div class="office-reporting entity-detail-form">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="officeReporting"
    [dataSource]="dataGridDefinition.dataSource"
    [selectedRowKeys]="selectedRowKeys"
    (selectedRowKeysChange)="selectedRowKeys = $event; selectedRowKeysChange()"
    (cgDxDataGridStateLoad)="onDataGridStateLoad($event)"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onDataGridCellPrepared($event)">
  </dx-data-grid>
</div>
