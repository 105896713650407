import {Component, Injector, OnInit} from '@angular/core';
import {isNumber} from 'pl-comps-angular';
import {ExcecoesCrAbDModalComponent} from '../../modals/excecoes/excecoesCrabd.modal.component';
import {IApiQueryResponse, IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IJsonABDES} from '../../../abdes/jsonABDES.entity.interface';
import {IJsonCRABDExcecoes} from '../../jsonCrAbD.entity.interface';
import {IJsonCRNEG, IJsonCRNEGGrid} from '../../../crneg/jsonCRNEG.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnToolbarPreparing} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../dgemps/dgemps.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonDGEMP} from '../../../dgemps/jsonDGEMP.entity.interface';

@Component({
  selector: 'crabd-edit',
  templateUrl: './crabd.entity.edit.component.html'
})
export class CruzamentosABDESEditComponent extends ModuloEntityDetailComponent<IJsonCRABDExcecoes> implements OnInit {
  public readonly abdesOutput: string = '{{codAbDesc}} - {{designacaoBreve}}';
  public dataGridDefinition: IDevExpressDataGrid;
  public isOrigemAbono: boolean;
  public excecoesList: Array<IJsonCRNEG>;
  public selectedRowKeys: Array<number>;

  private readonly _dgempsEntityService: IDGEMPSEntityService;
  private _dataGridInstance: dxDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._dgempsEntityService = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model && this.type === this.stateTypes.NEW) {
      this.model = {
        tipoAbDescOrigem: 0,
        codOrigem: '',
        nCodOrigem: '',
        tipoAbDescDestino: 1,
        codDestino: '',
        nCodDestino: '',
        entSuporteEmpregado: false,
        entSuportePatronato: false,
        incidenciaEmpregado: 0,
        incidenciaPatronato: 0,
        tipoExcecao: 0,
        excecoes: [],
        categoriaAbDescDestino: 0,
        nomeABDESDestino: '',
        siglaCategoriaAbDescDestino: '',
        siglaTipoAbdesDestino: '',
        nomeExcecao: ''
      };
    }

    this.selectedRowKeys = [];

    this.isOrigemAbono = this.model.tipoAbDescOrigem === 0;
    this.dataGridDefinition = {
      keyExpr: 'nCodEmp',
      columns: [
        {dataField: 'nCodEmp', dataType: 'string', caption: 'Cod. Empregado'},
        {dataField: 'nomeEmp', dataType: 'string', caption: 'Nome'}
      ],
      dataSource: [],
      export: {filename: 'crneg.title_plural'},
      selection: {mode: 'multiple', allowSelectAll: true, showCheckBoxesMode: 'always'}
    };

    this._getExcecoes();
    this.toolbar.instanceId = this.model.codOrigem;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onDataGridToolbarPreparing(event: IDevExpressDataGridEventOnToolbarPreparing): void {
    event.toolbarOptions.items.unshift({
      location: 'after',
      template: 'headerTemplateCRNEG'
    });
  }

  public selectedRowKeysChanged(newSelectedRowKeys: Array<number>): void {
    for (const excecao of this.model.excecoes) {
      excecao._selected = false;
    }
    for (const selectedItem of newSelectedRowKeys) {
      const index: number = this.model.excecoes.findIndex((item: IJsonCRNEG) => item.nCodEmp === selectedItem);
      if (index > -1) {
        this.model.excecoes[index]._selected = true;
      }
    }
  }

  public async save(config?: IApiRequestConfigWithBody<IJsonCRABDExcecoes>): Promise<IJsonCRABDExcecoes> {
    this.model.excecoes = this.model.excecoes.filter((value: IJsonCRNEG) => isNumber(value.nCodEmp));
    return super.save(config).then((response: IJsonCRABDExcecoes) => {
      if (this.maintenanceMode) {
        this._subjectOnSave.next(Promise.resolve(response));
      }
      return response;
    });
  }

  public deleteSelectedCRNEG(): void {
    this.model.excecoes = this.model.excecoes.filter((value: IJsonCRNEGGrid) => !value._selected);
    this._getExcecoes();
  }

  public async adicionarExcecao(): Promise<void> {
    const allDGEMPS: Array<IJsonCRNEG> = [];
    this.model.excecoes = this.model.excecoes.filter((value: IJsonCRNEG) => isNumber(value.nCodEmp));
    await this._dgempsEntityService.query().then((result: HttpResponse<IApiQueryResponse<IJsonDGEMP>>) => {
      const responseList: Array<IJsonDGEMP> = result.body.list;
      let auxCRNEG: IJsonCRNEG;
      for (const dgemp of responseList) {
        auxCRNEG = {
          nCodEmp: dgemp.codEmp,
          nomeEmp: dgemp.nome,
          nCodOrigem: this.model.nCodOrigem,
          nCodDestino: this.model.nCodDestino,
          entSuporte: 1
        };
        if (!this.model.excecoes.find((response: IJsonCRNEG) => response.nCodEmp === auxCRNEG.nCodEmp)) {
          allDGEMPS.push(auxCRNEG);
        }
      }
    });

    const modalInstance = this._cgModalService.showVanilla(ExcecoesCrAbDModalComponent);
    const componentInstance: ExcecoesCrAbDModalComponent = modalInstance.componentInstance;
    componentInstance.excecoesList = this.model.excecoes;
    componentInstance.cruzamento = this.model;
    componentInstance.allDGEMPS = allDGEMPS;
    return modalInstance.result.then((result) => {
      this.model.excecoes = result;
      this._getExcecoes();
    });
  }

  public codDestinoChange(event: IJsonABDES): void {
    this.model.codDestino = event.codAbDesc;
    this.model.nCodDestino = event.nCodABDESC;
  }

  private _getExcecoes(): void {
    this.dataGridDefinition.dataSource = this.model.excecoes;
  }
}
