import {Injectable, Injector} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IFaturacaoEletronicaConfiguracoesConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {
  ETipoCertificadoFaturaEletronica,
  IJsonFatEletronicaConfDigitalSign,
  IJsonFatEletronicaConfDigitalSignAddAuthorizer,
  IJsonFatEletronicaConfDigitalSignUpdateAuthorizer,
  IJsonFatEletronicaConfSafe,
  IJsonFatEletronicaConfSafeAddCertificate,
  IJsonFatEletronicaConfSafeObterAtributos,
  IJsonFatEletronicaUrlResponse,
  IJsonFaturacaoEletronicaConfig
} from '../jsonFaturacaoEletronicaConfiguracoes.module.interface';
import {MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES} from '../faturacaoEletronicaConfiguracoes.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturacaoEletronicaConfiguracoesService extends ConfigOptionsInstanceService<boolean, IFaturacaoEletronicaConfiguracoesConfigOptions, IConfigOptionsGroupErp> {
  protected readonly _apiService: ApiService;
  protected readonly _path: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.FATURACAO_ELETRONICA_CONFIGURACOES);
    this._apiService = this._injector.get<ApiService>(ApiService);
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES}`;
  }

  public getConfiguracoes(): TServiceResponse<IJsonFaturacaoEletronicaConfig> {
    return this._apiService.get<IJsonFaturacaoEletronicaConfig>({url: this._path});
  }

  public updateConfiguracoes(removedclients: string, faturacaoeletronicaconfiguracao: IJsonFaturacaoEletronicaConfig): TServiceResponse<IJsonFaturacaoEletronicaConfig> {
    return this._apiService.put<IJsonFaturacaoEletronicaConfig>({
      url: this._path,
      body: faturacaoeletronicaconfiguracao,
      params: {removedclients: removedclients}
    });
  }

  public updateTipoCertificadoActive(tipoCertificado: ETipoCertificadoFaturaEletronica): TServiceResponse<void> {
    return this._apiService.put<void, ETipoCertificadoFaturaEletronica>({
      url: `${this._path}/tipocertificado`,
      body: tipoCertificado
    });
  }

  public getSAFECertificates(ambientePreProducao: boolean = false): TServiceResponse<IJsonFatEletronicaConfSafe> {
    return this._apiService.get<IJsonFatEletronicaConfSafe>({
      url: `${this._path}/safe`,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public getSAFEAuthenticationUrl(
    nomeDaConta: string,
    email: string,
    validade: TDate,
    cidadaoNacional: boolean,
    ambientePreProducao: boolean = false
  ): TServiceResponse<IJsonFatEletronicaUrlResponse> {
    return this._apiService.get<IJsonFatEletronicaUrlResponse>({
      url: `${this._path}/safe/authentication`,
      params: {
        nomedaconta: nomeDaConta,
        email: email,
        validaate: validade,
        cidadaonacional: cidadaoNacional,
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public postAddSAFECertificate(nomeDaConta: string, accessToken: string, safetyKey: string, expiresIn: number, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.post<void, IJsonFatEletronicaConfSafeAddCertificate>({
      url: `${this._path}/safe/${nomeDaConta}`,
      body: {
        nomeDaConta: nomeDaConta,
        accessToken: accessToken,
        safetyKey: safetyKey,
        expiresIn: expiresIn
      },
      params: {
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public deleteSAFECertificate(nomeDaConta: string, authorizationCode?: string, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/safe/${nomeDaConta}`,
      params: {
        authorizationcode: authorizationCode,
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public getDigitalSignAuthorizers(ambientePreProducao: boolean = false): TServiceResponse<Array<IJsonFatEletronicaConfDigitalSign>> {
    return this._apiService.get<Array<IJsonFatEletronicaConfDigitalSign>>({
      url: `${this._path}/digitalsign`,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public getDigitalSignAuthenticationUrl(nomeAutorizador: string, idAutorizador: string, secret: string, ambientePreProducao: boolean = false): TServiceResponse<IJsonFatEletronicaUrlResponse> {
    return this._apiService.get<IJsonFatEletronicaUrlResponse>({
      url: `${this._path}/digitalsign/authentication`,
      params: {
        nomeautorizador: nomeAutorizador,
        idautorizador: idAutorizador,
        secret: secret,
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public postAddDigitalSignAuthorizer(nomeAutorizador: string, code: string, safetyKey: string, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.post<void, IJsonFatEletronicaConfDigitalSignAddAuthorizer>({
      url: `${this._path}/digitalsign/${nomeAutorizador}`,
      body: {
        nomeAutorizador: nomeAutorizador,
        code: code,
        safetyKey: safetyKey
      },
      params: {
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public registerDigitalSignAuthorizer(nomeAutorizador: string, idAutorizador: string, secret: string, usoGlobal: boolean, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.post<void, IJsonFatEletronicaConfDigitalSignUpdateAuthorizer>({
      url: `${this._path}/digitalsign/registar`,
      body: {
        nomeAutorizador: nomeAutorizador,
        idAutorizador: idAutorizador,
        secret: secret,
        usoGlobal: usoGlobal
      },
      params: {
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public authenticateDigitalSignAuthorizer(nomeAutorizador: string, ambientePreProducao: boolean = false): TServiceResponse<IJsonFatEletronicaUrlResponse> {
    return this._apiService.get<IJsonFatEletronicaUrlResponse>({
      url: `${this._path}/digitalsign/${nomeAutorizador}/authentication`,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public updateDigitalSignCertificate(nomeAutorizador: string, data: IJsonFatEletronicaConfDigitalSignUpdateAuthorizer, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.put<void, IJsonFatEletronicaConfDigitalSignUpdateAuthorizer>({
      url: `${this._path}/digitalsign/${nomeAutorizador}`,
      body: data,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public updateDigitalSignCertificateUsoGlobal(nomeAutorizador: string, usoGlobal: boolean, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.patch<void, boolean>({
      url: `${this._path}/digitalsign/${nomeAutorizador}/usoglobal`,
      body: usoGlobal,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public deleteDigitalSignAuthorizer(nomeAutorizador: string, ambientePreProducao: boolean = false): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/digitalsign/${nomeAutorizador}`,
      params: {ambientepreproducao: ambientePreProducao}
    });
  }

  public safeObterAtributos(nomeDaConta: string, validade: TDate, ambientePreProducao: boolean = false): TServiceResponse<string> {
    return this._apiService.post<string, IJsonFatEletronicaConfSafeObterAtributos>({
      url: `${this._path}/safe/${nomeDaConta}/obteratributos`,
      body: {
        nomeDaConta: nomeDaConta,
        validaAte: validade
      },
      params: {
        ambientepreproducao: ambientePreProducao
      }
    });
  }

  public ativarFaturacaoEletronica(ativar: boolean): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/estado`,
      params: {ativar: ativar}
    });
  }
}
