import {MODULE_NAME_PREV_TES_OBRAS} from './prevTesObras.module.interface';
import {PrevTesObrasModuleComponent} from './components/prevTesObras.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_PREV_TES_OBRAS: IModuleDefinition = {
  name: MODULE_NAME_PREV_TES_OBRAS,
  state: {
    url: `/${MODULE_NAME_PREV_TES_OBRAS}`,
    component: PrevTesObrasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      pageTitle: 'global.menu.prevtesobras'
    }
  }
};
