import {Component, Injector} from '@angular/core';
import {ComercialDigitalService} from '../../services/comercialDigital.service';
import {ComercialDigitalUIService} from '../../services/comercialDigital.ui.service';
import {ContabilidadeDigitalDocumentosDigitaisModuleComponent} from '../../../contabilidadedigital/documentosdigitais/components/contabDigital.documentosDigitais.module.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {ArquivoDigitalConfigModalComponent} from '../../../../components/arquivodigital/modals/config/arquivoDigital.config.modal.component';
import {IJsonDocDigitalizado} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'module-comercial-digital-documentos-digitais',
  templateUrl: './comercialDigital.documentosDigitais.module.component.html',
  providers: [
    {provide: ContabilidadeDigitalService, useExisting: ComercialDigitalService},
    {provide: ContabilidadeDigitalUIService, useExisting: ComercialDigitalUIService}
  ]
})
export class ComercialDigitalDocumentosDigitaisModuleComponent extends ContabilidadeDigitalDocumentosDigitaisModuleComponent {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  protected _callConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ArquivoDigitalConfigModalComponent, {size: 'xxl'});
    const componentInstance: ArquivoDigitalConfigModalComponent = modalInstance.componentInstance;
    componentInstance.comercialDigital = true;
    componentInstance.session = this.session;
    return modalInstance.result;
  }

  protected _setDocOCR(docDigitalizado: IJsonDocDigitalizado): void {
    this.docOCR = {
      cab: {
        validadoEFatura: false,
        totalBase: 0,
        totalIVA: 0,
        valorRetencao: 0,
        total: 0,
        ...docDigitalizado
      },
      linhasIVA: []
    };
  }
}
