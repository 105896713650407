import {Component, Injector, OnInit} from '@angular/core';
import {isEmpty, isNumber} from 'pl-comps-angular';
import {DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS} from '../../../../components/entity/entity.definition.interface';
import {IDevExpressDataGridEventOnContentReady} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonUserEmpresa} from '../../../../services/account/jsonUserApi.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';

@Component({
  selector: 'utilizadores-basic-list',
  templateUrl: './utilizadores.basic.list.component.html'
})
export class UtilizadoresBasicListComponent extends ModuloEntityListComponent<IJsonUserEmpresa> implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public onDataGridOnContentReady(event: IDevExpressDataGridEventOnContentReady<IJsonUserEmpresa>): void {
    event.component.columnOption(DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS, 'fixedPosition', 'left');
    event.component.columnOption(DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS, 'width', 50);
  }

  public itemActions(item: IJsonUserEmpresa): void {
    const id: string | number = this.entity.getId(item);
    if (isNumber(id) || !isEmpty(id)) {
      this.openDetailStateAsModal(id);
    }
  }
}
