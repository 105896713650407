import {ControloIVAEditComponent} from './components/edit/controloIVA.entity.edit.component';
import {DATA_SOURCE_NAME_AREAS_REGIONAIS_CONTROLO_IVA} from '../../datasources/areasregionaiscontroloiva/areasRegionaisControloIVA.datasource.interface';
import {DATA_SOURCE_NAME_INVERSAO_SUJ_PASSIVO} from '../../datasources/inversaosujpassivo/inversaoSujPassivo.datasource.interface';
import {DATA_SOURCE_NAME_MERCADOS} from '../../datasources/mercados/mercados.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_BEM} from '../../datasources/tipobem/tipoBem.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_DEDUCAO} from '../../datasources/tipodeducao/tipoDeducao.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_LIQUIDACAO} from '../../datasources/tipoliquidacao/tipoLiquidacao.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_OPERACAO} from '../../datasources/tipooperacao/tipoOperacao.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40} from '../../datasources/tiporegularizacaocampo40/tipoRegularizacaoCampo40.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41} from '../../datasources/tiporegularizacaocampo41/tipoRegularizacaoCampo41.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_TAXA} from '../../datasources/tipotaxa/tipoTaxa.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_TRANSMISSAO_ATIVA} from '../../datasources/tipotransmissaoactiva/tipoTransmissaoActiva.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_TRANSMISSAO_PASSIVA} from '../../datasources/tipotransmissaopassiva/tipoTransmissaoPassiva.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_VALIDACAO_NIF} from '../../datasources/tipovalidacaonif/tipoValidacaoNif.datasource.interface';
import {ENTITY_NAME_CONTROLO_IVA, IControloIVA} from './controloIVA.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {EControloIvaTipoOperacao, IJsonControloIVA} from './jsonControloIVA.entity.interface';
import {ControloIVAListComponent} from './components/list/controloIVA.entity.list.component';
import {ENTITY_NAME_QIVAAS} from '../qivaas/qivaas.entity.interface';
import {ENTITY_NAME_QIVAPS} from '../qivaps/qivaps.entity.interface';

export const ENTITY_CONTROLO_IVA: IEntityDefinition<IControloIVA> = {
  name: ENTITY_NAME_CONTROLO_IVA,
  roles: [ROLE.CONTABILIDADE, ROLE.RH],
  searchPlaceholder: 'controloIVA.pesquisa',
  metadata: {
    keyName: 'codControloIVA',
    fields: [
      {
        name: 'codControloIVA',
        caption: 'controloIVA.fields.codControloIVA',
        placeholder: 'controloIVA.fields.codControloIVA',
        validators: {required: true, maxlength: 10}
      },
      {
        name: 'descAbreviada',
        caption: 'controloIVA.fields.descAbreviada',
        placeholder: 'controloIVA.fields.descAbreviada',
        validators: {required: true, maxlength: 100}
      },
      {
        name: 'tipoBem',
        caption: 'controloIVA.fields.tipoBem',
        type: DATA_SOURCE_NAME_TIPO_BEM,
        validators: {required: true}
      },
      {name: 'tipoTaxa', type: DATA_SOURCE_NAME_TIPO_TAXA, caption: 'controloIVA.fields.tipoTaxa', validators: {required: true}},
      {
        name: 'tipoOperacao',
        caption: 'controloIVA.fields.tipoOperacao',
        type: DATA_SOURCE_NAME_TIPO_OPERACAO,
        validators: {required: true}
      },
      {
        name: 'tipoLiquidacao',
        caption: 'controloIVA.fields.tipoLiquidacao',
        type: DATA_SOURCE_NAME_TIPO_LIQUIDACAO,
        validators: {required: true}
      },
      {
        name: 'tipoTransmissaoAct',
        caption: 'controloIVA.fields.tipoTransmissaoAct',
        type: DATA_SOURCE_NAME_TIPO_TRANSMISSAO_ATIVA,
        validators: {required: true}
      },
      {
        name: 'tipoTransmissaoPas',
        caption: 'controloIVA.fields.tipoTransmissaoPas',
        type: DATA_SOURCE_NAME_TIPO_TRANSMISSAO_PASSIVA,
        validators: {required: true}
      },
      {
        name: 'mercado',
        caption: 'controloIVA.fields.mercado',
        type: DATA_SOURCE_NAME_MERCADOS,
        validators: {required: true}
      },
      {
        name: 'areaRegional',
        type: DATA_SOURCE_NAME_AREAS_REGIONAIS_CONTROLO_IVA,
        caption: 'controloIVA.fields.areaRegional',
        validators: {required: true}
      },
      {
        name: 'inversaoSujPassivo',
        type: DATA_SOURCE_NAME_INVERSAO_SUJ_PASSIVO,
        caption: 'controloIVA.fields.inversaoSujPassivo',
        validators: {required: true}
      },
      {
        name: 'tipoDeducao',
        caption: 'controloIVA.fields.tipoDeducao',
        type: DATA_SOURCE_NAME_TIPO_DEDUCAO,
        validators: {required: true}
      },
      {name: 'decPERBASETRIBUT', visible: false},
      {
        name: 'decPERBASETRIBUTNome',
        caption: 'controloIVA.fields.decPERBASETRIBUTNome',
        entity: {
          name: ENTITY_NAME_QIVAPS,
          keyTarget: 'decPERBASETRIBUT',
          outputTarget: 'decPERBASETRIBUTNome',
          output: '{{decPERBASETRIBUT}} - {{decPERBASETRIBUTNome}}',
          outputDescription: '{{nCampo}} - {{nomeCampo}}'
        }
      },
      {name: 'decANUBASETRIBUT', visible: false},
      {
        name: 'decANUBASETRIBUTNome',
        caption: 'controloIVA.fields.decANUBASETRIBUTNome',
        entity: {
          name: ENTITY_NAME_QIVAAS,
          keyTarget: 'decANUBASETRIBUT',
          outputTarget: 'decANUBASETRIBUTNome',
          output: '{{decANUBASETRIBUT}} - {{decANUBASETRIBUTNome}}',
          outputDescription: '{{nCampo}} - {{nQuadro}} - {{nome}}'
        }
      },
      {name: 'codIVA', visible: false},
      {
        name: 'codIVANome',
        caption: 'controloIVA.fields.codIVA',
        entity: {
          name: 'ivas',
          keyTarget: 'codIVA',
          outputTarget: 'codIVANome',
          output: '{{codIVA}} - {{codIVANome}}',
          outputDescription: '{{codIva}} - {{nome}}'
        },
        validators: {required: true}
      },
      {name: 'nContaIVADEDLIQREG', visible: false},
      {
        name: 'nCONTAIVADEDLIQREGNome',
        caption: 'controloIVA.fields.nContaIVADEDLIQREG',
        entity: {name: 'pocs', keyTarget: 'nContaIVADEDLIQREG', output: 'nContaIVADEDLIQREG', outputTarget: 'nCONTAIVADEDLIQREGNome', filter: 'tipo=0'},
        validators: {required: true}
      },
      {name: 'nContaIVADEDLIQREG', visible: false},
      {
        name: 'nCONTAVTNAODEDUTNome',
        caption: 'controloIVA.fields.nContaVTNAODEDUT',
        entity: {name: 'pocs', keyTarget: 'nContaVTNAODEDUT', output: 'nContaIVADEDLIQREG', outputTarget: 'nCONTAVTNAODEDUTNome', filter: 'tipo=0'}
      },
      {name: 'nContaIVANAODEDUT', visible: false},
      {
        name: 'nCONTAIVANAODEDUTNome',
        caption: 'controloIVA.fields.nContaIVANAODEDUT',
        entity: {name: 'pocs', keyTarget: 'nContaIVANAODEDUT', output: 'nContaIVANAODEDUT', outputTarget: 'nCONTAIVANAODEDUTNome', filter: 'tipo=0'}
      },
      {name: 'nContaVTNDEDPROR', visible: false},
      {
        name: 'nCONTAVTNDEDPRORNome',
        caption: 'controloIVA.fields.nContaVTNDEDPROR',
        entity: {name: 'pocs', keyTarget: 'nContaVTNDEDPROR', output: 'nContaVTNDEDPROR', outputTarget: 'nCONTAVTNDEDPRORNome', filter: 'tipo=0'}
      },
      {name: 'nContaIVANDEDPROR', visible: false},
      {
        name: 'nCONTAIVANDEDPRORNome',
        caption: 'controloIVA.fields.nContaIVANDEDPROR',
        entity: {name: 'pocs', keyTarget: 'nContaIVANDEDPROR', output: 'nContaIVANDEDPROR', outputTarget: 'nCONTAIVANDEDPRORNome', filter: 'tipo=0'}
      },
      {name: 'nContaVTLIQUIDRCH', visible: false},
      {
        name: 'nCONTAVTLIQUIDRCHNome',
        caption: 'controloIVA.fields.nContaVTLIQUIDRCH',
        entity: {name: 'pocs', keyTarget: 'nContaVTLIQUIDRCH', output: 'nContaVTLIQUIDRCH', outputTarget: 'nCONTAVTLIQUIDRCHNome', filter: 'tipo=0'}
      },
      {name: 'nContaIVALIQUIDRCH', visible: false},
      {
        name: 'nCONTAIVALIQUIDRCHNome',
        caption: 'controloIVA.fields.nContaIVALIQUIDRCH',
        entity: {name: 'pocs', keyTarget: 'nContaIVALIQUIDRCH', output: 'nContaIVALIQUIDRCH', outputTarget: 'nCONTAIVALIQUIDRCHNome', filter: 'tipo=0'}
      },
      {
        name: 'decPERIVADEDLIQREG',
        caption: 'controloIVA.fields.decPERIVADEDLIQREG',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'decPERIVADEDLIQREG', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERVTNAODEDUT',
        caption: 'controloIVA.fields.decPERVTNAODEDUT',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERIVANAODEDUT',
        caption: 'controloIVA.fields.decPERIVANAODEDUT',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERVTNDEDPROR',
        caption: 'controloIVA.fields.decPERVTNDEDPROR',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERIVANDEDPROR',
        caption: 'controloIVA.fields.decPERIVANDEDPROR',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERVTLIQUIDRCH',
        caption: 'controloIVA.fields.decPERVTLIQUIDRCH',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decPERIVALIQUIDRCH',
        caption: 'controloIVA.fields.decPERIVALIQUIDRCH',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAPS, keyTarget: 'nCampo', outputTarget: 'nomeCampo'}
      },
      {
        name: 'decANUIVADEDLIQREG',
        caption: 'controloIVA.fields.decANUIVADEDLIQREG',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUVTNAODEDUT',
        caption: 'controloIVA.fields.decANUVTNAODEDUT',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUIVANAODEDUT',
        caption: 'controloIVA.fields.decANUIVANAODEDUT',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUVTNDEDPROR',
        caption: 'controloIVA.fields.decANUVTNDEDPROR',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUIVANDEDPROR',
        caption: 'controloIVA.fields.decANUIVANDEDPROR',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUVTLIQUIDRCH',
        caption: 'controloIVA.fields.decANUVTLIQUIDRCH',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'decANUIVALIQUIDRCH',
        caption: 'controloIVA.fields.decANUIVALIQUIDRCH',
        visible: false,
        entity: {name: ENTITY_NAME_QIVAAS, keyTarget: 'nCampo', outputTarget: 'nome'}
      },
      {
        name: 'tipoValidacaoNIF',
        caption: 'controloIVA.fields.tipoValidacaoNIF',
        type: DATA_SOURCE_NAME_TIPO_VALIDACAO_NIF,
        validators: {required: true}
      },
      {
        name: 'usaVTNAODEDUTOrigem',
        type: 'boolean',
        caption: 'controloIVA.fields.usaVTNAODEDUTOrigem'
      },
      {
        name: 'usaIVANAODEDUTOrigem',
        type: 'boolean',
        caption: 'controloIVA.fields.usaIVANAODEDUTOrigem'
      },
      {
        name: 'usaVTNDEDPROROrigem',
        type: 'boolean',
        caption: 'controloIVA.fields.usaVTNDEDPROROrigem'
      },
      {
        name: 'usaIVANDEDPROROrigem',
        type: 'boolean',
        caption: 'controloIVA.fields.usaIVANDEDPROROrigem'
      },
      {
        name: 'isRegimeIvaCaixa',
        type: 'boolean',
        caption: 'controloIVA.fields.isRegimeIvaCaixa'
      },
      {
        name: 'regularizacoesC40',
        caption: 'controloIVA.fields.regularizacoesC40',
        type: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40,
        validators: {required: true}
      },
      {
        name: 'regularizacoesC41',
        caption: 'controloIVA.fields.regularizacoesC41',
        type: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41,
        validators: {required: true}
      },
      {
        name: 'regularizacoesC40RCH',
        caption: 'controloIVA.fields.regularizacoesC40RCH',
        visible: false,
        type: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40,
        validators: {required: true}
      },
      {
        name: 'regularizacoesC41RCH',
        caption: 'controloIVA.fields.regularizacoesC41RCH',
        visible: false,
        type: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41,
        validators: {required: true}
      },
      {
        name: 'nContaIvaCativo',
        caption: 'controloIVA.fields.nContaIvaCativo',
        visible: false,
        type: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41,
        validators: {required: true}
      }
    ],
    order: 'codControloIVA',
    searchFields: 'codControloIVA,descAbreviada',
    listFields: 'codControloIVA,descAbreviada',
    detailFields:
      'codControloIVA,descAbreviada,tipoBem,tipoTaxa,tipoOperacao,tipoLiquidacao,tipoTransmissaoAct,tipoTransmissaoPas,mercado,' +
      'areaRegional,inversaoSujPassivo,tipoDeducao,decPERBASETRIBUTNome,decANUBASETRIBUTNome,codIVANome,nCONTAIVADEDLIQREGNome,nCONTAVTNAODEDUTNome,' +
      'usaVTNAODEDUTOrigem,nCONTAIVANAODEDUTNome,usaIVANAODEDUTOrigem,nCONTAVTNDEDPRORNome,usaVTNDEDPROROrigem,nCONTAIVANDEDPRORNome,' +
      'usaIVANDEDPROROrigem,nCONTAVTLIQUIDRCHNome,nCONTAIVALIQUIDRCHNome,decPERIVADEDLIQREG,decPERVTNAODEDUT,decPERIVANAODEDUT,decPERVTNDEDPROR,' +
      'decPERIVANDEDPROR,decPERVTLIQUIDRCH,decPERIVALIQUIDRCH,decANUIVADEDLIQREG,decANUVTNAODEDUT,decANUIVANAODEDUT,decANUVTNDEDPROR,decANUIVANDEDPROR,' +
      'decANUVTLIQUIDRCH,decANUIVALIQUIDRCH,tipoValidacaoNIF,isRegimeIvaCaixa,regularizacoesC40,regularizacoesC41,regularizacoesC40RCH,regularizacoesC41RCH',
    newFields:
      'codControloIVA,descAbreviada,tipoBem,tipoTaxa,tipoOperacao,tipoLiquidacao,tipoTransmissaoAct,tipoTransmissaoPas,mercado,areaRegional,' +
      'inversaoSujPassivo,tipoDeducao,decPERBASETRIBUTNome,decANUBASETRIBUTNome,codIVANome,nCONTAIVADEDLIQREGNome,nCONTAVTNAODEDUTNome,' +
      'usaVTNAODEDUTOrigem,nCONTAIVANAODEDUTNome,usaIVANAODEDUTOrigem,nCONTAVTNDEDPRORNome,usaVTNDEDPROROrigem,nCONTAIVANDEDPRORNome,usaIVANDEDPROROrigem,' +
      'nCONTAVTLIQUIDRCHNome,nCONTAIVALIQUIDRCHNome,decPERIVADEDLIQREG,decPERVTNAODEDUT,decPERIVANAODEDUT,decPERVTNDEDPROR,decPERIVANDEDPROR,' +
      'decPERVTLIQUIDRCH,decPERIVALIQUIDRCH,decANUIVADEDLIQREG,decANUVTNAODEDUT,decANUIVANAODEDUT,decANUVTNDEDPROR,decANUIVANDEDPROR,decANUVTLIQUIDRCH,' +
      'decANUIVALIQUIDRCH,tipoValidacaoNIF,isRegimeIvaCaixa,regularizacoesC40,regularizacoesC41,regularizacoesC40RCH,regularizacoesC41RCH',
    editFields:
      'codControloIVA,descAbreviada,tipoBem,tipoTaxa,tipoOperacao,tipoLiquidacao,tipoTransmissaoAct,tipoTransmissaoPas,mercado,areaRegional,' +
      'inversaoSujPassivo,tipoDeducao,decPERBASETRIBUTNome,decANUBASETRIBUTNome,codIVANome,nCONTAIVADEDLIQREGNome,nCONTAVTNAODEDUTNome,usaVTNAODEDUTOrigem,' +
      'nCONTAIVANAODEDUTNome,usaIVANAODEDUTOrigem,nCONTAVTNDEDPRORNome,usaVTNDEDPROROrigem,nCONTAIVANDEDPRORNome,usaIVANDEDPROROrigem,' +
      'nCONTAVTLIQUIDRCHNome,nCONTAIVALIQUIDRCHNome,decPERIVADEDLIQREG,decPERVTNAODEDUT,decPERIVANAODEDUT,decPERVTNDEDPROR,decPERIVANDEDPROR,' +
      'decPERVTLIQUIDRCH,decPERIVALIQUIDRCH,decANUIVADEDLIQREG,decANUVTNAODEDUT,decANUIVANAODEDUT,decANUVTNDEDPROR,decANUIVANDEDPROR,decANUVTLIQUIDRCH,' +
      'decANUIVALIQUIDRCH,tipoValidacaoNIF,isRegimeIvaCaixa,regularizacoesC40,regularizacoesC41,regularizacoesC40RCH,regularizacoesC41RCH'
  },
  autocomplete: {
    rowTemplate: '{{codControloIVA}} - {{descAbreviada}}',
    output: 'descAbreviada',
    searchFields: 'codControloIVA,descAbreviada'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      component: ControloIVAListComponent,
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.controloIVA'
      }
    }
  },
  detail: {
    state: {
      component: ControloIVAEditComponent,
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.controloIVA'
      }
    }
  },
  service: function (): void {
    this.observe('tipoOperacao', (tipoOperacao: EControloIvaTipoOperacao, model: IJsonControloIVA): void => {
      if (tipoOperacao !== EControloIvaTipoOperacao.RegularizacaoAFavorEstado && tipoOperacao !== EControloIvaTipoOperacao.RegularizacaoAFavorEmpresa) {
        model.regularizacoesC40 = 0;
        model.regularizacoesC41 = 0;
        model.regularizacoesC40RCH = 0;
        model.regularizacoesC41RCH = 0;
      }
    });
  }
};
