import {Subscription} from 'rxjs';
import {Component, Injector, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PlRecaptchaComponent} from 'pl-comps-angular';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {EAbstractControlStatus} from '../../../../common/interfaces/interfaces';

@Component({
  selector: 'recaptcha-v2-modal',
  templateUrl: './recaptcha.v2.modal.component.html'
})
export class RecaptchaV2ModalComponent extends CGModalComponent<string> implements OnDestroy {
  public readonly formGroup: UntypedFormGroup;

  @ViewChild(PlRecaptchaComponent) private readonly _recaptcha: PlRecaptchaComponent;
  private readonly _subscriptionStatus: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _formBuilder: UntypedFormBuilder
  ) {
    super(_injector);
    const recaptchaControl: UntypedFormControl = this._formBuilder.control('', Validators.required);
    this.formGroup = this._formBuilder.group({
      recaptcha: recaptchaControl
    });
    this._subscriptionStatus = this.formGroup.statusChanges.subscribe((status: string) => {
      if (status === EAbstractControlStatus.Valid) {
        this.close(this._recaptcha.instance.getResponse());
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionStatus.unsubscribe();
  }
}
