import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IAtivosImportAjusteContasDoc} from '../../ativosImport.entity.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAtivosImportAjustes} from '../../jsonAtivosImport.entity.interface';
import {IJsonDocContabilidade} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

@Component({
  selector: 'modal-ativos-import-ajustesnascontas',
  templateUrl: './ativosImport.ajustesNasContas.modal.component.html'
})
export class AtivosImportAjustesNasContasModalComponent extends CGModalComponent<void> {
  @Input() public ajustesnascontas: Array<IJsonAtivosImportAjustes>;

  public readonly definition: IDevExpressDataGrid<IAtivosImportAjusteContasDoc, IAtivosImportAjusteContasDoc>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'ativosimport.modal.fields.periodo'},
        {dataField: 'ndiario', dataType: 'number', caption: 'ativosimport.modal.fields.ndiario'},
        {dataField: 'ndocInterno', dataType: 'string', caption: 'ativosimport.modal.fields.ndocInterno'}
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true,
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: false
      }
    };
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivosImportAjusteContasDoc, IAtivosImportAjusteContasDoc>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      const documento: IAtivosImportAjusteContasDoc = event.row.data;
      if (documento._docContabilidade) {
        return;
      }
      const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: documento.extPocCabID});
      documento._docContabilidade = response.body;
    });
  }
}
