<div class="mapa-pagamentos-enviados-prompt-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'mapapagam.texts.resumoEnvioErros'"></h5>
  </div>

  <div class="modal-body">
    <p [translate]="'mapapagam.texts.existemEmpresasComErros'"></p>
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th [translate]="'mapapagam.fields.nEmpresa'"></th>
          <th [translate]="'global.text.error'"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of list">
          <tr>
            <td style="width: 100px">{{ item.nEmpresa }}</td>
            <td>{{ item.errorMessage }}</td>
          </tr>
          <tr *ngIf="item.pagamentos.length > 0">
            <td colspan="2">
              <div style="font-size: 11px">
                <p class="mb-1"><span [translate]="'mapapagam.texts.listaDePagamentosAEnviar'"></span> :</p>
                <ul>
                  <li *ngFor="let pagamento of item.pagamentos">
                    <span
                      >{{ pagamento.nomePagamento }}<span *ngIf="pagamento.errorMessage.length">&nbsp;-&nbsp;{{ pagamento.errorMessage }}</span></span
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button type="button" class="btn btn-sm btn-primary" (click)="resend()"><i class="fa fa-paper-plane-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.btn.resend'"></span></button>
    <button type="button" class="btn btn-sm btn-warning" (click)="resendSkipErrors()">
      <i class="fa fa-paper-plane-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.btn.resendSkipErrors'"></span>
    </button>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
