import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_QPIRCT} from './rhQPIRCT.module.interface';
import {RHQPIRCTModuleComponent} from './components/rhQPIRCT.module.component';
import {HttpResponse} from '@angular/common/http';
import {RHQPIRCTService} from './rhQPIRCT.module.service';
import {IJsonRHQPIRCT} from './jsonRHQPIRCT.module.interface';

export const MODULE_RH_QPIRCT: IModuleDefinition = {
  name: MODULE_NAME_RH_QPIRCT,
  state: {
    url: `/${MODULE_NAME_RH_QPIRCT}`,
    component: RHQPIRCTModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhqpirct'
    },
    resolve: [
      {
        provide: 'listQPIRCT',
        deps: [RHQPIRCTService],
        useFactory: (rhQPIRCTService: RHQPIRCTService): Promise<Array<IJsonRHQPIRCT>> => {
          return rhQPIRCTService.getListaQPIRCT().then((response: HttpResponse<Array<IJsonRHQPIRCT>>) => response.body);
        }
      }
    ]
  }
};
