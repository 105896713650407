import {
  IJsonModelo22AnexoAItem04AB,
  IJsonModelo22AnexoAItem04C,
  IJsonModelo22AnexoAItem04D,
  IJsonModelo22AnexoDBeneficioMontante,
  IJsonModelo22AnexoDItem041,
  IJsonModelo22AnexoDItem0710,
  IJsonModelo22AnexoDItem0710A,
  IJsonModelo22AnexoDItem071Aand073Aand074A,
  IJsonModelo22AnexoDItem071and073and074,
  IJsonModelo22AnexoDItem076,
  IJsonModelo22AnexoDItem076A,
  IJsonModelo22AnexoDItem077,
  IJsonModelo22AnexoDItem078,
  IJsonModelo22AnexoDItem078A1,
  IJsonModelo22AnexoDItem078A2,
  IJsonModelo22AnexoDItem078A3,
  IJsonModelo22AnexoDItem079,
  IJsonModelo22AnexoDItem08,
  IJsonModelo22AnexoDItem121,
  IJsonModelo22AnexoDItem122,
  IJsonModelo22AnexoEList,
  IJsonModelo22AnexoFList6A,
  IJsonModelo22AnexoFList6B,
  IJsonModelo22AnexoGList03,
  IJsonModelo22AnexoGList04,
  IJsonModelo22RostoItemNIFValorPeriodo,
  IJsonModelo22RostoItemPeriodoMontante,
  IJsonModelo22RostoItemValorNIF,
  IJsonModelo22RostoQuadro14Item
} from './jsonModelo22.module.interface';

export enum ENavAnexosMod22 {
  Rosto = 'rosto',
  AnexoA = 'anexoA',
  AnexoB = 'anexoB',
  AnexoC = 'anexoC',
  AnexoD = 'anexoD',
  AnexoE = 'anexoE',
  AnexoF = 'anexoF',
  AnexoG = 'anexoG',
  AnexoAIMI = 'anexoAIMI',
  Anexos = 'anexos'
}

export enum EPaisesMod22 {
  AFEGANISTAO = '4',
  ALBANIA = '8',
  ANTARCTICA = '10',
  ARGELIA = '12',
  SAMOA_AMERICANA = '16',
  ANDORRA = '20',
  ANGOLA = '24',
  ANTIGUA_E_BARBUDA = '28',
  AZERBAIJAO = '31',
  ARGENTINA = '32',
  AUSTRALIA = '36',
  AUSTRIA = '40',
  BAHAMAS = '44',
  BAREM = '48',
  BANGLADESH = '50',
  ARMENIA = '51',
  BARBADOS = '52',
  BELGICA = '56',
  BERMUDA = '60',
  BUTAO = '64',
  BOLIVIA = '68',
  BOSNIA_E_HERZEGOVINA = '70',
  BOTSWANA = '72',
  ILHAS_BOUVET = '74',
  BRASIL = '76',
  BELIZE = '84',
  TERRITORIO_BRITANICO_DO_OCEANO_INDICO = '86',
  ILHAS_SALOMAO = '90',
  ILHAS_VIRGENS_BRITANICAS = '92',
  BRUNEI_DARUSSALAM = '96',
  BULGARIA = '100',
  MYANMAR = '104',
  BURUNDI = '108',
  BIELORRUSSIA = '112',
  CAMBOJA = '116',
  CAMAROES = '120',
  CANADA = '124',
  CABO_VERDE = '132',
  ILHAS_CAIMAO = '136',
  CENTRO_AFRICANA_REPUBLICA = '140',
  SRI_LANKA = '144',
  CHADE = '148',
  CHILE = '152',
  CHINA = '156',
  TAIWAN = '158',
  ILHAS_NATAL = '162',
  ILHAS_COCOS_O_KEELING = '166',
  COLOMBIA = '170',
  COMORES = '174',
  MAYOTTE = '175',
  CONGO = '178',
  CONGO_REPUBLICA_DEMOCRATICA_DO = '180',
  ILHAS_COOK = '184',
  COSTA_RICA = '188',
  CROACIA = '191',
  CUBA = '192',
  CHIPRE = '196',
  REPUBLICA_CHECA = '203',
  BENIN = '204',
  DINAMARCA = '208',
  DOMINICA = '212',
  REPUBLICA_DOMINICANA = '214',
  EQUADOR = '218',
  EL_SALVADOR = '222',
  GUINE_EQUATORIAL = '226',
  ETIOPIA = '231',
  ERITREIA = '232',
  ESTONIA = '233',
  ILHAS_FAROE = '234',
  ILHAS_FALKLAND_MALVINAS = '238',
  GEORGIA_DO_SUL_E_ILHAS_SANDWICH = '239',
  ILHAS_FIJI = '242',
  FINLANDIA = '246',
  ALAND_ISLANDS = '248',
  FRANCA = '250',
  GUIANA_FRANCESA = '254',
  POLINESIA_FRANCESA = '258',
  TERRITORIOS_FRANCESES_DO_SUL = '260',
  JIBUTI = '262',
  GABAO = '266',
  GEORGIA = '268',
  GAMBIA = '270',
  TERRITORIO_PALESTINIANO_OCUPADO = '275',
  ALEMANHA = '276',
  REPUBLICA_DEMOCRATICA_ALEM = '278',
  GANA = '288',
  GIBRALTAR = '292',
  KIRIBATI = '296',
  GRECIA = '300',
  GRONELANDIA = '304',
  GRANADA = '308',
  GUADALUPE = '312',
  GUAM = '316',
  GUATEMALA = '320',
  GUINE = '324',
  GUIANA = '328',
  HAITI = '332',
  ILHAS_HEARD_E_ILHAS_MCDONALD = '334',
  SANTA_SE_CIDADE_ESTADO_DO_VATICANO = '336',
  HONDURAS = '340',
  HONG_KONG = '344',
  HUNGRIA = '348',
  ISLANDIA = '352',
  INDIA = '356',
  INDONESIA = '360',
  IRAO_REPUBLICA_ISLAMICA = '364',
  IRAQUE = '368',
  IRLANDA = '372',
  ISRAEL = '376',
  ITALIA = '380',
  COSTA_DO_MARFIM = '384',
  JAMAICA = '388',
  JAPAO = '392',
  CAZAQUISTAO = '398',
  JORDANIA = '400',
  KENYA = '404',
  COREIA_REPUBLICA_POPULAR_DEMOCRATICA_DA = '408',
  COREIA_REPUBLICA_DA = '410',
  KUWAIT = '414',
  QUIRGUIZISTAO = '417',
  LAOS_REPUBLICA_POPULAR_DEMOCRATICA_DO = '418',
  LIBANO = '422',
  LESOTO = '426',
  LETONIA = '428',
  LIBERIA = '430',
  LIBIA_JAMAHIRIYA_ARABE_DA = '434',
  LIECHTENSTEIN = '438',
  LITUANIA = '440',
  LUXEMBURGO = '442',
  MACAU = '446',
  MADAGASCAR = '450',
  MALAWI = '454',
  MALASIA = '458',
  MALDIVAS = '462',
  MALI = '466',
  MALTA = '470',
  MARTINICA = '474',
  MAURITANIA = '478',
  MAURICIAS = '480',
  MEXICO = '484',
  MONACO = '492',
  MONGOLIA = '496',
  MOLDOVA_REPUBLICA_DE = '498',
  MONTENEGRO = '499',
  MONSERRATE = '500',
  MARROCOS = '504',
  MOCAMBIQUE = '508',
  OMA = '512',
  NAMIBIA = '516',
  NAURU = '520',
  NEPAL = '524',
  PAISES_BAIXOS = '528',
  ANTILHAS_HOLANDESAS = '530',
  CURA_AO = '531',
  ARUBA = '533',
  SINT_MAARTEN = '534',
  BONAIRE_SAINT_EUSTATIUS_E_SABA = '535',
  NOVA_CALEDONIA = '540',
  VANUATU = '548',
  NOVA_ZELANDIA = '554',
  NICARAGUA = '558',
  NIGER = '562',
  NIGERIA = '566',
  NIUE = '570',
  ILHAS_NORFOLK = '574',
  NORUEGA = '578',
  ILHAS_MARIANAS_DO_NORTE = '580',
  ILHAS_MENORES_DISTANTES_DOS_ESTADOS_UNIDO = '581',
  MICRONESIA_ESTADOS_FEDERADOS_DA = '583',
  ILHAS_MARSHALL = '584',
  PALAU = '585',
  PAQUISTAO = '586',
  PANAMA = '591',
  PAPUASIA_NOVA_GUINE = '598',
  PARAGUAI = '600',
  PERU = '604',
  FILIPINAS = '608',
  PITCAIRN = '612',
  POLONIA = '616',
  PORTUGAL = '620',
  GUINE_BISSAU = '624',
  TIMOR_LESTE = '626',
  PORTO_RICO = '630',
  CATAR = '634',
  REUNIAO = '638',
  ROMENIA = '642',
  RUSSIA_FEDERACAO_DA = '643',
  RUANDA = '646',
  S_O_BARTOLOMEU = '652',
  SANTA_HELENA = '654',
  SAO_CRISTOVAO_E_NEVIS = '659',
  ANGUILA = '660',
  SANTA_LUCIA = '662',
  SAO_PEDRO_E_MIQUELON = '666',
  SAO_VICENTE_E_GRANADINAS = '670',
  SAO_MARINO = '674',
  SAO_TOME_E_PRINCIPE = '678',
  ARABIA_SAUDITA = '682',
  SENEGAL = '686',
  SERVIA = '688',
  SEYCHELLES = '690',
  SERRA_LEOA = '694',
  SINGAPURA = '702',
  ESLOVACA_REPUBLICA = '703',
  VIETNAME = '704',
  ESLOVENIA = '705',
  SOMALIA = '706',
  AFRICA_DO_SUL = '710',
  ZIMBABWE = '716',
  ESPANHA = '724',
  SOUTH_SUDAN = '728',
  SUDAN = '729',
  SARA_OCIDENTAL = '732',
  SUDAO = '736',
  SURINAME = '740',
  SVALBARD_E_A_ILHA_DE_JAN_MAYEN = '744',
  SUAZILANDIA = '748',
  SUECIA = '752',
  SUICA = '756',
  SIRIA_REPUBLICA_ARABE_DA = '760',
  TAJIQUISTAO = '762',
  TAILANDIA = '764',
  TOGO = '768',
  TOKELAU = '772',
  TONGA = '776',
  TRINDADE_E_TOBAGO = '780',
  EMIRATOS_ARABES_UNIDOS = '784',
  TUNISIA = '788',
  TURQUIA = '792',
  TURQUEMENISTAO = '795',
  TURCOS_E_CAICOS_ILHAS = '796',
  TUVALU = '798',
  UGANDA = '800',
  UCRANIA = '804',
  MACEDONIA_ANTIGA_REPUBLICA_JUGOSLAVA_DA = '807',
  UNI_O_SOVIETICA = '810',
  EGIPTO = '818',
  REINO_UNIDO = '826',
  ILHAS_DO_CANAL = '830',
  GUERNSEY = '831',
  JERSEY = '832',
  ILHA_DE_MAN = '833',
  TANZANIA_REPUBLICA_UNIDA_DA = '834',
  ESTADOS_UNIDOS = '840',
  ILHAS_VIRGENS_ESTADOS_UNIDOS = '850',
  BURKINA_FASO = '854',
  URUGUAI = '858',
  USBEQUISTAO = '860',
  VENEZUELA = '862',
  WALLIS_E_FUTUNA_ILHAS = '876',
  SAMOA = '882',
  IEMEN = '887',
  JUGOSL_VIA = '891',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SERVIA_E_MONTENEGRO = '891',
  ZAMBIA = '894',
  OLIVEN_A = '900',
  REPUBLICA_DO_KOSOVO = '901',
  ALDERNE = '956'
}

export enum ETiposDadosDerrama {
  NA,
  PostosTrabalhoCriados,
  VolumeNegocios,
  InvestimentoNoAno,
  TemSedeNoConcelho,
  AnoFixacaoDaSede,
  MicroPequenaMediaEmpresa,
  AcrescimoTrabalhadores,
  PostosTrabalhoCriados2019,
  PostosTrabalhoCriados2020
}

export interface IRostoItemValorNIF extends IJsonModelo22RostoItemValorNIF {
  _index?: number;
}

export interface IModelo22ItemPeriodoMontante extends IJsonModelo22RostoItemPeriodoMontante {
  _index?: number;
}

export interface IRostoItemNIFValorPeriodo extends IJsonModelo22RostoItemNIFValorPeriodo {
  _index?: number;
}

export interface IRostoQuadro14List extends IJsonModelo22RostoQuadro14Item {
  _index?: number;
}

export interface IAnexoAQuadro4ListaAB extends IJsonModelo22AnexoAItem04AB {
  _index?: number;
}

export interface IAnexoAQuadro4ListaC extends IJsonModelo22AnexoAItem04C {
  _index?: number;
}

export interface IAnexoAQuadro4ListaD extends IJsonModelo22AnexoAItem04D {
  _index?: number;
}

export interface IAnexoDBeneficioMontante extends IJsonModelo22AnexoDBeneficioMontante {
  _index?: number;
}

export interface IAnexoDItem041 extends IJsonModelo22AnexoDItem041 {
  _index?: number;
}

export interface IAnexoDItem071and073and074 extends IJsonModelo22AnexoDItem071and073and074 {
  _index?: number;
}

export interface IAnexoDItem071Aand073Aand074A extends IJsonModelo22AnexoDItem071Aand073Aand074A {
  _index?: number;
}

export interface IAnexoDItem076 extends IJsonModelo22AnexoDItem076 {
  _index?: number;
}

export interface IAnexoDItem076A extends IJsonModelo22AnexoDItem076A {
  _index?: number;
}

export interface IAnexoDItem079 extends IJsonModelo22AnexoDItem079 {
  _index?: number;
}

export interface IAnexoDItem0710 extends IJsonModelo22AnexoDItem0710 {
  _index?: number;
}

export interface IAnexoDItem0710A extends IJsonModelo22AnexoDItem0710A {
  _index?: number;
}

export interface IAnexoDItem077 extends IJsonModelo22AnexoDItem077 {
  _index?: number;
}

export interface IAnexoDItem078 extends IJsonModelo22AnexoDItem078 {
  _index?: number;
}

export interface IAnexoDItem078A1 extends IJsonModelo22AnexoDItem078A1 {
  _index?: number;
}

export interface IAnexoDItem078A2 extends IJsonModelo22AnexoDItem078A2 {
  _index?: number;
}

export interface IAnexoDItem078A3 extends IJsonModelo22AnexoDItem078A3 {
  _index?: number;
}

export interface IAnexoDItem08 extends IJsonModelo22AnexoDItem08 {
  _index?: number;
}

export interface IModelo22AnexoDItem121 extends IJsonModelo22AnexoDItem121 {
  _index?: number;
}

export interface IModelo22AnexoDItem122 extends IJsonModelo22AnexoDItem122 {
  _index?: number;
}

export interface IAnexoEQuadro3Lista extends IJsonModelo22AnexoEList {
  _index?: number;
}

export interface IAnexoFQuadro6ListaA extends IJsonModelo22AnexoFList6A {
  _index?: number;
}

export interface IAnexoFQuadro6ListaB extends IJsonModelo22AnexoFList6B {
  _index?: number;
}

export interface IAnexoGQuadro3Lista extends IJsonModelo22AnexoGList03 {
  _index?: number;
}

export interface IAnexoGQuadro4Lista extends IJsonModelo22AnexoGList04 {
  _index?: number;
}

export interface IAnexoAQuadro4ListaATotais {
  title?: string;
  massaSalarial: number;
}

export interface IAnexoAQuadro4ListaBTotais {
  title?: string;
  massaSalarial: number;
}

export interface IAnexoAQuadro4ListaCTot {
  title?: string;
  massasalprestmun: number;
  areintexplomun: number;
  poteinstmun: number;
  toteletrprodmun: number;
}

export interface IAnexoAQuadro4ListaDTot {
  title?: string;
  massasalprestmun: number;
  areintexplomun: number;
  prodbocaminamun: number;
}

export interface IAnexoDQuadro7Totals {
  title?: string;
  _total1?: number;
  _total2?: number;
  _total3?: number;
  _total4?: number;
  _total5?: number;
  _total6?: number;
}

export interface IAnexoEQuadro3Totais {
  title?: string;
  rendimento: number;
  materiaColetavel: number;
}

export const MODULE_NAME_MODELO_22 = 'modelo22';
