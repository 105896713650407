import {Component, Input} from '@angular/core';

@Component({
  selector: 'pl-spinner',
  templateUrl: './spinner.component.html',
  standalone: false
})
export class PlSpinnerComponent {
  @Input() public type: string;
}
